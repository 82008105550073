module.exports = do ->
   _ = require("underscore").default
   moment = require("moment")
   EverTrue = require("app")
   {createStore} = require("@evertrue/et-flux")
   ImportSource = require("apps/layout/sources/import-source")

   createStore "ImportStore",
      getInitialState: ->
         last_update_timestamp: undefined
         loading: false

      registerActions: ->
         @on ImportSource.actions.loading, (is_loading) ->
            @setState {loading: is_loading}
         @on ImportSource.actions.fetchedLastImport, (timestamp) ->
            @setState {last_update_timestamp: timestamp}

      api:
         getLastUpdate: ->
            if timestamp = @getState("last_update_timestamp")
               moment(timestamp).format("MM/DD/YYYY @ h:mm A")

         getLastUpdateTimeStamp: ->
            @getState("last_update_timestamp")

         getLoading: ->
            @getState("loading")
