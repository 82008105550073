import PropTypes from "prop-types";
import { PageHeader, Icon, Button } from "@evertrue/et-components";
import AddTaskButton from "./create/add-task-button";
import EmailAlertsButton from "./create/email-alerts-button";
import EverTrue from "app";

const TasksHeader = ({ userType }) => {
  return (
    <div className="task-header-container">
      <Icon
        icon="chevron-left"
        size={2}
        className="task-header-back"
        onClick={() => {
          EverTrue.UrlManager.navigate("/home", true, false);
        }}
      />
      <PageHeader
        title="Tasks"
        actions={
          <>
            <EmailAlertsButton />
            <AddTaskButton userType={userType} />
            <Button type="simple" href="settings">
              <Icon icon="gear" size={1} className="task-header-settings-icon" />
            </Button>
          </>
        }
      />
    </div>
  );
};

TasksHeader.propTypes = {
  userType: PropTypes.string,
};

export default TasksHeader;
