module.exports = do ->
   EverTrue = require("app")
   React = require("react")
   {div} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   ModalConfirm = createFactory require("components/modals/modal-confirm")
   Modal = createFactory require("components/modals/modal")
   LoginSource = require("apps/login/sources/login-source")
   LayoutSource = require("apps/layout/sources/layout-source")
   SessionSource = require("base/session/session-source")
   OrgStore = require("base/org/org-store").default

   {createComponent} = require("@evertrue/et-flux")

   createComponent "LinkedinPromptController",
      registerStores: ->
         @on OrgStore, ->
            org: OrgStore.getCurrentOrg() || {}

      render: ->
         div className: "modal-container",
            Modal
               width: 420
               keepOpen: true
               remove: ->
                  LayoutSource.removeOverlay()

               ModalConfirm
                  header: "LinkedIn Authentication Expired"
                  requestHide: =>
                     @props.requestHide()
                  hasMargin: true
                  hide_close_icon: true
                  buttonLabel: "Reconnect to LinkedIn"
                  onSubmit: =>
                     # don't replace session if SSO auth
                     unless @state.org.sso_method is "saml"
                        SessionSource.saveToStore()
                        # this clears the the session from localStorage so that the user will will not be
                        # transparently re-logged in when they are redirected back to ET from LI
                     EverTrue.execute("setPageReferrer")
                     LoginSource.redirectToAuthUrl()

                  cancelLabel: "Sign Out"
                  onCancel: ->
                     EverTrue.execute("logout")

                  div className: "linkedin-prompt-controller",
                     "Your connection between EverTrue and LinkedIn has expired. Once you reconnect to LinkedIn,
                     you will be redirected back to EverTrue.
                  "
