import _ from "underscore";
import moment from "moment";
import { createRef, Component } from "react";
import PropTypes from "prop-types";
import EverTrue from "app";
import Api from "entities/helpers/api";
import get from "lodash.get";
import { connect } from "@evertrue/et-flux";
import { ModalConfirm, AdvancedCombobox, Switch } from "@evertrue/et-components";
import ProfileInteractionsStore from "apps/profile/stores/profile-interactions-store";
import ProfileInteractionsSource from "apps/profile/sources/profile-interactions-source";
import DNAStore from "base/dna/dna-store";
import InteractionSource from "apps/interactions/sources/interaction-source";
import InteractionStore from "apps/interactions/stores/interaction-store";
import CalendarPickerDropdown from "components/controls/calendars/calendar-picker-dropdown";
import CommentTextarea from "apps/profile/components/interactions/comment-textarea";
import CustomFields from "apps/interactions/components/interaction-custom-fields-form-element";
import FormField from "components/forms/form-field";
import FeatureStore from "apps/layout/stores/feature-store";
import InteractionLabelField from "apps/interactions/components/create/interaction-label-field";
import ContactCard from "apps/contact/components/contact-card/contact-card";
import CustomFieldsSource from "apps/interactions/sources/custom-fields-source";
import CustomFieldsStore from "apps/interactions/stores/custom-fields-store";
import { WithIdentity } from "base/identity-resolver";
import Decorator from "clientDecorator";
import CachedContactSource from "apps/contact/sources/cached-contact-source";
import InteractionFormAdditionalConstituents from "apps/interactions/components/create/interaction-form-additional-constituents";
import InteractionFormSolicitors from "apps/interactions/components/create/interaction-form-solicitors";
import InteractionFormDxo from "./interaction-form-dxo";
import { getContactIsSolicitor } from "apps/portfolio-performance/portfolio-queries";
import { formatDateToUnixWithCurrentTime } from "base/new-utils";
import {
  isSubmitInteractionDisabled,
  CreateInteractionApi,
  getHouseholdMembersByIds,
} from "apps/interactions/utils/interactions-utils";
import CharacterCounter from "../character-counter";
import { INTERACTION_CHARACTER_LIMIT } from "apps/proposals/utils";
import { getHouseholdMembersForContact } from "apps/profile/utils/profile-requests";

const DXO_ASK = "DXO Ask";
const ADD_PINNED_COMMENT_ACTION = "add-pinned-comment-action";

const _parseInteraction = (interaction = {}) => {
  return _.extend({}, _.omit(interaction, "label"), {
    labels: _.mapObject(_.groupBy(interaction.label, "name"), (val) =>
      // The platform supports multiple values with the same name,
      // however for editing purposes we're only supporting single selects
      // so we'll pick the first value that's provided.
      _.first(_.map(val, (item) => ({ value: item.value, label: item.value })))
    ),
    custom_field: _.reduce(
      interaction.custom_field,
      (accum, field) => {
        accum[field.id] = field.value;
        return accum;
      },
      {}
    ),
    secondary_target: interaction.secondary_target || [],
  });
};

const _defaultInteraction = () => ({
  interaction_type: undefined,
  summary: undefined,
  text: undefined,
  date_occurred: moment().valueOf(),
  labels: {},
  custom_field: {},
  secondary_target: [],
  ask_date: moment().valueOf(),
  ask_amount: null,
});

const mapStateToProps = () => ({
  interaction_types: _.uniq(ProfileInteractionsStore.getWritableTypes(), (i) => i.label),
  labels: InteractionStore.getLabels(),
  label_options: InteractionStore.getLabelValues(),
  has_fetched_labels: InteractionStore.hasFetchedLabels(),
  has_interaction_writes: FeatureStore.hasInteractionWrites(),
  has_custom_fields: FeatureStore.hasFeature("interaction_custom_fields"),
  org_custom_fields: CustomFieldsStore.getCustomFields().filter((field) => field.active),
  is_summary_required: DNAStore.getByKey("required_summary"),
  interaction_solicitor_limit: _.toNumber(DNAStore.getByKey("interaction_solicitor_limit")),
  has_rm_assignments: FeatureStore.hasFeature("rm_assignments"),
  interaction_standard_fields_config: DNAStore.getByKeyWithDefault({
    key: "interaction_standard_fields_config",
    options: {
      max_length: (a, b) => a < b,
    },
  }),
});

const _parseUser = (user, oid) => {
  const affils = Decorator.User.getAffiliationFor(user, oid) || {};
  return { remote_user_id: affils.remote_user_id, name: user.name, user_id: user.id };
};

// named export is for testing this and not the HOC'd component
export class InteractionForm extends Component {
  static propTypes = {
    interaction: PropTypes.object,
    contact: PropTypes.object,
    contactOptions: PropTypes.array,
    referrer: PropTypes.string,
    editing: PropTypes.bool,
    has_fetched_labels: PropTypes.bool,
    requestHide: PropTypes.func,
    has_custom_fields: PropTypes.bool,
    is_summary_required: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    interaction_solicitor_limit: PropTypes.number,
    org_custom_fields: PropTypes.array,
    interaction_types: PropTypes.array,
    has_interaction_writes: PropTypes.bool,
    labels: PropTypes.array,
    user: PropTypes.object, // HOC injected
    org: PropTypes.object,
    has_rm_assignments: PropTypes.bool,
    interaction_standard_fields_config: PropTypes.object,
    addNewCommentToState: PropTypes.func,
    addNewInteractionToState: PropTypes.func,
    saveLabel: PropTypes.any,
    cancelLabel: PropTypes.any,
  };

  static defaultProps = {
    interaction: {},
    user: {},
    contact: {},
    org_custom_fields: [],
    org: {},
  };

  state = {
    label_query: undefined,
    view_meta_fields: !_.isEmpty(this.props.interaction),
    interaction: _.isEmpty(this.props.interaction) ? _defaultInteraction() : _parseInteraction(this.props.interaction),
    isSubmitting: false,
    householdMembers: [],
    selectedHouseholdMembers: [],
  };

  commentBoxRef = createRef();

  componentDidMount() {
    if (!this.props.has_fetched_labels) {
      InteractionSource.fetchLabels();
    }

    CustomFieldsSource.fetchIfNotCached();
    InteractionSource.fetchTypesUnlessCached();

    // prime cache for tag list names
    const ids = _.pluck(this.props.interaction.secondary_target, "target_id");
    CachedContactSource.fetchContactsByIds(ids);
    this.getHouseholdMembers(ids);

    if (!this.props.interaction.id) {
      this.addUserToNewInteractions();
    }
  }

  addUserToNewInteractions = async () => {
    const user = _parseUser(this.props.user, this.props.org.id);
    const affil = Decorator.User.getAffiliationFor(this.props.user, this.props.org.id) || {};

    if (!this.props.has_rm_assignments) {
      this.handleFormChange("solicitor", [user]);
    } else if (affil.contact_id) {
      try {
        const results = await Api.CONTACTS.SEARCH.post({
          params: { limit: 1 },
          data: _.jsonStringify(getContactIsSolicitor(affil.contact_id)),
        });
        if (results.items.length) {
          this.handleFormChange("solicitor", [user]);
        }
      } catch (e) {
        console.log({ e });
      }
    }
  };

  creatingPinnedComment = () => this.props.referrer === ADD_PINNED_COMMENT_ACTION;

  handleFormChange = (property, value) => {
    const interaction = _.cloneData(this.state.interaction);
    interaction[property] = value;
    this.setState({ interaction });
  };

  handleLabelChange = (label_name, value) => {
    const interaction = _.cloneData(this.state.interaction);
    interaction.labels[label_name] = value;
    this.setState({ interaction });
  };

  handleCustomFieldChange = (customFields) => {
    this.setState((state) => ({ interaction: _.extend({}, state.interaction, { custom_field: customFields }) }));
  };

  handleHouseholdMemberSelect = (selected, member) => {
    if (selected) {
      this.setState({ selectedHouseholdMembers: [...this.state.selectedHouseholdMembers, member] });
    } else {
      this.setState({ selectedHouseholdMembers: this.state.selectedHouseholdMembers.filter((mem) => mem !== member) });
    }
  };

  handleTypeChange = (val) => {
    const category = this.state.interaction.interaction_type;
    const interaction = _.cloneData(this.state.interaction) || {};
    interaction.interaction_type = val.value;

    if (category === "EverTrue Comment" && !_.isEmpty(interaction.mentions)) {
      const string = get(this.commentBoxRef, "current.refs.textfield.innerText", "");
      interaction.text = string;
    }

    if (val.value === DXO_ASK) {
      interaction.solicitor = [_parseUser(this.props.user, this.props.org.id)];
    }
    this.setState({ interaction });
  };

  buildRequestPayload = (interaction) => {
    let payload;
    if (interaction.interaction_type === DXO_ASK) {
      payload = {
        author: interaction.author,
        id: interaction.id,
        interaction_type: interaction.interaction_type,
        text: interaction.text,
        solicitor: interaction.solicitor,
        ask_date: interaction.ask_date,
        ask_amount: interaction.ask_amount,
        substantive: interaction.substantive,
      };
    } else {
      payload = {
        author: interaction.author,
        id: interaction.id,
        interaction_type: interaction.interaction_type,
        summary: interaction.summary,
        text: interaction.text,
        solicitor: interaction.solicitor,
        date_occurred: interaction.date_occurred,
        meeting_id: interaction.meeting_id,
        trip_id: interaction.trip_id,
        substantive: interaction.substantive,
        proposal_id: interaction.proposal_id,
        label: _.flatten(_.map(interaction.labels, (val_object, key) => ({ name: key, value: val_object.value }))),
        custom_field: _.map(interaction.custom_field, (value, key) => ({ id: _.toNumber(key), value })),
        secondary_target: _.map(interaction.secondary_target, (contact) => {
          const remote_id = Decorator.Relationships.getRemoteId(contact) || contact.remote_id;
          return { target_id: contact.id || contact.target_id || contact.contact_id, ...(remote_id && { remote_id }) };
        }),
      };
    }

    return payload;
  };

  submitComment = async (comment) => {
    this.setState({ isSubmitting: true });
    this.props.requestHide();
    const { interaction } = this.state;
    const target_id = this.props.contact.id || interaction.contact_id;

    if (this.props.referrer) {
      EverTrue.track.setReferrer(this.props.referrer);
    }

    const valid_comment = _.omit(comment, "summary", "date_occurred");
    valid_comment.interaction_type = "EverTrue Comment";
    const newComment = await new CreateInteractionApi(target_id, valid_comment).saveComment();
    ProfileInteractionsSource.saveCommentFromResponse(target_id, newComment);
    if (typeof this.props.addNewCommentToState === "function") {
      this.props.addNewCommentToState(newComment);
    }
  };

  handleSubmit = async () => {
    this.setState({ isSubmitting: true });
    this.props.requestHide();
    const { interaction } = this.state;
    const target_id = this.props.contact.id || interaction.contact_id;
    const additionalSecondaryTargets = this.state.selectedHouseholdMembers;

    if (this.props.referrer) {
      EverTrue.track.setReferrer(this.props.referrer);
    }

    if (additionalSecondaryTargets.length > 0) {
      const householdMembers = await getHouseholdMembersByIds(additionalSecondaryTargets);
      interaction.secondary_target = interaction.secondary_target.concat(householdMembers);
    }

    const payload = this.buildRequestPayload(interaction);

    const newInteraction = await new CreateInteractionApi(target_id, payload).save();
    ProfileInteractionsSource.saveInteractionFromResponse(target_id, newInteraction);

    if (typeof this.props.addNewInteractionToState === "function") {
      this.props.addNewInteractionToState(newInteraction);
    }
  };

  handleToggleMeta = () => {
    this.setState({ view_meta_fields: !this.state.view_meta_fields });
  };

  getFieldLength = (field) => {
    if (!this.state.interaction[field]) return 0;
    return this.state.interaction[field].length;
  };

  getHouseholdMembers = async (excludedIds) => {
    const contact = this.props.contact || this.props.interaction.contact || this.state.interaction.contact || null;
    if (contact && contact.id) {
      try {
        const householdMembers = await getHouseholdMembersForContact(contact);
        this.setState({ householdMembers: householdMembers.filter((mem) => !excludedIds.includes(mem.id)) });
      } catch (e) {
        console.error(`Failed to fetch household members. Error: ${e}`);
      }
    }
  };

  render() {
    const { substantive, solicitor, date_occurred } = this.props.interaction_standard_fields_config;
    const {
      is_summary_required,
      has_custom_fields,
      org_custom_fields,
      interaction_standard_fields_config,
      has_interaction_writes,
    } = this.props;

    const { interaction, isSubmitting } = this.state;

    // Status, when available, is an important field to users, but is represented as a "label" in our data model
    // We treat it specially in the UI if it's imported as a label.
    let has_grouped_contacts, selected_constituent;
    const has_status = _.contains(this.props.labels, "status");
    const category = this.state.interaction.interaction_type;
    const category_object = _.findWhere(this.props.interaction_types, { category });
    const is_gt_comment = category === "EverTrue Comment" || !has_interaction_writes;
    const isDxoAsk = category === DXO_ASK;
    const areCustomFields = has_custom_fields && org_custom_fields.length;
    const isSummaryRequired =
      typeof is_summary_required === "string" ? is_summary_required.toLowerCase() === "true" : is_summary_required;

    if (this.props.contactOptions) {
      has_grouped_contacts = get(this.props.contactOptions, "[0].items", []).length;
      const contacts = has_grouped_contacts
        ? _.flatten(_.pluck(this.props.contactOptions, "items"))
        : this.props.contactOptions;
      selected_constituent = _.findWhere(contacts, { value: this.state.interaction.contact_id });
    }

    const header_text = this.props.editing ? "Edit" : "Add";
    const { interaction_solicitor_limit } = this.props;
    const solicitor_limit_text = interaction_solicitor_limit ? ` (${interaction_solicitor_limit} Maximum)` : "";
    const contact = this.props.contact.id ? this.props.contact : this.props.interaction.contact;
    const householdMembers = this.state.householdMembers;

    return (
      <div className="interaction-form">
        <ModalConfirm
          saveLabel={this.props.saveLabel}
          cancelLabel={this.props.cancelLabel}
          header={has_interaction_writes ? `${header_text} Interaction` : "Add Comment"}
          disableSave={isSubmitInteractionDisabled(
            has_interaction_writes,
            interaction,
            isSummaryRequired,
            has_custom_fields,
            org_custom_fields,
            interaction_standard_fields_config,
            isSubmitting
          )}
          onSubmit={!is_gt_comment ? this.handleSubmit : undefined}
          onCancel={this.props.requestHide}
        >
          <div className="interaction-form--fields">
            {contact ? (
              <ContactCard contact={contact} visibleData={["roleWithYear"]} showNameLink={false} />
            ) : this.props.contactOptions ? (
              <FormField label="Constituent" inlineLabel={false}>
                <AdvancedCombobox
                  value={selected_constituent}
                  options={this.props.contactOptions}
                  grouped={Boolean(has_grouped_contacts)}
                  searchable={true}
                  placeholder="Select constituent..."
                  onChange={(val) => {
                    this.handleFormChange("contact_id", val.value);
                  }}
                />
              </FormField>
            ) : null}

            {!is_gt_comment && !isDxoAsk && (
              <InteractionFormAdditionalConstituents
                interaction={this.state.interaction}
                handleFormChange={this.handleFormChange}
                contactToExclude={contact}
                householdMembers={householdMembers}
                handleHouseholdMemberSelect={this.handleHouseholdMemberSelect}
                numSelectedHouseholdMembers={this.state.selectedHouseholdMembers.length}
                numAdditionalConstituents={this.state.interaction.secondary_target.length}
              />
            )}

            <div className="interaction-form--constituent-block">
              {has_interaction_writes &&
                !this.creatingPinnedComment() && ( // if a user is creating a pinned comment, the "type" field should be pre-filled
                  <div className="interaction-form--type">
                    <FormField label="Interaction Type" inlineLabel={false} required={true}>
                      <AdvancedCombobox
                        value={
                          category_object
                            ? { label: category_object.label, value: category_object.category }
                            : { label: null, value: null }
                        }
                        disabled={!(this.props.contact.id || this.state.interaction.contact_id)}
                        options={_.map(this.props.interaction_types, (data) => ({
                          label: data.label,
                          value: data.category,
                        }))}
                        placeholder="Select type..."
                        searchable={true}
                        onChange={this.handleTypeChange}
                        label="Select interaction type"
                      />
                    </FormField>
                  </div>
                )}
              {this.state.interaction.interaction_type !== "EverTrue Comment" && (
                <div className="interaction-form--substantive">
                  <FormField label="Substantive" inlineLabel={false} required={substantive.required}>
                    <Switch
                      name="substantive"
                      showLabel={false}
                      checked={this.state.interaction.substantive || false}
                      onChange={(checked) => this.handleFormChange("substantive", checked)}
                    />
                  </FormField>
                </div>
              )}
            </div>
          </div>
          {isDxoAsk ? (
            <div className="form-limit">
              <InteractionFormDxo
                askDate={this.state.interaction.ask_date}
                askAmount={this.state.interaction.ask_amount}
                description={this.state.interaction.text}
                handleFormChange={this.handleFormChange}
                interaction_standard_fields_config={interaction_standard_fields_config}
              />
              {interaction_standard_fields_config.dxo_text.max_length < INTERACTION_CHARACTER_LIMIT.DXO_TEXT && (
                <CharacterCounter
                  count={this.getFieldLength("text")}
                  limit={interaction_standard_fields_config.dxo_text.max_length}
                />
              )}
            </div>
          ) : is_gt_comment ? (
            <CommentTextarea
              ref={this.commentBoxRef}
              className="interaction-form--gt-comment"
              open={true}
              disabled={!(this.props.contact.id || this.state.interaction.contact_id)}
              comment={this.props.interaction || {}}
              onSave={this.submitComment}
              onCancel={this.props.requestHide}
              disableCheckbox={this.creatingPinnedComment()} // if a user is creating a pinned comment, this will be "true" and unchangeable
            />
          ) : (
            <div className="interaction-form--fields">
              <div className="interaction-form--date-solicitor">
                <FormField
                  inlineLabel={false}
                  label="Date"
                  className="interaction-form--date interaction-form--column"
                  required={date_occurred.required}
                >
                  <CalendarPickerDropdown
                    label="Date of interaction"
                    value={
                      this.state.interaction.date_occurred
                        ? moment(this.state.interaction.date_occurred).format("YYYY-MM-DD")
                        : null
                    }
                    hideClear={true}
                    onChange={(val) => {
                      const date = formatDateToUnixWithCurrentTime(val);
                      if (date) this.handleFormChange("date_occurred", date);
                    }}
                  />
                </FormField>
                <FormField
                  inlineLabel={false}
                  label={`Solicitors${solicitor_limit_text}`}
                  className="interaction-form--column interaction-form--solicitors"
                  required={solicitor.required}
                  requiredLabelSize={"small"}
                >
                  <InteractionFormSolicitors
                    interaction_solicitor={this.state.interaction.solicitor}
                    handleFormChange={this.handleFormChange}
                  />
                </FormField>
              </div>

              {has_status && (
                <FormField label="Status" inlineLabel={false}>
                  <InteractionLabelField
                    label="status"
                    interaction={this.state.interaction}
                    onChange={this.handleLabelChange}
                  />
                </FormField>
              )}
              <div className="form-limit">
                <FormField required={isSummaryRequired} label="Summary" inlineLabel={false}>
                  <input
                    type="text"
                    value={this.state.interaction.summary}
                    className="interaction-form--summary"
                    placeholder={`Add a summary${!isSummaryRequired ? " (optional)..." : ""}`}
                    onChange={(e) => this.handleFormChange("summary", e.currentTarget.value)}
                  />
                </FormField>
                {interaction_standard_fields_config.summary.max_length < INTERACTION_CHARACTER_LIMIT.SUMMARY && (
                  <CharacterCounter
                    count={this.getFieldLength("summary")}
                    limit={interaction_standard_fields_config.summary.max_length}
                  />
                )}
                <FormField label="Description" inlineLabel={false} required={true}>
                  <textarea
                    value={this.state.interaction.text}
                    placeholder="Add details..."
                    onChange={(e) => this.handleFormChange("text", e.currentTarget.value)}
                  />
                </FormField>
                {interaction_standard_fields_config.text.max_length < INTERACTION_CHARACTER_LIMIT.TEXT && (
                  <CharacterCounter
                    count={this.getFieldLength("text")}
                    limit={interaction_standard_fields_config.text.max_length}
                  />
                )}
              </div>
              {/* Custom Fields */}
              {!!areCustomFields && (
                <CustomFields
                  onChange={this.handleCustomFieldChange}
                  interactionCustomFields={this.state.interaction.custom_field}
                  orgCustomFields={this.props.org_custom_fields}
                />
              )}
            </div>
          )}
        </ModalConfirm>
      </div>
    );
  }
}

export default _.compose(WithIdentity, connect)(
  InteractionForm,
  [ProfileInteractionsStore, InteractionStore, CustomFieldsStore, DNAStore],
  mapStateToProps
);
