module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default

   OperatorQuery = (property, val, type) ->
      operator_object = {}
      isNestedType = false
      value = val

      if _.isObject(val) && !_.isArray(val)
         value = $.extend(true, {}, val)

      if !type
         if _.isArray(value) then type = "contains"
         else if _.isObject(value) then type = "object"
         else type = "equals"

      wrapper = (operator) ->
         isNestedType = true
         operator_object[property] = {}
         operator_object[property][operator] = value

      query_types =
         contains: -> wrapper("in")
         object: -> operator_object[property] = value
         equals: -> wrapper("match")
         exists: -> wrapper("exists")
         instance: -> wrapper("instance")
         group: -> wrapper("bucket_by")
         passThrough: -> operator_object = value
         coerce: ->
            operator_object[property] = value || {}
            operator_object[property].coerce = 0
         wildcard: ->
            operator_object[property] =
               wildcard: value + "*"
      {
         toJSON: ->
            query_types[type]()
            operator_object
         isNestedType: -> isNestedType
      }

   return OperatorQuery
