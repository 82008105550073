module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")


   createSource "ShareSegmentSource",
      actions:
         fetchedSharedSearch: true

         creatingSharedLink: (navigate) ->
            @require _.isUndefined(navigate) or _.isBoolean(navigate), "if passed, navigate should be a boolean"
         createdShareLink: (resp, navigate) ->
            @require _.isObject(resp), "share link response should be an object"
            @require _.isUndefined(navigate) or _.isBoolean(navigate), "if passed, navigate should be a boolean"
         errorCreatingSharedLink: (navigate) ->
            @require _.isUndefined(navigate) or _.isBoolean(navigate), "if passed, navigate should be a boolean"

         sentShareLink: true
         fetchedFilters: true
         clearActiveLink: true
         errorFetchingFilters: true

      api:
         fetchFilters: (hash_id) ->
            user_id = EverTrue.UrlManager.get("sharer_id")
            user_id ?= EverTrue.store.user.get("id")

            Api.CONTACTS.SEARCH_SHARE.get
               disableAlerts: true
               params:
                  hash_id: hash_id
                  sharer_user_id: user_id
               success: (resp) =>
                  @actions.fetchedFilters(resp)
               error: =>
                  @actions.errorFetchingFilters(hash_id)

         createShareLink: (data, navigate) ->
            @actions.creatingSharedLink(navigate)
            Api.CONTACTS.SEARCH_SHARE.post
               data: _.jsonStringify(data)
               success: (resp) =>
                  @actions.createdShareLink(resp, navigate)
               error: =>
                  @actions.errorCreatingSharedLink(navigate)

         clearActiveLink: ->
            @actions.clearActiveLink()

         sendShareLink: (user_ids, data) ->
            Api.CONTACTS.SEARCH_SHARE.post
               data: _.jsonStringify _.extend {}, data,
                  share_with_users: _.uniq _.map user_ids, (id) ->
                     {share_with_user_id: _.toNumber(id)}
               success: (resp) =>
                  @actions.sentShareLink(resp, user_ids)
