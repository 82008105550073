module.exports = do ->
   _ = require("underscore").default

   # This is the new version of PaginationMixin
   # to be used without backbone collections.
   PaginationUtils =
      getBoundary: (data={}) ->
         {total, limit, offset} = data
         if total <= limit then "none"
         else if offset == 0 then "first"
         else if offset + limit > total then "last"
         else false

      isFinalPage: (data={}) ->
         {total, limit, offset} = data
         offset + limit >= total

      getPageOffset: (data={}) ->
         {page, total, limit} = data
         max_page = Math.ceil(total/limit)
         if page <= 1
            page = 1
         else if page > max_page
            page = max_page
         Math.max(page - 1, 0) * limit

      getParams: (data={}) ->
         {offset: @getPageOffset(data) || 0, limit: data.limit}

      getPage: (data={}) ->
         {total, limit, offset} = data
         page = (offset / limit) + 1
         _.withinRange(page, 1, Math.ceil(total/limit))

      paginate: (data={}) ->
         page = data.page || @getPage(data)
         _.extend {}, data,
            offset: @getPageOffset(_.extend {}, data, page: page + 1)

      mergePagedResults: (cached={}, new_results) ->
         if new_results?.offset > 0
            aggregate_items = _.cloneData(cached?.items) || []
            _.extend {}, cached, new_results,
               items: aggregate_items.concat(new_results.items)
         else new_results





