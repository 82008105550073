module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   EverTrue = require("app")
   React = require("react")
   BaseRouter = require("base/base-router")
   CompanySource = require("apps/companies/sources/company-source")
   CompanyStore = require("apps/companies/stores/company-store")
   CompanyProfileController = require("apps/companies/controllers/company-profile-controller")


   class CompanyRouter extends BaseRouter
      routes:
         "company/:id": "company"
         "company*splat": "company" # account for name
         "industry/:id": "industry"
         "industry*splat": "industry" # account for name

      beforeRoute: ->
         EverTrue.execute "removePageContainer"
         $(document).scrollTop(0)

      company: (id) ->
         if !_.isNaN(_.toNumber(id))
            CompanySource.fetchCompany(_.toNumber(id))
         else
            name = EverTrue.UrlManager.get("name")
            CompanySource.fetchCompany(name)

         EverTrue.vent.trigger "set:page:title", "Company"
         EverTrue.track.set "profile", {type: "company"}
         EverTrue.track.setReferrer("company")

         @currentView = CompanyProfileController id: id, type: "company"

         EverTrue.page.mount @currentView

      industry: ->
         EverTrue.vent.trigger "set:page:title", "Industry"
         EverTrue.track.set "profile", {type: "industry"}
         EverTrue.track.setReferrer("industry")

         name = EverTrue.UrlManager.get("name")

         CompanySource.fetchCompaniesForIndustry(name)
         @currentView = CompanyProfileController type: "industry"

         EverTrue.page.mount @currentView
