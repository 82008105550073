import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "underscore";
import { useFluxStore } from "@evertrue/et-flux";
import Api from "entities/helpers/api";
import FeatureStore from "apps/layout/stores/feature-store";
import { Loading, Icon, LinkButton } from "@evertrue/et-components";
import { useCachedContactByIdsResolver } from "@evertrue/vms-insights";
import ProposalDetailActions from "../components/proposal-detail-actions";
import ProposalDetailContacts from "../components/proposal-detail-contacts";
import ProposalDetailAmounts from "../components/proposal-detail-amounts";
import ProposalDetailInteractions from "../components/proposal-detail-interactions";
import useProposalStages from "apps/proposals/hooks/use-proposal-stages";

const featureStoreMapState = () => ({
  hasInteractionWrites: FeatureStore.hasInteractionWrites(),
  hasProposalWrites: FeatureStore.hasFeature("proposal_writes"),
  hasProposalReporting: FeatureStore.hasFeature("proposal_reported"),
});

const ProposalDetailController = ({ id }) => {
  const [loading, setLoading] = useState(false);
  const [proposal, setProposal] = useState({});
  const [proposalStages, loadingStages] = useProposalStages();
  const { hasProposalWrites, hasInteractionWrites, hasProposalReporting } = useFluxStore(
    FeatureStore,
    featureStoreMapState
  );
  const primaryContact = _.findWhere(proposal.contacts, { type: "PRIMARY" }) || {};
  // Some contacts dont have contact_ids so need to filter them out
  const prospectIds = _.pluck(proposal.contacts, "contact_id").filter((id) => !!id);
  const solicitorIds = _.pluck(proposal.solicitors, "contact_id").filter((id) => !!id);
  const contactIds = prospectIds.concat(solicitorIds);
  let { contacts } = useCachedContactByIdsResolver(contactIds); // full contact records for both prospects and solicitors

  useEffect(() => {
    setLoading(true);

    Api.SEARCH.PROPOSAL.post({
      data: JSON.stringify({ must: [{ id: { match: id } }] }),
      params: { limit: 1 },
      success: (resp) => {
        if (resp.items) {
          setProposal(resp.items[0]);
        }
        setLoading(false);
      },
      error: () => {
        setLoading(false);
      },
    });
  }, [id]);

  if (loading || loadingStages) {
    return <Loading />;
  }

  return (
    <div className="proposal-detail">
      <div className="proposal-detail--header">
        <div className="proposal-detail--title-section">
          <LinkButton 
            type="secondary"
            label="Go Back"
            className="proposal-detail--back-button" 
            onClick={() => {
                if (window.history.length > 1) {
                  window.history.back();
                }
              }}>
            <Icon icon="chevron-left" size={2} />
          </LinkButton>
          <div>
            <h3 className="proposal-detail--title">{proposal.title}</h3>
            <div>{proposal.proposal_type}</div>
          </div>
        </div>
        {primaryContact.contact_id && (
          <ProposalDetailActions
            proposal={proposal}
            primaryContactId={primaryContact.contact_id}
            hasProposalWrites={hasProposalWrites}
            setProposal={setProposal}
          ></ProposalDetailActions>
        )}
      </div>
      <div className="proposal-detail--body">
        <div className="proposal-detail--column">
          <ProposalDetailContacts proposal={proposal} contacts={contacts} />
        </div>
        <div className="proposal-detail--column-center">
          <ProposalDetailAmounts
            proposal={proposal}
            setProposal={setProposal}
            proposalStages={proposalStages}
            hasProposalWrites={hasProposalWrites}
            hasProposalReporting={hasProposalReporting}
          />
        </div>
        <div className="proposal-detail--column">
          <ProposalDetailInteractions
            proposalId={proposal.id}
            contact={contacts[primaryContact.contact_id]}
            hasInteractionWrites={hasInteractionWrites}
          />
        </div>
      </div>
    </div>
  );
};

ProposalDetailController.propTypes = {
  id: PropTypes.number.isRequired,
};

export default ProposalDetailController;
