module.exports = do ->
   React = require("react")
   EverTrue = require("app")
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   HelpTip = createFactory require("components/tooltip/helptip").default
   LinkButton = createFactory(require("@evertrue/et-components").LinkButton)

   createComponent "MyTeamNavItem",
      propTypes:
         report: ReactLibs.PropTypes.string
         title: ReactLibs.PropTypes.any
         value: ReactLibs.PropTypes.any
         subtitle: ReactLibs.PropTypes.any
         helpTip: ReactLibs.PropTypes.string
         selected: ReactLibs.PropTypes.bool

      handleClick: ->
         if @props.report
            EverTrue.Navigator("team/performance/#{@props.report}")
         else
            EverTrue.Navigator("team/performance")

      render: ->
         LinkButton
            title: @props.title
            className: classNames("my-team-nav-item", "is-selected": @props.selected)
            onClick: @handleClick

            div className: "my-team-nav-item--title",
               @props.title
               if @props.helpTip
                  HelpTip help_key: @props.helpTip
            div className: "my-team-nav-item--value", @props.value
