module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   EverTrue = require("app")
   classNames = require("classnames")
   UserStore = require("apps/users/stores/user-store")
   MatchContactStore = require("apps/users/stores/match-contact-store")
   MatchContactSource = require("apps/users/sources/match-contact-source")
   {createComponent} = require("@evertrue/et-flux")
   {createFactory} = require("base/new-utils")
   AdvancedCombobox = createFactory(require("@evertrue/et-components").AdvancedCombobox)
   ModalConfirm = createFactory(require("@evertrue/et-components").ModalConfirm)
   Icon = createFactory(require("@evertrue/et-components").Icon)
   UserCard = createFactory require("apps/users/components/user-card")

   Decorator = require("clientDecorator")
   {div, strong, input, span} = ReactLibs.DOMFactories

   _debounceSearch = _.debounce(MatchContactSource.fetchContactsByName, 300);

   createComponent "MatchContactModal",
      propTypes:
         type: ReactLibs.PropTypes.string
         data: ReactLibs.PropTypes.any

      getInitialState: ->
         selection: null
         input: null
         search_query: null

      registerStores: ->
         @on MatchContactStore, ->
            loading: MatchContactStore.getLoading()
            matched_contact_ids: MatchContactStore.getMatchedContactIds()
            contacts: MatchContactStore.getContacts()
            total_contacts: MatchContactStore.getTotalContacts()

      componentDidMount: ->
         MatchContactSource.fetchMatchedContactIds()

      handleSearch: (query, matched_contact_ids) ->
         if @depracated_is_mounted
            @setState search_query: query
         _debounceSearch(query, matched_contact_ids)

      handleUpdateSelection: (value) ->
         # On clear, AdvancedCombobox hands back and array
         if @depracated_is_mounted
            @setState {selection: if _.isArray(value) then null else (value || null)}

      handleCancel: ->
         if _.isFunction @props.requestHide
            @props.requestHide()

      handleMatch: ->
         contact_id = @state.input || @state.selection?.value || {}
         UserStore.updateContactID(@props.user.id, contact_id)
         @handleCancel()

      render: ->
         contacts = if !@state.search_query then @state.total_contacts else @state.contacts
         has_no_solicitors = _.isEmpty(@state.contacts) and _.isEmpty(@state.total_contacts) and !@state.loading

         options = _.map contacts, (contact) ->
            label: Decorator.Contacts.getFullName(Decorator.Contacts.parse(contact)), value: contact.id

         selection = @state.selection ||  _.findWhere(options, {value: @props.contact_id})

         ModalConfirm
            type: "default",
            header: "Link User to Contact Record",
            saveLabel: "Link",
            className: "match-modal match-user--modal"
            onSubmit: @handleMatch
            onCancel: @handleCancel
            hide_close_icon: true
            disableButton: !(@state.input || selection || selection?.value == @props.contact_id),

            div null,
               div className: "match-modal--intro",
                  "A contact record must be linked to each EverTrue user if you wish to have access to certain
                  functions in EverTrue, such as: interactions, gift officer features, and volunteer management."

               div className: "match-user--body",
                  if @props.contact_id
                     div null, "Currently linked to: ",
                        strong null, @props.contact_id
                  else div className: "match-modal--user-header", "EverTrue User"

                  UserCard user: @props.user

                  div className: "match-user--dropdown-wrapper",
                     span className: "match-user--contact-match-text", "Select a contact to link this user to"

                     AdvancedCombobox
                        className: "match-user--dropdown"
                        options: options
                        value: selection
                        placeholder: "Select by name"
                        search_placeholder: "Search by name..."
                        searchable: true
                        disabled: !!@state.input
                        loading: @state.loading
                        initial_search: @state.search_query
                        allowUnselectAll: true
                        onSearch: (query) => @handleSearch(query, @state.matched_contact_ids)
                        onChange: (val) => @handleUpdateSelection(val)
                        formatItem: (item, isSelected) ->
                           div className: "match-user--result",
                              Icon className: "advanced-combobox-item--selected", icon: "check" if isSelected
                              div className: "emphasis", item.label
                              div className: "text-label", "Contact: #{item.value}"
                        empty_placeholder:
                           div null, "No Results"
