import PoolAssignmentsSource from "apps/volunteers/sources/pool-assignments-source";
import AmountsModal from "apps/volunteers/components/amounts-modal";
import PropTypes from "prop-types";

const AskAmountModal = props => {
  const ask = props.searchAssignment.ask_amount_in_cents;
  return (
    <AmountsModal
      header="Ask Amount"
      amount={ask ? ask / 100 : null}
      label="Suggested Ask Amount:"
      handleSubmit={dollar_val => {
        const { assignment_id } = props.searchAssignment;
        const new_amount_in_cents = parseFloat(dollar_val) * 100;
        PoolAssignmentsSource.createAskAmount(assignment_id, new_amount_in_cents);
        props.close();
      }}
      {...props}
    />
  );
};

AskAmountModal.propTypes = {
  searchAssignment: PropTypes.object,
  close: PropTypes.func,
};

AskAmountModal.defaultProps = {
  searchAssignment: {},
};

export default AskAmountModal;
