import { useState, useEffect } from "react";
import _ from "underscore";
import EverTrue from "app";
import Api from "entities/helpers/api";
import { useGate } from "components/is-gated";
import { EmptyStateMessage } from "@evertrue/et-components";
import FeaturedSegmentsConfig from "apps/filters/featured-segments-config";
import SegmentSource from "apps/filters/sources/segment-source";
import PageHeader from "apps/layout/components/page-header";

import {
  Card,
  SearchInput,
  FlexTable,
  FlexTableSortHeaderCell,
  Icon,
  LinkButton,
  Loading,
  AdvancedCombobox,
} from "@evertrue/et-components";
import colors from "base/colors";

const options = [
  { label: "Daily", value: "daily" },
  { label: "Weekly", value: "weekly" },
  { label: "Biweekly", value: "biweekly" },
  { label: "Monthly", value: "monthly" },
  { label: "Never", value: "never" },
];

const NUM_SEGMENTS = 4;

const columns = (handleSort, sortReverse, handleValueChange, preferences) => [
  {
    key: 1,
    header: (
      <FlexTableSortHeaderCell onClick={() => handleSort(!sortReverse)} sortOrder={sortReverse} isSorted={true}>
        Search Name
      </FlexTableSortHeaderCell>
    ),
    accessor: (i) => (
      <LinkButton title="View Segment" onClick={() => SegmentSource.applySegment(i.id)}>
        {i.name}
      </LinkButton>
    ),
  },

  {
    key: 2,
    header: (
      <>
        <Icon icon="email" style={{ marginRight: 10, fontSize: 20 }} />
        Email Updates
      </>
    ),
    accessor: (i) => {
      const preference = _.findWhere(preferences, { key: `list:${i.id}:` });
      let currentValue = { label: "Never", value: "never" };
      if (preference && preference.email_enabled) {
        currentValue = _.findWhere(options, { value: preference.email_frequency.toLowerCase() });
      }

      return (
        <AdvancedCombobox
          value={currentValue}
          options={options}
          onChange={(value) => handleValueChange(value, "email", i)}
          placeholder="Select Frequency"
          formatSelection={(selectionText) => {
            if (currentValue.value !== "never") {
              return (
                <div className="frequency-selection">
                  <Icon className="notification-icon" icon="alarm" />
                  <span>{selectionText}</span>
                  <Icon icon="chevron-down" />
                </div>
              );
            } else
              return (
                <div className="frequency-selection">
                  {selectionText}
                  <Icon icon="chevron-down" />
                </div>
              );
          }}
        />
      );
    },
  },
  {
    key: 3,
    header: (
      <>
        <Icon icon="phone-android" style={{ marginRight: 10, fontSize: 20 }} />
        Mobile Notifications
      </>
    ),
    accessor: (i) => {
      const preference = _.findWhere(preferences, { key: `list:${i.id}:` });
      let currentValue = { label: "Never", value: "never" };
      if (preference && preference.push_enabled) {
        currentValue = _.findWhere(options, { value: preference.push_frequency.toLowerCase() });
      }

      return (
        <AdvancedCombobox
          value={currentValue}
          options={options}
          onChange={(value) => handleValueChange(value, "push", i)}
          placeholder="Select Frequency"
          formatSelection={(selectionText) => {
            if (currentValue.value !== "never") {
              return (
                <div className="frequency-selection">
                  <Icon className="notification-icon" icon="alarm" />
                  <span>{selectionText}</span>
                  <Icon icon="chevron-down" />
                </div>
              );
            } else
              return (
                <div className="frequency-selection">
                  {selectionText}
                  <Icon icon="chevron-down" />
                </div>
              );
          }}
        />
      );
    },
  },
];

const SegmentsPageController = () => {
  const [segments, setSegments] = useState([]);
  const [recentlyViewed, setRecentlyViewed] = useState([]);
  const [preferences, setPreferences] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [sortOrder, setSortOrder] = useState(true);
  const [selectableSegments, setSelectableSegments] = useState([]);
  const featuredSegments = FeaturedSegmentsConfig;

  const [has_filters_v2] = useGate("filters_v2");

  //TODO: Error catching in responses

  /*
  Function for grouping segments and featured Segments
  */
  const groupSegments = (segments) => {
    let segmentsArray = _.map(segments, (seg) => ({
      label: seg.name,
      value: seg.id,
      type: "saved_search",
    }));

    _.each(FeaturedSegmentsConfig, (group) =>
      _.each(group.segments, (seg) =>
        segmentsArray.push({
          label: seg.title,
          value: seg.id,
          type: "featured_search",
        })
      )
    );
    return segmentsArray;
  };

  const loadSegments = () => {
    Api.EMS.PREFS.get().then((resp) => {
      setPreferences(resp.preferences);
    });
  };

  /*
     Effect for loading all preferences
  */
  useEffect(() => {
    loadSegments();
  }, []);

  /*
  Initial loading of segments
  */
  useEffect(() => {
    setLoading(true);
    Api.CONTACTS.LISTS.get({
      params: { limit: 1000 },
    }).then((resp) => {
      const segments = _.where(resp.items, { type: "dynamic" });
      setSegments(segments);
      setLoading(false);
    });
  }, []);

  /*
  Gets recently viewed segments, including featured segments
  */
  useEffect(() => {
    Api.DNA.SETTING.get({
      urlArgs: { setting_key: "GT_recent_lists" },
      disableAlerts: true,
      params: { user_id: "me", oid: null },
    }).then((resp) => {
      const recent = _.uniq(resp.settings.GT_recent_lists.value.segments);
      const groupedSegments = groupSegments(segments);
      let ordered;
      if (!_.isEmpty(recent)) {
        ordered = _.compact(
          _.map(recent, (id) => {
            return _.findWhere(groupedSegments, { value: id });
          })
        );
        if (ordered.length < NUM_SEGMENTS) {
          let remaining = _.filter(groupedSegments, (seg) => {
            return !_.contains(recent, seg.value);
          });
          remaining = _.sortBy(remaining, "label");
          remaining = _.first(remaining, NUM_SEGMENTS - ordered.length);
          ordered = _.compact(ordered.concat(remaining));
        }
        setRecentlyViewed(ordered);
      } else setRecentlyViewed(_.sortBy(groupedSegments, "label"));
    });
  }, [segments]);

  /*
  Effect for search change
  */
  useEffect(() => {
    setSelectableSegments(_.filter(segments, (seg) => _.searchCompare(seg.name, searchText)));
  }, [searchText, segments]);

  /*
  Change handler for dropdown
  Responsible for hitting the API for the specific dropdown changed
  */

  const handleValueChange = (value, type, segment) => {
    let data = {};
    if (value.value === "never") data[type + "_enabled"] = false;
    else {
      data[type + "_enabled"] = true;
      data[type + "_frequency"] = value.value;
    }

    Api.EMS.PREFS_BY_KEY.put({
      urlArgs: { key: `list:${segment.id}:` },
      data: _.jsonStringify(data),
    }).then(() => {
      loadSegments();
    });
  };

  const renderFeatured = () =>
    featuredSegments.map((segment) =>
      segment.segments.map((segment) => (
        <div className="segments-page--featured" key={segment.id}>
          <LinkButton onClick={() => SegmentSource.applySegment(segment.id)} title={segment.title}>
            {segment.title}
          </LinkButton>
          <strong>{segment.leader}</strong> <span>{segment.text}</span>
        </div>
      ))
    );

  let segmentsSorted = _.sortBy(selectableSegments, (segment) => segment.name.toLowerCase());
  if (!sortOrder) {
    segmentsSorted = segmentsSorted.reverse();
  }

  if (has_filters_v2 && !localStorage.getItem("filters_v2_opt_out")) {
    return (
      <EmptyStateMessage
        className="segments-page--disabled"
        text={
          <>
            <div>Saved searches are not supported in filters beta yet.</div>
            <div>Switch back to access your saved searches</div>
          </>
        }
        actionLabel="Switch Back"
        onAction={() => {
          EverTrue.track.set("filter_v2_action", {
            type: "opt_out",
          });
          localStorage.setItem("filters_v2_opt_out", "true");
          window.location.reload();
        }}
      />
    );
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <PageHeader title="Saved Searches" subtitle={`${segments.length} Saved Searches`} />
          {segments.length === 0 && !loading ? (
            <Card className="segments-page--empty">
              <Icon icon="segment" size={3} style={{ color: colors.blue500 }} />
              <h2 className="segments-page--empty-title">New to EverTrue? Try these Searches!</h2>
              <div className="segments-page--empty-subtitle">
                Receive email and push notifications on these searches by saving them
              </div>
              <div className="segments-page--empty-featured">{renderFeatured()}</div>
            </Card>
          ) : (
            <div className="segments-page-controller">
              <Card className="segments-page--table">
                <div className="segments-page--search">
                  <SearchInput value={searchText} onChange={(val) => setSearchText(val)} />
                </div>
                <FlexTable
                  caption="segments"
                  data={segmentsSorted}
                  columns={columns(setSortOrder, sortOrder, handleValueChange, preferences)}
                />
              </Card>
              <div className="segments-page--side">
                <Card headingText="Recently Viewed" className="segments-page--recent" padding="16px">
                  <div className="segments-page--cardbody">
                    {recentlyViewed.map((segment) => (
                      <LinkButton
                        className="segments-page--recent-item"
                        key={segment.value}
                        onClick={() => SegmentSource.applySegment(segment.value)}
                        title={segment.label}
                      >
                        {segment.label}
                      </LinkButton>
                    ))}
                  </div>
                </Card>
                <Card headingText="Suggested Searches" padding="16px">
                  <div className="segments-page--cardbody">{renderFeatured()}</div>
                </Card>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default SegmentsPageController;
