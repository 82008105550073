module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   moment = require("moment")
   LinkHelper = require("entities/helpers/link-helper")
   {createStore} = require("@evertrue/et-flux")
   ExportSource = require("apps/export/sources/export-source")
   ContactSource = require("apps/contact/sources/contact-source")
   ListContactSource = require("apps/lists/sources/list-contact-source")
   MyPortfolioSource = require("apps/major-gifts/sources/my-portfolio-source")
   SortUtils = require("mixins/sort-utils")
   SocketSource = require("apps/notifications/sources/socket-source")
   FacebookPostDetailSource = require("apps/facebook/sources/facebook-post-detail-source")
   ScheduledExportsSource = require("apps/settings/export-settings/sources/scheduled-exports-source")
   ExportTypeConfig = require("apps/export/export-type-config").default

   TEMP_ID = "_temp_"
   _throttled_fetch =  _.throttle(ExportSource.fetch, 150)

   _is_complete = (export_item) ->
      export_item?.percent_complete == 1 || export_item?.state == "finished_success"


   createStore "ExportStore",
      getInitialState: ->
         exports: {}
         loading: false
         export_toasts: []
         running_exports: []
         last_export_date: {}
         scheduled_exports: []

      firstListenerDidMount: ->
         SocketSource.bindUser("contact_export_progress")
         SocketSource.bindUser("external_export_progress")
         SocketSource.bindUser("solicitor_export_progress")
         _.each ExportTypeConfig, (config) ->
            if config.socket
               SocketSource.bindUser(config.socket)

      registerActions: ->
         @on ExportSource.actions.fetchedExports, @respondToFetch
         @on ExportSource.actions.loadingExports, @respondToLoading
         @on ExportSource.actions.download, @respondToDownload

         @on ExportSource.actions.stopped, @respondToStop
         @on ExportSource.actions.started, @respondToExportStart

         @on ExportSource.actions.fetchedLastExportDate, @respondToLastExportDate

         @on ContactSource.actions.exportStarted, @respondToExportStart
         @on FacebookPostDetailSource.actions.exportStarted, @respondToExportStart
         @on ListContactSource.actions.exportedListContacts, @respondToExportStart
         @on MyPortfolioSource.actions.exportedProspects, @respondToExportStart

         @on SocketSource.actions.contact_export_progress, _throttled_fetch
         @on SocketSource.actions.external_export_progress, _throttled_fetch
         @on SocketSource.actions.solicitor_export_progress, _throttled_fetch
         _.each ExportTypeConfig, (config) =>
            if config.socket
               @on SocketSource.actions[config.socket], _throttled_fetch

         @on ScheduledExportsSource.actions.fetchScheduled, @respondTofetchScheduled

      respondToFetch: (data) ->
         running_exports = @getState("running_exports")
         export_toasts = @getState("export_toasts")

         if _.contains(running_exports, TEMP_ID)
            new_export = _.first(data.items)
            running_exports = _.without(running_exports, TEMP_ID)
            running_exports.push(new_export.id)
            export_toasts.push(new_export.id)

         [running_exports, new_toasts] = _.partition _.uniq(running_exports), (id) ->
            !_is_complete(_.findWhere(data.items, {id: id}))

         @setState
            exports: data
            running_exports: _.uniq(running_exports)
            export_toasts: _.union(new_toasts, _.intersection(running_exports, export_toasts))

      respondToLoading: (isLoading) ->
         @setState {loading: isLoading}

      respondToDownload: (export_item) ->
         exports = @getState("exports")
         scheduled_exports = @getState("scheduled_exports")
         _.extend(
            _.findWhere([].concat(exports.items, scheduled_exports), {id: export_item.id}),
            {last_downloaded_at: moment().valueOf()}
         )
         @setState {exports, scheduled_exports}

      respondToExportStart: ->
         running_exports = @getState("running_exports")
         running_exports.push(TEMP_ID)
         @setState {running_exports: _.uniq(running_exports)}
         ExportSource.fetch()

      respondToStop: (id) ->
         export_toasts = _.without(@getState("export_toasts"), id)
         running_exports = @getState("running_exports")
         finished_export = @getById(id)
         if _is_complete(finished_export)
            running_exports = _.without(running_exports, id)

            if _.contains(["prospect_status", "interaction"], finished_export?.type)
               ExportSource.fetchLastExportDate(finished_export.type)

         @setState
            running_exports: running_exports
            export_toasts: export_toasts

      respondToLastExportDate: (type, data) ->
         last_exports = @getState("last_export_date")
         last_exports[type] = data
         @setState {last_export_date: last_exports}

      respondTofetchScheduled: (scheduled_exports) ->
         if scheduled_exports.length
            @setState {scheduled_exports}

      api:
         getExports: ->
            scheduled_exports = _.map @getState("scheduled_exports"), (item) ->
               _.extend {},
                  scheduled: true
                  download_link: LinkHelper.formatLink("contacts/v2/exports/#{item.id}")
               , item
            exports =_.map @getState("exports")?.items, (item) ->
               _.extend {},
                  download_link: LinkHelper.formatLink("contacts/v2/exports/#{item.id}")
               , item


            return [].concat(exports, scheduled_exports)


         getExportsByMonth: (sort_reverse) ->
            exports = @getExports()
            groups = SortUtils.groupResultsByDate exports, (item) -> item.created_at

            group_data = _.map groups, (group) ->
               data = _.flatten _.pluck(group.data, "data")
               _.extend {}, group,
                  data: if sort_reverse then data.reverse() else data

            group_data.reverse() if sort_reverse
            group_data

         getById: (id) ->
            exports = @getExports()
            _.findWhere exports, {id: id}

         getLoading: ->
            @getState("loading")

         getToasts: ->
            _.compact _.map @getState("export_toasts"), (id) => @getById(id)

         getLastExport: (type) ->
            @getState("last_export_date")?[type]
