module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   EverTrue = require("app")
   Backbone = require("backbone")
   Contacts = require("entities/contacts/contacts")
   Export = require("entities/contacts/export")
   Filters = require("entities/search/filters")
   ExportSource = require("apps/export/sources/export-source")

   ContactStore = ->
      contacts = undefined
      selectedContacts = new Backbone.Collection()
      selectedContactsCache = {}
      selectionListRoute = undefined

      defaultSort = undefined
      sortParams = undefined
      requests = []
      _autocompleteRequests = []
      _cached_page = 1

      _setDefaultSort = ->
         if EverTrue.store.org?.hasFeature("scores")
            contacts.sortReverse = true
            defaultSort = "score.score"
         else
            defaultSort = "giving.et_donor_index"


      api = $.extend true, {}, Backbone.Events,
         initialize: (options={}) ->
            # Reset contact data
            contacts?.off?()
            contacts?.setBaseQuery([])

            contacts = EverTrue.store.contacts || new Contacts()
            if options.reset then contacts = new Contacts()

            # For gating score & et_donor_index
            # This is usually called before dna return features data
            # so we need to wait for org to initialize
            @listenTo EverTrue.vent, "org:initialized storeReset", =>
               if EverTrue.store.org?.hasFeature("scores")
                  sort = EverTrue.UrlManager.get("sort")
                  if sort then contacts.setSortFromKey(sort)
                  else contacts.setDefaultSort(_setDefaultSort())

                  contacts.syncing = true
                  contacts.trigger("sync:start")
                  @cancelSearches()
                  @query()
            contacts.setDefaultSort(_setDefaultSort())

            @listenTo EverTrue.vent, "storeReset", ->
               _cached_page = 1
               contacts.setPage(1)

            if EverTrue.UrlManager.get("sort")
               sortParams = EverTrue.UrlManager.get("sort")

            if EverTrue.UrlManager.get("page")
               _cached_page =  EverTrue.UrlManager.get("page")
            else if _cached_page
               EverTrue.UrlManager.set("page", _cached_page)

            contacts.setSortFromKey(sortParams) if sortParams
            EverTrue.store.push "contacts", contacts

         bindToUpdates: (callback) ->
            @listenTo contacts, "change reset sync:start sync:done", ->
               callback(contacts.toCollectionWrapper())

         unbind: ->
            @stopListening(contacts)
            @stopListening(EverTrue.vent)

         # bindOnce and removeBindOnce are used as a cheap hack
         # for the featured segments source

         bindOnce: (callback) ->
            @listenToOnce contacts, "change sync:start", callback

         removeBindOnce: (callback) ->
            @stopListening contacts, "change sync:start", callback

         getCurrentState: ->
            initial_contacts = contacts || new Contacts()
            initial_contacts.toCollectionWrapper()

         sort: (sort_key, options={}) ->
            @clearSelectedContacts(true)

            contacts.setSort(sort_key, options.reverse)
            sortParams = contacts.getSortValue()

            if _.isEmpty sortParams
               sortParams = contacts.setSort(defaultSort)
               EverTrue.UrlManager.set("sort", sortParams)
            else
               EverTrue.UrlManager.set "sort", sortParams

            @resetQuery() unless options.silent

         paginate: (page) ->
            selectedContactsCache[contacts.page] = selectedContacts.toJSON()
            @clearSelectedContacts()

            _cached_page = page
            EverTrue.UrlManager.set("page", page)

            contacts.setPage(page)

            selectedContacts.reset(selectedContactsCache[contacts.page])
            @query()

         resetQuery: (filters) ->
            contacts.setPage(1)
            _cached_page = 1
            EverTrue.UrlManager.set("page", 1)
            selectedContacts.reset(selectedContactsCache[contacts.page] || [])
            @query(filters)

         initialQuery: (filters) ->
            page = EverTrue.UrlManager.get("page") || 1
            contacts.dangerouslySetPage(page)
            selectedContacts.reset(selectedContactsCache[contacts.page] || [])
            @query(filters)

         query: (filters) ->
            contacts.syncing = true
            contacts.trigger("sync:start")

            contacts.fetch
               filters: filters || EverTrue.UrlManager.get("filters")
               reset: true
               beforeSend: (xhr, opts) -> requests.push(xhr)
               success: (args...) =>
                  if contacts.offset > contacts.total
                     @resetQuery(filters)
                  # Might be able to remove this referrer - probably for old filters UI for profile views and search actions
                  EverTrue.track.setReferrer("contact")
                  requests = _.filter requests, (req) -> req.readyState != 4

            EverTrue.store.push "contactsSortBy", contacts.getSortValue()

         autocomplete: (query, options) ->
            _.each _autocompleteRequests, (req) -> req.abort()
            _autocompleteRequests = []

            contacts_autocomplete = new Contacts()
            contacts_autocomplete.fetch _.extend {}, options,
               reset: true
               filters: {full_name: query}
               beforeSend: (xhr, opts) ->
                  options.beforeSend(xhr, opts) if _.isFunction(options.beforeSend)
                  _autocompleteRequests.push(xhr)

         export: (options, callback) ->

            exports = new Export()
            exports.setBaseQuery(contacts?.baseQuery)
            exports.save null, $.extend true, {}, options,
               disableAlerts: true
               success: -> ExportSource.start()

         setBaseQuery: (filters, isFilterObject) ->
            contacts.setBaseQuery(filters, isFilterObject)

         cancelSearches: ->
            _.each requests, (req) -> req.abort()

         # Selected Contact Data Access
         # Add, remove, reset the selected contacts in the table.
         selectContact: (ids) ->
            contactIdsToAdd = _.map _.makeArray(ids), (id) -> {id: id}
            selectedContacts.add contactIdsToAdd, {merge: true, silent: true}

            # Trigger Events Based of IDs so that we can listen to specfic events
            _.each contactIdsToAdd, (id) ->
               selectedContacts.trigger("add:contact:#{id.id}")

            selectedContacts.trigger("add")
            selectedContactsCache[contacts?.page] = selectedContacts.toJSON()

         unselectContact: (ids) ->
            contact_ids = _.makeArray(ids)
            contactToRemove = selectedContacts.filter (selected) ->
               _.contains(contact_ids, selected.get("id"))
            selectedContacts.remove(contactToRemove, {silent: true})

            # Trigger Events Based of IDs so that we can listen to specfic events
            _.each contactToRemove, (removed) ->
               selectedContacts.trigger("remove:contact:#{removed.get("id")}")

            selectedContacts.trigger("remove")
            selectedContactsCache[contacts?.page] = selectedContacts.toJSON()

         clearSelectedContacts: (clearCache) ->
            removed = selectedContacts.pluck("id")
            selectedContacts.reset([], {remove: true})
            selectedContactsCache = {} if clearCache

            # Trigger Events Based of IDs so that we can listen to specfic events
            _.each removed, (removed_id) ->
               selectedContacts.trigger("remove:contact:#{removed_id}")

         isSelected: (id) ->
            !!selectedContacts.findWhere {id: id}

         getSelectedContacts: ->
            selectedContacts.pluck("id")

         bindToContactSelection: (id, callback) ->
            # When a new list contact list is displayed, clear out selected contacts
            # This is to keep selection available when viewing profiles and returning to the list
            if !id && selectionListRoute != window.location.pathname
               @clearSelectedContacts(true)
               selectionListRoute = window.location.pathname

            events = if id then "add:contact:#{id} remove:contact:#{id}" else "add remove reset"
            @listenTo selectedContacts, events, ->
               callback(selectedContacts.pluck("id"))

         unbindSelectedContacts: (id) ->
            if id then @stopListening(selectedContacts, "add:contact:#{id} remove:contact:#{id}")
            else @stopListening(selectedContacts)

   ContactStore()
