/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import _ from "underscore";

import EverTrue from "app";
import { createStore } from "@evertrue/et-flux";
import AllUsersSource from "apps/users/sources/all-users-source";
import OrgSource from "base/org/org-source";
import AssignProspectSource from "apps/major-gifts/sources/assign-prospect-source";

const _getEmptyUser = id => ({ id, name: "Removed User", removed: true });

const _parseUser = user =>
  _.extend({}, user, {
    id: _.toNumber(user.id),
    // previously avatar was constructed clientside so use the new user_profile_picture_url property but change it to avatar
    // We have many components referencing it
    avatar: user.user_profile_picture_url,
  });

export default createStore("AllUsersStore", {
  getInitialState() {
    return {
      users: {},
      searched_users: {},
      loading: false,
    };
  },

  registerActions() {
    this.on(AllUsersSource.actions.fetchedUsers, this.respondToFetch);
    this.on(AllUsersSource.actions.loading, this.respondToLoading);
    this.on(AllUsersSource.actions.startFetchUsers, this.respondToStartFetch);
    this.on(AllUsersSource.actions.fetchedUsersByIds, this.respondToFetchedUsersByIds);

    this.on(AssignProspectSource.actions.changedRemoteIds, () => AllUsersSource.fetchAllUsers());

    this.on(OrgSource.actions.newOrg, this.respondToReset);

    this.on(OrgSource.actions.newOrg, function() {
      const current_user = EverTrue.store.user.toJSON();
      return this.setState({ users: {}, searched_users: _.toObject(current_user.id, current_user) });
    });
  },

  respondToReset() {
    AllUsersSource.reset();
    return AllUsersSource.fetchAllUsers();
  },

  respondToLoading(is_loading) {
    return this.setState({ loading: is_loading });
  },

  respondToFetch(users) {
    const users_map = _.cloneData(this.getState("users"));
    _.each(users, user => (users_map[user.id] = _parseUser(user)));
    this.setState({ users: users_map });
    return AllUsersSource.changedUsers();
  },

  respondToStartFetch(user_ids) {
    const cached_users = _.map(_.keys(this.getState("users")), _.toNumber);
    const cached_searched = _.map(_.keys(this.getState("searched_users")), _.toNumber);
    const new_ids = _.difference(user_ids, _.uniq(cached_users.concat(cached_searched)));
    if (!_.isEmpty(new_ids)) {
      return AllUsersSource.fetchUsersByIds(new_ids);
    }
  },

  respondToFetchedUsersByIds(requested_ids, response) {
    const cached_users = _.cloneData(this.getState("searched_users"));
    const unavailable_users = _.difference(requested_ids, _.pluck(response, "id"));
    _.each(unavailable_users, function(id) {
      if (EverTrue.store.user.get("id") !== id) {
        return (cached_users[id] = _getEmptyUser(id));
      }
    });
    _.each(response, user => (cached_users[user.id] = _parseUser(user)));
    this.setState({ searched_users: cached_users });
    return AllUsersSource.changedUsers();
  },

  api: {
    getAllUsers() {
      return _.extend({}, this.getState("searched_users"), this.getState("users"));
    },

    getUser(id) {
      return (
        __guard__(this.getState("users"), x => x[id]) ||
        __guard__(this.getState("searched_users"), x1 => x1[id]) ||
        this.getEmptyStateUser(id)
      );
    },

    getUserByName(name) {
      return _.findWhere(this.getAllGivingTreeUsers(), { name });
    },

    getAllGivingTreeUsers() {
      return this.getState("users");
    },

    getGivingTreeUser(id) {
      return __guard__(this.getState("users"), x => x[id]);
    },

    getEmptyStateUser(id) {
      if (this.getLoading()) {
        return {};
      } else {
        return _getEmptyUser(id);
      }
    },

    getLoading() {
      return this.getState("loading");
    },
  },
});

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined;
}
