import EverTrue from "app";
import _ from "underscore";
import BaseRouter from "base/base-router";
import LegacyCoreSearchController from "apps/core-search/controllers/legacy-core-search-controller";
import ProposalDetailController from "apps/proposals/controllers/proposal-detail-controller";

class ProposalRouter extends BaseRouter {
  static initClass() {
    this.prototype.routes = {
      proposal: "search",
      "proposal/:id": "proposal",
    };
  }

  search() {
    EverTrue.execute("setBrowserTitle", "Proposals");
    this.currentView = <LegacyCoreSearchController type="proposals" />;
    EverTrue.page.mount(this.currentView);
  }

  proposal(id) {
    EverTrue.track.set("viewed", { type: "proposal_details" });
    EverTrue.track.setReferrer("proposal_details");
    this.currentView = <ProposalDetailController id={_.toNumber(id)} />;
    EverTrue.page.mount(this.currentView);
  }
}

ProposalRouter.initClass();
export default ProposalRouter;
