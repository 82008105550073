import _ from "underscore";
import { useContext } from "react";
import PropTypes from "prop-types";
import EverTrue from "app";
import { AdvancedCombobox, Loading } from "@evertrue/et-components";
import { useIdentity } from "base/identity-resolver";
import UserDashboardWidgets from "apps/dashboard/components/user-dashboard-widgets";
import DashboardMetaDataContext from "apps/dashboard/contexts/dashboard-meta-data-provider";
import useTeams from "apps/dashboard/hooks/use-teams";
import usePools from "apps/dashboard/hooks/use-pools";
import useSavedTeam from "apps/dashboard/hooks/use-saved-team";
import { ALL_ACTIVITY, PORTFOLIO_ACTIVITY } from "apps/dashboard/constants";

const UserDashboard = ({ reorder = false, toggleReorder = () => {}, reloading = false }) => {
  const { users, loading_users, user_id, user_contact_id, selectUser } = useContext(DashboardMetaDataContext);
  const fullUser = _.findWhere(users, { id: user_id }) || {};
  const { isOwner, isSuperUser } = useIdentity();
  const [teams, loadingTeams] = useTeams(user_contact_id);
  const showPortfolioOption = teams.length > 1;
  const multiTeamOptions = showPortfolioOption ? [ALL_ACTIVITY, PORTFOLIO_ACTIVITY] : [ALL_ACTIVITY];
  const teamOptionsGrouped = [{ label: "Activity", items: multiTeamOptions }, { items: [...teams] }];
  const teamOptions = teamOptionsGrouped.map((group) => group.items).flat();
  const [team, setTeam] = useSavedTeam(teamOptions, teams.length);
  const [poolId, poolIds] = usePools(team, teams);

  return (
    <>
      <div className="dashboard--toolbar-wrapper">
        <div className="dashboard--toolbar">
          {/* User Dropdown */}
          {isOwner || isSuperUser ? (
            <AdvancedCombobox
              placeholder="Select solicitor..."
              label="Solicitor name"
              className="dashboard--solicitor-dropdown"
              options={_.map(users, (user = {}) => {
                return { value: user.id, label: user.name };
              })}
              value={{
                value: user_id,
              }}
              loading={loading_users}
              onChange={(option) => {
                EverTrue.track.set("launchpad_action", { type: "user_view_switch" });
                selectUser(option);
              }}
            />
          ) : (
            <AdvancedCombobox
              placeholder="Select solicitor..."
              label="Solicitor name"
              className="dashboard--solicitor-dropdown"
              options={[{ value: user_id, label: fullUser.name }]}
              value={{ value: user_id }}
              onChange={() => {}}
            />
          )}

          {/* Team Dropdown */}
          <AdvancedCombobox
            label="Team name"
            placeholder="Select team..."
            className="dashboard--solicitor-dropdown"
            grouped={true}
            options={teamOptionsGrouped}
            value={_.findWhere(teamOptions, { value: team })}
            onChange={(option) => {
              EverTrue.track.set("launchpad_action", {
                type: "team_view_switch",
                referrer: "Individual",
              });
              setTeam(option.value);
            }}
            loading={loadingTeams}
          />
        </div>
      </div>

      <div className={`dashboard-widgets ${reorder ? "dashboard-widgets--reordering" : ""}`}>
        {reloading ? (
          <Loading className="dashboard--loading" />
        ) : (
          <UserDashboardWidgets
            poolId={poolId}
            poolIds={poolIds}
            canReorder={reorder}
            cancelReorder={() => toggleReorder(false)}
          />
        )}
      </div>
    </>
  );
};

UserDashboard.propTypes = {
  reorder: PropTypes.bool,
  toggleReorder: PropTypes.func,
  reloading: PropTypes.bool,
};

export default UserDashboard;
