import { createSource } from "@evertrue/et-flux";

export default createSource("DynamicListSource", {
  actions: {
    setClearFilters: true,
    setDynamicListAlertPref: true,
  },
  api: {
    setClearFilters(bool) {
      this.actions.setClearFilters(bool);
    },
    setDynamicListAlertPref(obj) {
      this.actions.setDynamicListAlertPref(obj);
    },
  },
});
