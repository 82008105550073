import ActivityNav from "./activity-nav";
import PropTypes from "prop-types";
import { FlexTable } from "@evertrue/et-components/";
import ActiveJourneyPill from "components/elements/active-journey-pill";

const ActivityTable = ({ tab, data }) => {
  const columns = [
    {
      key: 1,
      header: "Cadence",
      accessor: ({ metadata }) => (metadata ? <ActiveJourneyPill activeCadence={metadata} /> : null),
    },
    {
      key: 2,
      header: "Task",
      accessor: "title",
    },
    {
      key: 3,
      header: "Constituent",
      accessor: ({
        contact: {
          contact_attributes: { name_full },
        },
      }) => name_full,
    },
    data.lastColumn,
  ];

  return (
    <div className="reporting--activity-wrapper">
      <ActivityNav tab={tab} />
      <div className="reporting--table-wrapper">
        <FlexTable data={data.tableData} columns={columns} caption="" />
      </div>
    </div>
  );
};

ActivityTable.propTypes = {
  tab: PropTypes.string,
  data: PropTypes.object,
};

export default ActivityTable;
