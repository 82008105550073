module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {createFactory} = require("base/new-utils")
   ModalConfirm = createFactory require("components/modals/modal-confirm")
   AdvancedCombobox = createFactory(require("@evertrue/et-components").AdvancedCombobox)


   createComponent "VisitSelectorModal",
      propTypes:
         selected: ReactLibs.PropTypes.array
         options: ReactLibs.PropTypes.array
         onSubmit: ReactLibs.PropTypes.func

      getInitialState: ->
         selected_types: @props.selected

      componentWillReceieveProps: (newProps) ->
         if !_.isEqual(newProps.selected, @props.selected)
            if !_.isEqual(@state.selected_types, newProps.selected)
               @setState {selected_types: newProps.selected}

      handleSubmit: ->
         @props.onSubmit?(@state.selected_types)
         @handleCancel()

      handleCancel: ->
         @props.requestHide()

      render: ->
         ModalConfirm
            className: "visit-selector-modal"
            header: "Select Visit Interaction Types"
            buttonLabel: "Done"
            onSubmit: @handleSubmit
            onCancel: @handleCancel

            AdvancedCombobox
               value: @state.selected_types
               multiple: true
               searchable: true
               options: @props.options
               onChange: (val) =>
                  @setState {selected_types: val}
