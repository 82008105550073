module.exports = do ->
   _ = require("underscore").default
   moment = require("moment")
   EverTrue = require("app")
   {createSource} = require("@evertrue/et-flux")

   # Force fake timeout since the {timeout} geolocation options
   # doesn't seem to actually do anything.
   TIMEOUT = 20000

   createSource "GeolocationSource",
      actions:
         fetchedGeolocation: (location) ->
            @require _.isNumber(location.lat), "Latitude should be number"
            @require _.isNumber(location.lng), "Longitude should be number"
         loadingGeolocation: (isLoading) ->
            @require _.isBoolean(isLoading), "isLoading should be boolean"
         setLocation: (location) ->
            @require (_.isObject(location) || _.isUndefined(location)), "Location should be an object"
         changedLocation: (has_changed) ->
            @require _.isBoolean(has_changed), "has_changed should be boolean"
         errorGeolocation: true
         clearGeolocation: true
         hideGeolocationError: true

      api:
         init: ->
            EverTrue.store.fetch "geolocation", null
            if EverTrue.store.geolocation
               geolocation = EverTrue.store.geolocation
               @actions.fetchedGeolocation(geolocation)

         fetch: ->
            EverTrue.store.fetch "geolocation"
            geolocation = EverTrue.store.geolocation
            @actions.loadingGeolocation(true)

            if geolocation?.expire_at && moment(geolocation.expire_at).isBefore()
               geolocation = EverTrue.store.geolocation
               @actions.fetchedGeolocation(_.omit(geolocation, "expire_at"))
            else
               _trigger_timeout = true
               error = (err) =>
                  _trigger_timeout = false
                  @actions.errorGeolocation()
                  Raven?.captureMessage "Geolocation Error", extra: {error: err}

               _.delay((-> error("Forced timeout") if _trigger_timeout), TIMEOUT)

               if navigator?.geolocation
                  navigator?.geolocation.getCurrentPosition (position) =>
                     _trigger_timeout = false
                     google_locale =
                        lat: position.coords.latitude
                        lng: position.coords.longitude
                        expire_at: moment().add(12, "h").valueOf()
                     EverTrue.store.push "geolocation", google_locale, {cache: true}
                     @actions.fetchedGeolocation(google_locale)
                  , error, {timeout: TIMEOUT, maximumAge: 1}
               else
                  _trigger_timeout = false
                  @actions.errorGeolocation()
                  Raven?.captureMessage "Geolocation Error", extra: {error: "navigator.geolocation unavailable"}

         reset: ->
            EverTrue.store.destroy "geolocation"
            @actions.setLocation(undefined)
            @fetch()

         # Same as reset though for new UI, it needs to be smarter about
         # waiting to clear the current location, while geolocating.
         refetch: ->
            EverTrue.store.destroy "geolocation", true
            @actions.clearGeolocation()
            @fetch()

         setLocation: (location) ->
            @actions.setLocation(location)

         changedLocation: (has_changed) ->
            @actions.changedLocation(has_changed)

         hideError: ->
            @actions.hideGeolocationError()
