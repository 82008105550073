import { useEffect, useState } from "react";
import _ from "underscore";
import API from "entities/helpers/api";

const gql = String.raw;
const query = gql`
  query ($id: BigInt!) {
    solicitor(contact_id: $id) {
      pools_pagination(page: 1, perPage: 100) {
        count
        items {
          id
          name
        }
      }
    }
  }
`;

const useTeams = (userContactId) => {
  const [teams, setTeams] = useState([]);
  const [loadingTeams, setLoadingTeams] = useState(false);

  useEffect(() => {
    const loadTeams = async () => {
      try {
        setLoadingTeams(true);
        const resp = await API.GRAPHQL.GRAPHQL.post({
          data: JSON.stringify({
            operationName: null,
            query: String.raw`${query}`,
            variables: { id: userContactId },
          }),
        });

        const pools = resp.data.solicitor.pools_pagination.items;
        const sortedPools = _.sortBy(pools, "name");
        const teams = _.map(sortedPools, (pool) => {
          return { value: pool.id, label: pool.name };
        });

        setTeams(teams);
        setLoadingTeams(false);
      } catch {
        setLoadingTeams(false);
      }
    };

    if (userContactId) {
      loadTeams();
    }
  }, [userContactId]);

  return [teams, loadingTeams];
};

export default useTeams;
