import { Component } from "react";
import ReactLibs from "base/react-libs";
import classNames from "classnames";
import numeral from "numeral";

class CountOf extends Component {
  static propTypes = {
    className: ReactLibs.PropTypes.string,
    dynamicTotal: ReactLibs.PropTypes.number,
    staticTotal: ReactLibs.PropTypes.number,
  };

  render() {
    const { dynamicTotal, staticTotal } = this.props;

    if (dynamicTotal !== staticTotal && (dynamicTotal || dynamicTotal === 0)) {
      return (
        <span className={classNames("count-of", this.props.className)}>
          (Showing
          <strong> {numeral(dynamicTotal).format("0,0")} </strong>
          of
          <strong> {numeral(staticTotal).format("0,0")}</strong>
          )
        </span>
      );
    }

    return null;
  }
}

export default CountOf;
