module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   EverTrue = require("app")
   React = require("react")
   AdminGateStore = require("apps/admin/stores/admin-gate-store")
   {createFactory} = require("base/new-utils")
   PagedColTable = createFactory require("components/tables/paged-col-table")
   AdvancedCombobox = createFactory(require("@evertrue/et-components").AdvancedCombobox)
   Dropdown = createFactory require("components/controls/dropdown")
   Pagination = require("components/controls/pagination")
   LoadingMixin = require("mixins/loading-mixin")
   str = require("underscore.string")
   {strong, input, h1, div, ul, li, a, span} = ReactLibs.DOMFactories
   Button = createFactory(require("@evertrue/et-components").Button)

   AdminGatingController = ReactLibs.createClass
      mixins: [LoadingMixin]
      getInitialState: ->
         {orgs: {}, filter: undefined, loading: true, features: [], all_features: []}

      UNSAFE_componentWillMount: ->
         @headerConfig = [{label: "Organization", row: "org", sticky: true, width: 250}]

      componentDidMount: ->
         @is_mounted = true
         AdminGateStore.initialize()
         AdminGateStore.bindToUpdates (orgs) =>
            @setState({orgs: orgs}) if @is_mounted

         AdminGateStore.getFeatures (names) =>
            @setState({features: names, all_features: names}) if @is_mounted
         AdminGateStore.getOrgs().then => @getResultGates()

      componentWillUnmount: ->
         @is_mounted = false
         AdminGateStore.unbind()

      UNSAFE_componentWillUpdate: (nextProps, nextState) ->
         @headerConfig = [{label: "Organization", row: "org", sticky: true, width: 250}]
         _.each nextState.features, (gate) =>
            @headerConfig.push
               label: @header(gate)
               row: "gate"
               key: gate

      getResultGates: ->
         @setState({loading: true}) if @is_mounted
         AdminGateStore.getGates().then =>
            @setState({loading: false}) if @is_mounted

      handleOrgSearch: ->
         filter = @refs.search.value
         AdminGateStore.filter(filter).then => @getResultGates()

      handleResetSearch: ->
         @refs.search.value = ""
         @handleOrgSearch()

      handleFeatureFilter: ->
         key = @refs.feature.value
         @setState
            features: _.filter @state.all_features, (gate) ->
               _.isEmpty(key) || gate.toLowerCase().match(key.toLowerCase())

      handlePage: (page) ->
         AdminGateStore.getOrgs(page).then => @getResultGates()
         $(document).scrollTop(0)

      handleGateSelect: (enabled, gate) ->
         $("body").trigger("click") #closeDropdowns
         message = """
            Are you sure you want to #{if enabled then "enable" else "disable"}
            all user and organization gates for this feature (#{str.humanize(gate)})?"""
         EverTrue.Alert.confirm message, (confirm) =>
            return unless confirm
            @setState({loading: true}) if @is_mounted
            AdminGateStore.deprecateGate(gate).then =>
               AdminGateStore.updateDefaultGate(gate, enabled).then =>
                  @getResultGates()

      handleSingleUpdate: (key, org, value) ->
         platforms = if value == "none" then undefined else value?.split?("|")
         AdminGateStore.updateGate(key, org.id, platforms)

      header: (gate) ->
         Dropdown icon_label: "true", label: str.titleize(str.humanize(gate.split(".").join("_"))),
            ul className: "menu menu--bordered",
               li null, a href: "#", onClick:(=> @handleGateSelect(true, gate)), "Enable all on iOS & Web"
               li null, a href: "#", onClick:(=> @handleGateSelect(false, gate)), "Disable all "

      rows: ->
         org: (data) ->
            div className: "admin-table--org",
               strong null, data.name || str.titleize(data.slug)
               div null,
                  span className: "text-label", "Slug/OID: "
                  data.slug
               div null,
                  span className: "text-label", "ID: "
                  data.id
         gate: (data, column) =>
            AdvancedCombobox
               options: [
                  {value: "ios", label: "Enabled on iOS"}
                  {value: "web", label: "Enabled on Web"}
                  {value: "ios|web", label: "Enabled on iOS & Web"}
                  {value: "none", label: "Disabled"}
               ]
               value: {value: AdminGateStore.getFeatureValue(column.key, data)}
               onChange: (selected_obj) =>
                  @handleSingleUpdate(column.key, data, selected_obj.value)

      render: ->
         div className: "page-container admin-page-body",
            h1 className: "admin-title", tabIndex: -1, "Features By Org"

            div className: "module",
               div className: "module--header clearfix",
                  input
                     type: "text"
                     className: "admin-table--search pull-left"
                     placeholder: "Search by organization name or oid"
                     ref: "search"
                  Button className: "pull-left", onClick: @handleOrgSearch, " Search "
                  Button type: "secondary", className: "pull-left", onClick: @handleResetSearch, " Reset "

                  input
                     type: "text"
                     className: "admin-table--feature-filter pull-right"
                     placeholder: "Filter Features"
                     ref: "feature"
                     onKeyUp: @handleFeatureFilter

               div className: "loading-container",
                  @loading(@state.loading)

                  PagedColTable
                     className: "admin-table"
                     data: @state.orgs.items || []
                     syncing: @state.loading
                     rows: @rows
                     columns: @headerConfig
                     columnWidth: 200
                     sortable: false

            Pagination
               className: "admin-table--pagination"
               page: @state.orgs.page
               total: @state.orgs.total / (@state.orgs.limit || 25)
               onChange: @handlePage
