module.exports = do ->
   _ = require("underscore").default
   {createStore} = require("@evertrue/et-flux")
   FacebookMixin = require("mixins/facebook-mixin")
   FacebookReactions = require("apps/facebook/helpers/facebook-reactions-utils")
   FacebookPostDetailSource = require("apps/facebook/sources/facebook-post-detail-source")
   PaginationUtils = require("mixins/pagination-utils")
   SortUtils = require("mixins/sort-utils")

   _getContactQuery = (post_id, types) ->
      has_child: [{
         type: "social",
         query:
            must: [
               {"engagement.type": in: types},
               {"content.remote_id": match: post_id}
            ]
      }]


   createStore "FacebookPostDetailStore",
      getInitialState: ->
         post: {}
         post_contacts: {limit: 50, items: []}
         post_contacts_page: 1
         post_contacts_sort: SortUtils.getDefaultSort("score.score")
         post_comments: {}
         type_filter: "all"
         loading: false

      registerActions: ->
         @on FacebookPostDetailSource.actions.loading, @respondToLoading
         @on FacebookPostDetailSource.actions.fetchedPost, @respondToFetchedPost
         @on FacebookPostDetailSource.actions.fetchPostContacts, @respondToFetchContacts
         @on FacebookPostDetailSource.actions.searchedPostContacts, @respondToSearchContacts

         @on FacebookPostDetailSource.actions.setSort, @respondToSort
         @on FacebookPostDetailSource.actions.setPage, @respondToPaginate
         @on FacebookPostDetailSource.actions.changeView, @respondToChangeView

         @on FacebookPostDetailSource.actions.startPostExport, @respondToPostExport
         @on FacebookPostDetailSource.actions.fetchedPostComments, @respondToFetchedComments

      respondToLoading: (is_loading) ->
         @setState {loading: is_loading}

      respondToFetchedPost: (post) ->
         @setState {post: post, post_comments: {}}

      respondToSearchContacts: (contact_data) ->
         @setState {post_contacts: contact_data}

         if @getState("type_filter") == "comment"
            id = FacebookMixin.formatContentId(@getState("post")?.id)
            FacebookPostDetailSource.getPostComments(id, _.pluck(contact_data.items, "id"))

      respondToFetchContacts: (post_id) ->
         @setState {type_filter: "all"}
         @queryContacts(post_id)

      respondToSort: (sort_prop, sort_reverse) ->
         sort_object = {sortProp: sort_prop, sortReverse: sort_reverse}
         @setState {post_contacts_sort: sort_object}
         @queryContacts()

      respondToPaginate: (page) ->
         @setState {post_contacts_page: page}
         @queryContacts()

      respondToChangeView: (key, should_update) ->
         @setState {type_filter: key}
         @queryContacts() if should_update

      respondToPostExport: (property_ids) ->
         query = @getQueryObject()
         FacebookPostDetailSource.exportContacts property_ids,
            name: "Facebook Post"
            search: query

      respondToFetchedComments: (comment_data) ->
         comment_map = {}
         _.each comment_data?.items, (comment) ->
            comment_map[comment.contact_id] = comment
         @setState {post_comments: comment_map}

      queryContacts: (post_id) ->
         query = @getQueryObject(post_id)
         params = PaginationUtils.getParams(@getContacts())
         FacebookPostDetailSource.searchPostContacts(query, params)

      getTypeForQuery: ->
         type = @getState("type_filter")
         if type == "all" then FacebookReactions.getAllValues()
         else _.makeArray(type)

      getQueryObject: (post_id) ->
         post_id ?= @getState("post")?.id
         query = _getContactQuery(post_id, @getTypeForQuery())
         query.sort = SortUtils.getQueryFromObj(@getState("post_contacts_sort"))
         query

      api:
         getPost: ->
            @getState("post")

         getContacts: ->
            page = @getState("post_contacts_page")
            sort_data = _.cloneData(@getState("post_contacts_sort"))
            contacts = _.cloneData(@getState("post_contacts"))
            comments = @getState("post_comments")

            contact_data = _.extend {page: page}, sort_data, contacts
            if !_.isEmpty(comments)
               contact_data.items = _.map contact_data.items, (contact) ->
                  contact.social = comment: comments[contact.id]?.engagement?.contents
                  contact
            contact_data

         getTypeFilter: ->
            @getState("type_filter")

         getLoading: ->
            @getState("loading")
