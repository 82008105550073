import { useState } from "react";
import _ from "underscore";
import PropTypes from "prop-types";
import EverTrue from "app";
import Api from "entities/helpers/api";
import { LinkButton, Loading } from "@evertrue/et-components";
import SocialProfiles from "apps/profile/components/enrichment/social-profiles";
import { sortProfiles } from "apps/profile/components/enrichment/enrichment-utils";

const gql = String.raw;

const SocialEditModal = ({ contact_id, profiles, onDelete, requestHide, lastRefresh }) => {
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const activeProfiles = sortProfiles(profiles.filter((profile) => !profile.rejected));
  const rejectedProfiles = profiles.filter((profile) => profile.rejected);

  const handleMutation = (profile, rejecting) => {
    setLoading(true);
    const profileMutation = {
      social_profiles: [{ id: profile.id, rejected: rejecting, updated_at: profile.updated_at }],
    };

    const query = gql`
      mutation($id: Int!, $enrichment: EnrichmentInput) {
        contact_mutation(id: $id, enrichment: $enrichment) {
          code
          enrichment_mutation {
            social_profiles_mutations {
              success
            }
          }
        }
      }
    `;

    Api.GRAPHQL.GRAPHQL.post({
      data: JSON.stringify({
        query: String.raw`${query}`,
        variables: { id: contact_id, enrichment: profileMutation },
      }),
    })
      .then(() => {
        _.delay(() => {
          rejecting
            ? EverTrue.Alert.success("Social Profile Deleted")
            : EverTrue.Alert.success("Social Profile Restored");
          onDelete();
          setLoading(false);
          requestHide();
        }, 1500);
      })
      .catch(() => {
        setLoading(false);
        rejecting
          ? EverTrue.Alert.error("Problem deleting profile. Please try again later")
          : EverTrue.Alert.error("Problem restoring profile. Please try again later");
      });
  };

  return (
    <div className="profile--enrichment-social-edit">
      {loading ? <Loading /> : null}
      <SocialProfiles
        profiles={activeProfiles}
        editMode="delete"
        onMutation={(profile) => handleMutation(profile, true)}
      />
      <div className="profile--enrichment-restore">
        {rejectedProfiles.length ? (
          <LinkButton
            onClick={() => setDeleteVisible(!deleteVisible)}
            title={deleteVisible ? "Hide deleted" : "See deleted"}
          >
            {deleteVisible ? "Hide deleted" : "See deleted"}
          </LinkButton>
        ) : null}
      </div>
      {deleteVisible ? (
        <div className="profile--enrichment-rejected-list">
          <SocialProfiles
            editMode="restore"
            profiles={rejectedProfiles}
            onMutation={(profile) => handleMutation(profile, false)}
          />
        </div>
      ) : null}
    </div>
  );
};

SocialEditModal.propTypes = {
  contact_id: PropTypes.number,
  profiles: PropTypes.array,
  onDelete: PropTypes.func,
  requestHide: PropTypes.func,
  lastRefresh: PropTypes.string,
};

export default SocialEditModal;
