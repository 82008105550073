module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")
   Decorator = require("clientDecorator")


   createSource "ClusterContactSource",
      actions:
         loadingClusterContacts: true
         fetchedClusterContacts: (key, contacts) ->
            @require _.isString(key), "key should be string"
            @require _.isPagedObject(contacts), "contacts should be paged object"

      api:
         fetch: (key, query) ->
            @actions.loadingClusterContacts(true)

            Api.CONTACTS.SEARCH.post
               data: _.jsonStringify(query)
               params: {limit: 50}
               error: =>
                  @actions.loadingClusterContacts(false)
               success: (resp) =>
                  resp.items = _.map resp.items, (contact) ->
                     Decorator.Contacts.parse(contact)
                  @actions.loadingClusterContacts(false)
                  @actions.fetchedClusterContacts(key, resp)
