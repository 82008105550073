import { Component } from "react";
import PropTypes from "prop-types";
import Icon from "components/elements/icon";
import { Link } from "@evertrue/et-components";
import IsGated from "components/is-gated";

class Assignment extends Component {
  static propTypes = {
    assignment: PropTypes.object,
  };

  static defaultProps = {
    assignment: {},
  };

  render() {
    const { name, solicitor_contact_id, membership } = this.props.assignment;
    return (
      <div className="assignee-template">
        <Icon icon="person" className="assignee-template--icon" />
        <div className="assignee-template--data">
          <div className="donor-info-name-row">
            {solicitor_contact_id ? (
              <Link href={`/contact/${solicitor_contact_id}`} title={`Link to ${name}'s profile`} data-refer="person">
                {name}
              </Link>
            ) : (
              name
            )}
            <IsGated feature="rm_key_prospect">
              {membership.key_prospect && <Icon icon="key-relationship" className="donor-info-key-relationship-icon" />}
            </IsGated>
          </div>

          <div className="assignee-template--label">{membership.stage}</div>
          <div className="assignee-template--label">{membership.assignment_title}</div>
          <div className="assignee-template--degree">{membership.pool_name}</div>
        </div>
      </div>
    );
  }
}

export default Assignment;
