import { PropTypes } from "base/react-libs";
import { Button, Icon } from "@evertrue/et-components";
import ContactTagList from "apps/contact/components/contact-tag-list";
import ContactCard from "apps/contact/components/contact-card/contact-card";
import FormField from "components/forms/form-field";

const TaskContact = ({ targetContact, setTargetContact, disableDelete }) => {
  return targetContact ? (
    <div className="task-contact-card--container">
      <ContactCard contact={targetContact} visibleData={["roleWithYear"]} showNameLink={false} />
      {!disableDelete && (
        <Button type="simple" onClick={() => setTargetContact(null)}>
          <Icon icon="close" size={1} />
        </Button>
      )}
    </div>
  ) : (
    <FormField label="Constituent" inlineLabel={false}>
      <ContactTagList
        onSelect={(selectedContacts) => {
          if (selectedContacts && selectedContacts.length) {
            setTargetContact(selectedContacts[0]);
          }
        }}
      />
    </FormField>
  );
};

TaskContact.propTypes = {
  targetContact: PropTypes.object,
  setTargetContact: PropTypes.func,
  disableDelete: PropTypes.bool,
};

export default TaskContact;
