module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   classNames = require("classnames")
   EverTrue = require("app")
   {createComponent} = require("@evertrue/et-flux")
   {div, span} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   Icon = createFactory (require("components/elements/icon").default)
   EllipsisOverflow = createFactory require("components/formatting/ellipsis-overflow")
   moment = require("moment")
   EventIcon = require("apps/events/components/event-icon")
   EventDetailLocation = require("apps/events/components/event-detail-location")
   AdvancedCombobox = createFactory(require("@evertrue/et-components").AdvancedCombobox)
   Link = createFactory(require("@evertrue/et-components").Link)

   RESPONSE_OPTIONS = {
      facebook: [
         {value: "all", label: "All Event Responses"}
         {value: "going", label: "Going"}
         {value: "interested", label: "Interested"}
         {value: "invited", label: "Invited"}
         {value: "cant-go", label: "Can't Go"}
      ],
      eventbrite: [
         {value: "all", label: "All Event Responses"}
         {value: "attending", label: "Attending"}
         {value: "checked-in", label: "Checked In"}
         {value: "not-attending", label: "Not Attending"}
      ]
   }

   createComponent "EventDetailPageHeader",
      propTypes:
         event: ReactLibs.PropTypes.object
         counts: ReactLibs.PropTypes.object
         selectedFilterCount: ReactLibs.PropTypes.number
         actions: ReactLibs.PropTypes.node
         showBackButton: ReactLibs.PropTypes.bool
         backButtonHref: ReactLibs.PropTypes.string
         disableActions: ReactLibs.PropTypes.bool
         onFilterChange: ReactLibs.PropTypes.func
         filters: ReactLibs.PropTypes.array

      getDefaultProps: ->
         event: {}
         counts: {}
         filters: ["all"]

      getOptionWithCount: (option) ->
         optionCopy = _.clone(option)
         # I check against undefined since 0 is a valid option
         if @props.counts[option.value] != undefined
            optionCopy.label = "#{option.label} (#{@props.counts[option.value]})"
         optionCopy

      handleBack: ->
         if window.history.length > 1 then window.history.back()
         else EverTrue.Navigator("/events", true)

      handleViewEvent: ->
         if @props.event.source is "facebook"
            "https://www.facebook.com/#{@props.event.remote_id}"
         else if @props.event.source is "eventbrite"
            "https://www.eventbrite.com/e/#{@props.event.remote_id}"


      render: ->
         show_back = @props.showBackButton || @props.backButtonHref
         {name, start_time, location} = @props.event
         link = @handleViewEvent()

         options = _.map RESPONSE_OPTIONS[@props.event.source], (option) =>
            @getOptionWithCount(option)

         filters = _.map @props.filters, (filter) ->
            _.findWhere(options, {value: filter})

         div className: classNames("event-detail-page-header clearfix", @props.className),
            div null,
               if show_back
                  Link
                     href: @props.backButtonHref || "/"
                     title: "go Back"
                     className: "event-detail-page-header--back"
                     onClick: if !@props.backButtonHref then @handleBack
                     Icon icon: "chevron-left", size: 2

               Link href: link, title: "View event #{name}", target: "_blank",
                  EventIcon
                     className: "event-detail-page-header--image"
                     event: @props.event
                     size: 43

               div className: classNames("event-detail-page-header--label", "has-back": show_back),
                  EllipsisOverflow null,
                     span className: "event-detail-page-header--title",
                        Link href: link, title: "View event #{name}", target: "_blank",
                           name
                  div className: "event-detail-page-header--subtitle",
                     EllipsisOverflow null,
                        moment(start_time).format("ddd, MMM D h:mmA"),
                        EventDetailLocation locationData: location

               div className: "event-detail-page-header--status",
                  AdvancedCombobox
                     value: filters
                     appendValue: " (#{@props.selectedFilterCount})"
                     options: options
                     multiple: true
                     onChange: (val) =>
                        @props.onFilterChange(val)

               @props.children
