import _ from "underscore";
import EverTrue from "app";
import BaseRouter from "base/base-router";
import LoginRouter from "apps/login/login-router";
import ContactRouter from "apps/contact/contact-router";
import CompanyRouter from "apps/companies/company-router";
import FacebookRouter from "apps/facebook/facebook-router";
import CommunityRouter from "apps/community/community-router";
import NotificationRouter from "apps/notifications/notification-router";
import ListRouter from "apps/lists/list-router";
import AdminRouter from "apps/admin/admin-router";
import LayoutController from "apps/layout/controllers/layout-controller";
import ExportRouter from "apps/export/export-router";
import SettingsRouter from "apps/settings/user-settings-router";
import MyPortfolioRouter from "apps/major-gifts/my-portfolio-router";
import ProfileRouter from "apps/profile/profile-router";
import InteractionsRouter from "apps/interactions/interactions-router";
import ProposalRouter from "apps/proposals/proposal-router";
import MyTripsRouter from "apps/my-trips/my-trips-router";
import VolunteersRouter from "apps/volunteers/volunteers-router";
import MyTeamRouter from "apps/my-team/my-team-router";
import EventsRouter from "apps/events/events-router";
import PortfolioPerformanceRouter from "apps/portfolio-performance/portfolio-performance-router";
import DashboardRouter from "apps/dashboard/dashboard-router";
import ClarityRouter from "apps/clarity/clarity-router";
import InitRouter from "base/init-router";
import SegmentsRouter from "apps/segments/segments-router";
import FiltersInventoryRouter from "apps/filters-inventory/filters-inventory-router";
import ConstituentsRouter from "apps/constituents/constituents-router";
import SearchRouter from "apps/search/search-router";
import DynamicListsRouter from "apps/dynamic-lists/dynamic-lists-router";
import TasksRouter from "apps/tasks/components/tasks-router";
import JourneysRouter from "apps/journeys/journeys-router";
import ReportingRouter from "../apps/reporting/reporting-router";
import EmailRouter from "components/emailer/email-router";
import EtHelpRouter from "components/et_help/et-help-router";
import HomepageRouter from "apps/home/homepage-router";
import AISearchRouter from "apps/ai-search/AISearchRouter";

// Manage Login Routes and Application Loading
class AppRouter extends BaseRouter {
  static initClass() {
    this.prototype.routeManager = {};
    this.prototype.routes = { "*path": "base" };
  }

  initialize() {
    this.routeManager = {};
    this.routeManager.login = new LoginRouter();
    this.routeManager.profile = new ProfileRouter();
    this.routeManager.contact = new ContactRouter();
    this.routeManager.companies = new CompanyRouter();
    this.routeManager.facebook = new FacebookRouter();
    this.routeManager.community = new CommunityRouter();
    this.routeManager.notifications = new NotificationRouter();
    this.routeManager.lists = new ListRouter();
    this.routeManager.admin = new AdminRouter();
    this.routeManager.export = new ExportRouter();
    this.routeManager.settings = new SettingsRouter();
    this.routeManager.myporfolio = new MyPortfolioRouter();
    this.routeManager.interactions = new InteractionsRouter();
    this.routeManager.mytrips = new MyTripsRouter();
    this.routeManager.myteam = new MyTeamRouter();
    this.routeManager.proposals = new ProposalRouter();
    this.routeManager.volunteers = new VolunteersRouter();
    this.routeManager.events = new EventsRouter();
    this.routeManager["portfolio-performance"] = new PortfolioPerformanceRouter();
    this.routeManager.dashboard = new DashboardRouter();
    this.routeManager.clarity = new ClarityRouter();
    this.routeManager.initRouter = new InitRouter();
    this.routeManager.segmentsRouter = new SegmentsRouter();
    this.routeManager.FiltersInventoryRouter = new FiltersInventoryRouter();
    this.routeManager.constituentsRouter = new ConstituentsRouter();
    this.routeManager.searchRouter = new SearchRouter();
    this.routeManager.dynamicListsRouter = new DynamicListsRouter();
    this.routeManager.TasksRouter = new TasksRouter();
    this.routeManager.journeys = new JourneysRouter();
    this.routeManager.reporting = new ReportingRouter();
    this.routeManager.reporting = new EmailRouter();
    this.routeManager.etHelpRouter = new EtHelpRouter();
    this.routeManager.homepageRouter = new HomepageRouter();
    this.routeManager.aiSearchRouter = new AISearchRouter();

    EverTrue.commands.setHandler("route:cleanup", () => {
      return _.each(this.routeManager, (router) => router.cleanup());
    });

    EverTrue.commands.setHandler("app:cleanup", () => EverTrue.execute("route:cleanup"));

    // Application Wide Controller for Navigation / Header
    // TODO: is there a cleaner way to manage this?
    EverTrue.vent.on("enter:app", () => EverTrue.mount(LayoutController()));

    return super.initialize(...arguments);
  }

  base() {
    EverTrue.Navigator("/init", true);
  }
}

AppRouter.initClass();
export default AppRouter;
