import PropTypes from "prop-types";
import _ from "underscore";
import moment from "moment";
import numeral from "numeral";
import { Icon } from "@evertrue/et-components";

export const ProposalDetailCustomField = ({ customField }) => {
  const customFieldValue = customField.value;

  switch (customField.data_type) {
    case "BOOLEAN":
      //The value is always a string so it needs to be compared as such.
      return customFieldValue.toLowerCase() === "true" ? (
        <>
          <Icon size={1} icon="check" className="proposal-detail--reporting-true" /> YES
        </>
      ) : (
        <>
          <Icon size={1} icon="close" className="proposal-detail--reporting-false" /> NO
        </>
      );
    case "DATE":
      const date_value =
        _.isString(customFieldValue) && customFieldValue !== "" ? _.toNumber(customFieldValue) : customFieldValue;
      return moment(date_value).format("MMM DD, YYYY");
    case "DOUBLE":
      return numeral(customFieldValue).format("$0,0.00");
    default:
      return customFieldValue;
  }
};

ProposalDetailCustomField.propTypes = {
  customField: PropTypes.object,
};

export default ProposalDetailCustomField;
