import Decorator from "clientDecorator";
import ContactFormatMixin from "mixins/contact-format-mixin";
import AddressMixin from "mixins/address-mixin";

const contactLatLngs = (contact) => {
  const { addresses, id } = contact;
  if (!addresses) return [];
  return addresses
    .map(({ lat, lng }, idx) => {
      if (!lat || !lng) return null;
      if (lat.value && lng.value)
        return {
          lat: lat.value,
          lng: lng.value,
          key: `${id}${idx}`,
          contactId: id,
          contactInfo: {
            name: Decorator.Contacts.getDetailedName(contact) || contact.name,
            constituencyType: ContactFormatMixin.constituencies(contact),
            addressType: AddressMixin.getAddressType(contact),
            graduationYear: ContactFormatMixin.graduationYear(contact),
            score: ContactFormatMixin.score(contact),
            donorIndex: ContactFormatMixin.giving(contact, "et_donor_index"),
          },
        };
      return null;
    })
    .filter((el) => !!el); // filter out nulls
};

export const getContactsLngLat = (contactsData) => {
  return contactsData.reduce((accum, contact) => {
    return accum.concat(contactLatLngs(contact));
  }, []);
};

export const getBounds = (map) => {
  const bounds = map.getBounds();
  return {
    north: bounds.getNorthEast().lat().toFixed(5),
    south: bounds.getSouthWest().lat().toFixed(5),
    east: bounds.getNorthEast().lng().toFixed(5),
    west: bounds.getSouthWest().lng().toFixed(5),
  };
};

export const getMapSettings = (map) => {
  const { lat, lng } = map.getCenter();
  const center = { lat: lat(), lng: lng() };
  return { zoom: map.getZoom(), center };
};
