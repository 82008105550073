module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   React = require("react")
   EverTrue = require("app")
   {createComponent} = require("@evertrue/et-flux")
   UrlSource = require("apps/layout/sources/url-source")
   ContactSource = require("apps/contact/sources/contact-source")
   FilterStore = require("apps/filters/stores/filter-store")
   FilterSource = require("apps/filters/sources/filter-source")
   FeatureStore = require("apps/layout/stores/feature-store").default
   ShareSegmentStore = require("apps/filters/stores/share-segment-store")
   ShareSegmentSource = require("apps/filters/sources/share-segment-source")
   SegmentStore = require("apps/filters/stores/segment-store")
   CoreSearchSource = require("apps/core-search/sources/core-search-source")
   CoreSearchStore = require("apps/core-search/stores/core-search-store")
   SegmentFiltersController = require("apps/filters/controllers/segment-filters-controller")
   LegacyAllConstituentsController = require("apps/contact/controllers/legacy-all-constituents-controller")
   {createFactory} = require("base/new-utils")
   LegacyProposalSearchController = createFactory require("apps/proposals/controllers/legacy-proposal-search-controller").default
   LegacyInteractionSearchController = require("apps/interactions/controllers/legacy-interaction-search-controller")
   {div} = ReactLibs.DOMFactories


   ACTIVE_FILTER_HEIGHT = 72


   createComponent "CoreSearchController",
      propTypes:
         view: ReactLibs.PropTypes.string
         type: ReactLibs.PropTypes.string
         hash_id: ReactLibs.PropTypes.string

      registerStores: ->
         @on FilterStore, ->
            filters: FilterStore.getActiveFilters()

         @on SegmentStore, ->
            selected_segment: SegmentStore.getSelectedSegment()

         @on FeatureStore, ->
            has_interaction_reads: FeatureStore.hasFeature("ugc_show_imported_notes")
            has_proposal_reads: FeatureStore.hasFeature("proposal_reads")

         @on CoreSearchStore, ->
            results_count: CoreSearchStore.getActiveResultsCount()
            type_search_config: CoreSearchStore.getConfig()

         @on ShareSegmentStore, ->
            is_creating_link: ShareSegmentStore.getIsCreatingLink()

      componentDidMount: ->
         $("body").css("overflow", "hidden")
         CoreSearchSource.setType(@props.type)
         UrlSource.fetchSegment()
         UrlSource.fetchOperator()

         if not @state.is_creating_link
            # If a long filter is currently being converted to a hash id
            # then we need to not mess with that until it's done.
            # A navigation trigger will occur when it resolves
            if @props.hash_id
               FilterSource.loading(true)
               ShareSegmentSource.fetchFilters(@props.hash_id)
            else
               UrlSource.fetchFilters()
               @handleTrack()

      componentWillUnmount: ->
         $("body").css("overflow", "auto")
         if !window.location.search.match(/selected_segment/i)
            UrlSource.updateSegment()

      UNSAFE_componentWillReceiveProps: (newProps) ->
         if @props.type != newProps.type
            CoreSearchSource.setType(newProps.type)

      handleFilterChange: ->
         CoreSearchSource.query(@props.type)
         route = @state.type_search_config[@props.type].route
         EverTrue.UrlManager.navigate(route) if route

      handleApplyFilters: ->
         if @props.view == "map"
            FilterSource.fetchFilteredClusters()
         @handleTrack()

      handleTrack: ->
         filter_keys = FilterStore.getActiveFilterKeys() || []
         if filter_keys.length > 0
            EverTrue.track.set "filter_applied",
               type: "filter"
               params: filter_keys
               operator: FilterStore.getOperator()
               result_type: if @props.type == "contacts" then "constituents" else @props.type

      handleExport: (property_ids) ->
         data = {search: FilterStore.getActiveFiltersQuery()}
         if @state.selected_segment && @state.selected_segment.type == "saved_search"
            _.extend data, {list_id: @state.selected_segment?.id}
         # called in the 'Export # Results' action item modal
         EverTrue.track.set "manual_export_action", type: "constituents"
         ContactSource.export(property_ids, data)

      render: ->
         div className: "filtered-contacts fixed-page-wrapper",
            if @props.type != "proposals"
               SegmentFiltersController
                  view: @props.view
                  resultCount: @state.results_count
                  onExport: @handleExport
                  onApply: @handleApplyFilters
                  onChange: @handleFilterChange

            switch @props.type
               when "interactions"
                  LegacyInteractionSearchController
                     style: {top: ACTIVE_FILTER_HEIGHT}
               when "proposals"
                  LegacyProposalSearchController null
               else
                  LegacyAllConstituentsController
                     totalNumber: @state.results_count
                     view: @props.view
                     style: {top: ACTIVE_FILTER_HEIGHT}
                     hash_id: @props.hash_id
