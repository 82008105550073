import EverTrue from "app";
import BaseRouter from "base/base-router";
import TasksController from "apps/tasks/controllers/tasks-controller";
import { TasksProvider } from "apps/tasks/hooks/tasks-context";
import IsGatedController from "components/gates/is-gated-controller";

class TasksRouter extends BaseRouter {
  static initClass() {
    this.prototype.routes = {
      "tasks(/)": "index",
      "tasks/assigned": "assigned",
      "tasks/ai-tasks": "aiTasks",
    };
  }

  index() {
    EverTrue.execute("setBrowserTitle", "Tasks");
    this.currentView = (
      <IsGatedController gate="task_management">
        <TasksProvider>
          <TasksController tab="" userType="OWNER" />
        </TasksProvider>
      </IsGatedController>
    );
    EverTrue.page.mount(this.currentView);
  }

  assigned() {
    EverTrue.execute("setBrowserTitle", "Tasks | Assigned");
    this.currentView = (
      <IsGatedController gate="task_management">
        <TasksProvider>
          <TasksController tab="assigned" userType="CREATOR_BUT_NOT_OWNER" />
        </TasksProvider>
      </IsGatedController>
    );
    EverTrue.page.mount(this.currentView);
  }
  aiTasks() {
    EverTrue.execute("setBrowserTitle", "Task Suggestions | AI Tasks");
    this.currentView = (
      <IsGatedController gate="task_management">
        <TasksProvider>
          <TasksController tab="ai-tasks" userType="OWNER" />
        </TasksProvider>
      </IsGatedController>
    );
    EverTrue.page.mount(this.currentView);
  }
}

TasksRouter.initClass();
export default TasksRouter;
