export const updateTasksAfterTimeout = (dataArray, startDate, endDate) => {
  const isDateInRange = (date, start, end) => {
    return date >= start && date <= end;
  };

  return dataArray.map((obj) => {
    const objDate = new Date(obj.date);
    if (isDateInRange(objDate, new Date(startDate * 1000), new Date(endDate * 1000))) {
      return {
        ...obj,
        email: 0,
        phone: 0,
        other: 0,
        total: 0,
        completed: 0,
      };
    }
    return obj;
  });
};

// takes an array of journey objects and combines their "task_due_counts" fields into a single object
export const reduceJourneysTaskDueCounts = (journeys = []) => {
  const totalsObj = { Total: 0, Emails: 0, Calls: 0, Other: 0, Completed: 0 };
  journeys.forEach((journey) => {
    const { tasks_due_counts = {} } = journey;
    const counts = mapTaskCounts(tasks_due_counts);
    Object.keys(counts).forEach((key) => {
      totalsObj[key] += counts[key];
    });
  });

  return totalsObj;
};

// takes an object with multiple action_types and maps them to a consolidated value.
export const mapTaskCounts = (taskCounts) => {
  const totalsObj = { Total: 0, Emails: 0, Calls: 0, Other: 0, Completed: 0 };
  Object.entries(taskCounts).forEach(([key, value]) => {
    switch (key) {
      case "total":
        totalsObj.Total += value;
        break;
      case "Email":
        totalsObj.Emails += value;
        break;
      case "Call":
        totalsObj.Calls += value;
        break;
      // Added this switch to avoid it getting into the Other count
      case "completed":
        totalsObj.Completed += value;
        break;
      default:
        totalsObj.Other += value;
        break;
    }
  });

  return totalsObj;
};

export const getPromotionDetails = (enrichment) => {
  const { employment_events = [] } = enrichment || {};
  let mostRecentEvent = null;
  employment_events.forEach((event) => {
    const eventType = event.type.toLowerCase();
    if (
      ["promotion", "new job", "retired"].includes(eventType) &&
      (!mostRecentEvent || event.recorded_at > mostRecentEvent.recorded_at)
    ) {
      mostRecentEvent = event;
    }
  });

  return mostRecentEvent;
};

export const titleCaseString = (string = "") => {
  if (!string.length) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getEmptySectionName = (subtitle) => {
  switch (subtitle) {
    case "Recent Gift":
      return "recent gift";
    case "Promotion":
      return "promotion";
    case "Last Gift":
      return "gift anniversary";
    default:
      return "";
  }
};

export function getDateRange(days) {
  const start = new Date();
  start.setHours(0, 0, 0, 0);

  const end = new Date();
  end.setHours(23, 59, 59, 999);
  end.setDate(end.getDate() + days);

  return [start.getTime(), end.getTime()];
}
