import PropTypes from "prop-types";
import _ from "underscore";
import { Component } from "react";
import ModalConfirm from "components/modals/modal-confirm";
import Avatar from "components/elements/avatar";
import JointContactCard from "apps/volunteers/components/joint-contact-card";
import AmountsInput from "./amounts-input";

class AmountModal extends Component {
  static propTypes = {
    searchAssignment: PropTypes.object,
    secondary: PropTypes.object,
    assignedToName: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
    header: PropTypes.string,
    amount: PropTypes.string,
    label: PropTypes.string,
  };

  static defaultProps = { searchAssignment: {}, amount: "" };

  state = {
    dollar_val: this.props.amount,
  };

  renderAvatar = (avatar_url) => {
    return (
      <div className="ask-amount-modal--joint-avatars">
        <Avatar className="ask-amount-modal--primary-avatar" url={avatar_url} width={48} />

        <Avatar
          className="ask-amount-modal--secondary-avatar"
          url={this.props.secondary != null ? this.props.secondary.avatar : undefined}
          width={48}
        />
      </div>
    );
  };

  render() {
    const assigned_to_node = this.props.assignedToName ? (
      <span className="ask-amount-modal--assigned-to">{`Assigned to: ${this.props.assignedToName}`}</span>
    ) : undefined;
    const is_joint = !_.isEmpty(this.props.secondary);

    return (
      <ModalConfirm
        style={{ minWidth: "348px" }}
        buttonLabel="Save"
        disableButton={this.state.dollar_val < 0 || this.state.dollar_val === "" || this.state.dollar_val === null}
        header={this.props.header}
        onSubmit={() => this.props.handleSubmit(this.state.dollar_val)}
        onCancel={this.props.close}
      >
        <div className="ask-amount-modal">
          <JointContactCard
            is_joint={Boolean(is_joint)}
            contact={this.props.searchAssignment.contact}
            secondary={this.props.secondary}
          >
            {is_joint ? <div>Joint Assignment</div> : undefined}
            {assigned_to_node}
          </JointContactCard>
          <AmountsInput
            label={this.props.label}
            amount={this.state.dollar_val}
            onChange={(dollar_val) => this.setState({ dollar_val })}
          />
        </div>
      </ModalConfirm>
    );
  }
}

export default AmountModal;
