module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   React = require("react")
   moment = require("moment")
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   AdvancedCombobox = createFactory(require("@evertrue/et-components").AdvancedCombobox)
   CalendarDropdown = createFactory(require("components/controls/calendar-dropdown"))
   FilterPanelRow = require("components/controls/filter-panel-row")
   FilterTextInput = require("apps/filters/components/filter-text-input")
   FilterMultiSelect = require("apps/filters/components/filter-multi-select")
   FacetSource = require("apps/filters/sources/facet-source")
   Gated = createFactory require("components/gated")

   DATE_STATE_OPTIONS = [
      {label: "Lifetime Interactions", value: "all"}
      {label: "Pick Specific Dates", value: "range"}
   ]

   DATE_FORMAT = "YYYY-MM-DD"
   DATE_SHORTCUTS = [
      {id: "now-1w", format: [1, "week"], label: "in past week"}
      {id: "now-1M", format: [1, "month"], label: "in past month"}
      {id: "now-3M", format: [3, "months"], label: "in past 3 months"}
      {id: "now-1y", format: [1, "year"], label: "in past year"}
   ]


   createComponent "NoteFilters",
      propTypes:
         filters: ReactLibs.PropTypes.object
         onChange: ReactLibs.PropTypes.func

      componentDidMount: ->
         FacetSource.fetch(["author.name.untouched", "interaction_type.untouched"], "contact_note")

      handleChange: (key, value) ->
         filters = $.extend true, {}, @props.filters
         if _.isEmpty(value) then filters[key] = undefined
         else filters[key] = value
         @props.onChange?(_.compactObject(filters))

      renderDateRangeOptions: ->
         current_date = new Date()
         _.compact _.map DATE_SHORTCUTS, (shortcut) ->
            [number, string] = shortcut.format || []
            id: shortcut.id
            date_from: moment(current_date).subtract(number, string).format(DATE_FORMAT)
            date_to: moment(current_date).format(DATE_FORMAT)
            title: shortcut.label

      render: ->
         filters = @props.filters || {}
         range_options = @renderDateRangeOptions()
         date_value = filters.date
         if date_value && !date_value?.date_to
            shortcut = _.findWhere range_options, {id: date_value?.date_from}
            date_value = _.pick shortcut, "date_from", "date_to"


         div className: "profile-filters",
            # Interaction Date
            FilterPanelRow label: "Date",
               div className: classNames("profile-filters--col", "is-active": !!filters.date),
                  AdvancedCombobox
                     value: if filters.date then DATE_STATE_OPTIONS[1] else DATE_STATE_OPTIONS[0]
                     options: _.cloneData DATE_STATE_OPTIONS
                     onChange: (val) =>
                        if val?.value == "all" then @handleChange("date", undefined)
                        else @handleChange("date", _.pick(_.last(range_options), "date_from", "date_to"))

               if filters.date
                  div className: classNames("interaction-date-dropdown"),
                     CalendarDropdown
                        date_from: moment(date_value?.date_from).format(DATE_FORMAT)
                        date_to: moment(date_value?.date_to).format(DATE_FORMAT)
                        date_range: range_options
                        onChange: (date_from, date_to) =>
                           shortcut = _.find range_options, (facet) ->
                              facet.date_from == date_from && facet.date_to == date_to

                           @handleChange "date", _.compactObject
                              date_from: if shortcut then shortcut?.id else date_from
                              date_to: date_to unless shortcut

            # Engagement Type
            Gated feature: "ugc_show_imported_notes",
               FilterPanelRow label: "Interaction Type",
                  div className: "profile-filters--col",
                     FilterMultiSelect
                        value: filters.category
                        filter_id: "9.1"
                        onChange: (value) => @handleChange("category", value)

            # Author
            FilterPanelRow label: "Authored By",
               div className: "profile-filters--col",
                  FilterMultiSelect
                     value: filters.author
                     filter_id: "9.4"
                     onChange: (value) =>
                        @handleChange("author", value)

            # Keyword Search
            FilterPanelRow label: "Content Keyword Search",
               FilterTextInput
                  className: "profile-filters--text"
                  value: {value: filters.keyword}
                  placeholder: "Enter keywords..."
                  onChange: (value) => @handleChange("keyword", value)
