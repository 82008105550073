module.exports = do ->

   _ = require("underscore").default
   React = require("react")
   EverTrue = require("app")
   {createComponent} = require("@evertrue/et-flux")
   {g} = ReactLibs.DOMFactories
   XAxis = require("components/charts/xaxis")
   YAxis = require("components/charts/yaxis")

   DEFAULT_PADDING = {left: 50, bottom: 30, right: 20, top: 10}


   createComponent "Grid",
      propTypes:
         width: ReactLibs.PropTypes.number
         height: ReactLibs.PropTypes.number
         padding: ReactLibs.PropTypes.object
         yTicks: ReactLibs.PropTypes.array
         xTicks: ReactLibs.PropTypes.array
         xFormat: ReactLibs.PropTypes.func
         yFormat: ReactLibs.PropTypes.func
         lineStyle: ReactLibs.PropTypes.object
         axisStyle: ReactLibs.PropTypes.object
         showXGridLines: ReactLibs.PropTypes.bool
         showYGridLines: ReactLibs.PropTypes.bool
         getCoords: ReactLibs.PropTypes.func

      getDefaultProps: ->
         showYGridLines: true
         showXGridLines: true
         padding: _.extend {}, DEFAULT_PADDING

      componentDidMount: ->
         @handleDraw(@props)

      UNSAFE_componentWillReceiveProps: (newProps) ->
         if newProps.width != @props.width ||
               newProps.height != @props.height ||
               _.jsonStringify(newProps.padding) != _.jsonStringify(@props.padding)
            @handleDraw(newProps)

      handleDraw: (props) ->
         padding = _.extend {}, DEFAULT_PADDING, props.padding
         grid_width = props.width - padding.left - padding.right
         grid_height = props.height - padding.top - padding.bottom
         props.onDraw?(grid_width, grid_height, padding)

      render: ->
         padding = _.extend {}, DEFAULT_PADDING, @props.padding
         grid_width = @props.width - padding.left - padding.right
         grid_height = @props.height - padding.top - padding.bottom


         g className: "etchart-grid",
            g transform: "translate(#{padding.left}, #{padding.top})",
               XAxis
                  width: grid_width
                  height: grid_height
                  ticks: @props.xTicks
                  format: @props.xFormat
                  lineStyle: if !@props.showXGridLines then {display: "none"} else @props.lineStyle
                  axisStyle: if !@props.showXGridLines then {display: "none"} else @props.axisStyle
                  getCoords: @props.getCoords

               YAxis
                  width: grid_width
                  height: grid_height
                  ticks: @props.yTicks
                  format: @props.yFormat
                  lineStyle: if !@props.showYGridLines then {display: "none"} else @props.lineStyle
                  axisStyle: if !@props.showYGridLines then {display: "none"} else @props.axisStyle
                  getCoords: @props.getCoords

               @props.children
