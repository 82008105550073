module.exports = do ->
   _ = require("underscore").default
   moment = require("moment")
   React = require("react")
   EverTrue = require("app")
   {createComponent} = require("@evertrue/et-flux")
   {span, div, h4, li, ul} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   CommentListItem = createFactory require("apps/contact/components/profile/comments/comments-list-item")
   ContactSource = require("apps/contact/sources/contact-source")
   NotificationStore = require("apps/notifications/stores/notification-store")
   DynamicListSource = require("apps/dynamic-lists/sources/dynamic-list-source")
   Avatar = require("components/elements/avatar")
   Icon = createFactory (require("components/elements/icon").default)


   createComponent "NotificationItem",
      propTypes:
         notification: ReactLibs.PropTypes.object
         count: ReactLibs.PropTypes.number
         onClick: ReactLibs.PropTypes.func

      isAnotherOid: ->
         oid = @props.notification?.organization_id
         oid != EverTrue.store?.org?.get("id")

      handleClick: (e) ->
         if @isAnotherOid()
            e.preventDefault()
            e.stopPropagation()

         if(@props.notification.activity_type == "dynamic_list_v2")
            DynamicListSource.default.setClearFilters(true)
         
         url = NotificationStore.getActivityUrl(@props.notification.id)
         if url && @props.notification.activity_type == "export"
            window.location = url
         else if url
            if window.location.pathname == url
               window.location.reload();
            else if url.match(/^\/contact/) then ContactSource.loading()
            _.defer (-> EverTrue.Navigator(url, true))

         @props.onClick?(@props.notification.id)

      render: ->
         notification = @props.notification
         activity = notification.activity || {}
         user_id = activity?.content?.added_by_user_id
         type = notification.activity_type
         url = NotificationStore.getActivityUrl(notification.id)
         message = activity?.content?.title

         is_other_oid = @isAnotherOid()
         is_visible_mention = type == "mention" && !is_other_oid

         classList = EverTrue.classSet
            "notification-item": true
            "is-unread": !notification.read_at
            "is-disabled": is_other_oid
            "is-static": !url
            "notification-item--with-mention": is_visible_mention

         activity_icon = switch type
            when "facebook" then "facebook-box"
            when "linkedin" then "linkedin-box"
            when "export" then "cloud-download"
            when "dynamic_list_v2" then "segment"
            when "mention"
               if is_other_oid then "et-pyramid" else undefined
            else "et-pyramid"

         li null,
            div className: classList, onClick: @handleClick,
               # Icon Display
               if _.contains(["list", "share_search"], type)
                  Avatar
                     className: "notification-item--icon fs-exclude-data"
                     url: if user_id then EverTrue.config.apiBase + "lids/users/#{user_id}/linkedin/avatar"
                     name: message
               else if activity_icon
                  Icon
                     icon: activity_icon
                     size: 2
                     className: EverTrue.classSet
                        "notification-item--icon": true
                        "icon-linkedin-brand": type == "linkedin"
                        "icon-facebook-brand": type == "facebook"
                        "is-disabled": !!notification.read_at

               div className: "notification-item--content",
                  h4 className: "notification-item--message",
                     span className: "fs-exclude-data", notification.message

                  if is_visible_mention and activity?.content?.note
                     ul className: "notification-item--comment fs-exclude-data",
                        CommentListItem comment: activity.content.note, readOnly: true

                  div className: "notification-item--caption",
                     moment(notification.created_at).fromNow()

                     if is_other_oid
                        span className: "text-warning",
                           " - Notification for Org ##{notification.organization_id || "null"}"
