import { useMemo, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import FiltersBarController from "apps/query-builder/components/filters-bar-controller";
import FilterMapReset from "apps/query-builder/components/filter-map-reset";
import FilterReset from "apps/query-builder/components/filter-reset";
import DynamicListTable from "apps/dynamic-lists/components/dynamic-list-table";
import DynamicListHeader from "apps/dynamic-lists/components/dynamic-list-header";
import { GlobalContactSearchContext } from "apps/layout/controllers/global-contact-search-context";
import useFetchDynamicList from "../hooks/use-fetch-dynamic-list";
import EmptyStateMessage from "components/lists/empty-state-message";
import { LIST_TAG_STAT } from "apps/dynamic-lists/utils/dynamic-list-enums";
import DynamicListStore from "apps/dynamic-lists/stores/dynamic-list-store";
import DynamicListSource from "apps/dynamic-lists/sources/dynamic-list-source";
import { connect } from "@evertrue/et-flux";
import Loading from "components/elements/loading";
import useFetchDynamicListAlertPrefs from "apps/dynamic-lists/hooks/use-fetch-dynamic-list-alert-prefs";

const mapStateToProps = () => ({
  clearFilters: DynamicListStore.getClearFilters(),
  dynamicListAlertPref: DynamicListStore.getDynamicListAlertPref(),
});

const ViewDynamicListController = ({ list_id, clearFilters, dynamicListAlertPref, showMap = false }) => {
  const { listData, dataLoading } = useFetchDynamicList(list_id);
  const { dslQuery } = useContext(GlobalContactSearchContext);
  const { resetFiltersAndSettings } = useContext(GlobalContactSearchContext);

  const dsl = useMemo(() => {
    return { ...dslQuery, scope: [{ list: { id: list_id } }] };
  }, [list_id, dslQuery]);

  const { tag_stats = {} } = listData;
  const { alertPrefs } = useFetchDynamicListAlertPrefs(list_id);

  const [isAlertShown, setisAlertShown] = useState(false);

  useEffect(() => {
    DynamicListSource.setDynamicListAlertPref(alertPrefs);
    if (clearFilters) {
      resetFiltersAndSettings();
      DynamicListSource.setClearFilters(false);
    }
  }, [resetFiltersAndSettings, clearFilters, alertPrefs]);

  useEffect(() => {
    if (dynamicListAlertPref.email_enabled) {
      setisAlertShown(true);
    } else {
      setisAlertShown(false);
    }
  }, [dynamicListAlertPref]);

  if (tag_stats === null) {
    return (
      <>
        <DynamicListHeader dsl={dsl} listData={listData} disabled={true} />
        <div style={{ marginTop: "24vh" }}>
          <Loading spinner_size={"small"} className={"loader-icon"} />
          <EmptyStateMessage hideIcon={true} text="This list is being processed">
            It should be ready in a few minutes.
            <br /> We'll send a notification once the list is ready!
          </EmptyStateMessage>
        </div>
      </>
    );
  } else {
    switch (tag_stats.operation_status) {
      case LIST_TAG_STAT.ACCESS_DENIED:
        return (
          <div style={{ marginTop: "30vh" }}>
            <EmptyStateMessage icon="lock" text="You do not have access to this list"></EmptyStateMessage>
          </div>
        );
      case LIST_TAG_STAT.DELETED:
        return (
          <div style={{ marginTop: "30vh" }}>
            <EmptyStateMessage icon="trash" text="This list has been permanently deleted"></EmptyStateMessage>
          </div>
        );
      case LIST_TAG_STAT.RUNNING:
      case null:
        return (
          <>
            <DynamicListHeader
              dsl={dsl}
              listData={listData}
              disabled={true}
              dynamicListAlertPref={dynamicListAlertPref}
            />
            <div style={{ marginTop: "24vh" }}>
              <Loading spinner_size={"small"} className={"loader-icon"} />
              <EmptyStateMessage hideIcon={true} text="This list is being processed">
                It should be ready in a few minutes.
                <br /> We'll send a notification once the list is ready!
              </EmptyStateMessage>
            </div>
          </>
        );
      case LIST_TAG_STAT.COMPLETED:
        return (
          <>
            <DynamicListHeader dsl={dsl} listData={listData} dynamicListAlertPref={dynamicListAlertPref} />
            <FiltersBarController list_id={list_id}>
              <FilterMapReset showMap={showMap} />
              <FilterReset />
            </FiltersBarController>
            <DynamicListTable list_id={list_id} dsl={dsl} isAlertShown={isAlertShown} showMap={showMap} />
          </>
        );
      default:
        if (!dataLoading) {
          return (
            <div style={{ marginTop: "30vh" }}>
              <EmptyStateMessage icon="warning" text="There was a problem loading your list"></EmptyStateMessage>
            </div>
          );
        }
    }
  }
};

ViewDynamicListController.propTypes = {
  list_id: PropTypes.string.isRequired,
  clearFilters: PropTypes.bool.isRequired,
  dynamicListAlertPref: PropTypes.object,
  showMap: PropTypes.bool,
};

export default connect(ViewDynamicListController, [DynamicListStore], mapStateToProps);
