import { Component } from "react";
import PropTypes from "prop-types";
import _ from "underscore";
import { AdvancedCombobox } from "@evertrue/et-components";
import Api from "entities/helpers/api";

class CustomFieldStringDropdown extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    fieldId: PropTypes.number,
  };

  static defaultProps = {
    onChange: _.noop,
    value: "",
  };

  state = {
    values: [],
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async (like) => {
    try {
      const { values = [] } =
        (await Api.UGC.CUSTOM_FIELD_VALUES.get({
          urlArgs: { custom_field_id: this.props.fieldId },
          disableAlerts: true,
          ...(like && { params: { like } }),
        })) || {};
      // endpoint returns data from either ES or MYSQL so format is inconsistent
      this.setState({ values, ...(!like && { total: values.length }) });
    } catch (error) {
      this.setState({ values: [] });
    }
  };

  debouncedSearch = _.debounce((val) => {
    this.fetchData(val);
  }, 300);

  render() {
    const options = this.state.values.map((item = {}) => ({
      label: item,
      value: item,
    }));

    return (
      <AdvancedCombobox
        value={_.findWhere(options, { value: this.props.value })}
        options={options}
        disabled={this.props.disabled}
        searchable
        onSearch={this.state.total >= 250 ? this.debouncedSearch : null}
        onChange={({ value }) => this.props.onChange(value)}
      />
    );
  }
}

export default CustomFieldStringDropdown;
