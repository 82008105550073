import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalHeaderWithActions, AdvancedCombobox } from "@evertrue/et-components";
import DatePickerDropdown from "components/calendar/date-picker-dropdown";
import moment from "moment";
import TimeDropdown from "components/forms/time-dropdown";
import TimezoneDropdown from "components/forms/timezone-dropdown";
import DNAStore from "base/dna/dna-store";
import TimezoneHelper from "entities/helpers/timezone-helper";
import { updateDynamicListAlerts } from "apps/dynamic-lists/utils/list-actions";
import useFetchDynamicListAlertPrefs from "apps/dynamic-lists/hooks/use-fetch-dynamic-list-alert-prefs";
import DynamicListSource from "apps/dynamic-lists/sources/dynamic-list-source";

const emailFrequencyOptions = [
  { label: "Never", value: "NEVER" },
  { label: "Daily", value: "DAILY" },
  { label: "Weekly", value: "WEEKLY" },
  { label: "Bi-Weekly", value: "BIWEEKLY" },
  { label: "Monthly", value: "MONTHLY" },
];

const EmailAlertsModal = ({ is_open, close, list_id }) => {
  const { alertPrefs } = useFetchDynamicListAlertPrefs(list_id);
  const [submitting, setSubmitting] = useState(false);
  const [email_frequency, setEmailFrequency] = useState(emailFrequencyOptions[0]);
  const [starts_on, setStartsOn] = useState(moment.now());
  const [time, setTime] = useState({
    label: moment("09:00:00", ["HH:mm"]).format("hh:mm a"),
    value: moment("09:00:00", ["HH:mm"]).format("HH:mm"),
  });
  const [notification_timezone, setTimeZone] = useState(
    TimezoneHelper.findSetValue(DNAStore.getByKey("ET.Importer.TimeZone") || moment.tz.guess())
  );

  useEffect(() => {
    const { email_frequency, email_enabled, notification_timezone, starts_on } = alertPrefs;
    if (email_enabled) {
      const time = moment(starts_on.split(" ")[1], ["HH:mm"]);
      setEmailFrequency(emailFrequencyOptions.find((options) => options.value === email_frequency));
      setStartsOn(moment(starts_on).unix() * 1000);
      setTimeZone(TimezoneHelper.findSetValue(notification_timezone));
      setTime({
        label: time.format("hh:mm a"),
        value: time.format("HH:mm:ss"),
      });
    }
  }, [alertPrefs]);

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      let prefs;

      if (email_frequency.value === "NEVER") {
        prefs = { email_enabled: false };
      } else {
        prefs = {
          email_frequency: email_frequency.value.toLowerCase(),
          starts_on: `${moment(starts_on).format("YYYY-MM-DD")} ${moment(time.label, "hh:mm a").format("HH:mm")}`,
          notification_timezone: notification_timezone.value,
          email_enabled: true,
        };
      }

      DynamicListSource.setDynamicListAlertPref({
        ...prefs,
        starts_on: `${moment(starts_on).format("YYYY-MM-DD")} ${time.label}`,
      });
      await updateDynamicListAlerts(list_id, prefs);
      close();
      setSubmitting(false);
    } catch (e) {
      setSubmitting(false);
    }
  };

  return (
    <Modal isOpen={is_open} closeModal={close} size="medium">
      <ModalHeaderWithActions
        title={"Email Alerts"}
        closeModal={close}
        onSubmit={handleSubmit}
        disableSubmit={submitting}
      />
      <ModalBody scrollable={false}>
        <p className="alerts-description">
          Set your preferred cadence for email alerts &ndash; we&rsquo;ll show you which constituents were added to the
          dynamic list during that time period. If no new constituents were found, then no notifications will be sent
          during the the next scheduled email alert.
        </p>
        <div className="alerts-container">
          <p style={{ marginBottom: "10px", fontWeight: "bold" }}>Get email alerts starting:</p>{" "}
          <div className="alerts-options">
            <p>Frequency:</p>
            <AdvancedCombobox
              options={emailFrequencyOptions}
              onChange={(freq) => setEmailFrequency(freq)}
              value={email_frequency}
              label={"Frequency Type"}
            ></AdvancedCombobox>
            <DatePickerDropdown
              date={starts_on}
              onDayClick={(start) => setStartsOn(start)}
              triggerClassName={"alerts-calendar"}
              disabled={email_frequency.value === "NEVER" ? true : false}
              chevronClassName={"date-picker-chevron"}
            />
            <p>Time:</p>
            <TimeDropdown
              increment={60}
              onChange={(time) => setTime(time)}
              value={time}
              disabled={email_frequency.value === "NEVER" ? true : false}
            />
            <TimezoneDropdown
              value={notification_timezone}
              onChange={(tz) => setTimeZone(tz)}
              disabled={email_frequency.value === "NEVER" ? true : false}
            />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

EmailAlertsModal.propTypes = {
  is_open: PropTypes.bool,
  close: PropTypes.func,
  list_id: PropTypes.number,
};

export default EmailAlertsModal;
