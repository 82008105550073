import { PropTypes } from "base/react-libs";
import { PageHeader, Icon, Button } from "@evertrue/et-components";
import EverTrue from "app";
import JourneyActionMenu from "./journeys-action-menu";
import AddJourneyButton from "./add-journey-button";

const JourneysBanner = ({
  journey = {},
  onDetailPage = true,
  userIsCreator = false,
  handleCreateJourney = () => {},
  optionalStateManagementFunction = () => {},
}) => {
  const title = journey.name || "Cadences";
  let { objective_description: description, owner } = journey;
  if (!owner) owner = { name: "EverTrue" };

  const breadcrumbs = [<span style={{ lineHeight: "normal" }}>{`Cadence (Owned by ${owner.name})`}</span>];

  const pageHeaderProps = {
    title,
    className: "journey-details-title",
    subtitle: <span className="dashboard-days--subtext">{description}</span>,
    ...(onDetailPage && {
      onBackClick: () => {
        EverTrue.UrlManager.navigate("/cadences", true, false);
      },
      breadcrumbs: breadcrumbs,
    }),
  };

  return (
    <div className="journeys-banner">
      <PageHeader {...pageHeaderProps} />
      <div className="journeys-banner--action-buttons">
        {onDetailPage ? (
          userIsCreator && (
            <JourneyActionMenu
              journey={journey}
              optionalStateManagementFunction={optionalStateManagementFunction}
            />
          )
        ) : (
          <AddJourneyButton handleCreateJourney={handleCreateJourney} />
        )}
        <Button type="simple" href="settings">
          <Icon icon="gear" size={1} className="homepage-settings-icon" />
        </Button>
      </div>
    </div>
  );
};

JourneysBanner.propTypes = {
  journey: PropTypes.object,
  onDetailPage: PropTypes.bool,
  userIsCreator: PropTypes.bool,
  handleEditJourney: PropTypes.func,
  handleCreateJourney: PropTypes.func,
  handleDeleteJourney: PropTypes.func,
  optionalStateManagementFunction: PropTypes.func,
};

export default JourneysBanner;
