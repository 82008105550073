import _ from "underscore";
import PropTypes from "prop-types";
import { Link, LinkButton, Icon } from "@evertrue/et-components";
import colors from "base/colors";

const FacebookPageMatching = ({ pageWithEngagement, postUrl, isUnmatching, onToggle, isDisabled }) => {
  const flexCenter = { display: "flex", alignItems: "center" };
  const { mostRecentEngagement, page } = pageWithEngagement;
  return (
    <div className="match-facebook-modal-post-info">
      <div style={flexCenter}>
        <img
          className="match-facebook-modal-page-image"
          alt={page.page_name}
          width="40"
          height="40"
          src={page.picture_url}
        />
        <div>
          <Link
            title={`View ${page.page_name}'s Facebook Page`}
            href={`http://www.facebook.com/${page.remote_id}`}
            target="_blank"
          >
            {page.page_name}
          </Link>
          <Link href={postUrl} title="Recent Post" target="_blank" style={{ ...flexCenter, marginTop: 5 }}>
            <img
              alt="facebook post"
              height="18"
              width="18"
              src={`/images/fb-reactions-${mostRecentEngagement.engagement.type}.png`}
              style={{ marginRight: 7 }}
            />
            Recent Post
          </Link>
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "flex-end", width: 75 }}>
        {isUnmatching ? <Icon icon="warning" className="match-facebook-modal-warning--icon" /> : null}
        {isDisabled ? (
          "Unmatch"
        ) : (
          <LinkButton
            title={isUnmatching ? "Unmatch" : "Undo"}
            onClick={() => onToggle()}
            style={{ color: isUnmatching ? colors.gray500 : colors.red500, cursor: "pointer" }}
          >
            {isUnmatching ? "Undo" : "Unmatch"}
          </LinkButton>
        )}
      </div>
    </div>
  );
};

FacebookPageMatching.propTypes = {
  pageWithEngagement: PropTypes.object,
  postUrl: PropTypes.string,
  isUnmatching: PropTypes.bool,
  onToggle: PropTypes.func,
  isDisabled: PropTypes.bool,
};

FacebookPageMatching.defaultProps = {
  pageWithEngagement: {},
  postUrl: "",
  isUnmatching: false,
  onToggle: _.noop,
  isDisabled: false,
};

export default FacebookPageMatching;
