module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   Decorator = require("clientDecorator")
   {createComponent} = require("@evertrue/et-flux")
   MyTripUtils = require("apps/my-trips/utils/my-trips-utils")
   {createFactory} = require("base/new-utils")
   Link = createFactory(require("@evertrue/et-components").Link)

   createComponent "MeetingTravelDistance",
      propTypes:
         meeting: ReactLibs.PropTypes.object
         next: ReactLibs.PropTypes.object

      getInitialState: ->
         distance: undefined
         time: undefined

      componentDidMount: ->
         @setTravelTime(@props)

      UNSAFE_componentWillReceiveProps: (newProps) ->
         if !_.isEqual(newProps.meeting?.location, @props.meeting?.location) ||
               !_.isEqual(newProps.next?.location, @props.next?.location)
            @setTravelTime(newProps)

      setTravelTime: (props) ->
         return if _.isEmpty(props.meeting?.location) || _.isEmpty(props.next?.location)

         origin = new google.maps.LatLng(props.meeting.location.lat, props.meeting.location.lng)
         destination = new google.maps.LatLng(props.next.location.lat, props.next.location.lng)

         service = new google.maps.DistanceMatrixService()
         service.getDistanceMatrix
            origins: [origin]
            destinations: [destination]
            travelMode: "DRIVING"
         , (response, status) =>
            if status == "OK"
               data = _.first(_.first(response.rows)?.elements)
               if @depracated_is_mounted
                  @setState
                     distance: data.distance?.value #in meters
                     time: data.duration?.value #in seconds

      render: ->
         is_valid = @state.distance && @state.time && @props.meeting && @props.next

         if !is_valid then null
         else
            directions_link = MyTripUtils.getDirectionsLink(@props.next, @props.meeting)
            Link title: "meeting directions", href: directions_link, target: "_blank",
               Decorator.MyTrips.getDriveDisplay(@state.distance, @state.time)
