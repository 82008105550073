import { useContext, useEffect } from "react";
import TasksTable from "apps/tasks/components/table/tasks-table";
import TasksHeader from "apps/tasks/components/tasks-header";
import TaskQuickFilters from "apps/tasks/components/task-quick-filters";
import { TasksContext } from "apps/tasks/hooks/tasks-context";
import TasksNav from "apps/tasks/controllers/tasks-nav";
import PropTypes from "prop-types";
import TasksWelcomePage from "apps/tasks/components/tasks-welcome-page";
import { Loading } from "@evertrue/et-components";
import AddTaskButton from "../components/create/add-task-button";
import TasksSuggestionsTable from "../components/table/tasks-suggestions-table";
import JourneyPauseContainer from "apps/journeys/components/top-nav/journeys-pause-container";

const TasksController = ({ tab, contact = {}, userType }) => {
  const {
    total,
    tasks,
    loading,
    getAndSetTasks,
    referrer,
    taskFilters,
    tableSettings,
    setTaskFilters,
    getDefaultFilters,
    allTasksCount,
    allTasksCountLoading,
    removedLastTask,
  } = useContext(TasksContext);

  useEffect(() => {
    setTaskFilters({ ...getDefaultFilters() });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, userType]);

  useEffect(() => {
    getAndSetTasks(taskFilters, userType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskFilters, tableSettings.sortReverse, tableSettings.page, tableSettings.sortProp]);

  if (allTasksCountLoading) {
    return <Loading />;
  } else {
    if (referrer !== "contact" && allTasksCount < 1 && !removedLastTask) {
      return <TasksWelcomePage userType={userType} loading={loading} />;
    } else {
      return (
        <>
          {referrer !== "contact" && <TasksHeader userType={userType} />}
          {referrer !== "contact" && <TasksNav tab={tab} contact={contact} />}
          {tab !== "ai-tasks" && (
            <div>
              <TaskQuickFilters userType={userType} />
              {tab !== "assigned" && <JourneyPauseContainer />}
            </div>
          )}
          <div className="flex">
            {referrer === "contact" && (
              <div style={{ padding: "16px" }}>
                <AddTaskButton contact={contact} userType={userType} />
              </div>
            )}
          </div>
          {tab === "ai-tasks" ? (
            <TasksSuggestionsTable />
          ) : (
            <TasksTable
              total={total}
              tasks={tasks}
              loading={loading}
              tab={tab}
              userType={userType}
              taskFilters={taskFilters}
              contact={contact}
            />
          )}
        </>
      );
    }
  }
};

TasksController.propTypes = {
  contact: PropTypes.object,
  tab: PropTypes.string,
  userType: PropTypes.string,
};

export default TasksController;
