module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   React = require("react")
   ReactDOM = require("react-dom")
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories


   createComponent "StickyHeader",
      propTypes:
         offset: ReactLibs.PropTypes.number
         header_key: ReactLibs.PropTypes.string
         onCollide: ReactLibs.PropTypes.func

      componentDidMount: ->
         @checkForCollision()

      UNSAFE_componentWillReceiveProps: (newProps) ->
         unless _.isEqual(newProps, @props)
            @checkForCollision()

      checkForCollision: ->
         offset = $(ReactDOM.findDOMNode(@)).offset()?.top
         position = offset - @props.offset

         if position <= 0
            if _.isFunction(@props.onCollide)
               @props.onCollide(@props.header_key, position)

      render: ->
         div className: classNames("sticky-header", @props.className),
            @props.children
