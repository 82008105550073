module.exports = do ->
   React = require("react")
   ContactFormatMixin = require("mixins/contact-format-mixin")
   {createComponent} = require("@evertrue/et-flux")
   {div, span} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   Icon = createFactory (require("components/elements/icon").default)
   Link = createFactory(require("@evertrue/et-components").Link)

   createComponent "Career",
      propTypes:
         data: ReactLibs.PropTypes.object

      render: ->
         career = @props.data

         if !(career.name || career.title || career.function || career.industry)
            div className: "career-template",
               Icon icon: "career", className: "career-template--icon"
               div className: "career-template--data is-empty",
                  "Not provided"
         else
            div className: "career-template",
               Icon icon: "career", className: "career-template--icon"

               div className: "career-template--data",
                  Link
                     className: "career-template--name fs-exclude-data"
                     href: "/company" + ContactFormatMixin.company_link(null, career)
                     title: "#{career.name}"
                     "data-refer": "person"
                     career.name

                  div className: "career-template--title",
                     span className: "fs-exclude-data", career.title
                  div className: "career-template--function",
                     span className: "fs-exclude-data", career.function

                  div null,
                     Link
                        className: "fs-exclude-data"
                        href: "/industry#{ContactFormatMixin.industry_link(career.industry)}"
                        title: "#{career.industry}"
                        "data-refer": "person"
                        career.industry
