import { useState, useContext } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeaderWithActions, ModalBody } from "@evertrue/et-components";
import EverTrue from "app";
import Api from "entities/helpers/api";
import { GlobalContactSearchContext } from "apps/layout/controllers/global-contact-search-context";
import FormField from "components/forms/form-field";
import { SEARCH_TYPES, BOOKMARK_TYPES } from "apps/search/utils/search-helper";

const BookMarkSearchModal = ({ isOpen, onClose, type }) => {
  const [searchName, setSearchName] = useState("");
  const { contactFilters, setModified } = useContext(GlobalContactSearchContext);

  const createSearch = async () => {
    try {
      const resp = await Api.HUB.SEARCH_CREATE.post({
        data: JSON.stringify({
          type: SEARCH_TYPES.BOOKMARKED,
          name: searchName,
          dsl_criteria: contactFilters,
        }),
      });

      EverTrue.track.set("filter_v2_action", {
        type: "bookmark_create",
        create_type: type,
      });

      return resp;
    } catch {
      EverTrue.Alert.error("Problem bookmarking your search, please try again later");
    }
  };

  return (
    <Modal isOpen={isOpen} closeModal={onClose}>
      <ModalHeaderWithActions
        title="Bookmark Search"
        closeModal={onClose}
        onSubmit={async () => {
          const resp = await createSearch();
          setModified(false);

          if (resp.id) {
            EverTrue.Navigator(`search/${resp.id}`);
            EverTrue.Alert.success("Bookmark Search Created Successfully");
          } else {
            EverTrue.Alert.error("Problem bookmarking your search, please try again later");
          }
        }}
        disableSubmit={searchName === ""}
      />
      <ModalBody>
        <FormField label="Search Name" inlineLabel={false}>
          <input
            aria-label="Search Name"
            type="text"
            value={searchName}
            placeholder="Search Name"
            onChange={(e) => setSearchName(e.target.value)}
            maxLength="100"
          />
        </FormField>
      </ModalBody>
    </Modal>
  );
};

BookMarkSearchModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  type: PropTypes.oneOf(Object.values(BOOKMARK_TYPES)).isRequired,
};

export default BookMarkSearchModal;
