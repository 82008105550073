module.exports = do ->
   _ = require("underscore").default
   Backbone = require("backbone")
   React = require("react")
   Utils = require("base/utils") # need so underscore mixins work
   Wreqr = require("backbone.wreqr")
   config = require("config/env")
   Store = require("store")
   Navigator = require("base/navigator")
   moment = require("moment")


   window.EverTrue = EverTrue =
      vent: new Backbone.Wreqr.EventAggregator()
      commands: new Backbone.Wreqr.Commands()
      reqres: new Backbone.Wreqr.RequestResponse()
      execute: (args...) -> @commands.execute(args...)
      request: (args...) -> @reqres.request(args)
      auth: {isRenewing: false, lids_token: undefined}
      Navigator: Navigator
      moment: moment
      testOidSwtich: (oid, optional_url, clear_local_storage) ->
         url = optional_url || window.location.href
         redirect = url + (if oid then if _.contains(url, "?") then "&" else "?") + (if oid then "oid=#{oid}" else "")
         if clear_local_storage
            localStorage.clear()
            EverTrue.Cookies.clear("gt_oid")
         window.location = redirect

      PropTypes:
         contactObject: (data) ->
            if !_.isEmpty(data)
               unless _.has(data, "customs") || _.has(data, "avatar") || _.has(data, "lids")
                  return new Error("Contact object should be wrapped with Decorator.Contacts.parse.")

   # Setup config object, loads here instead of setup because of async local_env request

   EverTrue.config = config.configure (conf) ->
      if conf.error_logging
         Raven?.config(conf.sentryPath, {
            environment: conf.base_environment
            tags: {data_environment: conf.environment}
            autoBreadcrumbs: {xhr: true, console: true, dom: true, location: true}
            breadcrumbCallback: (crumb = {}) ->
               {type, category, data} = crumb

               if type is "http" and category is "xhr" and (url = data?.url)
                  if url.indexOf("//api.mixpanel.com") > 0 then return false
                  if url.indexOf("//r.fullstory.com") > 0 then return false
                  if url.indexOf("//mnvchxx6cghv.statuspage.io") > 0 then return false

               return crumb

         })?.install()

   # TODO: Refactor w/ Flux
   EverTrue.WINDOW = "#layout-page"
   EverTrue.DOCUMENT = "#layout-page-wrapper"

   # Add Support to Backbone Classes for adding Mixins
   include = (mixins) ->
      throw new Error("include(mixins...) requires at least one mixin") unless mixins and mixins.length > 0

      _.each mixins, (mixin) =>
         _.each mixin, (value, key) =>
            @prototype[key] = value unless key is "included"

         include = mixin.included
         if (include) then include.apply(@)

   Backbone.Model.include = Backbone.Collection.include = include
   Backbone.View.include = Backbone.Router.include = include

   # Setup Global Mixin for Using events in React Components
   EverTrue.BackboneReactEvents =
      componentWillUnmount: -> @stopListening()
   _.extend(EverTrue.BackboneReactEvents, Backbone.Events)


   # Browser Version For Mixpanel
   navigator.sayswho = ->
      ua = navigator.userAgent
      tem = undefined
      M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []

      if /trident/i.test(M[1])
         tem = /\brv[ :]+(\d+)/g.exec(ua) || []
         return "IE " + (tem[1] || "")
      if M[1] is "Chrome"
         tem = ua.match(/\bOPR\/(\d+)/)
         return "Opera " + tem[1]  if tem?
      M = (if M[2] then [M[1], M[2]] else [navigator.appName, navigator.appVersion, "-?"])
      M.splice 1, 1, tem[1]  if (tem = ua.match(/version\/(\d+)/i))?
      M.join " "

   EverTrue.store = new Store()

   # Return EverTrue App Object
   EverTrue
