module.exports = do ->

   _ = require("underscore").default
   React = require("react")
   EverTrue = require("app")
   {createComponent} = require("@evertrue/et-flux")
   User = require("entities/auth/user")
   LoadingMixin = require("mixins/loading-mixin")
   UserStore = require("apps/users/stores/user-store")
   {h3, div, hr, i, span} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   PagedColumnTable = createFactory require("components/tables/paged-col-table")
   SearchBox = createFactory require("components/forms/search-box")
   Button = createFactory(require("@evertrue/et-components").Button)

   UserSource = require("base/user/user-source")

   createComponent "ImpersonationView",
      mixins: [LoadingMixin]

      getInitialState: ->
         users: []
         sortData: {sortReverse: false, sortProp: "name"}
         loading: true
         is_empty: false
         search: undefined

      headerConfig: [
         {label: "Name", prop: "name", row: "name"}
         {label: "Email", prop: "email", row: "email"}
         {label: "", prop: undefined, row: "impersonate", width: 145}
      ]

      componentDidMount: ->
         UserStore.initialize()
         UserStore.bindToUpdates (users) =>
            if @depracated_is_mounted
               filtered_users = _.reject (users.items || []), (user) ->
                  user.id == EverTrue.store.user.get("id")

               @setState
                  users: filtered_users
                  loading: false
                  is_empty: (filtered_users?.length == 0)

      componentWillUnmount: ->
         UserStore.unbind()

      closeModal: ->
         if _.isFunction @props.requestHide then @props.requestHide()

      searchUsers: ->
         @setState({loading: true})

         UserStore.getUsers
            limit: 10
            search_query:
               search: @state.search
               giving_tree_roles: true

      handleRowClick: (id, evnt) ->
         evnt.preventDefault()
         @closeModal()

         return unless EverTrue.store.user.isSuperUser()
         impersonate_user = new User()
         impersonate_user.set("id", id)
         impersonate_user.fetch
            success: =>
               @closeModal()
               UserSource.impersonate(impersonate_user)

      rows: ->
         name: (model) ->
            model.name
         email: (model) ->
            model.email
         impersonate: ->
            div null,
               i className: "fa et-icon-impersonate-user fa-lg"
               "Impersonate"

      handleKeyPress: (value, evnt) ->
         if evnt.key is "Enter"
            evnt.preventDefault()
            @searchUsers()
         @setState {search: value}

      handleSort: (sort_key) ->
         users = @state.users
         reverse = false
         if @state.sortData?.sortReverse is false
            reverse = true

         users = _.sortBy(users, sort_key)
         if reverse is true
            users.reverse()

         @setState
            "sortData": {sortProp: sort_key, sortReverse: reverse}
            "users": users

      render: ->
         users = @state.users

         div className: "impersonation-modal",
            h3 className: "impersonation-modal--title impersonation-modal--wrap",
               "Impersonate User"
               span className: "fa et-icon-close fa-lg pull-right", onClick: @closeModal

            hr(null)

            div className: "impersonation-modal--wrap clearfix",
               div className: "impersonation-modal--search",
                  SearchBox
                     id: "user-search"
                     placeholder: "Search EverTrue users by name..."
                     onKeyUp: @handleKeyPress

               Button className: "pull-right", onClick: @searchUsers,
                  "Search Users"

            if !users.length && !@state.is_empty
               div className: "impersonation-modal--empty",
                  "Search to find an EverTrue user to impersonate."

            if !users.length && @state.is_empty
               div className: "impersonation-modal--empty",
                  "No results found."

            if users.length
               div className: "impersonation-modal--wrap",
                  div className: "module loading-container",
                     @loading(@state.loading)

                     PagedColumnTable
                        className: "impersonation-modal--table contact-list"
                        data: users
                        columns: @headerConfig
                        sortable: true
                        onSort: @handleSort
                        sortData: @state.sortData
                        rows: @rows
                        onRowClick: @handleRowClick
                        syncing: @state.loading
