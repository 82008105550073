_ = require("underscore").default
React = require("react")
{div, span, a} = ReactLibs.DOMFactories
{createComponent} = require("@evertrue/et-flux")
Decorator = require("clientDecorator")
{object, func, any, string} = ReactLibs.PropTypes
EngagementCard = require("apps/profile/components/engagement-card")
EngagementIcon = require("apps/profile/components/engagement-icon")
{createFactory} = require("base/new-utils")
ModalTrigger = createFactory require("components/modals/modal-trigger")
ActivityHelpers = require("apps/profile/components/activity/activity-helpers")
ContactResolver = require("apps/profile/components/activity/contact-resolver")
Link = createFactory(require("@evertrue/et-components").Link)
LinkButton = createFactory(require("@evertrue/et-components").LinkButton)

formatDelimited = (list = []) ->
   _.map(list, (item = "") -> item.split(";").join("; "))

bold = (items...) ->
   span className: "text-bold", items...

module.exports =
   createComponent "GiftFeedItem",
      propTypes:
         gift: object
         onContactNameClick: func
         contactNameModal: any
         contactNameLink: string
         onAmountClick: func
         amountModal: any
         amountLink: string


      getDefaultProps: ->
         gift: {}

      renderName: ->
         displayName =
            ContactResolver
               contact_id: @props.gift.contact_id
               render: ({parsed_contact}) =>
                  if parsed_contact.id
                     name = Decorator.Contacts.getFullName(parsed_contact)?.trim?()
                  else name = @props.gift.donor_name
                  span className: "fs-exclude-data", name || "A Constituent"

         if @props.onContactNameClick
            LinkButton title: "constituent", onClick: @props.onContactNameClick,
               displayName

         else if @props.contactNameLink
            Link title: "constituent", href: @props.contactNameLink, displayName

         else if @props.contactNameModal
            ModalTrigger(modal: @props.contactNameModal, title: displayName,
               span(null, displayName)
            )

         else displayName

      renderAmount: ->
         amount = a className: "fs-exclude-data",
            ActivityHelpers.formatGiftAmount(@props.gift.amount)

         if @props.onAmountClick
            LinkButton title: "gift", onClick: @props.onAmountClick,
               amount

         else if @props.amountLink
            Link title: "gift", href: @props.amountLink, amount

         else if @props.amountModal
            ModalTrigger(modal: @props.amountModal, title: amount, amount)

         else bold amount

      renderDesignations: ->
         list = formatDelimited(@props.gift.designations)
         count = _.size(list)

         if count is 0 then null
         else if count is 1
            span null, " to ", span className: "fs-exclude-data", bold list[0]
         else if count is 2
            span null,
               " to "
               span className: "fs-exclude-data", bold(list[0])
               " and "
               span className: "fs-exclude-data", bold(list[1])
         else
            span null,
               " to "
               span className: "fs-exclude-data", bold(list[0])
               " and #{count - 1} other designations"


      renderTitle: ->
         { type } = @props.gift

         div null,
            @renderName()
            " gave "
            @renderAmount()

            @renderDesignations()
            ". "
            if display_type = ActivityHelpers.formatGiftType(type)
               span className: "text-light", " (" + display_type + ")"

      render: ->
         icon = EngagementIcon
            size: "40px"
            icon: "gift"

         EngagementCard
            className: "engagement-card-event"
            title: @renderTitle()
            date: @props.gift.occurred_at
            icon: icon
