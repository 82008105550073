import _ from "underscore";
import { createContext, Component } from "react";
import PropTypes from "prop-types";
import Api from "entities/helpers/api";

const { Consumer, Provider } = createContext();

const fetchEngagements = contact_id => {
  return Api.EVENTS.ENGAGEMENTS.post({
    params: { limit: 500 },
    data: _.jsonStringify({
      must: [
        {
          "event.source": { match: "eventbrite" },
        },
        {
          contact_id: { match: contact_id },
        },
      ],
    }),
  });
};

const parseEngagements = items => {
  const items_by_email = _.groupBy(_.filter(items, "email"), ({ email }) => email.toLowerCase());

  return _.mapObject(items_by_email, items => ({
    last_activity: _.max(_.pluck(items, "engaged_at")) || NaN,
    count: _.size(items),
  }));
};

const fetchProfiles = emails => {
  return Api.EVENTS.EVENTBRITE_PROFILES.get({
    params: { email: _.map(emails, encodeURIComponent) },
  });
};

const mergeInProfiles = (info, profiles) => {
  return _.mapObject(info, (val, email) => {
    return { ...val, profile: _.findWhere(profiles, { email }) };
  });
};

const getDefaults = () => ({
  engagementStatsByEmail: {},
  has_eventbrite_match: false,
  engagements: [],
  profiles: [],
  loading: false,
  error: false,
});

class EventbriteMatchFetcher extends Component {
  static propTypes = {
    contact_id: PropTypes.number,
    has_eventbrite: PropTypes.bool,
    children: PropTypes.any,
  };

  state = { ...getDefaults(), refresh: _.noop };

  componentDidMount() {
    this.setState({
      refresh: this.fetch.bind(this),
      _testError: this._testError.bind(this),
    });

    this.fetch();
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  componentDidUpdate(prevProps) {
    if (this.props.contact_id !== prevProps.contact_id || this.props.has_eventbrite !== prevProps.has_eventbrite) {
      this.fetch();
    }
  }
  _testError() {
    this.setState({ error: "error!" });
  }

  fetch() {
    const contact_id = this.props.contact_id;

    if (contact_id && this.props.has_eventbrite) {
      this.setState({ loading: true, error: false });

      fetchEngagements(contact_id)
        .then(resp => {
          const engagementStatsByEmail = parseEngagements(resp.items);
          return {
            engagementStatsByEmail,
            has_eventbrite_match: _.notEmpty(engagementStatsByEmail),
            engagements: resp.items,
          };
        })
        .then(info => {
          return fetchProfiles(_.keys(info.engagementStatsByEmail)).then(resp => {
            return {
              ...info,
              engagementStatsByEmail: mergeInProfiles(info.engagementStatsByEmail, resp.items),
              profiles: resp.items,
            };
          });
        })

        .then(info => {
          this.save(contact_id, { ...info, loading: false, error: false });
        })
        .catch(err => {
          this.save(contact_id, {
            engagementStatsByEmail: {},
            has_eventbrite_match: false,
            profiles: [],
            engagements: [],
            loading: false,
            error: err,
          });
        });
    } else {
      this.setState(getDefaults());
    }
  }

  save(contact_id, values) {
    if (!this.unmounted && contact_id === this.props.contact_id) {
      this.setState(state => values);
    }
  }

  render() {
    return (
      <Provider value={this.state}>
        {typeof this.props.children === "function" ? this.props.children(this.state) : this.props.children}
      </Provider>
    );
  }
}

const Value = props => <Consumer {...props} />;

export { Value };

export default EventbriteMatchFetcher;
