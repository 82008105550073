import EverTrue from "app";
import { createContext, useState } from "react";
import Api from "entities/helpers/api";
import _ from "lodash";
import PropTypes from "prop-types";
import ErrorLogger from "entities/helpers/error-log-helper";

const EmailTemplatesContext = createContext(() => {});

const EmailTemplatesProvider = ({ children }) => {
  const LIMIT = 50;
  const [loading, setLoading] = useState(false);

  const [templates, setTemplates] = useState([]);
  const [total, setTotal] = useState(0);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [showEditView, setShowEditView] = useState(false);

  const getEmailTemplates = async (offset = 0, limit) => {
    try {
      const res = await Api.OUTREACH.EMAIL_TEMPLATES.get({
        params: {
          offset,
          limit,
        },
      });
      return res;
    } catch (e) {
      ErrorLogger.error("Error getting Email Templates", { extra: { e, page_name: "Email Templates Modal" } });
      return EverTrue.Alert.error("There was a problem getting Email Templates.");
    }
  };

  const createEmailTemplate = async (template) => {
    setLoading(true);
    try {
      const newTemplate = await Api.OUTREACH.EMAIL_TEMPLATES.post({
        data: JSON.stringify(template),
      });
      EverTrue.Alert.success("Email Template created successfully");
      return newTemplate;
    } catch (e) {
      ErrorLogger.error("Error creating Email Template", { extra: { e, page_name: "Email Templates Create Modal" } });
    } finally {
      setLoading(false);
    }
  };

  const updateEmailTemplate = async (
    templateId,
    oid,
    userId,
    { templateName, templateSubject, templateBody, dynamicTemplate }
  ) => {
    setLoading(true);
    try {
      const updatedTemplate = await Api.OUTREACH.EMAIL_TEMPLATE.put({
        urlArgs: { id: templateId },
        data: JSON.stringify({
          templateName,
          templateSubject,
          templateBody,
          dynamicTemplate,
          oid,
          userId,
        }),
      });
      EverTrue.Alert.success("Email Template updated successfully");
      return updatedTemplate;
    } catch (e) {
      ErrorLogger.error("Error updating Email Template", { extra: { e, page_name: "Email Templates Update Modal" } });
    } finally {
      setLoading(false);
    }
  };

  const deleteEmailTemplate = async (id) => {
    setLoading(true);
    try {
      await Api.OUTREACH.EMAIL_TEMPLATE.delete({
        urlArgs: { id },
      });
      const updatedTemplates = templates.filter((template) => template.id !== id);
      setTemplates(updatedTemplates);
      setTotal(total - 1);
      if (id === selectedTemplate.id) {
        setSelectedTemplate(updatedTemplates[0] || {});
      }
      EverTrue.Alert.success("Email Template deleted successfully");
    } catch (e) {
      ErrorLogger.error("Error deleting Email Template", { extra: { e, page_name: "Email Templates Modal" } });
      return EverTrue.Alert.error("There was a problem deleting the Email Template.");
    } finally {
      setLoading(false);
    }
  };

  const searchEmailTemplates = async (searchParam) => {
    setLoading(true);
    try {
      const res = await Api.OUTREACH.EMAIL_TEMPLATES_SEARCH.get({
        params: { template_name: searchParam },
      });
      return res;
    } catch (e) {
      ErrorLogger.error("Error searching Email Templates", { extra: { e, page_name: "Email Templates Modal" } });
      return EverTrue.Alert.error("There was a problem searching Email Templates.");
    } finally {
      setLoading(false);
    }
  };

  const getAndSetTemplates = async (offset, resetOffset) => {
    setLoading(true);
    try {
      const { items = [], total } = await getEmailTemplates(offset, LIMIT);
      setTotal(total);
      if (offset === 0) {
        setTemplates(items);
        if (resetOffset) resetOffset();
      } else {
        setTemplates((prevTemplates) => [...prevTemplates, ...items]);
      }
      if (_.isEmpty(selectedTemplate)) {
        setSelectedTemplate(items[0] || {});
      }
    } catch (e) {
      ErrorLogger.error("Error getting Email Templates", { extra: { e, page_name: "Email Templates Modal" } });
      return EverTrue.Alert.error("There was a problem getting Email Templates.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <EmailTemplatesContext.Provider
      value={{
        templates,
        setTemplates,
        total,
        setTotal,
        selectedTemplate,
        setSelectedTemplate,
        getAndSetTemplates,
        loading,
        showEditView,
        setShowEditView,
        createEmailTemplate,
        updateEmailTemplate,
        deleteEmailTemplate,
        searchEmailTemplates,
      }}
    >
      {children}
    </EmailTemplatesContext.Provider>
  );
};

EmailTemplatesProvider.propTypes = { children: PropTypes.any };

export { EmailTemplatesProvider, EmailTemplatesContext };
