import Icon from "components/elements/icon";
import PropTypes from "prop-types";

const ReportingChartLegend = ({ completedTasks, skippedTasks, overdueTasks }) => {

  const completed_percent = completedTasks / (completedTasks + skippedTasks + overdueTasks) * 100;
  const skipped_percent = skippedTasks / (completedTasks + skippedTasks + overdueTasks) * 100;
  const overdue_percent = 100 - completed_percent - skipped_percent;

  return (
    <div className="reporting--legend-wrapper">
      <div className="reporting--legend-item">
        <div className="reporting--legend-item--label">
          <Icon
            icon="legend"
            iconKit="gt2"
            className="reporting--legend-icon--completed"
            size={2}
          />
          Completed tasks
        </div>
        <span><span className="reporting--legend-text-bold">{completedTasks} Tasks</span> ({!isNaN(completed_percent) ? completed_percent.toFixed(1) : "-"}%)</span>
      </div>
      <div className="reporting--legend-item">
        <div className="reporting--legend-item--label">
          <Icon
            icon="legend"
            iconKit="gt2"
            className="reporting--legend-icon--skipped"
            size={2}
          />
          Skipped tasks
        </div>
        <span><span className="reporting--legend-text-bold">{skippedTasks} Tasks</span> ({!isNaN(skipped_percent) ? skipped_percent.toFixed(1) : "-"}%)</span>
      </div>
      <div className="reporting--legend-item">
        <div className="reporting--legend-item--label">
          <Icon
            icon="legend"
            iconKit="gt2"
            className="reporting--legend-icon--overdue"
            size={2}
          />
          Overdue tasks that are incomplete
        </div>
        <span><span className="reporting--legend-text-bold">{overdueTasks} Tasks</span> ({!isNaN(overdue_percent) ? overdue_percent.toFixed(1) : "-"}%)</span>
      </div>
    </div>
  );
};

ReportingChartLegend.propTypes = {
  completedTasks: PropTypes.number,
  skippedTasks: PropTypes.number,
  overdueTasks: PropTypes.number,
};

export default ReportingChartLegend;
