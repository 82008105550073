import PropTypes from "prop-types";
import SideBar from "components/animations/side-bar";

const JourneySideBarConntainer = ({
  activeSideBarConstituentsList,
  viewSideBar,
  handleCloseSideBar,
  selectedJourney,
}) => {
  return (
    <>
      <SideBar
        className="active-constituent-side-bar"
        start={viewSideBar}
        speed={5000}
        mountOnEnter={true}
        unmountOnExit={true}
        enableEnterAnimations={true}
        enableExitAnimations={true}
        enableAppearAnimations={true}
        closeSideBar={() => handleCloseSideBar()}
      >
        <div className="journey-drawer-container">
          <strong className="journey-drawer-header">{selectedJourney.name}</strong>
          <div className="journey-drawer-active-constituents">
            {selectedJourney.active_constituents} Active Constituents
          </div>
          <table className="journey-drawer-table">
            {activeSideBarConstituentsList.map((constituent) => {
              return (
                <td className="journey-drawer-row" key={`${constituent}`}>
                  {constituent}
                </td>
              );
            })}
          </table>
        </div>
      </SideBar>
    </>
  );
};

JourneySideBarConntainer.propTypes = {
  selectedJourney: PropTypes.object,
  activeSideBarConstituentsList: PropTypes.array,
  viewSideBar: PropTypes.bool,
  handleCloseSideBar: PropTypes.func,
};

export default JourneySideBarConntainer;
