module.exports = do ->
   _ = require("underscore").default
   {span, strong} = ReactLibs.DOMFactories
   numeral = require("numeral")
   moment = require("moment")

   DATE_FORMAT = "MMM DD, YYYY"

   FacebookSearchSummary = ReactLibs.createClass
      propTypes:
         query: ReactLibs.PropTypes.string
         filter_pages: ReactLibs.PropTypes.array
         pages: ReactLibs.PropTypes.array
         items: ReactLibs.PropTypes.any
         date: ReactLibs.PropTypes.object

      getDefaultProps: ->
         total: 0
         query: ""
         filter_pages: []
         pages: []
         items: "Results"

      render: ->

         if @props.page_filters.length > 1
            pages = "#{@props.page_filters.length} Selected Pages"
         else if @props.page_filters.length is 1
            pages = _.findWhere(@props.pages, value: @props.page_filters[0])?.label
         else
            pages = "All Facebook Pages"

         query = @props.query.split(" ").join("\" and \"")

         total = numeral(@props.total || 0).format("0,0")

         span className: "facebook-search-summary",
            strong(null, "#{total} ")
            @props.items
            if @props.query then " containing "
            if @props.query then strong(null, "\"#{query}\"")
            " on "
            strong(null, pages)
            if @props.date
               span null,
                  " between "
                  strong null, "#{moment(@props.date.date_from).format(DATE_FORMAT)} -
                  #{moment(@props.date.date_to).format(DATE_FORMAT)}"
