import { connect } from "@evertrue/et-flux";
import _ from "underscore";
import { Component } from "react";
import EverTrue from "app";
import Api from "entities/helpers/api";
import ReactLibs from "base/react-libs";
import BackHeader from "components/elements/back-header";
import Module from "components/module";
import EmptyStateMessage from "components/lists/empty-state-message";
import FacebookSource from "apps/facebook/sources/facebook-source";
import FacebookPagesStore from "apps/facebook/stores/facebook-pages-store";
import FacebookAccountsTable from "apps/facebook/components/facebook-accounts-table";
import Loading from "components/elements/loading";
import AllUsersStore from "apps/users/stores/all-users-store";
import { Tooltip2, LinkButton } from "@evertrue/et-components";
import IdentityResolver from "base/identity-resolver";

const mapStateToProps = () => ({
  pages: FacebookPagesStore.getPages(),
  loading: FacebookPagesStore.getLoading(),
  users: AllUsersStore.getAllUsers(),
});

class FacebookAccountsController extends Component {
  static propTypes = {
    pages: ReactLibs.PropTypes.array,
    users: ReactLibs.PropTypes.object,
    loading: ReactLibs.PropTypes.bool,
  };

  static defaultProps = {
    pages: [],
    users: {},
    loading: false,
  };

  state = {
    has_facebook_identities: false,
  };

  componentDidMount() {
    this.fetchFacebookToken();
    FacebookSource.fetchPages({ include_warming: true });
    const error = EverTrue.UrlManager.get("error");
    const error_code = EverTrue.UrlManager.get("error_code");
    if (error && error_code) {
      EverTrue.Alert.warn(error, { timeout: 8000 });
      EverTrue.UrlManager.unset("error");
      EverTrue.UrlManager.unset("error_code");
    }
  }

  fetchFacebookToken() {
    Api.AUTH.HAS_FB_IDENTITIES.get({
      success: ({ has_facebook_identities }) => {
        this.setState({ has_facebook_identities });
      },
      error: () => {
        EverTrue.Alert.error("There was an error obtaining a Facebook token. Please try again later");
        this.setState({ has_facebook_identities: false });
      },
    });
  }

  handleConnect = () => {
    FacebookSource.startOauth();
  };

  handleDisconnect = id => {
    Api.AUTH.REMOVE_FB_TOKEN.delete({
      urlArgs: { id },
      success: () => {
        EverTrue.Alert.success("Successfully disconnected Facebook account");
        this.fetchFacebookToken();
      },
      error: () => {
        EverTrue.Alert.error("Sorry, there was a problem disconnected your Facebook account. Please try again later");
        this.fetchFacebookToken();
      },
    });
  };

  handleBack() {
    EverTrue.Navigator("/facebook/pages");
  }

  renderAction() {
    return (
      <div className="facebook-accounts--btn" onClick={this.handleConnect}>
        <Tooltip2
          trigger={<img src="/images/connect_with_fb.png" width="191px" height="32px" alt="connect with facebook" />}
        >
          Connect your Facebook page admin accounts through Facebook’s login flow.
        </Tooltip2>
      </div>
    );
  }

  render() {
    return (
      <div className="facebook-accounts-controller">
        <BackHeader handleBack={this.handleBack}>Manage Facebook Accounts</BackHeader>
        <div className="facebook-accounts-controller--body">
          {this.props.loading && <Loading />}
          {_.isEmpty(this.props.pages) ? (
            <Module>
              <EmptyStateMessage icon="facebook icon-facebook-brand" text="Facebook Pages">
                Connect your Facebook account to EverTrue so you can track constituent engagement, including likes,
                comments and reactions.
                <div className="empty-state-message--action-area">{this.renderAction()}</div>
              </EmptyStateMessage>
            </Module>
          ) : (
            <Module icon="facebook-circle" header="Facebook Accounts" additional_header_content={this.renderAction()}>
              <div className="facebook-accounts--text">
                The pages listed here have been added for your organization.
                {
                  " A Page Admin’s facebook account must be connected to EverTrue in order to track constituent engagement data for these pages."
                }
              </div>
              <FacebookAccountsTable pages={this.props.pages} users={this.props.users} />
              <IdentityResolver>
                {({ user }) =>
                  this.state.has_facebook_identities ? (
                    <LinkButton
                      className="facebook-accounts--disconnect"
                      onClick={() => this.handleDisconnect(user.id)}
                      title="Disconnect the Facebook Account"
                    >
                      Disconnect Facebook Account
                    </LinkButton>
                  ) : null
                }
              </IdentityResolver>
            </Module>
          )}
        </div>
      </div>
    );
  }
}

export default connect(
  FacebookAccountsController,
  [FacebookPagesStore, AllUsersStore],
  mapStateToProps
);
