import PropTypes from "prop-types";
import ProfileCardHeader from "apps/profile/components/profile-card-header";
import PhoneTemplate from "apps/profile/components/templates/phone";
import EmailTemplate from "apps/profile/components/templates/email";
import { ModalConfirm, ModalTrigger2, LinkButton } from "@evertrue/et-components";
import EmptyStateMessage from "components/lists/empty-state-message";

const renderViewAll = (phones, emails) => (
  <ModalTrigger2
    trigger={({ open }) => {
      return (
        <LinkButton className="profile-enrichment--more" title={`View all contact information`} onClick={open}>
          View All
        </LinkButton>
      );
    }}
  >
    {({ close }) => {
      return (
        <ModalConfirm header="Additional Contact Information" onCancel={close} style={{ width: 500 }}>
          <div className="profile-enrichment--more-modal">
            {phones.map(phone => {
              return <PhoneTemplate key={phone.phone} data={phone} />;
            })}
            {emails.map(email => {
              return <EmailTemplate key={email.email} data={email} />;
            })}
          </div>
        </ModalConfirm>
      );
    }}
  </ModalTrigger2>
);

const ContactInfoEnrichment = ({ phones = [], emails = [] }) => {
  return (
    <>
      <ProfileCardHeader title="Additional Contact Info">
        {phones.length > 2 || emails.length > 2 ? renderViewAll(phones, emails) : null}
      </ProfileCardHeader>
      <div className="profile--enrichment-inner">
        {phones.concat(emails).length > 0 ? (
          <>
            {phones.slice(0, 2).map(phone => (
              <PhoneTemplate key={phone.phone} data={phone} />
            ))}
            {emails.slice(0, 2).map(email => (
              <EmailTemplate key={email.email} data={email} />
            ))}
          </>
        ) : (
          <EmptyStateMessage icon="proposal" text="Contact Info was unable to be found" />
        )}
      </div>
    </>
  );
};

ContactInfoEnrichment.propTypes = {
  phones: PropTypes.array,
  emails: PropTypes.array,
};
export default ContactInfoEnrichment;
