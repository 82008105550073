_ = require("underscore").default
Query = require("entities/search/query-parts/query")
Parameter = require("entities/search/query-parts/parameter-query")
Property = require("entities/search/query-parts/property-query")
ChildDoc = require("entities/search/query-parts/child-doc-query")

INTERESTED_ACTIONS = []
OPTIONS = []

_get = (val) -> val?.value

_getListValues = (value) -> _.compact _.map(value, _get)

# generating query

_getDesignationQuery = (value) ->
   designations = _getListValues(value["designations"])

   if _.notEmpty(designations)
      Property "designations.untouched", designations, {type: "contains"}

_getDesignationCategoryQuery = (value) ->
   designation_categories = _getListValues(value["designation_categories"])

   if _.notEmpty(designation_categories)
      Property "designation_categories.untouched", designation_categories, {type: "contains"}

_getCampaignCreditQuery = (value) ->
   campaigns = _getListValues(value["campaigns"])

   if _.notEmpty(campaigns)
      Property "campaigns.untouched", campaigns, {type: "contains"}


_getGiftDateQuery = ({gift_date}) ->
   if gift_date
      Property "occurred_at", gift_date, {type: "object"}

_getGiftAmountQuery = (value) ->
   if _.notEmpty(value.amount)
      Property "amount", value.amount, {type: "coerce"}


getQuery = (value = {}) ->
   Query [ Parameter value.param, [
      ChildDoc "gift", Query [
         Parameter "must", _.compact [
            _getDesignationQuery(value)
            _getDesignationCategoryQuery(value)
            _getCampaignCreditQuery(value)
            _getGiftDateQuery(value)
            _getGiftAmountQuery(value)
         ]
      ]
   ]]

# criteria parsing

_getParamFromDate = (date) ->
   _.find(OPTIONS, ({value}) -> _.isEqual(date, value))?.key

_getValueByKey = (must, key) ->
   _.find(must, (prop) -> prop[key]?)?[key]

parseCriteria = (criteria) ->
   {parameter, value} = criteria.getAll()?[0] || {}
   gift_must = value?[0]?.query?.must

   filters = param: parameter

   _.each gift_must, (property) ->
      key = _.keys(property)?[0]
      value = _.values(property)?[0]

      switch key
         when "occurred_at"
            if _.notEmpty(value) then filters.gift_date = value

         when "designations.untouched"
            vals = _.uniq(value["in"])
            if _.notEmpty(vals)
               filters.designations = _.map vals, (val) -> label: val, value: val

         when "designation_categories.untouched"
            vals = _.uniq(value["in"])
            if _.notEmpty(vals)
               filters.designation_categories = _.map vals, (val) -> label: val, value: val

         when "campaigns.untouched"
            vals = _.uniq(value["in"])
            if _.notEmpty(vals)
               filters.campaigns = _.map vals, (val) -> label: val, value: val

         when "amount"
            if _.notEmpty(value) then filters.amount = value

   return filters

GiftTransactionsFiltersUtils = {getQuery, parseCriteria}

module.exports = GiftTransactionsFiltersUtils

