import * as Yup from "yup";

export const USER_VALIDATION_SCHEMA = Yup.object({
  firstName: Yup.string().required("First Name is required."),
  lastName: Yup.string().required("Last Name is required."),
  emailAddress: Yup.string()
    .email("Valid email format is required.")
    .required("Email Address is required."),
  classification: Yup.object({
    label: Yup.string().required("Classification is required."),
    value: Yup.string().required("Classification is required."),
  }).required("Classification is required."),
  userRole: Yup.object({
    label: Yup.string().required("Role is required."),
    value: Yup.string().required("Role is required."),
  }).required("Role is required."),
  jobTitle: Yup.string().required("Title is required."),
});

export const SSO_USER_VALIDATION_SCHEMA = Yup.object().shape({
  firstName: Yup.string().required("First Name is required."),
  lastName: Yup.string().required("Last Name is required."),
  emailAddress: Yup.string()
    .email("Valid email format is required.")
    .required("Email Address is required."),
  classification: Yup.object({
    label: Yup.string().required("Classification is required."),
    value: Yup.string().required("Classification is required."),
  }).required("Classification is required."),
  userRole: Yup.object({
    label: Yup.string().required("Role is required."),
    value: Yup.string().required("Role is required."),
  }).required("Role is required."),
  jobTitle: Yup.string().required("Title is required."),
  uid: Yup.string().required("UID is required."),
});
