module.exports = do ->
   _ = require("underscore").default
   {createStore} = require("@evertrue/et-flux")
   SimilarProfileSource = require("apps/profile/sources/similar-profile-source")


   createStore "SimilarProfileStore",
      getInitialState: ->
         all_similar: {} # mapping of current contact_id = [all_similar]
         donors: {} # mapping of current contact_id = [donors]
         non_donors: {} # mapping of current contact_id = [non_donors]
         loading: false

      registerActions: ->
         @on SimilarProfileSource.actions.fetchedProfiles, @respondToFetch
         @on SimilarProfileSource.actions.loading, @respondToLoading

      respondToLoading: (is_loading) ->
         @setState {loading: is_loading}

      respondToFetch: (contact_id, data) ->
         cached_all_similar = _.cloneData(@getState("all_similar"))
         cached_donors = _.cloneData(@getState("donors"))
         cached_non_donors = _.cloneData(@getState("non_donors"))

         if _.size(cached_donors) > 100
            cached_all_similar = {}
            cached_donors = {}
            cached_non_donors = {}

         grouped = _.groupBy data.items, (contact) ->
            if contact?.giving?.lifetime_amount?.value > 0 then "donors" else "non_donors"

         cached_all_similar[contact_id] = data.items
         cached_donors[contact_id] = grouped.donors
         cached_non_donors[contact_id] = grouped.non_donors

         @setState
            all_similar: cached_all_similar
            donors: cached_donors
            non_donors: cached_non_donors

      api:
         getLoading: ->
            @getState("loading")

         getAllSimilar: (contact_id) ->
            @getState("all_similar")[contact_id]

         getDonors: (contact_id) ->
            @getState("donors")[contact_id]

         getNonDonors: (contact_id) ->
            @getState("non_donors")[contact_id]

         getAll: (contact_id) ->
            _.compact _.flatten [@getDonors(contact_id), @getNonDonors(contact_id)]
