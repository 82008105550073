module.exports = do ->
   EverTrue = require("app")
   React = require("react")
   {createFactory} = require("base/new-utils")
   ContactExpandedRow = createFactory require("apps/contact/components/profile/contact-expanded-row").default
   MonthDayList = createFactory require("components/lists/month-day-list")
   {createComponent} = require("@evertrue/et-flux")
   {div, strong, i} = ReactLibs.DOMFactories
   Decorator = require("clientDecorator")
   Link = createFactory(require("@evertrue/et-components").Link)

   createComponent "CommunityInteractions",
      propTypes:
         interactions: ReactLibs.PropTypes.any.isRequired

      displayInteraction: (interaction) ->
         contact = Decorator.Contacts.parse(interaction.contact)
         name = Decorator.Contacts.getDetailedName(contact)

         return unless contact
         div null,
            div null,
               i className: "social-icon fa fa-fw et-icon-evertrue-logomark"
               Link
                  className: "fs-exclude-data"
                  title: "#{name}'s profile"
                  href: "/contact/" + contact.id
                  "data-refer": "community_interactions"
                  strong className: "contact-name",
                     name

               " used the #{EverTrue.store.org.get("name")} Community App"

            ContactExpandedRow
               data: contact
               className: "social-interaction--data fs-exclude-data"
               referrer: "community_interactions"

      render: ->
         MonthDayList
            list: @props.interactions
            displayListItem: @displayInteraction
            infiniteScroll: true
            loadMore: @props.loadMoreInteractions
