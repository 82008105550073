module.exports = do ->
   EverTrue = require("app")
   React = require("react")
   BaseRouter = require("base/base-router")
   SettingsController = require("apps/settings/user-settings-controller")
   {createFactory} = require("base/new-utils")
   InviteTeamController = createFactory(require("apps/settings/invite-team-settings/invite-team-controller").default)
   InvitationController = createFactory(require("apps/settings/manage-team-settings/invitation-controller").default)

   class UserSettingsRouter extends BaseRouter
      routes:
         "settings": "page"
         "settings/:page": "page"
         "user/manage": "manage"
         "invite": "invite"
         "invitation": "invitation"
         "settings/:profile": "profile"

      page: (page) ->
         @currentView = SettingsController(page: page)
         EverTrue.page.mount @currentView

      manage: ->
         # redirect for the legacy route user/manage
         EverTrue.Navigator "settings/manage_team", true

      invite: ->
         @currentView = InviteTeamController()
         EverTrue.page.mount @currentView

      invitation: ->
         @currentView = InvitationController()
         EverTrue.page.mount @currentView