module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   EverTrue = require("app")
   {createComponent} = require("@evertrue/et-flux")
   {g, line, text} = ReactLibs.DOMFactories
   colors = require("base/colors")


   createComponent "XAxis",
      propTypes:
         height: ReactLibs.PropTypes.number.isRequired
         ticks: ReactLibs.PropTypes.array
         format: ReactLibs.PropTypes.func
         lineStyle: ReactLibs.PropTypes.object
         axisStyle: ReactLibs.PropTypes.object
         textStyle: ReactLibs.PropTypes.object
         getCoords: ReactLibs.PropTypes.func.isRequired

      getDefaultProps: ->
         lineStyle: {stroke: colors.gray110, fill: "none"}
         axisStyle: {stroke: colors.gray110, fill: "none"}
         textStyle: {fill: colors.gray300}

      render: ->
         g className: "etchart--xaxis",
            _.map @props.ticks, (value, index) =>
               x_offset = @props.getCoords({x: value})?[0]

               g
                  className: "etchart--xaxis-section"
                  transform: "translate(#{x_offset}, 0)"
                  key: index

                  line
                     className: "etchart--xaxis-line"
                     style: if index == 0 then @props.axisStyle else @props.lineStyle
                     y2: @props.height
                     x2: 0

                  text
                     className: "etchart--xaxis-text"
                     y: @props.height + 20
                     textAnchor: "middle"
                     style: @props.textStyle

                     @props.format?(value) || value
