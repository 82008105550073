export const mapContactObjectToGraphqlShape = (contact) => {
  // changes the shape of the contact object into the graphql payload that the contact card expects
  const { addresses, name, id, properties, profile_image } = contact;
  return {
    addresses,
    contact_attributes: {
      name_full: name,
      year: properties && properties.year && properties.year.value,
    },
    id,
    profile_image: profile_image && profile_image.url,
    constituencies: [],
  };
};
