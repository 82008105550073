import { useContext } from "react";
import { PinnedCommentsContext } from "apps/profile/contexts/pinned-comments-context";
import ViewAllInteractionsModal from "./view-all-interactions-modal";
import ContactSummaryInteractionFeed from "./contact-summary-interaction-feed";

const PinnedCommentsWrapper = () => {
  const { loading, comments, error } = useContext(PinnedCommentsContext);

  return (
    <>
      <ContactSummaryInteractionFeed
        type="pinned comment"
        loading={loading}
        interactions={comments}
        error={error}
        displayLimit={3}
      />
      <ViewAllInteractionsModal type="Pinned Comment" interactions={comments} />
    </>
  );
};

export default PinnedCommentsWrapper;
