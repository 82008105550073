module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   {createSource} = require("@evertrue/et-flux")

   # TODO: This currently takes advantage of UrlHelper to work
   # with legacy architecture modules. Eventually this may be able to be cleaned up.
   createSource "UrlSource",
      actions:
         initializedUrl: (url) ->
            @require (_.isObject(url) || _.isUndefined(url)), "url data should be object or undefined"
         fetchedFilters: (filters) ->
            @require (_.isArray(filters) || _.isObject(filters) || _.isUndefined(filters)), "filters should be object, array or undefined"
         changedFilters: (filters) ->
            @require (_.isArray(filters) || _.isObject(filters) || _.isUndefined(filters)), "filters should be object, array or undefined"
         changedSegment: (id) ->
            @require (_.isNumber(id) || _.isUndefined(id) || _.isString(id)), "id should be integer or undefined"
         fetchedOperator: (operator) ->
            @require (operator == "and" || operator == "or" || !operator), "operator should be and/or or undefined"
         changedOperator: (operator) ->
            @require (operator == "and" || operator == "or" || !operator), "operator should be and/or or undefined"
         changedSort: (sort, silent) ->
            @require _.isObject(sort) || _.isUndefined(sort), "sort should be object or undefined"
            @require _.isBoolean(silent) || _.isUndefined(silent), "silent should be boolean"
         changedPage: (page) ->
            @require _.isNumber(page) || _.isUndefined(page), "page should be number or undefined"
         changedMap: (bounds) ->
            @require _.isObject(bounds) || _.isUndefined(bounds), "bounds should be number or undefined"
         reachedLimit: (url) ->
            @require _.isObject(url), "url should be object"
         changed: true
         fetched: true
         cleared: true

      initialize: ->
         return if @_initalized
         @_initalized = !!EverTrue.UrlManager

         getInitialUrlData = =>
            @actions.initializedUrl(EverTrue.UrlManager.toJSON())

         # This will probably fail on org change
         init_once = _.once(getInitialUrlData)
         _.delay ->
            init_once = _.once(getInitialUrlData)
         , 10000

         EverTrue.vent.on "org:initialized", =>
            @_initalized = !!EverTrue.UrlManager
            init_once() if @_initalized

      update: (key, value) ->
         if value
            EverTrue.UrlManager.set(key, value)
         else
            EverTrue.UrlManager.unset(key)

      api:
         fetchSegment: ->
            segment_id = EverTrue.UrlManager.get("selected_segment")
            @actions.changedSegment(parseFloat(segment_id))

         updateSegment: (segment_id) ->
            @update("selected_segment", segment_id)
            @fetchSegment()

         fetchFilters: ->
            @actions.fetchedFilters(EverTrue.UrlManager.get("filters"))

         updateFilters: (filters) ->
            EverTrue.UrlManager.set("filters", filters)
            @actions.changedFilters(EverTrue.UrlManager.get("filters"))

         fetchOperator: ->
            @actions.fetchedOperator(EverTrue.UrlManager.get("operator"))

         updateOperator: (operator) ->
            @update("operator", operator)
            @actions.changedOperator(EverTrue.UrlManager.get("operator"))

         fetchSort: (silent) ->
            @actions.changedSort(EverTrue.UrlManager.get("sort"), silent)

         updateSort: (sort, silent) ->
            EverTrue.UrlManager.set("sort", sort)
            @fetchSort(silent)

         fetchPage: (silent) ->
            @actions.changedPage(_.toNumber(EverTrue.UrlManager.get("page")), silent)

         updatePage: (page, silent) ->
            @update("page", page)
            @fetchPage(silent)

         fetchMap: ->
            @actions.changedMap(EverTrue.UrlManager.get("map"))

         updateMap: (bounds) ->
            EverTrue.UrlManager.set("map", bounds)
            @fetchMap()

         reachedLimit: (url) ->
            @actions.reachedLimit(url)

         fetch: ->
            @actions.fetched(EverTrue.UrlManager.toJSON())

         change: (data) ->
            EverTrue.UrlManager.set(data)
            @actions.changed(data)

         clear: (key) ->
            EverTrue.UrlManager.unset(key)
            @actions.cleared(key)
