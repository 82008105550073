import { createStore } from "@evertrue/et-flux";
import get from "lodash.get";
import _ from "underscore";
import VolunteersUserSource from "apps/volunteers/sources/volunteer-user-source";

export default createStore("VolunteersUserStore", {
  getInitialState() {
    return {
      volunteers_users: {},
      loading: false,
    };
  },

  registerActions() {
    this.on(VolunteersUserSource.actions.loading, is_loading => {
      this.setState({ loading: is_loading });
    });

    this.on(VolunteersUserSource.actions.fetchedUsers, (results, org_id) => {
      // not doing bulk b/c need to get most recent session for each
      const ids = _.pluck(results, "id");
      ids.forEach(id => VolunteersUserSource.fetchSessions(id, org_id));

      this.setState({ volunteers_users: { ...this.state.volunteers_users, ...results } });
    });

    this.on(VolunteersUserSource.actions.fetchIfNotCached, (ids, roles, org_id) => {
      const uncached = ids.filter(id => !this.state.volunteers_users[id]);
      if (uncached.length) VolunteersUserSource.fetch(uncached, roles, org_id);
    });

    this.on(VolunteersUserSource.actions.remove, contact_id => {
      this.setState({ volunteers_users: _.omit(this.state.volunteers_users, contact_id) });
    });
    this.on(VolunteersUserSource.actions.fetchedSessions, res => {
      const { value: user_id, count } = get(res, "facets.user_id[0]") || {};
      const last_session_time = get(res, "items[0].created_at");
      const volunteers_users = _.mapObject(
        this.state.volunteers_users,
        val => (val.id === user_id ? { ...val, session_count: count, last_session_time } : val)
      );

      this.setState({
        volunteers_users,
      });
    });
  },

  api: {
    getUsers() {
      return this.state.volunteers_users;
    },
    getLoading() {
      return this.state.loading;
    },
  },
});
