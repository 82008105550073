module.exports = do ->
   _ = require("underscore").default
   GiveContactsSource = require("apps/contact/sources/give-contacts-source")
   OrgSource = require("base/org/org-source").default
   Decorator = require("clientDecorator")
   {createStore} = require("@evertrue/et-flux")

   wrapContact = (contact = {}) ->
      _.extend received_at: Date.now(),
         if contact.id?
            name: Decorator.Contacts.getFullName(contact)
            lifetime_giving: contact.giving?.lifetime_amount?.value
            contact_record: contact
            avatar: contact.avatar
         else
            notFound: true

   makeContactsHash = (ids, contacts) ->
      _.reduce(ids, ((memo, id) ->
         _.toObject id, wrapContact(_.findWhere(contacts, id: id)), memo
      ), {})

   getDefaults = ->
      requested_ids: []
      in_flight_ids: []
      error_ids: []
      contacts: {}
      error: undefined

   createStore "GiveContactsStore",
      getInitialState: ->
         getDefaults()

      registerActions: ->
         @on OrgSource.actions.newOrg, ->
            @setState getDefaults()

         @on GiveContactsSource.actions.newRequestToLazyLoadContacts, (ids) ->
            @addToRequested(_.filter ids, @isLoadable)
            @fetchContacts()

         @on GiveContactsSource.actions.contactsLoading, (ids) ->
            @moveToInFlight(ids)
            @fetchContacts()

         @on GiveContactsSource.actions.contactsSuccess, (ids, contacts) ->
            @handleSuccess(ids)
            contacts_hash = makeContactsHash(ids, contacts)
            @mergeState "contacts", contacts_hash
            @fetchContacts()

         @on GiveContactsSource.actions.contactsError, (ids, error) ->
            @handleError(ids)
            @setState error: error
            @fetchContacts()

         @on GiveContactsSource.actions.clearContacts, ->
            @setState getDefaults()

      addToRequested: (ids) ->
         @setState requested_ids: _.uniq(@getState("requested_ids").concat(ids))

      moveToInFlight: (ids) ->
         @setState
            requested_ids: _.uniq(_.difference(@getState("requested_ids"), ids))
            in_flight_ids: _.uniq(@getState("in_flight_ids").concat(ids))

      handleSuccess: (ids) ->
         @setState
            requested_ids: _.uniq(_.difference(@getState("requested_ids"), ids))
            in_flight_ids: _.uniq(_.difference(@getState("in_flight_ids"), ids))

      handleError: (ids) ->
         @setState
            requested_ids: _.uniq(_.difference(@getState("requested_ids"), ids))
            in_flight_ids: _.uniq(_.difference(@getState("in_flight_ids"), ids))
            error_ids: _.uniq(@getState("error_ids").concat(ids))

      isLoadable: (id) ->
         not _.contains(@getState("in_flight_ids"), id) and
               not _.contains(@getState("requested_ids"), id) and
               not _.contains(@getState("error_ids"), id) and
               not @getState("contacts")[id]

      fetchContacts: (ids) ->
         requested_ids = @getState("requested_ids")
         if _.notEmpty(requested_ids) and not @isLoading()
            GiveContactsSource.fetchContactsForContactsStore _.first(requested_ids, 50)

      api:
         getContact: (id) ->
            @getState("contacts")[id]

         getContactRecord: (id)->
            @getState("contacts")[id]?.contact_record

         isLoading: ->
            _.notEmpty(@getState("in_flight_ids"))
