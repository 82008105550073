import _ from "underscore";
import { useState, useEffect, useContext } from "react";
import Api from "entities/helpers/api";
import PropTypes from "prop-types";
import { AdvancedCombobox } from "@evertrue/et-components";
import { GlobalPortfolioContext } from "../contexts/global-portfolio-context";

const TeamDropdown = ({ className, value, multiple, onChange, disabled }) => {
  const { assignmentType } = useContext(GlobalPortfolioContext);
  const [loading, setLoading] = useState(false);
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    setLoading(true);
    Api.VOLUNTEER.POOLS.get({
      params: {
        pool_type: assignmentType,
        limit: 100,
        rollups: false,
      },
    }).then((resp) => {
      const teams = resp.items || [];
      const sorted_teams = _.sortBy(teams, "name");
      setTeams(sorted_teams);
      setLoading(false);
    });
  }, [assignmentType]);

  let options = _.map(teams, (team) => {
    return { value: team.id, label: team.name };
  });

  return (
    <AdvancedCombobox
      className={className}
      options={options}
      value={value}
      placeholder="Select Team..."
      multiple={multiple}
      onChange={(selected_option) => {
        const selected_team = _.findWhere(teams, { id: selected_option.value });
        onChange(selected_team);
      }}
      loading={loading}
      disabled={disabled}
      searchable={true}
      allowChangeAll={false}
      allowUnselectAll={false}
    />
  );
};

TeamDropdown.propTypes = {
  className: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
};
export default TeamDropdown;
