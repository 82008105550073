module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   {span} = ReactLibs.DOMFactories
   {createComponent} = require("@evertrue/et-flux")

   createComponent "HighlightText",
      propTypes:
         string: ReactLibs.PropTypes.string
         query: ReactLibs.PropTypes.string
         highlightClass: ReactLibs.PropTypes.string

      getDefaultProps: ->
         highlightClass: "text-highlight"

      render: ->
         attrs = className: "highlight-component #{@props.className || ""}"
         return span(attrs, @props.string) unless @props.query

         regex = new RegExp("(#{@props.query})", "gi")
         string_pieces = @props.string?.split?(regex)

         span attrs,
            _.map string_pieces, (str, index) =>
               if str.match(regex)
                  span className: @props.highlightClass, key: index, str
               else
                  str


