module.exports = do ->
   _ = require("underscore").default
   moment = require("moment")
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   ProfileCardHeader = createFactory require("apps/profile/components/profile-card-header").default
   ProposalSearchSource = require("apps/proposals/sources/proposal-search-source")
   ProposalSearchStore = require("apps/proposals/stores/proposal-search-store")
   Loading = require("components/elements/loading")
   Link = createFactory(require("@evertrue/et-components").Link)

   createComponent "ProposalSummary",
      propTypes:
         contact: ReactLibs.PropTypes.object

      getDefaultProps: ->
         contact: {}

      registerStores: ->
         @on ProposalSearchStore, ->
            proposals: ProposalSearchStore.getProposals()
            loading: ProposalSearchStore.getLoading()

      componentDidMount: ->
         if @props.contact?.id
            ProposalSearchSource.sort("created_date", true, true)
            ProposalSearchSource.queryForContact(@props.contact.id)

      componentWillUnmount: ->
         ProposalSearchSource.setContact()

      render: ->
         proposals = @state.proposals?.items
         proposal = _.first(proposals) || {}
         proposal_amount = _.currencyFormatter(proposal.ask_amount)

         div className: "proposal-summary",

            ProfileCardHeader title: "Recent Proposal", className: "contact-profile--card-header-small",
               unless _.isEmpty(proposals)
                  Link title: "view all proposals for this contact", href: "/contact/#{@props.contact.id}/proposals",
                     "View All"

            div className: "proposal-summary--wrapper",
               if @state.loading then Loading()
               else if !_.isEmpty(proposal)
                  div null,
                     div className: "proposal-summary--amount", proposal_amount
                     div className: "text-label",
                        "Ask Amount "
                        if proposal.ask_date then "(#{moment(proposal.ask_date).format("MMM, DD YYYY")})"
                     div null,
                        Link title: "proposal #{proposal.title}", href: "proposal/#{proposal.id}", proposal.title
                     proposal.status
               else
                  div className: "proposal-summary--empty", "No current proposals"
