import { FiltersDataProvider } from "apps/query-builder/contexts/filters-data-context";
import FiltersInventoryList from "apps/filters-inventory/components/filters-inventory-list";

const FiltersInventoryController = () => {
  return (
    <FiltersDataProvider>
      <FiltersInventoryList />
    </FiltersDataProvider>
  );
};

export default FiltersInventoryController;
