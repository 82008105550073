import PropTypes from "prop-types";
import ProfileCardHeader from "apps/profile/components/profile-card-header";
import AddressCard from "apps/profile/components/address-card";
import SlideShow from "components/slideshow";
import { Icon } from "@evertrue/et-components";

const AddressSlideshow = ({ addresses = [] }) => {
  const renderSlideShow = () => {
    const slides = addresses.map((address) => ({
      content() {
        return <AddressCard address={address} />;
      },
    }));
    return <SlideShow slides={slides} />;
  };
  return (
    <>
      <ProfileCardHeader title={`Addresses (${addresses.length})`}>
        <span className="profile-enrichment--match">Machine-matched</span>
        <Icon icon="gauge" className="profile-enrichment--icon" />
      </ProfileCardHeader>
      {renderSlideShow()}
    </>
  );
};

AddressSlideshow.propTypes = {
  addresses: PropTypes.array,
};

export default AddressSlideshow;
