import { useState, useEffect, useCallback } from "react";
import ErrorLogger from "entities/helpers/error-log-helper";
import EverTrue from "app";
import moment from "moment";

const TIMEOUT = 20000;

const useGeolocation = () => {
  const [geolocationError, setGeolocationError] = useState(null);
  const [geolocation, setGeolocation] = useState(null);
  const [geolocationPermissionGranted, setGeolocationPermissionGranted] = useState(false);

  const successfulFetch = (position) => {
    const lat = position.coords.latitude;
    const lng = position.coords.longitude;
    const googleLocale = {
      lat,
      lng,
      expire_at: moment().add(12, "h").valueOf(),
    };
    EverTrue.store.push("geolocation", googleLocale, { cache: true });
    setGeolocation({ lat, lng });
  };

  const failedFetch = (err) => {
    ErrorLogger.error("Geolocation Error", { extra: { error: err } });
    setGeolocationError("There was an error with Google's Geolocation");
  };

  const fetchGeolocation = useCallback(() => {
    EverTrue.store.fetch("geolocation");
    const locationFromLocalStore = EverTrue.store.geolocation;

    setGeolocationPermissionGranted(!!navigator.geolocation);

    if (locationFromLocalStore) {
      const { lat, lng, expire_at } = locationFromLocalStore;
      if (expire_at && moment(expire_at).isBefore()) setGeolocation({ lat, lng });
      else if (geolocationPermissionGranted) {
        navigator.geolocation.getCurrentPosition(successfulFetch, failedFetch, { timeout: TIMEOUT, maximumAge: 1 });
      }
    } else if (geolocationPermissionGranted) {
      navigator.geolocation.getCurrentPosition(successfulFetch, failedFetch, { timeout: TIMEOUT, maximumAge: 1 });
    } else {
      setGeolocationError("EverTrue requires permission to use your current location.");
    }
  }, [geolocationPermissionGranted]);

  useEffect(() => fetchGeolocation(), [fetchGeolocation]);

  return {
    geolocationPermissionGranted,
    geolocationError,
    geolocation,
  };
};

export default useGeolocation;
