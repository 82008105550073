import { Component } from "react";
import EverTrue from "app";
import Composer from "react-composer";
import _ from "underscore";
import PoolsResolver from "apps/volunteers/components/pools-resolver";
import InvitesResolver from "apps/volunteers/components/invites-resolver";
import VolunteerUserResolver from "apps/volunteers/components/volunteer-user-resolver";
import VolunteerManagementTable from "apps/volunteers/components/volunteer-management-table";
import ContactsTableToolBar from "apps/contact/components/contacts/table/contact-table-toolbar";
import VolunteerManagementHeader from "apps/volunteers/components/volunteer-management-header";
import ContactsResolver from "apps/contact/components/contacts/contacts-resolver";
import { SOLICITORS_BY_NAME_QUERY } from "apps/volunteers/vms-queries";
import SolicitorAssignmentResolver from "apps/volunteers/components/solicitor-assignment-resolver";
import SelectedContactsSource from "apps/contact/sources/selected-contacts-source";
import { IconButton, ModalTrigger2, Tooltip } from "@evertrue/et-components";
import BulkInviteVolunteersModal from "apps/volunteers/components/bulk-invite-volunteers-modal";
import SelectedContactsResolver from "apps/contact/components/contacts/selected-contacts-resolver";
import { VMS_MANAGE_TABLE_LIMIT } from "apps/volunteers/constants";

class VolunteersManagementController extends Component {
  state = {
    current_page: 1,
    search_val: "",
    selected_pools: null,
    selected: [],
  };

  componentDidMount() {
    const page = _.toNumber(EverTrue.UrlManager.get("managePage"));
    if (page) {
      this.setState({ current_page: page });
    }
  }

  debounceSearch = _.debounce(search_val => {
    this.setState({
      search_val,
      current_page: 1,
    });
  }, 200);

  handlePoolChange = selected_pools => {
    this.setState({ selected_pools });
    this.handlePageChange(1);
  };

  handlePageChange = page => {
    const current_page = _.toNumber(page);
    this.setState({ current_page });
    EverTrue.UrlManager.set({ managePage: current_page });
    SelectedContactsSource.clearAllSelectedState();
  };

  render() {
    const selected_pools = _.pluck(this.state.selected_pools, "value");
    const search_query = SOLICITORS_BY_NAME_QUERY(
      this.state.search_val,
      _.isEmpty(selected_pools) ? null : selected_pools,
      "POOL"
    );

    return (
      <div>
        <Composer
          renderPropName="render"
          components={[
            <ContactsResolver
              key={1}
              params={{ limit: VMS_MANAGE_TABLE_LIMIT, offset: VMS_MANAGE_TABLE_LIMIT * (this.state.current_page - 1) }}
              query={search_query}
            />,
            // this just loads pools
            ([{ contacts }]) => <SolicitorAssignmentResolver doMountFetch cache contactIds={_.pluck(contacts, "id")} />,
            <SelectedContactsResolver />,
          ]}
        >
          {([{ contacts, total, loading }, { memberships, poolIds }, { selected }]) => {
            const contact_ids = _.pluck(contacts, "id");

            return (
              <div>
                {/* Kicks off fetches to prime the cache */}
                <InvitesResolver contactIds={contact_ids} />
                <VolunteerUserResolver cache contactIds={contact_ids} />

                <ContactsTableToolBar
                  hideTrips
                  totalResultsCount={total}
                  allowAddAll
                  showSelectedIndicator
                  hide_pagination
                  filterParams={search_query}
                  renderToolbarItems={({ allSelected, isChecked }) => {
                    if (!isChecked) return null;
                    return allSelected ? null : (
                      <ModalTrigger2
                        contentStyle={{ width: 500 }}
                        children={({ close }) => (
                          <BulkInviteVolunteersModal
                            onClose={close}
                            contacts={contacts.filter(con => selected.includes(con.id))}
                          />
                        )}
                        trigger={({ open }) => (
                          <Tooltip className="selected-items-toolbar--item" content="Invite Volunteers">
                            <IconButton size={1} icon="email" onClick={open} />
                          </Tooltip>
                        )}
                      ></ModalTrigger2>
                    );
                  }}
                  contacts={{ items: contacts }}
                >
                  <PoolsResolver
                    ids={poolIds}
                    render={({ pools }) => (
                      <VolunteerManagementHeader
                        onPoolChange={this.handlePoolChange}
                        selectedPools={this.state.selected_pools}
                        pools={pools}
                        search_val={this.state.search_val}
                        onSearchChange={this.debounceSearch}
                        contacts={contacts}
                      />
                    )}
                  />
                </ContactsTableToolBar>

                <VolunteerManagementTable
                  handlePageChange={this.handlePageChange}
                  totalContacts={total}
                  currentPage={this.state.current_page}
                  data={contacts}
                  loading={loading}
                  memberships={memberships}
                />
              </div>
            );
          }}
        </Composer>
      </div>
    );
  }
}

export default VolunteersManagementController;
