module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   EverTrue = require("app")
   {createComponent} = require("@evertrue/et-flux")
   LoadingMixin = require("mixins/loading-mixin")
   WindowSource = require("apps/layout/sources/window-source")
   CollaboratorSource = require("apps/lists/sources/collaborator-source")
   CollaboratorStore = require("apps/lists/stores/collaborator-store")
   AllUsersStore = require("apps/users/stores/all-users-store").default
   {table, textarea, div} = ReactLibs.DOMFactories
   TagInput = require("components/controls/tag-input")
   {createFactory} = require("base/new-utils")
   Module = createFactory require("components/module").default
   PagedColumnTableBody = createFactory require("components/tables/paged-col-table-body")
   UserCard = createFactory require("apps/users/components/user-card")
   IconButton = createFactory(require("@evertrue/et-components").IconButton)
   Button = createFactory(require("@evertrue/et-components").Button)
   Checkbox = createFactory(require("@evertrue/et-components").Checkbox)

   createComponent "ListShareSettings",
      mixins: [LoadingMixin]

      getInitialState: ->
         notify_enabled: true
         share_query: undefined
         tags: []

      registerStores: ->
         @on CollaboratorStore, ->
            loading: CollaboratorStore.getLoading()
            collaborators: CollaboratorStore.getCollaborators(@props.list_id)

         @on AllUsersStore, ->
            users_to_share: AllUsersStore.getAllGivingTreeUsers()
            loading_users_to_share: AllUsersStore.getLoading()

      headerConfig: [
         {label: "User", prop: "name", row: "user", width: 325, sticky: true}
         {label: "", prop: null, row: "role", key: "role"}
         {label: "", prop: null, row: "actions", width: 125, key: "action"}
      ]

      rows: ->
         user: (data) ->
            UserCard user: data.user
         role: (data) ->
            div null, if data.owner then "Owner" else "Can Edit"
         actions: (data) =>
            return if data.owner
            div null,
               IconButton
                  title: "Remove Access"
                  icon: "cancel"
                  size: 2
                  onClick: => @handleRemove(data)

      handleShare: (e) ->
         e.preventDefault()
         return if _.isEmpty(@state.tags)

         if @state.notify_enabled
            noteNode = @refs.note

         user_ids = _.pluck(@state.tags, "id")

         CollaboratorSource.share @props.list_id,
            user_ids: user_ids
            message: noteNode?.value
            skip_notification: !@refs.notify.getIsChecked()

         EverTrue.track.set "list_action", {type: "invite_collabs"}

         if noteNode? then noteNode.value = ""

         @setState {tags: []}

      handleRemove: (user) ->
         CollaboratorSource.remove(@props.list_id, user.collaborator_user_id)

         EverTrue.track.set "list_action", {type: "remove_collabs"}

         if user.collaborator_user_id == EverTrue.store.user?.get("id")
            EverTrue.Navigator("/contact", true)
            WindowSource.bodyEscape() # close modal

      handleNotifiyChange: ->
         @setState {notify_enabled: @refs.notify.getIsChecked()}

      render: ->
         users = @state.collaborators
         buttonClasses = EverTrue.classSet
            "pull-right": true

         div className: "share-settings",
            div className: "share-settings--users loading-container",
               div className: "share-settings--users-heading",
                  "Who has access?"

               div className: "share-settings--users-body loading-container",
                  @loading(@state.loading)

                  table className: "table",
                     PagedColumnTableBody
                        data: users
                        columns: @headerConfig
                        emptyMessage: "List has not been shared"
                        syncing: @state.loading
                        cells: @rows

            Module header: "Add Collaborators", icon: "fa fa-fw fa-envelope", noMargin: true,
               div className: "share-settings--invite loading-container",
                  @loading(@state.loading_users_to_share)

                  TagInput
                     ref: "invites"
                     placeholder: "Add Collaborators"
                     selected: @state.tags
                     options: _.filter @state.users_to_share, (user) =>
                        query = @state.share_query
                        return if _.findWhere(@state.collaborators, {collaborator_user_id: user.id})
                        return false if _.isEmpty(query?.trim())
                        return false if _.find @state.tags, {id: user.id}
                        _.searchCompare(user.name, query) || _.searchCompare(user.email, query)
                     onType: (query) =>
                        @setState {share_query: query}
                     onTag: (tags) =>
                        @setState {tags: tags, share_query: undefined}
                     itemContent: (user) ->
                        UserCard user: user

                  div className: "text-label",
                     "Collaborators can add or remove constituents from your list, and invite or remove other collaborators."

                  Checkbox
                     className: "share-settings--notify"
                     defaultChecked: @state.notify_enabled
                     onChange: @handleNotifiyChange
                     ref: "notify"
                     "Notify people via email"

                  if @state.notify_enabled
                     textarea
                        className: "share-settings--note"
                        placeholder: "Add a note (optional)"
                        ref: "note"

                  div className: "clearfix",
                     Button disabled: _.isEmpty(@state.tags), className: buttonClasses, onClick: @handleShare, " Share "
