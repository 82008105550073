module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   React = require("react")
   FeatureStore = require("apps/layout/stores/feature-store").default
   UserStore = require("apps/users/stores/user-store")
   OrgStore = require("base/org/org-store").default
   InviteStore = require("apps/users/stores/invite-store")
   RoleHelper = require("entities/helpers/role-helper")
   LoadingMixin = require("mixins/loading-mixin")
   {div, span} = ReactLibs.DOMFactories
   {createComponent} = require("@evertrue/et-flux")
   {createFactory} = require("base/new-utils")
   Module = createFactory require("components/module").default
   Icon = createFactory (require("components/elements/icon").default)
   ManageUsersTable = require("apps/users/components/manage-users-table")
   SearchBox =  createFactory require("components/forms/search-box")
   ButtonSelect = require("components/controls/button-select")
   Button = createFactory(require("@evertrue/et-components").Button)

   _column_keys = ["user", "role", "status", "sessions", "actions"]

   createComponent "ManageTeamController",
      mixins: [LoadingMixin]

      getInitialState: ->
         active_users: {items: []}
         invited_users: {items: []}
         loading_users: true
         loading_invites: true
         user_filter: "all"

      componentDidMount: ->
         UserStore.initialize()
         UserStore.bindToUpdates (users) =>
            @setState {active_users: users, loading_users: false} if @depracated_is_mounted
         UserStore.getAuthUsers {include_contacts: true}

         InviteStore.initialize()
         InviteStore.bindToUpdates (invites) =>
            @setState {invited_users: invites, loading_invites: false} if @depracated_is_mounted
         InviteStore.getInvites()

         @toggleGatedHeaders()

      componentDidUpdate: (prevProps, prevState) ->
         if ((!prevState.has_major_gifts and this.state.has_major_gifts) ||
           (!prevState.has_two_factor_auth and this.state.has_two_factor_auth) ||
           (!prevState.has_rm_assignments and this.state.has_rm_assignments))
              @toggleGatedHeaders()

      registerStores: ->
         @on OrgStore, ->
            org: OrgStore.getCurrentOrg() || {}

         @on FeatureStore, ->
            has_rm_assignments: FeatureStore.hasFeature("rm_assignments")
            has_major_gifts: FeatureStore.hasFeature("major_gifts")
            has_two_factor_auth: FeatureStore.hasFeature("two_factor_auth")

      handleUserSearch: (query) ->
         UserStore.filter(query)
         InviteStore.filter(query)

      handleChangeFilter: (type) ->
         @setState {user_filter: type} if @depracated_is_mounted

      handleInviteClick: ->
         EverTrue.Navigator "invitation", true

      toggleGatedHeaders: ->
         if EverTrue.store.user.hasMFA() || this.state.has_two_factor_auth
            _column_keys.push("mfaStatus") if !_.contains(_column_keys, "mfaStatus")

         if !EverTrue.request("isImpersonating?")
            _column_keys.push("matched_remote_id") if !_.contains(_column_keys, "matched_remote_id")
            _column_keys.push("matched_contact_id") if !_.contains(_column_keys, "matched_contact_id")

      getRoleList: ->
         [
            {label: "User", value: RoleHelper.getUserId()}
            {label: "Owner", value: RoleHelper.getOwnerId()}
         ]

      renderInviteUsersModal: ->
         EverTrue.Navigator "invitation", true


      renderInviteButton: ->
         Button type: "action", className: "pull-right", onClick: @handleInviteClick,
               Icon className: "invite-users-btn--icon", icon: "add"
               span  className: "invite-users-btn--text", "Invite Team Member"

      render: ->
         Module header: "Manage Team", graytitle: true, className: "manage-users--module fixed-page-wrapper",
            div className: "manage-users--top-actions clearfix",
               div className: "pull-left",
                  SearchBox
                     placeholder: "Search users..."
                     className: "manage-users--search pull-left"
                     onKeyUp: @handleUserSearch

                  if @state.org.sso_method != "saml"
                     div className: "button-select button-select-v2",
                        ButtonSelect
                           className: "button-select-v2"
                           selected: @state.user_filter
                           buttons: [
                              {value: "all", label: "All"}
                              {value: "invited", label: "Invited"}
                              {value: "active", label: "Active"}
                           ]
                           onSelect: @handleChangeFilter

               @renderInviteButton()

            ManageUsersTable
               col_keys: _column_keys
               loading: @state.loading_invites || @state.loading_users
               user_filter: @state.user_filter
               active_users: @state.active_users
               invited_users: @state.invited_users
