module.exports = do ->
   _ = require("underscore").default
   {createComponent} = require("@evertrue/et-flux")
   {div, h4} = ReactLibs.DOMFactories
   TransactionalGiftUtils = require("apps/contact/utils/transactional-gift-utils")
   DonationsDataTable = require("apps/contact/components/profile/giving-data/donations-data-table")
   GiftTooltip = require("apps/contact/components/profile/giving-data/gift-tooltip")
   CategorizedGivingChart = require("components/charts/categorized-giving-chart")
   ButtonSelect = require("components/controls/button-select")
   colors = require("base/colors")

   createComponent "CategorizedGivingHistory",
      propTypes:
         giving: ReactLibs.PropTypes.object

      getInitialState: ->
         sub_view: "chart"

      getDefaultProps: ->
         giving: {}

      renderTooltip: (item) ->
         GiftTooltip
            value: "Fiscal Year #{item.x}"
            amount: TransactionalGiftUtils.formatAxisDollars(item.y)

      render: ->
         chart_height = 300
         chart_default_width = 600

         chart_data = _.sortBy(_.map(@props.giving.history, (node) ->
            x: node?.year
            y: node?.amount
            style: {fill: colors.green300, opacity: .4}
         ), "x")

         div className: "giving-module--body",
            div className: "clearfix",
               div className: "giving-module--title pull-left",
                  h4 null, "How much has this constituent given?"

               ButtonSelect
                  className: "giving-module--toggle"
                  selected: @state.sub_view
                  buttons: [
                     {value: "chart", label: "Graph"}
                     {value: "table", label: "Table"}
                  ]
                  onSelect: (value) =>
                     @setState {sub_view: value}

            div className: "giving-module--chart",
               if @state.sub_view == "chart"
                  CategorizedGivingChart
                     data: chart_data
                     height: chart_height
                     defaultWidth: chart_default_width
                     tooltipContent: @renderTooltip
                     yFormat: TransactionalGiftUtils.formatAxisDollars
                     xFormat: TransactionalGiftUtils.formatDate
               else
                  DonationsDataTable
                     data: _.map chart_data, (item) ->
                        _.extend {}, item,
                           xFormat: item.x
                           yFormat: TransactionalGiftUtils.formatFullDollars(item.y)
                     xLabel: "Fiscal Year"
                     yLabel: "Amount"
