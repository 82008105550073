import _ from "underscore";
import { Component } from "react";
import PropTypes from "prop-types";
import GiftFeedItem from "apps/profile/components/activity/gift-feed-item";
import EmptyStateMessage from "components/lists/empty-state-message";
import Loading from "components/elements/loading";
import { LinkButton, Link } from "@evertrue/et-components";

class GiftsFeed extends Component {
  static propTypes = {
    contact: PropTypes.object,
    items: PropTypes.array,
    total: PropTypes.number,
    loading: PropTypes.bool,
    showViewAll: PropTypes.bool,
    onLoadMore: PropTypes.func,
    feedTitle: PropTypes.string,
    renderAmountModal: PropTypes.func,
    renderNameLink: PropTypes.func,
  };

  static defaultProps = {
    contact: {},
    onLoadMore: _.noop,
    feedTitle: "",
    showViewAll: false,
    renderAmountModal: _.noop,
    renderNameLink: _.noop,
    contactNameLink: false,
  };

  renderFeedItems() {
    return _.map(this.props.items, gift => (
      <GiftFeedItem
        key={gift.id}
        gift={gift}
        amountModal={this.props.renderAmountModal(gift)}
        contactNameLink={this.props.renderNameLink(gift)}
      />
    ));
  }

  renderFeedFooter() {
    const { total, items, loading, showViewAll } = this.props;

    if (_.size(items) < total) {
      if (showViewAll) {
        return (
          <div className="gifts-feed--view-all">
            <Link title="All the gifts for this constituent" href={`/contact/${this.props.contact.id}/gifts`}>
              View All
            </Link>
          </div>
        );
      }

      if (loading && items.length > 0) {
        return (
          <div className="gifts-feed--load-more">
            <Loading spinner_size="small" position="center" />
          </div>
        );
      }
      return (
        <div className="gifts-feed--load-more">
          <LinkButton onClick={() => this.props.onLoadMore()} title="View more">
            View More
          </LinkButton>
        </div>
      );
    }
    return null;
  }

  renderGiftsFeed() {
    const { loading, items, feedTitle } = this.props;
    if (loading && items.length === 0) {
      return <Loading />;
    } else if (_.isEmpty(items)) {
      return <EmptyStateMessage>No Gifts found</EmptyStateMessage>;
    }
    return (
      <div className="gifts-feed--list">
        <div className="gifts-feed--title">{feedTitle}</div>
        {this.renderFeedItems()}
      </div>
    );
  }

  render() {
    return (
      <div className="gifts-feed">
        {this.renderGiftsFeed()}
        {this.renderFeedFooter()}
      </div>
    );
  }
}

export default GiftsFeed;
