import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { TextInputForm } from "@evertrue/et-components";
import { PERMISSIONS, UPDATE_PROP } from "apps/dynamic-lists/utils/dynamic-list-enums";
import { updateList } from "apps/dynamic-lists/utils/list-actions";

const DynamicListName = ({ listId = "", listName = "", permission = "VIEW", disabled = false }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [name, setName] = useState(listName);
  const [viableName, setViableName] = useState(true);

  useEffect(() => {
    setName(listName);
  }, [listName]);

  const handleNameChange = async (newVal) => {
    if (newVal.length > 0 && newVal.trim() === "") {
      setViableName(false);
    } else {
      setViableName(true);
      try {
        setIsSubmitting(true);
        await updateList(listId, UPDATE_PROP.NAME, newVal);
        setName(newVal);
        setIsSubmitting(false);
      } catch {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <>
      {permission === PERMISSIONS.VIEW ? (
        <div>{name}</div>
      ) : (
        <>
          <TextInputForm
            label="Dynamic List Name"
            value={name}
            onSubmit={(val) => handleNameChange(val)}
            required={true}
            isSubmitting={isSubmitting}
            style={{ width: "400px" }}
            maxLength="100"
            disableEdit={disabled}
            onCancel={() => {
              setViableName(true);
            }}
          />
          {!viableName && (
            <h6 style={{ fontSize: "12px", margin: "0px", backgroundColor: "rgb(246,246,246)", color: "red" }}>
              Please enter a valid name and try again.
            </h6>
          )}
        </>
      )}
    </>
  );
};

DynamicListName.propTypes = {
  listId: PropTypes.number,
  listName: PropTypes.string,
  permission: PropTypes.string,
  disabled: PropTypes.bool,
};

export default DynamicListName;
