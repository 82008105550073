module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   {div, table} = ReactLibs.DOMFactories
   {createComponent} = require("@evertrue/et-flux")
   FixedTableHeader = require("components/tables/fixed-table-header")
   FixedTableRows = require("components/tables/fixed-table-rows")
   UrlSource = require("apps/layout/sources/url-source")

   scrollTimeout = null;

   createComponent "FixedTableBody",
      propTypes:
         data: ReactLibs.PropTypes.array
         columns: ReactLibs.PropTypes.array
         loading: ReactLibs.PropTypes.bool
         emptyMessage: ReactLibs.PropTypes.node
         onRowClick: ReactLibs.PropTypes.func
         footer: ReactLibs.PropTypes.any

      getInitialState: ->
         scrollPos: 0
         preventAutoScroll: false

      componentDidMount: ->
         @setState scrollPos: EverTrue.UrlManager.get("scrollpos")

      componentDidUpdate: (prevProps, prevState) ->
         if !@props.loading && !@state.preventAutoScroll && @state.scrollPos
            $(@refs.table_body).scrollTop(@state.scrollPos)
            @setState preventAutoScroll: true

      componentWillUnmount: ->
         UrlSource.clear("scrollpos")

      handleScroll: () ->
         clearTimeout(scrollTimeout);
         scrollTimeout = setTimeout((() => UrlSource.change({"scrollpos": $(@refs.table_body).scrollTop()})), 100);

      render: ->
         div null,
            # Table Header - Fixed Vertically - No auto scrolling
            div className: "fixed-table--horizontal", ref: "header",
               table className: "fixed-table--header",
                  FixedTableHeader
                     columns: @props.columns
                     columnWidth: @props.columnWidth

            # Table Body - Scrolls vertically with default browser behavior
            div className: "fixed-table--vertical", ref: "table_body", onScroll: @handleScroll,
               table className: "fixed-table--body",
                  FixedTableRows
                     data: @props.data
                     loading: @props.loading
                     emptyMessage: @props.emptyMessage
                     columnWidth: @props.columnWidth
                     onRowClick: @props.onRowClick
               if @props.footer
                  div className: "fixed-table--footer", @props.footer
