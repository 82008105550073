import { PropTypes } from "base/react-libs";
import NavButton from "components/lists/nav-button";

const JourneysTabNav = ({ tabs = [], selectedTab, onSelect }) => {
  return (
    <>
      {tabs.map((tab) => (
        <NavButton key={tab.id} active={selectedTab.id} activeWhen={tab.id} onSelect={() => onSelect(tab)}>
          {tab.label}
        </NavButton>
      ))}
    </>
  );
};

JourneysTabNav.propTypes = {
  tabs: PropTypes.array,
  selectedTab: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default JourneysTabNav;
