module.exports = do ->
   _ = require("underscore").default
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   {tr, td, tbody} = ReactLibs.DOMFactories
   Loading = require("components/elements/loading")

   createComponent "FixedTableRows",
      propTypes:
         data: ReactLibs.PropTypes.any
         loading: ReactLibs.PropTypes.bool
         emptyMessage: ReactLibs.PropTypes.node
         columnWidth: ReactLibs.PropTypes.number
         onRowClick: ReactLibs.PropTypes.func

      getDefaultProps: ->
         columnWidth: 175

      render: ->
         column_count = @props.data[0]?.length
         tbody className: "table--body paged-col-table--body",
            if (!@props.loading) && _.isEmpty(_.flatten(@props.data))
               tr null,
                  td colSpan: column_count, className:"is-empty",
                     @props.emptyMessage || "Not Available"
            else if @props.loading
               tr null, td colSpan: column_count, Loading()
            else
               _.map @props.data, (row) =>
                  tr key: row.key, onClick: (=> @props.onRowClick?(row.id || row.key)),
                     _.map row.cells, (cell) ->
                        td
                           key: cell.key
                           className: classNames(cell.className, "is-sticky": cell.sticky)
                           style:
                              width: cell.width
                              maxWidth: @props.columnWidth unless cell.width
                           cell.element
