module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   {div, span} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   Checkbox = createFactory(require("@evertrue/et-components").Checkbox)


   createComponent "ChartLegend",
      propTypes:
         series: ReactLibs.PropTypes.array
         value: ReactLibs.PropTypes.array
         onChange: ReactLibs.PropTypes.func

      handleChange: (item, willBeChecked) ->
         legend_values = @props.value || []
         if willBeChecked
            legend_values.push(item)
         else
            check = _.findWhere legend_values, {value: item.value}
            legend_values = _.without(legend_values, check)

         unless _.isEmpty(legend_values)
            @props.onChange?(legend_values)

      render: ->
         div className: classNames(@props.className, "chart-legend"),
            _.map @props.series, (item) =>
               isSelected = _.find @props.value, {value: item.value}

               if @props.onChange
                  span className: "chart-legend--item", key: item.value,
                     Checkbox
                        className: "chart-legend--checkbox"
                        checked: isSelected
                        checkboxStyle: if isSelected then item.style
                        onChange: (willBeChecked, e) =>
                           @handleChange(item, willBeChecked)
                        span className: "chart-legend--label ellipsis-overflow",
                           item.label
               else
                  span className: "chart-legend--item", key: item.value,
                     span className: "chart-legend--color", style: item.style
                     span className: "chart-legend--label ellipsis-overflow", item.label

