import { PropTypes } from "base/react-libs";
import { PinnedCommentsProvider } from "apps/profile/contexts/pinned-comments-context";
import AddPinnedCommentButton from "apps/profile/components/pinned-comments/add-pinned-comment-button";
import PinnedCommentsWrapper from "../interaction-feed/pinned-comments-wrapper";
import SummaryRow from "../summary-row";

const ContactSummaryPinnedComments = ({ contact }) => {
  return (
    <PinnedCommentsProvider contact={contact}>
      <SummaryRow title="Pinned Comments">
        <AddPinnedCommentButton buttonType="simple" classNames="contact-summary--header-row--button" title="+" />
        <span>Add Pinned Comment</span>
      </SummaryRow>
      <PinnedCommentsWrapper />
    </PinnedCommentsProvider>
  );
};

ContactSummaryPinnedComments.propTypes = {
  contact: PropTypes.object,
};

export default ContactSummaryPinnedComments;
