import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "@evertrue/et-flux";
import DNAStore from "base/dna/dna-store";
import { AdvancedCombobox } from "@evertrue/et-components";

const mapStateToProps = () => ({
  givingPatterns: DNAStore.getByKey("ET.enum.map.giving_pattern"),
});

class GivingPatternFilter extends Component {
  static propTypes = {
    givingPatterns: PropTypes.array,
    onChange: PropTypes.func,
    value: PropTypes.array,
  };

  render() {
    const valuesAdjusted =
      this.props.value && this.props.value.map((v) => (v.value === "" ? { label: v.label, value: null } : v));
    return (
      <div className="segment-filter-row--select-width">
        <AdvancedCombobox
          options={this.props.givingPatterns}
          value={valuesAdjusted}
          multiple={true}
          placeholder="Select Options..."
          onChange={this.props.onChange}
        />
      </div>
    );
  }
}

export default connect(GivingPatternFilter, [DNAStore], mapStateToProps);
