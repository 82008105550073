module.exports = do ->
   React = require("react")
   EverTrue = require("app")
   BaseRouter = require("base/base-router")
   ListController = require("apps/lists/controllers/list-controller")
   {createFactory} = require("base/new-utils")
   ListsPageController = createFactory(require("apps/lists/controllers/lists-page-controller").default)


   class ListRouter extends BaseRouter
      routes:
         "lists" : "lists"
         "list/:id": "show"
         "list/:id/:view": "show"

      lists: ->
         EverTrue.execute "removePageContainer"
         EverTrue.execute "setBrowserTitle", "Lists"
         EverTrue.track.set "viewed", {type: "lists"}
         EverTrue.track.setReferrer("lists")

         @currentView = ListsPageController()
         EverTrue.page.mount(@currentView)


      show: (id, view) ->
         EverTrue.execute "removePageContainer"
         EverTrue.execute "setBrowserTitle", "List"
         EverTrue.track.set "viewed", {type: "list"}
         EverTrue.track.setReferrer("list")
         view = "table" unless view == "map"

         @currentView = ListController {list_id: id, view: view}
         EverTrue.page.mount(@currentView)
