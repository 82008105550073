module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories
   FixedTable = require("components/tables/fixed-table")
   {createFactory} = require("base/new-utils")
   Searchbox = createFactory require("components/forms/search-box")
   MyTeamTableColumns = require("apps/my-team/components/my-team-table-columns")
   MyTeamTableDropdown = require("apps/my-team/components/my-team-table-dropdown")

   createComponent "MyTeamRollupTable",
      propTypes:
         data: ReactLibs.PropTypes.object
         visitsAreConfigured: ReactLibs.PropTypes.bool

      getInitialState: ->
         sortProp: "name"
         sortReverse: false

      handleSort: (prop, reverse) ->
         @setState
            sortProp: prop
            sortReverse: reverse

      render: ->
         data = _.compact _.map @props.data, (data, officer) =>
            if _.isEmpty(@state.query) || _.searchCompare(officer, @state.query)
               _.extend {
                  name: officer
                  id: officer
                  visits_are_configured: @props.visitsAreConfigured
               }, data

         sort_fields = @state.sortProp.split(".")
         data = _.sortBy data, (item) ->
            value = _.reduce sort_fields,((memo, path) -> memo?[path]), item
            if !value then 0 else value
         data = data.reverse() if @state.sortReverse

         div className: "my-team-rollup-table",
            div className: "my-team-rollup-table--sort",
               Searchbox
                  placeholder: "Search gift officers"
                  onKeyUp: (val) =>
                     @setState {query: val}

            div className: "my-team-rollup-table--body report-table--body has-header-dropdown",
               FixedTable
                  data: data
                  config: _.compact _.map MyTeamTableColumns, (cell, key) =>
                     if !cell.gated || cell.gated()
                        _.extend {}, cell,
                           key: key
                           label: MyTeamTableDropdown
                              column: _.extend {key: key}, cell
                              sort: _.pick(@state, "sortProp", "sortReverse")
                              onSort: @handleSort
