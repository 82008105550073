import { useState } from "react";
import { PropTypes } from "base/react-libs";
import FormField from "components/forms/form-field";
import { ModalHeaderWithActions, Modal, ModalBody } from "@evertrue/et-components";

const AddSignatureModal = ({ isOpen, close, handleSubmit }) => {
  const [newSignatureName, setNewSignatureName] = useState("");
  return (
    <>
      <Modal isOpen={isOpen} closeModal={close} closeOnOutsideClick={true} size="small">
        <ModalHeaderWithActions
          title="Add New Signature"
          closeModal={()=>{
            setNewSignatureName('')
            close();
          }}
          onSubmit={() => {
            handleSubmit(newSignatureName);
            setNewSignatureName('')
          }
          }
          disableSubmit={!newSignatureName.trim()}
        />
        <ModalBody>
          <FormField label="Signature Name" inlineLabel={false}>
            <input
              autoFocus
              type="text"
              value={newSignatureName}
              placeholder={`Enter the Signature Name...`}
              onChange={(e) => setNewSignatureName(e.currentTarget.value)}
            />
          </FormField>
        </ModalBody>
      </Modal>
    </>
  );
};

AddSignatureModal.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default AddSignatureModal;

