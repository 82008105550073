import PropTypes from "prop-types";
import { Button } from "@evertrue/et-components";

/**
 * A modal header that includes a Save and Cancel button. The button styles should not be changed.
 * If a custom header is needed in the future, consider creating a separate component.
 * This Modal includes image beside the title. Image styles can be adjusted as needed.
 */

const ModalHeaderWithActionsAndImage = ({
  title,
  imageSrc,
  submitLabel = "Save",
  closeModal = () => {},
  onSubmit = () => {},
  disableSubmit = false,
  closeLabel = "Cancel",
}) => {

  return (
    <div className="et--modal-header">
      <div style={{ display: "flex", alignItems: "baseline" }}>
        <img src={imageSrc} alt="header icon" style={{width: "27.23px", height: "25px"}}/>
        <h3>{title}</h3>
      </div>
      <div className="et--modal-header--actions">
        <Button type="secondary" onClick={closeModal}>
          {closeLabel}
        </Button>
        <Button type="default" disabled={disableSubmit} onClick={onSubmit}>
          {submitLabel}
        </Button>
      </div>
    </div>
  );
};

ModalHeaderWithActionsAndImage.propTypes = {
  title: PropTypes.string,
  submitLabel: PropTypes.string,
  imageSrc: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  disableSubmit: PropTypes.bool,
  closeLabel: PropTypes.string,
};

export default ModalHeaderWithActionsAndImage;
