module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   EverTrue = require("app")
   {createComponent} = require("@evertrue/et-flux")
   {div, strong} = ReactLibs.DOMFactories
   MyTripsSource = require("apps/my-trips/sources/my-trips-source")
   MyTripsUtils = require("apps/my-trips/utils/my-trips-utils")
   MeetingsStore = require("apps/my-trips/stores/meetings-store")
   TripForm = require("apps/my-trips/components/trip-form")
   TripShare = require("apps/my-trips/components/trip-share")
   {createFactory} = require("base/new-utils")
   InteractionForm = createFactory(require("apps/interactions/components/create/interaction-form").default)
   TripLogSource = require("apps/my-trips/sources/trip-log-source")
   TripLogStore = require("apps/my-trips/stores/trip-log-store")
   ActionMenuItem = createFactory(require("components/controls/action-menu-item").default)
   Modal = createFactory require("components/modals/modal")
   FeatureStore = require("apps/layout/stores/feature-store").default

   createComponent "TripActions",
      propTypes:
         trip: ReactLibs.PropTypes.object.isRequired

      registerStores: ->
         @on MeetingsStore, ->
            booked = MeetingsStore.getBookedContacts(@props.trip?.id)
            not_booked = MeetingsStore.getUnconfirmedContacts(@props.trip?.id)
            contact_options: MyTripsUtils.getGroupedContactOptions(booked, not_booked)
            has_meetings: !_.isEmpty(MeetingsStore.getForTrip(@props.trip?.id))

         @on TripLogStore, ->
            exportable_logs: TripLogStore.getExportableForTrip(@props.trip?.id)

         @on FeatureStore, ->
            has_interaction_exports: FeatureStore.hasInteractionExports()
            has_interaction_writes: FeatureStore.hasInteractionWrites()

      componentDidMount: ->
         TripLogSource.fetchInteractions(@props.trip?.id)

      handleDelete: ->
         EverTrue.Alert.confirm
            content: "Are you sure you want to delete your #{@props.trip.name} trip? You will still have access to your
            constiutent notes, but this action will permanently delete your meetings."
            headline: "Delete Trip?"
         , (e) =>
            if e
               MyTripsSource.delete(@props.trip?.id)
               EverTrue.Navigator("/trips", true)

      renderExportConfirm: ->
         EverTrue.Alert.confirm
            type: "info"
            headline: "Export Trip Log?"
            content: div null,
               "You are about to export the trip log for "
               strong null, @props.trip?.name
               ". The exported .CSV file will include all interactions except EverTrue Comments."
            buttonLabel: "Continue Export"
         , (e) =>
            if e
               EverTrue.track.set("manual_export_action", {type: "trip"})
               TripLogSource.startExport(@props.trip?.id)

      renderInteractionModal: ->
         Modal width: 568, keepOpen: true,
            InteractionForm
               interaction: {trip_id: @props.trip.id}
               contactOptions: @state.contact_options
               referrer: "trip"

      renderShareModal: ->
         Modal width: 586, keepOpen: true,
            TripShare trip: @props.trip


      render: ->
         is_owner_ish = (EverTrue.store.user?.isSuperUser() || EverTrue.store.user?.isOwner())

         div className: "trip-actions",
            if @props.trip?.is_my_trip
               ActionMenuItem
                  title: "Edit Trip Details"
                  icon: "edit"
                  modal: TripForm({trip: @props.trip})
                  "Edit Trip Details"

            if @props.trip?.is_my_trip
               ActionMenuItem
                  title: "Add Event"
                  icon: "add-meeting"
                  onClick: @props.openEventModal
                  "Add Event"

            ActionMenuItem
               title: if @state.has_interaction_writes then "Add Interaction" else "Add Comment"
               icon: "trip-log"
               modal: @renderInteractionModal()
               if @state.has_interaction_writes then "Add Interaction" else "Add Comment"

            if @state.has_meetings && @props.trip?.is_my_trip
               ActionMenuItem
                  title: "Email Trip Itinerary"
                  icon: "email"
                  modal: @renderShareModal()
                  "Email Trip Itinerary"

            if @state.has_interaction_exports && is_owner_ish && !_.isEmpty(@state.exportable_logs)
               ActionMenuItem
                  title: "Export Trip Log"
                  icon: "cloud-download"
                  onClick: @renderExportConfirm
                  "Export Trip Log"

            if @state.has_meetings && @props.trip?.is_my_trip
               ActionMenuItem
                  title: "Email Trip Itinerary"
                  icon: "email"
                  modal: @renderShareModal()
                  "Email Trip Itinerary"

               if @state.has_interaction_exports && is_owner_ish && !_.isEmpty(@state.exportable_logs)
                  ActionMenuItem
                     title: "Export Trip Log"
                     icon: "cloud-download"
                     onClick: @renderExportConfirm
                     "Export Trip Log"

               if @props.trip?.is_my_trip
                  ActionMenuItem
                     title: "Delete Trip"
                     className: "text-danger"
                     icon: "delete"
                     onClick: @handleDelete
                     "Delete Trip"
