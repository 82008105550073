module.exports = do ->
   _ = require("underscore").default
   moment = require("moment")
   Decorator = require("clientDecorator")

   _formatContactOptions = (contacts) ->
      options = _.map contacts, (contact) ->
         {value: contact.id, label: Decorator.Contacts.getDetailedName(contact)}
      _.uniq _.sortBy(options, "label"), true, (item) -> item.value


   MyTripsUtils =
      getDirectionsLink: (meeting, previous_meeting) ->
         if meeting?.location?.formatted_address
            base_url = "https://www.google.com/maps/dir/"
            if previous_meeting?.location?.formatted_address
               base_url += "#{encodeURIComponent(previous_meeting.location.formatted_address)}"
            base_url += "/#{encodeURIComponent(meeting.location.formatted_address)}"
            base_url

      getGroupedContactOptions: (booked, not_booked) ->
         _.filter [
            {label: "Booked", items: _formatContactOptions(booked)}
            {label: "Not Booked", items: _formatContactOptions(not_booked)}
         ], (group) -> !_.isEmpty(group.items)

      getTripDisplayDate: (trip) ->
         return "" unless trip
         start_year = moment(trip.start_at).format("YYYY")
         end_year = moment(trip.end_at).format("YYYY")

         formatted_date = moment(trip.start_at).format("MMM D")
         if start_year != end_year
            formatted_date += ", #{start_year}"
         formatted_date += " - "
         if moment(trip.start_at).format("MMM") == moment(trip.end_at).format("MMM")
            formatted_date += moment(trip.end_at).format("D")
         else
            formatted_date += moment(trip.end_at).format("MMM D")
         formatted_date += ", #{end_year}"
         formatted_date

      getMeetingDisplayDate: (trip) ->
         return "" unless trip

         formatted_date = moment(trip.start_at).format("MMM D, Y")
         formatted_time = trip.start_datetime.time_string + " - " + trip.end_datetime.time_string

         formatted_date + " | " + formatted_time

      # Check to determine if date is daylight savings based on
      # Whether the offset in Jan is different than the offset in JUL
      # Is a temporary fix for MyTrips time formatting issues.
      isDaylightSavings: (date_obj) ->
         jan = new Date(date_obj.getFullYear(), 0, 1)
         jul = new Date(date_obj.getFullYear(), 6, 1)
         standard_offset = Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset())
         date_obj.getTimezoneOffset() < standard_offset

      getDefaultEvent: (meetings, day, trip) ->
         last_meeting = _.last(meetings)
         if last_meeting
            last_hour = moment(last_meeting?.end_at).hour()
            meeting_block = _.find _.range(9, last_hour + 1), (hour) ->
               _.every meetings, (meeting) -> moment(meeting.start_at).hour() != hour
         meeting_block ?= 9

         trip_year = moment(trip.start_at).format("YYYY")
         date_setup = moment("#{day} #{trip_year} #{meeting_block}", "ddd MM/DD YYYY H")

         start_at: date_setup.valueOf()
         end_at: date_setup.add(1, "h").valueOf()


   MyTripsUtils
