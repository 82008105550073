module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   React = require("react")
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories
   FeatureStore = require("apps/layout/stores/feature-store").default
   {createFactory} = require("base/new-utils")
   ListboxSelect = createFactory(require("@evertrue/et-components").ListboxSelect)
   Icon = createFactory(require("components/elements/icon").default)
   LinkButton = createFactory(require("@evertrue/et-components").LinkButton)
   KeyPressManager = createFactory(require("components/helpers/KeyPressManager").default)
   PROXIMITY_SORTS = ["addresses.location", "proximity_sort"]

   createComponent "SortDropdown",
      propTypes:
         options: ReactLibs.PropTypes.array
         sortProp: ReactLibs.PropTypes.string
         sortReverse: ReactLibs.PropTypes.bool
         onSort: ReactLibs.PropTypes.func

      getDefaultProps: ->
         sortReverse: false

      registerStores: ->
         @on FeatureStore, ->
            has_scores: FeatureStore.hasFeature("scores")

      filterOptions: ->
         _.filter @props.options, (option) =>
            matcher = if @state.has_scores then "et_donor_index" else "score"
            !option.value?.match(matcher)

      handleSortReverse: (e) ->
         sort_reverse = !@props.sortReverse
         @props.onSort?(@props.sortProp, sort_reverse)
         $(e.currentTarget).blur() if e

      render: ->
         options = @filterOptions()

         if @props.sortProp in PROXIMITY_SORTS
            value_object = _.findWhere options, {value: "proximity_sort"}
         else
            value_object = _.findWhere options, {value: @props.sortProp}

         if options.length == 1
            KeyPressManager
               onEnter: @handleSortReverse,
               children: ({onKeyDown, ref}) =>
                  LinkButton
                     ref: ref
                     onKeyDown: onKeyDown
                     title: "Click to reverse the sort",
                     className: classNames(@props.className, "sort-dropdown--single")
                     onClick: @handleSortReverse

                     Icon className: classNames("sort-dropdown--icon", "is-selected": !@props.sortReverse), icon: "long-arrow-down"
                     Icon className: classNames("sort-dropdown--icon", "is-selected": !!@props.sortReverse), icon: "long-arrow-up"
                     _.first(options)?.label
         else
            div className: classNames("sort-dropdown", @props.className),
               KeyPressManager
                  onEnter: @handleSortReverse,
                  children: ({onKeyDown, ref}) =>
                     LinkButton ref: ref, onKeyDown: onKeyDown, title: "Reverse the sort", className: "sort-dropdown--btn", onClick: @handleSortReverse,
                        Icon className: classNames("sort-dropdown--icon", "is-selected": !@props.sortReverse), icon: "long-arrow-down"
                        Icon className: classNames("sort-dropdown--icon", "is-selected": !!@props.sortReverse), icon: "long-arrow-up"

               ListboxSelect
                  value: value_object
                  options: options
                  label: "sorting options"
                  onChange: (selected) =>
                     @props.onSort?(selected?.value, @props.sortReverse)
