import _ from "underscore";
import PropTypes from "prop-types";
import WidgetCard from "apps/dashboard/components/widget-card";
import { Responsive, WidthProvider } from "react-grid-layout";
import { Loading } from "@evertrue/et-components";
import { useTeamDashboard } from "apps/dashboard/hooks/use-team-dashboard";

const ResponsiveGridLayout = WidthProvider(Responsive);

const TeamDashboardWidgets = ({ fiscal_year, team_id }) => {
  const { widgets, loading } = useTeamDashboard();
  const variables = { poolId: team_id };

  if (!loading && _.isEmpty(widgets)) {
    return <div>No widgets were found.</div>;
  } else {
    // this widget grid is using https://github.com/STRML/react-grid-layout
    // see their docs for more info
    return (
      <>
        {/* we have the loading here so that it doesn't unload the widgets, thereby causing too many graphql requests */}
        {!!loading && <Loading className="dashboard--loading" />}

        <ResponsiveGridLayout
          className="layout"
          breakpoints={{ lg: 1200, md: 996, sm: 700, xs: 550, xxs: 0 }}
          rowHeight={250}
          cols={{ lg: 4, md: 3, sm: 2, xs: 2, xxs: 1 }}
          margin={[0, 12]}
        >
          {_.map(widgets, (widget = {}) => {
            const grid = {
              x: widget.position_x,
              y: widget.position_y,
              w: widget.width,
              h: widget.height,
              static: true,
            };
            return (
              <div key={widget.id} data-grid={grid}>
                <WidgetCard widget={widget} variables={variables} fiscalYear={fiscal_year} canReorder={false} />
              </div>
            );
          })}
        </ResponsiveGridLayout>
      </>
    );
  }
};

TeamDashboardWidgets.propTypes = {
  fiscal_year: PropTypes.number,
  team_id: PropTypes.number,
};

export default TeamDashboardWidgets;
