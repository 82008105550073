import EverTrue from "app";
import BaseRouter from "base/base-router";
import OauthRedirectController from "components/emailer/oauth-redirect-controller";

class EmailRouter extends BaseRouter {
  static initClass() {
    this.prototype.routes = {
      "oauth-redirect(/)": "redirect",
    };
  }

  redirect() {
    EverTrue.execute("setBrowserTitle", "Tasks");
    this.currentView = <OauthRedirectController />;
    EverTrue.page.mount(this.currentView);
  }
}

EmailRouter.initClass();
export default EmailRouter;
