module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   nouislider = require("nouislider")
   {div} = ReactLibs.DOMFactories

   SlideRange = ReactLibs.createClass
      propTypes:
         min: ReactLibs.PropTypes.number
         max: ReactLibs.PropTypes.number
         value: ReactLibs.PropTypes.object
         name: ReactLibs.PropTypes.string
         tickInterval: ReactLibs.PropTypes.number
         onChange: ReactLibs.PropTypes.func

      componentDidMount: ->
         @is_mounted = true
         el = @slider
         nouislider.create el,
            range:
               min: @props.min
               max: @props.max
            connect: true
            step: @tickInterval || 1
            start: [
               (@props.value?.gte || @props.min),
               (@props.value?.lte || @props.max)
            ]
            pips:
               mode: "steps"
               density: 5

         el.noUiSlider.on("change", @handleChange)

      componentWillUnmount: ->
         @is_mounted = false

      UNSAFE_componentWillReceiveProps: (newProps, newState) ->
         unless _.isEqual(newProps.value, @props.value)
            el = @slider
            el.noUiSlider.set [
               (newProps.value?.gte || @props.min),
               (newProps.value?.lte || @props.max)
            ]

      handleChange: ->
         return unless @is_mounted
         values = @slider.noUiSlider.get()
         [min, max] = _.map values, (val) -> parseInt(val, 10)

         valueObject = {}
         valueObject.gte = min
         valueObject.lte = max

         if _.isFunction @props.onChange
            @props.onChange(@props.name, valueObject)

      render: ->
         div className: "slide-range",
            div ref: ((elem) => @slider = elem)
