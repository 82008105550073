import EverTrue from "app";
import { addMillisecondsToTimestamp, formatDateFromTimestamp } from "apps/journeys/utils/helpers/dates";
import { useEffect, useState } from "react";
import { useOpenClose } from "@evertrue/et-components";

const RESUME_PAUSE_TITLE = "Resume Cadences and Tasks";
const RESUME_PAUSE_BODY =
  "Are you ready to come back? Your cadences and tasks will resume where you left off, and we'll reactivate your tasks starting with those due today.";
const RESUME_PAUSE_ACTION = "Resume";
const CANCEL_PAUSE_TITLE = "Cancel Pause";
const CANCEL_PAUSE_BODY = "Are you sure you want to cancel this pause?";
const CANCEL_PAUSE_ACTION = "Cancel Pause";
const DEFAULT_START_MESSAGE = "This is your first day out of office.";
const WILL_START_IMMEDIATELY_MESSAGE = "All your cadences and tasks will pause immediately.";
const CURRENTLY_PAUSED = "Your cadences and tasks are currently paused.";
const DEFAULT_END_MESSAGE = "This is the date you plan to resume your work.";

const usePauseJourneyForm = (inProgressJourneyTimeout, futureJourneyTimeout) => {
  const [startMessage, setStartMessage] = useState();
  const [endMessage, setEndMessage] = useState();
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);

  const [disableSave, setDisableSave] = useState(false);

  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [modalAction, setModalAction] = useState("");
  const [isModalOpen, openModal, closeModal] = useOpenClose();

  const todayTime = Math.floor(new Date().getTime() / 86400000);

  // START TIME & END TIME STATE
  useEffect(() => {
    const activeTimeout = inProgressJourneyTimeout || futureJourneyTimeout;

    if (activeTimeout) {
      setDisableSave((prevDisableSave) => !prevDisableSave);
      const { pause_start, pause_end } = activeTimeout;
      setSelectedStartDate(addMillisecondsToTimestamp(pause_start));
      setSelectedEndDate(addMillisecondsToTimestamp(pause_end));
    } else {
      setSelectedStartDate(null);
      setSelectedEndDate(null);
    }
  }, [inProgressJourneyTimeout, futureJourneyTimeout]);

  // START MESSAGE STATE
  useEffect(() => {
    const selectedDateTime = selectedStartDate ? Math.floor(new Date(selectedStartDate).getTime() / 86400000) : null;

    if (inProgressJourneyTimeout) {
      setStartMessage(CURRENTLY_PAUSED);
    } else if (!selectedStartDate || futureJourneyTimeout) {
      setStartMessage(DEFAULT_START_MESSAGE);
    } else if (selectedDateTime && selectedDateTime === todayTime) {
      setStartMessage(WILL_START_IMMEDIATELY_MESSAGE);
    } else {
      setStartMessage(
        "Your cadences and tasks will be paused starting on " + formatDateFromTimestamp(selectedStartDate) + "."
      );
    }
  }, [inProgressJourneyTimeout, futureJourneyTimeout, selectedStartDate, todayTime]);

  // END MESSAGE STATE
  useEffect(() => {
    if (!selectedEndDate) {
      setEndMessage(DEFAULT_END_MESSAGE);
    } else {
      setEndMessage("Your cadences and tasks will resume on " + formatDateFromTimestamp(selectedEndDate) + ".");
    }
  }, [selectedEndDate]);

  const handleEndDateClick = (day) => {
    setDisableSave(false);
    const selectedDay = Math.floor(new Date(day).getTime() / 86400000);
    const selectedStartDay = selectedStartDate ? Math.floor(new Date(selectedStartDate).getTime() / 86400000) : null;
    const selectedDayOfWeek = new Date(day).getDay();

    if (selectedDayOfWeek === 0 || selectedDayOfWeek === 6) {
      return EverTrue.Alert.error("End Date cannot be on a weekend");
    }

    if (selectedStartDay && selectedDay <= selectedStartDay) {
      return EverTrue.Alert.error("End Date cannot be on or before the Start Date");
    } else if (selectedDay < todayTime) {
      return EverTrue.Alert.error("End Date cannot be before today");
    } else {
      setSelectedEndDate(day);
    }
  };

  const handleStartDateClick = (day) => {
    setDisableSave(false);
    const selectedDay = Math.floor(new Date(day).getTime() / 86400000);
    const selectedEndDay = selectedEndDate ? Math.floor(new Date(selectedEndDate).getTime() / 86400000) : null;

    if (selectedDay < todayTime) {
      EverTrue.Alert.error("Start Date cannot be before today");
    } else if (selectedEndDay && selectedDay >= selectedEndDay) {
      EverTrue.Alert.error("Start Date cannot be on or after the End Date");
    } else setSelectedStartDate(day);
  };

  const handleResumeClick = () => {
    setModalAction(RESUME_PAUSE_ACTION);
    setModalTitle(RESUME_PAUSE_TITLE);
    setModalBody(RESUME_PAUSE_BODY);
    openModal();
  };

  const handleCancelClick = () => {
    setModalAction(CANCEL_PAUSE_ACTION);
    setModalTitle(CANCEL_PAUSE_TITLE);
    setModalBody(CANCEL_PAUSE_BODY);
    openModal();
  };

  return {
    startMessage,
    selectedStartDate,
    endMessage,
    selectedEndDate,
    disableSave,

    modalTitle,
    modalBody,
    modalAction,
    isModalOpen,
    closeModal,

    handleEndDateClick,
    handleStartDateClick,
    handleCancelClick,
    handleResumeClick,
  };
};

export default usePauseJourneyForm;
