module.exports = do ->
   _ = require("underscore").default
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")
   React = require("react")

   NAV_STATE_KEY = "GT_nav_is_closed"


   createSource "LayoutSource",
      actions:
         toggleNav: true
         endNavAnimation: true
         unmountContent: true
         unmountBanner: true
         removeOverlay: true
         remount: true
         mountContent: (component) ->
            @require !_.isUndefined(component), "component should be defined React component"
         mountBanner: (component) ->
            @require !_.isUndefined(component), "component should be defined React component"
         overlay: (component, properties) ->
            @require !_.isUndefined(component), "component should be defined React component"
            @require _.isObject(properties), "properties should be object"
         fetchedNavState: (is_closed) ->
            @require _.isBoolean(is_closed), "is_closed should be boolean"

      api:
         mountContent: (component) ->
            @actions.mountContent(component)

         unmountContent: ->
            @actions.unmountContent()

         mountBanner: (banner) ->
            @actions.mountBanner(banner)

         unmountBanner: ->
            @actions.unmountBanner()

         overlay: (component, properties) ->
            @actions.overlay(component, properties)

         removeOverlay: (key) ->
            @actions.removeOverlay(key)

         endNavAnimation: ->
            @actions.endNavAnimation()

         toggleNav: ->
            @actions.toggleNav()

         remount: ->
            @actions.remount()

         launchModal: (modal, key) ->
            modal_key = key || _.randomKey()
            dialog = React.cloneElement modal,
               remove: => @removeOverlay(modal_key)
               onUmount: => @removeOverlay(modal_key)

            @overlay(dialog, {
               key: modal_key
               top: 0
               left: 0
               bottom: 0
               right: 0
               zIndex: 900 # et-comps layers layer is 1000
            })

         fetchNavState: ->
            Api.DNA.SETTING.get
               urlArgs: {setting_key: NAV_STATE_KEY}
               disableAlerts: true
               params:
                  user_id: "me"
                  oid: null
               success: (resp) =>
                  data = resp?.settings?[NAV_STATE_KEY]?.value
                  @actions.fetchedNavState(!!data)

         saveNavState: (is_open) ->
            Api.DNA.SETTING.put
               urlArgs: {setting_key: NAV_STATE_KEY}
               disableAlerts: true
               params:
                  user_id: "me"
                  oid: null
               data: _.jsonStringify
                  value: !!is_open
