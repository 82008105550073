module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   {createStore} = require("@evertrue/et-flux")
   BulkListSource = require("apps/lists/sources/bulk-list-source")
   ListSource = require("apps/lists/sources/list-source")
   ListContactSource = require("apps/lists/sources/list-contact-source")

   tokenize = (text = "") ->
      _.uniq(_.compact(_.map(text.split(/[ ,\n\r\t]+/), (e) -> e.trim?())))

   isEmailRegex = new RegExp("@")
   isEmail = (string) -> isEmailRegex.test(string)

   getDefaults = ->
      step: "edit" # ["edit", "processing", "clean", "confirm", "done"]
      error_message: null
      list_id: null
      name: ""
      selected_contacts: []
      raw_tokens_string: ""
      unverified_tokens: []
      hits: {}
      misses: []

   createStore "ManualListStore",
      getInitialState: ->
         getDefaults()

      registerActions: ->
         @on BulkListSource.actions.newListId, (id) ->
            @setState list_id: id || null

         @on BulkListSource.actions.newName, (name) ->
            @setState name: name

         @on BulkListSource.actions.newManualInput, (text) ->
            @setState
               raw_tokens_string: text
               unverified_tokens: tokenize(text)

         @on BulkListSource.actions.newSelectedContacts, (contacts) ->
            @setState selected_contacts: contacts

         @on BulkListSource.actions.procesingManualContacts, ->
            @setState step: "processing"

         @on BulkListSource.actions.editManualContacts, ->
            @setState step: "edit"

         @on BulkListSource.actions.removeMiss, (token) ->
            @setState misses: _.without(@getState("misses"), token)

         @on BulkListSource.actions.removeHit, (token) ->
            @setState hits: _.omit(@getState("hits"), token)

         @on BulkListSource.actions.manualContactsSuccess, (resp) ->
            misses = _.uniq(@getState("misses").concat(resp.misses))

            @setState
               hits: _.extend({}, @getState("hits"), resp.hits)
               misses: misses
               raw_tokens_string: ""
               unverified_tokens: []

            if _.isEmpty(resp.misses) and _.notEmpty(@getNewIds())
               @createOrUpdate()
               @setState step: "done"
            else
               @setState step: "confirm"

         @on BulkListSource.actions.manualContactsError, (responseJSON) ->
            @setState
               step: "error"
               error_message: responseJSON.message || "Rando Problem Brah"

         @on BulkListSource.actions.submit, (bulkSelector) ->
            switch @getState("step")
               when "edit"
                  tokens = @getState("misses").concat(@getState("unverified_tokens"))
                  num_tokens = _.size(tokens)

                  if num_tokens > 1000
                     @setState
                        step: "error"
                        error_message: "#{num_tokens} ids submitted.  maximum 1000"
                  else if num_tokens > 0
                     BulkListSource.processManualContacts(tokens, bulkSelector)
                  else
                     @createOrUpdate()
                     @setState step: "done"

               when "confirm"
                  @createOrUpdate()
                  @setState step: "done"

         @on BulkListSource.actions.clearManualContacts, ->
            @setState getDefaults()

      getNewIds: ->
         selected_ids = _.pluck(@getState("selected_contacts"), "id")
         ids = _.values(@getState("hits"))
         _.compact(_.uniq(_.map(_.flatten(selected_ids.concat(ids)), _.toNumber)))

      createOrUpdate: ->
         ids = @getNewIds()
         list_id = @getState("list_id")
         name = @getState("name")

         if list_id
            ListContactSource.addContacts(list_id, ids, {})
         else if name
            ListSource.create({name: name, type: "user_list"}, ids)

      listIsValid: ->
         @getState("name")?.trim() or @getState("list_id")

      hasContacts: ->
         _.notEmpty(@getState("selected_contacts")) or _.notEmpty(@getState("hits"))

      hasUnverifiedTokens: ->
         _.notEmpty(@getState("unverified_tokens"))

      api:
         getStep: ->
            @getState("step")

         getErrorMessage: ->
            @getState("error_message")

         getListId: ->
            @getState("list_id")

         getName: ->
            @getState("name")

         getSelectedContacts: ->
            @getState("selected_contacts")

         getRawTakensString: ->
            @getState("raw_tokens_string")

         getUnverifiedTokens: ->
            @getState("unverified_tokens")

         getHits: ->
            @getState("hits")

         getMisses: ->
            @getState("misses")

         canSubmitFromEdit: ->
            @listIsValid() and (@hasContacts() or @hasUnverifiedTokens())

         canSubmitFromConfirm: ->
            @listIsValid() and @hasContacts()
