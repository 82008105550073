import { useState, createContext } from "react";
import PropTypes from "prop-types";
import useFilters from "apps/query-builder/hooks/use-filters";
import useQuickFilters from "apps/query-builder/hooks/use-quick-filters";

const FiltersDataContext = createContext(() => {});

const FiltersDataProvider = ({ children }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const { categorizedFilters, filters, filtersLoading } = useFilters(searchTerm);
  const { quickFilters } = useQuickFilters();

  return (
    <FiltersDataContext.Provider
      value={{
        setSearchTerm,
        searchTerm,
        loading: filtersLoading,
        filters,
        categorizedFilters,
        quickFilters,
      }}
    >
      {children}
    </FiltersDataContext.Provider>
  );
};

FiltersDataProvider.propTypes = {
  children: PropTypes.any,
};

export { FiltersDataProvider, FiltersDataContext };
