import EverTrue from "app";
import { Button, Modal, ModalBody, useOpenClose } from "@evertrue/et-components";
import QueryBuilderController from "apps/query-builder/components/query-builder-controller";
import { PropTypes } from "base/react-libs";

const QueryBuilderModal = ({ onApply, list_id, filterCount, contactFilters, setIsAllSelectedCheckbox }) => {
  const [isOpen, openModal, closeModal] = useOpenClose();

  const openQueryBuilder = () => {
    openModal();
    EverTrue.track.set("filter_v2_action", {
      type: "view_more_filters",
    });
  };

  return (
    <>
      <Button type="secondary" onClick={openQueryBuilder}>
        {filterCount > 0 ? `More Filters (${filterCount})` : "More Filters"}
      </Button>

      <Modal isOpen={isOpen} closeModal={closeModal} size="large">
        <ModalBody scrollable={false}>
          <QueryBuilderController
            closeModal={closeModal}
            onApply={(updatedFilters) => {
              onApply({ ...updatedFilters });
              closeModal();
            }}
            list_id={list_id}
            contactFilters={contactFilters}
            setIsAllSelectedCheckbox={setIsAllSelectedCheckbox}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

QueryBuilderModal.propTypes = {
  onApply: PropTypes.func,
  list_id: PropTypes.string,
  filterCount: PropTypes.number,
  contactFilters: PropTypes.object,
  setIsAllSelectedCheckbox: PropTypes.func,
};

export default QueryBuilderModal;
