import { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import HelpTip from "components/tooltip/helptip";
import Icon from "components/elements/icon";

class ProfileCardHeader extends Component {
  static propTypes = {
    className: PropTypes.string,
    icon: PropTypes.string,
    title: PropTypes.any,
    help: PropTypes.string,
    children: PropTypes.any,
    updateDate: PropTypes.string,
  };

  render() {
    return (
      <div
        className={classNames(
          this.props.className,
          "profile-card-header",
          this.props.updateDate ? "include-metadata" : ""
        )}
      >
        <div className="profile-card-header--content" style={{ display: "flex" }}>
          {this.props.icon && <Icon icon={this.props.icon} className="profile-card-header--icon" />}
          <h1 className="profile-card-header--title">{this.props.title}</h1>
          {this.props.help && <HelpTip help_key={this.props.help} className="profile-card-header--help" />}
        </div>
        <div className="profile-card-header--content">{this.props.children}</div>
        {this.props.updateDate && (
          <div className="profile--enrichment-meta">Data was last added to this section on {this.props.updateDate}</div>
        )}
      </div>
    );
  }
}

export default ProfileCardHeader;
