import _ from "underscore";
import $ from "jquery";
import config from "config/env";
import Cookies from "entities/helpers/cookies-helper";
import JsCookie from "js-cookie";

const app_key = "815e8d01be8f78a41d1c71eb652b8be124b89058b74d284c6bb752a034dbb301";
const is_stage = config.getEnv() !== "production";

const prefix = "et:store:";
const accounts_cookie = is_stage ? "stage_etauth" : "production_etauth";
const api_base = is_stage ? "https://stage-api.evertrue.com" : "https://api.evertrue.com";
const skiff_url = api_base + "/skiff";
const auth_session_url = api_base + "/auth/session";

const isUnauthedRoute = () => {
  const paths = ["/login", "/login/invite/", "/status", "/logout"];
  const pathname = (window.location.pathname || "").toLowerCase();
  return _.any(paths, path => pathname.indexOf(path) === 0);
};

const parseLocalStorage = key => {
  let parsed;
  try {
    const value = localStorage.getItem(prefix + key);
    parsed = JSON.parse(value);
  } catch (e) {}
  return parsed || {};
};

const setLocalStorage = (key, value) => {
  try {
    localStorage.setItem(prefix + key, JSON.stringify(value));
  } catch (e) {}
};

const clearLocalStorage = key => {
  try {
    localStorage.removeItem(prefix + key);
  } catch (e) {}
};

const parseRouteOid = () => {
  try {
    const oids = $.deparam(window.location.search.slice(1)).oid;
    const oid = _.isArray(oids) ? oids[0] : oids;
    return parseInt(oid, 10) || undefined;
  } catch (e) {}
};

const parseCookiesOid = () => {
  try {
    return parseInt(Cookies.get("gt_oid"), 10) || undefined;
  } catch (e) {}
};

const default_auth_headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
  "Authorization-Auto-Send": 0,
  "Authorization-Multifactor": 1,
  "Application-Key": app_key,
};

const createScopedSession = ({ oid }) => {
  return $.ajax({
    type: "POST",
    url: skiff_url,
    headers: { ...default_auth_headers, "Authorization-Provider": "EvertruePrimeToken" },
    xhrFields: {
      withCredentials: true,
    },
    data: JSON.stringify({ type: "SCOPED", app_key, oid }),
  });
};

const createUnscopedSession = () => {
  return $.ajax({
    type: "POST",
    url: skiff_url,
    headers: { ...default_auth_headers, "Authorization-Provider": "EvertruePrimeToken" },
    xhrFields: {
      withCredentials: true,
    },
  });
};

const refreshSession = token => {
  return $.ajax({
    type: "GET",
    url: auth_session_url,
    headers: { ...default_auth_headers, "Authorization-Provider": "EvertrueAuthToken", Authorization: token },
  });
};

const storeSession = (session = {}) => {
  const { oid, organization } = session;
  setLocalStorage("session", session);
  if (oid) {
    setLocalStorage("org", organization);
  } else {
    clearLocalStorage("org");
  }
};

const wipeSession = () => {
  clearLocalStorage("session");
  clearLocalStorage("org");
  Cookies.clear("gt_oid");
};

const hydrateSession = (token, oid) => {
  if (token) {
    // we should have session and a scope
    return refreshSession(token)
      .then((session = {}) => {
        // if we have an oid, so we want to end up with the right scoped session
        if (oid) {
          if (session.type === "SCOPED") {
            if (session.oid === oid) {
              // everything seems good
              return session;
            } else {
              // oh shit, we're in the wrong org, let's change that
              return createScopedSession({ oid }).catch(err => {
                // if that doesn't work, just try to create an unscoped session
                return createUnscopedSession();
              });
            }
          } else {
            // we got an unscoped session back, but we have an OID so we should try getting a scoped session
            return createScopedSession({ oid }).catch(err => {
              // we couldn't get a scoped session, so return the consolation prize of our original unscoped session
              return session;
            });
          }
        } else {
          // we didn't have a target OID, so just return whatever we got
          return session;
        }
      })
      .catch(err => {
        if (oid) {
          // refreshing didn't work, but we know where we want to go,
          // so try to create a scoped session for that place
          return createScopedSession({ oid }).catch(err => {
            // but if that doesn't work, just try to create an unscoped session
            return createUnscopedSession();
          });
        } else {
          // we didn't have a valid token, and we don't know what scope we want
          // so just create an unscoped session and roll with it
          return createUnscopedSession();
        }
      });
  } else if (oid) {
    // we have a scope, but no token (probably a cold start from a URL oid)
    // we try to create a scoped session (which will use the skiff cookie + the oid)
    return createScopedSession({ oid }).catch(err => {
      // but if that doesn't work, just try to create an unscoped session
      return createUnscopedSession();
    });
  } else {
    // we have neither a token nor a scope (pure cold start)
    return createUnscopedSession();
  }
};

const parseScopeInformation = () => {
  const accounts_token = JsCookie.get(accounts_cookie);
  const local_session = parseLocalStorage("session");
  const local_token = local_session && local_session.token;

  const local_session_oid = local_session && local_session.oid;
  const local_org = parseLocalStorage("org");
  const route_oid = parseRouteOid();
  const local_oid = local_org && local_org.id;
  const cookies_oid = parseCookiesOid();

  const token = accounts_token || local_token || undefined;
  const oid = route_oid || local_session_oid || local_oid || cookies_oid || undefined;

  if (oid !== local_session_oid) clearLocalStorage("session");
  if (oid !== local_oid) clearLocalStorage("org");
  if (oid !== cookies_oid) Cookies.clear("gt_oid");

  return hydrateSession(token, oid)
    .then(session => {
      storeSession(session);

      // Clear out accounts_token so we can differentiate between
      // login and reloads to be sent to Mixpanel. This returns the type
      // since Mixpanel isn't initialized yet and is used in main.coffee
      if (accounts_token || !token) {
        JsCookie.remove(accounts_cookie, { domain: "evertrue.com" });
        return "login";
      } else {
        return "refresh";
      }
    })
    .catch(error => {
      console.warn("App Startup Error", error);
      wipeSession();
    });
};

const runStartupRoutine = () => {
  return isUnauthedRoute() ? Promise.resolve() : parseScopeInformation();
};

export default runStartupRoutine;
