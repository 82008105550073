import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "@evertrue/et-flux";
import _ from "underscore";
import SolicitorInviteSource from "apps/volunteers/sources/solicitor-invite-source";
import SolicitorInviteStore from "apps/volunteers/stores/solicitor-invite-store";

const mapStateToProps = () => ({
  invites: SolicitorInviteStore.getSentInvites(),
});

class InvitesResolver extends Component {
  static propTypes = {
    contactIds: PropTypes.array,
    render: PropTypes.func,
    invites: PropTypes.object,
  };

  static defaultProps = {
    contactIds: [],
    query: () => null,
    render: () => null,
    invites: {},
  };

  componentDidMount() {
    this.fetchInvites(this.props.contactIds);
  }

  componentDidUpdate(prevProps) {
    const id_change = !_.isArrayEqual(prevProps.contactIds, this.props.contactIds);
    if (id_change) {
      this.fetchInvites(this.props.contactIds);
    }
  }

  fetchInvites(contactIds) {
    if (contactIds.length) {
      SolicitorInviteSource.fetchIfNotCached(contactIds);
    }
  }

  render() {
    return this.props.render({
      invites: this.props.invites,
    });
  }
}

export default connect(
  InvitesResolver,
  [SolicitorInviteStore],
  mapStateToProps
);
