import { Component } from "react";
import PropTypes from "prop-types";
import _ from "underscore";
import moment from "moment";
import CustomFieldStringDropdown from "apps/interactions/components/custom-field-string-dropdown";
import CalendarPickerDropdown from "components/controls/calendars/calendar-picker-dropdown";
import FormField from "components/forms/form-field";
import { IconButton, TextInput, NumericInput, BooleanRadio } from "@evertrue/et-components";
import { formatDateToUnixWithCurrentTime } from "base/new-utils";

class InteractionCustomFieldsFormElement extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    interactionCustomFields: PropTypes.object,
    orgCustomFields: PropTypes.array,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    orgCustomFields: [],
    clearCustomField: _.noop,
  };

  clearCustomField = (id) => {
    this.props.onChange(_.omit(this.props.interactionCustomFields, id));
  };

  renderField = ({ field, current_field_value, handleChange, clearButton }) => {
    const { disabled } = this.props;
    switch (field.dataType) {
      case "LONG":
        return (
          <div className="interaction-custom-fields-form--small-input">
            <NumericInput
              disabled={disabled}
              placeholder="0"
              value={current_field_value}
              onChange={(val) => {
                handleChange(val);
              }}
            />
          </div>
        );

      case "DATE":
        // Hack for when we were saving custom dates in seconds, if you have one of those dates, make sure
        // if gets changed into milliseconds
        if (current_field_value && current_field_value.toString().length < 13) {
          current_field_value = current_field_value * 1000;
        }
        return (
          <div className="interaction-custom-fields-form--input">
            <CalendarPickerDropdown
              disabled={disabled}
              value={current_field_value ? moment(current_field_value).format("YYYY-MM-DD") : null}
              onChange={(val) => {
                const date = formatDateToUnixWithCurrentTime(val);
                if (!val) this.clearCustomField(field.id);
                else if (date) handleChange(date);
              }}
            />
            {current_field_value ? clearButton : undefined}
          </div>
        );

      case "DOUBLE":
        return (
          <div className="interaction-custom-fields-form--small-input">
            <NumericInput
              disabled={disabled}
              allowDecimal={true}
              value={current_field_value}
              maskType="currency"
              onChange={handleChange}
              showIcon={true}
            />
          </div>
        );
      case "STRING":
        if (field.uiControlType === "FREEFORM") {
          return <TextInput value={current_field_value} onChange={handleChange} maxLength={500} />;
        } else {
          return (
            <div className="interaction-custom-fields-form--input">
              <CustomFieldStringDropdown
                disabled={disabled}
                onChange={handleChange}
                value={current_field_value}
                fieldId={field.id}
              />
              {current_field_value ? clearButton : undefined}
            </div>
          );
        }

      case "BOOLEAN":
        return (
          <div className="interaction-custom-fields-form--input">
            <BooleanRadio
              name="BooleanRadio"
              onChange={(name, bool) => handleChange(bool)}
              disabled={disabled}
              value={current_field_value}
            />
            {current_field_value === true || current_field_value === false ? clearButton : undefined}
          </div>
        );
      default:
        return null;
    }
  };

  render() {
    const { orgCustomFields } = this.props;
    return (
      <div>
        {_.map(orgCustomFields, (field = {}) => {
          const current_field_value = this.props.interactionCustomFields[field.id];
          const currentOrgField = _.findWhere(orgCustomFields, { id: field.id }) || {};
          const handleChange = (change_val) => {
            if (change_val === null || change_val === "") {
              this.props.onChange(_.omit(this.props.interactionCustomFields, field.id));
            } else {
              this.props.onChange({ ...this.props.interactionCustomFields, [field.id]: change_val });
            }
          };

          const clearButton = (
            <IconButton
              icon="cancel"
              title="Clear field"
              className="interaction-custom-fields-form--cancel"
              tooltip="Clear"
              onClick={() => {
                this.clearCustomField(field.id);
              }}
            />
          );

          return (
            <div key={field.id}>
              <FormField label={startCase(field.displayName)} inlineLabel={false} required={field.required}>
                {this.renderField({ field, current_field_value, handleChange, clearButton, currentOrgField })}
              </FormField>
            </div>
          );
        })}
      </div>
    );
  }
}

function startCase(str) {
  return str.replace(/\b\w/g, function (char) {
    return char.toUpperCase();
  });
}


export default InteractionCustomFieldsFormElement;
