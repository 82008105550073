import PropTypes from "prop-types";
import IsGated from "components/is-gated";
import { useOpenClose, Button } from "@evertrue/et-components";
import JourneyModal from "../modals/journey-modal";

const AddJourneyButton = ({ handleCreateJourney = () => {} }) => {
  const [isModalOpen, openModal, closeModal] = useOpenClose();

  // TODO: UNGATE
  return (
    <IsGated feature="unreleased_feature">
      <Button onClick={openModal}>Add Cadence</Button>
      <JourneyModal isOpen={isModalOpen} onClose={closeModal} handleCreateJourney={handleCreateJourney} />
    </IsGated>
  );
};

AddJourneyButton.propTypes = {
  handleCreateJourney: PropTypes.func,
};

export default AddJourneyButton;
