module.exports = do ->
   _ = require("underscore").default
   classNames = require("classnames")
   EverTrue = require("app")
   React = require("react")
   Decorator = require("clientDecorator")
   FacetStore = require("apps/filters/stores/facet-store")
   FacetSource = require("apps/filters/sources/facet-source")
   AssignProspectSource = require("apps/major-gifts/sources/assign-prospect-source")
   AssignProspectStore = require("apps/major-gifts/stores/assign-prospect-store")
   AllUsersStore = require("apps/users/stores/all-users-store").default
   FullContactStore = require("apps/contact/stores/full-contact-store")
   FullContactSource = require("apps/contact/sources/full-contact-source")
   {createComponent} = require("@evertrue/et-flux")
   {div, table, thead, th, tbody, tr, td} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   ContactCard = createFactory(require("apps/contact/components/contact-card/contact-card"))
   ModalConfirm = createFactory require("components/modals/modal-confirm")
   AdvancedCombobox = createFactory(require("@evertrue/et-components").AdvancedCombobox)
   ProspectStatusDropdown = require("apps/major-gifts/components/prospect-status-dropdown")
   Icon = createFactory (require("components/elements/icon").default)
   Loading = require("components/elements/loading")
   Button= createFactory require("@evertrue/et-components").Button
   LinkButton = createFactory(require("@evertrue/et-components").LinkButton)


   ASSIGNEE_STATUS = "assignees.status.untouched"
   ASSIGNEE_TYPE = "assignees.type"
   DROPDOWN_CLASSES = "assign-contact--dropdown advanced-combobox-secondary"
   GT_DEMO_TYPE_FACETS = [
      {value: "Volunteer"},
      {value: "Annual Fund"},
      {value: "Major Gifts"},
      {value: "Prospect Manager"}
   ]

   _parseAssignees = (full_contact) ->
      _.map full_contact?.assignees, (assignee_object) ->
         parsed_assignee = {id: assignee_object.id}
         _.each assignee_object.properties, (prop) ->
            parsed_assignee[prop.name] = prop.value
         parsed_assignee


   createComponent "AssignContactModal",
      propTypes:
         contact: ReactLibs.PropTypes.any

      getInitialState: ->
         search_query: undefined
         temp_assignee_data: []

      registerStores: ->
         @on FacetStore, ->
            facets_loading: FacetStore.getLoading()
            status_facets: FacetStore.getByKey(ASSIGNEE_STATUS)
            type_facets: FacetStore.getByKey(ASSIGNEE_TYPE)

         @on AssignProspectStore, ->
            is_saving: AssignProspectStore.getIsSaving()

         @on AllUsersStore, ->
            users: AllUsersStore.getAllGivingTreeUsers()
            loading_users: AllUsersStore.getLoading()

         @on FullContactStore, "contact", (props) ->
            full_contact: FullContactStore.getFullContact(props.contact?.id)
            is_loading_contact: FullContactStore.getIsLoading()

      componentDidMount: ->
         FacetSource.fetch([ASSIGNEE_STATUS, ASSIGNEE_TYPE])
         if @props.contact?.id
            FullContactSource.getFullContact(@props.contact?.id)
         @setState {temp_assignee_data: _parseAssignees(@state.full_contact)}

      componentDidUpdate: (prevProps, prevState) ->
         if prevState.is_saving && !@state.is_saving
            @props.requestHide?()

         if !_.isEqual(prevState.full_contact, @state.full_contact)
            @setState {temp_assignee_data: _parseAssignees(@state.full_contact)}

      getOptions: (facets) ->
         options = [{label: "None", value: null}]
         _.each facets, (facet) ->
            options.push {label: facet.value, value: facet.value}
         options

      getTypeOptions: ->
         # If GT Demo, show default types to help people set up fake portfolios
         options = if EverTrue.store.org.get("id") is 429
            @getOptions(GT_DEMO_TYPE_FACETS)
         else @getOptions(@state.type_facets)

         return options.filter((type) -> type.value != "Volunteer" && type.value != "Volunteer Lead")

      getUserOptions: (users) ->
         user_options = _.compact _.map users, (user) ->
            {label: user.name, value: user.id}
         user_options = _.sortBy user_options, "label"
         user_options.unshift {label: "None", value: null}
         user_options

      handleSave: ->
         # Mapping UI logic to format for the api
         AssignProspectSource.updateForContact(@state.full_contact?.id, @state.temp_assignee_data)

      handleChangeAssignee: (assignee_id, name, user_id) ->
         user =  _.findWhere(@state.users, {id: user_id})
         oid = EverTrue.store.org.get("id")

         temp_assignee_data = _.cloneData @state.temp_assignee_data
         assignee_id = _.findWhere temp_assignee_data, {id: assignee_id}
         _.extend assignee_id,
            name: name
            user_id: user_id
            remote_user_id: Decorator.User.getAffiliationFor(user, oid)?.remote_user_id

         @setState {temp_assignee_data: temp_assignee_data}

      handleChange: (assignee_id, key, value) ->
         temp_assignee_data = _.cloneData @state.temp_assignee_data
         assignee_id = _.findWhere temp_assignee_data, {id: assignee_id}
         assignee_id?[key] = value
         @setState {temp_assignee_data: temp_assignee_data}

      handleRemove: (id) ->
         @setState
            temp_assignee_data: _.reject _.cloneData(@state.temp_assignee_data), (assignee) ->
               assignee.id == id

      handleAddAssignee: (can_add) ->
         return unless can_add
         temp_assignee_data = _.cloneData @state.temp_assignee_data
         temp_assignee_data.push({id: "temp_#{_.randomKey()}"})
         @setState {temp_assignee_data: temp_assignee_data}

      render: ->
         can_add_row = _.every @state.temp_assignee_data, (assignee) ->
            !_.isEmpty(_.omit(assignee, "id"))

         ModalConfirm
            className: "assign-contact--modal"
            type: "success"
            header: "Assign Constituent"
            buttonLabel: "Save"
            onSubmit: @handleSave
            onCancel: @props.requestHide
            hide_close_icon: true
            is_saving: @state.is_saving
            disableButton: !can_add_row

            div className: "assign-contact--header",
               ContactCard contact: @props.contact

            div className: "assign-contact--body",
               if @state.is_loading_contact
                  Loading()
               else
                  table className: "assign-contact--table",
                     thead null,
                        tr null,
                           th null
                           th null, "Solicitor"
                           th null, "Solicitor Type"
                           th null, "Prospect Status"
                           th null

                     tbody null,
                        _.map @state.temp_assignee_data, (assignee, i) =>
                           tr key: assignee.id, className: "assign-contact--row",
                              td className: "assign-contact--cell-shrink emphasis",
                                 "Solicitor #{i+1}"

                              td className: "assign-contact--cell",
                                 AdvancedCombobox
                                    searchable: true
                                    className: classNames(DROPDOWN_CLASSES, "is-empty": !assignee.name)
                                    value: {value: assignee.user_id, label: assignee.name}
                                    options: @getUserOptions(@state.users)
                                    loading: @state.loading_users
                                    onChange: (val) => @handleChangeAssignee(assignee.id, val.label, val.value)

                              td className: "assign-contact--cell assign-contact--last-dd",
                                 AdvancedCombobox
                                    searchable: true
                                    className: classNames(DROPDOWN_CLASSES, "is-empty": !assignee.type)
                                    value: {value: assignee.type}
                                    options: @getTypeOptions()
                                    loading: @state.facets_loading
                                    onChange: (val) => @handleChange(assignee.id, "type", val.value)

                              td className: "assign-contact--cell",
                                 ProspectStatusDropdown
                                    className: classNames(DROPDOWN_CLASSES, "is-empty": !assignee.status)
                                    value: {value: assignee.status}
                                    includeNone: true
                                    onChange: (val) =>
                                       @handleChange(assignee.id, "status", val.value)

                              td className: "assign-contact--cell-shrink",
                                 Button type: "simple", title: "Remove assignment", onClick: ((=> @handleRemove(assignee.id))),
                                    Icon icon: "cancel", className: "assign-contact---remove-row"

               # Add additional assignee
               div className: classNames("assign-contact--new-row", "disabled": !can_add_row),
                  LinkButton onClick: ((=> @handleAddAssignee(can_add_row))), title: "Add a Solicitor",
                     Icon icon: "add-circle", className: "assign-contact--add-icon"
                     "Add a Solicitor"
