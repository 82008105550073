/* eslint-disable indent */
import PropTypes from "prop-types";
import Decorator from "clientDecorator";
import classNames from "classnames";
import Avatar from "components/elements/avatar";
import ContactCard from "apps/contact/components/contact-card/contact-card";
import get from "lodash.get";
import { Link, Icon } from "@evertrue/et-components";

const JointContactCard = ({ is_joint, is_key_prospect, contact, secondary, children, showNameLink }) => {
  const joint_name = Decorator.Contacts.getFullName(secondary || {});
  const primary_name = Decorator.Contacts.getFullName(contact || {});
  const is_deceased = get(contact, "properties.deceased.value");
  const is_secondary_deceased = get(secondary, "properties.deceased.value");

  return (
    <ContactCard
      showNameLink={showNameLink}
      avatar={
        is_joint
          ? avatar_url => (
              <div className="pool-prospect-card--joint-avatars">
                <Avatar
                  name={primary_name}
                  className="pool-prospect-card--primary-avatar"
                  url={avatar_url}
                  width={48}
                />
                <Avatar
                  name={joint_name}
                  className="pool-prospect-card--secondary-avatar"
                  url={secondary.avatar}
                  width={48}
                />
              </div>
            )
          : null
      }
      showClassYear={!is_joint}
      className="pool-prospect-card--card"
      contact={contact}
      name={
        is_joint
          ? (name, prospect_link) => (
              <span>
                <span className={classNames({ "is-deceased": is_deceased })}>
                  {prospect_link}
                  {is_deceased ? <span className="new-contact-card--deceased">Deceased</span> : undefined}
                </span>
                {" | "}
                {showNameLink ? (
                  <Link
                    title={`Link to ${joint_name}'s profile`}
                    href={`/contact/${secondary != null ? secondary.id : undefined}`}
                  >
                    <strong className={classNames({ "is-deceased": is_secondary_deceased })}>{joint_name}</strong>
                  </Link>
                ) : (
                  <strong className={classNames({ "is-deceased": is_secondary_deceased })}>{joint_name}</strong>
                )}
                {is_secondary_deceased ? <span className="new-contact-card--deceased">Deceased</span> : undefined}
                {is_key_prospect && <Icon icon="key-relationship" className="pool-prospect-card--key-icon" />}
              </span>
            )
          : (name, prospect_link) => (
              <span className={classNames({ "is-deceased": is_deceased })}>
                {prospect_link}
                {is_deceased ? <span className="new-contact-card--deceased">Deceased</span> : undefined}
                {is_key_prospect && <Icon icon="key-relationship" className="pool-prospect-card--key-icon" />}
              </span>
            )
      }
    >
      {children}
    </ContactCard>
  );
};

JointContactCard.propTypes = {
  contact: PropTypes.object,
  secondary: PropTypes.object,
  is_joint: PropTypes.bool,
  is_key_prospect: PropTypes.bool,
  showNameLink: PropTypes.bool,
  children: PropTypes.any,
};

export default JointContactCard;
