_ = require("underscore").default
EverTrue = require("app")
React = require("react")
{div, span} = ReactLibs.DOMFactories
{createComponent} = require("@evertrue/et-flux")
ProfileStore = require("apps/profile/stores/profile-store")
ProfileSource = require("apps/profile/sources/profile-source")
Popover = require("components/overlays/popover")
{createFactory} = require("base/new-utils")
Modal = createFactory require("components/modals/modal")
ModalTrigger = createFactory require("components/modals/modal-trigger")
ModalTrigger2 = createFactory require("@evertrue/et-components").ModalTrigger2
GoogleSearch = require("apps/profile/components/google-search")
EventbriteEmailsModal = require("apps/contact/components/profile/eventbrite-emails-modal")
EventbriteMatchFetcher = createFactory require("apps/profile/fetchers/eventbrite-match-fetcher").default
IsGated = createFactory require("components/is-gated").default
Decorator = require("clientDecorator")
Loading = require("components/elements/loading")
Link = createFactory(require("@evertrue/et-components").Link)
Icon = createFactory(require("@evertrue/et-components").Icon)
FacebookOutageModal = createFactory require("apps/facebook/components/facebook-outage-modal").default
FacebookMatchManage = createFactory require("apps/facebook/components/facebook-match-manage").default
ButtonTooltip = createFactory(require("@evertrue/et-components").ButtonTooltip)

module.exports = createComponent "SocialActions",
   propTypes:
      contact: ReactLibs.PropTypes.object

   getDefaultProps: ->
      contact: {}

   handleActionClick: ->
      EverTrue.track.setReferrer("profile_social")

   registerStores: ->
      @on ProfileStore, ->
         has_facebook_match: ProfileStore.hasFacebookMatch(@props.contact.id)
         loading_lids: ProfileStore.getLoadingLids()

   renderOutageModal: ->
      Modal width: 500,
         FacebookOutageModal()

   renderLinkedinSearchPopover: (name) ->
      link = "https://www.linkedin.com/search/results/all/?keywords=" + name.replace(" ", "%20")
      div className: "social-actions--popover",
         Link title: "Search for #{name} on LinkedIn", href: link, target: "_blank",
            "Search for \"#{name}\" on LinkedIn"

   renderFacebookManageButton: ->
      div
         className: "social-actions--button"
         role: "button"
         Icon
            icon: if @state.has_facebook_match then "facebook-box icon-facebook-brand" else "facebook-box"
         "Manage Facebook Page Match"

   renderFacebookManageModal: ->
      Modal width: 568,
         FacebookMatchManage contact: @props.contact

   render: ->
      { contact } = @props
      { loading_lids } = @state

      name = Decorator.Contacts.getFullName(contact)

      div className: "social-actions loading-container",
         if loading_lids then Loading()

         div onClick: @handleActionClick,
            # GOOGLE
            Popover
               trigger:
                  div className: "social-actions--button",
                     span className: "google-search--icon"
                     "Google \""
                     span className: "fs-exclude-data", name
                     "...\""
                     Icon icon: "chevron-down", className: "social-actions--button-arrow"
               GoogleSearch contact: contact

            # LINKEDIN
            Popover
               trigger: div className: "social-actions--button",
                  Icon icon: "linkedin-box icon-linkedin-brand"
                  "Find on LinkedIn"
                  Icon icon: "chevron-down", className: "social-actions--button-arrow"
               @renderLinkedinSearchPopover(name)

            # FACEBOOK
            if !@state.has_facebook_match
               ButtonTooltip
                  triggerClassName: "facebook-no-match-button"
                  children:
                     div className: "facebook-no-match",
                        "This person isn't matched to any Facebook Pages. Visit the Facebook tab to manually match engagers."
                  trigger: @renderFacebookManageButton()
            else
               ModalTrigger modal: @renderFacebookManageModal(), title: "Manage Facebook Page Match",
                  @renderFacebookManageButton()


            # EVENTBRITE
            IsGated feature: "events_eventbrite",
               EventbriteMatchFetcher
                  has_eventbrite: true
                  contact_id: _.toNumber(contact.id)

                  (eventBriteProps) =>
                     ModalTrigger2
                        closeOnOutsideClick: false
                        trigger: ({ open }) ->
                           div
                              className: "social-actions--button"
                              onClick: open
                              Icon
                                 icon: "eventbrite icon-eventbrite-brand"
                                 className: if !eventBriteProps.has_eventbrite_match then "unmatched" else ""
                              "Manage Eventbrite Emails"
                        children: ({ close }) =>
                           { refresh, engagementStatsByEmail, profiles, error } = eventBriteProps
                           EventbriteEmailsModal
                              close: close
                              contact: @props.contact
                              engagementStatsByEmail: engagementStatsByEmail
                              profiles: profiles
                              error: error
                              refreshProfiles: =>
                                 refresh()
                                 ProfileSource.fetch(@props.contact.id)
