import { Button, Icon } from "@evertrue/et-components";
import { Loading } from "@evertrue/et-components";
import useEmailer from "components/emailer/hooks/use-emailer";
import GoogleDisclosure from "components/emailer/google-disclosure";

const ProfileEmailerController = () => {
  const { loading, emailerIsConnected, handleConnectEmail, handleDisconnectEmail } = useEmailer();

  if (loading) return <Loading spinner_size="small" />;
  return (
    <div style={{ maxWidth: "535px" }}>
      <h3 className="module--header-title-gray">Email Outreach Account</h3>
      <p>Connect your preferred email account to send emails</p>
      {emailerIsConnected ? (
        <div className="profile--email-status">
          <div>
            <Icon icon="check-circle" className="email-status-check" />
            <strong>Account connected!</strong>
          </div>
          <Button type="secondary" onClick={handleDisconnectEmail}>
            Disconnect
          </Button>
        </div>
      ) : emailerIsConnected !== null ? (
        <div className="profile--email-status">
          <em>Connect your account</em>
          <Button type="secondary" onClick={handleConnectEmail}>
            Connect
          </Button>
        </div>
      ) : (
        <h2>There was a problem fetching your credentials.</h2>
      )}
      <GoogleDisclosure style={{ marginTop: "40px" }} />
    </div>
  );
};

export default ProfileEmailerController;
