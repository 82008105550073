module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {div, p, h2, img} = ReactLibs.DOMFactories
   StatusStore = require("apps/layout/stores/status-store")
   StatusSource = require("apps/layout/sources/status-source")
   {createFactory} = require("base/new-utils")
   Link = createFactory(require("@evertrue/et-components").Link)

   createComponent "StatusController",
      registerStores: ->
         @on StatusStore, ->
            is_down: StatusStore.isDown()

      componentDidMount: ->
         @pollForUpdates()

      componentDidUpdate: (prevProps, prevState) ->
         if @state.is_down == false && prevState.is_down != @state.is_down
            EverTrue.execute("goToInitialPage")

      componentWillUnmount: ->
         clearTimeout(@timeout)

      pollForUpdates: ->
         StatusSource.check()
         @timeout = _.delay =>
            @pollForUpdates()
         , 20000

      render: ->
         div className: "status-page",
            img src: "/images/outage.png"
            h2 null, "EverTrue is experiencing outages"
            p null, "The EverTrue team is aware of the problem and working quickly to fix it."
            p null,
               "Check out our "
               Link title: "EverTrue's status page", href: "https://evertrue.statuspage.io", target: "_BLANK", "status page"
               " for more updates"
