import { useState } from "react";
import { PropTypes } from "base/react-libs";
import { Loading } from "@evertrue/et-components";
import SideBar from "components/animations/side-bar";
import DueDatePicker from "../top-nav/due-date-picker";
import ContactSummary from "apps/contact/components/contact-summary/contact-summary";
import JourneysBanner from "../top-nav/journeys-banner";
import JourneyDetailTable from "./journey-detail-table/journey-detail-table";
import JourneyPauseContainer from "../top-nav/journeys-pause-container";

const JourneyDetail = ({
  user = {},
  journey = {},
  userIsCreator,
  loadingJourney = false,
  selectedContact,
  setSelectedContact = () => {},
  selectedDueDate,
  setSelectedDueDate = () => {},
  optionalStateManagementFunction = () => {},
}) => {
  const [viewSideBar, setViewSideBar] = useState(false);
  const closeSideBar = () => {
    setViewSideBar(false);
  };
  const openContactSummary = (contact) => {
    setSelectedContact(contact);
    setViewSideBar(true);
  };

  return loadingJourney ? (
    <Loading />
  ) : (
    <>
      <JourneysBanner
        journey={journey}
        userIsCreator={userIsCreator}
        optionalStateManagementFunction={optionalStateManagementFunction}
      />
      <div style={{ paddingLeft: "16px" }}>
        <DueDatePicker selectedDueDate={selectedDueDate} setSelectedDueDate={setSelectedDueDate} />
      </div>
      <JourneyPauseContainer />
      <JourneyDetailTable
        user={user}
        journey={journey}
        selectedContact={selectedContact}
        userIsCreator={userIsCreator}
        openContactSummary={openContactSummary}
        optionalStateManagementFunction={optionalStateManagementFunction}
      />
      <SideBar
        start={viewSideBar}
        closeSideBar={closeSideBar}
        speed={1000}
        mountOnEnter={true}
        unmountOnExit={true}
        enableEnterAnimations={true}
        enableExitAnimations={true}
        enableAppearAnimations={true}
        onExited={() => setSelectedContact(null)}
      >
        <ContactSummary contactFromGql={selectedContact} />
      </SideBar>
    </>
  );
};

JourneyDetail.propTypes = {
  user: PropTypes.object,
  journey: PropTypes.object.isRequired,
  userIsCreator: PropTypes.bool,
  loadingJourney: PropTypes.bool,
  selectedContact: PropTypes.object,
  setSelectedContact: PropTypes.func,
  selectedDueDate: PropTypes.number,
  setSelectedDueDate: PropTypes.func,
  optionalStateManagementFunction: PropTypes.func,
};

export default JourneyDetail;
