module.exports = do ->
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories
   RecentContactStore = require("apps/contact/stores/recent-contact-store")
   RecentContactSource = require("apps/contact/sources/recent-contact-source")
   SelectedContactsSource = require("apps/contact/sources/selected-contacts-source")
   SelectedContactsStore = require("apps/contact/stores/selected-contacts-store")
   {createFactory} = require("base/new-utils")
   ContactFixedTable = createFactory(require("apps/contact/components/contacts/contact-fixed-table").default)
   ListSelectorController = createFactory(require("apps/lists/controllers/lists-selector-controller").default)
   ContactTableToolbar = createFactory(require("apps/contact/components/contacts/table/contact-table-toolbar").default)
   ActionMenuItem = createFactory(require("components/controls/action-menu-item").default)
   Modal = createFactory require("components/modals/modal")
   ModalConfirm = createFactory require("components/modals/modal-confirm")
   ContactsPageHeader = createFactory(require("apps/contact/components/contacts/contacts-page-header.js").default)

   ACTIVE_FILTER_HEIGHT = 75
   TOOLBAR_HEIGHT = 68


   createComponent "RecentContactTable",
      getInitialState: ->
         enrichmentLoading: true

      registerStores: ->
         @on RecentContactStore, ->
            loading: RecentContactStore.getLoading()
            contacts: RecentContactStore.getContacts()

         @on SelectedContactsStore, ->
            selected_contacts: SelectedContactsStore.getSelectedContacts()

      componentDidMount: ->
         RecentContactSource.fetch()

      setEnrichmentLoading: (bool) -> 
        @setState({enrichmentLoading: bool})

      handlePage: (page) ->
         RecentContactSource.changePage(page)
         SelectedContactsSource.clear()
         @setEnrichmentLoading(true)

      render: ->
         num_selected_contacts = @state.selected_contacts?.length
         constituent_text = if num_selected_contacts == 1 then "Constituent" else "Constituents"

         div null,
            ContactsPageHeader
               totalResults: @state.contacts?.total
               numSelectedContacts: num_selected_contacts
               contactIds: @state.selected_contacts
               loading: @state.loading

            div
               className: "fixed-page-wrapper"
               style: {top: ACTIVE_FILTER_HEIGHT}
               ContactTableToolbar contacts: @state.contacts, onPage: @handlePage

               div
                  className: "fixed-page-wrapper"
                  style: {top: TOOLBAR_HEIGHT}
                  ContactFixedTable
                     contacts: @state.contacts
                     loading: @state.loading
                     setEnrichmentLoading: @setEnrichmentLoading
                     enrichmentLoading: @state.enrichmentLoading
