import PropTypes from "prop-types";
import { connect } from "@evertrue/et-flux";
import DNAStore from "base/dna/dna-store";

const mapStateToProps = ({ DNAKey }) => {
  return {
    value: DNAStore.getByKey(DNAKey),
    user_value: DNAStore.getUserSettingByKey(DNAKey),
  };
};

const DNAComponent = ({ value, user_value, children }) => {
  return children({ value, user_value });
};

const DNAResolver = connect(
  DNAComponent,
  [DNAStore],
  mapStateToProps
);

DNAResolver.propTypes = {
  children: PropTypes.func,
  DNAKey: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export const WithDNA = (WrappedComponent, DNAKey) => props => (
  <DNAResolver DNAKey={DNAKey}>
    {({ value, user_value }) => <WrappedComponent DNAKey={value} DNAUserSetting={user_value} {...props} />}
  </DNAResolver>
);

export default DNAResolver;
