module.exports = do ->
   _ = require("underscore").default
   moment = require("moment")
   {createStore} = require("@evertrue/et-flux")
   ScheduledExportsSource = require("apps/settings/export-settings/sources/scheduled-exports-source")
   ExportTypeConfig = require("apps/export/export-type-config").default

   createStore "ScheduledExportStore",
      getInitialState: ->
         exports_by_type: {}

      registerActions: ->
         @on ScheduledExportsSource.actions.fetchedExports, (exports) ->
            if exports.length
               exports_by_type = _.cloneData(@getState("exports_by_type"))
               _.each exports, (single_export) =>
                  formatted = @formatExport(single_export)
                  config = _.indexBy(ExportTypeConfig, "job")
                  if job_config = config[single_export.job?.jobClass]
                     exports_by_type[job_config.key] = formatted
                     @setState {exports_by_type}
                  else console.error("ExportTypeConfig not found for #{single_export.job?.jobClass}")

         @on ScheduledExportsSource.actions.cancelScheduledExport, (id, exportType) ->
            exports_by_type = _.cloneData(@getState("exports_by_type"))
            exports_by_type[exportType] = undefined
            @setState {exports_by_type}

      formatExport: (single_export) ->
         { job: {key: {name}, jobDataMap: {userId: user_id}} } = single_export
         {
            previousFireTime,
            startTimeOfDay: {hour, minute}
            jobDataMap: {timezone}
         } = single_export.triggers[0] # in future there will be more 'triggers' but ATM just is first one

         # moment requires a date to work w/ strings
         # so just choosing today's date here
         today = moment().format("YYYY-MM-DD")
         hour = if hour.toString().length == 1 then "0#{hour}" else hour.toString()
         minute = if minute.toString().length == 1 then "0#{minute}" else minute.toString()
         # stored in DB as UTC so lets tell moment ab that
         utc = moment.tz("#{today} #{hour}:#{minute}", "UTC")
         # then convert
         formatted_time = utc.clone().tz(timezone)
         scheduled = if formatted_time?.isValid() then formatted_time.format("hh:mm A") else ""
         last_export =
            if previousFireTime
               moment(previousFireTime).format("MMMM Do YYYY, h:mm A")
            else ""

         return _.extend({}, single_export, {
            key: name,
            scheduled,
            last_export,
            user_id,
            timezone: timezone || ""
         })

      api:
         getExports: ->
            @getState("exports_by_type")
