numeral = require("numeral")
React = require("react")
{createComponent} = require("@evertrue/et-flux")
{div, span, strong} = ReactLibs.DOMFactories
classNames = require("classnames")


module.exports = createComponent "SelectedContactsString",
   propTypes:
      selected: ReactLibs.PropTypes.number
      results: ReactLibs.PropTypes.number
      label: ReactLibs.PropTypes.string
      additional_text: ReactLibs.PropTypes.string

   getDefaultProps: ->
      label: "constituents"

   render: ->
      if !@props.loading
         div className: classNames(@props.className, "selected-contacts-string"),
            if @props.selected
               span null,
                  strong null, numeral(@props.selected).format("0,0")
                  span null, " of "
            strong null, numeral(@props.results).format("0,0")
            span null, " #{@props.label} #{if @props.selected then "selected" else ""}"
            if @props.additional_text
               span null, @props.additional_text
      else div null
