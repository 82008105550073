import { useIdentity } from "base/identity-resolver";
import { useState, useEffect } from "react";
import { getAndCreateRecommendedActionsForSection } from "apps/home/utils/api-requests/recommended-actions/use-recommended-actions-requests";
import { getUserContactIdForOrg } from "apps/tasks/utils/contact-helpers";

const useRecommendedActions = (section, enabled, giftAmount) => {
  const [loading, setLoading] = useState(false);
  const [recommendedActions, setRecommendedActions] = useState([]);
  const { user, org } = useIdentity();

  useEffect(() => {
    const getAndSetDataForSection = async () => {
      const userSolicitorContactId = getUserContactIdForOrg(user, org);
      if (!userSolicitorContactId || !section || !enabled) return;
      setLoading(true);
      const recs = await getAndCreateRecommendedActionsForSection(section, userSolicitorContactId, giftAmount);
      setLoading(false);
      setRecommendedActions(recs);
    };

    getAndSetDataForSection();
  }, [org, user, section, enabled, giftAmount]);

  return {
    loading,
    recommendedActions,
  };
};

export default useRecommendedActions;
