module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   EverTrue = require("app")
   AuthApi = require("entities/auth/auth-api")
   PaginatedCollectionMixin = require("mixins/pagination-mixin")
   SearchQueryCollectionMixin = require("mixins/search-query-mixin")
   Query = require("entities/search/query-parts/query")
   Parameter = require("entities/search/query-parts/parameter-query")
   Property = require("entities/search/query-parts/property-query")


   # Profile of all users who have engaged with content
   # For example, all users who have engaged with a facebook profile, regardless of if they have a contact
   class SocialProfile extends AuthApi.Collection
      @include [PaginatedCollectionMixin, SearchQueryCollectionMixin]
      urlRoot: "search/v2/social_profile/search"

      parse: (data) ->
         @boundaries(data)
         _.map data.items, (item) ->
            item.id = item.remote_id
            # These two properties seem to be switched around
            item.facebook_profile_url = item.public_profile_photo_url
            item.avatar = item.public_profile_url
            item

      fetch: (options) ->
         opts = _.clone(options)
         opts.params = $.extend true, {}, @paginate(opts.params)
         opts = @queryOptions(opts)
         super opts

      config:
         name:
            query_fn: (value) ->
               value_parts = value.split(" ")
               prop_array = []
               prop_array.push(Property "name", value, {type: "wildcard"})
               _.each value_parts, (part) ->
                  prop_array.push(Property "name", part, {type: "wildcard"})
               Query [
                  Parameter "should", prop_array
               ]

         unmatched:
            query_fn: (value) ->
               Query [
                  Parameter "must", [
                     Property "contact_id", false, {type: "exists"}
                  ]
               ]
