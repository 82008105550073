import { useState, useEffect, useCallback } from "react";
import EverTrue from "app";
import Api from "entities/helpers/api";

const useProposalQueryBuilder = (
  limit,
  page,
  sortReverse,
  sortProp,
  prospectContactId,
  userContactId,
  filterYours,
  filterActive,
  filterStage,
  refetch
) => {
  const [proposalsData, setProposalsData] = useState({});
  const [loading, setLoading] = useState(true);

  const buildQuery = useCallback(() => {
    let filterQuery = [];

    if (prospectContactId) {
      filterQuery.push({ "contacts.contact_id": { match: prospectContactId } });
    }

    if (filterYours === "yours") {
      filterQuery.push({ "solicitors.contact_id": { match: userContactId } });
    } else if (filterYours === "key") {
      filterQuery.push({
        solicitors: {
          instance: {
            contact_id: { match: userContactId },
            key_proposal: { match: true },
          },
        },
      });
    }

    if (filterActive !== "all") {
      filterQuery.push({ active: { match: filterActive === "active" } });
    }

    if (filterStage !== "all") {
      filterQuery.push({ status: { match: filterStage } });
    }

    return filterQuery;
  }, [prospectContactId, userContactId, filterYours, filterActive, filterStage]);

  const buildSort = useCallback(() => {
    const order = sortReverse ? "desc" : "asc";
    return [{ [sortProp]: { order } }];
  }, [sortProp, sortReverse]);

  useEffect(() => {
    setLoading(true);
    const query = buildQuery();
    const sort = buildSort();

    Api.SEARCH.PROPOSAL.post({
      params: {
        offset: (page - 1) * limit,
        limit: limit,
      },
      data: JSON.stringify({
        must: query,
        sort,
      }),
      success: (resp) => {
        setProposalsData(
          Object.assign({}, resp, {
            items: resp.items,
            total: resp.total,
          })
        );
        setLoading(false);
      },
      error: () => {
        EverTrue.Alert.error("There was an error loading proposals");
        setLoading(false);
      },
    });
  }, [
    page,
    sortReverse,
    sortProp,
    prospectContactId,
    buildQuery,
    buildSort,
    limit,
    filterYours,
    filterActive,
    filterStage,
    refetch,
  ]);

  return { loading, proposalsData };
};

export default useProposalQueryBuilder;
