import _ from "underscore";
import { createStore } from "@evertrue/et-flux";
import OrgSource from "base/org/org-source";
import SocketSource from "apps/notifications/sources/socket-source";
import BulkAddSource from "apps/layout/sources/bulk-add-source";
import ListContactSource from "apps/lists/sources/list-contact-source";
import PoolsSource from "apps/volunteers/sources/pools-source";

const _getDefaultState = () => ({
  bulk_add_to_list: {},
  bulk_add_to_pool: {},
  bulk_vms_rollover: {},
  bulk_vms_delete: {},
});

export default createStore("BulkAddStore", {
  getInitialState() {
    return _getDefaultState();
  },

  firstListenerDidMount() {
    SocketSource.bindUser("bulk_add_to_list_progress");
    SocketSource.bindUser("bulk_add_prospects_to_pool_from_search_criteria");
    SocketSource.bindUser("bulk_add_solicitors_to_pool_from_search_criteria");
    SocketSource.bindUser("bulk_pool_fy_rollover_update_progress");
    SocketSource.bindUser("bulk_pool_delete_progress");
  },

  registerActions() {
    this.on(BulkAddSource.actions.clearList, () => {
      this.setState({ bulk_add_to_list: {} });
    });

    this.on(BulkAddSource.actions.clearPool, () => {
      this.setState({ bulk_add_to_pool: {} });
    });

    this.on(BulkAddSource.actions.addToPool, ({ count, pool_id, progress = 0, type }) => {
      this.setState({ bulk_add_to_pool: { ...this.state.bulk_add_to_pool, id: pool_id, progress, count, type } });
    });

    this.on(BulkAddSource.actions.addToList, ({ count, list_id }) => {
      if (_.isEmpty(this.state.bulk_add_to_list)) {
        this.setState({
          bulk_add_to_list: {
            count,
            id: list_id,
            progress: 0,
          },
        });
      }
      if (this.state.bulk_add_to_list.id === list_id) {
        this.setState({ bulk_add_to_list: { ...this.state.bulk_add_to_list, progress: 0, count } });
      }
    });

    this.on(BulkAddSource.actions.bulkVMSRollover, this.respondToBulkVMSRollover);
    this.on(BulkAddSource.actions.bulkVMSPoolDelete, this.respondToBulkVMSDelete);

    this.on(OrgSource.actions.newOrg, () => {
      this.setState(_getDefaultState());
    });

    this.on(ListContactSource.actions.bulkAddToListInProgress, ({ count, id }) => {
      if (this.state.bulk_add_to_list.id === id) {
        this.setState({ bulk_add_to_list: { ...this.state.bulk_add_to_list, progress: 0, count } });
      }
    });

    this.on(SocketSource.actions.bulk_add_to_list_progress, (response) => {
      this.setState({
        bulk_add_to_list: {
          ...this.state.bulk_add_to_list,
          progress: response.progress,
          id: response.object_id,
          in_progress: true,
        },
      });
    });

    this.on(SocketSource.actions.bulk_add_prospects_to_pool_from_search_criteria, this.respondToPoolSocket);
    this.on(SocketSource.actions.bulk_add_solicitors_to_pool_from_search_criteria, this.respondToPoolSocket);
    this.on(SocketSource.actions.bulk_pool_fy_rollover_update_progress, this.respondToBulkVMSRollover);
    this.on(SocketSource.actions.bulk_pool_delete_progress, this.respondToBulkVMSDelete);
  },

  respondToPoolSocket(response) {
    this.setState({
      bulk_add_to_pool: {
        ...this.state.bulk_add_to_pool,
        progress: response.progress,
        id: response.pool_id,
      },
    });
  },

  respondToBulkVMSRollover(response) {
    const { progress } = response;
    this.setState({
      bulk_vms_rollover: response,
    });
    if (progress === 1) {
      PoolsSource.fetchPools({ limit: 50 });
    }
  },

  respondToBulkVMSDelete(response) {
    this.setState({
      bulk_vms_delete: response,
    });
    if (response.progress === 1) {
      PoolsSource.fetchPools({ limit: 50 });
    }
  },

  api: {
    getBulkAddToList() {
      return this.state.bulk_add_to_list;
    },
    getBulkAddToPool() {
      return this.state.bulk_add_to_pool;
    },
    getBulkVMSRollover() {
      return this.state.bulk_vms_rollover;
    },

    getBulkVMSDelete() {
      return this.state.bulk_vms_delete;
    },

    getIsBulkAddProgress() {
      return !_.isEmpty(this.state.bulk_add_to_list) || !_.isEmpty(this.state.bulk_add_to_pool);
    },

    getIsBulkAddToPoolInProgress(id) {
      const { progress, type } = this.state.bulk_add_to_pool;
      const is_bulk = this.state.bulk_add_to_pool.id === id;

      if (is_bulk && _.isNumber(progress) && progress !== 1) {
        return { in_progress: true, type };
      }
      return { in_progress: false };
    },
  },
});
