module.exports = do ->
   React = require("react")
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   {span, div} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   Icon = createFactory (require("components/elements/icon").default)
   LinkButton = createFactory(require("@evertrue/et-components").LinkButton)
   KeyPressManager = createFactory(require("components/helpers/KeyPressManager").default)


   createComponent "InteractionPagination",
      propTypes:
         label: ReactLibs.PropTypes.string
         loading: ReactLibs.PropTypes.bool
         offset: ReactLibs.PropTypes.number
         onPage: ReactLibs.PropTypes.func

      render: ->
         div className: classNames("interaction-pagination", @props.className),
            if @props.loading
               Icon icon: "spinner", spin: true
            else if @props.offset >= 450 # allows for 500 interactions loaded
               span className: "text-muted",
                  "EverTrue can display a maximum of 500 interactions at a time.
                  Use the filters above to further refine your results."
            else
               KeyPressManager
                  onEnter: @props.onPage,
                  children: ({onKeyDown, ref}) =>
                     LinkButton title: "View More", onClick: @props.onPage, ref: ref, onKeyDown: onKeyDown,
                        @props.label || "View More"
