import { ButtonTooltip } from "@evertrue/et-components";
import Proptypes from "prop-types";

const DotNotificationBadge = ({ popoverText = "", className = "", showBadge, style = {} }) => {
  if (showBadge) {
    return (
      <span className={className} style={{ ...style }} title="">
        <ButtonTooltip
          trigger={<div className="circle-notification-badge" />}
          contentPadding={"0 4px"}
          contentSize={"12px"}
        >
          {popoverText}
        </ButtonTooltip>
      </span>
    );
  }
  return null;
};

DotNotificationBadge.propTypes = {
  popoverText: Proptypes.string,
  showBadge: Proptypes.bool,
  className: Proptypes.string,
  style: Proptypes.object,
};

export default DotNotificationBadge;
