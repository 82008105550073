module.exports = do ->
   _ = require("underscore").default
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")
   Decorator = require("clientDecorator")
   {createAutoCompleteContactQuery} = require("apps/contact/utils/helpers")
   autocomplete_request = undefined


   createSource "CachedContactSource",
      actions:
         changedContacts: true
         fetchedContacts: (contact_ids, contacts) ->
            @require _.isPagedObject(contacts), "contacts should be paged object"
            @require _.isArray(contact_ids), "contact_ids should be an array"
         startFetchContacts: (contact_ids) ->
            @require _.isArray(contact_ids), "contact_ids should be an array"
         cacheContacts: (contact_objects) ->
            @require _.isArray(contact_objects), "contact_objects should be an array"
         clearFromCache: (ids) ->
            @require _.isArray(ids), "ids should be an array"
         searchedAutocomplete: (query, results) ->
            @require _.isString(query) || _.isUndefined(query), "query should be string or undefined"
            @require _.isPagedObject(results), "results should be paged object"

      api:
         fetchContacts: (contact_ids) ->
            valid_contact_ids = _.compact(contact_ids)
            unless _.isEmpty(valid_contact_ids)
               @actions.startFetchContacts(valid_contact_ids)

         fetchContactsByIds: (contact_ids) ->
            contact_ids = _.map _.uniq(_.compact(contact_ids)), _.toNumber
            return if _.isEmpty(contact_ids)

            Api.CONTACTS.BULK.get
               params:
                  id: contact_ids
                  limit: 50
               success: (resp) =>
                  contacts = _.clone resp
                  contacts.items = _.map contacts.items, (contact) ->
                     Decorator.Contacts.parse(contact)
                  @actions.fetchedContacts(contact_ids, contacts)

         changedContacts: ->
            @actions.changedContacts()

         cacheContacts: (contact_objects) ->
            @actions.cacheContacts _.makeArray(contact_objects)

         clearFromCache: (ids) ->
            @actions.clearFromCache(_.makeArray(ids))

         autocomplete: (name, list_id) ->
            autocomplete_request?.abort()
            query = createAutoCompleteContactQuery(name)
   
            if list_id
               query.must_not = [
                  {
                        "list_memberships.list_id": {
                           "match": list_id
                        }
                  }
               ]

            Api.CONTACTS.SEARCH.post
               params: {limit: 20}
               data: _.jsonStringify query
               beforeSend: (xhr) ->
                  autocomplete_request = xhr
               success: (resp) =>
                  autocomplete_request = undefined
                  resp.items = _.map(resp.items, Decorator.Contacts.parse)
                  @actions.searchedAutocomplete(name, resp)
