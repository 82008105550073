import _ from "underscore";
import { Component } from "react";
import EverTrue from "app";
import PropTypes from "prop-types";
import { connect } from "@evertrue/et-flux";
import ListStore from "apps/lists/stores/list-store";
import MembershipStore from "apps/profile/stores/membership-store";
import MembershipSource from "apps/profile/sources/membership-source";
import ListContactSource from "apps/lists/sources/list-contact-source";
import ListSource from "apps/lists/sources/list-source";
import WindowSource from "apps/layout/sources/window-source";
import NewListModal from "apps/lists/components/new-list-modal";
import SelectorMenu from "components/controls/selector-menu";
import LayoutSource from "apps/layout/sources/layout-source";
import ListContactStore from "apps/lists/stores/list-contact-store";
import SelectedContactsStore from "apps/contact/stores/selected-contacts-store";
import SelectedContactsSource from "apps/contact/sources/selected-contacts-source";

const mapStateToProps = (props) => ({
  lists: ListStore.getLists(),
  list_membership: _.map(MembershipStore.getListMembership(props.membership), (li) => _.toNumber(li.id)),
  bulk_add_to_list_in_progress: ListContactStore.getIsBulkAddToListInProgress(),
  all_results_selected: SelectedContactsStore.getIsAllSelected(),
  numberSelected: SelectedContactsStore.getSelectedContacts().length,
});

class ListsSelectorController extends Component {
  static propTypes = {
    contactIds: PropTypes.any,
    membership: PropTypes.any,
    allowAddAll: PropTypes.bool,
    totalResultsCount: PropTypes.number,
    closePopover: PropTypes.func,
    filterParams: PropTypes.object,
    onlyUseQuery: PropTypes.bool,
    isFiltersV2: PropTypes.bool,
    // from store
    lists: PropTypes.array,
    list_membership: PropTypes.array,
    bulk_add_to_list_in_progress: PropTypes.bool,
    all_results_selected: PropTypes.bool,
    requestHide: PropTypes.func,
    numberSelected: PropTypes.number,
  };

  static defaultProps = {
    isFiltersV2: false,
    closePopover: _.noop,
    requestHide: _.noop,
  };

  componentDidMount() {
    if (this.props.membership) {
      MembershipSource.fetch(this.props.membership);
    }
  }

  handleSelect = (item) => {
    if (!this.props.contactIds.length) {
      return;
    }
    const id = _.toNumber(item.value);
    const options = this.props.membership ? { active_contact_id: this.props.membership } : undefined;
    if (!_.contains(this.props.list_membership, id)) {
      ListContactSource.addContacts(id, this.props.contactIds, options);
    } else {
      ListContactSource.removeContacts(id, this.props.contactIds, options);
    }
    if (this.props.membership) {
      MembershipSource.fetch(this.props.membership);
    }
    WindowSource.outsideClick();
    this.props.requestHide();
    this.props.closePopover();
  };

  handleCreateList = (name) => {
    this.props.closePopover();
    WindowSource.outsideClick();
    this.props.requestHide();
    ListSource.create({ name, type: "user_list" }, this.props.contactIds);
  };

  handleCreate = (query) => {
    const on_submit_cb =
      (this.props.all_results_selected && this.props.allowAddAll) ||
      this.props.onlyUseQuery ||
      (this.props.isFiltersV2 && !this.props.numberSelected)
        ? this.handleBulkAddToListCreate
        : this.handleCreateList;

    if (_.isEmpty(query)) {
      // This is used by 2 parents, \
      //one uses requestHide and the other uses closePopover
      //Both are _.noop defaults
      this.props.requestHide();
      this.props.closePopover();
      LayoutSource.launchModal(
        NewListModal({
          onSubmit: on_submit_cb,
        })
      );
    } else {
      on_submit_cb(query);
    }
  };

  showInProcessModal = () => {
    EverTrue.Alert.confirm(
      {
        headline: <span>Please Wait</span>,
        content: <span>List processing must complete before a new list can be created</span>,
      },
      () => null
    );
  };

  handleBulkAddToListSelect = (item) => {
    const { totalResultsCount } = this.props;
    if (this.props.bulk_add_to_list_in_progress) {
      this.showInProcessModal();
    } else if (totalResultsCount > 500) {
      EverTrue.Alert.confirm(
        {
          content: `You are about to add ${totalResultsCount.toLocaleString()} constituents to ${
            item != null ? item.label : undefined
          }. This process cannot be undone.`,
          headline: (
            <span>
              <strong>{`Add ${totalResultsCount.toLocaleString()} to List?`}</strong>
            </span>
          ),
          buttonLabel: "Add",
        },
        (didConfirm) => {
          if (didConfirm) {
            this.bulkAddToList(item);
          }
        }
      );
    } else {
      this.bulkAddToList(item);
    }
  };

  handleBulkAddWithQuery = (item) => {
    const { totalResultsCount, filterParams } = this.props;
    if (this.props.bulk_add_to_list_in_progress) {
      this.showInProcessModal();
    } else if (totalResultsCount > 500) {
      EverTrue.Alert.confirm(
        {
          content: `You are about to add ${totalResultsCount.toLocaleString()} constituents to ${
            item != null ? item.label : undefined
          }. This process cannot be undone.`,
          headline: (
            <span>
              <strong>{`Add ${totalResultsCount.toLocaleString()} to List?`}</strong>
            </span>
          ),
          buttonLabel: "Add",
        },
        (didConfirm) => {
          if (didConfirm) {
            this.bulkAddToList(item);
          }
        }
      );
    } else {
      ListContactSource.bulkAddToList(filterParams, item, totalResultsCount);
      WindowSource.outsideClick();
      this.props.requestHide();
    }
  };

  bulkAddToList = (item) => {
    ListContactSource.bulkAddToList(
      this.props.filterParams,
      item,
      this.props.totalResultsCount,
      this.props.isFiltersV2
    );

    WindowSource.outsideClick();
    this.props.requestHide();
    this.props.closePopover();
    // clear all selected once a bulk add is fired
    SelectedContactsSource.toggleSelectedIndicator(false);
    SelectedContactsSource.selectAll(false);
    SelectedContactsSource.clear();
  };

  handleBulkAddToListCreate = (name) => {
    if (this.props.bulk_add_to_list_in_progress) {
      this.showInProcessModal();
    }
    ListSource.bulkAddToListCreate(
      { name, type: "user_list" },
      this.props.totalResultsCount,
      this.props.filterParams,
      this.props.isFiltersV2
    );
  };

  render() {
    const on_select_cb =
      (this.props.all_results_selected && this.props.allowAddAll) ||
      (this.props.isFiltersV2 && !this.props.numberSelected)
        ? this.handleBulkAddToListSelect
        : this.props.onlyUseQuery
        ? this.handleBulkAddWithQuery
        : this.handleSelect;

    return (
      <div className="lists-selector">
        <SelectorMenu
          label="list"
          options={_.map(this.props.lists, (list_group) => {
            return {
              value: list_group.id,
              label: list_group.label,
              items: _.map(_.sortBy(list_group.items, "name"), (list) => {
                return { value: list.id, label: list.name, selected: _.contains(this.props.list_membership, list.id) };
              }),
            };
          })}
          onCreate={this.handleCreate}
          onSelect={on_select_cb}
        />
      </div>
    );
  }
}

export default connect(
  ListsSelectorController,
  [ListStore, MembershipStore, ListContactStore, SelectedContactsStore],
  mapStateToProps
);
