module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   EverTrue = require("app")
   React = require("react")
   PureRenderMixin = require("react-addons-pure-render-mixin")
   {createFactory} = require("base/new-utils")
   FacebookPost = createFactory require("apps/facebook/components/facebook-post")
   {div, ul} = ReactLibs.DOMFactories

   FacebookPostsTimeline = ReactLibs.createClass
      mixins: [PureRenderMixin]
      propTypes:
         posts: ReactLibs.PropTypes.array.isRequired
         loadMorePosts: ReactLibs.PropTypes.func
         getPostUrl: ReactLibs.PropTypes.func
         scrollElement: ReactLibs.PropTypes.any
         containerElement: ReactLibs.PropTypes.any

      getDefaultProps: ->
         scrollElement: EverTrue.WINDOW
         containerElement: EverTrue.DOCUMENT

      getInitialState: ->
         like: 0, comment: []

      componentDidUpdate: ->
         FB?.XFBML.parse()

      componentDidMount: ->
         FB?.XFBML.parse()
         $(@props.scrollElement).on "scroll", @_infiniteScroll

      componentWillUnmount: ->
         $(@props.scrollElement).off "scroll", @_infiniteScroll

      _infiniteScroll: ->
         if $(@props.scrollElement).scrollTop() + $(@props.scrollElement).height() >= ($(@props.containerElement).height() - 300)
            @props.loadMorePosts()

      UNSAFE_componentWillUpdate: ->
         _.each @props.posts, (post) ->
            url = post.public_url
            FB?.Canvas?.Prefetcher?.addStaticResource(url) if url

      paginate: (page) ->
         @setState page: page

      posts: ->
         return unless @props.posts?.length > 0
         @props.posts.map (post) ->
            FacebookPost key: post.id, post: post

      render: ->
         div className: "facebook-posts",
            ul className: "timeline",
               @posts()
