import _ from "underscore";
import FormField from "components/forms/form-field";
import moment from "moment";
import CalendarPickerDropdown from "components/controls/calendars/calendar-picker-dropdown";
import { NumericInput, AdvancedCombobox, Switch } from "@evertrue/et-components";
import PropTypes from "prop-types";
import { formatDateToUnixWithCurrentTime } from "base/new-utils";

//All the ask amount proposal form rows are populated from this array.
const AMOUNT_FIELDS = [
  { inputValue: "expected_amount", label: "Expected", dateInputValue: "expected_date" },
  { inputValue: "original_ask_amount", label: "Original Ask", dateInputValue: "original_ask_date" },
  { inputValue: "ask_amount", label: "Current Ask", dateInputValue: "ask_date" },
  { inputValue: "funded_amount", label: "Funded", dateInputValue: "funded_date" },
];

export const ProposalFormAmounts = ({
  onFormChange = () => {},
  proposal = {},
  proposalStages = [],
  standardFieldsConfig,
  hasProposalReporting = false,
}) => {
  const { active, status, reported, confidence_score, require_pairs } = standardFieldsConfig;

  const showConnector = (dateField) => {
    const isRequiredPaired = require_pairs[dateField.split("_")[0]];
    return !!(isRequiredPaired && proposal[dateField]);
  };

  const disableNumericInput = (dateField) => {
    const isRequiredPaired = require_pairs[dateField.split("_")[0]];
    return isRequiredPaired && !proposal[dateField];
  };

  return (
    <>
      <div className="proposal-form--header">Status</div>

      <div className="proposal-form--row">
        <FormField label="Stage" inlineLabel={false} className="proposal-form--form-field" required={status.required}>
          <AdvancedCombobox
            className="proposal-form--form-field-dropdown"
            label={proposal.status ? proposal.status + " stage selected" : "Select stage"}
            value={_.findWhere(proposalStages, { value: proposal.status })}
            options={proposalStages}
            placeholder="Select a stage..."
            onChange={(stage) => {
              onFormChange("status", stage.value);
            }}
          />
        </FormField>
        <FormField label="Active" inlineLabel={false} className="proposal-form--form-field" required={active.required}>
          <Switch
            label="Proposal is active"
            showLabel={false}
            name="active"
            checked={proposal.active}
            value={proposal.active}
            onChange={(active) => {
              onFormChange("active", active);
            }}
          />
        </FormField>
      </div>

      {AMOUNT_FIELDS.map((field) => (
        <div className="proposal-form--row proposal-form--amounts" key={field.label}>
          <FormField
            label={field.label}
            inlineLabel={false}
            className={
              showConnector(field.dateInputValue)
                ? "proposal-form--form-field proposal-form--field-no-space"
                : "proposal-form--form-field"
            }
            required={standardFieldsConfig[field.dateInputValue].required}
          >
            <CalendarPickerDropdown
              className="proposal-form--form-field-date-picker"
              label={field.label + " Date"}
              value={
                proposal[field.dateInputValue] ? moment(proposal[field.dateInputValue]).format("YYYY-MM-DD") : null
              }
              onChange={(val) => {
                const date = !val ? "" : formatDateToUnixWithCurrentTime(val);
                if (date || date === "") onFormChange(field.dateInputValue, date);
              }}
              focus={showConnector(field.dateInputValue)}
            />
          </FormField>
          {showConnector(field.dateInputValue) && <hr data-testid="proposal-hr" />}
          <div
            className={
              standardFieldsConfig[field.inputValue].required
                ? "proposal-form--form-field-label"
                : "proposal-form--form-field-no-label"
            }
          >
            {standardFieldsConfig[field.inputValue].required && (
              <label className="form-field--label-wrap" style={{ marginBottom: "2px" }}>
                <span className="form-field--required">Required</span>
              </label>
            )}
            <NumericInput
              className={
                showConnector(field.dateInputValue)
                  ? "proposal-form--form-field-amount proposal-form--form-field-amount-focus"
                  : "proposal-form--form-field-amount"
              }
              aria-label={field.label}
              placeholder={field.label + " Dollars"}
              value={proposal[field.inputValue]}
              maskType="currency"
              integerLimit={10}
              onChange={(amount) => {
                onFormChange(field.inputValue, amount);
              }}
              showIcon={true}
              disabled={disableNumericInput(field.dateInputValue)}
            />
          </div>
          {field.inputValue === "expected_amount" && (
            <FormField
              label="Confidence"
              inlineLabel={false}
              className={"proposal-form--form-field confidence-score"}
              required={confidence_score.required}
            >
              <div className="proposal-form--form-field-percent">
                <NumericInput
                  aria-label="Confidence Score"
                  value={proposal.confidence_score}
                  maskType="percent"
                  integerLimit={3}
                  onChange={(score) => {
                    score <= 100
                      ? onFormChange("confidence_score", score)
                      : onFormChange("confidence_score", proposal.confidence_score);
                  }}
                  showIcon={true}
                />
              </div>
            </FormField>
          )}
        </div>
      ))}

      <div className="proposal-form--row proposal-form--launchpad">
        {hasProposalReporting && (
          <FormField
            label="Launchpad"
            inlineLabel={false}
            className="proposal-form--form-field"
            required={reported.required}
          >
            <Switch
              label="Included in Launchpad"
              showLabel={false}
              name="reported"
              checked={proposal.reported}
              value={proposal.reported}
              onChange={(reported) => {
                onFormChange("reported", reported);
              }}
            />
          </FormField>
        )}
      </div>
    </>
  );
};

ProposalFormAmounts.propTypes = {
  onFormChange: PropTypes.func,
  proposal: PropTypes.object,
  proposalStages: PropTypes.array,
  hasProposalReporting: PropTypes.bool,
  standardFieldsConfig: PropTypes.object.isRequired,
};

export default ProposalFormAmounts;
