import { ButtonTooltip, Icon } from "@evertrue/et-components";
import PropTypes from "prop-types";

const CONFIDENCE_INFO = {
  HIGH: {
    SCORE: 93,
    LABEL: "High",
    CSS: "high",
  },
  MEDIUM: {
    SCORE: 79,
    LABEL: "Medium",
    CSS: "med",
  },
  LOW: {
    SCORE: 0,
    LABEL: "Low",
    CSS: "low",
  },
};

const SOURCE_INFO = {
  SALESINTEL: {
    ICON: "gt2-icon gt2-icon-human-verified",
    ICON_KIT: "gt2",
    TOOLTIP_TEXT: "This enrichment data was verified by a human and our confidence level regarding this piece of data is ",
  },
  OTHER : {
    ICON: "gt-icon gt-icon-info",
    ICON_KIT: "gt",
    TOOLTIP_TEXT: "Our confidence level regarding this piece of enrichment data is ",
  },
}

const EnrichmentConfidenceScore = ({ score, source }) => {

  if(!score) {
    return "N/A";
  }

  const confidence_info = score >= CONFIDENCE_INFO.HIGH.SCORE ? CONFIDENCE_INFO.HIGH : (score >= CONFIDENCE_INFO.MEDIUM.SCORE ? CONFIDENCE_INFO.MEDIUM: CONFIDENCE_INFO.LOW);
  const source_info = source === "salesintel" ? SOURCE_INFO.SALESINTEL : SOURCE_INFO.OTHER;
  return (
    <>
      <span className={"profile--enrichment--confidence-" + confidence_info.CSS}>{confidence_info.LABEL}</span>
      <ButtonTooltip
        // temp b/c if arrow is open the tooltip only positions itself on the bottom.
        // we should fix in et-comps
        disableArrow={true}
        title="View help information"
        // title = " " to avoid proptype warning and not show title. don't want a title and a tooltip at same time
        trigger={<Icon size={1} icon={source_info.ICON} iconKit={source_info.ICON_KIT} disableTitle={true} />}
        contentWidth={300}
        wrapperClassName={"helptip-item"}
        headerClassName="helptip-item--header"
        header={""}
      >
        {source_info.TOOLTIP_TEXT}{confidence_info.LABEL}
      </ButtonTooltip>
    </>
  )
};

EnrichmentConfidenceScore.propTypes = {
  score: PropTypes.number,
  source: PropTypes.string,
};

export default EnrichmentConfidenceScore;