module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   {createStore} = require("@evertrue/et-flux")
   SubscriptionPrefSource = require("apps/filters/sources/subscription-pref-source")
   ListSource = require("apps/lists/sources/list-source")

   _formatListKey = (id) -> "list:#{id}:"
   _getDefaultPreferences = (enabled) ->
      email_enabled: enabled
      email_frequency: "weekly"
      push_enabled: enabled
      push_frequency: "weekly"


   createStore "SubscriptionPrefStore",
      getInitialState: ->
         preferences: {}

      registerActions: ->
         @on SubscriptionPrefSource.actions.fetchedPreferences, @respondToFetched
         @on SubscriptionPrefSource.actions.updatedPreference, @respondToUpdate
         @on SubscriptionPrefSource.actions.deletedPreference, @respondToDelete

         @on ListSource.actions.createdList, @respondToNewList
         @on ListSource.actions.updatedList, @respondToNewList
         @on ListSource.actions.deletedList, @respondToDeleteList

      respondToFetched: (preferences) ->
         prefs = $.extend(true, {}, @getState("preferences"))
         _.each preferences, (pref) ->
            prefs[pref.key] = pref
         @setState {preferences: prefs}

      respondToUpdate: (preference) ->
         prefs = $.extend(true, {}, @getState("preferences"))
         prefs[preference.key] = preference
         @setState {preferences: prefs}

      respondToDelete: (key) ->
         @setState {preferences: _.omit @getState("preferences"), key}

      respondToNewList: (list) ->
         if list.preferences && list.id != "temp"
            key = _formatListKey(list.id)
            prefs = _.extend {}, _.omit(list.preferences, "key"), label: list.name
            SubscriptionPrefSource.update(key, prefs)

      respondToDeleteList: (list_id) ->
         key = _formatListKey(list_id)
         if @getState("preferences")[key]
            SubscriptionPrefSource.delete(key)

      api:
         getByKey: (key) ->
            @getState("preferences")[key]

         getBySegmentId: (id) ->
            if id
               @getByKey(_formatListKey(id)) || _getDefaultPreferences(false)
            else _getDefaultPreferences(true)

         getDefaultPreferences: ->
            _getDefaultPreferences(true)
