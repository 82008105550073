module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   moment = require("moment")
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   Modal = createFactory require("components/modals/modal")
   ModalTrigger = createFactory require("components/modals/modal-trigger")
   ModalConfirm = createFactory require("components/modals/modal-confirm")
   GiftCard = require("apps/contact/components/profile/giving-data/gift-card")
   KpiCard = require("components/containers/kpi-card")
   classNames = require("classnames")
   FeatureStore = require("apps/layout/stores/feature-store").default


   createComponent "GivingSummary",
      propTypes:
         giving: ReactLibs.PropTypes.object

      registerStores: ->
         @on FeatureStore, ->
            has_imported_pledges: FeatureStore.hasFeature("imported_pledge_balance")

      getHelptipKey: (prop_key) ->
         "profile_#{prop_key.replace("_amount","")}_import"

      renderAmount: (prop) ->
         _.currencyFormatter(prop)

      renderDate: (date) ->
         date = date
         div null,
            if date
               "#{moment(new Date(date)).fromNow()}"
            else "--"

      renderGiftModal: (label, prop) ->
         return unless @props.giving
         date = @props.giving[prop + "_date"]

         ModalConfirm className: "profile-gift-summary--gift-modal", header: label,
            GiftCard
               gift:
                  amount: @props.giving[prop + "_amount"]
                  labels: _.map @props.giving[prop + "_label"]?.split(","), (label) -> {value: label.trim()}
                  occurred_at: if date then moment(new Date(date)).valueOf()

      render: ->
         div className: classNames("profile-gift-summary"),
            KpiCard
               title: "Last Gift"
               helpTip: @getHelptipKey("last_gift_amount")
               value: @renderAmount(@props.giving?.last_gift_amount)

               @renderDate(@props.giving?.last_gift_date)

               if @props.giving?.last_gift_amount
                  ModalTrigger
                     title: "View Last Gift Amount Details"
                     className: "profile-gift-summary--link"
                     modal: Modal width: 500,  @renderGiftModal("Last Gift Details", "last_gift")
                     "View Gift Details"

            KpiCard
               title: "Largest Gift"
               helpTip: @getHelptipKey("largest_gift_amount")
               value: @renderAmount(@props.giving?.largest_gift_amount)

               @renderDate(@props.giving?.largest_gift_date)

               if @props.giving?.largest_gift_amount
                  ModalTrigger
                     title: "View Largest Gift Amount Details"
                     className: "profile-gift-summary--link"
                     modal: Modal width: 500, @renderGiftModal("Largest Gift Details", "largest_gift")
                     "View Gift Details"

            KpiCard
               title: "Lifetime Giving"
               helpTip: @getHelptipKey("lifetime_amount")
               value: @renderAmount(@props.giving?.lifetime_amount)
               "Imported from your database"

            if @state.has_imported_pledges
               KpiCard
                  title: "Pledge Balance"
                  helpTip: "profile_pledges_import"
                  value: @renderAmount(@props.giving?.total_pledge_balance)
                  div null, "In unpaid pledges"
