import { useState } from "react";
import _ from "underscore";
import PropTypes from "prop-types";
import Api from "entities/helpers/api";
import EverTrue from "app";
import { Loading, LinkButton } from "@evertrue/et-components";
import BioItems from "apps/profile/components/enrichment/bio-items";
import LifeChanges from "apps/profile/components/enrichment/life-changes"

const gql = String.raw;

const BioEditModal = ({
  contact_id = null,
  enrichmentData = { contact_locations : [], enrichment_attributes : [] },
  onDelete = () => {},
  requestHide = () => {},
}) => {
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeLocations, rejectedLocations] = _.partition(enrichmentData.contact_locations, address => !address.rejected);
  const [activeNames, rejectedNames] = _.partition(enrichmentData.enrichment_attributes, name => !name.rejected);

  const handleMutation = (data, rejecting, type) => {
    setLoading(true);

    const query = () => {
      if (type === "names") {
        return gql`
          mutation($id: Int!, $enrichment: EnrichmentInput) {
            contact_mutation(id: $id, enrichment: $enrichment) {
              code
              enrichment_mutation {
                enrichment_attributes_mutations {
                  success
                }
              }
            }
          }
        `;
      } else if (type === "locations") {
        return gql`
          mutation($id: Int!, $enrichment: EnrichmentInput) {
            contact_mutation(id: $id, enrichment: $enrichment) {
              code
              enrichment_mutation {
                contact_locations_mutations {
                  success
                }
              }
            }
          }
        `;
      }
    };

    Api.GRAPHQL.GRAPHQL.post({
      data: JSON.stringify({
        query: String.raw`${query()}`,
        variables: {
          id: contact_id,
          enrichment: {
            [type === "names" ? "enrichment_attributes" : "contact_locations"]: [
              { id: data.id, rejected: rejecting, updated_at: data.updated_at },
            ],
          },
        },
      }),
    })
      .then(() => {
        _.delay(() => {
          rejecting ? EverTrue.Alert.success("Item Removed") : EverTrue.Alert.success("Item Restored");
          onDelete();
          setLoading(false);
          requestHide();
        }, 1500);
      })
      .catch(() => {
        setLoading(false);
        rejecting
          ? EverTrue.Alert.error("Problem removing item from record. Please try again later")
          : EverTrue.Alert.error("Problem restoring item to record. Please try again later");
      });
  };

  return (
    <div className="career-edit-modal">
      {loading ? <Loading /> : null}

      <div className="profile--enrichment-bio-location">
        <BioItems names={activeNames} locations={activeLocations} editMode="delete" onMutation={handleMutation} />
      </div>
      <div>
        <LifeChanges enrichmentData={enrichmentData} />
      </div>
      <div className="profile--enrichment-restore">
        {rejectedLocations.length || rejectedNames.length ? (
          <LinkButton onClick={() => setDeleteVisible(!deleteVisible)} title="">
            {deleteVisible ? "Hide deleted" : "See deleted"}
          </LinkButton>
        ) : null}
      </div>
      {deleteVisible ? (
        <div className="profile--enrichment-rejected-list">
          <BioItems
            names={rejectedNames}
            locations={rejectedLocations}
            editMode="restore"
            onMutation={handleMutation}
          />
        </div>
      ) : null}
    </div>
  );
};

BioEditModal.propTypes = {
  contact_id: PropTypes.number,
  enrichmentData: PropTypes.object,
  onDelete: PropTypes.func,
  requestHide: PropTypes.func,
};

export default BioEditModal;
