import _ from "underscore";
import classNames from "classnames";
import PropTypes from "prop-types";

const INDEX_MAP = { A: 5, B: 4, C: 3, D: 2, E: 1 };

const getScoreValue = score => {
  if (_.isNaN(_.toNumber(score))) {
    return INDEX_MAP[score] || 0;
  }
  return score;
};

const getColor = score => {
  switch (getScoreValue(score)) {
    case 5:
      return "#00ADF2";
    case 4:
      return "#19C300";
    case 3:
      return "#DDE100";
    case 2:
      return "#F99500";
    case 1:
      return "#E21E00";
    case 0:
      return "#E21E00";
    default:
      return "#00ADF2";
  }
};

const ScoreProgress = ({ score, className, showTotal }) => {
  const width = `${(getScoreValue(score) / 5) * 100}%`;

  return (
    <div className={classNames("score-progress", className)}>
      <div className="score-progress--wrapper">
        <div className="score-progress--inner" style={{ background: getColor(score), width }} />
      </div>
      <div className={classNames("score-progress--label", { "is-empty": !score })}>
        {score || "N/A"}
        {showTotal ? <span className="score-progress--total">/5</span> : null}
      </div>
    </div>
  );
};

ScoreProgress.propTypes = {
  score: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  showTotal: PropTypes.bool,
};

export default ScoreProgress;
