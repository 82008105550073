export const applySatismeter = (userId, name, email, createdAt) => {
  let error = null;

  try {
    (function () {
      window.satismeter =
        window.satismeter ||
        function () {
          (window.satismeter.q = window.satismeter.q || []).push(arguments);
        };
      window.satismeter.l = 1 * new Date();
      var script = document.createElement("script");
      var parent = document.getElementsByTagName("script")[0].parentNode;
      script.async = 1;
      script.src = "https://app.satismeter.com/satismeter.js";
      parent.appendChild(script);
    })();

    window.satismeter({
      writeKey: "7p5vjxGgVg6ZHA1l",
      userId,
      traits: {
        name,
        email,
        createdAt,
      },
    });
  } catch (err) {
    error = err;
    console.log(error);
  }
};
