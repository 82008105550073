import { PropTypes } from "base/react-libs";
import NextFiveDays from "./next-five-days/next-five-days";
import TodayContent from "./today-card/today-content";

const Upcoming = ({
  todayJourneyCount,
  todayTaskCount,
  nextFiveDaysCounts,
  loadingJourneysTodayCount,
  loadingTasksTodayCount,
  loadingNextFiveCounts,
  futureJourneyTimeout,
  inProgressJourneyTimeout,
  businessDaysUntilFutureTimeout,
  tripsAndMeetingDetails,
  tripsAndMeetingDetailsForNextFive,
  optionalStateManagementFunction = () => {},
}) => {
  return (
    <>
      <h1 className="resource-title-upcoming">Upcoming</h1>
      <div className="homepage-top">
        <TodayContent
          todayJourneyCount={todayJourneyCount}
          todayTaskCount={todayTaskCount}
          loadingJourneysTodayCount={loadingJourneysTodayCount}
          loadingTasksTodayCount={loadingTasksTodayCount}
          tripsAndMeetingDetails={tripsAndMeetingDetails}
          optionalStateManagementFunction={optionalStateManagementFunction}
        />
        <NextFiveDays
          loadingNextFiveCounts={loadingNextFiveCounts}
          nextFiveDaysCounts={nextFiveDaysCounts}
          businessDaysUntilFutureTimeout={businessDaysUntilFutureTimeout}
          futureJourneyTimeout={futureJourneyTimeout}
          inProgressJourneyTimeout={inProgressJourneyTimeout}
          tripsAndMeetingDetailsForNextFive={tripsAndMeetingDetailsForNextFive}
        />
      </div>
    </>
  );
};

Upcoming.propTypes = {
  todayJourneyCount: PropTypes.object,
  todayTaskCount: PropTypes.object,
  nextFiveDaysCounts: PropTypes.array,
  loadingJourneysTodayCount: PropTypes.bool,
  loadingTasksTodayCount: PropTypes.bool,
  loadingNextFiveCounts: PropTypes.bool,
  businessDaysUntilFutureTimeout: PropTypes.number,
  futureJourneyTimeout: PropTypes.object,
  inProgressJourneyTimeout: PropTypes.object,
  optionalStateManagementFunction: PropTypes.func,
  tripsAndMeetingDetails: PropTypes.any,
  tripsAndMeetingDetailsForNextFive: PropTypes.object,
};

export default Upcoming;
