import { PropTypes } from "base/react-libs";

const StrikeThroughWrapper = ({ children, hasStrikeThrough }) => {
  if (hasStrikeThrough) return <s aria-label="strikethrough">{children}</s>;
  return children;
};

export default StrikeThroughWrapper;

StrikeThroughWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  hasStrikeThrough: PropTypes.bool,
};
