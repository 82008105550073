import { Icon, Button } from "@evertrue/et-components";
import PropTypes from "prop-types";
import useMapsSearch from "../hooks/use-maps-search";

const MapSearchBar = ({ map, error, onMapCoordinateChange }) => {
  const gpsIcon = error ? "gps-off" : "gps-fixed";

  const { MapSearchInput, handleSearchClick, handleGeolocationClick, geolocationError } = useMapsSearch(
    map,
    onMapCoordinateChange
  );

  return (
    <>
      <div className="map-search--container">
        {MapSearchInput()}
        <Button type="default" title="Search location" className="map-search--button" onClick={handleSearchClick}>
          <Icon icon="search" />
        </Button>
      </div>
      <Button
        disabled={!!geolocationError}
        type="secondary"
        title="Current location"
        className="map-toolbar--button"
        onClick={handleGeolocationClick}
      >
        <Icon icon={gpsIcon} size={1} />
      </Button>
    </>
  );
};

MapSearchBar.propTypes = {
  map: PropTypes.object,
  error: PropTypes.bool,
  onMapCoordinateChange: PropTypes.func,
};

export default MapSearchBar;