module.exports = do ->
   _ = require("underscore").default
   classNames = require("classnames")
   React = require("react")
   ProspectColumnStore = require("apps/major-gifts/stores/prospect-column-store")
   ProspectColumnSource = require("apps/major-gifts/sources/prospect-column-source")
   {div, h3, h4, button, a} = ReactLibs.DOMFactories
   {createComponent} = require("@evertrue/et-flux")
   SortSelector = require("components/controls/sort-selector/sort-selector")


   createComponent "ProspectColumnSelector",
      registerStores: ->
         @on ProspectColumnStore, ->
            available_columns: ProspectColumnStore.getAvailableColumns()
            active_columns: ProspectColumnStore.getActiveColumns()

      handleSave: (active_columns) ->
         ProspectColumnSource.update(active_columns)
         EverTrue.track.set "my_portfolio_action", {type: "edit_stage_order"}
         @handleClose()

      handleClose: ->
         if _.isFunction @props.requestHide
            @props.requestHide()

      render: ->
         div className: "prospect-column-selector",
            SortSelector
               options: @state.available_columns
               value: @state.active_columns
               header: "Edit Stage Order"
               action: "Save Stage Order"
               onSave: @handleSave
               onCancel: @handleClose
