module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   EverTrue = require("app")
   NotificationSource = require("apps/notifications/sources/notification-source")
   NotificationStore = require("apps/notifications/stores/notification-store")
   ExportSource = require("apps/export/sources/export-source")
   ExportStore = require("apps/export/stores/export-store")
   WindowSource = require("apps/layout/sources/window-source")
   {createComponent} = require("@evertrue/et-flux")
   {div, span, ul, li, p} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   NotificationItem = require("apps/notifications/components/notification-item")
   Dropdown = createFactory require("components/controls/dropdown")
   Icon = createFactory (require("components/elements/icon").default)


   createComponent "NotificationInboxController",
      registerStores: ->
         @on NotificationStore, ->
            notifications: NotificationStore.getNotifications()
            count: NotificationStore.getUnreadCount()

         @on ExportStore, ->
            exports: ExportStore.getExports()

      componentDidMount: ->
         NotificationSource.fetch()
         ExportSource.fetch()

      handleNotificationClick: (id) ->
         EverTrue.track.setReferrer("inbox")
         WindowSource.outsideClick()

         is_impersonating = EverTrue.request "isImpersonating?"
         notification = NotificationStore.get(id)
         if !is_impersonating && !notification?.read_at
            NotificationSource.markAsRead(id)

      handleReadAll: ->
         is_impersonating = EverTrue.request "isImpersonating?"
         if @state.count > 0 && !is_impersonating
            NotificationSource.markAsRead()

      render: ->
         if @state.count > 0
            notification_label = "#{@state.count}"
         span "aria-live": "polite",
            Dropdown
               className: "header-dropdown notification-dropdown"
               stay_open: true
               onOpen: @handleReadAll
               label: "View notifications, you have #{@state.count} new " + if @state.count > 1 then "notifications" else "notification"
               header: div null,
                  Icon
                     className: "notification-dropdown--main-icon"
                     icon: if @state.count > 0 then "notifications" else "notifications-none"
                  if notification_label
                     span className: "notification--badge",
                        notification_label

               ul className: "notification-list",
                  if _.isEmpty(@state.notifications)
                     li className: "notification-list--empty",
                        p null, " You haven't received any notifications."
                        p null, " Add constituents to lists or save a search to get notifications about engagement."
                  else
                     _.map @state.notifications, (notification) =>
                        NotificationItem
                           key: notification.id
                           notification: notification
                           count: @state.count
                           onClick: @handleNotificationClick
