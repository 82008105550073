module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   Popover = require("components/overlays/popover")
   {div, span} = ReactLibs.DOMFactories
   {any, string, number, bool, oneOf} = ReactLibs.PropTypes
   colors = require("base/colors")

   STYLES =
      dark:
         arrowColor: colors.gray900
         arrowBorderColor: colors.gray900

   createComponent "Tooltip",
      propTypes:
         content: any
         position: string
         width: number
         disable: bool
         arrowSize: number
         event: oneOf ["hover", "click"]
         type: oneOf ["default", "light", "dark"]

      getDefaultProps: ->
         type: "dark"
         event: "hover"

      render: ->
         styles = STYLES.dark

         Popover
            className: classNames("tooltip-trigger", @props.className)
            trigger: if typeof @props.children == "function" then ({open, close}) => @props.children({open, close}) else span null, @props.children
            event: @props.event
            position: @props.position
            width: @props.width
            style: @props.style
            disable: @props.disable
            options: _.extend {}, styles, arrowSize: @props.arrowSize || 4

            div
               className: classNames("tooltip-trigger--content", "is-#{@props.type}")
               style: {width: @props.width}
               @props.content
