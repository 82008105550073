export const addMillisecondsToTimestamp = (timestamp) => {
  return Math.floor(timestamp * 1000);
};

export const removeMillisecondsToTimestamp = (timestamp) => {
  return Math.floor(timestamp / 1000);
};

export const formatDateFromTimestampAndAddMilliseconds = (timestamp, addDay = false) => {
  const date = new Date(timestamp * 1000);
  let options = { year: "numeric", month: "long", day: "numeric" };
  if (addDay) {
    date.setDate(date.getDate() + 1);
    options.weekday = "long";
  }
  return date.toLocaleDateString("en-US", options);
};

export const formatDateFromTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return date.toLocaleDateString("en-US", options);
};

export const formatDateToLocaleTimeString = (timestamp) => {
  const date = new Date(timestamp);
  return date.toLocaleTimeString("en-US", { hour12: true, hour: "numeric", minute: "numeric" }).toLowerCase();
};

export const formatDateFromTimestampWeekday = (timestamp) => {
  const date = new Date(timestamp);

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return date.toLocaleDateString("en-US", options);
};

// get the start(Monday) and end (Sunday) dates of the current week
export const getWeekBoundaries = () => {
  const today = new Date();
  const currentDay = today.getDay();
  const mondayOffset = currentDay === 0 ? 6 : currentDay - 1;
  // find Monday
  const monday = new Date(today);
  monday.setDate(today.getDate() - mondayOffset);
  // find Sunday
  const sundayOffset = currentDay === 0 ? 0 : 7 - currentDay;
  const sunday = new Date(today);
  sunday.setDate(today.getDate() + sundayOffset);

  return { monday, sunday };
};

export const setTimestampToMidnight = (timestamp) => {
  let newDate = new Date(timestamp); // create date object
  newDate.setHours(0, 0, 0, 0); // set times to midnight

  return newDate;
};

export const setTimestampToMidnightAndRemoveMilliseconds = (timestamp) => {
  const midnightTimestamp = setTimestampToMidnight(timestamp);

  return removeMillisecondsToTimestamp(midnightTimestamp);
};

// Takes in a date in milliseconds and returns in the format "Mon, Aug 2"
export const formatSingleDate = (dateInMilliseconds) => {
  const timestamp = Number(dateInMilliseconds);
  const date = new Date(timestamp);

  const formattedDate = date.toLocaleDateString("en-US", {
    weekday: "short",
    month: "short",
    day: "numeric",
  });

  return formattedDate;
};

export const formatGivingDate = (dateString) => {
  const date = new Date(dateString + "T00:00");

  if (isNaN(date)) {
    return;
  }

  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

export const givingTimeAgo = (pastDate) => {
  const currentDate = new Date();
  const past = new Date(pastDate);
  const diffInSeconds = Math.floor((currentDate - past) / 1000);

  if (diffInSeconds < 86400) {
    return `(Less than a day ago)`;
  } else if (diffInSeconds < 2592000) {
    const days = Math.floor(diffInSeconds / 86400);
    return `(${days} day${days > 1 ? "s" : ""} ago)`;
  } else if (diffInSeconds < 31536000) {
    const months = Math.floor(diffInSeconds / 2592000);
    return `(${months} month${months > 1 ? "s" : ""} ago)`;
  } else {
    const years = Math.floor(diffInSeconds / 31536000);
    return `(${years} year${years > 1 ? "s" : ""} ago)`;
  }
};
