module.exports = do ->
   _ = require("underscore").default
   moment = require("moment")
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   str = require("underscore.string")
   {div, span} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   CachedContactsResolver = createFactory(require("apps/contact/components/contacts/cached-contacts-resolver").default)
   Link = createFactory(require("@evertrue/et-components").Link)

   createComponent "InteractionDetails",
      propTypes:
         hasInteractionCustomFields: ReactLibs.PropTypes.bool
         interaction: ReactLibs.PropTypes.object

      getDefaultProps: ->
         interaction: {}
         hasInteractionCustomFields: false

      renderMetaLabel: (label, data, url) ->
         if data
            interaction_data_name = data.name || data.title

            span className: "interaction-details--labels-item interaction-details--#{label.toLowerCase()}",
               span null, "#{label}: "
               if url
                  Link href: "/#{url}/#{data.id}", title: interaction_data_name, interaction_data_name
               else
                  span className:"interaction-details--value", interaction_data_name

      getLabels: (interaction) ->
         labels = if @props.hasInteractionCustomFields
            []
         else
            _.filter interaction.label, (label) ->
               !_.isEmpty(label.value?.trim())

         has_solicitor_label = _.find labels, (label) ->
            label.name?.toLowerCase()?.match(/^(solicitor|solictors)/)

         if !has_solicitor_label && !_.isEmpty(interaction.solicitor)
            _.each interaction.solicitor, (solicitor) ->
               labels.push {name: "Solicitors", value: solicitor.name}

         if interaction.interaction_type == 'DXO Ask'
            labels.push {name: "Ask Date", value: moment(interaction.ask_date).format("MM/D/YYYY")}
            labels.push {name: "Ask Amount", value: numeral(interaction.ask_amount).format("$0,0.00")}

         labels

      render: ->
         {interaction} = @props
         secondary_target = interaction.secondary_target || []
         labels = @getLabels(interaction)
         groups = _.groupBy labels, "name"

         updated_at = moment(interaction.updated_at).format("MM/D/YYYY")
         updated_by = if interaction.update_source == "EVERTRUE" then " EverTrue" else " import"

         div className: "interaction-details",
            div className: "interaction-details--header", "EverTrue Details"
            div className: "interaction-details--content",
               div className: "interaction-details-labels",
                  @renderMetaLabel("Last Update", {name: "#{updated_at} via #{updated_by}"})
                  @renderMetaLabel("Trip", interaction.trip, "trips")
                  @renderMetaLabel("Proposal", interaction.proposal, "proposal")
                  @renderMetaLabel("Volunteer", interaction.volunteer, "contact")
                  @renderMetaLabel("Volunteer Pool", interaction.volunteer_pool, "volunteers/pool")


                  _.map groups, (label_values, key) ->
                     span className: "interaction-details--labels-item", key: key,
                        span null, str.humanize(key) + ": "
                        span className: "interaction-details--value", _.pluck(label_values, "value")?.join(", ")
                  CachedContactsResolver
                     ids: _.pluck(secondary_target, "target_id")
                     children: ({contacts}) ->
                        secondaries = secondary_target.map((target = {}) ->
                           contact = contacts[target.target_id] || {}
                           return contact
                        )
                        if secondaries.length
                           span className: "interaction-details--labels-item",
                              span null, "Additional Constituents: "
                              secondaries.map((contact, idx) ->
                                 Link key: contact.id || idx, href: "/contact/#{contact.id}", title: "profile of #{contact.name}",
                                    "#{contact.name}"
                                    if idx != secondaries.length - 1
                                       ", "
                              )
                        else null
