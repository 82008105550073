module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   {createSource} = require("@evertrue/et-flux")

   _initialized = false

   createSource "AlertSource",
      actions:
         clear: true
         clearConfirm: true
         clearUndoAlerts: true
         alert: (content, type, options) ->
            @require _.isString(type), "type should be string, (ex: success, warning)"
            @require _.isObject(options) || _.isUndefined(options), "options should be object or undefined"
         deleteStart: (id) ->
            @require !_.isUndefined(id), "id should not be undefined"
         deleteAlert: (id) ->
            @require !_.isUndefined(id), "id should not be undefined"
         confirm: (options, callback) ->
            @require _.isObject(options), "options should be object"
            @require _.isFunction(callback), "callback should be function"

      initialize: ->
         unless _initialized
            EverTrue.vent.on "route:change", =>
               @actions.clearUndoAlerts()
            _initialized = true

      api:

         confirm: (options, callback) ->
            if _.isString(options)
               options = {content: options}
            @actions.confirm(options, callback)

         success: (content, options) ->
            @actions.alert(content, "success", options)

         info: (content, options) ->
            @actions.alert(content, "info", options)

         error: (content, options) ->
            @actions.alert(content, "error", options)

         warn: (content, options) ->
            @actions.alert(content, "warning", options)

         delete: (id) ->
            @actions.deleteStart(id)
            _.delay((=> @actions.deleteAlert(id)), 500)

         clear: ->
            @actions.clear()

         clearConfirm: ->
            @actions.clearConfirm()
