import { INTERACTION_FIELDS, DXO_INTERACTION_FIELDS } from "apps/interactions/utils/interactions-enums.js";
import EverTrue from "app";
import Api from "entities/helpers/api";

export const isSubmitInteractionDisabled = (
  has_interaction_writes,
  interaction,
  isSummaryRequired,
  has_custom_fields,
  org_custom_fields,
  config,
  isSubmitting
) => {
  const missingRequiredStandardFields = (fields) => {
    return Object.entries(fields)
      .filter(([field]) => config[field].required)
      .some(([field, val]) => !interaction[val]);
  };

  const missingRequiredCustomFields = () => {
    const formFieldsObj = interaction.custom_field;
    if (!has_custom_fields) return false;

    return org_custom_fields
      .filter((field) => field.required)
      .some((field) => formFieldsObj[field.id] === null || formFieldsObj[field.id] === undefined);
  };

  const validateDXOAsk = () => {
    return !interaction.ask_amount || missingRequiredStandardFields(DXO_INTERACTION_FIELDS) || isInvalidDxoLength();
  };

  const validateComment = () => {
    return !interaction.text || missingRequiredCustomFields();
  };

  const missingSummary = () => {
    if (isSummaryRequired) {
      return !interaction.summary;
    } else return false;
  };

  const isInvalidInteractionLength = () => {
    const { summary, text } = interaction;
    let invalidSummaryLength, invalidTextLength;
    if (summary) invalidSummaryLength = summary.length > config.summary.max_length;
    if (text) invalidTextLength = text.length > config.text.max_length;
    return invalidSummaryLength || invalidTextLength;
  };
  const isInvalidDxoLength = () => {
    const { text } = interaction;
    let invalidTextLength;
    if (text) invalidTextLength = text.length > config.text.max_length;
    return invalidTextLength;
  };

  if (!interaction.interaction_type) return true;
  if (interaction.interaction_type === "EverTrue Comment" || !has_interaction_writes) return validateComment();
  if (interaction.interaction_type === "DXO Ask") return validateDXOAsk();
  return (
    !interaction.text ||
    missingRequiredCustomFields() ||
    missingRequiredStandardFields(INTERACTION_FIELDS) ||
    missingSummary() ||
    isInvalidInteractionLength() ||
    isSubmitting
  );
};

const _toast_options = (id) => {
  const has_action = !(window.location.pathname != null
    ? window.location.pathname.match(new RegExp(`^\\/interaction\\/${id}`))
    : undefined);
  const action_callback = () => EverTrue.Navigator(`/interaction/${id}`, { trigger: true });

  return {
    className: "toast-action-lg",
    timeout: 8000,
    actionLabel: has_action ? "View Interaction" : undefined,
    onAction: has_action ? action_callback : undefined,
  };
};

export async function getHouseholdMembersByIds(householdMembers) {
  const { items } = await Api.CONTACTS.BULK.get({
    params: { id: householdMembers.map((member) => member.id) },
  });
  return items;
}

function __guardMethod__(obj, methodName, transform) {
  if (typeof obj !== "undefined" && obj !== null && typeof obj[methodName] === "function") {
    return transform(obj, methodName);
  } else {
    return undefined;
  }
}
export class CreateInteractionApi {
  constructor(target_id, interaction, options = {}) {
    this.target_id = target_id;
    this.interaction = interaction;
    this.options = options;
  }

  async save() {
    const data = { ...this.interaction };
    if (
      !__guardMethod__(EverTrue.store.user, "getCurrentAffiliation", (o) => o.getCurrentAffiliation()) &&
      (EverTrue.config != null ? EverTrue.config.environment : undefined) === "production"
    ) {
      return EverTrue.Alert.error("Sorry, you must be affiliated with this organization to save an interaction.");
    } else {
      return Api.UGC.COMMENT.request({
        urlExtend: data.id ? `/contact/${this.target_id}/${data.id}` : undefined,
        type: data.id ? "PUT" : "POST",
        data: JSON.stringify({
          ...data,
          target_type: "CONTACT",
          target_id: this.target_id,
        }),
        success: (resp) => {
          return EverTrue.Alert.success(
            data.id ? "Success! Your interaction has been updated." : "Success! Your interaction has been saved.",
            _toast_options(resp.id)
          );
        },

        error(xhr, testStatus) {
          // 503 error status and message is for index blocked writes ES related from UGC so ignoring the hard coded errorMessage 
          if (xhr.status !== 503) {
          return EverTrue.Alert.error(
            data.id
              ? `We're sorry, an error occurred while trying to update your interaction. \
Please try again or contact support if the problem persists.`
              : `We're sorry, an error occurred while trying to save your interaction. \
Please try again or contact support if the problem persists.`
          );
          }
        },
      });
    }
  }

  async saveComment() {
    const payload = { ...this.interaction };
    delete payload.mentions;
    Object.keys(payload).forEach((key) => payload[key] === undefined && delete payload[key]);

    return await Api.UGC.COMMENT.request({
      urlExtend: this.interaction.id ? `/contact/${this.target_id}/${this.interaction.id}` : undefined,
      type: this.interaction.id ? "PUT" : "POST",
      data: JSON.stringify({
        ...payload,
        target_id: this.target_id,
        target_type: "CONTACT",
        contact_id: this.target_id,
        interaction_type: "EverTrue Comment",
      }),
      success: (resp) => {
        if (this.options.target_name) {
          return EverTrue.Alert.success(
            `Success! Your comment has been saved for ${this.options.target_name}.`,
            _toast_options(resp.id)
          );
        } else {
          return EverTrue.Alert.success("Success! Your comment has been saved.", _toast_options(resp.id));
        }
      },
      error(xhr) {
        // 503 error status and message is for index blocked writes ES related from UGC so ignoring the hard coded errorMessage 
        if (xhr.status !== 503) {
        return EverTrue.Alert.error(`We're sorry, an error occurred while trying to save your comment. \
Please try again or contact support if the problem persists.`);
        }
      },
    });
  }
}
