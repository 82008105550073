module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories
   AddressMixin = require("mixins/address-mixin")
   {createFactory} = require("base/new-utils")
   Icon = createFactory (require("components/elements/icon").default)
   Link = createFactory(require("@evertrue/et-components").Link)


   createComponent "Address",
      propTypes:
         data: ReactLibs.PropTypes.object
         hideIcon: ReactLibs.PropTypes.bool
         onClick: ReactLibs.PropTypes.func

      render: ->
         address = @props.data
         maps_link = if address then AddressMixin.formatMapsLink(address)

         div className: "address-template",
            if @props.hideIcon then div className: "address-template--no-icon"
            else Icon icon: "location", className: "address-template--icon"

            if _.isEmpty(address)
               div className: "address-template--data is-empty",
                  "Not provided"
            else
               div className: "address-template--data",
                  div className: "address-template--label",
                     address.type?.value || "Other"
                     if address.primary?.value then " (Primary)"

                  div className: "fs-exclude-data",
                     Link title: "address", href: maps_link, target: "_BLANK", onClick: @props.onClick,
                        div null, address.address_1?.value
                        div null, address.address_2?.value
                        div null, address.address_3?.value
                        div null, AddressMixin.formatLastLn(address)
