module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   AuthApi = require("entities/auth/auth-api")
   FeatureStore = require("apps/layout/stores/feature-store").default


   class Org extends AuthApi.Model
      urlRoot: ->
         url = "auth/organizations"
         url += "/#{@get("id")}" if @get("id")

      fetch: (options={}) ->
         opts =
            success:  ->
               EverTrue.store.cache "org"
               options.success() if _.isFunction options.success
         super _.extend opts, options

      # TODO: deprecate the use of this function,
      # instead use FeatureStore directly
      hasFeature: (key) ->
         FeatureStore.hasFeature(key)

      getRoleId: (name) ->
         role = _.findWhere @get("roles"), {name: name}
         role?.id
