module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   EverTrue = require("app")
   AuthApi = require("entities/auth/auth-api")
   PaginatedCollectionMixin = require("mixins/pagination-mixin")
   SortedCollectionMixin = require("mixins/sorted-mixin")
   SearchQueryCollectionMixin = require("mixins/search-query-mixin")
   Query = require("entities/search/query-parts/query")
   Parameter = require("entities/search/query-parts/parameter-query")
   Property = require("entities/search/query-parts/property-query")

   # Returns full social content data, including (content, engagement, profile, contact_id)
   class Social extends AuthApi.Collection
      @include [PaginatedCollectionMixin, SortedCollectionMixin, SearchQueryCollectionMixin]
      urlRoot: "search/v2/social/search"

      initialize: ->
         @setSort("engagement.created_at", true)

      parse: (data) ->
         @boundaries(data)
         _.map data.items, (data) ->
            data.id = data.contact_id + "_" + data.engagement?.compound_id
            data

      comparator: (model) ->
         key = _.first(@sortProp?.split("."))
         -model.get(key)?.created_at

      fetch: (options={}) ->
         opts = _.clone(options)
         params = _.clone(options.params)
         opts.params = $.extend true, {}, @paginate(params)
         opts.data = @doSort(opts.data)
         opts = @queryOptions(opts)
         super opts

      fetchForContact: (contact_id, options={}) ->
         opts = query: must: {contact_id: {match: contact_id}}
         @fetch _.extend opts, options

      fetchFacets: (key) ->
         @fetch(
            query: {facet: [{field: key, shardSize: 10000, size: 100} ]}
         ).then (collection, response) =>
            @reset(response.facets[key], {remove: true})

      infinityForContact: (contact_id, options={}) ->
         opts = _.clone(options)
         opts.query = must: {contact_id: {match: contact_id}}
         @infinity(opts)

      getEngagedContactIds: ->
         ids = @map (social) -> social.get("contact_id")
         _.uniq ids

      groupByDate: ->
         @groupResultsByDate( (social) ->
            social.get("engagement")?.created_at
         )

      pluckPostIds: ->
         remote_ids = @map (data) -> data.get("content")?.remote_id
         _.uniq(remote_ids)

      config:
         fb_pages:
            query_fn: (value) ->
               return if !value || _.isEmpty(value)
               ids = _.map value, (option) -> option.value
               Query [
                  Parameter "must", [
                     Property "content.page_remote_id", ids, {type: "contains"}
                  ]
               ]

         contents:
            query_fn: (value) ->
               return if !value || _.isEmpty(value)
               Query [
                  Parameter "must", _.map(value.split(" "), (val) ->
                     Property "content.contents", val, {type: "equals"}
                  )
               ]

