import _ from "underscore";
import { createStore } from "@evertrue/et-flux";
import Decorator from "clientDecorator";
import Query from "entities/search/query-parts/query";
import Parameter from "entities/search/query-parts/parameter-query";
import Property from "entities/search/query-parts/property-query";
import UrlSource from "apps/layout/sources/url-source";
import ProfileInteractionsSource from "apps/profile/sources/profile-interactions-source";
import InteractionSource from "apps/interactions/sources/interaction-source";
import InteractionStore from "apps/interactions/stores/interaction-store";
import InteractionUtils from "apps/interactions/interaction-utils";
import FeatureStore from "apps/layout/stores/feature-store";
import OrgSource from "base/org/org-source";
import get from "lodash.get";

const URL_KEY = "notefilters";
const SEARCH_CONFIG = {
  base(id, sort_order) {
    if (sort_order == null) {
      sort_order = "desc";
    }
    return Query([
      Parameter("must", [Property("target_id", id, { type: "equals" })]),

      Parameter("sort", [Property("date_occurred", { order: sort_order }, { type: "object" })]),
    ]);
  },

  category(value) {
    const categories = _.pluck(value, "value") || [];
    return Query([Parameter("must", InteractionUtils.getPropertiesForCategoryQuery(categories))]);
  },

  keyword(val) {
    const word_array = _.filter(val != null ? val.split(" ") : undefined, (word) => !!word);
    return Query([
      Parameter(
        "must",
        _.map(word_array, (val) => Property(["text", "summary"], val, { type: "wildcard" }))
      ),
    ]);
  },

  date(val) {
    const date_object = { gte: val.date_from, lte: val.date_to };
    return Query([Parameter("must", [Property("date_occurred", date_object, { type: "object" })])]);
  },

  author(values) {
    const names = _.pluck(values, "value");
    return Query([Parameter("must", [Property("author.name.untouched", names, { type: "contains" })])]);
  },
};

const _get_default_state = () => ({
  comments: {},
  contacts: {},
  filters: {},
  interaction_types: [],
  limit: 10,
  loading: false,
  interaction_types_in_flight: false,
});

export default createStore("ProfileInteractionsStore", {
  getInitialState: _get_default_state,

  firstListenerDidMount() {
    // this runs a lot, so adding a check before fetching
    return InteractionSource.fetchTypesUnlessCached();
  },

  registerActions() {
    this.on(ProfileInteractionsSource.actions.loading, this.respondToLoading);
    this.on(ProfileInteractionsSource.actions.fetching, this.respondToFetching);
    this.on(ProfileInteractionsSource.actions.fetchedNotes, this.respondToFetched);
    this.on(ProfileInteractionsSource.actions.filterNotes, this.respondToFilterNotes);
    this.on(ProfileInteractionsSource.actions.loadMore, this.respondToAddMore);
    this.on(ProfileInteractionsSource.actions.createdNote, this.respondToCreated);
    this.on(ProfileInteractionsSource.actions.deletedNote, this.respondToDeleted);

    this.on(UrlSource.actions.initializedUrl, this.respondToUrl);
    this.on(UrlSource.actions.fetched, this.respondToUrl);
    this.on(UrlSource.actions.changed, this.respondToUrl);

    // Needs the formatting from the updates here for gt_comments
    this.on(InteractionSource.actions.changedInteractions, function () {
      this.manual_trigger();
    });
    this.on(InteractionSource.actions.fetchedTypes, function (interaction_types) {
      this.setState({ interaction_types, interaction_types_in_flight: false });
    });

    this.on(InteractionSource.actions.fetchTypesUnlessCached, function () {
      if (_.isEmpty(this.state.interaction_types) && !this.state.interaction_types_in_flight) {
        this.setState({ interaction_types_in_flight: true });
        InteractionSource.fetchTypes();
      }
    });

    this.on(InteractionSource.actions.fetchedTypesError, function () {
      this.setState({ interaction_types_in_flight: true });
    });

    this.on(OrgSource.actions.newOrg, function () {
      this.setState(_get_default_state());
    });
  },

  respondToUrl(url) {
    if (url[URL_KEY]) {
      this.setState({ filters: url[URL_KEY] });
    }
  },

  respondToLoading(isLoading) {
    this.setState({ loading: isLoading });
  },

  respondToFetching(contact_id) {
    this.searchWithData(contact_id);
  },

  respondToFetched(id, data) {
    const comments = _.clone(this.getState("comments"));

    if (data.offset > 0) {
      const aggregate_items = (comments[id] != null ? comments[id].items : undefined) || [];
      _.extend(comments[id], data, { items: aggregate_items.concat(data.items) });
    } else {
      comments[id] = data;
    }

    InteractionSource.loadInteractions(data.items);
    this.setState({ comments, loading: false });
  },

  respondToFilterNotes(contact_id, filters, sort_order) {
    this.setState({ filters, sort_order });
    this.searchWithData(contact_id);
    UrlSource.change(_.toObject(URL_KEY, filters));
  },

  respondToAddMore(contact_id) {
    const comments = this.getState("comments")[contact_id] || {};
    this.searchWithData(contact_id, (comments.offset || 0) + this.getState("limit"));
  },

  respondToCreated(contact_id, note_data = {}) {
    const all_comments = _.cloneData(this.getState("comments")) || {};
    const contact_comments = all_comments[contact_id] || { items: [] };

    const updated_comment = _.findWhere(contact_comments.items, { id: note_data.id });
    if (updated_comment) {
      _.extend(updated_comment, note_data);
    } else {
      contact_comments.items = [note_data].concat(contact_comments.items);
    }
    contact_comments.items = _.sortBy(contact_comments.items, "date_occurred").reverse();

    InteractionSource.fetchInteraction(updated_comment ? updated_comment.id : note_data.id);
    InteractionSource.loadInteractions([updated_comment || note_data]);
    all_comments[contact_id] = contact_comments;
    this.setState({ comments: all_comments });

    // It takes a few seconds for the data to reach ES
    // re-fetching to get the displaynames for the custom fields
    _.delay(() => InteractionSource.fetchInteraction(updated_comment ? updated_comment.id : note_data.id), 1000);

    // It takes a few seconds for the data to reach ES
    // Fetch label values to pull any new values created
    if (!_.isEmpty(note_data.label)) {
      _.delay(() => _.each(note_data.label, (label) => InteractionSource.fetchValuesForLabel(label.name)), 2000);
    }
  },

  respondToDeleted(note, contact_id) {
    InteractionSource.removeInteraction(_.toNumber(note.id));
    const all_comments = _.cloneData(this.getState("comments")) || {};
    const comments = all_comments[contact_id];
    if (comments) {
      comments.items = _.reject(comments != null ? comments.items : undefined, (comment) => comment.id === note.id);
      this.setState({ comments: all_comments });
    }
  },

  searchWithData(id, next_offset) {
    const filter_object = _.cloneData(this.getState("filters"));
    const has_interaction_reads = FeatureStore.hasFeature("ugc_show_imported_notes");
    const query = SEARCH_CONFIG.base(id, this.getState("sort_order"));
    _.each(filter_object, function (filter_value, key) {
      let query_value;
      if ((query_value = typeof SEARCH_CONFIG[key] === "function" ? SEARCH_CONFIG[key](filter_value) : undefined)) {
        return query.merge(query_value);
      }
    });

    const query_obj = query.toJSON();
    const secondary = {
      "secondary_target.target_id": {
        in: [id],
      },
    };
    query_obj.must = query_obj.must.map((item) => {
      if (get(item, "target_id.match") === id) {
        return { ...item, ...secondary };
      }
      return item;
    });

    const comment = { "interaction_type.untouched": { match: "EverTrue Comment" } };

    const data = !has_interaction_reads
      ? {
          ...query_obj,
          must: [...query_obj.must, comment],
        }
      : query_obj;

    ProfileInteractionsSource.search(id, {
      data,
      params: {
        offset: next_offset || 0,
        limit: this.getState("limit"),
      },
    });
  },

  api: {
    getLoading() {
      return this.getState("loading");
    },

    getForContact(id) {
      return _.map(
        __guard__(
          __guard__(this.getState("comments"), (x1) => x1[id]),
          (x) => x.items
        ),
        (comment) => InteractionStore.getInteraction(comment.id) || {}
      );
    },

    getTotal(id) {
      return __guard__(
        __guard__(this.getState("comments"), (x1) => x1[id]),
        (x) => x.total
      );
    },

    getAppliedFilters() {
      return this.getState("filters");
    },

    getTypes() {
      const types = Decorator.Interactions.parseTypes(
        this.getState("interaction_types") || [],
        FeatureStore.hasFeature("dxo")
      );

      return _.map(types, (type = {}) => {
        return {
          label: type,
          category: type,
        };
      });
    },

    getWritableTypes() {
      return _.filter(this.getTypes(), function (type) {
        if (type == null) {
          type = {};
        }
        return !(type.label != null ? type.label.match("Volunteer") : undefined);
      });
    },

    hasMoreToLoad(id) {
      const comments = __guard__(this.getState("comments"), (x) => x[id]);
      return comments && comments.total > comments.offset + comments.limit;
    },
  },
});

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined;
}
