import { Component } from "react";
import _ from "underscore";
import ModalTrigger from "components/modals/modal-trigger";
import Module from "components/module";
import Icon from "components/elements/icon";
import CalendarSyncModal from "apps/my-trips/components/calendar-sync-modal";
import { LinkButton, Button } from "@evertrue/et-components";
import Loading from "components/elements/loading";
import EmptyState from "components/lists/empty-state-message";
import WithOrg from "base/org/with-org";
import Api from "entities/helpers/api";
import { REDIRECT_URI } from "apps/my-trips/constants";
import ErrorLogger from "entities/helpers/error-log-helper";

class CronofyTable extends Component {
  static propTypes = {
    accounts: ReactLibs.PropTypes.array,
    calendars: ReactLibs.PropTypes.object,
    isLoading: ReactLibs.PropTypes.bool,
    showDisconnectModal: ReactLibs.PropTypes.func,
    handleManageCalendars: ReactLibs.PropTypes.func,
    org: ReactLibs.PropTypes.object,
  };

  static defaultProps = {
    org: {},
    accounts: [],
    calendars: {},
    isLoading: false,
    authorizeUrl: "",
    showDisconnectModal: _.noop,
    handleManageCalendars: _.noop,
  };

  state = {
    url_loading: false,
  };

  handleRedirect = () => {
    this.setState({ url_loading: true });
    Api.VOYAGER.CRONOFY.get({
      urlExtend: "/auth/request",
      params: {
        redirect_uri: REDIRECT_URI,
        oid: this.props.org.id,
      },
      success: ({ authorize_url } = {}) => {
        window.location = authorize_url;
      },
      error: (xhr) => {
        ErrorLogger.captureRequest("Error loading cronofy auth URL", xhr);
        this.setState({ url_loading: false });
      },
    });
  };

  renderAccountTrigger() {
    return (
      <Button type="action" className="cronofy-table--connect-btn" onClick={this.handleRedirect}>
        {this.state.url_loading ? "Loading..." : "Connect a Calendar Service"}
      </Button>
    );
  }

  renderCalendarModalTrigger(init, calendars) {
    return (
      <ModalTrigger
        className="cronofy-table--add-calendar"
        modal={<CalendarSyncModal onManageCalendars={this.props.handleManageCalendars} calendars={calendars} />}
      >
        {init ? <Button className="cronofy-table--add-trigger">Select Calendars</Button> : "Manage Calendars"}
      </ModalTrigger>
    );
  }

  render() {
    const { accounts, calendars, isLoading, showDisconnectModal } = this.props;
    const display_account_trigger = Boolean(accounts.length);

    if (isLoading) return <Loading />;
    return (
      <Module
        className="cronofy-table"
        header={
          <div className="cronofy-table--header">
            <Icon icon="add-meeting" className="cronofy-table--icon" />
            Connected Calendar Service
            {display_account_trigger && this.renderAccountTrigger()}
          </div>
        }
      >
        <div className="cronofy-table--top-copy">
          Connect your Trips & Meetings to an external calendar. Once connected, the calendar will display your meetings
          with the date, time, location, description, and a link to the constituent(s) in EverTrue.
        </div>
        <div className="cronofy-table--wrapper">
          {accounts.length ? (
            accounts.map((account = {}) => {
              const { [account.provider_name]: calendar_group = [] } = calendars;
              const connected_cals = calendar_group.filter((cal) => cal.is_connected);

              return (
                <div className="cronofy-table--group" key={account.account_id}>
                  <div className="cronofy-table--group-header">
                    <div className="conofy-table--group-header--title">
                      <Icon className="cronofy-table--connected-icon" icon="check-circle" size={1} />
                      <span className="cronofy-table--group-name">{account.provider_name}</span>
                      <span className="cronofy-table--profile-name"> ({account.profile_name}) </span>

                      <LinkButton
                        className="cronofy-table--disconnect"
                        title="Disconnect Account"
                        onClick={() => showDisconnectModal(account)}
                      >
                        Disconnect Account
                      </LinkButton>
                    </div>

                    {connected_cals.length ? this.renderCalendarModalTrigger(false, calendar_group) : null}
                  </div>
                  <div className="cronofy-table--calendar-list">
                    {connected_cals.length ? (
                      connected_cals.map((calendar = {}) => (
                        <div className="cronofy-table--calendar-item" key={calendar.calendar_id}>
                          <span>{calendar.calendar_name}</span>
                        </div>
                      ))
                    ) : (
                      <div className="cronofy-table--calendar-empty">
                        <EmptyState icon="add-meeting" text="No Calendars added" size="small">
                          Please select the individual calendars you would like to connect with.
                        </EmptyState>
                        {this.renderCalendarModalTrigger(true, calendar_group)}
                      </div>
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <EmptyState className="cronofy-table--empty-state" icon="add-meeting" text="No Calendars Connected">
              {this.renderAccountTrigger(true)}
            </EmptyState>
          )}
        </div>
      </Module>
    );
  }
}

export default WithOrg(CronofyTable);
