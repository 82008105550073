import moment from "moment";
import { useState, useEffect } from "react";

const SORT_OPTIONS = [
  { label: "Last Name", value: "name_last" },
  { label: "Lifetime Giving", value: "giving.lifetime_amount" },
  { label: "Last Gift Date", value: "giving.last_gift_date" },
  { label: "Last Contact Date", value: "last_contact_date" },
  { label: "Time in Stage", value: "stage_start_date" },
];

const SORT_OPTIONS_MAP = [{ label: "Distance from map center", value: "proximity_sort" }, ...SORT_OPTIONS];

const getFormattedTimeInStage = (assignment = {}) => {
  let formatted_stage_start_date = "-";
  if (assignment.stage_start_date) {
    const duration = moment.duration(moment().diff(moment(assignment.stage_start_date)));
    const is_negative = Math.sign(duration) === -1;
    const human_duration = duration.humanize();
    formatted_stage_start_date = is_negative ? "- " + human_duration : human_duration;
  }
  return formatted_stage_start_date;
};

const useDebounce = (value, delay) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Set debouncedValue to value (passed in) after the specified delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Return a cleanup function that will be called every time ...
      // ... useEffect is re-called. useEffect will only be re-called ...
      // ... if value changes (see the inputs array below).
      // This is how we prevent debouncedValue from changing if value is ...
      // ... changed within the delay period. Timeout gets cleared and restarted.
      // To put it in context, if the user is typing within our app's ...
      // ... search box, we don't want the debouncedValue to update until ...
      // ... they've stopped typing for more than 500ms.
      return () => {
        clearTimeout(handler);
      };
    },
    // Only re-call effect if value changes
    // You could also add the "delay" var to inputs array if you ...
    // ... need to be able to change that dynamically.
    [delay, value]
  );

  return debouncedValue;
};

const getSolicitorInfoFromAssignments = (assignments) => {
  const hasAssignments = assignments[0] && assignments[0].assignment;
  const teamId = hasAssignments ? assignments[0].assignment.pool_id : undefined;
  const solicitorId = hasAssignments ? assignments[0].assignment.solicitor_contact_id : undefined;
  return { teamId, solicitorId };
};

export { SORT_OPTIONS, SORT_OPTIONS_MAP, getFormattedTimeInStage, useDebounce, getSolicitorInfoFromAssignments };
