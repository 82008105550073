import { PropTypes } from "base/react-libs";
import { Modal, ModalHeaderWithActions, ModalBody } from "@evertrue/et-components";
import { Checkbox, NumericInput } from "@evertrue/et-components";
import FormField from "components/forms/form-field";

const RecommendedActionUserSettingsModal = ({
  isOpen,
  onClose,
  submitUserSettings,
  enableGiftSection,
  enableAnniversarySection,
  enableCareerMovesSection,
  giftAmount,
  setEnableGiftSection,
  setEnableAnniversarySection,
  setEnableCareerMovesSection,
  setGiftAmount,
}) => {
  const onSubmit = async () => {
    await submitUserSettings();
    onClose();
  };

  const handleAmountChange = (value) => {
    if (!value) value = 0;
    setGiftAmount(value);
  };

  return (
    <Modal isOpen={isOpen} closeModal={onClose} size="medium">
      <ModalHeaderWithActions title="Recommended Actions Settings" closeModal={onClose} onSubmit={onSubmit} />
      <ModalBody scrollable={false}>
        <div className="recommendation-modal-subtitle">Select which Recommended Actions to Display</div>
        <Checkbox
          label="Made a gift in the last 15 days"
          value="gift"
          checked={enableGiftSection}
          onChange={() => setEnableGiftSection(!enableGiftSection)}
        >
          Made a gift in the last 15 days
        </Checkbox>
        <div style={{ paddingLeft: "31px" }}>
          <FormField inlineLabel={false} label="Minimum Gift Amount" required={false} className="title-subtitle-400-14">
            <NumericInput
              className="recommendation-modal-gift-amount-field"
              integerLimit={10}
              allowDecimal={true}
              value={giftAmount}
              placeholder={0}
              maskType="currency"
              onChange={handleAmountChange}
              showIcon={true}
            />
            <div className="journey-modal--alert">
              Your assigned constituents who make a gift amount of at least ${giftAmount} will appear in the Recommended
              Actions section.
            </div>
          </FormField>
        </div>
        <Checkbox
          className="recommended-action-user-settings-form-input"
          label="Career Moves in past 3 months"
          value="career_move"
          checked={enableCareerMovesSection}
          onChange={() => setEnableCareerMovesSection(!enableCareerMovesSection)}
        >
          Career Moves in past 3 months
        </Checkbox>
        <Checkbox
          className="recommended-action-user-settings-form-input"
          label="Upcoming gift anniversary in the next 30 days"
          value="anniversary"
          checked={enableAnniversarySection}
          onChange={() => setEnableAnniversarySection(!enableAnniversarySection)}
        >
          Upcoming gift anniversary in the next 30 days
        </Checkbox>
      </ModalBody>
    </Modal>
  );
};

RecommendedActionUserSettingsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  submitUserSettings: PropTypes.func,
  enableGiftSection: PropTypes.bool,
  enableAnniversarySection: PropTypes.bool,
  enableCareerMovesSection: PropTypes.bool,
  giftAmount: PropTypes.number,
  setEnableGiftSection: PropTypes.func,
  setEnableAnniversarySection: PropTypes.func,
  setEnableCareerMovesSection: PropTypes.func,
  setGiftAmount: PropTypes.func,
};

export default RecommendedActionUserSettingsModal;
