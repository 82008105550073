import PropTypes from "prop-types";
import IsGated from "components/is-gated";
import { useOpenClose, Button } from "@evertrue/et-components";
import JourneyTaskModal from "../modals/journey-task-modal";

const AddJourneyTaskButton = ({ journey = {}, optionalStateManagementFunction = () => {} }) => {
  const [isModalOpen, openModal, closeModal] = useOpenClose();

  // TODO: UNGATE
  return (
    <IsGated feature="unreleased_feature">
      <Button type="simple" onClick={openModal} className="add-step-button">
        + Add Step
      </Button>
      <JourneyTaskModal
        isOpen={isModalOpen}
        onClose={closeModal}
        journey={journey}
        optionalStateManagementFunction={optionalStateManagementFunction}
      />
    </IsGated>
  );
};

AddJourneyTaskButton.propTypes = {
  journey: PropTypes.object,
  optionalStateManagementFunction: PropTypes.func,
};

export default AddJourneyTaskButton;
