import { useContext } from "react";
import { Button, Icon } from "@evertrue/et-components";
import EverTrue from "app";
import { GlobalContactSearchContext } from "apps/layout/controllers/global-contact-search-context";
import SelectedContactsSource from "apps/contact/sources/selected-contacts-source";

const FilterReset = () => {
  const { resetFiltersAndSettings, quickFiltersCount, filterCount, setIsAllSelectedCheckbox, setMapMarkers } =
    useContext(GlobalContactSearchContext);

  return quickFiltersCount > 0 || filterCount > 0 ? (
    <Button
      type="simple"
      onClick={() => {
        EverTrue.track.set("filter_v2_action", {
          type: "reset_filters",
        });
        SelectedContactsSource.clear();
        setIsAllSelectedCheckbox(false);
        resetFiltersAndSettings(false);
        setMapMarkers({});
      }}
    >
      <Icon icon="clear" />
      Reset All
    </Button>
  ) : null;
};

export default FilterReset;
