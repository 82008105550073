module.exports = do ->
   _ = require("underscore").default
   numeral = require("numeral")
   moment = require("moment")
   str = require("underscore.string")
   colors = require("base/colors")

   TransactionalGiftUtils =
      formatAxisDollars: (axis_value) ->
         if axis_value < 1 then numeral(axis_value).format("($0,0[.]00a)")
         else if axis_value > 1000 then numeral(axis_value).format("$0,0[.]0a")
         else numeral(axis_value).format("$0,0a")

      formatFullDollars: (value) ->
         numeral(value).format("$0,00.00")

      formatDate: (value) ->
         if _.toNumber(value) == value
            if value.toString?()?.length == 4
               TransactionalGiftUtils.formatFiscalYear(value)
            else TransactionalGiftUtils.formatMonth(value)
         else if value instanceof Date
            TransactionalGiftUtils.formatMonth(value)
         else " "

      formatFiscalYear: (value) ->
         if _.toNumber(value) == value
            "FY " + moment(value, "YYYY").format("YY")
         else " "

      formatMonth: (value) ->
         moment(value).format("MMM. YY")

      formatType: (type, plural=true) ->
         humanize = str.titleize(str.humanize(type))
         if plural then _.pluralize(humanize)
         else humanize

      formatNonTransactionalGiftData: (contact_data) ->
         years = _.map contact_data, (gift) -> _.toNumber(gift.fiscal_year?.value)
         years = _.sortBy(years)

         _.map _.range(_.min(years), _.max(years) + 1), (year) ->
            value = _.find(contact_data, (d) -> d.fiscal_year && d.fiscal_year.value == year)
            {x: year, y: value?.amount?.value || 0, style: {fill: colors.green300, opacity: .4}}


