module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {path, g} = ReactLibs.DOMFactories
   colors = require("base/colors")

   MOVE = "M"
   LINE = "L"
   EXIT = "Z"
   DEFAULT_COLOR = colors.green300


   createComponent "Area",
      propTypes:
         data: ReactLibs.PropTypes.array
         clipData: ReactLibs.PropTypes.array # For cutting out bottom of area
         width: ReactLibs.PropTypes.number
         height: ReactLibs.PropTypes.number
         fillStyle: ReactLibs.PropTypes.object
         lineStyle: ReactLibs.PropTypes.object
         hideFill: ReactLibs.PropTypes.bool
         onMouseOver: ReactLibs.PropTypes.func
         onMouseOut: ReactLibs.PropTypes.func
         onClick: ReactLibs.PropTypes.func

      getDefaultProps: ->
         fillStyle: {fill: DEFAULT_COLOR, opacity: ".2"}
         lineStyle: {stroke: DEFAULT_COLOR, strokeWidth: 2, fill: "none"}

      getInitialState: ->
         area_fill: DEFAULT_COLOR
         area_opacity: ".2"
         line_stroke: DEFAULT_COLOR
         line_strokeWidth: 2
         line_fill: "none"

      componentDidMount: ->
         @setStyles(@props.fillStyle, @props.lineStyle, _.first(@props.data)?.style)

      UNSAFE_componentWillReceiveProps: (newProps) ->
         if (!_.isEqual(@props.fillStyle, newProps.fillStyle) ||
            !_.isEqual(@props.lineStyle, newProps.lineStyle) ||
            !_.isEqual(_.first(@props.data)?.style, _.first(newProps.data)?.style))
               @setStyles(newProps.fillStyle, newProps.lineStyle, _.first(newProps.data)?.style)

      setStyles: (fill_style, line_style, data_style) ->
         area_style = _.extend fill_style, data_style
         line_style = _.extend line_style, {stroke: data_style?.fill || DEFAULT_COLOR}, _.omit(data_style, "fill")

         @setState
            area_fill: area_style.fill
            area_opacity: area_style.opacity
            line_stroke: line_style.stroke
            line_strokeWidth: line_style.strokeWidth
            line_strokeOpacity: line_style.strokeOpacity
            line_fill: line_style.fill

      render: ->
         last_x = 0
         first_x = undefined
         line_length = @props.data?.length || 0
         line_desc = []

         data = @props.data || []
         if !_.isEmpty(@props.clipData)
            data = data.concat(@props.clipData.reverse())

         # This is the path description (d) to draw the area and line
         # It uses MOVE, LINE, EXIT to create the string for the property
         # Docs: https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/d
         desc = _.compact _.map data, (item, index) =>
            [xpoint, ypoint] = @props.getCoords(item)
            ypoint = @props.height - ypoint
            first_x ?= xpoint
            last_x = xpoint
            if !_.isNaN(xpoint) && !_.isNaN(ypoint) &&
                  !_.isUndefined(xpoint) && !_.isUndefined(ypoint)
               if index < line_length
                  line_desc.push LINE + xpoint + "," + ypoint
               LINE + xpoint + "," + ypoint

         first_x = first_x || 0
         fill_path = "M#{first_x},#{@props.height} " + desc.join("") + "L#{last_x},#{@props.height}" + EXIT
         line_path = "M" + line_desc.join("").slice(1)

         if _.isEmpty(desc) then null
         else
            g className: "etchart-area",
               unless @props.hideFill
                  path
                     className: "etchart-area--fill"
                     style: {fill: @state.area_fill, opacity: @state.area_opacity}
                     d: fill_path

               path
                  className: "etchart-area--line"
                  style:
                     stroke: @state.line_stroke
                     strokeWidth: @state.line_strokeWidth
                     strokeOpacity: @state.line_strokeOpacity
                     fill: @state.line_fill
                  d: line_path
                  onMouseOver: @props.onMouseOver
                  onMouseOut: @props.onMouseOut
                  onClick: @props.onClick
