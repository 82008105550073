import _ from "underscore";
import { createSource } from "@evertrue/et-flux";
import Api from "entities/helpers/api";

const InteractionSource = createSource("InteractionSource", {
  actions: {
    loadInteractions(interactions) {
      return this.require(_.isArray(interactions), "interactions should be an array");
    },
    removeInteraction(id) {
      return this.require(_.isNumber(id), "id should be a number");
    },
    fetchedInteraction(response) {
      return this.require(_.isPagedObject(response), "response should be a paged object");
    },
    fetchedTypes(types) {
      return this.require(_.isArray(types), "types should be an array");
    },
    fetchedLabels(labels) {
      return this.require(_.isArray(labels), "labels should be an array");
    },
    fetchedValuesForLabel(label, values) {
      this.require(_.isString(label), "label should be an array");
      return this.require(_.isArray(values), "values should be an array");
    },
    loading(is_loading) {
      return this.require(_.isBoolean(is_loading), "is_loading should be a boolean value");
    },
    fetchTypesUnlessCached: true,
    fetchedTypesError: true,
    changedInteractions: true,
  },

  api: {
    loadInteractions(interactions) {
      if (!_.isEmpty(interactions)) {
        this.actions.loadInteractions(interactions);
      }
    },

    changedInteractions() {
      this.actions.changedInteractions();
    },

    removeInteraction(id) {
      this.actions.removeInteraction(id);
    },

    fetchInteraction(id) {
      this.actions.loading(true);

      Api.SEARCH.CONTACT_NOTE.post({
        data: _.jsonStringify({ must: [{ id: { match: id } }] }),
        params: {
          limit: 5,
        },
        success: resp => {
          this.actions.fetchedInteraction(resp);
          this.actions.loading(false);
        },
      });
    },

    fetchTypes() {
      Api.UGC.INTERACTION_TYPES.get({
        success: response => {
          this.actions.fetchedTypes(response);
        },
        error: () => {
          this.actions.fetchedTypesError();
        },
      });
    },

    fetchLabels() {
      Api.SEARCH.CONTACT_NOTE.post({
        data: _.jsonStringify({
          facet: [{ field: "label.name.untouched", size: 500 }],
        }),
        success: resp => {
          const data = resp.facets["label.name.untouched"] || [];
          this.actions.fetchedLabels(data);
          _.each(data, label => {
            this.fetchValuesForLabel(label.value);
          });
        },
      });
    },

    fetchValuesForLabel(label) {
      Api.SEARCH.CONTACT_NOTE.post({
        data: _.jsonStringify({
          facet: [
            {
              field: "label.value.untouched",
              regexField: "label.name.untouched",
              regex: _.regexEscape(label),
              size: 500,
            },
          ],
        }),
        success: resp => {
          this.actions.fetchedValuesForLabel(label, resp.facets["label.value.untouched"] || []);
        },
      });
    },
    fetchTypesUnlessCached() {
      this.actions.fetchTypesUnlessCached();
    },
  },
});

export default InteractionSource;
