import EverTrue from "app";
import { PageHeader } from "@evertrue/et-components";
import PropTypes from "prop-types";
import DynamicListActions from "apps/dynamic-lists/components/dynamic-list-actions";
import DynamicListName from "apps/dynamic-lists/components/dynamic-list-name";
import { PERMISSIONS, FREQUENCY } from "apps/dynamic-lists/utils/dynamic-list-enums";
import moment from "moment";
import EmailAlertsModal from "apps/dynamic-lists/components/email-alerts-modal";
import Icon from "components/elements/icon";
import { useOpenClose } from "@evertrue/et-components";

const DynamicListHeader = ({ dsl, listData = {}, disabled = false, dynamicListAlertPref }) => {
  const { id, dsl_criteria, current_user_access, name, permission_stats: { owner_name } = {} } = listData;

  const { email_frequency, email_enabled, notification_timezone, starts_on } = dynamicListAlertPref;
  const [isModalOpen, openModal, closeModal] = useOpenClose();

  return (
    <>
      <PageHeader
        breadcrumbs={[
          current_user_access === PERMISSIONS.OWNER
            ? "Dynamic List (Owned by you)"
            : `Dynamic List (Owned by ${owner_name || "Removed User"})`,
        ]}
        title={<DynamicListName listId={id} listName={name} permission={current_user_access} />}
        onBackClick={() => EverTrue.Navigator("/dynamic-lists", true)}
        actions={
          <DynamicListActions
            contactFilters={dsl}
            id={id}
            listFilters={dsl_criteria}
            current_user_access={current_user_access}
            disabled={disabled}
          />
        }
      />
      {email_enabled && (
        <div className="display-alert" onClick={isModalOpen ? closeModal : openModal}>
          <Icon icon="email" />
          <p>
            {`${FREQUENCY[email_frequency]} starting from ${moment(starts_on).format("dddd")}, 
            ${moment(starts_on).format("MMM Do YYYY, h:mm a")} ${moment().tz(notification_timezone).format("z")}`}
          </p>
          <EmailAlertsModal is_open={isModalOpen} close={closeModal} list_id={id} />
        </div>
      )}
    </>
  );
};

DynamicListHeader.propTypes = {
  dsl: PropTypes.object.isRequired,
  listData: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  dynamicListAlertPref: PropTypes.object.isRequired,
};

export default DynamicListHeader;
