module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   EverTrue = require("app")
   React = require("react")
   {div} = ReactLibs.DOMFactories
   {createComponent} = require("@evertrue/et-flux")
   CompanyStore = require("apps/companies/stores/company-store")
   CompanySource = require("apps/companies/sources/company-source")
   CompanyProfileCard = require("apps/companies/components/company-profile-card")
   CompanyProfileBody = require("apps/companies/components/company-profile-body")
   MatchingConstituents = require("apps/companies/components/matching-constituents")
   FeatureStore = require("apps/layout/stores/feature-store").default

   createComponent "CompanyProfileController",
      registerStores: ->
         @on CompanyStore, ->
            data: CompanyStore.getData()
            stats: CompanyStore.getStats()
            constituents: CompanyStore.getConstituents()
         @on FeatureStore, ->
            has_filters_v2: FeatureStore.hasFeature("filters_v2")

      componentDidUpdate: (prevProps, prevState) ->
         if prevState.data.name != @state.data?.name
            EverTrue.execute "setBrowserTitle", @state.data?.name

      componentWillUnmount: ->
         CompanySource.resetData()

      render: ->
         filters_v2_opt_out = !!localStorage.getItem("filters_v2_opt_out")
         is_filters_v1 = !(@state.has_filters_v2 && !filters_v2_opt_out)

         switch @props.type
            when "company"
               help_key =  "company_constituents"
               header = "Constituents at Company"
               people_referrer = "company_people"
               link = $.param {filters: {company: [@state.data?.name]}}
               link_text = "View All Constituents at Company"
            when "industry"
               help_key = "industry_constituents"
               header = "Constituents in this Industry"
               people_referrer = "industry_people"
               link = $.param {filters: {industry: [@state.data?.name]}}
               link_text = "View All Constituents in this Industry"

         div className: "company-profile fixed-page-wrapper",
            div className: "profile",
               CompanyProfileCard
                  data: @state.data
                  matched: @state.constituents?.total
                  link: link
                  is_filters_v1: is_filters_v1

               div className: "company-profile--body",
                  div className: "company-profile--main-column",
                     CompanyProfileBody
                        data: @state.data
                        stats: @state.stats
                        type: @props.type

                  div className: "company-profile--side-column",
                     MatchingConstituents
                        link: link
                        link_text: link_text
                        limit: 5
                        header: header
                        help_key: help_key
                        help_position: "left"
                        referrer: people_referrer
                        constituents: @state.constituents?.items
                        is_filters_v1: is_filters_v1

