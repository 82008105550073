import EmailForm from "components/emailer/email-form";
import { Loading, Button } from "@evertrue/et-components";
import PropTypes from "prop-types";
import useEmailer from "components/emailer/hooks/use-emailer";
import GoogleDisclosure from "components/emailer/google-disclosure";
import { EmailTemplatesProvider } from "apps/outreach/email/templates/hooks/email-templates-context";

const EmailerController = ({ name, emailAddress = "", handleClose, setDrawerTitle, contactId, contact }) => {
  const { loading, emailerIsConnected, handleConnectEmail } = useEmailer();

  if (loading) return <Loading spinner_size="small" />;
  if (emailerIsConnected)
    return (
      <EmailTemplatesProvider>
        <EmailForm
          name={name}
          recipient={emailAddress}
          handleClose={handleClose}
          setDrawerTitle={setDrawerTitle}
          contactId={contactId}
          contact={contact}
        />
      </EmailTemplatesProvider>
    );
  if (emailerIsConnected !== null)
    return (
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <div className="oauth-controller">
          <h1>Connect Your Account</h1>
          <p>Link your account to start using email services here</p>
          <div>
            <Button className="oauth-provider--btn" onClick={handleConnectEmail}>
              Connect
            </Button>
          </div>
        </div>
        <GoogleDisclosure style={{ textAlign: "center", padding: "0 45px 15px" }} />
      </div>
    );
  return (
    <div className="oauth-controller">
      <h2 style={{ textAlign: "center" }}>There was a problem fetching your credentials.</h2>
      <p>Please contact genius@evertrue.com if the problem persists.</p>
    </div>
  );
};

EmailerController.propTypes = {
  emailAddress: PropTypes.string,
  name: PropTypes.string,
  handleClose: PropTypes.func,
  setDrawerTitle: PropTypes.func,
  contactId: PropTypes.number,
  contact: PropTypes.object,
};

export default EmailerController;
