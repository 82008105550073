import PropTypes from "prop-types";
import { LinkButton } from "@evertrue/et-components";

const EnrichmentList = ({ className, title = <div />, list = [], accessor, limit, openModal = () => {} }) => {
  const showMore = list.length > limit;
  const moreCount = list.length - limit;

  return list.length ? (
    <div className={`profile-enrichment--lists ${className}`}>
      {title}
      {list.slice(0, limit).map((item) => {
        return (
          <div className="profile-enrichment--list" key={item[accessor]}>
            {item[accessor]}
          </div>
        );
      })}
      {showMore && (
        <LinkButton className="profile-enrichment--more" title={`View ${moreCount} more interests`} onClick={openModal}>
          (+{moreCount} More)
        </LinkButton>
      )}
    </div>
  ) : null;
};

EnrichmentList.propTypes = {
  className: PropTypes.string,
  title: PropTypes.any,
  list: PropTypes.array,
  accessor: PropTypes.string,
  limit: PropTypes.number.isRequired,
  openModal: PropTypes.func,
};

export default EnrichmentList;
