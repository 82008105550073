import _ from "underscore";
import moment from "moment";
import uiComponents from "apps/query-builder/utils/filter-ui-components";

const isEmptyStringOrObject = (value) => {
  return value === "" || (typeof value === "object" && Object.keys(value).length === 0);
};

const isInvalidRollingDate = (rule) => {
  if (rule.ui_component === uiComponents.RollingDateInput) {
    if (
      (rule.value.lte && rule.value.lte.includes("null")) ||
      (rule.value.gte && rule.value.gte.includes("null")) ||
      (rule.value.gt && rule.value.gt.includes("null"))
    ) {
      return true;
    }
  }

  return false;
};

const isInvalidRange = (rule) => {
  if (rule.operator && rule.operator.includes("gte-lte") && rule.value) {
    if (
      rule.ui_component === uiComponents.CalendarDropdown ||
      rule.ui_component === uiComponents.CalendarPickerDropdown
    ) {
      if (!moment(rule.value.lte, "YYYY-MM-DD", true).isValid()) return true;
      if (!moment(rule.value.gte, "YYYY-MM-DD", true).isValid()) return true;
      return moment(rule.value.gte).isSameOrAfter(rule.value.lte);
    }

    return Number(rule.value.gte) >= Number(rule.value.lte);
  }
};

const isValuelessOperator = (operator) => {
  return operator && operator.includes("exists");
};

const isQueryBlockValid = (block) => {
  return block.rules.every((rule) => {
    if (isValuelessOperator(rule.operator) || _.isEmpty(rule)) return true;

    if (
      rule.value === undefined ||
      isEmptyStringOrObject(rule.value) ||
      isInvalidRange(rule) ||
      isInvalidRollingDate(rule)
    ) {
      return false;
    }

    return true;
  });
};

const isQueryValid = ({ filters }) => {
  const isValid = filters.blocks.every((block) => {
    return isQueryBlockValid(block);
  });

  return isValid;
};

export { isQueryValid, isQueryBlockValid };
