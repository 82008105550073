module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   React = require("react")
   Decorator = require("clientDecorator")
   EverTrue = require("app")
   {createComponent} = require("@evertrue/et-flux")
   {div, span} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   Link = createFactory(require("@evertrue/et-components").Link)

   createComponent "GoogleSearch",
      propTypes:
         contact: ReactLibs.PropTypes.object

      handleTrack: ->
         EverTrue.track.set "contact_action",
            type: "profile_google_search"
            referrer: "person"

      getSearches: ->
         name_string = Decorator.Contacts.getFullName(@props.contact)
         primary_work = Decorator.Work.getCurrentCompany(@props.contact)
         organization = EverTrue.store.org.get("name")

         primary_address = Decorator.Address.getPrimary(@props.contact)
         if primary_address
            location = _.compact([primary_address.city?.value, primary_address.state?.value]).join(", ")

         name_only: "#{name_string}"
         name_with_location: "#{name_string} in #{location}" if !_.isEmpty(location)
         name_with_company: "#{name_string} at #{primary_work.name}" if primary_work?.name
         name_with_org: "#{name_string} at #{organization}" if organization

      render: ->
         searches = @getSearches()

         div className: "google-search",
            _.map searches, (search) =>
               if search
                  query = $.param({q: search})
                  Link
                     title: "search this constituent on Google"
                     href: "https://www.google.com/search?#{query}"
                     className: "google-search--suggestion clearfix"
                     target: "_BLANK"
                     key: search
                     onClick: @handleTrack

                     span className: "google-search--icon"
                     span className: "google-search--label",
                        "Google \""
                        span className: "fs-exclude-data", search
                        "\""
