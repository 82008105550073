module.exports = do ->
   PropTypes = require("prop-types")
   $ = require("jquery")
   _ = require("underscore").default
   React = require("react")
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories
   CachedContactSource = require("apps/contact/sources/cached-contact-source")
   CachedContactStore = require("apps/contact/stores/cached-contact-store").default
   {createFactory} = require("base/new-utils")
   ContactCard = createFactory(require("apps/contact/components/contact-card/contact-card"))
   TagInput = require("components/controls/tag-input")


   createComponent "ContactTagList",
      propTypes:
         selected: PropTypes.array
         focusOnMount: PropTypes.bool
         presetResults: PropTypes.array
         onSelect: PropTypes.func
         className: PropTypes.string
         contactToExclude: PropTypes.object
         handleUnselect: PropTypes.func
         listId: PropTypes.number

      getDefaultProps: ->
         focusOnMount: true
         contactsFilterFunction: -> true

      getInitialState: ->
         typeahead_query: undefined

      registerStores: ->
         @on CachedContactStore, ->
            contacts: CachedContactStore.getAutocompleteResults()
            loading: false

      componentWillUnmount: ->
         clearTimeout(@searchTimeout)

      handleSelect: (tag) ->
         @props.onSelect(tag)

      handleTypeahead: (search) ->
         if _.isEmpty(search) then search = undefined
         @setState {typeahead_query: search, loading: !!search}
         clearTimeout(@searchTimeout)

         listId = @props.listId
         if search
            @searchTimeout = _.delay -> 
               CachedContactSource.autocomplete(search, listId)
            , 200


      render: ->
         selected_ids = _.pluck(@props.selected, "id")
         results =
            if @state.typeahead_query
               @state.contacts?[@state.typeahead_query]?.items
            else
               @props.presetResults

         filtered_results = _.reject results, (result) ->
            _.contains(selected_ids, result.id)

         contacts = filtered_results
         .filter(@props.contactsFilterFunction)

         div className: classNames("clearfix", @props.className), onClick: @handleInsideClick,
            TagInput
               options: contacts
               selected: @props.selected
               placeholder: @props.placeholder || "Search for Constituents..."
               focusOnMount: @props.focusOnMount
               loading: @state.loading
               onEscape: (evnt) ->
                  $(evnt.currentTarget).blur()
               itemContent: (item) =>
                  ContactCard
                     contact: item
                     showNameLink: false
                     highlight: @state.typeahead_query
                     showRemoteId: true
                     showMiddle: true
               onTag: @handleSelect
               onType: @handleTypeahead
               disabled: @props.disabled
               handleUnselect: @props.handleUnselect