module.exports = do ->
   _ = require("underscore").default
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")


   createSource "CustomFieldsSource",
      actions:
         fetchedFields: (resp) ->
            @require _.isObject(resp), "response should be an object"
         fetchIfNotCached: true

      api:
         fetch: ->
            Api.UGC.CUSTOM_FIELDS.get
               success: (resp) =>
                  @actions.fetchedFields(resp)

         fetchIfNotCached: ->
            @actions.fetchIfNotCached()
