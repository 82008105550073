import { useState, useEffect, Fragment } from "react";
import _ from "underscore";
import EverTrue from "app";
import numeral from "numeral";
import PropTypes from "prop-types";
import Api from "entities/helpers/api";
import ButtonSelect from "components/controls/button-select";
import { Button, ModalConfirm, FlexTable, FlexTableSortHeaderCell, Link, LinkButton } from "@evertrue/et-components";

const getTotal = (proposals, prop) =>
  _.reduce(
    proposals,
    (total, proposal) => {
      if (_.isNumber(proposal[prop])) {
        return total + proposal[prop];
      } else {
        return total;
      }
    },
    0
  );

const renderEmptyState = (tab, prospect_name, solicitor_name) => {
  return (
    <div className="pp-proposal-modal--empty-state">
      {tab === "my" ? (
        <div>{`No proposals for prospect ${prospect_name} and solicitor ${solicitor_name}.`}</div>
      ) : (
        <div>{`No proposals for prospect ${prospect_name}.`}</div>
      )}
    </div>
  );
};

// contact is the prospect's contact
// solicitor is the solicitor's contact
const ProspectProposalModal = ({ contact = {}, requestHide, solicitor = {} }) => {
  const [tab, setTab] = useState("my");
  const [myProposals, setMyProposals] = useState([]);
  const [allProposals, setAllProposals] = useState([]);
  const [sortReverse, setSortReverse] = useState(true);

  useEffect(() => {
    const query = { must: [{ "contacts.contact_id": { in: [contact.id] } }] };
    Api.SEARCH.PROPOSAL.post({ data: _.jsonStringify(query), params: { offset: 0, limit: 50 } }).then(
      (proposal_resp = {}) => {
        const proposals = proposal_resp.items || [];
        const my_proposals = _.filter(proposals, (proposal = {}) => {
          const solicitor_ids = _.pluck(proposal.solicitors || [], "contact_id");
          return _.contains(solicitor_ids, solicitor.id);
        });
        setMyProposals(my_proposals);
        setAllProposals(proposals);
      }
    );
  }, [contact.id, solicitor, solicitor.id]);

  const shown_proposals = _.sortBy(tab === "my" ? myProposals : allProposals, "ask_amount");
  const ask_total = getTotal(shown_proposals, "ask_amount");
  const funded_total = getTotal(shown_proposals, "funded_amount");

  const handleProposalLinkClick = (id) => {
    EverTrue.Navigator(`proposal/${id}`, true);
    EverTrue.track.set("proposal_action", { type: "view" });
  };

  return (
    <ModalConfirm header="Proposals" onCancel={requestHide}>
      <div className="pp-proposal-modal--top">
        <ButtonSelect
          selected={tab}
          buttons={[
            { value: "my", label: "My Proposals" },
            { value: "all", label: "All Proposals" },
          ]}
          onSelect={(val) => setTab(val)}
        />
        <div className="pp-proposal-modal--top-stat">
          <div className="pp-proposal-modal--stat-label">ASK</div>
          <div className="pp-proposal-modal--stat">{numeral(ask_total).format("$0,0[.]00")}</div>
        </div>
        <div className="pp-proposal-modal--top-stat">
          <div className="pp-proposal-modal--stat-label">FUNDED</div>
          <div className="pp-proposal-modal--stat">{numeral(funded_total).format("$0,0[.]00")}</div>
        </div>
        <div className="pp-proposal-modal--top-stat">
          <div className="pp-proposal-modal--stat-label">OUTSTANDING</div>
          <div className="pp-proposal-modal--stat">{numeral(ask_total - funded_total).format("$0,0[.]00")}</div>
        </div>
      </div>

      <div className="pp-proposal-modal--table-wrap">
        <div className="pp-proposal-modal--triangle" />
        {_.isEmpty(shown_proposals) ? (
          renderEmptyState(tab, contact.name, solicitor.name)
        ) : (
          <FlexTable
            caption="List of assignments this constituent has"
            data={sortReverse ? shown_proposals.reverse() : shown_proposals}
            columns={[
              {
                header: "Proposal",
                headerClassName: "pp-proposal-modal--col",
                cellClassName: "pp-proposal-modal--col",
                accessor: (proposal) => {
                  return (
                    <Fragment>
                      <div className="pp-proposal-modal--title-wrap">
                        <LinkButton
                          onClick={() => handleProposalLinkClick(proposal.id)}
                          className="pp-proposal-modal--title"
                        >
                          {proposal.title}
                        </LinkButton>
                        {!proposal.active && <span className="pp-proposal-modal--inactive">INACTIVE</span>}
                      </div>
                      <div>{proposal.description}</div>
                      <Link href={`contact/${contact.id}`}>{contact.name}</Link>
                    </Fragment>
                  );
                },
              },
              {
                header: (
                  <FlexTableSortHeaderCell
                    onClick={() => setSortReverse(!sortReverse)}
                    sortOrder={sortReverse}
                    isSorted={true}
                  >
                    Ask Amount
                  </FlexTableSortHeaderCell>
                ),
                headerClassName: "pp-proposal-modal--sort-col",
                accessor: (proposal) => {
                  return numeral(proposal.ask_amount).format("$0,0[.]00");
                },
              },
              {
                header: "Expected",
                accessor: (proposal) => {
                  return numeral(proposal.expected_amount).format("$0,0[.]00");
                },
              },
              {
                header: "Stage",
                accessor: (proposal) => {
                  return proposal.status;
                },
              },
              {
                header: "Solicitor",
                //   headerClassName: "rm-assign-contact--title",
                accessor: (proposal) => {
                  const solicitor_names = _.compact(_.pluck(proposal.solicitors || [], "name"));
                  return solicitor_names.join(", ");
                },
              },
            ]}
          />
        )}
      </div>
      <div className="pp-proposal-modal--footer">
        <Button type="simple" onClick={requestHide}>
          Cancel
        </Button>
      </div>
    </ModalConfirm>
  );
};

ProspectProposalModal.propTypes = {
  contact: PropTypes.object,
  solicitor: PropTypes.object,
  requestHide: PropTypes.func,
};

export default ProspectProposalModal;
