import _ from "underscore";
import EverTrue from "app";
import Decorator from "clientDecorator";
import { createStore } from "@evertrue/et-flux";
import InteractionSource from "apps/interactions/sources/interaction-source";
import AllUsersSource from "apps/users/sources/all-users-source";
import AllUsersStore from "apps/users/stores/all-users-store";
import CachedContactStore from "apps/contact/stores/cached-contact-store";
import CachedContactSource from "apps/contact/sources/cached-contact-source";
import MyTripsSource from "apps/my-trips/sources/my-trips-source";
import MyTripsStore from "apps/my-trips/stores/my-trips-store";
import PoolsSource from "apps/volunteers/sources/pools-source";
import ProposalSource from "apps/proposals/sources/proposal-source";
import ProposalStore from "apps/proposals/stores/proposal-store";
import FeatureStore from "apps/layout/stores/feature-store";
import OrgSource from "base/org/org-source";

const VOLUNTEER_CONTACT_LABEL = "Volunteer Contact ID";
const VOLUNTEER_POOL_LABEL = "Volunteer Pool ID";

export default createStore("InteractionStore", {
  getInitialState() {
    return {
      interactions: {},
      contacts: {},
      users: {},
      trips: {},
      pools: {},
      proposals: {},
      labels: undefined,
      label_values: {},
      loading: false,
    };
  },

  registerActions() {
    this.on(InteractionSource.actions.loadInteractions, this.respondToLoad);
    this.on(InteractionSource.actions.removeInteraction, this.respondToRemove);
    this.on(InteractionSource.actions.fetchedInteraction, this.respondToFetched);
    this.on(InteractionSource.actions.fetchedLabels, this.respondToFetchedLabels);
    this.on(InteractionSource.actions.fetchedValuesForLabel, this.respondToFetchedLabelValues);
    this.on(InteractionSource.actions.loading, this.respondToLoading);

    this.on(AllUsersSource.actions.changedUsers, function () {
      this.setState({ users: AllUsersStore.getAllUsers() });
      InteractionSource.changedInteractions();
    });

    this.on(CachedContactSource.actions.changedContacts, function () {
      this.setState({ contacts: CachedContactStore.getAllContacts() });
      InteractionSource.changedInteractions();
    });

    this.on(MyTripsSource.actions.changedTrips, function () {
      this.setState({ trips: MyTripsStore.getAllTrips() });
      InteractionSource.changedInteractions();
    });

    this.on(ProposalSource.actions.changedProposals, function () {
      this.setState({ proposals: ProposalStore.getAll() });
      InteractionSource.changedInteractions();
    });

    this.on(OrgSource.actions.newOrg, function () {
      this.setState({ labels: undefined, label_values: {} });
    });

    this.on(PoolsSource.actions.fetchedPoolById, this.respondToFetchedPoolById);
  },

  respondToLoading(is_loading) {
    this.setState({ loading: is_loading });
  },

  respondToLoad(interactions) {
    const cached_interactions = this.getState("interactions") || {};
    const updated_interactions = _.reduce(
      interactions,
      (accum, interaction) => _.extend({}, accum, { [interaction.id]: interaction }),
      cached_interactions
    );

    this.setState({ interactions: updated_interactions });
    this.downloadAdditionalData(interactions);
  },

  respondToRemove(id) {
    this.setState({
      interactions: _.omit(this.getState("interactions"), id),
    });
  },

  respondToFetched(data) {
    this.respondToLoad(data.items);
  },

  respondToFetchedPoolById(id, pool) {
    this.setState({
      pools: _.extend({}, this.getState("pools"), { [id]: pool }),
    });
  },

  downloadAdditionalData(interactions) {
    const contact_ids = [];
    const user_ids = [];
    const trip_ids = _.uniq(_.compact(_.pluck(interactions, "trip_id")));
    const proposal_ids = _.uniq(_.compact(_.pluck(interactions, "proposal_id")));

    _.each(interactions, function (interaction) {
      let pool_id, vol_id;
      if (interaction.target_type === "CONTACT") {
        contact_ids.push(interaction.target_id);
      }
      _.each(interaction.contact_mention, (mention) => contact_ids.push(mention.contact_id));

      if (interaction.creator_user_id) {
        user_ids.push(interaction.creator_user_id);
      }
      _.each(interaction.user_mention, (mention) => user_ids.push(mention.user_id));

      if ((vol_id = _.findWhere(interaction.label, { name: VOLUNTEER_CONTACT_LABEL }))) {
        contact_ids.push(_.toNumber(vol_id != null ? vol_id.value : undefined));
      }

      if ((pool_id = _.findWhere(interaction.label, { name: VOLUNTEER_POOL_LABEL }))) {
        // disable alerts because some pools may have been deleted
        return PoolsSource.fetchPoolById(_.toNumber(pool_id != null ? pool_id.value : undefined), true);
      }
    });

    AllUsersSource.fetchUsers(user_ids);
    CachedContactSource.fetchContacts(contact_ids);
    MyTripsSource.fetchCache(trip_ids);

    if (FeatureStore.hasFeature("proposal_reads")) {
      ProposalSource.fetchCache(proposal_ids);
    }
  },

  getFormattedInteraction(interaction = {}) {
    const users = this.getState("users");
    const contacts = this.getState("contacts");
    const trips = this.getState("trips");
    const proposals = this.getState("proposals");
    const pools = this.getState("pools");

    let user = users[interaction.creator_user_id];
    if (!user && interaction.creator_user_id === EverTrue.store.user.get("id")) {
      user = __guard__(EverTrue.store != null ? EverTrue.store.user : undefined, (x) => x.toJSON());
    }

    const mentions = _.flatten([interaction.contact_mention, interaction.user_mention]);
    const volunteer_id = __guard__(_.findWhere(interaction.label, { name: VOLUNTEER_CONTACT_LABEL }), (x1) => x1.value);
    let volunteer_contact = contacts[_.toNumber(volunteer_id)];
    if (volunteer_contact) {
      volunteer_contact = _.cloneData(volunteer_contact);
      volunteer_contact.name = Decorator.Contacts.getFullName(volunteer_contact);
    }

    const volunteer_pool_id = __guard__(
      _.findWhere(interaction.label, { name: VOLUNTEER_POOL_LABEL }),
      (x2) => x2.value
    );
    const volunteer_pool = pools[_.toNumber(volunteer_pool_id)];
    const custom_fields = interaction.custom_field || [];

    return _.extend({}, interaction, this.getSolicitorAndAuthorName(interaction, user), {
      creator: !(user != null ? user.removed : undefined) ? user : undefined,
      contact_id: interaction.target_id,
      contact: contacts[interaction.target_id],
      label: _.filter(
        interaction.label,
        (label) => !(label.name === VOLUNTEER_CONTACT_LABEL) && !(label.name === VOLUNTEER_POOL_LABEL)
      ),
      mentions: _.map(mentions, function (mention) {
        if (mention.user_id) {
          return _.extend({}, mention, { data: users[mention.user_id] });
        } else if (mention.contact_id) {
          return _.extend({}, mention, { data: contacts[mention.contact_id] });
        }
      }),
      trip: trips[interaction.trip_id],
      proposal: proposals[interaction.proposal_id],
      volunteer: volunteer_contact,
      volunteer_pool,
      custom_field: _.map(custom_fields, function (field) {
        if (field == null) {
          field = {};
        }
        return _.extend({}, field, { formattedValue: Decorator.Interactions.getFormattedCustomFieldValue(field) });
      }),
    });
  },

  respondToFetchedLabels(labels) {
    this.setState({ labels, label_values: {} });
  },

  respondToFetchedLabelValues(label, values) {
    const cached_label_values = _.cloneData(this.getState("label_values"));
    cached_label_values[label] = _.sortBy(
      _.compact(
        _.map(values, function (val) {
          if (!_.isEmpty(val.value)) {
            return { value: val.value, label: val.value };
          }
        })
      ),
      "label"
    );
    this.setState({ label_values: cached_label_values });
  },

  getSolicitorAndAuthorName(interaction = {}, user = {}) {
    const name =
      (interaction.author != null ? interaction.author.name : undefined) || (!user.removed ? user.name : undefined);
    const author_name = name
      ? name
      : !_.isEmpty(interaction.solicitor)
      ? __guard__(_.pluck(interaction.solicitor, "name"), (x) => x.join(" | "))
      : __guard__(EverTrue.store != null ? EverTrue.store.org : undefined, (x1) => x1.get("name")) + " Author";
    return { author_name };
  },

  api: {
    getLoading() {
      return this.getState("loading");
    },

    getInteractions(ids) {
      const interactions = this.getState("interactions");
      return _.compact(
        _.map(ids, (id) => {
          if (interactions[id]) {
            return this.getFormattedInteraction(interactions[id]);
          }
        })
      );
    },

    getInteraction(id) {
      const interaction = __guard__(this.getState("interactions"), (x) => x[id]);
      if (interaction) {
        return this.getFormattedInteraction(interaction);
      }
    },

    getLabels() {
      return _.map(this.getState("labels"), (facet) => facet.value);
    },

    getLabelValues(label) {
      const values = this.getState("label_values");
      if (label) {
        return values[label];
      } else {
        return values;
      }
    },

    hasFetchedLabels() {
      return !!this.getState("labels");
    },
  },
});

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined;
}
