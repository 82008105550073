import EverTrue from "app";
import { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "@evertrue/et-flux";
import { GlobalKeyPress, Icon, Popover2, Button } from "@evertrue/et-components";
import SelectedContactsSource from "apps/contact/sources/selected-contacts-source";
import SelectedContactsStore from "apps/contact/stores/selected-contacts-store";
import ContactAddTo from "apps/contact/components/contacts/table/contact-add-to";
import BulkAddStore from "apps/layout/stores/bulk-add-store";
import SelectedItemsToolbar from "components/controls/selected-items-toolbar";
import FocusLock from "react-focus-lock";

const mapStateToProps = () => ({
  selected: SelectedContactsStore.getSelectedContacts(),
  allSelected: SelectedContactsStore.getIsAllSelected(),
  bulkAddInProgress: BulkAddStore.getIsBulkAddProgress(),
});
const ID = "CONTACT_ADD_TO";

class SelectedContactsAddTo extends Component {
  static propTypes = {
    allowAddAll: PropTypes.bool,
    allSelected: PropTypes.bool,
    selected: PropTypes.array,
    contactIds: PropTypes.array,
    bulkAddInProgress: PropTypes.bool,
    className: PropTypes.string,
    totalResultsCount: PropTypes.number,
    filterParams: PropTypes.object,
    hideTrips: PropTypes.bool,
    renderToolbarItems: PropTypes.func,
  };

  static defaultProps = {
    selected: [],
    contactIds: [],
    allowAddAll: false,
    allSelected: false,
    bulkAddInProgress: false,
    className: "",
    totalResultsCount: null,
    filterParams: {},
    hideTrips: false,
    renderToolbarItems: () => null,
  };

  state = { modal: null };

  componentWillUnmount() {
    SelectedContactsSource.clear();
  }

  clearSelection() {
    SelectedContactsSource.unselect(this.props.contactIds);
  }

  setModal = (modal) => {
    this.setState({ modal });
  };

  render() {
    const {
      selected: { length: selectedLength },
      contactIds = [],
      totalResultsCount,
      renderToolbarItems,
      allSelected,
    } = this.props;
    const isPopoverDisabled = this.props.bulkAddInProgress || selectedLength === 0;
    const is_checked = selectedLength > 0;

    return (
      <div className={EverTrue.classSet("selected-contacts-addto", this.props.className)}>
        {/* this is for portals opened from a portal so we pass up to here to render */}
        {this.state.modal && this.state.modal}
        <SelectedItemsToolbar
          checked={is_checked}
          partial={is_checked && selectedLength < contactIds.length}
          onChange={(selectAll) => {
            if (totalResultsCount > 50) {
              SelectedContactsSource.toggleSelectedIndicator(selectAll);
            }
            if (selectAll) {
              SelectedContactsSource.select(contactIds);
            } else {
              SelectedContactsSource.selectAll(false);
              SelectedContactsSource.unselect(contactIds);
            }
          }}
        >
          <Fragment>
            <div className="addlist-popover">
              <Popover2
                placement="bottom-start"
                wrapperClassName="add-list--popover-wrap"
                disabled={isPopoverDisabled}
                trigger={({ togglePopover, is_open }) => {
                  return (
                    <Button
                      type="simple"
                      aria-disabled={isPopoverDisabled}
                      aria-expanded={is_open}
                      className={`selected-contacts-addto--btn ${isPopoverDisabled ? "is-disabled" : null}`}
                      onClick={!isPopoverDisabled ? togglePopover : () => { }}
                    >
                      Add to...
                      <Icon icon={is_open ? "chevron-up" : "chevron-down"} />
                    </Button>
                  );
                }}
              >
                {({ closePopover }) => (
                  <FocusLock>
                    <GlobalKeyPress onEscape={closePopover} />
                    <ContactAddTo
                      describedById={ID}
                      hideTrips={this.props.hideTrips}
                      totalResultsCount={this.props.totalResultsCount}
                      filterParams={this.props.filterParams}
                      contactIds={this.props.selected}
                      allSelected={allSelected}
                      allowAddAll={this.props.allowAddAll}
                      closePopover={closePopover}
                      setModal={this.setModal}
                    />
                  </FocusLock>
                )}
              </Popover2>
            </div>
            {renderToolbarItems({ isChecked: is_checked, allSelected })}
          </Fragment>
        </SelectedItemsToolbar>
      </div>
    );
  }
}
export default connect(SelectedContactsAddTo, [SelectedContactsStore, BulkAddStore], mapStateToProps);
