import numeral from "numeral";
import PropTypes from "prop-types";
import { ActionsMenu, Modal, ModalBody } from "@evertrue/et-components";
import ExportSearchController from "apps/export/controllers/export-search-controller";
import useResultCount from "apps/query-builder/hooks/use-result-count";
import colors from "@evertrue/et-variables/src/styles/variables.scss";
import ModifyQueryController from "apps/dynamic-lists/controllers/modify-query-controller";
import ExportSource from "apps/export/sources/export-source";
import DeleteModal from "apps/search/components/delete-modal";
import { deleteDynamicList } from "apps/dynamic-lists/utils/list-actions";
import EmailAlertsModal from "apps/dynamic-lists/components/email-alerts-modal";
import DynamicListSharingModal from "apps/dynamic-lists/components/dynamic-list-sharing-modal";
import AddToModal from "apps/search/components/add-to-modal.js";
import SelectedContactsStore from "apps/contact/stores/selected-contacts-store";
import { connect } from "@evertrue/et-flux";

const mapStateToProps = () => ({
  selected: SelectedContactsStore.getSelectedContacts(),
});

const DynamicListActions = ({ contactFilters, id, listFilters, current_user_access = null, disabled, selected }) => {
  const [resultCount] = useResultCount(contactFilters);
  const formattedCount = numeral(resultCount).format("0,0");
  const { scope } = contactFilters;

  const exportAction = {
    id: 1,
    icon: "cloud-download",
    iconStyle: { color: colors.blue300 },
    label: "Export Records",
    description: `Create a csv file with ${selected.length ? selected.length : formattedCount} records`,
    disabled: resultCount ? false : true,
    mountOnClick: ({ is_open, close }) => {
      const handleExport = (params) => {
        ExportSource.standardExportByType("contact", contactFilters, params);
      };

      return (
        <Modal isOpen={is_open} closeModal={close}>
          <ExportSearchController onExport={handleExport} onClose={close} />
        </Modal>
      );
    },
  };

  const addAction = {
    id: 6,
    icon: "list",
    iconStyle: { color: colors.green300 },
    description: `Add ${
      selected.length ? selected.length : resultCount.toLocaleString()
    } constituents to a List, Trip, or Pool`,
    label: "Add to List, Trip, or Pool",
    // eslint-disable-next-line react/prop-types
    mountOnClick: ({ is_open, close }) => {
      return (
        <AddToModal
          isOpen={is_open}
          close={close}
          selected={selected}
          ID={id.toString()}
          resultCount={resultCount}
          scope={scope}
        />
      );
    },
  };

  const modifyAction = {
    id: 2,
    icon: "arrow-up",
    iconStyle: { color: colors.blue300 },
    label: "Modify Search Criteria",
    description: `Update the search criteria for your list`,
    mountOnClick: ({ is_open, close }) => (
      <Modal isOpen={is_open} closeModal={close} size="large">
        <ModalBody scrollable={false}>
          <ModifyQueryController contactFilters={listFilters} closeModal={close} list_id={id} />
        </ModalBody>
      </Modal>
    ),
  };

  const deleteAction = {
    id: 3,
    icon: "trash",
    label: "Delete List",
    iconStyle: { color: colors.gray300 },
    mountOnClick: ({ is_open, close }) => (
      <DeleteModal
        isOpen={is_open}
        onClose={close}
        handleDelete={deleteDynamicList}
        title="Delete Dynamic List?"
        body="Are you sure you want to delete this Dynamic List? This action cannot be undone."
        list_id={String(id)}
      />
    ),
  };

  // eslint-disable-next-line no-unused-vars
  const alertsAction = {
    id: 4,
    icon: "email",
    label: "Email Alerts",
    description: "Create daily, weekly, monthly alerts",
    iconStyle: { color: colors.blue300 },
    mountOnClick: ({ is_open, close }) => <EmailAlertsModal is_open={is_open} close={close} list_id={id} />,
  };

  const checkPermissions = (user_access) => {
    const helperString = () => {
      return !selected.length
        ? `Action on All ${resultCount.toLocaleString()} Constituents`
        : `Action on ${selected.length} Constituents`;
    };

    const permissionOptions = {
      OWNER: [modifyAction, alertsAction, shareAction, deleteAction],
      EDIT: [modifyAction, alertsAction],
      VIEW: [alertsAction],
    };

    const options = [
      {
        id: 2,
        items: permissionOptions[user_access],
      },
      {
        id: 1,
        heading: helperString(),
        items: [exportAction, addAction],
      },
    ];

    return options;
  };

  const shareAction = {
    id: 5,
    icon: "share",
    label: "Share Dynamic List",
    iconStyle: { color: colors.purple300 },
    mountOnClick: ({ is_open, close }) => <DynamicListSharingModal id={String(id)} isOpen={is_open} onClose={close} />,
  };

  return <ActionsMenu options={checkPermissions(current_user_access)} disabled={disabled} />;
};

DynamicListActions.propTypes = {
  contactFilters: PropTypes.object,
  id: PropTypes.number,
  listFilters: PropTypes.object,
  current_user_access: PropTypes.string,
  disabled: PropTypes.bool,
  selected: PropTypes.array,
};

export default connect(DynamicListActions, [SelectedContactsStore], mapStateToProps);
