import _ from "underscore";
import EverTrue from "app";
import BaseRouter from "base/base-router";
import VolunteersController from "apps/volunteers/controllers/volunteers-controller";
import PoolController from "apps/volunteers/controllers/pool-controller";
import { PoolDataProvider } from "apps/volunteers/pool-data-context";
import { VolunteerSolicitorProvider } from "@evertrue/vms-insights";
import { fetchSolicitorsByContact } from "apps/volunteers/utils";

const Providers = props => (
  <VolunteerSolicitorProvider fetchSolicitorsByContactAndPoolId={fetchSolicitorsByContact}>
    {/* eslint-disable-next-line */}
    <PoolDataProvider>{props.children}</PoolDataProvider>
  </VolunteerSolicitorProvider>
);

class VolunteersRouter extends BaseRouter {
  static initClass() {
    this.prototype.routes = {
      "volunteers/:tab": "volunteers",
      "volunteers(/)": "volunteers",
      "volunteers/pool/:id": "pool",
      "volunteers/pool/:id/:tab": "pool",
    };
  }

  volunteers(tab = "pool") {
    const selected = String(tab).includes("manage") ? "manage" : "pools";
    EverTrue.execute("setBrowserTitle", "Volunteers");
    // TODO: figure out hot to make backbone router stop remounting app section to persist context btwn these routes
    this.currentView = (
      <Providers>
        <VolunteersController tab={selected} />
      </Providers>
    );
    EverTrue.page.mount(this.currentView);
  }

  pool(id, tab) {
    const the_tab = tab || "assignment";
    EverTrue.execute("setBrowserTitle", "Volunteers");
    this.currentView = (
      <Providers>
        <PoolController id={_.toNumber(id)} key={the_tab} tab={the_tab} />
      </Providers>
    );
    EverTrue.page.mount(this.currentView);
  }
}
VolunteersRouter.initClass();

export default VolunteersRouter;
