module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   React = require("react")
   BaseRouter = require("base/base-router")
   ExportsController = require("apps/export/controllers/exports-controller")

   class ExportRouter extends BaseRouter
      routes:
         "data/exports": "exports"

      exports: ->
         EverTrue.execute "removePageContainer"
         EverTrue.execute "setBrowserTitle", "Recent Exports"
         EverTrue.track.setReferrer("recent_exports")
         @currentView = ExportsController()
         EverTrue.page.mount @currentView
