_ = require("underscore").default
{createComponent} = require("@evertrue/et-flux")
{div} = ReactLibs.DOMFactories
{array, string} = ReactLibs.PropTypes
FeatureStore = require("apps/layout/stores/feature-store").default

_checkEnabled = (item) ->
   enabled = item?.enabled
   if not enabled? then true
   else if _.isFunction(enabled) then enabled()
   else !!enabled

module.exports =
   createComponent "FiltersBlock",
      propTypes:
         title: string
         filters: array # { label, component, [enabled]}

      render: ->
         filters = _.filter @props.filters, _checkEnabled
         ungated_filters = _.filter filters, (item) ->
            if !item.gated then true
            else if FeatureStore.hasFeature(item.gated) then true
            else false

         div className: "filters-block",
            div className: "filter-block--bracket"
            div className: "filters-block--wrapper",
               if @props.title
                  div className: "filters-block--title", @props.title

               div className: "filters-block--row",
                  _.map ungated_filters, ({label, component}, index) ->
                     div
                        key: label || index
                        className: "filters-block--item",
                        div className: "filters-block--label",
                           label
                        div className: "filters-block--component",
                           component
