module.exports = do ->
   React = require("react")
   {div} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   Icon = createFactory (require("components/elements/icon").default)
   classNames = require("classnames")
   Button = createFactory(require("@evertrue/et-components").Button)

   ReactLibs.createClass
      displayName: "EmptyStateMessage"
      propTypes:
         icon: ReactLibs.PropTypes.string
         iconKit: ReactLibs.PropTypes.string
         iconSize: ReactLibs.PropTypes.number
         text: ReactLibs.PropTypes.node
         size: ReactLibs.PropTypes.oneOf ["small", "default", "page"]
         actionLabel: ReactLibs.PropTypes.any
         actionLink: ReactLibs.PropTypes.any
         onAction: ReactLibs.PropTypes.func
         hideIcon: ReactLibs.PropTypes.bool
         subText: ReactLibs.PropTypes.oneOfType([ReactLibs.PropTypes.string, ReactLibs.PropTypes.node])
         iconClassName: ReactLibs.PropTypes.string

      getDefaultProps: ->
         icon: "search"
         size: "default"
         hideIcon: false
         iconClassName: "" 

      render: ->
         div className: classNames("empty-state-message", @props.className, "is-#{@props.size}"),
            if !@props.hideIcon
               Icon className: classNames("empty-state-message--icon", @props.iconClassName), icon: @props.icon, iconKit: @props.iconKit, size: @props.iconSize

            if @props.text
               div className: "empty-state-message--text", @props.text

            if @props.subtext
               div className: "empty-state-message--subtext", @props.subtext

            div className: "empty-state-message--content",
               @props.children

            if @props.actionLabel
               div className: "empty-state-message--action-area",
                  if @props.onAction
                     Button
                        type: "secondary"
                        className: "empty-state-message--action-button"
                        onClick: @props.onAction
                        @props.actionLabel
                  else if @props.actionLink
                     Button
                        type: "secondary"
                        className: "empty-state-message--action-button"
                        href: @props.actionLink
                        @props.actionLabel
