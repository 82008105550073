import PropTypes from "prop-types";
import EverTrue from "app";
import { useEffect, useRef } from "react";

const ChatMessages = ({ userMessageRequests, aiResponses, isStreaming, suggestedSearches, handleSuggestedSearchClick }) => {
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Scroll whenever there's a new response
  useEffect(() => {
    scrollToBottom();
  }, [aiResponses]);

  const handleCopyToClipboard = async (text, successMessage, errorMessage) => {
    try {
      await navigator.clipboard.writeText(text);
      EverTrue.Alert.success(successMessage);
    } catch {
      EverTrue.Alert.error(errorMessage);
    }
  };

  const handleResponseCopy = (responseText) => {
    const fullResponse = responseText.join("");
    handleCopyToClipboard(
      fullResponse,
      "AI response copied to clipboard!",
      "There was a problem copying the response. Please manually copy it."
    );
  };

  return (
    <div className={`chat-messages${ suggestedSearches && userMessageRequests.length === 0 ? '-centered' : ''}`}>
      {/* rendered list of suggested searches only if userMessageRequests is empty */}
      {suggestedSearches && userMessageRequests.length === 0 && (
      <div className="suggested-searches">
        <ul className="suggested-search-list">
          <li className="suggested-search-item-header">
            <div className="suggested-search-item-header-box">Get started with these suggested searches!</div>
          </li>
          {suggestedSearches.map((searchItem) => (
                <li key={searchItem} className="suggested-search-item" onClick={() => handleSuggestedSearchClick(searchItem)}>
                  <div className="suggested-search-item-box">{searchItem}</div>
                </li>
              ))}
        </ul>
      </div>
      )}
      {userMessageRequests.map(({ content: message, index: messageId }, i) => {
        const aiResponse = aiResponses[i];
        const currentResponseIndex = userMessageRequests.length - 1;

        const isTyping =
          isStreaming && // Check if streaming is active
          messageId === currentResponseIndex && // Check if the messageId matches the current response index
          (!aiResponses[currentResponseIndex] || // Check if there's no response yet
            aiResponses[currentResponseIndex].length < 2); // Check if the response length<2 (considered typing)

        return (
          <div key={messageId}>
            <div className="message-container request">
              <div className="message request" data-index={messageId}>
                {message}
              </div>
            </div>
            <div className="message-container response">
              <div className="message response" data-response-index={messageId} style={{ whiteSpace: "pre-ine" }}>
                {isTyping ? "..." : aiResponse}
              </div>
              {(!isStreaming || messageId !== currentResponseIndex) && (
                <div className="response-copy-icon">
                  {aiResponse && <i className="fa fa-fw fa-clone" onClick={() => handleResponseCopy(aiResponse)} />}
                </div>
              )}
            </div>
          </div>
        );
      })}
      <div ref={messagesEndRef} />
    </div>
  );
};

ChatMessages.propTypes = {
  userMessageRequests: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      content: PropTypes.string,
      index: PropTypes.number,
    })
  ),
  aiResponses: PropTypes.object,
  isStreaming: PropTypes.bool,
  suggestedSearches: PropTypes.object,
  handleSuggestedSearchClick: PropTypes.func,
};

export default ChatMessages;
