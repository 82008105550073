module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   EverTrue = require("app")
   {createComponent} = require("@evertrue/et-flux")
   {g, line, text} = ReactLibs.DOMFactories
   colors = require("base/colors")


   createComponent "YAxis",
      propTypes:
         width: ReactLibs.PropTypes.number.isRequired
         height: ReactLibs.PropTypes.number.isRequired
         ticks: ReactLibs.PropTypes.array
         format: ReactLibs.PropTypes.func
         lineStyle: ReactLibs.PropTypes.object
         axisStyle: ReactLibs.PropTypes.object
         textStyle: ReactLibs.PropTypes.object
         getCoords: ReactLibs.PropTypes.func.isRequired

      getDefaultProps: ->
         lineStyle: {stroke: "#E5E5E5", fill: "none"}
         axisStyle: {stroke: "#E5E5E5", fill: "none"}
         textStyle: {fill: colors.gray300}

      render: ->
         g className: "etchart--yaxis",
            _.map @props.ticks, (value, index) =>
               y_offset = @props.height - @props.getCoords({y: value})?[1]

               g
                  className: "etchart--yaxis-section"
                  transform: "translate(0, #{y_offset})"
                  key: index

                  line
                     className: "etchart--yaxis-line",
                     style: if index == 0 then @props.axisStyle else @props.lineStyle
                     y2: 0
                     x2: @props.width

                  text
                     className: "etchart--xaxis-text"
                     textAnchor: "end"
                     style: @props.textStyle
                     x: -5
                     y: 5

                     @props.format?(value) || value
