module.exports = do ->
   React = require("react")
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories


   createComponent "FilterPanelRow",
      propTypes:
         label: ReactLibs.PropTypes.any
         hasColumn: ReactLibs.PropTypes.bool
         hasActiveColumn: ReactLibs.PropTypes.bool

      render: ->
         div className: classNames("filter-panel-row", @props.className),
            div className: "filter-panel-row--label", @props.label
            div className: "filter-panel-row--control",
               @props.children
