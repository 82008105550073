import { useContext } from "react";
import { LinkButton, Card } from "@evertrue/et-components";
import { PropTypes } from "base/react-libs";
import { ClarityContext } from "../controllers/clarity-context";
import Api from "entities/helpers/api";
import EverTrue from "app";

const ClarityCard = ({ board }) => {
  const { org } = useContext(ClarityContext);

  const handleClick = async (board_path) => {
    try {
      const { url } = await Api.AUTH.TABLEAU_URL.post({
        params: { oid: org.id, dashboard: board_path },
      });

      window.open(url, "_blank", "noopener,noreferrer");
    } catch {
      EverTrue.Alert.error("Dashboard failed to open. Please try again later.");
    }
  };

  return (
    <div>
      <LinkButton className="clarity-link" title={board.description} onClick={() => handleClick(board.path)}>
        <Card className="clarity-card" headingText={board.name} hoverTransform="clickable" padding="12px">
          <img src={board.image_url} alt={board.name} />
        </Card>
      </LinkButton>
    </div>
  );
};

ClarityCard.propTypes = {
  board: PropTypes.object,
};

export default ClarityCard;
