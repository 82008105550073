module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   classNames = require("classnames")
   React = require("react")
   OutsideClickMixin = require("mixins/outside-click-mixin")
   LayoutSource = require("apps/layout/sources/layout-source")
   {createFactory} = require("base/new-utils")
   FocusLock = createFactory(require("react-focus-lock").default)
   {div} = ReactLibs.DOMFactories


   Modal = ReactLibs.createClass
      mixins: [OutsideClickMixin]
      propTypes:
         remove: ReactLibs.PropTypes.func # set to remove modal from DOM
         onUnmount: ReactLibs.PropTypes.func # call when unmount is triggered
         onMount: ReactLibs.PropTypes.func
         backdrop: ReactLibs.PropTypes.bool
         keepOpen: ReactLibs.PropTypes.bool
         width: ReactLibs.PropTypes.number
         display: ReactLibs.PropTypes.string
         className: ReactLibs.PropTypes.string
         removeOverlayOnHide: ReactLibs.PropTypes.bool
         animationClass: ReactLibs.PropTypes.oneOfType [
            ReactLibs.PropTypes.string
            ReactLibs.PropTypes.bool
         ]

      node: React.createRef()

      componentDidMount: ->
         @is_mounted = true
         $("body").on "keyup", @handleEscape
         @props.onMount?()

      componentWillUnmount: ->
         $("body").off "keyup", @handleEscape
         @props.onUnmount?()
         @is_mounted = false

      getDefaultProps: ->
         backdrop: true
         animationClass: "fadeInDown"

      handleOutsideClick: ->
         @hide() unless @props.keepOpen

      handleEscape: (e) ->
         @hide() if e.keyCode == 27

      hide: ->
         return unless @is_mounted
         $element = $(@node.current)
         $element.find(".modal--dialog.animated").removeClass(@props.animationClass).addClass("fadeOutUp")
         $element.find(".modal--overlay.animated").removeClass(@props.animationClass).addClass("fadeOut")
         _.delay =>
            if _.isFunction @props.remove then @props.remove()
            if @props.removeOverlayOnHide
               LayoutSource.removeOverlay()
         , 300

      content: ->
         # cloneWithProps doesn't work with BackboneReactComponent
         # because it removes the owner, which keeps track of the entities
         extendedProps = {requestHide: => @hide()}
         children = React.Children.toArray(@props.children)
         React.Children.map(children, (child) ->
            if child.clone
               # BackboneReactComponent.clone replaces all props, so we need
               # to add back the model(s) and collections(s) as they should be passed
               newProps = $.extend true, {}, child.props, extendedProps,
                  model: child.getModel?()
                  collection: child.getCollection?()
               child.clone(newProps)
            else
               React.cloneElement(child, extendedProps)
         )


      render: ->
         baseClasses = classNames(@props.className, {"modal": true})

         overlayClasses = classNames
            "modal--overlay": @props.backdrop
            "animated": !!@props.animationClass
            "fadeIn": !!@props.animationClass

         dialogClasses = classNames @props.animationClass,
            "modal--dialog": true
            "animated": !!@props.animationClass

         FocusLock null,
            div className: baseClasses, ref: @node,
               div className: overlayClasses, onClick: @handleOutsideClick,
                  div className: dialogClasses, onClick: @handleInsideClick, style: {width: @props.width, display: @props.display},

                     if @props.render
                        @props.render({onHide: @hide})
                     else
                        @content()

   return Modal
