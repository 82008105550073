module.exports = do ->

   _ = require("underscore").default
   EverTrue = require("app")
   AuthApi = require("entities/auth/auth-api")
   Contact = require("entities/contacts/contact")
   RoleHelper = require("entities/helpers/role-helper")


   class User extends AuthApi.Model
      urlRoot: ->
         if @get("id") then "auth/users/#{@get("id")}" else "auth/users"

      defaults:
         contact:
            avatar: undefined
         avatar: undefined

      parse: (data) ->
         data.avatar = @getAvatarUrl(data.id) if data?.id
         data

      initialize: (data) ->
         @set("avatar", @getAvatarUrl(data.id)) if data?.id

      getAvatarUrl: (id) ->
         EverTrue.config.apiBase + "lids/users/#{id}/linkedin/avatar"

      fetchWithContact: (options={}) ->
         opts = _.clone(options)
         opts.silent = true
         opts.success = (resp, data) =>
            contact = @fetchContact(options)
            if !contact
               @trigger("change")
               if _.isFunction options.success
                  options.success(resp, data)
         @fetch opts

      fetchContact: (options={}) ->
         contact_id = @getContact()
         if contact_id
            contact = new Contact({id: contact_id})
            contact.fetch
               cache: true
               success: =>
                  @set {contact: contact.toJSON()}
                  if _.isFunction options.success
                     options.success(@)
         contact_id

      getAllAffiliations: ->
         @get("affiliations")

      getGivingTreeAffiliations: ->
         _.filter @get("affiliations"), (affil) ->
            roles = _.filter affil.affiliation_roles, (role) ->
               name = role?.role?.name
               RoleHelper.isUserRole(name) || RoleHelper.isOwnerRole(name)
            !_.isEmpty(roles)

      getOrganizations: ->
         _.map @getGivingTreeAffiliations(), (affil) ->
            _.extend {}, affil.organization,
               id: affil.organization?.id || affil.organization_id

      getAffiliationFor: (org) ->
         _.find @get("affiliations"), (affiliation) ->
            oid = affiliation.organization?.id || affiliation.organization_id
            (oid == org) || (affiliation.organization?.name == org)

      getCurrentAffiliation: ->
         @getAffiliationFor(EverTrue.store.org.get("id"))

      getRoles: ->
         affil = @getCurrentAffiliation()
         _.map affil?.affiliation_roles, (role) ->
            {id: role.role?.id || role.role_id, name: role.role?.name}

      getGivingTreeRoles: ->
         _.filter @getRoles(), (role) ->
            user = RoleHelper.isUserRole(role.id) || RoleHelper.isUserRole(role.name)
            owner = RoleHelper.isOwnerRole(role.id) || RoleHelper.isOwnerRole(role.name)
            user || owner

      getContact: ->
         @getCurrentAffiliation()?.contact_id

      setAffiliation: (data) ->
         affiliations = @get("affiliations")
         affilition = _.findWhere affiliations, {id: data.id}
         if affilition then _.extend affilition, data
         else affiliations.push(data)

      hasAffiliationTo: (org) ->
         !!@getAffiliationFor(org)

      hasGivingTreeAffiliationTo: (org) ->
         orgs = @getOrganizations()
         _.find orgs, (o) -> (o?.id == org) || (o?.name == org)

      isInternal: ->
         !!(@hasAffiliationTo("EverTrue") || @get("email")?.match("@evertrue.com"))

      isSuperUser: ->
         !!@get("super_user")

      hasConsole: ->
         roles = _.pluck(@getRoles(), "name")
         _.notEmpty(_.intersection(roles, ["Admin", "Console User"]))

      isOwner: ->
         roles = _.pluck(@getRoles(), "id")
         !!_.contains(roles, RoleHelper.getOwnerId())

      isOwnerOrSuperUser: ->
         @isOwner() || @isSuperUser()

      hasMFA: ->
         !!@get("mfa_enabled_at")

      isUser: ->
         roles = _.pluck(@getRoles(), "id")
         !!_.contains(roles, RoleHelper.getUserId())
