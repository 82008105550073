module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   {createStore} = require("@evertrue/et-flux")
   User = require("entities/auth/user")
   UserSource = require("base/user/user-source")
   SessionSource = require("base/session/session-source")
   SecuritySource = require("apps/settings/security-settings/sources/security-source")
   LoginSource = require("apps/login/sources/login-source")
   OrgSource = require("base/org/org-source").default

   MAX_HOURLY_ATTEMPTS = 20

   _formatUsers = (users) ->
      _.map users, (user) ->
         _.extend {}, user,
            affiliations: _.map user.organizations, (org) ->
               organization: org
               affiliation_roles: _.map user?.roles_mapping?[org.id], (value) ->
                  {role: {name: value}}

   _getDefaults = ->
      lids_unauthorized: false
      recent_renewals: []
      is_renewing: false
      session: undefined


   createStore "SessionStore",
      getInitialState: ->
         _getDefaults()

      registerActions: ->
         # GENERAL
         @on SessionSource.actions.setSession, @respondToSetSession
         @on SessionSource.actions.isRenewing, @respondToRenew

         # APP INIT
         @on SessionSource.actions.startInitialSessionAttempt, (oid) ->
            if @getState("is_renewing")
               EverTrue.execute("startRouter")
            else
               SessionSource.createSessionWithCookie(oid)

         # LINKEDIN LOGIN
         @on SessionSource.actions.createdSessionWithLidsToken, @respondToCreatedWithLids

         # RENEW
         @on SessionSource.actions.startRenewSession, @respondToStartRenew
         @on SessionSource.actions.setSessionRenewed, @respondToRenewed
         @on SessionSource.actions.errorRenewingSession, @respondToErrorRenew

         # REFRESH
         @on SecuritySource.actions.refreshSession, @respondToRefresh
         @on SessionSource.actions.refresh, @respondToRefresh

         #UPDATE PHOTO
         @on SessionSource.actions.updatePhoto, (url) ->
            session = _.cloneData(@state.session)
            session.user.user_profile_picture_url = url
            @setState({session})

         @on SessionSource.actions.lidsIsUnauthorized, @respondToLidsUnauthorized

         # LOGOUT
         @on SessionSource.actions.logout, @respondToLogout

      respondToLogout: ->
         @setState(_getDefaults())
         SessionSource.saveToStore()

      respondToSetSession: (session) ->
         @setState {session: session}

      respondToRenew: (is_renewing) ->
         EverTrue.auth.isRenewing = is_renewing
         @setState {is_renewing: is_renewing}


      respondToCreatedWithLids: (resp) ->
         if _.isArray(resp)
            users = _formatUsers(resp)
         else
            UserSource.set(resp.user)
            SessionSource.setSession(resp)

            user = new User(resp.user)
            orgs = user.getOrganizations()
            if _.size(orgs) == 1 && !user.isSuperUser()
               OrgSource.select(_.first(orgs))
            else
               users = _.makeArray(resp?.user)

         @setState {user_options: users}
         EverTrue.execute "goToInitialPage"

      respondToStartRenew: (options) ->
         renewals = @getFilteredRenewals()
         unless @getState("is_renewing")
            if _.size(renewals) > MAX_HOURLY_ATTEMPTS
               SessionSource.errorMaxRenewals()
            else
               @setState
                  previous_user_id: EverTrue.store.user?.get("id")
                  recent_renewals: renewals.concat(new Date().getTime())
               SessionSource.promise.renewSession(options)

      respondToRenewed: (session) ->
         @validateAndRedirectUserForOrg(session.user)
         SessionSource.setSession(session)

      # non SSO - redirects to LI auth URL
      # SSO - redirects to accounts.evertrue/evertrue/login/org
      respondToErrorRenew: (ssoRedirectUrl) ->
         if ssoRedirectUrl
            SessionSource.ssoSessionEnded(ssoRedirectUrl)
         else LoginSource.redirectToAuthUrl()

      respondToLidsUnauthorized: (value) ->
         @setState lids_unauthorized: value

      respondToRefresh: ->
         if token = @getToken()
            SessionSource.fetchSession(token)
         else
            SessionSource.startRenewSession()

      validateAndRedirectUserForOrg: (user={}) ->
         oid = EverTrue.store.org?.get("id")
         old_user_id = @getState("previous_user_id")
         new_user = new User(user)

         if user.id != old_user_id
            console.log("Now logged in as #{user.name} (#{user.email})")

         if oid? and !new_user.isSuperUser() and !_.contains(_.pluck(new_user.getOrganizations(), "id"), oid)
            EverTrue.Navigator "/login/org", true

         @setState {previous_user_id: undefined}

      getFilteredRenewals: ->
         recent_renewals = _.cloneData(@getState("recent_renewals"))
         an_hour_ago = new Date().getTime() - 3600000
         _.filter(recent_renewals, (time) -> time > an_hour_ago)

      api:
         getSession: ->
            @getState("session")

         getUser: ->
            @getSession()?.user

         getToken: ->
            @getSession()?.token

         getUserOptions: ->
            @getState("user_options")

         getIsLidsUnauthorized: ->
            @getState("lids_unauthorized")
