import { skipTask } from "apps/tasks/utils/tasks-actions";
import { PropTypes } from "base/react-libs";
import { useEffect, useState } from "react";
import { trackJourneysAction } from "apps/journeys/utils/helpers/mixpanel";
import { Modal, ModalHeaderWithActions, ModalBody, ListboxSelect } from "@evertrue/et-components";
import FormField from "components/forms/form-field";
import JourneyContactCard from "apps/journeys/components/journey-contact-card";
import { createJourneyMove } from "apps/tasks/utils/journey-move-helper.js";

const reasonOptions = [
  { label: "Not appropriate for constituent", value: 1 },
  { label: "No phone number on file", value: 2 },
  { label: "No email on file", value: 3 },
  { label: "Can't find on LinkedIn", value: 4 },
  { label: "Donor prefers another contact method", value: 5 },
  { label: "Other", value: 6 },
];

const SkipTaskModal = ({
  isOpen,
  onClose,
  step,
  task,
  currentJourney = {},
  optionalStateManagementFunction = () => {},
}) => {
  const [reasonDetails, setReasonDetails] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);

  const [nextStep, setNextStep] = useState(null);

  useEffect(() => {
    // steps are sorted by step_number in the backend
    // so they are "in order" here
    const { steps } = currentJourney;
    const currentIndex = steps.findIndex((s) => s.id === step.id);
    setNextStep(steps[currentIndex + 1]);
  }, [currentJourney, step]);

  const nextStepRender = () => {
    if (!nextStep)
      return <div className="journey-modal--alert"> There is no next step in this Cadence, unable to skip </div>;
    else
      return (
        <div className="journey-modal--alert">
          <span className="bold-text">This constituent will be moved to:</span>
          <div>
            Step {nextStep.step_number} | Day {nextStep.day_number}
          </div>
          <div>{nextStep.description}</div>
        </div>
      );
  };

  const onSubmit = async () => {
    await skipTask(task, selectedOption.label, step);
    await createJourneyMove(
      task.contact,
      "SKIP_STEP",
      selectedOption,
      reasonDetails,
      currentJourney.id,
      task.id,
      step.id
    );
    optionalStateManagementFunction("removeTask", { task, step });
    trackJourneysAction("skip_cadence_step");
    onClose();
  };

  const handleListboxSelectChange = (val) => {
    if (val.value !== 6) {
      setReasonDetails("");
    }
    setSelectedOption(val);
  };

  return (
    <Modal isOpen={isOpen} closeModal={onClose}>
      <ModalHeaderWithActions
        title={"Skip Step"}
        closeModal={onClose}
        onSubmit={onSubmit}
        disableSubmit={
          !nextStep ||
          !selectedOption ||
          !Object.keys(selectedOption).length ||
          (selectedOption.value === 6 && !reasonDetails)
        }
      />
      <ModalBody scrollable={false}>
        <div>Are you sure you want to skip this step?</div>

        <JourneyContactCard contact={task.contact} avatarSize={60} />

        <div className="journey-modal--data-container">
          <FormField required={false} label="Current Cadence" inlineLabel={false} boldLabel={true}>
            {currentJourney.name}
          </FormField>

          <FormField required={false} label="Current Step" inlineLabel={false} boldLabel={true}>
            <div>
              Step {step.step_number} | Day {step.day_number}
            </div>
            <div>{step.title}</div>
          </FormField>
        </div>
        {nextStepRender()}
        <FormField required={false} label="Reason" inlineLabel={false}>
          <ListboxSelect
            emptyValue="Select Reason..."
            showLabelIfMultipleSelected={true}
            size="medium"
            options={reasonOptions}
            onChange={handleListboxSelectChange}
            value={selectedOption}
            label="Cadences"
          />
        </FormField>
        {selectedOption && selectedOption.value === 6 && (
          <FormField label="Details" inlineLabel={false}>
            <input type="text" value={reasonDetails} onChange={(e) => setReasonDetails(e.currentTarget.value)} />
          </FormField>
        )}
      </ModalBody>
    </Modal>
  );
};

SkipTaskModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  task: PropTypes.object,
  step: PropTypes.object,
  currentJourney: PropTypes.object,
  optionalStateManagementFunction: PropTypes.func,
};

export default SkipTaskModal;
