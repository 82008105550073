module.exports = do ->
   React = require("react")
   NotificationStore = require("apps/notifications/stores/notification-store")
   NotificationSource = require("apps/notifications/sources/notification-source")
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   ContactsFixedTable = createFactory(require("apps/contact/components/contacts/contact-fixed-table").default)
   ContactTableToolbar = createFactory(require("apps/contact/components/contacts/table/contact-table-toolbar").default)
   SelectedContactsStore = require("apps/contact/stores/selected-contacts-store")
   SelectedContactsString = require("components/formatting/selected-contacts-string")


   createComponent "NotificationDetailsController",
      propTypes:
         id: ReactLibs.PropTypes.number

      registerStores: ->
         @on NotificationStore, ->
            @getNotificationStoreData(@props.id)

         @on SelectedContactsStore, ->
            selected_contacts: SelectedContactsStore.getSelectedContacts()

      componentDidMount: ->
         NotificationSource.fetchContacts(@props.id)

      getNotificationStoreData: (id) ->
         contacts: NotificationStore.getContacts(id)
         activity: NotificationStore.getActivity(id)
         loading: NotificationStore.getLoadingContacts(id)
         notification: NotificationStore.get(id)

      UNSAFE_componentWillReceiveProps: (newProps) ->
         if newProps.id != @props.id
            NotificationSource.fetchContacts(newProps.id)
            @setState(@getNotificationStoreData(newProps.id))

      render: ->
         div className: "notification-details",
            div className: "notification-details--header",
               div className: "notification-details--label",
                  switch @state.notification?.activity_type
                     when "facebook" then "Recent Facebook Engagement"
                     when "linkedin" then "Recent LinkedIn Profile Updates"
                     else "Recent EverTrue Updates"

               SelectedContactsString
                  selected: @state.selected_contacts.length
                  results: @state.contacts?.total

            div className: "notification-details--toolbar",
               ContactTableToolbar contacts: @state.contacts

            div className: "notification-details--table",
               ContactsFixedTable
                  contacts: @state.contacts
                  loading: @state.loading
                  referrer: "search_update"
