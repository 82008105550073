_ = require("underscore").default
{createComponent} = require("@evertrue/et-flux")
React = require("react")
{div} = ReactLibs.DOMFactories
{object, func} = ReactLibs.PropTypes
moment = require("moment")
{createFactory} = require("base/new-utils")
GiftTransactionFacetDropdown = createFactory(require("apps/filters/gift-transaction-filter/gift-transaction-facet-dropdown").default)
CalendarDropdown = createFactory(require("components/controls/calendar-dropdown"))
RangeDropdown = require("components/controls/range-dropdown")
FiltersBlock = require("apps/filters/components/filters-block")

DATE_FORMAT = "YYYY-MM-DD"

ranges = [
  {
    "id": "now-1w",
    "format": [
      1,
      "week"
    ],
    "label": "in past week"
  },
  {
    "id": "now-1M",
    "format": [
      1,
      "month"
    ],
    "label": "in past month"
  },
  {
    "id": "now-3M",
    "format": [
      3,
      "month"
    ],
    "label": "in past 3 months"
  },
  {
    "id": "now-1y",
    "format": [
      1,
      "year"
    ],
    "label": "in past year"
  },
  {
    "id": "now-5y",
    "format": [
      5,
      "years"
    ],
    "label": "in past 5 years"
  },
]



sub_search_config = [{
   label: "Designation"
   key: "designations"
   render: ({value, onChange}) ->
      GiftTransactionFacetDropdown
         value: value
         property: "designations.untouched"
         placeholder: "Any Designation"
         onChange: onChange
},{
   label: "Designation Category"
   key: "designation_categories"
   render: ({value, onChange}) ->
      GiftTransactionFacetDropdown
         value: value
         property: "designation_categories.untouched"
         placeholder: "Any Designation Category"
         onChange: onChange

},{
   label: "Campaign Credit"
   key: "campaigns"
   render: ({value, onChange}) ->
      GiftTransactionFacetDropdown
         value: value
         property: "campaigns.untouched"
         placeholder: "Any Campaign Credit"
         onChange: onChange

},{
   label: "Gift Date"
   key: "gift_date"
   render: ({value, onChange}) ->
      {gte, lte} = value || {}
      dateRangeOptions = do ->
         currentDate = new Date()
         _.compact _.map ranges, (range) ->
            [number, string] = range.format || []

            id: range.id
            date_from: moment(currentDate).subtract(number, string).format(DATE_FORMAT)
            date_to: moment(currentDate).format(DATE_FORMAT)
            title: range.label

      rangeValue = _.find dateRangeOptions, (option) ->
         option.date_from == gte && option.date_to == lte || option.id == gte

      CalendarDropdown
         date_range: dateRangeOptions
         date_from: rangeValue?.date_from || gte
         date_to: rangeValue?.date_to || lte
         onChange: (date_from, date_to) ->
            rangeValue = _.find dateRangeOptions, (option) ->
               option.date_from == date_from && option.date_to == date_to

            if rangeValue
               onChange({gte: rangeValue.id})
            else
               onChange({gte: date_from, lte: date_to})
},{
   label: "Gift Amount"
   key: "amount"
   render: ({value, onChange}) ->
      {gte, lte} = value || {}
      RangeDropdown
         gte: gte
         lte: lte
         isCurrency: true
         onChange: ({gte, lte} = {}) ->
            onChange _.compactObject({gte, lte})
}]

module.exports =
   createComponent "GiftTransacationFiltersBlock",
      propTypes:
         input: object
         state: object
         onChange: func # args: (null, value) # filters config format

      handleChange: (key, value) ->
         current_value = @props.input?.value
         combined_value = _.extend({}, current_value, _.toObject(key, value))
         filtered_value = _.pick(combined_value, _.notEmpty)
         @props.onChange(null, filtered_value)

      renderFilters: ->
         {input, onChange} = @props

         _.map sub_search_config, ({key, label, render}) =>
            value = input?.value?[key]
            onChange = (val) => @handleChange(key, val)

            label: label
            component: render?({value, onChange}) || div null, "Component Contents"

      render: ->
         FiltersBlock
            filters: @renderFilters()
