import { Component, Fragment } from "react";
import { number, array, object, func } from "prop-types";
import _ from "underscore";
import { ModalConfirm, Radio } from "@evertrue/et-components";
import Api from "entities/helpers/api";
import moment from "moment";
import classNames from "classnames";
import EverTrue from "app";

class EmmaPushController extends Component {
  static displayName = "EmmaPushController";

  static propTypes = {
    list: object,
    listResultCount: number,
    accounts: array,
    groups: array,
    requestHide: func,
    onSuccess: func,
    onError: func,
  };

  static defaultProps = {
    list: {},
    accounts: [],
    groups: [],
    requestHide: _.noop,
    onSuccess: _.noop,
    onError: _.noop,
  };

  getLastPushedAccountId() {
    const latest_group = _.max(this.props.groups, "last_pushed") || {};
    const id = latest_group.account_id;
    const last_account = _.findWhere(this.props.accounts, { id }) || {};
    return last_account.invalidated_at ? undefined : id;
  }

  state = {
    selected_account_id: this.getLastPushedAccountId(),
    saving: false,
  };

  pushToEmma = ({ list_id, account_id }) => {
    this.setState({ saving: true });
    Api.EMMA.PUSH_LIST.post({
      params: { list_id, account_id },
    })
      .then(() => {
        const { last_pushed } = _.findWhere(this.props.groups, { account_id }) || {};
        const hours_since = last_pushed ? moment.duration(moment().diff(last_pushed)).asHours() : null;
        EverTrue.track.set("list_action", {
          type: "push_to_emma",
          count: this.props.listResultCount,
          hours_since_last_update: Math.round(hours_since),
        });

        this.setState({ saving: false });
        EverTrue.Alert.success("Please note, this process can take a few minutes. ", {
          header: "Sending List to Emma",
        });
        this.props.onSuccess();
        this.props.requestHide();
      })
      .catch(err => {
        this.setState({ saving: false });
        this.props.onError();
        throw err;
      });
  };

  render() {
    const { accounts, groups, list } = this.props;

    const { id: list_id, name: list_name } = list || {};
    const { selected_account_id } = this.state;

    return (
      <ModalConfirm
        style={{ minWidth: "348px" }}
        saveLabel={this.state.saving ? "Sending..." : "Send List to Emma"}
        disableSave={this.state.saving || !selected_account_id}
        header={"Send List to Emma"}
        onSubmit={() => {
          this.pushToEmma({ list_id, account_id: selected_account_id });
        }}
        onCancel={this.props.requestHide}
      >
        <div className="emma-push-controller--contents">
          <div className="emma-push-controller--description">
            Send email addresses from <strong>EverTrue to an Emma Group</strong>. If this list changes, you’ll need to
            re-send it to Emma.
          </div>
          <div className="emma-push-controller--group-name">
            <strong>Group Name: </strong>
            <span>{list_name}</span>
          </div>
          <div className="emma-push-controller--prompt">
            <strong>Select an Emma Account</strong>
          </div>

          {_.map(accounts, account => {
            const { name, id: account_id, remote_id, invalidated_at } = account;
            const { last_pushed } = _.findWhere(groups, { account_id }) || {};
            const m = moment(last_pushed || "-");
            const date = m.isValid() ? m.format("MM/DD/YY h:mma") : "Not sent";
            const checked = account_id === selected_account_id;

            return (
              <div
                key={account_id}
                className={classNames("emma-push-controller--account-option", { "is-disabled": invalidated_at })}
                onClick={() => {
                  if (!invalidated_at) {
                    this.setState({ selected_account_id: account_id });
                  }
                }}
              >
                <Radio
                  className="emma-push-controller--radio-button"
                  name={`radio--${account_id}`}
                  disabled={!!invalidated_at}
                  checked={checked}
                />
                <div className="emma-push-controller--account-description">
                  <div
                    className={classNames("emma-push-controller--account-name", { "is-disabled": invalidated_at })}
                    title={`Account ID: ${account_id}, Remote ID: ${remote_id}`}
                  >
                    {name}
                  </div>
                  <div>
                    {invalidated_at ? (
                      <span className="emma-push-controller--push-text is-error">
                        Account credentials are no longer valid, please update in data.evertrue.com
                      </span>
                    ) : (
                      <Fragment>
                        <span className="emma-push-controller--push-text">Last Sent On: </span>
                        <span className="emma-push-controller--push-date">{date}</span>
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </ModalConfirm>
    );
  }
}

export default EmmaPushController;
