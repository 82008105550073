import { useState, useEffect } from "react";
import { Icon, Button } from "@evertrue/et-components";
import useDrawer from "components/drawer/use-drawer";
import FeatureStore from "apps/layout/stores/feature-store";
import EmailerController from "components/emailer/emailer-controller";
import PropTypes from "prop-types";
import Drawer from "components/drawer/drawer";
import { useGlobalOutreachContext } from "components/emailer/email-modal-context";
import { trackOutreachAction } from "apps/outreach/utils/utils";

const EmailerDrawerButton = ({ emailAddress, contactId, activeModal, setActiveModal, contact }) => {
  const { openDrawer, closeDrawer, isOpen, toggleMinimize, isMinimized } = useDrawer();
  const [drawerTitle, setDrawerTitle] = useState("New Message");

  const { emailDrawerIsOpen, setEmailDrawerIsOpen } = useGlobalOutreachContext();

  // when the user exits out of modal, clear active modal state
  const closeAndClearDrawer = () => {
    if (setActiveModal && activeModal) {
      setActiveModal(null);
    }
    setDrawerTitle("New Message");
    closeDrawer();
  };

  /**
   * track the active modal when navigating from profile-header to prevent overlap
   * and close the drawer if the most recent active modal is not email
   */
  useEffect(() => {
    if (isOpen && setActiveModal) {
      setActiveModal("email");
    }
    if (activeModal && activeModal !== "email") {
      setDrawerTitle("New Message");
      closeDrawer();
    }
  }, [isOpen, activeModal, setActiveModal, closeDrawer]);

  useEffect(() => {
    if (emailDrawerIsOpen) {
      openDrawer();
      setEmailDrawerIsOpen(false);
    }
  }, [openDrawer, emailDrawerIsOpen, setEmailDrawerIsOpen]);

  return (
    FeatureStore.hasFeature("outreach_email") && (
      <>
        <Button
          type="secondary"
          onClick={() => {
            openDrawer();
            setActiveModal("email");
            trackOutreachAction("open_emailer");
          }}
          className={isOpen ? "round-button-focused" : "round-button"}
        >
          {isOpen ? (
            <Icon icon="email" style={{ color: "#A54AB5" }} />
          ) : (
            <Icon icon="email" style={{ color: "#007C9E" }} />
          )}
        </Button>
        <Drawer
          className="emailer-drawer"
          isOpen={isOpen}
          title={drawerTitle || "New Message"}
          toggleMinimize={toggleMinimize}
          isMinimized={isMinimized}
          close={closeAndClearDrawer}
        >
          <EmailerController
            emailAddress={emailAddress}
            handleClose={closeAndClearDrawer}
            setDrawerTitle={setDrawerTitle}
            contactId={contactId}
            contact={contact}
          />
        </Drawer>
      </>
    )
  );
};

EmailerDrawerButton.propTypes = {
  emailAddress: PropTypes.string,
  contactId: PropTypes.number,
  activeModal: PropTypes.string,
  setActiveModal: PropTypes.func,
  contact: PropTypes.object,
};

export default EmailerDrawerButton;
