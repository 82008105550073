/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react";
import ChatInputField from "./chat-input-field";
import ChatMessages from "./chat-messages";
import PropTypes from "prop-types";
import useChat from "../hooks/useChat";
import Loading from "components/elements/loading";
import { Icon } from "@evertrue/et-components";

const AiProfileChat = ({ contactId }) => {
  const [userInput, setUserInput] = useState("");
  const [userMessages, setUserMessages] = useState([]);
  const requestIndexRef = useRef(0);
  const currentRequestIndexRef = useRef(0);
  const { createChat, sendMessage, aiResponse, isStreaming, chat } = useChat(contactId, requestIndexRef.current);

  const { request_id: requestId } = chat;

  useEffect(() => {
    createChat();
  }, []);

  const handleInput = (input) => {
    setUserInput(input);
  };

  const handleSubmit = () => {
    const currentIndex = currentRequestIndexRef.current;
    requestIndexRef.current = currentIndex;
    sendMessage(userInput);
    setUserMessages((prevMessages) => [...prevMessages, { content: userInput, index: currentIndex }]);
    setUserInput("");
    currentRequestIndexRef.current++;
  };

  return (
    <>
      {Object.keys(chat).length > 0 ? (
        <div className="chat-bot">
          <ChatMessages userMessageRequests={userMessages} aiResponses={aiResponse} isStreaming={isStreaming} />
          <ChatInputField userInput={userInput} handleInput={handleInput} onSubmit={handleSubmit} placeholder="Type here..." />
          <div className="chat-bot-warning">
            <Icon icon="warning" style={{ fontSize: "11px" }} />
            <p>AI responses can be inaccurate or misleading</p>
          </div>
          <div className="request-id">
            {requestId && (
              <p>
                <small>{`Request ID: ${requestId}`}</small>
              </p>
            )}
          </div>
        </div>
      ) : (
        <div className="loader-div">
          <Loading spinner_size="small" position="center" />
        </div>
      )}
    </>
  );
};

AiProfileChat.propTypes = {
  contactId: PropTypes.number,
};

export default AiProfileChat;
