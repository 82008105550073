module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   EverTrue = require("app")
   React = require("react")
   ReactDOM = require("react-dom")
   PureRenderMixin = require("react-addons-pure-render-mixin")

   {createComponent} = require("@evertrue/et-flux")

   createComponent "ShowHideAnimate",
      mixins: [PureRenderMixin]
      propTypes:
         show: ReactLibs.PropTypes.bool
         hide: ReactLibs.PropTypes.bool
         el: ReactLibs.PropTypes.string
         speed: ReactLibs.PropTypes.number
         animateInClass: ReactLibs.PropTypes.string
         animateOutClass: ReactLibs.PropTypes.string
         animateOnMount: ReactLibs.PropTypes.bool
         className: ReactLibs.PropTypes.string

      getInitialState: ->
         {visible: false, animation: undefined}

      getDefaultProps: ->
         el: "div"
         speed: 400

      getVisibilty: (props) ->
         visible = props.show || props.hide is false

      getAnimationMode: (props) ->
         animate = if @getVisibilty(props) then "in" else "out"

      componentDidMount: ->
         @is_mounted = true

      componentWillUnmount:  ->
         @is_mounted = false

      UNSAFE_componentWillMount: ->
         animate = @getAnimationMode(@props)
         if !@props.animateOnMount && @props.animateInClass
            animate = undefined

         @setState
            visible: @getVisibilty(@props)
            animation: animate

      UNSAFE_componentWillReceiveProps: (newProps, newState) ->
         visible = @getVisibilty(newProps)

         if visible != @state.visible
            animate = @getAnimationMode(newProps)

            if visible
               @setState
                  animation: animate
                  visible: visible
            else
               @setState {animation: animate}
               _.delay =>
                  if @is_mounted
                     @setState {visible: visible}
               , @props.speed

      componentDidUpdate: (prevProps, prevState) ->
         if @state.animation == "in" && !@props.animateInClass
            $(ReactDOM.findDOMNode(@)).slideDown(@props.speed, "linear")

         if @state.animation == "out" && !@props.animateOutClass
            $(ReactDOM.findDOMNode(@)).slideUp(@props.speed, "linear")

      render: ->
         if @state.visible
            animateClass = switch @state.animation
               when "in" then @props.animateInClass
               when "out" then @props.animateOutClass

         classes = EverTrue.classSet @props.className, animateClass,
            "show-hide-animate": true
            "animated": !!(@props.animateInClass || @props.animateOutClass)

         seconds = @props.speed / 1000
         style = _.extend {}, @props.style,
            WebkitAnimationDuration: "#{seconds}s"
            MozAnimationDuration: "#{seconds}s"
            animationDuration: "#{seconds}s"

         if !@props.animateInClass && @props.animateOnMount
            style.display = "none"

         ReactLibs.DOMFactories[@props.el] {className: classes, style: style, onClick: @props.onClick},
            if !!@state.visible
               @props.children
            else null
