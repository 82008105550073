module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   React = require("react")
   moment = require("moment")
   require("inputmask/dist/inputmask/jquery.inputmask.js")
   require("inputmask/dist/inputmask/inputmask.date.extensions");
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   str = require("underscore.string")
   {input} = ReactLibs.DOMFactories


   createComponent "DateInput",
      propTypes:
         value: ReactLibs.PropTypes.any
         disabled: ReactLibs.PropTypes.bool
         placeholder: ReactLibs.PropTypes.string
         displayFormat: ReactLibs.PropTypes.string
         onLeave: ReactLibs.PropTypes.func
         onChange: ReactLibs.PropTypes.func

      getDefaultProps: ->
         displayFormat: "MM/DD/YYYY"
         placeholder: "__/__/____"

      getInitialState: ->
         value: moment(@props.value).format(@props.displayFormat)

      componentDidMount: ->
         $input = $(@refs.input)
         $input.inputmask(@props.displayFormat.toLowerCase(), {placeholder: @props.placeholder})

      componentDidUpdate: (prevProps) ->
         # Update the dates when the value has changed, but not while user is typing
         if prevProps.value != @props.value && @depracated_is_mounted
            if @state.value.indexOf("_") == -1
               @setState {value: moment(@props.value).format(@props.displayFormat)}

      handleLeave: (e) ->
         moment_date = moment(new Date(e.currentTarget.value))
         @props.onLeave?(moment_date.format("YYYY-MM-DD"))

      handleKeyUp: (e) ->
         if e.key == "Enter"
            @handleLeave(e)

         @setState {value: e.currentTarget.value}
         # InputMask makes the value of the input include the placeholder characters
         # This strips that and triggers change with a valid date
         clean_date = str.trim(e.currentTarget.value, "/_")
         if clean_date.length in [2, 5, 10]
            moment_date = moment(new Date(clean_date))
            if moment_date.isValid()
               # Dates should be passed around in valid ISO format
               @props.onChange?(moment_date.format("YYYY-MM-DD"))

      render: ->
         input
            ref: "input"
            value: @state.value
            className: classNames("date-input", @props.className)
            disabled: @props.disabled
            onKeyUp: @handleKeyUp
            onChange: _.noop # React warns without a value here, but inputmask overrides this :(
            onBlur: @handleLeave
