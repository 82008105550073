module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   React = require("react")
   {i, div} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   Icon = createFactory (require("components/elements/icon").default)
   Button = createFactory(require("@evertrue/et-components").Button)

   ToastAlert = ReactLibs.createClass
      propTypes:
         type: ReactLibs.PropTypes.string
         timeout: ReactLibs.PropTypes.number
         actionLabel: ReactLibs.PropTypes.string
         willBeRemoved: ReactLibs.PropTypes.bool
         icon: ReactLibs.PropTypes.string
         header: ReactLibs.PropTypes.string
         onAction: ReactLibs.PropTypes.func
         onClose: ReactLibs.PropTypes.func
         onUndo: ReactLibs.PropTypes.func

      getInitialState: ->
         {animation: "in"}

      componentDidMount: ->
         if @props.timeout
            _.delay((=> @handleClose()), @props.timeout)

      UNSAFE_componentWillReceiveProps: (newProps) ->
         if newProps.willBeRemoved
            @setState {animation: "out"}

      handleClose: ->
         if _.isFunction @props.onClose
            @props.onClose()

      handleUndo: ->
         if _.isFunction @props.onUndo
            @props.onUndo()
            @handleClose()

      handleAction: ->
         if _.isFunction @props.onAction
            @props.onAction()
            @handleClose()

      renderChildren: ->
         _.map _.makeArray(@props.children), (child, index) =>
            if child.type
               React.cloneElement child,
                  key: index
                  onToastClose: @handleClose
            else child

      render: ->
         toastClasses = EverTrue.classSet @props.className,
            "toast": true
            "toast--success": @props.type == "success"
            "toast--warning": @props.type == "warning"
            "toast--error": @props.type == "error"
            "animated": true
            "fadeInRight": @state.animation == "in"
            "fadeOutUp": @state.animation == "out"

         toastIcons = EverTrue.classSet @props.icon,
            "toast--icon": true
            "gt-icon": true
            "gt-icon-access-time": !@props.icon && @props.type == "info"
            "gt-icon-check-circle": !@props.icon && @props.type == "success"
            "gt-icon-cancel": !@props.icon && @props.type == "warning"
            "gt-icon-alert-circle": !@props.icon && @props.type == "error"


         div className: toastClasses, role: "alert",
            i className: toastIcons

            if @props.header
               div className: "toast--message",
                  div className: "toast--header", @props.header
                  div className: "toast--body", @renderChildren()
            else
               div className: "toast--message", @renderChildren()

            if @props.onAction || @props.onUndo
               div className: "toast--actions",
                  if @props.onAction
                     Button title: @props.actionLabel, onClick: @handleAction, @props.actionLabel

                  if @props.onUndo
                     Button type: "secondary", title: "Undo change", onClick: @handleUndo, "Undo"

            Icon className: "toast--close", icon: "close", onClick: @handleClose
