module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   EverTrue = require("app")
   AuthApi = require("entities/auth/auth-api")
   PaginatedCollectionMixin = require("mixins/pagination-mixin")
   SearchQueryCollectionMixin = require("mixins/search-query-mixin")
   Query = require("entities/search/query-parts/query")
   Parameter = require("entities/search/query-parts/parameter-query")
   Property = require("entities/search/query-parts/property-query")

   # Returns engagements that match a content_id
   # Example: All likes/comments/etc for a facebook post

   # note: 2017.08.25
   # this is a fork of social-engagement.coffee which is being converted to use
   # social documents from the contacts index.
   # this is currently only used by the postEngagersStore and should be able to be
   # completely removed if and when that is re-factored into modern flux
   #
   # DO NOT REQUIRE THIS INTO ANY NEW CODE

   class SodasSocialEngagement extends AuthApi.Collection
      @include [PaginatedCollectionMixin, SearchQueryCollectionMixin]
      urlRoot: "search/v2/social_engagement/search"

      config:
         post_engagers:
            query_fn: (value) ->
               Query [
                  Parameter "must", [
                     Property("type", value.types, {type: "contains"})
                     Property("content_id", value.post_id)
                     Property("contact_id", true, {type: "exists"})
                  ]
               ]

         post_engagers_with_contact:
            query_fn: (value) ->
               if value.contact_id?[0]
                  contact_property = Property("contact_id", value.contact_id, {type: "contains"})
               else
                  contact_property = Property("contact_id", true, {type: "exists"})
               Query [
                  Parameter "must", [
                     Property("type", value.types, {type: "contains"})
                     Property("content_id", value.post_id)
                     contact_property
                  ]
               ]

      parse: (data) ->
         @boundaries(data)
         data.items

      fetch: (options) ->
         opts = _.clone(options)
         opts.params = $.extend true, {}, @paginate(opts.params)
         opts = @queryOptions(opts)
         super opts
