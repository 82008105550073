/* eslint-disable no-restricted-globals */
import PropTypes from "prop-types";
import _ from "underscore";
import FormField from "components/forms/form-field";
import { AdvancedCombobox, ModalConfirm } from "@evertrue/et-components";
import { Formik, Field } from "formik";
import API from "entities/helpers/api";
import EverTrue from "app";
import UserStore from "apps/users/stores/user-store";
import { usePromiseResolver } from "@evertrue/promise-resolver";
import { useIdentity } from "base/identity-resolver";
import Decorator from "clientDecorator";

const EditUser = ({ user, onClose }) => {
  const { org } = useIdentity();

  const affiliation = Decorator.User.getAffiliationFor(user, org.id);

  const [profileData = {}] = usePromiseResolver(
    () => affiliation.id && API.AUTH.PROFILE.get({ urlArgs: { affiliation_id: affiliation.id } }),
    [affiliation]
  );

  const handleSave = (values) => {
    const { name, jobTitle, department = {}, classification = {} } = values;
    API.AUTH.PROFILE.put({
      urlArgs: { affiliation_id: affiliation.id },
      data: _.jsonStringify({
        name: name,
        title: jobTitle,
        school_division_department_id: department.value,
        persona: classification.value,
      }),
      success() {
        EverTrue.Alert.success(`${name} updated`);
        UserStore.getAuthUsers({});
      },
      error(xhr) {
        EverTrue.Alert.error(`Sorry, we encountered an error. ${xhr.responseJSON.message}`);
      },
    });
  };

  const departments = usePromiseResolver(() => API.AUTH.DEPARTMENTS.get({ urlArgs: { oid: org.id } }), [org.id])[0];
  const classifications = usePromiseResolver(() => API.AUTH.PERSONAS.get({ urlArgs: { oid: org.id } }), [])[0];

  const department_options =
    departments &&
    departments.map((dep) => {
      return { label: dep.value, value: dep.id };
    });

  const department_count = department_options ? department_options.length : 0;

  const department_label = (dept_id) => {
    const option = Array.isArray(department_options) ? department_options.find((dep) => dep.value === dept_id) : {};
    return option ? option.label : undefined;
  };

  const classification_options =
    classifications &&
    classifications.map((cl) => {
      return { label: cl.value, value: cl.value };
    });

  const InitialValues = {
    name: profileData.name || "",
    jobTitle: profileData.title || "",
    department:
      {
        label: department_label(profileData.school_division_department_id),
        value: profileData.school_division_department_id,
      } || {},
    classification:
      {
        label: profileData.persona,
        value: profileData.persona,
      } || {},
  };

  return (
    <div className="edit-user">
      <Formik enableReinitialize={true} initialValues={InitialValues}>
        {(props) => {
          const { dirty, values, handleChange } = props;

          return (
            <ModalConfirm
              header="Edit User"
              buttonLabel="Save Changes"
              closeModal={onClose}
              onCancel={onClose}
              onSubmit={() => handleSave(values)}
              disableSave={!dirty}
            >
              <form>
                <div className="edit-user--users">
                  <FormField label="Name" inlineLabel={false}>
                    <Field name="name" type="text" />
                  </FormField>
                  <FormField label="Email" inlineLabel={false}>
                    <Field name="email" type="text" placeholder={user != null ? user.email : undefined} />
                  </FormField>
                  <FormField label="Title" inlineLabel={false}>
                    <Field name="jobTitle" type="text" placeholder="Job Title" />
                  </FormField>
                  <FormField label="Classification" inlineLabel={false}>
                    <AdvancedCombobox
                      id="classification"
                      title="Classification"
                      tabIndex="0"
                      className="advanced-combobox-secondary"
                      options={classification_options}
                      label={values.classification.label}
                      value={values.classification}
                      onChange={handleChange("classification")}
                    />
                  </FormField>
                  <FormField label="Department" inlineLabel={false}>
                    <AdvancedCombobox
                      title="Department"
                      tabIndex="0"
                      className="advanced-combobox-secondary"
                      options={department_options}
                      label={values.department.label}
                      value={values.department}
                      onChange={handleChange("department")}
                      position={(department_count < 10) ? "up" : ""}
                    />
                  </FormField>
                </div>
              </form>
            </ModalConfirm>
          );
        }}
      </Formik>
    </div>
  );
};

EditUser.propTypes = {
  user: PropTypes.object,
  onClose: PropTypes.func,
  values: PropTypes.shape({
    classification: PropTypes.any,
    department: PropTypes.any,
  }),
  dirty: PropTypes.bool,
  handleChange: PropTypes.func,
};

export default EditUser;
