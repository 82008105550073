module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   {div, span} = ReactLibs.DOMFactories
   {createComponent} = require("@evertrue/et-flux")
   numeral = require("numeral")
   {createFactory} = require("base/new-utils")
   Module = createFactory require("components/module").default
   Nav = createFactory require("components/lists/nav")
   NavItem = createFactory require("components/lists/nav-item")
   ButtonSelect = require("components/controls/button-select")
   FacebookPostStats = createFactory require("apps/facebook/components/facebook-post-stats")
   AllPostEngagers = createFactory require("apps/facebook/components/all-post-engagers")
   FacebookReactions = require("apps/facebook/helpers/facebook-reactions-utils")
   PostStatStore = require("apps/facebook/stores/post-stat-store")
   ContactTableToolbar = createFactory(require("apps/contact/components/contacts/table/contact-table-toolbar").default)
   ContactFixedTable = createFactory(require("apps/contact/components/contacts/contact-fixed-table").default)
   FacebookPostDetailSource = require("apps/facebook/sources/facebook-post-detail-source")
   FacebookPostDetailStore = require("apps/facebook/stores/facebook-post-detail-store")
   FacebookSource = require("apps/facebook/sources/facebook-source")
   FacebookPagesStore = require("apps/facebook/stores/facebook-pages-store")
   SelectedContactsString = require("components/formatting/selected-contacts-string")
   ExportSearchController = createFactory require("apps/export/controllers/export-search-controller")
   ModalTrigger = createFactory require("components/modals/modal-trigger")
   Modal = createFactory require("components/modals/modal")


   createComponent "FacebookPostController",
      getInitialState: ->
         view: "matched"
         enrichmentLoading: true

      registerStores: ->
         @on FacebookPostDetailStore, ->
            post: FacebookPostDetailStore.getPost()
            contacts: FacebookPostDetailStore.getContacts()
            loading: FacebookPostDetailStore.getLoading()
            sub_view: FacebookPostDetailStore.getTypeFilter()

         @on FacebookPagesStore, ->
            page: FacebookPagesStore.getById(@state?.post?.page_remote_id)

      componentDidMount: ->
         FacebookPostDetailSource.fetchPost(@props.post_id)
         FacebookPostDetailSource.fetchPostContacts(@props.post_id)
         FacebookSource.fetchPages(include_warming: true )

      componentDidUpdate: (prevProps, prevState) ->
         if prevState.view != @state.view && @state.view == "matched"
            FacebookPostDetailSource.fetchPostContacts(@props.post_id)

      handleSubNavChange: (key) ->
         FacebookPostDetailSource.changeView(key, @state.view == "matched")

      handleSort: (sort_key, sort_reverse) ->
         FacebookPostDetailSource.sort(sort_key, sort_reverse)

      setEnrichmentLoading: (bool) -> 
        @setState({enrichmentLoading: bool})

      handlePage: (page) ->
         @setEnrichmentLoading(true)
         FacebookPostDetailSource.paginate(page)

      handleExport: (property_ids) ->
         FacebookPostDetailSource.export(property_ids)

      moduleHeaderNav: ->
         ButtonSelect
            className: "pull-right"
            buttons: [
               {value: "matched", label: "Matched Constituents", icon: "link"},
               {value: "unmatched", label: "Unmatched Engagers", icon: "unlink"}
            ]
            onSelect: (key) => @setState view: key
            selected: @state.view

      renderExportModal: ->
         Modal width: 600, keepOpen: true,
            ExportSearchController
               exportText: "You are about to export #{numeral(@state.contacts?.total).format("0,0")}
               records for constituents that have engaged with this Facebook post."
               onExport: @handleExport

      renderNavItems: ->
         nav_items = [
            NavItem key: "all", activeWhen: "all", label: "All Engagements"
         ,
            NavItem key: "comments", activeWhen: "comment", label: "Comments"
         ]

         reactions = PostStatStore.getReactionData(@props.post_id).items
         if reactions.length
            _.map reactions, (engagement_type) ->
               label = FacebookReactions.getLabelFor(engagement_type)
               nav_items.push NavItem key: engagement_type, activeWhen: engagement_type, label: label
         nav_items

      render: ->
         contacts = @state.contacts

         div className: "post-details page-container",
            Module className: "post-details--header", header: "Facebook Post Detail", icon: "facebook",
               FacebookPostStats
                  url: @state.post.public_url
                  post_id: @props.post_id

            Module
               className: "post-details--contacts"
               noMargin: true
               header: "Facebook Post Engagers"
               icon: "facebook"
               additional_header_content: @moduleHeaderNav

               if @state.view is "matched"
                  Nav className: "sub-nav", active: @state.sub_view, onSelect: @handleSubNavChange,
                     @renderNavItems()

               div className: "post-details--contacts-table",
                  if @state.view is "matched"
                     div null,
                        ContactTableToolbar
                           contacts: contacts
                           onPage: @handlePage

                           unless _.isEmpty(contacts.items)
                              span null,
                                 ModalTrigger modal: @renderExportModal(), title: "Export #{contacts.total} Contacts", "Export"

                                 SelectedContactsString
                                    className: "inline"
                                    results: contacts.total

                        div className: "fixed-page-wrapper",
                           ContactFixedTable
                              contacts: contacts
                              extraColumns: if @state.sub_view == "comment" then ["comment"]
                              loading: @state.loading
                              onPage: @handlePage
                              onSort: @handleSort
                              setEnrichmentLoading: @setEnrichmentLoading
                              enrichmentLoading: @state.enrichmentLoading
                  else
                     AllPostEngagers page: @state.page, post: @state.post, filter_type: @state.sub_view
