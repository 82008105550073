module.exports = do ->
   _ = require("underscore").default
   classNames = require("classnames")
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {div, span, table, tbody, tr, td, strong} = ReactLibs.DOMFactories
   FixedTable = require("components/tables/fixed-table")
   TableHeaderDropdown = require("components/tables/table-header-dropdown")

   CELL_WIDTH = 144

   createComponent "ReportTable",
      propTypes:
         data: ReactLibs.PropTypes.object
         cellFormat: ReactLibs.PropTypes.func

      getInitialState: ->
         config: {}
         sortProp: "Gift Officer"
         sortReverse: false

      componentDidMount: ->
         @updateTableConfig(@props.data?.columns)

      UNSAFE_componentWillReceiveProps: (newProps) ->
         if !_.isEqual(newProps.data?.columns, @props.data?.columns)
            @updateTableConfig(newProps.data?.columns)

      updateTableConfig: (columns) ->
         config = {}
         _.each columns, (column_obj, index) =>
            column = column_obj.label
            config[column] ?= _.compactObject
               label: column
               width: column_obj.width || CELL_WIDTH
               prop: column
               sticky: column_obj.sticky
               className: column_obj.className
               row: (row) =>
                  div className: classNames("text-right": _.isNumber(row[column])),
                     if row[column]
                        if @props.cellFormat
                           @props.cellFormat?(row[column], column)
                        else
                           span className: "ellipsis-overflow", row[column]
                     else span className: "text-label", "-"

         @setState {config: config}

      handleSort: (prop, reverse) ->
         @setState
            sortProp: prop
            sortReverse: reverse

      render: ->
         config = _.map @state.config, (conf, key) =>
            _.extend {}, conf,
               key: key
               label: TableHeaderDropdown
                  column: conf
                  sort: _.pick(@state, "sortProp", "sortReverse")
                  onSort: @handleSort

         sorted_data = _.coercedSortBy(@props.data.rows, @state.sortProp, @state.sortReverse)
         sorted_data = _.sortBy sorted_data, (val) ->
            if val.id == "total" then 1 else 0

         div
            className: classNames("report-table", @props.className)
            style:
               height: (_.size(sorted_data) * 38) + 53 # size table for number of rows plus heade

            # Table of Contacts
            if !_.isEmpty(@props.data)
               div className: "report-table--body has-header-dropdown",
                  FixedTable
                     data: sorted_data
                     config: config
