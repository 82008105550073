import { useState, useMemo } from "react";
import PropTypes from "prop-types";
import numeral from "numeral";
import EverTrue from "app";
import useResultCount from "apps/query-builder/hooks/use-result-count";
import useTotalCount from "apps/query-builder/hooks/use-total-count";
import QueryBuilder from "apps/query-builder/components/query-builder";
import QuickFilters from "apps/query-builder/components/quick-filters";
import PieChartGraph from "apps/query-builder/components/pie-chart-graph";
import { Button, Icon } from "@evertrue/et-components";
import colors from "base/colors";
import SelectedContactsSource from "apps/contact/sources/selected-contacts-source";

const QueryBuilderController = ({ onApply, closeModal, list_id, contactFilters, setIsAllSelectedCheckbox }) => {
  const dsl = useMemo(() => {
    if (list_id) return { ...contactFilters, scope: [{ list: { id: list_id } }] };
    return contactFilters;
  }, [list_id, contactFilters]);

  const [tempFilters, setTempFilters] = useState(JSON.parse(JSON.stringify(dsl)));
  const [totalCount] = useTotalCount();
  const [resultCount, isValid] = useResultCount(tempFilters);
  const { quick_filters } = tempFilters;

  return (
    <div className="filters-modal">
      <div className="filters-modal-header">
        <div className="filters-modal-count">
          <PieChartGraph
            className="filters-pie-chart"
            totalValue={totalCount}
            data={[{ title: "Filtered Amount", value: resultCount, color: colors.green200 }]}
            startAngle={-90}
          />
          {`${numeral(resultCount).format("0,0")} Constituents`}
        </div>

        <div>
          <Button type="simple" onClick={closeModal} className="filters-modal-cancel">
            Cancel
          </Button>
          <Button
            type="secondary"
            disabled={!isValid}
            onClick={() => {
              EverTrue.track.set("filter_v2_action", {
                type: "apply_filters",
                selected_filter: tempFilters,
              });
              onApply(tempFilters);
              SelectedContactsSource.clear();
              setIsAllSelectedCheckbox(false);
            }}
          >
            Apply
          </Button>
        </div>
      </div>
      <div className="filters-modal-quick">
        <QuickFilters
          appliedFilters={tempFilters}
          onChange={(change) => {
            const filtersCopy = { ...tempFilters };
            filtersCopy.quick_filters = change;
            setTempFilters(filtersCopy);
          }}
        />
        {quick_filters.length > 0 && (
          <Button
            type="simple"
            onClick={() => {
              const filtersCopy = { ...tempFilters };
              filtersCopy.quick_filters = [];
              setTempFilters(filtersCopy);
            }}
          >
            <Icon icon="clear" />
            Reset
          </Button>
        )}
      </div>

      <QueryBuilder
        appliedFilters={tempFilters}
        onChange={(change) => {
          setTempFilters({ ...change });
        }}
      />
    </div>
  );
};

QueryBuilderController.propTypes = {
  list_id: PropTypes.string,
  onApply: PropTypes.func,
  closeModal: PropTypes.func,
  contactFilters: PropTypes.object,
  setIsAllSelectedCheckbox: PropTypes.func,
};

export default QueryBuilderController;
