import { PropTypes } from "base/react-libs";
import { Icon, Button } from "@evertrue/et-components";
import TransitionWrapper from "./transition-wrapper";

const SideBar = ({ closeSideBar = () => {}, children, ...transitionProps }) => {
  return (
    <TransitionWrapper {...{ ...transitionProps, classNames: "side-bar", className: "side-bar" }}>
      <div style={{ padding: "8px 0px 0px 8px" }}>
        <Button onClick={() => closeSideBar()} type="simple">
          <Icon icon="close" />
        </Button>
      </div>
      {children}
    </TransitionWrapper>
  );
};

SideBar.propTypes = {
  closeSideBar: PropTypes.func,
  children: PropTypes.node,
};

export default SideBar;
