import _ from "underscore";
import { createStore } from "@evertrue/et-flux";
import OrgSource from "base/org/org-source";
import LayoutSource from "apps/layout/sources/layout-source";
import SocketSource from "apps/notifications/sources/socket-source";
// import SessionStore from "base/session/session-store";

export default createStore("OrgStore", {
  getInitialState() {
    return {
      current_org: undefined,
      organizations: [],
      loading: false,
    };
  },

  registerActions() {
    this.on(OrgSource.actions.set, this.respondToSet);
    this.on(OrgSource.actions.changed, this.respondToChange);
    this.on(OrgSource.actions.fetched, this.respondToFetched);
    this.on(OrgSource.actions.fetchedAll, this.respondToAll);
    this.on(OrgSource.actions.fetchedForUser, this.respondToAll);
    this.on(OrgSource.actions.loading, this.respondToLoading);
    this.on(OrgSource.actions.logout, this.respondToLogout);
  },

  respondToSet(org) {
    this.setState({ current_org: org });
    OrgSource.reset(org);
    SocketSource.subscribeOrg(org.id);
  },

  respondToChange(org) {
    const last_org = _.clone(this.getState("current_org"));
    this.setState({ current_org: org });

    if ((last_org != null ? last_org.id : undefined) !== org.id) {
      OrgSource.reset(org);
      SocketSource.subscribeOrg(org.id);
      _.defer(() => LayoutSource.remount());
    }
  },

  respondToFetched(org = {}) {
    const orgs = this.getState("organizations");
    const cached_org = _.findWhere(orgs, { id: org.id });
    if (cached_org) {
      _.extend(cached_org, org);
    } else {
      orgs.push(org);
    }

    let current_org = this.getState("current_org");
    if ((current_org != null ? current_org.id : undefined) === org.id) {
      current_org = org;
    }

    this.setState({
      organizations: orgs,
      current_org,
    });

    // Update legacy EverTrue.store.org with org data
    if (this.state.current_org && this.state.current_org.id) {
      OrgSource.saveToStore(org);
    }
  },

  respondToAll(orgs) {
    this.setState({ organizations: orgs });
  },

  respondToLoading(is_loading) {
    this.setState({ loading: is_loading });
  },

  respondToLogout() {
    this.setState({
      current_org: undefined,
      organizations: [],
      loading: false,
    });
  },

  api: {
    getCurrentOrg() {
      return this.getState("current_org");
    },

    getCurrentOid() {
      const current_org = this.getCurrentOrg();
      return current_org && current_org.id;
    },

    getOrgs() {
      return this.getState("organizations");
    },

    getOrgById(oid) {
      return _.findWhere(this.getState("organizations"), { id: oid });
    },

    getOrgByName(name) {
      return _.findWhere(this.getState("organizations"), { name });
    },

    getLoading() {
      return this.getState("loading");
    },
  },
});
