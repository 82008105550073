module.exports = do ->
   React = require("react")
   EverTrue = require("app")
   {createComponent} = require("@evertrue/et-flux")
   StatusStore = require("apps/layout/stores/status-store")
   {div, span} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   Link = createFactory(require("@evertrue/et-components").Link)

   createComponent "StatusIndidcator",
      propTypes:
         collapsed: ReactLibs.PropTypes.bool

      registerStores: ->
         @on StatusStore, ->
            status: StatusStore.getStatus()

      render: ->
         div className: EverTrue.classSet("status-indicator", @props.className, "is-collapsed": @props.collapsed),
            Link className: "status-indicator--link", title: @state.status?.description || "view evertrue status page", href: "https://evertrue.statuspage.io", target: "_BLANK",
               span className: EverTrue.classSet("status-indicator--dot", @state.status?.indicator)
               unless @props.collapsed
                  span className: "status-indicator--description", @state.status?.description
