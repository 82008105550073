import { Component } from "react";
import EverTrue from "app";
import PropTypes from "prop-types";
import { ModalConfirm, Radio } from "@evertrue/et-components";
import _ from "underscore";
import Api from "entities/helpers/api";
import BulkAddSource from "apps/layout/sources/bulk-add-source";
import { addSolicitorsToPool, addProspectsToPool } from "apps/volunteers/utils";
import ContactSource from "apps/contact/sources/contact-source";

function onAddSuccess(pool_id, count) {
  BulkAddSource.addToPool({ progress: 1, pool_id, count });
  ContactSource.loading(false);
}

class BulkAddToPoolModal extends Component {
  static propTypes = {
    filterParams: PropTypes.object,
    poolId: PropTypes.number,
    handleClose: PropTypes.func,
    totalResultsCount: PropTypes.number,
    contactIds: PropTypes.array,
    allSelected: PropTypes.bool.isRequired,
    closePopover: PropTypes.func.isRequired,
    isFiltersV2: PropTypes.bool,
  };

  state = {
    type: undefined,
  };

  handleSubmit = () => {
    this.props.closePopover();
    const { type } = this.state;
    const { allSelected, poolId: pool_id, totalResultsCount, contactIds } = this.props;

    if (allSelected) {
      if (totalResultsCount > 500) {
        EverTrue.Alert.confirm(
          {
            content: `You are about to add ${totalResultsCount.toLocaleString()} constituents to a pool. This process cannot be undone.`,
            headline: <strong>Add {totalResultsCount.toLocaleString()} to Pool?</strong>,
            buttonLabel: "Add",
          },
          (didConfirm) => {
            if (didConfirm) this.bulkAdd();
            else this.props.handleClose();
          }
        );
      } else {
        this.bulkAdd();
        return;
      }
      return;
    }
    if (type === "solicitors") {
      ContactSource.loading(true);
      addSolicitorsToPool(pool_id, contactIds).then(() => {
        onAddSuccess(pool_id, contactIds.length);
      });
    } else {
      ContactSource.loading(true);
      addProspectsToPool(pool_id, contactIds).then(() => {
        onAddSuccess(pool_id, contactIds.length);
      });
    }
  };

  bulkAdd() {
    const { filterParams, poolId: pool_id, totalResultsCount, isFiltersV2 } = this.props;
    const { type } = this.state;
    const path = isFiltersV2 ? Api.ASSIGNMENTS.BULK_ADD_TO_POOL : Api.VOLUNTEER.BULK_ADD;
    const params = isFiltersV2
      ? { pool_id }
      : { pool_id, ...(type === "solicitors" && { solicitor_role: "Volunteer" }) };

    BulkAddSource.addToPool({ count: totalResultsCount, pool_id, type });
    path.post({
      params,
      urlArgs: { type },
      data: _.jsonStringify(filterParams),
      success() {
        if (!isFiltersV2) BulkAddSource.addToPool({ count: totalResultsCount, pool_id });
      },
    });
  }

  render() {
    const { handleClose } = this.props;
    const { type } = this.state;
    return (
      <ModalConfirm
        header="Are these Constituents Prospects or Volunteers?"
        closeModal={handleClose}
        disableSave={!type}
        onSubmit={this.handleSubmit}
      >
        <div className="bulk-add-to-pool-modal">
          <div className="bulk-add-to-pool-modal--heading">Add as Prospects or Volunteers?</div>
          <div className="bulk-add-to-pool-modal--radios">
            <Radio
              checked={type === "prospects"}
              onChange={() => this.setState({ type: "prospects" })}
              label={"Prospects"}
            >
              Prospects
            </Radio>
            <Radio
              checked={type === "solicitors"}
              onChange={() => this.setState({ type: "solicitors" })}
              className="bulk-add-to-pool-modal--second-radio"
              label={"Volunteers"}
            >
              Volunteers
            </Radio>
          </div>
        </div>
      </ModalConfirm>
    );
  }
}

export default BulkAddToPoolModal;
