import { useState, useEffect } from "react";
import _ from "underscore";
import EverTrue from "app";
import PropTypes from "prop-types";
import Api from "entities/helpers/api";
import FormField from "components/forms/form-field";
import { ModalHeaderWithActions, ModalBody, Switch, NumericInput } from "@evertrue/et-components";
import { useGate } from "components/is-gated";

const TeamSettingsModal = ({ team = {}, close = () => {}, refreshData = () => {} }) => {
  const [hasDXO] = useGate("dxo");
  const [team_settings, setTeamSettings] = useState(team);
  const [team_goals, setTeamGoals] = useState({
    interactions: 0,
    proposals_total: 0,
    proposal_funded_amount: 0,
    proposal_ask_amount: 0,
    dxo_asks: 0,
    dxo_unique_asks: 0,
    dxo_substantive_asks: 0,
    dxo_substantive_unique_asks: 0,
  });

  // Load team hub goals
  useEffect(() => {
    Api.GOALS.TEAM.get({
      urlExtend: `/${team.id}`,
      disableAlerts: true,
    }).then((resp = {}) => {
      setTeamGoals(resp);
    });
  }, [team.id]);

  const saveGoals = () => {
    if (!_.isEmpty(team_goals)) {
      const data = {
        pool_id: team.id,
        interactions: team_goals.interactions,
        proposals_total: team_goals.proposals_total,
        proposal_funded_amount: team_goals.proposal_funded_amount,
        proposal_ask_amount: team_goals.proposal_ask_amount,
        dxo_asks: team_goals.dxo_asks,
        dxo_unique_asks: team_goals.dxo_unique_asks,
        dxo_substantive_asks: team_goals.dxo_substantive_asks,
        dxo_substantive_unique_asks: team_goals.dxo_substantive_unique_asks,
      };
      Api.GOALS.TEAM.post({
        data: _.jsonStringify(data),
      })
        .then(() => {
          EverTrue.Alert.success("Pool updates saved.");
          close();
          refreshData();
        })
        .catch(() => {
          EverTrue.Alert.error("There was a problem saving your settings. Please try again later");
          close();
          refreshData();
        });
    } else {
      EverTrue.Alert.success("Pool updates saved.");
      close();
      refreshData();
    }
  };

  const handleSubmit = () => {
    // Save Team Settings
    if (team_settings.id) {
      if (!_.isEqual(team_settings, team)) {
        Api.VOLUNTEER.POOLS.put({
          urlExtend: `/${team.id}`,
          data: _.jsonStringify(team_settings),
        })
          .then(() => {
            saveGoals();
          })
          .catch(() => {
            EverTrue.Alert.error("There was a problem saving your settings. Please try again later");
            close();
            refreshData();
          });
      } else {
        saveGoals();
      }
    } else {
      EverTrue.Alert.error("There was a problem saving your settings. Please try again later");
      close();
      refreshData();
    }
  };

  const handleTeamSettingsChange = (key, value) => {
    const new_data = {};
    new_data[key] = value;
    setTeamSettings((prev) => {
      return { ...prev, ...new_data };
    });
  };

  const handleTeamGoalsChange = (key, value) => {
    const new_data = {};
    new_data[key] = value;
    setTeamGoals((prev) => {
      return { ...prev, ...new_data };
    });
  };

  return (
    <>
      <ModalHeaderWithActions title="Edit Settings" closeModal={close} onSubmit={handleSubmit} />
      <ModalBody scrollable={false}>
        <FormField label="Team Name" inlineLabel={false}>
          <input
            type="text"
            placeholder="Name your team"
            value={team_settings.name}
            onChange={(e) => handleTeamSettingsChange("name", e.currentTarget.value)}
          />
        </FormField>

        <div className="pool-form-modal--row">
          <FormField label="Total Interactions">
            <NumericInput
              value={team_goals.interactions}
              placeholder={0}
              maskType="number"
              onChange={(value) => {
                handleTeamGoalsChange("interactions", value);
              }}
            />
          </FormField>

          <FormField label="Total Proposals">
            <NumericInput
              value={team_goals.proposals_total}
              placeholder={0}
              maskType="number"
              onChange={(value) => {
                handleTeamGoalsChange("proposals_total", value);
              }}
            />
          </FormField>
        </div>

        <div className="pool-form-modal--row">
          <FormField label="Proposal Funded Amounts">
            <NumericInput
              value={team_goals.proposal_funded_amount}
              placeholder={0}
              maskType="currency"
              onChange={(value) => {
                handleTeamGoalsChange("proposal_funded_amount", value);
              }}
              showIcon={true}
            />
          </FormField>

          <FormField label="Proposal Ask Amounts">
            <NumericInput
              value={team_goals.proposal_ask_amount}
              placeholder={0}
              maskType="currency"
              onChange={(value) => {
                handleTeamGoalsChange("proposal_ask_amount", value);
              }}
              showIcon={true}
            />
          </FormField>
        </div>

        <div className="pool-form-modal--row">
          <FormField label="Visit Threshold">
            <NumericInput
              integerLimit={3}
              placeholder="Maximum number of days"
              value={team_settings.visit_threshold}
              onChange={(value) => {
                handleTeamSettingsChange("visit_threshold", value);
              }}
            />
          </FormField>

          <FormField label="Contact Threshold">
            <NumericInput
              integerLimit={3}
              placeholder="Maximum number of days"
              value={team_settings.contact_threshold}
              onChange={(value) => {
                handleTeamSettingsChange("contact_threshold", value);
              }}
            />
          </FormField>
        </div>

        {hasDXO && (
          <>
            <div className="pool-form-modal--row">
              <FormField label="DXO Asks">
                <NumericInput
                  integerLimit={3}
                  placeholder={0}
                  value={team_goals.dxo_asks}
                  onChange={(value) => {
                    handleTeamGoalsChange("dxo_asks", value);
                  }}
                />
              </FormField>

              <FormField label="DXO Unique Asks">
                <NumericInput
                  integerLimit={3}
                  placeholder={0}
                  value={team_goals.dxo_unique_asks}
                  onChange={(value) => {
                    handleTeamGoalsChange("dxo_unique_asks", value);
                  }}
                />
              </FormField>
            </div>

            <div className="pool-form-modal--row">
              <FormField label="DXO Substantive Asks">
                <NumericInput
                  integerLimit={3}
                  placeholder={0}
                  value={team_goals.dxo_substantive_asks}
                  onChange={(value) => {
                    handleTeamGoalsChange("dxo_substantive_asks", value);
                  }}
                />
              </FormField>

              <FormField label="DXO Substantive Unique Asks">
                <NumericInput
                  integerLimit={3}
                  placeholder={0}
                  value={team_goals.dxo_substantive_unique_asks}
                  onChange={(value) => {
                    handleTeamGoalsChange("dxo_substantive_unique_asks", value);
                  }}
                />
              </FormField>
            </div>
            <div className="pool-form-modal--row">
              <FormField label="DXO Ask Threshold">
                <NumericInput
                  integerLimit={7}
                  maskType="currency"
                  showIcon={true}
                  placeholder="Dollar threshold"
                  value={team_settings.dxo_ask_threshold}
                  onChange={(value) => {
                    handleTeamSettingsChange("dxo_ask_threshold", value);
                  }}
                />
              </FormField>
            </div>
          </>
        )}

        <div className="pool-form-modal--assign-section">
          <strong>Team Assignment</strong>
          <div className="pool-form-modal--switch-section">
            <div>Allow solicitors to choose assignments</div>
            <Switch
              name="selection_mode"
              checked={team_settings.selection_mode === "SOLICITOR_SELECTION"}
              value={team_settings.selection_mode}
              onChange={(value) => {
                const selectionMode = value ? "SOLICITOR_SELECTION" : "disabled";
                handleTeamSettingsChange("selection_mode", selectionMode);
              }}
            />
          </div>
        </div>
      </ModalBody>
    </>
  );
};

TeamSettingsModal.propTypes = {
  team: PropTypes.object,
  refreshData: PropTypes.func,
  close: PropTypes.func,
};

export default TeamSettingsModal;
