module.exports = do ->
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {div, strong, span} = ReactLibs.DOMFactories
   Decorator = require("clientDecorator")
   FacebookPagesStore = require("apps/facebook/stores/facebook-pages-store")
   ProfileFacebookStore = require("apps/profile/stores/profile-facebook-store")
   {createFactory} = require("base/new-utils")
   FacebookEmbeddedPost = createFactory require("apps/facebook/components/facebook-embedded-post")
   Popover = require("components/overlays/popover")
   EngagementCard = require("apps/profile/components/engagement-card")
   EngagementIcon = require("apps/profile/components/engagement-icon")
   {getVerbByEngagementType} = require("apps/events/utils/engagement-config")
   Link = createFactory(require("@evertrue/et-components").Link)

   createComponent "EngagementCardFacebook",
      propTypes:
         contact: ReactLibs.PropTypes.object
         social: ReactLibs.PropTypes.object

      registerStores: ->
         @on FacebookPagesStore, ->
            page: FacebookPagesStore.getById(@props.social?.content?.page_remote_id)
            oauth: FacebookPagesStore.getOauth()

         @on ProfileFacebookStore, ->
            post: ProfileFacebookStore.getPost(@props.social?.content?.remote_id)

      renderTitle: ->
         [page_id, post_id] = @props.social?.content?.remote_id?.split("_")
         content = @props.social?.content || {}
         engagement = @props.social?.engagement || {}
         picture = @state.post?.picture || @state.page?.picture_url
         name = @props.social?.profile?.name

         # Facebook returns a file called save_image.php instead of throwing an error
         # which renders as blank, this should default it to the page icon.
         if picture?.match("safe_image.php")
            picture = @state.page?.picture_url

         div null,
            strong className: "fs-exclude-data", name

            getVerbByEngagementType(engagement.type)

            if @state.page?.page_name
               span null,
                  Link title: "Facebook page: #{@state.page?.page_name}", href: "https://www.facebook.com/#{@state.page?.remote_id}", target: "_BLANK",
                     span className: "fs-exclude-data", @state.page?.page_name
                  "'s "
            else "your "

            Popover
               className: "inline"
               trigger: Link(title: "view post on Facebook", href: "/facebook/#{page_id}/posts/#{post_id}", content.type)
               position: "left"
               event: "hover"

               div className: "facebook-engagement-card--post",
                  FacebookEmbeddedPost
                     url: content?.public_url
                     post_id: content?.remote_id
                     width: 350

            "."

      render: ->
         engagement = @props.social?.engagement || {}
         content = @props.social?.content || {}
         engaged_at = engagement.created_at

         icon = EngagementIcon icon: engagement.type, size: "40px", network: "facebook"

         EngagementCard
            title: @renderTitle()
            contents: span className: "fs-exclude-data", content.contents
            comment: if engagement.contents then span className: "fs-exclude-data", engagement.contents
            date: engaged_at
            icon: icon
            network: "facebook"
