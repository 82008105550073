module.exports = do ->
   _ = require("underscore").default
   {createStore} = require("@evertrue/et-flux")
   UrlSource = require("apps/layout/sources/url-source")
   AllEngagementsSource = require("apps/profile/sources/all-engagements-source")
   moment = require("moment")
   PropertySource = require("apps/contact/sources/property-source")
   FeatureStore = require("apps/layout/stores/feature-store").default
   GateSource = require("apps/layout/sources/gate-source")
   FB_URL_KEY = "fbfilters"
   ET_URL_KEY = "etfilters"

   LIMIT_INCREMENT = 15
   LIMIT = 500

   filterByDate = (engagements, date) ->
      {date_from, date_to} = date
      return _.filter engagements,  (engagement) ->
         moment(engagement.engagement_date).isBetween(date_from, date_to)

   filterByKeyword = (engagements, keyword) ->
      keyword = keyword.toLowerCase()
      _.filter engagements, (engagement) ->
         switch engagement.type
            when "facebook"
               content = engagement?.data?.content?.contents
               content = content?.toLowerCase()
               content?.indexOf(keyword) > -1
            when "event"
               description = (engagement?.data?.event?.description).toLowerCase()
               name = (engagement.data.event?.name).toLowerCase()
               description.indexOf(keyword) > -1 || name.indexOf(keyword) > -1


   filterByPages = (engagements, pages) ->
      pages = _.pluck pages, "value"
      _.filter engagements, (engagement) ->
         if engagement.type is "facebook"
            engagement.data.content.page_remote_id in pages
         else if engagement.type is "event"
            engagement.data.event.page_remote_id in pages
         else
            return false

   filterByReactions = (engagements, reactions) ->
      reactions = _.pluck reactions, "value"
      _.filter engagements, (engagement) ->
         if engagement.type is "facebook"
            engagement.data.engagement.type in reactions
         else if engagement.type is "event"
            engagement.data.engagement_action in reactions
         else
            return false

   sortByEngagementDate = (engagements, order) ->
      sorted = _.sortBy engagements, "engagement_date"
      if order is "desc" then sorted.reverse() else sorted

   createStore "AllEngagementsStore",
      getInitialState: ->
         facebook_engagements: {}
         event_engagements: {}
         thankview_engagements: []
         pending_post_ids: []
         posts: {}
         filters: {}
         facebook_filters: {}
         event_filters: {}
         limit: LIMIT_INCREMENT
         sort_order: "desc"
         events_loading: false
         engagements_loading: false
         loading_more: false
         schema: {}

      registerActions: ->
         @on AllEngagementsSource.actions.fetchedFBEngagements, @respondToFetchedFBEngagements
         @on AllEngagementsSource.actions.fetchedEvents, @respondToFetchedEvents
         @on AllEngagementsSource.actions.fetchedTVEngagements, @respondToFetchedTVEngagements
         @on AllEngagementsSource.actions.loading, @respondToLoading
         @on AllEngagementsSource.actions.fetchingEventsError, @respondToFetchingEventsError
         @on AllEngagementsSource.actions.fetchingFBEngagementsError, @respondToFetchingFBEngagementsError
         @on AllEngagementsSource.actions.applyFilters, @respondToFilter
         @on AllEngagementsSource.actions.loadMore, @respondToLoadMore
         @on AllEngagementsSource.actions.changeSort, @respondToSort
         @on UrlSource.actions.initializedUrl, @respondToUrl
         @on UrlSource.actions.fetched, @respondToUrl
         @on UrlSource.actions.changed, @respondToUrl
         @on GateSource.actions.fetchedGates, @trigger
         @on PropertySource.actions.fetchedSchema,(schema) ->
            @setState {schema: schema}


      getAllEngagements: (contact_id) ->
         facebook_engagements = @getState("facebook_engagements")[contact_id] || []
         event_engagements = @getState("event_engagements")[contact_id] || []

         if FeatureStore.hasFeature("events")
            (facebook_engagements).concat(event_engagements)
         else
            facebook_engagements

      getFbEngagements: (contact_id) ->
         facebook_engagements = @getState("facebook_engagements")[contact_id] || []
         facebook_engagements

      getEtEngagements: (contact_id) ->
         event_engagements = @getState("event_engagements")[contact_id] || []
         event_engagements
   
      respondToUrl: (url) ->
         if url[FB_URL_KEY] then @setState {facebook_filters: url[FB_URL_KEY]}
         if url[ET_URL_KEY] then @setState {event_filters: url[ET_URL_KEY]}

      respondToFetchedFBEngagements: (contact_id, engagements, stats) ->
         { offset, total } = stats
         if total >= offset
            if offset == 0
               @mergeState "facebook_engagements", _.toObject(contact_id, engagements)
            else
               current_fb_engagements = @getState("facebook_engagements")
               new_engagements = current_fb_engagements?[contact_id]?.concat(engagements)
               current_fb_engagements[contact_id] = new_engagements
               @setState facebook_engagements: current_fb_engagements

            AllEngagementsSource.fetchFacebookEngagements(contact_id, offset + LIMIT)
         else
            @setState {engagements_loading: false}

      respondToFetchedEvents: (contact_id, engagements, stats) ->
         { offset, total } = stats
         if total >= offset
            if offset == 0
               @mergeState "event_engagements", _.toObject(contact_id, engagements)
            else
               current_event_engagements = @getState("event_engagements")
               new_engagements = current_event_engagements?[contact_id]?.concat(engagements)
               current_event_engagements[contact_id] = new_engagements
               @setState facebook_engagements: current_event_engagements

            AllEngagementsSource.fetchEventEngagements(contact_id, offset + LIMIT)
         else
            @setState {events_loading: false}

      respondToFetchedTVEngagements: (contact_id, engagements) ->
         @setState thankview_engagements: engagements
         
      respondToLoading:  ->
         @setState {events_loading: true, engagements_loading: true}

      respondToFilter: (contact_id, filter_object) ->
         @setState {filters: filter_object, facebook_filters:filter_object.facebook, event_filters:filter_object.event, limit: LIMIT_INCREMENT}
         UrlSource.change(_.toObject(FB_URL_KEY, filter_object.facebook))
         UrlSource.change(_.toObject(ET_URL_KEY, filter_object.event))

      respondToLoadMore: (contact_id) ->
         @setState {loading_more: true, limit: @getState("limit") + LIMIT_INCREMENT}

      respondToSort: (contact_id, sort_order) ->
         @setState {sort_order: sort_order, limit: LIMIT_INCREMENT}

      respondToFetchingEventsError: ->
         @setState {events_loading: false}

      respondToFetchingFBEngagementsError: ->
         @setState {engagements_loading: false}

      getFilteredEngagements: (contact_id, engagement_type) ->
         if engagement_type == "Facebook"
            engagements = @getFbEngagements(contact_id)
            fb_filters = @getState("facebook_filters")
            fb_filters && { date, keyword, pages, reactions } = fb_filters
         else if engagement_type == "Event"
            engagements = @getEtEngagements(contact_id)
            et_filters = @getState("event_filters")
            et_filters && { date, keyword, pages, reactions } = et_filters
 
         sort_order = @getState("sort_order")
         if date then engagements = filterByDate(engagements, date)
         if keyword then engagements = filterByKeyword(engagements, keyword)
         if pages then engagements = filterByPages(engagements, pages)
         if reactions then engagements = filterByReactions(engagements, reactions)
         sortByEngagementDate(engagements, sort_order)

      api:
         getEngagements: (contact_id) ->
            engagements = @getFilteredEngagements(contact_id)
            _.first engagements, @getState("limit")

         getFacebookEngagements: (contact_id) ->
            engagements = @getFilteredEngagements(contact_id, "Facebook")
            _.first engagements, @getState("limit")

         getEventEngagements: (contact_id) ->
            engagements = @getFilteredEngagements(contact_id, "Event")
            _.first engagements, @getState("limit")
         
         getThankviewEngagements: (contact_id) ->
            engagements = @getState("thankview_engagements")

         getEngagementTotal: (contact_id) ->
            @getFilteredEngagements(contact_id).length

         getFacebookEngagementTotal: (contact_id) ->
            @getFilteredEngagements(contact_id, "Facebook").length

         getEventEngagementTotal: (contact_id) ->
            @getFilteredEngagements(contact_id, "Event").length
         

         getSchema: (contact_id) ->
            schema = @getState("schema")

         getLoading: ->
            @getState("engagements_loading") || @getState("events_loading") || @getState("thankview_engagements_loading")

         getPost: (post_id) ->
            @getState("posts")?[post_id]

         getAppliedFilters: ->
            $.extend true, {}, facebook: @getState("facebook_filters"), event: @getState("event_filters")

         getSortOrder: ->
            @getState("sort_order")

         getUrlFor: (key, value) ->
            object = _.toObject(FB_URL_KEY, _.toObject(key, value))
            "/posts?#{$.param(object)}"

         hasMoreToLoad: (contact_id) ->
            total_engagements = @getFilteredEngagements(contact_id)
            if !total_engagements then false
            else total_engagements.length > @getState("limit")
