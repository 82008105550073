module.exports = do ->
   _ = require("underscore").default
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   {tr, td} = ReactLibs.DOMFactories

   createComponent "PagedColumnTableRow",
      propTypes:
         data: ReactLibs.PropTypes.any
         columns: ReactLibs.PropTypes.array
         cells: ReactLibs.PropTypes.func
         sortProp: ReactLibs.PropTypes.string
         columnWidth: ReactLibs.PropTypes.number
         rowClass: ReactLibs.PropTypes.func
         onRowClick: ReactLibs.PropTypes.func

      handleRowClick: (evnt) ->
         if _.isFunction @props.onRowClick
            @props.onRowClick(@props.data.id, evnt)

      cells: ->
         headers = @props.columns
         _.map headers, (config) =>
            if _.isFunction @props.cells()[config.row]
               td
                  key: "row-" + config.row + (config.key || "")
                  className: classNames config.className,
                     "table-col-sorted": @props.sortProp && @props.sortProp == config.prop
                     "is-sticky": config.sticky
                  style:
                     width: config.width
                     maxWidth: (@props.columnWidth || 175) unless config.width
               , @props.cells()[config.row](@props.data, config)

      render: ->
         tr
            key: @props.data.id
            onClick: @handleRowClick
            className: @props.rowClass?(@props.data) || "",
               @cells()
