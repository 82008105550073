module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   EverTrue = require("app")
   numeral = require("numeral")
   {createComponent} = require("@evertrue/et-flux")
   {div, button, span} = ReactLibs.DOMFactories
   ProfileInteractionsStore = require("apps/profile/stores/profile-interactions-store").default
   MyTeamStore = require("apps/my-team/stores/my-team-store")
   MyTeamSource = require("apps/my-team/sources/my-team-source")
   DNAStore = require("base/dna/dna-store").default
   ReportPage = require("apps/my-team/components/report-page")
   VisitSelectorModal = require("apps/my-team/components/visit-selector-modal")
   {createFactory} = require("base/new-utils")
   Icon = createFactory (require("components/elements/icon").default)
   EmptyStateMessage = createFactory(require("components/lists/empty-state-message"))
   ModalTrigger = createFactory require("components/modals/modal-trigger")
   Modal = createFactory require("components/modals/modal")

   createComponent "ReportPageController",
      propTypes:
         report: ReactLibs.PropTypes.string.isRequired

      registerStores: ->
         @on MyTeamStore, "report", (props) ->
            loading: MyTeamStore.getLoading()
            legend: MyTeamStore.getReportLegend(props.report)
            chart_data: MyTeamStore.getReportData(props.report)
            table_data: MyTeamStore.getTableData(props.report)
            visit_types: MyTeamStore.getVisitTypes()
            title: MyTeamStore.getTitle(props.report)

         @on ProfileInteractionsStore, ->
            interaction_types: _.compact _.map ProfileInteractionsStore.getTypes(), (item) ->
               unless item.category == "EverTrue Comment"
                  _.extend {}, item, {value: item.category}

         @on DNAStore, ->
            prospect_stages: _.pluck(DNAStore.getByKey("GT_applied_prospect_columns"), "key")

      handleExport: ->
         MyTeamSource.startExport(@props.report)

      renderInteractionTypePicker: (types) ->
         Modal width: 400,
            VisitSelectorModal
               selected: types
               options: @state.interaction_types
               onSubmit: (val) ->
                  MyTeamSource.updateVisitTypes(_.pluck(val, "value"))

      renderInteractionTypesControl: (types) ->
         types = _.compact _.map @state.visit_types, (type) =>
            _.findWhere(@state.interaction_types, {value: type})

         div className: "report-page--visit-control",
            ModalTrigger modal: @renderInteractionTypePicker(types),
               _.pluck(types, "label").join(", ")
               Icon icon: "settings", after: true

      renderVisitsEmptyState: ->
         can_configure = EverTrue.store.user.isOwnerOrSuperUser()
         div className: "report-page--visit-empty",
            EmptyStateMessage
               icon: "other-interaction"
               text: "Report not configured"
               if can_configure
                  div null,
                     "To show this report, select which interactions are defined as \"visits\"."
                     ModalTrigger buttonType: "default", modal: @renderInteractionTypePicker(), title: "Configure Interaction Types",
                        "Configure Interaction Types"
               else
                  "An account owner must configure \"visit type\" before this report can be seen."

      render: ->
         div className: "report-page-controller",
            switch @props.report
               when "visits"
                  div className: "report-page--visits",
                     if !@state.visit_types
                        @renderVisitsEmptyState()
                     else
                        div null,
                           @renderInteractionTypesControl()
                           ReportPage
                              title: @state.title
                              question: "When is the team visiting prospects?"
                              legend: @state.legend
                              chartData: @state.chart_data
                              tableData: @state.table_data
                              chartType: "area"
                              defaultHeight: 250
                              onExport: @handleExport
               when "my-trips"
                  ReportPage
                     title: @state.title
                     question: "When is the team booking meetings?"
                     legend: @state.legend
                     chartData: @state.chart_data
                     tableData: @state.table_data
                     chartType: "area"
                     defaultHeight: 250
                     onExport: @handleExport
               when "interactions"
                  ReportPage
                     title: @state.title
                     question: "How is each solicitor interacting with prospects?"
                     legend: @state.legend
                     tableData: @state.table_data
                     chartData: @state.chart_data
                     onExport: @handleExport
               when "prospects"
                  ReportPage
                     title: @state.title
                     question: "How many prospects is each solicitor managing by stage?"
                     legend: _.compact _.map @state.prospect_stages, (stage_key) =>
                        _.findWhere @state.legend, {value: stage_key}
                     onExport: @handleExport
                     tableData: @state.table_data
                     chartData: _.filter @state.chart_data, (data) =>
                        data.series in @state.prospect_stages
               when "proposal-amount"
                  ReportPage
                     title: @state.title
                     question: "How are proposal values changing by solicitor?"
                     legend: _.filter @state.legend, (item) ->
                        item.value?.toLowerCase() != "proposals"
                     onExport: @handleExport
                     tableData: @state.table_data
                     chartData: _.filter @state.chart_data, (item) ->
                        item.series?.toLowerCase() != "proposals"
                     chartProps:
                        stacked: false
                        xFormat: _.currencyFormatter
                     tableCellFormat: (val, column) ->
                        if column in ["Proposals", "Solicitor"]
                           span className: "ellipsis-overflow", val
                        else numeral(val).format("$0,0.00")
               when "proposal-stage"
                  ReportPage
                     title: @state.title
                     question: "How many proposals is each solicitor managing by stage?"
                     legend: @state.legend
                     chartData: @state.chart_data
                     tableData: @state.table_data
                     onExport: @handleExport
