import { VolunteerSolicitorContext, VolunteerSolicitorResolver } from "@evertrue/vms-insights";

export const WithSolicitorResolver = Wrapped => props => (
  <VolunteerSolicitorContext.Consumer>
    {consumerProps => <Wrapped {...consumerProps} {...props} />}
  </VolunteerSolicitorContext.Consumer>
);

WithSolicitorResolver.displayName = "WithVolunteerSolicitorResolver";

export default VolunteerSolicitorResolver;
