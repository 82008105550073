import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "underscore";
import Api from "entities/helpers/api";

const gql = String.raw;
const lastInteractionQuery = gql`
  query ($id: BigInt!, $poolId: BigInt, $prospectIds: [BigInt!]) {
    solicitor(contact_id: $id, pool_id: $poolId) {
      assignments(prospect_ids: $prospectIds) {
        prospect_contact_id
        prospect {
          last_contact_date
        }
      }
    }
  }
`;

const normalize = (data) => {
  const ret = _.indexBy(data, "id");
  return ret;
};

const useLastInteraction = (contactIds = [], solicitorId, poolId) => {
  const [interactions, setInteractions] = useState([]);
  const contactIdsString = JSON.stringify(contactIds);

  useEffect(() => {
    const fetchLastInteractionByIds = () => {
      return Api.GRAPHQL.GRAPHQL.post({
        data: JSON.stringify({
          operationName: null,
          query: String.raw`${lastInteractionQuery}`,
          variables: { id: solicitorId, poolId: poolId, prospectIds: JSON.parse(contactIdsString) },
        }),
      }).then((resp) => {
        const data = resp.data.solicitor.assignments;
        const formattedData = _.map(data, (obj = {}) => {
          return { id: obj.prospect_contact_id, last_contact_date: obj.prospect.last_contact_date };
        });
        setInteractions(normalize(formattedData));
      });
    };

    if (solicitorId && poolId && JSON.parse(contactIdsString).length) {
      fetchLastInteractionByIds();
    }
  }, [solicitorId, poolId, contactIdsString]);

  return interactions;
};

useLastInteraction.propTypes = {
  contactIds: PropTypes.array,
  solicitorId: PropTypes.number,
  poolId: PropTypes.number,
};

export { lastInteractionQuery };
export default useLastInteraction;
