import EverTrue from "app";
import ErrorLogger from "entities/helpers/error-log-helper";
import TwilioCallButton from "../components/twilio-call-button";
import { useIdentity } from "base/identity-resolver";
import { useState, useEffect } from "react";
import { trackOutreachAction } from "apps/outreach/utils/utils";
import { validateNumber, formattedUSPhoneNumber } from "components/dialer/utils/dialer-utils";
import { establishTwilioDevice, createActionFromCall } from "../utils/twilio-utils";
import useCallSettings from "apps/settings/phone-settings/use-call-settings";

const useTwilio = (extension, phoneNumber, contactId, onCallEnd) => {
  const [device, setDevice] = useState(null);
  const [activeCall, setActiveCall] = useState(null);
  const { user, session } = useIdentity();

  const { callerIdNumber } = useCallSettings();

  useEffect(() => {
    const establishDevice = async () => {
      if (device) return; // don't make a new one
      const twilioDevice = await establishTwilioDevice();
      setDevice(twilioDevice);
    };

    establishDevice(); // create device

    return () => {
      if (device) device.destroy(); // destroy on unmount
    };
  }, [device]);

  const startCall = async () => {
    if (callerIdNumber) {
      trackOutreachAction("make_phone_call");
      if (device) {
        const { oid, app_key, token } = session;
        const { id: userId } = user;
        const isValid = validateNumber(phoneNumber, true);
        if (isValid) {
          const call = await device.connect({
            params: {
              oid: String(oid),
              app_key,
              auth: token,
              To: formattedUSPhoneNumber(phoneNumber),
              ext: extension,
            },
          });

          // event listeners
          // https://www.twilio.com/docs/voice/sdks/javascript/twiliocall#events
          let startTime;
          call.on("ringing", () => setActiveCall(call));

          call.on("accept", (callObject) => {
            // this happens when someone answers the user's outgoing call.
            startTime = new Date().getTime(); // store the time the call really "starts"
            setActiveCall(call);
          });

          call.on("disconnect", (callObject) => {
            // this happens when a call ends.
            createActionFromCall(callObject, startTime, contactId, oid, userId);
            setActiveCall(null);
            onCallEnd();
          });

          call.on("reconnecting", (TwilioError) => {
            //this happens when the connectivity was lost and that the SDK is trying to reconnect

            EverTrue.Alert.warn(TwilioError.causes[0], { id: TwilioError.code, timeout: 10000 });
            ErrorLogger.error(
              "Twilio.Call Error",
              TwilioError.code + ":" + TwilioError.description + " " + TwilioError.causes[0]
            );
          });

          call.on("reconnected", () => {
            //this happens when the connection was established.
            EverTrue.Alert.success("The call has regained connectivity");
          });

          call.on("warning", function (warningName, warningData) {
            if (warningName === "low-mos") {
              EverTrue.Alert.warn(
                "We have detected poor call quality conditions. You may experience degraded call quality."
              );
            }
          });

          call.on("warning-cleared", function (warningName) {
            if (warningName === "low-mos") {
              EverTrue.Alert.success("Call quality conditions restored");
            }
          });

          call.on("cancel", () => {
            // this happens when the user cancels the call BEFORE anyone answers.
            setActiveCall(null);
          });

          call.on("error", (TwilioError) => {
            // this happens when there is an error with the call.
            // needs more details
            // needs to create action from error
            EverTrue.Alert.error(TwilioError.causes[0]);
            ErrorLogger.error(
              "Twilio.Call Error",
              TwilioError.code + ":" + TwilioError.description + " " + TwilioError.causes[0]
            );
            setActiveCall(null);
          });
        } else EverTrue.Alert.error("Invalid Phone Number");
      } else {
        EverTrue.Alert.error("Sorry, there was an issue with our Caller integration. Please try again.");
      }
    } else {
      EverTrue.Alert.error(
        "Error: Caller ID is required to make this call. Please set up your caller ID number in settings.",
        {
          actionLabel: "Set Up Caller ID",
          onAction: () => (window.location.href = "/settings/phone"),
        }
      );
    }
  };

  const endCall = () => {
    if (device) device.disconnectAll();
    setActiveCall(null);
  };

  const TwilioCallButtonWithContext = () => (
    <TwilioCallButton device={device} activeCall={activeCall} startCall={startCall} endCall={endCall} />
  );

  return {
    TwilioCallButtonWithContext,
    activeCall,
  };
};

export default useTwilio;

//

// IF WE WANT INCOMING CALLS:
// 1. we need to update the outreach service to enable incoming calls (currently disabled).
// 2. Register Device (Only necessary for incoming calls) (twilioDevice.register();)
// 3. add event listener for registered (optional) twilioDevice.on("registered", () => {
//   EverTrue.Alert.success("Your Voice Device is ready to use!");
// });
// 4. add event listener for receiving incoming calls (twilioDevice.on("incoming", handleIncomingCall);)

// IF WE WANT TO ALLOW DIFFERENT AUDIO DEVICES
// twilioDevice.audio.on("deviceChange", updateAllAudioDevices.bind(device));

// // Show audio selection UI if it is supported by the browser.
// if (twilioDevice.audio.isOutputSelectionSupported) {
//   audioSelectionDiv.classList.remove("hide");
// }
