import Nav from "components/lists/nav";
import NavItem from "components/lists/nav-item";
import EverTrue from "app";
import PropTypes from "prop-types";

const ReportingNav = ({ tab }) => {
  return (
    <Nav
      active={tab}
      className="reporting--nav"
      onSelect={selected_tab => {
        // We are using this onSelect on <Nav> instead of href on <NavItem>
        // because the href were causing this controller to mount every time the NavItem was clicked
        // and ideally we want the url to update but not to remount this controller
        EverTrue.UrlManager.navigate(`/reporting/${selected_tab}`, true, false);
      }}
    >
      <NavItem activeWhen="activity" label="Activity" />
      {/* Other tabs can be added here in the future */}
    </Nav>
  );
};

ReportingNav.propTypes = {
  tab: PropTypes.string,
};

export default ReportingNav;
