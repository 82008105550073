module.exports = do ->
   _ = require("underscore").default

   CommentMixin =
      dslRegexAll: /// \<\@([^<>]+)\> ///g
      dslRegexCapture: /// (\<\@[^<>]+\>) ///
      dslRegexCaptureInner: /// \<[@$](.*)\> ///
      spanRegexCapture: /// \<span.*\>(.*)\<\/span\> ///
      scriptRegexCapture: /// (\<script\>.*\<\/script\>?|\<script.*\/\>) ///

      parse: (comment, callback) ->
         text = @escapeComment(comment)
         content = text.replace @dslRegexAll, (full_match, mention) =>
            [type, id, alias] = @parseDSL(mention)
            return callback(full_match, mention, type, id, alias)
         content

      parseDSL: (string) ->
         match = string.match(@dslRegexCaptureInner)
         if !!match
            [type, id, alias] = match[1]?.split(":")
         else
            [type, id, alias] = string.split(":")

      unwrapSpan: (text) ->
         text.replace @spanRegexCapture, (match) =>
            match.match(@spanRegexCapture)?[1]

      escapeComment: (string) ->
         if !string then return ""
         else
            splitText = string.split @dslRegexCapture
            _.each splitText, (text_part, index) =>
               if !text_part.match @dslRegexCapture
                  escaped = text_part?.replace(@scriptRegexCapture, "") # prevent <script/> hacks
                  # Solve for ES highlights which can wrap the ugc dsl in html
                  if escaped.match(@dslRegexCaptureInner) && escaped.match("<span")
                     span_match = escaped.match(@spanRegexCapture)
                     splitText[index] = escaped.replace(span_match[0], span_match[1])
                  else splitText[index] = escaped

            splitText.join("")

      getSnippet: (string="", max_char_length) ->
         # Get the char position of the start / end of each mention
         # Make sure the mention is not cut off in the middle of the DSL
         indexes = []
         while (match = @dslRegexAll.exec(string)) != null
            indexes.push [match.index, match.index + match[0]?.length]

         char_length = max_char_length
         _.each indexes, (tuple) ->
            [start, end] = tuple
            if start < max_char_length && end > max_char_length
               char_length = start

         string.substr(0, char_length) + (if string.length > char_length then "..." else "")
