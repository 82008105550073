import { Component } from "react";
import PropTypes from "prop-types";
import { Popover2, Icon } from "@evertrue/et-components";
import moment from "moment";
import classNames from "classnames";
import CalendarDatePicker from "./calendar-date-picker";
import { Button } from "@evertrue/et-components";
import colors from "base/colors";

class DatePickerDropdown extends Component {
  static propTypes = {
    onDayClick: PropTypes.func,
    date: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    triggerClassName: PropTypes.string,
    disableCloseOnDayClick: PropTypes.bool,
    disabled: PropTypes.bool,
    chevronClassName: PropTypes.string,
    allowClear: PropTypes.bool,
    buttonType: PropTypes.string,
    disabledDays: PropTypes.object,
    showDay: PropTypes.bool,
    disableWeekends: PropTypes.bool,
  };

  static defaultProps = {
    onDayClick: () => {},
    date: moment.now(),
    disabled: false,
    chevronClassName: "",
    buttonType: "secondary",
    disableWeekends: false,
  };

  handleClick = (date) => {
    const new_date = moment(date).valueOf();
    this.props.onDayClick(new_date);
  };

  render() {
    const { disableWeekends, disabledDays } = this.props;

    const weekendsDisabledDays = disableWeekends
      ? { daysOfWeek: [0, 6] } // 0 for Sunday, 6 for Saturday
      : {};

    const combinedDisabledDays = {
      ...disabledDays,
      ...weekendsDisabledDays,
    };
    return (
      <div>
        <Popover2
          borderColor={colors.blue300}
          fillcolor="#fff"
          trigger={({ openPopover }) => {
            return (
              <Button
                type={this.props.buttonType}
                className={classNames("date-picker-dropdown--btn", this.props.triggerClassName)}
                onClick={openPopover}
                disabled={this.props.disabled}
              >
                <span>
                  <Icon icon="date-range" className="date-picker-dropdown--cal-icon" size={1} />
                  {this.props.date
                    ? this.props.showDay
                      ? moment(this.props.date).isSame(moment(), "day")
                        ? moment(this.props.date).calendar(null, { sameDay: "[Today]" }) +
                          ", " +
                          moment(this.props.date).format("MMM DD, YYYY")
                        : moment(this.props.date).format("dddd, MMM DD, YYYY")
                      : moment(this.props.date).format("MMM DD, YYYY")
                    : "Select Date"}
                </span>
                <Icon icon="chevron-down" size={1} style={{ opacity: "65%" }} className={this.props.chevronClassName} />
              </Button>
            );
          }}
        >
          {({ closePopover }) => (
            <div className="date-picker--dropdown">
              <CalendarDatePicker
                disabledDays={combinedDisabledDays}
                selectedDays={this.props.date ? new Date(this.props.date) : new Date()}
                initialMonth={this.props.date ? new Date(this.props.date) : new Date()}
                onDayClick={(...args) => {
                  if (!this.props.disableCloseOnDayClick) {
                    closePopover();
                  }
                  this.handleClick(...args);
                }}
              />
              {this.props.allowClear && (
                <Button
                  type="simple"
                  onClick={() => {
                    closePopover();
                    this.props.onDayClick(null);
                  }}
                >
                  Clear
                </Button>
              )}
            </div>
          )}
        </Popover2>
      </div>
    );
  }
}

export default DatePickerDropdown;
