module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   AuthApi = require("entities/auth/auth-api")
   SessionSource = require("base/session/session-source")
   UserSource = require("base/user/user-source")

   class Session extends AuthApi.Model
      urlRoot: "skiff"

      initialize: ->
         @on "change", @saveToStore, @

      parse: (data={}) ->
         if data.token
            _.extend {}, data, {id: "id-#{data.token}"}

      renewSession: (options = {}) ->
         SessionSource.startRenewSession(options)

      saveToStore: ->
         EverTrue.store.push "session", @, {cache: true}
         unless EverTrue.request "isImpersonating?"
            if user_data = @get("user")
               UserSource.set(user_data)
