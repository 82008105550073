module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   LayoutSource = require("apps/layout/sources/layout-source")
   {createFactory} = require("base/new-utils")
   Button = createFactory(require("@evertrue/et-components").Button)


   ModalTrigger = ReactLibs.createClass
      displayName: "ModalTrigger"
      propTypes:
         title: ReactLibs.PropTypes.string.isRequired
         modal: ReactLibs.PropTypes.element.isRequired
         show: ReactLibs.PropTypes.bool
         disable: ReactLibs.PropTypes.bool
         onUnmount: ReactLibs.PropTypes.func
         onClick: ReactLibs.PropTypes.func
         buttonType: ReactLibs.PropTypes.string
         buttonClass: ReactLibs.PropTypes.string

      getDefaultProps: ->
         onClick: _.noop
         buttonType: "simple"

      UNSAFE_componentWillMount: ->
         if @props.show
            @handleLaunchModal()

      UNSAFE_componentWillReceiveProps: (newProps) ->
         if newProps.show && !@props.show
            @handleLaunchModal(newProps)
         else if !newProps.show && @props.show
            @handleUnmountModal()

      handleLaunchModal: (props) ->
         props ?= @props
         return if props.disable

         dialog = React.cloneElement props.modal,
            remove: => @handleUnmountModal()
            onUnmount: => @handleUnmountModal()

         overlay_key = @overlay_key || _.randomKey()
         @overlay_key = overlay_key

         LayoutSource.overlay dialog,
            key: overlay_key, top: 0, left: 0, bottom: 0, right: 0, zIndex: 900

         @props.onClick?()

      handleUnmountModal: ->
         LayoutSource.removeOverlay(@overlay_key)
         @overlay_key = undefined
         @props.onUnmount?()

      render: ->
         if @props.children
            additionalProps = _.omit(@props, "modal", "container", "disable")
            props =
               onClick: =>
                  @handleLaunchModal()
               onKeyPress: (event) =>
                  if event.key == "Enter" then @handleLaunchModal()
               title: @props.title
               type: @props.buttonType
               className: @props.buttonClass

            Button _.extend({}, additionalProps, props),
               @props.children
         else null

   return ModalTrigger
