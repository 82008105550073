import _ from "underscore";
import { Component } from "react";
import InviteTableInput from "apps/settings/invite-team-settings/components/invite-table-input";

const DEFAULT_ROLE = "User";

class InviteTableRow extends Component {
  static propTypes = {
    rowData: ReactLibs.PropTypes.object,
    rowConfig: ReactLibs.PropTypes.array,
    onChange: ReactLibs.PropTypes.func, // args (value, key, rowIndex)
    rowIndex: ReactLibs.PropTypes.number,
    blockErrors: ReactLibs.PropTypes.array,
  };

  static defaultProps = {
    rowData: {},
    rowConfig: [],
    onChange: () => {},
    rowIndex: 0,
    blockErrors: [],
  };

  handleChange = (value, key) => {
    this.props.onChange(value, key, this.props.rowIndex);
  };

  isRowEmpty() {
    return _.every(this.props.rowData, value => value === "" || value === DEFAULT_ROLE);
  }

  isRowError() {
    return this.props.blockErrors.find(error => _.isEqual(error.invitation, this.props.rowData));
  }

  renderTableRow(inputs) {
    const isRowEmpty = this.isRowEmpty();
    const rowError = this.isRowError();
    return inputs.map(node => (
      <InviteTableInput
        key={node.key.concat(this.props.rowIndex)}
        inputConfig={node}
        inputData={this.props.rowData[node.key]}
        rowEmpty={isRowEmpty}
        rowError={rowError}
        onChange={this.handleChange}
      />
    ));
  }

  render() {
    return <tr>{this.renderTableRow(this.props.rowConfig)}</tr>;
  }
}

export default InviteTableRow;
