const _ = require("underscore").default;
const { createSource } = require("@evertrue/et-flux");
const EverTrue = require("app");
const Api = require("entities/helpers/api");
const Decorator = require("clientDecorator");
const { addProspectsToPool } = require("apps/volunteers/utils");

const ES_DELAY = 1000;
let _pending_request = null;

const _trackAction = (type, object) =>
  EverTrue.track.set(
    "pool_action",
    _.extend(
      {
        type,
        object: "prospects",
      },
      object
    )
  );

module.exports = createSource("PoolProspectsSource", {
  actions: {
    loading: true,
    fetch(pool_id) {
      return this.require(!_.isUndefined(pool_id), "pool_id is required");
    },
    fetchedContacts(pool_id) {
      return this.require(!_.isUndefined(pool_id), "pool_id is required");
    },
    addContacts(pool_id, contact_ids) {
      this.require(!_.isUndefined(pool_id), "pool_id is required");
      return this.require(_.isArray(contact_ids), "contact_ids should be an array");
    },
    addContactsFromList(pool_id, list) {
      this.require(!_.isUndefined(pool_id), "pool_id is required");
      return this.require(_.isObject(list), "list should be an object");
    },
    removedContact(pool_id, contact_id) {
      this.require(!_.isUndefined(pool_id), "pool_id is required");
      return this.require(!_.isUndefined(contact_id), "contact_id is required");
    },
    updateFilter(pool_id, key) {
      this.require(!_.isUndefined(pool_id), "pool_id is required");
      return this.require(!_.isUndefined(key), "key is required");
    },
    paginate(pool_id) {
      return this.require(!_.isUndefined(pool_id), "pool_id is required");
    },
    select(pool_id, id) {
      this.require(!_.isUndefined(pool_id), "pool_id is required");
      return this.require(!_.isUndefined(id), "id is required");
    },
    setProspectTotal(pool_id, total) {
      this.require(!_.isUndefined(pool_id), "pool_id is required");
      return this.require(_.isNumber(total), "total should be number");
    },
    clearSelection(pool_id) {
      return this.require(!_.isUndefined(pool_id), "pool_id is required");
    },
  },
  promise: {
    removeContact(pool_id, contact_id, solicitor_contact_id) {
      _trackAction("remove_from_pool");
      this.actions.loading(pool_id, true);
      return Api.VOLUNTEER.PROSPECTS.delete({
        urlExtend: `/${contact_id}`,
        params: { pool_id },
        statusCode: {
          202: () => {
            this.actions.removedContact(pool_id, contact_id, solicitor_contact_id);
            this.actions.loading(pool_id, false);
            _.wait(ES_DELAY, () => {
              this.fetch(pool_id);
              this.fetchTotal(pool_id);
            });
          },
        },
      })
        .then(() => {
          this.actions.loading(pool_id, false);
        })
        .catch(() => {
          this.actions.loading(pool_id, false);
        });
    },
  },
  api: {
    fetchTotal(pool_id) {
      Api.CONTACTS.SEARCH.post({
        params: { limit: 1 },
        data: _.jsonStringify({
          has_child: [
            {
              type: "assignment",
              query: {
                must: [{ pool_id: { match: pool_id } }, { parent_role: { match: "prospect" } }],
              },
            },
          ],
        }),
        success: (contacts) => this.actions.setProspectTotal(pool_id, contacts.total),
      });
    },

    // round trip that calls the search method below
    fetch(pool_id) {
      this.actions.fetch(pool_id);
    },

    search(pool_id, query, params, load) {
      if (_pending_request && typeof _pending_request.abort === "function") {
        _pending_request.abort();
      }

      if (load) {
        this.actions.loading(pool_id, true);
      }
      Api.CONTACTS.SEARCH.post({
        params,
        data: _.jsonStringify(query),
        beforeSend(xhr) {
          _pending_request = xhr;
        },
        success: (resp) => {
          _pending_request = undefined;
          const contacts = _.cloneData(resp);
          contacts.items = _.map(contacts.items, (contact) => Decorator.Contacts.parse(contact));
          this.actions.fetchedContacts(pool_id, contacts);
          this.actions.loading(pool_id, false);
        },
        error: () => {
          this.actions.loading(pool_id, false);
        },
      });
    },

    addContacts(pool_id, contact_ids) {
      this.actions.loading(pool_id, true);
      addProspectsToPool(pool_id, contact_ids)
        .then(() => {
          _.wait(ES_DELAY, () => {
            this.fetch(pool_id);
            this.fetchTotal(pool_id);
          });
        })
        .catch(() => this.actions.loading(pool_id, false));
    },

    addContactsFromList(pool_id, list) {
      _trackAction("add_to_pool");
      this.actions.loading(pool_id, true);

      Api.VOLUNTEER.PROSPECTS.post({
        urlExtend: `/list/${list.id}`,
        params: { pool_id },
        success: () => {
          _.wait(ES_DELAY, () => {
            this.fetch(pool_id);
            this.fetchTotal(pool_id);
          });
        },
        error: () => {
          this.actions.loading(pool_id, false);
        },
      });
    },

    updateFilter(pool_id, key, value) {
      if (!_.isUndefined(value)) {
        _trackAction("filter_prospects", { filter_key: key });
      }
      this.actions.updateFilter(pool_id, key, value);
    },

    paginate(pool_id) {
      this.actions.paginate(pool_id);
    },

    select(pool_id, id) {
      this.actions.select(pool_id, id);
    },

    clearSelection(pool_id) {
      this.actions.clearSelection(pool_id);
    },
  },
});
