import _ from "underscore";
import { createContext, Component } from "react";
import PropTypes from "prop-types";
import { fetchSolicitorAssignments, fetchSolicitorProposals } from "apps/portfolio-performance/portfolio-requests";

const SolicitorDataContext = createContext(() => {});

class SolicitorDataProvider extends Component {
  static propTypes = {
    children: PropTypes.any,
    contactIds: PropTypes.array,
  };

  static defaultProps = {
    contactIds: [],
  };

  _isMounted = false;
  componentDidMount() {
    this._isMounted = true;
    this.fetchSolicitorAssignments();
    this.fetchSolicitorProposals();
  }

  componentDidUpdate(prevProps) {
    if (!_.isArrayEqual(prevProps.contactIds, this.props.contactIds)) {
      this.fetchSolicitorAssignments();
      this.fetchSolicitorProposals();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    if (this._isMounted) {
      this.setState(...args);
    }
  };

  fetchSolicitorAssignments = () => {
    if (_.isEmpty(this.props.contactIds)) {
      return null;
    }
    const { contactIds } = this.props;
    this.safeSetState({ loading_assignments: true });
    fetchSolicitorAssignments(contactIds)
      .then(resp => {
        this.safeSetState({ loading_assignments: false, solicitor_assignments: _.groupBy(resp, "contact_id") });
      })
      .catch(() => {
        this.safeSetState({ loading_assignments: false });
      });
  };

  fetchSolicitorProposals = () => {
    if (_.isEmpty(this.props.contactIds)) {
      return null;
    }
    const { contactIds } = this.props;
    this.safeSetState({ loading_proposals: true });
    fetchSolicitorProposals(contactIds)
      .then(resp => {
        let proposals_by_id = {};
        if (resp.stats) {
          proposals_by_id = resp.stats.id.reduce((acc, cur) => {
            acc[cur.group_by_field_value] = cur.stats.count;
            return acc;
          }, {});
        }
        this.safeSetState({ loading_proposals: false, solicitor_proposals: proposals_by_id });
      })
      .catch(() => {
        this.safeSetState({ loading_proposals: false });
      });
  };

  state = {
    solicitor_assignments: {},
    solicitor_proposals: {},
    loading_assignments: false,
    loading_proposals: false,
  };

  render() {
    return <SolicitorDataContext.Provider value={this.state}>{this.props.children}</SolicitorDataContext.Provider>;
  }
}

export { SolicitorDataProvider };

export default SolicitorDataContext;
