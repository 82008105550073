import _ from "underscore";
import { Component } from "react";
import PropTypes from "prop-types";
import numeral from "numeral";
import { connect } from "@evertrue/et-flux";
import ClusterContactStore from "apps/map/stores/cluster-contact-store";
import GeolocationSource from "apps/contact/sources/geolocation-source";
import MapUtils from "apps/map/map-utils";
import MapSource from "apps/map/sources/map-source";
import MapStore from "apps/map/stores/map-store";
import Map from "apps/map/components/map";
import MapInfoWindow from "apps/map/components/map-info-window";
import MapMarker from "apps/map/components/map-marker";
import MapClusterContacts from "apps/map/components/map-cluster-contacts";
import d3 from "d3";

const mapStateToProps = () => ({
  zoom: MapStore.getZoom(),
  map: MapStore.getMap(),
  highlighted: MapStore.getHighlightedClusters(),
  cluster_contacts: ClusterContactStore.getContacts(),
  loading_cluster_contacts: ClusterContactStore.getLoading(),
});

class MapWithClusters extends Component {
  static propTypes = {
    clusters: PropTypes.object,
    loading: PropTypes.bool,
    defaultZoom: PropTypes.number,
    defaultBounds: PropTypes.object,
    setBounds: PropTypes.object,
    setCenter: PropTypes.object,
    setLocationMarker: PropTypes.object,
    setZoom: PropTypes.number,
    onMapInit: PropTypes.func,
    onMapChange: PropTypes.func,
    onClusterOpen: PropTypes.func,
    onLocationChange: PropTypes.func,
    children: PropTypes.any,
    // props from stores
    zoom: PropTypes.number,
    map: PropTypes.object,
    highlighted: PropTypes.array,
    cluster_contacts: PropTypes.object,
    loading_cluster_contacts: PropTypes.bool,
  };

  static defaultProps = {
    clusters: {},
    onMapChange: _.noop,
    onClusterOpen: _.noop,
    onMapInit: _.noop,
    // props from store
    cluster_contacts: {},
  };

  state = {
    bounds: this.props.defaultBounds,
    active_cluster: undefined,
    marker: undefined,
    set_zoom: undefined,
  };

  handleMapChange = (zoom, bounds) => {
    const bounding_box = MapUtils.boundsToBox(bounds);
    this.setState({ set_zoom: false });
    if (zoom !== this.props.zoom) {
      MapSource.clearClusters();
      MapSource.zoom(zoom);
      this.setState({ bounds: bounding_box, marker: undefined, active_cluster: undefined });
      this.props.onMapChange(zoom, bounding_box);
    } else if (!this.state.marker) {
      this.setState({ bounds: bounding_box });
      this.props.onMapChange(zoom, bounding_box);
    }
  };

  handleClusterZoom = (latLng, zoom, map) => {
    this.setState({ set_zoom: true });
    MapSource.clearClusters();
    GeolocationSource.setLocation(latLng);
    MapSource.increasePrecision(zoom);
  };

  handleClusterOpen = (key, cluster, marker) => {
    if (!this.props.loading) {
      this.props.onClusterOpen(key, cluster);
      this.setState({ marker: marker, active_cluster: key });
    }
  };

  handleResetMarker = () => {
    if (this.state.marker && this.state.marker.close) {
      this.state.marker.close();
    }
    this.setState({ marker: undefined, active_cluster: undefined });
  };

  handleMapReload = (marker, map) => {
    this.handleReload(map.getZoom(), map.getBounds());
  };

  handleMapReloadClick = () => {
    this.handleReload(this.props.zoom, this.props.map.getBounds());
  };

  handleReload = (zoom, bounds) => {
    this.handleResetMarker();
    const bounding_box = MapUtils.boundsToBox(bounds);
    this.props.onMapChange(zoom, bounding_box);
    this.setState({ bounds: bounding_box });
  };

  getClusterMarker = (cluster) => {
    let clusters = _.uniq(
      _.sortBy(
        _.compact(
          _.map(this.props.clusters, (c) => {
            if (c.count > 1) {
              return c.count;
            }
          })
        )
      )
    );
    if (_.max(clusters) - _.min(clusters) > 100) {
      clusters = _.rest(clusters, Math.floor(clusters.length / 5) - 1);
    }
    const buckets = [35, 40, 44, 48, 52, 56, 60, 64, 68];
    const scale = d3.scale.quantile().domain(clusters).range(buckets);
    const size = scale(cluster.count);

    if (cluster.count === 1) {
      return { icon: "/images/map_pin_person", size: { width: 32, height: 32 } };
    } else {
      return { icon: "/images/map_cluster_2", size: { width: size, height: size } };
    }
  };

  render() {
    return (
      <div className="map-with-clusters">
        <Map
          defaultZoom={this.props.defaultZoom}
          defaultBounds={this.props.defaultBounds}
          setBounds={this.props.setBounds}
          setZoom={this.state.set_zoom ? this.props.zoom : this.props.setZoom}
          setCenter={this.props.setCenter}
          setLocationMarker={this.props.setLocationMarker}
          loading={this.props.loading}
          controls={this.props.children}
          onMapChange={this.handleMapChange}
          onLocationChange={this.props.onLocationChange}
          onMapInit={(map) => {
            MapSource.initializeMap(map, map.getZoom());
            const bounding_box = MapUtils.boundsToBox(map.getBounds());
            this.setState({ bounds: bounding_box });
            this.props.onMapInit(bounding_box);
          }}
          onMapClick={(map) => {
            if (this.state.marker) {
              this.handleMapReload(this.state.marker, map);
            }
          }}
        >
          <MapInfoWindow marker={this.state.marker} onClose={this.handleMapReload}>
            <MapClusterContacts
              contacts={
                this.props.cluster_contacts[this.state.active_cluster]
                  ? this.props.cluster_contacts[this.state.active_cluster].items
                  : null
              }
              loading={this.props.loading_cluster_contacts}
              geobox={
                this.props.clusters[this.state.active_cluster]
                  ? this.props.clusters[this.state.active_cluster].geobox
                  : null
              }
            />
          </MapInfoWindow>

          {_.map(this.props.clusters, (cluster = {}, key) => {
            const position = MapUtils.boxToLatLng(cluster.geobox);
            if (!MapUtils.isLatLngInGlobalBox(position, this.state.bounds)) {
              return;
            }
            const is_highlighted = _.contains(this.props.highlighted, key);
            const marker_style = this.getClusterMarker(cluster);
            return (
              <MapMarker
                key={key}
                label={cluster.count > 1 ? numeral(cluster.count).format("0,0") : ""}
                position={position}
                icon={is_highlighted ? marker_style.icon + "_active" : marker_style.icon}
                size={marker_style.size}
                isSelected={this.state.active_cluster === key}
                onClick={(latLng, count, marker, map) => {
                  this.handleResetMarker();
                  const zoom = map.getZoom();
                  if (cluster.count > 25 && zoom < 17) {
                    this.handleClusterZoom(latLng, zoom);
                  } else {
                    this.handleClusterOpen(key, cluster, marker);
                  }
                }}
              />
            );
          })}

          {this.props.loading && (
            <div className="map-container--feedback">
              <i className="fa fa-fw fa-spinner fa-pulse" />
              Loading Clusters...
            </div>
          )}
          {this.state.marker && (
            <div className="map-container--feedback">
              <span className="text-label">Map will not reload while viewing cluster </span>
              <a onClick={this.handleMapReloadClick}>Reload Now</a>
            </div>
          )}
        </Map>
      </div>
    );
  }
}

export default connect(MapWithClusters, [MapStore, ClusterContactStore], mapStateToProps);
