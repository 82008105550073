import { useState, useEffect, useContext } from "react";
import _ from "underscore";
import Api from "entities/helpers/api";
import PortfolioNav from "apps/portfolio-performance/controllers/portfolio-nav";
import { FlexTable, ActionsMenu, Icon, Modal, SearchInput } from "@evertrue/et-components";
import PageHeader from "apps/layout/components/page-header";
import TeamSettingsModal from "apps/portfolio-performance/components/team-settings-modal";
import { GlobalPortfolioContext } from "../contexts/global-portfolio-context";

const LIMIT = 20;

const PortfolioPerformanceTeamController = () => {
  const { assignmentType } = useContext(GlobalPortfolioContext);
  const [search_text, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  const [teams, setTeams] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    loadTeamData();
    // eslint-disable-next-line
  }, [page, search_text, assignmentType]);

  const loadTeamData = async () => {
    try {
      setLoading(true);
      const result = await Api.VOLUNTEER.POOLS.get({
        params: {
          pool_type: assignmentType,
          limit: LIMIT,
          offset: (page - 1) * LIMIT,
          name: search_text,
          rollups: false,
        },
      });

      const teams = result.items || [];
      const sorted_teams = _.sortBy(teams, "name");
      setLoading(false);
      setTeams(sorted_teams);
      setTotal(result.total);
    } catch {
      setLoading(false);
    }
  };

  const getColumns = (team_settings) => {
    return [
      {
        header: "Team",
        accessor: (team) => {
          return team.name;
        },
      },
      {
        header: "Team Size",
        accessor: (team) => {
          return team.total_prospect_count;
        },
      },
      {
        header: "Self Selection",
        accessor: (team) => {
          return team.selection_mode === "SOLICITOR_SELECTION" ? "Enabled" : "Disabled";
        },
      },
      {
        header: "Visit Threshold",
        accessor: (team) => {
          return team.visit_threshold || 365;
        },
      },
      {
        header: "Contact Threshold",
        accessor: (team) => {
          return team.contact_threshold || 365;
        },
      },
      {
        header: "Actions",
        Cell: (team) => {
          return (
            <td className="et--flex-table--cell">
              <ActionsMenu
                wrapperClassName="team-mgmt-row--action-menu"
                trigger={({ is_open }) => (
                  <Icon title="Open team action menu" icon="more-horiz" className="team-mgmt-row--actions-icon" />
                )}
                options={[
                  {
                    id: 1,
                    icon: "settings",
                    label: "Edit Settings",
                    // eslint-disable-next-line react/prop-types
                    mountOnClick: ({ is_open, close }) => (
                      <Modal isOpen={is_open} closeModal={close}>
                        <TeamSettingsModal team={team} close={close} refreshData={loadTeamData} />
                      </Modal>
                    ),
                  },
                ]}
              />
            </td>
          );
        },
      },
    ];
  };

  return (
    <>
      <PageHeader title="Teams" />
      <PortfolioNav tab="teams" />
      <div className="contact-table-toolbar--actions team-management--top">
        <SearchInput
          value={search_text}
          onChange={(val) => {
            setPage(1);
            setSearchText(val);
          }}
          width={300}
          placeholder="Search by name..."
        />
      </div>
      <div
        className="team-management--table-wrap team-management"
        style={{
          top: "185px",
        }}
      >
        <FlexTable
          className="portfolio-team-table"
          caption="Portfolio and Performance Teams"
          loading={loading}
          data={teams}
          columns={getColumns(teams)}
          limit={LIMIT}
          total={total}
          page={page}
          handlePageChange={(page) => {
            setPage(page);
          }}
        />
      </div>
    </>
  );
};

export default PortfolioPerformanceTeamController;
