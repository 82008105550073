import PropTypes from "prop-types";
import ProfileCardSubtitle from "apps/profile/components/profile-card-subtitle";
import ScoreProgress from "apps/contact/components/contacts/score-progress";
import ScoreBreakdown from "apps/profile/components/score-breakdown";

const ScoreCard = ({ scores = { score: {} } }) => {
  return (
    <div className="profile-clarity">
      <div className="profile-clarity--sub-header">
        <ProfileCardSubtitle label="EverTrue Score" help_key="profile_evertrue_score" help_position="bottom" />
      </div>
      <div className="profile-clarity--score-wrapper">
        <div className="profile-clarity--total-score">
          <ScoreProgress score={scores.score.value} showTotal className="profile-clarity--total-progress" />
        </div>
        <div className="profile-clarity--score-breakdown">
          <ScoreBreakdown scores={scores} />
        </div>
      </div>
    </div>
  );
};

ScoreCard.propTypes = {
  scores: PropTypes.object,
};

export default ScoreCard;
