import Api from "entities/helpers/api";
import _ from "underscore";
import $ from "jquery";
import Decorator from "clientDecorator";
import EverTrue from "app";
import BulkAddSource from "apps/layout/sources/bulk-add-source";
import str from "underscore.string";
import get from "lodash.get";
import { VOLUNTEER_LEAD_ROLE, VOLUNTEER_ROLE } from "apps/volunteers/constants";

export const fetchPoolById = (id, disableAlerts, assigned = "all") =>
  Api.VOLUNTEER.POOLS.get({
    urlExtend: `/${id}`,
    disableAlerts,
    ...(assigned !== "all" && { params: { prospect_assigned: assigned } }),
  });

export function fetchUsers(contact_ids, role_ids, org_id) {
  const query = {
    must: [
      {
        "affiliations.contact_id": {
          in: contact_ids,
        },
      },
      {
        "affiliations.affiliation_roles.role_id": {
          in: role_ids,
        },
      },
    ],
  };

  // If role_ids is empty they don't have volunteer roles for the org
  // and we want no users to be returned
  if (_.isEmpty(role_ids)) {
    return Promise.resolve({});
  }
  return Api.SEARCH.USER.post({
    data: _.jsonStringify(query),
  }).then(resp => {
    const users_by_contact_id = {};
    _.each(resp.items, user => {
      const contact_id = Decorator.User.getContactId(user, org_id);
      users_by_contact_id[contact_id] = user;
    });
    return users_by_contact_id;
  });
}

export function isVolunteerRole(role = {}) {
  return role.name.match(/^Volunteer/);
}

export function getVolunteerRoleIds(roles) {
  return _.compactMap(roles, role => (isVolunteerRole(role) ? role.id : undefined));
}

export function getNonVolunteerRoleIds(roles) {
  return _.compactMap(roles, role => (!isVolunteerRole(role) ? role.id : undefined));
}

export function fetchSolicitorsByContact({ contactIds, poolId }) {
  const contact_id = _.chain(contactIds)
    .makeArray()
    .uniq()
    .compact()
    .value();

  return Api.VOLUNTEER.SOLICITORS.get({
    urlExtend: "/bulk",
    params: {
      contact_id,
      ...(poolId ? { pool_id: poolId } : {}),
    },
  });
}

export function createPool(data) {
  trackVMSAction("pool_action", "create_pool", { object: "pool" });
  return Api.VOLUNTEER.POOLS.post({
    data: _.jsonStringify(data),
    disableAlerts: true,
  });
}

export function addSolicitorsToPool(pool_id, contact_ids) {
  trackVMSAction("pool_action", "add_to_pool");
  return Api.VOLUNTEER.SOLICITORS.post({
    urlExtend: "/bulk",
    params: { pool_id },
    data: _.jsonStringify(_.map(contact_ids, id => ({ contact_id: id, role: "Volunteer" }))),
  });
}

export function addProspectsToPool(pool_id, contact_ids) {
  trackVMSAction("pool_action", "add_to_pool", { object: "prospects" });
  return Api.VOLUNTEER.PROSPECTS.post({
    params: { pool_id },
    data: _.jsonStringify({ prospect_contact_ids: contact_ids }),
  }).then(data => {
    const num = contact_ids.length;
    const text = num === 1 ? "Prospect" : "Prospects";
    EverTrue.Alert.success(`${num} ${text} successfully added`);
    return data;
  });
}

export function trackVMSAction(action_type, type, object) {
  EverTrue.track.set(action_type, {
    type,
    object: "volunteers",
    ...object,
  });
}

// used in 2 places, fires confirm then kicks off spark job that resets assignments/stages
export function bulkResetPoolsConfirm(pool_ids, type) {
  const data = {
    pool_ids: _.makeArray(pool_ids),
    ...(type === "assignments" && { unassign_prospects: true }),
    ...(type === "stages" && { stage: { clear: true } }),
  };

  EverTrue.Alert.confirm(
    {
      content: `Are you sure you want to reset the ${str.titleize(type)} in this pool? This action \
        cannot be undone.`,
      headline: `Reset ${str.titleize(type)}?`,
    },
    did_confirm => {
      if (did_confirm) {
        BulkAddSource.bulkVMSRollover({ pool_ids, progress: 0 });
        Api.VOLUNTEER.BULK_ROLLOVER.put({
          data: _.jsonStringify(data),
        }).catch(xhr => {
          if (xhr.status !== 202) {
            BulkAddSource.bulkVMSRollover({});
          }
        });
      }
    }
  );
}
export const getProductTypes = (user, org_id) => {
  let product_string;
  const roles = _.pluck(Decorator.User.getRoles(user, org_id), "name");
  const is_community_user = _.contains(roles, "Alumni");

  if (_.any(roles, r => r.match(/^(GivingTree|Admin)/))) {
    if (is_community_user) {
      product_string = "EverTrue and Community platforms";
    } else {
      product_string = "EverTrue platform";
    }
  } else {
    product_string = "Community platform";
  }
  return product_string;
};

export const getContactDataForVMSInvite = ({ contact, user: { email }, email_type = "primary", alt_email, sso }) => {
  const name = Decorator.Contacts.getFullName(contact);
  const [first_name = "", last_name = " "] = name.split(" ");

  const saml_user_id = get(contact, "properties.school_identifier.value");

  let user_email = email;
  if (!email) {
    if (email_type === "primary") {
      user_email = get(Decorator.Profile.getPrimaryContactInfo(contact), "email.email");
    } else {
      user_email = alt_email;
    }
  }
  return { name, email: user_email, contact_id: contact.id, ...(sso && { saml_user_id, first_name, last_name }) };
};

export const fetchPoolData = poolId =>
  Api.VOLUNTEER.POOLS.get({
    urlExtend: `/${poolId}`,
    disableAlerts: true,
  });

export const toggleHeadAgentDesignation = (solicitor = {}, isHeadAgent, poolId) => {
  const role = !isHeadAgent ? VOLUNTEER_LEAD_ROLE : VOLUNTEER_ROLE;
  Api.VOLUNTEER.SOLICITORS.put({
    urlExtend: `/${solicitor.id}`,
    data: _.jsonStringify({ ...solicitor, role }),
  });
};

export const getVolunteerActivityFilterParam = (user = {}, pool = {}) =>
  $.param({
    operator: "and",
    filters: [
      {
        filter_id: "9.4",
        filter_row_id: "0",
        value: [{ value: user.name, label: user.name }],
      },
      {
        filter_id: "9.5",
        filter_row_id: "1",
        value: {
          label: { value: "Volunteer Pool ID", label: "Volunteer Pool iD" },
          values: [{ value: pool.id, label: pool.name }],
        },
      },
    ],
  });

export const getVolunteerAssignmentFilterParam = (solicitor_contact_id, name, pool = {}) =>
  $.param({
    operator: "and",
    filters: [
      {
        filter_id: "12.2",
        filter_row_id: "0",
        value: [{ value: pool.id, label: pool.name }],
      },
      {
        filter_id: "12.3",
        filter_row_id: "1",
        value: [{ value: solicitor_contact_id, label: name }],
      },
    ],
  });
