import { useState, useEffect } from "react";
import _ from "underscore";
import PropTypes from "prop-types";
import { ListboxSelect, Button, Icon, Tooltip2, usePrevious } from "@evertrue/et-components";
import EverTrue from "app";
import RuleRow from "apps/query-builder/components/logic-group/rule-row";
import { isQueryBlockValid } from "apps/query-builder/utils/is-query-valid";

const LogicGroup = ({ groupFilters, logicOperators, onChange }) => {
  const [condition, setCondition] = useState(groupFilters.condition);
  const [rules, setRules] = useState(groupFilters.rules);
  const prevCondition = usePrevious(condition);
  const prevRules = usePrevious(rules);
  const isAnyRuleEmpty = rules.find((rule) => _.isEmpty(rule)) ? true : false;
  const isQueryLogicValid = isQueryBlockValid(groupFilters);
  const isAddFilterDisabled = !isQueryLogicValid || isAnyRuleEmpty;

  useEffect(() => {
    if (prevCondition !== condition || prevRules !== rules) {
      onChange({ condition, rules });
    }
  }, [condition, rules, onChange, prevCondition, prevRules]);

  const handleAddFilter = () => {
    const rulesCopy = [...rules];
    rulesCopy.push({});
    setRules(rulesCopy);
  };

  const handleRemoveFilter = (index) => {
    const { error_message, required } = rules[index];
    if (required) {
      const errorMessage = error_message || "Filter is required, cannot remove";
      EverTrue.Alert.error(errorMessage);
    } else {
      const rulesCopy = [...rules];
      rulesCopy.splice(index, 1);
      if (rulesCopy.length === 0) {
        rulesCopy.push({});
      }
      setRules(rulesCopy);
    }
  };

  return (
    <div className="query-builder--group">
      <div className="query-logic">
        <ListboxSelect
          size="compact"
          label="Values"
          value={{ value: condition }}
          options={logicOperators}
          onChange={({ value }) => {
            EverTrue.track.set("filter_v2_action", {
              type: "condition_changed",
              filters_condition: value,
            });
            setCondition(value);
          }}
        />
      </div>
      <div className="query-builder--section">
        {rules.map((rule, index) => {
          const rowKey = `${rule.compound_id}${index}`;
          return (
            <RuleRow
              key={rowKey}
              className="query-builder--wrapper"
              filter={rule}
              onRemove={() => handleRemoveFilter(index)}
              onChange={(updatedFilter) => {
                const rulesCopy = [...rules];
                rulesCopy[index] = updatedFilter;
                setRules(rulesCopy);
              }}
            />
          );
        })}
        <div className="query-builder--buttons">
          <Button type="simple" onClick={handleAddFilter} disabled={isAddFilterDisabled} label="Add filter">
            <Tooltip2
              trigger={
                <div>
                  <Icon title="add filter" icon="add-circle-outline" size={2} />
                </div>
              }
            >
              <div>{isAddFilterDisabled ? "Complete your previous filter before adding another" : "Add a filter"}</div>
            </Tooltip2>
          </Button>
        </div>
      </div>
    </div>
  );
};

LogicGroup.propTypes = {
  groupFilters: PropTypes.object.isRequired,
  logicOperators: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default LogicGroup;
