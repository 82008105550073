module.exports = do ->
   EverTrue = require("app")
   {createSource} = require("@evertrue/et-flux")
   _ = require("underscore").default
   Api = require("entities/helpers/api")
   moment = require("moment")
   ErrorLogger = require("entities/helpers/error-log-helper")
   ExportTypeConfig = require("apps/export/export-type-config").default

   createSource "ScheduledExportSource",
      actions:
         fetchedExports: (resp) ->
            @require _.isArray(resp), "resp should be array"
         cancelScheduledExport: (id, exportType) ->
            @require _.isString(id), "id should be string"
            @require _.isString(exportType), "exportType should be string"
         fetchScheduled: (resp) ->
            @require _.isArray(resp), "resp should be array"

      fetch: (param) ->
         Api.CHRONOMETER.SCHEDULE.get
            urlExtend: "/#{param}"

      cancel: (id) ->
         Api.CHRONOMETER.SCHEDULE.delete
            urlExtend: "/#{id}"
            error: (err) ->
               ErrorLogger.captureRequest("Error canceling scheduled export job of id #{id}", err)

      api:
         fetchMyJobs: ->
            @fetch("my").then (res) =>
               @actions.fetchedExports(res)

         fetchOrgJobs: ->
            @fetch("organization").then (res) =>
               @actions.fetchedExports(res)

         scheduleExport: (exportType, time, timezone) ->
            time_obj = moment(time)
            hour = time_obj.format("HH")
            minute = time_obj.format("mm")

            config = ExportTypeConfig[exportType]
            job =
               jobClass: config.job
               description: config.label + " export"

            Api.CHRONOMETER.SCHEDULE.post
               data: JSON.stringify({
                  job,
                  triggers: [
                     {
                        repeatInterval: 24,
                        daysOfWeek: [
                           "EVERYDAY"
                        ],
                        startTimeOfDay: {
                           hour: hour
                           second: 0,
                           minute: minute,
                           timezone: timezone
                        },
                        jobDataMap: {timezone}
                        repeatIntervalUnit: "HOUR",
                        "@type": "daily"
                     }
               ]})
               success: (res) =>
                  EverTrue.Alert.success("Export scheduled.")
                  @actions.fetchedExports([res])

               error: (xhr) ->
                  ErrorLogger.captureRequest("Error scheduling export job of type #{exportType}", xhr)

         cancelScheduledExport: (id, exportType) ->
            @cancel(id).then =>
               @actions.cancelScheduledExport(id, exportType)
               EverTrue.Alert.success("Export canceled.")

         updateExport: (key, exportType, time, timezone) ->
            @cancel(key).then =>
               @actions.cancelScheduledExport(key, exportType)
               @scheduleExport(exportType, time, timezone)

         fetchScheduled: ->
            Api.CONTACTS.SCHEDULED.get
               success: (res) =>
                  @actions.fetchScheduled(res)
