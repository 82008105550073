module.exports = do ->
   $ = require("jquery")
   React = require("react")
   ReactDOM = require("react-dom")
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories


   createComponent "ChartResizer",
      propTypes:
         height: ReactLibs.PropTypes.number
         onResize: ReactLibs.PropTypes.func

      getInitialState: ->
         width: "100%"

      componentDidMount: ->
         $(window).resize(@handleResize)
         @handleResize()

      componentWillUnmount: ->
         $(window).off("resize", @handleResize)

      handleResize: ->
         width = $(ReactDOM.findDOMNode(@)).width()
         @setState {width: width}
         @props.onResize?(width, @props.height)

      render: ->
         div style: {width: "100%", height: @props.height},
            div style: {width: @state.width, height: @props.height},
               @props.children
