import PropTypes from "prop-types";


const CharacterCounter = ({limit, count}) => {
    let total = limit - count

    return(
        
        <div className="character-limit-container">
            {(total >= 0) 
            ? 
            <p>{total.toLocaleString("en-US")} characters remaining</p> 
            : <p className="character-limit-exceeded"> <strong>{total.toLocaleString("en-US")}</strong> characters (limit exceeded)</p>}
            
        </div>
    )
};

CharacterCounter.propTypes = {
  limit: PropTypes.number,
  count: PropTypes.number,
};

export default CharacterCounter;
