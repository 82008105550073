import PropTypes from "prop-types";
import classNames from "classnames";
import HelpTip from "components/tooltip/helptip";

const ProfileCardSubtitle = ({ label, help_key, help_position, children, className }) => {
  const renderHelpKey = () => (help_key ? <HelpTip help_key={help_key} position={help_position} /> : null);

  return (
    <div className={classNames(className, "profile-card-subtitle")} style={{ display: 'flex', alignItems: 'center' }}>
      <h1 className="profile-card-subtitle--label" style={{ fontSize: '14px', display: 'inline' }}>{label}</h1>
      {renderHelpKey()}
      <div className="profile-card-subtitle--actions">{children}</div>
    </div>
  );
};

ProfileCardSubtitle.propTypes = {
  label: PropTypes.node,
  help_key: PropTypes.string,
  help_position: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
};

export default ProfileCardSubtitle;
