import { PropTypes } from "base/react-libs";
import { LinkButton } from "@evertrue/et-components";
import ContactSummaryInteraction from "./contact-summary-interaction";
import EverTrue from "app";

const ContactSummaryInteractionListItem = ({ interaction, type }) => {
  const handleClick = () => {
    EverTrue.track.set("interaction_action", { type: "interaction", action: "view" });
    EverTrue.Navigator(`/interaction/${interaction.id}`, { trigger: true });
  };

  return (
    <LinkButton title={`Go to ${type}`} className="contact-summary-interaction-list-item" onClick={handleClick}>
      <ContactSummaryInteraction interaction={interaction} />
    </LinkButton>
  );
};

ContactSummaryInteractionListItem.propTypes = {
  interaction: PropTypes.object,
  type: PropTypes.string,
};

export default ContactSummaryInteractionListItem;
