import { useEffect, useState } from "react";
import Api from "entities/helpers/api";
import { LinkButton, Loading, ModalBody, ModalHeaderWithActions, NumericInput } from "@evertrue/et-components";
import { useGate } from "components/is-gated";
import FormField from "components/forms/form-field";
import PropTypes from "prop-types";
import classNames from "classnames";
import DNAStore from "base/dna/dna-store";
import EverTrue from "app";

const AddSolicitorGoalsModal = ({ contact = {}, close }) => {
  const [solicitorGoals, setSolicitorGoals] = useState({});
  const [loading, setLoading] = useState(false);
  const [hasDXO] = useGate("dxo");

  useEffect(() => {
    const loadGoals = async () => {
      try {
        setLoading(true);
        const result = await Api.GOALS.SOLICITOR.get({
          urlExtend: `/${contact.id}`,
          disableAlerts: true,
        });

        setSolicitorGoals(result);
        setLoading(false);
      } catch {
        setLoading(false);
      }
    };

    loadGoals();
  }, [contact.id]);

  const daysUntilEndOfFy = DNAStore.getDaysUntilFiscalYearEnd();

  return (
    <div className="solicitor-goals-form--modal">
      <ModalHeaderWithActions
        title={`${contact.name} FY Goals`}
        closeModal={close}
        onSubmit={() => {
          setLoading(true);
          const solicitorGoalToSave = {
            solicitor_contact_id: contact.id,
            interactions: solicitorGoals.interactions,
            visits_total: solicitorGoals.visits_total,
            visits_unique: solicitorGoals.visits_unique,
            proposal_ask_amount: solicitorGoals.proposal_ask_amount,
            proposal_funded_amount: solicitorGoals.proposal_funded_amount,
            proposals_total: solicitorGoals.proposals_total,
            dxo_asks: solicitorGoals.dxo_asks,
            dxo_unique_asks: solicitorGoals.dxo_unique_asks,
            dxo_substantive_asks: solicitorGoals.dxo_substantive_asks,
            dxo_substantive_unique_asks: solicitorGoals.dxo_substantive_unique_asks,
          };
          Api.GOALS.SOLICITOR.post({
            data: JSON.stringify(solicitorGoalToSave),
          })
            .then((resp) => {
              setLoading(false);
              EverTrue.Alert.success(`Success! Solicitor goal for ${contact.name} saved successfully.`);
              close();
            })
            .catch((err) => {
              setLoading(false);
              EverTrue.Alert.error(`Failure! Solicitor goal for ${contact.name} did not save.`);
            });
        }}
        disableSubmit={false}
      />
      {loading && <Loading />}
      <ModalBody scrollable={false}>
        <div className="solicitor-goals-form--row">
          <p className="solicitor-goals-form--fiscal-year-text">
            <strong>FY GOALS </strong> {`(${daysUntilEndOfFy} days left in FY)`}
          </p>
        </div>
        <div className="solicitor-goals-form--row">
          <SolicitorGoalsFormField
            label="Total Interactions"
            className="solicitor-goals-form--form-field-padding-right"
          >
            <NumericInput
              value={solicitorGoals.interactions}
              defaultValue=""
              placeholder={0}
              maskType="number"
              onChange={(interactionsTotal) => {
                setSolicitorGoals((prev) => ({ ...prev, interactions: interactionsTotal }));
              }}
            />
          </SolicitorGoalsFormField>

          <SolicitorGoalsFormField label="Total Proposals" className="solicitor-goals-form--form-field-padding-left">
            <NumericInput
              value={solicitorGoals.proposals_total}
              defaultValue=""
              placeholder={0}
              maskType="number"
              onChange={(proposalsTotal) => {
                setSolicitorGoals((prev) => ({ ...prev, proposals_total: proposalsTotal }));
              }}
            />
          </SolicitorGoalsFormField>
        </div>
        <div className="solicitor-goals-form--row">
          <SolicitorGoalsFormField label="Total Visits" className="solicitor-goals-form--form-field-padding-right">
            <NumericInput
              value={solicitorGoals.visits_total}
              defaultValue=""
              placeholder={0}
              maskType="number"
              onChange={(visitsTotal) => {
                setSolicitorGoals((prev) => ({ ...prev, visits_total: visitsTotal }));
              }}
            />
          </SolicitorGoalsFormField>

          <SolicitorGoalsFormField label="Unique Visits" className="solicitor-goals-form--form-field-padding-left">
            <NumericInput
              value={solicitorGoals.visits_unique}
              defaultValue=""
              placeholder={0}
              maskType="number"
              onChange={(visitsUnique) => {
                setSolicitorGoals((prev) => ({ ...prev, visits_unique: visitsUnique }));
              }}
            />
          </SolicitorGoalsFormField>
        </div>
        <div className="solicitor-goals-form--row">
          <SolicitorGoalsFormField
            label="Proposal Funded Amounts"
            className="solicitor-goals-form--form-field-padding-right"
          >
            <NumericInput
              value={solicitorGoals.proposal_funded_amount}
              defaultValue=""
              placeholder={0}
              maskType="currency"
              onChange={(proposalFundedAmount) => {
                setSolicitorGoals((prev) => ({ ...prev, proposal_funded_amount: proposalFundedAmount }));
              }}
              showIcon={true}
            />
          </SolicitorGoalsFormField>

          <SolicitorGoalsFormField
            label="Proposal Ask Amounts"
            className="solicitor-goals-form--form-field-padding-left"
          >
            <NumericInput
              value={solicitorGoals.proposal_ask_amount}
              defaultValue=""
              placeholder={0}
              maskType="currency"
              onChange={(proposalAskAmount) => {
                setSolicitorGoals((prev) => ({ ...prev, proposal_ask_amount: proposalAskAmount }));
              }}
              showIcon={true}
            />
          </SolicitorGoalsFormField>
        </div>

        {hasDXO && (
          <>
            <div className="solicitor-goals-form--row">
              <SolicitorGoalsFormField label="DXO Asks" className="solicitor-goals-form--form-field-padding-right">
                <NumericInput
                  value={solicitorGoals.dxo_asks}
                  defaultValue=""
                  placeholder={0}
                  maskType="number"
                  onChange={(dxoAsks) => {
                    setSolicitorGoals((prev) => ({ ...prev, dxo_asks: dxoAsks }));
                  }}
                />
              </SolicitorGoalsFormField>

              <SolicitorGoalsFormField
                label="DXO Unique Asks"
                className="solicitor-goals-form--form-field-padding-left"
              >
                <NumericInput
                  value={solicitorGoals.dxo_unique_asks}
                  defaultValue=""
                  placeholder={0}
                  maskType="number"
                  onChange={(dxoUniqueAsks) => {
                    setSolicitorGoals((prev) => ({ ...prev, dxo_unique_asks: dxoUniqueAsks }));
                  }}
                />
              </SolicitorGoalsFormField>
            </div>

            <div className="solicitor-goals-form--row">
              <SolicitorGoalsFormField
                label="DXO Substantive Asks"
                className="solicitor-goals-form--form-field-padding-right"
              >
                <NumericInput
                  value={solicitorGoals.dxo_substantive_asks}
                  defaultValue=""
                  placeholder={0}
                  maskType="number"
                  onChange={(dxoSubstantiveAsks) => {
                    setSolicitorGoals((prev) => ({ ...prev, dxo_substantive_asks: dxoSubstantiveAsks }));
                  }}
                />
              </SolicitorGoalsFormField>

              <SolicitorGoalsFormField
                label="DXO Substantive Unique Asks"
                className="solicitor-goals-form--form-field-padding-left"
              >
                <NumericInput
                  value={solicitorGoals.dxo_substantive_unique_asks}
                  defaultValue=""
                  placeholder={0}
                  maskType="number"
                  onChange={(dxoSubstantiveUniqueAsks) => {
                    setSolicitorGoals((prev) => ({ ...prev, dxo_substantive_unique_asks: dxoSubstantiveUniqueAsks }));
                  }}
                />
              </SolicitorGoalsFormField>
            </div>
          </>
        )}

        <LinkButton
          className="solicitor-goals-form--clear-all"
          onClick={() => {
            setSolicitorGoals({
              interactions: "",
              visits_total: "",
              visits_unique: "",
              proposal_ask_amount: "",
              proposal_funded_amount: "",
              proposals_total: "",
              dxo_asks: "",
              dxo_unique_asks: "",
              dxo_substantive_asks: "",
              dxo_substantive_unique_asks: "",
            });
          }}
          title="Clear All"
        >
          Clear All
        </LinkButton>
      </ModalBody>
    </div>
  );
};

export const SolicitorGoalsFormField = ({ className, ...rest }) => (
  <FormField inlineLabel={false} className={classNames("solicitor-goals-form--form-field", className)} {...rest} />
);

SolicitorGoalsFormField.propTypes = {
  className: PropTypes.string,
};

AddSolicitorGoalsModal.propTypes = {
  close: PropTypes.func.isRequired,
  contact: PropTypes.object,
};

export default AddSolicitorGoalsModal;
