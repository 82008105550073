import { useState, useRef } from "react";
import PropTypes from "prop-types";
import EverTrue from "app";
import DefaultPhoto from "images/et_avatar_user_120.png";
import { Button } from "@evertrue/et-components";
import API from "entities/helpers/api";
import _ from "underscore";
import SessionSource from "base/session/session-source";

const PhotoSettings = ({ org_id, contact_id, user_photo, affiliation_id }) => {
  const [file_selected, setFileSelected] = useState(null);
  const [displayable_file, setDisplayableFile] = useState(user_photo || DefaultPhoto);
  const fileUploadInput = useRef(null);

  const handleSetFileUpload = (e) => {
    // NOTE: will keep this here incase a size restriction is desired.
    // if (e.target.files[0].size > 2097152) {
    //   return EverTrue.Alert.error("Selected photo is too large. Please selected one smaller than 2MB");
    // }
    setFileSelected(e.target.files[0]);
    setDisplayableFile(URL.createObjectURL(e.target.files[0]));
  };

  const handleCancel = () => {
    setFileSelected(null);
    if (file_selected) {
      setDisplayableFile(user_photo);
    } else {
      setDisplayableFile(DefaultPhoto);
    }
  };

  const getBase64 = (file, _) => {
    if (file === undefined || file === null) {
      return Promise.resolve();
    }

    return new Promise(function (resolve, reject) {
      const reader = new FileReader();
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  //naming convention for the file: {oid}{contact_id}{current_unix_timestamp}.{format}
  const handleSaveFileUpload = () => {
    getBase64(file_selected).then(function (base64String) {
      let data;
      if (base64String === undefined || base64String === null) {
        data = { photo: null };
      } else {
        data = _.compactObject({ photo: base64String });
      }

      API.AUTH.PHOTO.post({
        contentType: false,
        processData: false,
        headers: {
          "Content-Type": "application/json",
        },
        urlArgs: { affiliation_id },
        data: _.jsonStringify(data),
      }).then((resp) => {
        EverTrue.Alert.success("Photo updated successfully");
        SessionSource.updatePhoto(resp.photo.url);
      });
    });
  };

  const disableUpload = user_photo === displayable_file;

  return (
    <div className="profile-photo-uploader--body">
      <div className="photo-overlay" onClick={handleCancel}>
        <img src={displayable_file} alt="user_photo" />
        <div className="hover-remove">Remove</div>
      </div>
      <div className="flex-photo-controls">
        <label className="profile-photo-uploader--label" htmlFor="hidden-photo-selector" tab-index={0}>
          <input
            ref={fileUploadInput}
            id="hidden-photo-selector"
            className="upload-select"
            type="file"
            onChange={handleSetFileUpload}
            accept="image/*"
          />
          <Button type="secondary" onClick={() => fileUploadInput.current.click()}>
            Select a Photo
          </Button>
        </label>
        <div className="upload-button">
          <Button disabled={disableUpload} className="upload-button" onClick={handleSaveFileUpload}>
            Update Photo
          </Button>
        </div>
      </div>
    </div>
  );
};

PhotoSettings.propTypes = {
  org_id: PropTypes.number,
  contact_id: PropTypes.number,
  user_photo: PropTypes.string,
  affiliation_id: PropTypes.number,
};

export default PhotoSettings;
