import _ from "underscore";
import { useContext, useEffect } from "react";
import { Checkbox, Icon, LinkButton } from "@evertrue/et-components";
import PropTypes from "prop-types";
import Loading from "components/elements/loading";
import ProspectCard from "apps/portfolio-performance/components/prospect-card";
import { DNDList } from "components/controls/dnd/dnd-controller";
import { useGate } from "components/is-gated";
import { useIdentity } from "base/identity-resolver";
import PortfolioMetaDataContext from "apps/portfolio-performance/contexts/portfolio-meta-data-context";
import ContactAssignmentContext from "apps/portfolio-performance/contexts/contact-assignment-context";
import TimeInStage from "apps/portfolio-performance/components/time-in-stage";
import ProspectColumnEmptyState from "apps/portfolio-performance/components/prospect-column-empty-state";
import SelectedContactsSource from "apps/contact/sources/selected-contacts-source";

const ProspectStageColumn = ({
  stage,
  team = {},
  portfolioHasProspects = false,
  selected_all = false,
  selected_ids = [],
  onSelectColumn = _.noop,
  onSelectContact = _.noop,
  onSetLoading,
  avg_time,
}) => {
  const { solicitor, sort_prop, sort_reverse, tempDisableDrag } = useContext(PortfolioMetaDataContext);
  const {
    fetchContactsByStage,
    fetchMoreContactsByStage,
    prospects_with_assignments_by_stage,
    loading_by_stage,
    total_by_stage,
  } = useContext(ContactAssignmentContext);

  const prospects_with_assignments = prospects_with_assignments_by_stage[stage] || [];
  const loading = loading_by_stage[stage];
  const total = total_by_stage[stage];
  const initial_loading = !prospects_with_assignments.length && loading;
  const loading_more = prospects_with_assignments.length && loading;
  const contact_ids = _.pluck(prospects_with_assignments, "id");
  const partial_selection = !selected_all && !_.isEmpty(selected_ids);
  const has_more_to_load = !_.isEmpty(prospects_with_assignments) ? total > prospects_with_assignments.length : false;

  // Load the column initially
  useEffect(() => {
    fetchContactsByStage({ pool_id: team.id, solicitor_id: solicitor.id, sort_prop, sort_reverse, stage, offset: 0 });
    // eslint-disable-next-line
  }, [solicitor.id, sort_prop, sort_reverse]);

  const loadMore = () => {
    const num_loaded = _.isEmpty(prospects_with_assignments) ? 0 : prospects_with_assignments.length;
    fetchMoreContactsByStage({
      pool_id: team.id,
      solicitor_id: solicitor.id,
      sort_prop,
      sort_reverse,
      stage,
      offset: num_loaded,
    });
  };

  // check which features are enabled
  const [stageWritesEnabled] = useGate("rm_portfolio_stage_writes");
  const { isOwner, isSuperUser, affiliation } = useIdentity();
  const user_contact_id = affiliation ? affiliation.contact_id : null;
  const isCurrentSolicitor = solicitor.id === user_contact_id;

  // tempDisableDrag is being used to disable dragging when the filters popover is open
  const can_drag_n_drop = (isOwner || isSuperUser || (stageWritesEnabled && isCurrentSolicitor)) && !tempDisableDrag;

  const draggable_items = _.map(prospects_with_assignments, (prospect_assignment = {}, i) => {
    const contact = prospect_assignment.contact || {};
    const assignment = prospect_assignment.assignment || {};

    return {
      key: JSON.stringify(prospect_assignment.id),
      component: (
        <ProspectCard
          team={team}
          key={prospect_assignment.id || _.randomKey()}
          contact={contact}
          lastInteractionDate={prospect_assignment.lastInteractionDate}
          assignment={assignment}
          selected={selected_all || _.contains(selected_ids, contact.id)}
          onSelectContact={(contact_id) => {
            onSelectContact(contact_id, stage, contact_ids, total);
          }}
          onSetLoading={onSetLoading}
          stage={stage}
        />
      ),
    };
  });

  return (
    <div className="portfolio-performance--column-outer">
      <TimeInStage portfolioHasProspects={portfolioHasProspects} avgTime={avg_time} />
      <div className="portfolio-performance--column-inner">
        <div className="portfolio-performance--column-header">
          <Checkbox
            label="Select all prospects"
            disabled={!total}
            checked={!!total && (selected_all || partial_selection)}
            partial={partial_selection}
            onChange={(select) => {
              SelectedContactsSource.toggleSelection(contact_ids, select)
              onSelectColumn(select, stage, total)
            }
            }
          />
          <span className="portfolio-performance--column-header-text">{stage}</span>
          {!!total && <span className="portfolio-performance--column-total-text">{` (${total} ${total === 1 ? 'PROSPECT' : 'PROSPECTS'})`}</span>}
        </div>

        <div className="portfolio-performance--column-cards">
          {initial_loading && <Loading />}
          {!initial_loading && (
            <DNDList
              className="portfolio-performance--dnd-list"
              id={stage}
              list={{
                disabled: !can_drag_n_drop,
                items: draggable_items,
                notSortable: true,
                grouped: false,
                non_draggable_items: has_more_to_load
                  ? [
                    {
                      key: "load_more",
                      component: (
                        <LinkButton
                          title="load more"
                          key={_.randomKey()}
                          className="portfolio-performance--load-more"
                          onClick={loadMore}
                        >
                          {loading_more ? <Icon icon="spinner" spin /> : "Load more"}
                        </LinkButton>
                      ),
                    },
                  ]
                  : [],
                emptyStateComponent: (
                  <ProspectColumnEmptyState
                    portfolioHasProspects={portfolioHasProspects}
                    team={team}
                    dnd_enabled={can_drag_n_drop}
                    stage={stage}
                    solicitor={solicitor}
                    refreshData={(stage) => fetchContactsByStage({ stage: stage })}
                  />
                ),
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

ProspectStageColumn.propTypes = {
  stage: PropTypes.string,
  team: PropTypes.object,
  portfolioHasProspects: PropTypes.bool,
  selected_all: PropTypes.bool,
  selected_ids: PropTypes.array,
  onSelectColumn: PropTypes.func,
  onSelectContact: PropTypes.func,
  onSetLoading: PropTypes.func,
  avg_time: PropTypes.number,
};
export default ProspectStageColumn;
