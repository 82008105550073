const uiComponents = {
  FilterValueMultiSelect: "FilterValueMultiSelect",
  FilterValueSelect: "FilterValueSelect",
  SearchInput: "SearchInput",
  NumberRange: "NumberRange",
  YearRange: "YearRange",
  NumericInput: "NumericInput",
  YearInput: "YearInput",
  CalendarDropdown: "CalendarDropdown",
  RollingDateInput: "RollingDateInput",
  CalendarPickerDropdown: "CalendarPickerDropdown",
  BooleanRadio: "BooleanRadio",
};

export default uiComponents;
