module.exports = do ->
   React = require("react")
   {tr, pre, div, tbody, td, table} = ReactLibs.DOMFactories
   _ = require("underscore").default
   {createComponent} = require("@evertrue/et-flux")
   {createFactory} = require("base/new-utils")
   Module = createFactory require("components/module").default
   CompanyGiving = require("apps/companies/components/company-giving")
   CompanyParticipation = require("apps/companies/components/company-participation")
   LoadingMixin = require("mixins/loading-mixin")
   Datalist = createFactory require("components/elements/datalist")
   Link = createFactory(require("@evertrue/et-components").Link)

   createComponent "CompanyProfileBody",
      mixins: [LoadingMixin]
      propTypes:
         data: ReactLibs.PropTypes.object
         type: ReactLibs.PropTypes.string
         stats: ReactLibs.PropTypes.object

      industries: ->
         industries = _.pluck @props.data?.industries, "name"
         industries.join(", ")

      specialties: ->
         specialties = @props.data?.specialties || []
         specialties?.join(", ")

      locations: ->
         _.compact _.map @props.data?.locations, (location) ->
            address = location.address
            return unless address
            "#{address.street_1}, #{address.city}, #{address.state} #{address.postal_code}"

      nameOf: (prop) ->
         @props.data?[prop]?.name

      helpKey: (type) ->
         page_type = @props.type
         switch type
            when "giving" then (page_type + "_giving_summary")
            when "participation" then (page_type + "_participation")

      render: ->
         company = @props.data || {}
         stats = @props.stats || {}
         companies = @props.data?.companies

         div className: "company-profile-body",
            Module
               header: "Giving Summary"
               help_key: @helpKey("giving")

               div className: "loading-container",
                  @loading(_.isEmpty(stats) || _.isEmpty(stats.giving_stats))
                  CompanyGiving
                     data: stats?.giving_stats

            Module
               header: "Participation"
               help_key: @helpKey("participation")

               div className: "loading-container",
                  @loading(_.isEmpty(stats) || _.isEmpty(stats.employee_participation))
                  CompanyParticipation
                     data: stats?.employee_participation

            if companies
               Module
                  header: "Popular Companies"
                  hidden: _.isEmpty(companies)
                  help_key: "popular_companies"
                  table null,
                     tbody null,
                        _.map companies[0..24], (company, index) ->
                           tr key: company.value,
                              td null,
                                 "#{index + 1}. "
                                 Link href: "company?name=" + encodeURIComponent(company.value), "data-refer": "industry", title: company.value,
                                    " #{company.value}"


