$ = require("jquery")
_ = require("underscore").default
EverTrue = require("app")
Config = require("config/env")

ErrorLogHelper =
   send: (message, data={}) ->
      if !(data.tags || data.extra)
         data = {extra: data}

      additional_data = $.extend true, {
         tags:
            oid: EverTrue.store.org.get("id")
         extra:
            user_roles: _.pluck(EverTrue.store.user.getRoles(), "name")
      }, data
      if Raven?.isSetup?() && Config.getEnv() == "production" # only send sentries in prod
         Raven.captureMessage(message, additional_data)
      else if !Config.isTest()
         console.warn("(Sentry Error) - #{message}", additional_data)

   error: (message, data) ->
      @send(message, data)

   warn: (message, data) ->
      if !(data.tags || data.extra)
         data = {extra: data}
      @send(message, _.extend({level: "warning"}, data))

   captureRequest: (message, xhr, meta_data={}) ->
      if meta_data.response
         console.warn "meta_data cannot contain 'response' property", meta_data

      @warn "#{message}",
         tags:
            status_code: xhr?.status
            status_text: xhr?.statusText
         extra: _.extend {}, meta_data,
            response: xhr?.responseJSON

module.exports = ErrorLogHelper
