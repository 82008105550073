module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")
   Decorator = require("clientDecorator")
   ExportFieldStore = require("apps/export/stores/export-field-store")

   _xhr = undefined
   _initialized = false
   _cache = []
   _getFromCache = (data) ->
      _.findWhere _cache, {data: _.jsonStringify(data)}

   createSource "ContactSource",
      actions:
         query: true
         exportStarted: true
         initQuery: true
         reset: true
         loadingContacts: (isLoading) ->
            @require _.isBoolean(isLoading), "is loading "
         searchedContacts: (contacts) ->
            @require _.isPagedObject(contacts), "contacts should be paged object"

      init: ->
         return if _initialized
         _initialized = true
         EverTrue.vent.on "org:initialized", ->
            _cache = []

      api:
         search: (data) ->
            _xhr?.abort?()
            @init()
            @actions.loadingContacts(true)

            if results = _getFromCache(data)?.results
               _.defer =>
                  @actions.searchedContacts(results)
                  @actions.loadingContacts(false)
               return

            _cache.push {data: _.jsonStringify(data)}

            _xhr = Api.CONTACTS.SEARCH.post
               data: _.jsonStringify(data?.query)
               params: data.params
               error: =>
                  @actions.loadingContacts(false)
                  @actions.searchedContacts({limit: data.params?.limit, total: 0, offset: 0, items: []})
               success: (resp) =>
                  resp.items = _.map resp.items, Decorator.Contacts.parse

                  cached = _getFromCache(data) || {}
                  cached.results = resp

                  @actions.searchedContacts(resp)
                  @actions.loadingContacts(false)

                  # Throttle the same requests from being run too frequently
                  # After 30sec, clear from the cache to clear memory
                  # otherwise this could build up for awhile and potentially cause issues
                  _.delay ->
                     _cache = _.without(_cache, cached)
                  , 30000

         export: (property_ids, data) ->
            exportParams = ExportFieldStore.getExportParams(property_ids);

            Api.CONTACTS.EXPORTS.post
               params: exportParams
               data: _.jsonStringify(data)
               disableAlerts: true
               success: =>
                  @actions.exportStarted()

         query: ->
            @actions.query()

         initQuery: ->
            @actions.initQuery()

         loading: (is_loading=true) ->
            @actions.loadingContacts(is_loading)

         reset: ->
            @actions.reset()
