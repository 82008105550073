module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   AuthApi = require("entities/auth/auth-api")
   Org = require("entities/auth/org")
   PaginatedCollectionMixin = require("mixins/pagination-mixin")


   class Orgs extends AuthApi.Collection
      @include [PaginatedCollectionMixin]
      urlRoot: "auth/organizations"
      model: Org

      parse: (data) ->
         @setCollectionWrapper(data)
         @unfiltered = data
         data

      getPage: ->
         _.map @slice(@offset, @offset + @limit), (model) ->
            model.toJSON()

      setCollectionWrapper: (data) ->
         @boundaries
            total: data.length
            limit: @limit
            offset: @offset

      setFiltered: (filtered, options) ->
         @setPage(1)
         @setCollectionWrapper(filtered)
         @set(filtered, options)

      unsetFiltered: (options) ->
         @setFiltered(@unfiltered, options)
