import { useGate } from "components/is-gated";
import { PropTypes } from "base/react-libs";
import { useIdentity } from "base/identity-resolver";
import { fetchJourneyDetails } from "../utils/api-requests/journey-details";
import { useEffect, useState } from "react";
import { useTasksNotificationContext } from "apps/tasks/hooks/tasks-notification-context.js";
import JourneyDetail from "../components/detail/journey-detail";

// THIS IS THE ONLY STATEFUL COMPONENT. EVERYTHING BENEATH IT RECEIVES PROPS.
const today = new Date();
const STEP_TASK_STATUSES = ["IN_PROGRESS", "COMPLETED"];
const CONSTITUENTS_TASK_STATUSES = ["IN_PROGRESS", "PAUSED"];

const JourneyDetailController = ({ journeyId }) => {
  const { user } = useIdentity();
  const { getAndSetGlobalTasksDueCount } = useTasksNotificationContext();
  const [journey, setJourney] = useState({});
  const [userIsCreator, setUserIsCreator] = useState(false);
  const [loadingJourney, setLoadingJourney] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null); // for right-side panel view
  const [selectedDueDate, setSelectedDueDate] = useState(today.getTime());
  const [hasUnreleasedFeature] = useGate("unreleased_feature");

  useEffect(() => {
    const getAndSetJourney = async () => {
      try {
        setLoadingJourney(true);
        const journeyFromGql = await fetchJourneyDetails(
          Number(journeyId),
          user.id,
          STEP_TASK_STATUSES,
          CONSTITUENTS_TASK_STATUSES,
          selectedDueDate
        );
        setUserIsCreator(user.id === journeyFromGql.creator_user_id && hasUnreleasedFeature); // TODO: Remove the unreleased feature logic
        setJourney(journeyFromGql);
      } finally {
        setLoadingJourney(false);
      }
    };

    getAndSetJourney();
    setSelectedContact(null);
  }, [journeyId, selectedDueDate, user.id, hasUnreleasedFeature]);

  // STATE MANAGEMENT FUNCTIONS

  // Tasks
  const handleCompletionToggle = (step, task, completed_at) => {
    task.completed_at = completed_at;
    handleUpdateTask(task, step);
    getAndSetGlobalTasksDueCount();
  };

  const handleRemoveTask = (task, step) => {
    const journeyCopy = { ...journey };
    const { steps } = journeyCopy;
    const stepCopy = steps.find((s) => s.id === step.id); // find correct step
    const replaceStepIndex = steps.findIndex((s) => s.id === step.id); // this is the step to update in-place
    const { tasks } = stepCopy;
    const filtered = tasks.filter((t) => !(t.id === task.id));
    stepCopy.tasks = filtered;
    steps[replaceStepIndex] = stepCopy;
    journeyCopy.steps = steps;
    getAndSetGlobalTasksDueCount();
    setJourney(journeyCopy);
  };

  const handleUpdateTask = (updatedTask, step) => {
    const journeyCopy = { ...journey };
    const { steps } = journeyCopy;
    const stepCopy = steps.find((j) => j.id === step.id); // find correct step
    const replaceStepIndex = steps.findIndex((s) => s.id === step.id); // this is the step to update in-place
    const { tasks } = stepCopy;
    const replaceTaskIndex = tasks.findIndex((t) => t.id === updatedTask.id); // this is the task to update in-place
    tasks[replaceTaskIndex] = updatedTask;
    stepCopy.tasks = tasks;
    steps[replaceStepIndex] = stepCopy;
    journeyCopy.steps = steps;
    setJourney(journeyCopy);
    getAndSetGlobalTasksDueCount();
  };

  const handleRemoveContact = (contactIdToRemove) => {
    // Get Initial Values
    const journeyCopy = { ...journey };
    const {
      steps = [],
      active_contact_journeys_paginated: paginatedContactJourneys = { items: [], count: 0 },
      active_contact_journeys_paginated_by_user: paginatedContactJourneysByUser = { items: [], count: 0 },
    } = journeyCopy;

    // Filter out Contact
    const filteredSteps = steps.map((step) => {
      const { tasks = [] } = step;
      const filtered = tasks.filter(({ contact }) => !(contact.id === contactIdToRemove));
      step.tasks = filtered;
      return step;
    });
    const filteredContactJourneys = {
      items:
        paginatedContactJourneys.items && Array.isArray(paginatedContactJourneys.items)
          ? paginatedContactJourneys.items.filter(({ contact_id }) => !(contact_id === contactIdToRemove))
          : [],
      count: paginatedContactJourneys.count - 1 || 0,
    };
    const filteredContactJourneysByUser = {
      items:
        paginatedContactJourneysByUser.items && Array.isArray(paginatedContactJourneysByUser.items)
          ? paginatedContactJourneysByUser.items.filter(({ contact_id }) => !(contact_id === contactIdToRemove))
          : [],
      count: paginatedContactJourneysByUser.count - 1 || 0,
    };

    // modify in place
    journeyCopy.steps = filteredSteps;
    journeyCopy.active_contact_journeys_paginated = filteredContactJourneys;
    journeyCopy.active_contact_journeys_paginated_by_user = filteredContactJourneysByUser;

    // update state
    setJourney(journeyCopy);
    getAndSetGlobalTasksDueCount();
    setSelectedContact(null);
  };

  // Steps
  const createStep = (journeyTask) => {
    const stepsCopy = [...journey.steps];
    const journeyCopy = { ...journey };
    stepsCopy.push(journeyTask);
    journeyCopy.steps = stepsCopy.sort((a, b) => a.step_number - b.step_number);
    setJourney(journeyCopy);
  };

  const editStep = (journeyTask) => {
    const stepsCopy = [...journey.steps];
    const journeyCopy = { ...journey };
    const indexToReplce = stepsCopy.findIndex((j) => j.id === journeyTask.id);
    const existingJourneyTask = stepsCopy[indexToReplce];
    journeyTask.tasks = existingJourneyTask.tasks || [];
    stepsCopy[indexToReplce] = journeyTask;
    journeyCopy.steps = stepsCopy.sort((a, b) => a.step_number - b.step_number);
    setJourney(journeyCopy);
  };

  const deleteStep = (journeyTask) => {
    const stepsCopy = [...journey.steps];
    const journeyCopy = { ...journey };
    const indexToDelete = stepsCopy.findIndex((j) => j.id === journeyTask.id);
    stepsCopy.splice(indexToDelete, 1);
    journeyCopy.steps = stepsCopy;
    setJourney(journeyCopy);
  };

  // Contact Journeys ("Active Constituents")
  const loadMoreActiveConstituents = (dataForNextPage = {}) => {
    const { userContacts = { items: [] }, allContacts = { items: [] } } = dataForNextPage;

    const journeyCopy = { ...journey };
    const {
      active_contact_journeys_paginated_by_user: userContactsCurrent,
      active_contact_journeys_paginated: allContactsCurrent,
    } = journeyCopy;

    // add new page to user-specific field
    journeyCopy.active_contact_journeys_paginated_by_user = {
      items: [...userContactsCurrent.items, ...userContacts.items],
      count: userContacts.count,
    };

    // add new page to all contacts field
    journeyCopy.active_contact_journeys_paginated = {
      items: [...allContactsCurrent.items, ...allContacts.items],
      count: allContacts.count,
    };

    // update state
    setJourney(journeyCopy);
  };

  // Journey
  const editJourney = (updatedJourney) => {
    const journeyCopy = { ...journey };
    setJourney({ ...journeyCopy, ...updatedJourney });
  };

  // We pass this function as a prop to the table
  // it is used by the modals to update the state of the table
  // we flag it as optional, which allows us to use the modals elsewhere
  // without needing to worry about managing the state of a component.
  // All state management should pass through this table; we should not pass dozens of functions as props. just this one.
  const optionalStateManagementFunction = (type = "", args = {}) => {
    switch (type) {
      case "removeContact":
        handleRemoveContact(args.contactIdToRemove);
        break;
      case "removeTask":
        handleRemoveTask(args.task, args.step);
        break;
      case "updateTask":
        handleUpdateTask(args.task, args.step);
        break;
      case "completionToggle":
        handleCompletionToggle(args.step, args.task, args.completed_at);
        break;
      case "createStep":
        createStep(args.journeyTask);
        break;
      case "editStep":
        editStep(args.journeyTask);
        break;
      case "deleteStep":
        deleteStep(args.journeyTask);
        break;
      case "editJourney":
        editJourney(args.journey);
        break;
      case "loadMoreActiveConstituents":
        loadMoreActiveConstituents(args);
        break;
      default:
        break;
    }
  };

  return (
    <JourneyDetail
      user={user}
      journey={journey}
      userIsCreator={userIsCreator}
      loadingJourney={loadingJourney}
      selectedContact={selectedContact}
      setSelectedContact={setSelectedContact}
      selectedDueDate={selectedDueDate}
      setSelectedDueDate={setSelectedDueDate}
      optionalStateManagementFunction={optionalStateManagementFunction}
    />
  );
};

JourneyDetailController.propTypes = {
  journeyId: PropTypes.string.isRequired,
};

export default JourneyDetailController;
