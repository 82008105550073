import { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import Api from "entities/helpers/api";
import { Loading } from "@evertrue/et-components";
import { useDashboard } from "apps/dashboard/hooks/use-user-dashboard";
import DashboardMetaDataContext from "../contexts/dashboard-meta-data-provider";
import UserWidgets from "apps/dashboard/components/user-widgets";
import UserErrorMessage from "apps/dashboard/components/user-error-message";

const UserDashboardWidgets = ({ poolId, poolIds, canReorder = false, cancelReorder = () => {} }) => {
  const { loading_users, user_id, user_contact_id } = useContext(DashboardMetaDataContext);
  const { widgets, loading: loadingWidgets } = useDashboard(user_id);
  const [loadingSolicitor, setLoadingSolicitor] = useState(true);
  const [isSolicitor, setIsSolicitor] = useState(true);
  const isWidgetsEmpty = Object.keys(widgets).length === 0;
  const isLoading = loading_users || loadingSolicitor || loadingWidgets;
  const gqlVariables = {
    id: user_contact_id,
    userId: user_id,
    poolId: poolId,
    poolIds: poolIds,
  };
  const getErrorMessage = () => {
    if (!user_contact_id) {
      return "Want to try Launchpad? Please contact your administrator to request access!";
    } else if (!isSolicitor) {
      return "Your user has no assignments, please contact your admin to configure your user to enable Portfolio & Performance, and the Launchpad";
    } else if (isWidgetsEmpty) {
      return "No widgets were found";
    }

    return null;
  };
  const errorMessage = getErrorMessage();

  useEffect(() => {
    setLoadingSolicitor(true);
    if (user_contact_id) {
      Api.ASSIGNMENTS.SEARCH.post({
        params: { limit: 0 },
        data: JSON.stringify({
          must: [{ parent_role: { match: "prospect" } }, { solicitor_contact_id: { match: user_contact_id } }],
        }),
      })
        .then((resp = {}) => {
          if (resp.total === 0) {
            setIsSolicitor(false);
          } else {
            setIsSolicitor(true);
          }
          setLoadingSolicitor(false);
        })
        .catch(() => {
          setLoadingSolicitor(false);
        });
    }

    setLoadingSolicitor(false);
  }, [user_contact_id]);

  if (isLoading) {
    return <Loading className="dashboard--loading" />;
  }

  if (errorMessage) {
    return <UserErrorMessage messageText={errorMessage} />;
  }

  return (
    <UserWidgets
      widgets={widgets}
      gqlVariables={gqlVariables}
      userId={user_id}
      canReorder={canReorder}
      cancelReorder={cancelReorder}
    />
  );
};

UserDashboardWidgets.propTypes = {
  poolId: PropTypes.number,
  poolIds: PropTypes.array,
  canReorder: PropTypes.bool,
  cancelReorder: PropTypes.func,
};

export default UserDashboardWidgets;
