import PropTypes from "prop-types";
import CreatePoolFormModal from "apps/volunteers/components/create-pool-form-modal";
import EditPoolFormModal from "apps/volunteers/components/edit-pool-form-modal";

const PoolFormModal = ({ pool = {}, ...rest }) => {
  return pool.id ? <EditPoolFormModal {...rest} pool={pool} /> : <CreatePoolFormModal {...rest} pool={pool} />;
};

PoolFormModal.propTypes = {
  pool: PropTypes.object,
};
export default PoolFormModal;
