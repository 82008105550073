module.exports = do ->
   {createComponent} = require("@evertrue/et-flux")
   React = require("react")
   {div} = ReactLibs.DOMFactories
   {any, func} = ReactLibs.PropTypes
   DNAStore = require("base/dna/dna-store").default
   {createFactory} = require("base/new-utils")
   Icon = createFactory (require("components/elements/icon").default)
   moment = require("moment")
   LinkButton = createFactory(require("@evertrue/et-components").LinkButton)

   _format = (date) ->
      moment(date, "YYYY-MM-DD").format("MMMM D, YYYY")

   createComponent "FiscalGivingFilter",
      propTypes:
         value: any
         onChange: func

      registerStores: ->
         @on DNAStore, ->
            fiscal_start: DNAStore.getFiscalYearStart()
            fiscal_end: DNAStore.getFiscalYearEnd()

      componentDidMount: ->
         if @props.value is true #default value when added
            @props.onChange(gte: @state.fiscal_start)

      handleUpdate: ->
         @props.onChange(gte: @state.fiscal_start)

      render: ->
         date = @props.value?.gte

         displayText = if date then "FY Starting on " + _format(date) else "Loading..."

         old_value = date != @state.fiscal_start

         div className: "segment-filter-row--read-only inline",
            Icon icon: "credit-card"
            displayText
            if old_value
               LinkButton
                  title: "Update Fiscal Year"
                  onClick: @handleUpdate
                  " (Update FY)"
