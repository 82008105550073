module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   {createStore} = require("@evertrue/et-flux")
   PaginationUtils = require("mixins/pagination-utils")
   SortUtils = require("mixins/sort-utils")
   ListContactSource = require("apps/lists/sources/list-contact-source")
   ListStore = require("apps/lists/stores/list-store")
   MapStore = require("apps/map/stores/map-store")
   Decorator = require("clientDecorator")
   ListSource = require("apps/lists/sources/list-source")
   LIMIT = 50

   _pluralizeText = (length) ->
      if length == 1 then "Constituent" else "#{length} constituents"

   _alertSetup = (msg, list_id, undoFn) ->
      options = {actionLabel: "View List"}
      if undoFn
         options.onUndo = -> undoFn()
      if list_id && window.location.pathname != "/list/#{list_id}"
         ListContactSource.resetTablePagination()
         options.onAction = -> EverTrue.Navigator("/list/#{list_id}", true)
      EverTrue.Alert.success(msg, options)


   # TODO: This may need to be split into 2 stores for
   # List Contact Results & Profile List Membership
   createStore "ListContactStore",
      getInitialState: ->
         list_contacts: {}
         contact_membership: {}
         loading: false
         sort: SortUtils.getDefaultSort()
         page: 1

      registerActions: ->
         @on ListContactSource.actions.fetchedContacts, @respondToFetchedContacts
         @on ListContactSource.actions.fetchedSortedContacts, @respondToFetchedSorted
         @on ListContactSource.actions.loadingListContacts, @respondToLoadingListContacts
         @on ListContactSource.actions.errorListContacts, @respondToError
         @on ListContactSource.actions.exportedListContacts, @respondToExport
         @on ListContactSource.actions.filterListContacts, @respondToFilter

         @on ListContactSource.actions.fetchedForContact, @respondToFetchedForContact

         @on ListContactSource.actions.addedContacts, @respondToAdd
         @on ListContactSource.actions.removedContacts, @respondToRemove

         @on ListContactSource.actions.changedSort, @respondToSortChange
         @on ListContactSource.actions.changedPage, @respondToPageChange
         @on ListContactSource.actions.setEvent, (key) ->
            @setState {event: key}

         @on ListContactSource.actions.resetTablePagination, @respondToResetTablePagination
         
         @on ListSource.actions.createdbulkAddToList, @respondToBulkAddToListCreated
         

      respondToResetTablePagination: ->
         @setState {page: 1}
      
      respondToFetchedContacts: (list_id, contact_data) ->
         list_contacts = @getState("list_contacts")
         if _.keys(list_contacts) > 3 then list_contacts = {}

         list_contacts[list_id] = _.extend {}, contact_data,
            page: PaginationUtils.getPage(contact_data)
            items: _.map contact_data?.items, (contact) ->
               Decorator.Contacts.parse(contact)

         @setState {list_contacts: list_contacts, error: false}

      respondToFetchedSorted: (list_id, page) ->
         @setState {loading: true}
         @fetchSortedContacts(list_id, page)

      respondToLoadingListContacts: (is_loading) ->
         @setState {loading: is_loading, error: false}


      respondToError: ->
         @setState {error: true}

      respondToFetchedForContact: (contact_id, membership) ->
         contact_membership = _.clone @getState("contact_membership")
         contact_membership[contact_id] = membership?.items
         @setState {contact_membership: contact_membership}

      respondToAdd: (list_id, contacts, options={}) ->
         @fetchSortedContacts(list_id) unless options.active_contact_id

         unless options.disableAlerts
            list = ListStore.getList(list_id)
            _alertSetup "#{_pluralizeText(contacts?.length)} added to #{list?.name || "list"}", list_id, ->
               ListContactSource.removeContacts(list_id, contacts, Object.assign options, {disableAlerts: true})

         unless options.active_contact_id
            EverTrue.track.set @getState("event"),
               type: "add_to_list"
               count: contacts?.length

      respondToRemove: (list_id, contacts, options={}) ->
         @fetchSortedContacts(list_id) unless options.active_contact_id
         unless options.disableAlerts
            list = ListStore.getList(list_id)
            _alertSetup "#{_pluralizeText(contacts?.length)} removed from #{list?.name || "list"}", list_id, ->
               ListContactSource.addContacts(list_id, contacts, Object.assign options, {disableAlerts: true})

         unless options.active_contact_id
            action = @getState("event")
            EverTrue.track.set action,
               type: if action is "list_action" then "remove_contacts" else "remove_from_list"
               count: contacts?.length

      respondToExport: (list_id) ->
         EverTrue.track.set "manual_export_action", {type: "list"}

      respondToFilter: (list_id, query) ->
         @setState {query: query}
         @fetchSortedContacts(list_id, 1) if list_id

      respondToSortChange: (list_id, key, reverse, silent) ->
         sort_data = SortUtils.changeSort(key, @getState("sort"))
         sort_data.sortReverse = reverse if !_.isUndefined(reverse)

         @setState({sort: sort_data})
         @fetchSortedContacts(list_id, 1) unless silent

      respondToPageChange: (list_id, page) ->
         @setState {page:page}
         @fetchSortedContacts(list_id, page) if list_id

      fetchSortedContacts: (list_id, page) ->
         selected_list_id = ListStore.getSelectedList()?.id
         if _.toNumber(list_id) == selected_list_id || !selected_list_id
            contact_data = @getState("list_contacts")[list_id] || {}
            contact_data.limit ?= LIMIT
            contact_data.page = @getState("page") if page
            @setState {loading: true}

            ListContactSource.fetchContacts list_id,
               page: _.pick(contact_data, "page", "total", "limit")
               sort: @getSortQuery()
               query: @getState("query")
         else @setState {loading: false}

      getSortQuery: ->
         sort_object = @getState("sort")
         sort_query = SortUtils.getQueryFromObj(sort_object)
         if sort_object.sortProp == "proximity_sort"
            sort_query = [MapStore.getProximitySort(sort_object.sortReverse)]
         sort_query

      respondToBulkAddToListCreated: (list_obj, filter_params, count, isFiltersV2) ->
         ListContactSource.bulkAddToList(filter_params, list_obj, count, isFiltersV2)


      api:
         getContacts: (list_id) ->
            contact_data = @getState("list_contacts")[list_id] || {}
            contact_data.limit ?= LIMIT
            _.extend {}, contact_data, @getState("sort")

         getLoading: ->
            @getState("loading")

         getError: ->
            @getState("error")

         getMembership: (contact_id) ->
            _.filter @getState("contact_membership")?[contact_id], (membership) ->
               !!ListStore.getList(membership.id)

         getFavoriteMembership: (contact_id) ->
            favorite = ListStore.getFavoriteList()
            _.findWhere @getState("contact_membership")?[contact_id], {id: favorite.id}

         getIsBulkAddToListInProgress: ->
            @getState("bulk_add_to_list_in_progress")

         getBulkAddToListProgress: ->
            @getState("bulk_add_to_list_progress")

         getBulkAddToListObj: ->
            list_obj = @getState("bulk_add_to_list_obj")
            _.extend {}, list_obj, {list: ListStore.getList(list_obj.value)}
