module.exports = do ->
   _ = require("underscore").default
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")

   createSource "BulkListSource",
      actions:
         procesingManualContacts: true
         manualContactsSuccess: true
         manualContactsError: true
         clearManualContacts: true
         newListId: true
         newName: (name) ->
            @require(_.isString(name), "name should be a string")

         newManualInput: (text) ->
            @require(_.isString(text), "manual input text should be a string")

         newSelectedContacts: (contacts) ->
            @require(_.isArray(contacts), "selected contacts should be an array")
            @require(_.every(contacts, (contact) -> contact.id), "contacts should have an id property")
         submit: true
         editManualContacts: true
         removeHit: true
         removeMiss: true

      api:
         setListId: (id) ->
            @actions.newListId(id)

         setName: (name) ->
            @actions.newName(name)

         setSelectedContacts: (contacts) ->
            @actions.newSelectedContacts(contacts)

         setManualInput: (text) ->
            @actions.newManualInput(text)

         editManualContacts: ->
            @actions.editManualContacts()

         removeHit: (token) ->
            @actions.removeHit(token)

         removeMiss: (token) ->
            @actions.removeMiss(token)

         processManualContacts: (tokens, bulkSelector) ->
            @actions.procesingManualContacts()
            Api.CONTACTS.IDENTITY_MATCH.post
               params: { provider: bulkSelector}
               data: _.jsonStringify(tokens)
               success: (resp) =>
                  @actions.manualContactsSuccess(resp, tokens)
               error: (xhr) =>
                  @actions.manualContactsError(xhr?.responseJSON)

         submit: (bulkSelector) ->
            @actions.submit(bulkSelector)

         clear: ->
            @actions.clearManualContacts()


