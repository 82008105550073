_ = require("underscore").default
$ = require("jquery")
EverTrue = require("app")

# TODO: This would be a good candidate for some Tests

route = undefined
is_direct_route = false
history_length = undefined

# Ask user to redirect to mobile app if coming from external link on mobile
# Store answer in localStorage and automatically check next attempt
module.exports = (path, history) ->
   EverTrue.store.fetch "redirectToApp"
   do_redirect = EverTrue.store.redirectToApp

   schemas =
      appstore: "givingtree"
      beta: "givingtreebeta"
      alpha: "givingtreealpha"

   mobile_routes =
      profile: /contact\/([0-9]+)(\?)?(.)*$/
      search_diff: /searches\/activity\/([0-9]+)/
      list: /list\/([0-9]+)/
      trip: /trips\/([0-9]+)/

   isMobileRedirectRoute =  (path) ->
      _.any mobile_routes, (route_regex) ->
         !!path?.match(route_regex)

   formatRouteForMobile = (pathname) ->
      [pathname, param_data] = pathname.split("?")
      path_data = pathname.split("/")
      route_name = path_data[path_data.length - 2]

      # Attach additional params to app url
      params = if !_.isEmpty(param_data) then $.deparam(param_data) else {}
      params.id = _.last path_data
      params.sandbox = EverTrue.config.environment
      params.oid = EverTrue.store?.org?.get("id") unless params.oid
      if params.sandbox == "production" then params.sandbox = "prod"

      "#{schemas.appstore}://#{route_name}?#{$.param(params)}"

   checkOrg = (pathname) ->
      pathname = pathname || route
      return unless pathname
      param_data = $.deparam _.last(pathname.split("?"))
      same_org = true
      if param_data?.oid && EverTrue.store?.org?.get("id")
         if parseInt(param_data.oid, 10) != EverTrue.store.org.get("id")
            same_org = false
            EverTrue.Alert.confirm
               content: "Logged into wrong organization"
               type: "warning"
               headline: "Change Organization?"
            , (confirm) ->
               if confirm
                  EverTrue.execute "setPageReferrer"
                  EverTrue.execute "logout"
               else
                  EverTrue.UrlManager.unset("oid")
      same_org

   showConfirmation = ->
      EverTrue.Alert.confirm
         content: "Do you want to open this page in the app?"
         type: "success"
         headline: "Open in App?"
      , (confirm) ->
         if confirm
            EverTrue.execute "setMobileRedirect", true
            redirect()
         else
            EverTrue.execute "setMobileRedirect", false
            _.delay((-> checkOrg()), 275)

   redirect = ->
      window.location = formatRouteForMobile(route)
      route = undefined

   # If user has chosen not to redirect, Check org and return
   if do_redirect in [false, "false"]
      checkOrg(path)
      return

   # Check if Path should be redirected
   # Set route to path to redirect if it should be redirected
   if isMobileRedirectRoute(path)
      route = path
      history_length = history
      param_data = $.deparam _.last(path.split("?"))
      is_direct_route = !!param_data.referrer || (history_length <= 1)

      # If direct or referred route, check for redirect cache or confirm redirect with user
      if is_direct_route
         if _.isUndefined(do_redirect) || _.isNull(do_redirect) then showConfirmation()
         else if do_redirect then redirect()
         else checkOrg()
