import { createStore } from "@evertrue/et-flux";
import _ from "underscore";
import StageSource from "apps/assignments/sources/stage-source";
import OrgSource from "base/org/org-source";

const INITIAL_STATE = () => ({
  stage_groups: {},
  stage_type: undefined,
  fetch_stage_groups_error: false,
  group_ids_have_errored: new Set(),
  in_flight: false,
  loading: false,
});

const StageStore = createStore("StageStore", {
  getInitialState() {
    return INITIAL_STATE();
  },

  registerActions() {
    this.on(StageSource.actions.fetchedStageGroups, (stages) => {
      this.setState({ stage_groups: stages, loading: false, in_flight: false });
    });

    this.on(StageSource.actions.fetchedSingleStageGroup, (stage) => {
      this.setState({ stage_groups: { ...this.state.stage_groups, [stage.id]: stage }, loading: false });
    });

    this.on(StageSource.actions.fetchStageGroupsError, () => {
      this.setState({ fetch_stage_groups_error: true, loading: false, in_flight: false });
    });

    this.on(StageSource.actions.fetchStageGroupsUnlessCached, (type) => {
      if (
        !this.state.in_flight &&
        (_.isEmpty(this.state.stage_groups) || this.state.stage_type !== type) &&
        !this.state.fetch_stage_groups_error
      ) {
        StageSource.fetchStageGroups(type);
        this.setState({ loading: true, in_flight: true });
        this.setState({ stage_type: type });
      }
    });

    this.on(StageSource.actions.fetchGroupUnlessCached, (id) => {
      if (!this.state.stage_groups[id] && !this.state.group_ids_have_errored.has(id)) {
        this.setState({ loading: true });
        StageSource.fetchStageGroupById(id);
      }
    });

    this.on(StageSource.actions.fetchStageGroupsError, (id) => {
      const current = new Set(this.state.group_ids_have_errored);
      this.setState({ group_ids_have_errored: current.add(id), loading: false });
    });

    this.on(OrgSource.actions.newOrg, () => {
      this.setState(INITIAL_STATE());
    });
  },

  api: {
    getStageGroups() {
      return this.state.stage_groups;
    },
    getLoading() {
      return this.state.loading;
    },
  },
});

export default StageStore;
