import { Formik } from "formik";
import { AddStepSchema } from "apps/journeys/utils/helpers/add-step-schema";
import { getActionTypes } from "../../utils/api-requests/journey-details";
import { useEffect, useState } from "react";
import { Modal, ModalHeaderWithActions, ModalBody, ListboxSelect } from "@evertrue/et-components";
import { createJourneyTask, editJourneyTask } from "apps/journeys/utils/api-requests/journey-details";
import PropTypes from "prop-types";
import FormField from "components/forms/form-field";
import CharacterCounter from "apps/interactions/components/character-counter";

export const stepDefaults = (taskObj, actionTypes, stepCount) => {
  const {
    title: stepTitle = "",
    description: stepDescription = "",
    step_number: stepNumber = 0,
    day_number: dayNumber = 0,
    default_action_type: stepType = "OTHER",
  } = taskObj;

  const selectedType = stepType ? actionTypes.find((option) => option.value === stepType) : { value: "", label: "" };

  const taskDefaults = {
    stepTitle,
    stepDescription,
    stepNumber: stepNumber ? stepNumber : stepCount + 1,
    dayNumber,
    stepType: selectedType,
  };
  return taskDefaults;
};

const JourneyTaskModal = ({
  isOpen,
  onClose,
  journey,
  existingJourneyTask = {},
  optionalStateManagementFunction = () => {},
}) => {
  const [editJourneyTaskId, setEditJourneyTaskId] = useState(existingJourneyTask.id);
  const [actionTypes, setActionTypes] = useState([]);
  const { id: journeyId, steps = [] } = journey;
  const stepCount = steps.length;

  const taskDefaults = stepDefaults(existingJourneyTask, actionTypes, stepCount);

  const handleSubmit = async ({ stepDescription, dayNumber, stepNumber, stepType, stepTitle }) => {
    const payload = {
      description: stepDescription,
      day_number: Number(dayNumber),
      step_number: Number(stepNumber),
      journey_id: journeyId,
      default_action_type: stepType.value,
      title: stepTitle,
    };

    if (editJourneyTaskId) {
      const journeyTask = await editJourneyTask(payload, editJourneyTaskId);
      optionalStateManagementFunction("editStep", { journeyTask });
    } else {
      const journeyTask = await createJourneyTask(payload);
      setEditJourneyTaskId(journeyTask.id);
      optionalStateManagementFunction("createStep", { journeyTask });
    }

    onClose();
  };

  useEffect(() => {
    const getAndSetActionTypes = async () => {
      const fetchedActionedTypes = await getActionTypes();
      setActionTypes(fetchedActionedTypes);
    };
    if (isOpen) getAndSetActionTypes();
  }, [isOpen]);

  if (actionTypes && !actionTypes.length) return null;

  return (
    <Modal isOpen={isOpen} closeModal={onClose} width={568}>
      <Formik
        initialValues={taskDefaults}
        validationSchema={AddStepSchema}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
        validateOnMount={true}
      >
        {({ handleChange, handleSubmit, values, isValid, setFieldValue }) => {
          return (
            <>
              <ModalHeaderWithActions
                title={editJourneyTaskId ? "Edit Step" : "Add Step"}
                submitLabel={editJourneyTaskId ? "Update" : "Create"}
                closeModal={onClose}
                onSubmit={handleSubmit}
                disableSubmit={!isValid}
              />
              <ModalBody scrollable={false}>
                <form>
                  <span>* Indicates required field</span>

                  <FormField inlineLabel={false} label="Step Name *">
                    <input
                      type="text"
                      onChange={handleChange("stepTitle")}
                      id="cadence-title"
                      value={values.stepTitle}
                    />
                    <CharacterCounter limit={120} count={values.stepTitle ? values.stepTitle.length : 0} />
                  </FormField>
                  <FormField inlineLabel={false} label="Description" required={false}>
                    <textarea
                      style={{ minHeight: "130px" }}
                      onChange={handleChange("stepDescription")}
                      id="cadence-description"
                      value={values.stepDescription}
                    />
                    <CharacterCounter limit={250} count={values.stepDescription ? values.stepDescription.length : 0} />
                  </FormField>
                  <div style={{ display: "flex" }}>
                    <FormField inlineLabel={false} label="Step Number *" className="add-step-number">
                      <input type="text" onChange={handleChange("stepNumber")} name="name" value={values.stepNumber} />
                    </FormField>
                    <FormField inlineLabel={false} label="Day Number *">
                      <input type="text" onChange={handleChange("dayNumber")} name="name" value={values.dayNumber} />
                    </FormField>
                  </div>
                  <FormField inlineLabel={false} label="Step Type *">
                    <ListboxSelect
                      label="Step Type"
                      emptyValue="Select Type"
                      options={actionTypes}
                      onChange={(v) => {
                        setFieldValue("stepType", v);
                      }}
                      value={values.stepType}
                    />
                  </FormField>
                </form>
              </ModalBody>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

JourneyTaskModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  journey: PropTypes.object,
  existingJourneyTask: PropTypes.object,
  optionalStateManagementFunction: PropTypes.func,
};

export default JourneyTaskModal;
