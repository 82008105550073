import AiSearchHome from "../components/ai-search-home";

const AISearchHomeController = () => {
  return (
    <div className="ai-search-home">
    <AiSearchHome contactId={0}/> {/* used the same code of profile chat with contactId = 0; will be changed later based on the API of NLS */}
    </div>
  );
};

export default AISearchHomeController;