module.exports = do ->
   React = require("react")
   _ = require("underscore").default
   numeral = require("numeral")
   {createComponent} = require("@evertrue/et-flux")
   {div, span, strong} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   ProfileCardHeader = createFactory require("apps/profile/components/profile-card-header").default
   ProfileFacebookSource = require("apps/profile/sources/profile-facebook-source")
   ProfileFacebookStore = require("apps/profile/stores/profile-facebook-store")
   ProfileStore = require("apps/profile/stores/profile-store")
   ProfileEventsSource = require("apps/profile/sources/profile-events-source")
   ProfileEventsStore = require("apps/profile/stores/profile-events-store")
   AllEngagementsStore = require("apps/profile/stores/all-engagements-store")
   DNAStore = require("base/dna/dna-store").default
   FeatureStore = require("apps/layout/stores/feature-store").default
   ProfileCardSubtitle = createFactory require("apps/profile/components/profile-card-subtitle").default
   EngagementIconList = require("apps/profile/components/engagement-icon-list")
   Icon = createFactory (require("components/elements/icon").default)
   IsVolunteerResolver = createFactory require("apps/profile/components/is-volunteer-resolver").default
   UserSessionResolver = createFactory require("apps/users/components/user-session-resolver").default
   ThankViewMetricsCard = createFactory require("apps/profile/components/thankview-metrics-card").default
   Loading = require("components/elements/loading")
   moment = require("moment")

   createComponent "ProfileEngagementMetrics",
      propTypes:
         contact: ReactLibs.PropTypes.object

      getDefaultProps: ->
         contact: {}

      registerStores: ->
         @on DNAStore, ->
            has_community: DNAStore.hasCommunity()

         @on FeatureStore, ->
            has_events: FeatureStore.hasFeature("events")
            has_volunteers: FeatureStore.hasFeature("volunteers")

         @on ProfileFacebookStore, ->
            reactions: ProfileFacebookStore.getReactionsForContact(@props.contact.id)

         @on ProfileStore, ->
            session_count: ProfileStore.getCommunityUsage(@props.contact.id)
            has_fb: ProfileStore.hasFacebookMatch(@props.contact.id)

         @on ProfileEventsStore, ->
            event_count: ProfileEventsStore.getEventEngagementCount(@props.contact.id)
            event_icons: ProfileEventsStore.getEventEngagementIcons(@props.contact.id)

         @on AllEngagementsStore, ->
            schema: AllEngagementsStore.getSchema(@props.contact.id)

      componentDidMount: ->
         ProfileFacebookSource.fetchReactionStats(@props.contact.id)
         ProfileEventsSource.fetchProfileEventEngagements(@props.contact.id)

      renderFBReactions: ->
         {reactions} = @state
         if _.isEmpty(reactions)
            EngagementIconList icons: ["sad"], disabled: true
         else
            EngagementIconList icons: _.keys(reactions)

      renderNumber: (number) ->
         span className: "profile-engagement-metrics--count",
            numeral(number).format("0k")

      renderMetric: ({number, icons, caption}) ->
         div className: "profile-engagement-metrics--metric",
            div null,
               span className: "profile-engagement-metrics--count",
                  numeral(number).format("0k")
               icons
            div className: "profile-engagement-metrics--caption",
               caption

      renderSession: ({session, icons, caption}) ->
         div className: "profile-engagement-metrics--metric",
            strong null,
               if session then moment(session).format("MM/DD/YYYY [at] h:mm a") else "--"
            div className: "profile-engagement-metrics--caption",
               caption



      render: ->
         comment_count = @props.contact.facebook?.comment_count?.value || 0
         total_count = @props.contact.facebook?.total_engagement_count?.value || 0
         reaction_count = (total_count - comment_count) || 0

         engagement_score = @props.contact.giving?.engagement_score?.value

         {has_events, event_count, event_icons, has_community, has_volunteers, schema} = @state

         div className: "profile-engagement-metrics",
            ProfileCardHeader
               title: "Engagement Metrics"

            div className: "profile-engagement-metrics--wrapper",

               ProfileCardSubtitle
                  label: "Engagement Score"

               div className: "profile-engagement-metrics--content",
                  if engagement_score?
                     span className: "profile-engagement-metrics--count",
                        "" + engagement_score
                  else
                     div className: "profile-engagement-metrics--empty-message",
                        "Not Provided"

               ProfileCardSubtitle
                  label: "Facebook Posts"
                  help_key: "fb_engagements"
                  help_position: "left-top-aligned"

               div className: "profile-engagement-metrics--content",
                  div className: "profile-engagement-metrics--column is-column-1",
                     @renderMetric
                        number: reaction_count
                        icons: @renderFBReactions()
                        caption: _.plural(comment_count, "Reaction", "Reactions")

                  div className: "profile-engagement-metrics--column is-column-2",
                     @renderMetric
                        number: comment_count
                        icons: EngagementIconList icons: ["comment"], disabled: !comment_count
                        caption: _.plural(comment_count, "Comment", "Comments")

               if has_events
                  div null,
                     ProfileCardSubtitle
                        label: "Events"
                        help_key: "event_engagements"
                        help_position: "left-top-aligned"

                     div className: "profile-engagement-metrics--content",
                        @renderMetric
                           number: event_count
                           icons: EngagementIconList icons: event_icons, disabled: !event_count
                           caption: _.plural(event_count, "Event Response", "Event Responses")

               if has_community
                  div null,
                     ProfileCardSubtitle
                        label: "Alumni Community"
                        help_key: "et_usage"
                        help_position: "left-top-aligned"

                     div className: "profile-engagement-metrics--content",
                        @renderMetric
                           number: @state.session_count
                           icons: Icon icon: "mappincircle", className: "profile-engagement-metrics--icon-community"
                           caption: "Logins"


               if has_volunteers
                  IsVolunteerResolver
                     id: @props.contact?.id
                     render: ({ volunteer_user }) =>
                        if !_.isEmpty volunteer_user
                           div null,
                              ProfileCardSubtitle
                                 label: "Volunteer Engagement"
                                 help_key: "vol_usage"
                                 help_position: "left-top-aligned"

                              UserSessionResolver
                                 user_id: volunteer_user.user_id
                                 org_id: volunteer_user.oid
                                 app_name: "volunteers"
                                 render: ({ user_session, loading }) =>
                                    if loading
                                       Loading()
                                    else if !loading && !_.isEmpty user_session
                                       div className: "profile-engagement-metrics--content",
                                          div className: "profile-engagement-metrics--column is-column-1",
                                             @renderMetric
                                                number: if volunteer_user.user_id then user_session.count else 0
                                                icons: Icon icon: "person", className: "profile-engagement-metrics--icon-volunteer"
                                                caption: _.plural(user_session.count, "Login", "Logins")
                                          div className: "profile-engagement-metrics--column is-column-2",
                                             @renderSession
                                                session: if volunteer_user.user_id then user_session.last_session_time
                                                icons: Icon icon: "person", className: "profile-engagement-metrics--icon-volunteer"
                                                caption: "Last Session"
                                    else null
                        else null
               ThankViewMetricsCard
                  data: @props.contact
                  hasMetrics: "true"
                  metrics: @state.schema
