import { useContext } from "react";
import { Loading } from "@evertrue/et-components";
import { ClarityContext } from "../controllers/clarity-context";
import ClarityList from "./clarity-list";

const ClarityDashboard = () => {
  const { loading } = useContext(ClarityContext);

  return <>{loading ? <Loading className="clarity--loading" /> : <ClarityList />}</>;
};

export default ClarityDashboard;
