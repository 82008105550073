import { PropTypes } from "base/react-libs";
import PinnedCommentsFeed from "apps/profile/components/pinned-comments/pinned-comments-feed";
import ProfileCardHeader from "apps/profile/components/profile-card-header";
import AddPinnedCommentButton from "apps/profile/components/pinned-comments/add-pinned-comment-button";
import ViewAllPinnedCommentsModal from "apps/profile/components/pinned-comments/view-all-pinned-comments-modal";

const ProfilePinnedCommentsController = ({ contact }) => {
  return (
    <>
      <ProfileCardHeader title="Pinned Comments" className="contact-profile--card-header-small">
        <AddPinnedCommentButton />
      </ProfileCardHeader>
      <div className="pinned-comments-controller">
        <PinnedCommentsFeed displayLimit={3} />
      </div>  
      <ViewAllPinnedCommentsModal />
    </>
  );
};

ProfilePinnedCommentsController.propTypes = {
  contact: PropTypes.object,
};

export default ProfilePinnedCommentsController;
