module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   React = require("react")
   numeral = require("numeral")
   {i, div, span, h3, img} = ReactLibs.DOMFactories
   {createComponent} = require("@evertrue/et-flux")
   ContactFormatMixin = require("mixins/contact-format-mixin")
   LoadingMixin = require("mixins/loading-mixin")
   {createFactory} = require("base/new-utils")
   LinkButton = createFactory(require("@evertrue/et-components").LinkButton)
   Link = createFactory(require("@evertrue/et-components").Link)

   createComponent "CompanyProfileCard",
      mixins: [LoadingMixin]
      propTypes:
         matched: ReactLibs.PropTypes.number
         link: ReactLibs.PropTypes.string
         is_filters_v1: ReactLibs.PropTypes.bool

      getDefaultProps: ->
         matched: 0

      handleConstituentsClick: ->
         EverTrue.UrlManager.clear(silent: true)
         EverTrue.Navigator("/contact?#{@props.link}", true)

      industries: ->
         _.map @props.data?.industries, (industry) ->
            Link
               href: "industry" + ContactFormatMixin.industry_link(industry.name)
               title: "industry #{industry.name}"
               key: industry.name
               "data-refer": "$last"
               industry.name

      location: ->
         locations = _.first @props.data?.locations
         state = locations?.address?.state
         city = locations?.address?.city
         if city && state
            city += ", " + state
         else if !city
            city = state || ""

      render: ->
         data = @props.data || {}
         url = @props.data?.website_url
         websiteUrl = if url?.match(/^http/) then url else "http://#{url}"
         if @props.matched == 1
            matched_text = "(1 Matched Constituent)"
         else
            matched_text = "(#{numeral(@props.matched).format("0,0")} Matched Constituents)"

         div className: "company-header loading-container",
            @loading(_.isEmpty(data))

            div className: "clearfix",
               div className: "company-header--name",
                  h3 null, data.name

                  div null,
                     span null, @industries()
                     span className: "company-header--divide",
                        if data.industries && data?.locations?[0]?.address?.state then " | " else ""
                     span null, @location()

                  if @props.matched
                     div null,
                        if @props.is_filters_v1
                           LinkButton
                              className: "company-header--matched"
                              onClick: @handleConstituentsClick
                              title: "View matched constituent"
                              matched_text
                        else
                           matched_text

               div className: "company-header--links",
                  if url
                     div className: "block",
                        Link href: websiteUrl, title: "company website", target: "_blank", "data-bypass": true,
                           span null, "Visit Website"
                           i className: "fa fa-fw fa-info-circle"
