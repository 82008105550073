module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   React = require("react")
   EverTrue = require("app")
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   {div, span} = ReactLibs.DOMFactories
   ProfileEngagementMetrics = require("apps/profile/components/profile-engagement-metrics")
   FilterDrawer = require("components/controls/filter-drawer")
   FacebookFilters = require("apps/profile/components/facebook-filters")
   {createFactory} = require("base/new-utils")
   FacebookSearchSummary = createFactory require("apps/facebook/components/facebook-search-summary")
   EventSearchSummary = createFactory require("apps/events/components/event-search-summary").default
   # ThankviewSearchSummary = createFactory require("apps/profile/components/thankview-search-summary").default
   EllipsisOverflow = createFactory require("components/formatting/ellipsis-overflow")
   SortDropdown = require("components/controls/sort-dropdown")
   BackToTop = createFactory require("components/controls/back-to-top")
   UrlSource = require("apps/layout/sources/url-source")
   EmptyStateMessage = createFactory(require("components/lists/empty-state-message.coffee"))
   Loading = require("components/elements/loading")
   AllEngagementsSource = require("apps/profile/sources/all-engagements-source")
   AllEngagementsStore = require("apps/profile/stores/all-engagements-store")
   EngagementCardEvent = require("apps/profile/components/engagement-card-event")
   EngagementCardFacebook = require("apps/profile/components/engagement-card-facebook")
   EngagementCardThankview = createFactory require("apps/profile/components/thankview-engagement-card.js").default
   ProfileCard = createFactory require("apps/profile/components/profile-card").default
   LinkButton = createFactory(require("@evertrue/et-components").LinkButton)
   AdvancedCombobox = createFactory(require("@evertrue/et-components").AdvancedCombobox)
   EventsSource = require("apps/events/sources/events-source")
   EventsFilterPanel = require("apps/events/components/events-filter-panel")
   EventFilters = require("apps/profile/components/event-filters")


   ENGAGEMENT_DROPDOWN_OPTIONS = [
      { icon: "gt-icon gt-icon-facebook-circle", label: "Facebook", value: "Facebook" }
      { icon: "gt-icon gt-icon-event", label: "Event", value: "Event" }
      { icon: "tv-icon tv-icon-logo", label: "ThankView", value: "ThankView" }
   ]

   createComponent "ProfileEngagementController",
      propTypes:
         contact: ReactLibs.PropTypes.object

      getDefaultProps: ->
         contact: {}

      getInitialState: ->
         filters_open: false
         facebook_filters: {}
         event_filters: {}
         sort_reverse: true
         selected_engagement: ENGAGEMENT_DROPDOWN_OPTIONS[0]

      registerStores: ->
         @on AllEngagementsStore, ->
            facebook_engagements: AllEngagementsStore.getFacebookEngagements(@props.contact.id)
            facebook_engagement_total: AllEngagementsStore.getFacebookEngagementTotal(@props.contact.id)
            event_engagements: AllEngagementsStore.getEventEngagements(@props.contact.id)
            event_engagement_total: AllEngagementsStore.getEventEngagementTotal(@props.contact.id)
            engagements: AllEngagementsStore.getEventEngagements(@props.contact.id)
            engagement_total: AllEngagementsStore.getEngagementTotal(@props.contact.id)
            thankview_engagements: AllEngagementsStore.getThankviewEngagements(@props.contact.id)
            applied_filters: AllEngagementsStore.getAppliedFilters()
            has_more_to_load: AllEngagementsStore.hasMoreToLoad(@props.contact.id)
            sort_order: AllEngagementsStore.getSortOrder()
            loading: AllEngagementsStore.getLoading()

      componentDidMount: ->
         UrlSource.fetch("fbfilters")
         UrlSource.fetch("etfilters")
        
         if @props.contact.id
            AllEngagementsSource.fetchAllEngagements(@props.contact.id)

         @setState
            body: @refs.body
            facebook_filters: $.extend true, {}, EverTrue.UrlManager.get("fbfilters")
            event_filters: $.extend true, {}, EverTrue.UrlManager.get("etfilters")
         

      UNSAFE_componentWillReceiveProps: (newProps) ->
         if newProps.contact.id != @props.contact.id
            if newProps.contact.id
               AllEngagementsSource.fetch(newProps.contact.id)

      handleToggleFilter: (is_open) ->
         if !is_open && (!_.isEqual(@state.facebook_filters, @state.applied_filters.facebook) || !_.isEqual(@state.event_filters, @state.applied_filters.event))
            @handleApplyFilter()
         else @setState {filters_open: is_open}

      handleCancelFilter: ->
         @setState
            facebook_filters: @state.applied_filters.facebook
            event_filters: @state.applied_filters.event
            filters_open: false

      handleApplyFilter: ->
         AllEngagementsSource.applyFilters(@props.contact.id, {facebook:@state.facebook_filters, event:@state.event_filters})
         @setState {filters_open: false}

      handleResetFilter: ->
         if @state.selected_engagement.value == "Facebook"
            @setState {facebook_filters: {}}
         else if @state.selected_engagement.value == "Event"
            @setState {event_filters:{}}

      handleSort: (key, reverse) ->
         sort_order = if reverse then "desc" else "asc"
         @setState sort_reverse: reverse
         AllEngagementsSource.changeSort(@props.contact.id, sort_order)

      handleTVSort: (key, reverse) ->
         sort_order = if reverse then "desc" else "asc"
         @setState sort_reverse: reverse
         handleSort: ->
         key = "date_occurred"
         sortedEngagements = @state.thankview_engagements.slice().sort (a, b) =>
            if @state.reverse
               # Sorting in descending order if reverse is true
               if new Date(a[key]) < new Date(b[key]) then 1 else -1
            else
               # Sorting in ascending order if reverse is false
               if new Date(a[key]) > new Date(b[key]) then 1 else -1

         # Update state to toggle reverse and update the engagements array
         @setState
            thankview_engagements: sortedEngagements
            reverse: not @state.reverse

      handleChangeEngagement: (val) ->
         if val.value == "Facebook"
            EverTrue.track.set "tv_engagement_tab", {type: "engagement_facebook"}
         else if val.value == "Event"
            EverTrue.track.set "tv_engagement_tab", {type: "engagement_event"}
         else if val.value == "ThankView"
            EverTrue.track.set "tv_engagement_tab", {type: "engagement_thankview"}
         @setState {selected_engagement: val}

      handleLoadMore: ->
         AllEngagementsSource.loadMore(@props.contact.id)

      renderFilterHeader: ->
         div className: "profile-engagement--filter-header",
            if @state.loading
               span className: "text-muted profile-engagement--filter-loading",
                  "Loading..."
            else if @state.selected_engagement.value == "Facebook"
               EllipsisOverflow null,
                  FacebookSearchSummary
                     total: @state.facebook_engagement_total
                     page_filters: _.pluck(@state.applied_filters.facebook?.pages, "value")
                     pages: @state.applied_filters.facebook?.pages
                     items: _.pluralize(@state.applied_filters.facebook?.type || "engagement")
                     query: @state.applied_filters.facebook?.keyword
            else if @state.selected_engagement.value == "Event"
               EllipsisOverflow null,
                  EventSearchSummary
                     count: @state.event_engagement_total
            # else if @state.selected_engagement.value == "ThankView"
            #    EllipsisOverflow null,
            #       ThankviewSearchSummary
            #          contact: @props.contact
            if @state.selected_engagement.value != "ThankView"
               SortDropdown
                  className: "profile-engagement--sort"
                  options: [{label: "Date Engaged", value: "engagement.created_at"}]
                  sortProp: "engagement.created_at"
                  sortReverse: @state.sort_reverse
                  onSort: @handleSort
            else if @state.selected_engagement.value == "ThankView"
               SortDropdown
                  className: "profile-engagement--sort"
                  options: [{label: "Date Engaged", value: "engagement.date_occurred"}]
                  sortProp: "engagement.date_occurred"
                  sortReverse: @state.sort_reverse
                  onSort: @handleTVSort

            AdvancedCombobox
               className: classNames("advanced-combobox-secondary profile-engagement--category-dropdown")
               options: ENGAGEMENT_DROPDOWN_OPTIONS
               value: { value: @state.selected_engagement.value }
               searchable: false
               onChange: (val) =>
                  @handleChangeEngagement(val)

      render: ->
         div className: "profile-engagement",
            ProfileCard size: 3,
               div className: classNames("profile-engagement--filters", "is-filter-open": @state.filters_open),
                  if  @state.selected_engagement.value == "Facebook"
                     FilterDrawer
                        canApply: true
                        header: @renderFilterHeader()
                        onToggle: @handleToggleFilter
                        onCancel: @handleCancelFilter
                        onApply: @handleApplyFilter
                        onReset: unless _.isEmpty(@state.facebook_filters) then @handleResetFilter
                        isDisabled: @state.selected_engagement.value == "ThankView"
                        FacebookFilters
                           filters: @state.facebook_filters
                           onChange: (value) =>
                              @setState {facebook_filters: value}

                  else if  @state.selected_engagement.value == "Event"
                     FilterDrawer
                        canApply: true
                        header: @renderFilterHeader()
                        onToggle: @handleToggleFilter
                        onCancel: @handleCancelFilter
                        onApply: @handleApplyFilter
                        onReset: unless _.isEmpty(@state.event_filters) then @handleResetFilter
                        isDisabled: @state.selected_engagement.value == "ThankView"
                        EventFilters
                           filters: @state.event_filters
                           onChange: (value) =>
                              @setState {event_filters: value}

                  else if  @state.selected_engagement.value == "ThankView"
                     FilterDrawer
                        canApply: true
                        header: @renderFilterHeader()
                        onToggle: @handleToggleFilter
                        onCancel: @handleCancelFilter
                        onApply: @handleApplyFilter
                        onReset: unless _.isEmpty(@state.event_filters) then @handleResetFilter
                        isDisabled: @state.selected_engagement.value == "ThankView"

               div
                  className: if @state.selected_engagement.value != "ThankView" then "profile-engagement--filter-results" else "profile-engagement--filter-results-tv"
                  div className: "profile-engagement--all-engagements",
                     div className: "profile-engagement--engagement-list",
                        if @state.loading
                           Loading spinnerSize: "small"
                        else if @state.selected_engagement.value == "ThankView"
                           EngagementCardThankview
                              contact: @props.contact
                              engagement_data: @state.thankview_engagements
                        else if @state.selected_engagement.value == "Facebook"
                           if _.isEmpty(@state.facebook_engagements)
                              EmptyStateMessage
                                 icon: "facebook-box"
                                 text: "No engagements were found matching your search criteria.
                                 Try adjusting your filters to find results."
                           else
                              _.map @state.facebook_engagements, (engagement, i) =>
                                 EngagementCardFacebook
                                    show_contents: true
                                    key: engagement.id
                                    contact: @props.contact
                                    social: engagement.data
                        else if @state.selected_engagement.value == "Event"
                           if _.isEmpty(@state.event_engagements)
                              EmptyStateMessage
                                 icon: "event"
                                 text: "No engagements were found matching your search criteria.
                                 Try adjusting your filters to find results."
                           else
                              _.map @state.event_engagements, (engagement, i) =>
                                 EngagementCardEvent
                                    show_contents: true
                                    key: engagement.id
                                    contact: @props.contact
                                    event_engagement: engagement.data

                     if @state.has_more_to_load and !@state.loading and @state.selected_engagement.value != "ThankView"
                        LinkButton title: "View more engagements", onClick: @handleLoadMore, className: "profile-engagement--pagination",
                           "View More"

                  if @state.engagement_total > 15 and @state.selected_engagement.value != "ThankView"
                     BackToTop
                        showPosition: 100
                        scrollElement: @state.body

            ProfileCard size: 1,
               ProfileEngagementMetrics contact: @props.contact
