import { Component } from "react";
import { createRoot } from "react-dom/client";
import PropTypes from "prop-types";

const google = window.google;

class MapInfoWindow extends Component {
  static propTypes = {
    map: PropTypes.object,
    marker: PropTypes.object,
    onClose: PropTypes.func,
    children: PropTypes.any,
  };

  static defaultProps = {
    onClose: () => {},
  };

  componentDidMount() {
    this.infoWindow = new google.maps.InfoWindow({ disableAutoPan: false });
    google.maps.event.addListener(this.infoWindow, "closeclick", () => {
      this.props.onClose(this.props.marker, this.props.map);
    });
    this.openMarker();
  }

  componentDidUpdate() {
    this.openMarker();
  }

  openMarker = () => {
    if (this.props.marker) {
      this.renderMarker();
      this.infoWindow.open(this.props.map, this.props.marker);
    } else {
      this.infoWindow.close();
    }
  };

  renderMarker = () => {
    const content = <div className="map-info--wrap">{this.props.children}</div>;
    const detached_DOM = document.createElement("div");
    const root = createRoot(detached_DOM);
    root.render(content);
    this.infoWindow.setContent(detached_DOM);
  };

  render() {
    return null;
  }
}

export default MapInfoWindow;
