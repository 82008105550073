import PropTypes from "prop-types";

const ListItem = ({ text, onClick, imageSrc, classNames = "list-item" }) => (
  <div className={classNames} onClick={onClick}>
    <img src={imageSrc} alt="item-img" />
    <div className="list-item-text">{text}</div>
  </div>
);

ListItem.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  imageSrc: PropTypes.string,
  classNames: PropTypes.string,
};

export default ListItem;
