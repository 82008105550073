/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import _ from "underscore";

import { createStore } from "@evertrue/et-flux";
import CustomFieldsSource from "apps/interactions/sources/custom-fields-source";
import OrgSource from "base/org/org-source";

export default createStore("CustomFieldsStore", {
  getInitialState() {
    return { custom_fields: [] };
  },

  registerActions() {
    this.on(CustomFieldsSource.actions.fetchedFields, function(custom_fields) {
      return this.setState({ custom_fields: _.sortBy(custom_fields, "sortOrder") });
    });

    this.on(CustomFieldsSource.actions.fetchIfNotCached, function() {
      if (!this.getState("custom_fields").length) {
        return CustomFieldsSource.fetch();
      }
    });

    return this.on(OrgSource.actions.newOrg, function() {
      return this.setState({ custom_fields: [] });
    });
  },

  api: {
    getCustomFields() {
      return this.getState("custom_fields");
    },

    getCustomFieldsObject() {
      return _.indexBy(this.getState("custom_fields"), "id") || {};
    },
  },
});
