export const getUserContactIdForOrg = (user, org) => {
  try {
    if (!user.affiliations.length) return null;

    const correctAffiliation = user.affiliations.find((a) => a.organization.id === org.id);

    return correctAffiliation.contact_id.toString() || null;
  } catch (error) {
    return null;
  }
};
