module.exports = do ->

   _ = require("underscore").default
   EverTrue = require("app")

   class RoleHelper
      keys:
         owner: "GivingTree Owner"
         user: "GivingTree User"

      toObject: ->
         obj = {}
         _.each @keys, (key, role) ->
            id = EverTrue.store.org?.getRoleId(key)
            obj[role] = id
         obj

      toArray: ->
         _.compact _.map @keys, (key, role) ->
            EverTrue.store.org?.getRoleId(key)

      isUserRole: (ref) ->
         (ref == @keys.user) || (_.toNumber(ref) == @getUserId())

      isOwnerRole: (ref) ->
         (ref == @keys.owner) || (_.toNumber(ref) == @getOwnerId())

      getUserId: ->
         _.toNumber EverTrue.store.org?.getRoleId(@keys.user)

      getOwnerId: ->
         _.toNumber EverTrue.store.org?.getRoleId(@keys.owner)

      getRoleKey: (id) ->
         key = undefined
         _.each @toObject(), (role_id, role_key) ->
            key = role_key if role_id == id
         key

   new RoleHelper()
