module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   {div, span} = ReactLibs.DOMFactories
   {createComponent} = require("@evertrue/et-flux")
   KpiCard = require("components/containers/kpi-card")
   numeral = require("numeral")

   createComponent "CompanyParticipation",
      percent: (prop) ->
         key = @props.data?[prop]
         if key && key != "failed"
            num = (key.numerator / key.denominator) || 0
            if !_.isFinite(num) then num = 0
            numeral(num).format("0.00%")
         else
            span className: "text-placeholder-light",
               "Not Provided"

      ratio: (prop) ->
         key = @props.data?[prop]
         if key && key != "failed"
            div null,
               key.numerator + " of " + key.denominator

      render: ->
         year = new Date().getFullYear()

         div className: "company-participation-cards fs-exclude-data",
            KpiCard
               title: "Lifetime Participation"
               value: @percent("lifetime_participation")
               width: 33
               @ratio("lifetime_participation")

            KpiCard
               title: "FY " + year
               value: @percent("current_year")
               width: 33
               @ratio("current_year")

            KpiCard
               title: "FY " + (year-1)
               value: @percent("last_year")
               width: 33
               @ratio("last_year")

