import { useRef } from "react";
import _ from "underscore";
import PropTypes from "prop-types";

const KeyPressManager = ({
  onArrowUp,
  onArrowDown,
  onArrowRight,
  onArrowLeft,
  onEsc,
  onEnter,
  onSpace,
  onKeyPress,
  component,
  children,
  ...rest
}) => {
  const Component = component;

  const ref = useRef(null);

  const handleKeyPress = e => {
    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        onArrowDown(ref.current, e);
        break;
      case "ArrowUp":
        e.preventDefault();
        onArrowUp(ref.current, e);
        break;
      case "ArrowRight":
        e.preventDefault();
        onArrowRight(ref.current, e);
        break;
      case "ArrowLeft":
        e.preventDefault();
        onArrowLeft(ref.current, e);
        break;
      case "Escape":
        e.preventDefault();
        onEsc(ref.current, e);
        break;
      case "Enter":
        // If you're trying to preventing default on Enter
        // you're probably doing something non-accessible
        onEnter(ref.current, e);
        break;
      case "Space":
        e.preventDefault();
        onSpace(ref.current, e);
        break;
      default:
        onKeyPress(ref.current, e);
        break;
    }
  };

  if (typeof children === "function") return children({ onKeyDown: handleKeyPress, ref });

  return (
    <Component onKeyDown={handleKeyPress} ref={ref} {...rest}>
      {children}
    </Component>
  );
};

KeyPressManager.propTypes = {
  onArrowUp: PropTypes.func,
  onArrowDown: PropTypes.func,
  onArrowRight: PropTypes.func,
  onArrowLeft: PropTypes.func,
  onEsc: PropTypes.func,
  onEnter: PropTypes.func,
  onSpace: PropTypes.func,
  onKeyPress: PropTypes.func,
  component: PropTypes.any,
  children: PropTypes.any,
};

KeyPressManager.defaultProps = {
  onArrowUp: _.noop,
  onArrowDown: _.noop,
  onArrowRight: _.noop,
  onArrowLeft: _.noop,
  onEsc: _.noop,
  onEnter: _.noop,
  onSpace: _.noop,
  onKeyPress: _.noop,
  component: "div",
};

export default KeyPressManager;
