import PropTypes from "prop-types";
import { ModalHeaderWithActions } from "@evertrue/et-components";
import EmailTemplatePreview from "./email-template-preview";
import EmailTemplateForm from "./email-template-form";
import { useState, useRef, useContext } from "react";
import { useIdentity } from "base/identity-resolver";
import { isDynamicTemplate } from "../../../utils/utils";
import { EmailTemplatesContext } from "../hooks/email-templates-context";

const CreateOrUpdateEmailTemplateModal = ({ originalTemplate = {}, setShowEditView, setSelectedTemplate, contact }) => {
  const {
    templateName: originalTemplateName = "",
    templateSubject: originalTemplateSubject = "",
    templateBody: originalTemplateBody = "",
  } = originalTemplate;

  const [templateName, setTemplateName] = useState(originalTemplateName);
  const [templateSubject, setTemplateSubject] = useState(originalTemplateSubject);
  const [templateBody, setTemplateBody] = useState(originalTemplateBody);
  const { getAndSetTemplates, createEmailTemplate, updateEmailTemplate } = useContext(EmailTemplatesContext);

  const quillRef = useRef();

  const {
    org: { id: oid },
    user: { id: userId },
  } = useIdentity();

  const handleSubmit = async () => {
    const quill = quillRef.current.getEditor();
    const quillBody = quill.root.innerHTML;
    const template = {
      templateName,
      templateSubject,
      templateBody: quillBody,
      dynamicTemplate: isDynamicTemplate(quillBody),
    };
    if (!Object.keys(originalTemplate).length) {
      await createEmailTemplate(template);
    } else {
      const { id } = originalTemplate;
      const updatedTemplate = await updateEmailTemplate(id, oid, userId, template);
      setSelectedTemplate(updatedTemplate);
    }
    setShowEditView(false);
    getAndSetTemplates(0);
  };

  return (
    <>
      <ModalHeaderWithActions
        title={!Object.keys(originalTemplate).length ? "Create Email Template" : "Edit Email Template"}
        closeModal={() => setShowEditView(false)}
        onSubmit={handleSubmit}
        disableSubmit={!templateName}
      ></ModalHeaderWithActions>
      <div style={{ display: "flex", height: "550px" }}>
        <EmailTemplateForm
          templateName={templateName}
          templateBody={templateBody}
          templateSubject={templateSubject}
          setTemplateName={setTemplateName}
          setTemplateBody={setTemplateBody}
          setTemplateSubject={setTemplateSubject}
          quillRef={quillRef}
          contact={contact}
        />
        <EmailTemplatePreview
          template={{
            templateName,
            templateSubject,
            templateBody,
          }}
          className={"edit-email-template--preview-container"}
        />
      </div>
    </>
  );
};

CreateOrUpdateEmailTemplateModal.propTypes = {
  originalTemplate: PropTypes.object,
  setShowEditView: PropTypes.func,
  setSelectedTemplate: PropTypes.func,
  contact: PropTypes.object,
};

export default CreateOrUpdateEmailTemplateModal;
