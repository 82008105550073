EverTrue = require("app")
config = require("config/env")
CookiesJS = require("js-cookie")

DOMAIN =  ".evertrue.com"

PREFIX = if EverTrue.config.environment isnt "production" then "stage_" else ""

Cookies =
   get: (key) ->
      if key then CookiesJS.get(PREFIX + key, domain: DOMAIN)

   set: (key, value) ->
      if key and value? then CookiesJS.set(PREFIX + key, value, domain: DOMAIN)

   clear: (key) ->
      if key then CookiesJS.remove(PREFIX + key, domain: DOMAIN)

EverTrue.Cookies = Cookies

module.exports = Cookies

