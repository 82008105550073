import { useState, useEffect, Fragment } from "react";
import EverTrue from "app";
import PropTypes from "prop-types";
import moment from "moment";
import Api from "entities/helpers/api";
import { IconButton, Loading, Icon } from "@evertrue/et-components";
import { useGate } from "components/is-gated";

const ExportDownloadItem = ({ label, exportKey, exportMetaKey, gate, trackingKey }) => {
  const [lastUpdateTime, setLastUpdateTime] = useState(null);
  const [loading, setLoading] = useState(false);
  const [canDownload, setCanDownload] = useState(false);

  useEffect(() => {
    Api.EXPORTS[exportMetaKey].get({
      disableAlerts: true,
      success: (response) => {
        setLastUpdateTime(response.last_modified);
        setCanDownload(true);
      },
    });
  }, [exportMetaKey]);

  const handleDownload = () => {
    EverTrue.track.set("manual_export_action", { type: trackingKey });
    Api.EXPORTS[exportKey].get({
      success: (response) => {
        window.location = response.url;
        setLoading(false);
      },
      error: (err) => {
        setLoading(false);
      },
    });
  };

  const [hasGate] = useGate(gate);

  return (
    <div className="export-item">
      {loading ? (
        <Loading />
      ) : (
        <Fragment>
          <div className="export-item--heading">{label}</div>

          <div className="export-item--frequency">
            <Icon icon="date-range" /> 1 month
          </div>
          <div>
            {hasGate && lastUpdateTime ? (
              <Fragment>
                <span> {moment(lastUpdateTime).format("MMM DD, YYYY h:mm A")}</span>
              </Fragment>
            ) : (
              "No data available yet"
            )}
          </div>
          <div className="export-item--manual-button">
            {hasGate && canDownload ? (
              <IconButton title="Download File" size={2} icon="cloud-download" onClick={handleDownload} />
            ) : (
              <IconButton title="No Download Available" size={2} icon="cloud-download-blocked" disabled />
            )}
          </div>
        </Fragment>
      )}
    </div>
  );
};

ExportDownloadItem.propTypes = {
  label: PropTypes.string,
  exportKey: PropTypes.string,
  exportMetaKey: PropTypes.string,
  gate: PropTypes.string,
  trackingKey: PropTypes.string,
};

export default ExportDownloadItem;
