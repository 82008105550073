import { useContext, useEffect } from "react";
import { Button, PageHeader } from "@evertrue/et-components";
import { GlobalContactSearchContext } from "apps/layout/controllers/global-contact-search-context";
import EverTrue from "app";
import FiltersBarController from "apps/query-builder/components/filters-bar-controller";
import ResultsCount from "components/charts/results-count";
import useResultCount from "apps/query-builder/hooks/use-result-count";
import useTotalCount from "apps/query-builder/hooks/use-total-count";
import RecentlyViewed from "apps/constituents/components/recently-viewed";
import FilterReset from "apps/query-builder/components/filter-reset";

const ConstituentsController = () => {
  const { resetFiltersAndSettings, contactFilters } = useContext(GlobalContactSearchContext);
  const [totalCount] = useTotalCount();
  const [resultCount] = useResultCount(contactFilters);

  useEffect(() => {
    resetFiltersAndSettings();
  }, [resetFiltersAndSettings]);

  const goToResults = () => {
    EverTrue.track.set("filter_v2_action", {
      type: "landing_page_go",
    });
    EverTrue.UrlManager.navigate("/contact", true);
  };

  return (
    <div className="constituents-controller">
      <PageHeader title="Constituents" />

      <FiltersBarController onApply={goToResults}>
        <FilterReset />
      </FiltersBarController>
      <div className="constituents-controller-count">
        <ResultsCount totalCount={totalCount} resultCount={resultCount} label="Constituents" />
      </div>

      <div className="constituents-table-placeholder">
        <div className="constituents-table-placeholder-th" />
        <div className="constituents-table-placeholder-tr" />
        <div className="constituents-table-placeholder-tr" />

        <Button className="constituents-table-placeholder-button" onClick={goToResults}>
          Go
        </Button>
      </div>

      <RecentlyViewed />
    </div>
  );
};

export default ConstituentsController;
