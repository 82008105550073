module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   React = require("react")
   EverTrue = require("app")
   {createComponent} = require("@evertrue/et-flux")
   {g, circle} = ReactLibs.DOMFactories
   colors = require("base/colors")

   DEFAULT_COLOR = colors.green300


   createComponent "Points",
      propTypes:
         data: ReactLibs.PropTypes.array
         width: ReactLibs.PropTypes.number
         height: ReactLibs.PropTypes.number
         style: ReactLibs.PropTypes.object
         onMouseOver: ReactLibs.PropTypes.func
         onMouseOut: ReactLibs.PropTypes.func
         onClick: ReactLibs.PropTypes.func

      getDefaultProps: ->
         style: {stroke: DEFAULT_COLOR, strokeWidth: 2, fill: colors.white, cursor: "pointer"}
         radius: 3

      handleGetPosition: (e, item, coords) ->
         node = $(e.target)
         position = _.extend {}, node.offset(),
            height: node.offsetHeight
            width: node.offsetWidth
            scrollTop: node.scrollTop
            coords: coords
         @props.onMouseOver?(item, position)

      render: ->
         g className: "etchart-points",
            _.compact _.map @props.data, (item, index) =>
               [xpoint, ypoint] = @props.getCoords?(item)
               ypoint = @props.height - ypoint

               if _.isFinite(xpoint) && _.isFinite(ypoint)
                  circle
                     cx: xpoint
                     cy: ypoint
                     r: @props.radius
                     key: "circle_#{item.x}_#{item.y}_#{item.series}"
                     style: _.extend({}, @props.style, item.pointStyle) || @props.style
                     className: "etchart-point",
                     onMouseOver: (e) => @handleGetPosition(e, item, [xpoint, ypoint])
                     onMouseOut: => @props.onMouseOut?()
                     onClick: => @props.onClick?(item)


