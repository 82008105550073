module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   moment = require("moment")
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   EverTrue = require("app")
   LoadingMixin = require("mixins/loading-mixin")
   ExportStore = require("apps/export/stores/export-store")
   ExportSource = require("apps/export/sources/export-source")
   {h6, table, em, span, div, h3} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   ProgressBar = createFactory(require("components/forms/progress-bar").default)
   PageHeader = createFactory(require("apps/layout/components/page-header").default)
   PagedColumnTableHeader = createFactory require("components/tables/paged-col-table-header")
   PagedColumnTableBody = createFactory require("components/tables/paged-col-table-body")
   Module = createFactory require("components/module").default
   StickyHeaderModule = require("components/containers/sticky-header-module")
   StickyHeader = require("components/containers/sticky-header")
   EmptyStateMessage = createFactory(require("components/lists/empty-state-message"))
   ScheduledExportsSource = require("apps/settings/export-settings/sources/scheduled-exports-source")
   Icon = createFactory (require("components/elements/icon").default)
   HelpTip = createFactory require("components/tooltip/helptip").default
   Link = createFactory(require("@evertrue/et-components").Link)

   HEADERCONFIG = [
         {label: "Export File", row: "file", width: "33%"}
         {label: "", row: "actions", width: "33%"}
         {label: "Date Completed", prop: "updated_at", row: "date", width: "33%"}
   ]
   createComponent "ExportController",
      getInitialState: ->
         table_height: 500
         sort_data: {sortProp: "updated_at", sortReverse: false}

      registerStores: ->
         @on ExportStore, ->
            exports: ExportStore.getExportsByMonth(@state?.sort_data?.sortReverse)
            loading: ExportStore.getLoading()

      componentDidMount: ->
         $(window).resize(@setTableHeight)
         @setTableHeight()
         ExportSource.fetch()
         if EverTrue.store.user?.isSuperUser() || EverTrue.store.user?.isOwner()
            ScheduledExportsSource.fetchScheduled()

      componentWillUnmount: ->
         $(window).off "resize", @setTableHeight

      setTableHeight: ->
         return unless @depracated_is_mounted && @refs.table
         $table = $(@refs.table)
         table_offset = $table.offset()?.top
         window_height = $(window).outerHeight()
         padding = 30
         @setState {table_height: window_height - table_offset - padding}

      handleDownload: (data) ->
         ExportSource.download(data)

      handleSort: (prop) ->
         reverse = prop == @state.sort_data.sortProp && !@state.sort_data?.sortReverse
         @setState
            exports: ExportStore.getExportsByMonth(reverse)
            sort_data:
               sortProp: prop
               sortReverse: reverse

      rows: ->
         file: (data) =>
            div null,
               if !data.scheduled
                  if data.percent_complete == 1
                     if data.state == "finished_success"
                        Link href: data.download_link, title: "Download Export", "data-bypass": true, onClick:(=> @handleDownload(data)),
                           data.name || "export.csv"
                     else if data.state == "finished_error"
                        span className: "text-label",
                           data.name || "export.csv"
                  else
                     ProgressBar percent: Math.max(data.percent_complete, 0.10), width: "75%"
               else
                  span className: "text-label", data.name || "export.csv"
         date: (data) ->
            span null,
               if data.percent_complete == 1
                  span null,
                     span className: "text-label exports-table--time",
                        moment(data.updated_at).format("MMMM D, YYYY ")
                        "at "
                        moment(data.updated_at).format("h:mm A")
               else
                  em className: "text-label", " Creating export... "
         actions: (data) =>
            div className: "text-right",
               if data.state == "finished_success" && !data.scheduled
                  Link
                     className: "exports-table--action-download"
                     href: data.download_link
                     title: "Download Export",
                     "data-bypass": true
                     onClick: => @handleDownload(data),
                     Icon icon: "cloud-download"
                     "Download"
               else if data.state == "finished_error" && data.scheduled
                  span className: "exports-table--error",
                     HelpTip help_key: "scheduled_export_failure"
                     span className: "exports-table--help-text", "Error Exporting File"
               else if data.state == "finished_error"
                  span className: "exports-table--error",
                     "Error Exporting File"

      renderExportGroups: ->
         _.flatten _.map @state.exports, (group) =>
            [
               StickyHeader header_key: group.month, key: group.month,
                  moment(group.month).format("MMMM YYYY")
            ,
               div className: "table", key: "table-" + group.month,
                  table className: "table table--left-aligned table-with-padding",
                     PagedColumnTableBody
                        data: group.data
                        columns: HEADERCONFIG
                        cells: @rows
                        rowClass: (data) ->
                           custom_class = "exports-table--row"
                           if !data.scheduled && (!data?.last_downloaded_at && data?.percent_complete) == 1 then "is-unread #{custom_class}"
                           else custom_class
            ]

      render: ->
         div null,
            PageHeader
               title: "Recent Exports"
               subtitle: "Export files you generated in the past 90 days",
                  Link
                     href: "/settings/export_data"
                     className: "exports-table--action"
                     title: "Export Schedule"
                     Icon icon: "access-time"
                     span null, "Manage Schedules"
            

            div className: "page-container",
               div className: "loading-container",
                  LoadingMixin.loading(@state.loading && _.isEmpty(@state.exports))

                  if !_.isEmpty(@state.exports)
                     div className: "exports-table table-module",
                        table className: "table",
                           PagedColumnTableHeader
                              visibleColumns: HEADERCONFIG
                              sortData: @state.sort_data
                              onSort: @handleSort

                        div ref: "table",
                           StickyHeaderModule maxHeight: @state.table_height,
                              @renderExportGroups()

                  if !@state.loading && _.isEmpty(@state.exports)
                     Module null,
                        div className: "exports-table--empty",
                           EmptyStateMessage
                              icon: "cloud-download"
                              text: "No recent exports"
                              "You can create exports from search results, Saved Searches, and Lists."
