import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeaderWithActions, ModalBody } from "@evertrue/et-components";
import EverTrue from "app";
import FormField from "components/forms/form-field";
import Api from "entities/helpers/api";
import { Button } from "@evertrue/et-components";
import DynamicListSource from "apps/dynamic-lists/sources/dynamic-list-source";
import { Icon } from "@evertrue/et-components";

const DynamicListsModal = ({ isOpen, onClose, filters }) => {
  const [listName, setListName] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [showWarning, setWarning] = useState(false);

  useEffect(() => {
    EverTrue.track.set("dynamic_lists_action", { type: "open_dynamic_list_modal" });
  }, []);

  const handleSubmit = (e) => {
    let id;
    setSubmitting(true);
    e.preventDefault();
    if (listName.length > 0 && listName.trim() === "") {
      setWarning(true);
      setSubmitting(false);
    } else {
      EverTrue.track.set("dynamic_lists_action", { type: "create_dynamic_list" });
      Api.HUB.LISTS.post({
        urlExtend: "/dynamic",
        data: JSON.stringify({
          name: listName,
          dsl_criteria: filters,
        }),
      })
        .then((data) => {
          id = data.id;
          Api.EMS.PREFS_BY_KEY.put({
            urlArgs: { key: `list_v2:${data.id}:` },
            data: JSON.stringify({
              email_enabled: false,
              push_enabled: false,
              label: data.id,
            }),
          });
        })
        .then((data) => {
          setSubmitting(false);
          onClose();
          EverTrue.Alert.success(
            <div>
              <p>Your list has been created.</p>
              <p style={{ marginBottom: 12 }}>We'll let you know when the list is ready to view.</p>
              <Button
                onClick={() => {
                  DynamicListSource.setClearFilters(true);
                  EverTrue.Navigator(`dynamic-lists/${id}`);
                }}
              >
                View list now
              </Button>
            </div>
          );
        })
        .catch(() => {
          setSubmitting(false);
        });
    }
  };

  return (
    <Modal isOpen={isOpen} closeModal={onClose}>
      <ModalHeaderWithActions
        title="Create Dynamic List"
        closeModal={onClose}
        onSubmit={handleSubmit}
        disableSubmit={listName === "" || submitting}
      />
      <ModalBody>
        {showWarning && (
          <div className="dynamic-list-warning">
            <Icon icon="alert-circle" style={{ color: "rgb(219,101,108)", marginRight: "8px" }} />
            <h6>Failed to create list. Please enter a valid list name.</h6>
          </div>
        )}

        <form onSubmit={handleSubmit} style={showWarning ? { marginTop: "40px" } : {}}>
          <FormField label="List Name" inlineLabel={false} required={true}>
            <input
              data-testId="input"
              required
              aria-label="List Name"
              type="text"
              value={listName}
              placeholder="List Name"
              onChange={(e) => setListName(e.target.value)}
              maxLength="255"
            />
          </FormField>
        </form>
      </ModalBody>
    </Modal>
  );
};

DynamicListsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
};

export default DynamicListsModal;
