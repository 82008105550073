_ = require("underscore").default
Query = require("entities/search/query-parts/query")
Parameter = require("entities/search/query-parts/parameter-query")
Property = require("entities/search/query-parts/property-query")

# not included in canned query
ADVANCED_FILTERS = [
  { key: "last_gift_date", hasValue: _.isEmpty },
  { key: "total_pledge_balance", hasValue: _.isEmpty },
  { key: "has_recurring_payments", hasValue: _.isBoolean }
]

_getQuery = (query_param, property, query_value, type) ->
   Query [
      Parameter query_param, [
         Property property, query_value, {
            type: type
            parent: "giving_categories"
            is_nested_type: true
            parent_type: "instance"
         }
      ]
   ]

getParam = (param) ->
   switch param
      when "donor" then "must"
      when "non_donor" then "must_not"
      else param

getQuery = (value = {}) ->
   { param, category, last_gift_date, lifetime_amount, total_pledge_balance, has_recurring_payments } = value
   query_param = getParam(param)

   # figure out if we're doing a canned query or an advanced query
   is_advanced = param in ["must", "must_not"]

   # get base query
   fullQuery = _getQuery(query_param, "label.untouched", category || "CATEGORY", "equals")

   # if advanced filter is in use, override LTG with that
   # but if not, still include a default value to ensure donor-ness

   date_value =
      if is_advanced
         _.compactObject({
            gt: _.max([lifetime_amount?.gte, 0])
            lte: lifetime_amount?.lte
            coerce: 0
         })
      else {gt: 0, coerce: 0}
   fullQuery.merge(_getQuery(query_param, "lifetime_amount", date_value, "object"))

   # only include following params if we're doing a advanced filter
   if is_advanced
      if last_gift_date
         fullQuery.merge(_getQuery(query_param, "last_gift_date", last_gift_date, "object"))
      if total_pledge_balance
         fullQuery.merge(_getQuery(query_param, "total_pledge_balance", total_pledge_balance, "object"))
      if has_recurring_payments
         value = if has_recurring_payments is "no" then false else if has_recurring_payments is "yes" then true
         if _.isBoolean value
            fullQuery.merge(_getQuery(query_param, "has_recurring_payments", value, "equals"))
   return fullQuery

parseCriteria = (criteria) ->
   all = criteria.getAll()
   index = _.indexBy(all, "property")
   plucked = _.mapObject(index, (val) -> val?.value)
   has_advanced_filters = _.any(ADVANCED_FILTERS, (filter) ->
      filter.hasValue(plucked[filter.key]))

   has_must = criteria.hasParameter("must")

   { last_gift_date, lifetime_amount, total_pledge_balance, has_recurring_payments } = plucked

   label = plucked["label.untouched"]

   {gt, gte, lte} = lifetime_amount || {}
   has_recurring  = if has_recurring_payments is true then "yes" else if has_recurring_payments is false then "no"

   if label and !has_advanced_filters and (gt is 0 and not lte)
      # this means we've found a canned filter
      return {
         category: label
         param: if has_must then "donor" else "non_donor"
      }

   else
      return _.compactObject {
         category: label
         param: if has_must then "must" else "must_not"
         lifetime_amount: _.compactObject(gte: (gt || gte), lte: lte)
         last_gift_date
         total_pledge_balance
         has_recurring_payments: has_recurring
      }

CategorizedGivingFiltersUtils = {getQuery, parseCriteria}

module.exports = CategorizedGivingFiltersUtils

