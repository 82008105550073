module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   {createStore} = require("@evertrue/et-flux")
   CollaboratorSource = require("apps/lists/sources/collaborator-source")
   ListSource = require("apps/lists/sources/list-source")
   ListStore = require("apps/lists/stores/list-store")
   SocketSource = require("apps/notifications/sources/socket-source")
   AllUsersStore = require("apps/users/stores/all-users-store").default
   AllUsersSource = require("apps/users/sources/all-users-source")


   createStore "CollaboratorStore",
      getInitialState: ->
         collaborators: {}
         users: {}
         loading: false
         selected_list_id: undefined

      registerActions: ->
         @on CollaboratorSource.actions.fetchedCollaborators, @respondToFetchedCollabs
         @on CollaboratorSource.actions.removedCollaborators, @respondToRemove
         @on CollaboratorSource.actions.sharedList, @respondToShared

         @on AllUsersSource.actions.fetchedUsers, ->
            @setState {users: AllUsersStore.getAllGivingTreeUsers()}

         @on SocketSource.actions.list_collaborator_change, (data) ->
            current_list = _.toNumber(@getState("selected_list_id"))
            if data?.object_id == current_list
               CollaboratorSource.fetchCollaborators(current_list)

      respondToFetchedCollabs: (list_id, collaborators) ->
         # Lookup user data for collaborators from ids
         user_ids = _.map collaborators.items, (collab) ->
            _.toNumber(collab.collaborator_user_id)
         user_ids.push ListStore.getList(list_id)?.user_id
         AllUsersSource.fetchUsers(user_ids)

         # Cache collaborators by list_id, for up to 10 lists
         collabs = @getState("collaborators")
         if _.size(collabs) > 10 then collabs = {}
         collabs[list_id] = collaborators
         @setState {collaborators: collabs, selected_list_id: list_id}

      respondToShared: (list_id, resp, options={}) ->
         @respondToFetchedCollabs(list_id, resp)
         unless options.disableAlert
            EverTrue.Alert.success "Successfully shared list"

      respondToRemove: (list_id, resp, options={}) ->
         @respondToFetchedCollabs(list_id, resp)

         if _.contains options?.user_ids, EverTrue.store.user.get("id")
            ListSource.fetch()

         unless options.disableAlert
            EverTrue.Alert.success "Successfully revoked access to list",
               onUndo: ->
                  CollaboratorSource.share list_id,
                     user_ids: options?.user_ids
                     skip_notification: true
                  , {disableAlert: true}

      getCurrentListOwner: (list_id) ->
         list_id ?= @getState("selected_list_id")
         ListStore.getList(list_id)?.user_id

      api:
         getCollaborators: (list_id) ->
            collabs = @getState("collaborators")?[list_id] || {}
            users = @getState("users")
            current_list_owner = @getCurrentListOwner(list_id)
            if current_list_owner
               owner = {collaborator_user_id: current_list_owner, owner: true}

            _.compact _.map _.flatten([[owner], collabs.items]), (collab) ->
               collab_id = collab?.collaborator_user_id
               if users[collab_id]
                  _.extend {}, collab, user: users[collab_id]
               else if collab_id == EverTrue.store.user.get("id")
                  _.extend {}, collab, user: EverTrue.store.user?.toJSON()
               else if collab_id == owner?.collaborator_user_id
                  _.extend {}, owner, user: AllUsersStore.getEmptyStateUser()

         getUserIds: (list_id) ->
            list_collaborators = @getState("collaborators")?[list_id]?.items
            _.compact _.pluck list_collaborators, "collaborator_user_id"

         getLoading: ->
            @getState("loading")
