module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   EverTrue = require("app")
   {createComponent} = require("@evertrue/et-flux")
   {div, span} = ReactLibs.DOMFactories
   TopFieldsStore = require("apps/profile/stores/top-fields-store")
   {createFactory} = require("base/new-utils")
   ProfileCardSubtitle = createFactory require("apps/profile/components/profile-card-subtitle").default
   ContactAssignee = require("apps/profile/components/templates/contact-assignee")
   EmptyStateMessage = createFactory(require("components/lists/empty-state-message"))
   Datalist = createFactory require("components/elements/datalist")
   SearchBox = createFactory require("components/forms/search-box")
   Icon = createFactory (require("components/elements/icon").default)
   Assignment = createFactory require("apps/profile/components/templates/assignment").default
   VolunteerAssignment = createFactory require("apps/profile/components/templates/volunteer-assignment").default

   createComponent "DonorInfoAll",
      propType:
         contact: ReactLibs.PropTypes.object
         totalAssignees: ReactLibs.PropTypes.array

      getInitialState: ->
         query: undefined

      registerStores: ->
         @on TopFieldsStore, ->
            fields: TopFieldsStore.getAllForContact(@props.contact)

      componentDidMount: ->
         @refs?.search?.focus?()

      UNSAFE_componentWillReceiveProps: (newProps) ->
         if !_.isEqual(newProps.contact, @props.contact)
            @setState {fields: TopFieldsStore.getAllForContact(newProps.contact)}

      render: ->
         filtered_fields = _.filter @state.fields, (field) =>
            _.searchCompare(field.label, @state.query) || _.searchCompare(field.group, @state.query)
         grouped_fields = _.groupBy(filtered_fields, "group")

         filtered_Assignees = @props.totalAssignees.filter((assignee) => 
            name = assignee.name?.value || assignee.name || ""
            return name.toLowerCase().match(@state.query))
         
         div className: "donor-info-all fs-exclude-data",
            SearchBox
               ref: "search"
               className: "donor-info--search"
               placeholder: "Search label or category to filter..."
               onKeyUp: (value) =>
                  @setState {query: value}

            # ASSIGNEES
            div className: "donor-info--section",
               ProfileCardSubtitle label: "Assigned To"
               if _.isEmpty(filtered_Assignees)
                  EmptyStateMessage
                     className: "donor-info--empty-state"
                     size: "small"
                     icon: "group"
                     "This constituent is not assigned"
               else
                  _.map filtered_Assignees, (assignee) ->
                     if assignee.is_assignment
                        Assignment assignment: assignee, key: _.randomKey()
                     else if assignee.is_volunteer_assignment
                        VolunteerAssignment assignment: assignee, key: _.randomKey()
                     else
                        ContactAssignee data: assignee, key: _.randomKey()

            # DONOR INFO, DONOR STATUS, EDUCATION, CUSTOM, MISC
            _.map grouped_fields, (group, label) ->
               div className: "donor-info--section", key: label,
                  ProfileCardSubtitle label: label

                  _.map group, (item) ->
                     Datalist
                        className: "donor-info--datalist"
                        label: span null,
                           if item.selected then Icon icon: "star"
                           item.label
                        key: item.key

                        if _.isEmpty(item.value)
                           span className: "donor-info--empty-field", "Not provided"
                        else _.map item.value, (val) -> div key: _.randomKey(), val
