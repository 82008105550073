import EverTrue from "app";
import PropTypes from "prop-types";
import { extractInteractionData, extractLinks, pluralize } from "./thankview-parser-helper.js";
import InteractionHyperlink from "./interaction-hyperlinks.js";

export const ThankViewBuilder = ({ condition, ThankViewParts }) => {
  return (
    <div>
      {/* Render tv components based on the condition */}
      {condition === "ThankViewInteractionsVideo" && <ThankViewParts.ThankViewInteractionsVideo />}
      {condition === "ThankViewInteractionsText" && <ThankViewParts.ThankViewInteractionsText />}
      {condition === "ThankViewInteractionsReplyWithVideo" && <ThankViewParts.ThankViewInteractionsReplyWithVideo />}
      {condition === "ThankViewInteractionsReplyWithText" && <ThankViewParts.ThankViewInteractionsReplyWithText />}
      {condition === "Error" && <ThankViewParts.Error />}
    </div>
  );
};

export const ThankViewComponentBuilder = ({ condition, interaction = {} }) => {
  const handleVideoClickForTVInteraction = () => {
    EverTrue.track.set("thankview_interactions_action", {
      type: "tv_details_video_clicked",
    });
  };

  const handleVideoClick = () => {
    EverTrue.track.set("thankview_interactions_action", {
      type: "tv_reply_video_clicked",
    });
  };

  //parse conditions
  const { message, replyMessage, replyMessageVideo, sentDate, watchedDate } = extractInteractionData(interaction);
  const linkResultInReply = extractLinks(replyMessage) ? extractLinks(replyMessage) : "";
  const viewField =
    (interaction.custom_field &&
      interaction.custom_field.find((field) => field.displayName === "ThankView Views")) ||
    0;
  const viewTimes = viewField ? viewField.value : 0;
  let videoUrl = "";
  let linksinInteraction = "";
  try {
    const text = interaction.text;
    videoUrl = text.match(/\bhttps?:\/\/\S+\/(?:[\w-]+\.(?:jpg|jpeg|gif|png|mp4))\b/) || [];
    const regex = /\bhttps?:\/\/\S+/gi;
    linksinInteraction = message.match(regex) || [];
  } catch (error) {
    EverTrue.alert.error("Error parsing text from interaction.");
  }

  const ThankViewParts = {
    ThankViewInteractionsVideo: () => (
      <>
        <div className="thankview-interaction-container" data-testid="thankview-video"></div>
        <div className="thankview-video">
          <div className="interaction--text" data-testid="tv-interaction">
            {message}
          </div>
          <div className="thankview-relative">
            <video
              controls
              width="300"
              src={videoUrl}
              type="video/mp4"
              data-testid="thankview-video"
              onClick={handleVideoClickForTVInteraction}
            >
              <p>
                Your browser doesn't support HTML video. Here is a<a href="videoUrl">link to the video</a> instead.
              </p>
            </video>
            <div className="thankview-content">
              {sentDate && (
                <p className="interaction-detail--type">{new Date(sentDate).toLocaleDateString("en-US")} Sent</p>
              )}
              {watchedDate && (
                <p className="interaction-detail--type">{new Date(watchedDate).toLocaleDateString("en-US")} Watched</p>
              )}
              <p className="dropdown-text-details">{pluralize(viewTimes, "Time")}</p>
            </div>
            <InteractionHyperlink links={linksinInteraction}></InteractionHyperlink>
          </div>
        </div>
      </>
    ),
    ThankViewInteractionsText: () => (
      <>
        <div className="thankview-interaction-container" data-testid="thankview-text"></div>
        <div className="thankview-video">
          <div className="interaction--text" data-testid="tv-interaction">
            {message}
          </div>
          <div className="thankview-relative">
            <div className="thankview-content">
              <InteractionHyperlink links={linksinInteraction}></InteractionHyperlink>
            </div>
          </div>
        </div>
      </>
    ),
    ThankViewInteractionsReplyWithVideo: () => (
      <>
        <div className="thankview-interaction-container" data-testid="thankview-video-reply">
          <div className="interaction--text">{replyMessage}</div>
        </div>
        <div className="thankview-video">
          <div className="thankview-relative">
            <video
              controls
              width="300"
              src={replyMessageVideo}
              type="video/mp4"
              data-testid="thankview-video"
              onClick={handleVideoClick}
            >
              <p>
                Your browser doesn't support HTML video. Here is a<a href="replyMessageVideo">link to the video</a>{" "}
                instead.
              </p>
            </video>
            <div className="thankview-content">
              <InteractionHyperlink linkResult={linkResultInReply}></InteractionHyperlink>
            </div>
          </div>
        </div>
      </>
    ),
    ThankViewInteractionsReplyWithText: () => (
      <div className="thankview-interaction-container" data-testid="thankview-text-reply">
        <div className="interaction--text">{replyMessage}</div>
        <div className="thankview-content">
          <InteractionHyperlink linkResult={linkResultInReply}></InteractionHyperlink>
        </div>
        <br />
      </div>
    ),
    Error: () => <div>Interaction or Interaction text is undefined.</div>,
  };

  return <ThankViewBuilder condition={condition} ThankViewParts={ThankViewParts} />;
};

ThankViewBuilder.propTypes = {
  condition: PropTypes.string,
  ThankViewParts: PropTypes.object,
};

ThankViewComponentBuilder.propTypes = {
  condition: PropTypes.string,
  interaction: PropTypes.object,
};
