import _ from "underscore";
import { Component } from "react";
import moment from "moment";
import Decorator from "clientDecorator";
import { connect } from "@evertrue/et-flux";
import EverTrue from "app";
import MyTripsStore from "apps/my-trips/stores/my-trips-store";
import MeetingsSource from "apps/my-trips/sources/meetings-source";
import AllUsersStore from "apps/users/stores/all-users-store";
import LocationAutocomplete from "components/controls/location-autocomplete";
import CalendarPickerDropdown from "components/controls/calendars/calendar-picker-dropdown";
import FormField from "components/forms/form-field";
import TimeRange from "components/forms/time-range";
import CalendarContainer from "apps/my-trips/components/cronofy-calendar-container";
import { LinkButton, ModalConfirm, AdvancedCombobox } from "@evertrue/et-components";
import Loading from "components/elements/loading";

const mapStateToProps = ({ tripId, timeblock }) => {
  const trip_id = tripId || timeblock.trip_id;
  return {
    trip: MyTripsStore.getTrip(trip_id),
    users: _.map(AllUsersStore.getAllGivingTreeUsers(), (user) => ({ value: user.id, label: user.name })),
  };
};

class TimeBlockForm extends Component {
  static propTypes = {
    timeblock: ReactLibs.PropTypes.object,
    headerVerb: ReactLibs.PropTypes.string,
    title: ReactLibs.PropTypes.string,
    trip: ReactLibs.PropTypes.object, // from my trips store
    users: ReactLibs.PropTypes.array, // from all users store
    remove: ReactLibs.PropTypes.func,
  };

  static defaultProps = {
    tripId: null,
    timeblock: {},
    headerVerb: "Add",
    title: "",
    trip: {}, // from my trips store
    users: [], // from all users store
  };

  state = {
    timeblock: {},
    utc_offset: undefined,
    valid_location: true,
  };

  componentDidMount() {
    this.setTimeBlock(this.props.timeblock, this.props.title);
    this.titleInput.focus();
  }

  setTimeBlock = (orig_timeblock, title) => {
    let timeblock = _.cloneData(orig_timeblock || {});
    const trip = this.props.trip;
    if (timeblock.start_at == null) {
      timeblock.start_at = moment(trip.start_at).hour(9).valueOf();
    }
    if (timeblock.end_at == null) {
      timeblock.end_at = moment(timeblock.start_at).add("1", "hour").valueOf();
    }
    timeblock = Decorator.MyTrips.parseMeeting(timeblock);
    this.setState({
      timeblock: {
        ...timeblock,
        name: timeblock.name || title,
        date: timeblock.start_datetime.date_string,
        time_start: timeblock.start_datetime.time_string,
        time_end: timeblock.end_datetime.time_string,
        anchor: timeblock.anchor,
      },
    });
  };

  handleSubmit = () => {
    const timeblock = _.cloneData(this.state.timeblock);
    const { date } = this.state.timeblock;

    const start = moment(`${date} ${timeblock.time_start}`, "YYYY-MM-DD hh:mm A").format("HH:mm");
    const end = moment(`${date} ${timeblock.time_end}`, "YYYY-MM-DD hh:mm A").format("HH:mm");

    const data = {
      name: timeblock.name,
      description: timeblock.description,
      location: timeblock.location,
      solicitor_user_ids: _.pluck(timeblock.solicitors, "id"),
      start_time: start,
      end_time: end,
      start_date: date,
      end_date: date,
      type: "timeblock",
    };
    if (timeblock.id) {
      data.id = timeblock.id;
    }
    MeetingsSource.saveTripMeeting(this.props.trip.id, data);
    this.props.remove();
  };

  handleChange = (prop, value) => {
    const timeblock = _.cloneData(this.state.timeblock || {});
    timeblock[prop] = value;
    this.setState({ timeblock });
  };

  handleTimeChange = (value) => {
    const timeblock = { ...this.state.timeblock, time_start: value.gte, time_end: value.lte };
    this.setState({ timeblock });
  };

  handleConnectedCalClick() {
    EverTrue.Navigator("/trips/calendar", true);
    this.props.remove();
  }

  renderHelperText() {
    return (
      <CalendarContainer
        render={({ hasCalendars, isLoading }) => {
          if (isLoading) {
            return Loading();
          } else if (hasCalendars) {
            return (
              <div>
                <span>Changes made to this meeting will be updated in your</span>
                <LinkButton onClick={() => this.handleConnectedCalClick()} title="connected calendars">
                  {" "}
                  connected calendars
                </LinkButton>
              </div>
            );
          }
          return null;
        }}
      />
    );
  }

  render() {
    const { timeblock } = this.state;
    const trip = this.props.trip || {};
    const valid_time = Decorator.MyTrips.hasValidTimeRange(timeblock.time_start, timeblock.time_end);

    return (
      <ModalConfirm
        className="meeting-form"
        header={`${this.props.headerVerb} Time Block`}
        hasMargin
        disableSave={!timeblock.name || !valid_time || _.isEmpty(timeblock.location)}
        onSubmit={this.handleSubmit}
        onCancel={this.props.remove}
        style={{ width: 575 }}
      >
        <FormField label="Title*">
          <input
            ref={(e) => {
              this.titleInput = e;
            }}
            type="text"
            placeholder="Enter time block name..."
            value={timeblock.name}
            onChange={(e) => this.handleChange("name", e.currentTarget.value)}
          />
        </FormField>
        <FormField label="Time*">
          <CalendarPickerDropdown
            className="meeting-form--calendar"
            value={timeblock.date || Decorator.MyTrips.getDate()}
            minDate={Decorator.MyTrips.getDate(trip.start_at)}
            maxDate={Decorator.MyTrips.getDate(trip.end_at)}
            hideClear={true}
            onChange={(date) => {
              this.handleChange("date", date);
            }}
          />
          <TimeRange
            className={!valid_time ? "meeting-form--invalid-time" : null}
            autoRange
            value={{ gte: timeblock.time_start, lte: timeblock.time_end }}
            onChange={this.handleTimeChange}
          />
        </FormField>
        <FormField label="Location*" helpKey="my_trips_location">
          <LocationAutocomplete
            type={[]}
            value={this.props.timeblock.location}
            onClear={() => {
              this.handleChange("location", null);
            }}
            onSelect={(lat_lng, place) => {
              const location = place.place_id ? { place_id: place.place_id } : lat_lng;
              this.setState({ utc_offset: place.utc_offset, valid_location: true });
              this.handleChange("location", location);
            }}
          />
        </FormField>
        <FormField label="Additional Solicitors">
          <AdvancedCombobox
            placeholder="Select Users..."
            multiple
            searchable
            value={_.map(timeblock.solicitors, (solicitor) => ({
              ...solicitor,
              value: solicitor.id,
              label: solicitor.name,
            }))}
            options={_.sortBy(this.props.users, "label")}
            onChange={(users) => {
              const solicitors = _.map(users, (user) => ({ ...user, id: user.value, name: user.label }));
              this.handleChange("solicitors", solicitors);
            }}
          />
        </FormField>
        <FormField label="Description">
          <textarea
            value={timeblock.description || ""}
            placeholder="Add Notes to your time block (optional)"
            onChange={(e) => {
              this.handleChange("description", e.currentTarget.value);
            }}
          />
        </FormField>
        <div className="modal-confirm--helper-text">{this.renderHelperText()}</div>
      </ModalConfirm>
    );
  }
}

export default connect(TimeBlockForm, [MyTripsStore, AllUsersStore], mapStateToProps);
