import PropTypes from "prop-types";
import { proposalSolicitor } from "../utils";

const ProposalTableSolicitors = ({ solicitors, userContactId }) => {
  if (!solicitors.length) return <span>-</span>;

  const userProposalContact = proposalSolicitor(solicitors, userContactId);
  const solicitorCount = solicitors.length;
  let firstSolicitor = "";
  let otherSolicitors = "";

  if (userProposalContact.id) {
    firstSolicitor = "You";
  } else {
    const primarySolicitor = solicitors.find((solicitor) => solicitor.type === "PRIMARY");
    firstSolicitor = primarySolicitor ? primarySolicitor.name : solicitors[0].name;
  }

  if (solicitorCount > 1) {
    otherSolicitors = `and ${solicitorCount - 1} other${solicitorCount > 2 ? "s" : ""}`;
  }

  return (
    <div>
      <div>{firstSolicitor}</div>
      <div>{otherSolicitors}</div>
    </div>
  );
};

ProposalTableSolicitors.propTypes = {
  solicitors: PropTypes.array.isRequired,
  userContactId: PropTypes.number,
};
export default ProposalTableSolicitors;
