import _ from "underscore";
import Api from "entities/helpers/api";
import { createContext, Component } from "react";
import Decorator from "@evertrue/client-decorator";
import PropTypes from "prop-types";
import { fetchSolicitorsInTeam } from "apps/portfolio-performance/portfolio-requests";

const PortfolioMetaDataContext = createContext(() => {});

const TEAM_KEY = "ET_PP_Team_ID";
const SOLICITOR_KEY = "ET_PP_Solicitor_ID";

class PortfolioMetaDataProvider extends Component {
  static propTypes = {
    routeTeamId: PropTypes.number,
    routeSolicitorId: PropTypes.number,
    children: PropTypes.any,
    user: PropTypes.object,
    org: PropTypes.object,
    hasViewOtherPortfolios: PropTypes.bool,
    hasViewOtherPortfoliosDXO: PropTypes.bool,
    assignmentType: PropTypes.string,
  };

  _isMounted = false;
  componentDidMount() {
    this._isMounted = true;
    this.fetchTeamData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.assignmentType !== this.props.assignmentType) {
      this.fetchTeamData();
    }
  }

  safeSetState = (...args) => {
    if (this._isMounted) {
      this.setState(...args);
    }
  };

  fetchTeamData = () => {
    Api.VOLUNTEER.POOLS.get({
      params: {
        pool_type: this.props.assignmentType,
        limit: 100,
        rollups: false,
      },
      // This is in a success callback because if the session token expires while viewing portfolios
      // it will re-up correctly from the success callback
      success: (data = {}) => {
        const teams = data.items || [];
        const sorted_teams = _.sortBy(teams, "name");
        this.safeSetState({ teams: sorted_teams });
        if (!sorted_teams.length) {
          this.setState({ team: {} });
        }
        this.determineTeam(sorted_teams);
      },
    });
  };

  determineTeam = async (teams) => {
    const stored_id = localStorage.getItem(TEAM_KEY);
    const user_aff = Decorator.User.getAffiliationFor(this.props.user, this.props.org.id);
    const user_contact_id = user_aff ? user_aff.contact_id : null;

    let team = {};
    // if the team id is in the url
    if (this.props.routeTeamId && _.findWhere(teams, { id: this.props.routeTeamId })) {
      team = _.findWhere(teams, { id: this.props.routeTeamId });
      // else if the team was saved in local storage
    } else if (stored_id && _.findWhere(teams, { id: parseInt(stored_id, 10) })) {
      team = _.findWhere(teams, { id: parseInt(stored_id, 10) });
      // else figure out what team to default to based on user's contact id's memberships
    } else if (user_contact_id) {
      // if the user has an affiliated contact id
      const resp = await Api.VOLUNTEER.MEMBERSHIP.get({
        urlArgs: {
          contact_id: user_contact_id,
          type: "solicitor",
        },
        params: { pool_type: this.state.assignment_type, sort: "pool_name" },
      });
      const sortedResults = [...resp].sort((a, b) => (a.pool_name > b.pool_name ? 1 : -1));
      const team_id = sortedResults[0] ? sortedResults[0].pool_id : null;
      team = _.findWhere(teams, { id: team_id });
      if (!team) {
        team = teams[0];
      }
    } else {
      team = teams[0];
    }
    if (team) {
      this.setTeam(team);
      this.fetchStages(team);
      this.fetchSolicitorData(team);
    }
  };

  handleSelectTeam = (selection) => {
    const team = _.findWhere(this.state.teams, { id: selection.value });
    if (team) {
      localStorage.setItem(TEAM_KEY, team.id);
      // clear solicitor selections
      this.safeSetState({ solicitors: [] });
      this.safeSetState({ solicitor: {} });

      // load new data
      this.setTeam(team);
      this.fetchStages(team);
      this.fetchSolicitorData(team);
    }
  };

  handleSelectSolicitor = (selection) => {
    const solicitor = _.findWhere(this.state.solicitors, { id: selection.value });
    if (solicitor) {
      localStorage.setItem(SOLICITOR_KEY, solicitor.id);
      this.safeSetState({ solicitor });
    }
  };

  fetchStages = (team) => {
    const id = team.stage_group_id;
    Api.VOLUNTEER.STAGES.get({
      urlExtend: `/${id}`,
      disableAlerts: true,
    })
      .then((resp) => {
        const stagesForGroup = _.chain(resp.stages)
          .filter(({ active }) => active)
          .uniq(({ stage }) => stage)
          .value();
        const active_sorted_stages = _.sortBy(stagesForGroup, "sort_order");
        const stage_names = _.pluck(active_sorted_stages, "stage");
        this.safeSetState({ stages: stage_names, stagesData: active_sorted_stages });
      })
      .catch(() => {
        this.safeSetState({ stages: [] });
      });
  };

  fetchSolicitorData = (team, search_text) => {
    this.setState({ loading_solicitor: true });
    fetchSolicitorsInTeam(team.id, search_text, undefined, this.props.assignmentType)
      .then((resp) => {
        const contacts = resp.data.contacts || [];
        const solicitors = contacts.map(({ id, contact_attributes = {} }) => {
          return { id: id, name: contact_attributes.name_full };
        });
        if (!_.isEmpty(solicitors)) {
          this.setState({ loading_solicitor: false, solicitors: solicitors });
          this.determineSolicitor(solicitors);
        } else {
          this.setState({ solicitors: [], solicitor: {}, loading_solicitor: false });
        }
      })
      .catch((err) => {
        this.setState({ loading_solicitor: false });
      });
  };

  determineSolicitor = (solicitors) => {
    const stored_id = localStorage.getItem(SOLICITOR_KEY);
    const user_aff = Decorator.User.getAffiliationFor(this.props.user, this.props.org.id);
    const user_contact_id = user_aff ? user_aff.contact_id : null;
    const isOwner = Decorator.User.isOwner(this.props.user, this.props.org.id);
    const isSuperUser = this.props.user.super_user;

    let solicitor = {};

    // if the solicitor id is in the url
    if (this.props.routeSolicitorId && _.findWhere(solicitors, { id: this.props.routeSolicitorId })) {
      solicitor = _.findWhere(solicitors, { id: this.props.routeSolicitorId }) || {};
      // else if the solicitor id is in local storage
    } else if (stored_id && _.findWhere(solicitors, { id: parseInt(stored_id, 10) })) {
      solicitor = _.findWhere(solicitors, { id: parseInt(stored_id, 10) }) || {};
      // if no solicitor has been explicitly selected, try using the user's contact id
    } else if (user_contact_id && _.findWhere(solicitors, { id: user_contact_id })) {
      solicitor = _.findWhere(solicitors, { id: user_contact_id }) || {};
      // if user is an owner or gate is on so the user can see all solicitors, then default to first solicitor result
    } else if (
      isOwner ||
      isSuperUser ||
      (this.props.hasViewOtherPortfolios && this.props.assignmentType === "TEAM") ||
      (this.props.hasViewOtherPortfoliosDXO && this.props.assignmentType === "DXO")
    ) {
      solicitor = solicitors[0];
    }
    this.safeSetState({ solicitor });
  };

  setSort = (sort, reverse) => {
    this.setState({ sort_prop: sort, sort_reverse: reverse });
  };

  setTotal = (total) => {
    this.setState({ total });
  };

  setDisableDrag = (tempDisableDrag) => {
    this.setState({ tempDisableDrag });
  };

  setTeam = (team) => {
    this.setState({ team });
  };

  setTeams = (teams) => {
    this.setState({ teams });
  };

  state = {
    team: {},
    teams: [],
    stages: [],
    stagesData: [],
    selectTeam: this.handleSelectTeam,
    selectSolicitor: this.handleSelectSolicitor,
    total: 0,
    loading_solicitor: true,
    solicitor: {},
    solicitors: [],
    sort_prop: "name_last",
    sort_reverse: false,
    setSort: this.setSort,
    setTotal: this.setTotal,
    tempDisableDrag: false,
    setDisableDrag: this.setDisableDrag,
    fetchTeamData: this.fetchTeamData,
  };

  render() {
    return (
      <PortfolioMetaDataContext.Provider value={{ ...this.state }}>
        {this.props.children}
      </PortfolioMetaDataContext.Provider>
    );
  }
}

export { PortfolioMetaDataProvider };

export default PortfolioMetaDataContext;
