import _ from "underscore";
import { createStore } from "@evertrue/et-flux";
import ProfileAssignmentSource from "apps/profile/sources/profile-assignment-source";
import CachedContactSource from "apps/contact/sources/cached-contact-source";
import CachedContactStore from "apps/contact/stores/cached-contact-store";
import Decorator from "clientDecorator";
import get from "lodash.get";
import { VOLUNTEER_LEAD_ROLE } from "apps/volunteers/constants";

export default createStore("ProfileAssignmentStore", {
  getInitialState() {
    return {
      vol_membership_by_contact: {},
      team_membership_by_contact: {},
      dxo_membership_by_contact: {},
      solicitor_names: {},
      assignment_titles: [],
    };
  },

  registerActions() {
    this.on(ProfileAssignmentSource.actions.fetchedVolunteerMemberships, (id, membership_by_role) => {
      const vol_membership_by_contact = _.cloneData(this.getState("vol_membership_by_contact"));
      vol_membership_by_contact[id] = membership_by_role;
      this.setState({ vol_membership_by_contact });

      CachedContactSource.fetchContactsByIds(_.pluck(membership_by_role.prospects, "solicitor_contact_id"));
    });

    this.on(ProfileAssignmentSource.actions.fetchedTeamMemberships, (id, membership_by_role) => {
      const team_membership_by_contact = _.cloneData(this.getState("team_membership_by_contact"));
      team_membership_by_contact[id] = membership_by_role;
      this.setState({ team_membership_by_contact });

      CachedContactSource.fetchContactsByIds(_.pluck(membership_by_role.prospects, "solicitor_contact_id"));
    });

    this.on(ProfileAssignmentSource.actions.fetchedDxoMemberships, (id, membership_by_role) => {
      const dxo_membership_by_contact = _.cloneData(this.getState("dxo_membership_by_contact"));
      dxo_membership_by_contact[id] = membership_by_role;
      this.setState({ dxo_membership_by_contact });

      CachedContactSource.fetchContactsByIds(_.pluck(membership_by_role.prospects, "solicitor_contact_id"));
    });

    this.on(ProfileAssignmentSource.actions.fetchedAssignmentTitles, (assignment_titles) => {
      this.setState({ assignment_titles });
    });

    this.on(CachedContactSource.actions.changedContacts, () => {
      if (this.hasListeners()) {
        const vol_prospects = _.flatten(_.pluck(this.state.vol_membership_by_contact, "prospects"));
        const team_prospects = _.flatten(_.pluck(this.state.team_membership_by_contact, "prospects"));
        const dxo_prospects = _.flatten(_.pluck(this.state.dxo_membership_by_contact, "prospects"));
        const all_prospects = _.union(vol_prospects, team_prospects, dxo_prospects);
        const solicitor_ids = _.uniq(_.pluck(all_prospects, "solicitor_contact_id"));

        const solicitor_names = _.cloneData(this.state.solicitor_names);
        _.each(solicitor_ids, (id) => {
          const contact = CachedContactStore.getContactById(id);
          if (contact) {
            solicitor_names[contact.id] = Decorator.Contacts.getFullName(contact);
          }
        });

        this.setState({ solicitor_names });
      }
    });
  },

  getSortOrder(title) {
    const assignment_title_obj = _.findWhere(this.state.assignment_titles, { title: title });
    return assignment_title_obj ? assignment_title_obj.sort_order : 100;
  },

  api: {
    getVolunteerMembershipByContact(id) {
      return this.getState("vol_membership_by_contact")[id] || {};
    },

    getAssignedVolunteerMemberships(id) {
      const prospects = this.getVolunteerMembershipByContact(id).prospects;
      return prospects && prospects.filter((prospect) => !!prospect.solicitor_contact_id);
    },

    getTeamMembershipByContact(id) {
      return this.getState("team_membership_by_contact")[id] || {};
    },

    getDxoMembershipByContact(id) {
      return this.getState("dxo_membership_by_contact")[id] || {};
    },

    getAssignedTeamMemberships(id) {
      const prospects = this.getTeamMembershipByContact(id).prospects;
      return prospects && prospects.filter((prospect) => !!prospect.solicitor_contact_id);
    },

    getAssignedDxoMemberships(id) {
      const prospects = this.getDxoMembershipByContact(id).prospects;
      return prospects && prospects.filter((prospect) => !!prospect.solicitor_contact_id);
    },

    // Group membership and roles by volunteer pool
    getVolunteerMembership(id) {
      const membership_by_pool = {};

      _.each(this.getVolunteerMembershipByContact(id), (memberships, role) => {
        _.each(memberships, (membership) => {
          const { pool_id, pool_name, solicitor_contact_id, solicitor_role } = membership;
          const labels = get(membership_by_pool[pool_id], "labels") || [];

          let label = solicitor_role === VOLUNTEER_LEAD_ROLE ? "Volunteer Lead" : "Volunteer Solicitor";

          if (role === "prospects") {
            const name = this.state.solicitor_names[solicitor_contact_id];
            label = name ? `Assigned to ${name}` : "Unassigned";
          }

          membership_by_pool[pool_id] = {
            id: pool_id,
            name: pool_name,
            route: `/volunteers/pool/${pool_id}`,
            type: "pool",
            is_editable: false,
            labels: _.uniq(_.compact(labels.concat(label))),
          };
        });
      });

      return _.sortBy(membership_by_pool, "name");
    },

    // Group membership and roles by team
    getTeamMembership(id) {
      const membership_by_team = {};

      _.each(this.getTeamMembershipByContact(id), (memberships, role) => {
        _.each(memberships, (membership) => {
          const { pool_id, pool_name, solicitor_contact_id } = membership;
          const labels = get(membership_by_team[pool_id], "labels") || [];

          let label = "Solicitor";
          if (role === "prospects") {
            const name = this.state.solicitor_names[solicitor_contact_id];
            label = name ? `Assigned to ${name}` : "Unassigned";
          }

          membership_by_team[pool_id] = {
            id: pool_id,
            name: pool_name,
            route: `/portfolio-performance/${pool_id}/portfolio`,
            type: "team",
            is_editable: false,
            labels: _.uniq(_.compact(labels.concat(label))),
          };
        });
      });

      return _.sortBy(membership_by_team, "name");
    },

    getVolunteerAssignmentsForProfile(id) {
      const membership_by_solicitor = this.getAssignedVolunteerMemberships(id);
      const solicitors = this.state.solicitor_names;
      const vol_assignments = [];
      membership_by_solicitor &&
        membership_by_solicitor.forEach((membership) => {
          const num_solicitor_id = parseInt(membership.solicitor_contact_id, 10);
          if (solicitors[num_solicitor_id]) {
            vol_assignments.push({
              is_volunteer_assignment: true,
              name: solicitors[num_solicitor_id],
              solicitor_contact_id: num_solicitor_id,
              membership: membership,
            });
          }
        });
      return vol_assignments;
    },

    getTeamAssignmentsForProfile(id) {
      const membership_by_solicitor = this.getAssignedTeamMemberships(id);
      const solicitors = this.state.solicitor_names;
      const assignments = [];
      membership_by_solicitor &&
        membership_by_solicitor.forEach((membership) => {
          const num_solicitor_id = parseInt(membership.solicitor_contact_id, 10);
          if (solicitors[num_solicitor_id]) {
            assignments.push({
              is_assignment: true,
              name: solicitors[num_solicitor_id],
              solicitor_contact_id: num_solicitor_id,
              membership: membership || {},
              sort: this.getSortOrder(membership.assignment_title),
            });
          }
        });
      return assignments.sort((a, b) => {
        return a.sort < b.sort ? -1 : 1;
      });
    },

    getDxoAssignmentsForProfile(id) {
      const membership_by_solicitor = this.getAssignedDxoMemberships(id);
      const solicitors = this.state.solicitor_names;
      const assignments = [];
      membership_by_solicitor &&
        membership_by_solicitor.forEach((membership) => {
          const num_solicitor_id = parseInt(membership.solicitor_contact_id, 10);
          if (solicitors[num_solicitor_id]) {
            assignments.push({
              is_assignment: true,
              name: solicitors[num_solicitor_id],
              solicitor_contact_id: num_solicitor_id,
              membership: membership || {},
              sort: this.getSortOrder(membership.assignment_title),
            });
          }
        });
      return assignments.sort((a, b) => {
        return a.sort < b.sort ? -1 : 1;
      });
    },
  },
});
