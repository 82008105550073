module.exports = do ->
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   AlertStore = require("apps/layout/stores/alert-store")
   AlertSource = require("apps/layout/sources/alert-source")
   {ul, li} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   ToastAlert = createFactory require("apps/layout/components/toast-alert")


   createComponent "AlertController",
      registerStores: ->
         @on AlertStore, ->
            toasts: AlertStore.getAlerts()

      handleClose: (toast) ->
         AlertSource.delete(toast.id)

      render: ->
         ul className: "toast-container",
            @state.toasts.map (toast) =>
               li key: toast.id,
                  ToastAlert
                     className: toast.className
                     type: toast.type
                     icon: toast.icon
                     header: toast.header
                     timeout: toast.timeout
                     actionLabel: toast.actionLabel
                     willBeRemoved: toast.willBeRemoved
                     onUndo: toast.onUndo
                     onAction: toast.onAction
                     onClose: => @handleClose(toast)

                     toast.message
