module.exports = do ->
   classNames = require("classnames")
   React = require("react")
   {span, input} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   Icon = createFactory (require("components/elements/icon").default)
   {createComponent} = require("@evertrue/et-flux")
   LinkButton = createFactory(require("@evertrue/et-components").LinkButton)

   createComponent "ControlledSearchBox",
      propTypes:
         className: ReactLibs.PropTypes.string
         name: ReactLibs.PropTypes.string
         placeholder: ReactLibs.PropTypes.string
         value: ReactLibs.PropTypes.any
         hideSearchIcon: ReactLibs.PropTypes.bool
         label: ReactLibs.PropTypes.string
         onKeyUp: ReactLibs.PropTypes.func # (value, e)
         onChange: ReactLibs.PropTypes.func # (value, e)
         onFocus: ReactLibs.PropTypes.func
         onBlur: ReactLibs.PropTypes.func
         onClear: ReactLibs.PropTypes.func

      handleClear: (e) ->
         @props.onClear?()

      handleKeyUp: (e) ->
         @props.onKeyUp?(e.target.value, e)

      handleChange: (e) ->
         @props.onChange?(e.target.value, e)

      render: ->
         span
            className: classNames("search-box", @props.className)

            Icon className: "search-box--search-icon", icon: "search"

            if @props.value
               LinkButton title: "Clear search box", onClick: @handleClear,
                  Icon className: "search-box--clear", icon: "cancel"

            input
               type: "search"
               ref: "search"
               className: "search-box--input" # Used in jasmine tests
               label: @props.placeholder || @props.name
               name: @props.name
               value: @props.value
               placeholder: @props.placeholder
               onFocus: @props.onFocus
               onBlur: @props.onBlur
               onKeyUp: @handleKeyUp
               onChange: @handleChange
