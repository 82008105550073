/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import _ from "underscore";
import EverTrue from "app";
import { useFluxStore } from "@evertrue/et-flux";
import ContactColumnStore from "apps/contact/stores/contact-column-store";
import ContactTableStore from "apps/contact/stores/contact-table-store";
import WindowSource from "apps/layout/sources/window-source";
import { Loading, Checkbox, Icon } from "@evertrue/et-components";
import EmptyStateMessage from "components/lists/empty-state-message";
import FixedTable from "components/tables/fixed-table";
import TableHeaderDropdown from "components/tables/table-header-dropdown";
import { gqlCall } from "entities/helpers/gqlCall";
import enrichmentQuery from "apps/contact/components/contacts/enrichment-query";
import SelectedContactsSource from "apps/contact/sources/selected-contacts-source";
import { GlobalContactSearchContext } from "apps/layout/controllers/global-contact-search-context";

const contactColumnMapState = () => ({ active_columns: ContactColumnStore.getAppliedColumns() });
const contactTableMapState = () => ({ column_config: ContactTableStore.getColumnConfig() });

const ContactFixedTable = ({
  contacts = {},
  extraColumns,
  loading,
  onSort,
  topPosition,
  className = "",
  height,
  footer,
  showCheckbox = false,
  numSelected = 0,
  emptyStateMessage = "No results match your current filters",
  actionLabel,
  onAction,
  enrichmentLoading,
  setEnrichmentLoading = () => {},
}) => {
  const { active_columns } = useFluxStore(ContactColumnStore, contactColumnMapState);
  const { column_config } = useFluxStore(ContactTableStore, contactTableMapState);
  const { setIsAllSelectedCheckbox, isAllSelectedCheckbox } = useContext(GlobalContactSearchContext);

  const [contactsWithEnrich, setContactsWithEnrich] = useState(contacts.items || []);
  const showLoading = loading || enrichmentLoading;

  useEffect(() => {
    const ids = _.pluck(contacts.items, "id");

    if (ids.length && !loading) {
      gqlCall(enrichmentQuery(), { ids: ids }).then((results) => {
        /*
             Occasionally on staging graphQL errors out if it is getting hit too frequently
             This just assigns state to contacts without enrichment data in those cases
           /*/
        if (results.data.contacts) {
          setContactsWithEnrich(
            _.map(results.data.contacts, (item) => {
              const contact = _.findWhere(contacts.items, { id: item.id });
              return Object.assign(_.clone(contact), { enrichment: item.enrichment });
            })
          );
        } else {
          setContactsWithEnrich(contacts.items);
        }
        setEnrichmentLoading(false);
      });
    } else if (!loading) {
      setContactsWithEnrich(contacts.items);
      setEnrichmentLoading(false);
    }
  }, [contacts]);

  useEffect(() => {
    setIsAllSelectedCheckbox(false);
  }, [setIsAllSelectedCheckbox]);

  const handleSort = (prop, reverse) => {
    WindowSource.outsideClick();
    if (prop === "et_score.dds_score") {
      const type = reverse ? "sort_descending" : "sort_ascending";
      EverTrue.track.set("dps_action", { type });
    }
    onSort(prop, reverse);
  };

  const renderColumnDropdown = (col) => {
    return (
      <TableHeaderDropdown
        column={col}
        sort={_.pick(contacts, "sortProp", "sortReverse")}
        onSort={handleSort}
        is_editable={true}
      />
    );
  };

  const handleSelectAllCheckBox = () => {
    const contactIds = contacts.items.map((contact) => contact.id);
    if (!isAllSelectedCheckbox) {
      SelectedContactsSource.select(contactIds);
    } else {
      SelectedContactsSource.selectAll(false);
      SelectedContactsSource.unselect(contactIds);
    }
    setIsAllSelectedCheckbox(!isAllSelectedCheckbox);
  };

  const getTableConfig = () => {
    let column_keys = _.pluck(active_columns, "key");
    if (extraColumns) {
      column_keys = extraColumns.concat(column_keys);
    }
    const columns = ["checkbox", "constituent", ...column_keys, "spacer"];

    return _.compact(
      _.map(columns, (key) => {
        const config = _.clone(column_config[key]);
        if (!config) return;
        if (!config.label) {
          const activeKey = _.findWhere(active_columns, { key: key });
          config.label = activeKey && activeKey.label;
          config.toolTip = activeKey && activeKey.toolTip;
        }
        if (key === "checkbox" && showCheckbox)
          return _.extend(
            {},
            config,
            { key },
            {
              label: numSelected ? (
                <Icon
                  icon="minus-box"
                  size={2}
                  className="contact-fixed-table--minus"
                  onClick={() => {
                    setIsAllSelectedCheckbox(false);
                    SelectedContactsSource.clearAllSelectedState();
                  }}
                />
              ) : (
                <Checkbox checked={isAllSelectedCheckbox} label="selectAll" onChange={handleSelectAllCheckBox} />
              ),
            }
          );
        return _.extend({}, config, { key }, { label: renderColumnDropdown(config) });
      })
    );
  };
  const config = getTableConfig();

  return (
    <div
      style={{ height, top: topPosition }}
      className={`contact-fixed-table contacts-table has-header-dropdown ${className}`}
    >
      {showLoading ? (
        <Loading />
      ) : _.isEmpty(contacts.items) ? (
        <div className="loading">
          <EmptyStateMessage icon="ban" text={emptyStateMessage} actionLabel={actionLabel} onAction={onAction} />
        </div>
      ) : (
        <div className="contact-fixed-table--body">
          <FixedTable
            data={contactsWithEnrich}
            config={config}
            emptyMessage=""
            appendSpaceColumn={false}
            footer={footer}
          />
        </div>
      )}
    </div>
  );
};

ContactFixedTable.propTypes = {
  contacts: PropTypes.object,
  extraColumns: PropTypes.array,
  loading: PropTypes.bool,
  showCheckbox: PropTypes.bool,
  onSort: PropTypes.func,
  topPosition: PropTypes.string,
  className: PropTypes.string,
  height: PropTypes.string,
  footer: PropTypes.any,
  numSelected: PropTypes.number,
  enrichmentLoading: PropTypes.bool,
  setEnrichmentLoading: PropTypes.func,

  emptyStateMessage: PropTypes.string,
  actionLabel: PropTypes.string,
  onAction: PropTypes.func,
};

export default ContactFixedTable;
