module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")

   _initialized = false

   createSource "ListSource",
      actions:
         loadingLists: true
         resetLists: true
         fetchedLists: (lists) ->
            @require _.isPagedObject(lists), "lists should be a paged object"
         createdList: (list) ->
            @require _.isObject(list), "list should be object"
         updatedList: (list) ->
            @require _.isObject(list), "list should be object"
         updatedPreferences: (pref) ->
            @require _.isObject(pref), "preferences should be object"
         deletedList: (list_id) ->
            @require _.isNumber(list_id), "list_id should be number"
         selectedList: (id) ->
            @require _.isNumber(id) || _.isUndefined(id), "list id should be number or undefined"
         fetchedFavorites: (list) ->
            @require _.isObject(list), "list should be object"
         fetchedViewedLists: (viewed) ->
            @require _.isObject(viewed) || _.isEmpty(viewed), "viewed is object or is empty"
         fetchedListOwners: (users) ->
            @require _.isArray(users), "users is an array"
         createdbulkAddToList: (list_id, filter_params, count, isFiltersV2) ->
            @require _.isObject list_id, "list_id should be an object"
            @require _.isObject filter_params, "filter_params should be an object"

      initialize: ->
         return if _initialized
         _initialized = true

      api:
         fetch: ->
            @actions.loadingLists(true)

            Api.CONTACTS.LISTS.get
               params: limit: 1000
               success: (resp) =>
                  @actions.loadingLists(false)
                  @actions.fetchedLists(resp)

         create: (list_data, contact_ids) ->
            @actions.createdList _.extend {}, list_data,
               id: "temp"
               user_id: EverTrue.store.user.get("id")

            Api.CONTACTS.LISTS.post
               data: _.jsonStringify(_.omit(list_data, "id", "preferences"))
               success: (list) =>
                  @actions.createdList(_.extend({}, list,
                     temp_id: "temp"
                     preferences: list_data.preferences
                  ), contact_ids)

         bulkAddToListCreate: (list_data, count, filter_params, isFiltersV2) ->
            @actions.createdList _.extend {}, list_data,
               id: "temp"
               user_id: EverTrue.store.user.get("id")
            
            Api.CONTACTS.LISTS.post
               data: _.jsonStringify(_.omit(list_data, "id", "preferences"))
               success: (list) =>
                  @actions.createdList(_.extend({}, list,
                     temp_id: "temp"
                     preferences: list_data.preferences
                  ), null)
                  @actions.createdbulkAddToList({
                     value: list.id
                     label: list_data.name
                     count
                  }, filter_params, count, isFiltersV2)

         update: (list_data) ->
            @actions.updatedList(_.extend {}, list_data, {optimistic: true, saving: true})

            Api.CONTACTS.LISTS.put
               urlExtend: "/#{list_data.id}"
               data: _.jsonStringify(_.omit(list_data, "preferences"))
               success: (list) =>
                  @actions.updatedList(_.extend list, {saving: false})

         delete: (id) ->
            Api.CONTACTS.LISTS.delete
               urlExtend: "/#{id}"
               success: =>
                  @actions.deletedList(_.toNumber(id))
                  EverTrue.Navigator('/lists', true)

         select: (id) ->
            list_id = if _.isEmpty(id) then undefined else _.toNumber(id)
            @actions.selectedList(list_id)

         reset: ->
            @actions.resetLists()

         fetchFavorites: ->
            Api.CONTACTS.LIST_FAVORITES.get
               success: (resp) =>
                  @actions.fetchedFavorites(resp)

         fetchListOwners: (user_ids) ->
            ids = _.uniq(_.compact(user_ids))
            unless _.isEmpty(ids)
               Api.SEARCH.USER.post
                  data: _.jsonStringify must: [{id: in: ids}]
                  success: (user_resp) =>
                     @actions.fetchedListOwners(user_resp.items)

         fetchViewed: ->
            Api.DNA.SETTING.get
               urlArgs: setting_key: "GT_recent_lists"
               disableAlerts: true
               params:
                  user_id: "me"
                  oid: null
               success: (resp) =>
                  data = resp?.settings?.GT_recent_lists?.value
                  @actions.fetchedViewedLists(data)
               error: =>
                  @actions.fetchedViewedLists()

         saveView: (object) ->
            unless EverTrue.request("isImpersonating?")
               Api.DNA.SETTING.put
                  urlArgs: setting_key: "GT_recent_lists"
                  disableAlerts: true
                  params:
                     user_id: "me"
                     oid: null
                  data: _.jsonStringify
                     value: object
