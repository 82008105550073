import { Component } from "react";
import _ from "underscore";
import PropTypes from "prop-types";
import { connect } from "@evertrue/et-flux";
import Decorator from "clientDecorator";
import { AdvancedCombobox } from "@evertrue/et-components";
import InteractionSource from "apps/interactions/sources/interaction-source";
import InteractionStore from "apps/interactions/stores/interaction-store";
import PoolsResolver from "apps/volunteers/components/pools-resolver";
import FeatureStore from "apps/layout/stores/feature-store";
import CachedContactsResolver from "apps/contact/components/contacts/cached-contacts-resolver";

const mapStateToProps = () => ({
  labels: InteractionStore.getLabels(),
  label_options: InteractionStore.getLabelValues(),
  has_fetched_labels: InteractionStore.hasFetchedLabels(),
  has_interaction_custom_fields: FeatureStore.getFeature("interaction_custom_fields"),
  has_volunteers: FeatureStore.getFeature("volunteers"),
});

const VOL_POOL_LABEL = "Volunteer Pool ID";
const VOL_CONTACT_LABEL = "Volunteer Contact ID";
const VOL_LABELS = [VOL_POOL_LABEL, VOL_CONTACT_LABEL];

class InteractionLabelFilter extends Component {
  static propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    labels: PropTypes.array,
    label_options: PropTypes.object,
    has_fetched_labels: PropTypes.bool,
    has_interaction_custom_fields: PropTypes.bool,
    has_volunteers: PropTypes.bool,
  };

  static defaultProps = {
    value: { label: {}, values: [] },
    labels: [],
    label_options: {},
  };

  componentDidMount() {
    if (!this.props.has_fetched_labels) {
      InteractionSource.fetchLabels();
    }
    this.fetchValuesForLabel();
  }

  fetchValuesForLabel = label => {
    const label_obj = label || this.props.value.label;
    if (label_obj.value) {
      InteractionSource.fetchValuesForLabel(label_obj.value);
    }
  };

  handleChangeName = (selected_label = {}) => {
    this.props.onChange({ label: selected_label, values: [] });
    this.fetchValuesForLabel(selected_label);
  };

  handleChangeValue = value => {
    this.props.onChange({ label: this.props.value.label, values: value });
  };

  renderSelectedLabelOptions = () => {
    const { label_options, value } = this.props;
    const label_obj = value.label;
    const label_key = label_obj.value;

    const selected_label_options = label_options[label_key] || [];

    if (label_key === VOL_CONTACT_LABEL) {
      return (
        <CachedContactsResolver ids={_.pluck(selected_label_options, "value")}>
          {({ contacts = {} }) => {
            const options = _.map(contacts, (val, key) => ({
              value: val.id,
              label: Decorator.Contacts.getFullName(val),
            }));
            return (
              <AdvancedCombobox
                value={_.map(value.values, val_obj => ({ label: val_obj.label, value: parseInt(val_obj.value, 10) }))}
                searchable
                multiple
                options={_.sortBy(options, "label")}
                onChange={this.handleChangeValue}
              />
            );
          }}
        </CachedContactsResolver>
      );
    } else if (label_key === VOL_POOL_LABEL) {
      return (
        <PoolsResolver ids={_.pluck(selected_label_options, "value")}>
          {({ pools }) => {
            const options = _.map(pools, (val = {}, key) => ({
              value: val.id,
              label: val.name,
            }));
            return (
              <AdvancedCombobox
                value={_.map(value.values, val_obj => ({ label: val_obj.label, value: parseInt(val_obj.value, 10) }))}
                searchable
                multiple
                options={_.sortBy(options, "label")}
                onChange={this.handleChangeValue}
              />
            );
          }}
        </PoolsResolver>
      );
    } else {
      return (
        <AdvancedCombobox
          value={value.values}
          searchable
          multiple
          options={selected_label_options}
          onChange={this.handleChangeValue}
        />
      );
    }
  };

  render() {
    const { labels, value } = this.props;
    let labels_to_show = labels;

    // If we're using interaction custom fields, but have volunteers,
    // then we'd still like to be able to filter on volunteer contact id or volunteer pool id
    if (this.props.has_interaction_custom_fields && this.props.has_volunteers) {
      labels_to_show = _.filter(labels, label => _.contains(VOL_LABELS, label));
    }

    const label_obj = value.label;
    const label_key = label_obj.value;

    return (
      <div className="interaction-label-filter">
        <div className={`interaction-label-filter--name ${label_key ? "is-selected" : ""}`}>
          <AdvancedCombobox
            value={label_obj}
            searchable
            options={_.map(labels_to_show, label => ({ value: label, label }))}
            onChange={this.handleChangeName}
          />
        </div>
        {!_.isEmpty(label_obj) && (
          <div className="interaction-label-filter--value">{this.renderSelectedLabelOptions()}</div>
        )}
      </div>
    );
  }
}

export default connect(
  InteractionLabelFilter,
  [InteractionStore, FeatureStore],
  mapStateToProps
);
