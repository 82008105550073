import { Modal, ModalBody, ModalHeader, Button } from "@evertrue/et-components";
import EverTrue from "app";
import { useOpenClose } from "@evertrue/et-components";
import { useContext } from "react";
import { PinnedCommentsContext } from "apps/profile/contexts/pinned-comments-context"
import PinnedCommentsFeed from "apps/profile/components/pinned-comments/pinned-comments-feed";

const ViewAllPinnedCommentsModal = () => {
  const { comments } = useContext(PinnedCommentsContext);
  const [isModalOpen, openModal, closeModal] = useOpenClose();

  const handleClick =()=>{
    EverTrue.track.set("pinned_comment_action", { type: "see_all_pinned" })
    isModalOpen ? closeModal() : openModal()
  }
  
  return (
    <>
      {(comments && comments.length > 3) && (
        <div className="pinned-comments-controller--footer">
          <div>
            <Button type="simple" onClick={handleClick}>
              View All
            </Button>
            <Modal isOpen={isModalOpen} closeModal={closeModal} closeOnOutsideClick={true}>
              <ModalHeader title="Pinned Comments" closeModal={closeModal} />
              <ModalBody>
                <PinnedCommentsFeed />
              </ModalBody>
            </Modal>
          </div>
        </div>
      )}
    </>
  )
};

export default ViewAllPinnedCommentsModal;