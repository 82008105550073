import { useState } from "react";
import PropTypes from "prop-types";
import { useOrg } from "base/org/with-org";
import { Modal, ModalHeader, ModalBody, Button } from "@evertrue/et-components";
import PoolsSource from "apps/volunteers/sources/pools-source";
import { CONFIRM_STRING, FORMATTED_CONFIRM_STRING } from "apps/volunteers/constants";

const DeletePoolModal = ({ isOpen, closeModal, pool = {} }) => {
  const [confirmString, setConfirmString] = useState("");
  const org = useOrg() || {};
  const disableSubmit = confirmString.toLowerCase().trim() !== CONFIRM_STRING;

  const onSubmit = () => {
    PoolsSource.deleteAndNavigate(pool.id, "/volunteers");
    closeModal();
  };

  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      <ModalHeader title={`Confirm Deletion of "${pool.name}"`} closeModal={closeModal} />
      <ModalBody>
        <div className="confirm-reset-pools-modal--disclaimer">
          You must agree to confirm that this action will be completed. Please type {FORMATTED_CONFIRM_STRING} to
          complete these actions.
        </div>
        <div className="confirm-reset-pools-modal--confirm-string">
          This will impact all {org.name} users and cannot be undone.
        </div>
        <div className="confirm-reset-pools--actions">
          <input
            type="text"
            placeholder={FORMATTED_CONFIRM_STRING}
            className="confirm-reset-pools-modal--input"
            value={confirmString}
            onChange={({ target: { value: confirmString } }) => setConfirmString(confirmString)}
          />
          <Button type="default" disabled={disableSubmit} onClick={onSubmit}>
            Confirm
          </Button>
          <Button type="secondary" onClick={closeModal}>
            Cancel
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

DeletePoolModal.propTypes = {
  pool: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default DeletePoolModal;
