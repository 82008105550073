
export function cleanData(thankviewEngagements) {
    const allCleanedData = [];
    thankviewEngagements.forEach((engagement) => {
      const cleanedData = {};
      cleanedData.id = engagement.id;
      cleanedData.date_occurred = engagement.date_occurred;
      engagement.custom_fields.forEach((field) => {
        cleanedData[field.meta_data.display_name] = field.value;
      });
      allCleanedData.push(cleanedData);
    });
    return allCleanedData;
}
