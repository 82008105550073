import journeysQueries from "../gql/queries";
import { fetchFromGraphql } from "entities/helpers/gqlCall";
import EverTrue from "app";
import Api from "entities/helpers/api";
import ErrorLogger from "entities/helpers/error-log-helper";

export const fetchJourneyGroups = async (journeyGroupType) => {
  const query = journeysQueries.fetchJourneyGroups;
  const queryVars = { journeyGroupType, includeInactive: false };
  const { journeyGroups } = await fetchFromGraphql(query, queryVars, "journeyGroups");

  return journeyGroups;
};

// returns an array of Journey objects with the tasks_due_counts fields for the given date.
/**
 *
 * @param {Number} selectedJourneyGroupId
 * @param {Number} taskOwnerUserId
 * @param {Array of Strings} taskStatuses // eg ["IN_PROGRESS"]
 * @param {Date} selectedDate
 * @returns
 [
  { 
    id: 1, 
    name: "Introduction", 
    tasks_due_counts: {
      total: 0,
      completed: 0,
      Email: 0,
      Call: 0,
      Other: 0,
      LinedIn: 0,
      Print Mail: 0,
      ThankView: 0,
      Text: 0,
    } 
  },
  { 
    id: 2, 
    name: "Solicitation", 
    tasks_due_counts: {
      total: 0,
      completed: 0,
      Email: 0,
      Call: 0,
      Other: 0,
      LinedIn: 0,
      Print Mail: 0,
      ThankView: 0,
      Text: 0,
    } 
  },
 ]
 */
export const fetchJourneysWithDueDatesForGroup = async (
  journeyGroupId,
  taskOwnerUserId,
  taskStatuses,
  selectedDate
) => {
  if (!journeyGroupId) return [];

  const query = journeysQueries.fetchJourneysWithDueDatesForGroup;
  const queryVars = {
    journeyGroupId,
    taskOwnerUserId,
    taskStatuses,
    selectedDate,
  };

  const { journeysForJourneyGroup } = await fetchFromGraphql(query, queryVars, "journeysForJourneyGroup");
  return journeysForJourneyGroup;
};

//Fetches counts for today/next five days for Journey and Other Tasks based on day range field.
export const fetchUpcomingTaskCounts = async (
  taskOwnerUserId,
  taskStatuses,
  includeInactive,
  fetchJourneysTaskCount,
  dayRange
) => {
  const query = journeysQueries.fetchUpcomingTaskCounts;
  const queryVars = {
    taskOwnerUserId,
    taskStatuses,
    includeInactive,
    fetchJourneysTaskCount,
    dayRange,
  };

  const { getUpcomingTaskCounts } = await fetchFromGraphql(query, queryVars, "getUpcomingTaskCounts");
  return getUpcomingTaskCounts;
};

// returns an array of Journey objects for the given journeyGroupId
// the only difference is that this query does not include the tasks_due_counts field
const fetchJourneysForGroup = async (journeyGroupId, userId) => {
  const query = journeysQueries.fetchJourneysForGroup;
  const queryVars = { journeyGroupId, userId };
  const { journeysForJourneyGroup } = await fetchFromGraphql(query, queryVars, "journeysForJourneyGroup");

  return journeysForJourneyGroup;
};

export const fetchJourneysForTable = async (tabId, dueDate, userId, statuses) => {
  if (tabId === 1) {
    return await fetchJourneysForUsersJourneyGroup(userId, statuses, dueDate);
  } else if (tabId === 2) {
    return await fetchFavoriteJourneys(userId, statuses, dueDate);
  } else if (tabId === 3) {
    return await fetchAllJourneysForAllJourneyGroups(userId, statuses, dueDate, true);
  }
};

// all args are optional
// if args are provided and includeTaskCounts is true, then the tasks_due_counts field will be included in the response
export const fetchAllJourneysForAllJourneyGroups = async (
  taskOwnerUserId,
  taskStatuses,
  date,
  includeTaskCounts = false
) => {
  const groups = await fetchJourneyGroups();

  return (
    await Promise.all(
      groups.map(async (group) => {
        if (includeTaskCounts)
          return await fetchJourneysWithDueDatesForGroup(group.id, taskOwnerUserId, taskStatuses, date);
        else return await fetchJourneysForGroup(group.id, taskOwnerUserId);
      })
    )
  ).flat();
};

export const fetchJourneysForUsersJourneyGroup = async (taskOwnerUserId, taskStatuses, selectedDate) => {
  if (!taskOwnerUserId) return [];
  const query = journeysQueries.fetchJourneysForUsersJourneyGroup;
  const queryVars = {
    taskOwnerUserId,
    taskStatuses,
    selectedDate,
  };

  const { journeysForUser } = await fetchFromGraphql(query, queryVars, "journeysForUser");
  return journeysForUser;
};

export const fetchFavoriteJourneys = async (taskOwnerUserId, taskStatuses, selectedDate) => {
  if (!taskOwnerUserId) return [];
  const query = journeysQueries.fetchFavoriteJourneys;
  const queryVars = {
    taskOwnerUserId,
    taskStatuses,
    selectedDate,
  };

  const { favoriteJourneys } = await fetchFromGraphql(query, queryVars, "favoriteJourneys");
  return favoriteJourneys;
};

//createFavoriteJourney need query param
export const createFavoriteJourney = async (journeyId) => {
  return await Api.JOURNEYS.JOURNEY_FAVORITES.post({
    params: { journey_id: journeyId },
    success: (response) => {
      EverTrue.Alert.success("Cadence has been favorited");
      return response;
    },
    error(e) {
      ErrorLogger.error("Favorite Error", { extra: { error: e, page_name: "Cadence Favorite" } });
      return EverTrue.Alert.error("Error in favoriting Cadence, try again later");
    },
  });
};

//DeleteFavoriteJourney need path Variable
export const deleteFavoriteJourney = async (favoriteId) => {
  return await Api.JOURNEYS.JOURNEY_FAVORITE.delete({
    urlArgs: { id: favoriteId },
    success: (response) => {
      EverTrue.Alert.success("Cadence has been unfavorited");
      return response;
    },
    error(e) {
      ErrorLogger.error("Delete Favorite Error", { extra: { error: e, page_name: "Cadence Favorite" } });
      return EverTrue.Alert.error("Error in unfavoriting Cadence, try again later");
    },
  });
};
