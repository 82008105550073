module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default


   ArrowSelectMixin =
      getInitialState: ->
         {selectedIndex: -1}

      componentDidMount: ->
         @is_mounted = true

      componentWillUnmount: ->
         @is_mounted = false
      # Note this expects arrowSelectBody to be positioned
      componentDidUpdate: ->
         return unless @is_mounted
         $element = $(@refs.arrowSelectBody)
         $element?.scrollToHighlight(".is-selected")

      handleKeyPress: (evnt, value_obj) ->
         switch evnt.key
            when "ArrowUp"
               evnt.preventDefault()
               @setState {selectedIndex: Math.max(@state.selectedIndex - 1, 0)}
            when "ArrowDown"
               evnt.preventDefault()
               @setState {selectedIndex: Math.min(@state.selectedIndex + 1, @getSelectable()?.length - 1)}
            when "Enter"
               evnt.preventDefault()
               @handleKeyEnter?(@state.selectedIndex)
            when "Escape"
               @handleEscape?(evnt)
            else
               unless evnt.key == "Shift"
                  query = @refs?.arrowSelectInput?.value
                  if value_obj?.input_value
                     query = value_obj.input_value
                  @handleType?(query, evnt)

      handleKeyDown: (evnt) ->
         query = @refs.arrowSelectInput?.value
         if _.isEmpty(query) && evnt.key == "Backspace"
            @handleEmptyBackspace?()

      handleHoverSelect: (index) ->
         @setState {selectedIndex: index}

   return ArrowSelectMixin
