import { useContext } from "react";
import { PropTypes } from "base/react-libs";
import { SearchInput, Button, Icon, Popover2, useFocus } from "@evertrue/et-components";
import ActionMenuItem from "components/controls/action-menu-item";
import { FiltersDataContext } from "apps/query-builder/contexts/filters-data-context";

const MoreFiltersModalLeftArea = ({ categorizedFilters, selectedFilters, setSelectedFilters }) => {
  const { searchTerm, setSearchTerm } = useContext(FiltersDataContext);
  const [searchRef] = useFocus();

  return (
    <div className="extra-filters-left-side">
      <div className="filter-picker--search" style={{ marginBottom: "15px" }}>
        <SearchInput
          ref={searchRef}
          placeholder="Search filters"
          value={searchTerm}
          onChange={setSearchTerm}
          debounceTimeout={500}
        />
      </div>
      <div className="new-filter-div-scrollbar">
        {categorizedFilters &&
          Object.keys(categorizedFilters).map((category) => {
            return (
              <div className="filter-picker--list" key={category} style={{ marginRight: "10px" }}>
                <div className="filter-picker--list-heading">
                  <div className="filter-picker--list-title">
                    <div className="filter-picker--title" style={{ marginTop: "12px" }}>
                      {category}
                    </div>
                  </div>
                </div>
                <div>
                  <hr />
                  <div>
                    {Object.keys(categorizedFilters[category]).map((group) => {
                      const groupString = group !== "null" ? group : "General";
                      return (
                        <div className="filter-picker--group" key={groupString}>
                          <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div className="filter-picker--title">{groupString}</div>
                            <a>Add Group</a>
                          </div>
                          <ul>
                            {categorizedFilters[category][group].map((filter) => {
                              return (
                                <li key={filter.compound_id} className="filter-picker--list-option">
                                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Button type="simple">
                                      <Icon className="filter-picker--list-icon" icon={filter.icon} />
                                      {filter.label}
                                    </Button>
                                    <div style={{ justifyContent: "end" }}>
                                      <Popover2 title="Add to" trigger={<Icon icon="add-circle-outline" size={1} />}>
                                        <div className="trip-card--menu-content">
                                          <div className="bold-text">Add to</div>
                                          <ActionMenuItem
                                            title="Top Block"
                                            onClick={() => {
                                              setSelectedFilters([...selectedFilters, filter]);
                                            }}
                                          >
                                            Top Block
                                          </ActionMenuItem>
                                          <ActionMenuItem title="Advanced Filters Block" onClick={() => { }}>
                                            Advanced Filters Block
                                          </ActionMenuItem>
                                        </div>
                                      </Popover2>
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

MoreFiltersModalLeftArea.propTypes = {
  categorizedFilters: PropTypes.any,
  selectedFilters: PropTypes.array,
  setSelectedFilters: PropTypes.func,
};

export default MoreFiltersModalLeftArea;
