import { Component } from "react";
import PropTypes from "prop-types";
import _ from "underscore";
import ContactCard from "apps/contact/components/contact-card/contact-card";
import PoolAssignmentsSource from "apps/volunteers/sources/pool-assignments-source";
import ContactsAutoComplete from "apps/contact/components/contacts-autocomplete";
import SearchBox from "components/forms/search-box";
import {
  ModalHeaderDeprecated,
  ModalFooterWithButtons,
  Button,
  Icon,
  Checkbox,
  Loading,
} from "@evertrue/et-components";

const poolProspectsQuery = (pool_id, contact) => ({
  sort: [{ name_last: { order: "asc" } }, { name_first: { order: "asc" } }],
  must_not: [{ id: { match: contact.id } }],
  has_child: [
    {
      type: "assignment",
      query: {
        must: [
          { pool_id: { match: pool_id } },
          { parent_role: { match: "prospect" } },
          { secondary_prospect_contact_ids: { exists: "false" } },
        ],
      },
    },
  ],
});

class LinkProspectsModal extends Component {
  static propTypes = {
    assignment: PropTypes.object,
    secondary: PropTypes.object,
    close: PropTypes.func,
    fetchSolicitors: PropTypes.func.isRequired,
    removeSecondaryProspect: PropTypes.func.isRequired,
    addSecondaryProspect: PropTypes.func.isRequired,
  };

  static defaultProps = {
    assignment: {},
    secondary: {},
  };

  state = {
    search: null,
    selected: null,
    swap: false,
  };

  delayedRefetchSolicitor = (contactId) => {
    window.setTimeout(() => {
      this.props.fetchSolicitors(this.props.assignment.pool_id, [contactId]);
    }, 1000);
  };

  handleSubmit = () => {
    const { assignment_id, pool_id, solicitor_contact_id } = this.props.assignment;
    if (this.state.swap) {
      PoolAssignmentsSource.promise.swapJoin(pool_id, assignment_id, this.props.secondary.id).then(() => {
        // flux code was doing this, but not really sure of the purpose since doesnt change assigned count (on solicitor obj)
        this.delayedRefetchSolicitor(solicitor_contact_id);
        this.props.addSecondaryProspect(this.state.selected);
      });
    } else {
      PoolAssignmentsSource.promise.join(pool_id, assignment_id, this.state.selected, solicitor_contact_id).then(() => {
        this.delayedRefetchSolicitor(solicitor_contact_id);
        this.props.addSecondaryProspect(this.state.selected);
      });
    }
    this.props.close();
  };

  handleUnjoin = () => {
    const { assignment_id, pool_id, solicitor_contact_id } = this.props.assignment;
    this.props.removeSecondaryProspect();
    PoolAssignmentsSource.promise
      .unjoin(pool_id, assignment_id, this.props.secondary.id, solicitor_contact_id)
      .then(() => {
        this.delayedRefetchSolicitor(solicitor_contact_id);
      });
    this.props.close();
  };

  handleSwap = () => {
    this.setState({ swap: !this.state.swap });
  };

  handleSelect = (id) => {
    this.setState({ selected: id });
  };

  renderCard = (contact, label, content) => {
    return (
      <div className="link-prospects-modal--card">
        {!!label && <div className="link-prospects-modal--text">{label}</div>}
        <ContactCard contact={contact} showNameLink={false} />
        {!!content && content}
      </div>
    );
  };

  renderResult = (contacts, is_spouse) => {
    return _.map(contacts, (contact) => {
      return (
        <div key={contact.id} className="link-prospects-modal--item" onClick={() => this.handleSelect(contact.id)}>
          <ContactCard contact={contact} showNameLink={false}>
            {is_spouse && <div className="text-label">Spouse</div>}
          </ContactCard>

          <div className="match-result--checkbox">
            <Checkbox checked={this.state.selected === contact.id} />
          </div>
        </div>
      );
    });
  };

  render() {
    let spouse_query;
    const { assignment } = this.props;
    const base_contact = assignment.contact;

    const is_linked = !_.isEmpty(this.props.secondary);
    let primary = base_contact;
    let { secondary } = this.props;
    if (this.state.swap) {
      primary = this.props.secondary;
      secondary = base_contact;
    }

    const query = poolProspectsQuery(assignment.pool_id, base_contact);
    const spouse_ids = _.compactMap(base_contact.relationships, (prop = {}) => {
      if (prop.type && prop.type.value && prop.type.value.toLowerCase() === "spouse") {
        return prop.remote_id != null ? prop.remote_id.value : undefined;
      }
    });

    if (!_.isEmpty(spouse_ids)) {
      spouse_query = { must: [{}, { "identities.value": { in: spouse_ids } }] };
    }

    return (
      <div className="link-prospects-modal">
        <ModalHeaderDeprecated
          header={is_linked ? "Edit Linked Prospects" : "Link Prospect"}
          onCancel={this.props.close}
          allowClose
        />
        {is_linked ? (
          <div className="link-prospects-modal--content">
            {this.renderCard(
              primary,
              "Primary Prospect",
              <Button type="simple" className="link-prospects-modal--swap" onClick={this.handleSwap}>
                <Icon icon="switch" rotate={90} />
                Swap
              </Button>
            )}
            {this.renderCard(secondary, "Secondary Prospect")}
          </div>
        ) : (
          <div className="link-prospects-modal--content">
            {this.renderCard(primary)}
            <div className="link-prospects-modal--selector">
              <ContactsAutoComplete
                initFetch
                query={query}
                limit={50}
                render={({ contacts, onSearch, loading }) => {
                  const debounced_search = _.debounce(onSearch, 300);
                  return (
                    <div className="link-prospects-modal--body">
                      <div className="link-prospects-modal--search">
                        <SearchBox
                          className="match-search-box"
                          placeholder="Search for constituents in this pool"
                          onKeyUp={(val) => {
                            this.setState({ search: val });
                            debounced_search(val);
                          }}
                        />
                      </div>
                      <div className="link-prospects-modal--results">
                        {loading && <Loading position="top" />}
                        {!_.isEmpty(spouse_ids) && (
                          <ContactsAutoComplete
                            initFetch
                            query={spouse_query}
                            limit={5}
                            render={(data) => {
                              const spouses = data.contacts;
                              if (!_.isEmpty(spouses)) {
                                return (
                                  <div className="link-prospects-modal--results-group">
                                    <h3>Suggested Results:</h3>
                                    {this.renderResult(spouses, true)}
                                  </div>
                                );
                              }
                              return null;
                            }}
                          />
                        )}
                        <div className="link-prospects-modal--results-group">
                          <h3>Pool Prospects</h3>
                          {this.renderResult(contacts)}
                        </div>
                      </div>
                    </div>
                  );
                }}
              />
            </div>
          </div>
        )}
        <ModalFooterWithButtons
          disableSave={!this.state.selected && !this.state.swap}
          saveLabel="Save"
          onSubmit={this.handleSubmit}
          onCancel={this.props.close}
          closeModal={this.props.close}
        >
          {is_linked && (
            <Button type="secondary" onClick={this.handleUnjoin}>
              Unlink
            </Button>
          )}
        </ModalFooterWithButtons>
      </div>
    );
  }
}

export default LinkProspectsModal;
