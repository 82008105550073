module.exports = do ->
   {createComponent} = require("@evertrue/et-flux")
   React = require("react")
   {div, nav, header, main, span} = ReactLibs.DOMFactories
   EverTrue = require("app")
   classSet = require("classnames")
   LayoutStore = require("apps/layout/stores/layout-store")
   SessionStore = require("base/session/session-store")
   UserStore = require("base/user/user-store")
   FeatureStore = require("apps/layout/stores/feature-store").default
   ImpersonationHeader = require("apps/impersonation/impersonation-header")
   Header = require("apps/layout/components/header")
   Navigation = require("apps/layout/components/navigation")
   Loading = require("components/elements/loading")
   {createFactory} = require("base/new-utils")
   PageErrorBoundary =  createFactory(require("apps/layout/page-error-boundary").default)
   SuspenseElement = createFactory(React.Suspense)
   KeyPressManager = createFactory(require("components/helpers/KeyPressManager").default)
   moveFocusToMain = require("base/accessibility-utils").moveFocusToMain
   GlobalProviderController = createFactory require("apps/layout/controllers/global-provider-controller").default
   Button = createFactory(require("@evertrue/et-components").Button)
   FiltersBetaToggle = createFactory(require("apps/layout/components/filters-beta-toggle").default);

   createComponent "LayoutController",
      registerStores: ->
         @on LayoutStore, ->
            current_page: LayoutStore.getPageComponent()
            banner: LayoutStore.getBanner()
            nav_is_collapsed: LayoutStore.getNavState()
            nav_is_done_animating: LayoutStore.getNavAnimating()
            remounting: LayoutStore.getRemounting()

         @on UserStore, ->
            user: UserStore.getUserInfo()
            isImpersonating: UserStore.isImpersonating()

         @on SessionStore, ->
            current_user: SessionStore.getUser()

         @on FeatureStore, ->
            has_filters_v2: FeatureStore.hasFeature("filters_v2")

      render: ->
         showMessage = @state.current_page?.props?.feature == "filters_v2"
         isAiSearchTab = window.location.pathname.match(///^\/ai/search///)

         SuspenseElement fallback: Loading(),
            GlobalProviderController user: @state.current_user,
               PageErrorBoundary page_name: "Layout",
                  div id: "layout-outer",
                     if @state.isImpersonating
                        div id: "layout-impersonation-banner", ImpersonationHeader(user: @state.user)

                     # Top Fixed banner - Used for anything other than impersonation
                     if @state.banner
                        div id: "layout-banner", className: classSet("is-impersonating": @state.isImpersonating),
                           @state.banner

                     KeyPressManager
                        onEnter: moveFocusToMain
                        onSpace: moveFocusToMain
                        Button type: "action", className: "skip-main",
                           "Skip To Main Content"

                     div
                        id: "layout-container",
                        className: classSet
                           "is-showing-banner": @state.banner
                           "is-impersonating": @state.isImpersonating

                        # Left Navigation, Adjustable Width, animates
                        nav id: "layout-nav",
                           Navigation() unless @state.remounting

                        div
                           id: "layout-content",
                           className: classSet
                              "nav-is-collapsed": @state.nav_is_collapsed
                              "nav-is-done-animating": @state.nav_is_done_animating

                           # Header, Fixed at top with Fixed Height
                           header id: "layout-header",
                              Header() unless @state.remounting

                           if @state.has_filters_v2 && showMessage
                              FiltersBetaToggle null

                           # Main Page Viewport
                           main id: "layout-page", tabIndex: -1, style:{ top: if isAiSearchTab then 60 else if @state.has_filters_v2 && showMessage then 103 else 65 },
                              div id: "layout-page-wrapper",
                                 if @state.remounting
                                    Loading className: "layout-page--loader", text: "Loading..."
                                 else
                                    PageErrorBoundary page_name: "Content",
                                       @state.current_page