import { useState, useContext, useEffect } from "react";
import { PageHeader } from "@evertrue/et-components";
import useFetchDynamicLists from "apps/dynamic-lists/hooks/use-fetch-dynamic-lists";
import DynamicListsTable from "apps/dynamic-lists/components/dynamic-lists-table";
import SortDropdown from "components/controls/sort-dropdown";
import { ListDataContext } from "apps/layout/controllers/list-data-context";
import { GlobalContactSearchContext } from "apps/layout/controllers/global-contact-search-context";
import EverTrue from "app";

const LIMIT = 10;

// TODO wire up sorting to add actual sort fields and pass along to useFetchDynamicLists hook
const sortOptions = [
  { label: "List Name", value: "NAME" },
  { label: "Constituents", value: "TOTAL_CONSTITUENTS" },
  { label: "Updated", value: "OPERATION_ENDED_AT" },
];

const DynamicListsController = () => {
  const [sortProp, setSortProp] = useState(sortOptions[2].value);
  const [sortReverse, setSortReverse] = useState(true);
  const { offset, setOffset, lists, total, loading } = useFetchDynamicLists(LIMIT, sortProp, sortReverse);
  const { resetTable } = useContext(ListDataContext);
  const { resetFiltersAndSettings } = useContext(GlobalContactSearchContext);

  useEffect(() => {
    resetTable();
    resetFiltersAndSettings();
  }, [resetTable, resetFiltersAndSettings]);

  useEffect(() => {
    EverTrue.track.set("dynamic_lists_action", { type: "total_dyanmic_list_table_view" });
  }, []);

  const loadMore = () => {
    setOffset(lists.length);
  };

  return (
    <>
      <PageHeader title="Dynamic Lists" />
      <div className="dynamic-lists-controller">
        <div className="dynamic-lists-controller--table-settings">
          <div className="dynamic-lists-controller--count">{total} Lists</div>
          <SortDropdown
            options={sortOptions}
            sortProp={sortProp}
            sortReverse={sortReverse}
            onSort={(sortProp, sortReverse) => {
              setOffset(0);
              setSortProp(sortProp);
              setSortReverse(sortReverse);
            }}
          />
        </div>
        <DynamicListsTable
          lists={lists}
          total={total}
          loading={loading}
          loadMore={loadMore}
          offset={offset}
          limit={LIMIT}
        />
      </div>
    </>
  );
};

export default DynamicListsController;
