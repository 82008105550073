module.exports = do ->
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories
   ProspectColumnStore = require("apps/major-gifts/stores/prospect-column-store")
   {createFactory} = require("base/new-utils")
   AdvancedCombobox = createFactory(require("@evertrue/et-components").AdvancedCombobox)
   ModalConfirm = createFactory require("components/modals/modal-confirm")


   createComponent "MyPortfolioStatusModal",
      propTypes:
         total: ReactLibs.PropTypes.number
         onSubmit: ReactLibs.PropTypes.func

      getInititalState: ->
         selected_stage: undefined

      registerStores: ->
         @on ProspectColumnStore, ->
            active_columns: ProspectColumnStore.getActiveColumns()

      handleSubmit: ->
         @props.onSubmit?(@state.selected_stage?.value)
         @handleClose()

      handleClose: ->
         @props.requestHide?()

      render: ->
         display = if @props.total == 1 then "Constituent" else "Constituents"

         ModalConfirm
            header: "Change Status for #{@props.total} #{display}"
            buttonLabel: "Save"
            onSubmit: @handleSubmit
            onCancel: @handleClose

            div className: "my-portfolio-status-change",
               div className: "my-portfolio-status-change--label",
                  "Prospect Status"

               div className: "clearfix",
                  AdvancedCombobox
                     className: "advanced-combobox-secondary"
                     value: @state.selected_stage
                     options: @state.active_columns
                     onChange: (val) =>
                        @setState {selected_stage: val}
