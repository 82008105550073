import _ from "underscore";
import { createSource } from "@evertrue/et-flux";
import Api from "entities/helpers/api";

export default createSource("ProfileAssignmentSource", {
  actions: {
    fetchedVolunteerMemberships(id, data) {
      this.require(_.isNumber(id), "id should be a number");
      this.require(_.isObject(data), "data should be an object");
    },
    fetchedTeamMemberships(id, data) {
      this.require(_.isNumber(id), "id should be a number");
      this.require(_.isObject(data), "data should be an object");
    },
    fetchedDxoMemberships(id, data) {
      this.require(_.isNumber(id), "id should be a number");
      this.require(_.isObject(data), "data should be an object");
    },
    fetchedAssignmentTitles(titles) {
      this.require(_.isArray(titles), "titles should be an array");
    },
  },

  fetchVolunteerMembershipByType(contact_id, type) {
    return Api.VOLUNTEER.MEMBERSHIP.get({
      urlArgs: { contact_id, type },
    });
  },

  fetchTeamMembershipByType(contact_id, type) {
    return Api.VOLUNTEER.MEMBERSHIP.get({
      urlArgs: { contact_id, type },
      params: { pool_type: "TEAM" },
    });
  },

  fetchDxoMembershipByType(contact_id, type) {
    return Api.VOLUNTEER.MEMBERSHIP.get({
      urlArgs: { contact_id, type },
      params: { pool_type: "DXO" },
    });
  },

  api: {
    fetchVolunteerMembership(contact_id) {
      const requests = ["solicitor", "prospect"].map((type) => this.fetchVolunteerMembershipByType(contact_id, type));

      Promise.all(requests).then(([solicitors, prospects]) => {
        this.actions.fetchedVolunteerMemberships(parseInt(contact_id, 10), { solicitors, prospects });
      });
    },

    fetchTeamMembership(contact_id) {
      const requests = ["solicitor", "prospect"].map((type) => this.fetchTeamMembershipByType(contact_id, type));

      Promise.all(requests).then(([solicitors, prospects]) => {
        this.actions.fetchedTeamMemberships(parseInt(contact_id, 10), { solicitors, prospects });
      });
    },

    fetchDxoMembership(contact_id) {
      const requests = ["solicitor", "prospect"].map((type) => this.fetchDxoMembershipByType(contact_id, type));

      Promise.all(requests).then(([solicitors, prospects]) => {
        this.actions.fetchedDxoMemberships(parseInt(contact_id, 10), { solicitors, prospects });
      });
    },

    fetchAssignmentTitles() {
      Api.ASSIGNMENTS.ASSIGNMENT_TITLES.get().then((resp = {}) => {
        const titles = resp.items || [];
        this.actions.fetchedAssignmentTitles(titles);
      });
    },
  },
});
