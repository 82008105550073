import { useContext } from "react";
import { ClarityContext } from "../controllers/clarity-context";
import ClarityCard from "./clarity-card";
import ClarityEmpty from "./clarity-empty";

const ClarityList = () => {
  const { dashboardList } = useContext(ClarityContext);

  return (
    <>
      {dashboardList.length ? (
        <div className="clarity-list">
          {dashboardList.map((board) => (
            <ClarityCard board={board} key={board.path} />
          ))}
        </div>
      ) : (
        <ClarityEmpty />
      )}
    </>
  );
};

export default ClarityList;
