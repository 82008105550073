import EverTrue from "app";
import useSearchList from "apps/constituents/hooks/use-search-list";
import { Listbox } from "@evertrue/et-components";
import { PropTypes } from "base/react-libs";

const ListItem = ({ search }) => {
  const { id, name } = search;
  return (
    <li onClick={() => EverTrue.Navigator(`search/${id}`)} style={{ padding: "8px 20px", cursor: "pointer" }}>
      {name}
    </li>
  );
};

ListItem.propTypes = {
  search: PropTypes.object,
};

const BookmarkedSearches = () => {
  const { searches } = useSearchList();

  searches.sort(function (a, b) {
    return a.name.localeCompare(b.name);
  });

  return (
    <Listbox
      className="bookmarked-search--scrollable-content"
      ariaLabel="Bookmarked Searches"
      value="Bookmarked Searches"
    >
      {({ closePopover }) => (
        <ul style={{ margin: "10px" }}>
          {searches &&
            searches.map((search) => {
              return <ListItem key={search.id} search={search} />;
            })}
        </ul>
      )}
    </Listbox>
  );
};

export default BookmarkedSearches;
