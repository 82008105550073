import PropTypes from "prop-types";
import str from "underscore.string";
import { Link, ModalTrigger2, ModalConfirm, LinkButton, Icon, IconButton } from "@evertrue/et-components";
import EmptyStateMessage from "components/lists/empty-state-message";
import colors from "base/colors";
import EnrichmentConfidenceScore from "./enrichment-confidence-score";
import CareerEnrichmentItem from "./career-enrichment-item";

const getSocialIcon = (network) => {
  switch (network) {
    case "facebook":
      return <Icon icon="facebook-circle" size={1} style={{ color: colors.facebookBlue }} />;
    case "twitter":
      return <Icon icon="twitter-square-2023" iconKit="gt2" size={1} />;
    case "instagram":
      return <Icon icon="instagram-rounded" size={1} style={{ color: colors.instagramPink }} />;
    case "linkedin":
      return <Icon icon="linkedin-box" size={1} style={{ color: colors.linkedinBlue }} />;
    default:
      return <Icon icon="social" size={1} before />;
  }
};

const SocialProfiles = ({ profiles, editMode = "", onMutation }) => {
  return profiles.length ? (
    profiles.map((profile) => {
      return (
        <div key={profile.type + profile.url} className="profile--enrichment-social-profile">
          <div className="profile--enrichment-social-icon">{getSocialIcon(profile.type)}</div>
          <div className="profile--enrichment-social-body profile-enrichment--item">
            <div className="profile--enrichment-social-profile-name">
              {profile.url && (
                <Link title={`View ${profile.type} profile`} href={profile.url} target="_blank">
                  {profile.type === "linkedin" ? "LinkedIn" : str.capitalize(profile.type)}
                </Link>
              )}
              {editMode === "delete" && (
                <IconButton
                  icon="clear"
                  className="profile--enrichment-delete-button"
                  title="delete social profile"
                  onClick={() => onMutation(profile)}
                />
              )}
              {editMode === "restore" && (
                <LinkButton className="profile--enrichment-undo-button" onClick={() => onMutation(profile)}>
                  Undo
                </LinkButton>
              )}
            </div>

            {profile.bio && (
              <ModalTrigger2
                closeOnOutsideClick
                trigger={({ open }) => {
                  return (
                    <LinkButton title={`View ${profile.type} bio`} onClick={open}>
                      View Bio
                    </LinkButton>
                  );
                }}
              >
                {({ close }) => {
                  return (
                    <ModalConfirm
                      header={` ${str.capitalize(profile.type)} Bio`}
                      onCancel={close}
                      style={{ width: 500 }}
                    >
                      <div className="profile-enrichment--more-modal">{profile.bio}</div>
                    </ModalConfirm>
                  );
                }}
              </ModalTrigger2>
            )}
            {(profile.followers || profile.following) && (
              <div className="profile--enrichment-social-followers">
                <div>
                  Followers: <span style={{ marginLeft: "70px" }}>{profile.followers || 0}</span>
                </div>
                <div>
                  Following: <span style={{ marginLeft: "70px" }}>{profile.following || 0}</span>
                </div>
              </div>
            )}
            {profile.followers || profile.following ? (
              <div className="profile--enrichment-followers-confidence">
                <span style={{ marginRight: "24px" }}>Confidence Score:</span>
                <CareerEnrichmentItem
                  label={""}
                  value={<EnrichmentConfidenceScore score={profile.confidence_score} source={profile.source} />}
                />
              </div>
            ) : (
              <CareerEnrichmentItem
                label={"Confidence Score:\u00A0"}
                value={<EnrichmentConfidenceScore score={profile.confidence_score || "N/A"} source={profile.source} />}
              />
            )}
          </div>
        </div>
      );
    })
  ) : (
    <EmptyStateMessage hideIcon text="No enriched social media data available" />
  );
};

SocialProfiles.propTypes = {
  profiles: PropTypes.array,
  editMode: PropTypes.string,
  onMutation: PropTypes.func,
};

export default SocialProfiles;
