import PropTypes from "prop-types";
import useDialer from "./hooks/use-dialer";
import DialerNumPad from "./components/dialer-num-pad";
import DialerNumbersDropdown from "./components/dialer-numbers-dropdown";
import DialerInputField from "./components/dialer-input-field";
import DialerNotes from "./components/dialer-notes";
import { useGate } from "components/is-gated";
import CallAiSummary from "./call-ai-summary";
import { isEmpty } from "underscore";

const Dialer = ({ contact, name, phones, task = {}, journey = {} }) => {
  const {
    phoneRef,
    extensionRef,
    phoneNumber,
    extension,
    notes,
    onPhoneFocus,
    onExtFocus,
    handleInput,
    handleDropdownInput,
    CallerButton,
    handleNoteChange,
    callEnded,
    setCallEnded,
  } = useDialer(contact.id);

  const [hasUnreleasedFeature, loadedUnreleasedFeature] = useGate("unreleased_feature");

  return (
    <div className="dialer">
      {hasUnreleasedFeature && loadedUnreleasedFeature ? (
        <div className="unreleased-feature-dailer">
          <div className="dialer-note-container">
            <DialerNotes handleNoteChange={handleNoteChange} notes={notes} />
          </div>
          <div className="dialer-right-container">
            <div className="dialer--contact-info">
              <DialerNumbersDropdown
                name={name}
                phones={phones}
                handleNumberChange={handleDropdownInput}
                value={phoneNumber}
              />

              <DialerInputField
                phoneRef={phoneRef}
                extensionRef={extensionRef}
                phoneNumber={phoneNumber}
                extension={extension}
                onPhoneFocus={onPhoneFocus}
                onExtFocus={onExtFocus}
                handleInput={handleInput}
              />
              <CallerButton />
            </div>
            <DialerNumPad handleNumPadPress={handleInput} />
          </div>
          {callEnded && !!task && !isEmpty(task) && !!journey && !isEmpty(journey) && (
            <CallAiSummary notes={notes} contact={contact} task={task} journey={journey} setCallEnded={setCallEnded} />
          )}
        </div>
      ) : (
        <>
          <div className="dialer--contact-info">
            <DialerNumbersDropdown
              name={name}
              phones={phones}
              handleNumberChange={handleDropdownInput}
              value={phoneNumber}
            />
            <DialerInputField
              phoneRef={phoneRef}
              extensionRef={extensionRef}
              phoneNumber={phoneNumber}
              extension={extension}
              onPhoneFocus={onPhoneFocus}
              onExtFocus={onExtFocus}
              handleInput={handleInput}
            />
            <CallerButton />
          </div>
          <DialerNumPad handleNumPadPress={handleInput} />
        </>
      )}
    </div>
  );
};

/**
 * PropTypes for the Dialer component:
 * - phones: An array of phone numbers.
 * - name: The name of the contact.
 * - contact: The contact object.
 * - task: The task object.
 */
Dialer.propTypes = {
  phones: PropTypes.array,
  name: PropTypes.string,
  contact: PropTypes.object,
  task: PropTypes.object,
  journey: PropTypes.object,
};

export default Dialer;
