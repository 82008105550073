module.exports = do ->
   _ = require("underscore").default
   {createStore} = require("@evertrue/et-flux")
   SolicitorInviteSource = require("apps/volunteers/sources/solicitor-invite-source").default

   createStore "SolicitorInviteStore",
      getInitialState: ->
         loading: false
         invites_by_contact_id: {}
         ids_in_flight: new Set()

      registerActions: ->
         @on SolicitorInviteSource.actions.loading, @respondToLoading
         @on SolicitorInviteSource.actions.fetchedInvites, @respondTofetchedInvites
         @on SolicitorInviteSource.actions.sendAppInviteSuccess, @respondSendAppInviteSuccess
         @on SolicitorInviteSource.actions.fetchIfNotCached, (contact_ids) ->
            not_cached_ids = contact_ids.filter((id) =>
               !@state.invites_by_contact_id[id] && !@state.ids_in_flight.has(id)
            )

            if not_cached_ids.length
               SolicitorInviteSource.fetch(not_cached_ids)

            ids = new Set(@state.ids_in_flight)
            contact_ids.forEach((id) -> ids.add(id))
            @setState {ids_in_flight: ids}

      respondToLoading: (is_loading) ->
         @setState {loading: is_loading}

      respondTofetchedInvites: (invite_array, contact_ids) ->
         existing_invites = @getState("invites_by_contact_id")
         invites_by_contact_id = _.reduce invite_array, (accum, invite) ->
            return _.extend {}, accum, "#{invite.contact_id}": invite
         , existing_invites

         contact_ids_in_flight = new Set(@state.ids_in_flight)
         contact_ids.forEach((id) -> contact_ids_in_flight.add(_.toNumber(id)))

         @setState {invites_by_contact_id, contact_ids_in_flight}

      respondSendAppInviteSuccess: (res, contact_id) ->
         invites_by_contact_id = _.cloneData @getState("invites_by_contact_id")
         if res then invites_by_contact_id[contact_id] = res
         else invites_by_contact_id = _.omit(invites_by_contact_id, contact_id)
         @setState {invites_by_contact_id}

      api:
         getLoading: ->
            @getState("loading")

         getSentInvites: ->
            @getState("invites_by_contact_id")

         getSentInvitesForContactIds: (contact_ids = []) ->
            contact_ids.reduce((accum, id) =>
               return _.extend {}, accum, "#{id}": @state.invites_by_contact_id[id]
            , {})

