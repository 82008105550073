import PropTypes from "prop-types";
import EverTrue from "app";
import { Button, Icon } from "@evertrue/et-components";
import FilterPickerCount from "apps/query-builder/components/filter-picker/filter-picker-count";

const FilterGroupOptions = ({
  filterOptions = {},
  selectedCategory = "",
  selectedFilter = {},
  onSelection = () => {},
  onBack = () => {},
}) => {
  const handleFilterSelection = (filter) => {
    EverTrue.track.set("filter_v2_action", {
      type: "select_filter",
      selected_filter: filter.label,
    });
    onSelection(filter);
  };

  return (
    <div className="filter-picker--list">
      <div className="filter-picker--list-heading">
        <div className="filter-picker--list-title">
          <Button type="simple" className="filter-picker--back-button" onClick={onBack}>
            <Icon icon="home" className="filter-picker--home-icon" />
            <Icon icon="chevron-right" className="filter-picker--back-chevron" />
          </Button>
          <div className="filter-picker--title">{selectedCategory}</div>
        </div>
        <Button type="simple" onClick={onBack}>
          <Icon icon="search" className="filter-picker--search-icon" />
        </Button>
      </div>
      <FilterPickerCount type="category" filterResults={filterOptions} />
      <div className="filter-picker--group-wrapper">
        <div className="filter-picker--group-wrapper-scroll">
          {Object.keys(filterOptions).map((group) => {
            const groupString = group !== "null" ? group : "General";
            return (
              <div className="filter-picker--group" key={groupString}>
                <div className="filter-picker--title">{groupString}</div>
                <ul>
                  {filterOptions[group].map((filter) => {
                    return (
                      <li
                        key={filter.compound_id}
                        className="filter-picker--list-option"
                        onClick={() => handleFilterSelection(filter)}
                      >
                        <Button type="simple">
                          {filter.compound_id === selectedFilter.compound_id ? (
                            <Icon className="filter-picker--list-icon filter-picker--list-icon-selected" icon="check" />
                          ) : (
                            <Icon className="filter-picker--list-icon" icon={filter.icon} />
                          )}
                          {filter.label}
                        </Button>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

FilterGroupOptions.propTypes = {
  filterOptions: PropTypes.object,
  selectedCategory: PropTypes.string,
  selectedFilter: PropTypes.object,
  onSelection: PropTypes.func,
  onBack: PropTypes.func,
};

export default FilterGroupOptions;
