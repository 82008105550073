import { useState, useEffect } from "react";
import Api from "entities/helpers/api";
import { LIST_ORDER } from "apps/dynamic-lists/utils/dynamic-list-enums";

const useFetchDynamicLists = (limit, sortProp, sortReverse) => {
  const [offset, setOffset] = useState(0);
  const [lists, setLists] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadSearches = async () => {
      setLoading(true);

      try {
        const resp = await Api.HUB.LISTS.get({
          params: {
            offset,
            limit,
            sort: sortProp,
            sort_order: sortReverse ? LIST_ORDER.DESC : LIST_ORDER.ASC,
          },
        });

        offset === 0 ? setLists(resp.items) : setLists((prev) => [...prev, ...resp.items]);
        setTotal(resp.total);
        setLoading(false);
      } catch {
        setLoading(false);
      }
    };

    loadSearches();
  }, [offset, limit, sortProp, sortReverse]);

  return { offset, setOffset, total, lists, loading };
};

export default useFetchDynamicLists;
