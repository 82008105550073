module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {div, strong} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   Searchbox = createFactory require("components/forms/search-box")
   SortDropdown = require("components/controls/sort-dropdown")
   EventsHeader = require("apps/events/components/events-header")
   EventsSource = require("apps/events/sources/events-source")
   EventsStore = require("apps/events/stores/events-store")
   EventsTable = require("apps/events/components/events-table")
   FacebookPagesStore = require("apps/facebook/stores/facebook-pages-store")
   FacebookSource = require("apps/facebook/sources/facebook-source")
   Layout = createFactory require("components/layout").default

   _options = [
      {label: "Name", value: "name.untouched"}
      {label: "Date", value: "start_time"}
   ]

   createComponent "EventsController",
      UNSAFE_componentWillMount: ->
         FacebookSource.fetchPages()

      componentDidMount: ->
         EventsSource.setPage(1)
         @debounceSearch = _.debounce(EventsSource.setQuickSearch, 300)

      registerStores: ->
         @on EventsStore, ->
            events: EventsStore.getEvents()
            quick_search: EventsStore.getQuickSearch()
            sort_params: EventsStore.getSortParams()
            loading: EventsStore.getIsLoading()

         @on FacebookPagesStore, ->
            pages: FacebookPagesStore.getPages()

      handleSort: (prop, reverse) ->
         EventsSource.newSort(prop, reverse)

      renderFacebookPageData: ->
         {events, pages} = @state
         _.map events, (event) ->
            page = _.findWhere(pages, {remote_id: event.page_remote_id})
            page_name = page?.page_name
            if page_name then _.extend event, {"page_name" : page_name} else event

      renderHeader: ->
         div className: "events-controller--filters-wrapper",
            EventsHeader()

      renderControls: ->
         div className: "events-controller--controls-wrapper",
            Searchbox
               className: "events-controller--searchbox"
               name: ""
               placeholder: "Search by name or location..."
               defaultValue: @state.quick_search
               onKeyUp: (query) => @debounceSearch(query)

            div className: "events-controller--controls-right",
               SortDropdown
                  className: "events-controller--sort-dropdown"
                  options: _options
                  sortProp: @state.sort_params?.prop
                  sortReverse: @state.sort_params?.reverse
                  onSort: @handleSort

      render: ->
         header = div className: "events-controller--header",
            @renderHeader()
            @renderControls()

         div className: "events-controller",
            Layout { header },
               div className: "events-controller--events-table-wrapper",
                  EventsTable
                     data: @renderFacebookPageData()
                     loading: @state.loading
