module.exports = do ->
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {createFactory} = require("base/new-utils")
   ModalConfirm = createFactory require("components/modals/modal-confirm")
   Modal = createFactory require("components/modals/modal")
   FilterTextInput = require("apps/filters/components/filter-text-input")

   createComponent "NewListModal",
      getInitialState: ->
         list_name: undefined

      handleChange: (name) ->
         @setState list_name: name

      handleSubmit: ->
         if @state.list_name
            @props.onSubmit?(@state.list_name)

      render: ->
         Modal
            width: 400
            keepOpen: true
            remove: @props.remove
            onUnmount: @props.onUnmount

            ModalConfirm
               type: "success"
               header: "Add to New List"
               buttonLabel: "Add"
               hasMargin: true
               onSubmit: @handleSubmit
               disableButton: !@state.list_name

               FilterTextInput
                  className: "add-new-list--input"
                  placeholder: "New list name..."
                  onChange: @handleChange


