import { useEffect } from "react";
import getGoogleApi from "apps/search/utils/get-google-api";
import { PropTypes } from "base/react-libs";

const google = getGoogleApi();

//Pass the MapToolbar as a child to add the toolbar
const Map = ({ map, onInit, onMapCoordinateChange, children }) => {
  useEffect(() => {
    if (map) {
      google.event.addListener(map, "zoom_changed", () => onMapCoordinateChange());
      google.event.addListener(map, "dragend", () => onMapCoordinateChange());
    }
  }, [onMapCoordinateChange, map]);

  useEffect(() => {
    onInit();
  }, [onInit]);

  return (
    <div className="map-containerv2" aria-label="mapv2">
      {children}
      <div id="mapv2"></div>
    </div>
  );
};

Map.defaultProps = {
  map: {},
  onInit: () => {},
  onMapCoordinateChange: () => {},
};

Map.propTypes = {
  map: PropTypes.object,
  onInit: PropTypes.func,
  onMapCoordinateChange: PropTypes.func,
  children: PropTypes.object,
};

export default Map;
