import PropTypes from "prop-types";
import EverTrue from "app";
import { createContext, useState, useEffect, useCallback } from "react";
import { useIdentity } from "base/identity-resolver";
import Api from "entities/helpers/api";

const ClarityContext = createContext(() => {});

const ClarityProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [dashboardList, setDashboardList] = useState([]);
  const { org } = useIdentity();

  const getDashboardList = useCallback(async () => {
    try {
      setLoading(true);

      const response = await Api.AUTH.TABLEAU.get({
        params: { oid: org.id },
      });
      setDashboardList(response);
      setLoading(false);
    } catch {
      setLoading(false);
      EverTrue.Alert.error("Dashboard failed to open. Please try again later");
    }
  }, [org.id]);

  useEffect(() => {
    getDashboardList();
  }, [getDashboardList]);

  return (
    <ClarityContext.Provider
      value={{
        loading,
        dashboardList,
        org,
      }}
    >
      {children}
    </ClarityContext.Provider>
  );
};

ClarityProvider.propTypes = {
  children: PropTypes.any,
};

export { ClarityProvider, ClarityContext };
