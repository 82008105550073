import { useContext } from "react";
import { PinnedCommentsContext } from "apps/profile/contexts/pinned-comments-context";
import PropTypes from "prop-types";
import Loading from "components/elements/loading";
import EmptyStateMessage from "components/lists/empty-state-message";
import PinnedCommentListItem from "apps/profile/components/pinned-comments/pinned-comment-list-item";

const PinnedCommentsFeed = ({ displayLimit = null }) => {
  const { loading, comments, error } = useContext(PinnedCommentsContext);

  const loadingOrEmpty = () => {
    if (loading) return <Loading text="Loading..." />;

    const { icon, message } = emptyData();

    if (!comments.length)
      return (
        <div className="note-feed--empty">
          <EmptyStateMessage icon={icon} text={message} />
        </div>
      );
  };

  const emptyData = () => {
    let message = "No pinned comments";
    let icon = "gt-comment";

    if (error === "DELETED") {
      icon = "trash";
      message = "This comment has been permanently deleted";
    }
    if (error === "ACCESS_DENIED") {
      icon = "lock";
      message = "You do not have access to this list";
    }

    return { icon, message };
  };

  const commentsToDisplay = () => {
    if (!displayLimit) return comments;

    return comments.slice(0, displayLimit);
  };

  return (
    <div className="note-feed">
      {loadingOrEmpty() || (
        <div className="note-feed--list">
          {commentsToDisplay().map((comment, i) => (
            <PinnedCommentListItem comment={comment} key={comment.id} />
          ))}
        </div>
      )}
    </div>
  );
};

PinnedCommentsFeed.propTypes = {
  displayLimit: PropTypes.number,
};

export default PinnedCommentsFeed;
