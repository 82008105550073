module.exports = do ->
   _ = require("underscore").default
   Parameter = require("entities/search/query-parts/parameter-query")
   Property = require("entities/search/query-parts/property-query")

   _toRadians = (degrees) -> degrees * (Math.PI/180)
   _toDegrees = (radians) -> radians * (180/Math.PI)
   _radiusConfig = [
      { radius: 1, zoom: 14 },
      { radius: 2, zoom: 13 },
      { radius: 5, zoom: 12 },
      { radius: 10, zoom: 11 },
      { radius: 25, zoom: 10 },
      { radius: 50, zoom: 8 },
   ]

   # Map Utility Functions
   MapUtils =
      getClusterQuery: (box) ->
         [
            Parameter "must", [
               Property "location", @getQueryFromBox(box), {type: "object", parent: "addresses", parent_type: "instance"}
            ]
         ]

      getQueryFromBox: (box) ->
         # If the cluster returns a point instead of a box, search with lat/lng
         if box && ((box.north == box.south) || (box.east == box.west))
            {lat, lng} = @boxToLatLng(box)
            {lat: lat, lon: lng, radius: "1ft"}
         else _.clone(box)

      boundsToBox: (bounds) ->
         north: bounds?.getNorthEast()?.lat()?.toFixed(5)
         south: bounds?.getSouthWest()?.lat()?.toFixed(5)
         east: bounds?.getNorthEast()?.lng()?.toFixed(5)
         west: bounds?.getSouthWest()?.lng()?.toFixed(5)

      boxToLatLngBounds: (box) ->
         southWest = new google.maps.LatLng(box.south, box.west)
         northEast = new google.maps.LatLng(box.north, box.east)
         bounds = new google.maps.LatLngBounds(southWest, northEast)
         bounds

      boxToLatLng: (box) ->
         return unless !_.isEmpty(box)
         bounds = @boxToLatLngBounds(box)?.getCenter()
         {lat: bounds?.lat(), lng: bounds?.lng()}

      radiusToLatLng: (radius_obj) ->
         return unless !_.isEmpty(radius_obj)
         {lat: parseFloat(radius_obj.lat), lng: parseFloat(radius_obj.lon)}

      getZoomForRadius: (radius) ->
         return unless radius
         _.findWhere(_radiusConfig, {radius: radius})?.zoom

      getRadiusOptions: ->
         _.map _radiusConfig, (obj) -> obj.radius

      getBoxFitForMarkers: (lat_lngs) ->
         marker_bounds = new google.maps.LatLngBounds()
         _.each lat_lngs, (lat_lng) ->
            marker_bounds.extend(new google.maps.LatLng(lat_lng.lat, lat_lng.lng))
         @boundsToBox(marker_bounds)

      getBoxFromLatLngRadius: (lat_lng, radius) ->
         earth_radius = 6371 # in km
         {lat, lng} = lat_lng
         lat_degrees = _toDegrees(radius / earth_radius / Math.cos(_toRadians(lat)))
         lng_degrees = _toDegrees(radius / earth_radius)

         west: lng - lat_degrees
         east: lng + lat_degrees
         north: lat + lng_degrees
         south: lat - lng_degrees

      isLatLngInBox: (latLng, box) ->
         return unless latLng.lat && latLng.lng && box
         latLng.lat >= box.south &&
               latLng.lat <= box.north &&
               latLng.lng >= box.west &&
               latLng.lng <= box.east

      # This logic checks for zoomed out map with more than 360 deg map
      # The above checks if an address point is within box
      isLatLngInGlobalBox: (latLng, box) ->
         box = _.mapObject box, (loc) -> parseFloat(loc)

         east_bound = latLng.lng < box.east
         west_bound = latLng.lng > box.west

         if box.east < box.west
            in_long = east_bound || west_bound
         else
            in_long = east_bound && west_bound

         latLng.lat > box.south &&
               latLng.lat < box.north &&
               in_long
