import { useGate } from "components/is-gated";
import { useState, useContext } from "react";
import PropTypes from "prop-types";
import { useIdentity } from "base/identity-resolver";
import PortfolioNav from "apps/portfolio-performance/controllers/portfolio-nav";
import PortfolioPerformanceActions from "apps/portfolio-performance/components/portfolio-performance-actions";
import PageHeader from "apps/layout/components/page-header";
import PortfolioMetaDataContext, {
  PortfolioMetaDataProvider,
} from "apps/portfolio-performance/contexts/portfolio-meta-data-context";
import PortfolioStageController from "apps/portfolio-performance/controllers/portfolio-stage-controller";
import PortfolioMapController from "apps/portfolio-performance/controllers/portfolio-map-controller";
import { ContactAssignmentProvider } from "apps/portfolio-performance/contexts/contact-assignment-context";
import PortfolioToolbar from "apps/portfolio-performance/components/portfolio-toolbar";
import PortfolioFilters from "apps/portfolio-performance/components/portfolio-filters";
import { ProspectSelectionProvider } from "apps/portfolio-performance/contexts/prospect-selection-context";
import { GlobalPortfolioContext } from "../contexts/global-portfolio-context";

const PortfolioPerformanceController = ({ team_id, solicitor_id }) => {
  const { assignmentType, setAssignmentType } = useContext(GlobalPortfolioContext);
  const [tab, setTab] = useState("stage");
  const { isOwner, isSuperUser, org, user } = useIdentity();
  const [canViewOtherPortfolios] = useGate("rm_assignments_view_others");
  const [canViewOtherPortfoliosDXO] = useGate("dxo_assignments_view_others");

  return (
    <PortfolioMetaDataProvider
      routeTeamId={team_id}
      routeSolicitorId={solicitor_id}
      org={org}
      user={user}
      hasViewOtherPortfolios={canViewOtherPortfolios}
      hasViewOtherPortfoliosDXO={canViewOtherPortfoliosDXO}
      assignmentType={assignmentType}
      setAssignmentType={setAssignmentType}
    >
      <PortfolioMetaDataContext.Consumer>
        {({ team, stages, solicitor, sort_prop, sort_reverse, total, setTotal }) => {
          return (
            <ContactAssignmentProvider
              stages={stages}
              team={team}
              sortProp={sort_prop}
              sortReverse={sort_reverse}
              solicitor_id={solicitor.id}
              setTotal={setTotal}
            >
              <ProspectSelectionProvider stages={stages} team={team} solicitor={solicitor} total={total}>
                <PageHeader
                  className="portfolio-performance--header"
                  actions={assignmentType === "TEAM" ? <PortfolioPerformanceActions tab={tab} /> : null}
                  title={
                    <div className="segment-filters--header">
                      <PortfolioFilters />
                      <div className="portfolio-performance--title">Portfolios</div>
                    </div>
                  }
                />
                {(isOwner || isSuperUser) && <PortfolioNav tab="portfolios" />}
                <PortfolioToolbar tab={tab} onChangeTab={(tab) => setTab(tab)} />
                {tab === "stage" ? <PortfolioStageController /> : <PortfolioMapController />}
              </ProspectSelectionProvider>
            </ContactAssignmentProvider>
          );
        }}
      </PortfolioMetaDataContext.Consumer>
    </PortfolioMetaDataProvider>
  );
};

PortfolioPerformanceController.propTypes = {
  team_id: PropTypes.number,
  solicitor_id: PropTypes.number,
};

export default PortfolioPerformanceController;
