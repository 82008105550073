import { useState } from "react";
import { PropTypes } from "base/react-libs";
import { Modal, ModalHeaderWithActions, ModalBody, ListboxSelect } from "@evertrue/et-components";
import FormField from "components/forms/form-field";
import JourneyContactCard from "../../journey-contact-card";

// This is the "base" component for moving a contact into a new journey. It is used in multiple places in the app.
// Extracted the shared piece of the modal into a separate component to reduce duplication.
const MoveModal = ({
  title = "Add to Cadence", // Default to "Add to Cadence"
  isOpen,
  onClose = () => {},
  handleSubmit = () => {},
  contact = {},
  options = [],
  activeJourneys = [],
}) => {
  const [selected, setSelected] = useState(null);

  return (
    <Modal isOpen={isOpen} closeModal={onClose}>
      <ModalHeaderWithActions
        title={title}
        closeModal={onClose}
        onSubmit={() => handleSubmit(selected)}
        disableSubmit={!selected}
      />
      <ModalBody>
        <JourneyContactCard contact={contact} avatarSize={60} />
        <FormField required={false} label="Current Cadences (Max 5)" inlineLabel={false} boldLabel={true}>
          {!!activeJourneys.length && activeJourneys.map(({ journey_name }) => <div>{journey_name}</div>)}
        </FormField>
        <FormField label="Select New Cadence" boldLabel={true} inlineLabel={false}>
          <ListboxSelect options={options} size="medium" onChange={setSelected} value={selected} label="" />
        </FormField>
      </ModalBody>
    </Modal>
  );
};

MoveModal.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  options: PropTypes.array,
  contact: PropTypes.object,
  activeJourneys: PropTypes.array,
};

export default MoveModal;
