import { useSession } from "base/session/with-session";
import { useOrg } from "base/org/with-org";
import Decorator from "clientDecorator";
import { useFluxStore } from "@evertrue/et-flux";
import UserStore from "base/user/user-store";

const userStoreMapState = () => ({
  is_impersonating: UserStore.isImpersonating(),
  user: UserStore.getUser(),
});

// eslint-disable-next-line
export const useIdentity = () => {
  const org = useOrg() || {};
  const { session = {}, isSuperUser } = useSession();
  const { is_impersonating, user = {} } = useFluxStore(UserStore, userStoreMapState);
  const affiliation = Decorator.User.getAffiliationFor(session.user, org.id);
  const roles = Decorator.User.getRoles(session.user, org.id) || [];
  const isOwner = Decorator.User.isOwner(session.user, org.id);
  // add more Decorator metadata here for easy reuse

  return {
    org,
    session,
    isSuperUser,
    isOwner,
    affiliation,
    roles,
    is_impersonating,
    // in most places we just use the user on the session
    // however, when impersonating, we swap the users in usersource so the user
    // in usersource should give more accurate impersonations
    user: is_impersonating ? user : session.user,
  };
};

const IdentityResolver = ({ children }) => {
  const obj = useIdentity();
  return children(obj);
};

export const WithIdentity = (WrappedComponent) => (props) =>
  <IdentityResolver>{(identity) => <WrappedComponent {...identity} {...props} />}</IdentityResolver>;

export default IdentityResolver;
