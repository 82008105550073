import PropTypes from "prop-types";
import ProfileCardHeader from "apps/profile/components/profile-card-header";
import InterestList from "../enrichment-list";
import EmptyStateMessage from "components/lists/empty-state-message";
import { useGate } from "components/is-gated";
import { Link, useOpenClose, Button, Icon } from "@evertrue/et-components";
import PhilanthropicInterestsModal from "./philanthropic-interests-modal";

const LIMIT = 3;

const PhilanthropicInterests = ({ philInterests, contactAttributes, onDelete }) => {
  const [hasWindfall] = useGate("windfall");
  const [isOpen, openModal, closeModal] = useOpenClose();

  return (
    <div className="profile--enrichment-phil-interests">
      <ProfileCardHeader title="Philanthropic Interest">
        {philInterests.length > 0 && (
          <Button type="simple" onClick={openModal}>
            <Icon title="Edit philanthropic interests" icon="edit" />
          </Button>
        )}
      </ProfileCardHeader>
      {hasWindfall ? (
        philInterests.length ? (
          <div className="profile--enrichment-inner">
            <InterestList list={philInterests} accessor="interest_name" limit={LIMIT} openModal={openModal} />
          </div>
        ) : (
          <EmptyStateMessage hideIcon text="No enriched interest data was available" />
        )
      ) : (
        <div className="profile-enrichment-teaser">
          We've partnered with Windfall to bring specific philanthropic interest details for high net worth households
          to TrueView profiles. Your organization hasn't added this feature yet, but you can{" "}
          <Link
            href={`/contact/${contactAttributes.contactId}/enrichment?appcue=-Ly1pV7jxtRJbWNfBVFM`}
            title="learn more about windfall"
          >
            see how it works
          </Link>{" "}
          and contact your Customer Success Manager to learn more.
        </div>
      )}
      {isOpen && (
        <PhilanthropicInterestsModal
          isOpen={isOpen}
          closeModal={closeModal}
          contactAttributes={contactAttributes}
          philInterests={philInterests}
          onDelete={onDelete}
        />
      )}
    </div>
  );
};

PhilanthropicInterests.propTypes = {
  philInterests: PropTypes.array,
  contactAttributes: PropTypes.object,
  onDelete: PropTypes.func,
};

export default PhilanthropicInterests;
