import PropTypes from "prop-types";
import JourneyContactCard from "apps/journeys/components/journey-contact-card";

const ContactSummaryHousehold = ({ contact }) => {
  return (
    <div className="household-container">
      <div className="household-header">Household Members</div>
      {contact.household_members.map((member) => (
        <JourneyContactCard key={member.id} contact={member} householdCard={true} />
      ))}
    </div>
  );
};

ContactSummaryHousehold.propTypes = {
  contact: PropTypes.object,
};

export default ContactSummaryHousehold;
