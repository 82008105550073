import { Loading } from "@evertrue/et-components";
import { useIdentity } from "base/identity-resolver";
import { bulkAddConstituentsToJourney } from "apps/journeys/utils/api-requests/modal-actions";
import Proptypes from "prop-types";
import { useTasksNotificationContext } from "apps/tasks/hooks/tasks-notification-context.js";
import SelectedContactsSource from "apps/contact/sources/selected-contacts-source";
import { trackJourneysAction } from "apps/journeys/utils/helpers/mixpanel";
import SelectorMenu from "components/controls/selector-menu";
import _ from "underscore";

const JourneySelectorController = ({
  contactIds = [],
  closePopover,
  journeys = [],
  loading = false,
  allSelected,
  totalResultsCount,
}) => {
  const { getAndSetGlobalTasksDueCount } = useTasksNotificationContext();
  const {
    org: { id: oid },
    user: { id: userId },
  } = useIdentity();

  const handleJourneyClick = async (item) => {
    const { label, value } = item;
    // bulk add endpoint
    await bulkAddConstituentsToJourney(contactIds, userId, oid, value, label);
    trackJourneysAction("list-portfolio");
    getAndSetGlobalTasksDueCount();
    SelectedContactsSource.clearAllSelectedState();
    closePopover();
  };

  if (totalResultsCount > 50 && allSelected) {
    return (
      <div className="trip-selector--empty">
        Unable to add more than 50 Constitents to a Cadence at one time. Please undo your selection.
      </div>
    );
  }
  return (
    <>
      <SelectorMenu
        isDisabled={!contactIds.length && !allSelected}
        label="Your Cadences"
        options={[
          {
            value: "journeys",
            label: "Your Cadences",
            items: _.chain(journeys)
              .map((journey) => ({ value: journey.value, label: journey.label }))
              .sortBy("label")
              .value(),
          },
        ]}
        onSelect={handleJourneyClick}
        searchPlaceholder="Search cadence..."
      />

      {loading && <Loading size="small" />}
    </>
  );
};

JourneySelectorController.propTypes = {
  contactIds: Proptypes.array.isRequired,
  closePopover: Proptypes.func.isRequired,
  journeys: Proptypes.array,
  loading: Proptypes.bool,
  allSelected: Proptypes.bool,
  totalResultsCount: Proptypes.number,
};

export default JourneySelectorController;
