import { useState, useEffect } from "react";
import _ from "underscore";
import moment from "moment";
import PropTypes from "prop-types";
import Api from "entities/helpers/api";
import { useIdentity } from "base/identity-resolver";
import EllipsisOverflow from "components/formatting/ellipsis-overflow";
import PageHeader from "apps/layout/components/page-header";
import FacebookNav from "apps/facebook/components/facebook-nav";
import ActionMenuItem from "components/controls/action-menu-item";
import { FlexTable, FlexTableSortHeaderCell, Link, Icon, SearchInput, Tooltip2 } from "@evertrue/et-components";
import SortDropdown from "components/controls/sort-dropdown";
import colors from "base/colors";
import ErrorLogger from "entities/helpers/error-log-helper";

const columns = (sortReverse, sortProp, handleSort) => [
  {
    key: 1,
    header: (
      <FlexTableSortHeaderCell
        onClick={() => handleSort("name")}
        sortOrder={sortReverse}
        isSorted={sortProp === "name"}
      >
        Facebook Page Name
      </FlexTableSortHeaderCell>
    ),
    accessor: (i) => {
      return (
        <>
          <a href={`http://www.facebook.com/${i.page_remote_id}`} target="_blank" rel="noopener noreferrer">
            <img className="facebook-pages-table--avatar" alt={i.name} src={i.picture_url} />
          </a>
          <div>
            <EllipsisOverflow className="facebook-pages-table--name">{i.name}</EllipsisOverflow>
            <div>{i.posts.toLocaleString()} posts</div>
          </div>
        </>
      );
    },
  },

  {
    key: 2,
    header: (
      <FlexTableSortHeaderCell
        onClick={() => handleSort("unmatched_engagers")}
        sortOrder={sortReverse}
        isSorted={sortProp === "unmatched_engagers"}
      >
        Unmatched Engagers
      </FlexTableSortHeaderCell>
    ),
    accessor: (i) => (
      <Link href={`/facebook/unmatched-engagers/${i.id}/${i.page_remote_id}`} title="View Unmatched engagers">
        {i.unmatched_profiles.toLocaleString()}
      </Link>
    ),
    headerClassName: "facebook-pages-table-head--metric",
    cellClassName: "facebook-pages-table--metric",
  },
  {
    key: 3,
    header: (
      <FlexTableSortHeaderCell
        onClick={() => handleSort("matched_constituents")}
        sortOrder={sortReverse}
        isSorted={sortProp === "matched_constituents"}
      >
        Matched Constituents
      </FlexTableSortHeaderCell>
    ),
    accessor: (i) => i.matched_profiles.toLocaleString(),
    headerClassName: "facebook-pages-table-head--metric",
    cellClassName: "facebook-pages-table--metric",
  },
  {
    key: 4,
    header: (
      <Tooltip2
        trigger={<span style={{ cursor: "pointer" }}>Match Rate</span>}
        contentWidth={210}
        style={{ cursor: "pointer" }}
      >
        Match Rate represents the number of matched engagers as a percent of the total number of engagers, per page
      </Tooltip2>
    ),
    accessor: (i) => `${Number.parseFloat(i.percent_matched).toFixed(1)}%`,
    headerClassName: "facebook-pages-table-head--metric",
    cellClassName: "facebook-pages-table--metric",
  },
  {
    key: 5,
    header: (
      <FlexTableSortHeaderCell
        onClick={() => handleSort("matched_engagements")}
        sortOrder={sortReverse}
        isSorted={sortProp === "matched_engagements"}
      >
        Matched Engagements
      </FlexTableSortHeaderCell>
    ),
    accessor: (i) => i.matched_engagements.toLocaleString(),
    headerClassName: "facebook-pages-table-head--metric",
    cellClassName: "facebook-pages-table--metric",
  },
  {
    key: 6,
    header: "Status",
    accessor: (i) => {
      switch (i.connection_status) {
        case "CONNECTED":
          return (
            <Tooltip2
              trigger={
                <span>
                  <Icon icon="success-circle" style={{ color: colors.green300, fontSize: "1.5rem" }} />
                </span>
              }
            >
              Connected
            </Tooltip2>
          );

        case "PENDING":
          return (
            <Tooltip2
              trigger={
                <span>
                  <Icon icon="success-circle" style={{ color: colors.gray500, fontSize: "1.5rem" }} />
                </span>
              }
            >
              Pending
            </Tooltip2>
          );

        case "DISCONNECTED":
          return (
            <Tooltip2
              trigger={
                <span>
                  <Icon icon="warning" style={{ color: colors.yellow150, fontSize: "1.5rem" }} />
                </span>
              }
            >
              Disconnected
            </Tooltip2>
          );

        default:
          break;
      }
    },
    headerClassName: "facebook-pages-table-head--center",
    cellClassName: "facebook-pages-table--center",
  },
];

const FacebookPagesController = ({ view_type }) => {
  const { org } = useIdentity();

  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalMatched, setTotalMatched] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [sortProp, setSortProp] = useState("name");
  const [sortReverse, setSortReverse] = useState(false);

  useEffect(() => {
    setLoading(true);
    const threeMonthsAgo = moment().subtract(3, "months").valueOf();
    Api.ENGAGEMENTS.SUMMARY.get({
      params: _.compactObject({
        from: threeMonthsAgo,
        limit: 50,
        offset: 0,
        search: searchText,
        sort: sortProp,
        sort_order: sortReverse ? "desc" : "asc",
      }),
      success: (res) => {
        setPages(res.page_summaries);
        setTotalPages(res.total_pages);
        setTotalMatched(res.matched_engagements);
        setLoading(false);
      },
      error: (xhr) => {
        ErrorLogger.captureRequest("Fetch Facebook Pages Error", xhr);
        setLoading(false);
      },
    });
  }, [org.id, searchText, sortProp, sortReverse]);

  const handleSort = (prop) => {
    if (prop === sortProp) {
      setSortReverse(!sortReverse);
    } else if (prop === "name") {
      setSortReverse(false);
    } else {
      setSortReverse(true);
    }
    setSortProp(prop);
  };

  const facebookPagesActions = () => (
    <ActionMenuItem icon="settings" title="Manage Facebook Accounts" href="/facebook/accounts">
      Manage Facebook Accounts
    </ActionMenuItem>
  );

  const renderSubtitle = () => {
    return (
      <div>
        <strong>{totalPages} </strong>pages with <strong>{totalMatched.toLocaleString()}</strong> matched engagements in
        the last <strong>3 months</strong>
      </div>
    );
  };

  return (
    <div className="facebook-pages-controller">
      <div className="facebook-header-wrapper">
        <PageHeader back title="All Facebook Pages" subtitle={renderSubtitle()} actions={facebookPagesActions()} />
      </div>
      <FacebookNav active={view_type} />
      <div className="facebook-pages-filters">
        <SearchInput
          value={searchText}
          onChange={(val) => setSearchText(val)}
          width={350}
          placeholder="Search by page name"
          debounceTimeout={300}
        />
        <SortDropdown
          options={[
            { label: "Facebook Page Name", value: "name" },
            { label: "Unmatched Engagers", value: "unmatched_engagers" },
            { label: "Matched Constituents", value: "matched_constituents" },
            { label: "Matched Engagements", value: "matched_engagements" },
          ]}
          sortProp={sortProp}
          sortReverse={sortReverse}
          onSort={handleSort}
        />
      </div>
      <div className="facebook-pages-table">
        <FlexTable
          loading={loading}
          data={pages}
          columns={columns(sortReverse, sortProp, handleSort)}
          caption="List of Facebook pages with meta data"
        />
      </div>
    </div>
  );
};

FacebookPagesController.propTypes = {
  view_type: PropTypes.string,
};

export default FacebookPagesController;
