import _ from "underscore";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { AdvancedCombobox } from "@evertrue/et-components";
import { AssignmentTitleResolver } from "apps/portfolio-performance/components/assignment-title-resolver";
import { getAssignmentTitles } from "apps/portfolio-performance/portfolio-requests";

const AssignmentTitleDropdown = props => {
  const [searchText, setSearchText] = useState("");
  const { assignment_options, loading } = AssignmentTitleResolver();
  const [importedTitles, setImportedTitles] = useState([]);

  const getFilteredOptions = () => {
    const activeOptions = assignment_options && assignment_options.filter(option => option.active === true)
    let filteredOptions = [];
    if (activeOptions && activeOptions.length > 0) {
      filteredOptions = activeOptions;
    } else {
      filteredOptions = importedTitles;
    }
    const { value } = props.value
    if (value && !filteredOptions.some(option => option.value === value)) {
      filteredOptions.push({ "label": value, "value": value });
    }
    return filteredOptions.filter(option => _.searchCompare(option.label, searchText));
  }


  useEffect(() => {
    const getAndSetImportedTitles = async () => {
      const titles = await getAssignmentTitles();
      const titlesObj = titles.map(val => ({
        value: val,
        label: val,
      }));
      setImportedTitles(titlesObj);
    };
    getAndSetImportedTitles();
  }, []);

  return (
    <AdvancedCombobox
      className={props.className}
      options={getFilteredOptions()}
      value={props.value}
      multiple={props.multiple}
      onChange={props.onChange}
      onSearch={search_text => setSearchText(search_text)}
      loading={loading}
      disabled={props.disabled}
      searchable
    />
  );
};

AssignmentTitleDropdown.propTypes = {
  className: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
};
export default AssignmentTitleDropdown;
