module.exports = do ->
   React = require("react")
   {ul} = ReactLibs.DOMFactories

   Nav = ReactLibs.createClass
      displayName: "Nav"
      propTypes:
         className: ReactLibs.PropTypes.string
         active: ReactLibs.PropTypes.any
         onSelect: ReactLibs.PropTypes.func

      navItems: ->
         React.Children.map @props.children, (child) =>
            if child
               React.cloneElement child,
                  onSelect: @props.onSelect
                  key: child.props.activeWhen
                  active: @props.active

      render: ->
         ul className: "nav #{@props.className || ""}", style: @props.style,
            @navItems()
