module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   EverTrue = require("app")
   Facebook = require("entities/external/facebook")
   SocialContentSearch = require("entities/search/social-content")
   SocialSearch = require("entities/search/social")
   SocialEngagement = require("entities/search/social-engagement")
   FacebookMixin = require("mixins/facebook-mixin")
   Backbone = require("backbone")
   POST_FILTERS = ["fb_pages", "contents", "date"]

   # TODO - still feel like the logic in the store is getting complicated
   # and should maybe be broken into an contact posts store and a single post store
   FacebookPostStore = ->
      facebook = {}
      posts = {}
      post_comments = {}
      contact_posts_with_engagements = []
      single_post_comments = []
      cache = {}
      cache.selected_pages = []
      _cachedCommentRequest = undefined

      EverTrue.vent.on "Facebook:PageFilter:Change", ->
         cache.selected_pages = EverTrue.UrlManager.get("fb_pages") || []

      _resetCache = ->
         contact_posts_with_engagements = []
         single_post_comments = []
         cache =
            query: {}
            filters: {}
            search_object: {}
            selected_pages: cache.selected_pages || []

      _saveContactPostInfo = (data, contact_id) ->
         unique_posts = contact_posts_with_engagements[contact_id] || []

         _.each data, (data) ->
            post_obj = {}
            post_obj = data.content || data
            post_obj.id = data.content?.remote_id || data.id
            post_obj.contact_id = data.contact_id

            # Kind of weird to have here because will be overridden later
            # but need to know for initial filtering
            if data.engagement?.type == "like"
               post_obj.like = 1
            else if data.engagement?.type == "comment"
               post_obj.comment = [data.engagement.contents]

            #  Check if contact has posts saved
            if unique_posts.length == 0
               unique_posts.push(post_obj)
            else
               existing_post = _.findWhere unique_posts, {id: post_obj.id}
               unique_posts.push(post_obj) if !existing_post

         contact_posts_with_engagements[contact_id] = unique_posts
         unique_posts

      # Public Methods
      api = $.extend true, {}, Backbone.Events,
         initializePosts: ->
            _resetCache()
            facebook = new Facebook(facebook?.toJSON?())
            posts = new SocialContentSearch()
            posts.setNetworkType("facebook")

         initializeContactPosts: ->
            _resetCache()
            facebook = new Facebook(facebook?.toJSON?())
            posts = new SocialSearch()
            posts.setNetworkType("facebook")
            posts.setSort("content.created_at", true)
            posts.limit = 5

         initializePost: ->
            facebook = new Facebook(facebook?.toJSON?())
            unless posts instanceof SocialContentSearch
               posts = new SocialContentSearch()
            posts.setNetworkType("facebook")
            @initializeSinglePostComments()

         initializeSinglePostComments: ->
            post_comments = new SocialEngagement()

         bindToUpdates: (callback) ->
            @listenTo posts, "change reset", =>
               data = posts.toJSON()
               callback(@parsePosts(data), posts.total)

         getPostData: ->
            data: @parsePosts posts.toJSON()
            total: posts.total
            search_object: cache.search_object

         parsePosts: (data) ->
            if !data
               data = posts.toJSON()
            contact_id = data[0]?.contact_id
            # If parsing posts on a contact's posts page, save contact's likes and comments
            if contact_id
               _saveContactPostInfo(data, contact_id)
            else
               data

         savePostEngagementData: (post_obj, callback) ->
            content_id = FacebookMixin.formatContentId(post_obj.remote_id)
            engagement_types = FacebookMixin.availEngagementTypes()
            post_stats = new SocialEngagement()
            post_stats.fetch
               search_query: {post_engagers_with_contact: {
                  post_id: content_id,
                  types: engagement_types,
                  contact_id: [post_obj.contact_id]
               }}
               success: ->
                  likes = 0
                  comments = []
                  stat_results = post_stats.toJSON()

                  _.each stat_results, (result) ->
                     if result.type == "like"
                        likes += 1
                     else if result.type == "comment"
                        comments.push(result.contents)

                  contact_posts = contact_posts_with_engagements[post_obj.contact_id]
                  post_to_update = _.findWhere contact_posts, {id: post_obj.id}
                  if post_to_update
                     post_to_update.like = likes
                     post_to_update.comment = comments

                     if _.isFunction callback
                        callback(post_to_update)
                  contact_posts_with_engagements[post_obj.contact_id] = contact_posts

         # Get all comments for a particular post and a list of contacts
         getPostComments: (post_id, contact_ids, callback) ->
            ids_to_fetch = []

            # Get ids of contacts that dont have posts cached
            _.each contact_ids, (contact_id) ->
               post = single_post_comments[contact_id]
               if !post
                  ids_to_fetch.push(contact_id)

            if ids_to_fetch.length > 0
               _cachedCommentRequest?.abort()

               post_comments.infinity_running = false
               post_comments.infinity
                  search_query: {post_engagers_with_contact: {
                     post_id: post_id,
                     types: ["comment"],
                     contact_id: ids_to_fetch
                  }}
                  beforeSend: (xhr) ->
                     _cachedCommentRequest = xhr
                  success: =>
                     # If theres more results then the total, call function again
                     # (for cases where one person has multiple comments)
                     if post_comments.length < post_comments.total
                        _cachedCommentRequest = undefined
                        @getPostComments(post_id, ids_to_fetch, callback)
                     else
                        # Dont' save until have all comments for these contacts
                        post_comments.infinity_running = false
                        _.each post_comments.toJSON(), (post) ->
                           if single_post_comments[post.contact_id]
                              single_post_comments[post.contact_id].comments?.push(post.contents)
                           else
                              single_post_comments[post.contact_id] = {comments: [post.contents]}
                        callback(single_post_comments) if _.isFunction(callback)
                  error: ->
                     callback([]) if _.isFunction(callback)
            else
               callback(single_post_comments) if _.isFunction(callback)

         unbind: ->
            @stopListening()

         hasMorePostsToLoad: (type) ->
            data = posts.toJSON()
            contact_id = data[0]?.contact_id
            if contact_id #if on contact posts page - TODO: add better way to check for this
               filtered_posts = FacebookMixin.filterSocialSearchResults(posts.toJSON(), type)
               return filtered_posts.length < posts.total
            else
               return posts.length < posts.total

         getPosts: ->
            posts.infinity
               query: cache.query
               search_query: cache.search_object
               success: -> posts.trigger("reset")
               error: -> posts.trigger("reset")

         infinityReset: ->
            posts.infinityReset {silent: true}

         getPostsForContact: (id, type) ->
            if type == "all"
               type = null
            posts.setEngagementType(type)
            posts.infinityForContact id,
               success: -> posts.trigger("reset")
               error: -> posts.trigger("reset")

         getPost: (id, callback) ->
            post = posts.findWhere {remote_id: id}
            if post then callback?(post.toJSON())
            else
               posts.fetch
                  query: must: [{remote_id: match: id}]
                  params: limit: 1
                  success: ->
                     post = posts.first()
                     callback?(post?.toJSON())

         loadMore: (callback) ->
            @getPosts()

         search: (search_object, options={}) ->
            cache.search_object = search_object
            cache.selected_pages = search_object?.fb_pages || []

            filters = {}
            _.each POST_FILTERS, (key) ->
               filters[key] = cache?.search_object?[key]
            filters = _.compactObject(filters)
            if _.isEmpty filters
               EverTrue.UrlManager.unset("fb_filters")
            else
               EverTrue.UrlManager.set("fb_filters", filters)

            unless options.silent
               posts.infinityReset({silent: true})
               @getPosts()

         reset: ->
            _resetCache()

   FacebookPostStore()
