import { useState, useCallback } from "react";

const DEFAULT_SETTINGS = { page: 1, sortReverse: false, sortProp: "giving.et_donor_index" };

const useTableSettings = (defaultSettings = DEFAULT_SETTINGS) => {
  const [tableSettings, setTableSettings] = useState(defaultSettings);

  const updateTableSettings = (newSettings) => {
    setTableSettings((prevSettings) => {
      return { ...prevSettings, ...newSettings };
    });
  };

  const resetTable = useCallback(() => {
    setTableSettings(DEFAULT_SETTINGS);
  }, []);

  return {
    tableSettings,
    updateTableSettings,
    resetTable,
  };
};

export default useTableSettings;
