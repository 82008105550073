module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   React = require("react")
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories
   HorizontalScrollbar = require("components/controls/horizontal-scrollbar")

   createComponent "HorizontalScrollViewport",
      propTypes:
         disable: ReactLibs.PropTypes.bool
         showArrows: ReactLibs.PropTypes.bool
         onScroll: ReactLibs.PropTypes.func

      getInitialState: ->
         scroll_offset: 0
         margin_offset: null
         viewport_width: 0
         page_width: 0
         max_offset: 0
         scrollbar_width: 0

      componentDidMount: ->
         $(window).on "resize", @setViewportWidth
         @setViewportWidth()
         @setPageWidth()

      componentWillUnmount: ->
         clearTimeout(@scrollThrottle)
         $(window).off "resize", @setViewportWidth

      setViewportWidth: ->
         if @depracated_is_mounted
            $wrapper = $(@refs.wrapper)
            @setState {viewport_width: _.toNumber($wrapper.outerWidth())}

      setPageWidth: ->
         if @depracated_is_mounted
            $page_width = $(@refs.pageWidth)
            @setState {page_width: _.toNumber($page_width.contents().outerWidth())}

      getStartMax: ->
         if @state.viewport_width > 0
            Math.floor(@state.page_width / @state.viewport_width)
         else 0

      handleScroll: (e) ->
         max_offset = @state.max_offset
         if e.deltaX and !e.deltaY
            e.preventDefault()
            e.stopPropagation()

            delta_pixels = e.deltaX
            offset = @state.scroll_offset + delta_pixels
            offset = _.withinRange(offset, 0, max_offset)

            @handleSetOffset(offset)

      handleSetOffset: (offset) ->
         @setState {scroll_offset: offset}
         @props.onScroll?(-@getMarginOffset(offset), offset)

      getMarginOffset: (offset) ->
         offset ?= @state.scroll_offset
         if offset > 0
            (offset * @state.page_width) / @state.scrollbar_width
         else 0

      getScrollBarSize: ->
         (@state.viewport_width / @state.page_width) * 100

      render: ->
         has_scrollbar = !@props.disable && (@state.viewport_width < @state.page_width)

         div className: classNames("horizontal-viewport", @props.className), ref: "wrapper",
            div
               className: classNames("horizontal-viewport--content", "has-scrollbar": has_scrollbar)
               ref: "pageWidth"
               style: {marginLeft: -@getMarginOffset()}
               onWheel: (e) => @handleScroll(e)
               @props.children

            if has_scrollbar
               HorizontalScrollbar
                  className: "fixed-table--scrollbar"
                  offset: @state.scroll_offset
                  size: @getScrollBarSize()
                  showArrows: @props.showArrows
                  onSetWidths: (max_offset, scrollbar_width) =>
                     @setState
                        max_offset: max_offset
                        scrollbar_width: scrollbar_width
                  onArrowChange: (change, max_offset) =>
                     new_offset = @state.scroll_offset + (10 * change)
                     @handleSetOffset(_.withinRange(new_offset, 0, max_offset))
                  onScrollDrag: @handleSetOffset
                  onScrollDragEnd: @handleSetOffset

