module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   BaseRouter = require("base/base-router")
   MyPortfolioController = require("apps/major-gifts/controllers/my-portfolio-controller")

   class MyPortfolioRouter extends BaseRouter
      routes:
         "my-portfolio"                : "showMyPortfolio"
         "my-portfolio/:type"          : "showMyPortfolio"
         "my-portfolio/:type/:subtype" : "showMyPortfolio"

      showMyPortfolio: (type, subtype) ->
         type = "prospects" if !type
         EverTrue.execute "removePageContainer"
         EverTrue.execute "setBrowserTitle", "My Portfolio"

         if !_.contains(["map", "stage"], subtype)
            subtype = null
         @currentView = MyPortfolioController({type: type, subtype: subtype})
         EverTrue.page.mount @currentView

   return MyPortfolioRouter
