import _ from "underscore";
import EverTrue from "app";
import { createSource } from "@evertrue/et-flux";
import Api from "entities/helpers/api";

const SUPPORT_ERROR_CODES = [107, 108, 104, 109, 110, 111, 112];

const InviteTeamSettingsSource = createSource("InviteTeamSettingsSource", {
  actions: {
    setErrors(message) {
      this.require(_.isString(message), "message should be a string");
    },
    setBulkErrors(submissionResponse) {
      this.require(_.isArray(submissionResponse), "bulk submission response must be an array");
    },
    sentInvite() {
      this.require(true);
    },
    setSupportError() {
      this.require(true);
    },
    setLoading(loading) {
      this.require(_.isBoolean(loading), "loading should be a boolean");
    },
    clearErrors() {
      this.require(true);
    },
  },

  api: {
    clearErrors() {
      this.actions.clearErrors();
    },

    sendInvite(user_object) {
      this.actions.setLoading(true);
      Api.AUTH.AFFIL_INVITES.post({
        disableAlerts: true,
        data: JSON.stringify(user_object),
        success: () => {
          this.actions.setLoading(false);
          this.actions.sentInvite();
          EverTrue.Alert.success("Invite Sent.");
        },
        error: xhr => {
          if (xhr.responseJSON) {
            this.actions.setLoading(false);
            if (_.contains(SUPPORT_ERROR_CODES, xhr.responseJSON.error_code)) {
              this.actions.setSupportError();
            }
            this.actions.setErrors(
              xhr.responseJSON.message,
              xhr.responseJSON.error_code,
              JSON.parse(xhr._options.data)
            );
          } else {
            this.actions.setSupportError();
          }
        },
      });
    },

    sendInvites(users_array) {
      this.actions.setLoading(true);
      Api.AUTH.AFFIL_INVITES_BULK.post({
        disableAlerts: true,
        data: JSON.stringify(users_array),
        success: xhr => {
          this.actions.setLoading(false);
          if (xhr.failed_invitations.length === 0) {
            this.actions.sentInvite();
            EverTrue.Alert.success("Invite(s) Sent.");
          } else {
            this.actions.setBulkErrors(xhr.failed_invitations, xhr.successful_invitations);
          }
        },
        error: () => {
          this.actions.setLoading(false);
          this.actions.setSupportError();
          // All bulk invites come back as 200
        },
      });
    },
  },
});

export default InviteTeamSettingsSource;
