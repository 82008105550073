import EverTrue from "app";
import _ from "underscore";
import BaseRouter from "base/base-router";
import LegacyCoreSearchController from "apps/core-search/controllers/legacy-core-search-controller";
import InteractionDetailController from "apps/interactions/controllers/interaction-detail-controller";

class InteractionRouter extends BaseRouter {
  static initClass() {
    this.prototype.routes = {
      interaction: "search",
      "interaction/:id": "interaction",
    };
  }

  search() {
    EverTrue.execute("removePageContainer");
    EverTrue.execute("setBrowserTitle", "Interactions");
    this.currentView = <LegacyCoreSearchController type="interactions" />;
    EverTrue.page.mount(this.currentView);
  }

  interaction(id) {
    this.currentView = <InteractionDetailController id={_.toNumber(id)} />;
    EverTrue.page.mount(this.currentView);
  }
}

InteractionRouter.initClass();
export default InteractionRouter;
