import { usePromiseResolver } from "@evertrue/promise-resolver";
import API from "entities/helpers/api";

const useProposalStages = () => {
  const [proposalStages, loading] = usePromiseResolver(() => API.UGC.PROPOSAL_STAGES.get(), []);

  const proposalStageOptions =
    proposalStages &&
    proposalStages.map((proposalStage) => {
      return {
        label: proposalStage.stage_name,
        value: proposalStage.stage_name,
        mappedAmount: proposalStage.proposal_amount_type,
      };
    });

  return [proposalStageOptions, loading];
};

export default useProposalStages;
