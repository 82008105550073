module.exports = do ->
   React = require("react")
   _ = require("underscore").default
   {div} = ReactLibs.DOMFactories
   moment = require("moment")
   EverTrue = require("app")
   {createFactory} = require("base/new-utils")
   Modal = createFactory require("components/modals/modal")
   ModalConfirm = createFactory require("components/modals/modal-confirm")
   {createComponent} = require("@evertrue/et-flux")
   FormField = createFactory require("components/forms/form-field")
   Icon = createFactory (require("components/elements/icon").default)
   AdvancedCombobox = createFactory(require("@evertrue/et-components").AdvancedCombobox)
   CalendarDropdown = createFactory(require("components/controls/calendar-dropdown"))
   ExportSource = require("apps/export/sources/export-source")
   ExportTypeConfig = require("apps/export/export-type-config").default


   DATE_FORMAT = "YYYY-MM-DD"
   _makeQuery = (start_date, end_date) ->
      return {must: [
         {livemode: {match: true}},
         {type: {match: "completed"}},
         {
            enqueued_at: {
               gte: start_date,
               lte: end_date
            }
         }
      ]}

   _track = (type) -> EverTrue.track.set("manual_export_action",{type})


   formatRange = (range) ->
      {gte, lte} = range
      _.compactObject
         gte: if gte then moment(gte, "YYYY-MM-DD").startOf("day").format("x")
         lte: if lte then moment(lte, "YYYY-MM-DD").endOf("day").format("x")

   createComponent "ManualExportModal",
      propTypes:
         exportType: ReactLibs.PropTypes.string

      getInitialState: ->
         time_period: ""
         custom_time: null
         date_from: moment().format(DATE_FORMAT)
         date_to: moment().format(DATE_FORMAT)

      handleSubmit: (date) ->
         {date_from, date_to, time_period} = @state

         if time_period.value == "full"
            start_date = 0
            end_date = moment()
         else if time_period.value != "custom"
            start_date = moment().subtract(time_period.value, "days").startOf("day")
            end_date = moment().startOf("day")
         else if time_period.value == "custom" && date_from && date_to
            start_date = moment(date_from)
            end_date = moment(date_to)
            # returns an object of moment objects that are start and end of day
            {gte, lte} = formatRange({gte: date_from , lte: date_to})
            start_date = _.toNumber(gte)
            end_date = _.toNumber(lte)


         ExportSource.dateRangeExportByType(@props.exportType, start_date?.valueOf(), end_date?.valueOf())


         if @props.exportType == "interaction"
            _track("interactions")

         if @props.exportType == "assignment"
            _track("volunteers")

         if @props.exportType == "assignment_team"
            _track("relationship_managment")

         if @props.exportType == "proposal"
            _track("proposals")

      render: ->
         {date_from, date_to, time_period} = @state
         is_disabled = !@state.time_period || @state.time_period == "custom" && (!date_from && !date_to)
         label = ExportTypeConfig[@props.exportType]?.label

         Modal
            width: 500
            keepOpen: true
            remove: @props.remove
            onUnmount: @props.onUnmount
            ModalConfirm
               buttonLabel: "Start Export",
               header: "Manual Export for #{label}",
               onSubmit: @handleSubmit
               buttonDisabled: is_disabled
               div className: "manual-exports-modal",
                  FormField
                     label: "Time Period"
                     AdvancedCombobox
                        value: time_period
                        options: ExportTypeConfig[@props.exportType]?.export_options
                        onChange: (time_period) =>
                           @setState {time_period}

                  if @state.time_period.value == "custom"
                     div className: "manual-exports-modal--custom-date",
                        Icon icon: "subdirectory-arrow-right", size: 2
                        CalendarDropdown
                           className: "trip-form-calendar"
                           date_from: @state.date_from
                           date_to: @state.date_to
                           onChange: (date_from, date_to) =>
                              @setState {date_from, date_to}
