import Api from "entities/helpers/api";
import _ from "underscore";
import {
  TEAM_SOLICITORS_QUERY,
  getProposalStatsQueryBySolicitorIds,
} from "apps/portfolio-performance/portfolio-queries";
import { fetchFromGraphql } from "entities/helpers/gqlCall";

const gql = String.raw;

export const updateStage = (
  assignment_id,
  payloadData,
  assignmentType = "TEAM",
) => {
  return Api.VOLUNTEER.UPDATE_STAGE.put({
    urlArgs: { assignment_id },
    data: JSON.stringify(payloadData),
  });
};

export const updateAssignmentTitle = (assignment_id, assignment_title) =>
  Api.VOLUNTEER.UPDATE_ASSIGNMENT_TITLE.put({
    urlArgs: { assignment_id },
    data: _.jsonStringify({ title: assignment_title }),
  });

// creates an assignment, which would add to a pool since we fetch assignments by pool_id
// only properties currently allowed to write to on the assignments table are:
// prospect_contact_id: int, solicitor_contact_id: int, stage: string
export const assignProspects = ({ teamId, data, assignmentType = "TEAM" }) => {
  return Api.VOLUNTEER.ASSIGNMENTS.post({
    urlExtend: "/assign",
    params: { pool_id: teamId, pool_type: assignmentType },
    data: JSON.stringify(data),
  });
};

export const updateTimeInStage = (assignment_id, stage, time) =>
  Api.VOLUNTEER.UPDATE_STAGE.put({
    urlArgs: { assignment_id },
    data: _.jsonStringify({ stage: stage, stage_start_date: time }),
  });

export const removeProspectFromPool = (assignmentId, assignmentType = "TEAM") => {
  return new Promise((res, rej) =>
    Api.VOLUNTEER.ASSIGNMENTS.delete({
      urlExtend: `/${assignmentId}`,
      statusCode: {
        202: res,
        400: rej,
      },
      error: (xhr) => {
        if (xhr.status === 202) return res();
        return rej();
      },
    })
  );
};

export const updateKeyProspect = (assignment_id, isKeyProspect) =>
  Api.VOLUNTEER.KEY_PROSPECT.put({
    urlArgs: { assignment_id },
    params: { key_prospect: isKeyProspect },
  });

export const fetchSolicitorsInTeam = (pool_id, search_text = "", sort_order = true, assignmentType) => {
  return Api.SEARCH.CONTACTS.post({
    params: { limit: 500 },
    data: _.jsonStringify(TEAM_SOLICITORS_QUERY(search_text, [pool_id], assignmentType)),
  }).then((search_resp = {}) => {
    const contact_ids = search_resp.items || [];
    const gql = String.raw;
    const query = gql`
      query ($ids: [BigInt!]) {
        contacts(ids: $ids) {
          id
          contact_attributes {
            name_full
          }
        }
      }
    `;

    // use the contact ids to retrieve the full name from graph ql without having to load the whole contact obj
    return Api.GRAPHQL.GRAPHQL.post({
      data: JSON.stringify({
        operationName: null,
        query: String.raw`${query}`,
        variables: { ids: contact_ids },
      }),
    });
  });
};

export const fetchSolicitorAssignments = (contact_ids) => {
  return Api.VOLUNTEER.SOLICITORS.get({
    urlExtend: "/bulk",
    params: {
      contact_id: contact_ids,
      pool_type: "TEAM",
    },
  });
};

export const fetchSolicitorProposals = (contact_ids) =>
  Api.SEARCH.PROPOSAL_STATS.post({
    data: JSON.stringify(getProposalStatsQueryBySolicitorIds(contact_ids)),
    params: { limit: 0 },
  });

export const removeSolicitorFromTeam = (contact_id, pool_id) =>
  new Promise((res, rej) =>
    Api.VOLUNTEER.SOLICITOR_CONTACT.delete({
      urlExtend: `/${contact_id}`,
      params: {
        pool_type: "TEAM",
        pool_id: pool_id,
      },
      statusCode: {
        202: res,
        400: rej,
      },
      error: (xhr) => {
        if (xhr.status === 202) return res();
        return rej();
      },
    })
  );

export const getAssignmentTitles = async () => {
  const query = gql`
      query {
        assignment_titles {
          title
        }
      }
    `;

  const { assignment_titles } = await fetchFromGraphql(query, null, "assignment_titles");

  return assignment_titles.map(t => t.title);
}