import _ from "underscore";
import { PropTypes } from "base/react-libs";
import RuleRow from "apps/query-builder/components/logic-group/rule-row";

const MoreFiltersModalRightArea = ({ selectedFilters }) => {
  const getFilterById = (compound_id) => {
    return _.find(selectedFilters, { compound_id: compound_id });
  };

  const getDefaultOperator = (compound_id) => {
    const selected = getFilterById(compound_id) || {};
    const equalsOp = selected.operators.find((op) => op.label === "equals");
    return equalsOp || _.first(selected.operators);
  };

  const renderRightAreaContent = () => {
    return getSelectedFiltersData().map((item, index) => (
      <div key={(item.compound_id, index)}>
        {item.group} : {item.label}
        <RuleRow className="query-builder--wrapper" filter={item} onRemove={() => {}} onChange={() => {}} />
      </div>
    ));
  };

  const getSelectedFiltersData = () => {
    return selectedFilters.map((filter) => {
      const ui_component = getDefaultOperator(filter.compound_id).ui_component;
      const operator = getDefaultOperator(filter.compound_id).operator_query_key;
      filter = { ...filter, ui_component: ui_component, value: "", operator: operator };
      return filter;
    });
  };

  return <div>{renderRightAreaContent()}</div>;
};

MoreFiltersModalRightArea.propTypes = {
  selectedFilters: PropTypes.array,
};

export default MoreFiltersModalRightArea;
