module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   EverTrue = require("app")
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   {div, strong } = ReactLibs.DOMFactories
   UrlSource = require("apps/layout/sources/url-source")
   FeatureStore = require("apps/layout/stores/feature-store").default
   ProfileInteractionsStore = require("apps/profile/stores/profile-interactions-store").default
   ProfileInteractionsSource = require("apps/profile/sources/profile-interactions-source").default
   InteractionsFeed = require("apps/interactions/components/interaction-feed")
   InteractionsFilters = require("apps/profile/components/interactions/interactions-filters")
   FilterDrawer = require("components/controls/filter-drawer")
   {createFactory} = require("base/new-utils")
   EllipsisOverflow = createFactory require("components/formatting/ellipsis-overflow")
   InteractionPagination = require("apps/interactions/components/interaction-pagination")
   InteractionUtils = require("apps/interactions/interaction-utils")
   ModalTrigger = createFactory require("components/modals/modal-trigger")
   Modal = createFactory require("components/modals/modal")
   SortDropdown = require("components/controls/sort-dropdown")
   InteractionForm = createFactory(require("apps/interactions/components/create/interaction-form").default)
   Button = createFactory(require("@evertrue/et-components").Button)
   
   scrollTimeout = null;

   createComponent "ProfileInteractionsController",
      propTypes:
         contact: ReactLibs.PropTypes.object

      getDefaultProps: ->
         contact: {}

      getInitialState: ->
         filters_open: false
         sort_reverse: true
         sort_order: "desc"
         viewMore: 0
         comment_filters: ProfileInteractionsStore.getAppliedFilters()

      registerStores: ->
         @on ProfileInteractionsStore, ->
            comments: ProfileInteractionsStore.getForContact(@props.contact.id)
            loading: ProfileInteractionsStore.getLoading()
            total: ProfileInteractionsStore.getTotal(@props.contact.id) || 0
            applied_filters: ProfileInteractionsStore.getAppliedFilters() || {}
            has_more_to_load: ProfileInteractionsStore.hasMoreToLoad(@props.contact.id)

         @on FeatureStore, ->
            has_interaction_writes: FeatureStore.hasInteractionWrites()
            has_imported_notes: FeatureStore.hasFeature("ugc_show_imported_notes")

      componentDidMount: ->
         # Flag for scrollPosition and View More interaction count
         # Ideally any or all instances of scrollpos should be replaced with ScrollContext and removed from URL but,
         # as this page is in Coffee script, we are unable to use hooks. We might have to change the code to JS
         @setState {scrollpos: EverTrue.UrlManager.get("scrollpos")}
         @setState {viewMore: EverTrue.UrlManager.get("viewMore") || 0 }

         UrlSource.fetch("notefilters")
         _.defer =>
            if @depracated_is_mounted
               @setState {comment_filters: ProfileInteractionsStore.getAppliedFilters()}

         ProfileInteractionsSource.fetch(@props.contact.id)

      componentDidUpdate: (prevProps, prevState) ->
         if (prevState.has_imported_notes != @state.has_imported_notes) ||
            (@props.contact.id && @props.contact.id != prevProps.contact.id)
               ProfileInteractionsSource.fetch(@props.contact.id)
         
         if !@state.loading && (prevState.loading != @state.loading)
            if @state.scrollpos && !@preventScroll
                  @scrollToInteraction(@state.scrollpos, @state.viewMore)

      componentWillUnmount: ->
         UrlSource.clear("scrollpos")
         UrlSource.clear("viewMore")
         ProfileInteractionsSource.applyFilters(@props.contact.id, {})
      
      # Ideally this method just need to run the $(@refs.body).animate {scrollTop: scrollpos} logic, but
      # since this page contains View More button which loads more interactions. 
      # So if the user navigates to the next page using the View More button and comes back, 
      # we need to make sure we load the page till that specific interaction 
      # then scroll to it.
      scrollToInteraction: (scrollpos,viewMore) ->
         @preventScroll = true
         
         # Setting back View More count to state for futher View More button clicks
         @setState {viewMore: +viewMore}
         loadMoreInteractions = viewMore 
         # Dividing total scroll position to scroll each iteration of View More Interactions
         scrollPos = scrollpos/viewMore
         scrollMultiplier = 1
         
         # Loading and scrolling over each iteration of Interactions
         loadMoreWithDelay = (count) =>
            if count > 0
               if !@state.loading && @state.has_more_to_load
                  # Loading an iteration of View More
                  ProfileInteractionsSource.loadMore(@props.contact.id)
                  currentScrollpos = scrollPos * scrollMultiplier
                  setTimeout =>
                     # Scrolling over an iteration of Interactions with a delay
                     $(@refs.body).animate {scrollTop: currentScrollpos}
                     scrollMultiplier = scrollMultiplier + 1
                     loadMoreWithDelay(count - 1)
                  , 350

         if viewMore > 0
            loadMoreWithDelay(loadMoreInteractions)
         else
            # Scrolling if there are no multiple iterations of Interactions
            $(@refs.body).animate {scrollTop: scrollpos}

         @preventScroll = false
         @setState {scrollpos: undefined} if @depracated_is_mounted

      handleScroll: () ->
         unless @preventScroll
            clearTimeout(scrollTimeout);
            scrollTimeout = setTimeout((() => UrlSource.change({"scrollpos": $(@refs.body).scrollTop()})), 100);

      handlePage: ->
         # Increasing the View More click count and storing in URL
         viewCount = @state.viewMore + 1;
         EverTrue.UrlManager.set("viewMore", viewCount)
         @setState {viewMore: +viewCount}
         ProfileInteractionsSource.loadMore(@props.contact.id)

      handleToggleFilter: (is_open) ->
         if !is_open && !_.isEqual(@state.comment_filters, @state.applied_filters)
            @handleApplyFilter()
         else @setState {filters_open: is_open}

      handleCancelFilter: ->
         @setState
            comment_filters: @state.applied_filters
            filters_open: false

      handleApplyFilter: ->
         { contact } = @props
         { comment_filters, sort_order } = @state

         ProfileInteractionsSource.applyFilters(contact.id, comment_filters, sort_order)
         @setState {filters_open: false}

      handleResetFilter: ->
         @setState {comment_filters: {}}

      handleSort: ->
         sort_order = if @state.sort_order is "desc" then "asc" else "desc"
         sort_reverse = !@state.sort_reverse
         @setState {
            sort_reverse,
            sort_order
         }, @handleApplyFilter

      renderCommentModal: ->
         Modal width: 568, keepOpen: true,
            InteractionForm
               contact: @props.contact
               referrer: "profile_action_menu"

      renderFilterHeader: ->
         if @state.has_imported_notes then label = "Interactions"
         else label = "EverTrue Comments"

         div className: "profile-notes--filter-header",
            EllipsisOverflow null,
               strong null,
                  if !_.isEmpty(@state.applied_filters.category)
                     _.pluck(@state.applied_filters.category, "label")?.join(", ")
                  else "All #{label}"
               div className: "profile-notes--filter-header-count",
                  strong null, @state.total + " #{label}"

            div className: "profile-notes--filter-header-controls",
               ModalTrigger
                  modal: @renderCommentModal(),
                  disable: @props.disable,
                  title: if @state.has_interaction_writes then "Add Interaction" else "Add Comment",
                  buttonType: "secondary"

                  if @state.has_interaction_writes then "Add Interaction" else "Add Comment"

               SortDropdown
                  className: "profile-notes--sort"
                  options: [{label: "Date Engaged", value: "interaction.created_at"}]
                  sortProp: "interactions.created_at"
                  sortReverse: @state.sort_reverse
                  onSort: @handleSort

      render: ->
         div className: "profile-notes",
            div className: classNames("profile-notes--filters", "is-filter-open": @state.filters_open),
               FilterDrawer
                  canApply: true
                  header: @renderFilterHeader()
                  onToggle: @handleToggleFilter
                  onCancel: @handleCancelFilter
                  onApply: @handleApplyFilter
                  onReset: if !_.isEmpty(@state.comment_filters) then @handleResetFilter

                  InteractionsFilters
                     filters: @state.comment_filters
                     onChange: (value) =>
                        @setState {comment_filters: value}
            div className: "contact-profile--body", ref: "body", onScroll: @handleScroll,
               div className: "profile-notes--feed",
                  InteractionsFeed
                     notes: @state.comments
                     contactId: @props.contact.id
                     loading: @state.loading
                     group: false
                     showContact: false
                     showFullText: false
                     highlight: InteractionUtils.getHighlightRegex(@state.applied_filters.keyword)
                     showMeta: false

               if @state.has_more_to_load
                  InteractionPagination
                     className: "profile-notes--pagination",
                     onPage: @handlePage
