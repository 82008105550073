module.exports = do ->
   _ = require("underscore").default
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")
   Decorator = require("clientDecorator")


   createSource "SimilarProfileSource",
      actions:
         fetchedProfiles: true
         loading: true

      api:
         fetch: (contact_id) ->
            @actions.loading(true)

            Api.CONTACTS.SEARCH.post
               data: _.jsonStringify
                  like: [contact_id]
               params: limit: 50
               disableAlerts: true
               success: (resp = {}) =>
                  data = _.clone(resp)
                  data.items = _.map resp.items, (contact) ->
                     Decorator.Contacts.parse(contact)
                  @actions.fetchedProfiles(contact_id, data)
                  @actions.loading(false)
