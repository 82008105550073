import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "@evertrue/et-flux";
import FeatureStore from "apps/layout/stores/feature-store";
import ProfileCard from "apps/profile/components/profile-card";
import ProfileCardHeader from "apps/profile/components/profile-card-header";
import ProfileGivingController from "apps/contact/controllers/profile-giving-controller";
import ProfileGiftsFeedController from "apps/profile/controllers/profile-gifts-feed-controller";
import ScoreCard from "apps/profile/components/score-card";
import AnnualGiving from "apps/profile/components/annual-giving";
import classNames from "classnames";

const mapStateToProps = () => ({
  has_score: FeatureStore.hasFeature("scores"),
  has_annual_giving_score: FeatureStore.hasFeature("annual_giving_score"),
  has_hybrid_giving: FeatureStore.hasFeature("hybrid_giving"),
});

class ProfileGivingTabController extends Component {
  static propTypes = {
    contact: PropTypes.object,
    has_score: PropTypes.bool,
    has_annual_giving_score: PropTypes.bool,
    has_hybrid_giving: PropTypes.bool,
  };

  renderTransactionalGifts(contact) {
    if (this.props.has_hybrid_giving) {
      return (
        <ProfileCard>
          <ProfileCardHeader className="contact-profile--card-header" title="Gift Transactions" />
          <ProfileGiftsFeedController contact={contact} limit={7} showViewAll isInModule />
        </ProfileCard>
      );
    }
    return false;
  }

  render() {
    const { contact, has_score, has_annual_giving_score } = this.props;

    return (
      <div className="contact-profile--giving">
        <ProfileCard size={4}>
          <ProfileGivingController contact={contact} />
        </ProfileCard>
        <div
          className={classNames("contact-profile--giving-row", {
            "has-gift-feed": this.props.has_hybrid_giving,
          })}
        >
          {this.renderTransactionalGifts(contact)}
          {has_score || has_annual_giving_score ? (
            <ProfileCard>
              <ProfileCardHeader className="contact-profile--card-header" title="EverTrue Clarity" />
              <div className="contact-profile--card-score">
                {has_score ? <ScoreCard scores={contact.score} /> : null}
                {has_annual_giving_score ? <AnnualGiving contact={contact} /> : null}
              </div>
            </ProfileCard>
          ) : null}
        </div>
      </div>
    );
  }
}

export default connect(ProfileGivingTabController, [FeatureStore], mapStateToProps);
