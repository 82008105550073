import EverTrue from "app";
import _ from "underscore";
import BaseRouter from "base/base-router";
import UserDashboardController from "apps/dashboard/controllers/user-dashboard-controller";
import TeamDashboardController from "apps/dashboard/controllers/team-dashboard-controller";

class DashboardRouter extends BaseRouter {
  static initClass() {
    this.prototype.routes = {
      "dashboard(/)": "dashboardUser",
      "dashboard/user": "dashboardUser",
      "dashboard/user/:user_id": "dashboardUser",
      "dashboard/team": "dashboardTeam",
    };
  }

  dashboardUser(orig_user_id) {
    EverTrue.execute("setBrowserTitle", "Launchpad");
    const parsed_user_id = orig_user_id ? parseInt(orig_user_id, 10) : null;
    const user_id = _.isNaN(parsed_user_id) ? null : parsed_user_id;

    this.currentView = <UserDashboardController user_id={user_id} />;
    EverTrue.page.mount(this.currentView);
  }

  dashboardTeam() {
    EverTrue.execute("setBrowserTitle", "Launchpad");
    this.currentView = <TeamDashboardController />;
    EverTrue.page.mount(this.currentView);
  }
}

DashboardRouter.initClass();
export default DashboardRouter;
