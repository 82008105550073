import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeaderWithActions, ModalBody, Loading } from "@evertrue/et-components";
import EverTrue from "app";
import FormField from "components/forms/form-field";
import Api from "entities/helpers/api";
import useFetchSearchById from "apps/search/hooks/use-fetch-search-by-id";

const BookMarkUpdateModal = ({ isOpen, onClose, id, filters, refetch }) => {
  const { loading, search } = useFetchSearchById({ id });
  const [searchName, setSearchName] = useState("");

  useEffect(() => {
    setSearchName(search.name || "");
  }, [search.name]);

  const handleUpdate = async () => {
    try {
      await Api.HUB.SEARCHES.patch({
        urlArgs: { id },
        data: JSON.stringify({
          name: searchName,
          dsl_criteria: filters,
        }),
      });

      EverTrue.track.set("filter_v2_action", {
        type: "bookmark_update",
        permission: search.current_user_access,
      });

      refetch();
    } catch {
      EverTrue.Alert.error("Problem updating your search, please try again later");
    }
  };

  return (
    <Modal isOpen={isOpen} closeModal={onClose}>
      <ModalHeaderWithActions
        title="Update Bookmark"
        closeModal={onClose}
        onSubmit={handleUpdate}
        disableSubmit={searchName === ""}
      />
      <ModalBody>
        <div className="bookmark-modal">
          {loading ? (
            <Loading className="bookmark-modal--loading" />
          ) : (
            <>
              <div className="bookmark-modal--info">Saving will update the search criteria for this bookmark</div>
              <FormField label="You may also wish to update the name" inlineLabel={false}>
                <input
                  aria-label="Search Name"
                  type="text"
                  value={searchName}
                  placeholder="Search Name"
                  onChange={(e) => setSearchName(e.target.value)}
                  maxLength="100"
                />
              </FormField>
            </>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

BookMarkUpdateModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default BookMarkUpdateModal;
