import _ from "underscore";
import { Component } from "react";
import PropTypes from "prop-types";
import EverTrue from "app";
import { WithDNA } from "base/dna/dna-resolver";
import { ModalConfirm, Checkbox, Loading } from "@evertrue/et-components";
import { updateStage } from "apps/portfolio-performance/portfolio-requests";
import CachedContactsResolver from "apps/contact/components/contacts/cached-contacts-resolver";
import InteractionForm from "apps/interactions/components/create/interaction-form";
import UserSettingsSource from "base/dna/user-settings-source";
import ContactAssignmentContext from "apps/portfolio-performance/contexts/contact-assignment-context";

const CONFIRM_MOVE_KEY = "ET.Volunteer.ConfirmStageMove";

class ConfirmStageMoveModals extends Component {
  static propTypes = {
    assignment_being_changed: PropTypes.object,
    onCancel: PropTypes.func,
    onStopLoading: PropTypes.func,
    DNAUserSetting: PropTypes.bool, // comes from WithDNA
    fetchContactsByStage: PropTypes.func.isRequired,
    assignmentType: PropTypes.string,
  };

  static defaultProps = {
    assignment_being_changed: {},
    onCancel: _.noop,
    onStopLoading: _.noop,
  };

  state = {
    loading: false,
    confirmation_stage: 1,
    add_interaction_contact_id: null,
    hide_modal_in_the_future: false,
  };

  componentDidMount() {
    this.setState({ loading: true });
    // By default the confirm move stage modal will be shown, however
    // if it's been set to false then automatically update the move and skip stage 1
    if (!this.props.DNAUserSetting) {
      this.updateProspectStage();
    } else {
      this.setState({ loading: false });
    }
  }

  updateProspectStage = () => {
    const { assignment_id, stage, stage_id, stage_group_id, prospect_contact_id, solicitor_contact_id } = this.props.assignment_being_changed;
    const { assignmentType } = this.props
    EverTrue.track.set("portfolio_performance_action", { type: "update_prospect_stage" });
    let payloadData = assignmentType === "DXO" ? {
      stage_id, stage_group_id, prospect_contact_id, solicitor_contact_id,
    } : { stage };
    updateStage(assignment_id, payloadData, assignmentType).then((resp) => {
      EverTrue.Alert.success("Stage updated.");
      this.setState({
        add_interaction_contact_id: resp.prospect_contact_id,
        confirmation_stage: 2,
        loading: false,
      });
      // TODO: get the old stage and just refresh lists that changed
      this.props.fetchContactsByStage({ stage });
    });
  };

  dontShowModalAgain = () => {
    UserSettingsSource.updateUserSetting(CONFIRM_MOVE_KEY, false);
  };

  render() {
    // stage 1: confirm move
    if (this.state.confirmation_stage === 1) {
      return (
        <ModalConfirm
          header={this.state.loading ? "Saving..." : "Update Stage and Reset Time"}
          saveLabel="Continue"
          hasMargin
          onCancel={this.props.onCancel}
          onSubmit={() => {
            this.updateProspectStage();
            if (this.state.hide_modal_in_the_future) {
              this.dontShowModalAgain();
            }
          }}
        >
          {this.state.loading ? (
            <Loading />
          ) : (
            <div>
              Note that time in stage will reset and will updated to start of today.
              <Checkbox
                className="stage-move-checkbox"
                onChange={(val) => this.setState({ hide_modal_in_the_future: val })}
              >
                Do not warn next time
              </Checkbox>
            </div>
          )}
        </ModalConfirm>
      );

      // stage 2: status has been updated, ask if want to add interaction
    } else if (this.state.confirmation_stage === 2) {
      return (
        <ModalConfirm
          header="Stage Updated"
          saveLabel="Add Interaction"
          cancelLabel="Skip"
          hasMargin
          onCancel={this.props.onCancel}
          onSubmit={() => {
            this.props.onStopLoading();
            this.setState({ confirmation_stage: 3 });
          }}
        >
          Prospect Status has been updated. Would you like to add an interaction?
        </ModalConfirm>
      );

      // stage 3: add interaction
    } else if (this.state.confirmation_stage === 3) {
      return (
        <CachedContactsResolver ids={[this.state.add_interaction_contact_id]}>
          {({ contacts }) => {
            const contact = contacts[this.state.add_interaction_contact_id];
            return <InteractionForm contact={contact} requestHide={this.props.onCancel} />;
          }}
        </CachedContactsResolver>
      );
    }
  }
}
const Wrapped = (props) => (
  <ContactAssignmentContext.Consumer>
    {({ fetchContactsByStage }) => <ConfirmStageMoveModals fetchContactsByStage={fetchContactsByStage} {...props} />}
  </ContactAssignmentContext.Consumer>
);
export default WithDNA(Wrapped, CONFIRM_MOVE_KEY);
