module.exports = do ->
   _ = require("underscore").default
   {createStore} = require("@evertrue/et-flux")
   EventsSource = require("apps/events/sources/events-source")
   SortUtils = require("mixins/sort-utils")
   FeatureStore = require("apps/layout/stores/feature-store").default

   getSource = (selection) ->
      has_fb = FeatureStore?.hasFeature("events_facebook")
      has_eb = FeatureStore?.hasFeature("events_eventbrite")
      if _.notEmpty(selection) then { "source": in: _.pluck(selection, "value") }
      else if has_fb and has_eb then null
      else if has_fb then { "source": in: [ "facebook" ] }
      else if has_eb then { "source": in: [ "eventbrite" ] }
      else { "source": in: [] }

   createStore "EventsStore",
      getInitialState: ->
         events: {}
         matched_engagers_counts: {}
         page: 1
         is_loading: false
         current_filters: {}
         temp_filters: {}
         quick_search: ""
         sort:
            prop: "start_time"
            reverse: true

      registerActions: ->
         @on EventsSource.actions.fetchedEvents, (events) ->
            counts = @getState("matched_engagers_counts")
            ids = _.pluck(events.items, "id")
            new_ids = _.reject ids, (id) -> counts[id]?
            if _.notEmpty(new_ids)
               EventsSource.fetchEventEngagersCount(new_ids)
            @setState events: events, is_loading: false

         @on EventsSource.actions.fetchedMatchedEngagersCounts, (counts) ->
            @mergeState "matched_engagers_counts", counts

         @on EventsSource.actions.loading, (loading) ->
            @setState is_loading: loading

         @on EventsSource.actions.newPage, (page) ->
            @setState page: page
            @fetchEvents()

         @on EventsSource.actions.setCurrentFilters, (filters) ->
            @setState current_filters: filters, page: 1
            @fetchEvents()

         @on EventsSource.actions.setTempFilters, (filters) ->
            @setState temp_filters: filters

         @on EventsSource.actions.newQuickSearch, (query) ->
            @setState quick_search: query, page: 1
            @fetchEvents()

         @on EventsSource.actions.newSort, (prop, reverse) ->
            @setState page: 1, sort: {prop: prop, reverse: reverse}
            @fetchEvents()

         @on EventsSource.actions.resetFilters, ->
            @setState temp_filters: {}

      generateSearchQuery: (props, quick_search) ->
         search_query = {}
         _.each props, (prop) ->
            search_query[prop] = wildcard: quick_search + "*"
         return search_query

      fetchEvents: ->
         {fb_page, date, source} = @getState("current_filters") || {}
         {prop, reverse} = @getState("sort") || {}
         page = @getState("page")
         quick_search = @getState("quick_search")
         query = _.compactObject(
            must: _.compact [
               if quick_search
                  @generateSearchQuery(["name", "location_string"], quick_search)
               getSource(source)
               if fb_page
                  {"page_remote_id": in: _.pluck(fb_page, "value")}
               if _.notEmpty(date)
                  {start_time: _.compactObject({gte: date.date_from, lte: date.date_to})}
            ]
            sort: if prop then SortUtils.getQuery(prop, reverse)
         )
         EventsSource.fetchEvents(query, page)

      api:
         getEvents: ->
            counts = @getState("matched_engagers_counts")
            events = @getState("events")?.items || []
            _.map events, (event) ->
               _.extend {}, event, {_matched_engagers_count: counts[event.id]}

         getTotal: ->
            @getState("events")?.total

         getPage: ->
            { offset, limit } = @getState("events") || {}
            (offset / limit) + 1

         getTotalPages: ->
            Math.ceil(@getTotal() / @getState("events")?.limit)

         getIsLoading: ->
            @getState("is_loading")

         getCurrentFilters: ->
            @getState("current_filters")

         getTempFilters: ->
            @getState("temp_filters")

         getQuickSearch: ->
            @getState("quick_search")

         hasFilterChanged: ->
            not _.isEqual @getState("current_filters"), @getState("temp_filters")

         getEventCount: ->
            @getState("events")?.total || 0

         getSortParams: ->
            @getState("sort")
