import { useContext } from "react";
import numeral from "numeral";
import { ActionsMenu, Modal } from "@evertrue/et-components";
import ExportSource from "apps/export/sources/export-source";
import ExportSearchController from "apps/export/controllers/export-search-controller";
import { GlobalContactSearchContext } from "apps/layout/controllers/global-contact-search-context";
import BookMarkSearchModal from "apps/search/components/bookmark-search-modal";
import { BOOKMARK_TYPES } from "apps/search/utils/search-helper";
import useResultCount from "apps/query-builder/hooks/use-result-count";
import colors from "@evertrue/et-variables/src/styles/variables.scss";
import DynamicListsModal from "apps/search/components/dynamic-lists-modal";
import SelectedContactsStore from "apps/contact/stores/selected-contacts-store";
import { connect } from "@evertrue/et-flux";
import { PropTypes } from "prop-types";
import AddToModal from "./add-to-modal";

const ID = "CONTACT_ADD_TO";

const mapStateToProps = () => ({
  selected: SelectedContactsStore.getSelectedContacts(),
});

const FiltersActions = ({ selected }) => {
  const { contactFilters, filterCount, quickFiltersCount, dslQuery } = useContext(GlobalContactSearchContext);
  const [resultCount] = useResultCount(dslQuery);
  const formattedCount = numeral(resultCount).format("0,0");
  const isFiltersEmpty = filterCount + quickFiltersCount === 0;

  const handleExport = (params) => {
    ExportSource.standardExportByType("contact", dslQuery, params);
  };

  const exportAction = {
    id: 1,
    icon: "cloud-download",
    iconStyle: { color: colors.blue300 },
    label: "Export Records",
    description: `Create a csv file with ${formattedCount} records`,
    // eslint-disable-next-line react/prop-types
    mountOnClick: ({ is_open, close }) => (
      <Modal isOpen={is_open} closeModal={close}>
        <ExportSearchController onExport={handleExport} onClose={close} />
      </Modal>
    ),
  };

  const bookmarkAction = {
    id: 2,
    icon: "star",
    iconStyle: { color: colors.yellow150 },
    label: "Bookmark Search",
    description: "Creation of new bookmarked searches is suspended",
    disabled: true,
    // eslint-disable-next-line react/prop-types
    mountOnClick: ({ is_open, close }) => (
      <BookMarkSearchModal isOpen={is_open} onClose={close} type={BOOKMARK_TYPES.NEW} />
    ),
  };

  const listAction = {
    id: 3,
    icon: "segment",
    iconStyle: { color: colors.green200 },
    label: "Create Dynamic List",
    description: "Creation of new dynamic lists is suspended",
    disabled: true,
    // eslint-disable-next-line react/prop-types
    mountOnClick: ({ is_open, close }) => (
      <DynamicListsModal isOpen={is_open} onClose={close} filters={contactFilters} />
    ),
  };

  const addAction = {
    id: 4,
    icon: "list",
    iconStyle: { color: colors.green300 },
    description: `Add ${
      selected.length ? selected.length : resultCount.toLocaleString()
    } constituents to a List, Trip, or Pool`,
    label: "Add to List, Trip, or Pool",
    // eslint-disable-next-line react/prop-types
    mountOnClick: ({ is_open, close }) => {
      return <AddToModal isOpen={is_open} close={close} selected={selected} ID={ID} resultCount={resultCount} />;
    },
  };

  const showActions = () => {
    const actionsOnAll = [
      {
        id: 1,
        items: [bookmarkAction, listAction],
      },
      {
        id: 2,
        heading: `Actions on All ${resultCount.toLocaleString()} Constituents`,
        items: [exportAction, addAction],
      },
    ];
    const actionsOnAllNoFilters = [
      {
        id: 1,
        heading: `Actions on All ${resultCount.toLocaleString()} Constituents`,
        items: [exportAction, addAction],
      },
    ];

    if (!isFiltersEmpty && selected.length) return [exportAction, bookmarkAction, listAction, addAction];
    if (!isFiltersEmpty && !selected.length) return actionsOnAll;
    if (isFiltersEmpty && selected.length) return [exportAction, addAction];
    return actionsOnAllNoFilters;
  };

  return <ActionsMenu options={showActions()} />;
};

FiltersActions.propTypes = {
  selected: PropTypes.array,
};

export default connect(FiltersActions, [SelectedContactsStore], mapStateToProps);
