module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   moment = require("moment")
   Decorator = require("clientDecorator")
   {createComponent} = require("@evertrue/et-flux")
   EverTrue = require("app")
   MeetingsSource = require("apps/my-trips/sources/meetings-source")
   AllUsersStore = require("apps/users/stores/all-users-store").default
   ContactTagList = require("apps/contact/components/contact-tag-list")
   {createFactory} = require("base/new-utils")
   CalendarPickerDropdown = createFactory require("components/controls/calendars/calendar-picker-dropdown")
   AdvancedCombobox = createFactory(require("@evertrue/et-components").AdvancedCombobox)
   LocationAutocomplete = createFactory require("components/controls/location-autocomplete")
   FormField =  createFactory(require("components/forms/form-field"))
   TimeRange = createFactory(require("components/forms/time-range").default)
   CalendarContainer = createFactory(require("apps/my-trips/components/cronofy-calendar-container").default)
   LinkButton = createFactory(require("@evertrue/et-components").LinkButton)
   Loading = require("components/elements/loading")
   {div, input, textarea} = ReactLibs.DOMFactories
   Modal = createFactory require("components/modals/modal")
   ModalConfirm = createFactory(require("@evertrue/et-components").ModalConfirm)

   createComponent "LocalMeetingForm",
      propTypes:
         tripId: ReactLibs.PropTypes.number
         meeting: ReactLibs.PropTypes.object
         selected_contacts: ReactLibs.PropTypes.any
         solicitors: ReactLibs.PropTypes.array
         headerVerb: ReactLibs.PropTypes.string
         title: ReactLibs.PropTypes.string

      getInitialState: ->
         meeting: {}
         utc_offset: undefined

      UNSAFE_componentWillMount: ->
         @setMeeting(@props.meeting)

      componentDidMount: ->
         @titleInput.focus()

      registerStores: ->
         @on AllUsersStore, ->
            users: _.map AllUsersStore.getAllGivingTreeUsers(), (user) ->
               {value: user.id, label: user.name}

      setMeeting: (meeting) ->
         meeting = _.cloneData(meeting || {})
         meeting.start_at ?= moment(@state.meeting.start_at).hour(9).minute(0).valueOf()
         meeting.end_at ?= moment(meeting.start_at).add("1", "hour").valueOf()
         meeting = Decorator.MyTrips.parseMeeting(meeting)

         contacts = _.map @props.selected_contacts, (contact) ->
            Decorator.Contacts.parse(contact)

         @setState meeting: _.extend meeting,
            name: meeting.name || @props.title
            date: meeting.start_datetime.date_string
            description: meeting.description
            contacts: contacts
            custom_location: meeting.custom_location
            location: meeting.location
            solicitor_user_ids: meeting.solicitor_user_ids
            solicitors: @props.solicitors
            time_start: meeting.start_datetime.time_string
            time_end: meeting.end_datetime.time_string
            anchor: meeting.anchor

      handleSubmit: ->
         meeting = _.cloneData @state.meeting
         {date} = meeting

         start = moment("#{date} #{meeting.time_start}", "YYYY-MM-DD hh:mm A").format("HH:mm")
         end = moment("#{date} #{meeting.time_end}", "YYYY-MM-DD hh:mm A").format("HH:mm")

         data =
            type: "local"
            name: meeting.name
            description: meeting.description
            custom_location: meeting.custom_location
            location: meeting.location
            contact_ids: _.pluck(meeting.contacts, "id")
            solicitor_user_ids: _.pluck(meeting.solicitors, "id")
            start_time: start
            end_time: end
            start_date: date
            end_date: date

         data.id = meeting.id if meeting.id
         MeetingsSource.saveMeeting(data)
         @props.remove()

      handleChange: (prop, value) ->
         meeting = _.cloneData(@state.meeting || {})
         meeting[prop] = value
         @setState {meeting: meeting}

      handleTimeChange: (value) ->
         meeting = _.cloneData(@state.meeting || {})
         _.extend meeting,
            time_start: value.gte
            time_end: value.lte
         @setState {meeting: meeting}

      handleConnectedCalClick: ->
         EverTrue.Navigator("/trips/calendar", true)
         @props.remove()

      renderHelperText: ->
         CalendarContainer
            render: ({hasCalendars, isLoading}) =>
               if isLoading then Loading()
               else if hasCalendars
                  div null,
                     "Changes made to this meeting will be updated in your"
                     LinkButton onClick: @handleConnectedCalClick, title: "connected calendars", " connected calendars"
               else null

      render: ->
         meeting = @state.meeting || {}
         trip = @state.trip?
         valid_time = Decorator.MyTrips.hasValidTimeRange(meeting.time_start, meeting.time_end)

         Modal
            width: 575
            keepOpen: true
            remove: @props.remove
            onUnmount: @props.onUnmount

            ModalConfirm
               className: "meeting-form"
               header: "Local Meeting"
               disableSave: !meeting.name || !valid_time
               hasMargin: true
               onSubmit: @handleSubmit
               onCancel: @props.remove

               FormField label: "Title*",
                     input
                        ref: ((e) => @titleInput = e)
                        type: "text"
                        placeholder: "Enter meeting title..."
                        value: meeting.name
                        onChange: (e) =>
                           @handleChange("name", e.currentTarget.value)

               FormField label: "Time*", helpKey: "my_meeting_time",
                     CalendarPickerDropdown
                        className: "meeting-form--calendar"
                        value: meeting.date || Decorator.MyTrips.getDate()
                        minDate: if trip then Decorator.MyTrips.getDate(trip.start_at)
                        hideClear: true
                        onChange: (date) =>
                           @handleChange("date", date)

                     TimeRange
                        className: if !valid_time then "meeting-form--invalid-time"
                        autoRange: true
                        value: {gte: meeting.time_start, lte: meeting.time_end}
                        onChange: @handleTimeChange

               FormField label: "Custom Location",
                     input
                        type: "text"
                        placeholder: "ie: Meeting Room 1, Office 2"
                        value: meeting.custom_location
                        onChange: (e) =>
                           @handleChange("custom_location", e.currentTarget.value)

               FormField label: "Location",
                     LocationAutocomplete
                        type: []
                        value: meeting.location
                        onClear: =>
                           @handleChange("location", null)
                        onSelect: (lat_lng, place) =>
                           location = if place.place_id then {place_id: place.place_id} else lat_lng
                           @setState {utc_offset: place.utc_offset, valid_location: true}
                           @handleChange("location", location)

               FormField label: "Constituent(s)",
                     ContactTagList
                        focusOnMount: false
                        presetResults: _.map meeting.contacts, (contact) ->
                           Decorator.Contacts.getFullName(contact)
                        selected: _.map meeting.contacts, (contact) ->
                           _.extend {name: Decorator.Contacts.getFullName(contact)}, contact
                        onSelect: (contacts) =>
                           @handleChange("contacts", contacts)

               FormField label: "Additional Solicitors",
                     AdvancedCombobox
                        placeholder: "Select Users..."
                        multiple: true
                        searchable: true
                        value: _.map meeting.solicitors, (solicitor) ->
                           _.extend {}, solicitor,
                              value: solicitor.id
                              label: solicitor.name
                        options: _.sortBy @state.users, "label"
                        onChange: (users) =>
                           solicitors = _.map users, (user) ->
                              _.extend {id: user.value, name: user.label}, user
                           @handleChange("solicitors", solicitors)

               FormField label: "Description",
                     textarea
                        value: meeting.description || ""
                        placeholder: "Describe your meeting here (Optional)..."
                        onChange: (e) =>
                           @handleChange("description", e.currentTarget.value)

               div className: "modal-confirm--helper-text",
                  @renderHelperText()
