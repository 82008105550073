import _ from "underscore";
import { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "@evertrue/et-flux";
import ActionMenu from "components/controls/action-menu";
import TripContactActions from "apps/my-trips/components/trip-contact-actions";
import TripContactsStore from "apps/my-trips/stores/trip-contacts-store";
import MeetingsStore from "apps/my-trips/stores/meetings-store";
import SortDropdown from "components/controls/sort-dropdown";
import { DNDList } from "components/controls/dnd/dnd-controller";
import TripContactCard from "apps/my-trips/components/trip-contact-card";

const mapStateToProps = ({ trip = {} }) => ({
  contacts: TripContactsStore.getContactsForTrip(trip.id),
  meetings_for_contacts: MeetingsStore.getMeetingsForContacts(trip.id),
});

class TripContactsController extends Component {
  static propTypes = {
    trip: PropTypes.object,
    // from stores
    contacts: PropTypes.array,
    meetings_for_contacts: PropTypes.object,
  };

  static defaultProps = {
    trip: {},
    contacts: [],
    meetings_for_contacts: {},
  };

  state = {
    sort_prop: "name_first",
    sort_reverse: false,
    is_dragging: false,
  };

  handleSort = (prop, reverse) => {
    if (prop !== this.state.sort_prop) {
      reverse = false;
    }
    this.setState({ sort_prop: prop, sort_reverse: reverse });
  };

  renderTripContactActions = () => {
    return (
      <div className="itinerary--module-action">
        <ActionMenu
          label="Add Constituents..."
          buttonType="secondary"
          disabled={!(this.props.trip ? this.props.trip.id : undefined)}
        >
          <TripContactActions trip={this.props.trip} />
        </ActionMenu>
      </div>
    );
  };

  renderEmptyConstituentState = () => {
    const { trip } = this.props;
    if (trip.is_my_trip) {
      return (
        <div className="itinerary--list-empty">
          Add constituents from a list or directly from the constituent profile.
          {this.renderTripContactActions()}
        </div>
      );
    } else {
      return <div className="itinerary--list-empty">No constituents have been added to this trip.</div>;
    }
  };

  render() {
    const sorted_contacts = _.sortBy(this.props.contacts, (contact = {}) => {
      const contact_properties = contact.properties || {};
      const contact_giving = contact.giving || {};

      switch (this.state.sort_prop) {
        case "name_first":
          return contact_properties.name_first ? contact_properties.name_first.value : null;
        case "name_last":
          return contact.properties.name_last ? contact.properties.name_last.value : null;
        case "lifetime_giving":
          return contact_giving.lifetime_amount ? contact_giving.lifetime_amount.value : 0;
        default:
          return contact_properties.name_first ? contact_properties.name_first.value : null;
      }
    });

    if (this.state.sort_reverse) {
      sorted_contacts.reverse();
    }

    const draggable_items = _.map(sorted_contacts, (contact = {}, i) => {
      const meetings = this.props.meetings_for_contacts[contact.id];
      return {
        key: JSON.stringify(contact.id), // this key is what is received when the item is dropped
        component: (
          <TripContactCard
            trip={this.props.trip}
            contact={contact}
            meetings={meetings}
            position={i < _.size(sorted_contacts) - 1 ? "bottom" : "top"}
          />
        ),
      };
    });

    return (
      <Fragment>
        {!_.isEmpty(this.props.contacts) && (
          <div className="trip-contacts--sort-header">
            <SortDropdown
              options={[
                { label: "First Name", value: "name_first" },
                { label: "Last Name", value: "name_last" },
                { label: "Lifetime Giving", value: "lifetime_giving" },
              ]}
              sortProp={this.state.sort_prop}
              sortReverse={this.state.sort_reverse}
              onSort={this.handleSort}
            />
          </div>
        )}

        <div className="trip-contacts--body">
          <DNDList
            id={"contact_list"}
            list={{
              disabled: !this.props.trip.is_my_trip,
              items: draggable_items,
              notSortable: true,
              grouped: false,
              emptyStateComponent: this.renderEmptyConstituentState(),
            }}
          />
        </div>
      </Fragment>
    );
  }
}

export default connect(TripContactsController, [TripContactsStore, MeetingsStore], mapStateToProps);
