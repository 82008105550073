import { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";

const ContactModalContext = createContext(null);

const ContactModalProvider = ({ children }) => {
  const [contactModalIsOpen, setContactModalIsOpen] = useState(false);

  return (
    <ContactModalContext.Provider value={{ contactModalIsOpen, setContactModalIsOpen }}>
      {children}
    </ContactModalContext.Provider>
  );
};

ContactModalProvider.propTypes = {
  children: PropTypes.node,
};

export { ContactModalProvider, ContactModalContext };
export const useContactModalContext = () => useContext(ContactModalContext);