const Referrers = {
  person: "person profile",
  industry: "industry profile",
  company: "company profile",
  people_in_common: "people in common",
  similar_people: "similar contacts",
  similar_to_list: "similar to list",
  company_people: "matched employees",
  industry_people: "matched employees",
  global_search: "global search results",
  saved_search: "saved search",
  suggested_search: "suggested search",
  featured_segment: "suggested search",
  recently_viewed: "recently viewed",
  contact: "directory",
  facebook_post: "matched likers",
  facebook_interactions: "facebook interactions",
  facebook_posts: "facebook posts",
  post_details: "facebook post details",
  inbox: "inbox",
  typeahead: "autocomplete",
  search_update: "activity - search query",
  list: "list",
  map: "map",
  quick_view: "quick view",
  recent_exports: "recent exports",
  export_toast: "toast",
  default: "direct",
  suggested_matches: "suggestion", // referrer is the suggested matches tab
  manual_matches: "profile lookup", // referrer is the manual matches tab
  manual_matching: "manual matching", // referrer a manual matching search
  list_activity: "list activity",
  list_popup: "lists popup",
  list_recent: "recent lists",
  bulk_create: "bulk create",
  bulk_add: "bulk add",
  opt_in_modal: "modal",
  opt_in_menu: "user menu",
  opt_in_list: "list",
  shared_search: "shared search",
  copy_search: "copy link",
  email_search: "email link",
  portfolio_list: "portfolio list",
  portfolio_map: "portfolio map",
  portfolio_performance: "portfolio performance",
  portfolio_performance_map: "portfolio performance - map",
  // PROFILE
  profile_section_summary: "summary",
  profile_section_bio: "bio",
  profile_section_giving: "giving - all",
  profile_section_posts: "engagement - all",
  profile_section_notes: "notes - all",
  profile_section_proposals: "proposals - all",
  profile_section_bio_all: "bio - detail",
  profile_interactions_all: "profile - interactions all",
  profile_interaction_summary: "profile - interaction summary",
  profile_action_menu: "profile - actions menu",
  profile_contacts_modal: "profile - contacts modal",
  profile_social: "profile - social module",
  interaction_results: "interaction results",
  interaction_detail: "interaction detail",
  my_trips: "my trips",
  trip: "trip",
  trip_contacts_name: "constituents by name",
  trip_contacts_list: "constituents by list",
  trip_contacts_portfolio: "constituents by portfolio",
  trip_itinerary: "trip - itinerary",
  trip_map: "trip - map",
  meeting_details: "meeting details",
  proposal_details: "proposal details",
  view_all_proposals: "view all proposals",
  export_generated: "Export Generated",
  export_downloaded: "Export Downloaded",
};

export default Referrers;
