module.exports = do ->
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories
   MyPortfolioProspects = require("apps/major-gifts/components/my-portfolio-prospects")

   createComponent "MyPorfolioController",
      propTypes:
         type: ReactLibs.PropTypes.string

      render: ->
         div null,
            if @props.type is "prospects"
               type = @props.subtype || "stage"
               MyPortfolioProspects(type: type)
