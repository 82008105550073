import { useState, useEffect, useContext } from "react";
import _ from "underscore";
import { Loading, FlexTable } from "@evertrue/et-components";
import { FiltersDataContext } from "apps/query-builder/contexts/filters-data-context";

const tableConfig = () => [
  {
    header: "Filter",
    accessor: (i) => i.label,
  },
  {
    header: "Category",
    accessor: (i) => i.category,
  },
  {
    header: "Operator Set Options",
    accessor: (i) => (
      <div>
        {i.operators.map((operator) => (
          <span key={operator.label} className="filter-inventory-operators">
            {operator.label}
          </span>
        ))}
      </div>
    ),
  },
];

const FiltersInventoryList = () => {
  const { filters, loading } = useContext(FiltersDataContext);
  const [categorizedFilters, setCategorizedFilters] = useState([]);

  useEffect(() => {
    const groupedFilters = _.groupBy(filters, "group");
    setCategorizedFilters(groupedFilters);
  }, [filters]);

  return (
    <div className="filters-inventory">
      {loading ? (
        <Loading />
      ) : (
        Object.keys(categorizedFilters).map((group) => {
          return (
            <div key={group} className="filters-inventory-group" id={group.toLowerCase().replace(/ /g, "")}>
              <h2>{group}</h2>
              <FlexTable caption="list of available filters" data={categorizedFilters[group]} columns={tableConfig()} />
            </div>
          );
        })
      )}
    </div>
  );
};

export default FiltersInventoryList;
