// extract for without reply.
export function extractLinks(text) {
  if (!text) {
    return "";
  }
  // Regular Expression for hyperlinks
  const regexForLinks = /\bhttps?:\/\/\S+/gi;
  let matchLinks;
  const plainLinks = [];

  while ((matchLinks = regexForLinks.exec(text)) !== null) {
    const websiteUrl = matchLinks[0];
    plainLinks.push(websiteUrl);
  }

  // Regular Expression for email addresses
  const regexForEmail = /\b[\w.-]+@[\w.-]+\.[A-Za-z]{2,}\b/g;
  let matchEmails;
  const emailLinks = [];

  while ((matchEmails = regexForEmail.exec(text)) !== null) {
    const email = matchEmails[0];
    emailLinks.push(email);
  }
  // Regular Expression for thankview video links
  const regexForTV = /\bhttps?:\/\/\S+\/(?:[\w-]+\.(?:jpg|jpeg|gif|png|mp4))\b/;
  const thankviewLinks = text.match(regexForTV);

  let linkResult = {};
  if (thankviewLinks) {
    linkResult.thankviewLink = thankviewLinks[0];
  } else {
    linkResult.thankviewLink = "No links ending in .mp4 or jpg were found.";
  }
  linkResult.plainLinks = plainLinks;
  linkResult.emailLinks = emailLinks;
  return linkResult;
}

// extract for with reply.
export function extractInteractionData(interaction) {
  if (!interaction || !interaction.text) {
    return "";
  }

  let message = "";
  let dataAfterDelimiter = "";
  const delimiter = "[THANKVIEW-METADATA]";
  const parts = interaction.text.split("\r\n\r\n\r\n\r\n");
  if (interaction && interaction.text.includes("[THANKVIEW-METADATA]")) {
    message = interaction && interaction.text.split(delimiter)[0].trim();
    dataAfterDelimiter = interaction && interaction.text.split(delimiter)[1].trim();
  } else {
    message = parts[0] ? parts[0] : "";
    dataAfterDelimiter = parts[1] ? parts[1] : "";
  }

  const extractValue = (text, regex) => {
    try {
      const match = text.match(regex);
      return match ? match[1] : "";
    } catch (err) {
      return "";
    }
  };

  const extractVideoValue = (text, regex) => {
    try {
      const match = text.match(regex);
      return match ? match[0] : "";
    } catch (err) {
      return "";
    }
  };

  const extractDate = (text, regex) => {
    try {
      const match = text.match(regex);
      return match ? new Date(match[1]).toLocaleDateString("en-US", { timeZone: "UTC" }) : "";
    } catch (err) {
      return "";
    }
  };

  const text = dataAfterDelimiter;
  let videoURL = extractVideoValue(text, /https?:\/\/[^\s"]+\.(?:jpg|jpeg|gif|png|mp4)/g);
  let replyReceived = extractValue(text, /ReplyReceived: (.+)/);
  let replyTo = extractValue(text, /ReplyTo: (.+)/);
  let sentDate = extractDate(text, /SentDate: (.+)/);
  let watchedDate = extractDate(text, /WatchedDate: (.+)/);
  let replyFrom = extractValue(text, /ReplyFrom: (.+)/);
  let replyType = extractValue(text, /ReplyType: (.+)/);
  let replyMessage = extractValue(text, /ReplyMessage:\s*([\s\S]*?)\s*WatchedDate:/);
  let ctaClickedDate = extractDate(text, /CtaClickedDate: (.+)/);
  let replyMessageVideo = extractVideoValue(replyMessage, /https?:\/\/[^\s"]+\.(?:jpg|jpeg|gif|png|mp4)/g);
  const result = {
    videoURL,
    replyReceived,
    replyTo,
    replyFrom,
    replyType,
    replyMessage,
    watchedDate,
    sentDate,
    ctaClickedDate,
    replyMessageVideo,
  };
  return {
    message,
    ...result,
    dateCreated: new Date(interaction.created_at),
    dateWatched: new Date(interaction.date_occurred),
  };
}

export function safeAccess(obj, path) {
  const keys = path.split(".");
  let value = obj;
  for (const key of keys) {
    if (!value || typeof value !== "object") {
      return undefined;
    }
    value = value[key];
  }
  return value;
}

export function shortenText(text, maxLength) {
  if (text.length > maxLength) {
    return text.substring(0, maxLength - 3) + "...";
  }
  return text;
}

export function pluralize(number, word) {
  if (number === 1) {
    return `Viewed ${number} ${word}`;
  } else {
    return `Viewed ${number} ${word}s`;
  }
}