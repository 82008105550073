React = require("react")
_ = require("underscore").default
{createComponent} = require("@evertrue/et-flux")
{div} = ReactLibs.DOMFactories
Api = require("entities/helpers/api")
{createFactory} = require("base/new-utils")
Modal = createFactory require("components/modals/modal")
GiftsFeed = createFactory require("apps/profile/components/activity/gifts-feed").default
GiftDetailsModal = require("apps/profile/components/gift-details-modal")
classNames = require("classnames")

getQuery = (contact_id) ->
   must: [{contact_id: {match: contact_id}}]
   sort: [occurred_at: {order: "desc"}]

module.exports =
   createComponent "ProfileGiftsFeedController",
      propTypes:
         contact: ReactLibs.PropTypes.object
         isInModule: ReactLibs.PropTypes.bool
         limit: ReactLibs.PropTypes.number

      getDefaultProps: ->
         contact: {}
         isInModule: false
         limit: 10

      getInitialState: ->
         loading: false
         items: []
         offset: 0
         total: 0
         has_error: false

      componentDidMount: ->
         if @props.contact.id then @fetchFirstGifts()

      componentDidUpdate: (prevProps, prevState) ->
         if newId = @props.contact.id
            if newId isnt prevProps.contact.id
               @fetchFirstGifts()

      componentWillUnmount: ->
         @xhr?.abort?()

      fetchFirstGifts: ->
         @xhr?.abort?()
         contact_id = @props.contact.id
         { limit } = @props
         @setState items: [], offset: 0, total: 0, loading: true, has_error: false
         @xhr = Api.SEARCH.GIFTS.post
            params: {limit, offset: 0}
            data: JSON.stringify(getQuery(contact_id))
            success: ({items, offset, total}) =>
               @setState {total, items, offset, loading: false}
            error: (xhr, status, error) =>
               @setState loading: false, has_error: true

      fetchMoreGifts: ->
         { limit } = @props
         contact_id = @props.contact.id
         new_offset = @state.offset + limit

         @setState loading: true

         @xhr = Api.SEARCH.GIFTS.post
            params: {limit, offset: new_offset}
            data: JSON.stringify(getQuery(contact_id))
            success: ({items, offset, total}) =>
               combined_items = _.uniq(@state.items.concat(items), "id")
               @setState {items: combined_items, offset, total, loading: false}
            error: (xhr, status, error) =>
               @setState loading: false, has_error: true

      renderModal:(gift) ->
         Modal width: 540, render: ({onHide}) ->
            GiftDetailsModal({onHide, gift})

      render: ->
         {total, loading, items} = @state
         { isInModule, showViewAll, contact } = @props

         div className: classNames("profile-gifts-controller",
                        "profile-gifts-controller--in-module": isInModule),
            GiftsFeed
               contact: contact
               items: items
               total: total
               loading: loading
               showViewAll: showViewAll
               renderAmountModal: @renderModal
               onLoadMore:  =>
                  @fetchMoreGifts()
