module.exports = do ->
   _ = require("underscore").default
   {createStore} = require("@evertrue/et-flux")
   ProfileFacebookSource = require("apps/profile/sources/profile-facebook-source")
   FacebookPagesStore = require("apps/facebook/stores/facebook-pages-store")
   FacebookSource = require("apps/facebook/sources/facebook-source")
   Query = require("entities/search/query-parts/query")
   Parameter = require("entities/search/query-parts/parameter-query")
   Property = require("entities/search/query-parts/property-query")
   UrlSource = require("apps/layout/sources/url-source")
   EngagementTypes = require("apps/profile/components/engagement-type-utils")

   URL_KEY = "fbfilters"
   SEARCH_CONFIG =
      base: (id, sort_order="desc") ->
         Query [
            Parameter "must", [
               Property "contact_id", id, {type: "equals"}
               Property "network", "facebook", {type: "equals"}
            ]
            Parameter "sort", [
               Property "engagement.created_at", {order: sort_order}, {type: "object"}
            ]
         ]

      type: (val) ->
         Query [
            Parameter "must", [
               Property "engagement.type", val, {type: "equals"}
            ]
         ]

      keyword: (val) ->
         word_array = _.filter val?.split(" "), (word) -> !!word
         properties = _.map word_array, (value) ->
            Property "content.contents", value, {type: "equals"}

         Query [
            Parameter "must", properties
         ]

      date: (val) ->
         date_object = {gte: val.date_from, lte: val.date_to}
         Query [
            Parameter "must", [
               Property "engagement.created_at", date_object, {type: "object"},
            ]
         ]

      pages: (options) ->
         ids = _.map options, (option) -> option.value
         Query [
            Parameter "must", [
               Property "content.page_remote_id", ids, {type: "contains"},
            ]
         ]

      reactions: (options) ->
         reactions = _.map options, (option) -> option.value
         Query [
            Parameter "must", [
               Property "engagement.type", reactions, {type: "contains"},
            ]
         ]

   getUrlFor = (key, value) ->
      object = _.toObject(URL_KEY, _.toObject(key, value))
      "/posts?#{$.param(object)}"

   createStore "ProfileFacebookStore",
      getInitialState: ->
         contact_engagements: {}
         contact_reactions: {}
         pending_post_ids: []
         posts: {}
         filters: {}
         limit: 15
         sort_order: "desc"
         loading: false
         loading_more: false

      registerActions: ->
         @on ProfileFacebookSource.actions.fetching, @searchWithData
         @on ProfileFacebookSource.actions.fetchedEngagements, @respondToFetch
         @on ProfileFacebookSource.actions.fetchedPost, @respondToFetchPost
         @on ProfileFacebookSource.actions.fetchedReactionStats, @respondToFetchedReactions
         @on ProfileFacebookSource.actions.loading, @respondToLoading
         @on ProfileFacebookSource.actions.applyFilters, @respondToFilter
         @on ProfileFacebookSource.actions.loadMore, @respondToLoadMore
         @on ProfileFacebookSource.actions.changeSort, @respondToSort

         @on FacebookSource.actions.fetchedOauth, @respondToOauth

         @on UrlSource.actions.initializedUrl, @respondToUrl
         @on UrlSource.actions.fetched, @respondToUrl
         @on UrlSource.actions.changed, @respondToUrl

      respondToUrl: (url) ->
         if url[URL_KEY] then @setState {filters: url[URL_KEY]}

      respondToFetch: (contact_id, data) ->
         contact_engagements = _.extend({}, @getState("contact_engagements")) || {}
         if _.size(contact_engagements) > 100 then contact_engagements = {}

         if @getState("loading_more")
            aggregate_items = contact_engagements[contact_id]?.items || []
            _.extend contact_engagements[contact_id], data,
               items: aggregate_items.concat(data.items)
         else
            contact_engagements[contact_id] = data

         post_ids = _.uniq _.compact _.map data.items, (engagement) ->
            engagement.content.remote_id

         new_posts = _.difference(post_ids, _.keys(@getState("posts")))
         if !_.isEmpty(new_posts)
            if oauth = FacebookPagesStore.getOauth()
               ProfileFacebookSource.fetchPosts(new_posts, oauth)
            else pending_post_ids = new_posts

         @setState
            contact_engagements: contact_engagements
            pending_post_ids: pending_post_ids
            loading_more: false

      respondToFetchPost: (post_data) ->
         posts = _.clone(@getState("posts")) || {}
         if _.size(posts) > 100 then posts = {}
         posts[post_data.id] = post_data
         @setState {posts: posts}

      respondToFetchedReactions: (id, stats) ->
         contact_reactions = @getState("contact_reactions")
         contact_reactions[id] = {}
         _.each stats, (stat) ->
            contact_reactions[id][stat.value] = stat.count
         @setState {contact_reactions: contact_reactions}

      respondToLoading: (is_loading) ->
         @setState {loading: is_loading}

      respondToFilter: (contact_id, filter_object) ->
         @setState {filters: filter_object}
         @searchWithData(contact_id)
         UrlSource.change(_.toObject(URL_KEY, filter_object))

      respondToLoadMore: (contact_id) ->
         @setState {loading_more: true}
         contact_engagements = @getState("contact_engagements")[contact_id]
         @searchWithData(contact_id, contact_engagements.offset + @getState("limit"))

      respondToSort: (contact_id, sort_order) ->
         @setState {sort_order: sort_order}
         @searchWithData(contact_id)

      respondToOauth: (data) ->
         pending_ids = @getState("pending_post_ids")
         if !_.isEmpty(pending_ids)
            ProfileFacebookSource.fetchPosts(pending_ids, FacebookPagesStore.getOauth())
            @setState {pending_post_ids: []}

      searchWithData: (id, next_offset) ->
         filter_object = @getState("filters")
         query = SEARCH_CONFIG.base(id, @getState("sort_order"))
         _.each filter_object, (filter_value, key) ->
            query.merge(SEARCH_CONFIG[key]?(filter_value))

         ProfileFacebookSource.search id,
            data: _.jsonStringify(query.toJSON?())
            params:
               offset: next_offset || 0
               limit: @getState("limit")

      api:
         getEngagements: (contact_id) ->
            @getState("contact_engagements")?[contact_id]?.items

         getEngagementTotal: (contact_id) ->
            @getState("contact_engagements")?[contact_id]?.total

         getReactionsForContact: (contact_id) ->
            @getState("contact_reactions")[contact_id]

         getLoading: ->
            @getState("loading")

         getPost: (post_id) ->
            @getState("posts")?[post_id]

         getAppliedFilters: ->
            $.extend true, {}, @getState("filters")

         getSortOrder: ->
            @getState("sort_order")

         getEventsUrl: ->
            getUrlFor("reactions", EngagementTypes.getEventActionValues())

         getReactionsUrl: ->
            getUrlFor("reactions", EngagementTypes.getReactionValues())

         hasMoreToLoad: (contact_id) ->
            engagements = @getState("contact_engagements")?[contact_id]
            if !engagements then false
            else engagements.total > (engagements.offset + engagements.limit)
