module.exports = do ->

   ParentDoc = (type, ParentQuery) ->
      query_object = {type: type, query: {}}
      _query = ParentQuery

      {
         toJSON: ->
            query_object.query = _query.toJSON()
            {has_parent: [query_object]}

         query: ->
            _query

         propertyKeys: ->
            _query.propertyKeys()

         properties: ->
            _query.properties()

         merge: (InnerQuery) ->
            _query.merge(InnerQuery.query())

         replace: (InnerQuery) ->
            _query = InnerQuery

         type: -> type

         isParentDocument: -> true

         isNestedDocument: -> true
      }

   return ParentDoc

