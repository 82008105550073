import { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Card } from "@evertrue/et-components";

class ProfileCard extends Component {
  static propTypes = {
    size: PropTypes.number,
    children: PropTypes.any,
    className: PropTypes.string,
  };

  calculateWidth() {
    switch (this.props.size) {
      case 1:
        return "calc(33.3%)";
      case 2:
        return "calc(50%)";
      case 3:
        return "calc(66.7%)";
      case 4:
        return "calc(100%)";
      default:
        return "calc(100%)";
    }
  }

  render() {
    const width = this.calculateWidth();
    return (
      <div className="profile-card--wrapper" style={{ width }}>
        <Card className={classNames("profile-card", this.props.className)}>{this.props.children}</Card>
      </div>
    );
  }
}

export default ProfileCard;
