import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Api from "entities/helpers/api";
import EverTrue from "app";
import { TextInputForm } from "@evertrue/et-components";
import { SEARCH_TYPES, BOOKMARK_PERMISSIONS } from "apps/search/utils/search-helper";

const BookmarkName = ({ bookmarkId, bookmarkName, permission, type, owner, disabled = false }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [name, setName] = useState(bookmarkName);
  const heading =
    type === SEARCH_TYPES.SUGGESTED
      ? "Suggested by Evertrue"
      : permission === BOOKMARK_PERMISSIONS.OWNER
      ? "Bookmarked search (Owned by you)"
      : `Bookmarked search (Owned by ${owner})`;

  useEffect(() => {
    setName(bookmarkName);
  }, [bookmarkName]);

  const handleNameChange = async (newName) => {
    try {
      setIsSubmitting(true);
      await Api.HUB.SEARCHES.patch({
        urlArgs: { id: bookmarkId },
        data: JSON.stringify({
          name: newName,
        }),
      });

      setName(newName);
      setIsSubmitting(false);
    } catch {
      EverTrue.Alert.error("Problem Editing your search, please try again later");
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div className="bookmarked-search--name-heading">{heading}</div>
      {permission === BOOKMARK_PERMISSIONS.VIEW ? (
        <div>{name}</div>
      ) : (
        <TextInputForm
          label="Bookmark Name"
          value={name}
          onSubmit={(val) => handleNameChange(val)}
          required={true}
          isSubmitting={isSubmitting}
          style={{ width: "400px" }}
          maxLength="100"
          disableEdit={disabled}
        />
      )}
    </>
  );
};

BookmarkName.propTypes = {
  bookmarkId: PropTypes.string.isRequired,
  bookmarkName: PropTypes.string.isRequired,
  permission: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  owner: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default BookmarkName;
