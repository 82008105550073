module.exports = do ->
   React = require("react")
   {div, span} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   Modal = createFactory require("components/modals/modal")
   ModalConfirm = createFactory require("components/modals/modal-confirm")
   {createComponent} = require("@evertrue/et-flux")
   FormField = createFactory require("components/forms/form-field")
   ScheduledExportsSource = require("apps/settings/export-settings/sources/scheduled-exports-source")
   TimeDropdown = require("components/forms/time-dropdown")
   TimezoneDropdown = require("components/forms/timezone-dropdown")
   TimezoneHelper = require("entities/helpers/timezone-helper")
   moment = require("moment")
   DNAStore = require("base/dna/dna-store").default
   ExportTypeConfig = require("apps/export/export-type-config").default
   Switch = createFactory(require("@evertrue/et-components").Switch)


   createComponent "ScheduleExportsModal",
      propTypes:
         exportType: ReactLibs.PropTypes.string
         item: ReactLibs.PropTypes.object

      componentWillUnmount: ->
         @setState {
            time: null,
            time_zone: null
         }

      getInitialState: ->
         today = moment().format("YYYY-MM-DD")
         default_timezone = DNAStore.getByKey("ET.Importer.TimeZone") || moment.tz.guess()
         time_zone = @props.item?.timezone || default_timezone
         time = @props.item?.scheduled || "12:00"

         return {
            time_zone: TimezoneHelper.findSetValue(time_zone),
            time: {
               label: @props.item?.scheduled || moment("#{today} 12:00").format("hh:mm a"),
               value: moment("#{today} #{time}", "YYYY-MM-DD HH:mm A").format(),
            },
            is_scheduled: !!@props.item
         }

      handleSubmit: ->
         {time:{value: time_value}, time_zone: {value: zone_value}} = @state
         if !@state.is_scheduled
            @handleCancel()
         else if !@props.item
            ScheduledExportsSource.scheduleExport(@props.exportType, time_value, zone_value)
         else
            ScheduledExportsSource.updateExport(@props.item.key, @props.exportType, time_value, zone_value)

      handleChange: (key, value) ->
         state = {}
         state[key] = value
         @setState(state)

      handleCancel: ->
         {item: {key}, exportType} = @props
         ScheduledExportsSource.cancelScheduledExport(key, exportType)

      isDisabled: ->
         sameTime = @props.item?.scheduled == @state.time.label && @props.item?.timezone == @state.time_zone.value
         sameToggle = !!@props.item == @state.is_scheduled

         if !@props.item
            if sameToggle then true
         else if sameToggle && sameTime
            true
         else false

      render: ->
         label = ExportTypeConfig[@props.exportType]?.label

         Modal
            width: 550
            keepOpen: true
            remove: @props.remove
            onUnmount: @props.onUnmount
            ModalConfirm
               buttonLabel: "Save and Close",
               header: "Export Schedule for #{label}",
               onSubmit: @handleSubmit
               disableButton: @isDisabled()
               div className: "schedule-exports-modal",
                  FormField
                     className: "schedule-exports-modal--form-field"
                     label: "Daily Job"
                     span className: "schedule-exports-modal--frequency",
                        Switch
                           className: "schedule-switch"
                           checked: @state.is_scheduled
                           onChange: => @setState({ is_scheduled: !@state.is_scheduled })

                        if @state.is_scheduled
                           span className: "schedule-switch-text",
                              "EverTrue will send a csv file to your organization daily at the specified time"

                  FormField label: "Time*",
                     div className: "schedule-exports-modal--dropdowns",
                        TimeDropdown
                           className: "schedule-exports-modal--time"
                           increment: 30
                           onChange: ((val) =>  @handleChange("time", val))
                           value: @state.time
                           disabled: !@state.is_scheduled
                        TimezoneDropdown
                           className: "schedule-exports-modal--timezone"
                           value: @state.time_zone
                           onChange: ((val) =>  @handleChange("time_zone", val))
                           disabled: !@state.is_scheduled
