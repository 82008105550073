module.exports = do ->
   _ = require("underscore").default
   classNames = require("classnames")
   {li, span} = ReactLibs.DOMFactories
   React = require("react")
   {createFactory} = require("base/new-utils")
   Link = createFactory(require("@evertrue/et-components").Link)
   LinkButton = createFactory(require("@evertrue/et-components").LinkButton)
   Icon = createFactory(require("@evertrue/et-components").Icon)

   NavItem = ReactLibs.createClass
      displayName: "NavItem"
      propTypes:
         activeWhen: ReactLibs.PropTypes.any
         href: ReactLibs.PropTypes.string
         bypass: ReactLibs.PropTypes.bool
         onSelect: ReactLibs.PropTypes.func # Passed from <Nav>
         active: ReactLibs.PropTypes.any # Passed from <Nav>

      handleClick: ->
         if _.isFunction @props.onSelect then @props.onSelect(@props.activeWhen)

      render: ->
         li
            className: "nav-item"
            style: @props.style

            if @props.href
               Link
                  title: @props.label
                  href: @props.href
                  className: classNames("nav-link-item", "is-active": @props.active == @props.activeWhen)
                  "data-refer": @props.refer
                  "data-bypass": @props.bypass,
                     span null,
                        if @props.icon
                           Icon
                              className: "nav-item-icon"
                              icon: @props.icon
                        @props.label || @props.children
            else
               LinkButton
                  title: @props.label
                  className: classNames("nav-link-item", "is-active": @props.active == @props.activeWhen)
                  "data-refer": @props.refer
                  "data-bypass": @props.bypass
                  onKeyPress: (event) =>
                        # enable tab navigation w/ keyboard for a11y
                     if event.key == "Enter"
                        @handleClick()
                  onClick: @handleClick,
                     span null,
                        if @props.icon
                           Icon
                              className: "nav-item-icon"
                              icon: @props.icon
                        @props.label
                        @props.children
