module.exports = do ->
  $ = require("jquery")
  _ = require("underscore").default
  EverTrue = require("app")
  Requester = require("clientRequester")

  # To use the LocalApi:
  # 1. ensure whatever backend service you want to test is running locally
  # 2. set the baseRoute to point to wherever your local service is running 
  # 3. add whatever route you want to test to the _endpoint_config 
  # 4. import LocalApi (in the same way you would import Api) and make your request to it 
  #     For example:
  #     import LocalApi from "entities/helpers/local-api"
  #     const response = await LocalApi.OUTREACH.VOIP_TOKEN.get()
  # 

  _endpoint_config =  # Add whatever API Routes you want to test locally:
    GRAPHQL:
        GRAPHQL: "graphql"
    OUTREACH:
        VOIP_TOKEN: "outreach/v1/voip/token"
        CREDENTIALS: "outreach/v1/email/credentials"
        PROVIDERS: "outreach/v1/email/providers"
        SEND: "outreach/v1/email/send"
        LINK: "outreach/v1/email/link"
    JOURNEYS:
        RECOMMENDED_ACTIONS: "journeys/v1/recommended-actions"
        TASK_ACTION_TYPES: "journeys/v1/tasks/action-types"
        TASKS: "journeys/v1/tasks"
    VOLUNTEER:
        SOLICITORS: "assignments-java/v2/solicitors"

  LocalApi = Requester.Manager _endpoint_config,
    debug: true
    baseRoute: "http://localhost:4000/" # change this to wherever your local app is running 
    appKey: EverTrue.config.app_keys?.givingtree

    onRequest: (options) ->
      $.ajax(options)
  
  LocalApi
