module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories
   {string, array, bool, oneOfType, number} = ReactLibs.PropTypes
   classNames = require("classnames")
   EngagementIcon = require("apps/profile/components/engagement-icon")

   createComponent "EngagementIconList",
      propTypes:
         className: string
         icons: array
         size: oneOfType [string, number]
         disabled: bool

      getDefaultProps: ->
         size: "24px"
         disbled: false

      render: ->
         {size, icons, className, disabled} = @props

         zIndex = _.size(icons)

         div className: classNames("engagement-icon-list clearfix", className),
            _.map icons, (icon, index) ->
               classes = classNames("engagement-icon-list--icon", "is-disabled": disabled)

               EngagementIcon
                  className: classes
                  zIndex: zIndex - index
                  key: icon
                  icon: icon
                  size: size

