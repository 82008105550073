/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Api from "entities/helpers/api";
import _ from "underscore";
import get from "lodash.get";
import PropTypes from "prop-types";
import Decorator from "clientDecorator";
import { LinkButton, ModalBody, Icon, useOpenClose } from "@evertrue/et-components";
import ActiveJourneysPills from "apps/journeys/components/profile/active-journeys-pills";
import ModalTrigger from "components/modals/modal-trigger";
import Modal from "components/modals/modal";
import ModalConfirm from "components/modals/modal-confirm";
import ModalHeaderWithActionsAndImage from "./ModalHeaderWithActionsAndImage";
import AvatarEditModal from "apps/profile/components/avatar-edit-modal";
import BioContactInfo from "apps/profile/components/bio-contact-info";
import AddTaskButton from "apps/tasks/components/create/add-task-button";
import ProfileActionsMenu from "apps/profile/components/profile-actions-menu";
import ProfileNav from "apps/profile/components/profile-nav";
import Avatar from "components/elements/avatar";
import DialerDrawerButton from "components/drawer/dialer-drawer-button";
import EmailerDrawerButton from "./emailer-drawer-button";
import EmailPromptsList from "./bio-email-prompts-list";
import FeatureStore from "apps/layout/stores/feature-store";
import AIEmailTemplate from "./ai-email-templates/ai-email-template";
import ContactTimezoneIndicator from "apps/contact/components/contact-timezone-indicator";
import { useGate } from "components/is-gated";

const ProfileHeader = ({ contact = {}, tab, onBack, afterProposalSave, userType }) => {
  const [avatar, setAvatar] = useState(null);
  const [activeModal, setActiveModal] = useState(null);
  const [socialProfile, setSocialProfile] = useState({});
  const [phones, setPhones] = useState([]);
  const [showEmailTemplate, setShowEmailTemplate] = useState(false);
  const [timeZoneContact, setTimeZoneContact] = useState({});
  const [activeJourneys, setActiveJourneys] = useState([]);
  const [emailPrompt, setEmailPrompt] = useState({ key: "no_prompt", display_name: "Blank Email" });
  const [profileRefreshed, refreshProfile] = useState(false);
  const [contactModalIsOpen, openContactModal, closeContactModal] = useOpenClose();
  const hasAI = FeatureStore.hasFeature("ai_summary");
  const [hasCadences] = useGate("cadences");

  const fetchProfiles = () => {
    const gql = String.raw;
    const query = gql`
      query ($id: BigInt!) {
        contact(id: $id) {
          phones {
            phone
            primary
            type
          }
          profile_image {
            url
            type
          }
          contact_attributes {
            name_full
          }
          enrichment {
            social_profiles {
              id
              type
              url
              username
              updated_at
            }
          }
          addresses {
            primary
            time_zone {
              time_zone
              zip_code
              observes_dst
              offset
            }
          }
          active_journeys {
            journey_id
            journey_name
            step_number
            day_number
            owner_user_id
            owner {
              name
            }
            creator {
              name
            }
          }
        }
      }
    `;

    Api.GRAPHQL.GRAPHQL.post({
      data: JSON.stringify({
        query: String.raw`${query}`,
        variables: { id: contact.id },
      }),
    }).then((resp = {}) => {
      const { contact } = resp.data;
      const { active_journeys = [] } = contact;
      setActiveJourneys(active_journeys);
      contact.addresses && setTimeZoneContact(contact);
      contact.profile_image && contact.profile_image.url ? setAvatar(contact.profile_image.url) : setAvatar(null);
      contact.profile_image && contact.profile_image.type
        ? setSocialProfile(_.findWhere(contact.enrichment.social_profiles, { type: contact.profile_image.type }))
        : setSocialProfile({});
      contact.phones && setPhones(contact.phones);
    });
  };

  useEffect(() => {
    fetchProfiles();
  }, [profileRefreshed]);

  useEffect(() => {
    const profileHeaderCard = document.querySelector(".profile-header");
    const profileHeaderHeight = profileHeaderCard && profileHeaderCard.offsetHeight;
    const additionalHeight = (remote_id ? 18 : 0) + (activeJourneys.length > 0 ? 18 : 0);
    const profileBodyCard = document.querySelector(".contact-profile--body");
    if (profileBodyCard) profileBodyCard.style.top = `${profileHeaderHeight + additionalHeight}px`;
  }, [contact, activeJourneys]);

  const first_name = get(contact, "properties.name_first.value");
  const deceased = Decorator.Profile.getDeceased(contact);
  const name = Decorator.Contacts.getDetailedName(contact, { include_middle: true, include_prefix: true });
  const remote_id = _.find(contact.identities, { type: "remote_id" });
  let primaryEmail;
  const { email } = Decorator.Profile.getPrimaryContactInfo(contact);
  if (email) primaryEmail = email.email;

  const handleOpenEmailTemplate = ({ key, display_name }) => {
    closeContactModal();
    setTimeout(() => {
      setShowEmailTemplate(true);
    }, [300]);
    setEmailPrompt({ key, display_name });
  };

  const renderContactModal = () => {
    return (
      <Modal width={480}>
        <ModalConfirm header="Contact" className="profile-header--contact">
          <BioContactInfo contact={contact} hasHeader={false} />
        </ModalConfirm>
      </Modal>
    );
  };

  const renderContactModalWithAI = () => {
    return (
      <Modal width={700} display={"flex"}>
        <ModalConfirm header="Contact" className="profile-header--contact">
          <BioContactInfo contact={contact} hasHeader={false} />
        </ModalConfirm>
        <ModalConfirm header="Suggested Outreach" className="email-prompts--suggested">
          <EmailPromptsList onListItemClick={handleOpenEmailTemplate} />
        </ModalConfirm>
      </Modal>
    );
  };

  const renderAvatarEditModal = () => {
    return (
      <Modal width={450}>
        <ModalConfirm header="Remove Profile Image">
          <AvatarEditModal
            contact={contact}
            imgUrl={avatar}
            socialProfile={socialProfile}
            onBlackList={fetchProfiles}
          />
        </ModalConfirm>
      </Modal>
    );
  };

  const renderEmailTemplateModal = () => {
    const title = emailPrompt.display_name;
    return (
      <Modal width={818} keepOpen>
        <ModalHeaderWithActionsAndImage
          title={title}
          submitLabel="Done"
          closeLabel="Back"
          imageSrc="/images/ai-email.png"
          closeModal={() => {
            setShowEmailTemplate(false);
            setTimeout(() => {
              openContactModal(true);
            }, [300]);
          }}
          onSubmit={() => setShowEmailTemplate(false)}
        />
        <ModalBody>
          <AIEmailTemplate title={title} contact={contact} promptKey={emailPrompt.key} />
        </ModalBody>
      </Modal>
    );
  };

  return (
    <div className="profile-header">
      <div style={{ display: "flex" }}>
        <LinkButton title="Go back" className="profile-header--back" onClick={onBack}>
          <Icon icon="chevron-left" size={2} />
        </LinkButton>
        <div style={{ width: "100%" }}>
          {avatar ? (
            <ModalTrigger modal={renderAvatarEditModal()} title={`Edit ${name}'s Avatar Images`} c>
              <div className="profile-header--avatar">
                <Avatar url={avatar} className="fs-exclude-data" name={name} hasDelete={true} />
              </div>
            </ModalTrigger>
          ) : (
            <div className="profile-header--avatar">
              <Avatar url={avatar} className="fs-exclude-data" name={name} />
            </div>
          )}

          <div className="profile-header--user">
            <div className="profile-header--name-wrapper">
              <h1 className={`profile-header--name ${deceased ? "is-deceased" : ""}`}>
                <span className="fs-exclude-data" style={{ display: "flex" }}>
                  {name} <ContactTimezoneIndicator contact={timeZoneContact} />
                </span>
              </h1>
              {deceased ? <span className="profile-header--deceased">{deceased}</span> : null}
              <div className="profile-header--role">
                <span className="fs-exclude-data">{Decorator.Profile.getRoleWithYear(contact)}</span>
              </div>
              {remote_id && remote_id.value ? (
                <div className="profile-header--role">Remote Id: {remote_id.value}</div>
              ) : null}
              {hasCadences && !!activeJourneys.length && (
                <ActiveJourneysPills activeJourneys={activeJourneys} contactName={contact.name} />
              )}
            </div>
          </div>
          <div className="pull-right profile-header--buttons">
            {/* close actions when other is active */}
            <DialerDrawerButton
              drawerTitle={name}
              contact={contact}
              name={name}
              phones={phones}
              activeModal={activeModal}
              setActiveModal={setActiveModal}
            />
            <EmailerDrawerButton
              name={name}
              emailAddress={primaryEmail}
              contactId={contact.id}
              activeModal={activeModal}
              setActiveModal={setActiveModal}
              contact={contact}
            />
            <AddTaskButton
              buttonType="icon-btn"
              contact={contact}
              primary={false}
              userType={userType}
              activeModal={activeModal}
              setActiveModal={setActiveModal}
            />
            <ModalTrigger
              onClick={openContactModal}
              show={contactModalIsOpen}
              modal={hasAI ? renderContactModalWithAI() : renderContactModal()}
              title={`View ${name}'s Contact Information`}
              buttonType="secondary"
            >
              Contact <span className="fs-exclude-data">{first_name}</span>
            </ModalTrigger>
            <ProfileActionsMenu
              contact={contact}
              afterProposalSave={afterProposalSave}
              activeJourneys={activeJourneys}
              refreshProfile={refreshProfile}
            />
          </div>
        </div>
      </div>
      <ProfileNav id={contact.id} tab={tab} />
      <ModalTrigger modal={renderEmailTemplateModal()} show={showEmailTemplate} title="AI generated email templates" />
    </div>
  );
};

ProfileHeader.propTypes = {
  contact: PropTypes.object,
  tab: PropTypes.string,
  onBack: PropTypes.func,
  afterProposalSave: PropTypes.func,
  userType: PropTypes.string,
};

export default ProfileHeader;
