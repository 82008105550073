import journeysQueries from "apps/journeys/utils/gql/queries";
import { fetchFromGraphql } from "entities/helpers/gqlCall";
import EverTrue from "app";
import Api from "entities/helpers/api";
import ErrorLogger from "entities/helpers/error-log-helper";

export const ACTIVE_CONSTITUENTS_PAGE_LIMIT = 25;

export const fetchJourneyDetails = async (
  journeyId,
  taskOwnerUserId,
  stepTaskStatuses,
  constituentsTaskStatuses,
  selectedDate,
  activeContactJourneyPage = 1,
  activeContactJourneyLimit = ACTIVE_CONSTITUENTS_PAGE_LIMIT
) => {
  const query = journeysQueries.fetchJourneyDetails;
  const queryVars = {
    journeyId,
    taskOwnerUserId,
    stepTaskStatuses,
    constituentsTaskStatuses,
    selectedDate,
    activeContactJourneyPage,
    activeContactJourneyLimit,
  };

  const { journey } = await fetchFromGraphql(query, queryVars, "journey");
  return journey || {};
};

export const getActionTypes = async () => {
  const data = await Api.JOURNEYS.TASK_ACTION_TYPES.get();
  const formattedTaskActionTypes = Object.entries(data).map(([value, label]) => ({ value, label }));
  return formattedTaskActionTypes;
};

// CONTACT JOURNEY ("Active Constituents") REQUESTS

export const loadMoreActiveConstituents = async (journeyId, page, perPage, taskStatuses) => {
  const query = journeysQueries.fetchPaginatedActiveContactJourneys;
  const queryVars = {
    journeyId,
    activeContactJourneyPage: page,
    activeContactJourneyLimit: perPage,
    taskStatuses,
  };

  const { journey } = await fetchFromGraphql(query, queryVars, "journey");
  return journey || {};
};

// JOURNEY TASK REQUESTS

export const createJourneyTask = async (payload) => {
  const data = { ...payload, active: true };
  return await Api.JOURNEYS.JOURNEY_TASKS.post({
    data: JSON.stringify(data),
    success: (response) => {
      EverTrue.Alert.success("Cadence step has been created");
      return response;
    },
    error(e) {
      ErrorLogger.error("Create Step Error", { extra: { error: e, page_name: "Cadence Step" } });
      return EverTrue.Alert.error("Error Creating Step, try again later");
    },
  });
};

export const editJourneyTask = async (payload, journeyTaskId) => {
  const existingJourneyTask = await getJourneyTaskById(journeyTaskId);
  const data = { ...existingJourneyTask, ...payload };
  return await Api.JOURNEYS.JOURNEY_TASK.put({
    data: JSON.stringify(data),
    urlArgs: { id: journeyTaskId },
    success: (response) => {
      EverTrue.Alert.success("Cadence step has been edited");
      return response;
    },
    error(e) {
      ErrorLogger.error("Create Step Error", { extra: { error: e, page_name: "Cadence Step" } });
      return EverTrue.Alert.error("Error Creating Step, try again later");
    },
  });
};

const getJourneyTaskById = async (journeyTaskId) => {
  try {
    return await Api.JOURNEYS.JOURNEY_TASK.get({
      urlArgs: { id: journeyTaskId },
    });
  } catch (error) {
    ErrorLogger.error("Error fetching journey task by id", { extra: { error: error, page_name: "Cadence Step" } });
    return EverTrue.Alert.error("There was a problem fetching the cadence. Please try again.");
  }
};

export const deactivateJourneyTask = async (journeyTask) => {
  return await Api.JOURNEYS.DEACTIVATE_JOURNEY_TASK.put({
    urlArgs: { id: journeyTask.id },
    success: (response) => {
      EverTrue.Alert.success("Cadence step has been deleted");
      return response;
    },
    error(e) {
      ErrorLogger.error("Deactive Step Error", { extra: { error: e, page_name: "Cadence Step" } });
      return EverTrue.Alert.error("There was a problem deleting your Step. Please try again.");
    },
  });
};
