module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   {createFactory} = require("base/new-utils")
   AdvancedCombobox = createFactory(require("@evertrue/et-components").AdvancedCombobox)
   {createComponent} = require("@evertrue/et-flux")

   createComponent "SearchPreferenceSelect",
      propTypes:
         id: ReactLibs.PropTypes.number
         enabled: ReactLibs.PropTypes.bool
         frequency: ReactLibs.PropTypes.string
         preference_type: ReactLibs.PropTypes.string
         isNewSearch: ReactLibs.PropTypes.bool
         onChange: ReactLibs.PropTypes.func

      update_options: [
         {label: "Daily", value: "daily"}
         {label: "Weekly", value: "weekly"}
         {label: "Biweekly", value: "biweekly"}
         {label: "Monthly", value: "monthly"}
         {label: "Never", value: "never"}
      ]

      handleValueChange: (value_obj) ->
         value = value_obj.value
         if _.isFunction(@props.onChange)
            if @props.id
               @props.onChange(@props.id, @props.preference_type, value)
            else
               @props.onChange(@props.preference_type, value)

      render: ->
         options = _.clone(@update_options)
         value = _.findWhere options, value: @props.frequency?.toLowerCase()

         # Preference select logic for save new search popover
         # dont want to include 'never' select option
         if !!@props.isNewSearch
            options.pop()
            disabled = !@props.enabled
         else if @props.enabled is false
            value = "" # Preference select logic for search management page

         AdvancedCombobox
            className: @props.className || ""
            value: value
            disabled: disabled
            options: options
            onChange: @handleValueChange
            placeholder: "Select Frequency"
            width: @props.width || "90%"
