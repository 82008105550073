module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   EverTrue = require("app")
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")
   LocalApi = require("entities/helpers/local-api")
   User = require("entities/auth/user")

   _storeUserFactory = (key) ->
      (user) ->
         data = user.toJSON?() || user || {}
         EverTrue.store[key].clear()
         EverTrue.store[key].set(data)
         EverTrue.store.push(key, EverTrue.store[key], {cache: true})

   _storeUser = _storeUserFactory("user")
   _storeAdmin = _storeUserFactory("admin_impersonator")


   createSource "UserSource",
      actions:
         newUser: (user, options) ->
            @require(user.id?, "user data needs an id")
            if options?.isImpersonating
               @require(_.isBoolean(options.isImpersonating), "isImpersonating should be a boolean")

      startImpersonationMode: ->
         {user, admin_impersonator} = EverTrue.store
         Api.set({impersonate_user_id: user.get("id")})
         LocalApi.set({impersonate_user_id: user.get("id")})
         EverTrue.track.updateMixpanel(admin_impersonator, user)

         @setRavenUser()
         $("body").addClass("impersonation-mode")
         @actions.newUser(user.toJSON(), isImpersonating: true)

      api:
         set: (user) ->
            original_user = EverTrue.store.user?.toJSON?() || {}
            user_model = new User()
            user_model.set(user_model.parse(user))

            EverTrue.store.push "user", user_model, {cache: true}
            if original_user.id != user_model.get("id")
               @actions.newUser(user_model.toJSON(), {isImpersonating: false})

         setRavenUser: ->
            {user, org, admin_impersonator} = EverTrue.store
            isImpersonating = !!admin_impersonator?.get("id")
            Raven?.setUser
               email: user.get("email")
               name: user.get("name")
               id: user.get("id")
               oid: org?.get("id")
               is_impersonating: isImpersonating

         impersonate: (impersonated_user) ->
            current_super_user =
               if EverTrue.store.admin_impersonator?.get("id")
                  EverTrue.store.admin_impersonator
               else
                  EverTrue.store.user

            _storeAdmin(current_super_user)
            _storeUser(impersonated_user)
            @startImpersonationMode()
            EverTrue.vent.trigger "storeReset"

         endImpersonation: ->
            _storeUser(EverTrue.store.admin_impersonator)
            EverTrue.store.destroy("admin_impersonator")

            user = EverTrue.store.user
            $(".show-disabled").removeClass("show-disabled")
            EverTrue.track.updateMixpanel(user)

            @setRavenUser()
            if user?.get("id")
               Api.set({impersonate_user_id: undefined})
               LocalApi.set({impersonate_user_id: undefined})
               EverTrue.vent.trigger "storeReset"
               @actions.newUser(user.toJSON(), isImpersonating: false)
            else
               EverTrue.execute("resetApp")

            $("body").removeClass("impersonation-mode")
