module.exports = do ->
   EverTrue = require("app")
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")


   createSource "SegmentActivitySource",
      actions:
         loading: true
         selectActivity: true
         fetchedActivity: true
         fetchedActivityError: true

      api:
         selectActivity: (id) ->
            @actions.selectActivity(id)

         fetchActivity: (id) ->
            return unless id
            @actions.loading(true)

            Api.EMS.ACTIVITY.get
               urlExtend: "/#{id}"
               error: =>
                  @actions.loading(false)
                  @actions.fetchedActivityError()
               success: (resp) =>
                  @actions.fetchedActivity(resp)
                  @actions.loading(false)
                  EverTrue.track.setReferrer("search_update")
