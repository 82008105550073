module.exports = do ->
   React = require("react")
   {div, i} = ReactLibs.DOMFactories

   ToastAlert = ReactLibs.createClass
      displayName: "Banner Notice"

      getDefaultProps: ->
         icon: "info-circle"
         className: ""

      render: ->
         div className: "banner-notice #{@props.className}",
            i className: "banner-notice--icon fa fa-fw fa-#{@props.icon}"
            div className: "banner-notice--text",
               @props.children
