module.exports = do ->
   React = require("react")
   EverTrue = require("app")
   {ul, li} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   Button = createFactory(require("@evertrue/et-components").Button)
   {createComponent} = require("@evertrue/et-flux")


   createComponent "AndOrToggle",
      propTypes:
         edit: ReactLibs.PropTypes.bool
         value: ReactLibs.PropTypes.string
         onChange: ReactLibs.PropTypes.func

      handleChange: (key) ->
         @props.onChange?(key)

      renderClasses: (check) ->
         EverTrue.classSet "and-or-toggle--item", check,
            "is-selected": !@props.edit || @props.value == check

      render: ->
         if @props.edit
            ul className: "and-or-toggle",
               li className: @renderClasses("and"),
                  Button type:"simple", title: "Select AND", onClick:(=> @handleChange("and")), "AND"
               li className: @renderClasses("or"),
                  Button type:"simple",title: "Select OR", onClick:(=> @handleChange("or")), "OR"
         else
            ul className: "and-or-toggle",
               li className: @renderClasses(),
                  Button type:"simple", null,  @props.value
