import _ from "underscore";
import numeral from "numeral";
import PropTypes from "prop-types";
import { connect } from "@evertrue/et-flux";
import { Component } from "react";
import Decorator from "clientDecorator";
import ContactFormatMixin from "mixins/contact-format-mixin";
import ContactCard from "apps/contact/components/contact-card/contact-card";
import ContactBusiness from "apps/contact/components/contact-card/contact-business";
import FeatureStore from "apps/layout/stores/feature-store";
import ProfileAssignmentStore from "apps/profile/stores/profile-assignment-store";
import ProfileAssignmentSource from "apps/profile/sources/profile-assignment-source";

const mapStateToProps = ({ data }) => ({
  has_rm_assignments: FeatureStore.hasFeature("rm_assignments"),
  has_volunteers: FeatureStore.hasFeature("volunteers"),
  vol_assignments: ProfileAssignmentStore.getVolunteerAssignmentsForProfile(data.id),
  team_assignments: ProfileAssignmentStore.getTeamAssignmentsForProfile(data.id),
});

class ContactExpandedRow extends Component {
  static propTypes = {
    data: PropTypes.object,
    className: PropTypes.string,
    has_rm_assignments: PropTypes.bool,
    has_volunteers: PropTypes.bool,
    team_assignments: PropTypes.array,
    vol_assignments: PropTypes.array,
  };

  static defaultProps = {
    data: {},
  };

  componentDidMount() {
    if (this.props.has_rm_assignments && this.props.data.id) {
      ProfileAssignmentSource.fetchTeamMembership(this.props.data.id);
    }
    if (this.props.has_volunteers && this.props.data.id) {
      ProfileAssignmentSource.fetchVolunteerMembership(this.props.data.id);
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.has_rm_assignments && this.props.has_rm_assignments && this.props.data.id) {
      ProfileAssignmentSource.fetchTeamMembership(this.props.data.id);
    }
    if (!prevProps.has_volunteers && this.props.has_volunteers && this.props.data.id) {
      ProfileAssignmentSource.fetchVolunteerMembership(this.props.data.id);
    }
  }

  render() {
    const contact = Decorator.Contacts.parse(this.props.data);
    const assignees = ContactFormatMixin.assignee(this.props.data);
    const assignee_text = _.isEmpty(assignees) ? "Not Assigned" : assignees.join(", ");

    // Team Assignment Data
    const team_assignment_names = _.isEmpty(this.props.team_assignments)
      ? "Not Assigned"
      : _.pluck(this.props.team_assignments, "name").join(", ");
    const team_memberships = _.flatten(_.pluck(this.props.team_assignments, "memberships"));
    const team_statuses = _.pluck(team_memberships, "stage");
    const team_status_text = _.isEmpty(team_statuses) ? "Not Provided" : team_statuses.join(", ");

    // Volunteer Assignment Data
    const vol_assignment_names = _.isEmpty(this.props.vol_assignments)
      ? "Not Assigned"
      : _.pluck(this.props.vol_assignments, "name").join(", ");

    return (
      <table className={`table contact-expanded ${this.props.className || ""}`}>
        <tbody>
          <tr>
            <td className="contact-expanded--contact">
              <table>
                <tbody>
                  <ContactCard contact={contact}>
                    <ContactBusiness contact={contact} />
                  </ContactCard>
                </tbody>
              </table>
            </td>
            <td>
              <div>
                <span className="text-label">Lifetime Giving: </span>
                <strong className="text-dollar-value fs-exclude-data">
                  {numeral(ContactFormatMixin.giving(this.props.data, "lifetime_amount")).format("$0,0")}
                </strong>
              </div>
              <div>
                <span className="text-label">Largest Gift: </span>
                <strong className="text-dollar-value fs-exclude-data">
                  {numeral(ContactFormatMixin.giving(this.props.data, "largest_gift_amount")).format("$0,0")}
                </strong>
              </div>
              <div>
                <span className="text-label">Last Gift: </span>
                <strong className="text-dollar-value fs-exclude-data">
                  {numeral(ContactFormatMixin.giving(this.props.data, "last_gift_amount")).format("$0,0")}
                </strong>
              </div>
            </td>
            <td>
              <div>
                <span className="text-label">Assigned To: </span>
                {this.props.has_rm_assignments ? team_assignment_names : assignee_text}
              </div>
              {this.props.has_volunteers && (
                <div>
                  <span className="text-label">Volunteer Solicitor: </span>
                  {vol_assignment_names}
                </div>
              )}
              <div>
                <span className="text-label">Constituent Status: </span>
                {this.props.has_rm_assignments
                  ? team_status_text
                  : ContactFormatMixin.status(this.props.data) || "Not Provided"}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

export default connect(
  ContactExpandedRow,
  [FeatureStore, ProfileAssignmentStore],
  mapStateToProps
);
