import _ from "underscore";
import { PropTypes } from "base/react-libs";
import CareerEnrichmentCard from "apps/profile/components/enrichment/career-enrichment-card";
import { IconButton, LinkButton } from "@evertrue/et-components";

const CareerItems = ({ employment, editMode = "", onMutation }) => {
  const currentEmployment = _.filter(
    employment,
    (employment) => employment.status === "current" || employment.status === null
  );
  const pastEmployment = _.filter(employment, (employment) => employment.status === "past");

  return (
    <>
      {!_.isEmpty(currentEmployment) ? (
        <>
          <div className="profile--enrichment-group">
            <div className="profile--enrichment-group-title">Current Employment</div>
            <div className="profile--enrichment-group-data">
              {currentEmployment.map((career) => (
                <div key={career.company_id + career.title} className="profile-enrichment--item">
                  <CareerEnrichmentCard data={career} key={career.company_id + career.title} />
                  {editMode === "delete" ? (
                    <IconButton
                      title="remove employment record from constituent"
                      icon="clear"
                      className="profile--enrichment-delete-button"
                      onClick={() => onMutation(career, true)}
                    />
                  ) : editMode === "restore" ? (
                    <LinkButton className="profile--enrichment-undo-button" onClick={() => onMutation(career, false)}>
                      Undo
                    </LinkButton>
                  ) : null}
                </div>
              ))}
            </div>
          </div>
        </>
      ) : null}
      {!_.isEmpty(pastEmployment) ? (
        <>
          <div className="profile--enrichment-group">
            <div className="profile--enrichment-group-title">Previous Employment</div>
            <div className="profile--enrichment-group-data">
              {pastEmployment.map((career) => (
                <div key={career.company_id + career.title} className="profile-enrichment--item">
                  <CareerEnrichmentCard data={career} key={career.company_id + career.title} />
                  {editMode === "delete" ? (
                    <IconButton
                      title="remove employment record from constituent"
                      icon="clear"
                      className="profile--enrichment-delete-button"
                      onClick={() => onMutation(career, true)}
                    />
                  ) : editMode === "restore" ? (
                    <LinkButton className="profile--enrichment-undo-button" onClick={() => onMutation(career, false)}>
                      Undo
                    </LinkButton>
                  ) : null}
                </div>
              ))}
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

CareerItems.propTypes = {
  employment: PropTypes.array,
  editMode: PropTypes.string,
  onMutation: PropTypes.func,
};
export default CareerItems;
