GiftTransactionFiltersBlock = require("apps/filters/gift-transaction-filter/gift-transactions-filters-block")
GiftTransactionParamSelector = require("apps/filters/gift-transaction-filter/gift-transactions-param-selector")
GiftTransactionsFiltersUtils = require("apps/filters/gift-transaction-filter/gift-transactions-filters-utils")

GiftTransactionConfig =
   help: "gift_transaction_filters"

   component: (input, state, onChange) ->
      GiftTransactionParamSelector
         input: input
         state: state
         onChange: onChange

   sub_component: (input, state, onChange) ->
      param = input?.value?.param
      if param? and param in ["must", "must_not"]
         GiftTransactionFiltersBlock
            input: input
            state: state
            onChange: onChange

   parse_fn: (criteria) ->
      GiftTransactionsFiltersUtils.parseCriteria(criteria)

   query_fn: (value) ->
      GiftTransactionsFiltersUtils.getQuery(value)

module.exports = GiftTransactionConfig
