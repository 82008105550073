import { useState, useEffect } from "react";
import Api from "entities/helpers/api";
import ErrorLogger from "entities/helpers/error-log-helper";

const useQuickFilters = () => {
  const [quickFilters, setQuickFilters] = useState([]);

  useEffect(() => {
    const loadFilters = async () => {
      try {
        const resp = await Api.HUB.FILTERS.get({ params: { type: "QUICK" } });
        setQuickFilters(resp);
      } catch {
        ErrorLogger.captureRequest("Error fetching quick filters");
      }
    };

    loadFilters();
  }, []);

  return { quickFilters };
};

export default useQuickFilters;
