/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react";
import getGoogleApi from "apps/search/utils/get-google-api";
import createMap from "apps/mapv2/utils/create-map";

const google = getGoogleApi();

// Hook to manage the state of a map instance
const useMap = (icon, mapSettings = {}) => {
  const [map, setMap] = useState(null);
  const [div, setDiv] = useState(null);
  const googleMapMarkers = useRef([]);

  useEffect(() => {
    setDiv(document.getElementById("mapv2"));
    createMap(div, setMap, mapSettings);
  }, [div]);

  const createMapMarkers = (map, positions) => {
    return positions.map((position) => {
      const marker = new google.Marker({
        position,
        icon: {
          url: icon,
          scaledSize: new google.Size(50, 50), // scaled size
        },
      });
      marker.setMap(map);

      googleMapMarkers.current.push({ marker, position });
      return marker;
    });
  };

  const clearMap = () => {
    googleMapMarkers.current.forEach(({ marker }) => {
      marker.setMap(null);
    });
    googleMapMarkers.current = [];
  };

  return {
    clearMap,
    createMapMarkers,
    map,
    googleMapMarkers,
  };
};

export default useMap;
