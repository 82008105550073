module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   React = require("react")
   {div, span} = ReactLibs.DOMFactories
   {createComponent} = require("@evertrue/et-flux")
   {createFactory} = require("base/new-utils")
   Icon = createFactory (require("components/elements/icon").default)
   Checkbox = createFactory(require("@evertrue/et-components").Checkbox)
   Popover = require("components/overlays/popover")
   FacetStore = require("apps/filters/stores/facet-store")
   FilterConfigStore = require("apps/filters/stores/filter-config-store")
   FilterStore = require("apps/filters/stores/filter-store")
   Button = createFactory(require("@evertrue/et-components").Button)

   createComponent "MapAddressFilter",
      propTypes:
         onApply: ReactLibs.PropTypes.func
         onSelectType: ReactLibs.PropTypes.func
         show_filter_text: ReactLibs.PropTypes.bool

      getInitialState: ->
         temp_address_filters: undefined

      registerStores: ->
         @on FacetStore, ->
            address_types: FacetStore.getByKey("addresses.type")

         @on FilterStore, ->
            type_filters: FilterStore.getActiveFilterByKey("address_type")
            filters: FilterStore.getActiveFilters()

      getSelectedAddressTypes: ->
         _.map @state.type_filters, (filter) -> filter.value

      handleApply: ->
         if @props.selected_types
            config = FilterConfigStore.getConfigByKey("address_type")
            filters = _.map @state.filters, (filter) -> _.clone(filter)
            active_filter = _.findWhere filters, {filter_id: config.filter_id}

            if active_filter
               if _.isEmpty(@props.selected_types)
                  filters = _.reject filters, (filter) ->
                     filter.filter_id == active_filter.filter_id
               else
                  active_filter.value = _.map @props.selected_types, (val) ->
                     {label: val, value: val}
            else if !_.isEmpty(@props.selected_types)
               filters.push
                  filter_row_id: filters.length + 1
                  filter_id: config.filter_id
                  value: _.map @props.selected_types, (val) ->
                     {label: val, value: val}

            @props.onApply?(filters)

         @handleCancel()

      handleCancel: ->
         $("body").trigger("click")

      handleSelectType: (type, isChecked) ->
         types = @props.selected_types || @getSelectedAddressTypes()
         if isChecked then types.push(type)
         else types = _.without(types, type)
         @props.onSelectType?(types)

      render: ->
         selected_types = @props.selected_types || @getSelectedAddressTypes()

         if _.isEmpty(@state.address_types) then null
         else
            Popover
               className: "map-address-filter"
               options: {arrowColor: "#3F565E", arrowBorderColor: "#3F565E"}
               trigger:
                  div null,
                     Icon icon: "filter", size: 1, className:  selected_types.length > 0 && "icon-highlight" 
                     if !!@props.show_filter_text and selected_types.length > 0
                        span className: "map-address-filter--text",
                           "#{selected_types.length} Active "
                           if selected_types.length > 1 then "Filters" else "Filter"

               div className: "map-address-filter--content",
                  div className: "map-address-filter--header",
                     "Filter by Address Type"

                  div className: "map-address-filter--body",
                     _.map @state.address_types, (type, i) =>
                        if !_.isEmpty(type.value?.trim?())
                           Checkbox
                              key: i
                              checked: _.contains(selected_types, type.value)
                              onChange: (isChecked, e) =>
                                 @handleSelectType(type.value, isChecked)
                              type.value

                  div className: "map-address-filter--footer pull-right clearfix",
                     Button type: "simple", title: "Cancel filter", onClick: @handleCancel, "Cancel"
                     Button className: "map-address-filter--apply", title: "Apply filter", onClick: @handleApply, "Apply"
