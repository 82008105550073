module.exports = do ->
   _ = require("underscore").default
   numeral = require("numeral")
   str = require("underscore.string")
   React = require("react")
   Api = require("entities/helpers/api")
   ContactFormatMixin = require("mixins/contact-format-mixin")
   AddressMixin = require("mixins/address-mixin")
   {div, span} = ReactLibs.DOMFactories
   Popover = require("components/overlays/popover")
   {createFactory} = require("base/new-utils")
   ReadMore = createFactory require("components/formatting/readmore")
   Modal = createFactory require("components/modals/modal")
   ModalTrigger = createFactory require("components/modals/modal-trigger")
   SelectContactCheckbox = require("apps/contact/components/contacts/select-contact-checkbox")
   ColumnSelector = require("apps/contact/components/contacts/columns/column-selector")
   ContactCell = require("apps/contact/components/contacts/contact-cell")
   Icon = createFactory (require("components/elements/icon").default)
   moment = require("moment")
   ScoreProgress = createFactory require("apps/contact/components/contacts/score-progress").default
   ScoreBreakdown = createFactory require("apps/profile/components/score-breakdown").default
   AnnualGivingScore = require("apps/contact/components/contacts/annual-giving-score")
   EngagementIcon = require("apps/profile/components/engagement-icon")
   {getTabByEngagementAction} = require("apps/events/utils/engagement-config")
   {PromiseResolver} = require("@evertrue/promise-resolver")
   PromiseResolverFactory = createFactory(PromiseResolver)
   CachedContactsResolver = createFactory require("apps/contact/components/contacts/cached-contacts-resolver").default
   IsGated = createFactory require("components/is-gated").default
   Loading = createFactory(require("@evertrue/et-components").Loading)
   Decorator = require("clientDecorator")
   Link = createFactory(require("@evertrue/et-components").Link)
   moment = require "moment"
   FeatureStore = require("apps/layout/stores/feature-store").default

   _WIDTHS =
      XS: 128
      S: 144
      M: 176
      L: 192
      XL: 224

   _formatDollarAmount = (num) ->
      format = if _.isNumber(num) < 1 then "$0.00" else "$0,00"
      numeral(num).format(format)

   _formatBooleanDisplay = (check) ->
      if check then "Y" else "N"

   _renderMultiple = (columnData, key) ->
      if _.isArray columnData
         if !_.isEmpty columnData
            _.map columnData, (item, index) ->
               div key: "item-" + index,
                  str.capitalize(item?[key]?.value || item?[key])
                  if index != columnData?.length - 1 then ","
         else div className: "text-centered", "-"
      else
         div className: "text-centered", str.capitalize(columnData) || "-"

   # Uses iso 8601 date format but should work fine with other formats
   _renderMostRecent = (columnData, returnKey, timeKey = "updated_at") ->
      if columnData?.length
         formattedData = columnData.map((data) ->
            Object.assign data, {updated_at: moment(data?[timeKey])})

         mostRecent = moment.max(_.pluck(formattedData, "updated_at"))
         _.findWhere(formattedData, {updated_at: mostRecent})[returnKey]

   oneMil = 1000000

   ContactTableRows =
      checkbox:
         label: ""
         width: 45
         prop: undefined
         key: "checkbox"
         sticky: true
         row: (data) ->
            contact_name = Decorator.Contacts.getDetailedName(data)
            SelectContactCheckbox id: data.id, name: contact_name

      constituent:
         label: "Constituent"
         width: 285
         prop: "name_last"
         key: "constituent"
         className: "contact-fixed-table--contact-cell"
         sticky: true
         row: (data) ->
            ContactCell contact: data

      primary_email:
         label: "Primary Email"
         width: 285
         row: (data) ->
            primaryEmails = _.compact _.map data?.emails, ({email, primary}) ->
               if primary?.value is true then email?.value?.toLowerCase()
            num = _.size(primaryEmails)
            switch num
               when 0 then span "aria-label": "Primary Email, No Primary Email", className: "text-muted", "No Primary Email"
               when 1 then span "aria-label": "Primary Email, #{primaryEmails[0]}", className: "fs-exclude-data",  span "aria-hidden": "true", primaryEmails[0]
               else span "aria-label": "Primary Email, #{num} Primary Emails ", className: "text-muted",  span "aria-hidden": "true", "#{num} Primary Emails"


      remote_id:
         label: "Remote ID"
         width: _WIDTHS.S
         row: (data) ->
            remote_id = _.find(data.identities, {type: "remote_id"})?.value
            div className: "text-centered", "aria-label": "Remote Id, #{remote_id || "not provided"}",
               if remote_id
                  remote_id
               else "-"

      capacity_score:
         width: _WIDTHS.L
         prop: "giving.capacity_score.untouched"
         row: (data) ->
            capacity_score = ContactFormatMixin.giving(data, "capacity_score")
            div className: "text-centered", "aria-label": "Capacity Score, #{capacity_score || "not provided"}",
               span "aria-hidden" : "true",
                  capacity_score ||  "-"


      donor_score:
         width: _WIDTHS.M
         row: (data) ->
            donor_score = ContactFormatMixin.giving(data, "donor_score")
            div className: "text-centered", "aria-label": "Donor Score, #{donor_score || "not provided"}",
               span "aria-hidden" : "true",
                  donor_score ||  "-"


      solicitation_codes:
         width: _WIDTHS.M
         row: (data) ->
            solicitations = data?.solicitation_codes
            solicitations_string = _.map data?.solicitation_codes, ({code}) ->
               code.value
            span "aria-label": "Solicitation codes, #{solicitations_string.join(", ") || "None"}",
               span "aria-hidden": "true", _renderMultiple(solicitations, "code")

      is_unrated:
         width: _WIDTHS.XS
         row: (data) ->
            capacity_score = ContactFormatMixin.giving(data, "capacity_score")
            capacity_bool = _formatBooleanDisplay(!capacity_score)
            div className: "text-centered", "aria-label": "Is Rated, #{capacity_bool}",
               span "aria-hidden": "true", capacity_bool

      wealthy_neighborhood:
         prop: "addresses.house_value_median"
         width: _WIDTHS.L
         row: (data) ->
            address = AddressMixin.getDefaultAddress(data)
            is_wealthy_neighborhood = _formatBooleanDisplay(address?.house_value_median?.value >= oneMil)
            div className: "text-centered", "aria-label": "Wealthy Neighborhood, #{is_wealthy_neighborhood}",
               span "aria-hidden": "true", is_wealthy_neighborhood

      net_worth:
         prop: "enrichment.wealth_attributes.net_worth"
         width: _WIDTHS.S
         row: (data) ->
            net_worth = data?.enrichment?.wealth_attributes?.at(-1)?.net_worth
            div "aria-label": "Net Worth, #{data?.enrichment?.wealth_attributes?.at(-1)?.net_worth}",
               span "aria-hidden": "true",
               if net_worth && (net_worth >= oneMil || (net_worth < oneMil && FeatureStore.hasFeature("windfall_wealth_plus")))
                  _formatDollarAmount(net_worth)
               else "-"

      physical_assets:
         width: _WIDTHS.M
         row: (data) ->
            div "aria-label": "Physical Assets",
               span "aria-hidden": "true",
               if data?.enrichment?.wealth_physical_assets?.length
                  _renderMultiple(data?.enrichment?.wealth_physical_assets, "type")
               else "-"

      wealth_indicators:
         width: _WIDTHS.M
         row: (data) ->
            div "aria-label": "Wealth Indicators",
               span "aria-hidden": "true",
               if data?.enrichment?.wealth_indicators?.length
                  _renderMultiple(data?.enrichment?.wealth_indicators, "type")
               else "-"

      wealth_life_events:
         width: _WIDTHS.M
         row: (data) ->
            div "aria-label": "Life Events",
               span "aria-hidden": "true",
               if data?.enrichment?.wealth_life_events?.length
                  _renderMultiple(data?.enrichment?.wealth_life_events, "type")
               else "-"

      industry:
         width: _WIDTHS.XL
         row: (data) ->
            industry = ContactFormatMixin.industry(data)
            span "aria-label": "Industry, #{industry || "Not Provided"}",
               span "aria-hidden": "true",
                  if industry
                     Link
                        className: "fs-exclude-data"
                        href: "industry" + ContactFormatMixin.industry_link(industry)
                        title: "#{industry}"
                        industry
                  else "-"

      company:
         width: _WIDTHS.XL
         row: (data) ->
            val = ContactFormatMixin.company(data)
            span "aria-label": "Company, #{val || "Not Provided"}",
               span "aria-hidden": "true",
                  if val
                     Link
                        className: "fs-exclude-data"
                        href: "company" + ContactFormatMixin.company_link(data)
                        title: "#{val}"
                        val
                  else "-"

      title:
         width: _WIDTHS.XL
         row: (data) ->
            title = data?.employments?[0]?.title?.value
            span "aria-label": "Title, #{title || "Not Provided"}",
               span "aria-hidden": "true",
                  if title then span className: "fs-exclude-data", title else "-"

      function:
         width: _WIDTHS.L
         row: (data) ->
            val = data?.employments?[0]?.function?.value
            span "aria-label": "Function, #{val || "Not Provided"}",
               span "aria-hidden": "true",
                  if val then span className: "fs-exclude-data", val else "-"

      grad_year:
         prop: "year"
         width: _WIDTHS.S
         row: (data) ->
            year = data.properties?.year?.value
            div className: "text-centered", "aria-label": "Graduation Year, #{year || "Not Provided"}",
               span "aria-hidden": "true",
                  if year then span className: "fs-exclude-data", year else "-"

      education:
         width: _WIDTHS.XL
         row: (data) ->
            val = data.educations?[0]?.school_name?.value
            span "aria-label": "Education, #{val || "Not Provided"}",
               span "aria-hidden": "true",
                  if val then span className: "fs-exclude-data", val else "-"

      athletics:
         width: _WIDTHS.M
         row: (data) ->
            sports_string = _.map data?.sports, (sport) ->
               sport?.name?.value
            span "aria-label": "Athletics, #{sports_string.join(", ") || "None"}",
               span "aria-hidden": "true", _renderMultiple(data?.sports, "name")

      extracurricular_activities:
         width: _WIDTHS.M
         row: (data) ->
            activities = data?.extracurricular_activities
            activities_string = _.map activities, (activity) ->
               activity?.name?.value
            span "aria-label": "Activities, #{activities_string.join(", ") || "None"}",
               span "aria-hidden": "true", _renderMultiple(activities, "name")

      major:
         width: _WIDTHS.XL
         row: (data) ->
            value = data.educations?[0]?.majors?.value
            span "aria-label": "Major, #{value || "Not Provided"}",
               span "aria-hidden": "true", value || "-"

      engagement_score:
         prop: "giving.engagement_score"
         width: _WIDTHS.L
         row: (data) ->
            value = ContactFormatMixin.giving(data, "engagement_score")
            div className: "text-centered", "aria-label": "Engagement Score, #{value || "Not Provided"}",
               span "aria-hidden": "true", value || "-"

      facebook_like_count:
         prop: "facebook.like_count"
         width: _WIDTHS.S
         row: (data) ->
            value = ContactFormatMixin.facebook(data, "like_count")
            div className: "text-right", "aria-label": "Facebook Likes, #{value}",
               span "aria-hidden": "true", value || 0

      facebook_reaction_count:
         width: _WIDTHS.S
         row: (data) ->
            total_engagement = ContactFormatMixin.facebook(data, "total_engagement_count")
            total_comments = ContactFormatMixin.facebook(data, "comment_count")
            value = total_engagement - total_comments
            div className: "text-right","aria-label": "Facebook Reactions, #{value}",
               span "aria-hidden": "true", value || 0

      facebook_engagement:
         width: _WIDTHS.L
         row: (data) ->
            engagement = ContactFormatMixin.facebook(data, "total_engagement_count")
            value = _formatBooleanDisplay(engagement)
            div className: "text-centered", "aria-label": "Facebook Engagement, #{value}",
               span "aria-hidden": "true", value

      last_fb_engagement:
         prop: "facebook.last_engagement_date"
         width: _WIDTHS.L
         row: (data) ->
            value = data.facebook?.last_engagement_date?.value
            span "aria-label": "Last Facebook Engagement, #{value || "Not Provided"}",
               span "aria-hidden": "true", value || "-"

      facebook_comments_count:
         prop: "facebook.comment_count"
         width: _WIDTHS.S
         row: (data) ->
            value = numeral(ContactFormatMixin.facebook(data, "comment_count")).format("0,0")
            div className: "text-right", "aria-label": "Facebook Comments Count, #{value}",
               span "aria-hidden": "true", value

      facebook_match:
         width: _WIDTHS.M
         row: (data) ->
            facebook_uid = _.findWhere(data.identities, {type: "facebook_id"})
            div className: "text-centered",
               _formatBooleanDisplay(facebook_uid?.value)

      linkedin_connections:
         width: _WIDTHS.S
         row: (data) ->
            linkedin =  _.findWhere(data?.enrichment?.social_profiles, {type: "linkedin"})
            div "aria-label": "Linkedin Connections",
               span "aria-hidden": "true",
               if linkedin?.followers
                  linkedin.followers
               else "-"

      twitter_followers:
         width: _WIDTHS.S
         row: (data) ->
            twitter =  _.findWhere(data?.enrichment?.social_profiles, {type: "twitter"})
            div "aria-label": "Twitter Followers",
               span "aria-hidden": "true",
               if twitter?.followers
                  twitter.followers
               else "-"

      lifetime_giving:
         prop: "giving.lifetime_amount"
         width: _WIDTHS.M
         row: (data) ->
            value =_formatDollarAmount(ContactFormatMixin.giving(data, "lifetime_amount"))
            div className: "text-left fs-exclude-data", "aria-label": "Lifetime giving, #{value}",
               span "aria-hidden": "true", value

      largest_gift:
         prop: "giving.largest_gift_amount"
         width: _WIDTHS.M
         row: (data) ->
            value = _formatDollarAmount(ContactFormatMixin.giving(data, "largest_gift_amount"))
            div className: "text-right fs-exclude-data", "aria-label": "Largest Gift Amount, #{value}",
               span "aria-hidden": "true", value

      last_gift:
         prop: "giving.last_gift_amount"
         width: _WIDTHS.M
         row: (data) ->
            value = _formatDollarAmount(ContactFormatMixin.giving(data, "last_gift_amount"))
            div className: "text-left fs-exclude-data", "aria-label": "Last Gift Amount, #{value}",
               span "aria-hidden": "true", value

      last_gift_date:
         prop: "giving.last_gift_date"
         width: _WIDTHS.S
         row: (data) ->
            value = ContactFormatMixin.giving(data, "last_gift_date")
            if value then formatted_value = moment(new Date(value)).format("MM/DD/YYYY")
            span className: "fs-exclude-data", "aria-label": "Last Gift Date, #{formatted_value || "None"}",
              span "aria-hidden": "true", formatted_value || "-"


      current_fiscal_giving:
         width: _WIDTHS.S
         row: (data) ->
            sorted = _.sortBy(data?.giving_annual_donations, (d) -> -d.fiscal_year?.value)
            amount = sorted[0]?.amount?.value
            span "aria-label": "Current Fiscal Year Giving",
               span "aria-hidden": "true",
                  if amount
                     _formatDollarAmount(amount)
                  else "-"

      last_fiscal_giving:
         width: _WIDTHS.S
         row: (data) ->
            sorted = _.sortBy(data?.giving_annual_donations, (d) -> -d.fiscal_year?.value)
            amount = sorted[1]?.amount?.value
            span "aria-label": "Last Fiscal Year Giving",
               span "aria-hidden": "true",
                  if amount
                     _formatDollarAmount(amount)
                  else "-"

      last_fiscal_giving_2:
         width: _WIDTHS.S
         row: (data) ->
            sorted = _.sortBy(data?.giving_annual_donations, (d) -> -d.fiscal_year?.value)
            amount = sorted[2]?.amount?.value
            span "aria-label": "Fiscal Year Giving - 2 years ago",
               span "aria-hidden": "true",
                  if amount
                     _formatDollarAmount(amount)
                  else "-"

      last_fiscal_giving_3:
         width: _WIDTHS.S
         row: (data) ->
            sorted = _.sortBy(data?.giving_annual_donations, (d) -> -d.fiscal_year?.value)
            amount = sorted[3]?.amount?.value
            span "aria-label": "Fiscal Year Giving - 3 years ago ",
               span "aria-hidden": "true",
                  if amount
                     _formatDollarAmount(amount)
                  else "-"


      last_fiscal_giving_4:
         width: _WIDTHS.S
         row: (data) ->
            sorted = _.sortBy(data?.giving_annual_donations, (d) -> -d.fiscal_year?.value)
            amount = sorted[4]?.amount?.value
            span "aria-label": "Fiscal Year Giving - 4 years ago ",
               span "aria-hidden": "true",
                  if amount
                     _formatDollarAmount(amount)
                  else "-"

      last_fiscal_giving_5:
         width: _WIDTHS.S
         row: (data) ->
            sorted = _.sortBy(data?.giving_annual_donations, (d) -> -d.fiscal_year?.value)
            amount = sorted[5]?.amount?.value
            span "aria-label": "Fiscal Year Giving - 5 years ago ",
               span "aria-hidden": "true",
                  if amount
                     _formatDollarAmount(amount)
                  else "-"

      lifetime_recognition_amount:
         prop: "giving.lifetime_recognition_amount"
         width: _WIDTHS.M
         row: (data) ->
            value = _formatDollarAmount(ContactFormatMixin.giving(data, "lifetime_recognition_amount"))
            div className: "text-right fs-exclude-data", "aria-label": "Lifetime Recognition Amount, #{value}",
               span "aria-hidden": "true", value

      lifetime_pledge_balance:
         prop: "giving.total_pledge_balance"
         width: _WIDTHS.M
         row: (data) ->
            value = _formatDollarAmount(ContactFormatMixin.giving(data, "total_pledge_balance"))
            div className: "text-right fs-exclude-data","aria-label": "Lifetime Pledge Balance, #{value}",
               span "aria-hidden": "true", value

      location:
         width: _WIDTHS.XL
         row: (data) ->
            city = AddressMixin.getDefaultAddress(data)?.city?.value
            span "aria-label": "Location, #{city || "Not Provided"}",
               if city then span className: "fs-exclude-data", "aria-hidden": "true", city else "-"

      city:
         width: _WIDTHS.XL
         row: (data) ->
            city = AddressMixin.getDefaultAddress(data)?.city?.value
            span "aria-label": "Location, #{city || "Not Provided"}",
               if city then span className: "fs-exclude-data", "aria-hidden": "true", city else "-"


      state:
         width: _WIDTHS.XS
         row: (data) ->
            address = AddressMixin.getDefaultAddress(data)
            state = address?.state?.value
            div className: "text-centered fs-exclude-data", "aria-label": "State, #{state || "Not Provided"}",
               span "aria-hidden": "true", state || "-"

      country:
         width: _WIDTHS.XS
         row: (data) ->
            address = AddressMixin.getDefaultAddress(data)
            country = address?.country?.value
            div className: "text-centered fs-exclude-data", "aria-label": "Country, #{country || "Not Provided"}",
               span "aria-hidden": "true", country || "-"

      donor_potential_score:
         prop: "et_score.dds_score"
         width: _WIDTHS.M
         row: (data) ->
            score = data?.et_score?.dds_score?.value
            div className: "text-left", "aria-label": "Donor Potential Score, #{score || "Not Available"}",
               span "aria-hidden": "true", score || "-"

      evertrue_score:
         prop: "score.score"
         width: _WIDTHS.M
         row: (data) ->
            score = data.score?.score?.value
            div "aria-label": "EverTrue Score, #{score}",
               span "aria-hidden": "true",
                  Popover
                     width: 220
                     position: "right"
                     event: "hover"
                     trigger: ScoreProgress score: score, className: "contact-fixed-table--score-progress"

                     div className: "contact-fixed-table--score-progress-card rich-tooltip",
                        div className: "rich-tooltip--header", "EverTrue Score"
                        div className: "rich-tooltip--body",
                           span "aria-hidden": "true",
                              ScoreBreakdown scores: data.score

      annual_giving_score:
         prop: "score.annual_fund"
         width: _WIDTHS.M
         row: (data) ->
            score = data?.score?.annual_fund?.value
            span "aria-label": "EverTrue Score, #{score}",
               span "aria-hidden": "true", AnnualGivingScore score: score

      assignee:
         width: _WIDTHS.L
         row: (data) ->
            IsGated
               feature: "rm_assignments"
               children: (gated = {}) ->
                  # If using the new assignments api
                  if gated.show
                     PromiseResolverFactory
                        args: data.id
                        promise: ->
                           Api.VOLUNTEER.MEMBERSHIP.get({
                              urlArgs:
                                 contact_id: data.id,
                                 type: "prospect"
                              params: { pool_type: "TEAM" }
                           })
                        render: ({ data, loading}) ->
                           if loading then Loading size: "small", position: "center"
                           else
                              solicitor_contact_ids = _.uniq(_.pluck(data, "solicitor_contact_id"))
                              if _.isEmpty(solicitor_contact_ids)
                                 span "aria-label": "Assignee, Not provided",
                                    span "aria-hidden": "true", "-"
                              else
                                 CachedContactsResolver
                                    ids: solicitor_contact_ids
                                    children: ({contacts, loading}) ->
                                       if loading
                                          Loading size: "small", position: "center"
                                       else if _.isEmpty(contacts)
                                          span "aria-label": "Assignee, Not provided",
                                             span "aria-hidden": "true",
                                                "-"
                                       else
                                          names = _.pluck(contacts, "name")
                                          name_string = names.join (", ")
                                          span "aria-label": "Assignee #{name_string}",
                                             span "aria-hidden": "true",
                                                name_string
                  # else show the assignments from the contact data
                  else
                     assignees = ContactFormatMixin.assignee(data).join(", ")
                     span "aria-label": "Assignee, #{assignees}",
                        span "aria-hidden": "true",
                           if _.isEmpty(assignees) then "-" else span null, assignees


      unassigned:
         width: _WIDTHS.S
         row: (data) ->
            IsGated
               feature: "rm_assignments"
               children: (gated = {}) ->
                  # If using the new assignments api
                  if gated.show
                     PromiseResolverFactory
                        args: data
                        promise: (data) ->
                           Api.VOLUNTEER.MEMBERSHIP.get({
                              urlArgs:
                                 contact_id: data.id,
                                 type: "prospect"
                              params: { pool_type: "TEAM" }
                           })
                        render: ({ data, loading}) ->
                           if loading then Loading size: "small", position: "center"
                           else
                              span "aria-label": "Unassigned, #{_.isEmpty(data)}",
                                 span "aria-hidden": "true",
                                    _formatBooleanDisplay(_.isEmpty(data))
                  else
                     has_new_assignees = _.size(data?.assignees) > 0
                     has_old_assignee = ContactFormatMixin.giving(data, "assignee")
                     assigned = (has_new_assignees || has_old_assignee)
                     assigned_bool = _formatBooleanDisplay(!assigned)
                     span "aria-label": "Unassigned, #{assigned_bool}",
                        span "aria-hidden": "true",
                           _formatBooleanDisplay(!assigned)

      # TODO: Support new default fields w/ sorting
      constituency:
         width: _WIDTHS.L
         row: (data) ->
            val = ContactFormatMixin.role(data)
            div className: "contact-fixed-table--constituency", "aria-label": "Constituency, #{val || "Not Provided"}",
               span "aria-hidden": "true",
                  if val then span null, val else "-"

      comment:
         label: "Comment"
         width: _WIDTHS.XL
         row: (data) ->
            comment = data?.social?.comment || ""
            span "aria-label": "Comment, #{comment}",
               span "aria-hidden": "true",
                  ReadMore height: 45, comment

      date_added:
         label: "Date Added"
         width: _WIDTHS.S
         row: (data) ->
            date_added = if data.date_added then moment(data?.date_added).format("MMM Do YYYY") else ""
            div "aria-label": "Added on #{date_added || "-"}",
               span "aria-hidden" : "true",
                  date_added ||  "-"

      event_response:
         label: "Event Response"
         width: _WIDTHS.L
         row: (data) ->
            div className: "contact-fixed-table--event-responses",
               _.map data?.event_engagement_actions, (action) ->
                  div key: action, className: "contact-fixed-table--event-response",
                     EngagementIcon
                        className: "contact-fixed-table--event-response-icon"
                        size: 18
                        icon: action
                     span className: "contact-fixed-table--event-response-label",
                        getTabByEngagementAction(action)

      enriched_current_company:
         width: _WIDTHS.M
         row: (data) ->
            currentEnrichedEmployment = data?.enrichment?.employments.filter((e) -> e.status =="current" || e.status == null)
            div "aria-label": "Enriched Current Company",
               span "aria-hidden": "true",
               if currentEnrichedEmployment?.length
                  if currentEnrichedEmployment.some((i) -> i.source != "fullcontact")
                     _renderMostRecent(currentEnrichedEmployment.filter((e) -> e.source != "fullcontact"), "company_name")
                  else _renderMostRecent(currentEnrichedEmployment, "company_name")
               else "-"

      enriched_current_title:
         width: _WIDTHS.M
         row: (data) ->
            currentEnrichedEmployment = data?.enrichment?.employments.filter((e) -> e.status == "current" || e.status == null)
            div "aria-label": "Enriched Current Title",
               span "aria-hidden": "true",
               if currentEnrichedEmployment?.length
                  if currentEnrichedEmployment.some((i) -> i.source != "fullcontact")
                     _renderMostRecent(currentEnrichedEmployment.filter((e) -> e.source != "fullcontact"), "title")
                  else _renderMostRecent(currentEnrichedEmployment, "title")
               else "-"

      enriched_metro:
         width: _WIDTHS.M
         row: (data) ->
            div "aria-label": "Enriched Metro Area",
               span "aria-hidden": "true",
               if _.some(data?.enrichment?.contact_locations, (i) -> i.metro_area )
                  _renderMostRecent(data?.enrichment?.contact_locations, "metro_area")
               else "-"

      enriched_city:
         width: _WIDTHS.M
         row: (data) ->
            div "aria-label": "Enriched City",
               span "aria-hidden": "true",
               if _.some(data?.enrichment?.contact_locations, (i) -> i.city)
                  _renderMostRecent(data?.enrichment?.contact_locations, "city")
               else "-"


      enriched_state:
         width: _WIDTHS.M
         row: (data) ->
            div "aria-label": "Enriched State",
               span "aria-hidden": "true",
               if _.some(data?.enrichment?.contact_locations, (i) -> i.state_code)
                  _renderMostRecent(data?.enrichment?.contact_locations, "state_code")
               else "-"

      enriched_country:
         width: _WIDTHS.M
         row: (data) ->
            div "aria-label": "Enriched Country",
               span "aria-hidden": "true",
               if _.some(data?.enrichment?.contact_locations, (i) -> i.country)
                  _renderMostRecent(data?.enrichment?.contact_locations, "country")
               else "-"

      enriched_linkedin:
         width: _WIDTHS.M
         row: (data) ->
            url = _.findWhere(data?.enrichment?.social_profiles, {type: "linkedin"})?.url
            div "aria-label": "Enriched Linkedin URL",
               span "aria-hidden": "true",
               if url
                  Link
                     className: "fs-exclude-data"
                     target: "_blank"
                     href: url
                     title: "View Linkedin"
                     "View Linkedin"
               else
                  "-"

      enriched_facebook:
         width: _WIDTHS.M
         row: (data) ->
            url = _.findWhere(data?.enrichment?.social_profiles, {type: "facebook"})?.url
            div "aria-label": "Enriched Facebook URL",
               span "aria-hidden": "true",
               if url
                  Link
                     className: "fs-exclude-data"
                     href: url
                     target: "_blank"
                     title: "View Facebook"
                     "View Facebook"
               else
                  "-"


      enriched_twitter:
         width: _WIDTHS.M
         row: (data) ->
            url = _.findWhere(data?.enrichment?.social_profiles, {type: "twitter"})?.url
            div "aria-label": "Enriched Twitter URL",
               span "aria-hidden": "true",
               if url
                  Link
                     className: "fs-exclude-data"
                     target: "_blank"
                     href: url
                     title: "View Twitter"
                     "View Twitter"
               else
                  "-"

      career_moves_type:
         width: _WIDTHS.M
         row: (data) ->
            div "aria-label": "Career Moves Type",
               span "aria-hidden": "true",
               if data?.enrichment?.employment_events?.length
                  _renderMultiple(data?.enrichment?.employment_events, "type")
               else "-"

      career_moves_date:
         width: _WIDTHS.M
         row: (data) ->
            div "aria-label": "Career Moves Last File Date",
               span "aria-hidden": "true",
               if _.some(data?.enrichment?.employment_events, (i) -> i.recorded_at)
                  _renderMostRecent(data?.enrichment?.employment_events, "recorded_at", "recorded_at")
               else "-"

      career_moves_start_date:
         width: _WIDTHS.M
         row: (data) ->
            employments = data?.enrichment?.employments
            if employments
               for emp in employments
                  start_month = moment("#{emp.start_month}").format("MM")
                  start_year = emp.start_year
                  div "aria-label": "Career Moves Start Date",
                     span "aria-hidden": "true",
                     if emp.start_month && emp.source == "windfall"
                        "#{start_month || ""}/#{start_year || ""}"
                     else ""

      spacer:
         label: ""
         width: "auto"
         className: "contact-fixed-table--col-spacer"
         row: (data) ->
            ModalTrigger
               title: "Edit Columns"
               buttonType: "secondary"
               buttonClass: "contact-fixed-table--edit-button"
               modal: Modal({width: 660, animationClass: false}, ColumnSelector())
               Icon icon: "edit"
               "Edit Columns"
