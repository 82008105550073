import _ from "underscore";
import { useContext } from "react";
import { ModalHeaderWithActions, ModalBody, FormManager } from "@evertrue/et-components";
import ContactTagList from "apps/contact/components/contact-tag-list";
import FormField from "components/forms/form-field";
import Decorator from "clientDecorator";
import PropTypes from "prop-types";
import EverTrue from "app";
import { assignProspects } from "apps/portfolio-performance/portfolio-requests";
import SolicitorContactsResolver from "apps/portfolio-performance/resolvers/solicitor-contacts-resolver";
import AssignmentTitleDropdown from "apps/portfolio-performance/components/assignment-title-dropdown";
import TeamDropdown from "apps/portfolio-performance/components/team-dropdown";
import StageDropdown from "apps/portfolio-performance/components/stage-dropdown";
import { useIdentity } from "base/identity-resolver";
import { GlobalPortfolioContext } from "../contexts/global-portfolio-context";
import PortfolioMetaDataContext from "apps/portfolio-performance/contexts/portfolio-meta-data-context";


const propTypes = {
  team: PropTypes.object, // when in portfolio view this will be set, but not in solicitor view
  solicitor: PropTypes.object,
  close: PropTypes.func.isRequired,
  refreshData: PropTypes.func,
};

const ES_DELAY = 2000;

const AddProspectsToTeamModal = ({ team = {}, solicitor, close, refreshData }) => {
  const { user, org } = useIdentity();
  const { assignmentType } = useContext(GlobalPortfolioContext);
  const { stagesData } = useContext(PortfolioMetaDataContext);
  return (
    <FormManager
      disableFunc={({ selected = [], selectedStage = {}, selectedTeam }) => {
        // if team has been passed into the modal, we dont need to have a selectedTeam
        const has_team = !_.isEmpty(selectedTeam) || !_.isEmpty(team);
        return !selected.length || !selectedStage.value || !has_team;
      }}
      data={{ selected: [], selectedTeam: {}, selectedStage: {}, selectedTitle: {} }}
      onSubmit={(state) => {
        const stage = state.selectedStage.value;
        const title = state.selectedTitle.value;
        const team_to_save = !_.isEmpty(team) ? team : state.selectedTeam;
        const { id } = stagesData.find((item) => item.stage === state.selectedStage.value);
        const data = {
          teamId: team_to_save.id,
          data: {
            assignments: state.selected.map((contact) => ({
              prospect_contact_id: contact.id,
              stage,
              solicitor_contact_id: solicitor.id,
              title: title,
              ...(assignmentType === 'DXO' && { stage_id: id }),
            })),
          },
          assignmentType,
        };
        assignProspects(data).then(() => {
          close();
          EverTrue.Alert.success(`${state.selected.length}  ${state.selected.length === 1 ? 'Prospect' : 'Prospects'} Added`);
          if (_.isFunction(refreshData)) {
            setTimeout(() => refreshData(stage), ES_DELAY);
          }
        });
      }}
    >
      {({ state, setState, errors, isDisabled, handleSubmit }) => {
        const user_contact_id = Decorator.User.getContactId(user, org.id);
        const header_text = user_contact_id === solicitor.id ? "your" : `${solicitor.name}'s`;
        // if a team has been passed in as a prop, then we know what team to add to
        // if we don't have a team, then we need to make sure to select one
        const team_id = _.isEmpty(team) ? state.selectedTeam.id : team.id;
        const has_team = team_id || state.selectedTeam.id;

        const stage_group_id = state.selectedTeam.stage_group_id || team.stage_group_id;

        return (
          <>
            <ModalHeaderWithActions
              title={`Add Prospects to ${header_text} Portfolio`}
              closeModal={close}
              disableSubmit={isDisabled}
              onSubmit={() => {
                handleSubmit();
                close();
              }}
            />
            <ModalBody scrollable={false}>
              <FormField inlineLabel={false} label={`Select Team*`}>
                <TeamDropdown
                  value={{ value: team_id }}
                  onChange={(selectedTeam) => setState({ selectedTeam })}
                  disabled={!_.isEmpty(team)}
                />
              </FormField>

              <FormField inlineLabel={false} label={`Search for Prospects*`}>
                <div style={{ width: '415px' }}>
                  <SolicitorContactsResolver key={solicitor.id} solicitor_id={solicitor.id} team={state.selectedTeam}>
                    {({ contacts = [], loading }) => {
                      const prospect_map = _.indexBy(contacts, "id");
                      return (
                        <ContactTagList
                          disabled={!has_team}
                          contactsFilterFunction={(contact) => {
                            // prevent users from adding duplicates to this team
                            return !prospect_map[contact.id];
                          }}
                          selected={state.selected}
                          onSelect={(selected) => setState({ selected })}
                        />
                      );
                    }}
                  </SolicitorContactsResolver>
                </div>
              </FormField>

              <FormField inlineLabel={false} label="Prospect Stage*">
                {!stage_group_id && <div className="text-danger">Stages need to be configured for this team</div>}
                <StageDropdown
                  value={state.selectedStage}
                  multiple={false}
                  includeNoStatus={false}
                  stageGroupId={state.selectedTeam.stage_group_id || team.stage_group_id}
                  onChange={(selectedStage) => setState({ selectedStage })}
                  disabled={!has_team || !stage_group_id}
                />
              </FormField>

              <FormField inlineLabel={false} label="Assignment Title">
                <AssignmentTitleDropdown
                  value={state.selectedTitle}
                  onChange={(selectedTitle) => setState({ selectedTitle })}
                />
              </FormField>
            </ModalBody>
          </>
        );
      }}
    </FormManager>
  );
};

AddProspectsToTeamModal.propTypes = propTypes;
export default AddProspectsToTeamModal;
