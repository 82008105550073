module.exports = do ->
   _ = require("underscore").default
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   {i, ul, li} = ReactLibs.DOMFactories

   MAX_VISIBLE = 5
   NUM_CONTROL = 3
   PLACEHOLDER = "..."

   createComponent "Pagination",
      propTypes:
         page: ReactLibs.PropTypes.number
         total: ReactLibs.PropTypes.number # total number of pages
         onChange: ReactLibs.PropTypes.func.isRequired

      handlePageChange: (page) ->
         return unless _.isNumber(page)
         page = @toValidPage(page)
         unless page == @props.page
            @props.onChange?(page)

      toValidPage: (page) ->
         page = _.toNumber(page)
         page = Math.max(page, 1)
         page = Math.min(page, @toValidTotal(@props.total))

      toValidTotal: (total) ->
         total = Math.max(1, Math.ceil(total))

      getVisiblePages: ->
         if @props.total <= MAX_VISIBLE
            _.range(1, @props.total + 1)
         else if @props.page < NUM_CONTROL
            _.range(1, MAX_VISIBLE + 1)
         else
            max_value = Math.min((@props.page + NUM_CONTROL),  Math.ceil(@props.total)) + 1
            min_value = max_value - NUM_CONTROL - 1
            _.flatten [1, PLACEHOLDER, _.range(min_value, max_value)]

      render: ->
         if !@props.page || !@props.total then return null

         page = @toValidPage(@props.page)
         total = @toValidTotal(@props.total)
         if total == 1 then return null

         visible_pages = @getVisiblePages()
         has_placeholder = _.contains(visible_pages, PLACEHOLDER)

         prevClasses = classNames "pagination--item", "pagination--prev", {"is-disabled": page == 1}
         nextClasses = classNames "pagination--item", "pagination--next", {"is-disabled": page == total}

         ul className: classNames("pagination", @props.className),
            li
               className: prevClasses
               role: "button"
               "aria-disabled": if page == 1 then true else false
               tabIndex: if page == 1 then -1 else 0
               "aria-label": "previous page"
               onClick:(=> @handlePageChange(page - 1))
               onKeyPress: (event) =>
                  # enable tab navigation w/ keyboard for a11y
                  if event.key == "Enter" then @handlePageChange(page - 1)

               i className: "fa fa-fw fa-chevron-left"

            _.map visible_pages, (num) =>
               isDots = num == PLACEHOLDER
               li
                  role: "button"
                  "aria-label": "page #{num} of #{total}"
                  "aria-hidden": if isDots then true else false
                  tabIndex: if isDots then -1 else 0
                  key: num
                  className: classNames "pagination--item",
                     "pagination--placeholder": (num == PLACEHOLDER)
                     "pagination--initial": has_placeholder && num == 1
                     "pagination--first": num == 1 || (has_placeholder && num == @props.page)
                     "pagination--last": num == _.last(visible_pages)
                     "is-active": @props.page == num
                  onClick:(=> @handlePageChange(num))
                  onKeyPress: (event) =>
                     # enable tab navigation w/ keyboard for a11y
                     if event.key == "Enter" then @handlePageChange(num)
                  num

            li
               className: nextClasses
               onClick:(=> @handlePageChange(page + 1))
               role: "button"
               "aria-label": "Next Page"
               "aria-disabled": if page == total then true else false
               tabIndex: if page == total then -1 else 0
               onKeyPress: (event) =>
                  # enable tab navigation w/ keyboard for a11y
                  if event.key == "Enter" then @handlePageChange(page + 1)
               i className: "fa fa-fw fa-chevron-right"
