import PropTypes from "prop-types";
import Icon from "components/elements/icon";
import { Link } from "@evertrue/et-components";
import IsGated from "components/is-gated";
import VolunteerMembership from "./volunteer-membership";

const VolunteerAssignment = ({ assignment = {} }) => {
  const { name, solicitor_contact_id, membership } = assignment;
  return (
    <div className="assignee-template">
      <Icon icon="person" className="assignee-template--icon" />
      <div className="assignee-template--data">
        <div className="donor-info-name-row">
          {solicitor_contact_id ? (
            <Link href={`/contact/${solicitor_contact_id}`} title={`Link to ${name}'s profile`} data-refer="person">
              {name}
            </Link>
          ) : (
            name
          )}
          <IsGated feature="rm_key_prospect">
            {membership.key_prospect && <Icon icon="key-relationship" className="donor-info-key-relationship-icon" />}
          </IsGated>
        </div>
        <VolunteerMembership membership={membership} />
      </div>
    </div>
  );
};

VolunteerAssignment.propTypes = {
  assignment: PropTypes.object,
};

export default VolunteerAssignment;
