import { Component } from "react";
import PropTypes from "prop-types";
import _ from "underscore";
import { connect } from "@evertrue/et-flux";
import { Giving } from "clientDecorator";
import DNAStore from "base/dna/dna-store";
import FeatureStore from "apps/layout/stores/feature-store";
import ProfileCardHeader from "apps/profile/components/profile-card-header";
import GivingSummary from "apps/contact/components/profile/giving-data/giving-summary";
import CategorizedGivingHistory from "apps/contact/components/profile/giving-data/categorized-giving-history";
import { Icon, Loading, AdvancedCombobox } from "@evertrue/et-components";
import UserSettingsSource from "base/dna/user-settings-source";

const mapStateToProps = () => ({
  dna_giving_category: DNAStore.getUserGivingCategory(),
  org_fiscal_mm_dd: DNAStore.getByKey("ET.Org.FiscalYear"),
  has_recurring_gifts: FeatureStore.hasFeature("recurring_payment_field"),
});

class ProfileGivingController extends Component {
  static propTypes = {
    contact: PropTypes.object,
    has_recurring_gifts: PropTypes.bool,
    dna_giving_category: PropTypes.string,
    org_fiscal_mm_dd: PropTypes.string,
  };

  static defaultProps = {
    contact: {},
  };

  state = {
    selected_category: "Total Giving",
  };

  componentDidMount() {
    this.setSelectedCategory();
  }

  componentDidUpdate(prevProps) {
    if (
      !_.isEqual(prevProps.contact.id, this.props.contact.id) ||
      !_.isEqual(prevProps.dna_giving_category, this.props.dna_giving_category)
    ) {
      this.setSelectedCategory();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  handleUpdateCategory = (val) => {
    clearTimeout(this.timeout);
    this.setState({ selected_category: val.value, loading: true });
    UserSettingsSource.updateGivingCategory(val.value);
    this.timeout = _.wait(300, () => this.setState({ loading: false }));
  };

  setSelectedCategory() {
    const userCategories = _.pluck(this.props.contact.giving_categories, "label");
    if (_.findWhere(userCategories, { value: this.props.dna_giving_category })) {
      this.setState({ selected_category: this.props.dna_giving_category });
    } else this.setState({ selected_category: "Total Giving" });
  }

  renderTitle() {
    return (
      <div>
        <span>Giving History</span>
        {this.renderRecurringGiftHeader()}
      </div>
    );
  }

  renderRecurringGiftHeader() {
    const {
      has_recurring_gifts,
      contact: { giving: { has_recurring_payments: { value } = {} } = {}, giving_categories } = {},
    } = this.props;
    const has_recurring_in_total = value;
    const total_is_selected = this.state.selected_category === "Total Giving";
    const categorized_recurring_gifts = _.filter(giving_categories, (cat_obj) => {
      return cat_obj.has_recurring_payments && cat_obj.has_recurring_payments.value;
    });
    const has_recurring_in_selected_category = !!_.find(categorized_recurring_gifts, (cat_obj) => {
      return cat_obj.label && cat_obj.label.value === this.state.selected_category;
    });

    if (has_recurring_gifts && ((has_recurring_in_total && total_is_selected) || has_recurring_in_selected_category)) {
      return (
        <span className="profile-giving-controller--recurring-gift">
          <Icon icon="recurring" />
          <span className="profile-giving-controller--recurring-text">Recurring Payments Set</span>
        </span>
      );
    }
  }

  renderCategoriesHeader(categories, labels) {
    return (
      <AdvancedCombobox
        className="advanced-combobox-secondary profile-giving-controller--category-dropdown"
        value={{ value: this.state.selected_category }}
        searchable={false}
        options={[{ value: "Total Giving", label: "Total Giving" }].concat(labels)}
        onChange={this.handleUpdateCategory}
      />
    );
  }

  renderGivingModule(givingHistory) {
    return <CategorizedGivingHistory giving={givingHistory} />;
  }

  render() {
    const { categories } = Giving.getProfileGiving(this.props.contact, this.props.org_fiscal_mm_dd) || {};
    const labels = _.map(categories, (cat) => ({
      label: cat.label,
      value: cat.label,
      disabled:
        cat.history.length === 0 && !cat.total_pledge_balance && !cat.has_recurring_payments && !cat.lifetime_amount,
    }));

    const category = this.state.selected_category === "Total Giving" ? null : this.state.selected_category;
    const giving = Giving.getCategoryGiving(this.props.contact, category, this.props.org_fiscal_mm_dd);

    return (
      <div className="profile-giving-controller">
        <ProfileCardHeader className="contact-profile--card-header" title={this.renderTitle()}>
          {this.renderCategoriesHeader(categories, labels)}
        </ProfileCardHeader>
        {this.state.loading ? <Loading position="center" text="Loading..." /> : null}
        <GivingSummary giving={giving} />
        <div className="giving-module fs-exclude-data">{this.renderGivingModule(giving)}</div>
      </div>
    );
  }
}

export default connect(ProfileGivingController, [DNAStore], mapStateToProps);
