import _ from "underscore";
import PropTypes from "prop-types";
import { useContext } from "react";
import moment from "moment";
import Decorator from "@evertrue/client-decorator";
import { useCachedUsersByContactIdResolver } from "apps/portfolio-performance/contexts/cached-contact-user-context";
import { Loading, FlexTable, FlexTableSortHeaderCell, Avatar, Link, Icon } from "@evertrue/et-components";
import SolicitorDataContext from "apps/portfolio-performance/contexts/solicitor-data-context";
import SolicitorActionMenu from "apps/portfolio-performance/components/solicitor-action-menu";

const SolicitorManagementTable = (props) => {
  const { refreshData } = props;
  const { solicitor_assignments, solicitor_proposals } = useContext(SolicitorDataContext);

  return (
    <div
      className="solicitor-management--table-wrap"
      style={{
        top: "185px",
      }}
    >
      <FlexTable
        caption="Solicitors"
        loading={props.loading}
        limit={props.limit}
        total={props.total}
        page={props.page}
        data={props.solicitors}
        columns={[
          {
            header: (
              <FlexTableSortHeaderCell
                onClick={() => props.onSort("name")}
                sortOrder={props.sort_order}
                isSorted={props.sort_prop === "name"}
              >
                Solicitor | Remote ID
              </FlexTableSortHeaderCell>
            ),
            Cell: (contact) => {
              const avatar_url = Decorator.Profile.getAvatar(contact);
              const remote_id_obj = _.findWhere(contact.identities, { type: "remote_id" }) || {};
              return (
                <td className="et--flex-table--cell solicitor-mgmt-row--solicitor">
                  <Avatar className="solicitor-mgmt-row--avatar" name={contact.name} url={avatar_url} />
                  <div>
                    <Link
                      className="fs-exclude-data"
                      href={`/contact/${contact.id}`}
                      title={`profile of ${contact.name}`}
                    >
                      {contact.name}
                    </Link>
                    <div>{remote_id_obj.value}</div>
                  </div>
                </td>
              );
            },
          },
          {
            header: "# Prospects Assigned",
            Cell: (contact) => {
              const assignments = solicitor_assignments[contact.id];
              let assignment_count = 0;
              _.each(assignments, (assignment) => {
                if (assignment.assignment_count) {
                  assignment_count += assignment.assignment_count;
                }
              });
              return <td className="et--flex-table--cell solicitor-mgmt-row--count">{assignment_count}</td>;
            },
          },
          {
            header: "# Proposal Total",
            Cell: (contact = {}) => {
              const proposal_total = solicitor_proposals[contact.id];
              return (
                <td className="et--flex-table--cell solicitor-mgmt-row--cell">
                  <span className="solicitor-mgmt-row--count">{proposal_total || 0}</span>
                </td>
              );
            },
          },
          {
            header: "Last Login",
            Cell: (contact = {}) => {
              const { user = {}, loading } = useCachedUsersByContactIdResolver(contact.id);
              return (
                <td className="et--flex-table--cell solicitor-mgmt-row--cell">
                  {loading ? (
                    <Loading size="small" />
                  ) : user.most_recent_session ? (
                    moment(user.most_recent_session).format("MM/DD/YYYY [at] h:mm a")
                  ) : (
                    "-"
                  )}
                </td>
              );
            },
          },
          {
            header: "EverTrue User",
            Cell: (contact) => {
              const { user, loading } = useCachedUsersByContactIdResolver(contact.id);
              return (
                <td className="et--flex-table--cell solicitor-mgmt-row--cell">
                  {loading ? (
                    <Loading size="small" />
                  ) : !_.isEmpty(user) ? (
                    <Icon size={1} icon="success-circle" className="icon-success" />
                  ) : (
                    <Icon size={1} icon="cancel-circle" className="icon-error" />
                  )}
                </td>
              );
            },
          },
          {
            header: "Actions",
            Cell: (contact) => {
              const { user } = useCachedUsersByContactIdResolver(contact.id);
              return (
                <td className="et--flex-table--cell">
                  <SolicitorActionMenu solicitor={contact} user={user} refreshData={refreshData} />
                </td>
              );
            },
          },
        ]}
        handlePageChange={(page) => {
          props.onPageChange(page);
        }}
      />
    </div>
  );
};

SolicitorManagementTable.propTypes = {
  loading: PropTypes.bool,
  limit: PropTypes.number,
  total: PropTypes.number,
  page: PropTypes.number,
  solicitors: PropTypes.array,
  sort_prop: PropTypes.string,
  sort_order: PropTypes.bool,
  onSort: PropTypes.func,
  onPageChange: PropTypes.func,
  refreshData: PropTypes.func,
};

export default SolicitorManagementTable;
