const _ = require("underscore").default;
const EverTrue = require("app");
const Api = require("entities/helpers/api");
const { createSource } = require("@evertrue/et-flux");
const Navigator = require("base/navigator");
const { createPool } = require("apps/volunteers/utils");
const get = require("lodash.get");

const _defaultErrorMessage = "Error, please contact genius@evertrue.com for assistance";
const _trackAction = (type, object) => EverTrue.track.set("pool_action", _.extend({ type, object: "pool" }, object));
let _request = null;

const fetchAllOrgPools = (params = { limit: 12 }, initialFetch = false) => {
  if (_request && typeof _request.abort === "function") {
    _request.abort();
  }

  return Api.VOLUNTEER.POOLS.get({
    params,
    beforeSend(xhr) {
      _request = xhr;
    },
  });
};

module.exports = createSource("PoolsSource", {
  actions: {
    loading: true,
    clearPools: true,
    deleted: true,
    paginate(page, initialfetch) {
      this.require(_.isNumber(page), "page should be a number");
      this.require(
        _.isBoolean(initialfetch) || _.isUndefined(initialfetch),
        "initialFetch should be bool or undefined"
      );
    },
    filter(filters) {
      return this.require(_.isObject(filters), "page should be an object");
    },
    fetchedPools(pools, initialFetch) {
      this.require(_.isObject(pools), "pools should be a paged object");
      return this.require(_.isBoolean(initialFetch), "initialfetch should be boolean");
    },
    fetchedPoolById(id, pool) {
      return this.require(_.isNumber(id) && _.isObject(pool), "id should be string and pool should be object");
    },
    fetchedGivingCategories(categories) {
      return this.require(_.isArray(categories), "categories should be an array");
    },
    loadingCategories(loading) {
      return this.require(_.isBoolean(loading), "loading should be a boolean");
    },
    fetchIfNotCached(id) {
      return this.require(_.isNumber(id), "id should be number");
    },
    fetchedPoolByIdError(id) {
      return this.require(_.isNumber(id), "id should be number");
    },
    setLimit(num) {
      return this.require(_.isNumber(num), "num should be number");
    },
    bulkFetchIfNotCached: true,
    loadingByIds: true,
    fetchedMorePools: true,
  },

  promise: {
    fetchPoolsForSelector(params) {
      return Api.VOLUNTEER.POOLS.get({
        params,
      });
    },

    bulkFetch(ids) {
      const requests = _.map(ids, (id) =>
        Api.VOLUNTEER.POOLS.get({
          urlExtend: `/${id}`,
        })
      );
      return Promise.all(requests);
    },
    create(data) {
      return createPool(data)
        .then((resp) => {
          return resp.id;
        })
        .catch((xhr, textStatus, error) => {
          const {
            responseJSON: { message = "" },
          } = xhr;

          EverTrue.Alert.error(message || _defaultErrorMessage);
          return Promise.reject(); // stop the chain
        });
    },
  },

  api: {
    fetchPools(params, initialFetch = false) {
      this.actions.loading(true);
      fetchAllOrgPools(params, initialFetch)
        .then((pools) => {
          this.actions.loading(false);
          this.actions.fetchedPools(pools, initialFetch);
        })
        .catch(() => {
          this.actions.loading(false);
        });
    },

    fetchMorePools(params) {
      this.actions.loading(true);
      fetchAllOrgPools(params)
        .then((pools) => {
          this.actions.loading(false);
          // fire a different action that merges response w/ old data instead of replacing
          this.actions.fetchedMorePools(pools);
        })
        .catch(() => {
          this.actions.loading(false);
        });
    },

    fetchPoolById(id, disableAlerts) {
      Api.VOLUNTEER.POOLS.get({
        urlExtend: `/${id}`,
        disableAlerts,
        success: (pool) => {
          this.actions.fetchedPoolById(id, pool);
        },
        error: () => {
          this.actions.fetchedPoolByIdError(id);
        },
      });
    },

    bulkFetchPoolsById(pool_ids) {
      this.actions.loadingByIds(pool_ids, true);
      Api.VOLUNTEER.POOLS.post({
        urlExtend: `/bulk/get`,
        disableAlerts: true,
        data: _.jsonStringify({ pool_ids }),
        success: (pools = []) => {
          pools.forEach((pool) => {
            this.actions.fetchedPoolById(pool.id, pool);
          });
          // fire off original ids instead of those from response b/c
          // ES has bad data (deleted pool_id in assignment docs)
          this.actions.loadingByIds(pool_ids, false);
        },
        error: () => {
          pool_ids.forEach((id) => {
            this.actions.fetchedPoolById(id);
          });
          this.actions.loadingByIds(pool_ids, false);
        },
      });
    },

    paginate(page, initialFetch) {
      this.actions.paginate(page, initialFetch);
    },

    search(name) {
      this.actions.filter({ name });
    },

    save(data = {}) {
      if (data.id) {
        this.update(data);
      } else {
        this.promise.create(data);
      }
    },

    // Triggers a websocket event which reloads
    update(data) {
      _trackAction("update_pool");
      Api.VOLUNTEER.POOLS.put({
        urlExtend: `/${data.id}`,
        data: _.jsonStringify(data),
        success() {
          EverTrue.Alert.success("Pool updated.");
        },
        error(xhr, textStatus, error) {
          if (xhr.status === 422 && _.contains(error != null ? error.name : undefined, "has already been taken")) {
            EverTrue.Alert.error(`A pool named '${data.name}' already exists. Please create a unique name.`);
          } else {
            EverTrue.Alert.error(_defaultErrorMessage);
          }
        },
      });
    },

    // Triggers a websocket event which reloads

    // Triggers a websocket event which reloads
    delete(id) {
      _trackAction("delete_pool");
      Api.VOLUNTEER.POOLS.delete({
        urlExtend: `/${id}`,
      });
    },

    deleteAndNavigate(id, route_to) {
      _trackAction("delete_pool");
      Api.VOLUNTEER.POOLS.delete({
        urlExtend: `/${id}`,
        success: () => {
          EverTrue.Alert.success("Pool deleted.");
          this.actions.clearPools();
          if (route_to) {
            Navigator(route_to);
          }
        },
      });
    },

    clear() {
      this.actions.clearPools();
    },

    fetchGivingCategories() {
      this.actions.loadingCategories(true);
      const field_name = "giving_categories.label.untouched";
      const query = { facet: [{ field: field_name }] };
      Api.CONTACTS.FACETS.post({
        data: _.jsonStringify(query),
        success: (resp = {}) => {
          this.actions.loadingCategories(false);
          this.actions.fetchedGivingCategories(get(resp, `facets["${field_name}"]`, []));
        },
        error: () => {
          this.actions.loadingCategories(false);
        },
      });
    },

    fetchIfNotCached(pool_id) {
      this.actions.fetchIfNotCached(pool_id);
    },

    setLimit(num) {
      this.actions.setLimit(num);
    },

    bulkFetchIfNotCached(ids) {
      this.actions.bulkFetchIfNotCached(ids);
    },
  },
});
