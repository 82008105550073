module.exports = do ->
   $ = require("jquery")
   React = require("react")
   EverTrue = require("app")
   BaseRouter = require("base/base-router")
   LoginSource = require("apps/login/sources/login-source")
   {createFactory} = require("base/new-utils")
   Login = createFactory(require("apps/login/components/login").default)
   LoginWaitController = require("apps/login/controllers/login-wait-controller")
   LoginSelectorController = require("apps/login/controllers/login-selection-controller")
   StatusController = require("apps/layout/controllers/status-controller")



   class LoginRouter extends BaseRouter
      routes:
         "login": "login_page"
         "lids/access": "login_wait"
         "login/org": "org"
         "login/invite/:token": "invite"
         "status": "status"
         "logout": "logout"

      login_page: ->
         if EverTrue.request "isAuthorized?"
            EverTrue.Navigator "/", true
            return

         # Render Login Page
         current_view = Login()
         EverTrue.mount(current_view)

         # Delay the tracking of this event for redirects from login

      # Manage Invite Token From Email Invites
      invite: (token) ->
         LoginSource.setInviteToken(token)
         EverTrue.track.setToken()
         EverTrue.Navigator("/login", true)

      login_wait: ->
         EverTrue.track.setToken()
         LoginSource.fetchInviteToken()

         params = $.deparam(window.location.search.slice(1))
         LoginSource.fetchAccessToken(params)

         EverTrue.mount LoginWaitController()

      org: ->
         EverTrue.mount LoginSelectorController()

      status: ->
         EverTrue.mount StatusController()

      logout: ->
         EverTrue.execute "setPageReferrer"
         EverTrue.execute "logout"
