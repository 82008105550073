import _ from "underscore";
import EverTrue from "app";
import { createSource } from "@evertrue/et-flux";
import Api from "entities/helpers/api";
import str from "underscore.string";

const _trackAction = (type, object) =>
  EverTrue.track.set("pool_action", {
    type,
    object: "volunteers",
    ...object,
  });

const SolicitorInviteSource = createSource("SolicitorInviteSource", {
  actions: {
    loading: true,
    fetchedInvites(res, contact_ids) {
      this.require(_.isArray(res), "res must be an Array");
      this.require(_.isArray(contact_ids), "contact_ids must be an Array");
    },
    sendAppInviteSuccess(res) {
      this.require(_.isObject(res) || _.isUndefined(res), "res should be object or undefined");
    },
    fetchIfNotCached(ids) {
      this.require(Array.isArray(ids), "ids must be an Array");
    },
  },

  cancelAppInvite(invite_id) {
    return Api.AUTH.AFFIL_INVITES.delete({
      urlExtend: `/${invite_id}`,
      data: _.jsonStringify({ app: "volunteers" }),
    });
  },

  promise: {
    async sendAppInvite(contact_data, role_name, disable_alerts) {
      _trackAction("invite");

      const role = await createOrGetRoles(role_name);
      const data = {
        ...contact_data,
        app: "volunteers",
        role_ids: _.makeArray(role.id),
      };

      return Api.AUTH.AFFIL_INVITES.post({
        data: _.jsonStringify(data),
        ...(disable_alerts && { disableAlerts: true }),
      })
        .then(res => {
          this.actions.sendAppInviteSuccess(res, res.contact_id);
        })
        .catch(error => error);
    },

    async sendSSOAppInvite(contact_data, role_name) {
      _trackAction("invite");
      const role = await createOrGetRoles(role_name);
      const data = {
        affiliation_invitation: {
          ...contact_data,
          role_name: role.name,
          app: "volunteers",
        },
      };

      return Api.AUTH.AFFIL_INVITES.post({
        data: _.jsonStringify(data),
        params: { type: "volunteers" },
      })
        .then(res => {
          this.actions.sendAppInviteSuccess(res, res.contact_id);
        })
        .catch(err => err);
    },
  },

  api: {
    async sendAppInvite(contact_data, role_name) {
      await this.promise.sendAppInvite(contact_data, role_name);
      EverTrue.Alert.success("Invite successfully sent");
    },

    async sendSSOAppInvite(contact_data, role_name) {
      await this.promise.sendSSOAppInvite(contact_data, role_name);
      EverTrue.Alert.success("Invite successfully sent");
    },

    fetch(contact_ids) {
      Api.AUTH.AFFIL_INVITES.post({
        urlExtend: "/search",
        params: {
          app: "volunteers",
          limit: 200,
        },
        data: _.jsonStringify({
          contact_id: contact_ids,
          role_name: ["Volunteer", "Volunteer Lead"],
        }),
        success: ({ affiliation_invitations }) => {
          this.actions.fetchedInvites(affiliation_invitations, contact_ids);
        },
      });
    },

    async resendAppInvite(email, role_name, invite_id) {
      _trackAction("invite");
      await this.cancelAppInvite(invite_id);
      this.sendAppInvite(email, role_name);
    },

    async cancelInvite(invite = {}) {
      await this.cancelAppInvite(invite.id);
      this.actions.sendAppInviteSuccess(undefined, invite.contact_id);
    },

    fetchIfNotCached(ids) {
      this.actions.fetchIfNotCached(ids);
    },
  },
});

function createVolunteerRoles() {
  const requests = ["Volunteer", "Volunteer Lead"].map(role_name =>
    Api.AUTH.ROLES.post({
      data: _.jsonStringify({
        can_see_private_data: false,
        default: false,
        name: role_name,
      }),
    })
  );
  return Promise.all(requests);
}

function createOrGetRoles(role_name) {
  return new Promise((resolve, reject) => {
    const title_case_role_name = str.titleize(role_name || "Volunteer");
    const role = _.findWhere(EverTrue.store.org.get("roles"), { name: title_case_role_name });

    if (_.isEmpty(role)) {
      return createVolunteerRoles()
        .then(roles => {
          const vol_role = _.findWhere(roles, { name: title_case_role_name });

          if (vol_role) resolve(vol_role);
          else {
            reject("error");
            EverTrue.Alert.error("Could not send invite because of role error.");
          }
        })
        .catch(() => {
          EverTrue.Alert.error("Could not send invite because of role error.");
          reject("error");
        });
    }
    return resolve(role);
  });
}

export default SolicitorInviteSource;
