module.exports = do ->
   React = require("react")
   {div, span} = ReactLibs.DOMFactories
   {createComponent} = require("@evertrue/et-flux")
   Decorator = require("clientDecorator")
   {object, bool} = ReactLibs.PropTypes
   EngagementCard = require("apps/profile/components/engagement-card")
   EngagementIcon = require("apps/profile/components/engagement-icon")
   {getVerbByEngagementType} = require("apps/events/utils/engagement-config")
   {createFactory} = require("base/new-utils")
   Link = createFactory(require("@evertrue/et-components").Link)

   createComponent "EngagementCardEvent",
      propTypes:
         event_engagement: object
         contact: object
         show_contents: bool

      getDefaultProps: ->
         event_engagement: {}
         contact: {}

      renderAction: ->
         getVerbByEngagementType(@props.event_engagement?.engagement_action)

      renderName: ->
         profile_link = @props.social?.profile?.public_profile_url
         name = Decorator.Contacts.getFullName(@props.contact)

         Link
            className: "fs-exclude-data"
            title: "profile for #{name}"
            href: "#{profile_link || "#"}"
            target: "_BLANK"
            Decorator.Contacts.getFullName(@props.contact)

      renderEventName: ->
         id = @props.event_engagement?.event?.id
         link = if id then "/events/" + id else "#"
         Link
            title: "event"
            className: "engagement-card-event--name fs-exclude-data",
            href: link
            @props.event_engagement?.event?.name || "An Event"

      renderTitle: ->
         div null,
            @renderName()
            @renderAction()
            @renderEventName()

      render: ->
         {event, engaged_at, engagement_action} = @props.event_engagement || {}
         {description, source} = event || {}

         icon = EngagementIcon
            size: "40px"
            icon: engagement_action
            network: source

         EngagementCard
            className: "engagement-card-event"
            title: @renderTitle()
            contents: if @props.show_contents then span className: "fs-exclude-data", description
            date: engaged_at
            icon: icon
