import Api from "entities/helpers/api";
import LocalApi from "entities/helpers/local-api";
import ErrorLogger from "entities/helpers/error-log-helper";

export const fetchFromGraphql = async (query, variables, queryName = null, local = false) => {
  const api = local ? LocalApi : Api;
  try {
    const { data, error } = await api.GRAPHQL.GRAPHQL.post({
      data: JSON.stringify({
        operationName: null,
        query,
        variables,
      }),
    });

    if (error) throw error;
    if (local) console.log("LOCAL DATA", data);
    return data;
  } catch (error) {
    const { errors } = error.responseJSON; // because of the Api request manager

    ErrorLogger.error("GQL Error", { extra: { error: errors, page_name: window.location.pathname } });

    return queryName ? { queryName } : {};
  }
};

export const gqlCall = (query, variables) =>
  Api.GRAPHQL.GRAPHQL.post({
    data: JSON.stringify({
      operationName: null,
      query: String.raw`${query}`,
      variables,
    }),
  });
