import { trackJourneysAction } from "apps/journeys/utils/helpers/mixpanel";
import { Button, useOpenClose } from "@evertrue/et-components";
import { formatDateFromTimestampAndAddMilliseconds } from "apps/journeys/utils/helpers/dates";
import ConfirmModal from "apps/settings/cadence-settings/confirm-pause-modal";
import useJourneyTimeoutsApi from "apps/journeys/hooks/use-journey-timeouts-api";

const RESUME_PAUSE_TITLE = "Resume Cadences and Tasks";
const RESUME_PAUSE_BODY =
  "Are you ready to come back? Your cadences and tasks will resume where you left off, and we'll reactivate your tasks starting with those due today.";
const RESUME_PAUSE_ACTION = "Resume";

const JourneyPauseContainer = () => {
  const { inProgressJourneyTimeout, handleResume } = useJourneyTimeoutsApi();
  const [isModalOpen, openModal, closeModal] = useOpenClose();

  const resumeAction = () => {
    trackJourneysAction("end_cadence_pause");
    handleResume();
    closeModal();
  };
  return (
    <>
      {inProgressJourneyTimeout && (
        <div className="journeys-pause-container">
          <div>
            <h5 className="bold-text">
              {`Your cadences and tasks are paused until ${formatDateFromTimestampAndAddMilliseconds(
                inProgressJourneyTimeout.pause_end
              )}`}
            </h5>
            <span>We hope you are having a restful break!</span>
          </div>
          <div>
            <Button type="secondary" onClick={openModal}>
              Resume Cadences and Tasks
            </Button>
            <Button
              href="settings/cadence"
              onClick={() => {
                trackJourneysAction("edit_cadence_pause");
              }}
            >
              Edit Pause
            </Button>
          </div>
        </div>
      )}
      <ConfirmModal
        title={RESUME_PAUSE_TITLE}
        body={RESUME_PAUSE_BODY}
        handleAction={resumeAction}
        actionName={RESUME_PAUSE_ACTION}
        isOpen={isModalOpen}
        onClose={closeModal}
      />
    </>
  );
};

export default JourneyPauseContainer;
