import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "@evertrue/et-flux";
import PoolsSource from "apps/volunteers/sources/pools-source";
import PoolsStore from "apps/volunteers/stores/pools-store";
import _ from "underscore";
import { POOL_FETCH_LIMIT } from "apps/volunteers/constants";

const mapStateToProps = () => ({
  pools: PoolsStore.getPoolsByIds(),
  idsLoading: PoolsStore.getIdsLoading(),
  total: PoolsStore.getPoolsSearchTotal(),
  loading: PoolsStore.getLoading(),
});

class PoolsResolver extends Component {
  static propTypes = {
    ids: PropTypes.array,
    render: PropTypes.func,
    pools: PropTypes.object,
    fetchBulk: PropTypes.bool,
    idsLoading: PropTypes.array,
    total: PropTypes.number,
    loading: PropTypes.bool,
    children: PropTypes.any,
  };

  static defaultProps = {
    ids: [],
    idsLoading: [],
  };

  componentDidMount() {
    if (this.props.fetchBulk) {
      PoolsSource.fetchPools({ limit: POOL_FETCH_LIMIT }, true);
    } else if (this.props.ids.length) {
      this.fetchPoolsForIds(this.props.ids);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.ids.length) {
      const new_ids = _.difference(this.props.ids, prevProps.ids);
      if (new_ids.length) {
        this.fetchPoolsForIds(new_ids);
      }
    }
  }

  fetchPoolsForIds(ids) {
    PoolsSource.bulkFetchIfNotCached(ids);
  }

  loadMore = () => {
    PoolsSource.fetchMorePools({ limit: 50, offset: Object.keys(this.props.pools).length });
  };

  render() {
    const { render, children, ids } = this.props;
    const fetched_pools = {};
    if (ids.length) {
      ids.forEach(id => {
        if (this.props.pools[id]) {
          fetched_pools[id] = this.props.pools[id];
        }
      });
    }
    const data = {
      // if we've passed in ids get specific fetched pools, otherwise return all pools
      pools: !_.isEmpty(fetched_pools) ? fetched_pools : this.props.pools,
      idsLoading: this.props.idsLoading,
      total: this.props.total,
      loadMore: this.loadMore,
      loading: this.props.loading,
    };
    return render ? render(data) : children(data);
  }
}

export default connect(
  PoolsResolver,
  [PoolsStore],
  mapStateToProps
);
