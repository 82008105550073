import _ from "underscore";
import { Component } from "react";
import { connect } from "@evertrue/et-flux";
import PropTypes from "prop-types";
import classNames from "classnames";
import LayoutSource from "apps/layout/sources/layout-source";
import LayoutStore from "apps/layout/stores/layout-store";
import WindowOverflowMixin from "mixins/window-overflow-mixin";
import { LinkButton, Link, Icon } from "@evertrue/et-components";

const mapStateToProps = () => ({
  nav_is_collapsed: LayoutStore.getNavState(),
});

class NavigationSubItem extends Component {
  static propTypes = {
    label: PropTypes.string,
    href: PropTypes.string,
    icon: PropTypes.string,
    onClick: PropTypes.func,
    nav_is_collapsed: PropTypes.bool,
    isActive: PropTypes.bool,
    children: PropTypes.any,
    className: PropTypes.string,
    iconKit: PropTypes.string,
  };

  static defaultProps = {
    referrer: "default",
    iconKit: "gt",
    onClick: _.noop,
  };

  state = { tooltip_key: undefined };

  handleMouseEnter = () => {
    if (this.props.nav_is_collapsed) {
      const overlay = <div className="base-nav-sub-item--tooltip">{this.props.label}</div>;
      const dom_props = _.extend(WindowOverflowMixin.getDOMProperties(this), { key: _.randomKey() });
      this.setState({ tooltip_key: dom_props.key });
      LayoutSource.overlay(overlay, dom_props);
    }
  };

  handleMouseLeave = () => {
    if (this.props.nav_is_collapsed) {
      return LayoutSource.removeOverlay(this.state.tooltip_key);
    }
  };

  handleKeyDown = (event) => {
    if (event.which === 13) {
      this.props.onClick();
    }
  };

  handleClick = (e) => {
    this.props.onClick(e);
  };

  render() {
    if (this.props.href) {
      return (
        <Link
          href={this.props.href}
          title={this.props.label}
          onClick={this.handleClick}
          onMouseOver={this.handleMouseEnter}
          onMouseOut={this.handleMouseLeave}
          onKeyDown={this.handleKeyDown}
          className={classNames("base-nav-sub-item", this.props.className, { "is-active": this.props.isActive })}
        >
          {this.props.icon ? (
            <Icon className="base-nav-sub-item--icon" icon={this.props.icon} iconKit={this.props.iconKit} />
          ) : undefined}
          <span className="base-nav-sub-item--label">{this.props.label}</span>
          {this.props.children}
        </Link>
      );
    } else {
      return (
        <LinkButton
          title={this.props.label}
          onClick={this.handleClick}
          onMouseOver={this.handleMouseEnter}
          onMouseOut={this.handleMouseLeave}
          onKeyDown={this.handleKeyDown}
          value={this.props.label}
          className={classNames("base-nav-sub-item", this.props.className, { "is-active": this.props.isActive })}
        >
          {this.props.icon ? (
            <Icon className="base-nav-sub-item--icon" icon={this.props.icon} iconKit={this.props.iconKit} />
          ) : undefined}
          <span className="base-nav-sub-item--label">{this.props.label}</span>
          {this.props.children}
        </LinkButton>
      );
    }
  }
}

export default connect(NavigationSubItem, [LayoutStore], mapStateToProps);
