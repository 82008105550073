import PropTypes from "prop-types";
import { useRef, useEffect } from "react";
import DrawerHeader from "./drawerHeader";
import classNames from "classnames"

const useOutsideClick = (ref, handleOutsideClick) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        // handleOutsideClick(); BUGGY WHEN WORKING WITH ListBoxSelect because ListBoxSelect items are not nested inside the drawer component, they are at top of page. So clicking them causes minimization.
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, handleOutsideClick]);
};

export const Drawer = ({ children, isOpen, isMinimized, className, title, close, toggleMinimize, scrollable = false}) => {
  const wrapperRef = useRef(null);

  const handleOutsideClick = () => {
    if (!isMinimized) toggleMinimize();
  };

  useOutsideClick(wrapperRef, handleOutsideClick);

  if (isOpen) {
    return (
      <>
        <div ref={wrapperRef} className={`drawer  ${isMinimized ? "minimized" : ""} ${className}`}>
          {title && <DrawerHeader title={title} isMinimized={isMinimized} onMinimize={toggleMinimize} close={close} />}
          <div data-testid="drawer-content" className={classNames(isMinimized ? "drawer--content-hidden" : "drawer--content", { "scrollable": scrollable}, className)}>
            {children}
          </div>
        </div>
      </>
    );
  }
  return null;
};

Drawer.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  isMinimized: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  close: PropTypes.func,
  toggleMinimize: PropTypes.func,
  scrollable: PropTypes.bool,
};

Drawer.defaultProps = {
  children: null,
  isOpen: false,
  isMinimized: false,
  className: "",
  close: () => {},
  toggleMinimize: () => {},
  childProps: {},
};

export default Drawer;
