module.exports = do ->
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {div, span} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   Icon = createFactory (require("components/elements/icon").default)
   Link = createFactory(require("@evertrue/et-components").Link)


   createComponent "EmailTemplate",
      propTypes:
         data: ReactLibs.PropTypes.object
         hideIcon: ReactLibs.PropTypes.bool
         onClick: ReactLibs.PropTypes.func

      render: ->
         div className: "email-template",
            if @props.hideIcon then div className: "email-template--no-icon"
            else Icon icon: "email", className: "email-template--icon"

            if !@props.data?.email
               div className: "email-template--data is-empty",
                  "Not provided"
            else
               div className: "email-template--data",
                  div className: "email-template--label",
                     @props.data?.type || "Other"
                     if @props.data?.primary then " (Primary)"

                  Link title: "send email", href: "mailto:#{@props.data?.email}", "data-bypass": true, onClick: @props.onClick,
                     span className: "fs-exclude-data", @props.data?.email
