import { PropTypes } from "base/react-libs";
import { Icon, Button } from "@evertrue/et-components";
import EverTrue from "app";
import { useEffect, useState } from "react";

const TripsAndMeetingsForNextFive = ({ tripsAndMeetingDetailsForNextFive }) => {
  const [tripMessage, setTripMessage] = useState();
  const [meetingMessage, setMeetingMessage] = useState();
  const { tripCount = 0, meetingCount = 0 } = tripsAndMeetingDetailsForNextFive || {};

  useEffect(() => {
    let tripMsg =
      tripCount > 0 ? (tripCount === 1 ? " trip this week" : " trips this week") : "No trips scheduled this week";
    setTripMessage(tripMsg);
    let meetingMsg =
      meetingCount > 0
        ? meetingCount === 1
          ? " meeting this week"
          : " meetings this week"
        : "No meetings scheduled this week";
    setMeetingMessage(meetingMsg);
  }, [tripCount, meetingCount]);

  return (
    <div className="trips-meetings-wrapper">
      <div className="trips-meetings-header">Trips and Meetings</div>
      <div className="trips-meetings-content">
        <div>
          <div className="trips-content">
            <Icon icon="my-trips" iconKit="gt" size={1} />
            {tripCount > 0 ? (
              <span>
                {`You have `}
                <span className="trips-meetings-count">{tripCount}</span>
                {` ${tripMessage}`}
              </span>
            ) : (
              <span className="trips-meeting-empty">{tripMessage}</span>
            )}
          </div>
          <div className="trips-meetings--vertical-padding"></div>
          <div className="meetings-content">
            <Icon icon="date-range" iconKit="gt" size={1} />
            {meetingCount > 0 ? (
              <span>
                {`You have `}
                <span className="trips-meetings-count">{meetingCount}</span>
                {` ${meetingMessage}`}
              </span>
            ) : (
              <span className="trips-meeting-empty">{meetingMessage}</span>
            )}
          </div>
        </div>
      </div>
      <div className="trips-meetings--view-weekly">
        <Button
          onClick={() => {
            EverTrue.track.set("home_screen", { type: "view_trips_meetings" });
            EverTrue.UrlManager.navigate("/trips", true, false);
          }}
          type="secondary"
        >
          View
        </Button>
      </div>
    </div>
  );
};

TripsAndMeetingsForNextFive.propTypes = {
  tripsAndMeetingDetailsForNextFive: PropTypes.shape({
    tripCount: PropTypes.number,
    meetingCount: PropTypes.number,
  }),
};

export default TripsAndMeetingsForNextFive;
