import { PropTypes } from "base/react-libs";
import { useOpenClose } from "@evertrue/et-components";
import { Modal, ModalBody, ModalHeader, Button } from "@evertrue/et-components";
import EverTrue from "app";
import ContactSummaryInteractionFeed from "./contact-summary-interaction-feed";

const ViewAllInteractionsModal = ({ type, interactions }) => {
  const [isModalOpen, openModal, closeModal] = useOpenClose();

  const handleClick = () => {
    EverTrue.track.set("pinned_comment_action", { type: "see_all_pinned" });
    isModalOpen ? closeModal() : openModal();
  };

  return (
    <>
      {interactions && interactions.length > 3 && (
        <>
          <div className="padded-sixteen">
            <Button type="simple" onClick={handleClick}>
              View All
            </Button>
          </div>
          <Modal isOpen={isModalOpen} closeModal={closeModal} closeOnOutsideClick={true}>
            <ModalHeader title={`${type}s`} closeModal={closeModal} />
            <ModalBody>
              <ContactSummaryInteractionFeed interactions={interactions} />
            </ModalBody>
          </Modal>
        </>
      )}
    </>
  );
};

ViewAllInteractionsModal.propTypes = {
  type: PropTypes.string,
  interactions: PropTypes.array,
};
export default ViewAllInteractionsModal;
