import str from "underscore.string";

export const DEFAULT_GIVING_DATA_STRING = "Total giving data (default)";
export const SELF_SELECTION_STRING = "SOLICITOR_SELECTION";
export const CONFIRM_STRING = "i understand";
export const FORMATTED_CONFIRM_STRING = str.titleize(CONFIRM_STRING);

export const FISCAL_YEAR = "FISCAL_YEAR";
// roles
export const VOLUNTEER_LEAD_ROLE = "Volunteer Lead";
export const VOLUNTEER_ROLE = "Volunteer";

export const VMS_MANAGE_TABLE_LIMIT = 50;
export const POOL_FETCH_LIMIT = 50;
