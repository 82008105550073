module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   ReactDOM = require("react-dom")

   $window = $(window)

   getDOMProperties = (ref = {}) ->
      node =
         if ref.tagName
            # raw DOM node or react 0.15 react ref
            ref
         else if ref.jquery
            # jquery wrapped node
            ref[0]
         else
            ReactDOM.findDOMNode(ref)

      _.extend {}, $(node).offset(),
         height: node.offsetHeight
         width: node.offsetWidth
         scrollTop: node.scrollTop

   checkWindowHeightOverflow = (ref, margin=0) ->
      if ref?
         dom_props = getDOMProperties(ref)
         window_height = $window.outerHeight()
         (dom_props.top + dom_props.height + margin) > window_height

   checkWindowWidthOverflow = (ref) ->
      if ref?
         dom_props = getDOMProperties(ref)
         window_width = $window.outerWidth()
         (dom_props.left + dom_props.width) > window_width

   calcHeight = (ref, min=0, max=100000) ->
      if ref?
         window_height = $window.outerHeight()
         dom_props = getDOMProperties(ref)
         _.withinRange(window_height - dom_props.top, min, max)

   calcWidth = (ref, min=0, max=100000) ->
      if ref?
         window_width = $window.outerWidth()
         dom_props = getDOMProperties(ref)
         _.withinRange(window_width - dom_props.left, min, max)

   WindowOverflowMixin =
      getDOMProperties: getDOMProperties
      checkWindowHeightOverflow: checkWindowHeightOverflow
      checkWindowWidthOverflow: checkWindowWidthOverflow
      calcHeight: calcHeight
      calcWidth: calcWidth



