export const BOOKMARK_PERMISSIONS = {
  VIEW: "VIEW",
  EDIT: "EDIT",
  OWNER: "OWNER",
};

export const SEARCH_TYPES = {
  OWNED: "OWNED",
  BOOKMARKED: "BOOKMARKED",
  SHARED: "SHARED",
  SUGGESTED: "SUGGESTED",
};

export const BOOKMARK_TYPES = {
  NEW: "New",
  COPY: "Copy",
  SAVE_AS: "Save-as",
};

export const getQuery = (filters, mapCoordinates, mapFilters, radius) => {
  const dsl = { ...filters };
  if (mapCoordinates) {
    dsl.maps = { ...mapCoordinates };
    if (mapFilters) {
      dsl.maps.quick_filters = mapFilters;
    }
    if (radius) {
      dsl.maps.radius = { ...radius };
    }
  }
  return dsl;
};

export const mergeFilters = (contactFilters, bookmarkFilters) => {
  if (!bookmarkFilters) return contactFilters;

  const mergeArrays = (target, source) => {
    return source.reduce(
      (accum, curr) => {
        if (target.some((elem) => elem.compound_id === curr.compound_id)) return accum;
        return accum.concat(curr);
      },
      [...target]
    );
  };

  return {
    ...contactFilters,
    filters: {
      blocks: [
        {
          rules: mergeArrays(contactFilters.filters.blocks[0].rules, bookmarkFilters.filters.blocks[0].rules),
          condition: "and_all_of",
        },
        {
          rules: mergeArrays(contactFilters.filters.blocks[1].rules, bookmarkFilters.filters.blocks[1].rules),
          condition: "or_all_of",
        },
      ],
    },
    quick_filters: mergeArrays(contactFilters.quick_filters, bookmarkFilters.quick_filters),
  };
};
