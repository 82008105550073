module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories
   $ = require("jquery")
   {createFactory} = require("base/new-utils")
   Icon = createFactory (require("components/elements/icon").default)
   Link = createFactory(require("@evertrue/et-components").Link)

   createComponent "Assignee",
      propTypes:
         data:  ReactLibs.PropTypes.shape({
            name: ReactLibs.PropTypes.object,
            status: ReactLibs.PropTypes.object,
            remote_user_id: ReactLibs.PropTypes.object,
            type: ReactLibs.PropTypes.object,
            user_id: ReactLibs.PropTypes.oneOfType([
               ReactLibs.PropTypes.object,
               ReactLibs.PropTypes.number
            ])
         })

      render: ->
         assignee = @props.data || {}
         status = assignee.status
         param_obj = _.extend {},
            filters: _.toObject("assignee", [assignee.name?.value])
            page: 1

         div className: "assignee-template",
            Icon icon: "person", className: "assignee-template--icon"

            div className: "assignee-template--data",
               div null,
                  if assignee.contact_id?.value
                     Link href: "/contact/#{assignee.contact_id?.value}", title: "assignee's profile", "data-refer": "person",
                        assignee.name?.value
                  else
                     Link href: "/contact?#{$.param(param_obj)}", title: "assignee's profile", "data-refer": "person",
                        assignee.name?.value

               div className: "assignee-template--label",
                  assignee.type?.value

               div className: "assignee-template--degree",
                  if _.isArray(status?.value)
                     _.map status?.value, (value, index) ->
                        div key: _.randomKey(), value
                  else
                     status?.value
