module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   {createStore} = require("@evertrue/et-flux")
   TripContactsSource = require("apps/my-trips/sources/trip-contacts-source")
   MyTripsStore = require("apps/my-trips/stores/my-trips-store")

   _pluralizeText = (length) ->
      if length == 1 then "Constituent" else "#{length} constituents"

   _isNotTripRoute = (trip_id) ->
      window.location.pathname != "/trips/#{trip_id}"


   createStore "TripContactsStore",
      getInitialState: ->
         trip_contacts: {}
         loading: false

      registerActions: ->
         @on TripContactsSource.actions.loading, @respondToLoading
         @on TripContactsSource.actions.fetchedContactsForTrip, @respondToFetch
         @on TripContactsSource.actions.addedContacts, @respondToAdd
         @on TripContactsSource.actions.removedContact, @respondToRemove

      respondToLoading: (is_loading) ->
         @setState {loading: is_loading}

      respondToFetch: (trip_id, contacts) ->
         trip_contacts = _.cloneData @getState("trip_contacts")

         if contacts.offset > 0 && trip_contacts[trip_id]
            aggregate_contacts = trip_contacts[trip_id] || {items: []}
            aggregate_contacts.items = aggregate_contacts.items.concat(contacts.items)
         else
            trip_contacts[trip_id] = _.cloneData(contacts)

         @setState {trip_contacts: trip_contacts}

      respondToAdd: (trip_id, contacts, options, length) ->
         if _isNotTripRoute(trip_id)
            @alertChange(length, "added to", trip_id)

      respondToRemove: (trip_id, contact_id, options) ->
         if _isNotTripRoute(trip_id)
            @alertChange(1, "removed from", trip_id)

      alertChange: (length, string, trip_id) ->
         trip = MyTripsStore.getTrip(trip_id)
         EverTrue.Alert.success "#{_pluralizeText(length)} #{string} #{trip?.name || "trip"}",
            actionLabel: "View Trip"
            onAction: -> EverTrue.Navigator("/trips/#{trip_id}", true)

      api:
         getAll: ->
            @getState("trip_contacts")

         getContactsForTrip: (trip_id) ->
            @getState("trip_contacts")[trip_id]?.items

         getContactCountForTrip: (trip_id) ->
            @getState("trip_contacts")[trip_id]?.total

         getTripContactById: (contact_id) ->
            all_contacts = _.flatten(_.pluck(@getState("trip_contacts"), "items"))
            _.findWhere all_contacts, {id: contact_id}

         getLoading: ->
            @getState("loading")
