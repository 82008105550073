import PropTypes from "prop-types";
import EverTrue from "app";
import Nav from "components/lists/nav";
import NavItem from "components/lists/nav-item";

const PortfolioNav = ({ tab }) => {
  return (
    <Nav
      active={tab}
      className="portfolio-performance--nav"
      onSelect={selected_tab => {
        // We are using this onSelect on <Nav> instead of href on <NavItem>
        // because the href were causing this controller to mount every time the NavItem was clicked
        // and ideally we want the url to update but not to remount this controller
        EverTrue.UrlManager.navigate(`/portfolio-performance/${selected_tab}`, true, false);
      }}
    >
      <NavItem activeWhen="portfolios" label="Portfolios" />
      <NavItem activeWhen="solicitors" label="Solicitors" />
      <NavItem activeWhen="teams" label="Teams" />
    </Nav>
  );
};

PortfolioNav.propTypes = {
  tab: PropTypes.string,
};

export default PortfolioNav;
