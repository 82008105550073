module.exports = do ->
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories
   {any, string, number, oneOfType} = ReactLibs.PropTypes
   {createFactory} = require("base/new-utils")
   Readmore = createFactory require("components/formatting/readmore")
   ActivityHelpers = require("apps/profile/components/activity/activity-helpers")

   createComponent "EngagementCard",
      propTypes:
         title: any
         contents: any
         comment: any
         date: oneOfType([number, string])
         icon: any # intended to be an Icon component or an Reaction component

      render: ->
         {title, contents, comment, date, icon} = @props

         div className: "engagement-card",
            div className: "engagement-card--icon-wrapper",
               div className: "engagement-card--icon",
                  icon
            div className: "engagement-card--wrapper",

               div className: "engagement-card--title",
                  title

               if contents
                  div className: "engagement-card--contents ellipsis-overflow",
                     contents

               if comment
                  div className: "engagement-card--comment",
                     Readmore height: 20, comment

               div className: "engagement-card--date",
                  ActivityHelpers.formatDate(date)

               if !contents
                  div style: marginBottom: "20px"

