module.exports = do ->
   _ = require("underscore").default
   moment = require("moment")
   ReunionYearOptions = require("apps/filters/stores/reunion-year-options")

   DEFAULTS = {sort: "giving.lifetime_amount", sortReverse: true}

   YEAR_GROUP = do ->
      _date = moment(new Date())
      _month = parseInt(_date.format("MM"), 10)
      _year = parseInt(_.last(_date.format("YYYY")), 10)
      if _month > 6 then _year += 1
      group = _.find ReunionYearOptions, (facet) ->
         facet?.value?.match(_year.toString())
      group?.id || "3and8"

   popular_searches = [
      id: 1.1
      title: "Engaged on Facebook Last Week"
      leader: "Strike while the iron is hot!"
      text: "Constituents who like or comment on your Facebook page have you top of mind."
      filters: facebook_engagement: true, last_fb_engagement_date: {parameter: "must", value: "now-1w", range:""}
   ]

   big_supporters = [
      id: 2.1
      title: "1M+ Lifetime Donors"
      leader: "Keep tabs on your big wigs."
      text: "Save this search to get notified whenever a donor breaks the one million mark."
      filters: lifetime_giving: {gte: 1000000}
   ]

   major_gifts = [
      id: 3.2
      title: "Unassigned Constituents in Wealthy Neighborhoods"
      leader: "Queue up constituents for discovery."
      text: "These individuals may have capacity based on their home addresses but are not yet assigned."
      filters: wealthy_neighborhood: true, unassigned: true
      gate_hide: "rm_assignments"
   ]

   annual_fund = [
      id: 4.4
      title: "LYBUNTs in a Reunion Year"
      leader: "The memories are flowing—so should the dollars!"
      text: "Quickly round up a list of LYBUNTs who are in a reunion year."
      filters: giving_pattern: [{label: "LYBUNT", value:"lybunt"}], reunion_year: [YEAR_GROUP]
      sort: "giving.last_gift_date"

   ]

   sections = [
      title: "Popular Searches"
      text: "Most users save these searches to get notified of new matches."
      segments: popular_searches
   ,
      title: "Monitor Your Biggest Supporters"
      text: "Saving these searches helps you keep track of your VIPs."
      segments: big_supporters
   ,
      title: "Discover Leadership and Major Gift Prospects"
      text: "Leverage indicators of capacity and engagement to surface potential unassigned prospects."
      segments: major_gifts
   ,
      title: "Grow the Annual Fund"
      text: "Identify constituents who are primed and ready to give."
      segments: annual_fund
   ]

   _.each sections, (section) ->
      _.each section.segments, (segment) ->
         _.defaults segment, DEFAULTS
