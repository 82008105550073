module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   classNames = require("classnames")
   {div, button, span, i} = ReactLibs.DOMFactories
   {createComponent} = require("@evertrue/et-flux")
   {createFactory} = require("base/new-utils")
   Icon = createFactory (require("components/elements/icon").default)

   # this.props.buttons should either be:
   # an array of strings, which will be used as the label and the value of each button
   # or
   # objects with keys: [value (required), label, icon, disabled(bool), selected(bool)]
   # can use 'label' or 'icon' or both
   # 'disabled' can be used per button but the props.disabled == true will disable the entire control

   createComponent "Button Select",
      propTypes:
         buttons: ReactLibs.PropTypes.array.isRequired
         onSelect: ReactLibs.PropTypes.func.isRequired
         selected: ReactLibs.PropTypes.any
         large: ReactLibs.PropTypes.bool
         disabled: ReactLibs.PropTypes.bool

      render: ->
         div className: classNames("button-select", @props.className), role: "tablist",
            _.map @props.buttons, (btn) =>
               if _.isString(btn) then value = btn
               else {value, label, icon, gt_icon, disabled, iconKit} = btn

               selected = _.makeArray(@props.selected)

               enabled = !(@props.disabled or disabled)

               classes =
                  classNames "button-select--item",
                     "is-disabled": !enabled
                     "is-selected": enabled and _.contains(selected, value)
                     "is-large": @props.large

               button
                  className: classes
                  key: value
                  value: value
                  role: "tab"
                  onClick: if enabled then ((evt) => @props.onSelect(value, evt))
                  if icon then i(className: "fa fa-fw fa-#{icon} button-select--item-icon")
                  else if gt_icon then Icon className: "button-select--item-icon", icon: gt_icon, iconKit: iconKit
                  if label then displayLabel = span(className: "button-select--item-label", label)
