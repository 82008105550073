import PropTypes from "prop-types";
import { ListboxSelect } from "@evertrue/et-components";
import useFacets from "apps/query-builder/hooks/use-facets";

const proptypes = {
  filterId: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  label: PropTypes.string,
  emptyValue: PropTypes.string,
};

const FilterValueSelect = ({ filterId, onChange, value, label = "", emptyValue = "Select..." }) => {
  const { options, getFacets } = useFacets(filterId);

  let searchQueryTimeout = null;

  return (
    <ListboxSelect
      size="medium"
      emptyValue={emptyValue}
      label={label}
      value={options.find((option) => option.value === value) || { label: value, value: value }}
      options={options}
      onChange={(change) => {
        onChange(change.value);
      }}
      onSearch={(search) => {
        clearTimeout(searchQueryTimeout);
        searchQueryTimeout = setTimeout(() => getFacets(search), 500);
      }}
      scrollFetch={(search) => {
        if (options[options.length - 1]) {
          getFacets(search, options[options.length - 1].value);
        }
      }}
      showInfoBar={true}
    />
  );
};

FilterValueSelect.propTypes = proptypes;
export default FilterValueSelect;
