import _ from "underscore";
import Api from "entities/helpers/api";
import { createContext, Component } from "react";
import Decorator from "@evertrue/client-decorator";
import PropTypes from "prop-types";
import RoleHelper from "entities/helpers/role-helper";
import { USER_DEFAULT_KEY, TEAM_PERSONAS } from "apps/dashboard/constants";

const DashboardMetaDataContext = createContext(() => {});

class DashboardMetaDataProvider extends Component {
  static propTypes = {
    children: PropTypes.any,
    user: PropTypes.object,
    org: PropTypes.object,
    routeUserId: PropTypes.number,
  };

  _isMounted = false;
  componentDidMount() {
    this._isMounted = true;
    this.fetchUsers();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    if (this._isMounted) {
      this.setState(...args);
    }
  };

  fetchUsers = (searchText) => {
    this.safeSetState({ loading_users: true });
    const roles = RoleHelper.toArray();
    const query = {
      must: [
        { "affiliations.affiliation_attributes.persona": { in: TEAM_PERSONAS } },
        { "affiliations.affiliation_roles.role_id": { in: roles } },
      ],
    };
    if (searchText) {
      query.must.push({
        name: {
          wildcard: `${searchText}*`,
        },
      });
    }

    Api.AUTH.USER.post({
      urlExtend: "/search",
      data: _.jsonStringify(query),
      params: { limit: 100 },
      disableAlerts: true,
      // need to have this in a success callback so when the session expires the dashboard
      // will refresh properly
      success: (resp) => {
        const users = _.sortBy(resp.users, "name");
        this.safeSetState({ loading_users: false });
        this.safeSetState({ users });
        this.determineUser(users);
      },
    });
  };

  determineUser = (users) => {
    const stored_id = localStorage.getItem(USER_DEFAULT_KEY);
    const isOwner = Decorator.User.isOwner(this.props.user, this.props.org.id);
    const isSuperUser = this.props.user.super_user;
    let user = {};

    // if the user id is in the url
    if (this.props.routeUserId && _.findWhere(users, { id: this.props.routeUserId })) {
      user = _.findWhere(users, { id: this.props.routeUserId }) || {};
      // else if the solicitor id is in local storage
    } else if (stored_id && _.findWhere(users, { id: parseInt(stored_id, 10) })) {
      user = _.findWhere(users, { id: parseInt(stored_id, 10) }) || {};
      // if no solicitor has been explicitly selected, try using the user's user id
    } else if (this.props.user.id && _.findWhere(users, { id: this.props.user.id })) {
      user = _.findWhere(users, { id: this.props.user.id }) || {};
      // if user is an owner or super users, then default to first user result
    } else if (isOwner || isSuperUser) {
      user = users[0];
    }

    this.safeSetState({ user_id: user.id });
    // set user contact id
    const user_contact_id = Decorator.User.getContactId(user, this.props.org.id);
    this.safeSetState({ user_contact_id });
  };

  handleSelectUser = (selection) => {
    const user = _.findWhere(this.state.users, { id: selection.value });
    if (user) {
      localStorage.setItem(USER_DEFAULT_KEY, user.id);
      this.safeSetState({ user_id: user.id });
      // set user contact id
      const user_contact_id = Decorator.User.getContactId(user, this.props.org.id);
      this.safeSetState({ user_contact_id });
    }
  };

  state = {
    users: [],
    loading_users: false,
    user_id: null,
    user_contact_id: null,
    selectUser: this.handleSelectUser,
  };

  render() {
    return (
      <DashboardMetaDataContext.Provider value={{ ...this.state }}>
        {this.props.children}
      </DashboardMetaDataContext.Provider>
    );
  }
}

export { DashboardMetaDataProvider };

export default DashboardMetaDataContext;
