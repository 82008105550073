const _ = require("underscore").default;
const { createSource } = require("@evertrue/et-flux");
const Api = require("entities/helpers/api");

let _request;

module.exports = createSource("VolunteerSource", {
  actions: {
    loading: true,
    fetchedVolunteers: true,
  },

  api: {
    fetch(search) {
      if (_request != null) {
        _request.abort();
      }
      this.actions.loading(true);

      Api.CONTACTS.SEARCH.post({
        params: { limit: 250 },
        data: _.jsonStringify({
          must: !search
            ? []
            : _.map(search != null ? search.split(" ") : undefined, (query) => ({
                name_first: { wildcard: `${query}*` },
                name_last: { wildcard: `${query}*` },
              })),
          sort: [{ name_first: { order: "asc" } }, { name_last: { order: "asc" } }],
          has_child: [
            {
              type: "assignment",
              query: {
                must: [{ parent_role: { match: "solicitor" } }, { pool_type: { match: "POOL" } }],
              },
            },
          ],
        }),
        beforeSend(xhr) {
          _request = xhr;
        },
        success: (results) => {
          _request = undefined;
          this.actions.fetchedVolunteers(results);
          this.actions.loading(false);
        },
        error: () => {
          _request = undefined;
          this.actions.loading(false);
        },
      });
    },
  },
});
