import { createStore } from "@evertrue/et-flux";
import CronofyCalendarSource from "apps/my-trips/sources/cronofy-calendar-source";
import _ from "underscore";

const CronofyCalendarStore = createStore("CronofyCalendarStore", {
  getInitialState() {
    return {
      calendars: [],
      loading: false,
    };
  },

  registerActions() {
    this.on(CronofyCalendarSource.actions.fetchCalendarsIfNotCached, () => {
      if (_.isEmpty(this.state.calendars)) {
        CronofyCalendarSource.calendarRefresh();
      }
    });
    this.on(CronofyCalendarSource.actions.fetchedCalendars, calendars => {
      this.setState({ calendars });
    });
    this.on(CronofyCalendarSource.actions.loading, loading => {
      this.setState({ loading });
    });
  },

  api: {
    getCalendarsByProvider() {
      // sort into an object keyed on provider name (Google, Outlook, etc)
      return this.state.calendars.reduce((accum, calendar = {}) => {
        const { account: { provider_name } = {} } = calendar;
        const existing_cals = accum[provider_name] || [];
        return { ...accum, [provider_name]: [...existing_cals, calendar] };
      }, {});
    },
    getLoadingCalendars() {
      return this.state.loading;
    },
    getCalendars() {
      return this.state.calendars;
    },
  },
});

export default CronofyCalendarStore;
