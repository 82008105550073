/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import { useState, useEffect, useContext } from "react";
import { ListboxSelect } from "@evertrue/et-components";
import { TasksContext } from "apps/tasks/hooks/tasks-context";
import { trackTaskAction } from "apps/tasks/utils/utils";

const taskCompletionOptions = [
  { label: "Incomplete Tasks", value: ["IN_PROGRESS", "PAUSED"] },
  { label: "Completed Tasks", value: ["COMPLETED"] },
  { label: "All Tasks", value: ["IN_PROGRESS", "COMPLETED", "PAUSED"] },
];

const TaskQuickFilters = ({ userType }) => {
  const { taskFilters, updateTaskFilters, updateTableSettings } = useContext(TasksContext);
  const [selectedTaskCompletion, setSelectedTaskCompletion] = useState(taskCompletionOptions[0]);

  useEffect(() => {
    setSelectedTaskCompletion(taskCompletionOptions[0]);
  }, [userType]);

  return (
    <div className="tasks-filters-bar">
      <ListboxSelect
        size="medium"
        options={taskCompletionOptions}
        onChange={(e) => {
          trackTaskAction(e.label);
          setSelectedTaskCompletion(e);
          if (e.label === "Completed Tasks") {
            updateTableSettings({ sortProp: "completed_at" });
          } else if (e.label === "Incomplete Tasks") {
            updateTableSettings({ sortProp: "due_date" });
          }
          updateTaskFilters({ ...taskFilters, statuses: e.value });
        }}
        value={selectedTaskCompletion}
        emptyValue="Task Completion"
        label="Task Completion"
      />
    </div>
  );
};

TaskQuickFilters.propTypes = {
  userType: PropTypes.string,
};

export default TaskQuickFilters;
