import { useEffect, useState, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import getGoogleApi from "apps/search/utils/get-google-api";
import EverTrue from "app";
import useGeolocation from "./use-geolocation";

const ERROR_MESSAGES = {
  ERROR: "There was a problem contacting the Google servers.",
  INVALID_REQUEST: "This request was invalid.",
  OVER_QUERY_LIMIT: "You have gone over the request limit in too short a period of time.",
  REQUEST_DENIED: "Geocoder access denied.",
  UNKNOWN_ERROR: "There was a server error, please try again.",
  ZERO_RESULTS: "No results were found for this request.",
};

const useMapsSearch = (map, onMapCoordinateChange = () => {}) => {
  const [locationValue, setLocationValue] = useState("");
  const [searchQuery, setSearchQuery] = useState({});
  const [googleApi, setGoogleApi] = useState(null);
  const [geoCoder, setGeoCoder] = useState(null);

  const inputRef = useRef();
  const { geolocation, geolocationError } = useGeolocation();

  const updateMap = useCallback(
    (data) => {
      const geometry = data ? data.geometry : null;
      const location = geometry !== null ? geometry.location : null;
      const viewport = geometry !== null ? geometry.viewport : null;
      const latLng = {
        lat: Number(location.lat()),
        lng: Number(location.lng()),
      };

      map.setCenter(latLng);
      map.fitBounds(viewport);
      onMapCoordinateChange(map);
    },
    [map, onMapCoordinateChange]
  );

  // setup google api
  useEffect(() => {
    if (!map) return;

    const setupGoogle = async () => {
      try {
        const google = await getGoogleApi();
        const geocoder = await new google.Geocoder();
        setGoogleApi(google);
        setGeoCoder(geocoder);
      } catch (e) {
        console.error(e);
      }
    };

    setupGoogle();
  }, [map]);

  // setup autocomplete
  useEffect(() => {
    if (!map || !googleApi) return;

    // config
    const autoCompleteOptions = {
      fields: ["place_id", "geometry", "name", "formatted_address"], // only return fields we care about; costs $$!!
      types: ["locality", "country", "postal_code", "street_address", "point_of_interest"], // only search these types of locations
      strictBounds: false, // try and limit results to the given bounds, but allow results outside of them if it's a match
    };

    const autoComplete = new googleApi.places.Autocomplete(inputRef.current, autoCompleteOptions);
    if (map) autoComplete.bindTo("bounds", map); // this limits the search to the map's viewport

    const handlePlaceChanged = async () => {
      inputRef.current.blur();
      const { place_id, name, formatted_address } = autoComplete.getPlace();
      const query = place_id
        ? { placeId: place_id }
        : formatted_address
        ? { address: formatted_address }
        : { address: name };
      setSearchQuery(query);
      setLocationValue(formatted_address || name);
    };

    // AutoComplete Event Listener; Triggers when Place Value is changed
    googleApi.event.addListener(autoComplete, "place_changed", handlePlaceChanged);
  }, [map, googleApi]);

  // Trigger search ONLY when query updates
  useEffect(() => {
    const geoCodeSearch = () => {
      if (!searchQuery.placeId && !searchQuery.address && !searchQuery.location) return;

      geoCoder.geocode(searchQuery, (results, status) => {
        try {
          if (status === "OK") updateMap(results[0]);
          else throw status;
        } catch (e) {
          EverTrue.Alert.error(ERROR_MESSAGES[e]);
          console.error(ERROR_MESSAGES[e]);
        }
      });
    };

    geoCodeSearch();
    /* eslint-disable react-hooks/exhaustive-deps */
    // only run this when the query changes
  }, [searchQuery]);

  const MapSearchInput = () => (
    <input
      ref={inputRef}
      type="text"
      id="map-search-input"
      value={locationValue}
      className="map-search--input"
      placeholder="Set map to location..."
      onChange={(e) => setLocationValue(e.target.value)}
    />
  );

  const handleGeolocationClick = () => {
    // this does NOT trigger the "place_changed" event.
    if (geolocationError) EverTrue.Alert.error(geolocationError);
    else {
      setLocationValue("Current Location");
      setSearchQuery({ location: geolocation });
    }
  };

  const handleSearchClick = () => setSearchQuery({ address: locationValue });

  return {
    MapSearchInput,
    handleSearchClick,
    handleGeolocationClick,
    geolocationError,
  };
};

useMapsSearch.PropTypes = {
  map: PropTypes.object,
  onMapCoordinateChange: PropTypes.func,
};

export default useMapsSearch;
