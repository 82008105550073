import { Fragment, useState } from "react";
import { ModalConfirm, Icon, Flex } from "@evertrue/et-components";
import Loading from "components/elements/loading";
import Api from "entities/helpers/api";
import { useIdentity } from "base/identity-resolver";
import _ from "underscore";
import colors from "base/colors";

const BulkCSVInviteModal = ({ onClose }) => {
  const [file, setFile] = useState(null);

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [numberOfInvites, setNumberOfInvites] = useState(0);

  const [error, setError] = useState(null);
  const { org } = useIdentity();

  const getSampleURL = () => {
    if (org.sso_method === "saml") {
      return "https://evertrue-prod-public.s3.amazonaws.com/sample_sso_bulk_invites.csv";
    } else {
      return "https://evertrue-prod-public.s3.amazonaws.com/sample_bulk_invites.csv";
    }
  };

  const handleFileSelect = (e) => {
    setFile(e.target.files[0]);
  };

  const getBase64 = (file, _) => {
    if (file === undefined || file === null) {
      return Promise.resolve();
    }

    return new Promise(function (resolve, reject) {
      const reader = new FileReader();
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleFileUpload = () => {
    getBase64(file).then(function (base64String) {
      let data;
      if (base64String === undefined || base64String === null) {
        data = { csv: null };
      } else {
        data = _.compactObject({ csv: base64String });
      }

      setLoading(true);
      setError(null);

      Api.AUTH.CSV_UPLOAD.post({
        headers: {
          "Content-Type": "application/json",
        },
        contentType: false,
        processData: false,
        data: _.jsonStringify(data),
      })
        .then((resp = {}) => {
          const inviteData = _.jsonStringify(_.compactObject({ s3_url: resp.csv_invite.url }));
          Api.AUTH.AFFIL_INVITES_CSV_INVITE.post({
            headers: {
              "Content-Type": "application/json",
            },
            contentType: false,
            processData: false,
            data: inviteData,
          })
            .then((resp = {}) => {
              setSuccess(true);
              setLoading(false);

              setNumberOfInvites(resp.affiliation_invitations.length);
            })
            .catch((err) => {
              setLoading(false);
              setError(err.responseJSON.affiliation_invitations);
            });
        })
        .catch((err) => {
          setLoading(false);
          setError(true);
        });
    });
  };

  const renderSuccess = () => (
    <ModalConfirm header="Invites sent" closeModal={onClose} hasMargin>
      <div className="bulk-csv-success">
        <Icon icon="success-circle" size={5} />
        <div className="bulk-csv-success-header">Success!</div>
        <p>
          <strong>{numberOfInvites}</strong> have been invited to EverTrue via email.
        </p>
      </div>
    </ModalConfirm>
  );

  const renderErrors = () => {
    const mappedErrors = error.map((e, i) => {
      return { row: i + 1, values: e };
    });

    return mappedErrors.map((err) => {
      return Object.entries(err.values).map((row) => {
        let keyTitle = row[0] === "persona" ? "classification" : row[0];
        keyTitle = row[0] === "saml_user_id" ? "UID" : keyTitle;

        return row[1].length ? (
          <p key={`${err.row}-${row[0]}`}>{`Row ${err.row}: ${keyTitle}: ${row[1].toString()}`}</p>
        ) : null;
      });
    });
  };

  const renderUploader = () => (
    <ModalConfirm
      header="Bulk invite team members from CSV file"
      saveLabel="Invite"
      closeModal={onClose}
      onSubmit={handleFileUpload}
      onCancel={onClose}
      disableSave={!file}
      closeOnSubmit={false}
      hasMargin
    >
      {loading && <Loading />}
      <div>
        Step 1) Download our{" "}
        <a href={getSampleURL()} download>
          preformatted sample spreadsheet
        </a>
      </div>
      <div>Step 2) Add your team’s information</div>
      <div>Step 3) Save your file as CSV</div>
      <div>Step 4) Click ‘Choose File’ below and select your CSV file for upload</div>
      <div>Step 5) Invite</div>
      <input style={{ marginTop: 30 }} type="file" accept=".csv" onChange={handleFileSelect} />

      {file && !error ? (
        <div className="bulk-csv-status">
          <div className="bulk-csv-status-line success" />
          <Flex alignItems="center">
            <Icon icon="check" isBeforeText style={{ color: colors.green300 }} />
            <div className="bulk-csv-message">Ready to invite</div>
          </Flex>
        </div>
      ) : null}

      {file && error ? (
        <div className="bulk-csv-status">
          <div className="bulk-csv-status-line error" />
          <Flex alignItems="center">
            <Icon icon="clear" isBeforeText style={{ color: colors.red300 }} />
            <div className="bulk-csv-message">Please resolve the following errors within your file and re-upload</div>
          </Flex>
          <div className="bulk-csv-error-block">{renderErrors()}</div>
        </div>
      ) : null}
    </ModalConfirm>
  );

  return <Fragment>{success ? renderSuccess() : renderUploader()}</Fragment>;
};

BulkCSVInviteModal.propTypes = {
  onClose: ReactLibs.PropTypes.func,
};

export default BulkCSVInviteModal;
