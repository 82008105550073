module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   EverTrue = require("app")
   React = require("react")
   {i} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   Button = createFactory(require("@evertrue/et-components").Button)


   # TODO: Convert to use WindowSource
   BackToTop = ReactLibs.createClass
      propTypes:
         showPosition: ReactLibs.PropTypes.number
         scrollElement: ReactLibs.PropTypes.any

      getDefaultProps: ->
         scrollElement: EverTrue.WINDOW

      getInitialState: ->
         {hidden: true}

      componentDidMount: ->
         $(@props.scrollElement).on "scroll", @_scrollCheck

      componentWillUnmount: ->
         $(@props.scrollElement).off "scroll", @_scrollCheck

      _scrollCheck: ->
         windowScroll = $(@props.scrollElement).scrollTop()
         pos = @props.showPosition || 0
         if windowScroll >= pos
            @setState {hidden: false}
         else
            @setState {hidden: true}

      shouldComponentUpdate: (newProps, newState) ->
         @state.hidden != newState.hidden

      handleClick: ->
         $(@props.scrollElement).scrollTop(0)

      render: ->
         return null if @state.hidden

         Button type: "secondary", className: "back-to-top", onClick: @handleClick,
            i className: "fa fa-fw fa-arrow-circle-up"
            "Back to top"

