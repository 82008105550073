module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   {div, textarea} = ReactLibs.DOMFactories
   Api = require("entities/helpers/api")
   TagList = require("components/tag-list")
   Loading = require("components/elements/loading")
   {createFactory} = require("base/new-utils")
   LinkButton = createFactory(require("@evertrue/et-components").LinkButton)

   _formatConstituents = (count) ->
      if count == 1 then "constituent" else "constituents"

   _tokenize = (text = "") ->
      _.uniq(_.compact(_.map(text.split(/[ ,\n\r\t]+/), (e) -> e.trim?())))

   createComponent "ValidateContactsTextbox",
      propTypes:
         value: ReactLibs.PropTypes.string
         placeholder: ReactLibs.PropTypes.string
         actionText: ReactLibs.PropTypes.string
         validateOnBlur: ReactLibs.PropTypes.bool
         limit: ReactLibs.PropTypes.number
         onChange: ReactLibs.PropTypes.func
         onValidate: ReactLibs.PropTypes.func
         bulkSelector: ReactLibs.PropTypes.string

      getDefaultProps: ->
         actionText: "add"
         limit: 500
         bulkSelector: "email"

      getInitialState: ->
         loading: false
         error: false
         token_state: []

      # This can also get called by a parent component through a ref
      # when it wants to handle the validation based on a different form action
      handleValidateAndSubmit: ->
         tokens = _tokenize(@props.value)
         # if the text input has been validated and token_state is set with the "hits" and "misses"
         if !_.isEmpty(@state.token_state)
            hits = _.filter @state.token_state, (item) -> item.type == "hit"
            # Submit if there's at least one successfull hit, otherwise the user will
            # need to correct the error if there are only errors
            if !_.isEmpty(hits)
               @handleSubmit(_.pluck(hits, "id"))
         # validate the text input
         else
            if tokens.length > @props.limit
               @setState
                  error: "You have exceed the max number of constituents
                  you can add. Please limit to #{@props.limit} and try again."
            else
               # TODO: Replace with Will's new flux stuff
               @setState {loading: true}
               Api.CONTACTS.IDENTITY_MATCH.post
                  data: _.jsonStringify(tokens)
                  params: { provider: @props.bulkSelector}
                  success: (resp) =>
                     if resp.total_misses == 0
                        @handleSubmit(_.values(resp.hits))
                     else
                        @setState
                           loading: false
                           token_state: _.compact _.map tokens, (token) ->
                              if resp.hits[token]
                                 {name: token, id: resp.hits[token], type: "hit"}
                              else if _.contains(resp.misses, token)
                                 {name: token, id: token, type: "miss", isError: true}
                  error: (xhr) =>
                     @setState {error: xhr?.responseJSON, loading: false}

      handleSubmit: (hits) ->
         @props.onSubmit?(_.map(hits, _.toNumber))

      handleChange: (val) ->
         @props.onChange?(val)

      handleShowEdit: ->
         @handleChange(_.pluck(@state.token_state, "name").join(", "))
         @setState {token_state: []}

      render: ->
         [misses, hits] = _.partition @state.token_state, (token) -> token.type == "miss"
         selection_text = _.pluralize(@props.bulkSelector.replace("_", " ").toLowerCase())
         default_placeholder = "Copy and paste constituent #{selection_text} from an external spreadsheet or document."

         div className: classNames("validate-contacts-textbox", @props.className),
            if @state.loading
               Loading()
            else if @state.error || !_.isEmpty(misses)
               div className: "validate-contacts-textbox--error",
                  if @state.error then @state.error
                  else if misses.length
                     "Unable to #{@props.actionText} #{misses.length} #{_formatConstituents(misses.length)}."

            if !_.isEmpty(@state.token_state)
               div className: "validate-contacts-textbox--taglist",
                  TagList
                     items: @state.token_state
                     onRemove: (item) =>
                        token_state = _.without(@state.token_state, item)
                        @setState {token_state: token_state}
                        @handleChange(_.pluck(token_state, "name").join(", "))

                  LinkButton
                     title: "Edit text"
                     className: "validate-contacts-textbox--edit"
                     onClick: @handleShowEdit
                     "Edit Text"
            else
               textarea
                  ref: (input) => @textbox = input
                  value: @props.value
                  placeholder: (@props.placeholder || default_placeholder) + " Add up to #{@props.limit} constituents."
                  onBlur: if @props.validateOnBlur then @handleValidate
                  onChange: (e) => @handleChange(e.target.value)

            if !_.isEmpty(@state.token_state) && hits.length >= 1
               div className: "validate-contacts-textbox--continue",
                  "Continue and #{@props.actionText} #{hits.length} #{_formatConstituents(hits.length)}?"

