module.exports = do ->
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories
   ProfileStore = require("apps/profile/stores/profile-store")
   ProfileSource = require("apps/profile/sources/profile-source")
   ProfileDetailHeader = require("apps/profile/components/profile-detail-header")
   ProfileGiftsFeedController = require("apps/profile/controllers/profile-gifts-feed-controller")
   FeatureStore = require("apps/layout/stores/feature-store").default


   createComponent "ProfileGiftDetailController",
      propTypes:
         id: ReactLibs.PropTypes.any
         section: ReactLibs.PropTypes.string

      registerStores: ->
         @on ProfileStore, ->
            contact: ProfileStore.getProfile(@props.id)

         @on FeatureStore, ->
            has_hybrid_giving: FeatureStore.hasFeature("hybrid_giving")

      componentDidMount: ->
         ProfileSource.fetch(@props.id)
         ProfileSource.recordProfileView(@props.id)

      render: ->
         if @state.has_hybrid_giving
            div className: "contact-profile contact-profile-details contact-profile-v2",
               ProfileDetailHeader
                  contact: @state.contact
                  section: @props.section
                  title: @props.title

               div className: "contact-profile-details--content",
                  ProfileGiftsFeedController contact: @state.contact, limit: 15
         else null
