import { Component, Fragment } from "react";
import PropTypes from "prop-types";
import _ from "underscore";
import { connect } from "@evertrue/et-flux";
import Api from "entities/helpers/api";
import Decorator from "clientDecorator";
import SolicitorsSourceInvite from "apps/volunteers/sources/solicitor-invite-source";
import ContactCard from "apps/contact/components/contact-card/contact-card";
import ModalConfirm from "components/modals/modal-confirm";
import Loading from "components/elements/loading";
import WithOrg from "base/org/with-org";
import WithSession from "base/session/with-session";
import get from "lodash.get";
import FeatureStore from "apps/layout/stores/feature-store";
import { getProductTypes, getContactDataForVMSInvite, toggleHeadAgentDesignation } from "apps/volunteers/utils";
import { Checkbox, Radio } from "@evertrue/et-components";
import { VOLUNTEER_ROLE, VOLUNTEER_LEAD_ROLE } from "apps/volunteers/constants";

const mapStateToProps = () => ({
  hasVolunteerSso: FeatureStore.hasFeature("volunteer_sso"),
  hasHeadAgentFeature: FeatureStore.hasFeature("vms_head_agent"),
});

class AppInviteModal extends Component {
  static propTypes = {
    contact: PropTypes.object,
    org: PropTypes.object,
    roleName: PropTypes.string,
    inviteId: PropTypes.number,
    onClose: PropTypes.func,
    hasVolunteerSso: PropTypes.bool,
    isSessionOfSuperUser: PropTypes.bool,
    hideHeadAgent: PropTypes.bool,
    solicitor: PropTypes.object,
    hasHeadAgentFeature: PropTypes.bool,
    poolId: PropTypes.number,
    enableHeadAgentDesignation: PropTypes.bool,
  };

  static defaultProps = {
    contact: {},
    inviteId: null,
    org: {},
    roleName: "Volunteer",
    onClose: _.noop,
    hasVolunteerSso: false,
    isSessionOfSuperUser: false,
    solicitor: {},
  };

  state = {
    checked: "primary",
    alt_email: "",
    user: {},
    uid: "",
    sso_checked: false,
    head_agent: this.props.solicitor.role === VOLUNTEER_LEAD_ROLE,
  };

  componentDidMount() {
    this.fetchUser();
  }

  componentWillUnmount() {
    this.setState({ checked: "primary", alt_email: "" });
  }

  fetchUser = () => {
    this.setState({ loading: true });
    Api.AUTH.USER.get({
      urlExtend: `/contact_id/${this.props.contact.id}`,
      disableAlerts: true,
      success: (user) =>
        this.setState({
          user: _.extend({ platforms: getProductTypes(user, this.props.org.id) }, user),
          loading: false,
        }),
      error: () => this.setState({ loading: false }),
    });
  };

  handleSubmit = () => {
    const contact_data = this.getContactData();
    if (this.props.inviteId) {
      SolicitorsSourceInvite.resendAppInvite(contact_data, this.props.roleName, this.props.inviteId);
    } else {
      SolicitorsSourceInvite.sendAppInvite(contact_data, this.props.roleName, false);
    }
    this.updateHeadAgent();
    this.props.onClose();
  };

  handleSSOSubmit = () => {
    const { saml_user_id, email, contact_id, first_name, last_name } = this.getContactData();

    const sso_uid = this.state.uid || saml_user_id;
    SolicitorsSourceInvite.sendSSOAppInvite({
      email,
      contact_id,
      first_name,
      last_name,
      saml_user_id: sso_uid,
    });
    this.updateHeadAgent();
    this.props.onClose();
  };

  getContactData = () => {
    return getContactDataForVMSInvite({
      contact: this.props.contact,
      user: this.state.user,
      email_type: this.state.checked,
      alt_email: this.state.alt_email,
      sso: this.props.hasVolunteerSso,
    });
  };

  handleUIDChange = ({ target: { value } }) => {
    this.setState({ uid: value });
  };

  handleSSOCheckbox = (checked) => {
    this.setState({ sso_checked: checked, ...(!checked && { uid: "" }) });
  };

  updateHeadAgent = () => {
    const role = this.state.head_agent ? VOLUNTEER_LEAD_ROLE : VOLUNTEER_ROLE;
    if (this.props.enableHeadAgentDesignation && this.props.hasHeadAgentFeature && role !== this.props.solicitor.role) {
      toggleHeadAgentDesignation(this.props.solicitor, !this.state.head_agent, this.props.poolId);
    }
  };

  render() {
    const email = get(Decorator.Profile.getPrimaryContactInfo(this.props.contact), "email.email");
    const has_sso = this.props.org.sso_method === "saml" && this.props.hasVolunteerSso;
    const sso_school_id = get(this.props.contact, "properties.school_identifier.value");
    const sso_missing_id_not_super_user = has_sso && !sso_school_id && !this.props.isSessionOfSuperUser;

    const isButtonDisabled =
      (!this.state.user.email && this.state.checked === "primary" && !email) ||
      (this.state.checked === "alternative" && !_.validateEmail(this.state.alt_email)) ||
      sso_missing_id_not_super_user;

    return (
      <ModalConfirm
        header="Send App Invitation"
        buttonLabel="Send"
        onSubmit={has_sso ? this.handleSSOSubmit : this.handleSubmit}
        disableButton={isButtonDisabled}
        closeModal={this.props.onClose}
        onCancel={this.props.onClose}
        style={{ width: 420 }}
      >
        <Fragment>
          <div className="app-invitation-modal">
            <ContactCard className="app-invitation-modal--contact-card" contact={this.props.contact} showClassYear />
            {this.state.loading && <Loading />}
            {this.state.user.email ? (
              <div className="app-invitation-modal--alt-section">
                <div className="app-invitation-modal--note">
                  {`This constituent is a registered user of our ${this.state.user.platforms}. \
  Volunteer invites will be sent to the email they used to authenticate previously.`}
                </div>
                <div>
                  <span className="app-invitation-modal--heading">{"Send app invite to: "}</span>
                  <span>{this.state.user.email}</span>
                </div>
              </div>
            ) : (
              <div className="app-invitation-modal--alt-section">
                {sso_missing_id_not_super_user ? (
                  <div>Missing Credentials</div>
                ) : (
                  <Fragment>
                    <div className="app-invitation-modal--heading">Send app invite to:</div>

                    <fieldset>
                      <Radio
                        checked={this.state.checked === "primary"}
                        onChange={() => this.setState({ checked: "primary" })}
                        className="app-invitation-modal--label"
                        label="Primary Email Address"
                        name="email"
                      />
                      <span className="app-invitation-modal--primary-email">{email || "No primary email found"}</span>
                      <Radio
                        checked={this.state.checked === "alternative"}
                        onChange={() => this.setState({ checked: "alternative" })}
                        className="app-invitation-modal--label"
                        label="Alternative Email Address"
                        name="email"
                      />
                    </fieldset>

                    {this.state.checked === "alternative" ? (
                      <input
                        name="alt_email"
                        disabled={!this.state.checked}
                        onChange={({ target: { value: alt_email } }) => this.setState({ alt_email })}
                        placeholder="Enter Alternative Email Address..."
                        type="text"
                      />
                    ) : undefined}
                  </Fragment>
                )}
                {!this.props.hideHeadAgent && this.props.hasHeadAgentFeature && (
                  <div className="app-invitation-modal--head-agent">
                    <Checkbox checked={this.state.head_agent} onChange={(head_agent) => this.setState({ head_agent })}>
                      <strong>Lead Volunteer</strong>
                    </Checkbox>
                  </div>
                )}
              </div>
            )}
            {this.props.isSessionOfSuperUser && has_sso ? (
              <div className="app-invitation-modal--sso-section">
                <span>Override SSO UID (Super User only):</span>
                <div className="app-invitation-modal--sso-checkbox">
                  <Checkbox checked={this.state.sso_checked} onChange={this.handleSSOCheckbox} />
                  {this.state.sso_checked && (
                    <input
                      disabled={!this.state.sso_checked}
                      type="text"
                      value={this.state.uid}
                      onChange={this.handleUIDChange}
                    />
                  )}
                </div>
              </div>
            ) : null}
          </div>
        </Fragment>
      </ModalConfirm>
    );
  }
}

export default _.compose(WithOrg, WithSession, connect)(AppInviteModal, [FeatureStore], mapStateToProps);
