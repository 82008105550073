import EverTrue from "app";
import { Icon } from "@evertrue/et-components";
import PropTypes from "prop-types";

const ActiveJourneyPill = ({
    activeCadence: {
        journey_name,
        step_number,
        day_number,
        journey_id,
    } = {},
    type,
}) => {

    const className = type === "profile" ? "profile-header--cadence" : "tasks-table--cadence";

    return (
        <div className={className} onClick={() => EverTrue.Navigator(`/cadences/${journey_id}`, true)}>
            <Icon iconKit="gt2" icon="cadences"></Icon>
            <p>{journey_name} - Step {step_number} | Day {day_number}</p>
        </div>
    )
}

export default ActiveJourneyPill;

ActiveJourneyPill.propTypes = {
    activeCadence: PropTypes.shape({
        journey_name: PropTypes.string,
        step_number: PropTypes.number,
        day_number: PropTypes.number,
    }),
    type: PropTypes.oneOf(["profile", "tasks"]),
}