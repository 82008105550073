import { Button, useOpenClose, Modal } from "@evertrue/et-components";
import { useContext } from "react";
import { PinnedCommentsContext } from "apps/profile/contexts/pinned-comments-context";
import InteractionForm from "apps/interactions/components/create/interaction-form";
import { PropTypes } from "base/react-libs";
import { trackJourneysAction } from "apps/journeys/utils/helpers/mixpanel";

const AddPinnedCommentButton = ({ buttonType = "secondary", classNames = "et--button-secondary", title = "Add" }) => {
  const [isModalOpen, openModal, closeModal] = useOpenClose();
  const { addNewCommentToState, contact } = useContext(PinnedCommentsContext);

  const handleClick = () => {
    trackJourneysAction("cadence-pinned-comment");
    isModalOpen ? closeModal() : openModal();
  };

  return (
    <>
      <Modal isOpen={isModalOpen} closeModal={closeModal}>
        <InteractionForm
          contact={contact}
          referrer="add-pinned-comment-action"
          interaction={{ interaction_type: "EverTrue Comment", pinned: true }}
          requestHide={closeModal}
          addNewCommentToState={addNewCommentToState}
        />
      </Modal>
      <Button type={buttonType} className={classNames} onClick={handleClick}>
        {title}
      </Button>
    </>
  );
};

AddPinnedCommentButton.propTypes = {
  buttonType: PropTypes.string,
  classNames: PropTypes.string,
  title: PropTypes.string,
};

export default AddPinnedCommentButton;
