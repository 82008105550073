import { Component } from "react";
import PropTypes from "prop-types";
import _ from "underscore";
import { connect } from "@evertrue/et-flux";
import CachedContactSource from "apps/contact/sources/cached-contact-source";
import CachedContactStore from "apps/contact/stores/cached-contact-store";

const mapStateToProps = () => ({
  contacts: CachedContactStore.getAllContacts(),
  loading: CachedContactStore.getLoading(),
});

class CachedContactsResolver extends Component {
  static propTypes = {
    children: PropTypes.any,
    ids: PropTypes.array,
    contacts: PropTypes.object,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    ids: [],
    contacts: {},
    loading: false,
  };

  componentDidMount() {
    CachedContactSource.fetchContacts(this.props.ids);
  }

  componentDidUpdate(prevProps, prevState) {
    const prev = _.sum(prevProps.ids);
    const current = _.sum(this.props.ids);
    if (prev !== current) {
      CachedContactSource.fetchContacts(this.props.ids);
    }
  }

  render() {
    return this.props.children({
      contacts: this.props.ids.reduce((accum, current) => {
        const existing = this.props.contacts[current];
        if (existing) {
          accum[current] = existing;
        }
        return accum;
      }, {}),
      loading: this.props.loading,
    });
  }
}

export default connect(
  CachedContactsResolver,
  [CachedContactStore],
  mapStateToProps
);
