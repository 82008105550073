import PropTypes from "prop-types";
import { mostRecentCreate, sortProfiles } from "apps/profile/components/enrichment/enrichment-utils";
import ProfileCardHeader from "apps/profile/components/profile-card-header";
import SocialProfiles from "apps/profile/components/enrichment/social-profiles";
import { Icon, Modal, ModalBody, useOpenClose, Button } from "@evertrue/et-components";
import SocialEditModal from "apps/profile/components/social-edit-modal";
import EnrichmentModalHeader from "apps/profile/components/enrichment/enrichment-modal-header";

const SocialEnrichment = ({ profiles = [], contact = {}, onDelete }) => {
  const lastRefresh = mostRecentCreate(profiles);
  const activeProfiles = profiles.filter((profile) => !profile.rejected);
  const [isModalOpen, openModal, closeModal] = useOpenClose();
  const sortedProfiles = sortProfiles(activeProfiles);

  // keep track of the count of each profile type
  const profileTypeCounts = {};
  let additionalTypesCount = 0;

  const uniqueProfiles = sortedProfiles.filter((profile) => {
    if (profile.type === "") {
      // keep count of it but don't display it.
      // it'll be displayed in the edit modal
      // with the option to delete it.
      additionalTypesCount++;
      return false;
    }
    if (!profileTypeCounts[profile.type]) {
      profileTypeCounts[profile.type] = 1;
      return true;
    } else {
      profileTypeCounts[profile.type]++;
      additionalTypesCount++;
      return false;
    }
  });

  const renderEditModal = (profiles) => (
    <Modal size="small" isOpen={isModalOpen} closeModal={closeModal}>
      <EnrichmentModalHeader
        headerText={contact.name + "'s Social Media"}
        updateDate={lastRefresh}
        closeModal={closeModal}
      />
      <ModalBody>
        <SocialEditModal contact_id={contact.id} profiles={profiles} onDelete={onDelete} lastRefresh={lastRefresh} />
      </ModalBody>
    </Modal>
  );

  return (
    <div className="profile--enrichment-social">
      <ProfileCardHeader title="Social Media" updateDate={profiles.length > 0 ? lastRefresh : ""}>
      {profiles.length > 0 && (
          <div className="profile--enrichment-inner-edit" onClick={isModalOpen ? closeModal : openModal}>
            <Icon title="Edit Social information" icon="edit" />
            {renderEditModal(profiles)}
          </div>
        )}
      </ProfileCardHeader>
      <div className="profile--enrichment-social-body">        
        <SocialProfiles editMode="edit" profiles={uniqueProfiles} />
        {(additionalTypesCount > 0) && (
          <Button type="simple" className="profile--enrichment-additional-socials" onClick={isModalOpen ? closeModal : openModal}>
            View Additional Social Media Data ({additionalTypesCount} more)
            {renderEditModal(profiles)}
          </Button>
        )}
      </div>
    </div>
  );
};

SocialEnrichment.propTypes = {
  profiles: PropTypes.array,
  contact: PropTypes.object,
  onDelete: PropTypes.func,
};

export default SocialEnrichment;
