import Api from "entities/helpers/api";
import config from "config/env";
import get from "lodash.get";
import { Component } from "react";
import PropTypes from "prop-types";
import _ from "underscore";

class UserSessionResolver extends Component {
  static propTypes = {
    user_id: PropTypes.number,
    org_id: PropTypes.number,
    app_name: PropTypes.string,
    render: PropTypes.func,
  };

  static defaultProps = {
    render: () => null,
  };

  state = {
    user_session: { total: 0 },
    loading: false,
  };

  componentDidMount() {
    this.fetchUserSession(this.props.user_id, this.props.org_id, this.props.app_name);
  }

  componentDidUpdate(prev_props) {
    if (!_.isEqual(prev_props, this.props)) {
      this.fetchUserSession(this.props.user_id, this.props.org_id, this.props.app_name);
    }
  }

  fetchUserSession(user_id, org_id, app_name) {
    const app_id = config.getApplicationId(app_name);
    const query = {
      must: [
        {
          application_id: {
            match: app_id,
          },
        },
        {
          user_id: {
            match: user_id,
          },
        },
        {
          oids: {
            match: org_id,
          },
        },
      ],
      sort: [
        {
          created_at: {
            order: "desc",
          },
        },
      ],
      facet: [
        {
          field: "user_id",
        },
      ],
    };

    this.setState({ loading: true });

    Api.SEARCH.SESSIONS.post({
      data: _.jsonStringify(query),
      params: { limit: 1 },
    })
      .then(res => {
        const { count } = get(res, "facets.user_id[0]") || {};
        const last_session_time = get(res, "items[0].created_at");
        this.setState({ user_session: { count, last_session_time }, loading: false });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  }

  render() {
    return this.props.render({
      user_session: this.state.user_session,
      loading: this.state.loading,
    });
  }
}

export default UserSessionResolver;
