module.exports = do ->
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {i, div, span} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   Link = createFactory(require("@evertrue/et-components").Link)

   createComponent "Help",
      render: ->
         Link
            href: "https://evertrue.forumbee.com/"
            title: "EverTrue help page"
            className: "user-voice-link"
            "data-bypass": "true"
            target: "_BLANK"

            div null,
               span className: "fa-stack",
                 i className: "fa fa-comment fa-stack-2x"
                 i className: "fa fa-question fa-stack-1x fa-inverse"
            "Help"
