import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "underscore";
import Api from "entities/helpers/api";
import Decorator from "clientDecorator";
import { Loading, LinkButton, Link, SearchInput, Checkbox } from "@evertrue/et-components";
import ContactCard from "apps/contact/components/contact-card/contact-card";
import ErrorLogger from "entities/helpers/error-log-helper";
import EllipsisOverflow from "components/formatting/ellipsis-overflow";

//TODO: Maybe move query to constant

const FB_IMAGE_URL = "/images/FB-f-Logo__blue_50.png";
const LIMIT = 25;

const MatchFacebook = ({
  profile,
  engagement,
  postUrl,
  page,
  matchedContactId,
  onCheckChange,
  activeSearchVal,
  onSearch,
}) => {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [searchVal, setSearchVal] = useState(activeSearchVal || profile.name);

  useEffect(() => {
    onSearch(searchVal);
    setOffset(0);
    if (!searchVal) onCheckChange(false, null);
  }, [onSearch, searchVal, onCheckChange]);

  useEffect(() => {
    setLoading(true);
    const searchTerm = !searchVal ? profile.name.trim() : searchVal.trim();
    Api.CONTACTS.SEARCH.post({
      data: JSON.stringify({
        must: _.map(searchTerm.split(" "), (search_part) => ({
          name_first: { wildcard: `${search_part}*` },
          name_last: { wildcard: `${search_part}*` },
          name_nick: { wildcard: `${search_part}*` },
          name_maiden: { wildcard: `${search_part}*` },
          name_suffix: { wildcard: `${search_part}*` },
          name_prefix: { wildcard: `${search_part}*` },
        })),
        // Adjust for lack of identites record for constituent match
        // must_not: [
        //   {
        //     has_child: [
        //       {
        //         type: "social",
        //         query: {
        //           must: [
        //             {
        //               "content.page_remote_id": { match: page.remote_id },
        //             },
        //           ],
        //         },
        //       },
        //     ],
        //   },
        // ],
      }),
      params: { limit: LIMIT, offset },
    })
      .then(({ items, total }) => {
        setLoading(false);
        setTotal(total);
        if (offset !== 0) {
          setContacts((contacts) => contacts.concat(items.map(Decorator.Contacts.parse)));
        } else {
          setContacts(items.map(Decorator.Contacts.parse));
        }
      })
      .catch((xhr) => {
        setLoading(false);
        ErrorLogger.captureRequest("contact search error", xhr);
      });
  }, [searchVal, offset, page.remote_id, profile.name]);

  const handleClear = () => {
    onCheckChange(false, null);
    setSearchVal("");
  };

  const handleMarkChecked = (profile) => {
    if (matchedContactId === profile.id) {
      onCheckChange(false, null);
    } else {
      onCheckChange(true, profile);
    }
  };

  const renderConstituents = () => {
    return contacts.map((profile) => (
      <div
        key={profile.id}
        data-key={profile.id}
        className="match-facebook-result"
        onClick={() => handleMarkChecked(profile)}
      >
        <ContactCard contact={profile} target="_blank" />
        <Checkbox label={`match ${profile.name}`} checked={matchedContactId === profile.id} isRound />
      </div>
    ));
  };

  const renderSuggestedSearch = () => {
    const nameArray = profile.name.split(" ");
    if (nameArray.length > 0) {
      return nameArray.map((name, i) => [
        i > 0 && ", ",
        <LinkButton key={name} title={name} onClick={() => setSearchVal(name)}>
          "{name}"
        </LinkButton>,
      ]);
    }
  };

  return (
    <div>
      <div className="match-facebook-modal--header">
        <div className="match-facebook-modal--header-info">
          <div className="match-facebook-modal--header-info-data">
            <img alt="facebook logo" width="40" height="40" src={FB_IMAGE_URL} />
            <div>
              <strong>{profile.name}</strong>
              Engager Name
            </div>
          </div>
          <div className="match-facebook-modal--header-info-data">
            <img
              className="match-facebook-modal-page-image"
              alt={page.page_name}
              width="40"
              height="40"
              src={page && page.picture_url}
            />
            <div>
              <EllipsisOverflow className="match-facebook-modal-name">{page.page_name}</EllipsisOverflow>
              <Link href={postUrl} title="Recent Post" target="_blank" style={{ display: "flex" }}>
                <img
                  alt="facebook post"
                  height="18"
                  width="18"
                  src={`/images/fb-reactions-${engagement.type}.png`}
                  style={{ marginRight: "7px" }}
                />
                Recent Post
              </Link>
            </div>
          </div>
        </div>
        <div className="match-facebook-modal--header-divider" />
        <div className="match-facebook-modal--section">
          <div className="match-facebook-modal-text-title">Select a Constituent</div>
          Suggestions are based on constituent first and last name. Enter a nickname, previous last name, or other name
          for more suggestions.
        </div>
        <div style={{ textAlign: "center" }}>
          <SearchInput
            className="match-facebook-search-box"
            placeholder="Search for a constituent by name"
            value={searchVal}
            onChange={(value) => setSearchVal(value)}
            onClear={handleClear}
            width={340}
            debounceTimeout={500}
          />
        </div>
      </div>
      {loading ? <Loading size="small" /> : null}
      <div className="match-facebook-modal--body">
        {!_.isEmpty(contacts) ? (
          <div className="match-facebook-result-list loading-container">
            {renderConstituents()}
            {total > contacts.length && offset + LIMIT < total ? (
              <div style={{ textAlign: "center", height: 30, marginTop: 10 }}>
                <LinkButton title="Load more" onClick={() => setOffset((o) => o + LIMIT + 1)}>
                  Load More
                </LinkButton>
              </div>
            ) : null}
          </div>
        ) : (
          <div className="empty-state-message">
            Sorry no suggestions found. Try one of the following: <div>{renderSuggestedSearch()}</div>
          </div>
        )}
      </div>
    </div>
  );
};

MatchFacebook.propTypes = {
  profile: PropTypes.object.isRequired,
  engagement: PropTypes.object.isRequired,
  postUrl: PropTypes.string.isRequired,
  page: PropTypes.object,
  matchedContactId: PropTypes.number,
  onCheckChange: PropTypes.func,
  activeSearchVal: PropTypes.string,
  onSearch: PropTypes.func,
};

export default MatchFacebook;
