module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {div, span, h1} = ReactLibs.DOMFactories
   EverTrue = require("app")
   AllUsersStore = require("apps/users/stores/all-users-store").default
   MyTripsSource = require("apps/my-trips/sources/my-trips-source")
   MyTripsStore = require("apps/my-trips/stores/my-trips-store")
   MeetingsSource = require("apps/my-trips/sources/meetings-source")
   MeetingsStore = require("apps/my-trips/stores/meetings-store")
   TripContactsSource = require("apps/my-trips/sources/trip-contacts-source")
   TripActions = require("apps/my-trips/components/trip-actions")
   TripDate = require("apps/my-trips/components/trip-date")
   {createFactory} = require("base/new-utils")
   ItineraryController = createFactory require("apps/my-trips/controllers/itinerary-controller").default
   TripMapController = require("apps/my-trips/controllers/trip-map-controller")
   TripLogController = require("apps/my-trips/controllers/trip-log-controller")
   ActionMenu2 = createFactory(require("components/controls/action-menu2").default)
   Icon = createFactory (require("components/elements/icon").default)
   ShowHideAnimate = require("components/show-hide-animate")
   Facepile = require("components/elements/facepile")
   ModalTrigger = createFactory require("components/modals/modal-trigger")
   Icon = createFactory (require("components/elements/icon").default)
   TripForm = require("apps/my-trips/components/trip-form")
   LinkButton = createFactory(require("@evertrue/et-components").LinkButton)
   Modal2  = createFactory(require("@evertrue/et-components").Modal2)
   Fragment = createFactory(React.Fragment)
   AddEventModal = createFactory(require("apps/my-trips/components/add-event-modal").default)
   Button = createFactory(require("@evertrue/et-components").Button)

   createComponent "TripController",
      propTypes:
         trip_id: ReactLibs.PropTypes.number
         view: ReactLibs.PropTypes.string

      getDefaultProps: ->
         view: "itinerary"

      getInitialState: ->
         log_is_open: false
         is_event_modal_open: false

      registerStores: ->
         @on MyTripsStore, ->
            trip: MyTripsStore.getTrip(@props.trip_id)
            selected_user: MyTripsStore.getSelectedUser()
            loading: MyTripsStore.getLoading()
            collaborators: MyTripsStore.getTripCollaborators(@props.trip_id)

         @on MeetingsStore, ->
            meetings: MeetingsStore.getForTrip(@props.trip_id)

         @on AllUsersStore, ->
            users: AllUsersStore.getAllUsers()

      componentDidMount: ->
         MyTripsSource.setTrip(@props.trip_id)
         MyTripsSource.fetchTrip(@props.trip_id)
         MeetingsSource.fetchMeetingsForTrip(@props.trip_id)
         @loadTripContacts(@props.trip_id)

      UNSAFE_componentWillReceiveProps: (newProps) ->
         if newProps.trip_id != @props.trip_id
            @setState
               trip: MyTripsStore.getTrip(newProps.trip_id)
               meetings: MeetingsStore.getForTrip(newProps.trip_id)
            @loadTripContacts(newProps.trip_id)

      loadTripContacts: (trip_id) ->
         if trip_id
            TripContactsSource.fetchContactsForTrip(trip_id)

      handleBack: ->
         if window.history.length > 1 then window.history.back()
         else EverTrue.Navigator("/contact", true)

      handleSwitchView: (view) ->
         trip_route = "/trips/#{@props.trip_id}"
         if view == "map" then trip_route += "/map"
         EverTrue.Navigator trip_route, {trigger: true}

      handleToggleLog: ->
         @setState {log_is_open: !@state.log_is_open}

      renderLogToggle: ->
         LinkButton
            title: "Toggle trip log"
            className: "trip--log-toggle"
            onClick: @handleToggleLog

            Icon icon: "collapse", size: 2
            span className: "trip--log-toggle-label",
               "View Trip Log"

      handleCloseEventModal: ->
         @setState {is_event_modal_open: false}

      render: ->
         owner_name = @state.users?[@state.trip?.user_id]?.name
         current_user_id = EverTrue.store?.user?.get("id")
         Fragment null,
            # this modal is portaled so hoist it up her
            Modal2 isOpen: @state.is_event_modal_open, onClose: @handleCloseEventModal,
               AddEventModal({tripId: @props.trip_id, remove: @handleCloseEventModal})

            div className: "trip--header clearfix",
               LinkButton title: "Go back", className: "trip--back", onClick: @handleBack,
                  Icon icon: "chevron-left", size: 2

               div className: "trip--title",
                  Icon
                     className: "trip--header-icon"
                     icon: "my-trips"
                     size: 2

                  div className: "trip--data",
                     h1 tabIndex: -1, @state.trip?.name

                     div null,
                        TripDate trip: @state.trip
                        span className: "trip--owner",
                           if @state.trip?.user_id == current_user_id then " | Owned by you"
                           else if owner_name then " | Owned by #{owner_name}"

               if @state.collaborators?.length
                  div className: "trip--facepile",
                     Facepile
                        list: _.first(@state.collaborators, 3)
                        total: _.size(@state.collaborators)
                        overlayPosition: "bottom"
                        moreTooltip: div null,
                           _.map _.rest(@state.collaborators, 3), (user) ->
                              div className: "trip--facepile-tooltip", key: user.id, user.label

               if @state.trip?.is_my_trip
                  ModalTrigger
                     title: "Trip Form"
                     modal: TripForm({trip: @state.trip, manageCollaborators: true})
                     Icon
                        icon: "group-add"
                        size: 2
                        before: true


               ActionMenu2 className: "trip--actions",
                  TripActions trip: @state.trip || {id: @props.trip_id}, openEventModal: => @setState {is_event_modal_open: true}

            div className: "trip--controls",
               if @props.view == "itinerary"
                  Button
                     type: "simple"
                     disabled: _.isEmpty(@state.meetings)
                     onClick: () => @handleSwitchView("map")
                     Icon icon: "map gt-icon-lg"
                     "Map"
               else
                  Button
                     type: "simple"
                     onClick: () => @handleSwitchView("itinerary")
                     Icon icon: "columns gt-icon-lg"
                     "Itinerary"

               @renderLogToggle()

            div className: "trip--toggle"


            div className: "trip--body",
               ShowHideAnimate
                  show: @state.log_is_open
                  className: "trip--log-panel"
                  animateInClass: "slideInRight"
                  animateOutClass: "slideOutRight"

                  TripLogController
                     trip: @state.trip || {id: @props.trip_id}
                     onClose: @handleToggleLog

               if @props.view == "itinerary"
                  ItineraryController trip: @state.trip
               else
                  TripMapController trip: @state.trip
