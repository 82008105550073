module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   {createSource} = require("@evertrue/et-flux")


   createSource "WindowSource",
      actions:
         scroll: true
         outsideClick: true
         resize: (size) ->
            @require _.isObject(size), "size should be object"

      initialize: ->
         _.defer =>
            @actions.resize
               height: $(window).outerHeight()
               width: $(window).outerWidth()

         $(window).on "resize", =>
            @actions.resize
               height: $(window).outerHeight()
               width: $(window).outerWidth()

         $(window).on "scroll", =>
            @actions.scroll()

      api:
         outsideClick: ->
            # Trigger outside click, TODO: convert only to action
            $("body").trigger("click")
            @actions.outsideClick()

         bodyEscape: ->
            # Trigger close modal when escape only
            # TODO: convert only to action
            e = $.Event("keyup")
            e.which = 27
            e.keyCode = 27
            $("body").trigger(e)

         resize: ->
            $(window).trigger("resize")
