module.exports = do ->
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {span} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   Icon = createFactory (require("components/elements/icon").default)
   Link = createFactory(require("@evertrue/et-components").Link)

   createComponent "EventDetailLocation",
      propTypes:
         locationData: ReactLibs.PropTypes.object

      getDefaultProps: ->
         locationData: {},

      buildLocation: ->
         { name, street, city, state, zip, location } = @props.locationData
         location = ""
         if name
            location += "#{name} | "
         if street
            location += "#{street}, "
         if city
            location += "#{city}, "
         if state
            location += "#{state} "
         if zip
            location += "#{zip} "

         location

      render: ->
         location = @buildLocation()
         if location
            " | "
            span null,
               Link
                  href: "https://google.com/maps/search/#{location}",
                  title: "View event location in google maps"
                  target: "_blank",
                     Icon
                        icon: "map-marker-bts",
                        size: .5,
                        className: "event-detail-page-header--marker",
                     location
         else null
