import { fetchFromGraphql } from "entities/helpers/gqlCall";
import homepageQueries from "../gql/queries";

// The following few functions are only necessary because of some backend choices:
// Essentially, any time a Meeting is created (even an ad hoc meeting) a Trip is also created
// The distinction comes in the Trip record's "type" field. if it has a "type" of "trip" then it is actually a trip
// if it has a different type, what it really means is "this is a meeting"
// For example, if a Trip's "Type" is "phone" then it is a Phone Meeting, not actually a "trip" at all
const countTrips = (trips) => {
  return trips.filter(({ type = "" }) => type.toLowerCase() === "trip").length;
};

const tripTypeName = (trip) => {
  const { type = "" } = trip;
  if (!type) return trip.name || "";

  switch (type.toLowerCase()) {
    case "phone":
      return "Phone Meeting";
    case "virtual":
      return "Virtual Meeting";
    case "local":
      return "Local Meeting";
    case "trip":
      return trip.name;
    default:
      return trip.name;
  }
};

const countMeetings = (trips) => {
  return trips.reduce((acc, trip) => {
    if (trip.upcoming_meetings && trip.upcoming_meetings.length) return acc + trip.upcoming_meetings.length;
    else return acc;
  }, 0);
};

const appendContactDetailsToMeeting = (meeting) => {
  let contactCount = 0;
  let contactDetails = "";

  const { contact_meetings: contactMeetings = [] } = meeting;
  if (contactMeetings && contactMeetings.length) {
    contactCount = contactMeetings.length;
    const { contact } = contactMeetings[0] || {};
    if (contact && Object.keys(contact).length) {
      const { contact_attributes: { name_full: contactName } = {} } = contact;
      contactDetails = contactName;
    }
    if (contactMeetings.length > 1) contactDetails += ` + ${contactCount - 1}`;
  }

  meeting.contact_details = contactDetails;

  return meeting;
};

const processTrips = (trips) => {
  const tripCount = countTrips(trips);
  const meetingCount = countMeetings(trips);
  let meetings = [];

  trips.forEach((trip) => {
    let { upcoming_meetings = [] } = trip;
    if (upcoming_meetings && upcoming_meetings.length) {
      // add relevant details (trip name and contact name) to meeting object
      upcoming_meetings = upcoming_meetings.map((meeting) => ({
        ...appendContactDetailsToMeeting(meeting),
        trip_name: tripTypeName(trip),
      }));
    }
    // create an array of just the meetings
    meetings = [...meetings, ...upcoming_meetings];
  });

  return {
    tripCount,
    meetingCount,
    meetings,
  };
};

// Fetches trips for user in a given number of days
export const fetchTrips = async (userId, dayRange) => {
  const query = homepageQueries.fetchTrips;
  const queryVars = {
    userId,
    dayRange,
  };

  const { trips } = await fetchFromGraphql(query, queryVars, "trips");
  return processTrips(trips) || {};
};
