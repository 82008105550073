import { Icon } from "@evertrue/et-components";
import { useRef } from "react";
import PropTypes from "prop-types";

const SUPPORTED_FILE_EXTENSIONS =
  ".jpg,.jpeg,.png,.pdf,.doc,.docx,.ppt, .xlsx, .gif, .pptx, .mp4, .mov, .avi, .wmv, .mkv, .mp3, .wav, .zip, .txt, .rtf";

const FileUpload = ({ onFileChange = () => {}, setFiles, allowMultiple = false }) => {
  const fileInputRef = useRef(null);

  const handleFileChange = ({ target: { files } }) => {
    const selectedFiles = Array.from(files);
    onFileChange(selectedFiles);
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  return (
    <>
      <Icon
        icon="paperclip"
        className="email-attachment-btn"
        iconKit="gt2"
        onClick={handleClick}
        isReachable={true}
        onKeyUp={handleClick}
      ></Icon>
      <input
        type="file"
        multiple={allowMultiple ? "multiple" : ""}
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: "none" }}
        accept={SUPPORTED_FILE_EXTENSIONS}
      />
    </>
  );
};

FileUpload.propTypes = {
  onFileChange: PropTypes.func,
  setFiles: PropTypes.func,
  allowMultiple: PropTypes.bool,
};

export default FileUpload;
