import { PropTypes } from "base/react-libs";
import { createContext, useState, useMemo, useCallback, useEffect } from "react";
import Api from "entities/helpers/api";

const InteractionsContext = createContext(() => {});

const InteractionsProvider = ({ contact, children }) => {
  const [loading, setLoading] = useState(true);
  const [interactions, setInteractions] = useState([]);
  const [error, setError] = useState(null);

  const query = useMemo(
    () => ({
      must: [
        {
          target_id: {
            match: contact.id,
          },
          "secondary_target.target_id": {
            in: [contact.id],
          },
        },
        {
          pinned: {
            match: false,
          },
        },
      ],
      sort: [
        {
          date_occurred: {
            order: "desc",
          },
        },
      ],
    }),
    [contact.id]
  );

  const fetchInteractions = useCallback(async () => {
    setLoading(true);
    try {
      const data = await Api.SEARCH.CONTACT_NOTE.post({
        data: JSON.stringify(query),
        params: {},
      });

      setInteractions([...data.items]);
      setLoading(false);
    } catch (err) {
      handleError(err);
      setLoading(false);
    }
  }, [query]);

  const handleError = (err) => {
    if (err.status === 404) {
      setError("DELETED");
    }
    if (err.status === 403) {
      setError("ACCESS_DENIED");
    }
  };

  const addNewInteractionToState = (interaction) => setInteractions([interaction, ...interactions]);

  const removeInteractionFromState = (interaction) =>
    setInteractions(interactions.filter((c) => c.id !== interaction.id));

  useEffect(() => {
    fetchInteractions();
  }, [fetchInteractions]);

  return (
    <InteractionsContext.Provider
      value={{
        contact,
        loading,
        interactions,
        error,
        addNewInteractionToState,
        removeInteractionFromState,
      }}
    >
      {children}
    </InteractionsContext.Provider>
  );
};

InteractionsProvider.propTypes = {
  contact: PropTypes.object,
  children: PropTypes.any,
};

export { InteractionsProvider, InteractionsContext };
