import { PropTypes } from "base/react-libs";
import { useState } from "react";
import { Modal, ModalHeader, ModalBody, Button } from "@evertrue/et-components";

const DeleteModal = ({ isOpen, onClose, handleDelete, title, body, list_id }) => {
  const [submitting, setSubmitting] = useState(false);

  return (
    <Modal isOpen={isOpen} closeModal={onClose} size="small">
      <ModalHeader title={title} />
      <ModalBody>{body}</ModalBody>
      <div className="bookmark-delete-modal-actions">
        <Button type="simple" onClick={onClose}>
          Cancel
        </Button>
        <div style={{ margin: "0 20px" }}>
          <Button type="destroy" onClick={() => handleDelete(list_id, setSubmitting)} disabled={submitting}>
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
};

DeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  list_id: PropTypes.string.isRequired,
};

export default DeleteModal;
