module.exports = do ->
   _ = require("underscore").default
   classNames = require("classnames")
   {createStore} = require("@evertrue/et-flux")
   {div, span} = ReactLibs.DOMFactories
   PropertySource = require("apps/contact/sources/property-source")
   PropertyStore = require("apps/layout/stores/property-store")
   ContactTableRows = require("apps/contact/components/contacts/contact-table-rows")


   createStore "ContactTableStore",
      getInitialState: ->
         columns: _.cloneData(ContactTableRows)

      registerActions: ->
         @on PropertySource.actions.fetchedSchema, @respondToFetchedSchema

      getSortProp: (field) ->
         on_v1_filters = !!localStorage.getItem("filters_v2_opt_out") # don't allow sort by custom fields on v2 filters
         unless (field.data_type is "boolean" or not on_v1_filters) 
            "#{field.list_name}.#{field.name}#{if field.data_type is "string" then ".lowercase_sort" else ""}"

      respondToFetchedSchema: ->
         base_columns = _.cloneData(ContactTableRows)
         custom_column_config = _.mapObject PropertyStore.getCustomFields(), (field, key) =>
            width: if field.data_type is "string" then 224 else 176
            prop: @getSortProp(field)
            row: (data) ->
               display = _.compact _.map data?.customs?[field.list_name], (item) ->
                  val = item?[field.name]?.value

                  if field.data_type is "currency"
                     val = _.currencyFormatter(val.toString())
                  else if field.data_type is "boolean"
                     val = if val then "Y" else "N"

                  if _.isString(val) then val.trim() else val

               label = if display.length > 0 then display.join(", ") else "Not provided"
               div className: classNames("contact-fixed-table--custom-field-cell",
                  "text-right": field.data_type in ["currency", "number"]
                  "text-center": field.data_type == "boolean"
               ), "aria-label": "#{field.description}, #{label}",
                  span "aria-hidden":"true",
                     if !_.isEmpty(display)
                        _.map display, (val) ->
                           div key: val, span className: "fs-exclude-data",  val
                     else " - "

         @setState {columns: _.extend {}, base_columns, custom_column_config}

      api:
         getColumnConfig: ->
            @getState("columns")
