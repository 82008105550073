import _ from "underscore";
import { createSource } from "@evertrue/et-flux";
import Api from "entities/helpers/api";
import Decorator from "clientDecorator";
import { addSolicitorsToPool, trackVMSAction } from "apps/volunteers/utils";

let _request = null;
export const _getSolicitorQuery = (pool_id, filters) =>
  _.extend({}, Decorator.Filters.getQuery(filters).toJSON(), {
    sort: [{ "name_first.untouched": { order: "asc" } }, { "name_last.untouched": { order: "asc" } }],
    has_child: [
      {
        type: "assignment",
        query: {
          must: [{ pool_id: { match: pool_id } }, { parent_role: { match: "solicitor" } }],
        },
      },
    ],
  });

const PoolSolicitorSource = createSource("PoolSolicitorsSource", {
  actions: {
    loading: true,
    loadingMore: true,
    select: true,
    paginate: true,
    filterByName: true,
    fetch: true,
    fetchedSample: true,
    fetchedSolicitors(pool_id, prospects) {
      this.require(!_.isUndefined(pool_id), "pool_id is required");
      this.require(_.isPagedObject(prospects), "prospects must be a paged object");
    },
    increment(pool_id, solicitor_id) {
      this.require(!_.isUndefined(pool_id), "pool_id is required");
      this.require(!_.isUndefined(solicitor_id), "solicitor_id is required");
    },
    removedSolicitor(pool_id, solicitor_id) {
      this.require(!_.isUndefined(pool_id), "pool_id is required");
      this.require(!_.isUndefined(solicitor_id), "solicitor_id is required");
    },
    addedSolicitors(pool_id) {
      this.require(!_.isUndefined(pool_id), "pool_id is required");
    },
  },

  promise: {
    fetchByName(pool_id, name) {
      const filters = name ? { full_name: name } : {};
      const query = _getSolicitorQuery(pool_id, filters);

      return new Promise(resolve => {
        Api.CONTACTS.SEARCH.post({
          params: { limit: 50 },
          data: _.jsonStringify(query),
          success(resp) {
            resolve(_.map(resp.items, Decorator.Contacts.parse));
          },
          error() {
            resolve([]);
          },
        });
      });
    },
  },

  api: {
    fetch(pool_id) {
      this.actions.fetch(pool_id);
    },

    fetchSample(pool_id) {
      this.actions.loading(true);
      const query = _getSolicitorQuery(pool_id, {});

      Api.CONTACTS.SEARCH.post({
        params: { pool_id, limit: 5 },
        data: _.jsonStringify(query),
        success: resp => {
          const contacts = resp;
          contacts.items = _.map(resp.items, Decorator.Contacts.parse);
          this.actions.fetchedSample(pool_id, contacts);
          this.actions.loading(false);
        },
      });
    },

    fetchWithQuery(pool_id, filters, params) {
      if (_request) _request.abort();
      this.actions.loading(true);
      const query = _getSolicitorQuery(pool_id, filters);

      Api.CONTACTS.SEARCH.post({
        params,
        data: _.jsonStringify(query),
        beforeSend(xhr) {
          _request = xhr;
        },
        success: resp => {
          _request = null;

          const contacts = resp;
          contacts.items = _.map(resp.items, Decorator.Contacts.parse);
          this.actions.fetchedSolicitors(pool_id, contacts);
          this.actions.loading(false);
          this.actions.loadingMore(false);
        },
        error: () => {
          this.actions.loading(false);
          this.actions.loadingMore(false);
        },
      });
    },

    addSolicitors(pool_id, contact_ids) {
      this.actions.loading(true);
      addSolicitorsToPool(pool_id, contact_ids)
        .then(() => {
          this.actions.addedSolicitors(pool_id);
          _.wait(1000, () => {
            this.actions.fetch(pool_id);
          });
        })
        .catch(() => {
          this.actions.loading(false);
        });
    },

    removeSolicitor(pool_id, solicitor_id, contact_id) {
      trackVMSAction("pool_action", "remove_from_pool");
      this.actions.loading(true);

      Api.VOLUNTEER.SOLICITORS.delete({
        urlExtend: `/${solicitor_id}`,
        success: () => {
          this.actions.removedSolicitor(pool_id, solicitor_id, contact_id);
          _.wait(1000, () => {
            this.actions.fetch(pool_id);
          });
        },
        error: () => {
          this.actions.loading(false);
        },
      });
    },

    filterByName(pool_id, query) {
      this.actions.filterByName(pool_id, query);
    },

    paginate(pool_id) {
      this.actions.loadingMore(true);
      this.actions.paginate(pool_id);
    },

    select(solicitor_id) {
      this.actions.select(solicitor_id);
    },
  },
});

export default PoolSolicitorSource;
