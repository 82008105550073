module.exports = do ->
   $ = require("jquery")
   React = require("react")
   EverTrue = require("app")
   {createComponent} = require("@evertrue/et-flux")
   FeatureStore = require("apps/layout/stores/feature-store").default
   ListSource = require("apps/lists/sources/list-source")
   ListContactSource = require("apps/lists/sources/list-contact-source")
   {createFactory} = require("base/new-utils")
   Modal = createFactory require("components/modals/modal")
   ModalConfirm = createFactory require("components/modals/modal-confirm")
   ExportSearchController = createFactory require("apps/export/controllers/export-search-controller")
   ListSelectorController = createFactory(require("apps/lists/controllers/lists-selector-controller").default)
   ListShareSettings = require("apps/lists/components/list-share-settings")
   {div, strong, span} = ReactLibs.DOMFactories
   ActionMenuItem = createFactory(require("components/controls/action-menu-item").default)
   BulkListController = require("apps/lists/controllers/bulk-list-controller")
   EmmaPushController = createFactory require("apps/lists/components/emma-push-controller").default

   createComponent "ListActions",
      propTypes:
         list: ReactLibs.PropTypes.object
         resultCount: ReactLibs.PropTypes.number
         selectedContacts: ReactLibs.PropTypes.array
         onRemove: ReactLibs.PropTypes.func
         emma_accounts: ReactLibs.PropTypes.array
         emma_groups: ReactLibs.PropTypes.array
         onEmmaChange: ReactLibs.PropTypes.func

      registerStores: ->
         @on FeatureStore, ->
            has_emma: FeatureStore.hasFeature("emma")

      handleDeleteList: ->
         EverTrue.Alert.confirm
            content: "Deleted lists cannot be recovered. Are you sure you want to delete this list?"
            headline: span null, "Delete ",
               strong null, @props.list?.name
            buttonLabel: "Delete List"
         , (didConfirm) =>
            if didConfirm
               ListSource.delete(@props.list?.id)

      handleExport: (property_ids) ->
         ListContactSource.export(@props.list?.id, property_ids)

      handleRemove: ->
         $("body").trigger("click")
         @props.onRemove?()

      # This is duplicated in CollaboratorIconList
      renderShareModal: ->
         Modal width: 600, keepOpen: true,
            ModalConfirm header: "Sharing Settings", className: "share-settings--modal",
               ListShareSettings list_id: @props.list?.id, owner_id: @props.list?.user_id


      renderExportModal: ->
         Modal width: 600, keepOpen: true,
            ExportSearchController
               exportType: "List"
               onExport: @handleExport
               exportText: div null,
                  div className: "text-block", "You are about to export all constituent records for the list:"
                  strong null, @props.list?.name

      renderAddModal: ->
         BulkListController list: @props.list

      renderEmmaModal: ->
         Modal width: 500,
            EmmaPushController
               list: @props.list
               listResultCount: @props.resultCount
               accounts: @props.emma_accounts
               groups: @props.emma_groups
               onSuccess: @props.onEmmaChange
               onError: @props.onEmmaChange

      render: ->
         isOwner = @props.list?.user_id == EverTrue.store.user.get("id")
         selectedCount = @props.selectedContacts.length

         div className: "list-actions",
            if selectedCount
               ActionMenuItem
                  title: "Add #{selectedCount} Selected Constituents to Another List"
                  icon: "playlist-add"
                  modal: Modal width: 400,
                     ModalConfirm header: "Add Constituents to List",
                        ListSelectorController contactIds: @props.selectedContacts
                  "Add #{selectedCount} Selected Constituents to Another List"

            if selectedCount
               ActionMenuItem
                  title: "Remove #{selectedCount} Selected Constituents From List"
                  icon: "playlist-minus"
                  onClick: @handleRemove
                  "Remove #{selectedCount} Selected Constituents From List"

            ActionMenuItem
               title:  "Manage Collaborators"
               icon: "settings"
               modal: @renderShareModal(),
               "Manage Collaborators"

            ActionMenuItem
               title: "Export #{@props.resultCount} Constituents"
               icon: "cloud-download"
               modal: @renderExportModal()
               "Export #{@props.resultCount} Constituents"

            ActionMenuItem
               title: "Add Constituents to List"
               icon: "add"
               modal: @renderAddModal(),
               "Add Constituents to List"
            if @state.has_emma
               ActionMenuItem
                  title: "Send List to Emma"
                  icon: "email"
                  modal: @renderEmmaModal(),
                  "Send List to Emma"

            if isOwner && @props.list?.name != "Favorites"
               ActionMenuItem
                  title: "Delete List"
                  icon: "delete"
                  className: "text-danger"
                  onClick: @handleDeleteList
                  "Delete List"
