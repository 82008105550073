module.exports = do ->
   _ = require("underscore").default
   classNames = require("classnames")
   React = require("react")
   {createFactory} = require("base/new-utils")
   AvatarFactory = createFactory require("@evertrue/et-components").Avatar
   {createComponent} = require("@evertrue/et-flux")
   {div, span} = ReactLibs.DOMFactories
   Tooltip = require("components/overlays/tooltip")
   Popover = require("components/overlays/popover")

   createComponent "Facepile",
      propTypes:
         list: ReactLibs.PropTypes.array # of objs: {id:1, label: "Bob can edit", avatar: "<url>"}
         total: ReactLibs.PropTypes.number
         avatarWidth: ReactLibs.PropTypes.number
         popoverContent: ReactLibs.PropTypes.func
         overlayPosition: ReactLibs.PropTypes.string
         moreTooltip: ReactLibs.PropTypes.node
         onAvatarClick: ReactLibs.PropTypes.func
         onMoreClick: ReactLibs.PropTypes.func

      getDefaultProps: ->
         avatarWidth: 40

      renderAvatar: (item) ->
         AvatarFactory
            url: item.avatar
            width: @props.avatarWidth
            className: "facepile--avatar"
            name: item.name
            onClick: =>
               @props.onAvatarClick?(item)

      renderPopover: (item) ->
         Popover
            event: "hover"
            position: @props.overlayPosition || "top"
            className: "facepile--popover"
            style: {height: @props.avatarWidth}
            trigger: @renderAvatar(item)
            div className: "facepile--popover-content",
               @props.popoverContent?(item)

      renderTooltip: (item, content) ->
         Tooltip
            content: item.label
            position: @props.overlayPosition || "top"
            className: "facepile--tooltip"
            style: {height: @props.avatarWidth}
            content

      renderMore: (leftover) ->
         div
            className: "facepile--more"
            style:
               width: @props.avatarWidth
               height: @props.avatarWidth
               lineHeight: @props.avatarWidth + "px"
            onClick: @props.onMoreClick
            "+" + leftover

      render: ->
         show_popover = !!@props.popoverContent
         leftover = @props.total - _.size(@props.list)

         div className: classNames("facepile", @props.className),
            _.map @props.list, (item) =>
               span key: item.id, className: "facepile--item",
                  if show_popover then @renderPopover(item)
                  else if item.label then @renderTooltip(item, @renderAvatar(item))
                  else @renderAvatar(item)

            if @props.total && leftover > 0
               if @props.moreTooltip
                  @renderTooltip({label: @props.moreTooltip}, @renderMore(leftover))
               else @renderMore(leftover)
