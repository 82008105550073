module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   classNames = require("classnames")
   moment = require("moment")
   {createComponent} = require("@evertrue/et-flux")
   FacetStore = require("apps/filters/stores/facet-store")
   {div} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   AdvancedCombobox = createFactory(require("@evertrue/et-components").AdvancedCombobox)
   CalendarDropdown = createFactory(require("components/controls/calendar-dropdown"))

   #CONSTANTS
   COL_CLASS = "segment-filter-row--col"
   OPERATOR_CLASS = "segment-filter-row--operator-value"
   SUB_VALUE_CLASS = "segment-filter-row--sub-value"
   DATE_FORMAT = "YYYY-MM-DD"


   createComponent "FilterDateRange",
      propTypes:
         value: ReactLibs.PropTypes.object
         facets: ReactLibs.PropTypes.object
         onChange: ReactLibs.PropTypes.func

      handleParameterChange: (param) ->
         @props.onChange?({parameter: param})

      handleValueChange: (from, to) ->
         options = @getDateRangeOptions()
         shortcut = _.find options, (facet) ->
            facet.date_from == from && facet.date_to == to

         @props.onChange?({
            value: shortcut?.id || "range"
            range: if shortcut then undefined else {gte: from, lte: to}
         })

      getFacets: ->
         _.reduce(@props.facets?.fields, (memo, fields) ->
            memo[fields.type] = fields.value
            memo
         , {})

      getDateRangeOptions: ->
         current_date = new Date()
         facets = FacetStore.getByKey(@getFacets()?.time)
         _.compact _.map facets, (facet) ->
            if facet.format
               [number, string] = facet.format || []
               today = moment(current_date).format(DATE_FORMAT)
               if facet.id?.indexOf("+") != -1
                  from = today
                  to = moment(current_date).add(number, string).format(DATE_FORMAT)
               else
                  from = moment(current_date).subtract(number, string).format(DATE_FORMAT)
                  to = today

               id: facet.id
               date_from: from
               date_to: to
               title: facet.label

      getDateValue: (options) ->
         if @props.value?.value == "range"
            date_value =
               date_from: @props.value?.range?.gte
               date_to: @props.value?.range?.lte
         else
            date_value = _.findWhere options, {id: @props.value?.value}
            date_value ?= _.first(options)
         date_value

      render: ->
         date_range_options = @getDateRangeOptions()
         date_value = @getDateValue(date_range_options)

         div className: "filter-date-range",
            div className: classNames(COL_CLASS, OPERATOR_CLASS),
               AdvancedCombobox
                  options: FacetStore.getByKey(@getFacets()?.control)
                  value: @props.value?.parameter
                  onChange: @handleParameterChange

            div className: SUB_VALUE_CLASS,
               CalendarDropdown
                  date_from: moment(date_value.date_from).format(DATE_FORMAT)
                  date_to: moment(date_value.date_to).format(DATE_FORMAT)
                  date_range: date_range_options
                  onChange: @handleValueChange
