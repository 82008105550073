import { useContext, useEffect, useState } from "react";
import { Formik } from "formik";
import { TaskFormSchema } from "apps/tasks/utils/task-schema";
import { buildTaskDefaults, setTaskPayload } from "apps/tasks/utils/utils";
import { ModalBody, ModalHeaderWithActions, AdvancedCombobox } from "@evertrue/et-components";
import PropTypes from "prop-types";
import SessionStore from "base/session/session-store";
import DatePickerDropdown from "components/calendar/date-picker-dropdown";
import FormField from "components/forms/form-field";
import TaskContact from "./task-contact";
import useTasks from "apps/tasks/hooks/use-tasks";
import CharacterCounter from "apps/interactions/components/character-counter";
import { TasksContext } from "apps/tasks/hooks/tasks-context";
import AddUser from "apps/search/components/add-user";
import { fetchContactInfoById } from "apps/home/utils/api-requests/recommended-actions/use-recommended-actions-requests";

const ReviewSuggestedTaskForm = ({
  taskObj = {},
  handleClose = () => {},
  optionalStateManagementFunction = () => {},
}) => {
  const user = SessionStore.getUser();
  const { createTask, taskActionTypes } = useTasks();
  const { getAndSetTasks = () => {}, taskFilters } = useContext(TasksContext);
  const { contact_journey_id, suggestedTaskDefaults } = buildTaskDefaults(taskObj, user);
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    const fetchUserInfo = async () => {
      const userInfo = await fetchContactInfoById(taskObj.target_contact_id, false);
      const { id, profile_image, contact_attributes } = userInfo;
      const formatted = {
        id: id,
        name: contact_attributes.name_full,
        avatar: profile_image ? profile_image.url : null,
      };
      setUserInfo(formatted);
    };

    fetchUserInfo();
  }, [taskObj.target_contact_id]);

  const handleSubmit = async (values) => {
    const payload = setTaskPayload({ ...values, targetContact: userInfo });
    handleClose();

    const task = await createTask(payload);
    optionalStateManagementFunction("addTask", { task });

    getAndSetTasks(taskFilters);
  };

  const formTitle = "Review Task";

  // an extra check to allow users to submit an unedited AI task
  const AllFieldsPoulated = (values) => {
    return values.title && values.description && values.actionType && values.due_date;
  };

  return (
    <Formik
      initialValues={suggestedTaskDefaults}
      validationSchema={TaskFormSchema}
      onSubmit={(values) => handleSubmit(values)}
      validatOnMount={true}
    >
      {({ handleChange, handleSubmit, values, isValid }) => (
        <>
          <ModalHeaderWithActions
            title={formTitle}
            closeModal={handleClose}
            onSubmit={handleSubmit}
            disableSubmit={!isValid && !AllFieldsPoulated(values)}
          />
          <ModalBody scrollable={false}>
            <TaskContact
              targetContact={userInfo}
              setTargetContact={handleChange("targetContact")}
              disableDelete={contact_journey_id}
            />
            <FormField required label="Task Name" inlineLabel={false}>
              <input
                type="text"
                value={values.title}
                placeholder={`Enter the Task Name...`}
                onChange={handleChange("title")}
                disabled={!!contact_journey_id}
              />
              <CharacterCounter limit={120} count={values.title.length} />
            </FormField>
            <FormField label="Description" inlineLabel={false}>
              <textarea
                value={values.description}
                placeholder="Add Details..."
                onChange={handleChange("description")}
                disabled={!!contact_journey_id}
              />
              <CharacterCounter limit={250} count={values.description.length} />
            </FormField>
            <div className="task-options">
              <FormField required={!contact_journey_id} label="Action Type" inlineLabel={false}>
                <AdvancedCombobox
                  options={taskActionTypes}
                  onChange={handleChange("actionType")}
                  value={values.actionType}
                  placeholder="Select Type..."
                  disabled={!!contact_journey_id}
                />
              </FormField>
              <FormField label="Assignee" inlineLabel={false}>
                <AddUser handleOnChange={handleChange("owner")} value={values.owner} disabled={!!contact_journey_id} />
              </FormField>
            </div>
            <FormField label="Due Date" inlineLabel={false}>
              <DatePickerDropdown
                date={values.due_date}
                onDayClick={handleChange("due_date")}
                triggerClassName={"task-calendar"}
                chevronClassName={"date-picker-chevron"}
                allowClear
              />
            </FormField>
          </ModalBody>
        </>
      )}
    </Formik>
  );
};

ReviewSuggestedTaskForm.propTypes = {
  handleClose: PropTypes.func,
  taskObj: PropTypes.object,
  optionalStateManagementFunction: PropTypes.func,
};

export default ReviewSuggestedTaskForm;
