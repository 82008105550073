module.exports = do ->
   _ = require("underscore").default
   {createStore} = require("@evertrue/et-flux")
   ProfileEventsSource = require("apps/profile/sources/profile-events-source")

   createStore "ProfileEventsStore",
      getInitialState: ->
         contact_event_engagements: {}

      registerActions: ->
         @on ProfileEventsSource.actions.profileEventEngagementsSuccess, (contact_id, event_engagements) ->
            @mergeState "contact_event_engagements", _.toObject(contact_id, event_engagements)

      getData: (contact_id) ->
         @getState("contact_event_engagements")[contact_id]

      api:
         getEventEngagements: (contact_id) ->
            @getData(contact_id)?.items

         getEventEngagementCount: (contact_id) ->
            @getData(contact_id)?.total

         getEventEngagementIcons: (contact_id) ->
            _.pluck(@getData(contact_id)?.facets?.engagement_action, "value")

