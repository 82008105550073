import { PropTypes } from "base/react-libs";

const SummaryRow = ({ title, children }) => {
  return (
    <div className="contact-summary--header-row">
      <div className="bold-text">{title}</div>
      <div>{children}</div>
    </div>
  );
};

SummaryRow.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
};

export default SummaryRow;
