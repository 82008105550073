import { useContext } from "react";
import classNames from "classnames";
import ModalTrigger from "components/modals/modal-trigger";
import { Button, Icon } from "@evertrue/et-components";
import PropTypes from "prop-types";
import { ActionMenu2ClosePopoverContext } from "components/controls/action-menu2";

const ActionMenuItem = (props) => {
  const actionMenu2ClosePopoverContext = useContext(ActionMenu2ClosePopoverContext);

  const handleKeyPress = (event) => {
    if (event.which === 13 && !props.disable) {
      props.onClick && props.onClick();
      if (actionMenu2ClosePopoverContext) {
        actionMenu2ClosePopoverContext();
      }
    }
  };

  const handleClick = () => {
    props.onClick && props.onClick();
    if (actionMenu2ClosePopoverContext) {
      actionMenu2ClosePopoverContext();
    }
  };

  return (
    <div className={classNames("action-menu-item", props.className, { "is-disabled": props.disable })}>
      {props.modal ? (
        <ModalTrigger
          modal={props.modal}
          disable={props.disable}
          title={props.title}
          onClick={() => {
            if (actionMenu2ClosePopoverContext) {
              actionMenu2ClosePopoverContext();
            }
          }}
        >
          {props.icon ? <Icon icon={props.icon} className="action-menu-item--icon" /> : undefined}
          <span className={classNames("action-menu-item--label", { "no-icon": !props.icon })}>{props.children}</span>
        </ModalTrigger>
      ) : (
        <Button
          type="simple"
          aria-label={props.title}
          href={props.href}
          title={props.title}
          onKeyPress={handleKeyPress}
          onClick={!props.disable ? handleClick : undefined}
          disabled={props.disable}
          hrefTarget={props.openNewTab ? "_blank" : undefined}
        >
          {props.icon ? <Icon icon={props.icon} className="action-menu-item--icon" /> : undefined}
          <span className={classNames("action-menu-item--label", { "no-icon": !props.icon })}>{props.children}</span>
        </Button>
      )}
    </div>
  );
};

ActionMenuItem.propTypes = {
  children: PropTypes.any,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  modal: PropTypes.node,
  href: PropTypes.string,
  disable: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  openNewTab: PropTypes.bool,
};

export default ActionMenuItem;
