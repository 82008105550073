module.exports = do ->
   _ = require("underscore").default
   {createStore} = require("@evertrue/et-flux")
   FacebookSource = require("apps/facebook/sources/facebook-source")

   createStore "FacebookPagesStore",
      getInitialState: ->
         pages: []
         oauth: undefined
         loading_pages: false

      registerActions: ->
         @on FacebookSource.actions.fetchedPages, @respondToFetchedPages
         @on FacebookSource.actions.fetchedOauth, @respondToFetchedOauth
         @on FacebookSource.actions.pagesLoading, (loading) -> @setState loading_pages: loading

      respondToFetchedPages: (data) ->
         @setState pages: data

      respondToFetchedOauth: (data) ->
         @setState oauth: data

      api:
         getPages: ->
            @getState("pages")

         getLoading: ->
            @getState("loading_pages")

         getById: (id) ->
            pages = @getState("pages")
            _.findWhere pages, {remote_id: id}

         getOauth: ->
            @getState("oauth")
