import { PropTypes } from "base/react-libs";
import { useState } from "react";
import { Modal, ModalHeaderWithActions, ModalBody, ListboxSelect } from "@evertrue/et-components";
import DatePickerDropdown from "components/calendar/date-picker-dropdown";
import JourneyContactCard from "apps/journeys/components/journey-contact-card";
import useTasks from "apps/tasks/hooks/use-tasks";
import FormField from "components/forms/form-field";
import { trackJourneysAction } from "apps/journeys/utils/helpers/mixpanel";
import { createJourneyMove } from "apps/tasks/utils/journey-move-helper.js";

const options = [
  {
    label: "Timing is not appropriate for given constituent",
    value: "Timing is not appropriate for given constituent",
  },
  {
    label: "Constituent requested communication for later date",
    value: "Constituent requested communication for later date",
  },
  { label: "Ran out of time to complete today", value: "Ran out of time to complete today" },
  { label: "Too late for communication due to time zone", value: "Too late for communication due to time zone" },
  {
    label: "Waiting for additional follow up from campus stakeholder",
    value: "Waiting for additional follow up from campus stakeholder",
  },
  { label: "Other", value: "Other" },
];

const EditTaskDueDateModal = ({ isOpen, onClose, task, step, optionalStateManagementFunction = () => {} }) => {
  const [selectedDueDate, setSelectedDueDate] = useState(task.due_date);
  const [reasonDetails, setReasonDetails] = useState("");
  const [selectedOption, setSelectedOption] = useState("");

  const { updateTask, getTaskById } = useTasks();

  const handleSubmit = async () => {
    const time = new Date(selectedDueDate).getTime() / 1000;
    const taskObj = await getTaskById(task.id);
    await createJourneyMove(task.contact, "EDIT_DUE_DATE", selectedOption, reasonDetails, undefined, task.id, step.id);
    let payload = taskObj;
    task.due_date = selectedDueDate;
    payload.due_date = time;

    updateTask(payload, taskObj);
    const selectedDateObj = new Date(selectedDueDate);
    const selectedTime = Math.floor(selectedDateObj.getTime() / 86400000);
    const todayTime = Math.floor(new Date().getTime() / 86400000);
    if (selectedTime > todayTime) optionalStateManagementFunction("removeTask", { task, step });
    else optionalStateManagementFunction("updateTask", { task, step });
    trackJourneysAction("edit_due_date");

    // will still need an api call to handle the reason why duedate was changed
    onClose();
  };

  const handleReasonChange = (newReason) => {
    // Reset reasonDetails if the reason is changed to something else
    if (newReason.value !== "Other") {
      setReasonDetails("");
    }
    setSelectedOption(newReason);
  };

  return (
    <Modal isOpen={isOpen} closeModal={onClose}>
      <ModalHeaderWithActions
        title="Edit Due Date"
        closeModal={onClose}
        onSubmit={handleSubmit}
        disableSubmit={!selectedOption || (selectedOption.value === "Other" && !reasonDetails)}
      />
      <ModalBody scrollable={false}>
        <p className="journey-modal--warning">
          The due date for the sequential cadence steps will adjust accordingly based on the new due date you set.
        </p>

        <JourneyContactCard contact={task.contact} avatarSize={60} />

        <FormField required={false} label="Current Step" inlineLabel={false} boldLabel={true}>
          <div>
            Step {step.step_number} | Day {step.day_number}
          </div>
          <div>{step.title}</div>
        </FormField>
        <FormField label="Due Date" inlineLabel={false}>
          <DatePickerDropdown
            date={selectedDueDate}
            onDayClick={(date) => setSelectedDueDate(date)}
            triggerClassName={"task-edit-date-picker"}
            chevronClassName={"date-picker-chevron"}
            allowClear={false}
          />
        </FormField>
        <FormField label="Reason" inlineLabel={false}>
          <ListboxSelect
            size="medium"
            options={options}
            onChange={handleReasonChange}
            value={selectedOption}
            emptyValue="Select Reason"
            label="Select Reason"
          />
        </FormField>
        {selectedOption && selectedOption.value === "Other" && (
          <FormField label="Details" inlineLabel={false}>
            <input type="text" value={reasonDetails} onChange={(e) => setReasonDetails(e.currentTarget.value)} />
          </FormField>
        )}
      </ModalBody>
    </Modal>
  );
};

EditTaskDueDateModal.propTypes = {
  task: PropTypes.object,
  step: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  optionalStateManagementFunction: PropTypes.func,
};
export default EditTaskDueDateModal;
