module.exports = do ->
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   moment = require("moment")
   {createFactory} = require("base/new-utils")
   AdvancedCombobox = createFactory(require("@evertrue/et-components").AdvancedCombobox)
   _ = require("underscore").default

   _generateTimes = (increment) ->
      dateTime = new Date(1970, 0, 1, 0, 0, 0, 0)
      time_arr = []
      while dateTime.getDate() == 1
         time_arr.push({
            value: moment(dateTime).format()
            label: moment(dateTime).format("hh:mm a")
         })
         dateTime.setMinutes(dateTime.getMinutes() + increment)
      return time_arr

   createComponent "TimeDropdown",
      propTypes:
         increment: ReactLibs.PropTypes.number
         onChange: ReactLibs.PropTypes.func
         value: ReactLibs.PropTypes.any
         disabled: ReactLibs.PropTypes.bool

      getDefaultProps: ->
         increment: 30

      UNSAFE_componentWillMount: ->
         @times_arr = _generateTimes(@props.increment)

      render: ->
         index = if @props.value
            _.findIndex(@times_arr, (time) => time.label == @props.value.label)
         else 0

         AdvancedCombobox
            className: @props.className
            value:  @times_arr[index]
            options: @times_arr
            onChange: @props.onChange
            disabled: @props.disabled
