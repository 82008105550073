import { PropTypes } from "base/react-libs";

const DialerNotes = ({ handleNoteChange, notes }) => (
  <div className="dialer--input-note">
    <textarea
      value={notes}
      placeholder="Call Notes"
      onChange={(e) => {
        handleNoteChange(e.currentTarget.value);
      }}
      className="dialer-note-textarea"
    />
  </div>
);

DialerNotes.propTypes = {
  handleNoteChange: PropTypes.func,
  notes: PropTypes.string,
};

export default DialerNotes;
