module.exports = do ->
   _ = require("underscore").default
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")


   createSource "ProposalSearchSource",
      actions:
         reset: true
         sort: true
         paginate: true
         loading: true
         queryForContact: true
         queryWithFilters: true
         setProfileFilter: true
         fetchedProposals: true
         setContact: true

      api:
         fetch: (data) ->
            @actions.loading(true)

            Api.SEARCH.PROPOSAL.post
               data: _.jsonStringify(data?.query || {})
               params: data.params
               success: (resp) =>
                  @actions.fetchedProposals(resp)
                  @actions.loading(false)

         queryWithFilters: ->
            @actions.queryWithFilters()

         queryForContact: (contact_id) ->
            @actions.queryForContact(contact_id)

         reset: ->
            @actions.reset()

         sort: (prop, reverse, silent) ->
            @actions.sort(prop, reverse, silent)

         paginate: (page) ->
            @actions.paginate(page)

         setProfileFilter: (filter_value) ->
            @actions.setProfileFilter(filter_value)

         setContact: (contact_id) -> @actions.setContact(contact_id)

