import { useState } from "react";
import _ from "underscore";
import EverTrue from "app";
import PropTypes from "prop-types";
import Api from "entities/helpers/api";
import { ModalBody, ModalHeaderWithActions } from "@evertrue/et-components";
import ContactTagList from "apps/contact/components/contact-tag-list";
import TeamDropdown from "./team-dropdown";

const submit = (pool_id, selected_contacts, assignmentType, excludeExisting) => {
  const ids = _.pluck(selected_contacts, "id");
  return Api.VOLUNTEER.SOLICITORS.post({
    urlExtend: "/bulk",
    params: { pool_id, pool_type: assignmentType, exclude_existing: excludeExisting },
    data: _.jsonStringify(_.map(ids, (id) => ({ contact_id: id }))),
  });
};

const AddNewSolicitorsModal = ({ refreshData, close, assignmentType, excludeExisting }) => {
  const [selectedTeam, setSelectedTeam] = useState({});
  const [selected_contacts, setSelectedContacts] = useState([]);

  return (
    <>
      <ModalHeaderWithActions
        title="New Solicitors"
        disableSubmit={_.isEmpty(selected_contacts) || !selectedTeam.id}
        closeModal={close}

        onSubmit={() => {
          submit(selectedTeam.id, selected_contacts, assignmentType, excludeExisting)
            .then((resp) => {
              if(!resp || resp.length === 0){
                const selectedSolicitorNames = selected_contacts.map(contact => contact.name).join(', ');
                const teamName = selectedTeam.name;
                // Determine pluralization based on contact count
                const pluralization = selected_contacts.length === 1 ? 'is' : 'are';
                EverTrue.Alert.error(`Please add new solicitor(s)  as ${selectedSolicitorNames}  ${pluralization} already part of the ${teamName} team.`);
              }
              else {
              EverTrue.Alert.success("New Solicitor(s) Added");
              setTimeout(refreshData, 1000);
            }
            })
            .catch(() => {
              EverTrue.Alert.error("There was a problem adding this Solicitor to the Team");
            });
          close();
        }}
      />
      <ModalBody scrollable={false}>
        <div className="new-solicitor-modal--text">Select Team to Add the Solicitor to</div>
        <TeamDropdown
          className="new-solicitor-modal--dropdown"
          value={{ value: selectedTeam.id }}
          onChange={(team) => {
            setSelectedTeam(team);
          }}
        />

        <div className="new-solicitor-modal--text">Search for Solicitors to Add</div>
        <ContactTagList selected={selected_contacts} onSelect={(selected) => setSelectedContacts(selected)} />
      </ModalBody>
    </>
  );
};

AddNewSolicitorsModal.propTypes = {
  assignmentType: PropTypes.string,
  close: PropTypes.func.isRequired,
  refreshData: PropTypes.func,
  excludeExisting: PropTypes.bool,
};

export default AddNewSolicitorsModal;
