import _ from "underscore";
import { useEffect, useState } from "react";
import Api from "entities/helpers/api";
import { useGate } from "components/is-gated";

export const useDashboard = (userId) => {
  const [hasEverhubGate, everhubGateLoaded] = useGate("everhub");
  const [loading, setLoading] = useState(true);
  const [widgetsLoaded, setWidgetsLoaded] = useState(false);
  const [widgets, setWidgets] = useState([]);

  useEffect(() => {
    if (userId) {
      const fetchWidgets = async () => {
        try {
          setLoading(true);
          const widgets = await Api.HUB.WIDGETS.get({ params: { solicitor_user_id: userId } });
          const webWidgets = _.filter(widgets, (widget = {}) => {
            return _.contains(widget.platforms, "web");
          });
          setWidgets(webWidgets);
          setWidgetsLoaded(true);
          setLoading(false);
        } catch {
          setLoading(false);
        }
      };

      fetchWidgets();
    }
  }, [userId]);

  return {
    has_dashboard: hasEverhubGate && !_.isEmpty(widgets),
    completed_dashboard_checks: everhubGateLoaded && widgetsLoaded,
    loading: !everhubGateLoaded || loading,
    widgets: widgets,
  };
};
