module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories


   createComponent "EmptyChart",
      propTypes:
         height: ReactLibs.PropTypes.number

      render: ->
         div
            className: classNames(@props.className, "etchart-empty")
            style: {position: "relative", height: @props.height}

            div
               className: "charting--empty-message"
               style: {marginTop: -30}
               "No data available"
