import { useState } from "react";
import PropTypes from "prop-types";
import _ from "underscore";
import str from "underscore.string";
import { Modal, ModalBody, ModalHeader, Button } from "@evertrue/et-components";
import Api from "entities/helpers/api";
import { useOrg } from "base/org/with-org";
import { CONFIRM_STRING, FORMATTED_CONFIRM_STRING } from "apps/volunteers/constants";

const BulkDeletePoolsModal = ({ isOpen, closeModal, pools }) => {
  const [confirmString, setConfirmString] = useState("");
  const pools_text = `pool${pools.length > 1 ? "s" : ""}`;
  const org = useOrg() || {};
  const disableSubmit = confirmString.toLowerCase().trim() !== CONFIRM_STRING;

  const onSubmit = () => {
    const pool_ids = _.pluck(pools, "id");
    Api.VOLUNTEER.POOLS.delete({
      urlExtend: "/bulk",
      data: _.jsonStringify({
        pool_ids,
      }),
    });
    closeModal();
  };

  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      <ModalHeader title={`Confirm Deletion of ${pools.length} ${str.titleize(pools_text)}`} closeModal={closeModal} />
      <ModalBody>
        <strong>The following {pools_text} will be affected:</strong>
        <ul className="confirm-reset-pools-modal--list">
          {pools.map((pool) => (
            <li key={pool.id}> {pool.name}</li>
          ))}
        </ul>
        <div className="confirm-reset-pools-modal--disclaimer">
          You must agree to confirm that this action will be completed. Please type {FORMATTED_CONFIRM_STRING} to
          complete these actions. ({pools.length} {pools_text} will be affected by this update)
        </div>
        <div className="confirm-reset-pools-modal--confirm-string">
          This will impact all {org.name} users and cannot be undone.
        </div>
        <div className="confirm-reset-pools--actions">
          <input
            type="text"
            placeholder={FORMATTED_CONFIRM_STRING}
            className="confirm-reset-pools-modal--input"
            value={confirmString}
            onChange={({ target: { value: confirmString } }) => setConfirmString(confirmString)}
          />
          <Button type="default" disabled={disableSubmit} onClick={onSubmit}>
            Confirm
          </Button>
          <Button type="secondary" onClick={closeModal}>
            Cancel
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

BulkDeletePoolsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  pools: PropTypes.array,
};

export default BulkDeletePoolsModal;
