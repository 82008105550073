import PropTypes from "prop-types";
import LinesEllipsis from "react-lines-ellipsis";
import { Link } from "@evertrue/et-components";
import ModalTrigger from "components/modals/modal-trigger";
import Modal from "components/modals/modal";
import MatchFacebookModal from "apps/facebook/components/match-facebook-modal";
import FacebookReactions from "apps/facebook/helpers/facebook-reactions-utils";

const FacebookEngagementProfile = ({ page, postUrl, profile, engagement, onMatch }) => {
  const matchModal = (
    <Modal width={570}>
      <MatchFacebookModal page={page} profile={profile} postUrl={postUrl} engagement={engagement} onMatch={onMatch} />
    </Modal>
  );

  const displayEngagement =
    engagement.type === "comment" ? (
      <LinesEllipsis text={engagement.contents} maxLine="2" basedOn="letters" style={{ "word-break": "break-all" }} />
    ) : (
      <img alt="view facebook post" height="24" width="24" src={FacebookReactions.getIconFor(engagement.type)} />
    );

  return (
    <div className="post-engagers--profile" key={profile.id}>
      <strong className="fs-exclude-data">{profile.name}</strong>
      <Link className="post-engagers--profile-link" href={postUrl} target="_blank" title="Recent Post">
        {displayEngagement}
      </Link>
      <ModalTrigger buttonType="secondary" modal={matchModal} title="Match to constituent">
        Match to Constituent
      </ModalTrigger>
    </div>
  );
};

FacebookEngagementProfile.propTypes = {
  page: PropTypes.object,
  postUrl: PropTypes.string,
  profile: PropTypes.object,
  engagement: PropTypes.object,
  onMatch: PropTypes.func,
};

FacebookEngagementProfile.defaultProps = {
  page: {},
  postUrl: "",
  profile: {},
  engagement: {},
  onMatch: () => null,
};

export default FacebookEngagementProfile;
