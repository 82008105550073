module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   EverTrue = require("app")
   Orgs = require("entities/auth/orgs")
   Gate = require("entities/dna/gate")
   Gates = require("entities/dna/gates")
   GateSource = require("apps/layout/sources/gate-source")
   Backbone = require("backbone")
   str = require("underscore.string")

   AdminGateStore = ->
      organizations = {}
      features = []
      default_gates = {}
      _org_gate_cache = {}

      _applyFeaturesToOrgs = ->
         organizations.each (org) ->
            org_features = _.clone(_org_gate_cache[org.get("id")])
            org.set "features", org_features, {silent: true}
         organizations.trigger("reset")

      _toastMessage = (key, value, platforms, org) ->
         platform = if platforms then platforms.join(" & ") else "iOS & Web"
         message = "#{str.humanize(key)} "
         if value then message += "enabled on #{platform} for "
         else message += "disabled for "
         message += org || "everyone"

      _emptyPromise = ->
         dfd = $.Deferred()
         dfd.resolve()
         dfd.promise()


      api = $.extend true, {}, Backbone.Events,
         initialize: ->
            features = []
            organizations = new Orgs()
            organizations.limit = 5
            organizations.toJSON()

         bindToUpdates: (callback) ->
            @listenTo organizations, "reset", ->
               data = organizations.toCollectionWrapper()
               data.items = organizations.getPage()
               callback(data) if _.isFunction callback

         unbind: ->
            @stopListening()

         getOrgs: (page) ->
            if page
               organizations.setPage(page)
               organizations.trigger("reset")
               _emptyPromise()
            else
               organizations.fetch({reset: true})

         getFeatures: (callback) ->
            if !_.isEmpty(features)
               callback?(features)
            else
               default_gates = new Gates()
               default_gates.fetchDefaults
                  success: ->
                     features = default_gates.pluck("name")
                     callback?(features)

         getGates: ->
            # Select keys for page and compare to cache
            ids = _.map _.pluck(organizations.getPage(), "id"), (id) -> id.toString()
            ids = _.difference ids, _.keys(_org_gate_cache)

            # If there are not new ids, apply features to org models and return
            if _.isEmpty(ids)
               _applyFeaturesToOrgs()
               return _emptyPromise()

            # For each platform & default fetch all org gates
            platforms = ["web", "ios", null]
            requests = _.map platforms, (platform) ->
               platform_gates = new Gates()
               platform_gates.fetch
                  params: {oids: ids, platform: platform}

            $.when.apply(@, requests).then (args...) ->
               gates = new Gates()
               _.each args, (resp) ->
                  gates.add(gates.parse(resp[0]), {silent: true, merge: true})

               $.extend true, _org_gate_cache, gates.toOrgList()
               _applyFeaturesToOrgs()

         updateGate: (key, oid, platforms) ->
            enabling = !_.isUndefined(platforms)
            all_platforms = ["ios", "web"]
            org = organizations.get(oid)

            requests = _.map all_platforms, (platform) ->
               gate = new Gate({name: key})
               gate.save null,
                  disableAlerts: true
                  params:
                     enabled: _.contains(platforms, platform)
                     platform: platform
                     oid: oid
                  success: ->
                     org_features = _org_gate_cache[oid]?[key] || []
                     feature = _.findWhere org_features, {platform: gate.get("platform")}
                     if feature then $.extend feature, gate.toJSON()
                     else org_features.push(gate.toJSON())

                     obj = {}
                     obj[key] = org_features
                     $.extend _org_gate_cache[oid], obj

            $.when(requests).done ->
               if oid == EverTrue.store.org.get("id")
                  GateSource.fetch()

               EverTrue.Alert.success(
                  _toastMessage(key, enabling, platforms, org.get("name"))
               )

         updateDefaultGate: (key, enabled) ->
            gate = default_gates.findWhere {name: key}
            gate.set {enabled: enabled}
            gate.updateDefault
               disableAlerts: true
               success: ->
                  _org_gate_cache = {}
                  EverTrue.Alert.success(_toastMessage(key, enabled))

         deprecateGate: (key) ->
            gate = new Gate({name: key, id: key})
            gate.deprecate {params: {oid: null}}

         filter: (key) ->
            query = /// \b#{key} ///i
            organizations.unsetFiltered(filtered, {silent: true})
            unless _.isEmpty(key)
               filtered = _.filter organizations.toJSON(), (org) ->
                  org.name?.match(query) || org.slug?.match(query)
               organizations.setFiltered(filtered, {silent: true})
            organizations.trigger("reset")
            _emptyPromise()

         getFeatureValue: (key, data) ->
            enabled = _.filter data.features?[key], (feature) -> feature.enabled && feature.platform
            platforms = _.pluck(_.compact(enabled), "platform")
            platforms = _.sortBy platforms, (platform) -> platform
            if _.isEmpty(platforms) then "none" else platforms?.join("|")

   AdminGateStore()
