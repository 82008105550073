module.exports = do ->
   _ = require("underscore").default
   {createSource} = require("@evertrue/et-flux")


   createSource "CoreSearchSource",
      actions:
         query: (type) ->
            @require _.isString(type), "type should be a string"
         setType: (type) ->
            @require _.isString(type), "type should be a string"
         setResultCount: (type, count) ->
            @require _.isString(type), "type should be a string"
            @require _.isNumber(count), "count should be a number"

      api:
         query: (type) ->
            @actions.query(type)

         setType: (type) ->
            @actions.setType(type)

         setResultCount: (type, count)  ->
            if type && (_.isNumber(count) && !_.isNaN(count))
               @actions.setResultCount(type, count)
