import _ from "underscore";
import { useState, useEffect } from "react";
import Api from "entities/helpers/api";

export const useTeamDashboard = () => {
  // load the user's widgets
  const [loading, setLoading] = useState(true);
  const [widgets, setWidgets] = useState([]);

  useEffect(() => {
    let didCancel = false;
    setLoading(true);
    Api.HUB.TEAM_WIDGETS.get({
      // need to have this in a success callback so when the session expires the dashboard
      // will refresh properly
      success: (resp) => {
        // only return widgets that are enabled for "web"
        const filtered_widgets = _.filter(resp, (widget = {}) => {
          return _.contains(widget.platforms, "web");
        });
        if (!didCancel) {
          setWidgets(filtered_widgets);
          setLoading(false);
        }
      },
      error: () => {
        if (!didCancel) {
          setLoading(false);
        }
      },
    });

    return () => {
      didCancel = true;
    };
  }, []);

  return {
    loading: loading,
    widgets: widgets,
  };
};
