import { Icon, Button } from "@evertrue/et-components";
import PropTypes from "prop-types";

const MapZoomControl = ({ map }) => {
  return (
    <div className="map-zoom--container">
      <Button className="map-zoom--button map-toolbar--button" type="secondary" onClick={() => map.setZoom(map.getZoom() + 1)}>
        <Icon size={2} icon="add" />
      </Button>
      <Button className="map-zoom--button map-toolbar--button" type="secondary" onClick={() => map.setZoom(map.getZoom() - 1)}>
        <Icon size={2} icon="minus" />
      </Button>
    </div>
  );
};

MapZoomControl.propTypes = {
  map: PropTypes.object,
};

export default MapZoomControl;
