module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   EverTrue = require("app")
   {createComponent} = require("@evertrue/et-flux")
   {div, span} = ReactLibs.DOMFactories
   Decorator = require("clientDecorator")
   Address = require("apps/profile/components/templates/address")
   Email = require("apps/profile/components/templates/email")
   Phone = require("apps/profile/components/templates/phone")
   AddressMixin = require("mixins/address-mixin")
   {createFactory} = require("base/new-utils")
   ProfileCardSubtitle = createFactory require("apps/profile/components/profile-card-subtitle").default
   Icon = createFactory (require("components/elements/icon").default)
   FeatureStore = require("apps/layout/stores/feature-store").default
   ModalTriggerDeprecated = createFactory require("@evertrue/et-components").ModalTriggerDeprecated
   ModalWrapper = createFactory require("@evertrue/et-components").ModalWrapper
   CDCModalController = createFactory require("apps/profile/components/cdc/cdc-modal-controller").default


   createComponent "BioContactInfo",
      propTypes:
         contact: ReactLibs.PropTypes.object
         hasHeader: ReactLibs.PropTypes.bool

      getDefaultProps: ->
         contact: {}
         hasHeader: true

      registerStores: ->
         @on FeatureStore, ->
            has_cdc_profile: FeatureStore.hasFeature("cdc_profile")

      handleTrack: (type) ->
         EverTrue.track.set "contact_action",
            type: type
            referrer: "profile_section_bio_all"

      renderContactInfoLabel: (label, icon) ->
         div className: "bio-info--contact-info-header clearfix",
            Icon icon: icon, className: "bio-info--contact-icon"
            div className: "bio-info--contact-label", label

      renderSuggestModal: ->
         ModalWrapper
            width: 770,
            isOpen: true,
            closeOnOutsideClick: false,
            render: ({close}) =>
               CDCModalController
                  contact: @props.contact
                  onClose: close
                  referrer: "profile_contact_modal"

      render: ->
         addresses = AddressMixin.getAddresses(@props.contact)
         contact_info = Decorator.Profile.getContactInfo(@props.contact)

         addresses = [{}] if _.isEmpty(addresses)
         contact_info?.phones = [{}] if _.isEmpty(contact_info?.phones)
         contact_info?.emails = [{}] if _.isEmpty(contact_info?.emails)

         div className: "bio-contact-info",
            div className: "bio-info--section",
               if @props.hasHeader then ProfileCardSubtitle label: "Contact Info"

               @renderContactInfoLabel("Phone", "call")
               _.map contact_info?.phones, (phone, i) ->
                  Phone data: phone, key: i, hideIcon: true

               @renderContactInfoLabel("Email", "email")
               _.map contact_info?.emails, (email, i) =>
                  Email data: email, key: i, hideIcon: true, onClick: => @handleTrack("email")

               @renderContactInfoLabel("Address", "location")
               _.map addresses, (address, i) =>
                  Address data: address, key: i, hideIcon: true, onClick: => @handleTrack("address")

               if @state.has_cdc_profile
                  ModalTriggerDeprecated buttonType: "default", modal: @renderSuggestModal(), title: "Suggest Contact Update",
                     span null,
                        Icon icon: "upload-contact"
                        "Suggest Contact Update"
