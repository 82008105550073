import PropTypes from "prop-types";
import ReactHtmlParser from "react-html-parser";

const EmailTemplatePreview = ({ template, className }) => {
  const { templateBody = "" } = template || {};

  return (
    <div className={className || "email-template--preview-container"}>
      <p>
        <strong>Preview</strong>
      </p>
      <div className="email-template--preview">{template && <div>{ReactHtmlParser(templateBody)}</div>}</div>
    </div>
  );
};

EmailTemplatePreview.propTypes = {
  template: PropTypes.object,
  className: PropTypes.string,
};

export default EmailTemplatePreview;
