module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   EverTrue = require("app")
   Utils = require("base/utils")
   deparam = require("deparam")
   UrlSource = require("apps/layout/sources/url-source")
   Backbone = require("backbone")
   URL_MAX = 2083


   class UrlManager extends Backbone.Model
      history: []
      initialize: (path) ->
         @setUrl()
         @on "change", => @navigate(null, false)
         @listenTo EverTrue.vent, "before:route:change", => @setUrl()

      setUrl: ->
         @history.push Backbone?.history?.location?.pathname
         @history = _.compact(_.last(@history, 5))


         query_string = window.location.search.slice(1)
         search = $.deparam(query_string)
         if _.isEmpty(query_string)
            @clear()
         else
            @set(search)

      toString: ->
         $.param(@toJSON())

      getPreviousPath: ->
         @history[@history.length - 2]

      hasPreviousPath: ->
         previous_path = @getPreviousPath()
         previous_path != Backbone.history?.location?.pathname

      navigate: (path, trigger, replace=true) ->
         urlPath = path || window.location.pathname
         if _.isEmpty(@toJSON())
            EverTrue.Navigator(urlPath, {trigger: trigger, replace: replace})
         else
            query_string = @toString()
            if urlPath.length + query_string.length > URL_MAX
               UrlSource.reachedLimit(@toJSON())
               @unset("filters")
            EverTrue.Navigator(urlPath + "?" + @toString(), {trigger: trigger, replace: replace})
