module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   React = require("react")
   {i, div, table, tbody} = ReactLibs.DOMFactories
   {createComponent} = require("@evertrue/et-flux")
   {createFactory} = require("base/new-utils")
   Module = createFactory require("components/module").default
   ContactCard = createFactory(require("apps/contact/components/contact-card/contact-card"))
   LinkButton = createFactory(require("@evertrue/et-components").LinkButton)

   createComponent "CompanyProfileCard",
      propTypes:
         link: ReactLibs.PropTypes.string
         link_text: ReactLibs.PropTypes.any
         help_key: ReactLibs.PropTypes.string
         help_position: ReactLibs.PropTypes.string
         icon: ReactLibs.PropTypes.string
         header: ReactLibs.PropTypes.any
         limit: ReactLibs.PropTypes.number
         referrer: ReactLibs.PropTypes.string
         constituents: ReactLibs.PropTypes.array
         is_filters_v1: ReactLibs.PropTypes.bool

      getDefaultProps: ->
         icon: "fa fa-fw fa-users"
         link_text: "View All Constituents"
         limit: 5

      handleMoreClick: ->
         EverTrue.UrlManager.clear(silent: true)
         EverTrue.Navigator("/contact?#{@props.link}", true)

      render: ->
         constituents = @props.constituents
         limit = @props.limit

         div null,
            Module
               header: @props.header
               help_key: @props.help_key
               help_position: @props.help_position
               noMargin: true

               if constituents
                  div className: "company-constituents--container",
                     if constituents.length > limit
                        div null,
                           "Showing first #{limit} matches."
                           if @props.is_filters_v1
                              LinkButton
                                 className: "company-constituents--more"
                                 onClick: @handleMoreClick
                                 title: "View all matches"
                                 "View All"
                                 i className: "gt-icon gt-icon-double-chevron-right"
                     else if constituents.length == 1
                        div null,
                           "Showing 1 match."
                     else
                        div null,
                           "Showing #{constituents.length} matches."

                     div className: "company-constituents--content",

                        table className: "contact-column",
                           tbody null,
                              _.map constituents[0..limit - 1], (constituent) ->
                                 ContactCard contact: constituent, key: constituent.id

                        if @props.link && @props.is_filters_v1
                           LinkButton
                              title: "View more"
                              className: "company-constituents--view-all"
                              onClick: @handleMoreClick
                              "data-refer": @props.referrer
                              @props.link_text
                              i className: "gt-icon gt-icon-double-chevron-right"
