import { PropTypes } from "base/react-libs";
import { Modal, ModalHeaderCustom, ModalBody, Button } from "@evertrue/et-components";

const ValidatePhoneModal = ({ isOpen, onClose, code }) => {
  return (
    <Modal isOpen={isOpen} closeModal={onClose} size="large" closeOnOutsideClick={true}>
      <ModalHeaderCustom>
        <h3 className="validate-phone-title">Verify your number</h3>
        <Button type="simple" onClick={onClose} className="validate-phone-button">
          Close
        </Button>
      </ModalHeaderCustom>
      <ModalBody>
        <p className="validate-phone-body-message">We’re calling you now. When prompted, enter the code below.</p>
        <p className="validate-phone-body-number">{code}</p>
        <p className="validate-phone-body-message">
          Please be sure to complete steps requested and end the call before closing the pop-up verification code
          window.
        </p>
      </ModalBody>
    </Modal>
  );
};

ValidatePhoneModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  code: PropTypes.string.isRequired,
};

export default ValidatePhoneModal;
