module.exports = do ->
   _ = require("underscore").default
   Decorator = require("clientDecorator")
   Api = require("entities/helpers/api")
   {createSource} = require("@evertrue/et-flux")


   # to use this GiveContactsStore, another store should:
   #
   # 1. call GiveContactsStore.getContact(id) from within a getter
   # 2. call GiveContactsSource.loadContacts(ids) where appropriate in the same getter
   # 3. subscribe to GiveContactsSource.actions.contactsSuccess and simply call @trigger()
   #
   # the GiveContactsStore will handle figuring of if it is already in the store or should be loaded

   createSource "GiveContactsSource",
      actions:
         contactsLoading: (ids) ->
            @require _.isArray(ids), "loading ids should be an array"

         contactsSuccess: (contact_ids, parsed_contacts) ->
            @require _.isArray(contact_ids), "requested Ids should be an array"
            @require _.isArray(parsed_contacts), "contacts response should be an array"

         contactsError: (contact_ids) ->
            @require _.isArray(contact_ids), "requested Ids should be an array"

         clearContacts: true

         newRequestToLazyLoadContacts: (contact_ids) ->
            @require _.isArray(contact_ids), "requested Ids should be an array"

      api:
         loadContacts: (contact_ids) ->
            ids = _.uniq _.compact _.makeArray contact_ids
            if _.notEmpty(ids) then @actions.newRequestToLazyLoadContacts(ids)

         fetchContactsForContactsStore: (ids) ->
            # this should really only be called by the GiveContactsStore
            # other stores should load contacts via the `loadContacts` method

            if _.notEmpty(ids)
               @actions.contactsLoading(ids)
               Api.CONTACTS.BULK.get
                  params: id: ids, limit: 50
                  success: (resp) =>
                     contacts = _.map(resp.items, Decorator.Contacts.parse)
                     @actions.contactsSuccess(ids, contacts)
                  error: (xhr, textStatus, err) =>
                     @actions.contactsError(ids, err)

         clearContacts: ->
            @actions.clearContacts()
