import { useState, createContext, useContext } from "react";
import PropTypes from "prop-types";

const GlobalOutreachContext = createContext(() => { });

const GlobalOutreachProvider = ({ children }) => {
  const [emailDrawerIsOpen, setEmailDrawerIsOpen] = useState(false);

  return (
    <GlobalOutreachContext.Provider
      value={{
        emailDrawerIsOpen,
        setEmailDrawerIsOpen,
      }}
    >
      {children}
    </GlobalOutreachContext.Provider>
  );
};

GlobalOutreachProvider.propTypes = {
  children: PropTypes.any,
};

export { GlobalOutreachProvider, GlobalOutreachContext };
export const useGlobalOutreachContext = () => useContext(GlobalOutreachContext);
