import useDrawer from "./use-drawer";
import Drawer from "components/drawer/drawer";
import AiProfileChat from "apps/constituents/ai-profile-chat/components/ai-profile-chat";
import PropTypes from "prop-types";
import colors from "@evertrue/et-variables/src/styles/variables.scss";
import { Icon } from "@evertrue/et-components";

const AiProfileChatDrawer = ({ contactId, drawerTitle, is_open, close }) => {
  const { toggleMinimize, isMinimized } = useDrawer();

  return (
     (
      <Drawer
        className="chat-bot-drawer"
        isOpen={is_open}
        toggleMinimize={toggleMinimize}
        isMinimized={isMinimized}
        close={close}
        title={
          <span>
            <Icon icon="ai-chat" iconKit="gt2" style={{ color: colors.purple500, fontSize: "1.25em" }} />
            {drawerTitle}
          </span>
        }
      >
        <AiProfileChat contactId={contactId} />
      </Drawer>
    )
  );
};

AiProfileChatDrawer.propTypes = {
  contactId: PropTypes.number,
  drawerTitle: PropTypes.string,
  is_open: PropTypes.bool,
  close: PropTypes.func,
};

export default AiProfileChatDrawer;
