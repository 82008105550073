import React from "react";
import moment from "moment";

export const isValidURL = (str) => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return pattern.test(str);
};

export const isHTTPSURL = (str) => {
  const a = document.createElement("a");
  a.href = str;
  return a.protocol === "https:";
};

export const isValidHTTPSURL = (str) => {
  return [isHTTPSURL(str), isValidURL(str)].every(Boolean);
};

export const formatDateToUnixWithCurrentTime = (date) => {
  if (date === "Invalid date") {
    return null;
  }

  const time = moment().format("HH:mm:ss");
  return moment(`${date} ${time}`).valueOf();
};

/**
 * Replacement for deprecated createFactory which is used by Coffeescript components
 * https://reactjs.org/blog/2020/02/26/react-v16.13.0.html#deprecating-reactcreatefactory
 */
export const createFactory = (type) => React.createElement.bind(null, type);
