import _ from "underscore";
import EverTrue from "app";
import { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import Api from "entities/helpers/api";
import { useState, Fragment } from "react";
import { usePromiseResolver } from "@evertrue/promise-resolver";
import SolicitorDropdown from "apps/portfolio-performance/components/solicitor-dropdown";
import StageDropdown from "apps/portfolio-performance/components/stage-dropdown";
import AssignmentTitleDropdown from "apps/portfolio-performance/components/assignment-title-dropdown";
import ProfileAssignmentSource from "apps/profile/sources/profile-assignment-source";
import {
  updateStage,
  updateAssignmentTitle,
  removeProspectFromPool,
  assignProspects,
} from "apps/portfolio-performance/portfolio-requests";
import { Icon, ModalConfirm, FlexTable, Button, LinkButton, AdvancedCombobox } from "@evertrue/et-components";
import { GlobalPortfolioContext } from "apps/portfolio-performance/contexts/global-portfolio-context";

const _parseAssignments = (assignments) => {
  const parsed_assignments = {};
  _.each(assignments, (assignment = {}) => {
    const membership = assignment.membership || {};
    parsed_assignments[membership.assignment_id] = { ...membership, name: assignment.name };
  });
  return parsed_assignments;
};

const _hasChangesToSave = (assignments) => {
  let has_changes = false;
  _.each(assignments, (assignment) => {
    if (assignment.updated_stage || assignment.remove_assignment || assignment.updated_assignment_title) {
      has_changes = true;
    }
  });
  return has_changes;
};

const RmAssignContactModal = ({ contact, close, assignments }) => {
  const { assignmentType, setAssignmentType } = useContext(GlobalPortfolioContext);
  const [parsedAssignments, setAssignments] = useState(_parseAssignments(assignments));
  const [newRow, setNewRow] = useState({});
  const new_row_complete = !!newRow.teamId && !!newRow.solicitorId && !!newRow.stage;
  // if a new assignment is all filled out or there are other changes (without an incomplete new row)
  const changes_to_save = new_row_complete || (_.isEmpty(newRow) && _hasChangesToSave(parsedAssignments));

  useEffect(() => {
    setAssignmentType("TEAM");
  }, [setAssignmentType]);

  // Load Teams
  const [teams = {}] = usePromiseResolver(() => {
    return Api.VOLUNTEER.POOLS.get({
      params: {
        pool_type: "TEAM",
        limit: 100,
      },
    });
  }, []);

  const handleRemove = (assignment_id) => {
    const assignment = parsedAssignments[assignment_id];
    const new_data = {};
    new_data[assignment_id] = { ...assignment, remove_assignment: true };
    setAssignments((prev) => {
      return { ...prev, ...new_data };
    });
  };

  const handleEditStage = (assignment_id, stage) => {
    const assignment = parsedAssignments[assignment_id];
    const new_data = {};
    new_data[assignment_id] = { ...assignment, updated_stage: stage };
    setAssignments((prev) => {
      return { ...prev, ...new_data };
    });
  };

  const handleEditAssignmentTitle = (assignment_id, title) => {
    const assignment = parsedAssignments[assignment_id];
    const new_data = {};
    new_data[assignment_id] = { ...assignment, updated_assignment_title: title };
    setAssignments((prev) => {
      return { ...prev, ...new_data };
    });
  };

  const handleSave = () => {
    const requests = [];

    _.each(parsedAssignments, (assignment) => {
      if (assignment.remove_assignment) {
        const remove_request = () => removeProspectFromPool(assignment.assignment_id, "DXO");
        requests.push(remove_request);
      } else {
        if (assignment.updated_stage) {
          const update_stage_request = () =>
            updateStage(assignment.assignment_id, { stage: assignment.updated_stage }, assignmentType).then(() => {
              EverTrue.track.set("portfolio_performance_action", { type: "update_prospect_stage", referer: "profile" });
            });
          requests.push(update_stage_request);
        }
        if (assignment.updated_assignment_title) {
          const update_assignment_title_request = () =>
            updateAssignmentTitle(assignment.assignment_id, assignment.updated_assignment_title);
          requests.push(update_assignment_title_request);
        }
      }
    });

    if (new_row_complete) {
      const data = {
        teamId: newRow.teamId,
        data: {
          assignments: [
            {
              prospect_contact_id: contact.id,
              stage: newRow.stage,
              title: newRow.title,
              solicitor_contact_id: newRow.solicitorId,
            },
          ],
        },
      };
      const new_row_request = () => assignProspects(data);
      requests.push(new_row_request);
    }

    // save all requests in request array
    Promise.all(_.map(requests, (promise) => promise()))
      .then(() => {
        EverTrue.Alert.success("Successfully updated prospect assignments");
        ProfileAssignmentSource.fetchTeamMembership(contact.id);
        ProfileAssignmentSource.fetchDxoMembership(contact.id);
        close();
      })
      .catch(() => {
        EverTrue.Alert.error("Prospect assignment did not update");
      });
  };

  // filter out removed rows
  let data = _.filter(parsedAssignments, (assignment, key) => !assignment.remove_assignment);
  const new_row = _.findWhere(data, { id: "new" });
  // add new row if one has been started
  if (!_.isEmpty(newRow)) {
    data.push(newRow);
    // if the new row has been removed but still is in the ui
  } else if (_.isEmpty(newRow) && new_row) {
    data = _.without(data, new_row);
  }

  const team_options = _.map(teams.items, (team) => {
    return { label: team.name, value: team.id };
  });

  return (
    <ModalConfirm
      className="rm-assign-contact--modal"
      header={
        <span>
          Edit
          <strong> {contact.name} </strong>
          assignment
        </span>
      }
      onSubmit={handleSave}
      onCancel={close}
      style={{ width: 850 }}
      disableSave={!changes_to_save}
    >
      <div className="rm-assign-contact--body">
        {_.isEmpty(data) ? (
          <div className="rm-assign-contact--no-assignments">No assignments</div>
        ) : (
          <div className="rm-assign-contact--table">
            <FlexTable
              caption="List of assignments this constituent has"
              data={_.values(data)}
              columns={[
                {
                  header: "Team",
                  headerClassName: "rm-assign-contact--title",
                  accessor: (assignment) => {
                    if (!assignment.assignment_id) {
                      return (
                        <AdvancedCombobox
                          options={_.sortBy(team_options, "label")}
                          value={{ value: newRow.teamId }}
                          placeholder="Select Team..."
                          onChange={(selection) => {
                            // clear everything in the new row if you change team
                            setNewRow({ teamId: selection.value });
                          }}
                          searchable
                          multiple={false}
                          formatSelection={(selectionText) => {
                            return (
                              <span style={{ color: "#454545" }}>
                                {selectionText.length > 15 ? selectionText.substring(0, 12).concat("....") : selectionText}
                              </span>
                            );
                          }}  
                        />
                      );
                    } else {
                      return assignment.pool_name;
                    }
                  },
                },
                {
                  header: "Solicitor",
                  headerClassName: "rm-assign-contact--title",
                  accessor: (assignment) => {
                    if (!assignment.assignment_id) {
                      return (
                        <SolicitorDropdown
                          value={{ value: newRow.solicitorId }}
                          onChange={(selection) => {
                            setNewRow((prev) => ({ ...prev, solicitorId: selection.value }));
                          }}
                          multiple={false}
                          disabled={!newRow.teamId}
                          allowChangeAll={false}
                          allowUnselectAll={false}
                          poolIds={newRow.teamId ? [newRow.teamId] : null}
                        />
                      );
                    } else {
                      return (
                        <Fragment>
                          {assignment.key_prospect ? (
                            <Icon icon="key-relationship" className="rm-assign-contact--key-icon" />
                          ) : (
                            <Icon icon="person" className="rm-assign-contact--person-icon" />
                          )}
                          <span className="rm-assign-contact--name">{assignment.name}</span>
                        </Fragment>
                      );
                    }
                  },
                },
                {
                  header: "Assignment Title",
                  headerClassName: "rm-assign-contact--title",
                  accessor: (assignment) => {
                    const assignment_team = _.findWhere(teams.items, { id: assignment.pool_id }) || {};
                    // If it's a brand new row
                    if (!assignment.assignment_id) {
                      return (
                        <AssignmentTitleDropdown
                          value={{ value: newRow.title }}
                          disabled={!newRow.teamId}
                          onChange={(selection) => {
                            setNewRow((prev) => ({ ...prev, title: selection.value }));
                          }}
                        />
                      );
                    } else if (!!assignment.assignment_id && assignment_team.stage_group_id > 0) {
                      return (
                        <AssignmentTitleDropdown
                          value={{ value: assignment.updated_assignment_title || assignment.assignment_title }}
                          onChange={(selection) => {
                            handleEditAssignmentTitle(assignment.assignment_id, selection.value);
                          }}
                        />
                      );
                    } else {
                      return assignment.assignment_title;
                    }
                  },
                },
                {
                  header: "Prospect Status",
                  headerClassName: "rm-assign-contact--title",
                  accessor: (assignment) => {
                    const assignment_team = _.findWhere(teams.items, { id: assignment.pool_id }) || {};
                    const selected_team = _.findWhere(teams.items, { id: newRow.teamId }) || {};

                    if (!assignment.assignment_id) {
                      return (
                        <StageDropdown
                          placeholder="Select a stage..."
                          value={{ value: newRow.stage }}
                          multiple={false}
                          includeNoStatus={false}
                          stageGroupId={selected_team.stage_group_id}
                          disabled={!newRow.teamId || selected_team.stage_group_id === 0}
                          onChange={(selection) => {
                            setNewRow((prev) => ({ ...prev, stage: selection.value }));
                          }}
                        />
                      );
                    } else if (!!assignment.assignment_id && assignment_team.stage_group_id > 0) {
                      return (
                        <StageDropdown
                          value={{ value: assignment.updated_stage || assignment.stage }}
                          multiple={false}
                          includeNoStatus={false}
                          stageGroupId={assignment_team.stage_group_id}
                          onChange={(selection) => {
                            handleEditStage(assignment.assignment_id, selection.value);
                          }}
                        />
                      );
                    } else {
                      return assignment.stage;
                    }
                  },
                },
                {
                  header: "Remove",
                  headerClassName: "rm-assign-contact--title rm-assign-contact--remove",
                  cellClassName: "rm-assign-contact--remove",
                  accessor: (assignment) => {
                    return (
                      <Button
                        type="simple"
                        title="Remove assignment"
                        onClick={() => {
                          if (!assignment.assignment_id) {
                            setNewRow({});
                          } else {
                            handleRemove(assignment.assignment_id);
                          }
                        }}
                      >
                        <Icon icon="cancel" className="assign-contact---remove-row" />
                      </Button>
                    );
                  },
                },
              ]}
            />
          </div>
        )}

        {_.isEmpty(newRow) && (
          <LinkButton
            onClick={() => setNewRow({ id: "new", teamId: null })}
            title="Add a Solicitor"
            className="rm-assign-contact--new-row"
          >
            <Icon icon="add-circle" className="assign-contact--add-icon" />
            Add Solicitors
          </LinkButton>
        )}
      </div>
    </ModalConfirm>
  );
};

RmAssignContactModal.propTypes = {
  contact: PropTypes.object,
  assignments: PropTypes.array,
  close: PropTypes.func,
};

export default RmAssignContactModal;
