module.exports = do ->
   _ = require("underscore").default
   numeral = require("numeral")
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {div, h1, strong} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   AdvancedCombobox = createFactory(require("@evertrue/et-components").AdvancedCombobox)
   MyTeamStore = require("apps/my-team/stores/my-team-store")
   MyTeamSource = require("apps/my-team/sources/my-team-source")


   createComponent "MyTeamHeader",
      propTypes:
         report: ReactLibs.PropTypes.string

      registerStores: ->
         @on MyTeamStore, ->
            date_range_key: MyTeamStore.getDateRangeKey()
            date_range: MyTeamStore.getFormattedDateRange()
            rollup: MyTeamStore.getReportsRollup()
            prospects: _.sum MyTeamStore.getReportData("prospects"), (item) -> item.x

      render: ->
         has_datepicker = !(@props.report in ["prospects"])

         div className: "my-team--header",
            div className: "my-team--title",
               h1 tabIndex: -1, "My Team"
               div className: "my-team--subtitle",
                  strong null, _.size(@state.rollup)
                  " Measured solicitors managing a total of "
                  strong null, "#{numeral(@state.prospects).format("0,0")} prospects"
                  if @state.date_range.to == @state.date_range.from
                     " for #{@state.date_range.from}"
                  else
                     " from #{@state.date_range.from} to #{@state.date_range.to}"

            div className: "my-team--datepicker",
               AdvancedCombobox
                  className: "advanced-combobox-secondary"
                  disabled: !has_datepicker
                  value: if has_datepicker then {value: @state.date_range_key}
                  placeholder: if !has_datepicker then "All Time"
                  options: [
                     {value: "fiscal_year", label: "This Fiscal Year to Date"}
                     {value: "last_fiscal_year", label: "Last Fiscal Year"}
                     {value: "ytd", label: "Year to Date"}
                     {value: "mtd", label: "Month to Date"}
                  ]
                  onChange: (val) ->
                     MyTeamSource.changeDateRange(val.value)
