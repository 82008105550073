module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   React = require("react")
   moment = require("moment")
   User = require("entities/auth/user")
   ContactFormatMixin = require("mixins/contact-format-mixin")
   WindowSource = require("apps/layout/sources/window-source")
   {li, div, span, img, i, h5} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   CommentText = createFactory(require("apps/interactions/components/lazy-comment-text").default)
   CommentTextarea = createFactory(require("apps/profile/components/interactions/comment-textarea"))
   Dropdown = createFactory require("components/controls/dropdown")
   LinkButton = createFactory(require("@evertrue/et-components").LinkButton)

   # This currently only renders EverTrue Comments, not all interaction types
   CommentItem = ReactLibs.createClass
      propTypes:
         comment: ReactLibs.PropTypes.object
         onDelete: ReactLibs.PropTypes.func
         onUpdate: ReactLibs.PropTypes.func
         readOnly: ReactLibs.PropTypes.bool

      mixins: [ContactFormatMixin]
      getInitialState: ->
         {user: User.prototype.defaults, mode: "view"}

      componentDidMount: ->
         @is_mounted = true
         # Download user data for creator avatar
         user = new User({id: @props.comment.creator_user_id})
         if user.get("id") == EverTrue.store.user?.get("id")
            @setState {user: EverTrue.store.user?.toJSON()}
         else
            user.fetchWithContact
               cache: true
               disableAlerts: true
               success: =>
                  if @is_mounted
                     @setState({user: user.toJSON()})

      componentWillUnmount: ->
         @is_mounted = false

      handleUpdate: (comment, comment_data) ->
         @props.onUpdate?(comment, comment_data)
         @setState {mode: "view"}

      handleDestroy: ->
         if _.isFunction(@props.onDelete)
            @props.onDelete(@props.comment.id)
         WindowSource.outsideClick()

      handleShowEdit: ->
         @setState {mode: "edit"}
         WindowSource.outsideClick()

      handleCancelEdit: ->
         _.delay((=> @setState {mode: "view"}), 150)

      getLabels: ->
         labels = {}
         _.each @props.comment?.labels, (label) ->
            if labels[label.name]
               labels[label.name] += ", #{label.value}"
            else
               labels[label.name] = label.value
         labels

      renderTimestamp: ->
         return if @props.readOnly
         comment_data = @props.comment.date_occurred || @props.comment.created_at
         return unless comment_data
         date = moment(comment_data)
         today = moment(new Date()).subtract(1, "minute")
         div className: "comment--date",
            if today.diff(date, "seconds") < 86400
               date.fromNow()
            else
               div null,
                  div null, date.format("MMMM D, YYYY")
                  div className: "comment--date-time", date.format("h:mm a")

      renderCommentImage: ->
         # If the note is not imported use user avatar
         if !@props.comment.remote_id
            img src: @state.user?.avatar
         else
            iconClass = switch @props.comment.category
               when "NOTE" then "fa-pencil"
               when "PHONECALL" then "fa-phone"
               when "MEETING" then "fa-calendar"
               when "MAILING" then "fa-envelope"
               when "TASK" then "fa-check"
               when "OTHER" then "fa-folder-o"
            div className: "comment-category--icon",
               i className: "fa fa-fw #{iconClass}"

      render: ->
         comment_labels = @getLabels()

         li className: "comment clearfix",
            @renderTimestamp()

            div className: "comment--image",
               @renderCommentImage()

            div className: "comment--content",
               # Author
               if @state.user.name
                  h5 className: "comment--name", @state.user.name
               else if comment_labels.author
                  h5 className: "comment--name", comment_labels.author

               if !@props.readOnly && @props.comment.creator_user_id == EverTrue.store.user.get("id")
                  Dropdown
                     className: "comment--actions"
                     header: i(className:"fa fa-fw fa-chevron-down")
                     stay_open: true

                     LinkButton onClick: @handleShowEdit, className: "comment-actions--item", title: "Edit",
                        i className: "fa fa-fw fa-pencil"
                        span null, "Edit"
                     LinkButton onClick: @handleDestroy, className: "comment-actions--item text-danger", title: "Delete",
                        i className: "fa fa-fw fa-trash"
                        span null, "Delete"

               if @state.mode == "view"
                  div null,
                     # Type and Title
                     if comment_labels.type
                        span className: "comment--content-type", comment_labels.type
                     if comment_labels.type and comment_labels.title
                        "|"
                     if comment_labels.title
                        span className: "comment--content-title", "\"#{comment_labels.title}\""
                     # Description
                     if comment_labels.description
                        div className: "comment--content-description", comment_labels.description
                     # Comment Text
                     div className: "comment--text",
                        CommentText
                           text: @props.comment?.text
                           mentions: @props.comment?.mentions

                     div className: "comment--meta",
                        if (@props.comment && @props.comment.syncing)
                           div className: "loader loader-small"

               if @state.mode == "edit"
                  CommentTextarea
                     className: "list-activity--comment-box"
                     onSave: @handleUpdate
                     comment: @props.comment
                     onCancel: @handleCancelEdit
                     hideAvatar: true


