import PropTypes from "prop-types";
import { Icon, ModalTrigger2, LinkButton } from "@evertrue/et-components";
import AddProspectsToTeamModal from "apps/portfolio-performance/components/add-prospects-to-team-modal";

const ProspectColumnEmptyState = ({ portfolioHasProspects, team, dnd_enabled, stage, solicitor, refreshData }) => {
  if (portfolioHasProspects && dnd_enabled) {
    return (
      <div className="portfolio-performance--empty-col">
        <Icon icon="dragndrop" />
        <div>Drag and Drop Prospects to this Stage</div>
      </div>
    );
  } else if (team.selection_mode === "SOLICITOR_SELECTION" && dnd_enabled) {
    return (
      <ModalTrigger2
        closeOnOutsideClick
        trigger={({ open, close }) => {
          return (
            <LinkButton title="Add new prospect" onClick={open} className="portfolio-performance--no-prospects-add">
              <Icon icon="group-add" />
              <div>Add New Prospects</div>
            </LinkButton>
          );
        }}
      >
        {({ close }) => (
          <AddProspectsToTeamModal
            stages={[stage]}
            defaultStage={stage}
            close={close}
            team={team}
            solicitor={solicitor}
            refreshData={refreshData}
          />
        )}
      </ModalTrigger2>
    );
  } else {
    return <div className="portfolio-performance--no-prospects">No Prospects</div>;
  }
};

ProspectColumnEmptyState.propTypes = {
  portfolioHasProspects: PropTypes.bool.isRequired,
  team: PropTypes.object.isRequired,
  dnd_enabled: PropTypes.bool.isRequired,
  stage: PropTypes.string.isRequired,
  solicitor: PropTypes.object.isRequired,
  refreshData: PropTypes.func.isRequired,
};

export default ProspectColumnEmptyState;
