module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   {div, span} = ReactLibs.DOMFactories
   {createSource} = require("@evertrue/et-flux")
   EverTrue = require("app")
   Api = require("entities/helpers/api")
   LocalApi = require("entities/helpers/local-api")
   ErrorLogger = require("entities/helpers/error-log-helper")
   UserSource = require("base/user/user-source")
   {createFactory} = require("base/new-utils")
   Link = createFactory(require("@evertrue/et-components").Link)

   _endImpersonation = ->
      # If in impersonation mode, kick out of impersonation
      if EverTrue.request "isImpersonating?"
         _.defer(-> EverTrue.Alert.error("Impersonation session has expired."))
         UserSource.endImpersonation()

   _showError = (header, subject) ->
      display_message = span null,
         div null, header
         span null, "If this problem persists, please contact "
         Link href: "mailto:genius@evertrue.com?subject=#{encodeURIComponent(subject)}", title: "Send email to Evertrue",
            "genius@evertrue.com"

      _.defer ->
         EverTrue.Alert.error display_message, {timeout: 5000}

   _logError = (message, xhr, meta_data={}) ->
      ErrorLogger.captureRequest "Session Error: #{message}", xhr, meta_data



   createSource "SessionSource",
      actions:
         refresh: true
         logout: true
         setSession: true
         isRenewing: true
         startInitialSessionAttempt: true
         createdSessionWithLidsToken: true
         startRenewSession: true
         setSessionRenewed: true
         errorRenewingSession: true
         lidsIsUnauthorized: true
         startSSORedirect: true
         updatePhoto: true

      promise:
         # RENEW: Get new session from Skiff (preventing errors where this gets in a loop)
         renewSession: (options = {}, scope_info = {}) ->
            type = "SCOPED"
            { oid } = scope_info
            app_key = EverTrue.config.app_keys.givingtree
            Api.pause()
            @actions.isRenewing(true)

            data = if oid then _.jsonStringify({ type, oid, app_key })
            return new Promise (resolve, reject) =>
               Api.AUTH.SKIFF.post
                  headers:
                     "Authorization-Auto-Send": if EverTrue.is_idle then 0 else 1
                     "Authorization": null
                     "Authorization-Provider": "EverTruePrimeToken"
                     "Content-Type": "application/json"
                  params: {oid: null}
                  xhrFields: {withCredentials: true}
                  forceRequest: true
                  disableAlerts: true
                  mfaPromptText: "Session Timeout"
                  data: data
                  success: (response) =>
                     @actions.isRenewing(false)
                     @actions.setSessionRenewed(response)
                     Api.restart()
                     resolve(response)
                  error: (xhr) =>
                     reject(xhr)
                     Api.clearQueue()
                     Api.restart()
                     @actions.isRenewing(false)
                     switch xhr.status
                        when 401
                           if EverTrue.inApp then @actions.errorRenewingSession(xhr.responseJSON.redirect_url)
                           else EverTrue.execute "resetApp"
                        when 412 then _endImpersonation()
                        else
                           EverTrue.execute("resetApp")

      api:
         setSession: (session) ->
            Api.set({session: session?.token})
            LocalApi.set({session: session?.token})
            @actions.setSession(session)
            @saveToStore(session)

         saveToStore: (session) ->
            if session then EverTrue.store.session.set(session, {silent: true})
            else EverTrue.store.session.clear()
            EverTrue.store.session.saveToStore()

         # APP_INIT. On In App Start w/o Session
         startInitialSessionAttempt: (oid) ->
            @actions.startInitialSessionAttempt(oid)

         # APP_INIT. On Initial Start Attempts to make request using cookie
         createSessionWithCookie: (oid) ->
            app_key = EverTrue.config.app_keys.givingtree

            Api.AUTH.SKIFF.post
               disableAlerts: true
               headers:
                  "Authorization": null
                  "Authorization-Provider": null
               xhrFields: withCredentials: true
               data: if oid then _.jsonStringify({ type: "SCOPED", oid, app_key })
               success: (resp) =>
                  @setSession(resp)
                  EverTrue.execute("startRouter")
               error: (xhr) =>
                  @actions.isRenewing(false)
                  EverTrue.execute("startRouter")

         # LOGIN. Create a session with a Lids token on login (lids token should otherwise not be stored)
         createSessionWithLidsToken: (user_id, lids_token) ->
            if lids_token
               Api.AUTH.SKIFF.post
                  headers: _.compactObject
                     "Authorization-Auto-Send": 1
                     "Authorization-Provider": "linkedinaccesstoken"
                     "Authorization": lids_token
                     "Authorization-User": user_id
                     "Authorization-User-Picker": if !user_id then 1
                  params: {oid: null}
                  xhrFields: withCredentials: true
                  success: (resp) =>
                     @actions.createdSessionWithLidsToken(resp)
                  error: (xhr) ->
                     _logError "createWithLidsToken", xhr, {user_id: user_id}
                     if xhr.status == 403
                        {error_code, redirect_url} = xhr.responseJSON
                        if error_code == 144 && redirect_url
                           EverTrue.Alert.warn("You are not authorized to login with LinkedIn.
                           Redirecting you to your organization's login...", {timeout: 5000})
                           url = redirect_url
                           if EverTrue.config.base_environment == "local"
                              url += "&redirect=#{window.location.origin}"
                           _.wait 1000, -> window.location = url
                        else _showError("We're sorry you are not authorized to access this application.")
                     else
                        _showError("We've encountered an error logging you in.")
                     EverTrue.execute("resetApp")
            else
               _showError("Unable to login due to missing LinkedIn access token.")
               EverTrue.execute "setPageReferrer"
               EverTrue.execute "resetApp"

         # RENEW: Trigger renew to pull from store is_renewing state
         startRenewSession: (options) ->
            @actions.startRenewSession(options)

         errorMaxRenewals: (options={}) ->
            error_data = _.pick(options, "url", "data", "params")
            _logError("Excessive Renewal Attempts", null, error_data)
            _showError("We've encountered an authorization error.", "Max Hourly Attempts")

         # REFRESH: Update for MFA or on app start
         refresh: ->
            @actions.refresh()

         # REFRESH: Just get a new auth token from skiff
         fetchSession: (token) ->
            Api.AUTH.SESSION.get
               success: (resp) =>
                  @setSession(resp)
               error: (xhr, statusCode, err) ->
                  _logError("fetchSession", xhr, {token: token})
                  if xhr?.status is 412 then _endImpersonation()

         # LIDS: is unauthorized
         lidsIsUnauthorized: (value) ->
            @actions.lidsIsUnauthorized(value)

         # only used on renew session error
         ssoSessionEnded: (ssoRedirectURL) ->
            window.location = ssoRedirectURL

         # LOGOUT: Delete session
         logout: ->
            EverTrue.track.set("auth_action", {type: "logout"})
            Api.AUTH.SKIFF.delete
               disableAlerts: true
               xhrFields: withCredentials: true
               success: =>
                  EverTrue.store.session.clear()
                  @actions.logout()
               error: (xhr) ->
                  EverTrue.store.session.clear()
                  _logError("Unable to logout", xhr)

            LocalApi.set({session: undefined, oid: undefined})
            Api.set({session: undefined, oid: undefined})

         updatePhoto: (url) ->
            @actions.updatePhoto(url)
