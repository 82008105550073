import { useState } from "react";
import { PropTypes } from "base/react-libs";
import JourneyDetailTableBody from "./journey-detail-table-body";
import JourneyDetailTableHeader from "./journey-detail-table-header";

const JourneyDetailTable = ({
  user = {},
  journey = {},
  userIsCreator = false,
  selectedContact,
  openContactSummary = () => {},
  optionalStateManagementFunction = () => {},
}) => {
  const [tableView, setTableView] = useState("steps");

  return (
    <div className="journey-detail-table--container">
      <JourneyDetailTableHeader journey={journey} tableView={tableView} setTableView={setTableView} />
      <JourneyDetailTableBody
        user={user}
        journey={journey}
        tableView={tableView}
        userIsCreator={userIsCreator}
        selectedContact={selectedContact}
        openContactSummary={openContactSummary}
        optionalStateManagementFunction={optionalStateManagementFunction}
      />
    </div>
  );
};

JourneyDetailTable.propTypes = {
  user: PropTypes.object,
  journey: PropTypes.object.isRequired,
  userIsCreator: PropTypes.bool,
  selectedContact: PropTypes.object,
  openContactSummary: PropTypes.func,
  optionalStateManagementFunction: PropTypes.func,
};

export default JourneyDetailTable;
