module.exports = do ->
   EverTrue = require("app")
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {createFactory} = require("base/new-utils")
   Nav = createFactory require("components/lists/nav")
   NavItem = createFactory require("components/lists/nav-item")


   createComponent "FacebookNav",
      propTypes:
         onSelect: ReactLibs.PropTypes.func
         active: ReactLibs.PropTypes.any

      getDefaultProps: ->
         active: "pages"

      handleNavChange: (active_key) ->
         switch active_key
            when "pages" then url = "/facebook/pages"
            when "interactions" then url = "/facebook/interactions"
            when "posts" then url = "/facebook/posts"
         EverTrue.UrlManager.navigate(url, true, false)

      renderNavItems: ->
         navItems = []
         navItems.push(NavItem refer: "default", activeWhen: "pages", label: "Pages", key: "pages")
         navItems.push(NavItem refer: "default", activeWhen: "posts", label: "Posts", key: "posts")
         navItems

      render: ->
         Nav className: "facebook-nav sub-nav", active: @props.active, onSelect: @handleNavChange,
            @renderNavItems()
