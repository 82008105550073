import { useState, useEffect } from "react";
import Api from "entities/helpers/api";

const useFetchDynamicListAlertPrefs = (list_id) => {
  const [loading, setLoading] = useState(true);
  const [alertPrefs, setAlertPrefs] = useState({});

  useEffect(() => {
    const fetchPrefs = async () => {
      setLoading(true);

      try {
        const resp = await Api.EMS.PREFS_BY_KEY.get({
          urlArgs: { key: `list_v2:${list_id}:` },
        });
        setAlertPrefs(resp);
        setLoading(false);
      } catch {
        setLoading(false);
      }
    };

    fetchPrefs();
  }, [list_id]);

  return { loading, alertPrefs };
};

export default useFetchDynamicListAlertPrefs;
