import { PropTypes } from "base/react-libs";
import { trackJourneysAction } from "apps/journeys/utils/helpers/mixpanel";
import DatePickerDropdown from "components/calendar/date-picker-dropdown";

const DueDatePicker = ({ selectedDueDate, setSelectedDueDate }) => (
  <DatePickerDropdown
    date={selectedDueDate}
    onDayClick={(date) => {
      trackJourneysAction("view_calendar_date");
      setSelectedDueDate(date);
    }}
    showDay={true}
    triggerClassName={"journeys-date-dropdown"}
    chevronClassName={"date-picker-chevron"}
    allowClear={false}
    buttonType="simple"
  />
);

DueDatePicker.propTypes = {
  selectedDueDate: PropTypes.number,
  setSelectedDueDate: PropTypes.func,
};

export default DueDatePicker;
