module.exports = do ->
   classNames = require("classnames")
   {div} = ReactLibs.DOMFactories
   {createComponent} = require("@evertrue/et-flux")

   createComponent "Loading",
      propTypes:
         text: ReactLibs.PropTypes.string
         position: ReactLibs.PropTypes.oneOf ["top", "bottom", "center"]

      getDefaultProps: ->
         position: "center"

      render: ->
         div className: classNames("loading", @props.className),
            div className: classNames("loading--wrapper",
               "is-center": @props.position == "center"
               "is-top": @props.position == "top"
               "is-bottom": @props.position == "bottom"
               "loading--wrapper-small": @props.spinner_size == "small"
            ),
               div className: classNames("loading--spinner", "loading--spinner-small": @props.spinner_size == "small")
               div className: "loading--text", @props.text
