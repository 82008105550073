module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   Decorator = require("clientDecorator")
   {createComponent} = require("@evertrue/et-flux")
   WindowOverflowMixin = require("mixins/window-overflow-mixin")
   WindowStore = require("apps/layout/stores/window-store")
   {table, tbody, div} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   Modal = createFactory require("components/modals/modal")
   ModalConfirm = createFactory require("components/modals/modal-confirm")
   ModalTrigger = createFactory require("components/modals/modal-trigger")
   UserCard = createFactory require("apps/users/components/user-card")
   ContactCard = createFactory(require("apps/contact/components/contact-card/contact-card"))
   Avatar = require("components/elements/avatar")


   createComponent "ActivityFacepile",
      propTypes:
         list: ReactLibs.PropTypes.array
         type: ReactLibs.PropTypes.oneOf ["users", "contacts"]
         label: ReactLibs.PropTypes.string
         icon: ReactLibs.PropTypes.string

      getDefaultProps: ->
         type: "contacts"

      getInitialState: ->
         visible: 5

      registerStores: ->
         @on WindowStore, ->
            visible: @getVisibleItems()
            width: WindowStore.getWidth()

      UNSAFE_componentWillReceiveProps: ->
         @setState visible: @getVisibleItems()

      getVisibleItems: ->
         if node = @refs?.facepile
            dom_props = WindowOverflowMixin.getDOMProperties(node)
            Math.floor((dom_props.width - 50) / 50) - 1

      renderFacepileModal: ->
         Modal width: 550, className: "activitiy-facepile--modal",
            ModalConfirm header: @props.label,
               div className: "activity-facepile--table",

                  _.map @props.list, (item) =>
                     if item
                        if @props.type == "users"
                           UserCard user: item, key: item.id
                        else
                           ContactCard
                              contact: item
                              key: item.id
                              referrer: "list_activity"
                              showRemoteId: true
                              includeMiddle: true

      render: ->
         div className: "activity-facepile", ref: "facepile",
            _.map _.first(@props.list, @state.visible), (item) ->
               if item
                  Avatar
                     className: "activity-facepile--avatar"
                     key: item?.id
                     url: item.avatar
                     name: Decorator.Contacts.getDetailedName(item)

            if @props.list.length > @state.visible
               div className: "activity-facepile--more",
                  "+#{@props.list.length - @state.visible}"

            ModalTrigger
               title: "See all Activities"
               modal: @renderFacepileModal()
               className: "activity-facepile--link"
               "see all"
