import { PropTypes } from "base/react-libs";
import { PageHeader, Icon, Button } from "@evertrue/et-components";
import Resources from "./resources";
import Metrics from "./metrics";
import Upcoming from "./upcoming";
import RecommendedActions from "./recommended-actions/recommended-actions";

const getUserName = (user = {}) => {
  const { name = "" } = user;
  return name.split(" ")[0];
};

const HomepageMainContent = ({
  user = {},
  todayJourneyCount,
  todayTaskCount,
  nextFiveDaysCounts,
  loadingJourneysTodayCount,
  loadingTasksTodayCount,
  loadingNextFiveCounts,
  futureJourneyTimeout,
  inProgressJourneyTimeout,
  businessDaysUntilFutureTimeout,
  tripsAndMeetingDetails,
  tripsAndMeetingDetailsForNextFive,
  optionalStateManagementFunction = () => {},
}) => {
  const userName = getUserName(user);
  return (
    <>
      <div className="homepage-title-containter">
        <PageHeader title={`Welcome, ${userName}!`} className="homepage-title" />
      </div>
      <div className="homepage-settings-container">
        <Button type="simple" href="settings">
          <Icon icon="gear" size={1} className="homepage-settings-icon" />
        </Button>
      </div>
      <div className="homepage-main-content">
        <Upcoming
          todayJourneyCount={todayJourneyCount}
          todayTaskCount={todayTaskCount}
          nextFiveDaysCounts={nextFiveDaysCounts}
          loadingJourneysTodayCount={loadingJourneysTodayCount}
          loadingTasksTodayCount={loadingTasksTodayCount}
          loadingNextFiveCounts={loadingNextFiveCounts}
          businessDaysUntilFutureTimeout={businessDaysUntilFutureTimeout}
          futureJourneyTimeout={futureJourneyTimeout}
          inProgressJourneyTimeout={inProgressJourneyTimeout}
          tripsAndMeetingDetails={tripsAndMeetingDetails}
          tripsAndMeetingDetailsForNextFive={tripsAndMeetingDetailsForNextFive}
          optionalStateManagementFunction={optionalStateManagementFunction}
        />
        <RecommendedActions />
        <Metrics />
        <Resources />
      </div>
    </>
  );
};

HomepageMainContent.propTypes = {
  user: PropTypes.object,
  todayJourneyCount: PropTypes.object,
  todayTaskCount: PropTypes.object,
  nextFiveDaysCounts: PropTypes.array,
  loadingJourneysTodayCount: PropTypes.bool,
  loadingTasksTodayCount: PropTypes.bool,
  loadingNextFiveCounts: PropTypes.bool,
  businessDaysUntilFutureTimeout: PropTypes.number,
  futureJourneyTimeout: PropTypes.object,
  inProgressJourneyTimeout: PropTypes.object,
  optionalStateManagementFunction: PropTypes.func,
  tripsAndMeetingDetails: PropTypes.any,
  tripsAndMeetingDetailsForNextFive: PropTypes.object,
};

export default HomepageMainContent;
