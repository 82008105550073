import { PropTypes } from "base/react-libs";
import Decorator from "clientDecorator";
import classNames from "classnames";
import Avatar from "components/elements/avatar";
import { Link } from "@evertrue/et-components";

const MapContactCard = ({
  contact = {},
  referrer,
  onMouseOver = () => {},
  onMouseOut = () => {},
  onClick = () => {},
  children,
  mapMarkerTooltipContactInfo,
}) => {
  const renderLink = (component, name) => {
    if (!contact.id) return component;

    const contactLink = `/contact/${contact.id}`;

    return (
      <Link href={contactLink} target="_self" title={`${name}'s profile`} data-refer={referrer}>
        {component}
      </Link>
    );
  };

  const isDeceased = () => {
    if (contact && contact.properties && contact.properties.deceased) return contact.properties.deceased.value;
    return false;
  };

  const formattedName = Decorator.Contacts.getDetailedName(contact) || contact.name;
  const avatar = Decorator.Profile.getAvatar(contact, contact.lids) || contact.avatar;

  return (
    <div className="new-contact-card map-tooltip" onClick={onClick} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
      <div className="new-contact-card--body">
        {renderLink(
          <Avatar
            className="new-contact-card--avatar fs-exclude-data"
            name={mapMarkerTooltipContactInfo ? mapMarkerTooltipContactInfo.name : formattedName}
            url={avatar}
            width={40}
          />,
          mapMarkerTooltipContactInfo ? mapMarkerTooltipContactInfo.name : formattedName
        )}
        <div className="new-contact-card--content" style={{ width: "calc(100% - 40px)", verticalAlign: "middle" }}>
          <div className={classNames("new-contact-card--name", isDeceased() ? "is_deceased" : "")}>
            {renderLink(
              <strong className="fs-exclude-data">
                {mapMarkerTooltipContactInfo ? mapMarkerTooltipContactInfo.name : formattedName}
              </strong>,
              mapMarkerTooltipContactInfo ? mapMarkerTooltipContactInfo.name : formattedName
            )}
          </div>
          {mapMarkerTooltipContactInfo && (
            <div>
              <div>
                <span className="contact-card--label">{`Constituency Type: `}</span>
                <span className="contact-card--value fs-exclude-data">
                  {mapMarkerTooltipContactInfo.constituencyType}
                </span>
              </div>
              <div>
                <span className="contact-card--label">{`Address Type: `}</span>
                <span className="contact-card--value fs-exclude-data">{mapMarkerTooltipContactInfo.addressType}</span>
              </div>
              <div>
                <span className="contact-card--label">{`Graduation Year: `}</span>
                <span className="contact-card--value fs-exclude-data">
                  {mapMarkerTooltipContactInfo.graduationYear}
                </span>
              </div>
            </div>
          )}
          {isDeceased() && <span className="new-contact-card--deceased fs-exclude-data">Deceased</span>}
          {children && <div className="new-contact-card--children">{children}</div>}
        </div>
      </div>
    </div>
  );
};

MapContactCard.propTypes = {
  contact: PropTypes.object,
  referrer: PropTypes.string,
  onMouseOver: PropTypes.func,
  onMouseOut: PropTypes.func,
  onClick: PropTypes.func,
  children: PropTypes.any,
  mapMarkerTooltipContactInfo: PropTypes.object,
};

export default MapContactCard;
