import { Component } from "react";
import Api from "entities/helpers/api";
import _ from "underscore";

const matchEngagementToProfile = (profiles = [], engagements = {}) => {
  const engagementsByEmail = _.groupBy(engagements.items, "email");

  return profiles.map(profile => ({
    id: profile.id,
    profile,
    engagements: engagementsByEmail[profile.email],
  }));
};

class UnmatchedEngagementsResolver extends Component {
  static propTypes = {
    profiles: ReactLibs.PropTypes.array,
    event_id: ReactLibs.PropTypes.number,
    activeFilters: ReactLibs.PropTypes.array,
    render: ReactLibs.PropTypes.func,
  };

  state = {
    engagements: [],
    loading: false,
    error: null,
  };

  xhr = undefined;

  componentDidMount() {
    const { profiles, event_id, activeFilters } = this.props;
    const eventbrite_profile_ids = _.pluck(profiles, "id");
    this.fetchEngagements(event_id, activeFilters, eventbrite_profile_ids);
  }

  componentWillUnmount() {
    this.abortXHR();
    this.xhr = undefined;
  }

  componentDidUpdate(prevProps) {
    const { profiles, event_id, activeFilters } = this.props;
    const old_ids = _.pluck(prevProps.profiles, "id");
    const new_ids = _.pluck(profiles, "id");
    if (
      _.notEmpty(_.difference(new_ids, old_ids)) ||
      prevProps.event_id !== event_id ||
      !_.isEqual(prevProps.activeFilters, activeFilters)
    ) {
      this.fetchEngagements(event_id, activeFilters, new_ids);
    }
  }

  abortXHR = () => {
    if (this.xhr && _.isFunction(this.xhr.abort)) this.xhr.abort();
  };

  fetchEngagements(event_id, activeFilters, eventbrite_profile_ids) {
    if (event_id) {
      const event_id_array = [event_id];
      this.abortXHR();
      Api.EVENTS.EVENT_ENGAGEMENTS.get({
        params: _.compactObject({
          response_type: activeFilters,
          event_id: event_id_array,
          eventbrite_profile_ids,
          limit: 100,
        }),
        beforeSend: xhr => {
          this.xhr = xhr;
        },
      }).then((engagements = {}) => {
        this.setState({
          engagements,
          loading: false,
        });
      });
    }
  }

  // render returns a property 'profilesWithEngagements',
  // which is a list of objects, each with the properties 'profile' and 'engagements'
  render() {
    const { loading, engagements, error } = this.state;
    const { profiles } = this.props;
    const profilesWithEngagements = matchEngagementToProfile(profiles, engagements);
    return this.props.render({
      profilesWithEngagements,
      loading,
      error,
    });
  }
}

export default UnmatchedEngagementsResolver;
