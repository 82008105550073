module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories
   FacetUtils = require("apps/filters/utils/facet-utils")
   FacetSource = require("apps/filters/sources/facet-source")
   FacetStore = require("apps/filters/stores/facet-store")
   FilterConfigStore = require("apps/filters/stores/filter-config-store")
   {createFactory} = require("base/new-utils")
   AdvancedCombobox = createFactory(require("@evertrue/et-components").AdvancedCombobox)



   createComponent "FilterMultiSelect",
      propTypes:
         value: ReactLibs.PropTypes.any
         filter_id: ReactLibs.PropTypes.string
         loading: ReactLibs.PropTypes.bool
         onChange: ReactLibs.PropTypes.func
         onSearch: ReactLibs.PropTypes.func

      registerStores: ->
         @on FacetStore, "filter_id", (props) ->
            facets: FacetStore.getForFilter(props.filter_id)
            facets_loading: FacetStore.getLoading()

      getInitialState: ->
         query: ""

      componentDidMount: ->
         if _.isEmpty(_.keys(@state.facets))
            FacetSource.fetchActiveFilterFacets()

      componentDidUpdate: (prevProps, prevState) ->
         if (!_.isEqual(prevState.facets, @state.facets))
            FacetSource.fetchActiveFilterFacets()

      componentWillUnmount: ->
         clearTimeout(@facetSearchTimeout)

      getFormattedFacets: ->
         config = FilterConfigStore.getOptionById(@props.filter_id) || {}
         # remove strings with only spaces and the empty string
         query = @state.query

         facets = _.mapObject @state.facets, (items, key) ->
            items = _.reject items, (facet) ->
               facet.value?.trim() == "" or
                     # this is an extra client side filter for the case
                     # where facets returns extra values
                     if query? then !_.searchCompare(facet.value, query)

         FacetUtils.parseToGroups(facets, config)

      getSelectedValues: ->
         if _.isArray(@props.value)
            FacetUtils.parseToOptions(@props.value)
         else @props.value?.value

      handleChange: (list_values) ->
         uniq_values = _.uniq(_.pluck(list_values, "value"))
         deduped_values = _.filter list_values, (val) ->
            if _.contains(uniq_values, val.value)
               uniq_values = _.without(uniq_values, val.value)
               return true
         @props.onChange?(deduped_values)

      render: ->
         facets = @getFormattedFacets()
         selected_values = @getSelectedValues()


         # Group into selected group for list components
         if _.size(_.flatten(_.values(@state.facets))) > 25
            unless _.isEmpty(selected_values)
               facets.unshift {label: "Current Selections", value: "selected", items: selected_values}

         div className: classNames("filter-multi-select", @props.className),
            AdvancedCombobox _.extend
               options: facets
               value: selected_values
               loading: !!@state.facets_loading
               searchable: true
               multiple: true
               grouped: true
               placeholder: "Select Options..."
               empty_placeholder: "No matching options"
               allowChangeAll: true
               onChange: @handleChange
               onSearch: (query) =>
                  clearTimeout(@facetSearchTimeout)
                  @facetSearchTimeout = _.wait 200, =>
                     @setState {query: query, facets: FacetStore.getSearch(@props.filter_id, query)}
            , _.omit(@props, "onChange", "filter_id", "value")
