import { useState } from "react";
import PropTypes from "prop-types";
import EverTrue from "app";
import { Button, Icon, Link } from "@evertrue/et-components";
import { extractInteractionData, shortenText, pluralize } from "./thankview-parser-helper.js";

export default function InteractionThankviewDropdown({ interaction = {} }) {
  const [isOpen, setIsOpen] = useState(false);

  const openVideo = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
    EverTrue.track.set("thankview_interactions_action", {
      type: "tv_dropdown_button_clicked",
    });
  };
  const handleVideoClick = () => {
    EverTrue.track.set("thankview_interactions_action", {
      type: "tv_dropdown_video_clicked",
    });
  };
  const handleReplyClick = () => {
    EverTrue.track.set("thankview_interactions_action", {
      type: "tv_reply_link_clicked",
    });
  };

  if (!interaction || !interaction.text) {
    return "";
  }

  const { message, replyMessage, sentDate, watchedDate } = extractInteractionData(interaction);
  const snippetMessage = shortenText(message, 250);
  const viewField =
    (interaction.custom_field && interaction.custom_field.find((field) => field.displayName === "ThankView Views")) ||
    0;
  const viewTimes = viewField ? viewField.value : 0;
  let videoUrl;
  try {
    const text = interaction.text;
    videoUrl = text.match(/\bhttps?:\/\/\S+\/(?:[\w-]+\.(?:jpg|jpeg|gif|png|mp4))\b/) || [];
  } catch (error) {
    EverTrue.alert.error("Error parsing video URL from interaction text.");
  }
  return videoUrl.length > 0 ? (
    <div className="accordion">
      <div className="accordion-content">{snippetMessage}</div>
      <div className="dropdown-container">
        {isOpen ? (
          <Button type="secondary" className="accordion-button" onClick={(e) => openVideo(e)}>
            <Icon icon="chevron-up" size={1} /> Close Video
          </Button>
        ) : (
          <Button type="secondary" className="accordion-button" onClick={(e) => openVideo(e)}>
            <Icon icon="chevron-down" size={1} /> Expand Video
          </Button>
        )}
        <div className="dropdown-details">
          {sentDate && <p className="interaction-detail--type">{sentDate} Sent</p>}
          {watchedDate && <p className="interaction-detail--type">{watchedDate} Watched</p>}
          <p className="dropdown-text-details">{pluralize(viewTimes, "Time")}</p>
          {replyMessage && (
            <Link
              href={`/interaction/${interaction.id}`}
              title="Click here to view the reply"
              className="dropdown-text-details see-reply"
              onClick={handleReplyClick}
            >
              See Reply
            </Link>
          )}
        </div>
      </div>
      {isOpen && (
        <div className="accordion-content">
          <video
            controls
            width="300"
            src={videoUrl}
            type="video/mp4"
            data-testid="thankview-video"
            onClick={handleVideoClick}
          >
            <p>
              Your browser doesn't support HTML video. Here is a<a href="videoUrl">link to the video</a> instead.
            </p>
          </video>
        </div>
      )}
      {!isOpen && <div></div>}
    </div>
  ) : (
    <>
      <div className="accordion">
        <div className="accordion-content">{snippetMessage}</div>
      </div>
      {replyMessage && (
        <Link
          href={`/interaction/${interaction.id}`}
          title="Click here to view the reply"
          className="dropdown-text-details see-reply"
          onClick={handleReplyClick}
        >
          See Reply
        </Link>
      )}
    </>
  );
}
InteractionThankviewDropdown.propTypes = {
  interaction: PropTypes.object,
};
