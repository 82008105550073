import { Component } from "react";
import _ from "underscore";
import ModalConfirm from "components/modals/modal-confirm";
import { Link } from "@evertrue/et-components";
import PropTypes from "prop-types";

class FacebookOutageModal extends Component {
  static propTypes = {
    requestHide: PropTypes.func.isRequired,
  };

  render() {
    const handleClose = () => {
      if (_.isFunction(this.props.requestHide)) {
        this.props.requestHide();
      }
    };

    return (
      <ModalConfirm
        header="Facebook Matching Unavailable"
        buttonLabel="Got it"
        hideCancelButton={true}
        onSubmit={() => {
          handleClose();
        }}
      >
        <div style={{ margin: 15 }}>
          Manual matching is temporarily unavailable due to scheduled maintenance of EverTrue’s Facebook integration.
          <div style={{ marginTop: 15 }}>
            See the{" "}
            <Link href="http://status.evertrue.com/" title="Evertrue status page" target="_blank">
              status page{" "}
            </Link>
            for additional details.
          </div>
        </div>
      </ModalConfirm>
    );
  }
}

export default FacebookOutageModal;
