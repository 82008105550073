module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   moment = require("moment")
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   {createFactory} = require("base/new-utils")
   EmptyStateMessage = createFactory(require("components/lists/empty-state-message"))
   {div} = ReactLibs.DOMFactories
   Loading = require("components/elements/loading")
   InteractionListItem = createFactory(require("apps/interactions/components/interaction-list-item").default)


   createComponent "InteractionFeed",
      propTypes:
         notes: ReactLibs.PropTypes.array
         group: ReactLibs.PropTypes.bool
         loading: ReactLibs.PropTypes.bool
         highlight: ReactLibs.PropTypes.string
         showContact: ReactLibs.PropTypes.bool
         showFullText: ReactLibs.PropTypes.bool
         showMessage: ReactLibs.PropTypes.bool
         showMeta: ReactLibs.PropTypes.bool
         contactId: ReactLibs.PropTypes.number

      render: ->
         if @props.group
            grouped_notes = _.reduce @props.notes, (obj, note) ->
               date = moment(note.date_occurred).local().format("MMMM YYYY")
               obj[date] ?= {sort: note.date_occurred, date, notes: []}
               obj[date].notes.push(note)
               obj
            , {}

         div className: classNames("note-feed", @props.className, "note-feed--loading": @props.loading),
            if @props.loading
               Loading text: "Loading..."
            else if _.isEmpty(@props.notes)
               div className: "note-feed--empty", style: minHeight: "71vh",
                  EmptyStateMessage
                     icon: "gt-comment"
                     text: "No interactions were found matching your search criteria.
                      Try adjusting your filters to find results."
            else
               div className: "note-feed--list",
                  if @props.group
                     _.map _.sortBy(grouped_notes, "sort").reverse(), (group = {}) =>
                        div key: group.year || _.randomKey(),
                           div className: "note-feed--year", group.date
                           div className: "note-feed--group",
                              _.map group.notes, (note, index) =>
                                 InteractionListItem
                                    className: classNames("note-feed-item", "is-last": index == group.notes?.length - 1)
                                    interaction: note
                                    key: note.id || _.randomKey()
                                    highlight: @props.highlight
                                    showContact: @props.showContact
                                    showFullText: @props.showFullText
                                    showDetails: false
                                    showMeta: @props.showMeta
                                    contactId: @props.contactId
                  else
                     _.map @props.notes, (note, index) =>
                        InteractionListItem
                           className: classNames("note-feed-item", "is-last": index == @props.notes.length - 1)
                           interaction: note
                           key: note.id || _.randomKey()
                           highlight: @props.highlight
                           showContact: @props.showContact
                           showFullText: @props.showFullText
                           showDetails: false
                           showMessage: @props.showMessage
                           showMeta: @props.showMeta
                           contactId: @props.contactId
