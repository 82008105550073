module.exports = do ->
   _ = require("underscore").default
   {createComponent} = require("@evertrue/et-flux")
   React = require("react")
   {div} = ReactLibs.DOMFactories
   {object, func} = ReactLibs.PropTypes
   moment = require("moment")
   {createFactory} = require("base/new-utils")
   ControlledSearchbox = require("components/forms/controlled-search-box")
   CalendarDropdown = createFactory(require("components/controls/calendar-dropdown"))
   FiltersBlock = require("apps/filters/components/filters-block")
   EventResponseDropdown = require("apps/filters/components/event-response-dropdown")
   EventFilterDropdown = require("apps/events/components/event-filter-dropdown")

   # enabling a particular filter property should only be needed
   # during development of the event filters epic.

   _msubtract = (n, string) ->
      moment().subtract(n, string).format("YYYY-MM-DD")

   renderOptions = ->
      today = moment().format("YYYY-MM-DD")

      [
         {title: "Upcoming", date_from: today}
         {title: "In Past 7 Days", date_from: _msubtract(7, "days"), date_to: today}
         {title: "In Past Month", date_from: _msubtract(1, "months"), date_to: today}
         {title: "In Past 3 Months", date_from: _msubtract(3, "months"), date_to: today}
         {title: "In Past Year", date_from: _msubtract(1, "years"), date_to: today}
         {title: "All Time", id: "all-time"}
      ]

   sub_search_config = [{
      label: "Event Date"
      key: "event_date"
      render: ({value, onChange}) ->
         {gte, lte} = value || {}
         CalendarDropdown
            date_range: renderOptions()
            date_from: gte
            date_to: lte
            onChange: (date_from, date_to) ->
               onChange(_.compactObject(gte: date_from, lte: date_to))
   },{
      label: "Event"
      key: "events"
      render: ({value, onChange}) ->
         EventFilterDropdown
            value: value
            onChange: onChange
   },{
      label: "Event Response"
      key: "event_response"
      render: ({value, onChange}) ->
         EventResponseDropdown
            value: value
            onChange: onChange

   }]


   createComponent "EventEngagementFiltersBlock",
      propTypes:
         input: object
         state: object
         onChange: func # args: (null, value) # filters config format

      handleChange: (key, value) ->
         current_value = @props.input?.value
         combined_value = _.extend({}, current_value, _.toObject(key, value))
         filtered_value = _.pick(combined_value, _.notEmpty)
         @props.onChange(null, filtered_value)

      renderFilters: ->
         {input, onChange} = @props

         _.map sub_search_config, ({key, label, render}) =>
            value = input?.value?[key]
            onChange = (val) => @handleChange(key, val)

            label: label
            component: render?({value, onChange})

      render: ->
         FiltersBlock
            filters: @renderFilters()
