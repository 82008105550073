module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   Decorator = require("clientDecorator")
   EverTrue = require("app")
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories
   MyPortfolioStore = require("apps/major-gifts/stores/my-portfolio-store")
   MyPortfolioSource = require("apps/major-gifts/sources/my-portfolio-source")
   {createFactory} = require("base/new-utils")
   ContactCard = createFactory(require("apps/contact/components/contact-card/contact-card"))
   ProspectColumnSource = require("apps/major-gifts/sources/prospect-column-source")
   Loading = require("components/elements/loading")
   EmptyStateMessage = createFactory(require("components/lists/empty-state-message"))
   ModalConfirm = createFactory require("components/modals/modal-confirm")
   SearchBox = createFactory require("components/forms/search-box")
   Checkbox = createFactory(require("@evertrue/et-components").Checkbox)
   classNames = require("classnames")

   createComponent "PorfolioPicker",
      propTypes:
         header: ReactLibs.PropTypes.string
         selected: ReactLibs.PropTypes.array
         onSelect: ReactLibs.PropTypes.func

      getInitialState: ->
         query: undefined
         selected_contacts: []

      registerStores: ->
         @on MyPortfolioStore, ->
            contacts: MyPortfolioStore.getGroup()

      componentDidMount: ->
         MyPortfolioSource.changeUser(EverTrue.store?.user?.toJSON())
         ProspectColumnSource.fetch()
         unless _.isEmpty(_.keys(@state.contacts))
            _.each @state.contacts, (group, key) ->
               MyPortfolioSource.infiniteLoadByStatus(key, 0)

      componentDidUpdate: (prevProps, prevState) ->
         if _.isEmpty(_.keys(prevState.contacts)) && !_.isEmpty(_.keys(@state.contacts))
            _.each @state.contacts, (group, key) ->
               MyPortfolioSource.infiniteLoadByStatus(key, 0)

      handleSelectContact: (id) ->
         selected = @state.selected_contacts
         if _.contains(selected, id)
            selected = _.without(selected, id)
         else selected.push(id)
         @setState {selected_contacts: selected}

      handleSelect: ->
         @props.onSelect(@state.selected_contacts)
         @props.requestHide()

      getFilteredData: ->
         filtered_data = {}
         _.each @state.contacts, (group_data, group) =>
            filtered_contacts = _.compact _.map group_data.contacts, (contact_id) =>
               contact = MyPortfolioStore.getContact(contact_id)
               full_name = Decorator.Contacts.getDetailedName(contact)
               contact if _.searchCompare(full_name, @state.query)

            if !_.isEmpty(filtered_contacts)
               filtered_data[group] = _.extend {}, group_data,
                  contacts: filtered_contacts
         filtered_data

      render: ->
         if !_.isEmpty(@state.selected_contacts)
            label = "Add #{@state.selected_contacts?.length} Constituents"
         else label = "Add Constituents"
         is_loading = _.some @state.contacts, (group) -> group.initial_loading

         results = @getFilteredData()

         ModalConfirm
            className: "my-portfolio-picker"
            header: @props.header || "Select Constituents from My Portfolio"
            buttonLabel: label
            hasMargin: false
            disableButton: _.isEmpty(@state.selected_contacts)
            onSubmit: @handleSelect
            onCancel: @props.requestHide

            div className: "my-portfolio-picker--inner clearfix",
               div className: "my-portfolio-picker--search",
                  SearchBox
                     placeholder: "Search for constituents in your portfolio"
                     onKeyUp: (query) =>
                        @setState {query: query}

               div className: "my-portfolio-picker--contacts clearfix",
                  if is_loading then Loading()
                  else if _.isEmpty(results)
                     EmptyStateMessage text: "No Constituents Found", size: "small"
                  else
                     _.map results, (group_data, group) =>
                        div className: "my-portfolio-picker--group", key: group,
                           div className: "my-portfolio-picker--group-header", group
                           div className: "my-portfolio-picker--group-contacts",
                              _.map group_data.contacts, (contact) =>
                                 is_selected = _.contains(@props.selected, contact.id)
                                 div
                                    key: contact.id
                                    className: classNames("my-portfolio-picker--group-contact", "is-disabled": is_selected)

                                    Checkbox
                                       defaultChecked: is_selected
                                       disabled: is_selected
                                       onChange: =>
                                          unless is_selected
                                             @handleSelectContact(contact.id)

                                    ContactCard
                                       contact: contact
                                       showNameLink: false


