import _ from "underscore";
import { createSource } from "@evertrue/et-flux";
import Api from "entities/helpers/api";

export default createSource("UserSettingsSource", {
  actions: {
    newUserSettings: true,
  },

  api: {
    refreshUserSettings() {
      Api.DNA.ALL.get({
        disableAlerts: true,
        params: { user_id: "me" },
        success: resp => {
          const settings = _.mapObject(resp.settings, (setting, key) => setting.value);
          this.actions.newUserSettings(settings);
        },
        error: () => {
          this.actions.newUserSettings({});
        },
      });
    },

    updateUserSetting(key, value) {
      Api.DNA.SETTING.put({
        urlArgs: { setting_key: key },
        disableAlerts: true,
        params: { user_id: "me" },
        data: _.jsonStringify({ value }),
      }).then(response => {
        this.refreshUserSettings();
      });
    },

    updateGivingCategory(value) {
      this.updateUserSetting("user_giving_category", {
        user_giving_category: value || "",
      });
    },
  },
});
