module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   PagedColumnTableRow = require("components/tables/paged-col-table-row")
   {tbody, tr, td} = ReactLibs.DOMFactories


   PagedColumnTableBody = ReactLibs.createClass
      propTypes:
         data: ReactLibs.PropTypes.any
         columns: ReactLibs.PropTypes.array
         cells: ReactLibs.PropTypes.func
         sortProp: ReactLibs.PropTypes.string
         syncing: ReactLibs.PropTypes.bool
         rowClass: ReactLibs.PropTypes.func
         onRowClick: ReactLibs.PropTypes.func

      rows: ->
         if (!@props.syncing) && _.isEmpty(@props.data)
            tr null,
               td colSpan: @props.columns.length, className:"paged-col-table--body-empty",
                  @props.emptyMessage || "Not Available"
         else
            @props.data.map (item, i) =>
               PagedColumnTableRow
                  data: item
                  sortProp: @props.sortProp
                  onRowClick: @props.onRowClick
                  rowClass: @props.rowClass
                  columns: @props.columns
                  cells: @props.cells
                  key: item.id || i

      render: ->
         tbody className: "table--body paged-col-table--body",
            @rows()

