module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories
   TripContactsSource = require("apps/my-trips/sources/trip-contacts-source")
   TripContactsStore = require("apps/my-trips/stores/trip-contacts-store")
   ListStore = require("apps/lists/stores/list-store")
   ListPicker = require("apps/lists/components/list-picker")
   ContactPicker = require("apps/contact/components/contact-picker")
   MyPortfolioPicker = require("apps/major-gifts/components/my-portfolio-picker")
   {createFactory} = require("base/new-utils")
   ActionMenuItem = createFactory(require("components/controls/action-menu-item").default)
   FeatureStore = require("apps/layout/stores/feature-store").default
   Modal = createFactory require("components/modals/modal")

   MODAL_WIDTH = 536


   createComponent "TripContactActions",
      propTypes:
         trip: ReactLibs.PropTypes.object

      registerStores: ->
         @on ListStore, ->
            lists: ListStore.getLists()

         @on FeatureStore, ->
            has_major_gifts: FeatureStore.hasFeature("major_gifts")
            has_rm_assignments: FeatureStore.hasFeature("rm_assignments")

         @on TripContactsStore, ->
            trip_contact_ids: _.pluck(TripContactsStore.getContactsForTrip(@props.trip?.id), "id")

      renderListModal: ->
         Modal width: MODAL_WIDTH,
            ListPicker
               header: "Add constituents from a list"
               onSelect: (list) =>
                  TripContactsSource.addContactsFromList(@props.trip.id, list.id)

      renderContactModal: ->
         Modal width: MODAL_WIDTH,
            ContactPicker
               header: "Add constituents by name"
               description: "Search for constituents to add to your trip."
               onSelect: (contacts) =>
                  TripContactsSource.addContacts(@props.trip.id, _.pluck(contacts, "id"))

      renderPortfolioModal: ->
         Modal width: MODAL_WIDTH,
            MyPortfolioPicker
               header: "Add Constituents from My Portfolio"
               selected: @state.trip_contact_ids
               onSelect: (contact_ids) =>
                  TripContactsSource.addContacts @props.trip.id, contact_ids,
                     referrer: "trip_contacts_portfolio"

      render: ->
         div className: "trip-contact-actions",
            ActionMenuItem
               title: "Add constituents from a list"
               icon: "playlist-add"
               modal: @renderListModal()
               "Add constituents from a list"

            if @state.has_major_gifts and !@state.has_rm_assignments
               ActionMenuItem
                  title: "Add constituents from My Portfolio"
                  icon: "work"
                  modal: @renderPortfolioModal()
                  "Add constituents from My Portfolio"

            ActionMenuItem
               title: "Add constituents by name"
               icon: "group-add"
               modal: @renderContactModal()
               "Add constituents by name"
