import PropTypes from "prop-types";
import moment from "moment";
import { BooleanRadio, SearchInput, NumericInput, Range, RollingDateInput } from "@evertrue/et-components";
import FilterValueMultiSelect from "apps/query-builder/components/filter-value-multiselect";
import CalendarDropdown from "components/controls/calendar-dropdown";
import CalendarPickerDropdown from "components/controls/calendars/calendar-picker-dropdown";
import uiComponents from "apps/query-builder/utils/filter-ui-components";

const proptypes = {
  filter: PropTypes.object,
  uiComponent: PropTypes.string,
  operator: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

const FilterInputComponent = ({ filter, uiComponent, operator, value, onChange, isDisabled = false }) => {
  if (!operator) {
    return null;
  }

  const getValue = () => {
    if (operator.match("gte")) return value.gte;
    if (operator.match("lte")) return value.lte;
    if (operator.match("gt")) return value.gt;
    if (operator.match("lt")) return value.lt;
    return value;
  };

  const setValue = (change) => {
    if (!change) return undefined;
    if (operator.match("gte")) return { gte: change };
    if (operator.match("lte")) return { lte: change };
    if (operator.match("gt")) return { gt: change };
    if (operator.match("lt")) return { lt: change };
    return change;
  };

  switch (uiComponent) {
    case uiComponents.FilterValueMultiSelect:
      return (
        <FilterValueMultiSelect
          filterId={filter.compound_id}
          label={filter.label}
          value={value}
          onChange={onChange}
          isDisabled={isDisabled}
        />
      );

    case uiComponents.SearchInput:
      return <SearchInput value={value} onChange={onChange} debounceTimeout={300} searchIcon={false} placeholder="" />;

    case uiComponents.NumberRange:
      return <Range name="number_range" value={value} onChange={onChange} />;

    case uiComponents.YearRange:
      return <Range name="number_year_range" maskType="year" integerLimit={4} value={value} onChange={onChange} />;

    case uiComponents.NumericInput:
      return (
        <NumericInput
          maskType="number"
          value={value ? getValue() : ""}
          onChange={(change) => {
            if (change === null) {
              onChange("");
            } else {
              onChange(setValue(change));
            }
          }}
        />
      );

    case uiComponents.YearInput:
      return (
        <NumericInput
          maskType="year"
          integerLimit={4}
          value={value ? getValue() : ""}
          onChange={(change) => {
            if (change === null) {
              onChange("");
            } else {
              onChange(setValue(change));
            }
          }}
        />
      );

    case uiComponents.CalendarDropdown:
      return (
        <CalendarDropdown
          date_from={value && value.gte}
          date_to={value && value.lte}
          onChange={(fr, to) => {
            if (value && value.gte === fr && value.lte === to) return;
            onChange({ gte: fr, lte: to });
          }}
        />
      );

    case uiComponents.RollingDateInput:
      return <RollingDateInput value={value ? getValue() : ""} onChange={(change) => onChange(setValue(change))} />;

    case uiComponents.CalendarPickerDropdown:
      return (
        <CalendarPickerDropdown
          value={value ? moment(getValue()).format("YYYY-MM-DD") : null}
          onChange={(change) => onChange(setValue(change))}
        />
      );

    case uiComponents.BooleanRadio:
      return (
        <BooleanRadio
          value={value}
          onChange={(name, change) => {
            onChange(change);
          }}
        />
      );

    default:
      return null;
  }
};

FilterInputComponent.propTypes = proptypes;
export default FilterInputComponent;
