import { useContext, useEffect, useState } from "react";
import EverTrue from "app";
import { PageHeader } from "@evertrue/et-components";
import ContactsFixedTable from "apps/contact/components/contacts/contact-fixed-table";
import TableToolbar from "components/tables/table-toolbar";
import FiltersBarController from "apps/query-builder/components/filters-bar-controller";
import { GlobalContactSearchContext } from "apps/layout/controllers/global-contact-search-context";
import FiltersActions from "apps/search/components/filters-actions";
import FilterReset from "apps/query-builder/components/filter-reset";
import FilterMapReset from "apps/query-builder/components/filter-map-reset";
import useFetchContacts from "apps/search/hooks/use-fetch-contacts";
import SelectedContactsStore from "apps/contact/stores/selected-contacts-store";
import { connect } from "@evertrue/et-flux";
import { PropTypes } from "base/react-libs";
import MapsController from "apps/search/controllers/maps-controller";
import MapToggle from "components/tables/map-toggle";

const mapStateToProps = () => ({
  selected: SelectedContactsStore.getSelectedContacts().length,
});

const ContactsSearchController = ({ selected = 0, showMap = false, isComingFromSearchBar = false }) => {
  const {
    contactFilters,
    setContactFilters,
    resetFiltersAndSettings,
    tableSettings,
    updateTableSettings,
    setIsAllSelectedCheckbox,
  } = useContext(GlobalContactSearchContext);
  const { contactsData, loading, offset, LIMIT, resultCount } = useFetchContacts(contactFilters, tableSettings);
  const [enrichmentLoading, setEnrichmentLoading] = useState(true);

  var query = EverTrue.UrlManager.get("query");

  useEffect(() => {
    if (isComingFromSearchBar) {
      const filter = {
        quick_filters: [],
        filters: {
          blocks: [
            {
              condition: "and_all_of",
              rules: [
                {
                  group: "Name",
                  label: "Name",
                  compound_id: EverTrue.config.environment === "production" ? "123" : "235",
                  operator: "must:wildcard",
                  value: query,
                  ui_component: "SearchInput",
                },
              ],
            },
            {
              condition: "or_all_of",
              rules: [{}],
            },
          ],
        },
      };
      resetFiltersAndSettings();
      setContactFilters(filter);
    }
  }, [isComingFromSearchBar, resetFiltersAndSettings, query, setContactFilters]);

  return (
    <>
      <PageHeader
        title="Search & Segment"
        breadcrumbs={["Constituents"]}
        onBackClick={() => EverTrue.Navigator("/constituents", true)}
        actions={<FiltersActions />}
      />
      <FiltersBarController>
        <FilterMapReset showMap={showMap} />
        <FilterReset />
      </FiltersBarController>

      {/* TODO: Get rid of the wrapper element with fixed top after removing legacy filters drawer */}
      <div className="filtered-contacts fixed-page-wrapper" style={{ top: 150 }}>
        <div className="space-between toolbar-container">
          <TableToolbar
            resultCount={resultCount}
            page={tableSettings.page}
            offset={offset}
            limit={LIMIT}
            onPage={(page) => {
              setEnrichmentLoading(true);
              updateTableSettings({ page });
              setIsAllSelectedCheckbox(false);
            }}
          />
          <MapToggle showMap={showMap} referrer={"contact"} />
        </div>
        {showMap ? (
          <MapsController loading={loading} contacts={contactsData} />
        ) : (
          <ContactsFixedTable
            topPosition="55px"
            contacts={contactsData}
            loading={loading}
            onSort={(prop, order) => updateTableSettings({ sortProp: prop, sortReverse: order })}
            showCheckbox={true}
            numSelected={selected}
            enrichmentLoading={enrichmentLoading}
            setEnrichmentLoading={setEnrichmentLoading}
          />
        )}
      </div>
    </>
  );
};

ContactsSearchController.propTypes = {
  selected: PropTypes.number,
  showMap: PropTypes.bool,
  isComingFromSearchBar: PropTypes.bool,
};

export default connect(ContactsSearchController, [SelectedContactsStore], mapStateToProps);
