import { PropTypes } from "base/react-libs";
import { formatDateFromTimestampAndAddMilliseconds } from "apps/journeys/utils/helpers/dates";
import DaysBarChart from "./bar-chart/days-bar-chart";
import HomepageCard from "../homepage-card";
import TripsAndMeetingsForNextFive from "./trips-meetings-next-five";
import Loading from "components/elements/loading";

const NextFiveDays = ({
  nextFiveDaysCounts,
  loadingNextFiveCounts,
  businessDaysUntilFutureTimeout,
  futureJourneyTimeout,
  inProgressJourneyTimeout,
  tripsAndMeetingDetailsForNextFive,
}) => {
  let message;
  if (futureJourneyTimeout) {
    message = (
      <span>
        Your upcoming cadence and task pause is starting in {businessDaysUntilFutureTimeout} business days on{" "}
        <strong>{formatDateFromTimestampAndAddMilliseconds(futureJourneyTimeout.pause_start, true)}.</strong>
      </span>
    );
  } else if (inProgressJourneyTimeout) {
    message = (
      <span>
        Your cadence steps and tasks are currently paused and will resume on{" "}
        <strong>{formatDateFromTimestampAndAddMilliseconds(inProgressJourneyTimeout.pause_end, true)}.</strong>
      </span>
    );
  }

  return (
    <HomepageCard
      leftHeading={"Your Next 5 Days"}
      rightHeading={"Based on your scheduled cadence steps and tasks"}
      headingRow={false}
      body={
        <>
          {message}
          {loadingNextFiveCounts ? (
            <div style={{ marginTop: "120px", height: "200px" }}>
              <Loading spinner_size={"small"} className={"loader-icon"} position="center" />
            </div>
          ) : (
            <div className="chart-wrapper">
              <DaysBarChart
                nextFiveDaysCounts={nextFiveDaysCounts}
                futureJourneyTimeout={futureJourneyTimeout}
                inProgressJourneyTimeout={inProgressJourneyTimeout}
              />
            </div>
          )}
          <hr></hr>
          <TripsAndMeetingsForNextFive tripsAndMeetingDetailsForNextFive={tripsAndMeetingDetailsForNextFive} />
        </>
      }
    />
  );
};

NextFiveDays.propTypes = {
  nextFiveDaysCounts: PropTypes.array,
  loadingNextFiveCounts: PropTypes.bool,
  businessDaysUntilFutureTimeout: PropTypes.number,
  futureJourneyTimeout: PropTypes.object,
  inProgressJourneyTimeout: PropTypes.object,
  tripsAndMeetingDetailsForNextFive: PropTypes.object,
};

export default NextFiveDays;
