import _ from "underscore";
import PropTypes from "prop-types";
import Api from "entities/helpers/api";
import { PromiseResolver } from "@evertrue/promise-resolver";
import { getContactsForPortfolioQuery } from "apps/portfolio-performance/portfolio-queries";

// Gets the contacts assigned to a given solicitor
const SolicitorContactsResolver = ({ solicitor_id, team, stages, children }) => {
  if (!solicitor_id) {
    return null;
  }
  const query = getContactsForPortfolioQuery("name", true, team.id, solicitor_id, stages);
  return (
    <PromiseResolver
      args={solicitor_id}
      promise={() =>
        Api.CONTACTS.SEARCH.post({
          data: _.jsonStringify(query),
          params: { limit: 200 },
        })
      }
      render={({ data = {}, loading }) => {
        return children({
          contacts: data.items,
          total: data.total,
          loading: loading,
        });
      }}
    />
  );
};

SolicitorContactsResolver.propTypes = {
  solicitor_id: PropTypes.number,
  team: PropTypes.object,
  stages: PropTypes.array,
  children: PropTypes.any,
};

SolicitorContactsResolver.defaultProps = {
  team: {},
  stages: [],
};

export default SolicitorContactsResolver;
