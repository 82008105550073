module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {div, strong} = ReactLibs.DOMFactories
   AllUsersStore = require("apps/users/stores/all-users-store").default
   MeetingsSource = require("apps/my-trips/sources/meetings-source")
   TagInput = require("components/controls/tag-input")
   {createFactory} = require("base/new-utils")
   ModalConfirm = createFactory require("components/modals/modal-confirm")
   UserCard = createFactory require("apps/users/components/user-card")


   createComponent "TripShare",
      propTypes:
         trip: ReactLibs.PropTypes.object

      getInitialState: ->
         query: undefined
         tags: []

      registerStores: ->
         @on AllUsersStore, ->
            users: AllUsersStore.getAllGivingTreeUsers()

      handleSubmit: ->
         emails = _.compact _.map @state.tags, (tag) ->
            email = if tag.type == "manual_email" then tag.name else tag.email
            if _.validateEmail(email) then email
         MeetingsSource.shareItinerary(@props.trip?.id, emails)
         @handleCancel()

      handleCancel: ->
         @props.requestHide()

      render: ->
         query = @state.query
         user_options = _.filter @state.users, (user) =>
            return false if _.isEmpty(query?.trim())
            return false if _.findWhere @state.tags, {id: user.id}
            _.searchCompare(user.name, query) || _.searchCompare(user.email, query)

         if _.isEmpty(user_options) && !_.isEmpty(query?.trim())
            user_options.push {name: @state.query, id: @state.query, type: "manual_email"}

         ModalConfirm
            header: "Email Trip Itinerary"
            buttonLabel: "Send"
            disableButton: _.isEmpty(@state.tags)
            onSubmit: @handleSubmit
            onCancel: @handleCancel

            div className: "trip-share",
               div className: "trip-share--description",
                  "Itinerary emails contain the details of all meetings in your trip.
                  Title, date, time and associated constituents names are included."

               TagInput
                  placeholder: "Enter name or email..."
                  selected: @state.tags
                  options: user_options
                  focusOnMount: true
                  onType: (query, e) =>
                     @setState {query: query}
                  onTag: (tags) =>
                     formatted_tags = _.map _.cloneData(tags), (tag) ->
                        if tag.type == "manual_email" && !_.validateEmail(tag.name)
                           _.extend tag, {className: "is-invalid"}
                        tag
                     @setState {tags: formatted_tags, query: undefined}
                  itemContent: (item) ->
                     if item.type == "manual_email"
                        div className: "trip-share--manual-desc",
                           "Send itinerary to "
                           strong null, item.name
                     else UserCard user: item
