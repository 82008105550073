import { useState } from 'react';
import EverTrue from "app";
import PropTypes from "prop-types";
import _ from "underscore";
import moment from "moment";
import { useGate } from "components/is-gated";
import { Icon, IconButton, AdvancedCombobox } from "@evertrue/et-components";

const CareerUpdateExports = ({ careerType, careerExports, trackingKey }) => {

  const [hasCareerUpdates] = useGate("career_updates");

  const [lastUpdateTimeState, setLastUpdateTimeState] = useState(null)
  const [exportUrlState, setExportUrlState] = useState(null)

  const options = careerExports.filter(item => item.source === careerType).map((option) => {
    return { label: moment(option.last_modified).format("MMM DD, YYYY h:mm A"), value: option.last_modified };
  });

  const getLastUpdateTime = () => {
    return lastUpdateTimeState || (careerExports.filter(item => item.source === careerType)[0] && careerExports.filter(item => item.source === careerType)[0].last_modified)
  }

  const getExportUrl = () => {
    EverTrue.track.set("manual_export_action", { type: trackingKey });
    return exportUrlState || (careerExports.filter(item => item.source === careerType)[0] && careerExports.filter(item => item.source === careerType)[0].url)
  }

  const changeLastUpdatedTime = (val) => {
    setLastUpdateTimeState(val)
  }

  const changeExportUrl = (val) => {
    setExportUrlState(val)
  }

  return hasCareerUpdates ? (
    <div className="export-item">
      <div className="export-item--title">
        <div className="export-item--heading">Career Moves {careerType === "windfall" ? "(All)" : careerType === "windfall_updates" ? "(Updates)" : careerType === "salesintel_all_matches" ? "(All Matches)" : "(High Confidence Matches)"}</div>
        <div className="export-item--subheading">
          {careerType.startsWith("windfall") ? "" : careerType === "salesintel_all_matches"
            ? "This file includes all career moves records where Evertrue was able to match the career move data to a given constituent with a high, medium, or low level of confidence"
            : "This file includes only records for which Evertrue has a high confidence that the career move data matches up with the given constituents"}
        </div>
      </div>

      <div className="export-item--frequency">
        <Icon icon="date-range" /> {careerType.startsWith("windfall") ? "1 month" : "3 months"}
      </div>
      <div className="export-item--datepicker">
        {getLastUpdateTime() ? (
          <AdvancedCombobox
            options={options}
            value={{ label: moment(getLastUpdateTime()).format("MMM DD, YYYY h:mm A"), value: getLastUpdateTime() }}
            onChange={(selection) => {
              changeLastUpdatedTime(selection.value)
              changeExportUrl(_.findWhere(careerExports, { last_modified: selection.value }).url)
            }}
          />
        ) : (
          "No data available yet"
        )}
      </div>
      <div className="export-item--manual-button">
        {getLastUpdateTime() ? (
          <IconButton
            title="download file"
            size={2}
            icon="cloud-download"
            onClick={() => (window.location = getExportUrl())}
          />
        ) : (
          <IconButton title="no download file available" size={2} icon="cloud-download-blocked" disabled />
        )}
      </div>
    </div>
  ) : null;
};

CareerUpdateExports.propTypes = {
  careerType: PropTypes.string,
  careerExports: PropTypes.array,
  trackingKey: PropTypes.string,
};

export default CareerUpdateExports;
