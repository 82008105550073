import Nav from "components/lists/nav";
import NavItem from "components/lists/nav-item";
import EverTrue from "app";
import PropTypes from "prop-types";

const ActivityNav = ({ tab }) => {
  return (
    <Nav
      active={tab}
      className="reporting--nav"
      onSelect={selected_tab => {
        // We are using this onSelect on <Nav> instead of href on <NavItem>
        // because the href were causing this controller to mount every time the NavItem was clicked
        // and ideally we want the url to update but not to remount this controller
        EverTrue.UrlManager.navigate(`/reporting/activity/${selected_tab}`, true, false);
      }}
    >
      <NavItem activeWhen="completed" label="Completed tasks" />
      <NavItem activeWhen="skipped" label="Skipped tasks" />
      <NavItem activeWhen="overdue" label="Overdue tasks that are incomplete" />
    </Nav>
  );
};

ActivityNav.propTypes = {
  tab: PropTypes.string,
};

export default ActivityNav;
