module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   EverTrue = require("app")
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   {div, strong, span} = ReactLibs.DOMFactories
   colors = require("base/colors")

   createComponent "GiftTooltip",
      propTypes:
         value: ReactLibs.PropTypes.any
         valueColor: ReactLibs.PropTypes.string
         amount: ReactLibs.PropTypes.any
         subText: ReactLibs.PropTypes.string
         isEmpty: ReactLibs.PropTypes.bool
         canClick: ReactLibs.PropTypes.bool

      getDefaultProps: ->
         valueColor: colors.green300

      render: ->
         div className: classNames("giving-module--tooltip", @props.className),
            if @props.isEmpty
               div className: "giving-module--tooltip-empty", "No gifts"
            else
               div null,
                  # yValue and Series
                  div className: "giving-module--tooltip-value", style: {color: @props.valueColor},
                     strong null, @props.amount

                  # xValue and subtext
                  div null,
                     @props.value
                     if @props.subText
                        " (#{@props.subText})"

                  if @props.canClick
                     div className: "text-label", "Click for details"
