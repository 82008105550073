import { Icon, Button } from "@evertrue/et-components";
import useDrawer from "./use-drawer";
import FeatureStore from "apps/layout/stores/feature-store";
import Dialer from "components/dialer/dialer";
import Drawer from "components/drawer/drawer";
import PropTypes from "prop-types";
import { trackOutreachAction } from "apps/outreach/utils/utils";
import { useEffect } from "react";

const DialerDrawerButton = ({ drawerTitle, contact, name, phones, activeModal, setActiveModal }) => {
  const { openDrawer, closeDrawer, isOpen, toggleMinimize, isMinimized } = useDrawer();

  // when the user exits out of modal, clear active modal state
  const closeAndClearDrawer = () => {
    if (setActiveModal && activeModal) {
      setActiveModal(null);
    }
    closeDrawer();
  };

  /**
   * track the active modal when navigating from profile-header to prevent overlap
   * and close the drawer if the most recent active modal is not email
   */
  useEffect(() => {
    if (isOpen && setActiveModal) {
      setActiveModal("phone");
    }
    if (activeModal === "phone") {
      openDrawer();
    }
    if (activeModal && activeModal !== "phone") {
      closeDrawer();
    }
  }, [isOpen, activeModal, setActiveModal, openDrawer, closeDrawer]);

  return (
    FeatureStore.hasFeature("signal") && (
      <>
        <Button
          type="secondary"
          onClick={() => {
            trackOutreachAction("open_dialer");
            setActiveModal("phone");
            openDrawer();
          }}
          className={isOpen ? "round-button-focused" : "round-button"}
        >
          {isOpen ? (
            <Icon icon="call" style={{ color: "#A54AB5" }} />
          ) : (
            <Icon icon="call" style={{ color: "#007C9E" }} />
          )}
        </Button>
        <Drawer
          isOpen={isOpen}
          toggleMinimize={toggleMinimize}
          isMinimized={isMinimized}
          close={closeAndClearDrawer}
          title={drawerTitle}
        >
          <Dialer contact={contact} name={name} phones={phones} />
        </Drawer>
      </>
    )
  );
};

DialerDrawerButton.propTypes = {
  drawerTitle: PropTypes.string,
  contact: PropTypes.object,
  name: PropTypes.string,
  phones: PropTypes.array,
  activeModal: PropTypes.string,
  setActiveModal: PropTypes.func,
};

export default DialerDrawerButton;
