import { useIdentity } from "base/identity-resolver";
import useHomePage from "apps/home/hooks/use-home-page";
import HomepageMainContent from "../components/homepage-main-content";
import useJourneyTimeoutsApi from "apps/journeys/hooks/use-journey-timeouts-api";
import useTripsAndMeetings from "../hooks/use-trips-meetings";

const HomepageController = () => {
  const { user = {} } = useIdentity();
  const {
    todayTaskCount,
    addTaskToCount,
    nextFiveDaysCounts,
    todayJourneyCount,
    loadingJourneysTodayCount,
    loadingTasksTodayCount,
    loadingNextFiveCounts,
  } = useHomePage();
  const { businessDaysUntilFutureTimeout, futureJourneyTimeout, inProgressJourneyTimeout } = useJourneyTimeoutsApi();
  const { tripsAndMeetingDetails, tripsAndMeetingDetailsForNextFive } = useTripsAndMeetings(user.id);

  const optionalStateManagementFunction = (type, args = {}) => {
    switch (type) {
      case "addTask":
        addTaskToCount(args.task);
        break;
      default:
        break;
    }
  };

  return (
    <HomepageMainContent
      user={user}
      todayJourneyCount={todayJourneyCount}
      todayTaskCount={todayTaskCount}
      nextFiveDaysCounts={nextFiveDaysCounts}
      loadingJourneysTodayCount={loadingJourneysTodayCount}
      loadingTasksTodayCount={loadingTasksTodayCount}
      loadingNextFiveCounts={loadingNextFiveCounts}
      futureJourneyTimeout={futureJourneyTimeout}
      inProgressJourneyTimeout={inProgressJourneyTimeout}
      businessDaysUntilFutureTimeout={businessDaysUntilFutureTimeout}
      tripsAndMeetingDetails={tripsAndMeetingDetails}
      tripsAndMeetingDetailsForNextFive={tripsAndMeetingDetailsForNextFive}
      optionalStateManagementFunction={optionalStateManagementFunction}
    />
  );
};

export default HomepageController;
