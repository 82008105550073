import _ from 'underscore';
import PropTypes from 'prop-types';
import ActiveJourneyPill from 'components/elements/active-journey-pill';
import StrikeThroughWrapper from 'components/containers/strikethrough-wrapper';

const TaskName = ({
    title,
    completed_at,
    metadata = {},
    onClick,
}) => {

    return (
        <div className="dynamic-lists-table--link fs-exclude-data">
            <StrikeThroughWrapper hasStrikeThrough={!!completed_at}>
                <a title={`Open task ${title}`}
                    onClick={onClick}>
                    {title}
                </a>
            </StrikeThroughWrapper>

            {!_.isEmpty(metadata) && (
                <ActiveJourneyPill
                    activeCadence={metadata}
                />
            )}
        </div>
    );
}

export default TaskName;

TaskName.propTypes = {
    title: PropTypes.string,
    completed_at: PropTypes.number,
    metadata: PropTypes.object,
    onClick: PropTypes.func,
}