import { Component } from "react";
import ErrorLogger from "entities/helpers/error-log-helper";
import EmptyStateMessage from "components/lists/empty-state-message";

class PageErrorBoundary extends Component {
  static propTypes = {
    children: ReactLibs.PropTypes.any,
    page_name: ReactLibs.PropTypes.string.isRequired,
  };

  state = { has_error: false, error_count: 0 };

  componentDidCatch(error, info) {
    this.setState((state) => ({ has_error: true, error_count: state.error_count + 1 }));
    const { page_name } = this.props;
    const { error_count } = this.state;
    ErrorLogger.error("Page Render Errror", { extra: { info, error, page_name, error_count } });
  }

  componentDidUpdate(prevProps) {
    if (this.state.has_error && prevProps.children !== this.props.children) {
      this.setState({ has_error: false });
    }
  }

  render() {
    if (this.state.has_error || this.state.error_count > 25) {
      return (
        <EmptyStateMessage
          className="page-error-boundary"
          actionLabel="Contact EverTrue Support"
          onAction={() => (window.location.href = "mailto:genius@evertrue.com")}
          size="page"
          icon="build"
          text="There was a problem displaying this page"
        />
      );
    }
    return this.props.children || null;
  }
}

export default PageErrorBoundary;
