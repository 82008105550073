module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   ExportFieldStore = require("apps/export/stores/export-field-store")
   {div} = ReactLibs.DOMFactories
   ExportFieldSelector = require("apps/export/components/export-field-selector")
   {createFactory} = require("base/new-utils")
   ModalConfirm = createFactory require("components/modals/modal-confirm")


   ExportSearchController = ReactLibs.createClass
      propTypes:
         onExport: ReactLibs.PropTypes.func

      getInitialState: ->
         {step: 1}

      handleExport: ->
         property_ids = ExportFieldStore.getSelectedPropertyIds()
         @props.onExport?(property_ids)
         @handleClose()


      handleClose: ->
         if _.isFunction @props.requestHide
            @props.requestHide()
         if _.isFunction @props.onClose
            @props.onClose()

      render: ->
         div className: "export-search",
            ModalConfirm
               type: "accent"
               header: "Export Fields"
               buttonLabel: "Start Export"
               onSubmit: @handleExport
               onCancel: @handleClose

               div null,
                  ExportFieldSelector()
