import { Icon } from "@evertrue/et-components";
import PropTypes from "prop-types";

export const DrawerHeader = ({ title, isMinimized, onMinimize, close }) => {
  return (
    <div className="drawer--header">
      <div className="drawer--header-title">{title}</div>
      <div>
        <button onClick={onMinimize} className="drawer--header-button">
          <Icon icon={isMinimized ? "chevron-up" : "minus"} />
        </button>
        <button onClick={close} className="drawer--header-button">
          <Icon icon="close" />
        </button>
      </div>
    </div>
  );
};

DrawerHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isMinimized: PropTypes.bool,
  onMinimize: PropTypes.func,
  close: PropTypes.func,
};

export default DrawerHeader;
