/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
module.exports = (function () {
  const _ = require("underscore").default;
  const EverTrue = require("app");
  const { createSource } = require("@evertrue/et-flux");
  const Api = require("entities/helpers/api");
  const ErrorLogger = require("entities/helpers/error-log-helper");

  const getCreateType = (type) => {
    switch (type) {
      case "virtual":
        return "create_virtual_meeting";
      case "local":
        return "create_local_meeting";
      case "phone":
        return "create_phone_meeting";
      case "meeting":
        return "create_meeting";
      default:
        return null;
    }
  };

  const getUpdateType = (type) => {
    switch (type) {
      case "virtual":
        return "update_virtual_meeting";
      case "local":
        return "update_local_meeting";
      case "phone":
        return "update_phone_meeting";
      case "meeting":
        return "update_meeting";
      default:
        return null;
    }
  };

  return createSource("MeetingsSource", {
    actions: {
      loading: true,
      savedMeeting: true,
      deletedMeeting: true,
      fetchedMeetingsForTrip: true,
      forceTripRefresh: true,
    },

    api: {
      fetchMeetingsForTrip(trip_id) {
        let timeout = undefined;

        return Api.VOYAGER.MEETINGS.get({
          params: { trip_id, limit: 100 },
          beforeSend: () => {
            // Prevent loading from firing unless this request takes a long time
            // Websockets currently cause this to fire extra, but a platform change should resolve that
            return (timeout = _.delay(() => this.actions.loading(true), 300));
          },
          success: (meetings) => {
            clearTimeout(timeout);
            this.actions.fetchedMeetingsForTrip(trip_id, meetings);
            this.actions.loading(false);
            if (meetings.total >= meetings.limit) {
              ErrorLogger.warn("Meetings exceed trip limit", { extra: { trip_id, total: meetings.total } });
            }
          },
          error: () => {
            return this.actions.loading(false);
          },
        });
      },

      saveMeeting(data) {
        const is_updating = !!data.id;
        return Api.VOYAGER.MEETINGS.request({
          type: is_updating ? "PUT" : "POST",
          params: !is_updating ? { trip_id: data.id } : undefined,
          urlExtend: is_updating ? `/${data.id}` : undefined,
          data: _.jsonStringify(data),
          success: (meeting) => {
            if (meeting == null) {
              meeting = {};
            }
            this.actions.forceTripRefresh();

            if (data.type !== "timeblock") {
              EverTrue.track.set("trip_action", {
                type: is_updating ? getUpdateType(data.type) : getCreateType(data.type),
              });
            }

            if (is_updating) {
              return EverTrue.Alert.success("Successfully edited the meeting");
            } else {
              return EverTrue.Alert.success("Successfully added the meeting");
            }
          },

          error({ status }, statusText, error) {
            if (status === 404) {
              EverTrue.Alert.error("There was an error saving your meeting.");
            }
            return ErrorLogger.error("SAVE MEETING ERROR", { extra: { statusText } });
          },
        });
      },

      saveTripMeeting(trip_id, data) {
        const is_updating = !!data.id;
        return Api.VOYAGER.MEETINGS.request({
          type: is_updating ? "PUT" : "POST",
          params: !is_updating ? { trip_id } : undefined,
          urlExtend: is_updating ? `/${data.id}` : undefined,
          data: _.jsonStringify(data),
          success: (meeting) => {
            this.actions.savedMeeting(meeting);

            if (data.type !== "timeblock") {
              EverTrue.track.set("trip_action", {
                type: is_updating ? getUpdateType(data.type) : getCreateType(data.type),
              });
            }

            if (is_updating) {
              return EverTrue.Alert.success("Successfully edited the meeting");
            } else {
              return EverTrue.Alert.success("Successfully added the meeting");
            }
          },
          error({ status }, statusText, error) {
            if (status === 404) {
              EverTrue.Alert.error("There was an error saving your meeting.");
            }
            return ErrorLogger.error("SAVE MEETING ERROR", { extra: { statusText } });
          },
        });
      },

      delete(meeting_id) {
        return Api.VOYAGER.MEETINGS.delete({
          urlExtend: `/${meeting_id}`,
          success: (resp) => {
            this.actions.deletedMeeting(meeting_id);
            EverTrue.track.set("trip_action", { type: "delete_meeting" });
          },
        });
      },

      shareItinerary(trip_id, emails) {
        return Api.VOYAGER.TRIPS.post({
          urlExtend: `/${trip_id}/share`,
          data: _.jsonStringify({ to: emails }),
          statusCode: {
            202() {
              return EverTrue.Alert.success("Itinerary Emails Sent");
            },
          },
        });
      },
    },
  });
})();
