import { useState, useEffect } from "react";
import Api from "entities/helpers/api";
import { isQueryValid } from "apps/query-builder/utils/is-query-valid";

const useResultCount = (filters) => {
  const [loading, setLoading] = useState(false);
  const [resultCount, setResultCount] = useState(0);
  const isValid = isQueryValid(filters);

  useEffect(() => {
    let canceled = false;

    if (isValid) {
      const loadResultCount = async () => {
        setLoading(true);

        try {
          const resp = await Api.HUB.FILTERS_SEARCH_CONTACTS.post({
            params: { limit: 0 },
            data: JSON.stringify(filters),
          });
          if (!canceled) {
            setResultCount(resp.total);
          }
          setLoading(false);
        } catch {
          setLoading(false);
        }
      };

      loadResultCount();
    }

    return () => (canceled = true);
  }, [filters, isValid]);

  return [resultCount, isValid, loading];
};

export default useResultCount;
