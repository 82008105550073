module.exports = do ->
   _ = require("underscore").default
   moment = require("moment")
   EverTrue = require("app")
   {createStore} = require("@evertrue/et-flux")
   MyTripsSource = require("apps/my-trips/sources/my-trips-source")
   TripContactSource = require("apps/my-trips/sources/trip-contacts-source")
   TripLogSource = require("apps/my-trips/sources/trip-log-source")
   MeetingsSource = require("apps/my-trips/sources/meetings-source")
   UserSource = require("base/user/user-source")
   SocketSource = require("apps/notifications/sources/socket-source")
   Decorator = require("clientDecorator")
   AllUsersStore = require("apps/users/stores/all-users-store").default
   AllUsersSource = require("apps/users/sources/all-users-source")

   _trackSave = (type, trip) ->
      EverTrue.track.set "trip_action",
         type: "trip_#{type}"
         trip_id: trip.id
         trip_length: moment(trip.end_at).diff(moment(trip.start_at), "days")

   _formatCollaboratorObj = (id, is_owner = false) ->
      user = AllUsersStore.getUser(id)
      name = user.name || "Removed User"
      return {
         id: id
         name: user.name
         avatar: user.avatar
         label: name + if is_owner then " is owner" else " can edit"
      }

   createStore "MyTripsStore",
      getInitialState: ->
         user_trips: {}
         current_user_data: {}
         selected_user: undefined
         selected_trip: undefined
         fetch_all: false
         calendar_feed_url: undefined

      firstListenerDidMount: ->
         SocketSource.bindUser("trip_added")
         SocketSource.bindUser("trip_updated")
         SocketSource.bindUser("trip_deleted")

      lastListenerWillUnmount: ->
         SocketSource.unbindUser("trip_added")
         SocketSource.unbindUser("trip_updated")
         SocketSource.unbindUser("trip_deleted")

      registerActions: ->
         @on MyTripsSource.actions.loading, @respondLoading
         @on MyTripsSource.actions.fetchedTrips, @respondToFetchedTrips
         @on MyTripsSource.actions.fetchedBulkTrips, @respondToFetchedBulk
         @on MyTripsSource.actions.fetchCache, @respondToFetchCache
         @on MyTripsSource.actions.fetchAll, ->
            @setState {fetch_all: true}

         @on MyTripsSource.actions.setUser, @respondToSetUser
         @on MyTripsSource.actions.fetchedCurrentUserData, @respondToFetchedCurrentUserData
         @on MyTripsSource.actions.setTrip, @respondToSetTrip

         @on MyTripsSource.actions.fetchedTrip, @respondToFetchedTrip
         @on MyTripsSource.actions.createdTrip, @respondToCreate
         @on MyTripsSource.actions.updatedTrip, @respondToUpdate
         @on MyTripsSource.actions.deletedTrip, @respondToDelete

         @on MyTripsSource.actions.fetchedCalendarFeed, @respondToFetchedFeed

         @on SocketSource.actions.trip_added, @respondToSocketChange
         @on SocketSource.actions.trip_updated, @respondToSocketChange
         @on SocketSource.actions.trip_deleted, @respondToSocketChange

         @on UserSource.actions.newUser, (user, options) ->
            @setState {selected_user: user.id}

         @on AllUsersSource.actions.fetchedUsers, @trigger

         @on MeetingsSource.actions.forceTripRefresh, @respondToForceRefresh

      respondLoading: (is_loading) ->
         @setState {loading: is_loading}

      respondToFetchedTrips: (user_id, response) ->
         trips = _.cloneData @getState("user_trips")

         # Merge paginated trips
         if response.offset > 0 && trips[user_id]
            user_trips = _.extend trips[user_id], {offset: response.offset}
            _.each response.items, (trip) ->
               if update_trip = _.findWhere(user_trips.items, {id: trip.id})
                  _.extend update_trip, trip
               else user_trips.items.push(trip)
         else
            trips[user_id] = response

         if user_id == @getSelectedUser() && @getState("fetch_all")
            _.each response.items, (trip) ->
               TripLogSource.fetchInteractions(trip.id, {prevent_full_load: true})

         @setState {user_trips: trips, fetch_all: false}
         MyTripsSource.changedTrips()

      respondToFetchCache: (ids) ->
         cached_ids = _.keys(@getAllTrips())
         new_ids = _.difference ids, cached_ids
         if !_.isEmpty(new_ids)
            MyTripsSource.fetchBulkTrips(new_ids)

      respondToFetchedBulk: (trips_object) ->
         trips = _.cloneData @getState("user_trips")
         _.each trips_object, (trip) ->
            trips[trip.user_id] ?= {items: []}
            update_trip = _.findWhere trips[trip.user_id].items, {id: trip.id}
            if update_trip then _.extend update_trip, trip
            else trips[trip.user_id].items.push trip
         @setState {user_trips: trips}
         MyTripsSource.changedTrips()

      respondToSetUser: (user_id) ->
         @setState {selected_user: user_id}

      respondToFetchedCurrentUserData: (user_data) ->
         @setState {current_user_data: user_data}

      respondToCreate: (trip, contact_ids, filterParams, isFiltersV2) ->
         current_user = EverTrue.store.user.get("id")
         selected_user = @getSelectedUser()
         is_trip_route = window.location.pathname.match("trips")

         if selected_user && selected_user != current_user && is_trip_route
            EverTrue.Alert.success "Created trip #{trip.name}",
               actionLabel: "View Trip"
               onAction: -> EverTrue.Navigator("/trips/#{trip.id}", true)
         else MyTripsSource.fetchTrips(current_user)

         if !_.isEmpty(contact_ids)
            TripContactSource.addContacts(trip.id, _.uniq(contact_ids))
         else if !_.isEmpty(filterParams)
            TripContactSource.addContactsFromQuery(trip.id, filterParams, undefined, isFiltersV2)
         _trackSave("create", trip)

      respondToUpdate: (trip, options={}) ->
         unless options.disableAlerts
            EverTrue.Alert.success "Successfully Updated #{trip.name}!"
            _trackSave("edit", trip)
         MyTripsSource.fetchTrips(@getSelectedUser())

      respondToDelete: (trip_id) ->
         selected_user = @getSelectedUser() || EverTrue.store?.user?.get("id")
         all_user_trips = _.cloneData @getState("user_trips")
         user_trips = all_user_trips[selected_user]
         user_trips.items = _.filter user_trips.items, (item) -> trip_id != item.id
         @setState {user_trips: all_user_trips}
         MyTripsSource.fetchTrips(selected_user)
         EverTrue.track.set "trip_action",
            type: "trip_delete"
            trip_id: trip_id

      respondToSetTrip: (trip_id) ->
         @setState {selected_trip: trip_id}
         MyTripsSource.fetchTrip(trip_id)

      respondToFetchedTrip: (trip_id, trip_data) ->
         user_trips = _.cloneData(@getState("user_trips"))
         trips = user_trips[trip_data?.user_id]
         trip = _.findWhere trips?.items, {id: trip_data.id}
         meeting = _.findWhere trips?.items, {trip_id: trip_data.id}
         if trip then _.extend trip, trip_data
         else if meeting then _.extend meeting, trip_data
         else user_trips[trip_data.user_id] = {items: _.union(trips?.items, [trip_data])}
         @setState {user_trips: user_trips}
         MyTripsSource.changedTrips()

      respondToSocketChange: ->
         current_user = EverTrue.store?.user?.get("id")
         if @getSelectedUser() == current_user
            MyTripsSource.fetchTrips(current_user)

      respondToForceRefresh: ->
         selected_id = @getSelectedUser()
         MyTripsSource.fetchTrips(selected_id)

      respondToFetchedFeed: (url) ->
         @setState {calendar_feed_url: url}

      api:
         getAllTrips: ->
            current_user_id = EverTrue.store?.user?.get("id")
            trip_object = {}
            _.each @getState("user_trips"), (trips) ->
               _.each trips.items, (trip) ->
                  trip_object[trip.id] = _.extend {}, trip,
                     is_my_trip: current_user_id == trip.user_id
            trip_object

         getTrips: (user_id) ->
            user_id ?= @getSelectedUser()
            if !user_id then user_id = EverTrue.store?.user?.get("id")

            current_user_id = EverTrue.store?.user?.get("id")
            _.map @getState("user_trips")[user_id]?.items, (trip) ->
               _.extend {}, Decorator.MyTrips.parse(trip),
                  is_my_trip: current_user_id == trip.user_id

         getAllTripsByDate: ->
            trips = @getTrips()
            current_date = moment().format("YYYY-MM-DD")
            upcoming_trips = _.filter trips, (trip) ->
               if trip.end_datetime
                  moment(trip.end_datetime?.date_string).isSameOrAfter(current_date)

            past_trips = _.filter trips, (trip) ->
               if trip.end_datetime
                  moment(trip.end_datetime?.date_string).isBefore(current_date)

            {upcoming_trips, past_trips}

         getTrip: (trip_id) ->
            trip = @getAllTrips()[trip_id]
            current_user_id = EverTrue.store.user.get("id")
            if _.contains(trip?.solicitor_user_ids, current_user_id)
               _.extend({}, trip, {is_my_trip: true})
            else
               trip

         getTripCollaborators: (trip_id) ->
            trip = @getTrip(trip_id)
            if trip?.solicitor_user_ids?.length
               collaborators = _.map trip.solicitor_user_ids, (id) -> _formatCollaboratorObj(id)
               owner = _formatCollaboratorObj(trip.user_id, true)
               full_collaborators = [owner].concat(collaborators)
               _.uniq(full_collaborators, "id")
            else []

         getLoading: ->
            @getState("loading")

         getSelectedUser: ->
            @getState("selected_user")
         
         getCurrentUserData: ->
            @getState("current_user_data")

         getSelectedTrip: ->
            @getState("selected_trip")

         getCalendarFeedUrl: ->
            @getState("calendar_feed_url")
