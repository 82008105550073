import { PropTypes } from "base/react-libs";
import { Popover2, Button, Icon } from "@evertrue/et-components";
import EllipsisOverflow from "components/formatting/ellipsis-overflow";
import FilterPicker from "apps/query-builder/components/filter-picker/filter-picker";

const FilterDropdown = ({ selectedFilter = {}, onSelection, isDisabled = false }) => {
  return (
    <Popover2
      placement="bottom-start"
      trigger={({ openPopover }) => (
        <Button className="filter-picker--trigger" type="secondary" onClick={openPopover} disabled={isDisabled}>
          <EllipsisOverflow>
            {selectedFilter.label && selectedFilter.group ? (
              <>
                {selectedFilter.group}
                <Icon icon="chevron-right" size={1} />
                {selectedFilter.label}
              </>
            ) : (
              "Filters"
            )}
          </EllipsisOverflow>
          <Icon className="filter-picker--trigger--icon" size={1} icon="chevron-down" />
        </Button>
      )}
    >
      {({ closePopover }) => (
        <FilterPicker
          selectedFilter={selectedFilter}
          onSelection={(filter) => {
            onSelection(filter);
            closePopover();
          }}
        />
      )}
    </Popover2>
  );
};

FilterDropdown.propTypes = {
  selectedFilter: PropTypes.object,
  onSelection: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export default FilterDropdown;
