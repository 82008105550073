module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   classNames = require("classnames")
   React = require("react")
   {div} = ReactLibs.DOMFactories
   LayoutSource = require("apps/layout/sources/layout-source")
   WindowOverflowMixin = require("mixins/window-overflow-mixin")

   EllipsisOverflow = ReactLibs.createClass

      getInitialState: ->
         overlay_key: null

      getDefaultProps: ->
         component: "div"
         showTooltip: true

      componentDidMount: ->
         @depracated_is_mounted = true

      componentWillUnmount: ->
         @depracated_is_mounted = false
         clearTimeout(@revealDelay)
         if @state.overlay_key
            LayoutSource.removeOverlay(@state.overlay_key)

      handleReveal: ->
         if @props.showTooltip
            clearTimeout(@revealDelay)
            @revealDelay = _.delay =>
               @renderOverlay()
            , 400

      handleClose: ->
         clearTimeout(@revealDelay)
         if @state.overlay_key
            LayoutSource.removeOverlay(@state.overlay_key)

      getFullWidth: ->
         element = @refs.concealed
         ruler = $("<span className='overflow-ruler'>#{$(element).html()}</span>")
         $("body").append(ruler)
         ruler.css
            "font-family": $(element).css("font-family")
            "font-size": $(element).css("font-size")
            "visibility": "hidden"
         fullWidth = ruler.width()
         ruler.remove()
         fullWidth

      getHeight: ->
         element = @refs.concealed
         $(element).height()

      getWidthDiff: ->
         element = @refs.concealed
         if element and $(element).width() > 0
            @getFullWidth() - $(element).width()
         else 0

      getOverlay: ->
         div className: classNames("ellipsis-overflow--content", {"is-max-width": @getFullWidth() > 800}),
            @props.children

      renderOverlay: ->
         return unless @depracated_is_mounted
         dom_props = WindowOverflowMixin.getDOMProperties(@)
         key = @state.overlay_key || _.randomKey()
         if @getWidthDiff() > 0
            @setState {overlay_key: key}
            top = dom_props.top + @getHeight()

            # Adjust position left if it extends beyond window
            window_width = $(window).outerWidth()
            full_width = @getFullWidth() + 14 # 14 is for padding and border on ellipsis-overflow--content
            if full_width + dom_props.left > window_width
               dom_props.left = window_width - full_width
            LayoutSource.overlay @getOverlay(), _.extend(dom_props, {key: key, top: top})

      render: ->
         div
            className: "overflow-text"
            onMouseOver: @handleReveal
            onMouseOut: @handleClose

            React.createElement(
               @props.component
               {className: classNames("ellipsis-overflow", @props.className), ref: "concealed"},
                  @props.children)
