module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   {div, g} = ReactLibs.DOMFactories
   Chart = require("components/charts/chart")
   ChartResizer = require("components/charts/chart-resizer")
   EmptyChart = require("components/charts/empty-chart")
   Grid = require("components/charts/grid")


   createComponent "ChartContainer",
      propTypes:
         data: ReactLibs.PropTypes.array
         padding: ReactLibs.PropTypes.object
         height: ReactLibs.PropTypes.number
         defaultWidth: ReactLibs.PropTypes.number
         xFormat: ReactLibs.PropTypes.func
         yFormat: ReactLibs.PropTypes.func
         xTicks: ReactLibs.PropTypes.array
         yTicks: ReactLibs.PropTypes.array
         showXGridLines: ReactLibs.PropTypes.bool
         showYGridLines: ReactLibs.PropTypes.bool
         onSetScales: ReactLibs.PropTypes.func.isRequired
         onGetCoords: ReactLibs.PropTypes.func
         onMouseOut: ReactLibs.PropTypes.func

      getInitialState: ->
         chart_width: @props.defaultWidth
         chart_height: @props.height
         grid_width: @props.defaultWidth
         grid_height: @props.height
         xScale: undefined
         yScale: undefined
         padding: @props.padding

      componentDidMount: ->
         @setScales()

      UNSAFE_componentWillReceiveProps: (newProps) ->
         if newProps.data != @props.data
            @setScales()

         if newProps.height != @props.height
            @setState {chart_height: newProps.height}
            _.defer (=> @setScales())

         if !_.isEqual(newProps.padding, @props.padding)
            if !newProps.padding then new_padding = undefined
            else new_padding = _.extend {}, @state.padding, newProps.padding
            @setState {padding: new_padding}

      setScales: ->
         @props.onSetScales
            grid_width: @state.grid_width
            grid_height: @state.grid_height
            chart_height: @state.chart_height
            chart_width: @state.chart_width
            padding: @state.padding

      handleResize: (width, height) ->
         @setState
            chart_width: width
            chart_height: height
         _.defer (=> @setScales())

      handleDrawGrid: (grid_width, grid_height, padding) ->
         @setState
            grid_width: grid_width
            grid_height: grid_height
            padding: padding
         _.defer (=> @setScales())

      render: ->
         chart_width =  @state.chart_width
         chart_height = @state.chart_height

         div
            className: @props.classNames
            style:
               width: "100%"
               height: chart_height
            onMouseOut: @props.onMouseOut

            if _.isEmpty(@props.data)
               EmptyChart(height: chart_height)
            else
               ChartResizer height: chart_height, onResize: @handleResize,
                  Chart className: "et-chart--svg", width: chart_width, height: chart_height,
                     Grid
                        width: chart_width
                        height: chart_height
                        padding: @state.padding
                        xTicks: @props.xTicks
                        yTicks: @props.yTicks
                        getCoords: @props.onGetCoords
                        onDraw: @handleDrawGrid
                        xFormat: @props.xFormat
                        yFormat: @props.yFormat
                        showXGridLines: @props.showXGridLines
                        showYGridLines: @props.showYGridLines

                        @props.children
