module.exports = do ->
   {createComponent} = require("@evertrue/et-flux")
   React = require("react")
   {div} = ReactLibs.DOMFactories
   {object, number} = ReactLibs.PropTypes
   classNames = require("classnames")
   EngagementHelper = require("apps/events/utils/engagement-config")

   EVENT_PLACEHOLER = "/images/event-placeholder.png"

   createComponent "EventIcon",
      propTypes:
         event: object
         size: number

      getDefaultProps: ->
         size: 24

      render: ->
         {event, className, size} = @props

         src = event?.cover_photo_url || event?.picture_url || EVENT_PLACEHOLER
         network_src = EngagementHelper.getNetworkLogoByType(event?.source)

         div
            className: classNames("event-icon", className)
            style: {
               width: size
               height: size
               backgroundImage: "url(#{src})"
            }
            if network_src
               div
                  className: "event-icon--network-icon"
                  style: {
                     width: Math.min(size * 0.4, 20)
                     height: Math.min(size * 0.4, 20)
                     backgroundImage: "url(#{network_src})"
                  }



