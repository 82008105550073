import { useEffect } from "react";
import { usePrevious, useSafeSetState } from "@evertrue/et-components";
import _ from "underscore";
import Api from "entities/helpers/api";

const SolicitorAssignmentsResolver = ({ contactIds = [], render }) => {
  const prevIds = usePrevious(contactIds);
  const [data, setData] = useSafeSetState({});
  useEffect(() => {
    const newIds = _.difference(contactIds, prevIds);
    if (newIds.length) {
      Api.VOLUNTEER.BULK_MEMBERSHIPS.post({
        urlArgs: { type: "solicitor" },
        data: JSON.stringify(contactIds),
        params: { pool_type: "POOL" },
      }).then(setData);
    }
  }, [contactIds, prevIds, setData]);
  const poolIds = Object.values(data).flatMap((i) => i.map((membership) => membership.pool_id));
  return render({ poolIds: poolIds, memberships: data });
};

export default SolicitorAssignmentsResolver;
