module.exports = do ->
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {input, div} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   Icon = createFactory (require("components/elements/icon").default)
   Button = createFactory(require("@evertrue/et-components").Button)


   createComponent "CopyInput",
      propTypes:
         url: ReactLibs.PropTypes.string

      getInitialState: ->
         just_copied: false

      handleCopy: (e) ->
         @copy_input.select()
         document.execCommand("copy")
         @setState {just_copied: true}

      render: ->
         div className: "copy-input",
            input
               ref: ((e) => @copy_input = e)
               className: "copy-input--share-input"
               value: @props.url || "Generating..."
               readOnly: true
               onClick: (e) -> e.target.select()

            Button className: "copy-input--copy", onClick: @handleCopy,
               if @state.just_copied
                  Icon icon: "check", size: 1
               else "Copy"
