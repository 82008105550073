import FormField from "components/forms/form-field";
import PropTypes from "prop-types";
import { NumericInput } from "@evertrue/et-components";

const AmountsInput = (props) => {
  return (
    <FormField className={props.formClassName} label={props.label} inlineLabel={false}>
      <NumericInput
        className={props.inputClassName}
        placeholder="Total Dollars"
        value={props.amount}
        step="0.01"
        maskType="currency"
        onChange={props.onChange}
        integerLimit={props.integerLimit}
        showIcon={true}
        size={1}
      />
    </FormField>
  );
};

AmountsInput.propTypes = {
  label: PropTypes.string,
  formClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  amount: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
  onChange: PropTypes.func.isRequired,
  integerLimit: PropTypes.number,
};

AmountsInput.defaultProps = {
  integerLimit: 8,
};

export default AmountsInput;
