import PropTypes from "prop-types";
import EverTrue from "app";
import Nav from "components/lists/nav";
import NavItem from "components/lists/nav-item";
import str from "underscore.string";

const DashboardNav = ({ tab }) => {
  return (
    <Nav
      active={tab}
      className="portfolio-performance--nav"
      onSelect={(selected_tab) => {
        EverTrue.track.set("launchpad_action", {
          type: "launchpad_tab_change",
          tab: selected_tab === "user" ? "Individual" : str.capitalize(selected_tab),
        });
        // We are using this onSelect on <Nav> instead of href on <NavItem>
        // because the href were causing this controller to mount every time the NavItem was clicked
        // and ideally we want the url to update but not to remount this controller
        EverTrue.UrlManager.navigate(`/dashboard/${selected_tab}`, true, false);
      }}
    >
      <NavItem activeWhen="user" label="Gift Officer" />
      <NavItem activeWhen="team" label="Team" />
    </Nav>
  );
};

DashboardNav.propTypes = {
  tab: PropTypes.string,
};

export default DashboardNav;
