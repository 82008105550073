import { PageHeader, Icon } from "@evertrue/et-components";
import ClarityDashboard from "apps/clarity/components/clarity-dashboard";
import { ClarityProvider } from "./clarity-context";
import EverTrue from "app";

const ClarityController = () => (
  <ClarityProvider>
    <div className="title-container">
      <Icon
        icon="chevron-left"
        size={2}
        className="settings-back"
        onClick={() => {
          EverTrue.UrlManager.navigate("/home", true, false);
        }}
      />
      <PageHeader title={<div className="Clarity-title">Clarity</div>} />
    </div>
    <ClarityDashboard />
  </ClarityProvider>
);

export default ClarityController;
