import { PageHeader, Icon } from "@evertrue/et-components";
import ReportingDashboard from "../components/reporting-dashboard";
import ReportingNav from "../components/reporting-nav";
import EverTrue from "app";
import PropTypes from "prop-types";

const ReportingController = ({ tab }) => {
  return (
    <div>
      <div className="title-container">
        <Icon
          icon="chevron-left"
          size={2}
          className="settings-back"
          onClick={() => {
            EverTrue.UrlManager.navigate("/home", true, false);
          }}
        />
        <PageHeader title="Reporting" />
      </div>
      <ReportingNav tab={"activity"} />
      <ReportingDashboard tab={tab} />
    </div>
  );
};

ReportingController.propTypes = {
  tab: PropTypes.string,
};

export default ReportingController;
