import get from "lodash.get";
import { Fragment, Component } from "react";
import { connect } from "@evertrue/et-flux";
import PropTypes from "prop-types";
import ProfileCardSubtitle from "apps/profile/components/profile-card-subtitle";
import Loading from "components/elements/loading";
import Icon from "components/elements/icon";
import numeral from "numeral";
import moment from "moment";
import DNAStore from "base/dna/dna-store";

const mapStateToProps = () => ({
  fiscal_start: DNAStore.getFiscalYearStart(),
});

class AnnualGiving extends Component {
  static propTypes = {
    contact: PropTypes.object,
    onlineGiving: PropTypes.object,
    fiscal_start: PropTypes.string,
  };

  static defaultProps = {
    contact: {},
    onlineGiving: {},
    fiscal_start: "",
  };

  renderScoreAmount(score) {
    if (score) {
      return (
        <div className="annual-giving--score-label">
          <span className="annual-giving--numerator">{score}</span>
          <span className="annual-giving--divider">/</span>
          <span className="annual-giving--denominator">100</span>
        </div>
      );
    }
    return (
      <div className="annual-giving--score-label">
        <span className="annual-giving--denominator">N/A</span>
      </div>
    );
  }

  renderScore() {
    const score = get(this.props, "contact.score.annual_fund.value", 0);
    return (
      <div className="annual-giving--section fs-exclude-data">
        <div className="annual-giving--score-outer">
          <div className="annual-giving--score-inner" style={{ width: `${score}%` }} />
        </div>
        {this.renderScoreAmount(score)}
      </div>
    );
  }

  renderHasGivenAmount(date, amount) {
    const { giving_pattern = {} } = this.props.contact.giving;
    if (giving_pattern.value === "gave this year") {
      const displayDate = date ? moment(date, "YYYY-MM-DD").format("MM/DD/YYYY") : "Unknown Date";
      const displayAmount = amount ? numeral(amount).format("$0,0[.]00") : "N/A";

      return (
        <Fragment>
          <div className="annual-giving--gave" key="gave">
            <Icon icon="check" className="annual-giving--checkmark" />
            <span className="annual-giving--value">Yes</span>
          </div>
          <ProfileCardSubtitle label="Last Gift" />
          <div className="annual-giving--gift-info">
            <span className="annual-giving--numerator">{displayAmount}</span>
            <span className="annual-giving--divider" />
            <span className="annual-giving--denominator">{displayDate}</span>
          </div>
        </Fragment>
      );
    }
    return (
      <div className="annual-giving--gave">
        <Icon icon="clear" className="annual-giving--checkmark is-negative" />
        <span className="annual-giving--value">No</span>
      </div>
    );
  }

  renderHasGiven(date, amount) {
    return (
      <div className="annual-giving--section">
        <ProfileCardSubtitle label="Gave this FY?" />
        {this.renderHasGivenAmount(date, amount)}
      </div>
    );
  }

  render() {
    let amount;
    let date;
    let last_gift_amount;
    let last_gift_date;

    const { contact, onlineGiving, fiscal_start } = this.props;

    if (contact.giving) {
      const contact_date = get(contact, "giving.last_gift_date.value");

      const give_date = get(onlineGiving, "date");

      const mContact = contact_date ? moment(contact_date, "MM/DD/YYYY") : undefined;
      const mGive = give_date ? moment(give_date, "x") : undefined;

      // check give and contact giving for latest last_gift_date
      if (give_date && (!contact_date || mGive.isAfter(mContact.endOf("day")))) {
        last_gift_date = mGive.format("YYYY-MM-DD");
        last_gift_amount = onlineGiving.amount * 0.01;
      } else if (contact_date) {
        last_gift_date = mContact.format("YYYY-MM-DD");
        last_gift_amount = get(contact, "giving.last_gift_amount.value");
      }

      // check last_gift_date against fiscal year
      if (last_gift_date) {
        const mdate = moment(last_gift_date);
        if (last_gift_date && !mdate.isBefore(fiscal_start)) {
          date = last_gift_date;
          amount = last_gift_amount;
        }
      }

      return (
        <div className="annual-giving">
          <ProfileCardSubtitle label="Annual Giving" help_key="annual_giving_score" position="top" />
          {onlineGiving.loading ? (
            <Loading />
          ) : (
            <div className="annual-giving--info">
              {this.renderScore()}
              {this.renderHasGiven(date, amount)}
            </div>
          )}
        </div>
      );
    }
    return null;
  }
}

export default connect(
  AnnualGiving,
  [DNAStore],
  mapStateToProps
);
