_ = require("underscore").default
{createComponent} = require("@evertrue/et-flux")
React = require("react")
{div} = ReactLibs.DOMFactories
{object, func, array} = ReactLibs.PropTypes
{createFactory} = require("base/new-utils")
AdvancedCombobox = createFactory(require("@evertrue/et-components").AdvancedCombobox)

getOptions = (category) ->
   label = category || "Categorized"
   [
      label: "#{label} Donor Status..."
      items: [
         value: "donor"
         label: "Donor"
      ,
         value: "non_donor"
         label: "Non-Donor"
      ]
   ,
      label: "Advanced #{label} Giving Filters"
      items: [
         value: "must"
         label: "Constituents who gave..."
      ,
         value: "must_not"
         label: "Constituents who did NOT give..."
      ]
   ]

module.exports =
   createComponent "CategorizedGivingParamSelector",
      propTypes:
         categories: array
         input: object
         state: object
         onChange: func

      handleCategoryChange: (category) ->
         new_value =
            if _.isEmpty(@props.input.value) then { category, param: "donor" }
            else _.extend({}, @props.input.value, { category })
         @props.onChange(null, new_value)


      handleParamChange: (param) ->
         if category = @props.input.value?.category
            new_value =
               if param in ["must", "must_not"]
                  _.extend({}, @props.input.value, { param })
               else
                  { param, category }

            @props.onChange(null, new_value)

      render: ->
         categories = _.map @props.categories, ({value}) -> {value, label: value}

         category = @props.input.value?.category
         param = @props.input.value?.param

         options = getOptions(category)
         flat_options = _.flatten(_.pluck(options, "items"))

         param_value = _.findWhere(flat_options, value: param)

         div className: "segment-filter--compound-value",
            AdvancedCombobox
               className:  "segment-filter-row--col segment-filter-row--operator-value",
               placeholder: "Select Category..."
               options: categories
               value: {label: category, value: category}
               onChange:({value}) => @handleCategoryChange(value)

            AdvancedCombobox
               className: "segment-filter-row--sub-value"
               grouped: true
               disabled: !category
               placeholder: "Select Donor Status..."
               options: options
               value: param_value
               onChange: ({value}) => @handleParamChange(value)
