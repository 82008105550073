import EverTrue from "app";
import InteractionMeta from "apps/interactions/components/interaction-meta";
import Interaction from "apps/interactions/components/interaction";
import { LinkButton } from "@evertrue/et-components";
import { PropTypes } from "base/react-libs";

const InteractionListItem = (props) => {
  const { interaction: { interaction_type = "", summary = "", id, pinned } = {}, showFullText, interaction } = props;

  return (
    <LinkButton
      title={
        interaction_type === "EverTrue Comment"
          ? "Go to EverTrue Comment"
          : summary
          ? `Go to interaction: ${summary}`
          : "Go to interaction"
      }
      className={pinned ? "interaction-list-item interaction-list-item--pinned" : "interaction-list-item"}
      onClick={() => {
        if (!showFullText) {
          const type = interaction_type === "EverTrue Comment" ? "comment" : "interaction";
          EverTrue.track.set("interaction_action", { type, action: "view" });
          EverTrue.Navigator(`/interaction/${id}`, { trigger: true });
        }
      }}
    >
      <Interaction props={props} />
      <InteractionMeta interaction={interaction} />
    </LinkButton>
  );
};

InteractionListItem.propTypes = {
  interaction: PropTypes.object.isRequired,
  showFullText: PropTypes.bool,
};

export default InteractionListItem;
