module.exports = do ->
   _ = require("underscore").default
   PropertyHelper = require("entities/helpers/property-helper")
   PropertySource = require("apps/contact/sources/property-source")
   OrgSource = require("base/org/org-source").default
   Decorator = require("clientDecorator")
   str = require("underscore.string")
   {createStore} = require("@evertrue/et-flux")

   _group_display =
      "educations": "Education"
      "employments": "Work History"
      "giving": "Giving Data"
      "linkedin": "LinkedIn Data"

   _formatDisplay = (key) ->
      _group_display[key] || str.humanize(key)

   _getCustomFields = (schema) ->
      data =
         app_key: EverTrue.config.app_keys.givingtree
         roles: _.pluck(EverTrue.store.user?.getRoles(), "id")
         oid: EverTrue.store.org?.get("id")
         super_user: EverTrue.store.user?.isSuperUser()
      Decorator.Schema.getCustomFields(schema, data)

   createStore "PropertyStore",
      getInitialState: ->
         schema: {}

      registerActions: ->
         @on PropertySource.actions.fetchedSchema, (schema) ->
            @setState {schema: schema}

         @on OrgSource.actions.newOrg, -> PropertySource.fetch()

      api:
         getProperties: ->
            properties = PropertyHelper.toPropLookup(@getState("schema"))
            if _.isEmpty(properties) then PropertySource.fetch()
            properties

         getCurrentState: ->
            PropertyHelper.filterPermission(@getProperties())

         getCustomFields: ->
            flex = _getCustomFields(@getState("schema"))

            fields_as_object = {}
            _.each flex, (data) ->
               fields_as_object["custom:#{data.name}"] = data
            fields_as_object

         getGroupedFields: ->
            grouped_fields = PropertyHelper.toGroupArray(@getState("schema"), true)
            groups_with_label = {}
            _.each grouped_fields, (group) ->
               avail_props = PropertyHelper.filterPermission(group.properties)
               if !_.isEmpty(avail_props)
                  groups_with_label[group.group_name] =
                     label: _formatDisplay(group.group_name)
                     properties: avail_props
            groups_with_label

         hasReadAccessTo: (prop) ->
            PropertyHelper.hasAccessTo(@getProperties(), prop, false)

         hasWriteAccessTo: (prop) ->
            PropertyHelper.hasAccessTo(@getProperties(), prop, true)

         hasSchema: ->
            !_.isEmpty(@getState("schema"))
