import { createSource } from "@evertrue/et-flux";
import Api from "entities/helpers/api";
import _ from "underscore";
import ErrorLogger from "entities/helpers/error-log-helper";

const StageSource = createSource("StageSource", {
  actions: {
    fetchedStageGroups: res_should_be_object,
    fetchStageGroupsUnlessCached: true,
    fetchGroupUnlessCached: true,
    fetchedSingleStageGroup: res_should_be_object,
    stageUpdated(assignment_id, res) {
      this.require(_.isNumber(assignment_id), "assignment_id should be number");
      res_should_be_object.call(this, res);
    },
    fetchStageGroupsError: true,
    fetchedStageGroupError: true,
  },
  api: {
    fetchStageGroupsUnlessCached(type) {
      this.actions.fetchStageGroupsUnlessCached(type);
    },

    fetchGroupUnlessCached(id) {
      this.actions.fetchGroupUnlessCached(id);
    },

    fetchStageGroups(type) {
      Api.VOLUNTEER.STAGES.get({
        params: { stage_group_type: type },
        success: res => {
          this.actions.fetchedStageGroups(_.indexBy(res, "id"));
        },
        error: xhr => {
          this.actions.fetchStageGroupsError(true);
          ErrorLogger.captureRequest("Fetch STAGES Error", xhr);
        },
      });
    },

    fetchStageGroupById(id) {
      Api.VOLUNTEER.STAGES.get({
        urlExtend: `/${id}`,
        success: res => {
          this.actions.fetchedSingleStageGroup(res);
        },
        error: xhr => {
          ErrorLogger.captureRequest("Fetch STAGES Error", xhr);
          this.actions.fetchedStageGroupError(id);
        },
      });
    },
  },
});

function res_should_be_object(res) {
  this.require(_.isObject(res), "res should be object");
}

export default StageSource;