import { useGate } from "components/is-gated";
import PropTypes from "prop-types";
import EverTrue from "app";

const FEATURES = Object.freeze({
  cadences: "Cadences",
  task_management: "Tasks",
  signal: "Signal",
});

const IsGatedController = ({ gate, children, navigatePath }) => {
  const [enabled, loaded] = useGate(gate);

  if (loaded && !enabled && navigatePath) {
    EverTrue.Navigator(navigatePath, true);
    return null;
  }
  if (loaded && !enabled)
    return (
      <h1
        style={{
          textAlign: "center",
          margin: "auto",
          marginTop: "35vh",
          color: "#737373",
          lineHeight: 2,
        }}
        className="empty-state-message--text"
      >
        Your Organization does not have&nbsp;{FEATURES[gate] || "this feature"}&nbsp;enabled.
        <br />
        Please contact your CSM to add&nbsp;{FEATURES[gate] || "this feature"}&nbsp;to your organization.
      </h1>
    );
  if (enabled) return <>{children}</>;
  return null;
};

IsGatedController.propTypes = {
  gate: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  navigatePath: PropTypes.string,
};

export default IsGatedController;
