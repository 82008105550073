module.exports = do ->
   React = require("react")
   classNames = require("classnames")
   {button} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   Icon = createFactory (require("components/elements/icon").default)
   Popover = require("components/overlays/popover")
   createClass = require("create-react-class")
   KeyPressManager = createFactory(require("components/helpers/KeyPressManager").default)
   {prevFocusWithWrap, nextFocusWithWrap, sendFocus} = require("base/accessibility-utils")
   Button = createFactory(require("@evertrue/et-components").Button)


   createClass
      displayName: "ActionMenu"
      propTypes:
         label: ReactLibs.PropTypes.any
         width: ReactLibs.PropTypes.number
         buttonClass: ReactLibs.PropTypes.string
         buttonType: ReactLibs.PropTypes.string
         contentClass: ReactLibs.PropTypes.string
         position: ReactLibs.PropTypes.string
         disabled: ReactLibs.PropTypes.bool

      getDefaultProps: ->
         label: "Actions..."
         buttonClass: ""
         position: "bottom-right-aligned"
         buttonType: "action"

      handleClose: (close) ->
         close()
         sendFocus(@refs.trigger)

      render: ->
         Popover
            ref: "menu"
            className: classNames(@props.className, "action-menu")
            position: @props.position
            disable: @props.disabled
            arrowSize: 0
            isReachable: false
            trigger:
               Button
                  ref: "trigger"
                  type: @props.buttonType
                  className: classNames(@props.buttonClass, "action-menu--button"),
                  disabled: @props.disabled,
                     @props.label
                     Icon icon: "chevron-down", size: 1, after: true, isReachable:false
            render: ({close}) =>
               KeyPressManager
                  onArrowUp: (node,event) -> prevFocusWithWrap(node)
                  onArrowDown: (node, event) -> nextFocusWithWrap(node)
                  onEsc: => @handleClose(close)
                  className: classNames(@props.contentClass, "action-menu--content")
                  style: {width: @props.width}
                  onClick: close
                  @props.children
