module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories
   Decorator = require("clientDecorator")
   Career = require("apps/profile/components/templates/career")
   Education = require("apps/profile/components/templates/education")
   AddressMixin = require("mixins/address-mixin")
   BioContactInfo = require("apps/profile/components/bio-contact-info")
   {createFactory} = require("base/new-utils")
   ProfileCardSubtitle = createFactory require("apps/profile/components/profile-card-subtitle").default


   createComponent "BioInfoAll",
      propTypes:
         contact: ReactLibs.PropTypes.object

      # Handle modal-on-modal situation with matching links
      # the outside click forces this modal to close when the other is opened
      componentDidMount: ->
         $(@refs.inside).bind "clickoutside", @handleOutsideClick

      componentWillUnmount: ->
         $(@refs.inside).unbind "clickoutside", @handleOutsideClick

      handleInsideClick: (evnt) ->
         evnt.stopPropagation()

      handleOutsideClick: ->
         @props.requestHide?()

      render: ->
         positions = Decorator.Work.getAllPositions(@props.contact)
         addresses = AddressMixin.getAddresses(@props.contact)
         educations = Decorator.Profile.getParsedEducation(@props.contact)
         contact_info = Decorator.Profile.getContactInfo(@props.contact)

         positions = [{}] if _.isEmpty(positions)
         addresses = [{}] if _.isEmpty(addresses)
         educations = [{}] if _.isEmpty(educations)
         contact_info?.phones = [{}] if _.isEmpty(contact_info?.phones)
         contact_info?.emails = [{}] if _.isEmpty(contact_info?.emails)


         div className: "bio-info",
            div ref: "inside", onClick: @handleInsideClick,
               div className: "bio-info--section",
                  ProfileCardSubtitle label: "Career"
                  _.map positions, (work, i) ->
                     Career data: work, key: i

               div className: "bio-info--section",
                  ProfileCardSubtitle label: "Education"
                  _.map educations, (school, i) ->
                     Education data: school, key: i

               BioContactInfo contact: @props.contact
