module.exports = do ->
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   classNames = require("classnames")
   {div, img} = ReactLibs.DOMFactories
   default_avatar = require("images/et_avatar_user_2.png")
   {createFactory} = require("base/new-utils")
   Icon = createFactory(require("@evertrue/et-components").Icon)

   createComponent "Avatar",
      propTypes:
         url: ReactLibs.PropTypes.string
         name: ReactLibs.PropTypes.string
         width: ReactLibs.PropTypes.number
         onClick: ReactLibs.PropTypes.func
         hasDelete: ReactLibs.PropTypes.bool

      getDefaultProps: ->
         hasDelete: false

      render: ->
         div null,
            img
               className: classNames("et-avatar", @props.className)
               style: @props.style
               src: @props.url || default_avatar
               alt: @props.name
               width: @props.width
               onClick: @props.onClick
               onError: (e) ->
                  unless e.target.src == default_avatar
                     e.target.onerror = null
                     e.target.src = default_avatar
            if @props.hasDelete
               Icon icon: "cancel-circle", className: "et-avatar-delete"
