module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   classNames = require("classnames")
   moment = require("moment")
   {div, i} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   CalendarDatePicker = createFactory(require("components/controls/calendar-date-picker"))
   Popover = require("components/overlays/popover")
   Button = createFactory(require("@evertrue/et-components").Button)
   FocusLock = createFactory(require("react-focus-lock").default)
   moment = require("moment")


   _format = (date) -> moment(date).format("MMM DD, YYYY")

   renderTitle = (from_date, to_date) ->
      if from_date? and to_date?
         _format(from_date) + " - " + _format(to_date)
      else if from_date? and !to_date?
         "After " + _format(from_date)
      else if to_date? and !from_date?
         "Before " + _format(to_date)
      else
         "Any Date"

   # Expects dates in the format YYYY-DD-MM
   ReactLibs.createClass
      displayName: "CalendarDropdown",
      propTypes:
         date_from: ReactLibs.PropTypes.string
         date_to: ReactLibs.PropTypes.string
         date_range: ReactLibs.PropTypes.array
         position: ReactLibs.PropTypes.string
         onChange: ReactLibs.PropTypes.func # args: (date_from, date_to)
         title: ReactLibs.PropTypes.any
         disabled: ReactLibs.PropTypes.bool
         
      getInitialState: ->
         date_from: @props.date_from
         date_to: @props.date_to
         initial_from: @props.date_from
         initial_to: @props.date_to
         
      componentDidMount: ->
         @depracated_is_mounted = true

      UNSAFE_componentWillReceiveProps: (newProps) ->
         if (!_.isEqual(@props.date_from, newProps.date_from) || !_.isEqual(@props.date_to, newProps.date_to))
            @setState
               date_from: newProps.date_from
               date_to: newProps.date_to
               initial_from: newProps.date_from
               initial_to: newProps.date_to

      componentWillUnmount: ->
         @depracated_is_mounted = true
         clearTimeout(@timeout)

      handleChange: ->
         clearTimeout(@timeout)
         @timeout = _.delay =>
            if @depracated_is_mounted
               @props.onChange(@state.date_from, @state.date_to)
         , 100

      handleApplyDates: ->
         @handleChange()
         @handleClose()

      handleCancel: ->
         @setState
            date_from: @state.initial_from
            date_to: @state.initial_to
         @handleClose()

      handleClose: ->
         _.defer =>
            @refs?.popover?.handleClose() if @depracated_is_mounted
      
      handleDateChange: (date_from, date_to, key) ->
         @setState {date_from: date_from, date_to: date_to}

      render: ->
         Popover
            ref: "popover"
            disable: @props.disabled
            className: classNames(@props.className)
            position: @props.position
            trigger: div className: "date-picker--header",
               @props.title || renderTitle(@state.initial_from, @state.initial_to)
               i className: "fa fa-fw fa-angle-down"
            onClose: @handleChange

            div className: classNames("date-picker--wrap", if @props.className then @props.className + "--body"),
               FocusLock null,
                  CalendarDatePicker
                     date_from: @state.date_from
                     date_to: @state.date_to
                     date_range: @props.date_range
                     onChange: @handleDateChange

                  div className: "date-picker--actions clearfix",
                     Button
                        className: "date-picker--action-btn",
                        disabled: !(new Date(moment(@state.date_to).format('L')).getTime() >= new Date(moment(@state.date_from).format('L')).getTime()) || !@state.date_from,
                        onClick: @handleApplyDates,
                        "Apply"
                     Button
                        type: "simple"
                        className: "date-picker--action-btn",
                        onClick: @handleCancel,
                        "Cancel"

                     div className: "data-picker--extra-actions",
                        @props.children
