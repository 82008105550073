module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   React = require("react")
   classNames = require("classnames")
   {div} = ReactLibs.DOMFactories
   {createComponent} = require("@evertrue/et-flux")

   createComponent "HorizontalScrollbar",
      propTypes:
         size: ReactLibs.PropTypes.any
         offset: ReactLibs.PropTypes.any
         stickyWidth: ReactLibs.PropTypes.number
         onScrollDrag: ReactLibs.PropTypes.func
         onScrollDragEnd: ReactLibs.PropTypes.func

      getInitialState: ->
         dragging: false
         offset: 0
         max_offset: 0

      componentDidMount: ->
         $(window).on "resize", @getWidths
         @is_mounted = true
         @getWidths()
         @setState
            offset: @toValidOffset(@props.offset)

      componentWillUnmount: ->
         $(window).off "resize", @getWidths
         @unbindMouseEvents()
         @is_mounted = false

      UNSAFE_componentWillReceiveProps: (newProps) ->
         if @props.offset != newProps.offset
            if @depracated_is_mounted && !@state.dragging
               @setState {offset: @toValidOffset(newProps.offset)}

      bindMouseEvents: ->
         $(document).on "mouseup", @handleMouseUp
         $(document).on "mousemove", @handleMouseMove

      unbindMouseEvents: ->
         $(document).off "mouseup", @handleMouseUp
         $(document).off "mousemove", @handleMouseMove

      handleMouseDown: (e) ->
         @bindMouseEvents()
         @setState {dragging: true, initialPos: e.pageX}

      handleMouseUp: ->
         @unbindMouseEvents()
         @setState {dragging: false}
         @props.onScrollDragEnd?(@state.offset)

      handleMouseMove: (e) ->
         if @state.dragging
            e.stopPropagation()
            e.preventDefault()
            change = _.toNumber(@props.offset) - (@state.initialPos - e.pageX)
            valid_offset = @toValidOffset(change)
            @setState {offset: valid_offset}
            @props.onScrollDrag?(valid_offset)

      toValidOffset: (offset) ->
         _.withinRange(offset, 0, @state.max_offset)

      getWidths: ->
         if @is_mounted
            handleWidth = $(@refs.handle).width()
            scrollbarWidth = $(@refs.scrollbar).width()
            max_offset = scrollbarWidth - handleWidth
            @setState max_offset: max_offset
            @props.onSetWidths?(max_offset, scrollbarWidth)

      render: ->
         style = if @props.stickyWidth then {left: @props.stickyWidth} else {}
         div className: @props.className, style: style,
            div className: "scrollbar", ref: "scrollbar",
               div
                  className: classNames("scrollbar--handle", {"is-dragging": @state.dragging})
                  onMouseDown: @handleMouseDown
                  ref: "handle"
                  style:
                     width: @props.size + "%"
                     left: @state.offset
