module.exports = do ->
   _ = require("underscore").default
   Decorator = require("clientDecorator")
   {createStore} = require("@evertrue/et-flux")
   VolunteersSource = require("apps/volunteers/sources/volunteers-source")

   createStore "VolunteersStore",
      getInitialState: ->
         volunteers_search: {}
         loading: false

      registerActions: ->
         @on VolunteersSource.actions.loading, (is_loading) ->
            @setState {loading: is_loading}

         @on VolunteersSource.actions.fetchedVolunteers, (results) ->
            contacts = _.cloneData(results)
            contacts.items = _.map results.items, (item) ->
               Decorator.Contacts.parse(item)
            @setState {volunteers_search: contacts}

      api:
         getLoading: ->
            @getState("loading")

         getVolunteerOptions: ->
            _.map @getState("volunteers_search").items, (contact) ->
               value: contact.id, label: Decorator.Contacts.getFullName(contact)
