import { useEffect, useRef } from "react";
import PropTypes from "prop-types";

const ReadMore = ({ height, className, children }) => {
  const contentRef = useRef(null);

  useEffect(() => {
    const content = contentRef.current;
    if (!content) return;

    content.style.maxHeight = `${height}px`;
    content.style.overflow = "hidden";

    const moreLink = document.createElement("a");
    moreLink.href = "#";
    moreLink.textContent = "Show more";
    moreLink.addEventListener("click", (e) => {
      e.preventDefault();
      content.style.maxHeight = null;
      moreLink.style.display = "none";
      if (lessLink) lessLink.style.display = "inline";
    });

    const lessLink = document.createElement("a");
    lessLink.href = "#";
    lessLink.textContent = "Show less";
    lessLink.style.display = "none";
    lessLink.addEventListener("click", (e) => {
      e.preventDefault();
      content.style.maxHeight = `${height}px`;
      lessLink.style.display = "none"; 
      moreLink.style.display = "inline"; 
    });

    content.parentNode.insertBefore(moreLink, content.nextSibling);
    content.parentNode.insertBefore(lessLink, moreLink.nextSibling);

    return () => {
      moreLink.removeEventListener("click", moreLink.clickHandler);
      lessLink.removeEventListener("click", lessLink.clickHandler);
      if (moreLink.parentNode) moreLink.parentNode.removeChild(moreLink);
      if (lessLink.parentNode) lessLink.parentNode.removeChild(lessLink);
    };
  });

  return (
    <div ref={contentRef} className={className || ""}>
      {children}
    </div>
  );
};

ReadMore.propTypes = {
  height: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default ReadMore;
