import { useState, useContext, useEffect } from "react";
import _ from "underscore";
import PropTypes from "prop-types";
import EverTrue from "app";
import { SearchInput, Loading, useLocalStorage, useFocus } from "@evertrue/et-components";
import { FiltersDataContext } from "apps/query-builder/contexts/filters-data-context";
import FilterGroupOptions from "apps/query-builder/components/filter-picker/filter-group-options";
import FilterCategoryList from "apps/query-builder/components/filter-picker/filter-category-list";
import FilterSearchOptions from "apps/query-builder/components/filter-picker/filter-search-options";
import RecentFilters from "apps/query-builder/components/filter-picker/recent-filters";

const FilterPicker = ({ selectedFilter, onSelection }) => {
  const { searchTerm, setSearchTerm, categorizedFilters, loading } = useContext(FiltersDataContext);
  const [recentFilters, setRecentFilters] = useLocalStorage("recent_filters", []);
  const [searchRef, setSearchFocus] = useFocus();

  // set default category if there is a selected filter AND they haven't searched
  const [selectedCategory, setSelectedCategory] = useState(() =>
    !_.isEmpty(selectedFilter) ? selectedFilter.category : null
  );

  const [filterOptions, setFilterOptions] = useState(() =>
    selectedCategory ? categorizedFilters[selectedCategory] : {}
  );

  useEffect(() => {
    if (!selectedCategory) setSearchFocus();
  }, [selectedCategory, setSearchFocus]);

  useEffect(() => {
    return () => {
      setSearchTerm("");
    };
  }, [setSearchTerm]);

  const handleCategorySelect = (category, filters) => {
    EverTrue.track.set("filter_v2_action", {
      type: "select_categories",
      selected_category: category,
    });
    setSelectedCategory(category);
    setFilterOptions(filters);
  };

  const handleBack = () => {
    setSelectedCategory(null);
    setFilterOptions([]);
  };

  const handleSelection = (filter) => {
    const { compound_id, icon, group, label } = filter;

    setRecentFilters((prev) => {
      if (prev.find((recent) => recent.compound_id === compound_id)) {
        return prev;
      }
      return [{ compound_id, icon, group, label }, ...prev].slice(0, 5);
    });

    onSelection(filter);
  };

  const renderContent = () => {
    if (searchTerm)
      return <FilterSearchOptions searchResults={categorizedFilters} onSelection={handleSelection} loading={loading} />;

    if (selectedCategory)
      return (
        <FilterGroupOptions
          filterOptions={filterOptions}
          onSelection={handleSelection}
          selectedCategory={selectedCategory}
          selectedFilter={selectedFilter}
          onBack={handleBack}
        />
      );

    return (
      <>
        <FilterCategoryList categories={categorizedFilters} handleCategorySelect={handleCategorySelect} />
        <RecentFilters recentFilters={recentFilters} onSelection={onSelection} />
      </>
    );
  };

  return (
    <div className="filter-picker--content">
      <div className="filter-picker--steps">
        {!selectedCategory && (
          <div className="filter-picker--search">
            <SearchInput
              ref={searchRef}
              placeholder="Search filters"
              value={searchTerm}
              onChange={setSearchTerm}
              debounceTimeout={500}
            />
          </div>
        )}
        {loading ? <Loading size="small" /> : renderContent()}
      </div>
    </div>
  );
};

FilterPicker.propTypes = {
  selectedFilter: PropTypes.object,
  onSelection: PropTypes.func,
};

export default FilterPicker;
