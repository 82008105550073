import { PropTypes } from "base/react-libs";

const DialerInputField = ({
  phoneNumber,
  extension,
  phoneRef,
  extensionRef,
  onPhoneFocus,
  onExtFocus,
  handleInput,
}) => (
  <div className="dialer--input">
    <input
      type="tel"
      ref={phoneRef}
      onFocus={onPhoneFocus}
      placeholder="(777) 777-7777"
      className="dialer--input-box phone-box"
      value={phoneNumber}
      onChange={(e) => handleInput(e.target.value)}
    />
    <input
      ref={extensionRef}
      onFocus={onExtFocus}
      placeholder="ext."
      className="dialer--input-box extension-box"
      value={extension}
      onChange={(e) => handleInput(e.target.value)}
    />
  </div>
);

DialerInputField.propTypes = {
  phoneNumber: PropTypes.string,
  extension: PropTypes.string,
  phoneRef: PropTypes.object,
  extensionRef: PropTypes.object,
  onPhoneFocus: PropTypes.func,
  onExtFocus: PropTypes.func,
  handleInput: PropTypes.func,
};

export default DialerInputField;
