import _ from "underscore";
import { Component } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import EverTrue from "app";
import Decorator from "clientDecorator";
import InteractionAddBtn from "apps/interactions/components/create/interaction-add-btn";
import ContactCard from "apps/contact/components/contact-card/contact-card";
import Datalist from "components/elements/datalist";
import MeetingForm from "apps/my-trips/components/meeting-form";
import TimeBlockForm from "apps/my-trips/components/timeblock-form";
import MeetingsSource from "apps/my-trips/sources/meetings-source";
import CalendarContainer from "apps/my-trips/components/cronofy-calendar-container";
import { ModalTrigger2, Link, Icon, ModalConfirm, Button } from "@evertrue/et-components";

class MeetingDetailsModal extends Component {
  static propTypes = {
    trip: PropTypes.object,
    meeting: PropTypes.object,
    contacts: PropTypes.any,
    solicitors: PropTypes.any,
    remove: PropTypes.func, // passed from ModalTrigger
  };

  static defaultProps = {
    trip: {},
    meeting: {},
    remove: _.noop,
  };

  componentDidMount() {
    EverTrue.track.set("viewed", { type: "meeting_details" });
  }

  handleClose = () => {
    this.props.remove();
  };

  handleDelete = () => {
    this.handleClose();

    EverTrue.Alert.confirm(
      {
        headline: "Delete Meeting?",
        content: (
          <span>
            {`Are you sure you want to delete ${this.props.meeting.name || ""}? \
                Deleting a meeting removes it from your trip and cannot be undone.`}
            <CalendarContainer
              render={({ hasCalendars }) => {
                if (hasCalendars) {
                  return " Deleting this meeting will also remove it from your connected calendar(s).";
                } else {
                  return null;
                }
              }}
            />
          </span>
        ),
      },
      (e) => {
        if (e) {
          MeetingsSource.delete(this.props.meeting.id);
        }
      }
    );
  };

  handleAddInteraction = () => {
    _.defer(() => {
      this.handleClose();
    });
  };

  renderAddress = () => {
    const meeting_location = this.props.meeting.location || {};
    // If there's no place the address returns as the name object and is duplicated in the display
    const location = meeting_location.name;
    const address = meeting_location.formatted_address;
    const has_location = location && !address.match(location);

    return (
      <div>
        {has_location && <div>{location}</div>}
        <div>{address}</div>
      </div>
    );
  };

  render() {
    let maps_link;
    const { meeting, remove, trip } = this.props;
    const address_string = meeting.location ? meeting.location.formatted_address : undefined;
    if (address_string) {
      maps_link = `https://www.google.com/maps/place/${encodeURIComponent(address_string)}`;
    }
    const zone = meeting.start_datetime ? meeting.start_datetime.timezone_display : undefined;

    const contacts = meeting.contacts || this.props.contacts || null;
    const solicitors = meeting.solicitors || this.props.solicitors || null;

    return (
      <ModalConfirm className="meeting-details--wrapper" header={meeting.name} onCancel={remove} style={{ width: 550 }}>
        <div className="meeting-details">
          <div className="meeting-details--body">
            {/* Time */}
            <Datalist className="meeting-details--datalist" label="Time">
              <span>
                {moment(meeting.start_datetime ? meeting.start_datetime.date_string : undefined).format("ddd, MMMM D")}
                {`, ${(meeting.start_datetime ? meeting.start_datetime.time_string : undefined) || ""}`}
                {` - `}
                {meeting.end_datetime && `${meeting.end_datetime.time_string}`}
                {!!zone ? ` ${zone}` : undefined}
              </span>
            </Datalist>

            {/* Custom Location */}
            {meeting.type !== "virtual" && meeting.type !== "phone" && meeting.custom_location && (
              <Datalist className="meeting-details--datalist" label="Custom Location">
                <span>{meeting.custom_location}</span>
              </Datalist>
            )}

            {/* Location */}
            {meeting.type !== "virtual" && meeting.type !== "phone" && (
              <Datalist className="meeting-details--datalist" label="Location">
                {_.isEmpty(meeting.location) ? (
                  <span className="text-muted">No Location Added</span>
                ) : maps_link ? (
                  <Link title="Meeting address" href={maps_link} target="_blank">
                    {this.renderAddress()}
                  </Link>
                ) : (
                  this.renderAddress()
                )}
              </Datalist>
            )}

            {/* URL */}
            {meeting.type === "virtual" && meeting.meeting_link && (
              <Datalist className="meeting-details--datalist" label="Meeting Link">
                {_.isEmpty(meeting.meeting_link) ? (
                  <span className="text-muted">No Meeting Link Added</span>
                ) : (
                  <Link title="Meeting link" href={meeting.meeting_link} target="_blank">
                    {meeting.meeting_link}
                  </Link>
                )}
              </Datalist>
            )}

            {/* Phone Number */}
            {meeting.type === "phone" && meeting.phone && (
              <Datalist className="meeting-details--datalist" label="Phone Number">
                {_.isEmpty(meeting.phone) ? (
                  <span className="text-muted">No Phone Number Added</span>
                ) : (
                  <span className="text-muted">{meeting.phone}</span>
                )}
              </Datalist>
            )}

            {/* Constituents */}
            {meeting.type.toLowerCase() !== "timeblock" && (
              <Datalist className="meeting-details--datalist" label="Constituents">
                {_.isEmpty(contacts) ? (
                  <span className="text-muted">No Constituents Added</span>
                ) : (
                  _.map(contacts, (cont) => {
                    const contact = Decorator.Contacts.parse(cont);
                    return (
                      <div className="meeting-details--contact" key={contact.id}>
                        <ContactCard contact={contact} />
                        <InteractionAddBtn
                          interaction={{
                            date_occurred:
                              meeting.start_datetime != null
                                ? moment(meeting.start_datetime.date_string).valueOf()
                                : undefined,
                            summary: meeting.name,
                            trip_id: trip.id,
                          }}
                          contact={contact}
                          showIcon={true}
                          onClick={() => this.handleAddInteraction()}
                        />
                      </div>
                    );
                  })
                )}
              </Datalist>
            )}

            {/* Solicitors */}
            {!_.isEmpty(solicitors) && (
              <Datalist className="meeting-details--datalist" label="Additional Solicitors">
                {_.pluck(solicitors, "name").join(", ")}
              </Datalist>
            )}

            {/* Description */}
            <Datalist className="meeting-details--datalist" label="Description">
              {!meeting.description ? (
                <span className="text-muted">No Description Added</span>
              ) : (
                <span className="meeting-details--description">{meeting.description}</span>
              )}
            </Datalist>

            {/* Zoom Details */}
            {meeting.meeting_link_type === "ZOOM" && (
              <Datalist className="meeting-details--datalist" label="Zoom Details">
                {!meeting.meeting_link_remote_info.event_details ? (
                  <span className="text-muted">No Zoom Details Added</span>
                ) : (
                  <span className="meeting-details--description">{meeting.meeting_link_remote_info.event_details}</span>
                )}
              </Datalist>
            )}

            {/* Anchor */}
            {meeting.anchor && (
              <Datalist className="meeting-details--datalist" label={<Icon icon="star" />}>
                Anchor Meeting
              </Datalist>
            )}
          </div>

          {trip.is_my_trip && (meeting.type === "timeblock" || meeting.type === "meeting") && (
            <div className="meeting-details--footer">
              <Button type="destroy" onClick={this.handleDelete}>
                Delete
              </Button>
              <ModalTrigger2
                closeOnOutsideClick
                trigger={({ open }) => {
                  return <Button onClick={open}>Edit</Button>;
                }}
              >
                {({ close }) => {
                  switch (meeting.type) {
                    case "timeblock":
                      return <TimeBlockForm timeblock={meeting} headerVerb="Edit" remove={close} />;
                    default:
                      return <MeetingForm meeting={meeting} headerVerb="Edit" remove={close} />;
                  }
                }}
              </ModalTrigger2>
            </div>
          )}
        </div>
      </ModalConfirm>
    );
  }
}

export default MeetingDetailsModal;
