module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   EverTrue = require("app")
   {div} = ReactLibs.DOMFactories
   {createComponent} = require("@evertrue/et-flux")
   FixedTableBody = require("components/tables/fixed-table-body")
   HorizontalScrollbar = require("components/controls/horizontal-scrollbar")

   createComponent "FixedTable",
      propTypes:
         data: ReactLibs.PropTypes.array
         config: ReactLibs.PropTypes.array
         height: ReactLibs.PropTypes.number
         columnWidth: ReactLibs.PropTypes.number
         emptyMessage: ReactLibs.PropTypes.node
         loading: ReactLibs.PropTypes.bool
         appendSpaceColumn: ReactLibs.PropTypes.bool
         onRowClick: ReactLibs.PropTypes.func
         footer: ReactLibs.PropTypes.any

      getInitialState: ->
         start: 0
         table_width: "100%"
         scroll_offset: 0

      getDefaultProps: ->
         data: []
         config: []
         columnWidth: 175
         appendSpaceColumn: true

      componentDidMount: ->
         $(window).on "resize", @setTableDimensions
         @setTableDimensions()

      componentWillUnmount: ->
         $(window).off "resize", @setTableDimensions

      UNSAFE_componentWillReceiveProps: (newProps) ->
         if !_.isArrayEqual(_.pluck(newProps.config, "key"), _.pluck(@props.config, "key"))
            @setState {start: 0, scroll_offset: 0}

      setTableDimensions: ->
         $wrapper = $(@refs.wrapper)
         if @depracated_is_mounted
            @setState {table_width: _.toNumber($wrapper.outerWidth())}

      getNumberOfVisibleColumns: ->
         scrollingWidth = @state.table_width - @getWidthOfStickyColumns()
         scrollingCols = _.filter @props.config, (col) -> !col.sticky

         columns = _.size _.filter _.rest(scrollingCols, @state.start), (col) =>
            col_width = if _.isNumber(col.width) then col.width else @props.columnWidth
            scrollingWidth = scrollingWidth - col_width
            scrollingWidth >= 0
         columns

      getNumberOfStickyColumns: ->
         _.size _.filter @props.config, (col) -> col.sticky

      getWidthOfStickyColumns: ->
         _.reduce @props.config, (memo, conf) =>
            if conf.sticky then memo + (conf.width || @props.columnWidth) else memo
         , 0

      getTotalWidth: ->
         _.reduce @props.config, (memo, conf) =>
            memo + (_.toNumber(conf.width) || @props.columnWidth)
         , 0

      getVisibleColumns: ->
         num_visible = @getNumberOfVisibleColumns()
         if _.size(@props.config) < num_visible
            return @props.config
         else
            sticky = _.filter @props.config, (col) -> col.sticky
            real_start_index = @state.start + sticky.length
            real_start_index = Math.min(real_start_index, _.size(@props.config) - num_visible)
            _.uniq _.flatten [sticky, @props.config[real_start_index..]]

      getFormattedResults: (visible_columns) ->
         _.map @props.data, (item) =>
            id: item.id || item.key
            key: "row-" + item.id
            cells: _.map visible_columns, (column) =>
               _.extend {className: item.className}, column,
                  key: "cell-" + column.key + item.id
                  element: column.row(item)
                  width: column.width || @props.columnWidth

      getStartMax: ->
         scrollingWidth = @state.table_width - @getWidthOfStickyColumns()
         reversed_config = _.clone(@props.config)?.reverse?()
         count = 0
         _.each reversed_config, (col) =>
            col_width = if _.isNumber(col.width) then _.toNumber(col.width) else _.toNumber(@props.columnWidth)
            scrollingWidth = scrollingWidth - col_width
            if scrollingWidth >= 0 then count += 1
         @props.config.length - @getNumberOfStickyColumns() - count

      handleScroll: (e, max_offset) ->
         if e.deltaX and !e.deltaY
            e.stopPropagation()
            return if @throttle

            delta_pixels = e.deltaX
            beginIndex = @state.start

            # Add/Subtract from start index if change
            if delta_pixels >= 1
               beginIndex = @state.start + 1
            else if delta_pixels <= -1
               beginIndex = @state.start - 1
            beginIndex = _.withinRange(beginIndex, 0, @getStartMax())

            # Calculate new scrollbar offset based on beginIndex value
            if beginIndex == @state.start
               offset = (_.toNumber(@state.scroll_offset) || 0) + delta_pixels
            else
               offset = max_offset * (beginIndex * (1 / @getStartMax()))
            offset = _.withinRange(offset, 0, max_offset)

            @throttle = true
            _.delay =>
               @throttle = false if @depracated_is_mounted
            , 150

            @setState
               scroll_offset: offset
               start: beginIndex

      render: ->
         stickyWidth = @getWidthOfStickyColumns()
         width = @getTotalWidth()
         average_column_width = _.average(_.without(_.pluck(@props.config, "width"), "auto"))
         visible_columns = @getVisibleColumns()

         # TODO: This logic needs to be changed if UI is updated and scrollbar width % changes
         viewport_width = @state.table_width - stickyWidth - 64
         scrollbar_percent = (((viewport_width / average_column_width) + 1) / _.size(@props.config)) * 100
         scrollbar_width = viewport_width * (scrollbar_percent / 100)
         max_offset = viewport_width - scrollbar_width

         if @props.appendSpaceColumn && !_.findWhere(visible_columns, {key: "spacer"})
            visible_columns.push
               label: ""
               key: "spacer"
               width: "auto"
               className: "contact-fixed-table--col-spacer"
               row: (data) -> ""

         div className: EverTrue.classSet("fixed-table", @props.className),
            div
               className: "fixed-table--wrapper"
               ref: "wrapper"
               style: {height: @props.height}
               onWheel: (e) =>
                  @handleScroll(e, max_offset)

               FixedTableBody
                  ref: "wrapper"
                  data: @getFormattedResults(visible_columns)
                  columns: visible_columns
                  loading: @props.loading
                  emptyMessage: @props.emptyMessage
                  columnWidth: @props.columnWidth
                  onRowClick: @props.onRowClick
                  footer: @props.footer

               # Fake Horiztonal Scrollbar
               if (viewport_width + stickyWidth) < width
                  HorizontalScrollbar
                     className: "fixed-table--scrollbar"
                     size: scrollbar_percent
                     offset: @state.scroll_offset
                     showArrows: true
                     stickyWidth: stickyWidth
                     onArrowChange: (change, max_offset) =>
                        start = _.withinRange(@state.start + change, 0, @getStartMax())
                        if !_.isNaN(start)
                           offset = max_offset * (start * (1 / @props.start_max))
                           @setState
                              start: start
                              scroll_offset: _.withinRange(offset, 0, max_offset)
                     onScrollDrag: (offset) =>
                        new_max_offset = viewport_width - scrollbar_width
                        start_max = @getStartMax()
                        col_percent = 1 / (start_max + @getNumberOfStickyColumns())
                        start = Math.floor((offset / new_max_offset) / col_percent)
                        start = _.withinRange(start, 0, start_max)
                        if start != @state.start
                           @setState {start: start}
                     onScrollDragEnd: (offset) =>
                        @setState {scroll_offset: offset}
