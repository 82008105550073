module.exports = do ->
   _ = require("underscore").default
   moment = require("moment")
   DNAStore = require("base/dna/dna-store").default
   colors = require("base/colors")

   OPACITY = .5
   DATE_FORMAT = "YYYY-MM-DD"
   CURRENT_YEAR = moment().format("YYYY")
   CURRENT_MONTH = moment().format("MM")

   COLORS = [
      colors.green300
      "#4573A7"
      "#71588F"
      "#4297AF"
      "#DB843D"
      "#93A9D0"
      "#8dd3c7"
      "#bebada"
      "#fb8072"
      "#80b1d3"
      "#fdb462"
      "#b3de69"
      "#fccde5"
      "#d9d9d9"
      "#bc80bd"
      "#ccebc5"
      "#ffed6f"
   ]

   MyTeamUtils =
      getFormattedReportData: (new_reports, old_report_data) ->
         cached_report_data = _.cloneData(old_report_data)
         _.each new_reports, (data, key) =>
            colors = @getSeriesColorMap(data)
            cached_report_data[key] = _.map _.sortBy(data, "x"), (item) ->
               _.extend {}, item,
                  style:
                     fill: colors[item.series]
                     opacity: OPACITY
                     strokeOpacity: OPACITY
                  lineStyle:
                     strokeOpacity: OPACITY
                  pointStyle:
                     stroke: colors[item.series]
                     strokeOpacity: OPACITY
         cached_report_data

      getFundraiserRollup: (list, prefix) ->
         object = {}
         _.each list, (report_data, key) ->
            _.each report_data, (datum) ->
               officer_key = datum.x
               value_key = datum.y
               series_key = datum.series

               if key in ["visits", "my-trips"]
                  officer_key = datum.series
                  series_key = datum.x

               if officer_key
                  object[officer_key] ?= {}
                  object[officer_key][key] ?= {}
                  if prefix
                     object[officer_key][key]["#{prefix}_#{series_key}"] = value_key
                     if key in ["proposal-amount"]
                        value_key = 0 if series_key != "Funded Amount"
                     object[officer_key]["#{key}_#{prefix}_total"] ?= 0
                     object[officer_key]["#{key}_#{prefix}_total"] += value_key
                  else
                     object[officer_key][key][series_key] = value_key
                     if key in ["proposal-amount"]
                        value_key = 0 if series_key != "Funded Amount"
                     object[officer_key]["#{key}_total"] ?= 0
                     object[officer_key]["#{key}_total"] += value_key
         object

      getSeriesColorMap: (data) ->
         series_values = _.uniq _.pluck(data, "series")
         series_to_color = {}
         _.each series_values, (val, index) ->
            series_to_color[val] = COLORS[index] || _.randomHex()
         series_to_color

      getDateRangeParams: (date_key) ->
         fy_start = DNAStore.getFiscalYearStart()
         fy_end = DNAStore.getFiscalYearEnd()

         switch date_key
            when "fiscal_year"
               date = {gte: fy_start, lte: "now"}
               compare =
                  gte: moment(fy_start).subtract(1, "year").format(DATE_FORMAT)
                  lte: moment().subtract(1, "year").format(DATE_FORMAT)
            when "last_fiscal_year"
               date =
                  gte: moment(fy_start).subtract(1, "year").format(DATE_FORMAT)
                  lte: moment(fy_end).subtract(1, "year").format(DATE_FORMAT)
               compare =
                  gte: moment(fy_start).subtract(2, "year").format(DATE_FORMAT)
                  lte: moment(fy_end).subtract(2, "year").format(DATE_FORMAT)
            when "ytd"
               date = {gte: moment("#{CURRENT_YEAR}-01-01").format(DATE_FORMAT), lte: "now"}
               compare =
                  gte: moment("#{CURRENT_YEAR}-01-01").subtract(1, "year").format(DATE_FORMAT)
                  lte: moment().subtract(1, "year").format(DATE_FORMAT)
            when "mtd"
               date = {gte: moment("#{CURRENT_YEAR}-#{CURRENT_MONTH}-01").format(DATE_FORMAT), lte: "now"}
               compare =
                  gte: moment("#{CURRENT_YEAR}-#{CURRENT_MONTH}-01").subtract(1, "month").format(DATE_FORMAT)
                  lte: moment(date.gte).subtract(1, "day").format(DATE_FORMAT)

         date_range: date
         date_range_compare: compare
