module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   moment = require("moment")
   numeral = require("numeral")
   {createComponent} = require("@evertrue/et-flux")
   {div, span, strong} = ReactLibs.DOMFactories


   createComponent "GiftCard",
      propTypes:
         gift: ReactLibs.PropTypes.object

      render: ->
         div className: "gift-card",
            if @props.gift?.occurred_at
               span className: "gift-card--label fs-exclude-data",
                  moment(@props.gift?.occurred_at).format("MMM DD, YYYY")

            strong className: "gift-card--amount fs-exclude-data",
               numeral(@props.gift?.amount).format("$0,00.00")

            div className: "gift-card--designations",
               _.map @props.gift?.labels, (label) ->
                  if label?.value?.trim?()
                     span className: "gift-card--tag fs-exclude-data", key: label.value,
                        label.value
