module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   numeral = require("numeral")
   {createComponent} = require("@evertrue/et-flux")
   {div, span} = ReactLibs.DOMFactories
   FilterStore = require("apps/filters/stores/filter-store")
   MapUtils = require("apps/map/map-utils")
   Loading = require("components/elements/loading")
   {createFactory} = require("base/new-utils")
   ScoreProgress = createFactory require("apps/contact/components/contacts/score-progress").default
   ContactCard = createFactory(require("apps/contact/components/contact-card/contact-card"))
   Gated = createFactory require("components/gated")
   EmptyStateMessage = createFactory(require("components/lists/empty-state-message"))

   _roundObject = (obj) ->
      _.mapObject obj, (data) ->
         parseFloat numeral(data).format("0.00000")


   createComponent "MapClusterContacts",
      propTypes:
         loading: ReactLibs.PropTypes.bool
         contacts: ReactLibs.PropTypes.array
         geobox: ReactLibs.PropTypes.object

      renderAddressTypes: (contact) ->
         box = @props.geobox
         if box && ((box.north == box.south) || (box.east == box.west))
            box = MapUtils.getBoxFromLatLngRadius(MapUtils.boxToLatLng(box), 1)

         types = _.pluck FilterStore.getActiveFilterByKey("address_type"), "value"
         addresses = _.compact _.map contact.addresses, (address) ->
            if address
               lat_lng = _roundObject({lat: address.lat?.value, lng: address.lng?.value})
               if (_.isEmpty(types) || _.contains(types, address.type?.value)) &&
                     MapUtils.isLatLngInBox(lat_lng, _roundObject(box))
                  address?.type?.value

         if _.isEmpty(addresses) then "Other Address" else addresses.join(", ")

      render: ->
         div className: "map-cluster-contacts",
            if @props.loading
               Loading position: "center"
            else
               div className: "map-cluster-contacts--content",
                  if _.isEmpty @props.contacts
                     div className: "map-cluster-contacts--empty-wrapper",
                        EmptyStateMessage
                           className: "map-cluster-contacts--empty"
                           size: "small"
                           text: "Error loading constituents"
                  else
                     _.map @props.contacts, (contact) =>
                        [
                           ContactCard key: contact.id, contact: contact,
                              div className: "map-cluster-contacts--row",
                                 Gated feature: "scores",
                                    span className: "text-label", "EverTrue Score:"
                                    ScoreProgress score: contact.score?.score?.value

                              div className: "map-cluster-contacts--row",
                                 div className: "text-label", @renderAddressTypes(contact)
                        ]

