module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")
   Decorator = require("clientDecorator")

   _initialized = false


   createSource "NotificationSource",
      actions:
         fetchedNotifications: (notifications) ->
            @require _.isObject(notifications), "notifications should be object"
         fetchedContacts: (noti_id, contacts) ->
            @require _.isNumber(noti_id), "noti_id is notification id number"
            @require _.isPagedObject(contacts), "contacts is paged object"
         fetchedMentionedContacts: (contacts) ->
            @require _.isPagedObject(contacts), "contacts is paged object"
         updatedCount: (badge_count) ->
            @require _.isNumber(badge_count), "badge count should be number"
         updatedNotification: (notification) ->
            @require _.isObject(notification), "notification should be object"

      init: ->
         return if _initialized
         EverTrue.vent.on "storeReset org:initialized", => @fetch()
         _initialized = true

      api:
         fetch: ->
            @init()
            Api.EMS.NOTIFICATION.get
               success: (resp) =>
                  count = resp?.meta?.badge_count
                  @actions.updatedCount(count) unless _.isUndefined(count)
                  @actions.fetchedNotifications(resp.notifications)

         fetchContacts: (id) ->
            Api.EMS.NOTIFICATION.get
               urlExtend: "/#{id}"
               success: (resp) =>
                  notification = resp.notification
                  activity =  notification?.activity?.content?.search?.contact_ids

                  Api.CONTACTS.BULK.get
                     params:
                        id: _.uniq(activity)
                        limit: 50
                     success: (resp) =>
                        contacts = _.clone resp
                        contacts.items = _.map contacts.items, (contact) ->
                           Decorator.Contacts.parse(contact)
                        @actions.fetchedContacts id, contacts

         fetchMentionedContacts: (contact_ids) ->
            return if _.isEmpty(contact_ids)
            query = must: [{id: in: contact_ids}]

            Api.CONTACTS.SEARCH.post
               data: _.jsonStringify(query)
               success: (resp) =>
                  resp.items = _.map resp.items, (item) ->
                     Decorator.Contacts.parse(item)
                  @actions.fetchedMentionedContacts(resp)

         markAsRead: (id) ->
            Api.EMS.NOTIFICATION.put
               urlExtend: "/#{id || "all"}/read"
               success: (resp) =>
                  @actions.updatedCount(resp?.inbox?.meta?.badge_count)
                  if resp?.notification
                     @actions.updatedNotification(resp?.notification)
