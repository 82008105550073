module.exports = do ->
   React = require("react")
   EverTrue = require("app")
   _ = require("underscore").default
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories
   OverlayStore = require("apps/layout/stores/overlay-store")


   createComponent "OverlayController",
      registerStores: ->
         @on OverlayStore, ->
            overlays: OverlayStore.getOverlays()

      render: ->
         if @state.overlays
            div null,
               _.map @state.overlays, (overlay) ->
                  div
                     id: "layout-overlay"
                     key: overlay.key
                     style:
                        position: "fixed"
                        top: overlay.position?.top
                        left: overlay.position?.left
                        bottom: overlay.position?.bottom
                        right: overlay.position?.right
                        zIndex: overlay.position.zIndex
                     overlay.component
         else null
