import PropTypes from "prop-types";
import { Draggable } from "react-beautiful-dnd";

const DraggableItem = ({ item, disabled, index }) => (
  <Draggable isDragDisabled={disabled} draggableId={item.key || item.value} index={index}>
    {(provided, snapshot) => (
      <div
        ref={provided.innerRef}
        className={snapshot.isDragging ? "dnd-controller--is-dragging" : ""}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        {item.component}
      </div>
    )}
  </Draggable>
);

DraggableItem.propTypes = {
  item: PropTypes.object,
  disabled: PropTypes.bool,
  index: PropTypes.number,
};

DraggableItem.defaultProps = {
  item: {},
  disabled: false,
};

export default DraggableItem;
