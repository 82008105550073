/* eslint-disable react-hooks/exhaustive-deps */
import EverTrue from "app";
import { useEffect, useState } from "react";
import ProfileCardHeader from "../components/profile-card-header";
import AISummary from "../components/profile-ai-summary";
import PropTypes from "prop-types";
import useDataStream from "../../../entities/helpers/use-data-stream";


const ProfileAiController = ({ contactId }) => {

  const { openDataStream, isStreaming, response, metadata } = useDataStream();
  const [summary, setSummary] = useState("");
  const { request_id: requestId } = metadata;

  useEffect(() => {
    async function getData() {
      try {
        const options = {
          method: "POST",
          endpoint:
            `ai/v1/summarizations/profile/${contactId}`,
          params: {
            append_request_id: false,
          },
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "ET-Request-ID": `summarization:${contactId}`,
          },
          data: {},
        }
        await openDataStream(options);
      }
      catch (err) {
        EverTrue.Alert.error("AI email generation failed.");
      }
    }
    getData();
  }, []);



  useEffect(() => {
    setSummary(summary => {
      return summary += response
    });
  }, [response])

  return (
    <>
      <ProfileCardHeader className="profile-ai--card-header-small" title="What You Need to Know" help="profile_ai_summary_import" />
      <AISummary
        isStreaming={isStreaming}
        summary={summary}
        requestId={requestId}
      />
    </>
  );
};

ProfileAiController.propTypes = {
  contactId: PropTypes.number,
};

export default ProfileAiController;
