import { Component, Fragment } from "react";
import { connect } from "@evertrue/et-flux";
import PropTypes from "prop-types";
import BulkAddStore from "apps/layout/stores/bulk-add-store";
import BulkAddToast from "apps/layout/components/bulk-add-toast";
import PoolsResolver from "apps/volunteers/components/pools-resolver";
import BulkAddSource from "apps/layout/sources/bulk-add-source";
import _ from "underscore";
import BulkJobToast from "apps/layout/components/bulk-job-toast";

const mapStateToProps = () => ({
  listStatusObj: BulkAddStore.getBulkAddToList(),
  poolStatusObj: BulkAddStore.getBulkAddToPool(),
  bulkVMSRolloverStatusObj: BulkAddStore.getBulkVMSRollover(),
  bulkVMSDeleteStatusObj: BulkAddStore.getBulkVMSDelete(),
});

class BulKAddController extends Component {
  static propTypes = {
    listStatusObj: PropTypes.object,
    poolStatusObj: PropTypes.object,
    bulkVMSRolloverStatusObj: PropTypes.object,
    bulkVMSDeleteStatusObj: PropTypes.object,
  };

  static defaultProps = {
    listStatusObj: {},
    poolStatusObj: {},
    bulkVMSRolloverStatusObj: {},
    bulkVMSDeleteStatusObj: {},
  };

  state = {
    list_active: false,
    pool_active: false,
    bulk_vms_rollover_active: false,
    bulk_vms_delete_active: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    // we don't want to base whether or not toast is open based on
    // progress # alone. after it's done, you let user close it
    const pool_progress = nextProps.poolStatusObj.progress;
    if (!prevState.list_active && _.isNumber(nextProps.listStatusObj.progress)) {
      return { list_active: true };
    }
    if (!prevState.pool_active && _.isNumber(pool_progress)) {
      return { pool_active: true };
    }
    if (!prevState.bulk_vms_rollover_active && _.isNumber(nextProps.bulkVMSRolloverStatusObj.progress)) {
      return { bulk_vms_rollover_active: true };
    }
    if (!prevState.bulk_vms_delete_active && _.isNumber(nextProps.bulkVMSDeleteStatusObj.progress)) {
      return { bulk_vms_delete_active: true };
    }
    return null;
  }

  handleClose = key => () => {
    BulkAddSource.clearPool();
    BulkAddSource.clearList();
    BulkAddSource.bulkVMSRollover({});
    BulkAddSource.bulkVMSPoolDelete({});
    this.setState({ [key]: false });
  };

  render() {
    const { listStatusObj, poolStatusObj, bulkVMSRolloverStatusObj, bulkVMSDeleteStatusObj } = this.props;
    return (
      <Fragment>
        {this.state.list_active && (
          <BulkAddToast
            type="list"
            handleClose={this.handleClose("list_active")}
            name={listStatusObj.label}
            count={listStatusObj.count}
            id={listStatusObj.id}
            inProgress={Boolean(_.isNumber(listStatusObj.progress) && listStatusObj.progress !== 1)}
            progress={listStatusObj.progress}
          />
        )}
        {this.state.pool_active && (
          <PoolsResolver
            ids={[poolStatusObj.id]}
            render={({ pools }) => {
              const pool = pools[poolStatusObj.id] || {};
              return (
                <BulkAddToast
                  type="pool"
                  handleClose={this.handleClose("pool_active")}
                  name={pool.name}
                  count={poolStatusObj.count}
                  id={poolStatusObj.id}
                  inProgress={Boolean(_.isNumber(poolStatusObj.progress) && poolStatusObj.progress !== 1)}
                  progress={poolStatusObj.progress}
                />
              );
            }}
          />
        )}
        {this.state.bulk_vms_rollover_active && (
          <BulkJobToast
            handleClose={this.handleClose("bulk_vms_rollover_active")}
            progress={bulkVMSRolloverStatusObj.progress}
            text={bulkVMSRolloverStatusObj.progress !== 1 ? "Pool Updates in Progress" : "Pool Updates Completed"}
          />
        )}
        {this.state.bulk_vms_delete_active && (
          <BulkJobToast
            handleClose={this.handleClose("bulk_vms_delete_active")}
            progress={bulkVMSDeleteStatusObj.progress}
            text={bulkVMSDeleteStatusObj.progress !== 1 ? "Pool deletion in Progress" : "Pool Deletion Completed"}
          />
        )}
      </Fragment>
    );
  }
}

export default connect(
  BulKAddController,
  [BulkAddStore],
  mapStateToProps
);
