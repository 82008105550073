// eslint-disable-rule import/newline-after-import
import Api from "entities/helpers/api";
import { createSource } from "@evertrue/et-flux";
import Decorator from "@evertrue/client-decorator";
import { fetchUsers, getVolunteerRoleIds } from "apps/volunteers/utils";
import config from "config/env";
import EverTrue from "app";
import _ from "underscore";

const cleanup = _.compose(
  _.compact,
  _.uniq
);

const VolunteerUserSource = createSource("VolunteerUserSource", {
  actions: {
    fetchIfNotCached: true,
    loading: true,
    fetch: true,
    fetchedUsers: true,
    remove: true,
    fetchedSessions: true,
  },

  api: {
    fetchIfNotCached(contact_ids, role_ids, org_id) {
      this.actions.fetchIfNotCached(contact_ids, role_ids, org_id);
    },

    fetch(contact_ids, roles, org_id) {
      const ids = cleanup(contact_ids);
      if (ids.length) {
        fetchUsers(ids, getVolunteerRoleIds(roles), org_id).then(resp => {
          this.actions.fetchedUsers(resp, org_id);
        });
      }
    },

    fetchSessions(contact_id, org_id) {
      const vol_app_id = config.getApplicationId("volunteers");
      const query = {
        must: [
          {
            application_id: {
              match: vol_app_id,
            },
          },
          {
            user_id: {
              match: contact_id,
            },
          },
          {
            oids: {
              match: org_id,
            },
          },
        ],
        sort: [
          {
            created_at: {
              order: "desc",
            },
          },
        ],
        facet: [
          {
            field: "user_id",
          },
        ],
      };

      Api.SEARCH.SESSIONS.post({
        data: _.jsonStringify(query),
        params: { limit: 1 },
        success: res => {
          this.actions.fetchedSessions(res);
        },
      });
    },
    removeAsVolunteer({ user, oid, roles = [], vol_role }) {
      const contact_id = Decorator.User.getContactId(user, oid);

      Api.AUTH.AFFIL.put({
        urlExtend: `/${vol_role.affiliation_id}`,
        data: _.jsonStringify({
          contact_id,
          role_ids: _.pluck(roles, "id"),
        }),
        success: () => {
          EverTrue.Alert.success("This user's access to Volunteer By Evertrue has been removed.");
          this.actions.remove(contact_id);
        },
        error: () => {
          EverTrue.Alert.error(
            "An error occurred while updating this volunteer. Please contact support if this issue persists."
          );
        },
      });
    },
  },
});

export default VolunteerUserSource;
