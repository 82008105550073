module.exports = do ->
   _ = require("underscore").default
   Api = require("entities/helpers/api")
   {createSource} = require("@evertrue/et-flux")
   EverTrue = require("app")
   ErrorLogger = require("entities/helpers/error-log-helper")


   createSource "FacebookSource",
      actions:
         fetchedPages: (pages) ->
            @require _.isObject(pages), "pages should be object"
         fetchedOauth: (token) ->
            @require _.isString(token), "token should be a string"
         # applyFilters: (type, filter_object) ->
         #    @require _.isString(type), "type should be a string"
         #    @require _.isObject(filter_object), "filter_object should be in an object"
         pagesLoading: (loading) ->
            @require _.isBoolean(loading), "loading should be boolean"
         fetchedAuthedPages: true

      api:
         fetchPages: (params={}) ->
            @actions.pagesLoading(true)
            oid = EverTrue.store.org.get("id")
            Api.LIDS.ORG_DATA.get
               urlExtend: "/#{oid}/pages"
               params: params
               success: (resp) =>
                  @actions.fetchedPages(resp.pages)
                  @actions.pagesLoading(false)
               error: (xhr) =>
                  ErrorLogger.captureRequest "Facebook Pages Error", xhr
                  @actions.pagesLoading(false)

         fetchOauth: ->
            Api.LIDS.SERVICES.get
               urlExtend: "/facebook"
               success: (resp) =>
                  @actions.fetchedOauth(resp?.oauth2_token)

         startOauth: ->
            Api.LIDS.FACEBOOK_AUTH.get
               params:
                  callback_url: encodeURIComponent(EverTrue.config.wwwBase + "facebook/accounts")
               success: ({authorize_url}) ->
                  window.location = authorize_url
         # unsued but leaving for future
         disconnect: (page_id) ->
            Api.LIDS.FACEBOOK_TOKENS.put
               urlArgs: {page_id: page_id}
               success: (resp) =>
                  @fetchPages()
