import EverTrue from "app";
import BaseRouter from "base/base-router";
import FiltersInventoryController from "apps/filters-inventory/controllers/filters-inventory-controller";

class FiltersInventoryRouter extends BaseRouter {
  static initClass() {
    this.prototype.routes = {
      "filters-inventory": "inventory",
    };
  }

  inventory() {
    EverTrue.execute("setBrowserTitle", "Filters Inventory");
    this.currentView = <FiltersInventoryController type="proposals" />;
    EverTrue.page.mount(this.currentView);
  }
}

FiltersInventoryRouter.initClass();
export default FiltersInventoryRouter;
