import { usePromiseResolver } from "@evertrue/promise-resolver";
import API from "entities/helpers/api";

const useProposalTypes = () => {
  const [proposalTypes, loading] = usePromiseResolver(() => API.UGC.PROPOSAL_TYPES.get(), []);
  const formattedProposalTypes = proposalTypes ? proposalTypes.map((type) => ({ label: type, value: type })) : [];

  return [formattedProposalTypes, loading];
};

export default useProposalTypes;
