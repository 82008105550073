module.exports = do ->
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {createFactory} = require("base/new-utils")
   PageHeader = createFactory(require("apps/layout/components/page-header").default)
   SelectedContactsStore = require("apps/contact/stores/selected-contacts-store")
   SelectedContactsString = require("components/formatting/selected-contacts-string")


   createComponent "ContactsPageHeader",
      propTypes:
         title: ReactLibs.PropTypes.any
         subtitle: ReactLibs.PropTypes.node
         actions: ReactLibs.PropTypes.node
         loading: ReactLibs.PropTypes.bool
         totalResults: ReactLibs.PropTypes.number
         additionalText: ReactLibs.PropTypes.node
         showBackButton: ReactLibs.PropTypes.bool
         backButtonHref: ReactLibs.PropTypes.string
         disableActions: ReactLibs.PropTypes.bool

      registerStores: ->
         @on SelectedContactsStore, ->
            selected_contacts: SelectedContactsStore.getSelectedContacts()

      renderSelectedContacts: ->
         SelectedContactsString
            selected: @state.selected_contacts.length
            results: @props.totalResults
            loading: @props.loading
            additional_text: @props.additionalText

      render: ->
         PageHeader
            className: @props.className
            title: @props.title
            subtitle: @renderSelectedContacts()
            actions: @props.actions
            showBackButton: @props.showBackButton
            backButtonHref: @props.backButtonHref
            disableActions: @props.disableActions
            @props.children
