import Api from "entities/helpers/api";
import ErrorLogger from "entities/helpers/error-log-helper";

export const uploadFile = async (file) => {
  const getPresignedUrl = async (name) => {
    return Api.OUTREACH.UPLOAD_PRESIGNED_URL.get({
      params: {
        fileName: name,
      },
    }).then((res) => res);
  };

  const uploadFileToAWS = async (presignedUrl) => {
    const res = await fetch(presignedUrl, {
      method: "PUT",
      body: file,
    });
    return res;
  };

  const getDownloadUrl = async (key) => {
    return Api.OUTREACH.DOWNLOAD_PRESIGNED_URL.get({
      params: {
        objectKey: key,
      },
    }).then((res) => res);
  };

  const parseKey = (url) => {
    return url.split("s3.amazonaws.com/")[1].split("?")[0];
  };

  try {
    const { url } = await getPresignedUrl(file.name);
    const key = parseKey(url);
    await uploadFileToAWS(url);
    return await getDownloadUrl(key);
  } catch (e) {
    ErrorLogger.error("Error uploading file to AWS", { extra: { e, page_name: "Emailer" } });
    return { error: e };
  }
};
