module.exports = do ->
   React = require("react")
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   moment = require("moment")
   {span, div} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   Icon = createFactory (require("components/elements/icon").default)
   ButtonTooltip = createFactory(require("@evertrue/et-components").ButtonTooltip)


   createComponent "InteractionMeta",
      propTypes:
         interaction: ReactLibs.PropTypes.object
         showContact: ReactLibs.PropTypes.bool

      getDefaultProps: ->
         showContact: true
         interaction: {}
         showMetaRight: true

      render: ->
         {date_occurred, created_at, author_name, update_source} = @props.interaction
         interaction_date = if @props.showMetaRight
            moment(date_occurred || created_at).format("MM/D/YYYY")
         else
            moment(date_occurred || created_at).format("MMMM Do YYYY")

         div className: "interaction-meta",
            div className: classNames("clearfix", "text-right": @props.showMetaRight),
               div null,
                  if update_source == "IMPORTER"
                     ButtonTooltip children: "Last Updated via Import", trigger: Icon icon: "exit-to-app", rotate: 90, before: true, className: "icon-muted", disableTitle: true
                  else
                     ButtonTooltip children: "Last Updated via EverTrue", trigger: Icon icon: "community", before: true, className: "icon-muted", disableTitle: true

                  if (interaction_date && interaction_date != "Invalid date")
                     span className: "fs-exclude-data", interaction_date

               if author_name
                  span className: "interaction-meta--header-name",
                     span className: "fs-exclude-data", author_name
