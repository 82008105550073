import _ from "underscore";
import AmountsModal from "apps/volunteers/components/amounts-modal";
import Api from "entities/helpers/api";
import PropTypes from "prop-types";

const CommittedAmountModal = props => {
  return (
    <AmountsModal
      header="Committed Amount"
      label="Committed Amount:"
      amount={props.searchAssignment.committed_amount_in_dollars}
      handleSubmit={dollar_val => {
        const { assignment_id, committed_amount_in_dollars } = props.searchAssignment;
        if (dollar_val !== committed_amount_in_dollars) {
          // refresh kicked off by websocket event
          Api.VOLUNTEER.COMMITTED_AMOUNT.post({
            data: _.jsonStringify({ committed_amount_in_dollars: dollar_val }),
            urlExtend: `/${assignment_id}`,
          });
        }

        props.close();
      }}
      {...props}
    />
  );
};

CommittedAmountModal.propTypes = {
  searchAssignment: PropTypes.object,
  close: PropTypes.func,
};

CommittedAmountModal.defaultProps = {
  searchAssignment: {},
};

export default CommittedAmountModal;
