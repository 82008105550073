import PropTypes from "prop-types";
import classNames from "classnames";

const { any, string, bool } = PropTypes;

const barStyles = {
  flexGrow: 0,
  flexShrink: 0,
  position: "relative",
};

const panelStyles = {
  flexGrow: 0,
  flexShrink: 0,
  overflowY: "auto",
  position: "relative",
};

const propTypes = {
  className: string,

  header: any,
  headerBorder: bool,

  subHeader: any,
  subHeaderBorder: bool,

  children: any,

  leftPanel: any,
  leftPanelWidth: any,
  leftPanelBorder: bool,

  rightPanel: any,
  rightPanelWidth: any,
  rightPanelBorder: bool,

  footer: any,
  footerBorder: bool,

  overflowYValue: string,
};

const defaultProps = {
  headerBorder: true,
  subHeaderBorder: true,
  leftPanelBorder: true,
  leftPanelWidth: 300,
  rightPanelBorder: true,
  rightPanelWidth: 300,
  footerBorder: true,
  overflowYValue: "auto",
};

const Layout = props => {
  const {
    className,
    header,
    headerBorder,
    subHeader,
    subHeaderBorder,
    leftPanel,
    leftPanelWidth,
    leftPanelBorder,
    rightPanel,
    rightPanelWidth,
    rightPanelBorder,
    footer,
    footerBorder,
    overflowYValue,
    children,
  } = props;

  return (
    <div
      className={classNames("layout", className)}
      style={{ height: "100%", display: "flex", flexDirection: "column" }}
    >
      {header && (
        <div className={classNames("layout--header", { "has-border": headerBorder })} style={{ ...barStyles }}>
          {header}
        </div>
      )}

      {subHeader && (
        <div className={classNames("layout--sub-header", { "has-border": subHeaderBorder })} style={{ ...barStyles }}>
          {subHeader}
        </div>
      )}

      <div className="layout--main-wrap" style={{ display: "flex", flexGrow: 1 }}>
        {leftPanel && (
          <div
            className={classNames("layout--left-panel", { "has-border": leftPanelBorder })}
            style={{ ...panelStyles, width: leftPanelWidth }}
          >
            {leftPanel}
          </div>
        )}

        <div
          className="layout--main"
          style={{
            overflowY: overflowYValue,
            flexGrow: 1,
            position: "relative",
          }}
        >
          {children}
        </div>

        {rightPanel && (
          <div
            className={classNames("layout--right-panel", { "has-border": rightPanelBorder })}
            style={{ ...panelStyles, width: rightPanelWidth }}
          >
            rightPanel
          </div>
        )}
      </div>
      {footer && (
        <div className={classNames("layout--footer", { "has-border": footerBorder })} style={{ ...barStyles }}>
          {footer}
        </div>
      )}
    </div>
  );
};

Layout.propTypes = propTypes;

Layout.defaultProps = defaultProps;

export default Layout;
