import { Button, useOpenClose } from "@evertrue/et-components";
import TaskEmailAlertsModal from "./task-email-alerts-modal";

const EmailAlertsButton = () => {
  const [isModalOpen, openModal, closeModal] = useOpenClose();

  return (
    <>
      <Button type="secondary" onClick={isModalOpen ? closeModal : openModal}>
        Email Alerts
      </Button>
      <TaskEmailAlertsModal isModalOpen={isModalOpen} closeModal={closeModal} />
    </>
  );
};

export default EmailAlertsButton;
