module.exports = do ->
   _ = require("underscore").default
   str = require("underscore.string")
   React = require("react")
   {span, strong} = ReactLibs.DOMFactories
   str = require("underscore.string")
   # "EngagementUtils"

   ##############################
   # network: "facebook"
   # type: "reaction"
   # value: reaction
   # verb: for use
   # label:
   # src: enagement icon src
   ##############################

   verb = (leading_word, strong_word, trailing_word) ->
      span null,
         if leading_word then " #{leading_word} " else " "
         strong(null, strong_word)
         if trailing_word then " #{trailing_word} " else " "

   SRC_ATTENDING = "/images/event-engagement-attending.png"
   SRC_CHECKED_IN = "/images/event-engagement-checked-in.png"
   SRC_MAYBE = "/images/event-engagement-maybe.png"
   SRC_INVITED = "/images/event-engagement-invited.png"
   SRC_DECLINED = "/images/event-engagement-declined.png"
   # SRC_COMMUNITY = "/images/community-logo.png"
   SRC_GIFT = "/images/engagement-gift.png"
   SRC_DEFAULT = "/images/engagement-default.png"
   SRC_FACEBOOK = "/images/facebook_outline.png"
   SRC_EVENTBRITE = "/images/eventbrite_outline.png"
   SRC_LOGO_FACEBOOK = "/images/FB-f-Logo__blue_50.png"
   SRC_LOGO_EVENTBRITE = "/images/icon_eventbrite_lg.png"

   REACTED_VERB = verb(null, "reacted", "to")

   _reaction_keys = [
      {type: "comment", verb: verb(null, "commented", "on")}
      {type: "like", verb: verb(null, "liked")}
      {type: "love", verb: REACTED_VERB}
      {type: "wow", verb: REACTED_VERB}
      {type: "sad", verb: REACTED_VERB}
      {type: "haha", verb: REACTED_VERB}
      {type: "angry", verb: REACTED_VERB}
      {type: "thankful", verb: REACTED_VERB}
   ]

   FACEBOOK_REACTIONS = _.map _reaction_keys, ({type, verb}) ->
      network: "facebook"
      type: "reaction"
      value: type
      verb: verb
      label: str.capitalize(type)
      src: "/images/fb-reactions-#{type}.png"


   _fb_event_engagements = [
      {value: "attending", label: "Going", verb: verb("is", "going", "to"), src: SRC_ATTENDING}
      {value: "unsure", label: "Interested", verb: verb("is", "interested", "in"), src: SRC_MAYBE}
      {value: "not_replied", label: "Invited", verb: verb("is", "invited", "to"), src: SRC_INVITED}
      {value: "declined", label: "Can't Go", verb: verb(null, "can't go", "to"), src: SRC_DECLINED}
   ]

   FACEBOOK_EVENT_ENGAGEMENTS = _.map _fb_event_engagements, ({value, label, verb, src}) ->
      network: "facebook"
      type: "event_engagement"
      value: value
      label: label
      verb: verb
      src: src

   _eb_enagements = [
      {value: "Checked In", verb: verb("is", "checked in", "for"), src: SRC_CHECKED_IN}
      {value: "Attending", verb: verb("is", "attending"), src: SRC_ATTENDING}
      {value: "Not Attending", verb: verb("is", "not attending"), src: SRC_DECLINED}
      {value: "Deleted", label: "Removed", verb: verb("is", "not attending"), src: SRC_DECLINED}
   ]

   EVENTBRITE_ENGAGEMENTS = _.map _eb_enagements, ({value, label, verb, src}) ->
      network: "eventbrite"
      type: "event_engagement"
      value: value
      label: label || value
      verb: verb
      src: src

   GIFT_ENGAGEMETNS = [
      network: null
      type: "gift"
      value: "gift"
      label: "Gift"
      verb: "gave"
      src: SRC_GIFT
   ]

   # default icon for unknown engagement type
   DEFAULT_ENGAGEMENTS = [
      network: null
      type: "default_engagemnt"
      value: "_default_engagement"
      label: "Engaged"
      verb: verb(null, "engaged", "with")
      src: SRC_DEFAULT
   ]


   NETWORK_TYPES = [
      # NETWORK icons
      value: "facebook"
      label: "Facebook"
      src: SRC_FACEBOOK
      logo_src: SRC_LOGO_FACEBOOK
   ,
      value: "eventbrite"
      label: "Eventbrite"
      src: SRC_EVENTBRITE
      logo_src: SRC_LOGO_EVENTBRITE
   ]

   ALL_ENGAGEMENTS = [].concat(
      FACEBOOK_REACTIONS
      FACEBOOK_EVENT_ENGAGEMENTS
      EVENTBRITE_ENGAGEMENTS
      GIFT_ENGAGEMETNS
      DEFAULT_ENGAGEMENTS
   )

   EVENT_ENGAGEMENTS = [].concat(
      FACEBOOK_EVENT_ENGAGEMENTS
      EVENTBRITE_ENGAGEMENTS
      DEFAULT_ENGAGEMENTS
   )

   SRC_ATTENDING = "/images/event-engagement-attending.png"
   SRC_CHECKED_IN = "/images/event-engagement-checked-in.png"
   SRC_MAYBE = "/images/event-engagement-maybe.png"
   SRC_INVITED = "/images/event-engagement-invited.png"
   SRC_DECLINED = "/images/event-engagement-declined.png"

   EVENT_FILTERS = [
      label: "All Matched Engagers"
      route_key: "all"
      engagement_actions: []
      type: ["facebook", "eventbrite"]
   ,
      label: "Checked In"
      route_key: "checked-in"
      engagement_actions: ["Checked In"]
      type: ["eventbrite"]
      src: SRC_CHECKED_IN
   ,
      label: "Attending"
      route_key: "attending"
      engagement_actions: ["Attending"]
      type: ["eventbrite"]
      src: SRC_ATTENDING
   ,
      label: "Not Attending"
      route_key: "not-attending"
      engagement_actions: ["Not Attending", "Removed", "Deleted"]
      type: ["eventbrite"]
      src: SRC_DECLINED
   ,
      label: "Going"
      route_key: "going"
      engagement_actions: ["attending"]
      type: ["facebook"]
      src: SRC_ATTENDING
   ,
      label: "Interested"
      route_key: "interested"
      engagement_actions: ["unsure"]
      type: ["facebook"]
      src: SRC_MAYBE
   ,
      label: "Invited"
      route_key: "invited"
      engagement_actions: ["not_replied"]
      type: ["facebook"]
      src: SRC_INVITED
   ,
      label: "Can't Go"
      route_key: "cant-go"
      engagement_actions: ["declined"]
      type: ["facebook"]
      src: SRC_DECLINED
   ]

   getEventActionsByNetwork = (network) ->
      items  = _.filter EVENT_FILTERS, ({type, engagement_actions}) ->
         _.notEmpty(engagement_actions) and network in type

      _.map items, ({label, src}) -> label: label, value: label, src: src

   getIconInfoByTabLabel = (tab) ->
      _.findWhere EVENT_FILTERS, label: tab

   getEngagementInfoByValue = (value) ->
      _.findWhere(ALL_ENGAGEMENTS, value: value) || DEFAULT_ENGAGEMENTS[0]

   getEngagementSrcByValue = (value) ->
      (_.findWhere(ALL_ENGAGEMENTS, value: value) || DEFAULT_ENGAGEMENTS[0]).src

   getEngagementActionsByTab = (value) ->
      _.findWhere(EVENT_FILTERS, label: value)?.engagement_actions || []

   getEngagementActionsByFilterKeys = (keys) ->
      filters = _.map keys, (key) ->
         _.findWhere(EVENT_FILTERS, route_key: key)?.engagement_actions
      
      if filters.length is 0 then return []
      _.flatten filters

   getTabByEngagementAction = (value) ->
      _.find(EVENT_FILTERS, (tab) -> value in tab.engagement_actions)?.label || "Engaged"

   getVerbByEngagementType = (value) ->
      _.findWhere(ALL_ENGAGEMENTS, value: value)?.verb || "engaged"

   getNetworkImageByType = (network) ->
      _.findWhere(NETWORK_TYPES, value: network)?.src

   getNetworkLogoByType = (network) ->
      _.findWhere(NETWORK_TYPES, value: network)?.logo_src

   return {
      EVENT_ENGAGEMENTS
      ALL_ENGAGEMENTS
      NETWORK_TYPES
      EVENT_FILTERS
      getIconInfoByTabLabel
      getEventActionsByNetwork
      getEngagementInfoByValue
      getEngagementActionsByTab
      getEngagementActionsByFilterKeys
      getTabByEngagementAction
      getVerbByEngagementType
      getNetworkImageByType
      getNetworkLogoByType
      getEngagementSrcByValue
   }



