module.exports = do ->
   React = require("react")
   {createFactory} = require("base/new-utils")
   Gated = createFactory require("components/gated")
   BannerNotice = createFactory require("components/banner-notice")
   Link = createFactory(require("@evertrue/et-components").Link)

   FacebookSodaMessage = ReactLibs.createClass
      displayName: "Facebook SODA Message"

      render: ->
         link = Link href: "https://evertrue.statuspage.io", title: "View Status Page", target: "_blank",
            "status page"

         Gated feature: "facebook_soda_message",
            BannerNotice icon: "exclamation-circle", className: "facebook-soda-notice",
               "Facebook post collection and constituent matching is currently delayed by several days. We are working to remedy the situation now. Please check our ", link, " for updates."


