import PropTypes from "prop-types";
import _ from "underscore";
import EverTrue from "app";
import { Icon, Button, Loading } from "@evertrue/et-components";
import FilterPickerCount from "apps/query-builder/components/filter-picker/filter-picker-count";

const FilterSearchOptions = ({ searchResults, onSelection, loading }) => {
  const handleSelection = (filter) => {
    EverTrue.track.set("filter_v2_action", {
      type: "select_filter",
      selected_filter: `${filter.group} > ${filter.label}`,
    });
    onSelection(filter);
  };
  const renderSearchResults = (category, group) => (
    <ul>
      {searchResults[category][group].map((filter) => (
        <li key={filter.compound_id} className="filter-picker--list-option">
          {
            <Button type="simple" onClick={() => handleSelection(filter)}>
              <Icon className="filter-picker--list-icon" icon={filter.icon} />
              {filter.label}
            </Button>
          }
        </li>
      ))}
    </ul>
  );

  return (
    <>
      <FilterPickerCount type="search" filterResults={searchResults} />
      {!_.isEmpty(searchResults) ? (
        <div className="filter-picker--group-wrapper">
          <div className="filter-picker--group-wrapper-scroll">
            {Object.keys(searchResults).map((category) => {
              return Object.keys(searchResults[category]).map((group) => {
                return (
                  <div className="filter-picker--group" key={group}>
                    <div className="filter-picker--title">{`${category} > ${group}`}</div>
                    {loading ? <Loading size="small" /> : renderSearchResults(category, group)}
                  </div>
                );
              });
            })}
          </div>
        </div>
      ) : (
        <div className="filter-picker--no-results">No results found</div>
      )}
    </>
  );
};

FilterSearchOptions.propTypes = {
  searchResults: PropTypes.object,
  onSelection: PropTypes.func,
  loading: PropTypes.bool,
};

export default FilterSearchOptions;
