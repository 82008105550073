import PropTypes from "prop-types";
import _ from "underscore";
import FormField from "components/forms/form-field";
import CachedContactsResolver from "apps/contact/components/contacts/cached-contacts-resolver";
import ContactTagList from "apps/contact/components/contact-tag-list";
import DNAResolver from "base/dna/dna-resolver";
import Composer from "react-composer";
import HouseholdMemberCheckboxes from "./interaction-form-household-member-checkboxes";

const propTypes = {
  interaction: PropTypes.object,
  handleFormChange: PropTypes.func,
  contactToExclude: PropTypes.object,
  householdMembers: PropTypes.array,
  handleHouseholdMemberSelect: PropTypes.func,
  numSelectedHouseholdMembers: PropTypes.number,
  numAdditionalConstituents: PropTypes.number,
};

const InteractionFormAdditionalConstituents = ({
  interaction = {},
  handleFormChange = () => {},
  contactToExclude = {},
  householdMembers = [],
  handleHouseholdMemberSelect = () => {},
  numSelectedHouseholdMembers = 0,
  numAdditionalConstituents = 0,
}) => {
  /* interaction pulled from DB only has ids so fetch rest of contact for name */
  return (
    <Composer
      components={[
        <DNAResolver DNAKey="note-export-fixed-number-of-secondary-targets" />,
        <CachedContactsResolver ids={_.pluck(interaction.secondary_target, "target_id")} />,
      ]}
    >
      {([{ value: limit = 0 }, { contacts }]) => {
        const limit_text = limit ? ` (${limit - numSelectedHouseholdMembers} Maximum)` : "";

        const selected = interaction.secondary_target.map((existing = {}) => {
          // existing if pulled from autocomplete search
          if (existing.name && existing.id) return existing;
          const { target_id } = existing;
          const my_contact = contacts[target_id];
          return my_contact;
        });

        const showHouseholdCheckboxes = limit >= householdMembers.length;
        const excludedIds = showHouseholdCheckboxes
          ? [contactToExclude.id, ...householdMembers.map((mem) => mem.id)]
          : [contactToExclude.id];

        // hide this if limit is 0. This is default value in DNA
        // coercing b/c comes from DNA as a string
        if (_.toNumber(limit) === 0) return null;

        return (
          <>
            <FormField
              className="interaction-form--tags"
              inlineLabel={false}
              label={`Additional Constituents${limit_text}`}
            >
              <ContactTagList
                contactsFilterFunction={(contact = {}) => !excludedIds.includes(contact.id)}
                selected={selected}
                onSelect={(selectedContacts) => {
                  if (selectedContacts && selectedContacts.length <= limit - numSelectedHouseholdMembers) {
                    handleFormChange("secondary_target", selectedContacts);
                  }
                }}
                disabled={selected.length >= limit}
              />
            </FormField>
            {showHouseholdCheckboxes && (
              <HouseholdMemberCheckboxes
                householdMembers={householdMembers}
                onChange={handleHouseholdMemberSelect}
                disabled={numAdditionalConstituents >= limit}
              />
            )}
          </>
        );
      }}
    </Composer>
  );
};
InteractionFormAdditionalConstituents.propTypes = propTypes;

export default InteractionFormAdditionalConstituents;
