import moment from "moment";

export const mostRecentUpdate = (set, property = "updated_at", format = "MMM DD, YYYY") => {
  const timestamps = set.map((d) => moment(d[property]));
  return moment.max(timestamps).format(format);
};

export const mostRecentCreate = (set, property = "created_at", format = "MMM DD, YYYY") => {
  const timestamps = set.map((d) => moment(d[property]));
  return moment.max(timestamps).format(format);
};

// Sort careers prioritizing by weight, then start date (updated at if date is null)
export const prioritizeCareers = ( careers ) => {
  return careers.sort((a, b) => {          
      if (a.weight === b.weight) {
        const { start_year: a_year, start_month: a_month, start_day: a_day } = a;
        const { start_year: b_year, start_month: b_month, start_day: b_day } = b;

        if (a_year && a_month && a_day && b_year && b_month && b_day) {
          const aDate = new Date(a_year, a_month, a_day);
          const bDate = new Date(b_year, b_month, b_day);

          return  aDate < bDate ? 1 : -1;
        } else return a.created_at < b.created_at ? 1 : -1;
      }
      return a.weight < b.weight ? 1 : -1;
  });
}

// Sort enrichment bio data (e.g. Names and Locations) first by "weight", then by "created_at"
export const prioritizeBioItems = ( items ) => {
  return items.sort((a, b) => {
    if(a.weight === b.weight) {
      return a.created_at < b.created_at ? 1 : -1;
    }
    return a.weight < b.weight ? 1 : -1;
  });
}

// Order that social links are listed, with 1 being the top network
const networkPriorities = {
  "linkedin": 1,
  "facebook": 2,
  "instagram": 3,
  "twitter": 4,
};

export const sortProfiles = ( profiles ) => {
  return profiles.sort((a, b) => {
      if (a.type === b.type){
        if(!a.url) return 1;
        if(!b.url) return -1;
        if (a.weight === b.weight){
          return a.created_at < b.created_at ? 1 : -1;
        } else {
          return a.weight < b.weight ? 1 : -1;
        }
      }
    return networkPriorities[a.type] > networkPriorities[b.type] ? 1 : -1;
  });
};