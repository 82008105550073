import { Component } from "react";
import PropTypes from "prop-types";
import _ from "underscore";
import PoolSolicitorsController from "apps/volunteers/controllers/pool-solicitors-controller";
import PoolProspectsController from "apps/volunteers/controllers/pool-prospects-controller";
import numeral from "numeral";
import { KPICard, Card } from "@evertrue/et-components";

class PoolAssignmentController extends Component {
  static propTypes = {
    pool: PropTypes.object,
    solicitors: PropTypes.object,
  };

  render() {
    const {
      pool: {
        total_dollars_raised,
        pool_participation_rate,
        assigned_prospect_rate,
        total_prospect_count,
        total_solicitor_count,
      },
      pool,
    } = this.props;
    return (
      <div className="pool-assignmenent--body">
        <Card className="pool-detail--reporting">
          <div className="pool-detail--reporting-module">
            <KPICard
              title="Total Dollars"
              value={pool.giving_category_label === "EverTrueNoData" ? "-" : _.currencyFormatter(total_dollars_raised)}
            />
            <KPICard
              title="Participation"
              value={
                pool.giving_category_label === "EverTrueNoData"
                  ? "-"
                  : numeral(pool_participation_rate).format("0[.]0%")
              }
            />
            <KPICard title="Volunteers" value={numeral(total_solicitor_count).format("0,0")} />
            <KPICard title="Prospects" value={numeral(total_prospect_count).format("0,0")} />
            <KPICard title="Assigned" value={numeral(assigned_prospect_rate).format("0%")} />
          </div>
        </Card>
        <div className="pool-detail--manage">
          <div className="pool-detail--volunteers">
            <Card>
              <PoolSolicitorsController solicitors={this.props.solicitors} poolId={pool.id} />
            </Card>
          </div>
          <div className="pool-detail--prospects">
            <Card>
              <PoolProspectsController key={pool.id} pool={pool} />
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

export default PoolAssignmentController;
