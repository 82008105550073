module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   EverTrue = require("app")
   {createComponent} = require("@evertrue/et-flux")
   LoadingMixin = require("mixins/loading-mixin")
   {div} = ReactLibs.DOMFactories
   SessionSource = require("base/session/session-source")
   OrgSelector = require("apps/login/components/org-selector")
   {createFactory} = require("base/new-utils")
   ModalConfirm = createFactory require("components/modals/modal-confirm")
   OrgSource = require("base/org/org-source").default
   resetFiltersEnvironment = require("base/reset-filters-environment").default


   createComponent "ChangeOrganizationView",
      mixins: [LoadingMixin]
      propTypes:
         affiliations: ReactLibs.PropTypes.array

      getInitialState: ->
         {loading: false}

      handleConfirmOrgChange: (org) ->
         resetFiltersEnvironment()
         oid = org.id

         if !oid
            EverTrue.Alert.info "Please select an organization"
         else
            @setState loading: true
            SessionSource.promise
               .renewSession(null, { oid })
               .then (session) =>
                  @setState loading: false
                  { type, oid, organization } = session
                  if type != "SCOPED" or !oid
                     EverTrue.Alert.error "Problem selecting an organization.  Please contact genius@evertrue.com", { timeout: 10000 }
                  else
                     OrgSource.change(organization)
                     _.defer (=> @handleCloseModal())

      handleCloseModal: ->
         if _.isFunction @props.requestHide then @props.requestHide()

      render: ->
         user = EverTrue.store.user?.toJSON()

         div className: "change-org-modal loading-container",

            ModalConfirm
               type: "accent"
               onCancel: @handleCloseModal
               hideOnSubmit: true

               div className: "change-org--wrap",
                  OrgSelector
                     user: user
                     onChange: @handleConfirmOrgChange
