import _ from "underscore";
import { Component } from "react";
import { AdvancedCombobox } from "@evertrue/et-components";
import Api from "entities/helpers/api";

const { array, func, string } = ReactLibs.PropTypes;

class GiftTransactionFacetDropdown extends Component {
  static propTypes = {
    value: array,
    property: string,
    placeholder: string,
    onChange: func,
  };

  static defaultProps = {
    value: [],
    property: "",
    placeholder: "",
    onChange: _.noop,
  };

  state = {
    query: "",
    options: [],
    loading: false,
    error: undefined,
  };

  constructor() {
    super();
    this.debouncedFetchFacets = _.debounce(this.fetchFacets.bind(this), 150);
  }

  componentDidMount() {
    this.mounted = true;
    this.fetchFacets(this.state.query);
  }

  componentWillUnmount() {
    this.mounted = false;
    this.debouncedFetchFacets.cancel();
  }

  handleChange = value => {
    this.props.onChange(value);
  };

  handleSearch = query => {
    // set loading here intead of in fetch so that it shows up as soon as the user types something
    this.setState(state => (state.query !== query ? { query, loading: true } : null));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.query !== this.state.query) {
      this.debouncedFetchFacets(this.state.query);
    }
  }

  fetchFacets(query = "") {
    const search_id = _.uniqueId();
    this.search_id = search_id;

    const data = _.jsonStringify({
      facet: [
        _.compactObject({
          field: this.props.property,
          regex: query ? `^${query}.*|.*\\s${query}.*` : undefined,
        }),
      ],
    });

    Api.SEARCH.GIFTS.post({ data, params: { limit: 0 } })
      .then(resp => {
        if (this.mounted && search_id === this.search_id) {
          const options = resp.facets[this.props.property];
          this.setState({ loading: false, options, error: undefined });
        }
      })
      .catch(err => {
        if (this.mounted && search_id === this.search_id) {
          this.setState({ loading: false, options: [], error: err });
        }
      });
  }

  render() {
    const { value, placeholder } = this.props;
    const { options, query, loading } = this.state;

    const combinedOptions = _.uniq((_.first(options, 100) || []).concat(value), "value");

    const filteredOptions = _.filter(combinedOptions, item => _.searchCompare(item.value, query));

    const dipslayOptions = _.map(filteredOptions, item => ({ label: item.label || item.value, value: item.value }));

    return (
      <AdvancedCombobox
        className="gift-transaction-facet-dropdown"
        options={dipslayOptions}
        value={value}
        multiple
        placeholder={placeholder}
        loading={loading}
        onChange={this.handleChange}
        searchable
        allowChangeAll
        allowUnselectAll
        onSearch={this.handleSearch}
      />
    );
  }
}

export default GiftTransactionFacetDropdown;
