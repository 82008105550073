module.exports = do ->
   React = require("react")
   classNames = require("classnames")
   moment = require("moment")
   {createComponent} = require("@evertrue/et-flux")
   {div, span} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   Icon = createFactory (require("components/elements/icon").default)


   createComponent "NewResultsFilterDisplay",
      propTypes:
         value: ReactLibs.PropTypes.object
         className: ReactLibs.PropTypes.string

      render: ->
         val = @props.value || {}

         # old version of saved search alerter
         min_date = parseFloat(val.created_at?.gte)
         min_date = if min_date then moment(min_date).format("MMM DD, YYYY") else ""

         # old version of saved search alerter
         max_date = parseFloat(val.created_at?.lte)
         max_date = if max_date then moment(max_date).format("MMM DD, YYYY") else ""

         # new version of saved search alerter
         min_date_v2 = parseFloat(val.updated_at?.gte)
         min_date_v2 = if min_date_v2 then moment(min_date_v2).format("MMM DD, YYYY") else ""

         # old version of saved search alerter
         max_date_v2 = parseFloat(val.updated_at?.lte)
         max_date_v2 = if max_date_v2 then moment(max_date_v2).format("MMM DD, YYYY") else ""


         div className: classNames("new-results-filter-display", @props.className),
            Icon icon: "playlist-add"
            # old version of saved search alerter
            if min_date || max_date
               span null,
                  "New Results: "
                  span className: "text-weight-normal",
                     "#{min_date}#{if min_date && max_date then " - "}#{max_date}"
            # new version of saved search alerter
            else if min_date_v2 || max_date_v2
               span null,
                  "New Results: "
                  span className: "text-weight-normal",
                     "#{min_date_v2}#{if min_date_v2 && max_date_v2 then " - "}#{max_date_v2}"
            else "New Results"
