module.exports = do ->
   React = require("react")
   classNames = require("classnames")
   moment = require("moment")
   {span} = ReactLibs.DOMFactories
   AdvancedDropdown = require("components/controls/advanced-dropdown")
   {createFactory} = require("base/new-utils")
   CalendarPicker = createFactory require("components/controls/calendars/calendar-picker").default
   Icon = createFactory (require("components/elements/icon").default)

   DISPLAY_FORMAT = "MMM DD, YYYY"

   # Expects dates in the format YYYY-DD-MM
   CalendarPickerDropdown = ReactLibs.createClass
      propTypes:
         label: ReactLibs.PropTypes.string
         value: ReactLibs.PropTypes.string
         position: ReactLibs.PropTypes.string
         minDate: ReactLibs.PropTypes.string
         maxDate: ReactLibs.PropTypes.string
         disabled: ReactLibs.PropTypes.bool
         focus: ReactLibs.PropTypes.bool
         onChange: ReactLibs.PropTypes.func
         hideClear: ReactLibs.PropTypes.bool

      handleDateChange: (date) ->
         @props.onChange?(date)
         @refs?.dropdown.handleClose?()

      render: ->
         date = moment(@props.value).isValid() && moment(@props.value).format(DISPLAY_FORMAT)

         AdvancedDropdown
            label: @props.label
            ref: "dropdown"
            className: classNames(@props.className, "calendar-picker-dropdown")
            header: span null,
               Icon icon: "date-range", className: "calendar-picker-dropdown--icon"
               date
            disabled: @props.disabled
            onOpen: @handleOpen
            focus: @props.focus

            CalendarPicker
               value: @props.value
               minDate: @props.minDate
               maxDate: @props.maxDate
               onChange: @handleDateChange
               hideClear: @props.hideClear
