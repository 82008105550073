import { useState } from "react";
import PropTypes from "prop-types";
import Api from "entities/helpers/api";
import EverTrue from "app";
import colors from "base/colors";
import moment from "moment";
import numeral from "numeral";
import { ProgressThermometer, ProgressBar, AdvancedCombobox, Icon } from "@evertrue/et-components";
import FeatureStore from "apps/layout/stores/feature-store";
import { useFluxStore } from "@evertrue/et-flux";
import { getHumanReadableTimeFromNow } from "apps/proposals/components/proposal-form-utils";
import { ProposalDetailCustomField } from "./proposal-detail-custom-field";
import { askAmounts } from "../utils";

const featureStoreMapState = () => ({
  isDesignationInDollars: FeatureStore.hasFeature("proposal_designation_dollars"),
});

const ProposalDetailAmounts = ({
  proposal,
  setProposal,
  proposalStages = [],
  hasProposalWrites = false,
  hasProposalReporting = false,
}) => {
  const [loading, setLoading] = useState(false);
  const { isDesignationInDollars } = useFluxStore(FeatureStore, featureStoreMapState);
  const totalSteps = proposalStages.length;
  const currentStep = 1 + proposalStages.findIndex((stage) => stage.value === proposal.status);
  const customFields = proposal.custom_fields ? proposal.custom_fields.sort((a, b) => a.sort_order - b.sort_order) : [];
  const activeStage = proposalStages.find((stage) => stage.value === proposal.status);
  const activeMappedStage = activeStage && askAmounts.find((askAmount) => askAmount.key === activeStage.mappedAmount);
  const formatDollars = (amount) => (amount || amount === 0 ? numeral(amount).format("$0,0") : "—");
  const formatPercent = (amount) => (amount || amount === 0 ? numeral(amount).format("0.00") + "%" : "—");
  const formatAskDate = (date) => (date ? moment(date).format("MMM DD, YYYY") : "—");

  /**
   * Update proposal right away for UX. Then update again with api response to ensure state is in sync
   * and revert change in catch block if error.
   */
  const handleStageChange = (stage) => {
    setLoading(true);
    const prevStage = proposal.status;

    setProposal({
      ...proposal,
      status: stage,
    });

    Api.UGC.PROPOSAL_STAGE.put({
      urlArgs: { proposal_id: proposal.id },
      params: { status: stage },
    })
      .then((updatedProposal) => {
        setProposal(updatedProposal);
        setLoading(false);
        EverTrue.Alert.success("Stage updated successfully");
        EverTrue.track.set("proposal_action", { type: "edit_proposal" });
      })
      .catch(() => {
        setProposal({
          ...proposal,
          status: prevStage,
        });
        setLoading(false);
        EverTrue.Alert.success("There was a problem updating stage. Please try again.");
      });
  };

  return (
    <>
      {proposalStages.length > 1 && (
        <div className="proposal-detail--stages-section">
          <div className="proposal-detail--select-stage">
            {hasProposalWrites ? (
              <AdvancedCombobox
                label={proposal.status ? proposal.status + " stage selected" : "Select stage"}
                options={proposalStages}
                value={proposalStages.find((stage) => stage.value === proposal.status)}
                placeholder="Select a stage..."
                onChange={(stage) => handleStageChange(stage.value)}
                compact
                disabled={loading}
              />
            ) : (
              <strong>{proposal.status}</strong>
            )}
          </div>

          <ProgressThermometer totalSteps={totalSteps} currentStep={currentStep} />

          {proposal.status_start_date && (
            <div className="proposal-detail--time-in-stage">
              time in stage: {getHumanReadableTimeFromNow(proposal.status_start_date)}
            </div>
          )}
        </div>
      )}

      {activeMappedStage && (
        <>
          <div className="proposal-detail--heading">{activeMappedStage.label}</div>
          <div className="proposal-detail--amounts-row">
            <div>{formatAskDate(proposal[activeMappedStage.date])}</div>
            <div className="proposal-detail--mapped-amount">{formatDollars(proposal[activeMappedStage.amount])}</div>
          </div>
        </>
      )}

      {/* Loop through ask amounts and display them unless they are mapped to the active stage in which case show above */}
      {askAmounts.map(
        (askAmount) =>
          (!activeMappedStage || activeMappedStage.key !== askAmount.key) && (
            <div key={askAmount.key}>
              <div className="proposal-detail--heading">{askAmount.label}</div>
              <div className="proposal-detail--amounts-row">
                <div>{formatAskDate(proposal[askAmount.date])}</div>
                <div className="proposal-detail--amount">{formatDollars(proposal[askAmount.amount])}</div>
              </div>
            </div>
          )
      )}

      <div className="proposal-detail--heading">Designations</div>
      {proposal.proposal_designations && proposal.proposal_designations.length ? (
        proposal.proposal_designations.map((designation) => (
          <div key={designation.proposal_designation_id} className="proposal-detail--amounts-row">
            <div>{designation.name}</div>
            <div className="proposal-detail--amount">
              {isDesignationInDollars ? formatDollars(designation.value) : formatPercent(designation.value)}
            </div>
          </div>
        ))
      ) : (
        <div className="proposal-detail--light-text">No designations selected</div>
      )}

      <div className="proposal-detail--heading">Confidence</div>
      {proposal.confidence_score ? (
        <div className="proposal-detail--confidence-score">
          <div className="proposal-detail--confidence-score-percent">{proposal.confidence_score}%</div>
          <ProgressBar progressColor={colors.green200} percent={proposal.confidence_score} />
        </div>
      ) : (
        <div className="proposal-detail--light-text">No confidence score provided</div>
      )}

      {hasProposalReporting && (
        <>
          <div className="proposal-detail--heading">Launchpad</div>
          {proposal.reported ? (
            <>
              <Icon size={1} icon="check" className="proposal-detail--reporting-true" /> YES
            </>
          ) : (
            <>
              <Icon size={1} icon="close" className="proposal-detail--reporting-false" /> NO
            </>
          )}
        </>
      )}

      {customFields.map((field) => (
        <div key={field.custom_field_id}>
          <div className="proposal-detail--heading">{field.display_name}</div>
          <ProposalDetailCustomField customField={field} />
        </div>
      ))}
    </>
  );
};

ProposalDetailAmounts.propTypes = {
  proposal: PropTypes.object.isRequired,
  setProposal: PropTypes.func.isRequired,
  proposalStages: PropTypes.array,
  hasProposalWrites: PropTypes.bool,
  hasProposalReporting: PropTypes.bool,
};

export default ProposalDetailAmounts;
