import { useGate } from "components/is-gated";
import { useState } from "react";
import { PropTypes } from "base/react-libs";
import { trackOutreachAction } from "apps/outreach/utils/utils";
import { ThreeDotActionsMenu } from "@evertrue/et-components";
import Drawer from "components/drawer/drawer";
import Dialer from "components/dialer/dialer";
import useDrawer from "components/drawer/use-drawer";
import EmailerController from "components/emailer/emailer-controller";
import MoveToJourneyModal from "apps/journeys/components/modals/move-to-journey-modal";
import RemoveFromJourneyModal from "apps/journeys/components/modals/remove-from-journey-modal";

const ActiveConstituentActions = ({
  journey = {},
  contactJourney = {},
  optionalStateManagementFunction = () => {},
}) => {
  const [hasEmail] = useGate("outreach_email");
  const [hasSignal] = useGate("signal");
  const [drawerTitle, setDrawerTitle] = useState("New Message");
  const [closePrevDrawer, setClosePrevDrawer] = useState({ close: () => {} });

  const { contact } = contactJourney;
  let primaryEmail, phoneList, fullName, contactId;
  if (contact) {
    const { id, phones = [], contact_attributes = {}, emails = [] } = contact;
    const email = emails && emails.filter((email) => email.primary);
    if (email && email[0]) primaryEmail = email[0].email;
    fullName = contact_attributes ? contact_attributes.name_full : "";
    phoneList = phones;
    contactId = id;
  }

  const {
    openDrawer: openPhoneDrawer,
    closeDrawer: closePhoneDrawer,
    isMinimized: phoneIsMinimized,
    toggleMinimize: togglePhoneIsMinimize,
    isOpen: phoneIsOpen,
  } = useDrawer();

  const {
    openDrawer: openEmailDrawer,
    closeDrawer: closeEmailDrawer,
    isMinimized: emailIsMinimized,
    toggleMinimize: toggleEmailIsMinimize,
    isOpen: emailIsOpen,
  } = useDrawer();

  const phoneAction = {
    id: 1,
    onClick: () => {
      closePrevDrawer.close();
      openPhoneDrawer();
      trackOutreachAction("open_dialer");
      setClosePrevDrawer({ close: closePhoneDrawer });
    },
    label: "Call Constituent",
    icon: "call",
  };

  const emailAction = {
    id: 2,
    onClick: () => {
      closePrevDrawer.close();
      openEmailDrawer();
      trackOutreachAction("open_emailer");
      setClosePrevDrawer({ close: closeEmailDrawer });
    },
    label: "Email Constituent",
    icon: "email",
  };

  const moveToCadenceAction = {
    id: 1,
    mountOnClick: ({ is_open, close }) => (
      <MoveToJourneyModal
        currentJourney={journey}
        isOpen={is_open}
        onClose={close}
        contact={contact}
        optionalStateManagementFunction={optionalStateManagementFunction}
      />
    ),
    label: "Move to Another Cadence",
    icon: "swap-horizontal",
    iconKit: "gt2",
  };

  const removeAction = {
    id: 2,
    mountOnClick: ({ is_open, close }) => (
      <RemoveFromJourneyModal
        onClose={close}
        isOpen={is_open}
        contact={contact}
        currentJourneys={[{ journey_name: journey.name, journey_id: journey.id }]} //matching active_journeys object
        optionalStateManagementFunction={optionalStateManagementFunction}
      />
    ),
    label: "Remove from Cadence",
    icon: "cancel-circle",
  };

  const contactOptions = {
    id: 1,
    heading: "Contact",
    items: [],
  };

  if (hasSignal) contactOptions.items.push(phoneAction);
  if (hasEmail) contactOptions.items.push(emailAction);

  const cadenceOptions = {
    id: 2,
    heading: "Cadence",
    items: [moveToCadenceAction, removeAction],
  };

  return (
    <>
      <ThreeDotActionsMenu
        options={hasSignal || hasEmail ? [contactOptions, cadenceOptions] : [cadenceOptions]}
        iconSize={1}
      />
      <Drawer
        title={drawerTitle || "New Message"}
        className="emailer-drawer"
        isMinimized={emailIsMinimized}
        isOpen={emailIsOpen}
        toggleMinimize={toggleEmailIsMinimize}
        close={closeEmailDrawer}
      >
        <EmailerController
          emailAddress={primaryEmail}
          handleClose={closeEmailDrawer}
          setDrawerTitle={setDrawerTitle}
          contactId={contactId}
        />
      </Drawer>
      <Drawer
        isOpen={phoneIsOpen}
        toggleMinimize={togglePhoneIsMinimize}
        isMinimized={phoneIsMinimized}
        close={closePhoneDrawer}
        title={fullName}
      >
        <Dialer contact={contact} name={fullName} phones={phoneList} />
      </Drawer>
    </>
  );
};

ActiveConstituentActions.propTypes = {
  journey: PropTypes.object.isRequired,
  contactJourney: PropTypes.object.isRequired,
  optionalStateManagementFunction: PropTypes.func,
};

export default ActiveConstituentActions;
