import EverTrue from "app";
import BaseRouter from "base/base-router";
import BookmarkedSearchController from "apps/search/controllers/bookmarked-search-controller";

class SearchRouter extends BaseRouter {
  static initClass() {
    this.prototype.routes = {
      "search/:id": "bookmarked",
      "search/map/:id": "map",
    };
  }

  bookmarked(id) {
    EverTrue.execute("setBrowserTitle", "Bookmarked Search");
    EverTrue.page.mount(<BookmarkedSearchController id={id} showMap={false} />);
  }

  map(id) {
    EverTrue.execute("setBrowserTitle", "Bookmarked Search");
    EverTrue.page.mount(<BookmarkedSearchController id={id} showMap={true} />);
  }
}

SearchRouter.initClass();
export default SearchRouter;
