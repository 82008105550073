import Api from "entities/helpers/api";
import EverTrue from "app";
import ErrorLogger from "entities/helpers/error-log-helper";

export const getUserSettings = async () => {
  return await Api.JOURNEYS.USER_SETTINGS.get({
    success: (res) => {
      return res;
    },
    error(e) {
      ErrorLogger.error("User Settings GET API error.", { extra: { e, page_name: "home_page" } });
      return EverTrue.Alert.error("Unable to fetch user settings");
    },
  });
};

export const createUserSettings = async (payload) => {
  return await Api.JOURNEYS.USER_SETTINGS.post({
    data: JSON.stringify(payload),
    success: (response) => {
      EverTrue.Alert.success("User Settings created");
      return response;
    },
    error(e) {
      ErrorLogger.error("User settings CREATE API error.", { extra: { e, page_name: "home_page" } });
      return EverTrue.Alert.error("User Settings creation unsuccessful. Please try again.");
    },
  });
};

export const updateUserSettings = async (payload, id) => {
  return await Api.JOURNEYS.EDIT_USER_SETTINGS.put({
    urlArgs: { id: id },
    data: JSON.stringify(payload),
    success: (response) => {
      EverTrue.Alert.success("User Settings updated");
      return response;
    },
    error(e) {
      ErrorLogger.error("User Settings UPDATE API error.", { extra: { e, page_name: "home_page" } });
      return EverTrue.Alert.error("User Settings updation unsuccessful. Please try again.");
    },
  });
};
