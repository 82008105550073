import { PropTypes } from "base/react-libs";
import RecommendedActionSection from "./recommended-action-section";

const CareerMovesRecommendedActions = ({ enabled, recommendedActions = [] }) => {
  if (!enabled) return null;
  return (
    <RecommendedActionSection
      title="Career Moves in past 3 months"
      subtitle="Promotion"
      recommendedActions={recommendedActions}
      icon={"work"}
      iconKit="gt"
      type="career"
      emailButtonLabel="Say Congrats"
    />
  );
};

CareerMovesRecommendedActions.propTypes = {
  enabled: PropTypes.bool,
  recommendedActions: PropTypes.array,
};

export default CareerMovesRecommendedActions;
