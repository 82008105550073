import PropTypes from "prop-types";
import TripForm from "apps/my-trips/components/trip-form";
import LocalMeetingForm from "apps/my-trips/components/local-meeting-form";
import PhoneMeetingForm from "apps/my-trips/components/phone-meeting-form";
import VirtualMeetingForm from "apps/my-trips/components/virtual-meeting-form";
import ActionMenuItem from "components/controls/action-menu-item";
import CalendarContainer from "apps/my-trips/components/cronofy-calendar-container";
import { useGate } from "components/is-gated";

const MyTripsActions = ({ current_user_platform_data }) => {
  const [has_manage_calendar_sync] = useGate("manage_calendar_sync");

  return (
    <div className="my-trips-actions">
      <ActionMenuItem title="Create Trip" icon="map-pin" modal={<TripForm navigateToTrip={true} />}>
        Create Trip
      </ActionMenuItem>

      <ActionMenuItem title="Create Local Meeting" icon="group" modal={<LocalMeetingForm navigateToTrip={true} />}>
        Create Local Meeting
      </ActionMenuItem>

      <ActionMenuItem
        title="Create Virtual Meeting"
        icon="desktop-mac"
        modal={<VirtualMeetingForm navigateToTrip={true} current_user_platform_data={current_user_platform_data} />}
      >
        Create Virtual Meeting
      </ActionMenuItem>

      <ActionMenuItem title="Create Phone Meeting" icon="call" modal={<PhoneMeetingForm navigateToTrip={true} />}>
        Create Phone Meeting
      </ActionMenuItem>

      {has_manage_calendar_sync && (
        <ActionMenuItem title="Manage External Calendar" icon="settings" href="/trips/calendar">
          <CalendarContainer
            render={({ hasCalendars }) => {
              const text = hasCalendars ? "Manage" : "Connect";
              return <span>{`${text} External Calendar`}</span>;
            }}
          />
        </ActionMenuItem>
      )}
    </div>
  );
};

MyTripsActions.propTypes = {
  current_user_platform_data: PropTypes.any,
};

export default MyTripsActions;
