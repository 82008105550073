module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   {div} = ReactLibs.DOMFactories
   LoadingMixin = require("mixins/loading-mixin")
   {createFactory} = require("base/new-utils")
   Button = createFactory(require("@evertrue/et-components").Button)

   FacebookEmbeddedPost = ReactLibs.createClass
      displayName: "FacebookEmbeddedPost"
      mixins: [LoadingMixin]
      propTypes:
         url: ReactLibs.PropTypes.string
         post_id: ReactLibs.PropTypes.string
         silent: ReactLibs.PropTypes.bool
         width: ReactLibs.PropTypes.oneOfType [
            ReactLibs.PropTypes.string
            ReactLibs.PropTypes.number
         ]

      componentDidUpdate: ->
         FB?.XFBML.parse() unless @props.silent

      componentDidMount: ->
         FB?.XFBML.parse() unless @props.silent

      UNSAFE_componentWillUpdate: ->
         unless @props.silent
            url = @props.url
            FB?.Canvas.Prefetcher.addStaticResource(url) if url

      shouldComponentUpdate: (nextProps) ->
         !_.isEqual(@props, nextProps)

      action: ->
         if @props.post_id
            [page_id, post_id] = @props.post_id.split("_")
            link = "/facebook/#{page_id}/posts/#{post_id}"

            div className: "facebook-post--actions",
               Button type: "secondary", href: link, title: "View Post Engagers", "data-refer": "$last", "View Post Engagers"

      render: ->
         width = {width: this.props.width || 500}
         if !@props.url
            div className: "facebook-post-container is-empty", style: width,
               @action()
         else
            div className: "facebook-post-container", style: width,
               div
                  ref: "post"
                  className: "fb-post"
                  "data-href": @props.url
                  "data-width": @props.width || 500
               @action()
