module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   {div, span, h1} = ReactLibs.DOMFactories
   {createComponent} = require("@evertrue/et-flux")
   FilterDrawer = require("components/controls/filter-drawer")
   FacebookFilterPanel = require("apps/facebook/components/facebook-filter-panel")
   {createFactory} = require("base/new-utils")
   FacebookSearchSummary = createFactory require("apps/facebook/components/facebook-search-summary")
   FacebookPagesStore = require("apps/facebook/stores/facebook-pages-store")
   ActiveFiltersHeader = require("apps/facebook/components/active-filters-header")
   ActionMenuItem = createFactory(require("components/controls/action-menu-item").default)
   ActionMenu = createFactory(require("components/controls/action-menu2").default)


   CONSTITUENT_FILTERS = {
      lifetime_giving: "Lifetime Giving"
      location: "Location"
      assignee: "Assignment"
      last_gift_date: "Last Gift Date"
      grad_year: "Graduation Year"
   }

   createComponent "FacebookHeader",
      propTypes:
         active: ReactLibs.PropTypes.string
         total: ReactLibs.PropTypes.number
         onSearch: ReactLibs.PropTypes.func
         onReset: ReactLibs.PropTypes.func
         filters: ReactLibs.PropTypes.any
         isDisabled: ReactLibs.PropTypes.bool

      getDefaultProps: ->
         isDisabled: false

      getInitialState: ->
         filters: _.cloneData @props.filters
         shouldOpen: false

      registerStores: ->
         @on FacebookPagesStore, -> # FacebookSource.fetchPages called when DNA loaded
            pages: FacebookPagesStore.getPages()

      UNSAFE_componentWillReceiveProps: (newProps) ->
         if !_.isEqual(@state.filters, newProps.filters) and newProps.filters
            @setState filters: newProps.filters

      handleSearch: ->
         @props.onSearch(_.compactObject(_.clone(@state.filters)))
         @setState shouldOpen: false

      handleToggleFilter: ->
         @setState shouldOpen: true

      onToggle: (next_visible) ->
         @setState shouldOpen: next_visible

      onCancel: ->
         @setState shouldOpen: false

      renderHeader: ->
         active_text = switch @props.active
            when "posts" then "Posts"
            when "interactions" then "Engagements"
            else "Pages"
         active_constituent_filters = _.values _.filter CONSTITUENT_FILTERS, (value, key) =>
            _.has(@props.filters, key)

         div className: "facebook-header--subheader",
            div className: "facebook-header--title",
               h1 className: "facebook-header--text", tabIndex: -1, "Facebook #{active_text}"

               if @props.active is "interactions" and active_constituent_filters.length
                  span null,
                     ActiveFiltersHeader
                        filter_labels: active_constituent_filters
                        onClick: @handleToggleFilter

               div null,
                  FacebookSearchSummary
                     total: @props.total
                     page_filters: _.pluck(@props.filters?.fb_pages, "value")
                     pages: _.map @state.pages, (value) ->
                        {value: value.remote_id, label: value.page_name}
                     items: active_text
                     query: @props.filters?.contents
                     date: @props.filters?.date
               ActionMenu buttonClass: "facebook-header--actions inline",
                  ActionMenuItem
                     icon: "settings"
                     href: "/facebook/accounts"
                     title: "Manage Facebook Accounts"
                     "Manage Facebook Accounts"

      render: ->
         div className: "facebook-header",
            FilterDrawer
               canApply: true
               header: @renderHeader()
               onApply: @handleSearch
               onReset: if !_.isEmpty(@state.filters) then @props.onReset
               onToggle: @onToggle
               onCancel: @onCancel
               shouldOpen: @state.shouldOpen
               isDisabled: @props.isDisabled

               FacebookFilterPanel
                  activeType: @props.active
                  filters: @state.filters
                  onChange: (value) =>
                     @setState {filters: value}
