import { useState, useEffect } from "react";
import { useGate } from "components/is-gated";
import EverTrue from "app";
import useDataStream from "entities/helpers/use-data-stream.js";

const useCallDescription = (callNotes, taskTitle, journey, contact) => {
  const [enabledAI] = useGate("ai_summary");

  const { openDataStream, isStreaming, response } = useDataStream();
  const [callDescription, setCallDescription] = useState("");

  useEffect(() => {
    const getData = async () => {
      if (enabledAI) {
        try {
          const query = {
            prompt: {
              key: "cadences/call_description",
              args: {
                conversation_details: callNotes,
                task_title: taskTitle,
                journey,
                contact,
              },
            },
          };
          const options = {
            method: "POST",
            endpoint: `ai/v1/completions/`,
            params: {
              append_request_id: "false",
            },
            headers: {
              "Content-Type": "application/json; charset=UTF-8",
              "ET-Request-ID": `call_description`,
            },
            data: JSON.stringify(query),
          };
          await openDataStream(options);
        } catch (err) {
          EverTrue.Alert.error("AI email generation failed.");
        }
      }
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabledAI]);

  useEffect(() => {
    setCallDescription((summary) => {
      return (summary += response);
    });
  }, [response]);
  return { callDescription, isStreaming };
};

export default useCallDescription;
