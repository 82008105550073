import { Component } from "react";
import _ from "underscore";
import PropTypes from "prop-types";
import { LinkButton } from "@evertrue/et-components";

const getHour = moment_value => {
  const hour = moment_value.hour();
  if (hour === 0) {
    return 12;
  } else {
    return hour > 12 ? hour - 12 : hour;
  }
};

class TimePicker extends Component {
  static propTypes = {
    value: PropTypes.any, // expects value to be a moment.js moment
    onClose: PropTypes.func,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    onClose: _.noop,
    onChange: _.noop,
  };

  state = {
    hour: getHour(this.props.value),
    min: this.props.value.minute(),
    is_am: this.props.value.hour() >= 12 ? false : true,
  };

  componentDidMount() {
    // scroll hour into view
    var hourElement = document.getElementById("chosenHour");
    if (hourElement) {
      hourElement.scrollIntoView(true);
    }
    // scroll minute into view
    var minElement = document.getElementById("chosenMin");
    if (minElement) {
      minElement.scrollIntoView(true);
    }
  }

  handleChange = (key, value) => {
    const updated_state = _.cloneData(this.state);
    updated_state[key] = value;

    const { hour, min, is_am } = updated_state;
    // if its after 12pm add 12 hours to turn into 24 hour format
    // but if its 12am then subtract 12
    let new_hour = is_am || hour === 12 ? hour : hour + 12;
    if (hour === 12 && is_am) {
      new_hour = 0;
    }
    const new_time = this.props.value.hour(new_hour);
    new_time.minute(min);

    this.props.onChange(new_time);
    this.setState(updated_state);
  };

  render() {
    const hours = _.range(1, 13);
    const minutes = _.range(0, 60);

    return (
      <div className="time-picker">
        <div className="time-picker--cols">
          <ul className="time-picker--col">
            {_.map(hours, hour => {
              return (
                <li
                  key={hour}
                  className={`time-picker--item ${hour === this.state.hour ? "is-selected" : ""}`}
                  role="button"
                  id={hour === this.state.hour ? "chosenHour" : ""}
                  tabIndex="0"
                  aria-label={`Select Hour: ${hour}`}
                  title={`Select Hour: ${hour}`}
                  onClick={() => this.handleChange("hour", hour)}
                >
                  {hour < 10 ? `0${hour}` : hour}
                </li>
              );
            })}
          </ul>
          <ul className="time-picker--col">
            {_.map(minutes, min => {
              return (
                <li
                  key={min}
                  id={min === this.state.min ? "chosenMin" : ""}
                  className={`time-picker--item ${min === this.state.min ? "is-selected" : ""}`}
                  role="button"
                  tabIndex="0"
                  aria-label={`Select Minute: ${min}`}
                  title={`Select Minute: ${min}`}
                  onClick={() => this.handleChange("min", min)}
                >
                  {min < 10 ? `0${min}` : min}
                </li>
              );
            })}
          </ul>
          <ul className="time-picker--col">
            <li
              role="button"
              tabIndex="0"
              aria-label="Select am"
              title="Select am"
              className={`time-picker--item ${this.state.is_am ? "is-selected" : ""}`}
              onClick={() => this.handleChange("is_am", true)}
            >
              am
            </li>
            <li
              role="button"
              tabIndex="0"
              aria-label="Select pm"
              title="Select pm"
              className={`time-picker--item ${!this.state.is_am ? "is-selected" : ""}`}
              onClick={() => this.handleChange("is_am", false)}
            >
              pm
            </li>
          </ul>
        </div>
        <div className="time-range--done">
          <LinkButton title="Close time picker" onClick={this.props.onClose}>
            Done
          </LinkButton>
        </div>
      </div>
    );
  }
}

export default TimePicker;
