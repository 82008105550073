module.exports = do ->
   _ = require("underscore").default
   {createComponent} = require("@evertrue/et-flux")
   React = require("react")
   {div, img} = ReactLibs.DOMFactories
   {any, func, string, bool, array} = ReactLibs.PropTypes
   {createFactory} = require("base/new-utils")
   AdvancedCombobox = createFactory(require("@evertrue/et-components").AdvancedCombobox)
   Checkbox = createFactory(require("@evertrue/et-components").Checkbox)
   classNames = require("classnames")


   createComponent "ComboboxWithImages",
      propTypes:
         options: array
         value: any
         onChange: func
         renderIcon: func
         renderLabel: func
         multiple: bool
         placeholder: string
         loading: bool
         searchable: bool
         onSearch: func
         allowSelectAll: bool


      formatItem: (item={}, isSelected, isHovered) ->
         {renderIcon, renderLabel} = @props
         div className: "combobox-with-images--item",
            Checkbox className: "et--checkbox-small", checked: isSelected, value: item
            div className: "combobox-with-images--icon",
               if _.isFunction renderIcon
                  renderIcon(item)
               else
                  img src: item.icon

            div className: "combobox-with-images--label",
               if _.isFunction renderLabel
                  renderLabel(item)
               else
                  item.label

      render: ->
         AdvancedCombobox
            className: classNames("combobox-with-images", @props.className)
            options: @props.options
            value: @props.value
            multiple: @props.multiple
            placeholder: @props.placeholder
            loading: @props.loading
            onChange: @props.onChange
            formatItem: @formatItem
            searchable: @props.searchable
            allowChangeAll: @props.allowSelectAll
            allowUnselectAll: @props.allowSelectAll
            onSearch: @props.onSearch
