import { Device } from "@twilio/voice-sdk";
import { createAction } from "apps/tasks/utils/tasks-actions";
import { setTimestampToMidnightAndRemoveMilliseconds } from "apps/journeys/utils/helpers/dates";
import Api from "entities/helpers/api";
import EverTrue from "app";
import ErrorLogger from "entities/helpers/error-log-helper";

export const establishTwilioDevice = async () => {
  // Fetch unique token and instantiate Device
  const { token } = await Api.OUTREACH.VOIP_TOKEN.get();
  const twilioDevice = await new Device(token);

  // EVENT LISTENERS

  // If there is an error, show a message to user and log it in Sentry
  twilioDevice.on("error", (error) => {
    EverTrue.Alert.error("Sorry, there was an issue with our Caller integration. Please try again soon.", {
      id: 4,
      timeout: 10000,
    });
    ErrorLogger.error("Twilio.Device Error", { extra: { error, page_name: "dialer" } });
  });

  // in the event tokens expire, refresh token
  twilioDevice.on("tokenWillExpire", async () => {
    const { token } = await Api.OUTREACH.VOIP_TOKEN.get();
    twilioDevice.updateToken(token);
  });

  return twilioDevice;
};

export const createActionFromCall = async (callObject, startTime, contactId, oid, userId) => {
  const todayDate = setTimestampToMidnightAndRemoveMilliseconds(startTime);
  const endTime = new Date().getTime();

  const { _isAnswered: answered, _wasConnected: wasConnected } = callObject;
  const twoWayCommunication = !!(answered && wasConnected);
  let durationInSeconds = 0;
  if (twoWayCommunication) {
    durationInSeconds = Math.floor((endTime - startTime) / 1000);
  }

  const action = {
    date_occurred: todayDate,
    outreach_method: "PHONE",
    outreach_tech_status: "success",
    outreach_result: "success",
    outreach_source: "Twilio",
    inbound: false, // we are making an outbound call
    two_way_communication: twoWayCommunication,
    duration_in_seconds: durationInSeconds,
    creator_user_id: userId,
    completed_by_id: userId,
    updater_user_id: userId, // for some reason this is required by the db
    created_at: endTime, // for some reason this is required by the db
    oid,
    contact_id: contactId,
  };

  await createAction(action);
};
