module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   str = require("underscore.string")
   numeral = require("numeral")
   # Adding new methods as mixins is depracated, use new-utils.js


   # Wrap str with <span class="className" />
   # Use HighlightText component where possible instead of this
   $.fn.highlight = (str, className, search_html) ->
      if _.isArray(str)
         regex_str = _.map(str, (val) -> _.regexEscape(val)).join("|")
      else
         regex_str = _.map(str.split("|"), (val) -> _.regexEscape(val)).join("|")
      regex = new RegExp(regex_str, "gi")

      @each ->
         if search_html
            content = $(@).html()
         else
            content = $(@).text()
         @innerHTML = content.replace regex, (matched) ->
            "<span class=\"" + className + "\">" + matched + "</span>"

   # Call on body element and pass in selected element selector
   # For autocomplete dropdowns to scroll to highlighted element in list

   originalRange = _.range
   originalFirst = _.first

   $.fn.scrollToHighlight = (selector) ->
      $body = $(@)
      if $body.length
         $highlighted = $body.find(selector)

         maxHeight = parseInt($body.css("maxHeight"), 10)
         visible_top = $body.scrollTop()
         visible_bottom = maxHeight + visible_top

         # Expects $body to be positioned
         high_top = $highlighted.position()?.top + visible_top
         high_bottom = high_top + $highlighted.outerHeight()

         if high_bottom >= visible_bottom
            top = if (high_bottom - maxHeight) > 0 then (high_bottom - maxHeight) else 0
            $body.scrollTop(top)
         else if high_top < visible_top
            $body.scrollTop(high_top)

   # Extend Underscore with additional methods

   _.mixin
      jsonParse: (data, fail_default) ->
         try
            JSON.parse(data)
         catch e
            fail_default

      jsonStringify: (data) ->
         try
            JSON.stringify(data)
         catch
            try
               JSON.stringify("" + data)
            catch
               ""

      regexEscape: (str) ->
         return unless str
         str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&") # escape RegExp

      searchCompare: (str, query) ->
         !query || str?.toLowerCase().match(/// \b#{_.regexEscape(query?.toLowerCase())} ///)

      deepArrayCompare: (arr1, arr2, options={}) ->
         if options.exclude
            arr1 = _.map arr1, (arr) -> _.omit(arr, options.exclude)
            arr2 = _.map arr2, (arr) -> _.omit(arr, options.exclude)
         _.jsonStringify(arr1) != _.jsonStringify(arr2)

      compactObject: (object) ->
         obj = {}
         _.each object, (value, key) ->
            unless _.isUndefined(value) || _.isNull(value)
               obj[key] = value
         obj

      randomKey: (id) ->
         key = Math.round(Math.random() * 100000)
         key + "_#{id}" if id
         key

      randomHex: ->
         "#"+((1<<24)*Math.random()|0).toString(16)

      toNumber: (str) ->
         parseInt(str, 10)

      average: (list) ->
         _.sum(list) / _.size(list)

      sum: (list, fn) ->
         parse = if _.isFunction(fn) then ((n) -> parseFloat fn n) else parseFloat
         _.reduce list, ((memo, item) -> memo + (parse(item) || 0)), 0

      isOdd: (num) ->
         return num % 2

      withinRange: (number, min, max) ->
         Math.min(Math.max(min, number), max)

      pastTense: (string) ->
         # TODO - add list of irregular verbs
         lastChar = string.charAt(string.length - 1)
         if lastChar is "y"
            string.slice(0, -1) + "ied"
         else if lastChar is "e"
            string + "d"
         else string + "ed"

      plural: (count, singular_string, plural_string = "") ->
         if count == 1
            singular_string
         else
            if plural_string
               plural_string
            else
               _.pluralize(singular_string)

      pluralize: (string) ->
         lastChar = string.charAt(string.length - 1)
         if lastChar is "y"
            # If the y has a vowel before it (i.e. toys), then you just add the s.
            # Otherwise a this ends in y with a consonant before it (fly), you drop the y and add -ies to make it plural.
            if string.charAt(string.length - 2) in ["a", "e", "i", "o", "u"] then string + "s"
            else string.slice(0, -1) + "ies"
         else if string.substring(string.length - 1) is "us"
            # // ends in us -> i, needs to preceed the generic "s" rule
            string.slice(0, -2) + "i"
         else if ["ch", "sh"].indexOf(string.substring( string.length - 2)) != -1 || ["x","s"].indexOf(lastChar) != -1
            # // If a this ends in ch, sh, x, s, you add -es to make it plural.
            string + "es"
         else string + "s"

      pluralizePossession: (string) ->
         return if _.isEmpty(string.trim())
         lastChar = string.charAt(string.length - 1)
         if lastChar == "s" then string + "'"
         else string + "'s"

      makeArray: (element) ->
         if _.isArray(element) then element else [element]

      censusFormatter: (value) ->
         if value >= 1000000
            numeral(value).format("$0ak").toUpperCase() + "+"
         else if value || value == 0
            numeral(value).format("$0,0")
         else
            "Not Available"

      currencyFormatter: (num) ->
         if num >= 1000000
            numeral(num).format("$0.00a")
         else
            numeral(num).format("$0,0")

      convertDateString: (date_string) ->
         return if _.isEmpty(date_string)
         [month, date, year] = date_string.split("/")
         "#{year}-#{month}-#{date}"

      reverseSortBy: (sortfn) ->
         fn = (left, right) ->
            l = sortfn(left)
            r = sortfn(right)
            return -1 if l is undefined
            return 1 if r is undefined
            (if l < r then 1 else (if l > r then -1 else 0))

      coercedSortBy: (list, sortProp, reverse) ->
         sorted = _.sortBy list, (item) ->
            item[sortProp] || 0
         if reverse then sorted.reverse() else sorted

      validateEmail: (email) ->
         regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
         regex.test(email)

      toObject: (key, value, obj = {}) ->
         if key? then obj[key] = value
         obj

      mergeHashData: (current_obj, new_obj) ->
         _.each new_obj, (new_data, new_key) ->
            current_obj[new_key] = _.extend(current_obj[new_key] || {}, new_data)
         current_obj

      # TODO: Move into a type check module
      isPagedObject: (data) ->
         _.isObject(data) && !_.isArray(data) && _.isNumber(data.limit) && _.isNumber(data.offset) && _.isNumber(data.total) && _.isArray(data.items)

      push: (array = [], items...) ->
         for item in items then array.push(item)
         return array

      isArrayEqual: (array1, array2) ->
         if array1?.length != array2?.length
            return false
         else if !_.isEmpty(_.difference(array1, array2))
            return false
         else if !_.isEmpty(_.difference(array2, array1))
            return false
         else
            return true

      urlInfo: (url = "") ->
         link = document.createElement("a")
         link.href = url
         env: (try link.hostname.split(".").reverse()[2].split("-").reverse()[1])
         endpoint: link.pathname
         params: $.deparam(link.search.slice(1))

      notEmpty: (something) -> !_.isEmpty(something)

      eachWithObject: (collection, iteratee, object = {}) ->
         _.each collection, (value, index, collection) ->
            iteratee(object, value, index, collection)
         return object

      cloneData: (item) ->
         # Functions and arrays return true for _.isObject(...)
         if !_.isObject(item) || _.isFunction(item) then item
         else if _.isArray(item) then _.map item, _.cloneData
         else if _.isObject(item) then _.mapObject item, _.cloneData
         else item

      reverse: (array) ->
         array.slice(0).reverse()

      wait: (time, func) ->
         _.delay(func, time)

      # developer / debugging methods
      log: (items...) ->
         console.log(items...)
         _.last(items)

      print: (items...) ->
         first = _.initial(items)
         last = _.last(items)
         if _.notEmpty(first) then console.log(first...)
         console.log JSON.stringify(last, null, 3)
         _.last(items)

      # Save many requests and return a promise, wrap logic for handling data
      # when many requests are saved `args` is an array of arrays for each request
      # when only one request is saved, `args` is an array different
      promiseBulk: (array) ->
         $.when.apply(@, array).then (args...) ->
            if args.length == array.length
               _.map args, (response) -> _.first(response)
            else [_.first(args)]

      flatMap: _.compose(_.flatten, _.map)
      compactMap: _.compose(_.compact, _.map)
      uniqPluck: _.compose(_.uniq, _.pluck)

      trimRemoteId: (remote_id = "") ->
         str.ltrim("" + remote_id, "0")

      range: (start, stop, step) ->
         # underscroe 1.9.0 fix - infinite bounds should return an empty array
         if (start? and !_.isFinite(start)) or (stop? and !_.isFinite(stop)) then []
         else originalRange(start, stop, step)

      first: (list, number) ->
         # underscroe 1.9.0 fix - using numer should always return an array
         originalFirst(list, number) || (if number? then [])

      truncate: (text, limit) ->
         if text.length > limit
            text.substring(0, limit - 3) + "..."
         else
            text

   Utils =
      paramToObject: (params) ->
         object = {}
         if params.indexOf("?") != -1
            params = params.slice(1)

         _.each params.split("&"), (string) ->
            s = string.split("=")
            if s[0].match(/\[\]/)
               key = s[0].split("[")[0]
               object[key] = object[key] || []
               object[key].push s[1]
            else
               object[s[0]] = s[1]
         object
