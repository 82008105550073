module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   classNames = require("classnames")
   moment = require("moment")
   {createComponent} = require("@evertrue/et-flux")
   FilterSource = require("apps/filters/sources/filter-source")
   FilterStore = require("apps/filters/stores/filter-store")
   FacetStore = require("apps/filters/stores/facet-store")
   FeatureStore = require("apps/layout/stores/feature-store").default
   FilterConfigStore = require("apps/filters/stores/filter-config-store")
   {div, span, i} = ReactLibs.DOMFactories
   FilterDateRange = require("apps/filters/components/filter-date-range")
   FilterMultiSelect = require("apps/filters/components/filter-multi-select")
   FilterTextInput = require("apps/filters/components/filter-text-input")
   {createFactory} = require("base/new-utils")
   AdvancedCombobox = createFactory(require("@evertrue/et-components").AdvancedCombobox)
   CalendarDropdown = createFactory(require("components/controls/calendar-dropdown"))
   CalendarPickerDropdown = createFactory require("components/controls/calendars/calendar-picker-dropdown")
   RollingDateInput = createFactory(require("@evertrue/et-components").RollingDateInput)
   NumericInput = createFactory(require("@evertrue/et-components").NumericInput)
   Range = createFactory require("components/forms/range")
   SlideRange = createFactory require("components/forms/slide-range")
   HelpTip = createFactory require("components/tooltip/helptip").default
   Icon = createFactory (require("components/elements/icon").default)
   ButtonTooltip = createFactory(require("@evertrue/et-components").ButtonTooltip)
   BooleanRadio = createFactory(require("@evertrue/et-components").BooleanRadio)
   FILTER_CLASS = "segment-filter-row--filter"
   VALUE_CLASS = "segment-filter-row--value"
   COL_CLASS = "segment-filter-row--col"
   OPERATOR_CLASS = "segment-filter-row--operator-value"
   SUB_VALUE_CLASS = "segment-filter-row--sub-value"
   VAL_WRAPPER_CLASS = "segment-filter-row--val-wrapper"
   REMOVE_CLASS = "segment-filter-row--remove"
   DROPDOWN_SIZE_CLASS = "segment-filter-row--min-width"

   _not_options = [
      {label: "is", value: "must"}
      {label: "is not", value: "must_not"}
   ]

   _operator_options = [
      {label: "equals", value: "must"}
      {label: "does not equal", value: "must_not"}
      {label: "is set", value: "is_set"}
      {label: "is not set", value: "is_not_set"}
   ]

   _date_operator_options = [
      {label: "Is in the last", value: "must:gte"}
      {label: "Is not in the last", value: "must_not:gt"}
      {label: "Is before", value: "must:lt"}
      {label: "Is after", value: "must:gt"}
      {label: "Is between", value: "must"}
      {label: "Is not between", value: "must_not"}
      {label: "Is on", value: "must:match"}
      {label: "Is not on", value: "must_not:match"}
   ]

   _number_operator_options = [
      {label: "Is between", value: "must:gte-lte"}
      {label: "Is not between", value: "must_not:gte-lte"}
      {label: "Is greater than", value: "must:gt"}
      {label: "Is less than", value: "must:lt"}
      {label: "Is equal to", value: "must"}
      {label: "Is not equal to", value: "must_not"}
      {label: "Is set", value: "is_set"}
      {label: "Is not set", value: "is_not_set"}
   ]
    
   DATE_FORMAT = "YYYY-MM-DD"
   CURRENT_DATE = moment(new Date()).format(DATE_FORMAT)
   PAST_WEEK = moment(new Date()).subtract(1, "week").format(DATE_FORMAT)


   createComponent "SegmentFilterRow",
      propTypes:
         filter_row_id: ReactLibs.PropTypes.number
         filter_id: ReactLibs.PropTypes.string
         value: ReactLibs.PropTypes.any
         onRemove: ReactLibs.PropTypes.func

      registerStores: ->
         @on FilterConfigStore, ->
            options: FilterConfigStore.getGroupedOptions(@props.filter_id)
            filter_data: FilterConfigStore.getOptionById(@props.filter_id)

         @on FilterStore, ->
            filter_count: FilterStore.getCurrentFilters().length
            selected_filter_ids: _.map FilterStore.getCurrentFilters(), (filter) -> filter.filter_id

         @on FacetStore, ->
            facets: FacetStore.getForFilter(@props.filter_id)
            facets_loading: FacetStore.getLoading()

         @on FeatureStore, ->
            has_interaction_custom_fields: FeatureStore.getFeature("interaction_custom_fields")
            has_volunteers: FeatureStore.getFeature("volunteers")

      UNSAFE_componentWillReceiveProps: (newProps) ->
         if newProps.filter_id != @props.filter_id
            if @depracated_is_mounted
               @setState
                  facets: FacetStore.getForFilter(newProps.filter_id)
                  filter_data: FilterConfigStore.getOptionById(newProps.filter_id)
                  options: FilterConfigStore.getGroupedOptions(newProps.filter_id)

      handleFilterSelect: (selection) ->
         filter = FilterConfigStore.getOptionById(selection.value) || {}
         defaultValue = switch filter.component_type
            when "boolean", "static" then true
            when "basic_select" then {parameter: {value: "must", label: "is"}}
            when "multi_select_with_not" then {parameter: {value: "must", label: "is"}}
            when "date_range" then {parameter: {value: "must"}, value: "now-1w"}
            when "date_operator" then {parameter: {label: "Is between", value: "must"}, from: PAST_WEEK, to: CURRENT_DATE}
            when "small_range" then {gte: 1, lte: 5}
            when "text_input" then ""
            when "operator_component" then {parameter: {label: "equals", value: "must"}}
            when "number_operator", "currency_operator" then {parameter: {label: "Is equal to", value: "must"}}

         FilterSource.update @props.filter_row_id,
            filter_id: selection.value
            value: defaultValue || filter.component_default

      handleChange: (name, value) ->
         FilterSource.update(@props.filter_row_id, {value: value})

      handleRemove: ->
         @props.onRemove?()
         FilterSource.remove(@props.filter_row_id)

      getOptions: ->
         _.map @state.options, (options) =>
            remove_interaction_labels = @state.has_interaction_custom_fields && !@state.has_volunteers && options.value == "Interactions"
            formattedItems = _.reduce options.items, (accum, item) =>
               is_selected = _.contains(@state.selected_filter_ids, item.value)

               # Remove interaction labels if using interaction custom fields and not volunteers,
               # because we dont show those labels on the interaction UNLESS the customer has volunteers,
               # then we want to have the volunteer pool and volunteer contact label
               if remove_interaction_labels && item.label == "Interaction Label"
                  return accum

               accum.concat(_.extend {}, item, disabled: item.disabled || is_selected)
            , []

            _.extend {}, options, {items: formattedItems}


      extendValue: (value) ->
         _.extend {}, @props.value, value

      render: ->
         filter = @state.filter_data || {}
         facets = @state.facets[_.first(_.keys(@state.facets))]
         # Render Content
         div className: "segment-filter-row",
            div className: "segment-filter-row--container",
               if filter.component_type != "read_only"
                  div className: classNames(FILTER_CLASS, if @props.filter_id then COL_CLASS),
                     AdvancedCombobox
                        options: @getOptions()
                        value: {label: filter.label, value: @props.filter_id}
                        searchable: true
                        grouped: true
                        title: "Select #{filter.label || "a filter"}"
                        placeholder: "Select #{filter.label || "Filter..."}"
                        onChange: @handleFilterSelect

               div className: VALUE_CLASS,
                  switch filter.component_type
                     when "boolean"
                        BooleanRadio
                           name: @props.filter_id
                           value: _.jsonParse(@props.value)
                           onChange: @handleChange

                     when "range"
                        Range
                           min: "0"
                           max: "Max"
                           value: @props.value
                           name: @props.filter_id
                           onChange: @handleChange
                           mask: {separator: ","}

                     when "dps_range"
                        Range
                           min: "0"
                           max: "100"
                           value: @props.value
                           name: @props.filter_id
                           onChange: @handleChange
                           mask: {separator: ","}

                     when "currency_range"
                        Range
                           min: "$0"
                           max: "Any"
                           value: @props.value
                           name: @props.filter_id
                           onChange: @handleChange
                           mask: {prefix: "$", separator: ","}

                     when "slider_range"
                        SlideRange
                           min: 1
                           max: 5
                           name: @props.filter_id
                           value: @props.value
                           onChange: @handleChange

                     when "list"
                        div style: {width: "275px"},
                           FilterMultiSelect
                              value: @props.value
                              filter_id: @props.filter_id
                              onChange: (value) =>
                                 @handleChange(null, value)

                     when "suggestion_list"
                        div style: {width: "275px"},
                           FilterMultiSelect
                              value: @props.value
                              filter_id: @props.filter_id
                              onSearch: undefined
                              onChange: (val) =>
                                 @handleChange(null, val)
                              formatSelection: (selectionText, selection, placeholder) ->
                                 if selectionText.match(/// contains: ///i)
                                    create_key = _.last(selectionText.split(":"))
                                    selectionText = "contains: \"#{create_key.trim()}\""
                                 span null,
                                    span className: "advanced-combobox--selected-text", selectionText
                                    i className: "fa fa-fw fa-angle-down"
                              createFromSearch: (search) ->
                                 {label: "#{filter.label} Contains: #{search}", value: "contains: #{search}"}

                     when "multi_select_with_not"
                        div className: VAL_WRAPPER_CLASS,
                           div className: classNames(COL_CLASS, OPERATOR_CLASS),
                              AdvancedCombobox
                                 options: _not_options
                                 value: @props.value?.parameter
                                 onChange: (val) =>
                                    @handleChange(null, @extendValue({parameter: val}))

                           div className: classNames(SUB_VALUE_CLASS, DROPDOWN_SIZE_CLASS),
                              FilterMultiSelect
                                 value: @props.value
                                 filter_id: @props.filter_id
                                 onChange: (val) =>
                                    @handleChange(null, @extendValue({value: val}))

                     when "basic_select"
                        div className: VAL_WRAPPER_CLASS,
                           div className: classNames(COL_CLASS, OPERATOR_CLASS),
                              AdvancedCombobox
                                 options: _not_options
                                 value: @props.value?.parameter
                                 onChange: (val) =>
                                    @handleChange(null, @extendValue({parameter: val}))

                           div className: classNames(SUB_VALUE_CLASS, DROPDOWN_SIZE_CLASS),
                              AdvancedCombobox
                                 options: facets
                                 value: _.findWhere facets, {value: @props.value?.value?.value}
                                 placeholder: "Select Options..."
                                 onChange: (val) =>
                                    @handleChange(null, @extendValue({value: val}))

                     when "date_range"
                        FilterDateRange
                           value: @props.value
                           facets: filter.facets
                           onChange: (val) =>
                              @handleChange null, @extendValue(val)

                     when "text_input"
                        FilterTextInput
                           value: @props.value
                           placeholder: "Enter keywords..."
                           onChange: (val) =>
                              if val
                                 @handleChange(null, @extendValue({value: val}))
                              else
                                 @handleChange(null, undefined)

                     when "operator_component"
                        div className: VAL_WRAPPER_CLASS,
                           div className: classNames(COL_CLASS, OPERATOR_CLASS), style: {width: "400px"},
                              AdvancedCombobox
                                 placeholder: "Select operator"
                                 options: _operator_options
                                 value: if @props.value?.parameter
                                    @props.value?.parameter
                                 else
                                    @props.value?.parameter = {label: "equals", value: "must"}
                                 onChange: (val) =>
                                    @handleChange(null, @extendValue({parameter: val}))
                           if @props.value and @props.value.parameter and !@props.value.parameter.value.includes("is")
                              div className: classNames(SUB_VALUE_CLASS, DROPDOWN_SIZE_CLASS),
                                 FilterMultiSelect
                                    value: @props.value
                                    filter_id: @props.filter_id
                                    onChange: (val) =>
                                       @handleChange(null, @extendValue({value: val}))
                    
                     when "date_operator"
                        div className: VAL_WRAPPER_CLASS,
                           div className: classNames(COL_CLASS, OPERATOR_CLASS), style: {width: "500px"},
                              AdvancedCombobox
                                 placeholder: "Select operator"
                                 options: _date_operator_options
                                 value: @props.value?.parameter
                                 onChange: (val) =>
                                    @handleChange(null, @extendValue({parameter: val}))
                           if @props.value.parameter.label.includes("last") 
                              div className: classNames(SUB_VALUE_CLASS),
                                 RollingDateInput
                                    value: if @props.value?.in
                                       @props.value.in
                                    onChange: (val) =>
                                       @handleChange(null, @extendValue({in: val}))

                           else if @props.value.parameter.label.includes("between")
                              div className: classNames(SUB_VALUE_CLASS), style: {width: "240px"},
                                 CalendarDropdown
                                    date_from: @props.value.from
                                    date_to: @props.value.to
                                    onChange: (from,to) =>
                                       @handleChange(null, @extendValue({from: from, to: to}))

                           else 
                              div className: classNames(SUB_VALUE_CLASS),
                                 CalendarPickerDropdown
                                    value: if @props.value?.date
                                       @props.value.date
                                    onChange: (date) =>
                                       @handleChange(null, @extendValue({date: date}))
                     
                     when "number_operator", "currency_operator"
                        div className: VAL_WRAPPER_CLASS,
                           div className: classNames(COL_CLASS, OPERATOR_CLASS), style: {width: "400px"},
                              AdvancedCombobox
                                 options: _number_operator_options
                                 value: @props.value?.parameter
                                 onChange: (val) =>
                                    @handleChange(null, @extendValue({parameter: val}))

                           if @props.value?.parameter?.label?.includes("between") 
                              div className: classNames(SUB_VALUE_CLASS),
                                 Range
                                    min: if filter.component_type == "number_operator" then "0" else "$0"
                                    max: "Max"
                                    value: @props.value
                                    name: @props.filter_id
                                    mask: if filter.component_type == "number_operator" then {separator: ","} else {prefix: "$", separator: ","}
                                    onChange: @handleChange
                           
                           else if @props.value?.parameter && !@props.value?.parameter.label.includes("between") && !@props.value?.parameter.label.includes("set") 
                              div className: classNames(SUB_VALUE_CLASS, DROPDOWN_SIZE_CLASS),
                                 NumericInput
                                    value: @props.value?.value
                                    maskType: "number"
                                    placeholder: if filter.component_type == "number_operator" then "0" else "$0"
                                    className: "number-range--input"
                                    onChange: (value, evnt) =>
                                       @handleChange(null, @extendValue({value: value}))
                     
                     when "constituency_operator_component"
                        div className: VAL_WRAPPER_CLASS,
                           div className: classNames(COL_CLASS, OPERATOR_CLASS), style: {width: "400px"},
                              AdvancedCombobox
                                 placeholder: "Select operator"
                                 options: _operator_options
                                 value: if @props.value?.parameter
                                    if @props.value?.parameter.label == "is"
                                       @props.value?.parameter = {label: "equals", value: "must"}
                                    else if @props.value?.parameter.label == "is not"
                                       @props.value?.parameter = {label: "does not equal", value: "must_not"}
                                    else
                                       @props.value?.parameter
                                 else
                                    @props.value?.parameter = {label: "equals", value: "must"}
                                 onChange: (val) =>
                                    @handleChange(null, @extendValue({parameter: val}))
                           if @props.value and @props.value.parameter and !@props.value.parameter.value.includes("is")
                              div className: classNames(SUB_VALUE_CLASS, DROPDOWN_SIZE_CLASS),
                                 FilterMultiSelect
                                    value: @props.value
                                    filter_id: @props.filter_id
                                    onChange: (val) =>
                                       @handleChange(null, @extendValue({value: val}))

                     else
                        if _.isFunction(filter.component)
                           filter.component(@props, @state, @handleChange)

                  if filter.help
                     HelpTip
                        className: "segment-filter-row--help"
                        help_key: filter.help
                        iconSize: 1

            if (@state.filter_count > 1 || !!@props.filter_id)
               div className: REMOVE_CLASS,
                  ButtonTooltip
                     children: "Remove"
                     onTriggerClick: @handleRemove
                     trigger: Icon icon: "cancel", disableTitle: true

            if _.isFunction(filter.sub_component)
               div className: "segment-filter-row--sub-component-container",
                  filter.sub_component(@props, @state, @handleChange)