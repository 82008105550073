import { Modal, LinkButton } from "@evertrue/et-components";
import EnrichmentModalHeader from "../enrichment-modal-header";
import { PropTypes } from "base/react-libs";
import { mostRecentUpdate } from "apps/profile/components/enrichment/enrichment-utils";
import { useState } from "react";
import ContactInterestsList from "./contact-interests-list";

const PhilanthropicInterestsModal = ({ isOpen, closeModal, philInterests = [], contactAttributes, onDelete }) => {
  const activeInterests = philInterests.filter(({ rejected }) => !rejected);
  const rejectedInterests = philInterests.filter(({ rejected }) => rejected);
  const { contactName, contactId } = contactAttributes;
  const [showRejected, setShowRejected] = useState(false);

  return (
    <Modal isOpen={isOpen} closeModal={closeModal} size="500px" closeOnOutsideClick={true}>
      <EnrichmentModalHeader
        headerText={`${contactName}'s Philathropic Interests`}
        closeModal={closeModal}
        updateDate={mostRecentUpdate(philInterests)}
      />
      <div className="profile-enrichment--more-modal">
        <ContactInterestsList interests={activeInterests} contactId={contactId} onDelete={onDelete} />

        {rejectedInterests.length > 0 && (
          <LinkButton onClick={() => setShowRejected(!showRejected)} title="">
            {showRejected ? "Hide deleted" : "See deleted"}
          </LinkButton>
        )}
        {showRejected && (
          <ContactInterestsList interests={rejectedInterests} contactId={contactId} onDelete={onDelete} />
        )}
      </div>
    </Modal>
  );
};

PhilanthropicInterestsModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  contactAttributes: PropTypes.object,
  onDelete: PropTypes.func,
  philInterests: PropTypes.array,
};

export default PhilanthropicInterestsModal;
