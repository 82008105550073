module.exports = do ->
   _ = require("underscore").default
   {createStore} = require("@evertrue/et-flux")
   PoolSolicitorsSource = require("apps/volunteers/sources/pool-solicitors-source").default
   PoolAssignmentsSource = require("apps/volunteers/sources/pool-assignments-source")
   SocketSource = require("apps/notifications/sources/socket-source")
   PaginationUtils = require("mixins/pagination-utils")

   createStore "PoolSolicitorsStores",
      getInitialState: ->
         loading: false
         loading_more: false
         solicitors_by_pool: {}
         sample_solicitors_by_pool:{}
         selected_solicitor_id: undefined
         name_query: undefined

      registerActions: ->
         @on PoolSolicitorsSource.actions.loading, @respondToLoading
         @on PoolSolicitorsSource.actions.loadingMore, @respondToLoadingMore

         @on PoolSolicitorsSource.actions.fetch, @respondToFetch
         @on PoolSolicitorsSource.actions.fetchedSample, @respondToFetchedSample
         @on PoolSolicitorsSource.actions.fetchedSolicitors, @respondToFetched

         @on PoolSolicitorsSource.actions.filterByName, @respondToFilter
         @on PoolSolicitorsSource.actions.paginate, @respondToPaginate
         @on PoolSolicitorsSource.actions.select, @respondToSelect

         @on PoolSolicitorsSource.actions.addedSolicitors, @respondToCountChange
         @on PoolSolicitorsSource.actions.removedSolicitor, @respondToCountChange


      respondToLoading: (is_loading) ->
         @setState {loading: is_loading}

      respondToLoadingMore: (is_loading) ->
         @setState {loading_more: is_loading}

      respondToFetch: (pool_id) ->
         @fetchWithQuery(pool_id)

      respondToFilter: (pool_id, name_query) ->
         @setState {name_query}
         @fetchWithQuery(pool_id)

      respondToPaginate: (pool_id) ->
         solicitor_data = _.cloneData(@getState("solicitors_by_pool")?[pool_id])
         @fetchWithQuery(pool_id, PaginationUtils.paginate(solicitor_data))

      respondToFetched: (pool_id, solicitors) ->
         solicitors_by_pool = _.cloneData @getState("solicitors_by_pool")
         merged_data = PaginationUtils.mergePagedResults(solicitors_by_pool[pool_id], solicitors)
         merged_data.items = _.uniq(merged_data.items, (s={}) -> s.id)
         solicitors_by_pool[pool_id] = merged_data
         @setState {solicitors_by_pool: solicitors_by_pool}

      respondToFetchedSample: (pool_id, sample) ->
         sample_solicitors_by_pool = _.cloneData @getState("sample_solicitors_by_pool")
         sample_solicitors_by_pool[pool_id] = sample
         @setState({sample_solicitors_by_pool})

      respondToSelect: (solicitor_id) ->
         if @getState("selected_solicitor_id") == solicitor_id
            @setState {selected_solicitor_id: undefined}
         else @setState {selected_solicitor_id: solicitor_id}


      respondToCountChange: (pool_id) ->
         _.wait 1000, -> PoolSolicitorsSource.fetchSample(pool_id)

      fetchWithQuery: (pool_id, params) ->
         cached_solicitors = @getState("solicitors_by_pool")[pool_id]
         query_params = _.pick((params || cached_solicitors), "offset", "limit")
         if _.isEmpty(query_params)
            query_params = {offset: 0, limit: 50}

         filters = _.compactObject {full_name: @getState("name_query")}
         PoolSolicitorsSource.fetchWithQuery(pool_id, filters, query_params)

      api:
         getForPool: (pool_id) ->
            @getState("solicitors_by_pool")?[pool_id] || {}

         getSample: (pool_id) ->
            @getState("sample_solicitors_by_pool")[pool_id]

         getStaticTotal: (pool_id) ->
            @getState("sample_solicitors_by_pool")[pool_id]?.total

         getSolicitorsIndex: (pool_id) ->
            _.indexBy @getForPool(pool_id).items, "id"

         getSelected: (pool_id) ->
            index = _.indexBy(@getForPool(pool_id).items, "id")
            if selected = @getState("selected_solicitor_id")
               index[selected] || {}

         getFilter: ->
            @getState("name_query")

         getLoading: ->
            @getState("loading") && !@getLoadingMore()

         getLoadingMore: ->
            @getState("loading_more")

         hasMoreToLoad: (pool_id) ->
            pool_data = @getState("solicitors_by_pool")?[pool_id]
            !PaginationUtils.isFinalPage(pool_data)
