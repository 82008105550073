import Decorator from "clientDecorator";
import EverTrue from "app";
import { Avatar, Button } from "@evertrue/et-components";
import useRecentlyViewed from "apps/constituents/hooks/use-recently-viewed";

const HEIGHT = 40;
const WIDTH = 40;

const RecentlyViewed = () => {
  const { recentlyViewed } = useRecentlyViewed({ limit: 6 });

  return (
    <>
      <div className="recently-viewed">
        <div className="constituents-controller-title">Recently Viewed</div>
        <div className="recently-viewed-wrapper">
          {recentlyViewed.map((contact) => {
            const parsedContact = Decorator.Contacts.parse(contact);
            const primaryAddress = Decorator.Address.getPrimary(parsedContact);
            const locationString = () => {
              if (primaryAddress) {
                const { city, state } = primaryAddress;
                if (city && state) {
                  return `${city.value}, ${state.value}`;
                }
                if (city && !state) return city.value;
                if (!city && state) return state.value;
              }
            };

            return (
              <Button
                type="simple"
                className="recently-viewed-avatar"
                onClick={() => {
                  EverTrue.track.set("filter_v2_action", {
                    type: "recently_viewed_clicked",
                  });
                  EverTrue.UrlManager.navigate(`/contact/${parsedContact.id}`, true, false);
                }}
                key={parsedContact.id}
              >
                <Avatar
                  height={HEIGHT}
                  width={WIDTH}
                  className="recently-viewed-avatar--image"
                  name={parsedContact.name}
                  url={parsedContact.avatar}
                />
                <div className="recently-viewed-avatar--name">{parsedContact.name}</div>
                <div className="recently-viewed-avatar--location">{locationString()}</div>
              </Button>
            );
          })}
          <Button
            type="simple"
            className="recently-viewed-avatar"
            onClick={() => {
              EverTrue.track.set("filter_v2_action", {
                type: "show_recently_viewed",
              });
              EverTrue.UrlManager.navigate("contact/recent", true, false);
            }}
          >
            <Avatar height={HEIGHT} width={WIDTH} className="recently-viewed-avatar--image" name="Show All" />
            <div className="recently-viewed-avatar--show-all">Show All</div>
          </Button>
        </div>
      </div>
    </>
  );
};

export default RecentlyViewed;
