import { parseTimezoneFromContact } from "../utils/helpers";
import { PropTypes } from "base/react-libs";
import { Icon } from "@evertrue/et-components";

const ContactTimezoneIndicator = ({ contact = {} }) => {
  const timezone = parseTimezoneFromContact(contact);

  return (
    timezone && (
      <div className="contact-timezone-indicator" style={{ marginLeft: "5px" }}>
        <Icon icon="timezone" iconKit="gt2" />
        <div style={{ fontSize: "x-small" }}>{timezone}</div>
      </div>
    )
  );
};

ContactTimezoneIndicator.propTypes = {
  contact: PropTypes.object,
};

export default ContactTimezoneIndicator;
