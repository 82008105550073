import EverTrue from "app";
import BaseRouter from "base/base-router";
import HomepageController from "./controllers/homepage-controller";
import IsGatedController from "components/gates/is-gated-controller";

class HomepageRouter extends BaseRouter {
  static initClass() {
    this.prototype.routes = {
      "home(/)": "homepage",
    };
  }
  homepage() {
    EverTrue.execute("setBrowserTitle", "Home");
    this.currentView = (
      <IsGatedController gate="signal">
        <HomepageController />
      </IsGatedController>
    );
    EverTrue.page.mount(this.currentView);
  }
}

HomepageRouter.initClass();
export default HomepageRouter;
