import { useState } from "react";
import PropTypes from "prop-types";
import Api from "entities/helpers/api";
import EverTrue from "app";
import { ListboxSelect, Button, Icon, Avatar } from "@evertrue/et-components";
import { BOOKMARK_PERMISSIONS } from "apps/search/utils/search-helper";

const path = {
  bookmark: "SEARCH_USER_PERMISSIONS",
  dynamicList: "SEARCH_LIST_USER_PERMISSIONS",
};

const options = [
  { label: "Viewer", value: BOOKMARK_PERMISSIONS.VIEW },
  { label: "Editor", value: BOOKMARK_PERMISSIONS.EDIT },
];

const EditUserSharingPermissions = ({ searchId, user, setUserPermissions, referrer }) => {
  const [value, setValue] = useState(options.find((option) => option.value === user.permission) || options[0]);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleOnChange = async (change) => {
    const payload = {
      name: user.name,
      user_id: user.user_id,
      permission: change.value,
    };

    setValue(options.find((option) => option.value === change.value));

    try {
      const results = await Api.HUB[path[referrer]].post({
        urlArgs: { search_id: searchId, user_id: user.user_id },
        data: JSON.stringify(payload),
      });

      if (referrer === "bookmark") {
        EverTrue.track.set("filter_v2_action", {
          type: "bookmark_share_user",
          permission: change.value,
        });
      }

      setUserPermissions(results);
    } catch {
      EverTrue.Alert.error("There was a problem editing user.");
    }
  };

  const handleDelete = async () => {
    setIsDeleting(true);

    try {
      const results = await Api.HUB[path[referrer]].delete({
        urlArgs: { search_id: searchId, user_id: user.user_id },
      });
      setUserPermissions(results);
    } catch {
      EverTrue.Alert.error("There was a problem deleting user.");
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <li className="bookmark-sharing-modal--edit-user">
      <Avatar height={40} width={40} name={user.name} url={user.user_profile_picture_url} />

      <div className="bookmark-sharing-modal--user-name">{user.name ? user.name : "Removed User"}</div>

      <ListboxSelect
        label={`Permissions for ${user.name}`}
        size="small"
        value={value}
        onChange={handleOnChange}
        options={options}
      />

      <Button type="simple" label={`Delete user ${user.name}`} onClick={handleDelete} disabled={isDeleting}>
        <Icon icon="trash" />
      </Button>
    </li>
  );
};

EditUserSharingPermissions.propTypes = {
  searchId: PropTypes.string.isRequired,
  setUserPermissions: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  referrer: PropTypes.string.isRequired,
};

export default EditUserSharingPermissions;
