import { Component } from "react";
import Icon from "components/elements/icon";
import FacebookSource from "apps/facebook/sources/facebook-source";
import HelpTip from "components/tooltip/helptip";
import EverTrue from "app";
import _ from "underscore";

class FacebookAccountsTable extends Component {
  static propTypes = {
    pages: ReactLibs.PropTypes.array,
    users: ReactLibs.PropTypes.object,
  };

  static defaultProps = {
    pages: [],
    users: {},
  };
  // unsused but leaving  here for future
  handleDisconnect = page => {
    EverTrue.Alert.confirm(
      {
        headline: `Disconnect ${page.name}?`,
        content:
          "Are you sure you want to disconnect this page? This will disable any constituent engagement data for posts on this page.",
      },
      did_confirm => {
        if (did_confirm) {
          FacebookSource.disconnect(page.id);
        }
      }
    );
  };

  renderState(page) {
    if (page.is_connected) {
      return (
        <div className="facebook-accounts-table--connected">
          <Icon icon="check-circle" size={1} before />
          Connected
        </div>
      );
    }
    return (
      <div>
        <Icon icon="check-circle" size={1} before />
        Not Connected
        <HelpTip help_key="facebook_pages_auth" />
      </div>
    );
  }

  renderUsers(page) {
    const { users } = this.props;
    const names = _.compact(
      _.map(page.connected_uids, id => {
        if (users[id]) {
          return <div key={id}>{`${users[id].name} - ${users[id].email}`}</div>;
        }
        return null;
      })
    );

    if (!names.length && page.is_connected) {
      return <div>Connected by EverTrue</div>;
    }
    return <div>{_.isEmpty(names) ? "-" : names}</div>;
  }

  render() {
    const { pages } = this.props;

    return (
      <table className="facebook-accounts-table">
        <thead className="facebook-accounts-table--header">
          <tr>
            <th>Facebook Page</th>
            <th>Connected Page Admins</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody className="facebook-accounts-table--body">
          {_.map(pages, page => (
            <tr key={page.remote_id}>
              <td>
                <img src={page.picture_url} alt={page.page_name} className="facebook-accounts-table--icon" />
                {page.page_name}
              </td>
              <td className="facebook-accounts-table--users">
                {_.isEmpty(page.connected_uids) ? "-" : this.renderUsers(page)}
              </td>
              <td>{this.renderState(page)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

export default FacebookAccountsTable;
