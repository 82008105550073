_ = require("underscore").default
EverTrue = require("app")
React = require("react")
{createFactory} = require("base/new-utils")
Fragment = createFactory React.Fragment
{createComponent} = require("@evertrue/et-flux")
Icon = createFactory (require("components/elements/icon").default)
ModalConfirm = createFactory require("components/modals/modal-confirm")
ContactCard = createFactory(require("apps/contact/components/contact-card/contact-card"))
HelpTip = createFactory require("components/tooltip/helptip").default
Loading = require("components/elements/loading")
Decorator = require("clientDecorator")
{div, span} = ReactLibs.DOMFactories
moment = require("moment")
Api = require("entities/helpers/api")
EmptyStateMessage = createFactory(require("components/lists/empty-state-message"))
LinkButton = createFactory(require("@evertrue/et-components").LinkButton)

{ func, object, array, bool, any } = ReactLibs.PropTypes

module.exports = createComponent "EventbriteEmailsModal",
   propTypes:
      close: func
      contact: object
      engagementStatsByEmail: object
      infoByEmail: object
      profiles: array
      loading: bool
      error: any
      refreshProfiles: func

   getDefaultProps: ->
      close: _.noop
      contact: {}
      engagementStatsByEmail: {}
      profiles: []
      loading: false
      refreshProfiles: _.noop

   componentDidMount: ->
      @props.refreshProfiles()

   getAllEmails: ->
      contact_emails = _.compact _.map @props.contact.identities, ({type, value}) ->
         val = value?.toLowerCase?()
         if val and type in ["eventbrite_id", "email"] then val

      _.uniq contact_emails.concat(_.keys(@props.engagementStatsByEmail))

   getSortedEmailData:  ->
      all_emails = @getAllEmails()

      _.sortBy(_.map(all_emails, (email) =>
         profile = _.findWhere(@props.profiles, { email })

         info = @props.engagementStatsByEmail[email] || {}

         email: email
         has_email_identity: !!_.findWhere(@props.contact.identities, {type: "email", value: email})
         has_eb_identity: !!_.findWhere(@props.contact.identities, {type: "eventbrite_id", value: email})
         profile: profile
         match_status: profile?.matchStatus
         count: info.count || 0
         last_activity: info.last_activity
      ), "count").reverse()


   handleUnmatch: (email) ->
      Api.EVENTS.EVENTBRITE_MATCH.post(
         disableAlerts: true
         params:
            op: "unmatch"
            contact_id: @props.contact.id
            email: encodeURIComponent(email)
      ).then( (resp) =>
         @props.refreshProfiles()
         EverTrue.Alert.success "Unmatched '#{email}' from constituent"
         EverTrue.track.set "identity_matching",
            type: "unmatch"
            source: "Eventbrite"
            referrer: "profile"
      ).catch( (err) =>
         @props.refreshProfiles()
         EverTrue.Alert.error "Problem unmatching '#{email}' from constituent"
      )

   renderEmails: (emails, emptyMessage) ->
      if _.notEmpty(emails)
         _.map emails, (data = {}) =>
            { email, has_email_identity, has_eb_identity, match_status, count, last_activity } = data

            last_seen = moment(last_activity || NaN).format("DD MMM YYYY h:mm a")

            div key: email, className: "eventbrite-emails-modal--list-item",
               if count
                  Icon className: "eventbrite-emails-modal--icon is-eventbrite", icon: "eventbrite"
               else
                  Icon className: "eventbrite-emails-modal--icon", icon: "email"

               span
                  className: "eventbrite-emails-modal--email"
                  title: if count then "#{count} engagements, \nlast activity: #{last_seen}"
                  email

               span className: "eventbrite-emails-modal--source",
                  if has_email_identity
                     Fragment null,
                        "Imported Email"
                        HelpTip help_key: "event_imported_email"

                  else if has_eb_identity and match_status
                     switch match_status
                        when "MATCHED"
                           LinkButton
                              title: "Remove Email"
                              onClick: => @handleUnmatch(email)
                              Icon icon: "unlink"
                              "Remove Email"
                        when "UNMATCH_QUEUED" then "Unmatching..."
                        when "MATCH_QUEUED" then "Matching..."
                        when "UNMATCHED" then "Unmatching..."
                        when "ERROR" then "Match Issue"
                        else "Match Issue"
                  else
                     span className:"text-muted",
                        "Loading Issue - "
                        LinkButton onClick: @props.refreshProfiles, title: "Refresh the page", "Refresh"
      else
         div className: "eventbrite-emails-modal--list-item is-empty",
            emptyMessage

   render: ->
      contact = Decorator.Contacts.parse(@props.contact)
      data = @getSortedEmailData()

      list = _.reject(data, {match_status: "UNMATCHED"})

      { matched, unmatched } = _.groupBy list, ({count}) ->
         if count then "matched" else "unmatched"


      div className: "eventbrite-emails-modal",
         ModalConfirm
            header: "Manage Eventbrite Emails",
            onCancel: @props.close

            ContactCard contact: contact, showNameLink: false

            div className: "eventbrite-emails-modal--contents",

               if @props.loading or @props.error
                  div className: "eventbrite-emails-modal--status-container loading-container",
                     if @props.loading
                        Loading(spinner_size: "small", position: "center")
                     else
                        EmptyStateMessage
                           icon: "question-circle"
                           text: "Loading Error"
                           actionLabel: "Retry"
                           size: "small"
                           onAction: @props.refreshProfiles
                           div className: "eventbrite-emaisl-modal--error-description",
                              "There was an issue loading the Eventbrite match information for this constituent."
               else
                  Fragment null,
                     div className: "eventbrite-emails-modal--title",
                        "MATCHED EMAILS"

                     div className: "eventbrite-emails-modal--list",
                        @renderEmails(matched, "No Matched Emails")

                     div className: "eventbrite-emails-modal--title",
                        "OTHER EMAILS"

                     div className: "eventbrite-emails-modal--list",
                        @renderEmails(unmatched, "No Other Emails")
