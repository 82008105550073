module.exports = do ->
   _ = require("underscore").default
   {createStore} = require("@evertrue/et-flux")
   ProposalSource = require("apps/proposals/sources/proposal-source")
   ProfileInteractionsSource = require("apps/profile/sources/profile-interactions-source").default

   createStore "ProposalStore",
      getInitialState: ->
         proposals: {}
         loading: false
         loading_interactions: false
         proposal_interactions: {}

      registerActions: ->
         @on ProposalSource.actions.loading, @respondToLoading
         @on ProposalSource.actions.fetchedProposal, @respondToFetched

         @on ProposalSource.actions.loadingInteractions, @respondToLoadingInteractions
         @on ProposalSource.actions.fetchedInteractions, @respondToFetchInteractions

         @on ProposalSource.actions.fetchCache, @respondToFetchCache
         @on ProposalSource.actions.fetchedBulk, @respondToFetchedBulk

         @on ProfileInteractionsSource.actions.createdNote, @respondToSaveInteraction
         @on ProfileInteractionsSource.actions.deletedNote, (note) =>
            @respondToSaveInteraction(note.contact_id, note)

      respondToLoading: (is_loading) ->
         @setState {loading: is_loading}

      respondToLoadingInteractions: (is_loading) ->
         @setState {loading_interactions: is_loading}

      respondToFetched: (data) ->
         proposals = _.cloneData @getState("proposals")
         proposals = {} if _.size(proposals) > 50
         proposals[data.id] = data

         @setState {proposals: proposals}

      respondToFetchInteractions: (data) ->
         interactions = _.cloneData @getState("proposal_interactions")
         if data.offset == 0 || !interactions
            interactions = data
         else
            aggregate_items = interactions.items.concat(data.items)
            _.extend interactions, data, {items: aggregate_items}

         @setState {proposal_interactions: interactions}

      respondToSaveInteraction: (contact_id, note) ->
         if @hasListeners() && note.proposal_id
            @respondToLoadingInteractions(true)
            _.wait 1000, ->
               ProposalSource.fetchProposalInteractions(note.proposal_id)

      respondToFetchCache: (proposal_ids) ->
         cached_proposal_ids = _.pluck(@getState("proposals"), "id")
         new_proposal_ids = _.difference(proposal_ids, cached_proposal_ids)
         unless _.isEmpty(new_proposal_ids)
            ProposalSource.fetchBulk(new_proposal_ids)

      respondToFetchedBulk: (proposals) ->
         cached_proposals = _.cloneData @getState("proposals")
         @setState {proposals: cached_proposals}
         ProposalSource.changeProposalCache()

      api:
         getAll: ->
            @getState("proposals")

         getProposal: (id) ->
            @getState("proposals")?[id]

         getInteractions: ->
            @getState("proposal_interactions") || {}

         getLoading: ->
            @getState("loading")

         getLoadingInteractions: ->
            @getState("loading_interactions")
