module.exports = do ->
   _ = require("underscore").default
   classNames = require("classnames")
   React = require("react")
   {div, span} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   NumericInput = createFactory(require("@evertrue/et-components").NumericInput)

   Range = ReactLibs.createClass
      propTypes:
         name: ReactLibs.PropTypes.string.isRequired
         value: ReactLibs.PropTypes.object
         mask: ReactLibs.PropTypes.object
         min: ReactLibs.PropTypes.oneOfType [
            ReactLibs.PropTypes.string
            ReactLibs.PropTypes.number
         ]
         max: ReactLibs.PropTypes.oneOfType [
            ReactLibs.PropTypes.string
            ReactLibs.PropTypes.number
         ]
         disabled: ReactLibs.PropTypes.bool
         showClear: ReactLibs.PropTypes.bool
         onChange: ReactLibs.PropTypes.func
         onKeyPress: ReactLibs.PropTypes.func

      handleChange: (key, value, evnt) ->
         valueObject = _.extend {}, @props.value
         if _.isNaN(value) || _.isUndefined(value) || _.isNull(value)
            valueObject = _.omit(valueObject, key)
         else valueObject[key] = value

         if _.isFunction @props.onChange
            @props.onChange(@props.name, valueObject, evnt)

      render: ->
         minValue = if !_.isNaN(parseInt(@props.value?.gte)) then _.toNumber(@props.value?.gte) else null
         maxValue = if !_.isNaN(parseInt(@props.value?.lte)) then _.toNumber(@props.value?.lte) else null
         mask = _.extend {},
            groupSeparator: ","
            autoGroup: true
            rightAlign: true
         , @props.mask

         div className: classNames("number-range", @props.className),
            NumericInput
               value: minValue
               maskType: "number"
               placeholder: @props.min?.toString()
               disabled: @props.disabled
               className: "number-range--input"
               onChange: (value, evnt) =>
                  @handleChange("gte", value, evnt)
               onKeyPress: @props.onKeyPress

            span className: "number-range--separator"

            NumericInput
               value: maxValue
               maskType: "number"
               placeholder: @props.max?.toString()
               disabled: @props.disabled
               className: "number-range--input"
               onChange: (value, evnt) =>
                  @handleChange("lte", value, evnt)
               onKeyPress: @props.onKeyPress

   return Range
