import _ from "underscore";
import { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Decorator from "clientDecorator";
import { connect } from "@evertrue/et-flux";
import MeetingsStore from "apps/my-trips/stores/meetings-store";
import AddEventModal from "apps/my-trips/components/add-event-modal";
import MeetingCard from "apps/my-trips/components/meeting-card";
import MeetingTravelDistance from "apps/my-trips/components/meeting-travel-distance";
import EmptyMeetingCard from "apps/my-trips/components/empty-meeting-card";
import MyTripsUtils from "apps/my-trips/utils/my-trips-utils";
import { ModalTrigger2, Icon, Loading, LinkButton } from "@evertrue/et-components";
import { Droppable } from "react-beautiful-dnd";

const mapStateToProps = ({ trip = {} }) => ({
  meetings: MeetingsStore.getForTrip(trip.id),
  loading: MeetingsStore.getLoading(),
});

class MeetingController extends Component {
  static propTypes = {
    trip: PropTypes.object,
    isCurrentlyDragging: PropTypes.bool,
    // from stores
    meetings: PropTypes.array,
    loading: PropTypes.bool,
  };

  state = {
    edit_meeting: undefined,
  };

  renderEmptyMeetingCard = (meetings, day) => {
    const event_default = MyTripsUtils.getDefaultEvent(meetings, day, this.props.trip);
    return (
      <Droppable droppableId={`Date=${day}`}>
        {(provided, snapshot) => {
          return (
            <div
              key={day}
              ref={provided.innerRef}
              className={`dnd-controller--list-${day}
                ${snapshot.isDraggingOver ? " dnd-controller--dragged-over-list" : ""}
                `}
              {...provided.droppableProps}
            >
              <EmptyMeetingCard tripId={this.props.trip.id} event={event_default} />
            </div>
          );
        }}
      </Droppable>
    );
  };

  renderBetween = (next = {}, current = {}) => {
    const time = Decorator.MyTrips.getTimeBetween(next, current);
    const has_locations = !(_.isEmpty(current.location) || _.isEmpty(next.location));
    return (
      <div className="meeting-calendar--meta">
        {time === "Meetings Overlap" ? (
          <div className="text-danger">Meetings Overlap</div>
        ) : (
          <div>
            <strong>{time}</strong>
            {!!time && has_locations && " | "}
            <MeetingTravelDistance meeting={current} next={next} />
          </div>
        )}
      </div>
    );
  };

  render() {
    const { trip, isCurrentlyDragging } = this.props;
    const meetings_by_date = Decorator.MyTrips.getCalendarData(trip, this.props.meetings);
    const calendar_width = { width: _.size(meetings_by_date) * 260 - 32 };

    if (!trip || this.props.loading) {
      return (
        <div className="meeting-calendar">
          <Loading />
        </div>
      );
    } else {
      return (
        <Fragment>
          {/* if no contacts have been added to meetings yet, show meeting empty stat */}
          {_.isEmpty(this.props.meetings) && !isCurrentlyDragging && (
            <div className="meeting-calendar--empty" style={calendar_width}>
              <div className="meeting-calendar--empty-content">
                <img
                  alt="drag and drop constituents here to create a meeting"
                  src="/images/drag-drop-empty.png"
                  className="meeting-calendar--empty-image"
                />
                <div className="meeting-calendar--empty-header">Drag & Drop</div>
                <div>Constituents in your Trip List can be dragged into a new or existing meeting.</div>
                {trip.is_my_trip && (
                  <div className="meeting-calendar--empty-action">
                    {` or `}
                    <ModalTrigger2
                      closeOnOutsideClick
                      trigger={({ open }) => {
                        return (
                          <LinkButton title="Add event" onClick={open}>
                            Add Event
                          </LinkButton>
                        );
                      }}
                    >
                      {({ close }) => {
                        return <AddEventModal tripId={trip.id} remove={close} />;
                      }}
                    </ModalTrigger2>
                  </div>
                )}
              </div>
            </div>
          )}

          <div className="meeting-calendar" style={calendar_width}>
            <table className="meeting-calendar--table" style={calendar_width}>
              <thead>
                <tr>
                  {_.map(meetings_by_date, (meetings, day) => {
                    const event_default = MyTripsUtils.getDefaultEvent(meetings, day, this.props.trip);
                    return (
                      <th className="meeting-calendar--col-header" key={day}>
                        {trip.is_my_trip && (
                          <ModalTrigger2
                            closeOnOutsideClick
                            trigger={({ open }) => {
                              return (
                                <LinkButton
                                  title="Add new meeting"
                                  onClick={open}
                                  className="meeting-calendar--col-header-icon"
                                >
                                  <Icon icon="add-circle-outline" />
                                </LinkButton>
                              );
                            }}
                          >
                            {({ close }) => {
                              return <AddEventModal event={event_default} tripId={trip.id} remove={close} />;
                            }}
                          </ModalTrigger2>
                        )}
                        {day}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {_.map(meetings_by_date, (meetings, day) => {
                    return (
                      <td className="meeting-calendar--col" key={day}>
                        <div className="meeting-calendar--col-body">
                          {/* If no meetings or dragging, show add meeting cell */}
                          {trip.is_my_trip && this.renderEmptyMeetingCard(meetings, day)}

                          {/* Show list of existing meetings, can drag on top of to add */}
                          {_.map(meetings, (meeting = {}, i) => {
                            return (
                              <div key={meeting.id}>
                                <Droppable droppableId={`Meeting=${meeting.id}`}>
                                  {(provided, snapshot) => {
                                    return (
                                      <div
                                        ref={provided.innerRef}
                                        className={`dnd-controller--list-${day}${
                                          snapshot.isDraggingOver ? " dnd-controller--dragged-over-list" : ""
                                        } meeting-card--target`}
                                        {...provided.droppableProps}
                                      >
                                        <MeetingCard meeting={meeting} trip={trip} />
                                      </div>
                                    );
                                  }}
                                </Droppable>
                                {i + 1 < meetings.length && this.renderBetween(meetings[i + 1], meeting)}
                              </div>
                            );
                          })}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              </tbody>
            </table>
          </div>
        </Fragment>
      );
    }
  }
}

export default connect(MeetingController, [MeetingsStore], mapStateToProps);
