const _ = require("underscore").default;
const { createSource } = require("@evertrue/et-flux");
const EverTrue = require("app");
const Api = require("entities/helpers/api");
const ErrorLogger = require("entities/helpers/error-log-helper");

const _trackAction = (type, object) =>
  EverTrue.track.set(
    "pool_action",
    _.extend(
      {
        type,
        object: "prospects",
      },
      object
    )
  );

module.exports = createSource("PoolAssignmentsSource", {
  actions: {
    loadingAll: true,
    loading(prospect_ids) {
      return this.require(!_.isUndefined(prospect_ids), "prospect_ids required");
    },
    fetchedProspect(resp) {
      return this.require(_.isObject(resp), "resp should be object");
    },
    fetchedFromContacts(resp) {
      return this.require(_.isPagedObject(resp), "resp should be paged object");
    },
    fetchForContactIdsIfNotCached(contact_ids, query) {
      this.require(_.isArray(contact_ids), "contact_ids should be array");
      return this.require(_.isObject(query), "query should be object");
    },
    assign: true,
    unassign: true,
    join: true,
    unjoin: true,
    swapJoin: true,
    updatedStage: true,
  },
  promise: {
    assign(pool_id, solicitor_contact_id, prospect_ids) {
      _trackAction("assign", { count: prospect_ids.length });
      this.actions.loading(prospect_ids, true);

      // Fetch solicitor id from contact id that we have stored
      return Api.VOLUNTEER.SOLICITORS.get({
        urlExtend: "/bulk",
        params: { contact_id: [solicitor_contact_id], pool_id },
        error: () => this.actions.loading(prospect_ids, false),
        success: (solicitors) => {
          const solicitor = _.first(solicitors);
          if (!solicitor) {
            EverTrue.Alert.error("Unable to assign prospects. Solicitor not found.");
            this.actions.loading(prospect_ids, false);
          } else {
            return Api.VOLUNTEER.ASSIGNMENTS.post({
              urlExtend: `/assign/${solicitor.id}`,
              params: { pool_id },
              data: _.jsonStringify({
                prospect_contact_ids: prospect_ids,
              }),
            })
              .then(() => {
                const text = prospect_ids.length > 1 ? "Prospects" : "Prospect";
                EverTrue.Alert.success(`Successfully Assigned ${text}.`);
                this.actions.assign(pool_id, solicitor_contact_id);
              })
              .catch((e) => {
                this.actions.loading(prospect_ids, false);
              });
          }
        },
      });
    },
    unassign(pool_id, solicitor_contact_id, prospect_id) {
      _trackAction("unassign");
      this.actions.loading(prospect_id, true);

      return Api.VOLUNTEER.ASSIGNMENTS.post({
        urlExtend: `/${prospect_id}/un_assign`,
        params: { pool_id },
      })
        .then(() => {
          EverTrue.Alert.success("Successfully Unassigned Prospect.");
          this.actions.unassign(pool_id, solicitor_contact_id);
        })
        .catch(() => {
          this.actions.loading(prospect_id, false);
        });
    },
    join(pool_id, assignment_id, secondary_contact_id, solicitor_contact_id) {
      this.actions.loadingAll(true);

      return Api.VOLUNTEER.ASSIGNMENTS.post({
        urlExtend: `/${assignment_id}/join`,
        data: _.jsonStringify({
          secondary_prospect_contact_ids: [secondary_contact_id],
        }),
        success: () => {
          EverTrue.Alert.success("Successfully linked prospects.");
          this.actions.join(pool_id, solicitor_contact_id);
        },
        error: (xhr) => {
          ErrorLogger.captureRequest("ASSIGNMENTS: /join", xhr);
          this.actions.loadingAll(false);
        },
      });
    },

    unjoin(pool_id, assignment_id, secondary_contact_id, solicitor_contact_id) {
      this.actions.loadingAll(true);

      return Api.VOLUNTEER.ASSIGNMENTS.delete({
        urlExtend: `/${assignment_id}/join/${secondary_contact_id}`,
        statusCode: {
          202: () => {
            EverTrue.Alert.success("Successfully unlinked prospects.");
            this.actions.unjoin(pool_id, solicitor_contact_id);
          },
          error: (xhr) => {
            ErrorLogger.captureRequest("ASSIGNMENTS: delete /join", xhr);
            this.actions.loadingAll(false);
          },
        },
      });
    },

    swapJoin(pool_id, assignment_id, secondary_contact_id) {
      this.actions.loading(assignment_id, true);

      return Api.VOLUNTEER.ASSIGNMENTS.put({
        urlExtend: `/${assignment_id}/join/${secondary_contact_id}/swap`,
        success: () => {
          EverTrue.Alert.success("Successfully changed primary prospect.");
          this.actions.swapJoin(pool_id);
          this.actions.loading(assignment_id, false);
        },
        error: (xhr) => {
          ErrorLogger.captureRequest("ASSIGNMENTS: /join/swap", xhr);
          this.actions.loading(assignment_id, false);
        },
      });
    },
  },
  api: {
    fetch(prospect_ids) {
      this.actions.loading(prospect_ids, true);

      Api.SEARCH.ASSIGNMENTS.post({
        data: _.jsonStringify({
          must: [{ assignment_id: { in: prospect_ids } }, { parent_role: { match: "prospect" } }],
        }),
        success: (resp) => {
          this.actions.fetchedProspect(resp);
          this.actions.loading(prospect_ids, false);
        },
        error: () => {
          this.actions.loading(prospect_ids, true);
        },
      });
    },

    fetchFromContacts(pool_id, contact_ids) {
      this.actions.loadingAll(true);

      Api.SEARCH.ASSIGNMENTS.post({
        data: _.jsonStringify({
          must: [
            { pool_id: { match: pool_id } },
            { prospect_contact_id: { in: contact_ids } },
            { parent_role: { match: "prospect" } },
          ],
        }),
        success: (resp) => {
          this.actions.fetchedFromContacts(resp);
          this.actions.loadingAll(false);
        },
        error: () => {
          this.actions.loadingAll(false);
        },
      });
    },

    createAskAmount(prospect_id, amount_in_cents) {
      this.actions.loading(prospect_id, true);

      Api.VOLUNTEER.ASK_AMOUNT.post({
        data: _.jsonStringify({ amount_in_cents }),
        params: { assignment_id: prospect_id },
        error_message: "There was an error creating this ask amount. Please try again.",
        error: () => {
          this.actions.loading(prospect_id, false);
        },
      });
    },

    fetchForContactIdsIfNotCached(contact_ids, query) {
      this.actions.fetchForContactIdsIfNotCached(contact_ids, query);
    },

    fetchSolicitorsForContactIds(contact_ids, query) {
      Api.SEARCH.ASSIGNMENTS.post({
        data: _.jsonStringify(query(contact_ids)),
        success: (resp) => {
          this.actions.fetchedFromContacts(resp);
          this.actions.loadingAll(false);
        },
        error: () => {
          this.actions.loadingAll(false);
        },
      });
    },

    updateStage(assignment_id, compound_id, stage) {
      Api.VOLUNTEER.UPDATE_STAGE.put({
        urlArgs: { assignment_id },
        data: _.jsonStringify({ stage }),
        success: (res) => {
          EverTrue.Alert.success("Stage updated.");
          this.actions.updatedStage(compound_id, res);
        },
        error(xhr) {
          EverTrue.Alert.error("Error updating stage.");
          ErrorLogger.captureRequest("STAGE UPDATE", xhr);
        },
      });
    },

    updateKeyProspect(assignment_id, isKeyProspect) {
      Api.VOLUNTEER.KEY_PROSPECT.put({
        urlArgs: { assignment_id },
        params: { key_prospect: isKeyProspect },
        success: (res) => {
          _.delay(() => this.fetch([assignment_id]), 1000);
          EverTrue.Alert.success("Prospect marked successfully");
        },
        error(xhr) {
          EverTrue.Alert.error("Marking prospect failed");
        },
      });
    },
  },
});
