import { PropTypes } from "base/react-libs";
import { trackJourneysAction } from "apps/journeys/utils/helpers/mixpanel";
import { getStepIconComponent } from "apps/journeys/utils/helpers/icon-fetch";
import { Button, Icon, ButtonTooltip } from "@evertrue/et-components";
import StepRowActionButton from "./step-row-action-button";
import ProgressBarWithLabel from "components/forms/progress-bar-with-label";

const StepRowSummary = ({
  step,
  journey,
  isExpanded,
  setIsExpanded,
  userIsCreator,
  completionPercentage,
  optionalStateManagementFunction = () => {},
}) => {
  const { description: stepDescription, default_action_type: actionType } = step;
  const numberOfConstituents = step.tasks ? step.tasks.length : 0;
  const constituentLabel = numberOfConstituents === 1 ? "Constituent" : "Constituents";

  return (
    <div className="journeys-task-table-row--summary">
      <div style={{ flex: 4 }} className="journeys-task-table-row--summary-container">
        <div className="journeys-task-table-row-icon">{getStepIconComponent(actionType)}</div>
        <div className="journeys-task-table-row--summary-details">
          <div className="journeys-task-table-row--sub-header">Step {step.step_number}</div>
          <div className="bold-text">
            {step.title}
            {stepDescription && (
              <span data-testid="tooltip-icon">
                <ButtonTooltip trigger={<i className="gt-icon gt-icon-info tooltip-icon" />}>
                  {stepDescription}
                </ButtonTooltip>
              </span>
            )}
          </div>
        </div>
      </div>
      <>
        {!!numberOfConstituents && (
          <ProgressBarWithLabel
            title={`${numberOfConstituents} ${constituentLabel}`}
            showPercent={true}
            weight="thin"
            percent={completionPercentage}
            progressColor="#73c166"
            width={50}
            containerStyle={{ flex: 4 }}
          />
        )}
        <div style={{ flex: 1 }} className="journey-task-table-row--action-buttons">
          {!!numberOfConstituents && (
            <Button
              type="simple"
              onClick={() => {
                trackJourneysAction("expand_step");
                setIsExpanded(!isExpanded);
              }}
            >
              <Icon icon={isExpanded ? "chevron-up" : "chevron-down"} size={2} />
            </Button>
          )}
          {userIsCreator && (
            <StepRowActionButton
              journey={journey}
              existingJourneyTask={step}
              optionalStateManagementFunction={optionalStateManagementFunction}
            />
          )}
        </div>
      </>
    </div>
  );
};

StepRowSummary.propTypes = {
  step: PropTypes.object,
  journey: PropTypes.object,
  isExpanded: PropTypes.bool,
  setIsExpanded: PropTypes.func,
  userIsCreator: PropTypes.bool,
  completionPercentage: PropTypes.number,
  optionalStateManagementFunction: PropTypes.func,
};

export default StepRowSummary;
