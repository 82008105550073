import { useEffect, useState, useRef } from "react";
import { formatIncompletePhoneNumber } from "libphonenumber-js";
import useTwilio from "../voip-services/twilio/hooks/use-twilio";

const useDialer = (contactId) => {
  const phoneRef = useRef();
  const extensionRef = useRef();

  const [phoneNumber, setPhoneNumber] = useState("");
  const [extension, setExtension] = useState("");
  const [phoneFocus, setPhoneFocus] = useState(true);
  const [extFocus, setExtFocus] = useState(false);
  const [notes, setNotes] = useState("");
  const [callEnded, setCallEnded] = useState(false);

  const onCallEnd = () => {
    setCallEnded(true);
  };

  const { TwilioCallButtonWithContext, activeCall } = useTwilio(extension, phoneNumber, contactId, onCallEnd);

  useEffect(() => {
    if (phoneRef.current) phoneRef.current.focus(); // set focus on input on load
  }, []);

  const onPhoneFocus = () => {
    setExtFocus(false);
    setPhoneFocus(true);
  };

  const onExtFocus = () => {
    setPhoneFocus(false);
    setExtFocus(true);
  };

  const formatPhoneNumber = (value) => {
    if (!value) return "";
    // hack to handle backspacing
    if (value.includes("(") && !value.includes(")")) {
      return value.replace("(", "");
    }

    if (activeCall) return value;
    return formatIncompletePhoneNumber(value, "US");
  };

  const handleInput = (val, usingDialer = false) => {
    if (activeCall) !!val && activeCall.sendDigits(val); // for inputting data during a call
    if (phoneFocus) handlePhoneChange(val, usingDialer);
    else if (extFocus) handleExtensionChange(val, usingDialer);
  };

  const handleDropdownInput = (val) => {
    const trimmed = val.trim();
    if (trimmed.includes("ext.")) {
      const [phone, ext] = trimmed.split("ext.");
      setPhoneNumber(phone);
      setExtension(ext);
    } else {
      setPhoneNumber(trimmed);
      setExtension("");
    }
  };

  const handlePhoneChange = (val, usingDialer = false) => {
    let updatedPhoneNumber;

    if (usingDialer) {
      const num = !!phoneNumber ? phoneNumber : "";
      if (!val) updatedPhoneNumber = num.slice(0, -1); // backspace button
      else updatedPhoneNumber = num + val; // number button
    } else {
      // typing input
      updatedPhoneNumber = val;
    }

    setPhoneNumber(formatPhoneNumber(updatedPhoneNumber));

    setPhoneFocus(true);
    phoneRef.current.focus();
  };

  const handleExtensionChange = (val, usingDialer = false) => {
    let updatedExtension;

    if (usingDialer) {
      const ext = !!extension ? extension : "";
      if (!val) updatedExtension = ext.slice(0, -1); // backspace button
      else updatedExtension = ext + val; // number button
    } else {
      // typing input
      updatedExtension = val;
    }
    // validateNumber(updatedPhoneNumber);

    setExtension(updatedExtension);

    setExtFocus(true);
    extensionRef.current.focus();
  };

  const handleNoteChange = (value) => {
    setNotes(value);
  };

  return {
    phoneNumber,
    extension,
    phoneRef,
    extensionRef,
    notes,
    onPhoneFocus,
    onExtFocus,
    handleInput,
    handleDropdownInput,
    CallerButton: TwilioCallButtonWithContext,
    handleNoteChange,
    callEnded,
    setCallEnded,
  };
};

export default useDialer;
