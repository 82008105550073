module.exports = do ->
   EverTrue = require("app")
   User = require("entities/auth/user")
   {createStore} = require("@evertrue/et-flux")
   LoginSource = require("apps/login/sources/login-source")
   SessionSource = require("base/session/session-source")


   createStore "LoginStore",
      getInitialState: ->
         invite_token: undefined
         temp_linkedin_access_token: undefined
         error: ""

      registerActions: ->
         @on LoginSource.actions.fetchedAccessToken, @respondToFetchedAccessToken
         @on LoginSource.actions.activateToken, @respondToAssociate

         @on LoginSource.actions.setInviteToken, @respondToSetInvite
         @on LoginSource.actions.clearedInviteToken, @respondToClearedInvite

         @on LoginSource.actions.resetToken, @respondToTokenReset

         @on LoginSource.actions.error, (error) => @setState({ error })

      respondToFetchedAccessToken: (token) ->
         EverTrue.auth.lids_token = token # required for session
         @setState {temp_linkedin_access_token: token}
         LoginSource.activate(token)

      respondToAssociate: ->
         # case where user has to refresh li session
         # in an SSO org, shouldn't create new session
         if EverTrue.store.org.get("sso_method") is "saml"
            EverTrue.Navigator "/"
         else
            @createSession()

      respondToSetInvite: (token) ->
         @setState {invite_token: token}
         EverTrue.store.push "invite_token", token, {cache: true}

      respondToClearedInvite: ->
         @setState {invite_token: undefined}

      respondToTokenReset: ->
         @setState {temp_linkedin_access_token: undefined}

      createSession: (user_id) ->
         lids_token = @getState("temp_linkedin_access_token")
         user_id ?= EverTrue.store.user?.get("id")
         SessionSource.createSessionWithLidsToken(user_id, lids_token)

      api:
         getInviteToken: ->
            @getState("invite_token")

         getError: ->
            @getState("error")
