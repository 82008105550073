module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")


   createSource "FilterSource",
      actions:
         cancel: true
         addFilter: true
         clearedFilters: true
         fetchFilteredClusters: true
         setFilters: (filters) ->
            @require _.isArray(filters) || _.isUndefined(filters), "filters should be an array or undefined"
         updatedFilter: (filter_row_id, filter_obj) ->
            @require !_.isUndefined(filter_row_id) && _.isNumber(filter_row_id), "filter_row_id is required and should be number"
            @require _.isObject(filter_obj), "filter_obj is object"
         removedFilter: (filter_row_id) ->
            @require !_.isUndefined(filter_row_id), "filter_row_id is required"
         updatedOperator: (key) ->
            @require (key == "and" || key == "or"), "operator should be and/or"
         updatedMapFilter: (map_obj) ->
            @require _.isObject(map_obj), "map_obj should be object"
         parseAndSet: (hash_id, filter_rep, criteria) ->
            @require _.isString(hash_id), "hash_id should be string"
            @require _.isArray(filter_rep), "filter_rep should be array"
            @require _.isObject(criteria), "object should be object"
         loading: (is_loading) ->
            @require _.isBoolean(is_loading), "is_loading should be a boolean"

      api:
         add: ->
            @actions.addFilter()

         clear: ->
            @actions.clearedFilters()

         set: (filters) ->
            @actions.setFilters(filters)

         update: (filter_row_id, filter_object) ->
            @actions.updatedFilter(_.toNumber(filter_row_id), filter_object)

         remove: (filter_row_id) ->
            @actions.removedFilter(filter_row_id)

         updateOperator: (key) ->
            @actions.updatedOperator(key)

         updateMapFilter: (map_obj) ->
            @actions.updatedMapFilter(map_obj)

         fetchFilteredClusters: ->
            @actions.fetchFilteredClusters()

         parseAndSet: (hash_id, filter_rep, criteria) ->
            @actions.parseAndSet(hash_id, filter_rep, criteria)

         loading: (is_loading) ->
            @actions.loading(is_loading)

         cancel: ->
            @actions.cancel()
