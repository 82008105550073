import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Icon, AdvancedCombobox, Radio } from "@evertrue/et-components";
import SolicitorDropdown from "apps/portfolio-performance/components/solicitor-dropdown";

export const ProposalFormSolicitors = ({
  onFormChange = () => {}, //function signature is onFormChange("fieldName", value)
  proposal = {},
  solicitorLimit = 1,
  solicitorTitles = [],
  standardFieldsConfig,
}) => {
  const [defaultToUser, setDefaultToUser] = useState(true);
  const { solicitor_title } = standardFieldsConfig;

  useEffect(() => {
    if (proposal.solicitors.length) setDefaultToUser(false);
  }, [proposal.solicitors.length]);

  const getPrimaryIfNone = (solicitors) => {
    const solicitorsWithPrimary = [...solicitors];
    if (!solicitorsWithPrimary.length) return solicitorsWithPrimary;
    const primary = solicitorsWithPrimary.find((solicitor) => solicitor.type === "PRIMARY");
    if (!primary) solicitorsWithPrimary[0].type = "PRIMARY";
    return solicitorsWithPrimary;
  };

  const handleRemoveSolicitor = (contactId) => {
    const solicitors = proposal.solicitors.filter((solicitor) => solicitor.contact_id !== contactId);
    const solicitorsWithPrimary = getPrimaryIfNone(solicitors);
    onFormChange("solicitors", solicitorsWithPrimary);
  };

  const handleSolicitorTypeChange = (contactId, type) => {
    const solicitors = proposal.solicitors.map((solicitor) => {
      if (solicitor.contact_id === contactId) {
        return {
          ...solicitor,
          type: "PRIMARY",
        };
      }
      return {
        ...solicitor,
        type: "SECONDARY",
      };
    });

    onFormChange("solicitors", solicitors);
  };

  const handleSolicitorTitleChange = (contactId, title) => {
    const solicitors = proposal.solicitors.map((solicitor) => {
      if (solicitor.contact_id === contactId) {
        return {
          ...solicitor,
          title: title.value,
        };
      }
      return solicitor;
    });

    onFormChange("solicitors", solicitors);
  };

  const handleAddSolicitor = (solicitor) => {
    const solicitors = [
      ...proposal.solicitors,
      {
        contact_id: solicitor.value,
        name: solicitor.label,
        type: "SECONDARY",
      },
    ];

    const solicitorsWithPrimary = getPrimaryIfNone(solicitors);
    onFormChange("solicitors", solicitorsWithPrimary);
  };

  return (
    <>
      <div className="proposal-form--header">
        Solicitors <span>{` (max ${solicitorLimit})`}</span>
      </div>
      <ul>
        {proposal.solicitors.map((solicitor) => (
          <li key={solicitor.contact_id} className="proposal-form--list-row ">
            <div className="proposal-form--solicitor-column">
              <Radio
                label={`Is ${solicitor.name} the primary solicitor`}
                showLabel={false}
                checked={solicitor.type === "PRIMARY"}
                onChange={(isPrimary) => {
                  const type = isPrimary ? "PRIMARY" : "SECONDARY";
                  handleSolicitorTypeChange(solicitor.contact_id, type);
                }}
                name="is_primary"
              />
              <div>
                <strong>{solicitor.name}</strong>
                {solicitor.type === "PRIMARY" && <div>Primary</div>}
              </div>
            </div>
            <div className="proposal-form--solicitor-column">
              <div className="proposal-form--solicitor-required">
                {solicitor_title.required && !solicitor.title && (
                  <label className="form-field--label-wrap">
                    <span className="form-field--required">Required</span>
                  </label>
                )}
                <AdvancedCombobox
                  className="proposal-form--solicitor-title"
                  options={solicitorTitles}
                  value={{ value: solicitor.title }}
                  placeholder="Select a title..."
                  onChange={(title) => handleSolicitorTitleChange(solicitor.contact_id, title)}
                />
              </div>
              <Button
                type="simple"
                onClick={() => handleRemoveSolicitor(solicitor.contact_id)}
                label="Remove solicitor"
              >
                <Icon
                  icon="cancel"
                  className="proposal-form--cancel"
                  title={`Remove solicitor, ${solicitor.name}`}
                  size={1}
                />
              </Button>
            </div>
          </li>
        ))}
        <li className="proposal-form--add-item-row">
          <div className="proposal-form--form-field-dropdown">
            <SolicitorDropdown
              placeholder="Select a solicitor to add..."
              label={`Select solicitor to add to proposal. You must add at least one and a max of ${solicitorLimit}`}
              onChange={(solicitor) => handleAddSolicitor(solicitor)}
              multiple={false}
              defaultToUser={defaultToUser}
              disabled={proposal.solicitors.length >= solicitorLimit}
              excludeIds={proposal.solicitors.map((solicitor) => solicitor.contact_id)}
            />
          </div>
          <div>{proposal.solicitors.length === 0 && <span className="form-field--required">Required</span>}</div>
        </li>
      </ul>
    </>
  );
};

ProposalFormSolicitors.propTypes = {
  onFormChange: PropTypes.func,
  proposal: PropTypes.object,
  solicitorLimit: PropTypes.number,
  solicitorTitles: PropTypes.array,
  standardFieldsConfig: PropTypes.object,
};

export default ProposalFormSolicitors;
