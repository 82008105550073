import { Component } from "react";
import _ from "underscore";
import PropTypes from "prop-types";
import { Loading, Modal2 } from "@evertrue/et-components";
import PoolsResolver from "apps/volunteers/components/pools-resolver";
import SelectorMenu from "components/controls/selector-menu";
import BulkAddToPoolModal from "apps/volunteers/components/bulk-add-to-pool-modal";
import PoolFormModal from "apps/volunteers/components/pool-form-modal";
import WindowSource from "apps/layout/sources/window-source";

class PoolSelectorController extends Component {
  static propTypes = {
    filterParams: PropTypes.object,
    totalResultsCount: PropTypes.number,
    contactIds: PropTypes.array,
    allSelected: PropTypes.bool,
    setModal: PropTypes.func.isRequired,
    isFiltersV2: PropTypes.bool,
    closePopover: PropTypes.func, // not calling this b/c of bug w/ Popover/Modal2
  };

  static defaultProps = {
    allSelected: false,
    contactIds: [],
    isFiltersV2: false,
    closePopover: _.noop,
  };
  closeModal = () => {
    this.props.setModal(null);
  };

  render() {
    // this is a portal opened from a portal so we pass up to toolbar to render
    const openModal = (poolId) => {
      return this.props.setModal(
        <Modal2 isOpen={true} onClose={this.closeModal}>
          <BulkAddToPoolModal
            allSelected={this.props.allSelected}
            contactIds={this.props.contactIds}
            totalResultsCount={this.props.totalResultsCount}
            handleClose={this.closeModal}
            poolId={poolId}
            filterParams={this.props.filterParams}
            closePopover={this.props.closePopover}
            isFiltersV2={this.props.isFiltersV2}
          />
        </Modal2>
      );
    };
    const openPoolFormModal = (query) => {
      return this.props.setModal(
        <Modal2 isOpen={true} onClose={this.closeModal}>
          <PoolFormModal
            pool={{ name: query || "" }}
            onClose={this.closeModal}
            afterCreateCb={(poolId) => {
              // open after b/c of rendering up in parent, make sure it gets the id
              openModal(poolId);
            }}
          />
        </Modal2>
      );
    };
    return (
      <div className="pool-selector">
        <PoolsResolver
          fetchBulk
          render={({ pools = {}, total, loadMore, loading }) => (
            <>
              <SelectorMenu
                isDisabled={!this.props.contactIds.length && !this.props.allSelected}
                label="pool"
                loadMore={total !== Object.keys(pools).length ? loadMore : false}
                onCreate={(query) => {
                  WindowSource.outsideClick();

                  openPoolFormModal(query);
                }}
                onSelect={(query) => {
                  // open after b/c of rendering up in parent, make sure it gets the id
                  openModal(query.value);
                }}
                options={[
                  {
                    value: "pools",
                    label: "Pools",
                    items: _.chain(pools)
                      .map((pool) => ({ value: pool.id, label: pool.name }))
                      .sortBy("label")
                      .value(),
                  },
                ]}
              />
              {loading && <Loading size="small" />}
            </>
          )}
        />
      </div>
    );
  }
}

export default PoolSelectorController;
