import _ from "underscore";
import { Component, Fragment } from "react";
import PropTypes from "prop-types";
import EverTrue from "app";
import { connect } from "@evertrue/et-flux";
import PoolSolicitorsSource from "apps/volunteers/sources/pool-solicitors-source";
import PoolSolicitorsStore from "apps/volunteers/stores/pool-solicitors-store";
import Loading from "components/elements/loading";
import { Icon, LinkButton } from "@evertrue/et-components";
import EmptyStateMessage from "components/lists/empty-state-message";
import PoolSolicitorCard from "apps/volunteers/components/pool-solicitor-card";
import PoolContactList from "apps/volunteers/components/pool-contact-list";
import SolicitorPanel from "apps/volunteers/components/solicitor-panel";
import CountOf from "components/formatting/count-of";
import BulkAddStore from "apps/layout/stores/bulk-add-store";

const _debounceSearch = _.debounce(PoolSolicitorsSource.filterByName, 300);

const mapStateToProps = (props) => ({
  static_total: PoolSolicitorsStore.getStaticTotal(props.poolId),
  selected: PoolSolicitorsStore.getSelected(props.poolId),
  has_more: PoolSolicitorsStore.hasMoreToLoad(props.poolId),
  loading: PoolSolicitorsStore.getLoading(),
  loading_more: PoolSolicitorsStore.getLoadingMore(),
  query: PoolSolicitorsStore.getFilter(),
  bulk_add_obj: BulkAddStore.getIsBulkAddToPoolInProgress(props.poolId),
});

class PoolSolicitorsController extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    query: PropTypes.string,
    bulk_add_obj: PropTypes.object,
    loading_more: PropTypes.bool,
    has_more: PropTypes.bool,
    selected: PropTypes.object,
    static_total: PropTypes.number,
    solicitors: PropTypes.object,
    poolId: PropTypes.number,
    pool: PropTypes.object,
  };

  static defaultProps = {
    solicitors: {},
    bulk_add_obj: {},
  };

  componentDidMount() {
    if (this.props.poolId) {
      PoolSolicitorsSource.fetch(this.props.poolId);
      PoolSolicitorsSource.fetchSample(this.props.poolId);
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.poolId !== this.props.poolId) {
      PoolSolicitorsSource.fetch(this.props.poolId);
      PoolSolicitorsSource.fetchSample(this.props.poolId);
    }
  }

  componentWillUnmount() {
    PoolSolicitorsSource.select();
    PoolSolicitorsSource.filterByName(this.props.poolId);
  }

  handleSearch = (val) => {
    _debounceSearch(this.props.poolId, val);
  };

  handlePaginate = () => {
    PoolSolicitorsSource.paginate(this.props.poolId);
  };

  handleSelect = (id) => {
    PoolSolicitorsSource.select(id);
  };

  handleAddSolicitors = (contact_ids) => {
    PoolSolicitorsSource.addSolicitors(this.props.poolId, contact_ids);
  };

  handleRemove = (solicitor_id, contact_id) => {
    EverTrue.Alert.confirm(
      {
        headline: "Are you sure?",
        content: "Removing this volunteer will remove access to their assignments.",
      },
      (did_confirm) => {
        if (did_confirm) {
          return PoolSolicitorsSource.removeSolicitor(this.props.poolId, solicitor_id, contact_id);
        }
      }
    );
  };

  render() {
    const is_bulk_add = this.props.bulk_add_obj.in_progress && this.props.bulk_add_obj.type === "solicitors";
    return (
      <div className="pool-solicitors">
        {this.props.selected ? (
          <SolicitorPanel poolId={this.props.poolId} contact={this.props.selected} onClose={this.handleSelect} />
        ) : (
          <PoolContactList
            className="pool-solicitors--list"
            title={
              <span>
                Volunteers
                <CountOf dynamicTotal={this.props.solicitors.total} staticTotal={this.props.static_total} />
              </span>
            }
            label="Volunteers"
            search={this.props.query}
            addLimit={100}
            onSearch={this.handleSearch}
            onAdd={this.handleAddSolicitors}
          >
            <Fragment>
              {this.props.loading && <Loading />}
              {(this.props.loading || is_bulk_add) && <Loading text={is_bulk_add ? "Adding Volunteers to Pool" : ""} />}
              {!this.props.solicitors.total && !this.props.loading ? (
                <EmptyStateMessage icon="person" text="No Volunteer Solicitors">
                  Add constituents as volunteer solicitors.
                </EmptyStateMessage>
              ) : (
                <div className="pool-contact--list-content">
                  {_.map(this.props.solicitors.items, (contact) => {
                    return (
                      <PoolSolicitorCard
                        pool={this.props.pool}
                        key={contact.id}
                        contact={contact}
                        poolId={this.props.poolId}
                        className="pool-solicitors--card"
                        onClick={() => this.handleSelect(contact.id)}
                        onRemove={(solicitor_id) => this.handleRemove(solicitor_id, contact.id)}
                      />
                    );
                  })}
                  {this.props.has_more && (
                    <LinkButton className="pool-solicitors--pagination" onClick={this.handlePaginate} title="View More">
                      {this.props.loading_more ? <Icon icon="spinner" spin={true} /> : "View More"}
                    </LinkButton>
                  )}
                </div>
              )}
            </Fragment>
          </PoolContactList>
        )}
      </div>
    );
  }
}

export default connect(PoolSolicitorsController, [BulkAddStore, PoolSolicitorsStore], mapStateToProps);
