import { useContext } from "react";
import PropTypes from "prop-types";
import EverTrue from "app";
import { FiltersDataContext } from "apps/query-builder/contexts/filters-data-context";
import QuickFilterInputComponent from "apps/query-builder/components/quick-filter-input-component";
import SelectedContactsSource from "apps/contact/sources/selected-contacts-source";
import { GlobalContactSearchContext } from "apps/layout/controllers/global-contact-search-context";

const QuickFilters = ({ appliedFilters, onChange }) => {
  const { quickFilters } = useContext(FiltersDataContext);
  const { setIsAllSelectedCheckbox } = useContext(GlobalContactSearchContext);

  const isEmpty = (value) => {
    const isEmptyArray = Array.isArray(value) && !value.length;
    const isUndefined = value === undefined;
    const isNull = value === null;

    return isEmptyArray || isUndefined || isNull;
  };

  const getFilterValue = (compound_id) => {
    const filter = appliedFilters.quick_filters.find((filter) => filter.compound_id === compound_id);
    return filter ? filter.value : null;
  };

  const handleChange = (change, compound_id, operator_query_key, label) => {
    const filters = appliedFilters.quick_filters.filter((filter) => filter.compound_id !== compound_id);
    SelectedContactsSource.clear();
    setIsAllSelectedCheckbox(false);

    if (isEmpty(change)) {
      onChange(filters);
    } else {
      EverTrue.track.set("filter_v2_action", {
        type: "quick_filter_set",
        quick_filter: label,
      });

      onChange([
        {
          compound_id: compound_id,
          operator: operator_query_key,
          value: change,
        },
        ...filters,
      ]);
    }
  };

  return (
    <div className="quick-filters">
      {quickFilters &&
        quickFilters.map((filter) => {
          const { ui_component, operator_query_key } = filter.operators[0];
          return (
            <QuickFilterInputComponent
              key={filter.compound_id}
              filter={filter}
              uiComponent={ui_component}
              value={getFilterValue(filter.compound_id)}
              operator={operator_query_key}
              onChange={(change) => handleChange(change, filter.compound_id, operator_query_key, filter.label)}
            />
          );
        })}
    </div>
  );
};

QuickFilters.propTypes = {
  appliedFilters: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default QuickFilters;
