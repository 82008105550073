module.exports = do ->
   _ = require("underscore").default
   {createStore} = require("@evertrue/et-flux")
   RecentContactSource = require("apps/contact/sources/recent-contact-source")
   PaginationUtils = require("mixins/pagination-utils")

   createStore "RecentContactStore",

      getInitialState: ->
         contacts: {}
         laoding: false

      registerActions: ->
         @on RecentContactSource.actions.loading, @respondToLoading
         @on RecentContactSource.actions.changedPage, @respondToChangedPage
         @on RecentContactSource.actions.fetchedRecentContacts, @respondToFetchedRecentContacts

      respondToLoading: (loading) ->
         @setState loading: loading

      respondToChangedPage: (page) ->
         contacts = @getState("contacts")
         param_obj = {limit: contacts.limit, total: contacts.total, page: page}
         RecentContactSource.fetch param_obj

      respondToFetchedRecentContacts: (contacts) ->
         contacts = _.extend contacts, page: PaginationUtils.getPage(contacts)
         @setState contacts: contacts

      api:
         getContacts: -> @getState("contacts")

         getLoading: -> @getState("loading")
