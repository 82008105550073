_ = require("underscore").default
React = require("react")
{number, array, func} = ReactLibs.PropTypes
EverTrue = require("app")
{createComponent} = require("@evertrue/et-flux")
{div} = ReactLibs.DOMFactories
Api = require("entities/helpers/api")
EventDetailStore = require("apps/events/stores/event-detail-store")
UnmatchedTableToolbar = require("apps/events/components/unmatched-engagers/events-unmatched-toolbar")
FixedTable = require("components/tables/fixed-table")
{createFactory} = require("base/new-utils")
UnmatchedEngagementsResolver = createFactory(require("apps/events/components/unmatched-engagers/unmatched-engagements-resolver").default)
EmptyStateMessage = createFactory(require("components/lists/empty-state-message"))
Pagination = require("components/controls/pagination")
Layout = createFactory require("components/layout").default
{ getEngagementActionsByFilterKeys } = require("apps/events/utils/engagement-config")

getEventbriteUnmatchedTableConfig = require("apps/events/configs/eventbrite-unmatched-table-config")

LIMIT = 25

SHOWN_STATES = ["UNMATCH_QUEUED", "MATCH_QUEUED", "UNMATCHED", "ERROR"]

module.exports =
   createComponent "EventsUnmatchedController",

      PropTypes:
         event_id: number
         activeFilters: array
         handleToggleChange: func
         footer: func

      registerStores: ->
         @on EventDetailStore, ->
            search: EventDetailStore.getConstituentSearch()

      getInitialState: ->
         page: 1
         profiles: []
         profiles_loading: false

      getDefaultProps: ->
         event_id: null
         activeFilters: []
         handleToggleChange: _.noop
         footer: _.noop

      UNSAFE_componentWillMount: ->
         # this configures the config to ensure that EventBrite profiles
         # will be refreshed after a match occurs
         @config = getEventbriteUnmatchedTableConfig({
            onMatchCompleted: => @fetchEventbriteUnmatched()
         })

      componentDidMount: ->
         @fetchEventbriteUnmatched()

      componentDidUpdate: (prevProps, prevState) ->
         { search, page } = @state
         { activeFilters, event_id } = @props

         if _.any [
            !_.isEqual(prevProps.activeFilters, activeFilters)
            prevProps.event_id != event_id
            prevState.search != search
         ]
            @setState { page: 1 }, @fetchEventbriteUnmatched

         else if page != prevState.page
            @fetchEventbriteUnmatched()

      fetchEventbriteUnmatched: ->
         { activeFilters } = @props
         response_type = getEngagementActionsByFilterKeys(activeFilters)
         @setState profiles_loading: true

         Api.EVENTS.EVENTBRITE_PROFILES.get
            params: _.compactObject({
               response_type: response_type
               limit: LIMIT
               offset: (@state.page - 1) * LIMIT
               match_status: SHOWN_STATES
               event_id: [@props.event_id]
               search: @state.search || null
            })
            success: ({items, total}) =>
               @setState { profiles: items, total, profiles_loading: false }
            error: (error) =>
               @setState profiles_loading: false
               EverTrue.Alert.error "Problem fetching fetching unmatched engagers"

      handlePageChange: (page) ->
         @setState { page }

      renderPagination: ->
         { total, page } = @state
         if (total / LIMIT) > 1
            div className: "events-unmatched-controller--footer",
               Pagination
                  className:"events-unmatched-controller--pagination"
                  page: page
                  total: total / LIMIT
                  onChange: @handlePageChange

      renderEmptyState: ->
         EmptyStateMessage
            icon: "search"
            text: "Your search didn't return any responses"

      render: ->
         { profiles, profiles_loading} = @state
         { event_id } = @props
         header = UnmatchedTableToolbar handleToggleChange: @props.handleToggleChange

         div className: "events-unmatched-controller",
            Layout { header },
               UnmatchedEngagementsResolver
                  profiles: profiles
                  event_id: event_id
                  render: ({ profilesWithEngagements, loading }) =>
                     div className: "events-unmatched-controller--unmatched-table",
                        FixedTable
                           data: profilesWithEngagements
                           config: @config
                           emptyMessage: @renderEmptyState()
                           appendSpaceColumn: false
                           loading: profiles_loading || loading
                           footer: @renderPagination()
