import PropTypes from "prop-types";
import Decorator from "clientDecorator";
import { mostRecentUpdate } from "apps/profile/components/enrichment/enrichment-utils";
import { Icon, useOpenClose, Modal, Button } from "@evertrue/et-components";
import ProfileCardHeader from "apps/profile/components/profile-card-header";
import BioEditModal from "apps/profile/components/bio-edit-modal";
import BioItems from "apps/profile/components/enrichment/bio-items";
import LifeChanges from "./life-changes";
import EnrichmentModalHeader from "apps/profile/components/enrichment/enrichment-modal-header";

const BioEnrichment = ({ contact = {}, enrichmentData = {}, onDelete = () => {} }) => {
  const {
    contact_locations : contactLocations = [],
    enrichment_attributes: enrichmentAttributes = [],
    wealth_life_events : wealthLifeEvents = [],
    employment_events : employmentEvents = [],
  } = enrichmentData;

  const activeLocations = contactLocations.filter((address) => !address.rejected);
  const activeNames = enrichmentAttributes.filter((name) => !name.rejected);

  const [isModalOpen, openModal, closeModal] = useOpenClose();
  const lastUpdatedDate = mostRecentUpdate(
    [
      ...enrichmentAttributes,
      ...contactLocations,
      ...wealthLifeEvents,
      ...employmentEvents,
    ],
    "updated_at",
    "MMM DD, YYYY");

  const lifeChangeLimit = 3;
  const lifeChangeEvents = wealthLifeEvents.concat(employmentEvents);
  const additionalItems = Math.max(lifeChangeEvents.length - lifeChangeLimit, 0) +
                          Math.max(activeNames.length - 1, 0) +
                          Math.max(activeLocations.length - 1, 0);

  return (
    <>
      <ProfileCardHeader title="Summary" updateDate={lastUpdatedDate}>
        {(contactLocations.length > 0 || enrichmentAttributes.length > 0) && (
        <Button type="simple" onClick={isModalOpen ? closeModal : openModal}>
          <Icon title="Edit career information" icon="edit"/>
          <Modal isOpen={isModalOpen} closeModal={closeModal} size="small">
            <EnrichmentModalHeader
              headerText={`Edit ${Decorator.Contacts.getFullName(contact)}'s Bio`}
              updateDate={lastUpdatedDate}
              closeModal={closeModal}
            ></EnrichmentModalHeader>
            <BioEditModal
              contact_id={contact.id}
              enrichmentData={enrichmentData}
              onDelete={onDelete}
            />
          </Modal>
        </Button>
        )}
      </ProfileCardHeader>
      <div className={"profile--enrichment-inner"}>
        <BioItems names={activeNames} locations={activeLocations} />
        <LifeChanges enrichmentData={enrichmentData} limit={lifeChangeLimit} />
        {additionalItems > 0 && (
        <Button className="profile--enrichment--additional-employments" type="simple" onClick={isModalOpen ? closeModal : openModal}>
          View Additional Summary Data ({additionalItems} more)
          <Modal isOpen={isModalOpen} closeModal={closeModal} size="small">
            <EnrichmentModalHeader
              headerText={`Edit ${Decorator.Contacts.getFullName(contact)}'s Bio`}
              updateDate={lastUpdatedDate}
              closeModal={closeModal}
            ></EnrichmentModalHeader>
            <BioEditModal
              contact_id={contact.id}
              enrichmentData={enrichmentData}
              onDelete={onDelete}
            />
          </Modal>
        </Button>
      )}
      </div>
    </>
  );
};

BioEnrichment.propTypes = {
  contact: PropTypes.object,
  enrichmentData: PropTypes.object,
  onDelete: PropTypes.func,
};

export default BioEnrichment;
