module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   AddressMixin = require("mixins/address-mixin")
   Decorator = require("clientDecorator")


   ContactFormatMixin =
      fullName: (data) ->
         Decorator.Contacts.getFullName(data)

      detailedName: (data) ->
         if data
            Decorator.Contacts.getDetailedName(data)

      value: (prop, before="", after="") ->
         if prop?.value
            before + prop?.value + after

      address: (data) ->
         main_address = AddressMixin.getDefaultAddress(data)
         line_1 = (main_address?.address_1?.value || "") + " - "
         line_1 + AddressMixin.formatLastLn(main_address)

      company: (data) ->
         position = Decorator.Work.getCurrentCompany(data)
         position?.name

      company_link: (data, company_data) ->
         company = company_data || Decorator.Work.getCurrentCompany(data)
         "?" + $.param({name: company?.name})

      # Get link from Decorator company object instead
      getCompanyLink: (company_object={}) ->
         "?" + $.param({name: company_object.name})

      industry: (data) ->
         position = Decorator.Work.getCurrentCompany(data)
         position?.industry

      industry_link: (industry) ->
         "?" + $.param({name: industry})

      role: (data) ->
         values = []
         constituencies = data.constituencies
         if constituencies
            values = _.compact _.map constituencies, (field) -> field?.status?.value
         else
            constituentProperties = ["constituency", "constituencies", "constituentcode", "consttype"]
            _.find constituentProperties, (prop) ->
               customField = data[prop + "_list"]
               if customField then values = _.compact _.map customField, (field) -> field?[prop]?.value
               customField

         if _.isEmpty(values) then undefined else values.join(", ")

      roleWithYear: (data) ->
         role = @role(data)
         year = @value(data?.properties?.year, "Class of ")
         (role || "") + (if role && year then " - " else "") + (year || "")

      deceased: (data) ->
         if data?.properties?.deceased?.value
            deceased = "Deceased"
            if data?.properties?.dt_deceased?.value
               deceased += " (#{data.properties.dt_deceased.value})"
         deceased

      url: (data) ->
         if data?.id
            "/contact/#{data.id}"

      assignee: (data) ->
         _.compact _.map data.assignees, (value) ->
            value?.name?.value

      giving: (data, prop) ->
         data?.giving?[prop]?.value

      score: (data) ->
         data?.score?.score?.value

      facebook: (data, prop) ->
         if _.isNumber(data?.facebook?[prop]?.value) then data.facebook[prop].value else 0

      customValue: (data, prop) ->
         _.first(data[prop + "_list"])?[prop]?.value

      constituencies: (data) ->
         if data.constituencies
            _.map data.constituencies, (constituency) ->
               constituency?.status?.value || constituency?.name?.value
         else null

      solicitationCodes: (data) ->
         if data.solicitation_codes
            _.map data.solicitation_codes, (solicitation) ->
               solicitation.code?.value
         else null

      getStatusProp: (data) ->
         statusProperties = ["status", "prospectstatus"]
         properties = _.map statusProperties, (prop) =>
            if @customValue(data, prop) then prop
         _.first(_.compact(properties))

      status: (data) ->
         property = @getStatusProp(data)
         @customValue(data, property)

      prospectStatus: (data) ->
         statuses = _.map data?.assignees, (assignee) ->
            assignee.status?.value

         _.first(_.compact(statuses))
      
      graduationYear: (data) ->
         data?.properties?.year?.value
