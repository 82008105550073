import { useEffect, useState } from "react";
import { ALL_ACTIVITY, PORTFOLIO_ACTIVITY } from "apps/dashboard/constants";

const usePools = (team, teams) => {
  const [poolId, setPoolId] = useState(null);
  const [poolIds, setPoolIds] = useState(null);

  useEffect(() => {
    if (team === ALL_ACTIVITY.value) {
      if (teams.length === 1) {
        setPoolId(teams[0].value);
      } else {
        setPoolId(null);
      }
      setPoolIds(null);
    } else if (team === PORTFOLIO_ACTIVITY.value) {
      setPoolId(null);
      setPoolIds(teams.map((option) => option.value));
    } else {
      setPoolId(team);
      setPoolIds([team]);
    }
  }, [team, teams]);

  return [poolId, poolIds];
};

export default usePools;
