module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   MarkerWithLabel = require("markerwithlabel")(google?.maps)
   {createComponent} = require("@evertrue/et-flux")


   createComponent "MapMarker",
      propTypes:
         map: ReactLibs.PropTypes.object
         label: ReactLibs.PropTypes.node
         position: ReactLibs.PropTypes.object
         icon: ReactLibs.PropTypes.string
         size: ReactLibs.PropTypes.object
         isSelected: ReactLibs.PropTypes.bool
         onClick: ReactLibs.PropTypes.func
         onHover: ReactLibs.PropTypes.func
         onLoad: ReactLibs.PropTypes.func

      getDefaultProps: ->
         size: {width: 45, height: 45}

      componentDidMount: ->
         @marker = new MarkerWithLabel
            map: @props.map
            position: new google?.maps?.LatLng(@props.position.lat, @props.position.lng)
            labelContent: @props.label
            labelClass: "map-marker-label"
            labelAnchor: new google?.maps?.Point(@props.size.width / 2, @props.size.height / 2)
            labelStyle: @getLabelStyles(@props)
            icon: @getIconProps(@props)

         google.maps.event.addListener @marker, "click", =>
            @props.onClick?(@props.position, @props.label, @marker, @props.map)

         google.maps.event.addListener @marker, "mouseover", =>
            @marker.setIcon(@getIconProps(@props, "_active"))

         google.maps.event.addListener @marker, "mouseout", =>
            @marker.setIcon(@getIconProps(@props))

         @props.onLoad?(@marker) if @props.map

      UNSAFE_componentWillReceiveProps: (newProps) ->
         if !@props.map && newProps.map
            @marker.setMap(newProps.map)
            @props.onLoad?(@marker)

         if @props.label != newProps.label
            @marker.labelContent = newProps.label
            @marker.label.setContent()

         if _.jsonStringify(newProps.size) != _.jsonStringify(@props.size)
            @marker.labelAnchor = new google?.maps?.Point(newProps.size.width / 2, newProps.size.height / 2)
            @marker.labelStyle = @getLabelStyles(newProps)
            @marker.label.setStyles()
            @marker.label.setAnchor()
            @marker.setIcon(@getIconProps(newProps))

         if @props.position.lat != newProps.position.lat || @props.position.lng != newProps.position.lng
            @marker.setPosition(new google.maps.LatLng(newProps.position.lat, newProps.position.lng))

         if newProps.isSelected != @props.isSelected || newProps.icon != @props.icon
            @marker.setIcon(@getIconProps(newProps))

      componentWillUnmount: ->
         google.maps.event.clearListeners(@marker)
         @marker.setMap(null)

      getIconUrl: (suffix="", props) ->
         props ?= @props
         suffix = if props.isSelected then "_active" else suffix
         props.icon + suffix + ".png"

      getSize: (size) ->
         size = _.extend {width: 45, height: 45}, size
         new google?.maps?.Size(size.width, size.height)

      getLabelStyles: (props) ->
         width: props.size?.width + "px"
         height: props.size?.height + "px"
         lineHeight: props.size?.height + "px"

      getIconProps: (props, suffix) ->
         url: @getIconUrl(suffix, props)
         scaledSize: @getSize(props.size)
         anchor: new google?.maps?.Point(props.size.width / 2, props.size.height / 2)

      shouldComponentUpdate: ->
         false

      render: ->
         null
