module.exports = do ->
   _ = require("underscore").default
   Query = require("entities/search/query-parts/query")

   # QueryContext defines the elastic search document that's being
   # searched. It looks at nested queries to determine if there are any
   # has_child or has_parent relationship and pulls out queries for the same context

   QueryContext = (type, InnerQuery) ->
      _context_type = type
      _query = InnerQuery

      _recursivelyFindContext = (q) ->
         _.compact _.flatten _.map q.parameters(), (param) ->
            if param.isNestedDocument?()
               if param.type?() == _context_type
                  param.query().parameters()
               else if param.query().hasNestedDocument()
                  _recursivelyFindContext(param.query(), param)

      _recursivelyFilter = (q) ->
         _.compact _.map q.parameters(), (param) ->
            if param.isNestedDocument?()
               if param.type?() != _context_type
                  filter_nested_params = _recursivelyFilter(param.query())
                  if !_.isEmpty(filter_nested_params)
                     param.replace(Query(filter_nested_params))
                     param
            else param

      {
         toJSON: ->
            # If the element has a nested child doc, un-nest the parameters
            # so you are doing has_parent: has_child: of the current context
            unnested_query = Query(_recursivelyFindContext(_query))
            unnested_query.merge Query(_recursivelyFilter(_query))
            unnested_query.toJSON()

         query: ->
            _query

         propertyKeys: ->
            _query.propertyKeys()

         properties: ->
            _query.properties()

         type: -> type
      }

   return QueryContext
