import { useState } from "react";
import { PropTypes } from "base/react-libs";
import { Button } from "@evertrue/et-components";
import { trackJourneysAction } from "apps/journeys/utils/helpers/mixpanel";
import JourneyContactCard from "apps/journeys/components/journey-contact-card";
import TaskRowActionButton from "./task-row-action-button";
import CompleteTaskToggle from "apps/tasks/components/complete-task-toggle";
import EmailerDrawerButton from "apps/profile/components/emailer-drawer-button";
import DialerDrawerButton from "components/drawer/dialer-drawer-button";
import { daysFromNow } from "apps/tasks/utils/utils";

const TaskRow = ({
  step = {},
  journey = {},
  task = {},
  handleCompletionClick,
  openContactSummary,
  optionalStateManagementFunction = () => {},
}) => {
  const [activeModal, setActiveModal] = useState(null);
  
  if(!task){
    return null;
  }
  const { contact = {}, action_type } = task || {};
  const { contact_attributes: { name_full: name = "" } = {}, primary_email: { email: primaryMail = "" } = {}, phones = []} = contact || {};
  

  const overdueMessage = (task) => {
    const { due_date } = task;
    if (!due_date) return null;
    const time = new Date(due_date).getTime() / 1000;
    const due = daysFromNow(time);
    if (due.msg) return <div className="journeys-task-table-row--details--due-date-text">{due.msg}</div>;
  };

  return (
    <div key={`task-record-${task.id}`} className="journeys-task-table-row--details">
      <div>
        <CompleteTaskToggle task={task} size="small" onClick={handleCompletionClick} />
        <JourneyContactCard contact={task.contact} currentJourney={journey} />
      </div>
      <div style={{ display: 'flex', gap: '15px' }}>
        {overdueMessage(task)}
        {action_type === "CALL" && (
          <DialerDrawerButton
            drawerTitle={name}
            contact={contact}
            name={name}
            phones={phones}
            activeModal={activeModal}
            setActiveModal={setActiveModal}
          />
        )}
        {action_type === "EMAIL" && (
          <EmailerDrawerButton
            name={name}
            emailAddress={primaryMail}
            contactId={contact.id}
            activeModal={activeModal}
            setActiveModal={setActiveModal}
            contact={contact}
          />
        )}
        <TaskRowActionButton
          journey={journey}
          step={step}
          task={task}
          optionalStateManagementFunction={optionalStateManagementFunction}
        />
        <Button
          disabled={!task || !task.contact}
          onClick={() => {
            trackJourneysAction("view_link");
            openContactSummary(contact);
          }}
          type="simple"
        >
          View
        </Button>
      </div>
    </div>
  );
};

TaskRow.propTypes = {
  step: PropTypes.object.isRequired,
  journey: PropTypes.object.isRequired,
  task: PropTypes.object.isRequired,
  handleCompletionClick: PropTypes.func,
  openContactSummary: PropTypes.func,
  optionalStateManagementFunction: PropTypes.func,
};

export default TaskRow;
