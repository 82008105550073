import PropTypes from "prop-types";
import { NumericInput } from "@evertrue/et-components";
import FormField from "components/forms/form-field";
import CalendarPickerDropdown from "components/controls/calendars/calendar-picker-dropdown";
import moment from "moment";
import { formatDateToUnixWithCurrentTime } from "base/new-utils";

const InteractionFormDxo = ({
  askDate = "",
  askAmount = null,
  description = "",
  handleFormChange = () => {},
  interaction_standard_fields_config = {},
}) => {
  const { dxo_text, ask_date } = interaction_standard_fields_config;

  return (
    <div className="interaction-form--fields">
      <div className="interaction-form--dxo-ask">
        <FormField inlineLabel={false} label="Ask Date" required={ask_date.required}>
          <CalendarPickerDropdown
            value={askDate ? moment(askDate).format("YYYY-MM-DD") : null}
            hideClear={true}
            onChange={(val) => {
              const date = formatDateToUnixWithCurrentTime(val);
              if (date) handleFormChange("ask_date", date);
            }}
          />
        </FormField>
        <FormField inlineLabel={false} label="Ask Amount" required={true}>
          <NumericInput
            integerLimit={10}
            allowDecimal={true}
            value={askAmount}
            placeholder={0}
            maskType="currency"
            onChange={(amount) => {
              handleFormChange("ask_amount", amount);
            }}
            showIcon={true}
          />
        </FormField>
      </div>
      <FormField label="Description" inlineLabel={false} required={dxo_text.required}>
        <textarea
          value={description}
          placeholder="Add a comment"
          onChange={(e) => handleFormChange("text", e.currentTarget.value)}
        />
      </FormField>
    </div>
  );
};

InteractionFormDxo.propTypes = {
  askDate: PropTypes.number,
  askAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  description: PropTypes.string,
  handleFormChange: PropTypes.func,
  interaction_standard_fields_config: PropTypes.object,
};

export default InteractionFormDxo;
