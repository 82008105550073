React = require("react")
EverTrue = require("app")
{createComponent} = require("@evertrue/et-flux")
ProfileStore = require("apps/profile/stores/profile-store")
ProfileSource = require("apps/profile/sources/profile-source")
GateSource = require("apps/layout/sources/gate-source")
FeatureStore = require("apps/layout/stores/feature-store").default
ProfileInteractionsSource = require("apps/profile/sources/profile-interactions-source").default
ProfileSummaryController = require("apps/profile/controllers/profile-summary-controller")
ProfileInteractionsController = require("apps/profile/controllers/profile-interactions-controller")
ProfileEngagementController = require("apps/profile/controllers/profile-engagement-controller")
{createFactory} = require("base/new-utils")
ProposalSearchController = createFactory require("apps/proposals/controllers/legacy-proposal-search-controller").default
ProfileBioController = require("apps/profile/controllers/profile-bio-controller")
ProfileGivingTabController = createFactory require("apps/profile/controllers/profile-giving-tab-controller").default
ProfileEnrichmentController = createFactory require("apps/profile/controllers/profile-enrichment-controller").default
EmptyStateMessage = createFactory(require("components/lists/empty-state-message"))
ProfileHeader = createFactory require("apps/profile/components/profile-header").default
{div} = ReactLibs.DOMFactories
Link = createFactory(require("@evertrue/et-components").Link)
PinnedCommentsProvider = createFactory require("apps/profile/contexts/pinned-comments-context").PinnedCommentsProvider
TasksProvider = createFactory require("apps/tasks/hooks/tasks-context").TasksProvider
TasksController = createFactory require('apps/tasks/controllers/tasks-controller').default

module.exports = createComponent "ProfilePageController",
   propTypes:
      id: ReactLibs.PropTypes.string
      type: ReactLibs.PropTypes.string

   getInitialState: ->
      refetchProposal: 0

   registerStores: ->
      @on ProfileStore, "id", ({id}) ->
         contact: ProfileStore.getProfile(id)
         error: ProfileStore.getProfileError(id)

      @on FeatureStore, ->
         has_features_loaded: FeatureStore.hasFeaturesLoaded()
         has_notes: FeatureStore.hasFeature("ugc_show_imported_notes")

   componentDidMount: ->
      if !@state.has_features_loaded
         GateSource.promise.fetchAll()
            .then => ProfileInteractionsSource.fetch(@props.id)
      ProfileSource.fetch(@props.id)
      ProfileSource.fetchLIDS(@props.id)
      ProfileSource.recordProfileView(@props.id)

   handleBack: ->
      if window.history.length > 1 then window.history.back()
      else EverTrue.Navigator("/contact", true)

   handleRefetchProposals: ->
      @setState((prevState) -> {refetchProposal: prevState.refetchProposal + 1})

   render: ->
      div className: "contact-profile contact-profile-v2",
         if @state.error
            EmptyStateMessage
               className: "contact-profile--not-found"
               icon: "person"
               text: "Constituent not found"
               Link title: "Link back to search", href: "/contact", "Back to Search"
         else
            PinnedCommentsProvider contact: @state.contact,
               TasksProvider referrer: "contact", id: @state.contact.id,
                  div null,
                     ProfileHeader
                        contact: @state.contact
                        tab: @props.type
                        onBack: @handleBack
                        afterProposalSave: @handleRefetchProposals
                        userType: if @props.type == "assignedTask" then "CREATOR_BUT_NOT_OWNER" else "OWNER"

                     div className: (if @props.type == "notes" then "contact-profile--body contact-profile--notes" else "contact-profile--body"),
                        switch @props.type
                           when "bio"
                              ProfileBioController contact: @state.contact
                           when "giving"
                              ProfileGivingTabController contact: @state.contact
                           when "posts"
                              ProfileEngagementController contact: @state.contact
                           when "notes"
                              ProfileInteractionsController contact: @state.contact
                           when "proposals"
                              ProposalSearchController prospectContactId: @state.contact.id, refetch: @state.refetchProposal
                           when "enrichment"
                              ProfileEnrichmentController contact: @state.contact
                           when "task"
                              TasksController contact: @state.contact
                           else
                              ProfileSummaryController contact: @state.contact
