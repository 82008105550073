module.exports = do ->
   React = require("react")
   EverTrue = require("app")
   SessionStore = require("base/session/session-store")
   {createComponent} = require("@evertrue/et-flux")
   {a} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   Icon = createFactory (require("components/elements/icon").default)

   createComponent "ChatLink",
      propTypes:
         onClick: ReactLibs.PropTypes.func

      registerStores: ->
         @on SessionStore, ->
            user: SessionStore.getUser()

      openBeacon: ->
         window.location.href = "mailto:genius@evertrue.com";

      render: ->
         if EverTrue.request("isImpersonating?") then null
         else
            a onClick: @openBeacon,
               Icon
                  className: "help-dropdown--icon"
                  icon: "chat"
                  before: true
                  size: 1
               "EverTrue Support"
