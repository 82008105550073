import _ from "underscore";
import { useState, useContext } from "react";
import PropTypes from "prop-types";
import { AdvancedCombobox } from "@evertrue/et-components";
import StageResolver from "apps/assignments/components/stage-resolver";
import { GlobalPortfolioContext } from "../contexts/global-portfolio-context";

// Used in filters for "prospect status"
// and used in editing assignments from the profile
const StageDropdown = ({ stageGroupId, className, value, multiple, onChange, disabled }) => {
  const { assignmentType } = useContext(GlobalPortfolioContext);
  const [searchText, setSearchText] = useState("");
  return (
    <StageResolver type={assignmentType}>
      {({ stageGroups, loading }) => {
        let stages = [];
        if (stageGroupId) {
          const group = stageGroups[stageGroupId] || {};
          stages = group.stages;
        } else {
          stages = _.flatten(_.pluck(_.values(stageGroups), "stages"));
        }
        const stagesForGroup = _.chain(stages)
          .filter(({ active }) => active)
          .uniq(({ stage }) => stage)
          .value();

        const stage_names = _.uniq(_.pluck(stagesForGroup, "stage"));
        const formatted_stages = _.map(stage_names, (stage_name) => ({ value: stage_name, label: stage_name }));
        const filtered_formatted_stages = _.filter(formatted_stages, (stage) =>
          _.searchCompare(stage.label, searchText)
        );

        return (
          <AdvancedCombobox
            className={className}
            options={filtered_formatted_stages}
            value={value}
            multiple={multiple}
            onChange={onChange}
            onSearch={(search_text) => setSearchText(search_text)}
            loading={loading}
            disabled={disabled}
            searchable
            allowChangeAll
            allowUnselectAll
          />
        );
      }}
    </StageResolver>
  );
};

StageDropdown.propTypes = {
  className: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  stageGroupId: PropTypes.number, // optional stage id from pool if you only want stages specific to a certain pool
};
export default StageDropdown;
