module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   EverTrue = require("app")
   BaseRouter = require("base/base-router")
   MyTripsController = require("apps/my-trips/controllers/my-trips-controller")
   TripController = require("apps/my-trips/controllers/trip-controller")
   {createFactory} = require("base/new-utils")
   CronofySettingsController = createFactory(require("apps/my-trips/controllers/cronofy-settings-controller").default)


   class MyTripsRouter extends BaseRouter
      routes:
         "trips": "allTrips"
         "trips/calendar": "cronofy"
         "trips/:trip_id": "tripPage"
         "trips/:trip_id/:view": "tripPage"

      allTrips: ->
         EverTrue.execute "setBrowserTitle", "Trips"
         @currentView = MyTripsController()
         EverTrue.page.mount @currentView

      tripPage: (trip_id, view) ->
         EverTrue.execute "setBrowserTitle", "Trips"
         if !_.contains(["itinerary", "map"], view) then view = "itinerary"
         @currentView = TripController
            trip_id: _.toNumber(trip_id)
            view: view

         EverTrue.page.mount @currentView

      cronofy: ->
         @currentView = CronofySettingsController()
         EverTrue.page.mount @currentView

   MyTripsRouter
