import { Component } from "react";
import EverTrue from "app";
import ExportSearchController from "apps/export/controllers/export-search-controller";
import Icon from "components/elements/icon";
import ModalTrigger from "components/modals/modal-trigger";
import Modal from "components/modals/modal";
import EventDetailSource from "apps/events/sources/event-detail-source";
import classNames from "classnames";

class ContactExportButton extends Component {
  static propTypes = {
    event_id: ReactLibs.PropTypes.number,
    count: ReactLibs.PropTypes.number,
    filters: ReactLibs.PropTypes.array,
    disabled: ReactLibs.PropTypes.bool,
    className: ReactLibs.PropTypes.string,
  };

  static defaultProps = {
    event_id: 0,
    count: 0,
    filters: ["all"],
    disabled: false,
  };

  state = {
    showModal: false,
  };

  renderExportModal = () => (
    <Modal width={600}>
      <ExportSearchController
        onExport={this.handleExport}
        exportText={`You are about to export ${this.props.count} records of constituents who responded to this event`}
      />
    </Modal>
  );

  handleExportClick = () => {
    this.setState({ showModal: true });
  };

  handleExport = (property_ids) => {
    EverTrue.track.set("manual_export_action", { type: "event_export" });
    EventDetailSource.export(property_ids, this.props.event_id, this.props.filters);
  };

  render() {
    return (
      <div className={classNames("contact-export-button--wrapper", this.props.className)}>
        <ModalTrigger
          title="Export"
          modal={this.renderExportModal()}
          disable={this.props.disabled}
          buttonType="secondary"
        >
          <Icon icon="cloud-download" before />
          Export
        </ModalTrigger>
      </div>
    );
  }
}

export default ContactExportButton;
