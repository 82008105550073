import { PoolDataProvider as Provider, PoolDataContext } from "@evertrue/vms-insights";
import { fetchPoolData } from "apps/volunteers/utils";

export const PoolDataProvider = props => {
  return (
    <Provider fetchPoolData={fetchPoolData} >
      {props.children} {/* eslint-disable-line */}
    </Provider>
  );
};

export default PoolDataContext;
