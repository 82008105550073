module.exports = do ->
   $ = require("jquery")
   React = require("react")
   ReactDOM = require("react-dom")
   readmore = require("readmore")
   {div} = ReactLibs.DOMFactories

   ReadMore = ReactLibs.createClass
      propTypes:
         height: ReactLibs.PropTypes.number

      componentDidMount: ->
         @readmore()

      readmore: ->
         dom_node = $(ReactDOM.findDOMNode(@))
         dom_node.readmore(
            speed: 75
            collapsedHeight: @props.height
            moreLink: '<a href="#">Show more</a>'
            lessLink: '<a href="#">Show less</a>'
         )

      render: ->
         div className: @props.className || "",
            @props.children

