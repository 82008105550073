import { Component } from "react";
import PropTypes from "prop-types";
import WithSession from "base/session/with-session";
import _ from "underscore";

class BeamerConnector extends Component {
  static propTypes = {
    session: PropTypes.object,
  };

  static defaultProps = {
    session: {},
  };

  componentDidMount() {
    this.setBeamerConfig(this.props.session);

    const script = document.createElement("script");
    script.src = "https://app.getbeamer.com/js/beamer-embed.js";
    script.type = "text/javascript";
    script.defer = "defer";
    script.async = true;

    return document.body.appendChild(script);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps.session, this.props.session)) {
      this.setBeamerConfig(nextProps.session);
    }
  }

  setBeamerConfig(session) {
    const user = {
      firstname: "",
      lastname: "",
      email: "",
    };

    if (session.user && session.user.name && session.user.email) {
      const nameArray = session.user.name.split(" ");

      user["email"] = session.user.email;
      user["firstname"] = nameArray.length > 0 ? nameArray[0] : "";
      user["lastname"] = nameArray.length > 1 ? nameArray[nameArray.length - 1] : "";
    }

    window.beamer_config = {
      product_id: "ZQvIrihz7426",
      selector: "",
      user_email: user["email"],
      user_firstname: user["firstname"],
      user_lastname: user["lastname"],
      button: false,
    };
  }

  render() {
    return null;
  }
}

export default WithSession(BeamerConnector);
