import PropTypes from "prop-types";
import { extractInteractionData } from "./thankview-parser-helper.js";
import { ThankViewComponentBuilder } from "./thankview-component-builder.js";

const InteractionThankviewReply = ({ interaction = {} }) => {
  let errorFlag = false;
  const { replyType, replyMessageVideo } = extractInteractionData(interaction);
  const isVideo = replyType === "VIDEO" && replyMessageVideo;
  
  if (interaction && interaction.text) {
    errorFlag = false;
  } else {
    errorFlag = true;
  }
  return (
    <>
      {isVideo ? (
        <ThankViewComponentBuilder condition="ThankViewInteractionsReplyWithVideo" interaction={interaction} />
      ) : (
        <ThankViewComponentBuilder condition="ThankViewInteractionsReplyWithText" interaction={interaction} />
      )}
      {errorFlag && <ThankViewComponentBuilder condition="Error" interaction={interaction} />}
    </>
  );
};
InteractionThankviewReply.propTypes = {
  interaction: PropTypes.object,
};

export default InteractionThankviewReply;
