module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   {div, fieldset} = ReactLibs.DOMFactories
   {createComponent} = require("@evertrue/et-flux")
   ContactTagList = require("apps/contact/components/contact-tag-list")
   CachedContactStore = require("apps/contact/stores/cached-contact-store").default
   {createFactory} = require("base/new-utils")
   Modal = createFactory require("components/modals/modal")
   ModalConfirm = createFactory require("components/modals/modal-confirm")
   Radio = createFactory(require("@evertrue/et-components").Radio)
   ValidateContactsTextbox = require("apps/volunteers/components/validate-contacts-textbox")

   createComponent "AddToPoolModal",
      propTypes:
         header: ReactLibs.PropTypes.any
         limit: ReactLibs.PropTypes.number
         onSubmit: ReactLibs.PropTypes.func

      getInitialState: ->
         chosen_contacts: []
         entered_contacts: []
         bulk_selection: "email"

      registerStores: ->
         @on CachedContactStore, ->
            contacts: CachedContactStore.getAutocompleteResults()

      handleSubmit: (contact_ids) ->
         selected_ids = _.pluck(@state.chosen_contacts, "id")
         if contact_ids
            selected_ids = _.uniq selected_ids.concat(contact_ids)

         @props.onSubmit?(selected_ids)
         @props.remove?()

      handleVolunteerSelect: (selected) ->
         @setState {chosen_contacts: selected}

      handleValidateAndSubmit: ->
         # Triggers the textbox to handle it's own validation
         # based on the submit button click within this component
         @textbox.handleValidateAndSubmit?()

      render: ->
         Modal
            width: 450
            keepOpen: true
            remove: @props.remove
            onUnmount: @props.onUnmount

            ModalConfirm
               header: @props.header
               buttonLabel: "Add"
               disableButton: _.isEmpty(@state.chosen_contacts) && _.isEmpty(@state.entered_contacts)
               hideOnSubmit: false
               is_saving: @state.is_saving
               onSubmit: if !_.isEmpty(@state.entered_contacts) then @handleValidateAndSubmit else @handleSubmit

               div className: "add-volunteers-modal",
                  div className: "add-volunteers-modal--label",
                     "Add Constituents"

                  ContactTagList
                     selected: @state.chosen_contacts
                     onSelect: @handleVolunteerSelect


                  div className: "add-volunteers-modal--label", "Bulk Add To List"
                  fieldset null,
                     Radio
                        checked: @state.bulk_selection == "email"
                        onChange: => @setState {bulk_selection: "email"}
                        label: "Email"
                        name: "addVolunteer"
                     Radio
                        checked: @state.bulk_selection == "remote_id"
                        onChange: => @setState {bulk_selection: "remote_id"}
                        label: "Remote ID"
                        name: "addVolunteer"

                  ValidateContactsTextbox
                     # by setting the key to bulkselector, when it changes from email to remote id
                     # the component state will get reset and that will allow text input to get re-validated on submit
                     key: @state.bulk_selection
                     className: "add-volunteers-modal--textbox"
                     ref: (element) => @textbox = element
                     value: @state.entered_contacts
                     limit: @props.limit
                     onChange: (val) =>
                        @setState {entered_contacts: val}
                     onSubmit: @handleSubmit
                     bulkSelector: @state.bulk_selection
