import { useContext } from "react";
import _ from "underscore";
import VolunteerUserResolver from "apps/volunteers/components/volunteer-user-resolver";
import InvitesResolver from "apps/volunteers/components/invites-resolver";
import { useVolunteerSolicitorResolver, VolunteerSolicitorContext } from "@evertrue/vms-insights";

const PROPTYPES = {
  contactIds: ReactLibs.PropTypes.array.isRequired,
  poolId: ReactLibs.PropTypes.number.isRequired,
  render: ReactLibs.PropTypes.func,
};

const DEFAULTS = {
  render: _.noop,
};

const SolicitorAuthResolver = ({ contactIds, poolId, render }) => {
  useVolunteerSolicitorResolver({ contactIds, poolId });
  const { solicitors } = useContext(VolunteerSolicitorContext);
  return (
    <VolunteerUserResolver
      contactIds={contactIds}
      render={({ volunteer_users }) => (
        <InvitesResolver
          contactIds={contactIds}
          render={({ invites }) => {
            const solicitors_auth = _.indexBy(solicitors, "id");
            const solicitors_with_auth = _.mapObject(solicitors_auth, solicitor =>
              _.extend({}, solicitor, {
                user: volunteer_users[solicitor.contact_id],
                has_authed: !!volunteer_users[solicitor.contact_id],
                invite: invites[solicitor.contact_id],
              })
            );
            return render({
              solicitors_with_auth: _.indexBy(solicitors_with_auth, "contact_id"),
            });
          }}
        />
      )}
    />
  );
};

SolicitorAuthResolver.propTypes = PROPTYPES;
SolicitorAuthResolver.defaultProps = DEFAULTS;
export default SolicitorAuthResolver;
