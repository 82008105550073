module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   EverTrue = require("app")
   AuthApi = require("entities/auth/auth-api")
   Gate = require("entities/dna/gate")

   class Gates extends AuthApi.Collection
      urlRoot: "dna/gates"
      model: Gate

      # Parse data from the server, depending on the
      # request it will return different wrapped responses
      parse: (data) ->
         if data?.organizations
            list = []
            _.each data.organizations, (org, oid) =>
               list.push _.map @parseFeatures(org.features, data.scopes), (feature) =>
                  feature.oid = oid
                  feature.id = @formatId(feature)
                  feature
         else
            list = @parseFeatures(data?.features, data?.scopes)
         _.flatten(list)

      # Parse filter array into array of filter models so
      # each model has the scopes as attributes
      parseFeatures: (features, scopes={}) ->
         @model.prototype.parseFeatures(features, scopes)

      formatId: (feature) ->
         @model.prototype.formatId(feature)

      fetch: (options) ->
         opts = $.extend true, {},
            params: {platform: "web"}
         , options
         super opts

      fetchDefaults: (options) ->
         opts = $.extend true, {},
            params: {platform: null, oid: null}
         , options
         @fetch(opts)

      toList: ->
         _.compact @map (feature) ->
            feature.get("name") if !!feature.get("enabled")

      # Map all oids to features ex: {132: {intercom: {...}}}
      toOrgList: ->
         list = {}
         @each (model) =>
            oid = model.get("oid")
            allForOrg = _.filter @toJSON(), (model) -> model.oid == oid
            list[oid] = _.groupBy allForOrg, "name"
         list
