import PropTypes from "prop-types";
import { ActionsMenu } from "@evertrue/et-components";
import JourneyTaskModal from "../modals/journey-task-modal";
import DeleteJourneyModal from "../modals/delete-journey-modal";
import JourneyModal from "../modals/journey-modal";

const JourneyActionMenu = ({ journey = {}, optionalStateManagementFunction = () => {} }) => {
  return (
    <ActionsMenu
      options={[
        {
          id: 1,
          icon: "add-step-icon",
          iconKit: "gt2",
          label: "Add Step",
          mountOnClick: ({ is_open, close }) => {
            return (
              <JourneyTaskModal
                isOpen={is_open}
                onClose={close}
                journey={journey}
                optionalStateManagementFunction={optionalStateManagementFunction}
              />
            );
          },
        },
        {
          id: 2,
          icon: "edit",
          label: "Edit Cadence",
          mountOnClick: ({ is_open, close }) => {
            return (
              <JourneyModal
                isOpen={is_open}
                onClose={close}
                existingJourney={journey}
                optionalStateManagementFunction={optionalStateManagementFunction}
              />
            );
          },
        },
        {
          id: 3,
          icon: "trash",
          label: "Delete Cadence",
          mountOnClick: ({ is_open, close }) => {
            return <DeleteJourneyModal isOpen={is_open} onClose={close} journey={journey} />;
          },
        },
      ]}
    />
  );
};

JourneyActionMenu.propTypes = {
  journey: PropTypes.object.isRequired,
  optionalStateManagementFunction: PropTypes.func,
};

export default JourneyActionMenu;
