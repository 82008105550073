import { useEffect } from "react";
import PropTypes from "prop-types";
import { AdvancedCombobox } from "@evertrue/et-components";
import useProposalStages from "apps/proposals/hooks/use-proposal-stages";

const ProposalTableFilterStage = ({ filterValue, handleApplyFilter }) => {
  const [proposalStages] = useProposalStages();

  // Handle case when switching orgs and proposal stage from local storage doesnt exist in list
  useEffect(() => {
    if (proposalStages && filterValue !== "all" && !proposalStages.find((stage) => stage.value === filterValue)) {
      handleApplyFilter("all");
    }
  }, [handleApplyFilter, filterValue, proposalStages]);

  return (
    <>
      {proposalStages && (
        <div className="proposal-search-controller--filter-stage">
          <AdvancedCombobox
            className={filterValue !== "all" ? "proposal-search-controller--filter-selected" : ""}
            value={{ value: filterValue }}
            options={[{ value: "all", label: "All Stages" }, ...proposalStages]}
            onChange={(option) => handleApplyFilter(option.value)}
          />
        </div>
      )}
    </>
  );
};

ProposalTableFilterStage.propTypes = {
  filterValue: PropTypes.string,
  handleApplyFilter: PropTypes.func,
};

export default ProposalTableFilterStage;
