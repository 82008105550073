module.exports = do ->
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {div, p} = ReactLibs.DOMFactories
   GeolocationStore = require("apps/contact/stores/geolocation-store")
   GeolocationSource = require("apps/contact/sources/geolocation-source")
   {createFactory} = require("base/new-utils")
   Icon = createFactory (require("components/elements/icon").default)


   createComponent "GeolocationError",
      registerStores: ->
         @on GeolocationStore, ->
            show_error: GeolocationStore.shouldShowError()

      handleHide: ->
         GeolocationSource.hideError()

      componentDidUpdate: (prevProps, prevState) ->
         if !prevState.show_error && @state.show_error
            _.delay ->
               GeolocationSource.hideError()
            , 10000

      render: ->
         return null unless @state.show_error

         div className: "geolocation-error",
            div className: "geolocation-error--header",
               "Location Permission Denied"
               Icon
                  className: "geolocation-error--close"
                  icon: "close"
                  size: 1
                  onClick: @handleHide

            div className: "geolocation-error--body",
               p null,
                  "EverTrue requires browser location in order to set
                  the map to your current location. Permission is managed in your
                  browser's settings."

               div null,
                  "If you don't want to allow browser location, use the search box
                  to find locations by name."
