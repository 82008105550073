module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")
   RoleHelper = require("entities/helpers/role-helper")

   _cached_users =  {}

   createSource "CollaboratorSource",
      actions:
         loadingCollaborators: (isLoading) ->
            @require _.isBoolean(isLoading), "isLoading should be boolean"
         fetchedCollaborators: (list_id, collabs) ->
            @require !_.isUndefined(list_id), "list_id should be defined"
            @require _.isPagedObject(collabs), "collabs should be paged object"
         removedCollaborators: (list_id, response, options) ->
            @require !_.isUndefined(list_id), "list_id should be defined"
            @require _.isPagedObject(response), "collabs should be paged object"
            @require _.isObject(options), "options should be arrays"
         sharedList: (list_id, response, options) ->
            @require !_.isUndefined(list_id), "list_id should be defined"
            @require _.isPagedObject(response), "list_id should be number"

      api:
         fetchCollaborators: (list_id) ->
            @actions.loadingCollaborators(true)

            Api.CONTACTS.LIST_COLLABS.get
               urlArgs: {list_id: list_id}
               error: =>
                  @actions.loadingCollaborators(false)
               success: (resp) =>
                  @actions.loadingCollaborators(false)
                  @actions.fetchedCollaborators(list_id, resp)

         share: (list_id, share_data, options={}) ->
            Api.CONTACTS.LIST_COLLABS.post
               urlArgs: {list_id: list_id}
               data: _.jsonStringify
                  message: share_data.message
                  collaborators: _.map share_data.user_ids, (id) ->
                     {collaborator_user_id: _.toNumber(id)}
                  skip_notification: share_data.skip_notification
               error_message: "Error sharing list, please contact support or try again soon"
               success: (resp) =>
                  @actions.sharedList(list_id, resp, options)

         remove: (list_id, users, options={}) ->
            user_ids = _.map(_.makeArray(users), _.toNumber)
            Api.CONTACTS.LIST_COLLABS.delete
               urlArgs: {list_id: list_id}
               data: _.jsonStringify(user_ids)
               success: (resp) =>
                  opts =  _.extend({user_ids: user_ids}, options)
                  @actions.removedCollaborators(list_id, resp, opts)
