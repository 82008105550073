import parsePhoneNumber from "libphonenumber-js";

export const validateNumber = (num, strict = false) => {
  if (!num) return false;

  const phoneNumber = parsePhoneNumber(num, {
    defaultCountry: "US",
  });

  if (phoneNumber) {
    if (strict)
      return phoneNumber.isValid(); // strict validation
    else return phoneNumber.isPossible(); // just checks the number is long enough to be a phone number
  } else {
    return false; // Handle the case where parsePhoneNumber returns undefined
  }
};

/**
 * @input a phone number, defaults to US i.e. +1 country code [(417) 988-2587]
 * @output a formatted phone number with US country code [+14179882587]
 */
export const formattedUSPhoneNumber = (num) => {
  return parsePhoneNumber(num, {
    defaultCountry: "US",
  }).number;
};

/**
 * @input array of objects with a phone key [{phone: "222.333.4444"}, { phone: null}]
 * @output array of internationally formatted phone numbers with extensions  ["+1 222 333 4444", null]
 */
export const formattedContactNumbers = (phones = [{ phone: null, primary:false, type: null }]) => {
  return phones
    .map(({ phone, type }) => {
      if (!phone) return null;
      const extension = phone.replace(/\D/g, "").slice(10); // get extension
      const cleanNum = phone.replace(/x.*/, "").replace(/\D/g, ""); // get rid of anything after an "x" (extension), get rid of any non-numeric characters
      const phoneNumber = parsePhoneNumber(cleanNum, {
        defaultCountry: "US",
      });
      if (phoneNumber && phoneNumber.isPossible()) {
        if (extension) phoneNumber.setExt(extension);
        return { numberObject: phoneNumber, type };
      } else return null;
    })
    .filter((p) => !!p);
};

/**
 * @input array of strings of phone number objects
 * @output array of objects with label and value keys.
 */
export const mapToDropdownOptions = (numberObjects) => {
  const phones = numberObjects.map(({ numberObject, type }) => {
    const nationalNum = numberObject.formatNational();
    const label = type ? `${nationalNum} (${type})` : numberObject.formatNational();
    const value = nationalNum;
    return { label, value };
  });

  return phones;
};

export const getPhoneOptions = (phones = []) => {
  const sortedPhones = phones.sort((a, b) => b.primary - a.primary)
  const numberObjects = formattedContactNumbers(sortedPhones);
  return {
    dropdownOptions: mapToDropdownOptions(numberObjects),
  };
};
