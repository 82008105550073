module.exports = do ->
   _ = require("underscore").default
   {createStore} = require("@evertrue/et-flux")
   ClusterContactSource = require("apps/map/sources/cluster-contact-source")
   MapSource = require("apps/map/sources/map-source")


   createStore "ClusterContactStore",
      getInitialState: ->
         loading_contacts: false
         contacts: {}

      registerActions: ->
         @on ClusterContactSource.actions.loadingClusterContacts, @respondToLoadingContacts
         @on ClusterContactSource.actions.fetchedClusterContacts, @respondToFetchedContacts
         @on MapSource.actions.fetchedClusters, @respondToFetchedClusters

      respondToLoadingContacts: (isloading) ->
         @setState {loading_contacts: isloading}

      respondToFetchedContacts: (key, contacts) ->
         cluster_contacts = @getState("contacts") || {}
         cluster_contacts[key] = contacts
         @setState {contacts: cluster_contacts}

      respondToFetchedClusters: ->
         @setState {contacts: {}}

      api:
         getForCluster: (key) ->
            @getState("contacts")[key]

         getContacts: ->
            @getState("contacts")

         getLoading: ->
            @getState("loading_contacts")
