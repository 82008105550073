import { usePromiseResolver } from "@evertrue/promise-resolver";
import API from "entities/helpers/api";

const useSolicitorTitles = () => {
  const [solicitorTitles, loading] = usePromiseResolver(() => API.UGC.PROPOSAL_SOLICITOR_TITLES.get(), []);
  const formattedSolicitorTitles = solicitorTitles
    ? solicitorTitles.map((title) => ({ label: title, value: title }))
    : [];

  return [formattedSolicitorTitles, loading];
};

export default useSolicitorTitles;
