module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   EverTrue = require("app")
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {table, tbody, tr, td, thead, th, div, i} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   LinkButton = createFactory(require("@evertrue/et-components").LinkButton)

   createComponent "ContactChartData",
      getDefaultProps: ->
         defaultSort: "-x"

      getInitialState: ->
         sort_prop: @props.defaultSort.replace("-", "")
         sort_reverse: @props.defaultSort.indexOf("-") != -1

      componentDidMount: ->
         @updateColumnSizeForScrollbar()
         $(window).resize(@updateColumnSizeForScrollbar)

      componentWillUnmount: ->
         $(window).off "resize", @updateColumnSizeForScrollbar

      updateColumnSizeForScrollbar: ->
         $body = $(@refs.body)
         header_width = $(@refs.header).width()
         diff = header_width - $body.width()
         if diff
            last_col_width = $body.find("td.giving-table--y").outerWidth()
            @setState {last_col_width: last_col_width + diff} if @depracated_is_mounted

      handleSort: (key) ->
         @setState
            sort_prop: key
            sort_reverse: if @state.sort_prop == key then !@state.sort_reverse else false

      getSortClasses: (prop) ->
         EverTrue.classSet
            "gt-icon ": true
            "gt-icon-arrow-down": @state.sort_prop == prop && !@state.sort_reverse
            "gt-icon-arrow-up": @state.sort_prop == prop && @state.sort_reverse

      render: ->
         sorted_data = _.sortBy(@props.data, @state.sort_prop)
         sorted_data = sorted_data.reverse() if @state.sort_reverse

         div className: "giving-table clearfix #{@props.className}",

            # Table Header -- Stick for Scrolling
            div className: "giving-table--header",
               table className: "table table-with-borders", ref: "header",
                  thead null,
                     tr null,
                        th className: "giving-table--x",
                           LinkButton onClick: (=> @handleSort("x")), title: "Sort on X Axis label",
                              i className: @getSortClasses("x")
                              @props.xLabel

                        if @props.seriesLabel
                           th className: "giving-table--series",
                              LinkButton, onClick: (=> @handleSort("series")), title: "Sort on Series",
                                 i className: @getSortClasses("series")
                                 @props.seriesLabel

                        th className: "giving-table--y", style: {width: @state.last_col_width},
                           LinkButton onClick: (=> @handleSort("y")), title: "Sort on Y Axis label",
                              i className: @getSortClasses("y")
                              @props.yLabel

            # Table Body / Content
            div className: "giving-table--body",
               table className: "table table-with-borders", ref: "body",
                  tbody null,
                     _.map sorted_data, (item, index) =>
                        tr key: index,
                           td className: "giving-table--x", item.xFormat || item.x
                           if item.series && @props.seriesLabel
                              td className: "giving-table--series", item.series
                           td className: "giving-table--y", item.yFormat || item.y
