module.exports = do ->
   _ = require("underscore").default
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")

   _previous_request = {}
   _reset_previous_request = ->
      _previous_request = {}


   createSource "GlobalAutocompleteSource",
      actions:
         loading: (is_loading) ->
            @require _.isBoolean(is_loading), "is_loading should be boolean"
         search: (query) ->
            @require _.isString(query) || _.isUndefined(query), "search() requires a string or undefined query"
         fetched: (type, string_query, resp) ->
            @require _.isObject(resp) || _.isUndefined(resp), "response should be an object or undefined"
            @require _.isString(type), "type should be a string"
            @require _.isString(string_query), "string_query should be a string"
         viewAll: (type, query) ->
            @require _.isString(type), "type should be a string"
            @require _.isString(query) || _.isUndefined(query), "query should be an string or undefined"
         registerQuery: (query) ->
            @require _.isString(query), "query should be a string"
         unregisterQuery: (query) ->
            @require _.isString(query), "query should be a string"

      api:
         search: (query) ->
            _reset_previous_request()
            @actions.search(query)

         cancelPreviousSearches: ->
            _.each _previous_request, (requests, type) ->
               _.each requests, (req) -> req?.abort?()

         fetch: (search_query, string_query, type="contact") ->
            @actions.loading(true)
            _previous_request[type] ?= []

            request = switch type
               when "interactions" then Api.SEARCH.CONTACT_NOTE
               else Api.CONTACTS.SEARCH

            request.post
               data: _.jsonStringify(search_query)
               params: limit: 5
               beforeSend: (xhr) ->
                  _previous_request[type]?.push?(xhr)
               error: (xhr, textStatus) =>
                  unless textStatus == "abort"
                     @actions.loading(false)
               success: (resp) =>
                  @actions.fetched(type, string_query, resp)
                  @actions.loading(false)

         viewAll: (type, query, options) ->
            @actions.viewAll(type, query, options)

         registerQuery: (query) ->
            @actions.registerQuery(query)

         unregisterQuery: (query) ->
            @actions.unregisterQuery(query)
