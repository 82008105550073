module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   EverTrue = require("app")
   React = require("react")
   PostEngagerStore = require("apps/facebook/stores/post-engager-store")
   FacebookMixin = require("mixins/facebook-mixin")
   {createFactory} = require("base/new-utils")
   FacebookEngagementProfile = createFactory require("apps/facebook/components/facebook-engagement-profile").default
   {div} = ReactLibs.DOMFactories

   _window = EverTrue.WINDOW
   _document = EverTrue.DOCUMENT

   AllPostEngagers = ReactLibs.createClass
      displayName: "AllPostEngagers"
      mixins: [FacebookMixin]
      propTypes:
         post: ReactLibs.PropTypes.any
         page: ReactLibs.PropTypes.object
         filter_type: ReactLibs.PropTypes.string

      getInitialState: ->
         {users: [], loading: true}

      componentDidMount: ->
         @depracated_is_mounted = true
         PostEngagerStore.initialize()
         PostEngagerStore.bindToUpdates (users, engagements) =>
            @setState {users: users, engagements: engagements, loading: false} if @depracated_is_mounted
            $(_window).on "scroll", @handleScroll

         @fetchEngagers(@props.filter_type)

      UNSAFE_componentWillReceiveProps: (newProps) ->
         if @props.filter_type != newProps.filter_type
            @setState {users: [], loading: true} if @depracated_is_mounted
            @fetchEngagers(newProps.filter_type)

      componentWillUnmount: ->
         PostEngagerStore.unbind()
         $(_window).off "scroll", @handleScroll
         @depracated_is_mounted = false

      fetchEngagers: (filter_type = @props.filter_type) ->
         content_id = @formatContentId(@props.post.id)
         PostEngagerStore.getPostEngagersByType(content_id, filter_type)

      handleScroll: ->
         return unless PostEngagerStore.hasMoreToLoad()
         if $(_window).scrollTop() + $(_window).height() >= $(_document).height()
            @setState {loading: true} if @depracated_is_mounted
            PostEngagerStore.infinity(@props.post.id)

      getEngagementForProfile: (profile) ->
         _.findWhere(@state.engagements, {profile_id : profile.profile_id})

      renderEngagements: ->
         @state.users.map (profile) =>
            engagement = @getEngagementForProfile(profile)
            FacebookEngagementProfile
               page: @props.page
               postUrl: @props.post.public_url
               profile: profile
               engagement: engagement
               key: profile.id
               onMatch: @fetchEngagers

      render: ->
         div className:"post-engagers",
            div className:"post-details--engagers",
               @renderEngagements()

            if @state.loading
               div className: "loader is-centered"

            if !@state.loading and _.isEmpty(@state.users)
               div className: "text-empty-message", "No Engagers Found"
