module.exports = do ->

   _ = require("underscore").default
   EverTrue = require("app")
   AuthApi = require("entities/auth/auth-api")

   class Affiliation extends AuthApi.Model
      urlRoot: ->
         if @get("id")
            "auth/affiliations/#{@get("id")}"
         else "auth/affiliations"

      destroyRole: (role_id, options) ->
         return unless role_id

         unless options.wait
            roles = @get("affiliation_roles")
            roles = _.reject roles, (role) -> role.id == role_id
            @set("affiliation_roles", roles)

         @destroy _.extend {},
            urlExtend: "/affiliation_roles/#{role_id}"
         , options

      getAffiliationRoleId: (role_id) ->
         role = _.find @get("affiliation_roles"), (role) ->
            role.role_id == role_id || role?.role?.id == role_id
         role?.id
