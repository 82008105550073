import { ListboxSelect, Button } from "@evertrue/et-components";
import { useState, useEffect, useCallback } from "react";
import { PropTypes } from "base/react-libs";
import getGoogleApi from "apps/search/utils/get-google-api";

const google = getGoogleApi();

const options = [
  { label: "1mi", value: "1", zoom: 14 },
  { label: "2mi", value: "2", zoom: 13 },
  { label: "5mi", value: "5", zoom: 12 },
  { label: "10mi", value: "10", zoom: 11 },
  { label: "25mi", value: "25", zoom: 10 },
  { label: "50mi", value: "50", zoom: 8 },
];

const MapRadius = ({ map, onMapRadiusChange, radius }) => {
  const [value, setValue] = useState(options.find(({ label }) => label === radius) || null);
  const [circle, setCircle] = useState(null);

  const createRadius = useCallback(
    (radius, center) => {
      setCircle(
        new google.Circle({
          strokeColor: "#5598ab",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#5598ab",
          fillOpacity: 0.35,
          map,
          center: center,
          radius: radius * 1609.34, // to meters
        })
      );
    },
    [map]
  );

  useEffect(() => {
    if (value && map) {
      const { lat, lng } = map.getCenter();
      const center = { lat: lat(), lng: lng() };
      createRadius(value.value, center);
    }
  }, [createRadius, map, value]);

  const handleChange = (val) => {
    if (circle) {
      circle.setMap(null);
    }
    const { lat, lng } = map.getCenter();
    map.setZoom(val.zoom);
    setValue(val);
    onMapRadiusChange({ lat: lat(), lon: lng(), radius: val.label });
  };

  const handleClear = () => {
    if (circle) {
      circle.setMap(null);
    }
    setValue(null);
    onMapRadiusChange(null);
  };

  return (
    <>
      <ListboxSelect
        options={options}
        onChange={handleChange}
        value={value}
        label="Map Radius"
        emptyValue=""
        icon="map-marker-radius"
        buttonClassName="map-toolbar--button"
        headerText="Search by Radius"
      >
        <Button className="map-radius--clear-btn" type="simple" onClick={handleClear}>
          Clear Selection
        </Button>
      </ListboxSelect>
    </>
  );
};

MapRadius.propTypes = {
  map: PropTypes.object,
  radius: PropTypes.string,
  onMapRadiusChange: PropTypes.func,
};

export default MapRadius;
