import moment from "moment";
import PropTypes from "prop-types";

const TimeInStage = ({ portfolioHasProspects, avgTime }) => {
  let readable_time_in_stage = "N/A";
  if (avgTime) {
    readable_time_in_stage = moment.duration(avgTime, "seconds").humanize(false);
  }

  // If the portfolio has prospects, then show average time in stage.
  if (portfolioHasProspects) {
    return (
      <div className="portfolio-performance--column-average-time-in-stage-text-container">
        <span className="portfolio-performance--column-average-time-in-stage-text">
          {readable_time_in_stage === "N/A" ? (
            <span>{readable_time_in_stage}</span>
          ) : (
            <span>
              {" "}
              Avg time of{" "}
              <strong className="portfolio-performance--column-average-time-in-stage-bold">
                {readable_time_in_stage}
              </strong>{" "}
              in stage{" "}
            </span>
          )}
        </span>
      </div>
    );
  } else {
    return null;
  }
};

TimeInStage.propTypes = {
  avgTime: PropTypes.number,
  portfolioHasProspects: PropTypes.bool,
};

export default TimeInStage;
