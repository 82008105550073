// INSTRUCTIONS
// This is a list of active mixpanel events and their related properties.
// EverTrue.Track.set("event_key", {type: "type_key", ...props })

// The event is defined by the `name` string and must match EXACTLY across all platform,
// because of this it referenced by a key in the code for more easy access to info and to
// keep it consistent throughout the app.

// We have defined "type" as a special event property which allows us to drill further
// into data without creating too many top-level events. Since these also need to be
// matched across platforms, they are referenced by keys for consistency.

// Any number of additional properties can be passed to mixpanel in order to
// be able ot drill into data further. Please defined here what additional props are sent
// for documentation purposes.

// Events should read
// Event Name: "Action on a THING" or "THING Action"
// type: "edit", "update", "create", "drill-in", etc. (the type of action being done)

// Referrers are used to track where in the app the user is coming from, use sparingly.

const MixpanelEvents = {
  auth_action: {
    name: "Auth Action",
    type: {
      login: "login",
      logout: "logout",
      refresh: "refresh",
    },
  },

  filter_applied: {
    name: "Search Applied",
    hasReferrer: true,
    type: {
      filter: "filter",
    },
    props: ["result_type", "params", "operator"],
  },

  filter_v2_action: {
    name: "Filter V2 Action",
    type: {
      opt_out: "Toggle Beta Off",
      opt_in: "Toggle Beta On",
      select_categories: "Clicks into filter categories",
      select_filter: "Filter name selected",
      search_filters: "User searches for filter name",
      apply_filters: "Apply Filters",
      reset_filters: "Reset Filters",
      recently_viewed_used: "Recently Used Filter Selected",
      condition_changed: "Condition Changed",
      operator_changed: "Operator Changed",
      quick_filter_set: "Quick Filter Set",
      landing_page_view: "Landing Page - View",
      view_more_filters: "More Filters",
      landing_page_go: "Landing Page - Click Go",
      recently_viewed_clicked: "Landing Page - Click Recent Profile",
      show_recently_viewed: "Landing Page - Show Recently Viewed",
      bookmark_create: "Bookmark - Create",
      bookmark_view: "Bookmark - View",
      bookmark_update: "Bookmark - Update DSL",
      bookmark_open_share: "Bookmark - Open Share Modal",
      bookmark_enable_share: "Bookmark - Enable Shareable Link",
      bookmark_disable_share: "Bookmark - Disable Shareable link",
      bookmark_share_user: "Bookmark - Share with User",
    },
    props: [
      "search_term",
      "selected_filter",
      "selected_category",
      "filters_condition",
      "filters_operator",
      "quick_filter",
      "permission",
      "create_type",
    ],
  },

  profile: {
    name: "Profile Viewed",
    hasReferrer: true,
    type: {
      person: "person",
      company: "company",
      industry: "industry",
    },
  },

  contact_action: {
    name: "Action on a Contact",
    hasReferrer: true,
    type: {
      email: "email", // click on profile link
      address: "address", // click on profile link
      favorite: "favorite",
      add_to_trip: "add to trip",
      remove_from_trip: "remove from trip",
      add_to_list: "add to list",
      remove_from_list: "remove from list",
      profile_google_search: "google search",
      profile_top_field_change: "top field change",
    },
  },

  search_action: {
    name: "Action on a Search",
    hasReferrer: true,
    type: {
      view: "view",
      save: "save",
      update: "update",
      delete: "delete",
      update_name: "update name",
    },
    props: ["params"],
  },

  tab_view: {
    name: "Tab View",
    type: {
      browse: "browse",
      portfolio: "portfolio",
      trips: "trips",
      events: "events",
      facebook: "facebook",
      linkedin: "linkedin",
      give: "give",
      give_reporting: "give reporting",
      volunteers: "volunteers",
      lists: "lists",
      saved_searches: "saved searches",
      recently_viewed: "recently viewed",
      interactions: "interactions",
      launchpad: "Launchpad",
      dynamic_lists: "dynamic_lists",
      clarity: "clarity",
      homepage: "home_page",
      journeys: "journeys",
    },
  },

  list_action: {
    name: "Action on a List",
    type: {
      create: "create",
      delete: "delete",
      update_name: "update name",
      bulk_add: "bulk add",
      invite_collabs: "invite collaborators",
      remove_collabs: "remove collaborators",
      list_activity_feed: "view list activity",
      add_to_list: "add_to_list",
      remove_contacts: "remove_contacts",
      push_to_emma: "push to emma",
    },
    props: ["count", "hours_since_last_update"],
  },

  map_action: {
    name: "Action on Map",
    type: {
      map_change_location: "go to searched location",
      map_geolocation: "go to geolocation",
    },
  },

  trip_action: {
    name: "Action on Trip",
    type: {
      trip_itinerary: "trip - itinerary",
      trip_map: "trip - map",
      trip_create: "create trip",
      trip_edit: "edit trip",
      trip_delete: "delete trip",
      trip_add_contacts: "add constituents",
      trip_directions: "get directions",
      trip_search_nearby: "search nearby",
      trip_log: "trip log",
      trip_contact_info: "trip contact info",
      create_meeting: "create meeting",
      create_virtual_meeting: "create virtual meeting",
      create_phone_meeting: "create phone meeting",
      create_local_meeting: "create local meeting",
      update_meeting: "update meeting",
      update_virtual_meeting: "update virtual meeting",
      update_phone_meeting: "update phone meeting",
      update_local_meeting: "update local meeting",
      delete_meeting: "delete meeting",
      meeting_details: "meeting details",
      meeting_map_pin: "meeting map pin",
    },
  },

  report_action: {
    name: "Action on a Report",
    type: {
      report_action_view: "view",
      report_action_user: "user action",
    },
    props: ["report"],
  },

  pool_action: {
    name: "Action on a Pool",
    type: {
      create_pool: "create pool",
      update_pool: "update pool",
      delete_pool: "delete pool",
      add_to_pool: "add to pool",
      remove_from_pool: "remove from pool",
      filter_prospects: "filter prospects",
      assign: "assign",
      unassign: "unassign",
    },
    props: ["object", "count", "filter_key"],
  },

  pool_report_action: {
    name: "Action on Pool Report",
    type: {
      viewed: "viewed",
      sorted: "sorted",
      filtered: "filtered",
    },
    props: ["filter"],
  },

  todo_action: {
    name: "Action on a Todo",
    type: {
      todo_edit: "ToDo edited",
      todo_open: "ToDo list opened",
      todo_create: "ToDo created",
      todo_delete: "ToDo deleted",
      todo_complete: "ToDo marked completed",
      reminder_create: "Reminder created",
      reminder_delete: "Reminder Deleted",
      reminder_edit: "Reminder Edited",
    },
  },

  my_portfolio_action: {
    name: "Action on My Portfolio",
    hasReferrer: true,
    type: {
      edit_stage_order: "edit stage order",
      add_to_list: "add to list",
    },
  },

  portfolio_performance_action: {
    name: "Action on Portfolio Performance",
    hasReferrer: true,
    type: {
      view: "view",
      filter_key_prospects: "filter key prospects",
      update_prospect_stage: "update prospect stage",
    },
  },

  enrichment_action: {
    name: "Enrichment Action",
    type: {
      view: "view",
    },
    props: ["matched", "available_data", "contact_id"],
  },

  event_action: {
    name: "Event Action",
    type: {
      view: "view",
      filter: "filter",
    },
    props: ["data_type", "params"],
  },

  contact_suggestion: {
    name: "Contact Suggestion",
    hasReferrer: true,
    type: {
      view: "view",
      edit: "edit",
      new: "new",
      retire: "retire",
    },
    props: ["data_type"],
  },

  interaction_action: {
    name: "Content Created", // renamed in Lexicon
    description: "User generated content (primarily contacts and interactions).",
    hasReferrer: true,
    type: {
      interaction: "interaction",
      comment: "comment",
      list_comment: "list note",
    },
    props: ["action", "colleagues mentioned", "constituents mentioned", "category"],
  },

  viewed: {
    name: "View",
    description: `A catch all for viewing various types of information.
      Preferred usage going forward is to use 'view' as a type of action event`,
    hasReferrer: true,
    type: {
      list: "list",
      lists: "lists",
      dynamic_lists: "dynamic_lists",
      saved_searches: "saved searches",
      global_search: "global search results",
      community_interactions: "community_interactions",
      facebook_interactions: "facebook interactions",
      facebook_posts: "facebook posts",
      facebook_unmatched_engagers: "facebook_unmatched_engagers",
      facebook_pages: "facebook pages",
      portfolio_all_map: "portfolio all - list",
      portfolio_all_list: "portfolio all - map",
      portfolio_performance: "portfolio performance",
      portfolio_performance_map: "portfolio performance - map",
      my_trips: "my trips",
      proposal_details: "proposal details",
    },
  },

  profile_content_viewed: {
    name: "Profile Content Viewed",
    hasReferrer: true,
    type: {
      // # PROFILE TABS
      profile_section_summary: "summary",
      profile_section_bio: "bio - all",
      profile_section_giving: "giving - all",
      profile_section_posts: "engagement - all",
      profile_section_notes: "notes - all",
      profile_section_proposals: "proposals - all",
      profile_section_enrichment: "enrichment",
      profile_section_task: "tasks",
    },
  },

  // Match a constituent to a profile, use "source" to
  // split by which profiles are being matched most
  identity_matching: {
    name: "Identity Matching",
    hasReferrer: true,
    type: {
      match: "match",
      unmatch: "unmatch",
    },
    props: ["source"],
  },

  manual_export_action: {
    name: "Manual Export Actions",
    type: {
      constituents: "constituents",
      interactions: "interactions",
      volunteers: "volunteers",
      relationship_managment: "relationship management",
      gifts: "gifts",
      list: "list",
      trip: "trip",
      volunteer_usage: "volunteer usage",
      event_export: "event constituents",
      wealth_enrichment: "wealth_enrichment",
      career_moves_export: "career_moves_export",
    },
  },

  global_search_action: {
    name: "Global Search Action",
    type: {
      contacts: "constituent selection",
      interactions: "interaction selection",
      view_all: "select all",
    },
  },

  chat_action: {
    name: "Chat Action",
    hasReferrer: true,
    type: {
      view: "view",
      leave: "leave",
      open: "open chat",
      globalSearch: "globalSearch",
    },
    props: ["chat_type"],
  },

  proposal_action: {
    name: "Proposal Action",
    hasReferrer: true,
    type: {
      view: "view",
      create_proposal: "create proposal",
      edit_proposal: "edit proposal",
      mark_key_proposal: "Mark Key Proposal",
      unmark_key_proposal: "Unmark Key Proposal",
    },
  },

  launchpad_action: {
    name: "Launchpad Action",
    type: {
      widget_detail_view: "Widget Detail View",
      widget_reorder: "Widget Reorder",
      widget_add_or_remove: "Widget Add or Remove",
      launchpad_tab_change: "Launchpad Tab Change",
      team_view_switch: "Team View Switch",
      user_view_switch: "User View Switch",
    },
    props: ["tab", "referrer"],
  },
  dynamic_lists_action: {
    name: "Dynamic List Action",
    type: {
      open_dynamic_list_modal: "Open Dynamic List Modal",
      create_dynamic_list: "Create Dynamic List",
      delete_dynamic_list: "Delete Dynamic List",
      total_dyanmic_list_table_view: "Total Dynamic List Table View",
      total_dyanmic_list_view: "Total Dynamic List View",
      total_view_per_user: "Total View Per User",
      modify_dynamic_list: "Modify Dynamic List",
    },
  },
  thankview_interactions_action: {
    name: "ThankView Interactions Action",
    type: {
      interactions_page: "Open Interactions Page",
      launch_filter_tv: "Interactions Page - Click Filter(ThankView Video)",
      tv_dropdown_video_clicked: "Play Thankview Video in Dropdown",
      tv_details_video_clicked: "Play ThankView Video in Details Page",
      tv_dropdown_button_clicked: "Open/Close ThankView Dropdown",
      tv_reply_link_clicked: "ThankView - See Reply Link Clicked",
      tv_reply_video_clicked: "ThankView - See Reply Video Clicked",
      tv_engagement_clicked: "ThankView - Engagement Clicked",
    },
  },
  pinned_comment_action: {
    name: "Pinned Comment Action",
    type: {
      pinned_comment: "Pinned a Comment",
      unpinned_comment: "Unpinned a Comment",
      see_all_pinned: "Total View of All Pinned Comments",
      number_of_pinned_comments_per_user: "Total Number of Pinned Comments Per User",
    },
  },
  tv_engagement_tab: {
    name: "ThankView Engagements Action",
    type: {
      engagement_facebook: "Clicks on Facebook Engagement Tab",
      engagement_thankview: "Clicks on ThankView Engagement Tab",
      engagement_fb_switch: "Clicks on Switch Back to Facebook Button",
    },
  },
  tasks: {
    name: "Action on Task",
    type: {
      delete: "delete a task",
      assigned_to_others: "Assign Task To Someone Else",
      assigned_to_creator: "Assign Task To Creator",
      create_task_from_task_screen: "Create Task From Task Screen",
      create_task_email_notification: "Create Task Email Notification",
      disable_task_email_notification: "Disable Task Email Notification",
      complete_task_from_task_screen: "Complete Task From Task Screen",
      complete_task_from_profile: "Complete Task From Profile",
      edit_task_task_page: "Edit Task from Task Page",
      edit_task_profile: "Edit Task from Profile",
      delete_task_task_page: "Delete Task from Task Page",
      delete_task_profile: "Delete Task from Profile",
      view_complete_task_task_page: "View Complete Task from Task Page",
      view_complete_tasks_profile: "View Complete Tasks from Profile",
      view_all_tasks_task_page: "View All Tasks from Task Page",
      view_all_tasks_profile: "View All Tasks from Profile",
      view_inprogress_tasks_task_page: "View In Progress Tasks from Task Page",
      view_inprogress_tasks_profile: "View In Progress Tasks from Profile",
      navigate_to_tasks_from_left_nav: "Navigate To Task Page From Left Hand Nav Bar",
    },
  },
  move_to_active_column: {
    name: "Active Columns",
    hasReferrer: true,
    type: {
      moved_to_active: "Column Moved to Active",
    },
    props: ["params"],
  },
  dps_action: {
    name: "DPS Action",
    type: {
      sort_ascending: "DPS Sort Ascending",
      sort_descending: "DPS Sort Descending",
      export: "DPS Export",
    },
  },
  cadences: {
    name: "Cadence Action",
    type: {
      left_nav: "View Cadence Tab Left Nav",
      add_interaction: "Add Interaction Right Panel",
      add_pinned_comment: "Add Pinned Comment Right Panel",
      view_link: "Click View Link in Right Panel",
      expand_step: "Expand Step To View",
      complete_step: "Complete Cadence Step",
      uncomplete_step: "Uncomplete Cadence Step",
      view_calendar_date: "Click To View Cadence From Calendar Date",
      move_cadence: "Move To Another Cadence From Cadence View",
      edit_due_date: "Edit Due Date Of Cadence Step",
      remove_cadence: "Remove Cadence From Cadence View",
      skip_cadence_step: "Skip Cadence Step From Cadence View",
      create_cadence_pause: "Create Cadence Pause",
      end_cadence_pause: "End Cadence Pause",
      edit_cadence_pause: "Edit Cadence Pause",
      click_linkedin: "Clicked LinkedIn",
      add_cadence_from_profile: "Add To Cadence From Profile",
      add_cadence_from_portfolio: "Add To Cadence From Portfolio View",
      add_cadence_from_list: "Add To Cadence From List",
      add_cadence_from_constituent: "Add To Cadence From Constituent Main Page", //will need follow up as it currently isnt not made
    },
  },

  outreach_action: {
    name: "Outreach Action",
    description: "Actions on emails and phone calls sent from EverTrue.",
    type: {
      open_dialer_from_profile: "Open Dialer From Profile",
      open_dialer_from_cadences: "Open Dialer From Cadences",
      open_emailer_from_profile: "Open Emailer From Profile",
      open_emailer_from_cadences: "Open Emailer From Cadences",
      make_phone_call: "Make Phone Call",
      send_email: "Send Email",
    },
  },

  home_screen: {
    name: "Home Screen",
    type: {
      add_task: "Add Task From Homepage",
      view_task: "View Task",
      view_trips_meetings: "View Trips And Meetings",
      clarity: "Go To Clarity From Homepage",
      cadences: "Go To Cadences From Homepage",
      reporting: "Go To Reporting From Homepage",
      recommended_action: "Clicked from Recommended Action",
    },
  },
};

export default MixpanelEvents;
