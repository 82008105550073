const gql = String.raw;

const journeysQueries = {
  fetchJourneyGroups: gql`
    query ($journeyGroupType: String, $includeInactive: Boolean!) {
      journeyGroups(journeyGroupType: $journeyGroupType, includeInactive: $includeInactive) {
        id
        group_type
        name
      }
    }
  `,

  fetchJourneyDetails: gql`
    query (
      $journeyId: Int!
      $taskOwnerUserId: BigInt!
      $stepTaskStatuses: [String!]
      $constituentsTaskStatuses: [String!]
      $selectedDate: Date!
      $activeContactJourneyPage: Int
      $activeContactJourneyLimit: Int
    ) {
      journey(journeyId: $journeyId) {
        id
        name
        journey_type
        creator_user_id
        objective_description
        journey_group_id
        active_constituents
        owner {
          name
        }
        active_contact_journeys_paginated(page: $activeContactJourneyPage, perPage: $activeContactJourneyLimit) {
          items {
            id
            user_id
            contact_id
            tasks(statuses: $constituentsTaskStatuses) {
              title
              due_date
              status
              journey_task {
                step_number
                day_number
              }
            }
            contact {
              profile_image {
                url
              }
              id
              contact_attributes {
                name_full
                year
              }
              constituencies {
                status
              }
            }
          }
          count
        }
        active_contact_journeys_paginated_by_user(
          page: $activeContactJourneyPage
          perPage: $activeContactJourneyLimit
        ) {
          items {
            id
            user_id
            contact_id
            tasks(statuses: $constituentsTaskStatuses) {
              title
              due_date
              status
              journey_task {
                step_number
                day_number
              }
            }
            contact {
              profile_image {
                url
              }
              id
              contact_attributes {
                name_full
                year
              }
              constituencies {
                status
              }
            }
          }
          count
        }
        steps {
          id
          journey_id
          step_number
          day_number
          description
          default_action_type
          active
          title
          tasks(ownerUserId: $taskOwnerUserId, statuses: $stepTaskStatuses, selectedDate: $selectedDate) {
            id
            title
            due_date
            completed_at
            action_type
            contact {
              profile_image {
                url
              }
              id
              contact_attributes {
                name_full
                name_first
                name_last
                year
              }
              constituencies {
                status
              }
              household_members {
                id
                contact_attributes {
                  name_full
                }
                profile_image {
                  url
                }
              }
              emails {
                email
                primary
              }
              phones {
                primary
                phone
                type
              }
              addresses {
                primary
                time_zone {
                  time_zone
                  zip_code
                  observes_dst
                  offset
                }
              }
            }
          }
        }
      }
    }
  `,

  fetchPaginatedActiveContactJourneys: gql`
    query ($journeyId: Int!, $activeContactJourneyPage: Int, $activeContactJourneyLimit: Int, $taskStatuses: [String]) {
      journey(journeyId: $journeyId) {
        active_contact_journeys_paginated(page: $activeContactJourneyPage, perPage: $activeContactJourneyLimit) {
          items {
            id
            user_id
            contact_id
            tasks(statuses: $taskStatuses) {
              title
              due_date
              status
              journey_task {
                step_number
                day_number
              }
            }
            contact {
              profile_image {
                url
              }
              id
              contact_attributes {
                name_full
                year
              }
              constituencies {
                status
              }
            }
          }
          count
        }
        active_contact_journeys_paginated_by_user(
          page: $activeContactJourneyPage
          perPage: $activeContactJourneyLimit
        ) {
          items {
            id
            user_id
            contact_id
            tasks(statuses: $taskStatuses) {
              title
              due_date
              status
              journey_task {
                step_number
                day_number
              }
            }
            contact {
              profile_image {
                url
              }
              id
              contact_attributes {
                name_full
                year
              }
              constituencies {
                status
              }
            }
          }
          count
        }
      }
    }
  `,

  // the only difference between this and fetchJourneysWithDueDatesForGroup is the "tasks_due_counts" field
  // separated the queries because the "tasks_due_counts" field is not needed in some cases and requires args
  fetchJourneysForGroup: gql`
    query ($journeyGroupId: BigInt!, $userId: BigInt) {
      journeysForJourneyGroup(journeyGroupId: $journeyGroupId, userId: $userId) {
        id
        name
        journey_type
        active_constituents(userId: $userId)
        duration_in_days
        number_of_steps
        journey_group {
          id
          name
        }
      }
    }
  `,

  fetchJourneysWithDueDatesForGroup: gql`
    query ($journeyGroupId: BigInt!, $taskOwnerUserId: BigInt!, $taskStatuses: [String!], $selectedDate: Date!) {
      journeysForJourneyGroup(journeyGroupId: $journeyGroupId) {
        id
        name
        journey_type
        tasks_due_counts(taskOwnerUserId: $taskOwnerUserId, taskStatuses: $taskStatuses, selectedDate: $selectedDate)
        active_constituents(userId: $taskOwnerUserId)
        duration_in_days
        number_of_steps
        contact_journeys(taskOwnerUserId: $taskOwnerUserId) {
          id
          contact {
            contact_attributes {
              name_full
              year
            }
          }
        }
        journey_group {
          id
          name
        }
        favorite(userId: $taskOwnerUserId) {
          id
          journey_id
        }
      }
    }
  `,
  //todo: replace with a query which accepts date range or returns details for next 5 days
  //query to fetch counts for all journey groups for a specific date
  fetchUpcomingTaskCounts: gql`
    query (
      $taskOwnerUserId: BigInt!
      $taskStatuses: [String!]
      $includeInactive: Boolean!
      $fetchJourneysTaskCount: Boolean!
      $dayRange: Int!
      $journeyGroupType: String
    ) {
      getUpcomingTaskCounts(
        taskOwnerUserId: $taskOwnerUserId
        taskStatuses: $taskStatuses
        includeInactive: $includeInactive
        fetchJourneysTaskCount: $fetchJourneysTaskCount
        dayRange: $dayRange
        journeyGroupType: $journeyGroupType
      )
    }
  `,

  fetchTaskCount: gql`
    query (
      $journeyTaskIds: [BigInt]
      $selectedDate: Date!
      $taskOwnerUserId: BigInt!
      $taskStatuses: [String!]
      $taskActionTypes: [String]
    ) {
      taskCount(
        journeyTaskIds: $journeyTaskIds
        selectedDate: $selectedDate
        taskOwnerUserId: $taskOwnerUserId
        taskStatuses: $taskStatuses
        taskActionTypes: $taskActionTypes
      )
    }
  `,

  fetchCompleteAndOverdueTasks: gql`
    query ($startDate: Date!, $endDate: Date!, $userId: BigInt!) {
      getCompletedAndOverdueTasks(startDate: $startDate, endDate: $endDate, userId: $userId) {
        id
        completed_at
        contact_journey_id
        due_date
        owner_user_id
        status
        target_contact_id
        title
        updated_at
        contact {
          profile_image {
            url
          }
          contact_attributes {
            name_full
          }
        }
        metadata {
          journey_name
          step_number
          day_number
        }
      }
    }
  `,

  fetchContactJourneys: gql`
    query ($startDate: Date!, $endDate: Date, $userId: Int!) {
      contactJourneyByDate(startDate: $startDate, endDate: $endDate, userId: $userId) {
        contact_id
    }
  `,

  // this exists elsewhere in the codebase
  fetchContacts: gql`
    query ($ids: [BigInt!]) {
      contacts(ids: $ids) {
        id
        contact_attributes {
          name_full
        }
      }
    }
  `,

  // recommended actions
  fetchAssignedWithRecentGiftContactIds: gql`
    query ($userSolicitorContactId: BigInt!, $daysSinceLastGift: Int!, $minGiftAmount: Float!) {
      assignedWithRecentGiftContactIds(
        userSolicitorContactId: $userSolicitorContactId
        daysSinceLastGift: $daysSinceLastGift
        minGiftAmount: $minGiftAmount
      )
    }
  `,

  fetchAssignedWithGiftAnniversaryContactIds: gql`
    query ($userSolicitorContactId: BigInt!) {
      assignedWithGiftAnniversaryContactIds(userSolicitorContactId: $userSolicitorContactId)
    }
  `,

  fetchAssignedWithCareerMovesContactIds: gql`
    query ($userSolicitorContactId: BigInt!) {
      assignedWithCareerMovesContactIds(userSolicitorContactId: $userSolicitorContactId)
    }
  `,

  fetchContactForRecommendedAction: gql`
    query ($id: BigInt!) {
      contact(id: $id) {
        contact_attributes {
          name_full
        }
        profile_image {
          url
        }
        active_journey {
          journey_name
          step_number
          day_number
        }
        giving {
          lifetime_amount
          last_gift_date
          last_gift_amount
          last_gift_label
        }
        id
        enrichment {
          employment_events {
            type
            recorded_at
          }
        }
      }
    }
  `,

  fetchJourneysForUsersJourneyGroup: gql`
    query ($taskOwnerUserId: BigInt!, $taskStatuses: [String!], $selectedDate: Date!) {
      journeysForUser(userId: $taskOwnerUserId) {
        id
        name
        journey_type
        tasks_due_counts(taskOwnerUserId: $taskOwnerUserId, taskStatuses: $taskStatuses, selectedDate: $selectedDate)
        active_constituents(userId: $taskOwnerUserId)
        duration_in_days
        number_of_steps
        contact_journeys(taskOwnerUserId: $taskOwnerUserId) {
          id
          contact {
            contact_attributes {
              name_full
              year
            }
          }
        }
        journey_group {
          id
          name
        }
        favorite(userId: $taskOwnerUserId) {
          id
          journey_id
        }
      }
    }
  `,

  fetchFavoriteJourneys: gql`
    query ($taskOwnerUserId: BigInt!, $taskStatuses: [String!], $selectedDate: Date!) {
      favoriteJourneys(userId: $taskOwnerUserId) {
        id
        name
        journey_type
        tasks_due_counts(taskOwnerUserId: $taskOwnerUserId, taskStatuses: $taskStatuses, selectedDate: $selectedDate)
        active_constituents(userId: $taskOwnerUserId)
        duration_in_days
        number_of_steps
        contact_journeys(taskOwnerUserId: $taskOwnerUserId) {
          id
          contact {
            contact_attributes {
              name_full
              year
            }
          }
        }
        journey_group {
          id
          name
        }
        favorite(userId: $taskOwnerUserId) {
          id
          journey_id
        }
      }
    }
  `,
};

export default journeysQueries;
