module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   {createStore} = require("@evertrue/et-flux")
   AlertSource = require("apps/layout/sources/alert-source")


   createStore "AlertStore",
      getInitialState: ->
         alerts: []
         confirm: undefined

      registerActions: ->
         @on AlertSource.actions.alert, @respondToAlert
         @on AlertSource.actions.clear, @respondToClear
         @on AlertSource.actions.deleteStart, @respondToDeleteStart
         @on AlertSource.actions.deleteAlert, @respondToDelete
         @on AlertSource.actions.confirm, @respondToConfirm
         @on AlertSource.actions.clearConfirm, @respondToClearConfirm
         @on AlertSource.actions.clearUndoAlerts, @respondToClearUndos

      respondToAlert: (content, type, options={}) ->
         data = _.clone(options)
         data.timeout ?= 5000
         data.id ?= Math.round(Math.random() * 1000)
         data.type = type
         data.header = options.header
         data.message = content
         @respondToClearUndos()

         if _.isFunction options.onUndo
            data.timeout = 10000

         alerts = @getState("alerts")
         alert = _.findWhere alerts, {id: data.id}
         if alert then _.extend alert, data
         else alerts.push(data)
         @setState {alerts: alerts}

      respondToDeleteStart: (id) ->
         alerts = @getState("alerts")
         toast = _.findWhere alerts, {id: id}
         return unless toast
         toast.willBeRemoved = true
         @setState {alerts: alerts}

      respondToDelete: (id) ->
         alerts = @getState("alerts")
         toast = _.findWhere alerts, {id: id}
         @setState {alerts: _.without(alerts, toast)}

      respondToClear: ->
         @setState {alerts: []}

      respondToConfirm: (options, callback) ->
         # Default headline to 'Delete?' for fallback from old Aleritfy confirm
         # Also, the most frequent use for this is to confirm a delete action
         @setState
            confirm:
               headline: options.headline || "Delete?"
               content: options.content || "Are you sure you want to delete this?"
               type: options.type || "error"
               width: options.width
               buttonLabel: options.buttonLabel
               cancelLabel: options.cancelLabel
               onConfirm: ->
                  callback?(true)
                  AlertSource.clearConfirm()
               onCancel: ->
                  callback?(false)
                  AlertSource.clearConfirm()

      respondToClearConfirm: ->
         @setState {confirm: undefined}

      respondToClearUndos: ->
         undo_alerts = _.filter @getState("alerts"), (alert) -> !alert.onUndo
         @setState {alerts: undo_alerts}

      api:
         getAlerts: ->
            @getState("alerts")

         getConfirm: ->
            @getState("confirm")
