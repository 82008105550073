module.exports = do ->

   _ = require("underscore").default
   EverTrue = require("app")
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")

   COLUMN_SETTINGS_KEY = "GT_applied_columns"


   createSource "ColumnSource",
      actions:
         fetchedColumns: true
         updatedColumns: true

      api:
         fetch: ->
            Api.DNA.SETTING.get
               urlArgs: {setting_key: COLUMN_SETTINGS_KEY}
               disableAlerts: true
               params:
                  user_id: "me"
                  oid: null
               success: (response) =>
                  columns = response?.settings[COLUMN_SETTINGS_KEY]
                  @actions.fetchedColumns(columns?.value)
               error: =>
                  @actions.fetchedColumns()

         update: (new_columns) ->
            # Optimistically update columns before dealing with DNA
            # DNA saving can be behind the scenes & w/o UI display
            @actions.updatedColumns(new_columns)

            Api.DNA.SETTING.put
               urlArgs: {setting_key: COLUMN_SETTINGS_KEY}
               disableAlerts: true
               params:
                  user_id: "me"
                  oid: null
               data: _.jsonStringify
                  value: _.map new_columns, (col) -> _.omit(col, ["value", "component"])
               success: (response) =>
                  columns = response?.settings[COLUMN_SETTINGS_KEY]
                  @actions.updatedColumns(columns?.value)
                  EverTrue.Alert.success "Successfully updated columns"
