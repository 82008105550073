import { useEffect, useContext, useMemo } from "react";
import { PropTypes } from "base/react-libs";
import { PageHeader, Loading } from "@evertrue/et-components";
import EverTrue from "app";
import { GlobalContactSearchContext } from "apps/layout/controllers/global-contact-search-context";
import useFetchSearchById from "apps/search/hooks/use-fetch-search-by-id";
import FiltersBarController from "apps/query-builder/components/filters-bar-controller";
import ContactsFixedTable from "apps/contact/components/contacts/contact-fixed-table";
import TableToolbar from "components/tables/table-toolbar";
import BookmarkActions from "apps/search/components/bookmark-actions";
import BookmarkName from "apps/search/components/bookmark-name";
import FilterRevert from "apps/query-builder/components/filter-revert";
import useFetchContacts from "apps/search/hooks/use-fetch-contacts";
import MapsController from "apps/search/controllers/maps-controller";
import MapToggle from "components/tables/map-toggle";
import { mergeFilters } from "../utils/search-helper";

const BookmarkedSearchController = ({ id, showMap = false }) => {
  const { loading, search, refetch } = useFetchSearchById({ id });
  const { dslQuery, setContactFilters, tableSettings, updateTableSettings, modified, setModified } =
    useContext(GlobalContactSearchContext);
  const handleRefetch = () => {
    refetch();
    setModified(false);
  };

  const dsl = useMemo(() => {
    return mergeFilters(dslQuery, search.dsl_criteria);
  }, [dslQuery, search.dsl_criteria]);

  const { contactsData, loading: loadingContacts, offset, LIMIT, resultCount } = useFetchContacts(dsl, tableSettings);

  /*
   This modified flag is needed for cases when the user modifies
   their query and visits a profile and comes back to this page.
   If the flag is modified then we don't want to refetch the search
  */
  useEffect(() => {
    if (search.dsl_criteria && !modified) {
      setContactFilters(search.dsl_criteria, false);
    }
  }, [search.dsl_criteria, modified, setContactFilters]);

  useEffect(() => {
    if (search.current_user_access) {
      EverTrue.track.set("filter_v2_action", {
        type: "bookmark_view",
        permission: search.current_user_access,
      });
    }
  }, [search.current_user_access]);

  if (loading) return <Loading />;

  if (!search.current_user_access)
    return <h1 className="bookmarked-search--unauthorized">You are not authorized to view this search</h1>;

  return (
    <>
      <PageHeader
        title={
          <BookmarkName
            bookmarkId={id}
            bookmarkName={search.name}
            permission={search.current_user_access}
            owner={search.permission_stats.owner_name || "Removed User"}
            type={search.type}
            disabled={modified}
          />
        }
        subtitle={search.description}
        onBackClick={() => EverTrue.Navigator("/constituents", true)}
        actions={
          <BookmarkActions
            id={id}
            refetch={handleRefetch}
            userPermission={search.current_user_access}
            orgPermission={search.permission_stats.org_permission}
            dsl={dsl}
          />
        }
      />
      <FiltersBarController shouldSetModified={true}>
        <FilterRevert
          modified={modified}
          onRevert={() => {
            setModified(false);
            setContactFilters(search.dsl_criteria);
          }}
        />
      </FiltersBarController>
      <div className="filtered-contacts fixed-page-wrapper" style={{ top: 150 }}>
        <div className="space-between toolbar-container">
          <TableToolbar
            resultCount={resultCount}
            page={tableSettings.page}
            offset={offset}
            limit={LIMIT}
            onPage={(page) => updateTableSettings({ page })}
            referrer={"search"}
            id={id}
            showMap={showMap}
            loading={loadingContacts}
          />
          <MapToggle id={id} referrer={"search"} showMap={showMap} />
        </div>
        {showMap ? (
          <MapsController loading={loading} contacts={contactsData} />
        ) : (
          <ContactsFixedTable
            topPosition="55px"
            contacts={contactsData}
            loading={loadingContacts}
            onSort={(prop, order) => updateTableSettings({ sortProp: prop, sortReverse: order })}
          />
        )}
      </div>
    </>
  );
};

BookmarkedSearchController.propTypes = {
  id: PropTypes.string.isRequired,
  showMap: PropTypes.bool,
};

export default BookmarkedSearchController;
