module.exports = do ->
   _ = require("underscore").default
   {createStore} = require("@evertrue/et-flux")
   LayoutSource = require("apps/layout/sources/layout-source")
   MapSource = require("apps/map/sources/map-source")
   MapUtils = require("apps/map/map-utils")
   GeolocationStore = require("apps/contact/stores/geolocation-store")
   UrlSource = require("apps/layout/sources/url-source")


   createStore "MapStore",
      getInitialState: ->
         map: undefined
         clusters: {}
         zoom: 2
         loading_clusters: false
         bounding_box: undefined
         highlighted: undefined

      registerActions: ->
         @on MapSource.actions.fetchedClusters, @respondToFetchedClusters
         @on MapSource.actions.loadingClusters, @respondToLoadingClusters
         @on MapSource.actions.clearClusters, @respondToClear

         @on MapSource.actions.initializedMap, @respondToInitializedMap
         @on MapSource.actions.changedZoom, @respondToZoom
         @on MapSource.actions.setBoundingBox, @respondToChangedBounds
         @on MapSource.actions.highlightCluster, @respondToHighlight

         @on UrlSource.actions.initializedUrl, (url) =>
            @setState {bounding_box: url.map} if url.map

         @on UrlSource.actions.changedMap, (bounds) =>
            @setState {bounding_box: bounds}

         @on LayoutSource.actions.endNavAnimation, =>
            map = @getState("map")
            _.defer ->
               google.maps.event.trigger(map, "resize") if map

      respondToFetchedClusters: (zoom, data) ->
         @setState
            zoom: zoom || @getState("zoom")
            clusters: _.mapObject data.geo_cluster, (cluster) ->
               {count: cluster.count, geobox: _.omit(cluster, "count")}

      respondToLoadingClusters: (is_loading) ->
         @setState {loading_clusters: is_loading}

      respondToClear: ->
         @setState {clusters: {}}

      respondToInitializedMap: (map_object, zoom) ->
         @setState {map: map_object, zoom: _.toNumber(zoom)}

      respondToZoom: (zoom_level) ->
         @setState {zoom: zoom_level}

      respondToChangedBounds: (box) ->
         @setState {bounding_box: box}

      respondToHighlight: (cluster_key) ->
         @setState {highlighted: _.makeArray(cluster_key)}

      api:
         getClusters: ->
            @getState("clusters")

         getHighlightedClusters: ->
            @getState("highlighted")

         getLoadingClusters: ->
            @getState("loading_clusters")

         getZoom: ->
            @getState("zoom")

         getMap: ->
            @getState("map")

         getBoundingBox: ->
            @getState("bounding_box")

         getLatLng: ->
            latLng = MapUtils.boxToLatLng(@getState("bounding_box"))
            if latLng then {lat: latLng.lat, lon: latLng.lng}

         getProximitySort: (sort_reverse) ->
            order = if !!sort_reverse then "desc" else "asc"
            sort_param_query = {}
            latLng = MapUtils.boxToLatLng(@getState("bounding_box"))
            latLng ?= GeolocationStore.getLocationOrDefault()
            sort_param_query["addresses.location"] =
               lat: latLng.lat
               lon: latLng.lng
               operator: "min"
               order: order
            sort_param_query
