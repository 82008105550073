import * as proposal_standard_fields_config from "base/dna/default-configs/proposal_standard_fields_config.json";
import * as interaction_standard_fields_config from "base/dna/default-configs/interaction_standard_fields_config.json";

export default class DNAValidator {
  constructor(key) {
    this.key = key;
    this.defaultConfigs = {
      proposal_standard_fields_config,
      interaction_standard_fields_config,
    };
  }

  getDefaultConfig() {
    return this.defaultConfigs[this.key].default;
  }

  validateType(val, defaultVal) {
    return typeof val === typeof defaultVal;
  }

  validateField(fieldName, defaultSettings, dnaConfig, optionalValidations) {
    if (!dnaConfig[fieldName]) {
      dnaConfig[fieldName] = { ...defaultSettings };
    } else {
      Object.entries(defaultSettings).forEach(([key, val]) => {
        if (!this.validateType(val, dnaConfig[fieldName][key])) {
          dnaConfig[fieldName][key] = val;
        }

        if (optionalValidations[key]) {
          if (!optionalValidations[key](dnaConfig[fieldName][key], val)) dnaConfig[fieldName][key] = val;
        }
      });
    }
  }

  validateWithDefaultConfig(dnaConfig, optionalValidations) {
    const defaultConfig = this.getDefaultConfig();

    if (dnaConfig === null) return defaultConfig;

    Object.entries(defaultConfig).forEach(([fieldName, customSettings]) =>
      this.validateField(fieldName, customSettings, dnaConfig, optionalValidations)
    );
    return dnaConfig;
  }
}
