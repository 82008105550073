import { PropTypes } from "base/react-libs";
import moment from "moment";
import CommentText from "apps/interactions/components/lazy-comment-text";
import InteractionUtils from "apps/interactions/interaction-utils";
import InteractionIcon from "apps/interactions/components/interaction-icon";

const ContactSummaryInteraction = ({ interaction }) => {
  const formattedDate = () => moment(date_occurred || created_at).format("MM/DD/YYYY");

  const interactionWithUpdatedType = () => {
    if (!interaction.interaction_type) return interaction;
    if (interaction.interaction_type.toLowerCase().match("evertrue comment")) {
      interaction.interaction_type = "note";
    }
    return interaction;
  };

  const { date_occurred, created_at, author, user_mention, contact_mention, interaction_type } = interaction;

  return (
    <div className="contact-summary-interaction">
      <div className="contact-summary-interaction--image">
        <InteractionIcon interaction={interactionWithUpdatedType()} />
      </div>
      <div className="contact-summary-interaction--content">
        <div>
          <span className="contact-summary-interaction--details">{interaction_type}</span>
          <div className="contact-summary-interaction--text fs-exclude-data">
            <CommentText
              text={InteractionUtils.renderText(interaction, false, null)}
              mentions={[...user_mention, ...contact_mention]}
            />
          </div>
        </div>
        <div className="fs-exclude-data">
          {formattedDate()} <br></br>
          {author.name}
        </div>
      </div>
    </div>
  );
};

ContactSummaryInteraction.propTypes = {
  interaction: PropTypes.object,
};

export default ContactSummaryInteraction;
