import { PropTypes } from "base/react-libs";
import { useState, useEffect } from "react";
import checkIcon from "images/check.svg";
import TaskCount from "apps/journeys/components/task-count/task-count";

const JourneyTaskCount = ({ todayJourneyCount = {}, loadingJourneysTodayCount }) => {
  const [showIcon, setShowIcon] = useState(false);
  const [title, setTitle] = useState("");
  const [data, setData] = useState({});

  useEffect(() => {
    const { Completed, ...rest } = todayJourneyCount;
    const { Total } = todayJourneyCount;

    let newTitle = "";
    if (Total === 0 && Completed === 0) {
      newTitle = "You have no cadence steps due";
      setShowIcon(false);
    } else if (Total > 0) {
      newTitle = null;
      setShowIcon(false);
    } else {
      newTitle = "You completed all of your cadence steps!";
      setShowIcon(true);
    }
    setTitle(newTitle);
    setData(rest);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [todayJourneyCount]);

  return (
    <TaskCount
      title="Cadence Steps"
      subTitle={title}
      showIcon={showIcon}
      icon={checkIcon}
      data={data}
      loadingCounts={loadingJourneysTodayCount}
    />
  );
};

JourneyTaskCount.propTypes = {
  todayJourneyCount: PropTypes.object,
  loadingJourneysTodayCount: PropTypes.bool,
};

export default JourneyTaskCount;
