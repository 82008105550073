module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")


   createSource "ProfileFacebookSource",
      actions:
         loading: true
         fetching: true
         fetchedEngagements: true
         fetchedPost: true
         fetchedReactionStats: true
         applyFilters: true
         loadMore: true
         changeSort: true

      api:
         fetch: (id, options) ->
            @actions.loading(true)
            @actions.fetching(id)

         search: (id, options) ->
            opts = _.omit(options, "success")
            Api.SEARCH.SOCIAL.post $.extend true, {},
               success: (resp) =>
                  @actions.fetchedEngagements(id, resp)
                  @actions.loading(false)
               error: =>
                  @actions.loading(false)
            , opts

         fetchPosts: (ids, token) ->
            return unless token
            _.each ids, (id) =>
               $.ajax
                  url: "https://graph.facebook.com/#{id}"
                  data: access_token: token
                  success: (resp) =>
                     @actions.fetchedPost(resp)

         fetchReactionStats: (id) ->
            Api.SEARCH.SOCIAL.post
               params: limit: 0
               data: _.jsonStringify
                  must: [{contact_id: match: id}]
                  must_not: [{"engagement.type": match: "comment"}]
                  facet: [{field: "engagement.type"}]
               success: (resp) =>
                  facets = resp.facets?["engagement.type"]
                  @actions.fetchedReactionStats(id, facets)

         applyFilters: (id, filter_object) ->
            @actions.applyFilters(id, filter_object)

         changeSort: (id, sort_key) ->
            @actions.changeSort(id, sort_key)

         loadMore: (id) ->
            @actions.loadMore(id)
