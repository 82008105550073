module.exports = do ->
   _ = require("underscore").default
   $ = require("jquery")
   React = require("react")
   ReactDOM = require("react-dom")
   {createComponent} = require("@evertrue/et-flux")
   {div, button, span, i} = ReactLibs.DOMFactories
   WindowStore = require("apps/layout/stores/window-store")
   {createFactory} = require("base/new-utils")
   Icon = createFactory (require("components/elements/icon").default)
   ShowHideAnimate = require("components/show-hide-animate")
   classNames = require("classnames")
   LinkButton = createFactory(require("@evertrue/et-components").LinkButton)
   Button = createFactory(require("@evertrue/et-components").Button)

   createComponent "FilterDrawer",
      propTypes:
         canApply: ReactLibs.PropTypes.bool
         onReset: ReactLibs.PropTypes.func
         onToggle: ReactLibs.PropTypes.func
         onApply: ReactLibs.PropTypes.func
         onCancel: ReactLibs.PropTypes.func
         shouldOpen: ReactLibs.PropTypes.bool
         isDisabled: ReactLibs.PropTypes.bool

      getDefaultProps: ->
         isDisabled: false

      getInitialState: ->
         visible: false
         dock_to_footer: false
         drawer_height: undefined

      registerStores: ->
         @on WindowStore, ->
            window_height: WindowStore.getHeight()

      componentDidUpdate: ->
         @checkDocking()

      UNSAFE_componentWillReceiveProps: (newProps) ->
         if !_.isUndefined newProps.shouldOpen
            if @state.visible != newProps.shouldOpen then @setState visible: newProps.shouldOpen

      checkDocking: ->
         if @state.visible
            header_height = 0
            drawer_height = @state.drawer_height || @refs.drawer.offsetHeight || 0
            offset = $(ReactDOM.findDOMNode(@)).offset().top
            footer = 50

            if (offset + header_height + drawer_height) >= @state.window_height - footer
               if !@state.dock_to_footer && @depracated_is_mounted
                  @setState {dock_to_footer: true, drawer_height: drawer_height}
                  @props.onDockChange?(true)
            else if @state.dock_to_footer && @depracated_is_mounted
               @setState {dock_to_footer: false, drawer_height: undefined}
               @props.onDockChange?(false)

      handleToggle: ->
         if !@props.isDisabled
            next_visible = !@state.visible
            @props.onToggle?(next_visible)
            @setState {visible: next_visible}

      handleCancel: ->
         @setState {visible: false}
         @props.onCancel?()

      handleApply: ->
         @setState {visible: false}
         @props.onApply?()

      render: ->
         div className: "filter-drawer",
            div className: classNames("filter-drawer--wrapper", {"is-docked": @state.visible && @state.dock_to_footer}),
               div className: classNames("filter-drawer--header", {"is-open": @state.visible}),
                  div
                     className: classNames("filter-drawer--toggle", {"is-open": @state.visible}),
                     "aria-disabled": @state.isDisabled
                     onClick: @handleToggle

                     div className: classNames("filter-drawer--toggle-wrap", {"is-disabled": @props.isDisabled}),
                        Icon icon: "filter", size: 1
                        span null, "Filters"
                        Icon icon: (if @state.visible then "chevron-up" else "chevron-down"), size: 1

                  div className: "filter-drawer--header-content",
                     if @state.visible
                        div className: "filter-drawer--header-overlay"
                     @props.header

               div
                  ref: "drawer"
                  className: classNames "filter-drawer--drawer",
                     "is-open": @state.visible,
                     "is-docked": @state.dock_to_footer && @state.visible

                  div className: "filter-drawer--content",
                     if @props.render
                        @props.render()
                     else @props.children

                  div className: "filter-drawer--footer clearfix",
                     div className: "filter-drawer--clear",
                        if @props.onReset
                           Button type: "simple", title: "Clear all filters", onClick: @props.onReset,
                              "Clear all filters"

                     div className: "filter-drawer--apply",
                        Button
                           type: "simple"
                           onClick: @handleCancel
                           "Cancel"

                        Button
                           disabled: !@props.canApply
                           onClick: => if @props.canApply then @handleApply()
                           "Apply Filters"

            ShowHideAnimate
               show: @state.visible
               animateInClass: "fadeIn"
               animateOutClass: "fadeOut"
               className: if @state.visible then "filter-drawer--overlay"
               onClick: @handleToggle
