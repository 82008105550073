module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories
   {string, number, oneOfType} = ReactLibs.PropTypes
   classNames = require("classnames")
   EngagementHelper = require("apps/events/utils/engagement-config")
   str = require("underscore.string")

   renderTitle = (network, label) ->
      _.map(_.compact([network, label]), str.capitalize).join(" - ")

   createComponent "EngagementIcon",
      propTypes:
         className: string
         icon: string
         action: string
         tab: string
         size: oneOfType [string, number]
         network: string
         network_size: string
         zIndex: number

      getDefaultProps: ->
         size: 24

      render: ->
         {size, zIndex, icon, action, tab, network, className} = @props
         action = action || icon
         size = _.toNumber(size) || size

         if tab
            {src, label} = EngagementHelper.getIconInfoByTabLabel(tab)
         else
            {src, label} = EngagementHelper.getEngagementInfoByValue(action)
         div
            className: classNames("engagement-icon", className)
            style:
               width: size
               height: size
               zIndex: zIndex
               backgroundImage: "url(#{src})"
            title: renderTitle(network, label)
            if network_src = EngagementHelper.getNetworkImageByType(network)
               network_size = size * 0.5 || 12
               div
                  className: "engagement-icon--network-image"
                  style:
                     height: network_size
                     width: network_size
                     right: network_size * - 0.25
                     bottom: network_size * - 0.25
                     backgroundImage: "url(#{network_src})"



