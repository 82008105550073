module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")
   str = require("underscore.string")

   createSource "TripLogSource",
      actions:
         loading: true
         fetchedInteractions: true
         startExport: true
         exportedTripLog: true

      api:
         fetchInteractions: (trip_id, options={}) ->
            @actions.loading(true)
            Api.SEARCH.CONTACT_NOTE.post
               data: _.jsonStringify
                  must: [{trip_id: {match: trip_id}}]
                  sort: [{date_occurred: {order: "desc"}}]
               success: (resp) =>
                  @actions.fetchedInteractions(trip_id, resp, options)
                  @actions.loading(false)
               error: =>
                  @actions.fetchedInteractions(trip_id, {items: [], total: 0}, options)
                  @actions.loading(false)

         startExport: (id) ->
            @actions.startExport(id)

         export: (trip_id, name, date) ->
            file_name = str.underscored(str.slugify(name.toLowerCase()))

            Api.EXPORTS.EXPORT_TYPE.post
               urlArgs: {type: "interaction"}
               params: {type: "trip_log"}
               data: _.jsonStringify
                  name: "trip_#{file_name}"
                  search:
                     must: [
                        {created_at: gte: date}
                        {update_source: match: "EVERTRUE"}
                        {trip_id: match: trip_id}
                     ]
                     must_not: [
                        {interaction_type: match: "EverTrue Comment"}
                     ]
               success: =>
                  @actions.exportedTripLog()

                  EverTrue.track.set "trip_action",
                     type: "export"
                     trip_id: trip_id

