module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   Decorator = require("clientDecorator")
   EverTrue = require("app")
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories
   MeetingsStore = require("apps/my-trips/stores/meetings-store")
   {createFactory} = require("base/new-utils")
   MeetingCard = createFactory require("apps/my-trips/components/meeting-card").default
   MeetingMapPin = require("apps/my-trips/components/meeting-map-pin")
   Map = createFactory require("apps/map/components/map").default
   MapMarker = require("apps/map/components/map-marker")
   MapInfoWindow = createFactory require("apps/map/components/map-info-window").default
   MapUtils = require("apps/map/map-utils")
   Loading = require("components/elements/loading")
   Icon = createFactory (require("components/elements/icon").default)
   LinkButton = createFactory(require("@evertrue/et-components").LinkButton)

   createComponent "TripMapController",
      propTypes:
         trip: ReactLibs.PropTypes.object

      getInitialState: ->
         active_marker: undefined
         active_meeting_id: undefined

      registerStores: ->
         @on MeetingsStore, ->
            meetings = MeetingsStore.getForTrip(@props.trip?.id)
            loading: MeetingsStore.getLoading()
            meetings: meetings
            meetings_contact_count: MeetingsStore.getContactCountForTrip(@props.trip?.id)
            meetings_box: @getMeetingBounds(meetings)

      componentDidMount: ->
         EverTrue.track.set "trip_action", {type: "trip_map"}
         EverTrue.track.setReferrer("trip_map")

      UNSAFE_componentWillReceiveProps: (newProps) ->
         if !_.isEqual(newProps.trip, @props.trip)
            if trip_id = newProps.trip?.id
               meetings = MeetingsStore.getForTrip(trip_id)
               @setState
                  meetings: meetings
                  meetings_contact_count: MeetingsStore.getContactCountForTrip(trip_id)
                  meetings_box: @getMeetingBounds(meetings)

      componentDidUpdate: (prevProps, prevState) ->
         if !@state.loading && _.isEmpty(@state.meetings)
            if @props.trip?.id
               EverTrue.Navigator("/trips/#{@props.trip.id}", true)

      getMeetingBounds: (meetings) ->
         if !_.isEmpty(meetings)
            meeting_latlngs = _.compact _.map meetings, (meeting) ->
               location = meeting.location
               _.pick(location, "lat", "lng") if !_.isEmpty(location)
            MapUtils.getBoxFitForMarkers(meeting_latlngs) unless _.isEmpty(meeting_latlngs)

      handleResetMarker: ->
         @state.active_marker?.close?()
         if @depracated_is_mounted
            @setState
               active_marker: undefined
               active_meeting_id: undefined

      handleHoverMeeting: (meeting) ->
         @setState {highlighted_meeting: meeting?.id}

      handleClickMeeting: (meeting) ->
         marker = @markers_map[meeting.id]
         @handleClickMarker((if marker then meeting else undefined),marker)

      handleClickMarker: (meeting, marker) ->
         @setState
            active_marker: marker
            active_meeting_id: meeting.id
         EverTrue.track.set "viewed", {type: "meeting_map_pin"}

      handleLoadMarker: (meeting, marker) ->
         @markers_map ?= {}
         @markers_map[meeting.id] = marker

      render: ->
         trip = @props.trip
         grouped_meetings = Decorator.MyTrips.getCalendarData(trip, @state.meetings)
         active_meeting = _.findWhere @state.meetings, {id: @state.active_meeting_id}


         div className: "trip-map",
            div className: "trip-map--meetings",
               if @state.loading
                  Loading()
               else
                  _.map grouped_meetings, (meetings, day) =>
                     div className: "trip-map--meeting", key: day,
                        div className: "trip-map--meeting-header", day
                        _.map meetings, (meeting, index) =>
                           div
                              key: meeting.id
                              className: "trip-map--meeting-card"
                              onMouseOver: => @handleHoverMeeting(meeting)
                              onMouseOut: => @handleHoverMeeting()

                              MeetingCard trip: trip, meeting: meeting

                              if !_.isEmpty(meeting.location)
                                 LinkButton
                                    title: "View meeting pin"
                                    className: "trip-map--card-meta"
                                    onClick: => @handleClickMeeting(meeting)
                                    Icon icon: "location", size: 1
                                    "View Pin"

            div className: "trip-map--container",
               Map
                  defaultBounds: @state.meetings_box
                  setBounds: @state.meetings_box
                  zoomOnBoundChange: false
                  loading: @state.loading
                  map_options: {styles: []}
                  onMapClick: @handleResetMarker

                  MapInfoWindow marker: @state.active_marker, onClose: @handleResetMarker,
                     MeetingMapPin trip: trip, meeting: active_meeting

                  _.map @state.meetings, (meeting, key) =>
                     position = _.pick(meeting.location, "lat", "lng")
                     icon = "/images/map_single"
                     if meeting.type is "timeblock"
                        icon = "/images/map_single_stripe"

                     if !_.isEmpty(position)
                        if meeting.anchor then icon += "_star"
                        if @state.highlighted_meeting == meeting.id then icon += "_active"

                        MapMarker
                           key: key
                           position: position
                           icon: icon
                           size: {width: 32, height: 32}
                           onLoad: (marker) =>
                              @handleLoadMarker(meeting, marker)
                           onClick: (latLng, count, marker, map) =>
                              @handleClickMarker(meeting, marker)
