module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   React = require("react")
   classNames = require("classnames")
   MyPortfolioStore = require("apps/major-gifts/stores/my-portfolio-store")
   AssignProspectStore = require("apps/major-gifts/stores/assign-prospect-store")
   {createComponent} = require("@evertrue/et-flux")
   {div, strong, button} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   Modal = createFactory require("components/modals/modal")
   ModalTrigger = createFactory require("components/modals/modal-trigger")
   ProspectColumnSelector = require("apps/major-gifts/components/prospect-column-selector")
   MyPortfolioColTable = require("apps/major-gifts/components/my-portfolio-col-table")
   ProspectGroupCheckbox = require("apps/major-gifts/components/prospect-group-checkbox")
   Loading = require("components/elements/loading")
   SearchLink = createFactory(require("components/elements/search-link"))
   HorizontalScrollViewport = require("components/controls/horizontal-scroll-viewport")
   EmptyStateMessage = createFactory(require("components/lists/empty-state-message"))
   EllipsisOverflow = createFactory require("components/formatting/ellipsis-overflow")

   createComponent "MyPorfolioColumns",
      propTypes:
         grouped_prospects: ReactLibs.PropTypes.object

      getInitialState: ->
         scroll_start: 0
         scroll_offset: 0
         page_width: 0

      registerStores: ->
         @on MyPortfolioStore, ->
            show_aging: MyPortfolioStore.getShowAging()

         @on AssignProspectStore, ->
            is_saving_assignment: AssignProspectStore.getIsSaving()

      getFilter: (status, user_remote_name) ->
         return unless status and user_remote_name and !_.isEmpty(@props.all_columns)
         columns = _.uniq @props.all_columns?.concat(@props.active_columns)
         if status is "No Status"
            values = _.map _.without(columns, "No Status"), (status) -> {value: status}
            status_val = {value: values, parameter: value: "must_not"}
         else status_val = {value: [{value: status}], parameter: value: "must"}

         assignee: [{value: user_remote_name}]
         assignee_status: status_val

      render: ->
         div className: "fixed-page-wrapper my-portfolio--wrapper",
            div className: "fixed-page-wrapper",
               if @props.loading_cols
                  Loading()
               else
                  if !_.isEmpty(@props.active_columns)
                     HorizontalScrollViewport null,
                        div className: "my-portfolio--columns",
                           _.map @props.active_columns, (col) =>
                              group = MyPortfolioStore.getGroup(col)

                              constituent_text = if group.total is 1 then "Constituent" else "Constituents"
                              div className: "my-portfolio--col", key: group.label,
                                 if group.initial_loading || @state.is_saving_assignment
                                    Loading()
                                 div className: classNames("my-portfolio--col-header", "show-aging" : @state.show_aging),
                                    ProspectGroupCheckbox
                                       className: "prospect-group--checkbox prospect--checkbox"
                                       group_ids: group.contacts

                                    div className: "my-portfolio--col-title",
                                       EllipsisOverflow null,
                                          SearchLink
                                             className: "status-title"
                                             filters: @getFilter(group.label, @props.user_remote_name)
                                             group.label

                                       div null,
                                          strong null, group.total
                                          " #{constituent_text}"

                                       if group?.median_time and @state.show_aging
                                          div null,
                                             strong null, group.median_time
                                             " median time in stage"

                                 MyPortfolioColTable
                                    group: group
                                    user_id: @props.user_id

                  else if !@props.loading_cols
                     div className: "my-portfolio--empty-msg",
                        div className: "my-portfolio--empty-text",
                           EmptyStateMessage
                              icon: "work"
                              text: "Setup Portfolio"
                              div null,
                                 "Get started using your portfolio by setting your team's Prospect Status order"

                              ModalTrigger modal: Modal({width: 660, animationClass: false}, ProspectColumnSelector()), title: "Set Stage Order",
                                 button
                                    className: "btn my-portfolio--empty-btn"
                                    disabled: if (EverTrue.store.user?.isOwner() or EverTrue.store.user?.isSuperUser()) then null else "disabled"
                                    "Set Stage Order"
