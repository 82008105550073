module.exports = do ->
   _ = require("underscore").default
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")
   RoleHelper = require("entities/helpers/role-helper")
   ErrorLogger = require("entities/helpers/error-log-helper")

   LIMIT = 100
   _fetchedCount = 0


   createSource "AllUsersSource",
      actions:
         fetchedUsers: true
         fetchedUsersByIds: true
         startFetchUsers: true
         changedUsers: true
         loading: true

      api:
         fetchUsers: (user_ids) ->
            valid_user_ids = _.compact(user_ids)
            unless _.isEmpty(valid_user_ids)
               @actions.startFetchUsers(valid_user_ids)

         fetchAllUsers: (offset = 0) ->
            @actions.loading(true)

            roles = RoleHelper.toArray()
            query = must: [{"affiliations.affiliation_roles.role_id": in: roles}]

            Api.AUTH.USER.post
               urlExtend: "/search"
               data: _.jsonStringify(query)
               params: {limit: LIMIT, offset: offset, include_profile_picture: true}
               error: (xhr) =>
                  @actions.loading(false)
                  ErrorLogger.captureRequest "All Users Error", xhr,
                     extra: {roles: roles}
               success: (resp) =>
                  @actions.fetchedUsers(resp.users)
                  @actions.loading(false)

                  # Fetch up to 500 users and prevent an infinite loop if
                  # pagination returns with improper data
                  if resp.total > (resp.offset + LIMIT)
                     _fetchedCount += 1
                     if _fetchedCount <= 5
                        @fetchAllUsers(resp.offset + LIMIT)
                     else
                        ErrorLogger.warn "Max User Fetch Reached",
                           extra: {roles: roles, resp: _.omit(resp, "items"), fetchedCount: _fetchedCount}
                  else @reset()

         fetchUsersByIds: (user_ids) ->
            user_ids = _.map _.uniq(_.compact(user_ids)), _.toNumber
            return if _.isEmpty(user_ids)
            query = must: [{id: in: user_ids}]

            Api.SEARCH.USER.post
               data: _.jsonStringify(query)
               success: (resp) =>
                  @actions.fetchedUsersByIds(user_ids, resp.items)

         changedUsers: ->
            @actions.changedUsers()

         reset: ->
            _fetchedCount = 0
