module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   React = require("react")
   AuthApi = require("entities/auth/auth-api")


   class Dna extends AuthApi.Model
      urlRoot: "dna/setting_values"
      ignoreFilters: ["giving.facebook"]

      parse: (data) ->
         _.mapObject data.settings, (setting) ->
            setting.value
