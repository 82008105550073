import _ from "underscore";
import { Component } from "react";
import PropTypes from "prop-types";
import Api from "entities/helpers/api";
import { PromiseResolver } from "@evertrue/promise-resolver";

const { number, bool, any } = PropTypes;

class EmmaListFetcher extends Component {
  static propTypes = {
    list_id: number,
    has_emma: bool,
    children: any,
  };

  render() {
    const { list_id, has_emma, children } = this.props;

    return (
      <PromiseResolver
        args={{ list_id, has_emma }}
        promise={args => {
          if (!list_id) {
            return Promise.reject("No List ID");
          } else if (!has_emma) {
            return Promise.reject("Emma Not Enabled");
          } else {
            const accountsPromise = Api.EMMA.ACCOUNTS.get();
            const listPromise = Api.EMMA.LIST.get({ urlArgs: { list_id } });

            return Promise.all([accountsPromise, listPromise])
              .then(val => {
                const [accounts, list] = val;
                return { groups: list.groups, accounts };
              })
              .catch(err => {
                console.log("error: ", err);
                throw err;
              });
          }
        }}
        render={({ data = {}, loading, error, refresh }) => {
          const accounts = data.accounts || [];
          const groups = _.filter(data.groups, ({ account_id }) => _.findWhere(accounts, { id: account_id }));

          return children({ accounts, groups, loading, error, refresh });
        }}
      />
    );
  }
}

export default EmmaListFetcher;
