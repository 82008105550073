module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   {createStore} = require("@evertrue/et-flux")
   CompanySource = require("apps/companies/sources/company-source")

   createStore "CompanyStore",
      getInitialState: ->
         data:
            square_logo_url: "/images/et_avatar_company_120.png"
         stats:
            giving_stats: {}
            employee_participation: {}
         constituents: {}

      registerActions: ->
         @on CompanySource.actions.fetchedCompany, @respondToFetchedCompany
         @on CompanySource.actions.fetchedStats, @respondToFetchedStats
         @on CompanySource.actions.fetchedCompaniesForIndustry, @respondToFetchedCompanies
         @on CompanySource.actions.fetchedConstituents, @respondToFetchedConstituents
         @on CompanySource.actions.resetData, @resetData

      resetData: ->
         @setState {data: {}, stats: {}, constituents: {}}

      respondToFetchedCompany: (data) ->
         if !data.square_logo_url
            data.square_logo_url = "/images/et_avatar_company_120.png"
         @setState {data: data}

      respondToFetchedStats: (data) ->
         stats =
            giving_stats:
               total: data.lifetime?.stats["giving.lifetime_amount"]?.total
               average: data.lifetime?.stats["giving.lifetime_amount"]?.mean
               median: @calculateMedian(data.instances?.items)
            employee_participation:
               lifetime_participation:
                  numerator: data.participation_life?.total
                  denominator: data.instances?.total
               current_year:
                  numerator: data.current_year?.total
                  denominator: data.instances?.total
               last_year:
                  numerator: data.last_year?.total
                  denominator: data.instances?.total

         stats.employee_participation.current_year = "failed" if _.isEmpty(data.current_year?.items)
         stats.employee_participation.last_year = "failed" if _.isEmpty(data.last_year?.items)

         @setState {stats: stats}

      respondToFetchedCompanies: (data) ->
         @setState
            data:
               name: data.name
               companies: data.companies
               square_logo_url: "/images/et_avatar_industry_120.png"

      respondToFetchedConstituents: (data) ->
         @setState {constituents: data}

      calculateMedian: (array_of_contacts) ->
         median = _.map array_of_contacts, (contact) ->
            contact.giving?.lifetime_amount?.value
         median = _.sortBy _.compact(median), (val) -> val
         median = median[Math.floor(median.length / 2)]
         median

      api:
         getData: -> #for company or industry
            @getState("data")

         getStats: ->
            @getState("stats")

         getConstituents: ->
            @getState("constituents")
