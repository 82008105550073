module.exports = do ->
   _ = require("underscore").default
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")

   # Map google zoom position to query precision
   ZOOM_PRECISION_MAP =
      2: 1
      3: 1
      4: 2
      5: 2
      6: 3
      7: 3
      8: 3
      9: 4
      10: 4
      11: 5
      12: 5
      13: 5
      14: 6
      15: 6
      16: 7
      17: 8

   _throttle = undefined

   createSource "MapSource",
      actions:
         loadingClusters: true
         clearClusters: true
         fetchedClusters: (zoom, data) ->
            @require _.isNumber(zoom), "zoom level should be number"
            @require _.isPagedObject(data), "data is paged object"
         changedZoom: (level) ->
            @require _.isNumber(level), "zoom level should be number"
         initializedMap: (map_object, zoom) ->
            @require _.isObject(map_object), "map_object should be an object"
            @require _.isNumber(zoom), "zoom level should be number"
         setBoundingBox: (box) ->
            @require _.isObject(box) || _.isUndefined(box), "box should be object"
         highlightCluster: (cluster_key) ->
            @require _.isString(cluster_key) || _.isArray(cluster_key) || _.isUndefined(cluster_key), "cluster_key should be string, array or undefined"

      api:
         fetchClusters: (zoom, data) ->
            if _.isEmpty(data?.query?.must)
               console.error("MapSource.fetchClusters() needs data.query to contain a must clause")
               return

            _throttle?.abort?()
            @actions.loadingClusters(true)

            query = data.query
            query.geo_cluster = [
               {field: "addresses.location", precision: ZOOM_PRECISION_MAP[zoom]}
            ]

            Api.CONTACTS.SEARCH.post
               data: _.jsonStringify(query)
               params: data.params
               beforeSend: (xhr) ->
                  _throttle = xhr
               error: =>
                  @actions.loadingClusters(false)
               success: (resp) =>
                  @actions.loadingClusters(false)
                  @actions.fetchedClusters(zoom, resp)

         clearClusters: ->
            @actions.clearClusters()

         zoom: (zoom_level) ->
            @actions.changedZoom(zoom_level)

         increasePrecision: (zoom) ->
            zoom_level = zoom + 1
            current_precision = ZOOM_PRECISION_MAP[zoom]

            if ZOOM_PRECISION_MAP[zoom_level] > current_precision
               @zoom(zoom_level)
            else
               zoom_level = _.findKey ZOOM_PRECISION_MAP, (precision, key) ->
                  precision > current_precision
               @zoom(_.toNumber(zoom_level)) if zoom_level

         initializeMap: (map_object, zoom) ->
            @actions.initializedMap(map_object, _.toNumber(zoom))

         setBoundingBox: (box) ->
            @actions.setBoundingBox(box)

         highlightCluster: (cluster_key) ->
            @actions.highlightCluster(cluster_key)
