import PropTypes from "prop-types";
import { ThankViewComponentBuilder } from "./thankview-component-builder.js";

const InteractionThankview = ({ interaction = {} }) => {
  let errorFlag = false;
  let videoUrl = "";

  if (interaction && interaction.text) {
    try {
      const text = interaction.text;
      videoUrl = text.match(/\bhttps?:\/\/\S+\/(?:[\w-]+\.(?:jpg|jpeg|gif|png|mp4))\b/) || [];
    } catch (error) {
      errorFlag = true;
    }
  } else {
    return "";
  }
  return (
    <>
      {videoUrl.length > 0 ? (
        <ThankViewComponentBuilder condition="ThankViewInteractionsVideo" interaction={interaction} />
      ) : (
        <ThankViewComponentBuilder condition="ThankViewInteractionsText" interaction={interaction} />
      )}
      {errorFlag && <ThankViewComponentBuilder condition="Error" interaction={interaction} />}
    </>
  );
};

InteractionThankview.propTypes = {
  interaction: PropTypes.object,
};

export default InteractionThankview;
