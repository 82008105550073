import { Component } from "react";
import PropTypes from "prop-types";
import ContactCard from "apps/contact/components/contact-card/contact-card";
import Decorator from "clientDecorator";
import FacebookPageLink from "./facebook-page-link";
import FacebookConnectedPagesResolver from "apps/facebook/components/facebook-connected-pages-resolver";
import Loading from "components/elements/loading";
import { Icon } from "@evertrue/et-components";

class MatchFacebookConfirm extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    postUrl: PropTypes.string.isRequired,
    contact: PropTypes.object.isRequired,
    engagement: PropTypes.object.isRequired,
  };

  static defaultProps = {
    contact: {},
    engagement: {},
  };

  render() {
    const fullName = Decorator.Contacts.getFullName(this.props.contact);
    const { contact, page, postUrl, engagement } = this.props;
    return (
      <div className="match-facebook-confirm--modal">
        <div className="match-facebook-modal--contact">
          <ContactCard contact={this.props.contact} target="_blank" />
        </div>
        <div className="match-facebook-modal--section">
          <div className="match-facebook-modal-text-title">Now Matching</div>
          Please confirm in order to match <strong>{fullName}</strong> to {page.page_name} page
          <FacebookPageLink page={page} postUrl={postUrl} engagement={engagement} />
        </div>
        <div className="match-facebook-modal-additional-matches match-facebook-list">
          <div className="match-facebook-modal-text-title">Other Matched Pages</div>
          <FacebookConnectedPagesResolver
            contact={contact}
            render={({ engagedPages, loading }) => {
              if (loading) return <Loading />;
              else if (engagedPages.length > 0) {
                return engagedPages.map(({ page, mostRecentEngagement }) => {
                  return (
                    <FacebookPageLink
                      key={page.id}
                      page={page}
                      postUrl={mostRecentEngagement.content.public_url}
                      engagement={mostRecentEngagement.engagement}
                    />
                  );
                });
              } else {
                return (
                  <div className="match-facebook-no-additional">
                    <Icon icon="flag" size={3} style={{ color: "#F3B452" }} />
                    <div style={{ width: "90%" }}>
                      <strong>This person isn't matched to any Facebook Pages.</strong> Visit the Facebook tab to
                      manually match engagers.
                    </div>
                  </div>
                );
              }
            }}
          />
        </div>
      </div>
    );
  }
}

export default MatchFacebookConfirm;
