import { useState } from "react";
import { PropTypes } from "base/react-libs";
import { setTaskCompletion } from "apps/tasks/utils/tasks-actions";
import { trackJourneysAction } from "apps/journeys/utils/helpers/mixpanel";
import { useOpenClose, Modal } from "@evertrue/et-components";
import EndJourneyModal from "apps/journeys/components/modals/end-journey-modal";
import InteractionForm from "apps/interactions/components/create/interaction-form";
import moment from "moment";
import { fetchContactsById } from "apps/volunteers/vms-queries";
import Decorator from "clientDecorator";
import IsGated, { useGate } from "components/is-gated";
import TaskRow from "./task-row";

const TaskRowDetails = ({
  step,
  journey,
  isFinalStep,
  openContactSummary,
  optionalStateManagementFunction = () => {},
}) => {
  const [isModalOpen, openModal, closeModal] = useOpenClose();
  const [isInteractionModalOpen, openInteractionModal, closeInteractionModal] = useOpenClose();
  const [taskToEnd, setTaskToEnd] = useState({});
  const [contact, setContact] = useState({});
  const [title, setTitle] = useState();

  const [hasUnrelasedFeature, loadedUnreleasedFeature] = useGate("unreleased_feature");

  const completeTaskStateUpdates = async (task, step) => {
    const markComplete = task.completed_at ? false : true;
    const type = markComplete ? "complete_step" : "uncomplete_step";
    trackJourneysAction("complete_toggle", type);
    const { completed_at } = await setTaskCompletion(task, markComplete);
    optionalStateManagementFunction("completionToggle", { task, step, completed_at });
  };

  const handleCompletionClick = async (task) => {
    const { action_type, title, completed_at, contact = {} } = task;
    if (isFinalStep && !completed_at) {
      setTaskToEnd(task);
      isModalOpen ? closeModal() : openModal();
      return;
    }
    completeTaskStateUpdates(task, step);
    if (hasUnrelasedFeature && loadedUnreleasedFeature) {
      if (action_type === "EMAIL" && !completed_at) {
        getAndSetContactDetails(contact.id);
        setTitle(title);
        isInteractionModalOpen ? closeInteractionModal() : openInteractionModal();
      }
    }
  };

  const getAndSetContactDetails = async (id) => {
    const { items } = await fetchContactsById({ contactIds: [id] });
    const [fullContact] = items;
    const decoratedContact = Decorator.Contacts.parse(fullContact);
    setContact(decoratedContact);
  };

  const { tasks } = step;

  return (
    <div>
      {tasks &&
        tasks.map((task) => (
          <TaskRow
            key={task.id}
            step={step}
            journey={journey}
            task={task}
            handleCompletionClick={handleCompletionClick}
            openContactSummary={openContactSummary}
            optionalStateManagementFunction={optionalStateManagementFunction}
          />
        ))}
      <EndJourneyModal
        isOpen={isModalOpen}
        onClose={closeModal}
        task={taskToEnd}
        step={step}
        completeTaskStateUpdates={completeTaskStateUpdates}
        currentJourney={journey}
        optionalStateManagementFunction={optionalStateManagementFunction}
      />
      <IsGated feature="unreleased_feature">
        <Modal isOpen={isInteractionModalOpen} closeModal={closeInteractionModal}>
          <InteractionForm
            saveLabel="Save"
            cancelLabel="Skip"
            contact={contact}
            interaction={{
              interaction_type: "Other letter, Fax, Email",
              summary: title,
              date_occurred: moment().valueOf(),
            }}
            requestHide={closeInteractionModal}
          />
        </Modal>
      </IsGated>
    </div>
  );
};

TaskRowDetails.propTypes = {
  step: PropTypes.object,
  tasks: PropTypes.array,
  journey: PropTypes.object,
  isFinalStep: PropTypes.bool,
  openContactSummary: PropTypes.func,
  optionalStateManagementFunction: PropTypes.func,
};

export default TaskRowDetails;
