import { Component } from "react";
import { ModalConfirm, Button } from "@evertrue/et-components";

class SelectedErrorModal extends Component {
  static propTypes = {
    remove: ReactLibs.PropTypes.func,
  };

  render() {
    return (
      <ModalConfirm onCancel={this.props.remove} hasMargin className="selected-error-modal">
        <>
          <p>
            <strong>
              Please select a maximum of 50
              <br />
              constituents at a time.
            </strong>
          </p>
          <Button onClick={() => this.props.remove()}>OK</Button>
        </>
      </ModalConfirm>
    );
  }
}

export default SelectedErrorModal;
