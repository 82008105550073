module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")
   ExportTypeConfig = require("apps/export/export-type-config").default
   ExportFieldStore = require("apps/export/stores/export-field-store")

   _initialized = false
   EXPORT_TYPES = _.keys(ExportTypeConfig)


   createSource "ExportSource",
      actions:
         errorExports: true
         fetchedExports: (exports) ->
            @require _.isPagedObject(exports), "exports should be paged object"
         loadingExports: (is_loading) ->
            @require _.isBoolean(is_loading), "is_loading should be boolean"
         download: (data) ->
            @require _.isObject(data), "data should be object"
         stopped: (id) ->
            @require !_.isUndefined(id), "export id should be defined to stop"
         fetchedLastExportDate: (type, data) ->
            @require _.contains(EXPORT_TYPES, type), "export type should be one of #{EXPORT_TYPES.join(",")}"
         started: true

      init: ->
         return if _initialized
         _initialized = true
         EverTrue.vent.on "org:initialized", => @fetch()

      exportByType: (type, data, params) ->
         new Promise (resolve, reject) ->
            Api.EXPORTS.EXPORT_TYPE.post
               urlArgs: {type: type}
               params: params
               data: _.jsonStringify(data)
               success: resolve
               statusCode: {202: resolve}
               error: reject

      api:
         fetch: ->
            @init()
            @actions.loadingExports(true)

            Api.CONTACTS.EXPORTS.get
               error: =>
                  @actions.errorExports()
                  @actions.loadingExports(false)
               success: (resp) =>
                  @actions.loadingExports(false)
                  @actions.fetchedExports(resp)

         download: (data) ->
            @actions.download(data)

         start: ->
            @actions.started()

         stop: (id) ->
            @actions.stopped(id)

         fetchLastExportDate: (type) ->
            Api.CONTACTS.EXPORT_LATEST.get
               params: {type: type}
               disableAlerts: true
               success: (resp) =>
                  @actions.fetchedLastExportDate(type, resp)

         standardExportByType: (type, query, params) ->
            exportParams = if type == "contact" then ExportFieldStore.getExportParams(params) else params;
            @exportByType(type, query, exportParams).then =>
               @start()


         dateRangeExportByType: (type, start_date, end_date) ->
            export_config = ExportTypeConfig[type] || {}
            {query, params} = export_config.getQuery(start_date, end_date)
            export_type = export_config.url_export_type || type
            @exportByType(export_type, query, params).then =>
               @start()
               @fetchLastExportDate(export_type)