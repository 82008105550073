module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")
   Decorator = require("clientDecorator")


   createSource "TripContactsSource",
      actions:
         loading: true
         addedContacts: true
         removedContact: true
         fetchedContactsForTrip: true

      api:
         fetchContactsForTrip: (trip_id, params={}) ->
            @actions.loading(true)
            LIMIT = 100

            Api.VOYAGER.CONTACTS.get
               urlArgs: {trip_id: trip_id}
               params: _.extend {limit: LIMIT}, params
               success: (resp) =>
                  if resp.total > (resp.offset + resp.limit)
                     if resp.total > 200
                        Raven.captureMessage "Max Trip Contacts Reached",
                           level: "warning"
                           extra: {trip_id: trip_id, total: resp.total}
                     else
                        @fetchContactsForTrip(trip_id, {offset: resp.offset + LIMIT})

                  # Returns a list of contact ids so this fetches the actual contacts
                  if resp.total
                     Api.CONTACTS.BULK.get
                        params: {id: resp.items}
                        success: (contacts) =>
                           full_response = _.extend _.cloneData(resp),
                              items: _.map contacts.items, (contact) ->
                                 Decorator.Contacts.parse(contact)
                           @actions.fetchedContactsForTrip(trip_id, full_response)
                           @actions.loading(false)
                  else
                     @actions.fetchedContactsForTrip(trip_id, {items: [], total: 0})
                     @actions.loading(false)

         addContactsFromQuery: (trip_id, query, params={}, isFiltersV2) ->
            LIMIT = 100
            if isFiltersV2
               Api.HUB.FILTERS_SEARCH_CONTACTS.post
                  disableAlerts: true,
                  params: {limit: LIMIT},
                  data: JSON.stringify(query),
                  success: (resp) => 
                     @addContacts(trip_id,resp.items, {preventEvents: true})
            else 
               Api.CONTACTS.SEARCH.post
                  data: JSON.stringify(query)
                  params: _.extend params, {limit: LIMIT}
                  success: (resp) =>
                     if resp.total > (resp.offset + LIMIT)
                        @addContactsFromQuery(trip_id, query, {offset: resp.offset + LIMIT})
                     else
                        EverTrue.track.set "trip_action",
                           type: "trip_add_contacts"
                           trip_contacts: resp.total
                           referrer: "trip_contacts_list"
                        @addContacts(trip_id, _.pluck(resp.items, "id"), {preventEvents: true})

         addContactsFromList: (trip_id, list_id, params={}) ->
            LIMIT = 100

            Api.CONTACTS.LIST_CONTACTS.get
               urlArgs: {id: list_id}
               params: _.extend params, {limit: LIMIT}
               success: (resp) =>
                  if resp.total > (resp.offset + LIMIT)
                     @addContactsFromList(trip_id, list_id, {offset: resp.offset + LIMIT})
                  else
                     EverTrue.track.set "trip_action",
                        type: "trip_add_contacts"
                        trip_contacts: resp.total
                        referrer: "trip_contacts_list"
                  @addContacts(trip_id, _.pluck(resp.items, "id"), {preventEvents: true})

         addContacts: (trip_id, contact_ids, options={preventSearchEvents: true}) ->
            Api.VOYAGER.CONTACTS_BULK.post
               urlArgs: {trip_id: trip_id}
               params: {contact_id: contact_ids}
               success: (trip_contacts) =>
                  @actions.addedContacts(trip_id, contact_ids, options, contact_ids.length)
                  @fetchContactsForTrip(trip_id)

                  unless options.preventEvents
                     if !options.preventTripEvents
                        EverTrue.track.set "trip_action",
                           type: "trip_add_contacts"
                           trip_contacts: contact_ids.length
                           referrer: options.referrer || "trip_contacts_name"


         removeContact: (trip_id, contact_id, options) ->
            Api.VOYAGER.CONTACTS.delete
               urlArgs: {trip_id: trip_id}
               urlExtend: "/#{contact_id}"
               success: =>
                  @actions.removedContact(trip_id, contact_id, options)
                  @fetchContactsForTrip(trip_id)
