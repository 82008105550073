import { lazy, Suspense } from "react";
import { Loading } from "@evertrue/et-components";

const CommentText = lazy(() => import("apps/interactions/components/comment-text"));

const LazyCommentText = props => {
  return (
    <Suspense fallback={<Loading />}>
      <CommentText {...props} />
    </Suspense>
  );
};

export default LazyCommentText;
