module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   EverTrue = require("app")
   Contacts = require("entities/contacts/contacts")
   UserSearch = require("entities/search/user-search")
   AuthUsers = require("entities/auth/users")
   Affiliation = require("entities/auth/affiliation")
   RoleHelper = require("entities/helpers/role-helper")
   Backbone = require("backbone")

   UserStore = ->
      users = {}
      contacts = {}
      all_users = {}
      autocompleteRequests = []
      _cache = {query: {}}

      _getUserContacts = ->
         ids = users.getContactIds()
         contacts.fetchBulk ids,
            success: ->
               users.each (user) ->
                  id = user.getContact()
                  contact = contacts.get(id)
                  user.set("contact", contact?.toJSON(), {silent: true}) if contact
               users.trigger("reset")

      _applyExtraData = ->
         users.each (user) ->
            user_id = RoleHelper.getUserId() if user.isUser()
            owner_id = RoleHelper.getOwnerId() if user.isOwner()
            aff = user.getCurrentAffiliation()
            user.set
               current_role_id: owner_id || user_id
               current_status: user?.getCurrentAffiliation()?.created_at
               matched_user_id: aff?.remote_user_id || ""
               matched_contact_id: aff?.contact_id || ""
            , {silent: true}
         users.trigger("reset")


      api = $.extend true, {}, Backbone.Events,
         initialize: ->
            users = new UserSearch()
            users.limit = 500
            all_users = users.clone()
            contacts = new Contacts()

         bindToUpdates: (callback) ->
            @listenTo users, "change reset", =>
               callback?(@getUserState())

         unbind: ->
            @stopListening()

         getUserState: (filter_self=false) ->
            data = users.toCollectionWrapper()
            data.items = _.reject users.getAccessibleUsers(), (user) ->
               user.id == EverTrue.store.user.get("id") && filter_self
            data

         getUsers: (options) ->
            users.setLimit(options.limit) if options.limit
            users.fetch _.extend {}, options,
               reset: true
               success: ->
                  all_users = users.clone()
                  _applyExtraData()
                  if options.include_contacts
                     _getUserContacts()

         getAuthUsers: (options) ->
            roles = RoleHelper.toArray()
            auth_users = new AuthUsers()
            auth_users.fetchForGivingTree
               success: ->
                  users.reset(auth_users.models)
                  all_users = users.clone()
                  _applyExtraData()
                  if options.include_contacts
                     _getUserContacts()

         getUsersTypeahead: (query) ->
            _.each autocompleteRequests, (req) -> req.abort()
            autocompleteRequests = []
            @getUsers
               search_query:
                  giving_tree_roles: true
                  name_search: query
               beforeSend: (xhr, opts) -> autocompleteRequests.push(xhr)

         filter: (query) ->
            base_users = all_users?.toJSON?()
            if _.isEmpty(query)
               filtered = base_users
            else
               filtered = _.filter base_users, (user) ->
                  email = !!user.email.toLowerCase().match(/// \b#{_.regexEscape(query.toLowerCase())} ///)
                  name = !!user.name.toLowerCase().match(/// \b#{_.regexEscape(query.toLowerCase())} ///)
                  email || name
            users.reset(filtered)

         sort: (sort_key, reverse) ->
            users.comparator = (model) -> model.get(sort_key)
            users.comparator = _.reverseSortBy(users.comparator) if reverse
            @listenToOnce users, "sort", -> users.trigger("reset")
            users.sort()

         paginate: (page) ->
            users.setPage(page)
            @getUsers(_cache.query)

         updateRemoteID: (user_id, remote_id, assignee_data, bulk_assignee_data) ->
            user = users.get(user_id)
            name = user.get("name") || "User"
            affil = new Affiliation(user?.getCurrentAffiliation())
            affil.save null,
               type: "PUT"
               attrs: {remote_user_id: remote_id}
               success_message: "#{name}'s remote ID has been updated successfully."
               error_message: "Remote ID could not be updated."
               success: ->
                  user.setAffiliation(affil.toJSON())
                  user.set "matched_user_id", affil.get("remote_user_id")
                  users.trigger("reset")

         updateContactID: (user_id, contact_id, remote_id) ->
            user = users.get(user_id)
            name = user.get("name") || "User"
            affil = new Affiliation(user?.getCurrentAffiliation())
            affil.save null,
               type: "PUT"
               attrs: {contact_id: contact_id, remote_user_id: remote_id, skip_roles_overwrite: true, match_remote_id: true}
               success_message: "#{name}'s Contact ID has been updated successfully."
               error_message: "Contact ID could not be updated."
               success: ->
                  user.setAffiliation(affil.toJSON())
                  user.set "matched_contact_id", affil.get("contact_id")
                  users.trigger("reset")

         setUser: (user_object = {}) ->
            users.get(user_object.id)?.set(user_object)
            users.trigger("reset")

         # Toggle between User/Owner Roles & Keep Other Org Roles Available
         updateRole: (user_id, new_role) ->
            user = users.get(user_id)
            old_role_id = user.get("current_role_id")
            return if _.first(new_role) == old_role_id

            # Update to add new_role to user"s roles
            affil = new Affiliation(user?.getCurrentAffiliation())
            affil.save null,
               type: "PATCH"
               attrs: {role_ids: _.makeArray(new_role)}
               success_message: "#{user.get("name")} updated to #{RoleHelper.getRoleKey(_.first(new_role))}"
               error_message: "Failed to update, #{user.get("name")} is still #{RoleHelper.getRoleKey(old_role_id)}"
               success: (model) ->
                  # Delete previous role to prevent unecessary roles
                  affil_role_id = model.getAffiliationRoleId(old_role_id)
                  affil.destroyRole affil_role_id, {disableAlerts: true}
                  user.set "current_role_id", _.first(_.makeArray(new_role))
                  user.setAffiliation(affil.toJSON())

         # Remove User/Owner Roles & Keep Other Org Roles Available
         delete: (user_id) ->
            user = users.get(user_id)
            return unless user

            affil = new Affiliation(user?.getCurrentAffiliation())
            requests = _.map user.getGivingTreeRoles(), (role) ->
               affil_role_id = affil.getAffiliationRoleId(role?.id)
               affil.destroyRole(affil_role_id, disableAlerts: true) if affil_role_id

            $.when.apply(@, _.compact(requests)).then(->
               all_users.remove(user_id)
               users.remove(user_id, {silent: true})
               users.trigger("reset")
               EverTrue.Alert.success("Removed #{user.get("name")} from EverTrue")
            ).fail(->
               EverTrue.Alert.error("Error Removing User. Please contact genius@evertrue.com for assistence")
            )

   UserStore()
