module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {input} = ReactLibs.DOMFactories
   goog = google

   _formatLocation = (place) ->
      if !place then return undefined
      location_string = if !place.formatted_address?.match(place.name) then place.name + " " else ""
      location_string += place.formatted_address || ""
      location_string

   createComponent "LocationAutocomplete",
      propTypes:
         type: ReactLibs.PropTypes.array
         value: ReactLibs.PropTypes.object
         onSelect: ReactLibs.PropTypes.func
         onClear: ReactLibs.PropTypes.func

      getDefaultProps: ->
         type: ["geocode"]

      getInitialState: ->
         location_string: undefined

      componentDidMount: ->
         text_input = @refs.location
         @autocomplete = new goog?.maps.places.Autocomplete text_input,
            type: @props.type

         goog?.maps.event.addListener @autocomplete, "place_changed",  =>
            return if @pasted
            place = @autocomplete.getPlace()
            location = place?.geometry?.location
            google_locale = {lat: location?.lat(), lng: location?.lng()}
            if _.isFunction @props.onSelect
               @props.onSelect(google_locale, place, @autocomplete)
            @setState {location_string: _formatLocation(place)}

         if @props.value?.place_id
            geocoder = new goog?.maps.Geocoder()
            geocoder.geocode {"placeId": @props.value.place_id}, (results) =>
               place = _.extend({}, @props.value, {formatted_address: results?[0]?.formatted_address})
               @setState {location_string: _formatLocation(place)}

      componentWillUnmount: ->
         goog?.maps.event.clearListeners(@autocomplete)

      handleFocus: ->
         $(@refs.location).select()

      handlePaste: ->
         @pasted = true

      handleBlur: (e) ->
         if @pasted
            @pasted = false
            geocoder = new goog?.maps?.Geocoder()
            geocoder?.geocode {address: e.currentTarget.value}, (results) =>
               place = _.first(results)
               if place
                  location = place?.geometry?.location
                  google_locale = {lat: location?.lat(), lng: location?.lng()}
                  @props.onSelect?(google_locale, place)
                  @setState {location_string: place.formatted_address}

      handleChange: (e) ->
         value = e.currentTarget.value
         @setState {location_string: value}
         if _.isEmpty(value)
            @props.onClear?()

      handleKeyUp: (e) ->
         value = e.currentTarget.value
         @props.onKeyUp(value)

      render: ->
         input
            type: "text"
            ref: "location"
            value: @state.location_string || ""
            onFocus: @handleFocus
            onBlur: @handleBlur
            onPaste: @handlePaste
            onChange: @handleChange
            onClick: @handleClick
            className: @props.className || ""
