module.exports = do ->
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {div, span} = ReactLibs.DOMFactories
   {number} = ReactLibs.PropTypes
   classNames = require("classnames")

   createComponent "AnnualGivingScore",
      propTypes:
         score: number

      render: ->
         div className: classNames("annual-giving-score", @props.className),
            div className: "annual-giving-score--wrapper",
               div
                  className: "annual-giving-score--inner"
                  style: width: @props.score + "%"

            div className: "annual-giving-score--label",
               span className: "annual-giving-score--numerator", @props.score
               span className: "annual-giving-score--divider", "/"
               span className: "annual-giving-score--denominator", "100"

