import { PropTypes } from "base/react-libs";
import { getEmptySectionName } from "apps/home/utils/helpers";

const EmptyRecommendedSection = ({ subtitle }) => {
  const section = getEmptySectionName(subtitle);

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
      <div className="homepage-recommendations-empty">No {section} related recommendations.</div>
    </div>
  );
};

EmptyRecommendedSection.propTypes = {
  subtitle: PropTypes.string,
};

export default EmptyRecommendedSection;
