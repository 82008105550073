module.exports = do ->
   EverTrue = require("app")
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   MyTeamStore = require("apps/my-team/stores/my-team-store")
   MyTeamSource = require("apps/my-team/sources/my-team-source")
   {div} = ReactLibs.DOMFactories
   MyTeamHeader = require("apps/my-team/components/my-team-header")
   MyTeamNav = require("apps/my-team/components/my-team-nav")
   MyTeamRollupTable = require("apps/my-team/components/my-team-rollup-table")
   ReportPageController = require("apps/my-team/controllers/report-page-controller")
   Loading = require("components/elements/loading")
   str = require("underscore.string")

   createComponent "MyTeamController",
      propTypes:
         report: ReactLibs.PropTypes.string

      registerStores: ->
         @on MyTeamStore, "report", (props) ->
            rollup: MyTeamStore.getReportsRollup()
            visits_configured: !!MyTeamStore.getVisitTypes()
            loading: MyTeamStore.getLoading()

      componentDidMount: ->
         MyTeamSource.startInitialFetch()
         @handleTrack(@props.report)

      UNSAFE_componentWillReceiveProps: (newProps) ->
         if newProps.report != @props.report
            @handleTrack(newProps.report)

      handleTrack: (report) ->
         EverTrue.track.set "report_action",
            type: "report_action_view"
            report: "team - #{str.humanize(report).toLowerCase() || "summary"}"

      render: ->
         div className: "my-team",
            MyTeamHeader {report: @props.report}

            div className: "my-team--body",
               if @state.loading then Loading()

               div className: "my-team--nav",
                  MyTeamNav
                     report: @props.report
                     visitsAreConfigured: @state.visits_configured

               div className: "my-team--content",
                  if @props.report
                     ReportPageController
                        report: @props.report
                  else
                     MyTeamRollupTable
                        data: @state.rollup
                        visitsAreConfigured: @state.visits_configured
                        loading: @state.loading
