import { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import PropTypes from "prop-types";
import { useGate } from "components/is-gated";
import CustomImageResize from "./utils/custom-image-resize";
import DynamicFieldPopup from "./dynamic-popup";
import useDynamicFields from "./hooks/use-dynamic-fields";

const CustomIcon = `
  <svg viewBox="0 0 1 15" class="dynamic-fields" role="img">
   <title>Add Dynamic Field</title>
   <p id="dynamic-fields" style="margin-top:0.04rem;" >{ }</p>
  </svg>
`;

export const RichTextEditor = ({
  setEditorBody,
  editorBody,
  className,
  quillRef,
  placeholderText = "Compose an email...",
  onTextChange = () => { },
  isSignatureForm = false,
  contact,
  autoReplaceDynamicFields = false,
  enableDynamicFields = false,
  isTemplateBody,
}) => {
  const quill = quillRef && quillRef.current && quillRef.current.getEditor();
  const fontNames = [
    "arial",
    "times new roman",
    "monospace",
    "arial black",
    "arial narrow",
    "comic sans ms",
    "garamond",
    "georgia",
    "tahoma",
    "trebuchet ms",
    "verdana",
    "brush script mt",
  ];
  const TRIM_NEWLINES = /("\\\\n(\\\\n){0,3}"\}\]\})$/;

  const [hasUnrelasedFeature] = useGate("unreleased_feature");
  const [formatValues, setFormatValues] = useState({});
  const [formatLength, setFormatLength] = useState(0);
  const quillContents = quill && quill.getContents();
  const quillSelection = quill && quill.getSelection();

  const {
    showDynamicPopup,
    setShowDynamicPopup,
    popupPosition,
    filteredFields,
    handleFieldClick,
    dynamicFieldsHandler,
    dynamicPopupViaToolbar,
  } = useDynamicFields(quillRef, contact, isSignatureForm, autoReplaceDynamicFields, isTemplateBody);

  useEffect(() => {
    if (quillRef.current && quillContents) {
      const quill = quillRef.current.getEditor();

      if (quillSelection !== null && !quill.hasFocus()) {
        quill.focus();
      }

      if (isTemplateBody && quill.getSelection() && quill.getSelection().index === 0) {
        quill.setSelection({ index: quill.getText().length, length: 0 });
      }

      if (
        quillContents.ops[quillContents.ops.length - 2] &&
        quillContents.ops[quillContents.ops.length - 2].insert.length === 1
      ) {
        setFormatValues(quillContents.ops[quillContents.ops.length - 2].attributes);
        setFormatLength(quillContents.ops.length);
      }
      if (quillContents.ops.length < formatLength - 1 && quillContents.ops.length !== 0) {
        for (const property in formatValues) {
          quill.format(`${property}`, `${formatValues[property]}`);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quillContents, formatValues, formatLength, quillRef, isTemplateBody]);

  useEffect(() => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      const handleClickOutside = (event) => {
        const pattern = /(?:^|\s)ql-picker-label(?:\s|$)/;
        if (
          (quill.hasFocus() || document.activeElement.className.match(pattern)) &&
          !quill.container.contains(event.target) &&
          quill.getFormat().background === "#ACCEF7"
        ) {
          quill.format("background", false);
        }
      };
      document.addEventListener("click", handleClickOutside);
      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    }
  }, [quillRef]);

  if (quillRef.current) {
    const quill = quillRef.current.getEditor();
    quill.keyboard.bindings[9].unshift({
      key: 9,
      handler: () => quill.blur(),
    });
    const updatedContent = JSON.stringify(quill.getContents());
    if (updatedContent.match(TRIM_NEWLINES) && quill.hasFocus()) {
      let child = document.querySelector(".ql-size").firstElementChild;
      if (child) {
        child.className = "ql-picker-label ql-active";
        child.setAttribute("data-value", quill.getFormat().size);
      }
    }
    quill.on("text-change", (delta, oldDelta, source) => {
      if (source === "user") {
        onTextChange();
      }
    });
  }

  const Quill = ReactQuill.Quill;
  Quill.import("ui/icons")["dynamic-fields"] = CustomIcon;
  const FontAttributor = Quill.import("attributors/style/font");
  FontAttributor.whitelist = fontNames;
  Quill.register(FontAttributor, true);
  const Size = Quill.import("attributors/style/size");
  Size.whitelist = ["13px", "16px", "19px"];
  Quill.register(Size, true);
  Quill.register(Quill.import("attributors/style/direction"), true);
  Quill.register(Quill.import("attributors/style/align"), true);
  Quill.register(Quill.import("attributors/style/color"), true);
  Quill.register("modules/imageResize", CustomImageResize);

  let Link = Quill.import("formats/link");

  Link.sanitize = function (url) {
    if (!(url.startsWith("http://") || url.startsWith("https://"))) {
      return "http://" + url;
    }
    return url;
  };

  const toolbarOptions = [
    [
      { font: fontNames },
      { size: ["13px", "16px", "19px"] },
      "bold",
      "italic",
      "underline",
      { color: [] },
      { background: [] },
    ],
    [{ align: "" }, { align: "center" }, { align: "right" }, { list: "ordered" }, { list: "bullet" }],
    hasUnrelasedFeature ? [{ link: "link" }, { image: "image" }] : [{ link: "link" }],
  ];

  if (!isSignatureForm && hasUnrelasedFeature) {
    toolbarOptions.push(["dynamic-fields"]);
  }

  const modules = {
    toolbar: {
      handlers: {

      },
      container: toolbarOptions,
    },
    imageResize: { modules: ["Resize"] },
  };

  if (hasUnrelasedFeature) {
    Object.assign(modules.toolbar.handlers, { "dynamic-fields": dynamicFieldsHandler });
  }

  const handleDrop = (e) => {
    if (hasUnrelasedFeature) {
      e.preventDefault();
      e.stopPropagation();

      const editor = quillRef.current.getEditor();
      const selection = editor.getSelection();

      if (!selection) {
        return;
      }

      const cursorPosition = selection.index;
      const file = e.dataTransfer.files[0];
      const reader = new FileReader();

      reader.onload = (event) => {
        const base64ImageSrc = event.target.result;
        editor.insertEmbed(cursorPosition, "image", base64ImageSrc);
        editor.setSelection(cursorPosition + 1); // Move cursor after image
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <div onDrop={handleDrop} onDragOver={(e) => e.preventDefault()} style={{ overflow: "hidden" }}>
        <ReactQuill
          className={className || "email-form--body-inner"}
          value={editorBody}
          onChange={(e) => setEditorBody(e, quillRef)}
          placeholder={placeholderText}
          modules={modules}
          theme="snow"
          ref={quillRef}
        />
      </div>
      {enableDynamicFields && hasUnrelasedFeature && (
        <DynamicFieldPopup
          showDynamicPopup={showDynamicPopup}
          setShowDynamicPopup={setShowDynamicPopup}
          popupPosition={popupPosition}
          filteredFields={filteredFields}
          handleFieldClick={handleFieldClick}
          dynamicPopupViaToolbar={dynamicPopupViaToolbar}
        />
      )}
    </>
  );
};

RichTextEditor.propTypes = {
  editorBody: PropTypes.string,
  setEditorBody: PropTypes.func,
  className: PropTypes.string,
  quillRef: PropTypes.object,
  placeholderText: PropTypes.string,
  onTextChange: PropTypes.func,
  isSignatureForm: PropTypes.bool,
  contact: PropTypes.object,
  autoReplaceDynamicFields: PropTypes.bool,
  enableDynamicFields: PropTypes.bool,
  isTemplateBody: PropTypes.bool,
};

export default RichTextEditor;
