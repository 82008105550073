import MapContactCard from "apps/search/components/map-contact-card";
import ScoreProgress from "apps/contact/components/contacts/score-progress";
import ReactDOMServer from "react-dom/server";
import getGoogleApi from "apps/search/utils/get-google-api";
import { useFluxStore } from "@evertrue/et-flux";
import FeatureStore from "apps/layout/stores/feature-store";

const google = getGoogleApi();

const useMapMarkerTooltip = () => {
  const featureStoreMapState = () => ({
    hasScores: FeatureStore.hasFeature("scores"),
  });

  const { hasScores } = useFluxStore(FeatureStore, featureStoreMapState);

  const contactScoreOrIndex = (donorIndex, score) => {
    return (
      <div className="map-contacts--score">
        {hasScores && (
          <>
            <span className="text=label">EverTrue Score:</span>
            <ScoreProgress score={score} />
          </>
        )}
      </div>
    );
  };

  const InfoWindowContent = (position) => {
    const { contactInfo, contactId } = position;
    return (
      <MapContactCard mapMarkerTooltipContactInfo={contactInfo} contact={{ id: contactId }}>
        {contactScoreOrIndex(contactInfo.donorIndex, contactInfo.score)}
      </MapContactCard>
    );
  };

  const handleMarkerMouseOver = (marker, map, position, googleMapMarkers) => {
    const content = ReactDOMServer.renderToString(InfoWindowContent(position));
    marker.infoWindow = new google.InfoWindow({
      content: content,
    });
    marker.infoWindow.open(map, marker);
  };

  const closeAllInfoWindow = (googleMapMarkers) => {
    googleMapMarkers.current.forEach(({ marker }) => {
      marker.infoWindow && marker.infoWindow.close();
    });
  };

  return { handleMarkerMouseOver, closeAllInfoWindow };
};

export default useMapMarkerTooltip;
