module.exports = do ->
   _ = require("underscore").default
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")


   createSource "InteractionSearchSource",
      actions:
         query: true
         reset: true
         paginate: true
         sort: (prop, reverse) ->
            @require _.isString(prop), "sort props should be a string"
            @require _.isBoolean(reverse) || _.isUndefined(reverse), "reverse should be boolean or undefined"
         loading: (is_loading) ->
            @require _.isBoolean(is_loading), "is_loading should be a boolean"
         fetchedInteractions: (resp) ->
            @require _.isPagedObject(resp), "response should be a paged object"

      api:
         fetch: (data) ->
            @actions.loading(true)

            Api.SEARCH.CONTACT_NOTE.post
               data: _.jsonStringify(data?.query || {})
               params: data.params
               success: (resp) =>
                  @actions.fetchedInteractions(resp)
                  @actions.loading(false)

         query: ->
            @actions.query()

         reset: ->
            @actions.reset()

         sort: (prop, reverse) ->
            @actions.sort(prop, reverse)

         paginate: ->
            @actions.paginate()



