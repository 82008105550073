module.exports = do ->
   moment = require("moment")
   EverTrue = require("app")
   DNAStore = require("base/dna/dna-store").default

   Reports =
      "visits":
         sort: 1
         title: "Visit Interactions"
         endpoint: "/myteam/visits"
         help_tip: "my_team_visits"

      "my-trips":
         sort: 2
         title: "My Trips Meetings"
         endpoint: "/myteam/meetings"
         help_tip: "my_team_meetings"
         gated: ->
            EverTrue.store?.org?.hasFeature?("my_trips")

      "interactions":
         sort: 3
         title: "Interactions By Type"
         endpoint: "/myteam/interactions"

      "prospects":
         sort: 4
         title: "Prospect Distribution"
         endpoint: "/myteam/prospects"
         datepicker: false
         help_tip: "my_team_prospects"

      "proposal-amount":
         sort: 5
         title: "Proposals By Value"
         endpoint: "/myteam/proposals"
         gated: ->
            EverTrue.store?.org?.hasFeature?("proposal_reads")

      "proposal-stage":
         sort: 6
         title: "Proposals By Stage"
         endpoint: "/myteam/proposals_by_status"
         gated: ->
            EverTrue.store?.org?.hasFeature?("proposal_reads")
