module.exports = do ->
   _ = require("underscore").default
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")

   createSource "MatchContactSource",
      actions:
         loading: true
         fetchedMatchedContactIds: (contact_ids) ->
            @require _.isArray(contact_ids), "contact_ids should be an object"
         fetchedContactsByName: (contacts) ->
            @require _.isObject(contacts), "contacts should be an object"
         fetchedAllContacts: (contacts) ->
            @require _.isObject(contacts), "contacts should be an object"

      api:
         fetchMatchedContactIds: () ->
            matched_contact_ids = undefined
            @actions.loading(true)
            Api.AUTH.AFFIL.get(
               params: {limit: 500, offset: 0, matched_contacts_only: true}
               success: (resp) =>
                  matched_contact_ids = _.map(resp.affiliations, (affiliation) => affiliation.contact_id )
                  @actions.fetchedMatchedContactIds(matched_contact_ids)
               error: =>
                  @actions.loading(false)
            ).then =>
               data = {}

               if matched_contact_ids
                  matched_contact_ids_query = _.map matched_contact_ids, (contact_id) -> JSON.parse("{ \"id\": { \"match\": " + contact_id + " } }")
                  data["must_not"] = matched_contact_ids_query

               Api.CONTACTS.SEARCH.post
                  params: { limit: 100, offset: 0 }
                  data: JSON.stringify(data)
                  success: (resp) =>
                     @actions.loading(false)
                     if name is ""
                        @actions.fetchedAllContacts(resp)
                     else
                        @actions.fetchedContactsByName(resp)
                  error: =>
                     @actions.loading(false)

         fetchContactsByName: (name, matched_contact_ids) ->
            @actions.loading(true)
            name = "" if _.isUndefined(name)
            search_fields = ["name_first", "name_last", "name_nick"]
            data = {}

            if name
               # construct search query data based on name input. match on each possible field split by whitespace
               # ex: {
               #  "must": [
               #    {
               #      "name_first": {
               #        "wildcard": "some*"
               #      },
               #      "name_last": {
               #        "wildcard": "some*"
               #      },
               #      "name_nick": {
               #        "wildcard": "some*"
               #      }
               #    },
               #    {
               #      "name_first": {
               #        "wildcard": "name*"
               #      },
               #      "name_last": {
               #        "wildcard": "name*"
               #      },
               #      "name_nick": {
               #        "wildcard": "name*"
               #      }
               #    }
               #  ]
               #}
               name_parts = name.split(" ")
               query_parts =
                  _.map name_parts, (name_part) ->
                     _.map search_fields, (field) ->
                        {
                           field: field
                           operator: "wildcard"
                           value: name_part + "*"
                        }

               data_parts = _.map query_parts, (query_part) ->
                  data_part_obj = {}
                  _.each query_part, (inner_query_part) ->
                     inner_query_obj = {}
                     inner_query_obj[inner_query_part.operator] = inner_query_part.value

                     data_part_obj[inner_query_part.field] = inner_query_obj
                  data_part_obj

               data["must"] = data_parts

            if matched_contact_ids
               matched_contact_ids_query = _.map matched_contact_ids, (contact_id) -> JSON.parse("{ \"id\": { \"match\": " + contact_id + " } }")
               data["must_not"] = matched_contact_ids_query

            Api.CONTACTS.SEARCH.post
               params: { limit: 100, offset: 0 }
               data: JSON.stringify(data)
               success: (resp) =>
                  @actions.loading(false)
                  if name is ""
                     @actions.fetchedAllContacts(resp)
                  else
                     @actions.fetchedContactsByName(resp)
               error: =>
                  @actions.loading(false)
