import journeysQueries from "../../../journeys/utils/gql/queries";
import ErrorLogger from "entities/helpers/error-log-helper";
import { fetchFromGraphql } from "entities/helpers/gqlCall";

export const getCompletedAndOverdueTasksByDate = async (startDate, endDate, userId) => {
  const query = journeysQueries.fetchCompleteAndOverdueTasks;
  const params = {
    startDate,
    endDate,
    userId,
  };

  try {
    const { getCompletedAndOverdueTasks } = await fetchFromGraphql(query, params, "getCompletedAndOverdueTasks");
    return getCompletedAndOverdueTasks;
  } catch (e) {
    ErrorLogger.error("Reporting Error Fetching Tasks", { extra: { error: e, page_name: "reporting" } });
    return [];
  }
};

export const uniqueContactJourneyCountByDate = async (startDate, endDate, userId, prevCount) => {
  const invalidDate = "Invalid date";
  const query = journeysQueries.fetchContactJourneys;

  const params = {
    startDate: startDate,
    endDate: endDate,
    userId: userId,
  };

  if (startDate === invalidDate || endDate === invalidDate) {
    return prevCount;
  }
  const { contactJourneyByDate } = await fetchFromGraphql(query, params, "contactJourneyByDate");

  var uniqueContactIds = [...new Set(contactJourneyByDate.map((p) => p.contact_id))];

  return uniqueContactIds.length;
};
