module.exports = do ->
   _ = require("underscore").default
   classNames = require("classnames")
   EverTrue = require("app")
   React = require("react")
   Decorator = require("clientDecorator")
   AllUsersStore = require("apps/users/stores/all-users-store").default
   FacetStore = require("apps/filters/stores/facet-store")
   FacetSource = require("apps/filters/sources/facet-source")
   AssignProspectSource = require("apps/major-gifts/sources/assign-prospect-source")
   AssignProspectStore = require("apps/major-gifts/stores/assign-prospect-store")
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   ModalConfirm = createFactory require("components/modals/modal-confirm")
   AdvancedCombobox = createFactory(require("@evertrue/et-components").AdvancedCombobox)
   ProspectStatusDropdown = require("apps/major-gifts/components/prospect-status-dropdown")

   ASSIGNEE_STATUS = "assignees.status"
   ASSIGNEE_TYPE = "assignees.type"
   DROPDOWN_CLASSES = "assign-contact--dropdown advanced-combobox-secondary"


   createComponent "BulkAssignContactModal",
      propTypes:
         contactIds: ReactLibs.PropTypes.array

      getInitialState: ->
         search_query: undefined
         selected_data:
            name: undefined
            user_id: undefined
            remote_user_id: undefined
            type: undefined

      registerStores: ->
         @on FacetStore, ->
            facets_loading: FacetStore.getLoading()
            status_facets: FacetStore.getByKey(ASSIGNEE_STATUS)
            type_facets: FacetStore.getByKey(ASSIGNEE_TYPE)

         @on AssignProspectStore, ->
            is_saving: AssignProspectStore.getIsSaving()

         @on AllUsersStore, ->
            users: AllUsersStore.getAllGivingTreeUsers()
            loading_users: AllUsersStore.getLoading()

      componentDidMount: ->
         FacetSource.fetch([ASSIGNEE_STATUS, ASSIGNEE_TYPE])

      componentDidUpdate: (prevProps, prevState) ->
         if prevState.is_saving && !@state.is_saving
            @handleCancel()

      getOptions: (facets) ->
         options = [{label: "None", value: null}]
         _.each facets, (facet) ->
            options.push {label: facet.value, value: facet.value}
         options

      getUserOptions: (users) ->
         user_options = _.compact _.map users, (user) ->
            {label: user.name, value: user.id}
         user_options = _.sortBy user_options, "label"
         user_options.unshift {label: "None", value: null}

         _.filter user_options, (option) =>
            _.searchCompare(option.label, @state.search_query)

      handleCancel: ->
         if _.isFunction @props.requestHide
            @props.requestHide()

      handleSave: ->
         data = _.cloneData(@state.selected_data)
         data.status = data.status.value
         AssignProspectSource.add(data.user_id, @props.contactIds, data)

      handleChangeAssignee: (val) ->
         name = val.label
         user_id = val.value

         selected_user =  _.findWhere(@state.users, {id: user_id})
         oid = EverTrue.store.org.get("id")
         if selected_user
            selected_data = _.cloneData @state.selected_data
            _.extend selected_data,
               name: name
               user_id: user_id
               remote_user_id: Decorator.User.getAffiliationFor(selected_user, oid)?.remote_user_id
            @setState {selected_data: selected_data}

      handleChange: (key, value) ->
         selected_data = _.cloneData @state.selected_data
         selected_data[key] = value
         @setState {selected_data: selected_data}

      handleSearch: (query) ->
         @setState {search_query: query}

      render: ->
         {name, status, type, user_id} = @state.selected_data

         ModalConfirm
            className: "bulk-assign--modal"
            type: "success"
            header: "Add Assignment"
            buttonLabel: "Save"
            onSubmit: @handleSave
            onCancel: @handleCancel
            hide_close_icon: true
            is_saving: @state.is_saving
            disableButton: _.isEmpty(@state.selected_data)
            disableCancelButton: @state.is_saving

            div className: "bulk-assign--body clearfix",

               div className: "bulk-assign--dd-title", "Solicitor"
               AdvancedCombobox
                  searchable: true
                  className: classNames(DROPDOWN_CLASSES, "is-empty": !name)
                  value: {value: user_id, label: name}
                  options: @getUserOptions(@state.users)
                  loading: @state.loading_users
                  disabled: @state.is_saving
                  onChange: @handleChangeAssignee
                  onSearch: (query) => @handleSearch(query)

               div className: "bulk-assign--dd-title", "Solicitor Type"
               AdvancedCombobox
                  searchable: true
                  className: classNames(DROPDOWN_CLASSES, "is-empty": !type)
                  value: {value: type}
                  options: @getOptions(@state.type_facets)
                  loading: @state.facets_loading
                  disabled: !name || @state.is_saving
                  onChange: (val) => @handleChange("type", val.value)

               div className: "bulk-assign--dd-title", "Prospect Status"
               ProspectStatusDropdown
                  className: classNames(DROPDOWN_CLASSES, "is-empty": !status)
                  value: status
                  disabled: !name || @state.is_saving
                  includeNone: true
                  onChange: (val) => @handleChange("status", val)
