import { useEffect, useState } from "react";
import Api from "entities/helpers/api";
import DEFAULT_QUERY from "apps/query-builder/utils/default-query";

const useTotalCount = () => {
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  //This effect is for loading the totalCount on initial mount
  useEffect(() => {
    const loadResultCount = async () => {
      setLoading(true);

      try {
        const resp = await Api.HUB.FILTERS_SEARCH_CONTACTS.post({
          params: { limit: 0 },
          data: JSON.stringify(DEFAULT_QUERY),
        });
        setTotalCount(resp.total);
        setLoading(false);
      } catch {
        setLoading(false);
      }
    };

    loadResultCount();
  }, []);

  return [totalCount, loading];
};

export default useTotalCount;
