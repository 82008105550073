module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   {ul, li, span} = ReactLibs.DOMFactories
   {createComponent} = require("@evertrue/et-flux")
   {createFactory} = require("base/new-utils")
   Dropdown = createFactory require("components/controls/dropdown")
   HelpTip = createFactory require("components/tooltip/helptip").default
   Icon = createFactory (require("components/elements/icon").default)
   WindowSource = require("apps/layout/sources/window-source")
   LinkButton = createFactory(require("@evertrue/et-components").LinkButton)

   createComponent "MyTeamTableDropdown",
      propTypes:
         column: ReactLibs.PropTypes.object
         sort: ReactLibs.PropTypes.object
         onSort: ReactLibs.PropTypes.func

      handleSort: (prop, desc) ->
         WindowSource.outsideClick()
         prop ?= @props.sort?.sortProp
         if prop != @props.sort?.sortProp then desc = false
         else desc ?= @props.sort?.sortReverse
         @props.onSort(prop, desc)

      renderColumnHeader: ->
         col = @props.column
         sort_prop = @props.sort?.sortProp
         sort_reverse = @props.sort?.sortReverse
         span className: "contact-fixed-table--header-label",
            col.label
            if col.prop && sort_prop?.match(col.prop)
               Icon
                  className: "contact-fixed-table--sort",
                  icon: if sort_reverse then "arrow-up" else "arrow-down"
            if col.help_tip
               HelpTip help_key: col.help_tip

      renderListItem: (label, prop, desc) ->
         li null,
            LinkButton title: "Sort #{label}", onClick:(=> @handleSort(prop, desc)),
               span className: "my-team-table-dropdown--icon",
                  if !_.isUndefined(desc)
                     Icon icon: if desc then "arrow-up" else "arrow-down"
                  else if @props.sort?.sortProp == prop
                     Icon icon: "check"
               label

      render: ->
         column_prop = @props.column?.prop
         if @props.column?.key in ["proposals", "fundraiser"]
            column_prop = undefined

         Dropdown header: @renderColumnHeader(), stay_open: true,
            switch @props.column?.key
               when "proposals"
                  ul className: "contact-fixed-table--header-menu",
                     @renderListItem("Sort by Ask", "proposal-amount.Ask Amount")
                     @renderListItem("Sort by Expected", "proposal-amount.Expected Amount")
                     @renderListItem("Sort by Funded", "proposal-amount.Funded Amount")
               when "fundraiser"
                  ul className: "contact-fixed-table--header-menu",
                     @renderListItem("Sort by Gift Officer", "name")
                     @renderListItem("Sort by Prospects", "prospects_total")

            ul className: "contact-fixed-table--header-menu my-team-table-dropdown--order",
               @renderListItem("Sort Ascending", column_prop, false)
               @renderListItem("Sort Descending", column_prop, true)
