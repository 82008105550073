import { useState } from "react";
import Decorator from "clientDecorator";
import EverTrue from "app";
import PropTypes from "prop-types";
import MeetingForm from "apps/my-trips/components/meeting-form";
import ContactCard from "apps/contact/components/contact-card/contact-card";
import ContactGiving from "apps/contact/components/contact-card/contact-giving";
import BioContactInfo from "apps/profile/components/bio-contact-info";
import ActionMenuItem from "components/controls/action-menu-item";
import {
  ModalConfirm,
  ModalTrigger2,
  Modal2,
  Icon,
  Popover2,
  LinkButton,
  useOpenClose,
  Card,
} from "@evertrue/et-components";
import MeetingDetails from "apps/my-trips/components/meeting-details-modal";
import TripContactsSource from "apps/my-trips/sources/trip-contacts-source";
import colors from "base/colors";

const TripContactCard = (
  props = {
    trip: {},
    contact: {},
    meetings: [],
    position: "top",
  }
) => {
  const renderRemoveModal = () => {
    const name = Decorator.Contacts.getFullName(contact);
    EverTrue.Alert.confirm(
      {
        content: `Are you sure you want to remove ${name} from your ${props.trip.name} trip?`,
        headline: "Remove Constituent from Trip?",
      },
      (e) => {
        if (e) {
          TripContactsSource.removeContact(props.trip.id, contact.id);
        }
      }
    );
  };

  const [is_meeting_form_modal_open, openMeetingForm, closeMeetingForm] = useOpenClose();
  const [is_meeting_details_modal_open, openMeetingDetails, closeMeetingDetails] = useOpenClose();
  const [meetingToEdit, setMeetingToEdit] = useState({});

  const { trip, contact, meetings } = props;
  const name = Decorator.Contacts.getFullName(contact);
  return (
    <Card className="trip-contacts--card" cardSize="small" hoverTransform="draggable">
      <div className="trip-contacts--card-content">
        {trip.is_my_trip && (
          <div className="trip-card--menu">
            <Popover2 trigger={<Icon icon="more-horiz" size={1} />}>
              <div className="trip-card--menu-content">
                <ActionMenuItem icon="add-meeting" title="Add to meeting" onClick={openMeetingForm}>
                  Add to Meeting
                </ActionMenuItem>

                <ActionMenuItem title="View Profile" icon="person" href={`/contact/${contact.id}`}>
                  View Profile
                </ActionMenuItem>

                <ActionMenuItem
                  title="Remove from Trip"
                  className="text-danger"
                  icon="delete"
                  onClick={() => renderRemoveModal(contact)}
                >
                  Remove from Trip
                </ActionMenuItem>
              </div>
            </Popover2>
          </div>
        )}

        <div className="trip-contacts--contact-card">
          <ContactCard contact={contact} showNameLink={false}>
            <ContactGiving contact={contact} />
          </ContactCard>
          <div className="trip-contacts--contact-card-footer">
            <ModalTrigger2
              closeOnOutsideClick
              trigger={({ open }) => {
                return (
                  <LinkButton onClick={open} title="Click for contact info">
                    <Icon icon="info" size={1} />
                    Info
                  </LinkButton>
                );
              }}
            >
              {({ close }) => {
                return (
                  <ModalConfirm
                    header="Contact Info"
                    closeModal={close}
                    style={{
                      width: 500,
                    }}
                  >
                    <div className="trip-contacts--bio-info">
                      <ContactCard contact={contact} className="trip-contacts--contact-card" />
                      <BioContactInfo contact={contact} />
                    </div>
                  </ModalConfirm>
                );
              }}
            </ModalTrigger2>

            {!!meetings.length && (
              <div className="trip-contacts--meeting-menu">
                <Popover2
                  borderColor={colors.blue300}
                  trigger={
                    <LinkButton title="Click for more info on this contact's meetings">
                      <Icon icon="date-range" size={1} />
                      {` ${meetings.length} Meeting${meetings.length > 1 ? "s" : ""}`}
                    </LinkButton>
                  }
                >
                  <div className="trip-contacts--meeting-menu-inner">
                    {meetings.map((meeting) => {
                      return (
                        <ActionMenuItem
                          key={meeting.id}
                          onClick={() => {
                            openMeetingDetails();
                            setMeetingToEdit(meeting);
                          }}
                        >
                          {meeting.name}
                        </ActionMenuItem>
                      );
                    })}
                  </div>
                </Popover2>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal2 isOpen={is_meeting_form_modal_open} onClose={closeMeetingForm}>
        <MeetingForm
          tripId={props.trip.id}
          meeting={{
            name: `Meeting with ${name}`,
            contacts: [contact],
          }}
          remove={closeMeetingForm}
        />
      </Modal2>
      <Modal2 isOpen={is_meeting_details_modal_open} onClose={closeMeetingDetails}>
        <MeetingDetails trip={props.trip} meeting={meetingToEdit} remove={closeMeetingDetails} />
      </Modal2>
    </Card>
  );
};
TripContactCard.propTypes = {
  trip: PropTypes.object,
  contact: PropTypes.object,
  meetings: PropTypes.array,
};

export default TripContactCard;
