EverTrue = require("app")

# Format for "properties" is determined by key that is created
# in PropertyHelper, unsually group_name.property_name

config = {
   basic:
      order: 1
      category: "Basic Information"
      fields: [
            group: "Name"
            properties: [
               "name_first.name_first"
               "name_last.name_last"
               "name_maiden.name_maiden"
               "name_middle.name_middle"
               "name_nick.name_nick"
               "name_prefix.name_prefix"
               "name_suffix.name_suffix"
            ]
         ,
            group: "Deceased"
            properties: ["deceased.deceased"]
         ,
            group: "Year"
            properties: ["year.year"]
         ,
            group: "Education"
            properties: [
               "educations.degree_key"
               "educations.majors"
               "educations.school_name"
               "educations.year"
               "sports.name"
               "extracurricular_activities.name"
            ]
         ,
            group: "Work History"
            properties: [
               "employments.company"
               "employments.industry"
               "employments.title"
            ]
         ,
            group: "Assigned To"
            properties: ["assignees.name"]
         ,
            group: "Wealth and Assets"
            properties: []
         ,
            group: "Career Moves"
            properties: []
      ]

   contact:
      order: 2
      category: "Contact Information"
      fields: [
            group: "Address"
            properties: [
               "addresses.address_1"
               "addresses.address_2"
               "addresses.address_3"
               "addresses.city"
               "addresses.country"
               "addresses.lat"
               "addresses.lng"
               "addresses.primary"
               "addresses.state"
               "addresses.type"
               "addresses.zip_code"
            ]
         ,
            group: "Email"
            properties: [
               "emails.email"
               "emails.primary"
               "emails.type"
            ]
         ,
            group: "Phone"
            properties: [
               "phones.phone"
               "phones.primary"
               "phones.type"
            ]
      ]

   census:
      order: 3
      category: "Census Data"
      fields: [
            group: "Neighborhood Home Values"
            properties: [
               "addresses.house_value_lq"
               "addresses.house_value_median"
               "addresses.house_value_uq"
            ]
         ,
            group: "Neighborhood Home Income"
            properties: [
               "addresses.income_median"
            ]
      ]

   giving_history:
      order: 4
      category: "Giving History"
      fields: [
            group: "Lifetime Giving"
            properties: [
               "giving.lifetime_amount"
               "giving.lifetime_recognition_amount"
               "giving.lifetime_pledge_balance"
            ]
         ,
            group: "Annual Totals"
            properties: [
               "giving_annual_donations.amount"
               "giving_annual_donations.fiscal_year"
            ]
         ,
            group: "Donor Info"
            properties: [
               "ethnicity.ethnicity"
               "gender.gender"
               "constituencies.status"
            ]
         ,
            group: "Other Giving Data"
            properties: [
               "giving.largest_gift_amount"
               "giving.largest_gift_date"
               "giving.largest_gift_label"
               "giving.last_gift_amount"
               "giving.last_gift_date"
               "giving.last_gift_label"
               "giving.giving_pattern"
               "solicitation_codes.code"
               "assignees.status"
            ]
      ]

   social:
      order: 6
      category: "Social"
      fields: [
            group: "Facebook Engagement"
            properties: [
               "facebook.comment_count"
               "facebook.last_engagement_date"
               "facebook.like_count"
               "facebook.total_engagement_count"
               "facebook.warmed_at"
            ]
      ]

   scores:
      order: 7
      category: "Scores"
      fields: [
            group: "Your Capacity Score"
            properties: [
               "giving.capacity_score"
            ]
         ,
            group: "Donor Potential Score (DPS)"
            properties: [
               "et_score.dds_score"
            ]
         ,
            group: "Your Donor Score"
            properties: [
               "giving.donor_score"
            ]
         ,
            group: "EverTrue Score"
            is_available: -> EverTrue.store?.org?.hasFeature("scores")
            properties: [
               "score.demographics"
               "score.giving"
               "score.professional"
               "score.score"
               "score.social"
            ]
         ,
            group: "Annual Giving Score"
            is_available: ->
               if hasFeature = EverTrue.store?.org?.hasFeature
                  hasFeature("annual_giving_score") and hasFeature("give_reporting")

            properties: [
               "score.annual_fund"
            ]
         ,
            group: "Your Engagement Score"
            properties: [
               "giving.engagement_score"
            ]
      ]

   custom:
      order: 8
      category: "Your Custom Fields"
      fields: []
}

module.exports = config
