import PropTypes from "prop-types";
import { connect } from "@evertrue/et-flux";
import { CDCModalController, CDCDependenciesProvider } from "@evertrue/cdc";
import Api from "entities/helpers/api";
import EverTrue from "app";
import SessionStore from "base/session/session-store";
import _ from "underscore";

const mapStateToProps = () => ({ user: SessionStore.getUser() });

const deps = {
  submitSuggestion: ({ data }) => {
    return Api.SUGGESTIONS.SUBMISSIONS.post({
      data: _.jsonStringify(data),
    });
  },
  sendMixPanel(type, args) {
    EverTrue.track.set(type, args);
  },
  successToast() {
    EverTrue.Alert.success("Suggestion Sent.");
  },
  setMixPanelReferrer(referrer) {
    EverTrue.track.setReferrer(referrer);
  },
  isVMSApp: false,
};

const CDCModal = ({ user, ...props }) => {
  return (
    <CDCDependenciesProvider value={{ ...deps, user }}>
      <CDCModalController {...props} />
    </CDCDependenciesProvider>
  );
};

CDCModal.propTypes = {
  user: PropTypes.object.isRequired,
};

export default connect(
  CDCModal,
  [SessionStore],
  mapStateToProps
);
