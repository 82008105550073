module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   React = require("react")
   ReactDOM = require("react-dom")
   classNames = require("classnames")
   OutsideClickMixin = require("mixins/outside-click-mixin")
   clickOutside = require("clickOutside")
   {div, span, i} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   LinkButton = createFactory(require("@evertrue/et-components").LinkButton)
   KeyPressManager = createFactory(require("components/helpers/KeyPressManager").default)
   {prevFocusWithWrap, nextFocusWithWrap} = require("base/accessibility-utils")

   # TODO: This component is getting used a lot and in very different ways
   # would be good to have tests for variations & flexibilty of this component
   # May also eventually need to be multiple components
   ReactLibs.createClass
      displayName: "Dropdown"
      mixins: [OutsideClickMixin]
      propTypes:
         className: ReactLibs.PropTypes.string
         label: ReactLibs.PropTypes.string
         icon_label: ReactLibs.PropTypes.string
         header: ReactLibs.PropTypes.node
         stay_open: ReactLibs.PropTypes.bool
         disable_outside_click: ReactLibs.PropTypes.bool
         onOpen: ReactLibs.PropTypes.func
         onClose: ReactLibs.PropTypes.func
         disabled: ReactLibs.PropTypes.bool
         focus: ReactLibs.PropTypes.bool

      dropdownToggle: React.createRef()

      getInitialState: ->
         open: false, position: "left"

      componentDidMount: ->
         $(window).resize @_calcMaxHeight
         @depracated_is_mounted = true

      UNSAFE_componentWillReceiveProps: (nextProps) ->
         @setState({open: false}) unless nextProps.stay_open

      componentDidUpdate: ->
         if @state.open then @_calcMaxHeight()

      componentWillUnmount: ->
         $(window).off "resize", @_calcMaxHeight
         @depracated_is_mounted = false

      _calcMaxHeight: ->
         return unless @depracated_is_mounted
         $node = $(@refs.body)
         windowHeight = $(window).outerHeight()
         distanceFromTop = $node.offset().top - $(window).scrollTop()
         paddingBottom = 15
         $content = $node.find(".dropdown--content")
         $footer = $node.find(".dropdown--footer")

         cssMaxHeight = parseInt($content.css("maxHeight"), 10)  || Infinity
         newMaxHeight = windowHeight - distanceFromTop - paddingBottom - $footer.outerHeight()
         $content.css
            maxHeight: Math.min(newMaxHeight, cssMaxHeight)

      _calcPosition: ->
         $body = $(@refs.body)
         pos = $body.width() + $(ReactDOM.findDOMNode(@)).offset().left
         if pos > ($("body").outerWidth()) then "right"

      handleToggle: (evnt) ->
         return undefined if @props.disabled
         # evnt.stopPropagation()
         toggle_state = !@state.open
         @setState {open: toggle_state, position: @_calcPosition()}

         if _.isFunction(@props.onOpen) && toggle_state
            _.defer((=> @props.onOpen(@state.open)))

         if _.isFunction(@props.onClose) && !toggle_state
            _.defer((=> @props.onClose()))

      handleOutsideClick: (evnt) ->
         if @state.open and not @props.disable_outside_click
            @setState open: false
            @props.onClose?()

      showChildren: ->
         if @state.open then @props.children

      headerContent: ->
         if @props.header
            @props.header
         else if @props.icon_label && @props.label
            span className: "dropdown--header-content",
               i className: @props.icon_label
               @props.label
               i className: "fa fa-fw fa-caret-down"
         else if @props.icon_label
            span className: "dropdown--header-content",
               i className: @props.icon_label
               i className: "fa fa-fw fa-caret-down"
         else
            span className: "dropdown--header-content",
               @props.label
               i className: "fa fa-fw fa-caret-down"

      render: ->
         id = _.randomKey()
         bodyClasses =
            classNames "dropdown--body",
               "is-open": @state.open
               "is-right": @state.position == "right"

         div className: "dropdown #{@props.className || ""}",

            div className: classNames("dropdown--header", "is-disabled": @props.disabled),
                # should be changed to a button but a ton of components use this, so breaks a lot
               LinkButton
                  id: id,
                  disabled: @props.disabled,
                  title: @props.label,
                  ref: @dropdownToggle,
                  onClick: @handleToggle,
                  className: classNames("dropdown--toggle", "is-open": @state.open, "dropdown--toggle-focus": @props.focus),
                  "aria-expanded": @state.open,
                     @headerContent()

            KeyPressManager
               onArrowUp: (node, event) -> prevFocusWithWrap(node)
               onArrowDown: (node, event) -> nextFocusWithWrap(node),
               onEsc: (node, event) =>
                  @handleOutsideClick(event)
                  @dropdownToggle.current?.focus()

               div
                  className: bodyClasses
                  onClick: @handleInsideClick
                  "aria-owns": id
                  ref: "body",
                     @showChildren()
