import { useContext } from "react";
import { Button, Icon } from "@evertrue/et-components";
import { GlobalContactSearchContext } from "apps/layout/controllers/global-contact-search-context";
import SelectedContactsSource from "apps/contact/sources/selected-contacts-source";
import PropTypes from "prop-types";

const FilterMapReset = ({ showMap }) => {
  const { setIsAllSelectedCheckbox, resetMapFilter, mapCoordinates } = useContext(GlobalContactSearchContext);

  return mapCoordinates && !showMap ? (
    <Button
      type="simple"
      onClick={() => {
        SelectedContactsSource.clear();
        setIsAllSelectedCheckbox(false);
        resetMapFilter(true);
      }}
    >
      <Icon icon="clear" />
      Clear map filters
    </Button>
  ) : null;
};

FilterMapReset.propTypes = {
  showMap: PropTypes.bool,
};

export default FilterMapReset;
