import { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";

// This context is used to keep track of which journey is selected across the entire application
// so that when a user navigates away from "cadences" and back to it, their spot is saved

const GlobalJourneyContext = createContext(() => {});

const GlobalJourneyProvider = ({ children }) => {
  const [globalJourney, setGlobalJourney] = useState({});

  return (
    <GlobalJourneyContext.Provider
      value={{
        globalJourney,
        setGlobalJourney,
      }}
    >
      {children}
    </GlobalJourneyContext.Provider>
  );
};

const useGlobalJourneyProvider = () => {
  const context = useContext(GlobalJourneyContext);
  if (!context) {
    throw new Error("useGlobalJourneyProvider must be used within a GlobalJourneyProvider");
  }
  return context;
};

GlobalJourneyProvider.propTypes = {
  children: PropTypes.any,
};

export { GlobalJourneyProvider, GlobalJourneyContext, useGlobalJourneyProvider };
