module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   EverTrue = require("app")


   PaginatedCollectionMixin =
      page: 1
      offset: 0
      limit: 50
      total: 0

      boundaries: (data) ->
         return unless data
         @total = data.total
         if (data.total <= data.limit)
            @boundary = "none"
         else if (data.offset == 0)
            @boundary = "first"
         else if (data.offset + data.limit >= data.total)
            @boundary = "last"
         else
            @boundary = false

      isFinalPage: ->
         @offset + @limit >= @total

      setPage: (page) ->
         @page = page
         @setOffset()

      setLimit: (limit) ->
         @limit = limit

      changePage: (change) ->
         @page += change
         @setOffset()

      setOffset: ->
         max_page = Math.ceil @total / @limit
         if @page <= 1
            @page = 1
         else if @page > max_page
            @page = max_page

         @offset = Math.max(@page - 1, 0) * @limit

      # Setting page without knowledge of the total
      # could query for data that doesn't exist
      dangerouslySetPage: (page) ->
         @page = page
         @offset = Math.max(page - 1, 0) * @limit

      paginate: (options={}) ->
         params =
            offset: @offset
            limit: @limit
         opts = $.extend true, {}, params, options
         opts

      # Infinite scrolling for collections with pagination
      infinity_running: false
      infinity: (options={}) ->
         return if @infinity_running || @boundary is "last"
         @infinity_running = true
         @changePage(1)
         clone = @clone()
         clone.reset [], {silent: true}
         clone.total = @total
         clone.limit = @limit
         clone.setPage(@page)
         clone.setSort?(@sortProp, @sortReverse)
         clone.setBaseQuery?(@baseQuery)

         originalSuccess = options.success
         options.success = (collection, response, xhr) =>
            @boundaries(response)
            @setPage(clone.page)
            @add clone.toJSON(), {merge: true}
            _.delay((=> @infinity_running = false), 250)
            if _.isFunction originalSuccess
               originalSuccess(collection, response, xhr)

         originalError = options.error
         options.error = (xhr, textStatus, error) =>
            _.delay((=> @infinity_running = false), 250)
            if (textStatus.statusText == "abort")
               @infinity_running = false
            if _.isFunction originalError
               originalError(xhr, textStatus, error)

         clone.fetch(options)

      infinityReset: (options) ->
         return if @page < 1 || @infinity_running
         @reset([], options)
         @page = 0
         @offset = 0
         @boundary = "first"

      pageData: ->
         data = _.pick @, "page", "limit", "total"
