import PropTypes from "prop-types";
import numeral from "numeral";
import { Button, Icon, Link, useOpenClose } from "@evertrue/et-components";
import ProfileCardHeader from "apps/profile/components/profile-card-header";
import EnrichmentList from "./enrichment-list";
import { useGate } from "components/is-gated";
import WealthIndicatorsModal from "./wealth-indicators-modal";

const LIMIT = 3;
const ACCESSOR = "type";

const WealthIndicators = ({ netWorths, physicalAssets = [], wealthIndicators = [], contactId }) => {
  const [hasWindfallPlus] = useGate("windfall_wealth_plus");
  const [hasWindfall] = useGate("windfall");
  const [isOpen, openModal, closeModal] = useOpenClose();

  let simplifiedNetWorthsArray = [];
  const oneMil = 1000000;

  if (netWorths.length > 0) {
    const mostRecentNetWorth = netWorths.at(-1).net_worth;
    const mostRecentSource = netWorths.at(-1).source;
    if (
      (mostRecentNetWorth < oneMil || mostRecentNetWorth == null) &&
      (mostRecentSource === "windfall_wealth_plus" || mostRecentSource === "windfall")
    ) {
      simplifiedNetWorthsArray = [{ value: "Less than $1M" }];
    } else if (mostRecentSource !== "windfall_wealth_plus" || mostRecentNetWorth >= oneMil) {
      simplifiedNetWorthsArray = netWorths
        .map((indicator) => ({ value: numeral(indicator.net_worth).format("$0.00a") }))
        .slice(-1);
    }
  }

  function showWindfallWealthData() {
    if (simplifiedNetWorthsArray.length) {
      return (
        (hasWindfallPlus && simplifiedNetWorthsArray[0].value === "Less than $1M") ||
        simplifiedNetWorthsArray[0].value !== "Less than $1M"
      );
    }
    return false;
  }

  const shouldShowWealthData = showWindfallWealthData();

  return (
    <div className="profile--enrichment-wealth">
      <ProfileCardHeader title="Wealth and Assets">
        {(netWorths.length > 0 || physicalAssets.length > 0 || simplifiedNetWorthsArray.length > 0) && hasWindfall && (
          <Button type="simple" onClick={openModal}>
            <Icon title="Edit wealth and assets" icon="edit" />
          </Button>
        )}
        {isOpen && (
          <WealthIndicatorsModal
            isOpen={isOpen}
            closeModal={closeModal}
            simplifiedNetWorthsArray={simplifiedNetWorthsArray}
            netWorths={netWorths}
            physicalAssets={physicalAssets}
            wealthIndicators={wealthIndicators}
            shouldShowWealthData={shouldShowWealthData}
          />
        )}
      </ProfileCardHeader>
      {hasWindfall ? (
        <>
          <div className="profile--enrichment-inner columns">
            <div>
              <Icon icon="money" />
              <span className="profile-enrichment-wealth-title">Household Net Worth</span>
              {simplifiedNetWorthsArray.length && shouldShowWealthData ? (
                <EnrichmentList
                  list={simplifiedNetWorthsArray}
                  accessor="value"
                  limit={LIMIT}
                  className="profile--enrichment-wealth--networth"
                  openModal={openModal}
                />
              ) : (
                <div> - </div>
              )}
            </div>
            <div>
              <Icon icon="home" />
              <span className="profile-enrichment-wealth-title">Physical Assets</span>
              {physicalAssets.length && shouldShowWealthData ? (
                <EnrichmentList list={physicalAssets} accessor={ACCESSOR} limit={LIMIT} openModal={openModal} />
              ) : (
                <div> - </div>
              )}
            </div>
            <div>
              <Icon icon="piggy-bank" />
              <span className="profile-enrichment-wealth-title">Wealth Indicators</span>
              {wealthIndicators.length && shouldShowWealthData ? (
                <EnrichmentList list={wealthIndicators} accessor={ACCESSOR} limit={LIMIT} openModal={openModal} />
              ) : (
                <div> - </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="profile-enrichment-teaser">
          We've partnered with Windfall to bring prospect net worth and info about wealth, assets, and nonprofit giving
          to TrueView profiles. Your organization hasn't added this feature yet, but you can{" "}
          <Link href={`/contact/${contactId}/enrichment?appcue=-Ly1pV7jxtRJbWNfBVFM`} title="learn more about windfall">
            see how it works
          </Link>{" "}
          and contact your Customer Success Manager to learn more.
        </div>
      )}
    </div>
  );
};

WealthIndicators.propTypes = {
  netWorths: PropTypes.array,
  physicalAssets: PropTypes.array,
  wealthIndicators: PropTypes.array,
  contactId: PropTypes.number,
};

export default WealthIndicators;
