module.exports = do ->
   _ = require("underscore").default
   {createStore} = require("@evertrue/et-flux")
   MatchContactSource = require("apps/users/sources/match-contact-source")

   createStore "MatchContactStore",
      getInitialState: ->
         loading: false
         matched_contact_ids: []
         contacts: []
         total_contacts: []

      registerActions: ->
         @on MatchContactSource.actions.loading, @respondToLoading
         @on MatchContactSource.actions.fetchedMatchedContactIds, @respondToFetchedMatchedContactIds
         @on MatchContactSource.actions.fetchedContactsByName, @respondToFetchedContactsByName
         @on MatchContactSource.actions.fetchedAllContacts, @respondToFetchedAllContacts

      respondToLoading: (isloading) ->
         @setState {loading: isloading}

      respondToFetchedMatchedContactIds: (contact_ids) ->
         @setState {matched_contact_ids: contact_ids}

      respondToFetchedContactsByName: (contacts) ->
         if contacts?.items
            @setState {contacts: contacts.items}

      respondToFetchedAllContacts: (contacts) ->
         if contacts?.items
            @setState {total_contacts: contacts.items}

      api:
         getMatchedContactIds: -> @getState("matched_contact_ids")

         getContacts: -> @getState("contacts")

         getTotalContacts: -> @getState("total_contacts")

         getLoading: -> @getState("loading")

