import { PropTypes } from "base/react-libs";
import numeral from "numeral";
import PieChartGraph from "apps/query-builder/components/pie-chart-graph";
import colors from "base/colors";

const ResultsCount = ({ resultCount, label = "", className, selected }) => {
  const recordRangeTextSelected = resultCount <= 1 ? `${resultCount}` : `${selected.length}`;

  return (
    <div className={`results-count ${className}`}>
      <PieChartGraph
        className="table-toolbar-pie filters-pie-chart"
        totalValue={resultCount <= 50 ? resultCount : 50}
        data={[{ title: "Filtered Amount", value: selected.length, color: colors.purple500 }]}
        startAngle={-90}
      />
      <div className="text-container">
        <p>{numeral(resultCount).format("0,0")} constituents</p>
        <p>
          <strong style={{ color: colors.purple500 }}>{recordRangeTextSelected}</strong> selected &#40;50 max&#41;
        </p>
      </div>
    </div>
  );
};

ResultsCount.propTypes = {
  resultCount: PropTypes.number.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  selected: PropTypes.array,
};

export default ResultsCount;
