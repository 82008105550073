import PropTypes from "prop-types";
import SortDropdown from "components/controls/sort-dropdown";
import { proposalSortOptions } from "../utils";

const ProposalTableSort = ({ sortProp, sortReverse, onSort }) => {
  return (
    <SortDropdown
      className="proposal-search-controller--sort"
      options={proposalSortOptions}
      sortProp={sortProp}
      sortReverse={sortReverse}
      onSort={onSort}
    />
  );
};

ProposalTableSort.propTypes = {
  sortProp: PropTypes.string,
  sortReverse: PropTypes.bool,
  onSort: PropTypes.func,
};

export default ProposalTableSort;
