module.exports = do ->
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   SelectedContactsSource = require("apps/contact/sources/selected-contacts-source")
   SelectedContactsStore = require("apps/contact/stores/selected-contacts-store")
   {createFactory} = require("base/new-utils")
   Checkbox = createFactory require("@evertrue/et-components").Checkbox
   EverTrue = require("app")
   Modal2  = createFactory(require("@evertrue/et-components").Modal2)
   ModalConfirm = createFactory(require("@evertrue/et-components").ModalConfirm)
   SelectedErrorModal = createFactory(require("apps/contact/components/contacts/selected-error-modal").default)
   {div} = ReactLibs.DOMFactories

   createComponent "SelectContactCheckbox",
      propTypes:
         id: ReactLibs.PropTypes.number
         onContactSelectChange: ReactLibs.PropTypes.func
         name: ReactLibs.PropTypes.string.isRequired

      getInitialState: ->
         showModal: false

      registerStores: ->
         @on SelectedContactsStore, ->
            isChecked: SelectedContactsStore.isSelected(@props.id)
            isAll: SelectedContactsStore.getIsAllSelected()

      handleChange: ->
            if @state.isChecked
               SelectedContactsSource.unselect(@props.id)
               if @state.isAll
                  SelectedContactsSource.selectAll(false)
                  SelectedContactsSource.toggleSelectedIndicator(false)
            else if (SelectedContactsStore.getSelectedContacts().length < 50)
               SelectedContactsSource.select(@props.id)
            else
               @setState {showModal: true}
            @props.onContactSelectChange?()

      handleCloseModal: -> 
         @setState {showModal: false}

      render: ->
         div null,
            Checkbox
               value: String(@props.id)
               checked: if @state.isAll then true else @state.isChecked
               onChange: @handleChange
               label: @props.name

            Modal2 isOpen: @state.showModal, onClose: @handleCloseModal,
               SelectedErrorModal({tripId: @props.trip_id, remove: @handleCloseModal})
