module.exports = do ->
   _ = require("underscore").default
   {createStore} = require("@evertrue/et-flux")
   EverTrue = require("app")
   UserSource = require("base/user/user-source")
   LayoutSource = require("apps/layout/sources/layout-source")
   SocketSource = require("apps/notifications/sources/socket-source")


   createStore "UserStore",
      getInitialState: ->
         user: undefined
         isImpersonating: false

      firstListenerDidMount: ->
         SocketSource.subscribeUser(EverTrue.store?.user.get("id"))

      registerActions: ->
         @on UserSource.actions.newUser, (user, options) ->
            is_impersonating = @getState("isImpersonating")
            @setState {user: user, isImpersonating: !!(options?.isImpersonating)}

            if !!options?.isImpersonating != is_impersonating
               _.defer (-> LayoutSource.remount())
            SocketSource.subscribeUser(user.id)

      api:
         isImpersonating: ->
            @getState("isImpersonating")

         getUserInfo: ->
            user = @getState("user")

            name: user?.name
            avatar: user?.avatar

         getUser: ->
            @getState("user")