module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   moment = require("moment")
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   AdvancedCombobox = createFactory(require("@evertrue/et-components").AdvancedCombobox)
   CalendarDropdown = createFactory(require("components/controls/calendar-dropdown"))
   FilterPanelRow = require("components/controls/filter-panel-row")
   FilterTextInput = require("apps/filters/components/filter-text-input")
   FacebookPagesDropdown = require("apps/facebook/components/facebook-pages-dropdown")
   EngagementTypeDropdown = require("apps/profile/components/engagement-type-dropdown")

   DATE_STATE_OPTIONS = [
      {label: "Lifetime Engagement", value: "all"}
      {label: "Pick Specific Dates", value: "range"}
   ]

   DATE_FORMAT = "YYYY-MM-DD"
   DATE_SHORTCUTS = [
      {id: "now-1w", format: [1, "week"], label: "in past week"}
      {id: "now-1M", format: [1, "month"], label: "in past month"}
      {id: "now-3M", format: [3, "months"], label: "in past 3 months"}
      {id: "now-1y", format: [1, "year"], label: "in past year"}
   ]


   createComponent "FacebookFilters",
      propTypes:
         filters: ReactLibs.PropTypes.object
         onChange: ReactLibs.PropTypes.func

      handleChange: (key, value) ->
         filters = $.extend true, {}, @props.filters
         if _.isEmpty(value) then filters[key] = undefined
         else filters[key] = value
         @props.onChange?(_.compactObject(filters))

      handleChangeType: (name, checked) ->
         if name == "all" then value = undefined
         else value = name
         @handleChange("type", value)

      renderDateRangeOptions: ->
         current_date = new Date()
         _.compact _.map DATE_SHORTCUTS, (shortcut) ->
            [number, string] = shortcut.format || []
            id: shortcut.id
            date_from: moment(current_date).subtract(number, string).format(DATE_FORMAT)
            date_to: moment(current_date).format(DATE_FORMAT)
            title: shortcut.label

      render: ->
         filters = @props.filters || {}
         range_options = @renderDateRangeOptions()

         div className: "facebook-filters",

            # Engagement Type
            FilterPanelRow label: "Facebook Engagement Type",
               div className: "facebook-filters--col",
                  EngagementTypeDropdown
                     placeholder: "All Engagement Types"
                     value: filters.reactions
                     onChange: (value) => @handleChange("reactions", value)

            # Page Engagement
            FilterPanelRow label: "Facebook Page",
               div className: "facebook-filters--col",
                  FacebookPagesDropdown
                     placeholder: "All Facebook Pages"
                     value: filters.pages
                     onChange: (value) => @handleChange("pages", value)

            # Engagement Date
            FilterPanelRow label: "Facebook Engagement Date",
               div className: classNames("facebook-filters--col", "is-active": !!filters.date),
                  AdvancedCombobox
                     value: if filters.date then DATE_STATE_OPTIONS[1] else DATE_STATE_OPTIONS[0]
                     options: _.map DATE_STATE_OPTIONS, _.clone
                     onChange: (val) =>
                        if val?.value == "all" then @handleChange("date", undefined)
                        else @handleChange("date", _.pick(_.last(range_options), "date_from", "date_to"))

               if filters.date
                  CalendarDropdown
                     date_from: moment(filters.date?.date_from).format(DATE_FORMAT)
                     date_to: moment(filters.date?.date_to).format(DATE_FORMAT)
                     date_range: range_options
                     onChange: (date_from, date_to) =>
                        @handleChange("date", {date_from: date_from, date_to: date_to})

            # Keyword Search
            FilterPanelRow label: "Facebook Content",
               FilterTextInput
                  className: "facebook-filters--text"
                  value: {value: filters.keyword}
                  placeholder: "Enter keywords..."
                  onChange: (value) => @handleChange("keyword", value)
