import _ from "underscore";
import { createStore } from "@evertrue/et-flux";
import DNASource from "base/dna/dna-source";
import OrgSource from "base/org/org-source";
import UserSettingsSource from "base/dna/user-settings-source";
import Decorator from "clientDecorator";
import DNAValidator from "base/dna/dna-validator";

export default createStore("DNAStore", {
  getInitialState() {
    return { dna_values: {}, user_values: {} };
  },

  firstListenerDidMount() {
    UserSettingsSource.refreshUserSettings();
  },

  registerActions() {
    this.on(OrgSource.actions.newOrg, () => {
      DNASource.fetchAll();
      UserSettingsSource.refreshUserSettings();
    });

    this.on(DNASource.actions.fetched, (data) => {
      this.setState({
        dna_values: _.mapObject(data.settings, (setting) => setting.value),
      });
    });

    this.on(UserSettingsSource.actions.newUserSettings, (settings) => {
      this.setState({ user_values: settings });
    });
  },

  api: {
    getByKey(key) {
      return this.state.dna_values[key];
    },

    getByKeyWithDefault({ key, options = {} } = {}) {
      const validator = new DNAValidator(key);
      return validator.validateWithDefaultConfig(this.state.dna_values[key], options);
    },

    getUserSettingByKey(key) {
      return this.state.user_values[key];
    },

    getUserGivingCategory() {
      const val = this.state.user_values.user_giving_category;
      return val && val.user_giving_category;
    },

    hasCommunity() {
      const products = this.getByKey("ET.Org.Products");
      const community = _.findWhere(products, { name: "community" });
      return !!(community && community.status === "LIVE");
    },

    getFiscalYearStart() {
      const fiscal_start = this.getByKey("ET.Org.FiscalYear") || "07-01";
      return Decorator.Org.getFiscalYearStart(fiscal_start);
    },

    getFiscalYearEnd() {
      const fiscal_start = this.getByKey("ET.Org.FiscalYear") || "07-01";
      return Decorator.Org.getFiscalYearEnd(fiscal_start);
    },

    getDaysUntilFiscalYearEnd() {
      const fiscal_start = this.getByKey("ET.Org.FiscalYear") || "07-01";
      return Decorator.Org.getDaysUntilFiscalYearEnd(fiscal_start);
    },

    isLoaded() {
      return !_.isEmpty(this.getState("dna_values"));
    },
  },
});
