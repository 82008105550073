import { Component } from "react";
import { connect } from "@evertrue/et-flux";
import Api from "entities/helpers/api";
import FacebookSource from "apps/facebook/sources/facebook-source";
import _ from "underscore";
import FacebookPagesStore from "apps/facebook/stores/facebook-pages-store";

const mapStateToProps = () => ({
  pages: FacebookPagesStore.getPages(),
});

class FacebookConnectedPagesResolver extends Component {
  static propTypes = {
    contact: ReactLibs.PropTypes.object,
    render: ReactLibs.PropTypes.func,
    pages: ReactLibs.PropTypes.array,
  };

  state = {
    engagedPageIds: [],
    loading: false,
  };

  componentDidMount() {
    FacebookSource.fetchPages();
    this.fetchPageIds();
  }

  fetchPageIds = () => {
    this.setState({ loading: true });
    Api.SEARCH.SOCIAL.post({
      params: { limit: 500 },
      data: JSON.stringify({
        must: [
          {
            contact_id: { match: this.props.contact.id },
          },
        ],
        sort: [{ "engagement.created_at": { order: "desc" } }],
        facet: [
          {
            field: "content.page_remote_id",
            size: 250,
          },
        ],
      }),
    }).then(resp => {
      const engagedPageIds = _.pluck(resp.facets["content.page_remote_id"], "value");
      this.setState({ engagedPageIds, engagements: resp.items, loading: false });
    });
  };

  render() {
    const { engagedPageIds, engagements, loading } = this.state;
    const groupedEngagements = _.groupBy(engagements, item => item.content.page_remote_id);
    const mostRecentEngagements = _.mapObject(groupedEngagements, engagement => engagement && engagement[0]);
    const engagedPages = _.map(engagedPageIds, pageRemoteId => {
      if (this.props.pages.length > 0) {
        return {
          page: _.findWhere(this.props.pages, { remote_id: pageRemoteId }),
          mostRecentEngagement: mostRecentEngagements[pageRemoteId],
        };
      } else return {};
    });

    return this.props.render({
      engagedPages,
      loading,
    });
  }
}

export default connect(
  FacebookConnectedPagesResolver,
  [FacebookPagesStore],
  mapStateToProps
);
