import { createSource } from "@evertrue/et-flux";
import Api from "entities/helpers/api";

const CronofyCalendarSource = createSource("CronofyCalendarSource", {
  actions: {
    fetchCalendarsIfNotCached: true,
    fetchedCalendars: true,
    loading: true,
  },

  api: {
    fetchCalendars() {
      this.actions.loading(true);
      Api.VOYAGER.CALENDARS.get({
        disableAlerts: true, // 404s if no cals
        success: ({ calendars = [] } = {}) => {
          this.actions.fetchedCalendars(calendars);
          this.actions.loading(false);
        },
        error: () => {
          this.actions.loading(false);
        },
      });
    },
    fetchCalendarsIfNotCached() {
      this.actions.fetchCalendarsIfNotCached();
    },
    // refresh updates the list of cals in user's accounts
    // ie after init connect if they create another cal we can find out
    calendarRefresh() {
      this.actions.loading(true);
      Api.VOYAGER.CALENDARS.get({
        urlExtend: "/refresh",
        disableAlerts: true, // 404s if no cals
        success: ({ calendars = [] } = {}) => {
          this.actions.fetchedCalendars(calendars);
          this.actions.loading(false);
        },
        error: () => {
          this.actions.loading(false);
        },
      });
    },
  },
});

export default CronofyCalendarSource;
