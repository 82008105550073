import PropTypes from "prop-types";
import _ from "underscore";
import FormField from "components/forms/form-field";
import ProposalCustomField from "./proposal-form-custom-field";

export const ProposalFormCustomFields = ({ onFormChange, proposal, customFields }) => {
  return (
    <>
      <div className="proposal-form--header">Custom Fields</div>
      {_.map(customFields, (field = {}) => {
        const currentField = _.findWhere(proposal.custom_fields, { custom_field_property_id: field.id });
        const currentFieldValue = currentField ? currentField.value : "";
        const currentOrgField = _.findWhere(customFields, { id: field.id }) || {};
        const label = field.display_name;

        return (
          <div className="proposal-form--row" key={field.id}>
            <FormField
              className="proposal-form--form-field"
              label={label}
              inlineLabel={false}
              required={field.required}
            >
              <ProposalCustomField
                label={label}
                onFormChange={onFormChange}
                customFields={proposal.custom_fields}
                field={field}
                currentFieldValue={currentFieldValue}
                currentOrgField={currentOrgField}
              />
            </FormField>
          </div>
        );
      })}
    </>
  );
};

ProposalFormCustomFields.propTypes = {
  onFormChange: PropTypes.func,
  proposal: PropTypes.object,
  customFields: PropTypes.array,
};

export default ProposalFormCustomFields;
