module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   EverTrue = require("app")
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories
   Help = require("apps/layout/components/help")


   createComponent "LoginHeader",
      render: ->
         div className: "login--header-outer",
            div className: "login--header"
