import _ from "underscore";
import numeral from "numeral";
import { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "@evertrue/et-flux";
import PoolSolicitorsSource from "apps/volunteers/sources/pool-solicitors-source";
import PoolSolicitorsStore from "apps/volunteers/stores/pool-solicitors-store";
import PoolProspectsSource from "apps/volunteers/sources/pool-prospects-source";
import PoolProspectsStore from "apps/volunteers/stores/pool-prospects-store";
import CardAnalytics from "components/containers/card-analytics";
import ContactCard from "apps/contact/components/contact-card/contact-card";
import Facepile from "components/elements/facepile";
import { bulkResetPoolsConfirm } from "apps/volunteers/utils";
import { Link, ThreeDotActionsMenu, Modal2, Card } from "@evertrue/et-components";
import PoolFormModal from "apps/volunteers/components/pool-form-modal";
import DeletePoolModal from "apps/volunteers/components/delete-pool-modal";

const mapStateToProps = (ownProps) => ({
  solicitors: PoolSolicitorsStore.getSample(ownProps.pool.id) || {},
  prospects_total: PoolProspectsStore.getTotalForPool(ownProps.pool.id),
});

const PoolCard = ({ pool = {}, solicitors, prospects_total }) => {
  useEffect(() => {
    if (pool.id) {
      PoolSolicitorsSource.fetchSample(pool.id);
      PoolProspectsSource.fetchTotal(pool.id);
    }
  }, [pool.id]);

  const items = [
    {
      id: 1,
      icon: "edit",
      // eslint-disable-next-line react/prop-types
      mountOnClick: ({ is_open, close }) => (
        <Modal2 isOpen={is_open} onClose={close}>
          <PoolFormModal onClose={close} pool={pool} />
        </Modal2>
      ),
      label: "Edit Pool",
    },
    {
      id: 2,
      icon: "delete",
      className: "text-danger",
      // eslint-disable-next-line react/prop-types
      mountOnClick: ({ is_open, close }) => (
        <Modal2 isOpen={is_open} onClose={close}>
          <DeletePoolModal isOpen={is_open} closeModal={close} pool={pool} />
        </Modal2>
      ),
      label: "Delete Pool",
    },
    {
      id: 3,
      icon: "reset-assignments",
      label: "Reset Assignments",
      className: "pools--reset-icon pools--reset-assignments",
      onClick: () => bulkResetPoolsConfirm(pool.id, "assignments"),
    },
    {
      id: 4,
      icon: "reset-stages",
      className: "pools--reset-icon",
      label: "Reset Stages",
      onClick: () => bulkResetPoolsConfirm(pool.id, "stages"),
    },
  ];

  let analytics;

  if (pool.giving_category_label === "EverTrueNoData") {
    analytics = [
      {
        label: "Assigned",
        value: numeral(pool.assigned_prospect_rate).format("0%"),
      },
      {
        label: "Volunteers",
        value: numeral(solicitors.total).format("0,0"),
      },
      {
        label: "Prospects",
        value: numeral(prospects_total).format("0,0"),
      },
    ];
  } else {
    analytics = [
      {
        label: "In Total Gifts",
        value: _.currencyFormatter(pool.total_dollars_raised),
      },
      {
        label: "Participation",
        value: numeral(pool.pool_participation_rate).format("0[.]0%"),
      },
      {
        label: "Prospects",
        value: numeral(prospects_total).format("0,0"),
      },
    ];
  }

  return (
    <Card className="pool-card">
      <div className="pool-card--header">
        <Link
          title={`Link to pool ${pool.name}`}
          href={`volunteers/pool/${pool.id}`}
          className="pool-card--title ellipsis-overflow"
        >
          {pool.name}
        </Link>
        <ThreeDotActionsMenu triggerClassName="pool-card--menu" menuClassName="pool-card--popover" options={items} />
      </div>
      <div className="pool-card--preview">
        {_.isEmpty(solicitors.items) ? (
          <div className="pool-card--user-empty text-muted">No volunteers have been added to the pool.</div>
        ) : (
          <Facepile
            className="pool-card--facepile"
            total={solicitors.total}
            list={solicitors.items}
            popoverContent={(contact) => {
              return (
                <div className="trip-card--avatar-hover">
                  <ContactCard contact={contact} visibleData={["roleWithYear"]} disableNameLink />
                </div>
              );
            }}
          />
        )}
      </div>
      <CardAnalytics className="pool-card--analytics" data={analytics} />
    </Card>
  );
};

PoolCard.propTypes = {
  pool: PropTypes.object,
  solicitors: PropTypes.object,
  prospects_total: PropTypes.number,
};

export default connect(PoolCard, [PoolProspectsStore, PoolSolicitorsStore], mapStateToProps);
