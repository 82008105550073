module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories
   {string} = ReactLibs.PropTypes
   classNames = require("classnames")
   EngagementHelper = require("apps/events/utils/engagement-config")
   EngagementIcon = require("apps/profile/components/engagement-icon")

   createComponent "EngagementIconPill",
      propTypes:
         action: string
         className: string

      render: ->
         {action, className} = @props
         icon = _.first EngagementHelper.getEngagementActionsByTab action

         div className: classNames("engagement-action-pill", className),
            EngagementIcon
               className: "engagement-action-pill--icon"
               icon: icon
               size: 12
            " " + @props.action
