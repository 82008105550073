import { PropTypes } from "base/react-libs";
import { PageHeader, EmptyStateMessage } from "@evertrue/et-components";
import AddTaskButton from "./create/add-task-button";

const TasksWelcomePage = ({ userType }) => {
  return (
    <>
      <PageHeader title="Tasks" />
      <div style={{ padding: "130px 250px" }}>
        <EmptyStateMessage
          icon=""
          text={"Welcome To Tasks"}
          subtext={
            "Get Started by creating a task. All open and closed tasks assigned to you and that you assign to others will be visible on this page. Learn more about tasks here"
          }
          actionLabel="Learn More"
          onAction={() => window.open("https://help.evertrue.com/t/35y34y7/all-about-signal-collaborative-tasks", "_blank")}
          actionButton={<AddTaskButton userType={userType} />}
        />
      </div>
    </>
  );
};

TasksWelcomePage.propTypes = {
  userType: PropTypes.string,
};

export default TasksWelcomePage;
