import EverTrue from "app";
import { useState, useRef, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Button, Loading, ModalTrigger, Icon, useOpenClose } from "@evertrue/et-components";
import RichTextEditor from "./rich-text-editor";
import Api from "entities/helpers/api";
import { MultiRecipientsInput } from "./multi-recipients-input";
import { useIdentity } from "base/identity-resolver";
import { createAction } from "apps/tasks/utils/tasks-actions";
import { setTimestampToMidnightAndRemoveMilliseconds } from "apps/journeys/utils/helpers/dates";
import EmailTemplatesModalController from "apps/outreach/email/templates/controllers/email-templates-modal-controller";
import { trackOutreachAction } from "apps/outreach/utils/utils";
import EmailSignaturesModal from "apps/outreach/email/signatures/components/email-signatures-modal";
import FileUpload from "./components/file-upload";
import UploadedFiles from "./components/uploaded-files";
import useFileUploader from "./hooks/use-file-uploader";
import { v4 as uuidv4 } from "uuid";
import { uploadFile } from "./utils/upload-file";
import { EmailTemplatesContext } from "apps/outreach/email/templates/hooks/email-templates-context";

export const EmailForm = ({ recipient, handleClose = () => {}, setDrawerTitle = () => {}, contactId, contact }) => {
  const defaultEmails = recipient ? [recipient] : [];
  const [inputRecipients, setInputRecipients] = useState(defaultEmails);
  const [subject, setSubject] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [defaultSignature, setDefaultSignature] = useState({ id: 0, name: "No Signature", signatrure_body: "" });
  const { user, org } = useIdentity();
  const [signaturesModalIsOpen, openSignaturesModal, closeSignaturesModal] = useOpenClose(false);
  const { files, setFiles, removeFile } = useFileUploader();
  const { total, showEditView, getAndSetTemplates } = useContext(EmailTemplatesContext);

  const quillRef = useRef();

  useEffect(() => {
    getAndSetTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setValue = ({ target: { value } }, setFunction) => {
    setFunction(value);
  };

  const handleChangeSubject = (e) => {
    setValue(e, setSubject);
    setTimeout(() => setDrawerTitle(e.target.value), "3000");
  };

  const handleSubmit = () => {
    trackOutreachAction("send_email");
    const today = setTimestampToMidnightAndRemoveMilliseconds(new Date().getTime());
    setIsSending(true);
    createAction({
      contact_id: contactId,
      date_occurred: today,
      outreach_method: "EMAIL",
      oid: org.id,
      completed_by_id: user.id,
      creator_user_id: user.id,
      updater_user_id: user.id,
      subject,
      body: emailBody,
    });

    Api.OUTREACH.SEND.post({
      data: JSON.stringify({
        message: {
          to: inputRecipients.map((email) => ({ email })),
          subject,
          body: emailBody.replaceAll("<p>", "<div>").replaceAll("</p>", "</div>"), //p tags contain a default margin in most email clients
          attachment_urls: files.map(({ url }) => url),
        },
      }),
    })
      .then(() => {
        EverTrue.Alert.success("Email sent");
        setDrawerTitle("");
        handleClose();
      })
      .catch(() => {
        EverTrue.Alert.error("There was a problem sending your email. Please try again.");
        setIsSending(false);
      });
  };

  const getEmailBodyWithoutSignature = (lastSignatureIndex) => {
    let bodyWithoutSignature = emailBody.substring(0, lastSignatureIndex - 3);
    while (bodyWithoutSignature.endsWith("<p><br></p>")) {
      bodyWithoutSignature = bodyWithoutSignature.slice(0, bodyWithoutSignature.length - 11); // Remove the last 13 characters (length of "<p><br></p>")
    }
    return bodyWithoutSignature;
  };

  useEffect(() => {
    const getDefaultSignature = () => {
      if (defaultSignature.id !== 0) {
        setEmailBody((prev) => {
          let updatedEmailBody = "";
          const lastSignatureIndex = prev.lastIndexOf("--");
          if (lastSignatureIndex !== -1) {
            const bodyWithoutSignature = getEmailBodyWithoutSignature(lastSignatureIndex);
            updatedEmailBody = bodyWithoutSignature + `<br><br>--<br>${defaultSignature.signature_body} </p>`;
          } else {
            updatedEmailBody = prev + `<br><br>--<br>${defaultSignature.signature_body} </p>`;
          }
          return updatedEmailBody;
        });
      } else {
        setEmailBody((prev) => {
          let updatedEmailBody = "";
          const lastSignatureIndex = prev && prev.lastIndexOf("--");
          if (lastSignatureIndex !== -1) {
            updatedEmailBody = getEmailBodyWithoutSignature(lastSignatureIndex);
            return updatedEmailBody;
          }
        });
      }
    };

    getDefaultSignature();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSignature]);

  const handleFileUpload = async (files) => {
    await Promise.all(
      files.map(async (file) => {
        const newFile = { id: uuidv4(), file, url: "" };
        setFiles((prevFiles) => [...prevFiles, newFile]);
        const res = await uploadFile(file);
        if (res.error) {
          EverTrue.Alert.error("There was a problem uploading your file. Please try again.");
          removeFile(newFile.id);
          return;
        } else {
          newFile.url = res.url;
          setFiles((prevFiles) =>
            prevFiles.map((f) => {
              if (f.id === newFile.id) {
                return newFile;
              } else return f;
            })
          );
        }
      })
    );
  };

  return (
    <div className="email-form email-editor">
      <div className="email-form-header">
        <div className="email-form-header-field" style={{ minHeight: "64px" }}>
          <label className="email-form--label">
            <strong className="email-form-header-label">To:</strong>
            <MultiRecipientsInput inputRecipients={inputRecipients} setInputRecipients={setInputRecipients} />
          </label>
        </div>
        <div className="email-form-header-field email-subject">
          <label className="space-between email-form--label">
            <strong>Subject:</strong>
            <input
              className="email-form--input-field"
              style={{ marginLeft: "5px" }}
              value={subject}
              onChange={handleChangeSubject}
              data-testid="subject-field"
            />
          </label>
        </div>
      </div>
      <div className="email-form--body" data-testid="body-field">
        {isSending ? (
          <Loading />
        ) : (
          <RichTextEditor
            contact={contact}
            editorBody={emailBody}
            setEditorBody={setEmailBody}
            quillRef={quillRef}
            autoReplaceDynamicFields={true}
            enableDynamicFields={true}
          />
        )}
      </div>
      {files.length > 0 && (
        <div className="email-form--files" style={{ height: "78px", overflowY: "auto", paddingTop: "10px" }}>
          <UploadedFiles files={files} setFiles={setFiles} removeFile={removeFile} />
        </div>
      )}
      <div className="email-form--footer space-between">
        <div className="space-between">
          <Button
            label="sendButton"
            type="default"
            onClick={handleSubmit}
            disabled={inputRecipients.length === 0 || isSending}
          >
            Send
          </Button>
          <div className="email-footer-btns">
            <FileUpload onFileChange={handleFileUpload} allowMultiple={true} />
            <Icon
              icon="email-signature-icon"
              className="email-signatures-btn"
              iconKit="gt2"
              onClick={openSignaturesModal}
              isReachable={true}
              onKeyUp={openSignaturesModal}
            ></Icon>
          </div>
        </div>
        <EmailSignaturesModal
          isOpen={signaturesModalIsOpen}
          onClose={closeSignaturesModal}
          defaultSignature={defaultSignature}
          setDefaultSignature={setDefaultSignature}
          contact={contact}
        />
        <ModalTrigger
          size={total > 0 || showEditView ? "1060px" : "850px"}
          closeOnOutsideClick={true}
          buttonProps={{ type: "secondary" }}
          buttonText="Email Templates"
        >
          {({ closeModal }) => (
            <EmailTemplatesModalController
              contact={contact}
              closeModal={closeModal}
              emailBodyRef={quillRef}
              setSubject={setSubject}
            />
          )}
        </ModalTrigger>
      </div>
    </div>
  );
};

EmailForm.propTypes = {
  recipient: PropTypes.string,
  handleClose: PropTypes.func,
  setDrawerTitle: PropTypes.func,
  contactId: PropTypes.number,
  contact: PropTypes.object,
};

EmailForm.defaultProps = {
  recipient: "",
  handleSubmit: () => {},
};

export default EmailForm;
