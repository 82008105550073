import useEmailTemplates from "../fetchers/email-templates-fetcher";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import EverTrue from "app";
import ListItem from "./list-item";

const EmailPromptsList = ({ requestHide, onListItemClick }) => {
  const [emailPrompts, setPrompts] = useState([]);
  const { fetchEmailTemplatePrompts } = useEmailTemplates();
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  /**
   * on the click of list item the current model closes using requestHide()
   */
  const handleListItemClick = (key, display_name) => {
    requestHide();
    onListItemClick({ key, display_name });
  };

  useEffect(() => {
    if (isInitialLoad) {
      async function getData() {
        try {
          const { items } = await fetchEmailTemplatePrompts();
          const promptKeys = items.map(({ key, display_name }) => {
            return { key, display_name };
          });
          setPrompts(promptKeys);
        } catch (e) {
          EverTrue.Alert.error(e.message);
        } finally {
          setIsInitialLoad(false);
        }
      }
      getData();
    }
  }, [fetchEmailTemplatePrompts, isInitialLoad]);

  return (
    <div className="email-prompts">
      <div className="email-prompts--description-container">
        <div className="email-prompts--description">
          <img src="/images/ai-icon.png" alt="ai-email-img" />
          <p>Explore AI-generated email templates to kickstart your outreach. Tailor them for a personal touch.</p>
        </div>
      </div>
      <div className="email-prompts--lists-container">
        {emailPrompts.map(({ key, display_name }) => (
          <div className="email-prompts--list" key={key}>
            <ListItem
              text={display_name}
              onClick={() => handleListItemClick(key, display_name)}
              imageSrc={"/images/ai-email.png"}
              classNames={"email-prompts--list-item"}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

EmailPromptsList.propTypes = {
  requestHide: PropTypes.func,
  onListItemClick: PropTypes.func.isRequired,
};

export default EmailPromptsList;
