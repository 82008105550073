import { Button } from "@evertrue/et-components";
import EverTrue from "app";
import HomepageCard from "./homepage-card";

const Metrics = () => {
  return (
    <>
      <h1 className="homepage-card-title">Metrics</h1>
      <HomepageCard
        leftHeading={"See How You're Doing"}
        body={
          <div className="metrics-card-body">
            <div>
              <span className="metrics-button-title">View your Tableau dashboards</span>
              <Button
                type="secondary"
                onClick={() => {
                  EverTrue.track.set("home_screen", { type: "clarity" });
                  EverTrue.UrlManager.navigate(`/clarity/`, true, false);
                }}
              >
                View Clarity
              </Button>
            </div>
            {/* Commenting below to be reimplmented in May */}
            {/* <div>
              <span className="metrics-button-title">View your cadence activity metrics</span>
              <Button
                type="secondary"
                onClick={() => {
                  EverTrue.track.set("home_screen", { type: "reporting" });
                  EverTrue.UrlManager.navigate(`/reporting/`, true, false);
                }}
              >
                View Reporting
              </Button>
            </div> */}
          </div>
        }
      />
    </>
  );
};

export default Metrics;
