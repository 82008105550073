import { PropTypes } from "base/react-libs";
import { Modal, ModalHeader, ModalBody, Button } from "@evertrue/et-components";

const ConfirmModal = ({ isOpen, onClose, handleAction, title, body, actionName }) => {
  return (
    <Modal isOpen={isOpen} closeModal={onClose} size="small">
      <ModalHeader title={title} />
      <ModalBody>{body}</ModalBody>
      <div className="confirm-modal-actions">
        <Button type="simple" onClick={onClose}>
          Cancel
        </Button>
        <div style={{ margin: "0 20px" }}>
          <Button onClick={() => handleAction()}>{actionName}</Button>
        </div>
      </div>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleAction: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  actionName: PropTypes.string.isRequired,
};

export default ConfirmModal;
