import { fetchUpcomingTaskCounts } from "apps/journeys/utils/api-requests/journey-index";

//Common function to fetch counts for today/next five days for Journey and Other Tasks based on day range field.
// dayRange 1 : Today's Count
// dayRange 7 : Next Five Days Count
// fetchJourneysTaskCount: If TRUE - Fetches journey counts, if FALSE fetches other task count
export const getUpcomingTaskCounts = async (
  taskOwnerUserId,
  taskStatuses,
  includeInactive,
  fetchJourneysTaskCount,
  dayRange
) => {
  const journeysForDate = await fetchUpcomingTaskCounts(
    taskOwnerUserId,
    taskStatuses,
    includeInactive,
    fetchJourneysTaskCount,
    dayRange
  );
  return journeysForDate;
};

//Fetching counts for Next five days for Journeys and Other tasks
export const fetchNextFiveDays = async (taskOwnerUserId, taskStatuses, includeInactive, dayRange) => {
  const nextFiveJourneyTasks = await getUpcomingTaskCounts(
    taskOwnerUserId,
    taskStatuses,
    includeInactive,
    true,
    dayRange
  );
  const nextFiveOtherTasks = await getUpcomingTaskCounts(
    taskOwnerUserId,
    taskStatuses,
    includeInactive,
    false,
    dayRange
  );

  let merged = [];
  if (nextFiveJourneyTasks && nextFiveOtherTasks) {
    nextFiveJourneyTasks.forEach((journeyDay) => {
      const found = nextFiveOtherTasks.find((taskDay) => taskDay.name === journeyDay.name);
      if (found) {
        const { name, date, ...counts } = found;
        Object.entries(counts).forEach(([key, value]) => {
          journeyDay[key] += value;
        });
      }
      merged.push(journeyDay);
    });

    nextFiveOtherTasks.forEach((taskDay) => {
      const found = merged.find((day) => day.name === taskDay.name);
      if (!found) {
        merged.push(taskDay);
      }
    });

    merged = merged.sort((a, b) => a.date - b.date).slice(0, 5);
  }
  return merged;
};
