module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   EverTrue = require("app")
   Decorator = require("clientDecorator")
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")

   LIMIT = 100

   createSource "MyTripsSource",
      actions:
         loading: (is_loading) ->
            @require _.isBoolean(is_loading), "is_loading should be true/false"
         fetchedTrips: (user_id, response) ->
            @require !_.isUndefined(user_id), "user_id should be defined"
            @require _.isPagedObject(response), "trip response should be paged object"
         createdTrip: (trip) ->
            @require _.isObject(trip), "trip should be an object"
         updatedTrip: (trip) ->
            @require _.isObject(trip), "trip should be an object"
         deletedTrip: (trip_id) ->
            @require _.isNumber(trip_id), "trip_id should be a number"
         setUser: (user_id) ->
            @require _.isNumber(user_id), "user_id should be a number"
         fetchedCurrentUserData: (current_user_data) ->
            @require _.isObject(current_user_data), "user_id should be a number"
         setTrip: (trip_id) ->
            @require _.isNumber(trip_id), "user_id should be a number"
         fetchedTrip: (trip_id, trip) ->
            @require _.isNumber(trip_id), "user_id should be a number"
            @require _.isObject(trip), "trip should be an object"
         fetchedCalendarFeed: (url) ->
            @require _.isString(url), "calendar feed url should be string"
         fetchedBulkTrips: true
         fetchAll: true
         fetchCache: true
         changedTrips: true
         errorFetchingTrip: true

      api:
         fetchCache: (ids) ->
            @actions.fetchCache(ids)

         fetchAll: (user_id) ->
            @actions.fetchAll()
            @fetchTrips(user_id)

         fetchTrips: (user_id, sort="desc", offset=0) ->
            @actions.loading(true)

            if _.isString(user_id) then user_id = _.toNumber(user_id)
            else if !user_id then user_id = EverTrue.store.user.get("id")

            Api.VOYAGER.CONNECTIONS.get
               params:
                  include_full_data: true
                  user_id: user_id
                  order: sort
                  limit: LIMIT
                  offset: offset
               success: (resp) =>
                  trip_data = _.extend {}, resp,
                     items: _.map resp.items, Decorator.MyTrips.parse

                  @actions.fetchedTrips(user_id, trip_data)

                  # Load all trips
                  if resp.total > (LIMIT + resp.offset)
                     @fetchTrips(user_id, sort, resp.offset + LIMIT)
                  else @actions.loading(false)
               error: (error) =>
                  @actions.loading(false)

         fetchTrip: (trip_id) ->
            Api.VOYAGER.TRIPS.get
               urlExtend: "/#{trip_id}"
               success: (resp) =>
                  @actions.fetchedTrip(trip_id, Decorator.MyTrips.parse(resp))
               error: (error) =>
                  @actions.errorFetchingTrip(trip_id)

         fetchBulkTrips: (ids) ->
            ids = _.makeArray(ids)
            trips = {}
            requests = _.map ids, (id) ->
               Api.VOYAGER.TRIPS.get
                  urlExtend: "/#{id}"
                  disableAlerts: true
                  success: (resp) -> trips[resp.id] = resp
            $.when.apply(@, requests).then =>
               @actions.fetchedBulkTrips(trips)

         # This is used to fetch the users integrations for virtual meetings set up
         # It returns an array of objects - each object is an integration
         fetchCurrentUserData: ->
            user_id = EverTrue.store.user.get("id")
            Api.VOYAGER.USER.get
               disableAlerts: true,
               success: (resp) =>
                  @actions.fetchedCurrentUserData(resp)
               error: (error) ->
                  console.log error

         create: ({ data: data, contactIds, filterParams: filterParams, options: options={} }, isFiltersV2) ->
            Api.VOYAGER.TRIPS.post
               data: _.jsonStringify(data)
               success: (resp) =>
                  @actions.createdTrip(resp, contactIds, filterParams, isFiltersV2)
                  if options.navigateToTrip
                     EverTrue.Navigator("/trips/#{resp.id}", true)

         update: (data, options) ->
            Api.VOYAGER.TRIPS.put
               urlExtend: "/#{data.id}"
               data: _.jsonStringify(data)
               success: (resp) =>
                  @actions.updatedTrip(resp, options)

         delete: (trip_id) ->
            Api.VOYAGER.TRIPS.delete
               urlExtend: "/#{trip_id}"
               success: (resp) =>
                  @actions.deletedTrip(trip_id)

         setTrip: (trip_id) ->
            if !_.isNaN(_.toNumber(trip_id))
               @actions.setTrip(_.toNumber(trip_id))

         setUser: (user_id) ->
            if !_.isNaN(_.toNumber(user_id))
               @actions.setUser(_.toNumber(user_id))

         changedTrips: ->
            @actions.changedTrips()

         fetchCalendarFeed: ->
            Api.VOYAGER.MEETINGS.get
               urlExtend: "/feed_url"
               success: (resp) =>
                  @actions.fetchedCalendarFeed(resp.feed_url)

