module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {g} = ReactLibs.DOMFactories
   Bar = require("components/charts/bar")
   colors = require("base/colors")

   MIN_HEIGHT = 2
   NO_DATA_STYLE = {fill: colors.gray200, opacity: .4}


   createComponent "BarStack",
      propTypes:
         data: ReactLibs.PropTypes.array
         bucket: ReactLibs.PropTypes.string
         xScale: ReactLibs.PropTypes.any
         yScale: ReactLibs.PropTypes.any
         grid_height: ReactLibs.PropTypes.number
         padding: ReactLibs.PropTypes.number
         horizontal: ReactLibs.PropTypes.bool
         onMouseOver: ReactLibs.PropTypes.func
         onMouseOut: ReactLibs.PropTypes.func
         onBarClick: ReactLibs.PropTypes.func

      getDefaultProps: ->
         padding: 5

      handleGetCoords: (item) ->
         if @props.xScale && @props.yScale
            [@props.xScale?(item.x), @props.yScale?(item.y)]
         else [0, 0]

      render: ->
         group = @props.data
         bucket = @props.bucket
         bar_padding = @props.padding
         sorted_group = _.sortBy(group, "series")
         series = _.uniq(_.pluck(@props.data, "series"))

         _.each series, (series_key) =>
            unless _.findWhere(sorted_group, {series: series_key})
               if @props.horizontal
                  sorted_group.push {x: 0, y: bucket, series: series_key, style: NO_DATA_STYLE}
               else
                  sorted_group.push {x: bucket, y: 0, series: series_key, style: NO_DATA_STYLE}
               sorted_group = _.sortBy(sorted_group, "series")

         g className: "bar-group",
            _.map sorted_group, (datum, i) =>
               [x, y] = @handleGetCoords(datum)
               y ?= 0

               if @props.xScale && @props.yScale
                  if @props.horizontal
                     bar_width = Math.max(x, MIN_HEIGHT)
                     bar_height = (@props.yScale.rangeBand?() - bar_padding) / series.length
                     x_prop = 0
                     y_prop = (@props.grid_height - y) - ((bar_height * series.length) / MIN_HEIGHT) + (i * bar_height)
                  else
                     bar_width = (@props.xScale.rangeBand?() - bar_padding) / series.length
                     bar_height = Math.max(y, MIN_HEIGHT)
                     x_prop = x - ((bar_width * series.length) / MIN_HEIGHT) + (i * bar_width)
                     y_prop = Math.min(@props.grid_height - y, @props.grid_height - MIN_HEIGHT)

                  Bar
                     className: "area-chart--points"
                     key: x_prop + "_" + y_prop + "_" + bucket
                     width: bar_width
                     height: bar_height
                     x: x_prop
                     y: y_prop
                     style: datum.style
                     onMouseOver: (value, label, width, position, e) =>
                        position = _.extend {}, $(e.target).offset(), width: width
                        if @props.horizontal then position.left += width
                        else position.left += (width / 2)
                        @props.onMouseOver(datum, position)
                     onMouseOut: @props.onMouseOut
                     onClick: => @props.onBarClick(datum)
