import PropTypes from "prop-types";
import _ from "underscore";
import EverTrue from "app";
import Decorator from "clientDecorator";
import Api from "entities/helpers/api";
import { useIdentity } from "base/identity-resolver";
import { Button, Icon, Tooltip2, useOpenClose, Modal } from "@evertrue/et-components";
import { proposalSolicitor } from "../utils";
import ProposalForm from "./proposal-form";

/**
 * If org hasProposalWrites then allow actions such as setting a key proposal and editing proposal.
 * Key proposals can only be set by users who are a solicitor on the proposal.
 * If org does not have proposal writes, then just show whether or not the proposal is key for the user.
 */
const ProposalDetailActions = ({ proposal, primaryContactId, hasProposalWrites, setProposal }) => {
  const { user, org } = useIdentity();
  const userContactId = Decorator.User.getContactId(user, org.id);
  const userProposalContact = proposalSolicitor(proposal.solicitors, userContactId);
  const userIsContact = !_.isEmpty(userProposalContact);
  const [isModalOpen, openModal, closeModal] = useOpenClose();

  const handleToggleKeyProposal = (isKeyProposal) => {
    Api.UGC.KEY_PROPOSAL.put({
      urlArgs: { proposal_id: proposal.id, contact_id: userProposalContact.contact_id },
      params: { key_proposal: isKeyProposal },
    })
      .then((updatedProposal) => {
        setProposal(updatedProposal);
        EverTrue.Alert.success(isKeyProposal ? "Marked key proposal" : "Unmarked key proposal");
        EverTrue.track.set("proposal_action", { type: isKeyProposal ? "mark_key_proposal" : "unmark_key_proposal" });
      })
      .catch(() => {
        EverTrue.Alert.success("There was a problem updating proposal. Please try again.");
      });
  };

  const handleDelete = () => {
    EverTrue.Alert.confirm(
      {
        headline: "Delete Proposal?",
        content: "Are you sure you want to delete this proposal? This action cannot be undone.",
      },
      (confirmed) => {
        if (confirmed) {
          Api.UGC.PROPOSAL.delete({
            urlExtend: `/${proposal.contact_id}/${proposal.id}`,
          })
            .then(() => {
              EverTrue.UrlManager.hasPreviousPath() ? window.history.back() : EverTrue.Navigator("/proposal", true);
              EverTrue.Alert.success("Proposal deleted successfully");
            })
            .catch(() => {
              EverTrue.Alert.success("There was a problem deleting the proposal. Please try again.");
            });
        }
      }
    );
  };

  return (
    <>
      <Modal isOpen={isModalOpen} closeModal={closeModal}>
        <ProposalForm
          proposal={proposal}
          primaryContactId={primaryContactId}
          setProposal={setProposal}
          closeModal={closeModal}
        />
      </Modal>
      <div className="proposal-detail--actions">
        {userIsContact && (
          <>
            {userProposalContact.key_proposal ? (
              <Tooltip2
                trigger={
                  <div>
                    <Button
                      disabled={!hasProposalWrites}
                      label="Remove this proposal from your key proposals"
                      className="proposal-detail--action-button"
                      type="simple"
                      onClick={() => handleToggleKeyProposal(false)}
                    >
                      <Icon icon="star" size={1} className="proposal-detail--key-proposal" /> Key Proposal
                    </Button>
                  </div>
                }
              >
                {hasProposalWrites
                  ? "Remove this proposal from your key proposals"
                  : "This is one of your key proposals"}
              </Tooltip2>
            ) : (
              <Tooltip2
                trigger={
                  <div>
                    <Button
                      disabled={!hasProposalWrites}
                      label="Mark this proposal as one of your key proposals"
                      className="proposal-detail--action-button"
                      type="simple"
                      onClick={() => handleToggleKeyProposal(true)}
                    >
                      <Icon icon="star" size={1} /> Key Proposal
                    </Button>
                  </div>
                }
              >
                {hasProposalWrites ? "Mark this proposal as one of your key proposals" : "This is not a key proposal"}
              </Tooltip2>
            )}
          </>
        )}
        {hasProposalWrites && (
          <Button
            label="Edit this proposal"
            className="proposal-detail--action-button"
            type="simple"
            onClick={isModalOpen ? closeModal : openModal}
          >
            <Icon icon="edit" size={1} className="proposal-detail--edit-icon" />
            Edit
          </Button>
        )}
        {hasProposalWrites && EverTrue.store.user.isOwnerOrSuperUser() && (
          <Button
            label="Delete this proposal"
            className="proposal-detail--action-button"
            type="simple"
            onClick={handleDelete}
          >
            <Icon icon="trash" className="proposal-detail--edit-icon" />
            Delete
          </Button>
        )}
      </div>
    </>
  );
};

ProposalDetailActions.propTypes = {
  proposal: PropTypes.object.isRequired,
  primaryContactId: PropTypes.number.isRequired,
  hasProposalWrites: PropTypes.bool.isRequired,
  setProposal: PropTypes.func.isRequired,
};

export default ProposalDetailActions;
