module.exports = do ->
   _ = require("underscore").default
   Api = require("entities/helpers/api")
   {createSource} = require("@evertrue/et-flux")

   _query_xhr = undefined
   MAX_FACET_SIZE = 250

   getEndpoint = (type) ->
      switch type
         when "contact" then Api.CONTACTS.FACETS
         when "gift" then Api.SEARCH.GIFTS
         when "online_gift" then Api.GIVE.CONTACT_APPEAL_EVENTS
         when "contact_note" then Api.SEARCH.CONTACT_NOTE
         when "proposal" then Api.UGC.PROPOSAL_FACET
         when "assignment" then Api.SEARCH.ASSIGNMENTS
         else
            console.log "unknown type for fetching facets.  got: ", type
            Api.CONTACTS.FACETS

   createSource "FacetSource",
      actions:
         clearFacets: true
         fetchActiveFilterFacets: true
         loadingFacets: (isLoading) ->
            @require _.isBoolean(isLoading), "isLoading should be boolean"
         fetchedFacets: (object, type) ->
            @require _.isObject(object), "should be object"
            @require _.isString(type), "type should be string"
         searchingFacets: (isSearching) ->
            @require _.isBoolean(isSearching), "isSearching should be boolean"
         searchedFacets: (keys, term, type, object) ->
            @require _.isObject(object), "object should be paged object"
            @require _.isArray(keys), "keys should be array"
            @require _.contains(["gift", "contact", "contact_note"], type), "type is a valid facet type"
            @require _.isString(term) || _.isUndefined(term), "term should be string or undefined"

      api:
         clearFacets: ->
            @actions.clearFacets()

         fetch: (keys, type="contact", size=MAX_FACET_SIZE) ->
            return if _.isEmpty(keys)
            @actions.loadingFacets(true)

            endpoint = getEndpoint(type)

            endpoint.post
               data: _.jsonStringify {facet: _.map keys, (key) -> {field: key, size: size}}
               success: (resp) =>
                  # Include all keys that were requested so that items
                  # with no values won't continue to be requested
                  data = resp
                  _.each keys, (key) ->
                     if !_.has(data.facets, key) && data.facets
                        data.facets[key] = []
                  @actions.loadingFacets(false)
                  @actions.fetchedFacets(data, type)

         fetchActiveFilterFacets: ->
            @actions.fetchActiveFilterFacets()

         search: (keys, term = "", type="contact") ->
            _query_xhr.abort() if _query_xhr
            @actions.searchingFacets(true)

            endpoint = getEndpoint(type)
            search_term = if term.includes(":") then term.replace(/:/g, "\\:") else term

            endpoint.post
               beforeSend: (xhr) -> _query_xhr = xhr
               data: _.jsonStringify facet: _.map keys, (filter_key) ->
                  {field: filter_key.value, regex: "^#{search_term}.*|.*\\s#{search_term}.*"}
               success: (resp) =>
                  _query_xhr = undefined
                  @actions.searchingFacets(false)
                  @actions.searchedFacets(keys, term, type, resp)
