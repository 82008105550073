import { Component } from "react";
import _ from "underscore";
import Modal from "components/modals/modal";
import ModalConfirm from "components/modals/modal-confirm";
import { Checkbox } from "@evertrue/et-components";

class CalendarSyncModal extends Component {
  static defaultProps = {
    calendars: [],
    onManageCalendars: _.noop,
  };

  static propTypes = {
    onManageCalendars: ReactLibs.PropTypes.func,
    calendars: ReactLibs.PropTypes.array,
  };

  state = {
    calendars: this.props.calendars,
  };

  handleCalendarChange = (cal) => {
    this.setState({
      calendars: this.state.calendars.map((calendar) => {
        if (calendar.calendar_id === cal.calendar_id) return { ...calendar, is_connected: !calendar.is_connected };
        return calendar;
      }),
    });
  };

  handleSubmit = () => {
    const calendar_map = _.indexBy(this.props.calendars, "calendar_id");

    const cal_connected = this.state.calendars.reduce(
      (accum, updated_cal) => {
        const old_cal = calendar_map[updated_cal.calendar_id] || {};
        // see if they have changed
        if (updated_cal.is_connected !== old_cal.is_connected) {
          // split into groups
          if (updated_cal.is_connected) {
            return { ...accum, connect: [...accum.connect, updated_cal.calendar_id] };
          }
          return { ...accum, disconnect: [...accum.disconnect, updated_cal.calendar_id] };
        }
        return accum;
      },
      { connect: [], disconnect: [] }
    );

    this.props.onManageCalendars(cal_connected);
  };

  render() {
    const { calendars, ...modalTriggerProps } = this.props;
    return (
      <Modal {...modalTriggerProps} width={500}>
        <ModalConfirm header="Choose Calendars" buttonLabel="Save" onSubmit={this.handleSubmit}>
          <div className="calendar-sync-modal">
            <div className="calendar-sync-modal--header">Choose the calendars to sync with your Trips & Meetings:</div>
            {this.state.calendars.map((calendar = {}) => (
              <Checkbox
                key={calendar.calendar_id}
                className="calendar-sync-modal--item"
                value={calendar.calendar_id}
                checked={calendar.is_connected}
                onChange={() => this.handleCalendarChange(calendar)}
              >
                {calendar.calendar_name}
              </Checkbox>
            ))}
          </div>
        </ModalConfirm>
      </Modal>
    );
  }
}

export default CalendarSyncModal;
