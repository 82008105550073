module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   EverTrue = require("app")
   {createComponent} = require("@evertrue/et-flux")
   ExportToast = require("apps/export/components/export-toast")
   ExportStore = require("apps/export/stores/export-store")


   createComponent "ExportToastController",
      registerStores: ->
         @on ExportStore, ->
            toasts: ExportStore.getToasts()

      componentDidUpdate: (prevProps, prevState) ->
         if _.deepArrayCompare(prevState.toasts, @state.toasts)
            _.each @state.toasts, (export_data) ->
               EverTrue.Alert.info ExportToast({export_id: export_data.id}),
                  timeout: 0
                  id: "export_#{export_data.id}"
                  icon: if export_data.percent_complete < 1 then "gt-icon-access-time" else "gt-icon-check"

      render: ->
         null
