module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   EverTrue = require("app")
   {createComponent} = require("@evertrue/et-flux")
   classNames = require("classnames")
   WindowOverflowMixin = require("mixins/window-overflow-mixin")
   UrlSource = require("apps/layout/sources/url-source")
   SegmentSource = require("apps/filters/sources/segment-source")
   LayoutSource = require("apps/layout/sources/layout-source")
   ImportSource = require("apps/layout/sources/import-source")
   ImportStore = require("apps/layout/stores/import-store")
   LayoutStore = require("apps/layout/stores/layout-store")
   FeatureStore = require("apps/layout/stores/feature-store").default
   SegmentStore = require("apps/filters/stores/segment-store")
   ListStore = require("apps/lists/stores/list-store")
   ListSource = require("apps/lists/sources/list-source")
   FilterSource = require("apps/filters/sources/filter-source")
   ContactSource = require("apps/contact/sources/contact-source")
   DNAStore = require("base/dna/dna-store").default
   {createFactory} = require("base/new-utils")
   {span, div, strong} = ReactLibs.DOMFactories
   Button = createFactory(require("@evertrue/et-components").Button)
   Gated = createFactory require("components/gated")
   IsGated = createFactory(require("components/is-gated").default)
   ModalConfirm = createFactory require("components/modals/modal-confirm")
   Modal = createFactory require("components/modals/modal")
   NavigationSubItem = createFactory require("apps/layout/components/navigation-sub-item").default
   NavigationDashboardLink = createFactory require("apps/layout/components/navigation-dashboard-link").default
   NavigationTableauLink = createFactory require("apps/layout/components/navigation-tableau-link").default
   StatusIndicator = require("apps/layout/components/status-indicator")
   ListDetailedSelector = require("apps/lists/components/list-detailed-selector")
   Icon = createFactory (require("components/elements/icon").default)
   KeyPressManager = createFactory(require("components/helpers/KeyPressManager").default)
   NavigationTaskLink = createFactory require("apps/tasks/components/tasks-link").default
   NavigationJourneysLink = createFactory require("apps/layout/components/navigation-journeys-link").default
   NavigationReportingLink = createFactory require("apps/reporting/components/navigation-reporting-link").default
   NavigationHomepageLink = createFactory require("apps/layout/components/navigation-homepage-link").default
   NavigationAiSearchLink = createFactory require("apps/layout/components/navigation-ai-search-link").default
   {prevFocusWithWrap, nextFocusWithWrap, moveFocusToMain, containsActiveElement} = require("base/accessibility-utils")

#    _beta = span className: "base-nav--beta-badge", "BETA"

   createComponent "Navigation",
      getInitialState: ->
         active_path: undefined
         tooltip_key: undefined
         show_list_creator: false

      registerStores: ->
         @on SegmentStore, ->
            selected_segment: SegmentStore.getSelectedSegment()
            segment_length: SegmentStore.getSegments().length
            segments: SegmentStore.getRecentSegments()

         @on ListStore, ->
            lists_length: ListStore.getJoinedLists().length
            lists: ListStore.getRecentLists()

         @on LayoutStore, ->
            nav_is_collapsed: LayoutStore.getNavState()

         @on ImportStore, ->
            last_data_update: ImportStore.getLastUpdate()
            loading_last_update: ImportStore.getLoading()

         @on FeatureStore, ->
            has_community_gate: FeatureStore.hasFeature("et_community")
            has_proposal_reads: FeatureStore.hasFeature("proposal_reads")
            has_interaction_reads: FeatureStore.hasFeature("ugc_show_imported_notes")
            has_rm_assignments: FeatureStore.hasFeature("rm_assignments")
            has_major_gifts: FeatureStore.hasFeature("major_gifts")
            has_my_team: FeatureStore.hasFeature("my_team")
            has_unreleased_feature: FeatureStore.hasFeature("unreleased_feature")
            has_ai_search: FeatureStore.hasFeature("ai_search")

         @on DNAStore, ->
            org_has_community: DNAStore.hasCommunity()

      UNSAFE_componentWillMount: ->
         @setActivePath()
         ListSource.fetchViewed()
         LayoutSource.fetchNavState()

      componentDidMount: ->
         ImportSource.fetchLastImport()
         EverTrue.vent.on "route:change", @setActivePath

      componentWillUnmount: ->
         EverTrue.vent.off "route:change", @setActivePath

      setActivePath: ->
         @setState active_path: window.location.pathname

      handleResetBrowse: ->
         EverTrue.track.set "tab_view", {type: "browse"}
         EverTrue.Navigator("/contact", true)
         UrlSource.updateSegment()
         FilterSource.set()
         ContactSource.query()
         return undefined

      handleNavCollapse: ->
         LayoutSource.toggleNav()

      handleNavItemToggle: (key) ->
         @setState _.toObject(key, !@state[key])

      handleSelectSegment: (segment) ->
         SegmentSource.applySegment(segment.value)

      handleSelectRecentlyViewed: ->
         EverTrue.Navigator("/contact/recent", true)

      handleTooltipShow: (ref, label) ->
         if @state.nav_is_collapsed
            overlay = div className: "base-nav-sub-item--tooltip", label
            props = _.extend WindowOverflowMixin.getDOMProperties(ref), {key: _.randomKey()}
            @setState {tooltip_key: props.key}
            LayoutSource.overlay overlay, props

      handleTooltipHide: ->
         if @state.nav_is_collapsed
            LayoutSource.removeOverlay(@state.tooltip_key)

      renderListModal: ->
         Modal width: 401,
            ModalConfirm
               className: "list-detailed-selector--header"
               header: "Select List"
               hasMargin: false
               ListDetailedSelector
                  onSelect: (list) ->
                     EverTrue.track.setReferrer("list_popup")
                     Backbone.history.navigate("list/#{list?.id}", true)
                  onCreate: =>
                     @refs.list_modal?.handleUnmountModal?()
                     @refs.more_list_modal?.handleUnmountModal?()
                     @setState show_list_creator: true

      render: ->
         show_community = @state.org_has_community && @state.has_community_gate

         path = @state.active_path || ""

         browse_is_active = !@state.selected_segment &&
               !path.match(///^\/contact/recent///) &&
               (!!path.match(///^\/contact///))
         
         browse_segment = @state.selected_segment


         # This is used to determine whether to show the raise section of the nav, if they have no gates
         # associated with that section, it should hide it.
         raise_gates = ["volunteers", "rm_assignments","my_trips", "events", "my_team" ]
         has_raise_section = _.some(raise_gates, (gate) -> FeatureStore.hasFeature(gate))

         filters_v2_opt_out = !!localStorage.getItem("filters_v2_opt_out")

         div id: "base-nav", className: EverTrue.classSet("is-collapsed": @state.nav_is_collapsed),
            # Header w/ Logo
            div className: "base-nav--header",
               Button type: "simple", className: "base-nav--logo", onClick: @handleResetBrowse, title: "Reset to Browse"
               if @state.nav_is_collapsed then Button type: "simple", className: "base-nav--pyramid-logo", onClick: @handleNavCollapse, title: "Toggle Nav Bar" else Icon icon: "collapse", onClick: @handleNavCollapse

            KeyPressManager
               onArrowUp: (node, event) -> prevFocusWithWrap(node)
               onArrowDown: (node, event) -> nextFocusWithWrap(node)
               onArrowRight: moveFocusToMain
               onEnter: (node,event) ->
                  _.defer ->
                     main = document.getElementsByTagName("main")[0]
                     if !containsActiveElement(main)
                        _.delay(moveFocusToMain, 100)
               div className: "base-nav--content",
                  # Main Nav Content
                  div className: "base-nav--main",
                     div className: "base-nav--main-group",

                        NavigationHomepageLink active_path: path
                        NavigationJourneysLink active_path: path,  isCollapsed: @state.nav_is_collapsed
                        NavigationReportingLink active_path: path
                        NavigationDashboardLink active_path: path
                        NavigationTaskLink active_path: path, isCollapsed: @state.nav_is_collapsed
                        IsGated feature: "filters_v2", inverse: true, render: ({ show: doesnt_have_filters_v1 }) ->
                           if doesnt_have_filters_v1 || filters_v2_opt_out

                              NavigationSubItem
                                 icon: "segment"
                                 label: "Saved Searches"
                                 isActive: if path.match(///^\/segments///) ||
                                    browse_segment
                                    then true else false
                                 onClick: ->
                                    EverTrue.track.set "tab_view", {type: "saved_searches"}
                                    EverTrue.Navigator("/segments", true)
                           else 

                              NavigationSubItem
                                 icon: "segment"
                                 label: "Dynamic Lists"
                                 isActive: if path.match(///^\/dynamic-lists///) then true else false
                                 onClick: ->
                                    EverTrue.track.set "tab_view", {type: "dynamic_lists"}
                                    EverTrue.Navigator("/dynamic-lists", true)

                        NavigationSubItem
                           icon: "list-view"
                           label: "Lists"
                           isActive: if path.match(///^\/list*///) then true else false
                           onClick: ->
                              EverTrue.track.set "tab_view", {type: "lists"}
                              EverTrue.Navigator("/lists", true)

                        NavigationSubItem
                           icon: "recently-viewed"
                           label: "Recently Viewed"
                           isActive: if path.match(///^\/contact/recent///) then true else false
                           onClick: ->
                              EverTrue.track.set "tab_view", {type: "recently_viewed"}
                              EverTrue.Navigator("/contact/recent", true)

                      div className:"base-nav--line"
                      NavigationAiSearchLink active_path: path
                      # These conditionals are applied in the AI Search link component. It's here separately because
                      # I couldn't get Coffeescript to accept two statements under the conditional.
                      if @state.has_ai_search && @state.has_unreleased_feature
                        div className:"base-nav--line"

                      div className: "base-nav--main-group",
                        div className: classNames("base-nav--main-group-title", "is-collapsed": @state.nav_is_collapsed)

                        IsGated feature: "filters_v2", render: ({ show: has_filters_v2 }) ->
                           div null,
                              # Filters 2.0 Landing Page
                              if has_filters_v2 && !filters_v2_opt_out
                                 NavigationSubItem
                                    icon: "search"
                                    label: "Constituents"
                                    isActive: if path.match(///^\/constituents///) ||
                                       path.match(///^\/search///) ||
                                       browse_is_active then true else false
                                    onClick: ->
                                       #TODO: Add Mixpanel tracking - just need to add to whitelist once we know the value
                                       # EverTrue.track.set "tab_view", {type: "constituents"}
                                       EverTrue.Navigator("/constituents", true)
                              else
                                 NavigationSubItem
                                    icon: "person"
                                    label: "Constituents"
                                    isActive: browse_is_active
                                    onClick: ->
                                       EverTrue.track.set "tab_view", {type: "browse"}
                                       EverTrue.Navigator("/contact", true)

                        NavigationSubItem
                           icon: "interactions"
                           label: "Interactions"
                           isActive: if path.match(///^\/interaction///) then true else false
                           onClick: ->
                              EverTrue.track.set "tab_view", {type: "interactions"}
                              EverTrue.Navigator("/interaction", true)

                        NavigationSubItem
                           icon: "other-interaction"
                           label: "Proposals"
                           isActive: if path.match(///^\/proposal///) then true else false
                           onClick: ->
                              EverTrue.track.set "tab_view", {type: "trips"}
                              EverTrue.Navigator("/proposal", true)


                        Gated feature: "events", el: "div",
                           NavigationSubItem
                              icon: "event"
                              label: "Events"
                              isActive: if path.match(///^\/events.*///) then true else false
                              onClick: ->
                                 EverTrue.track.set "tab_view", {type: "events"}
                                 EverTrue.Navigator("/events", true)

                        NavigationSubItem
                           icon: "facebook"
                           label: "Facebook"
                           isActive: if path.match(///^\/facebook\/.*///) then true else false
                           onClick: ->
                              EverTrue.track.set "tab_view", {type: "facebook"}
                              EverTrue.Navigator("/facebook/pages", true)

                     div className: "base-nav--line"

                     if has_raise_section
                        div className: "base-nav--main-group",
                           div className: classNames("base-nav--main-group-title", "is-collapsed": @state.nav_is_collapsed)
                           Gated feature: "my_trips", el: "div",
                              NavigationSubItem
                                 icon: "meeting"
                                 label: "Trips & Meetings"
                                 isActive: if path.match(///^\/trips.*///) then true else false
                                 onClick: ->
                                    EverTrue.track.set "tab_view", {type: "trips"}
                                    EverTrue.Navigator("/trips?trip_user=#{EverTrue.store.user.get("id")}", true)

                           if @state.has_major_gifts && !@state.has_rm_assignments
                              NavigationSubItem
                                 href: "/my-portfolio/prospects"
                                 icon: "work"
                                 label: "My Portfolio"
                                 isActive: if path.match(///my-portfolio\/.*///) then true else false

                           Gated feature: "rm_assignments", el: "div",
                              NavigationSubItem
                                 icon: "insert-chart"
                                 label: "Portfolios"
                                 isActive: if path.match(///portfolio-performance.*///) then true else false
                                 onClick: ->
                                    EverTrue.track.set "tab_view", {type: "portfolio"}
                                    EverTrue.Navigator("/portfolio-performance", true)

                           Gated feature: "volunteers", el: "div",
                              NavigationSubItem
                                 icon: "group"
                                 label: "Volunteers"
                                 isActive: if path.match("volunteers") then true else false
                                 onClick: ->
                                    EverTrue.track.set "tab_view", {type: "volunteers"}
                                    EverTrue.Navigator("/volunteers/pools", true)

                           NavigationTableauLink active_path: path

                           if show_community
                              NavigationSubItem
                                 href: "/community/interactions"
                                 icon: "community"
                                 label: "Community"
                                 isActive: if path.match("community/interactions") then true else false

                           if @state.has_my_team && !@state.has_rm_assignments
                              NavigationSubItem
                                 href: "/team/performance"
                                 icon: "insert-chart"
                                 label: "My Team"
                                 isActive: if path.match(///team.*///) then true else false


               # Footer
               div className: EverTrue.classSet("base-nav--footer", "is-collapsed": @state.nav_is_collapsed),
                  div null,
                     if EverTrue.config.environment_data
                        div className: "base-nav--db-data-tag",
                           span className: "badge-warning",
                              "#{EverTrue.config.environment_data}"

                     div
                        ref: "data_update"
                        className: "base-nav--footer-item"
                        onMouseOut: @handleTooltipHide
                        onMouseOver: =>
                           @handleTooltipShow(@refs.data_update, "Last Data Update: #{@state.last_data_update}")

                        div null,
                           Icon icon: "access-time"
                           strong className: "base-nav--footer-text", "Last Data Update"
                        div className: "base-nav--data-update",
                           @state.last_data_update || "Unavailable"

                     div
                        ref: "status"
                        className: "base-nav--status base-nav--footer-item",
                        onMouseOut: @handleTooltipHide
                        onMouseOver: =>
                           @handleTooltipShow(@refs.status, "EverTrue Status")
                        StatusIndicator()
