module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   OrgStore = require("base/org/org-store").default
   config = require("config/env")
   {createFactory} = require("base/new-utils")
   Module = createFactory require("components/module").default
   BackToTop = createFactory require("components/controls/back-to-top")
   CommunityInteractions = require("apps/community/components/community-interactions")
   Loading = require("components/elements/loading")
   {createComponent} = require("@evertrue/et-flux")
   {h3, div} = ReactLibs.DOMFactories
   Api = require("entities/helpers/api")
   SortUtils = require("mixins/sort-utils")

   LIMIT = 15

   _getIdsQuery = (ids) ->
      {must: [{id: {in: ids}}]}

   _fetchCommunityInteractions = (offset) ->
      query = {sort:[{created_at:{order:"desc"}}]}
      query.must = [{application_id:{match:config.getApplicationId("community")}}]

      Api.SEARCH.SESSION.post
         data: JSON.stringify(query)
         params:
            limit: LIMIT
            offset: offset

   _fetchUsers = (user_ids) ->
      Api.SEARCH.USER.post
         data: JSON.stringify(_getIdsQuery(user_ids))

   _fetchContacts = (contact_ids) ->
      Api.CONTACTS.SEARCH.post
         data: JSON.stringify(_getIdsQuery(contact_ids))

   createComponent "CommunityController",
      getInitialState: ->
         loading: true
         data: []
         total: undefined
         offset: 0
         interactions: []

      componentDidMount: ->
         @handleLoadInteractions()

      registerStores: ->
         @on OrgStore, ->
            current_oid: OrgStore.getCurrentOid()

      combineAndSort: (contacts, unprocessed_sessions) ->
         sessions_with_contact = _.compact _.map unprocessed_sessions, (session) ->
            contact = _.findWhere contacts, {user_id: session.user_id}
            if contact
               _.extend {}, session, { contact }
         all_interactions = _.union @state.interactions, sessions_with_contact
         grouped_interactions =
            SortUtils.groupResultsByDate(all_interactions,
               (session) -> session.created_at
            )

         @setState
            data: grouped_interactions
            loading: false
            interactions: all_interactions

      handleLoadInteractions: ->
         @setState loading: true
         _fetchCommunityInteractions(@state.offset).then (interactions) =>
            @setState
               total: interactions.total
               offset: @state.offset + LIMIT
            unprocessed_sessions = interactions.items
            user_ids = _.uniq _.pluck(unprocessed_sessions, "user_id")
            _fetchUsers(user_ids).then (users) =>
               contact_ids = _.compact _.map users.items, (user) =>
                  affil = _.findWhere user.affiliations, {organization_id: @state.current_oid}
                  affil.contact_id
               _fetchContacts(contact_ids).then (contacts) =>
                  @combineAndSort(contacts.items, unprocessed_sessions)

      handleLoadMore: ->
         return if @state.loading || _.isEmpty(@state.data)
         if @state.total > @state.interactions.length
            @handleLoadInteractions()

      render: ->
         div className: "page-container",
            Module
               className: "social-posts-timeline loading-container"
               header: "Matched constituent activity in the EverTrue Community App"
               icon: "fa fa-fw et-icon-evertrue-logomark"

               CommunityInteractions
                  interactions: @state.data
                  loadMoreInteractions: @handleLoadMore

               if @state.loading
                  Loading position: "center", text: "Loading..."
               else if _.isEmpty(@state.data)
                  h3 className: "text-placeholder", " No Content Available "

               BackToTop showPosition: 265
