module.exports = do ->
   _ = require("underscore").default
   {createSource} = require("@evertrue/et-flux")
   _pusher = require("base/sockets/pusher").default

   _user_channel = undefined
   _user_channel_callbacks = {}

   _org_channel = undefined
   _org_channel_callbacks = {}


   createSource "SocketSource",
      actions:
         refresh_inbox: true
         trip_added: true
         trip_updated: true
         trip_deleted: true
         meeting_added: true
         meeting_updated: true
         meeting_deleted: true
         list_collaborator_change: true
         contact_export_progress: true
         external_export_progress: true
         solicitor_export_progress: true
         interaction_export_progress: true
         assignment_export_progress: true
         prospect_status_export_progress: true
         bulk_add_to_list_progress: true
         proposal_export_progress: true
         # Volunteers
         pool_change: true
         solicitor_change: true
         prospect_change: true
         assignment_change: true
         joint_assignment_change: true
         ask_change: true
         bulk_add_prospects_to_pool_from_search_criteria: true
         bulk_add_solicitors_to_pool_from_search_criteria: true
         bulk_pool_fy_rollover_update_progress: true
         bulk_pool_delete_progress: true
         committed_amount_change: true
         # don't add new actions, see: https://evertroops.atlassian.net/browse/ET-7645
      api:
         reset: ->
            _pusher.unsubscribe(_user_channel?.name) if _user_channel?.name
            _user_channel = undefined
            _user_channel_callbacks = {}

            _pusher.unsubscribe(_org_channel?.name) if _org_channel?.name
            _org_channel = undefined
            _org_channel_callbacks = {}

         subscribeUser: (id) ->
            _pusher.unsubscribe(_user_channel?.name) if _user_channel?.name
            _user_channel = _pusher.subscribe("user_#{id}")
            _.each _user_channel_callbacks, (cb, evnt) ->
               _user_channel.bind(evnt, cb)

         bindUser: (evnt) ->
            console.error "#{evnt} not defined as action" if !@actions[evnt]
            _user_channel_callbacks[evnt] = (data) => @actions[evnt]?(data)
            _user_channel?.bind evnt, _user_channel_callbacks[evnt]

         unbindUser: (evnt) ->
            _user_channel.unbind evnt, _user_channel_callbacks[evnt]
            _user_channel_callbacks = _.omit(_user_channel_callbacks, evnt)

         subscribeOrg: (id) ->
            _pusher.unsubscribe(_org_channel?.name) if _org_channel?.name
            _org_channel = _pusher.subscribe("oid_#{id}")
            _.each _org_channel_callbacks, (cb, evnt) ->
               _org_channel.bind(evnt, cb)

         bindOrg: (evnt) ->
            console.error "#{evnt} not defined as action" if !@actions[evnt]
            _org_channel_callbacks[evnt] = (data) => @actions[evnt]?(data)
            _org_channel?.bind evnt, _org_channel_callbacks[evnt]

         unbindOrg: (evnt) ->
            _org_channel?.unbind evnt, _org_channel_callbacks[evnt]
            _org_channel_callbacks = _.omit(_org_channel_callbacks, evnt)
