import { PageHeader, Modal, ModalBody, ActionsMenu, ModalHeader } from "@evertrue/et-components";
import colors from "@evertrue/et-variables/src/styles/variables.scss";
import ListSelectorController from "apps/lists/controllers/lists-selector-controller";
import { PropTypes } from "prop-types";
import SelectedContactsString from "components/formatting/selected-contacts-string";

const ContactsPageHeader = ({ totalResults, numSelectedContacts, contactIds, loading }) => {
  const addAction = {
    id: 1,
    icon: "list",
    iconStyle: { color: colors.green300 },
    description: `Add ${numSelectedContacts} ${numSelectedContacts === 1 ? "Constituent" : "Constituents"} to a List`,
    label: "Add to List",
    mountOnClick: ({ is_open, close }) => {
      return (
        <Modal isOpen={is_open} closeModal={close} closeOnOutsideClick={true} size="small">
          <ModalHeader title="Add Constituents to List" closeModal={close} />
          <ModalBody>
            <ListSelectorController contactIds={contactIds} closePopover={close} />
          </ModalBody>
        </Modal>
      );
    },
  };

  const renderSelectedContacts = () => {
    return <SelectedContactsString selected={numSelectedContacts} results={totalResults} loading={loading} />;
  };

  return (
    <>
      <PageHeader
        title="Recently Viewed"
        subtitle={renderSelectedContacts()}
        actions={<ActionsMenu options={[addAction]} disabled={numSelectedContacts < 1} />}
      />
    </>
  );
};

ContactsPageHeader.propTypes = {
  totalResults: PropTypes.number,
  numSelectedContacts: PropTypes.number,
  contactIds: PropTypes.array,
  loading: PropTypes.bool,
};

export default ContactsPageHeader;
