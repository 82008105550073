import PropTypes from "prop-types";
import { AdvancedCombobox } from "@evertrue/et-components";

const ProposalTableFilterYours = ({ filterValue, handleApplyFilter }) => {
  return (
    <div className="proposal-search-controller--filter-yours">
      <AdvancedCombobox
        className={filterValue !== "all" ? "proposal-search-controller--filter-selected" : ""}
        value={{ value: filterValue }}
        options={[
          { value: "all", label: "Any Solicitor" },
          { value: "yours", label: "Your Proposals" },
          { value: "key", label: "Your Key Proposals" },
        ]}
        onChange={(option) => handleApplyFilter(option.value)}
      />
    </div>
  );
};

ProposalTableFilterYours.propTypes = {
  filterValue: PropTypes.string,
  handleApplyFilter: PropTypes.func,
};

export default ProposalTableFilterYours;
