module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   EverTrue = require("app")
   React = require("react")
   BaseRouter = require("base/base-router")
   CommunityController = require("apps/community/controllers/community-controller")

   class CommunityRouter extends BaseRouter
      routes:
         "community/interactions" : "index"

      beforeRoute: ->
         EverTrue.vent.trigger "set:page:title", "EverTrue Community"
         $(document).scrollTop(0)

      index: ->
         EverTrue.execute "removePageContainer"
         EverTrue.execute "setBrowserTitle", "EverTrue Community Interactions"
         EverTrue.track.set "viewed", {type: "community_interactions"}
         EverTrue.track.setReferrer("community_interactions")

         @currentView = CommunityController()
         EverTrue.page.mount @currentView

