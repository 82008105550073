module.exports = do ->
   _ = require("underscore").default
   ReactDOM = require("react-dom")
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories
   LayoutSource = require("apps/layout/sources/layout-source")
   PopoverOverlay = require("components/overlays/popover-overlay")
   WindowOverflowMixin = require("mixins/window-overflow-mixin")


   createComponent "Popover",
      propTypes:
         trigger: ReactLibs.PropTypes.any
         disable: ReactLibs.PropTypes.bool
         event: ReactLibs.PropTypes.oneOf ["click", "hover"]
         position: ReactLibs.PropTypes.string
         width: ReactLibs.PropTypes.number
         options: ReactLibs.PropTypes.object
         onClose: ReactLibs.PropTypes.func
         render: ReactLibs.PropTypes.func
         arrowSize: ReactLibs.PropTypes.number
         isReachable: ReactLibs.PropTypes.bool

      getDefaultProps: ->
         event: "click"
         isReachable: true

      getInitialState: ->
         open: false

      componentDidMount: ->
         @is_mounted = true
         document.body.addEventListener("keydown", @handleEscape)

      componentDidUpdate: (prevProps, prevState) ->
         if !prevState.open && @state.open
            $("body").trigger("click")
            @poller = setInterval(@checkForOriginChange, 200)

      componentWillUnmount: ->
         @is_mounted = false
         clearInterval(@poller)
         if @state.overlay_key
            @handleClose()
         document.body.removeEventListener("keydown", @handleEscape)

      UNSAFE_componentWillReceiveProps: (newProps) ->
         if @state.open && !_.isEqual(newProps, @props)
            @renderOverlay(newProps)

      checkForOriginChange: ->
         if @is_mounted
            if node = ReactDOM.findDOMNode(@)
               dom_props = WindowOverflowMixin.getDOMProperties(node)
               if !_.isEqual dom_props, @state.dom_props
                  @renderOverlay()

      getOverlay: (dom_props, props) ->
         props ?= @props
         attrs = _.extend {},
            position: @props.position
            width: @props.width
            origin: dom_props
            arrowPosition: @props.arrowPosition
            arrowSize: @props.arrowSize
            onClose: => _.defer(@handleClose)
         , @props.options
         if !@props.render
            PopoverOverlay attrs, props.children
         else
            PopoverOverlay attrs, props.render({close: attrs.onClose})


      handleClickToggle: (e) ->
         # _.defer is used to fix the bug where you "outside click"
         # but click on another popover, this prevents it
         # from showing and removing at once and breaking the component
         if @props.event == "click"
            e.stopPropagation()
            if !@state.open then _.defer => @renderOverlay()

      handleMouseOver: ->
         if @props.event == "hover"
            @renderOverlay()

      handleMouseOut: ->
         if @props.event == "hover"
            @handleClose()

      handleKeyPress: (event) ->
         if event.which in [13,32]
            @handleClickToggle(event)

      handleClose: ->
         clearInterval(@poller)
         @setState {open: false} if @is_mounted
         LayoutSource.removeOverlay(@state.overlay_key)
         @props.onClose?()

      handleEscape: (e) ->
         if e.key == "Escape"
            @handleClose()


      renderOverlay: (props) ->
         return if @props.disable
         dom_props = WindowOverflowMixin.getDOMProperties(@)
         key = @state.overlay_key || _.randomKey()
         if @is_mounted
            @setState {open: true, dom_props: dom_props, overlay_key: key}
         LayoutSource.overlay @getOverlay(dom_props, props), _.extend(dom_props, {key: key})

      render: ->
         div
            role:"button"
            tabIndex: if @props.isReachable then 0 else -1
            className: classNames("popover-trigger", @props.className, {"is-open": @state.open}),
            style: @props.style
            onKeyPress: @handleKeyPress
            onClick: @handleClickToggle
            onMouseOver: @handleMouseOver
            onMouseOut: @handleMouseOut

            if typeof @props.trigger == "function"
               @props.trigger({open: @renderOverlay, close: @handleClose})
            else
               @props.trigger
