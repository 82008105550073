module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   PropTypes = require("prop-types")
   {createComponent} = require("@evertrue/et-flux")
   {div, table, tbody, tr, td, span} = ReactLibs.DOMFactories
   MapSource = require("apps/map/sources/map-source")
   MapStore = require("apps/map/stores/map-store")
   FeatureStore = require("apps/layout/stores/feature-store").default
   FilterStore = require("apps/filters/stores/filter-store")
   Loading = require("components/elements/loading")
   SelectContactCheckbox = require("apps/contact/components/contacts/select-contact-checkbox")
   {createFactory} = require("base/new-utils")
   ScoreProgress = createFactory require("apps/contact/components/contacts/score-progress").default
   SortDropdown = require("components/controls/sort-dropdown")
   ContactCard = createFactory(require("apps/contact/components/contact-card/contact-card"))
   ContactGiving = createFactory(require("apps/contact/components/contact-card/contact-giving").default)
   MapUtils = require("apps/map/map-utils")
   Decorator = require("clientDecorator")

   _options = [
      {label: "Distance from map center", value: "proximity_sort"}
      {label: "EverTrue Score", value: "score.score"}
      {label: "Lifetime Giving", value: "giving.lifetime_amount"}
   ]


   createComponent "MapContactsController",
      propTypes:
         loading: PropTypes.bool
         contacts: PropTypes.object
         onSort: PropTypes.func
         hasActiveFocus: PropTypes.bool

      contactListRef: React.createRef()


      registerStores: ->
         @on FeatureStore, ->
            has_scores: FeatureStore.hasFeature("scores")

         @on MapStore, ->
            bounding_box: MapStore.getBoundingBox()
            clusters: MapStore.getClusters()

         @on FilterStore, ->
            types: _.map FilterStore.getActiveFilterByKey("address_type"), (filter) ->
               filter.value

      componentDidMount: ->
         if this.props.hasActiveFocus
            @contactListRef.current.focus()

      componentDidUpdate: (prevProps) ->
         if !prevProps.hasActiveFocus && this.props.hasActiveFocus
            @contactListRef.current.focus()

      handleMouseOver: (contact) ->
         clearTimeout(@mouseOutTimeout)
         lat_lngs = _.compact _.map contact.addresses, (address) =>
            address_not_filtered = (_.isEmpty(@state.types) || _.contains(@state.types, address.type?.value))
            if address.lat?.value && address.lng?.value && address_not_filtered
               {lat: address.lat.value, lng: address.lng.value}

         clusters = _.compact _.map @state.clusters, (cluster, key) ->
            key if _.any lat_lngs, (latlng) -> MapUtils.isLatLngInBox(latlng, cluster.geobox)
         MapSource.highlightCluster(clusters)

      handleMouseOut: ->
         clearTimeout(@mouseOutTimeout)
         @mouseOutTimeout = _.delay ->
            MapSource.highlightCluster()
         , 100

      render: ->
         div className: "map-contacts", tabIndex: -1, ref: this.contactListRef,
            if @props.loading
               Loading text: "Loading...", position: "top"
            else
               div null,
                  div className: "map-contacts--header",
                     SortDropdown
                        options: @props.sort_options || _options
                        sortProp: @props.sortProp || @props.contacts.sortProp
                        sortReverse: @props.sortReverse || @props.contacts.sortReverse
                        onSort: @props.onSort

                  div className: "map-contacts--wrapper",
                     table null,
                        tbody null,
                           _.map @props.contacts.items, (contact) =>
                              return if _.isEmpty(contact)

                              tr className: "map-contacts--contact", key: contact.id,
                                 td className: "map-contacts--checkbox",
                                    SelectContactCheckbox id: contact.id, name: Decorator.Contacts.getDetailedName(contact)
                                 td className: "map-contacts--card",
                                    ContactCard
                                       contact: contact
                                       onMouseOver: => @handleMouseOver(contact)
                                       onMouseOut: @handleMouseOut,
                                          # hideGiving is used in the portfolio performance map because we are getting that
                                          # giving data from graphql and ContactGiving has a differeent format
                                          unless @props.hideGiving
                                             ContactGiving contact: contact, showLastGiftDate: @props.showLastGiftDate

                                          if _.isFunction(@props.contactCardChildren) then @props.contactCardChildren(contact) else null

                                          unless @props.hideScores
                                             div className: "map-contacts--score",
                                                if @state.has_scores
                                                   span className: "text-label", "EverTrue Score:"
                                                   ScoreProgress score: contact.score?.score?.value
