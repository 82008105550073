import PropTypes from "prop-types";
import _ from "underscore";
import ScoreProgress from "apps/contact/components/contacts/score-progress";

const _score_schema = {
  professional: { display: "Professional" },
  demographics: { display: "Demographic" },
  social: { display: "Social" },
  giving: { display: "Giving" },
};

const ScoreBreakdown = ({ scores = {} }) => {
  const renderScoreBreakdown = () => {
    return _.map(scores, (score_obj, key) => {
      if (_score_schema[key]) {
        return (
          <div className="score-breakdown--sub-score" key={key}>
            <div className="score-breakdown--label">{_score_schema[key].display}</div>
            <ScoreProgress score={score_obj.value} className="score-breakdown--progress" />
          </div>
        );
      }
      return null;
    });
  };

  return <div className="score-breakdown">{renderScoreBreakdown()}</div>;
};

ScoreBreakdown.propTypes = {
  scores: PropTypes.object,
};

export default ScoreBreakdown;
