module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {div, span, a} = ReactLibs.DOMFactories

   createComponent "Breadcrumbs",
      propTypes:
         items: ReactLibs.PropTypes.array # {id: #, label: node or string, link: string}

      render: ->
         last_index = _.size(@props.items) - 1

         div className: "breadcrumbs",
            _.map @props.items, (item, index) ->
               span className: "breadcrumbs--item", key: item.id,
                  if item.link
                     a href: item.link, className: "breadcrumbs--link", item.label
                  else
                     span className: "breadcrumbs--text", item.label

                  unless index == last_index
                     span className: "breadcrumbs--seperator", " / "
