import { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { ContactDisplayCard, Button } from "@evertrue/et-components";
import ContactCard from "apps/contact/components/contact-card/contact-card";

const ProposalDetailContacts = ({ proposal, contacts }) => {
  const [showMore, setShowMore] = useState(false);

  const sortContacts = (a) => {
    if (a.type === "PRIMARY") return -1;
    return 1;
  };

  const prospects = proposal.contacts ? [...proposal.contacts].sort(sortContacts) : [];
  const solicitors = proposal.solicitors ? [...proposal.solicitors].sort(sortContacts) : [];

  return (
    <>
      <div className="proposal-detail--heading">Prospects</div>
      {prospects.map((prospect) => (
        <ContactCard
          key={prospect.id}
          className={prospect.type === "PRIMARY" ? "proposal-detail--primary-contact" : ""}
          contact={contacts[prospect.contact_id]}
          showClassYear={true}
        />
      ))}
      {proposal.description && proposal.description.length > 200 ? (
        <>
          <div>{showMore ? proposal.description : `${proposal.description.substring(0, 200)}...`}</div>
          <Button
            type="simple"
            label={showMore ? "show less of the description" : "show more of the description"}
            className="proposal-detail--show-more-link"
            onClick={() => setShowMore((prev) => !prev)}
          >
            {showMore ? "show less" : "show more"}
          </Button>
        </>
      ) : (
        proposal.description
      )}
      <div className="proposal-detail--heading">Solicitors</div>
      {solicitors.length === 0
        ? "No solicitors"
        : solicitors.map((solicitor) => {
            if (contacts[solicitor.contact_id]) {
              return (
                <ContactCard
                  key={solicitor.id}
                  className={solicitor.type === "PRIMARY" ? "proposal-detail--primary-contact" : ""}
                  contact={contacts[solicitor.contact_id]}
                  showNameLink={false}
                  showClassYear={false}
                >
                  {solicitor.title}
                </ContactCard>
              );
            }

            return (
              <ContactDisplayCard
                key={solicitor.id}
                className={solicitor.type === "PRIMARY" ? "proposal-detail--primary-contact" : ""}
                contact={{}}
                formattedName={solicitor.name}
                showNameLink={false}
                showClassYear={false}
              >
                {solicitor.title}
              </ContactDisplayCard>
            );
          })}
      <div className="proposal-detail--heading">Import ID</div>

      {proposal.remote_id ? <div>{proposal.remote_id}</div> : <div className="proposal-detail--light-text">N/A</div>}

      <div className="proposal-detail--heading">Last Update</div>
      <div>
        {moment(proposal.updated_at).format("MMM DD, YYYY")} (
        {proposal.update_source === "IMPORTER" ? "via import" : "via EverTrue"})
      </div>
    </>
  );
};

ProposalDetailContacts.propTypes = {
  proposal: PropTypes.object.isRequired,
  contacts: PropTypes.object.isRequired,
};

export default ProposalDetailContacts;
