import PropTypes from "prop-types";
import { Loading, ProgressBar } from "@evertrue/et-components";
import get from "lodash.get";
import ProfileCardHeader from "./profile-card-header";
import Helptip from 'components/tooltip/helptip';


import { gqlCall } from "entities/helpers/gqlCall";
import { usePromiseResolver } from "@evertrue/promise-resolver";

const gql = String.raw;
const QUERY = gql`
  query ($id: BigInt!) {
    contact(id: $id) {
      et_score {
        dds_score
      }
    }
  }`;
const tooltip_text = "The Donor Potential Score is a score from 1-100 that indicates the strength of a constituent's giving pattern. The score is calculated using gift transaction information, lifetime giving, and other data points. Scores are calculated nightly for each constituent with gift transaction information."

const DonorPotentialScoreInfo = ({ id }) =>  {

  const [dps, fetching] = usePromiseResolver(() => {
    return gqlCall(QUERY, { id: id }).then((results) => {
      return get(results, "data.contact.et_score.dds_score", 0);
    });
  }, [id]);

  return (
    <>
    {fetching ? (
      <Loading />
    ) : (
      <>
      <ProfileCardHeader
          className={"donor-potential-score--card-header-small"}
          title={"Donor Potential Score"}
        >
          <Helptip help_key="dps" body={tooltip_text}/>
        </ProfileCardHeader>
      <div className="donor-potential-score">
        <div className="donor-potential-score--score">
          { dps > 0 ? (
            <>
              <div className={"donor-potential-score--score-value"}>
                { dps } out of 100
              </div>
              <div className={"donor-potential-score--score-bar"}>
                <ProgressBar progressColor={"#73C167"} percent={dps}/>
              </div>
            </>)
            : "No gift transaction data available for this constituent"
          }
        </div>
      </div>
      </>
    )}
    </>
  );
}

DonorPotentialScoreInfo.propTypes = {
  id: PropTypes.number,
};

export default DonorPotentialScoreInfo;
