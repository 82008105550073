import _ from "underscore";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Button, Icon, Popover2 } from "@evertrue/et-components";
import { createContext, useState, useMemo, useContext, createRef, Component } from "react";
import colors from "base/colors";

// Temporary hack for launching portaled modals from menu. It's used in PageHeader so too many instances to convert
// ActionsMenu from et-components supports launching portaled modals, but it accepts an array of objects as actions
// this accepts children - so too difficult to replace for now

const ActionsMenu2ModalContext = createContext();

//Let children of this menu be able to easily close it
export const ActionMenu2ClosePopoverContext = createContext();

const ActionsMenu2ModalProvider = (props) => {
  const [modal, setModal] = useState(null);

  const value = useMemo(() => ({ setModal }), [setModal]);

  return (
    <ActionsMenu2ModalContext.Provider value={value}>
      {modal}
      {/* eslint-disable-next-line react/prop-types */}
      {props.children}
    </ActionsMenu2ModalContext.Provider>
  );
};

export const useActionsMenu2ModalContext = () => {
  const context = useContext(ActionsMenu2ModalContext);
  if (!context) {
    throw new Error(
      "useActionsMenu2ModalContext must be used within a ActionsMenu2ModalContext - or within an actionsMenu2 "
    );
  }
  return context;
};

class ActionMenu2 extends Component {
  static propTypes = {
    label: PropTypes.any,
    width: PropTypes.number,
    buttonClass: PropTypes.string,
    contentClass: PropTypes.string,
    disabled: PropTypes.bool,
    children: PropTypes.any,
  };

  static defaultProps = {
    label: "Actions...",
    position: "bottom-right-aligned",
    buttonClass: "",
  };

  triggerRef = createRef();
  contentRef = createRef();

  render() {
    const action_menu_id = _.randomKey();

    return (
      <ActionsMenu2ModalProvider>
        <Popover2
          borderColor={colors.blue300}
          wrapperClassName="action-menu--popover"
          placement="bottom-end"
          disabled={this.props.disabled}
          trigger={({ togglePopover, is_open }) => {
            return (
              <Button
                type="action"
                className={this.props.buttonClass}
                disabled={this.props.disabled}
                ref={this.triggerRef}
                aria-label="Open Actions Menu"
                aria-haspopup={true}
                aria-owns={action_menu_id}
                aria-expanded={is_open}
                onClick={togglePopover}
              >
                {this.props.label}
                <Icon icon={is_open ? "chevron-up" : "chevron-down"} size={1} />
              </Button>
            );
          }}
        >
          {({ closePopover }) => (
            <ActionMenu2ClosePopoverContext.Provider value={closePopover}>
              <div
                className={classNames(this.props.contentClass, "action-menu--content")}
                style={{ width: this.props.width }}
                id={action_menu_id}
                ref={this.contentRef}
              >
                {this.props.children}
              </div>
            </ActionMenu2ClosePopoverContext.Provider>
          )}
        </Popover2>
      </ActionsMenu2ModalProvider>
    );
  }
}

export default ActionMenu2;
