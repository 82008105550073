import moment from "moment";
import InteractionForm from "apps/interactions/components/create/interaction-form";
import { useOpenClose, Modal } from "@evertrue/et-components";
import useCallDescription from "apps/journeys/hooks/use-call-description";
import { useEffect } from "react";
import { useGate } from "components/is-gated";
import PropTypes from "prop-types";

const CallAiSummary = ({ notes, contact, task, journey, setCallEnded }) => {
  const { callDescription } = useCallDescription(notes, task.title, journey.name, contact);
  const [isInteractionModalOpen, openInteractionModal, closeInteractionModal] = useOpenClose();
  const [hasUnreleasedFeature] = useGate("unreleased_feature");

  const handleClose = () => {
    setCallEnded(false);
    closeInteractionModal();
  };

  useEffect(() => {
    if (hasUnreleasedFeature) {
      openInteractionModal();
    }
  }, [hasUnreleasedFeature, openInteractionModal]);

  return (
    <Modal isOpen={isInteractionModalOpen} closeModal={closeInteractionModal}>
      {callDescription !== "" && (
        <InteractionForm
          saveLabel="Save"
          cancelLabel="Skip"
          contact={contact}
          interaction={{
            interaction_type: "Phone Call",
            summary: task.title,
            text: callDescription,
            date_occurred: moment().valueOf(),
          }}
          requestHide={handleClose}
        />
      )}
    </Modal>
  );
};

CallAiSummary.propTypes = {
  notes: PropTypes.string,
  contact: PropTypes.object,
  task: PropTypes.object,
  journey: PropTypes.object,
  setCallEnded: PropTypes.func,
};

export default CallAiSummary;
