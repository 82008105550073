import PropTypes from "prop-types";
import FormField from "components/forms/form-field";
import CharacterCounter from "apps/interactions/components/character-counter";
import { Formik } from "formik";
import { editJourney } from "apps/journeys/utils/api-requests/modal-actions";
import { useIdentity } from "base/identity-resolver";
import { fetchJourneyGroups } from "apps/journeys/utils/api-requests/journey-index";
import { useEffect, useState } from "react";
import { CreateJourneySchema } from "../../schema/create-journey-schema";
import { Modal, ModalBody, AdvancedCombobox, ModalHeaderWithActions, TextInput } from "@evertrue/et-components";

const TYPE_OPTIONS = [
  { label: "Other", value: "OTHER" },
  { label: "Qualification", value: "QUALIFICATION" },
  { label: "Cultivation", value: "CULTIVATION" },
  { label: "Solicitation", value: "SOLICITATION" },
  { label: "Stewardship", value: "STEWARDSHIP" },
];

const journeyModalDefaults = (existingJourney = {}, journeyGroups = []) => {
  const {
    name: journeyName = "",
    objective_description: journeyDescription = "",
    journey_group_id: journeyGroupId = "",
    journey_type: journeyType = "",
  } = existingJourney;

  const selected = journeyGroups.find((group) => group.value === journeyGroupId);

  const selectedType = TYPE_OPTIONS.find((type) => type.value === journeyType);

  return {
    journeyName,
    journeyDescription,
    journeyGroupId: selected,
    journeyType: selectedType,
  };
};

const JourneyModal = ({
  isOpen,
  onClose,
  existingJourney = {},
  handleCreateJourney = () => {},
  optionalStateManagementFunction = () => {},
}) => {
  const { org } = useIdentity();
  const { id: editJourneyId } = existingJourney;
  const [journeyGroups, setJourneyGroups] = useState([]);

  const taskDefaults = journeyModalDefaults(existingJourney, journeyGroups);

  useEffect(() => {
    const getAndSetJourneyGroups = async () => {
      const journeyGroupsFromApi = await fetchJourneyGroups();

      const journeyGroupOptions = journeyGroupsFromApi.map((group) => ({
        label: group.name,
        value: group.id,
      }));
      setJourneyGroups(journeyGroupOptions);
    };

    if (isOpen) getAndSetJourneyGroups();
  }, [isOpen]);

  const handleSubmit = async ({ journeyName, journeyDescription, journeyGroupId, journeyType }) => {
    const { value: journeyGroupIdVal = null } = journeyGroupId;
    const { value: journeyTypeVal = null } = journeyType;

    const payload = {
      oid: org.id,
      id: editJourneyId,
      name: journeyName,
      objective_description: journeyDescription,
      journey_group_id: journeyGroupIdVal,
      journey_type: journeyTypeVal,
    };
    if (existingJourney.id) {
      const editedJourney = await editJourney(payload);
      optionalStateManagementFunction("editJourney", { journey: editedJourney });
      onClose();
    } else {
      delete payload.id;
      handleCreateJourney(payload);
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} closeModal={onClose}>
      <Formik
        initialValues={taskDefaults}
        validationSchema={CreateJourneySchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ handleSubmit, handleChange, setFieldValue, values, isValid }) => {
          return (
            <>
              <ModalHeaderWithActions
                title={editJourneyId ? "Edit Cadence Details" : "Create Cadence"}
                submitLabel={editJourneyId ? "Update" : "Create"}
                closeModal={onClose}
                onSubmit={handleSubmit}
                disableSubmit={!isValid}
              />
              <ModalBody scrollable={false}>
                <form>
                  <span>* Indicates required field</span>
                  <FormField label="Cadence Name *" inlineLabel={false}>
                    <TextInput
                      type="text"
                      onChange={handleChange("journeyName")}
                      name="journeyName"
                      value={values.journeyName}
                    />
                    <CharacterCounter limit={120} count={values.journeyName.length} />
                  </FormField>
                  <FormField label="Description" inlineLabel={false}>
                    <textarea
                      onChange={handleChange("journeyDescription")}
                      name="journeyDescription"
                      id="cadence-description"
                      value={values.journeyDescription}
                    />
                    <CharacterCounter limit={250} count={values.journeyDescription.length} />
                  </FormField>
                  <FormField label="Cadence Groups *" inlineLabel={false}>
                    <AdvancedCombobox
                      placeholder="Select Cadence Group..."
                      options={journeyGroups}
                      value={values.journeyGroupId}
                      name="journeyGroupId"
                      onChange={(v) => {
                        setFieldValue("journeyGroupId", v);
                      }}
                    />
                  </FormField>
                  <FormField label="Cadence Type *" inlineLabel={false}>
                    <AdvancedCombobox
                      placeholder="Select Cadence Type..."
                      options={TYPE_OPTIONS}
                      value={values.journeyType}
                      name="journeyType"
                      onChange={(v) => {
                        setFieldValue("journeyType", v);
                      }}
                    />
                  </FormField>
                </form>
              </ModalBody>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

JourneyModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  existingJourney: PropTypes.object,
  handleCreateJourney: PropTypes.func,
  optionalStateManagementFunction: PropTypes.func,
};

export default JourneyModal;
