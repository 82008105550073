module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   EverTrue = require("app")
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")

   createSource "MobileTextMeSource",
      actions:
         incrementStep: true
         resetStep: true

      api:
         incrementStep: ->
            @actions.incrementStep()
         resetStep: ->
            @actions.resetStep()
         sendSMS: (number) ->
            Api.EMS.SMS.post
               data: _.jsonStringify
                  #message_id needs 32 char. currently app key + mobile number + timestamp
                  message_id: "815e8d01be8f78a41d1c71eb652b8be124b89058b74d284c6bb752a034dbb301" + number.toString() + new Date().getTime().toString()
                  mobile_number: number
                  canned_sms: "df97bb4d-9dac-4581-8c66-157f9d4e557d"
