module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories
   LayoutSource = require("apps/layout/sources/layout-source")
   ChartContainer = require("components/charts/chart-container")
   ChartTooltip = require("components/charts/chart-tooltip")
   BarGroup = require("components/charts/bar-group")
   BarStack = require("components/charts/bar-stack")
   d3 = require("d3")


   createComponent "BarChart",
      propTypes:
         data: ReactLibs.PropTypes.array
         height: ReactLibs.PropTypes.number
         stacked: ReactLibs.PropTypes.bool
         defaultWidth: ReactLibs.PropTypes.number
         horizontal: ReactLibs.PropTypes.bool
         xFormat: ReactLibs.PropTypes.func
         yFormat: ReactLibs.PropTypes.func
         padding: ReactLibs.PropTypes.object
         onBarClick: ReactLibs.PropTypes.func

      getInitialState: ->
         grid_width: @props.defaultWidth
         grid_height: @props.height
         xScale: undefined
         yScale: undefined
         padding: @props.padding

      UNSAFE_componentWillReceiveProps: (newProps) ->
         if newProps.data != @props.data
            @setState {xScale: undefined, yScale: undefined}
            @handleTooltipHide()

      componentWillUnmount: ->
         @handleTooltipHide()
         clearInterval(@interval)

      handleGetScales: (scale_data) ->
         data = @props.data
         return if _.isEmpty(data)
         is_horizontal = @props.horizontal

         bucket_key = if is_horizontal then "y" else "x"
         value_key = if is_horizontal then "x" else "y"

         data = _.map _.groupBy(data, bucket_key), (group, key) ->
            sum = _.sum group, (item) -> item[value_key]
            if is_horizontal then {y: key, x: sum} else {x: key, y: sum}

         if is_horizontal
            bar_width = scale_data.grid_height / (data.length || 1)
            range_max = scale_data.grid_height
            value_range_max = scale_data.grid_width
            bucket_domain = _.pluck(data, bucket_key).reverse()
         else
            bar_width = scale_data.grid_width / (data.length || 1)
            range_max = scale_data.grid_width
            value_range_max = scale_data.grid_height
            bucket_domain = _.pluck(data, bucket_key)

         bucket_scale = d3.scale.ordinal()
            .domain(bucket_domain)
            .rangeRoundBands([(bar_width / 2), range_max])

         value_scale = d3.scale.linear()
            .domain([0, _.max(_.pluck(data, value_key))])
            .range([0, value_range_max])

         @setState _.extend {}, scale_data,
            xScale: if is_horizontal then value_scale else bucket_scale
            yScale: if is_horizontal then bucket_scale else value_scale

      handleGetCoords: (item) ->
         if @state.xScale && @state.yScale
            [@state.xScale?(item.x), @state.yScale?(item.y)]
         else [0, 0]

      handleTooltipShow: (item, position) ->
         if @state.tooltip_key
            LayoutSource.removeOverlay(@state.tooltip_key)

         if @props.tooltipContent
            key = _.randomKey()
            content = ChartTooltip null, @props.tooltipContent?(item)
            position = _.omit(position, "width")
            LayoutSource.overlay(content, _.extend {key: key}, position)
            @setState {tooltip_key: key}

      handleTooltipHide: (item, coords) ->
         if @state.tooltip_key
            LayoutSource.removeOverlay(@state.tooltip_key)
            @setState {tooltip_key: undefined} if @depracated_is_mounted

      render: ->
         bucket_key = if @props.horizontal then "y" else "x"
         data = _.groupBy(@props.data, bucket_key)

         ChartContainer
            className: classNames(@props.className, "area-chart")
            data: @props.data
            height: @props.height
            defaultWidth: @props.defaultWidth
            padding: @props.padding
            xFormat: @props.xFormat
            yFormat: @props.yFormat
            xTicks: if @props.horizontal then @state.xScale?.ticks?() else @state.xScale?.domain?()
            yTicks: if @props.horizontal then @state.yScale?.domain?() else @state.yScale?.ticks?()
            showXGridLines: !!@props.horizontal
            showYGridLines: !@props.horizontal
            tooltipContent: @props.tooltipContent
            tooltipData: @state.tooltip
            onSetScales: @handleGetScales
            onGetCoords: @handleGetCoords
            onMouseOut: @handleTooltipHide

            if @props.stacked
               _.map data, (group, i) =>
                  BarStack
                     key: i
                     data: group
                     xScale: @state.xScale
                     yScale: @state.yScale
                     horizontal: @props.horizontal
                     grid_height: @state.grid_height
                     onMouseOver: @handleTooltipShow
                     onMouseOut: @handleTooltipHide
                     onBarClick: @props.onBarClick
            else
               _.map data, (group, bucket) =>
                  BarGroup
                     key: bucket
                     data: group
                     bucket: bucket
                     xScale: @state.xScale
                     yScale: @state.yScale
                     horizontal: @props.horizontal
                     grid_height: @state.grid_height
                     onMouseOver: @handleTooltipShow
                     onMouseOut: @handleTooltipHide
                     onBarClick: @props.onBarClick
