import PropTypes from "prop-types";

const CareerEnrichmentItem = ({ label, value }) => {
  return (
    <tr>
      <td className="profile--enrichment--employment-item">{label}</td>
      <td className="profile--enrichment--employment-item">{value}</td>
    </tr>
  )
};

CareerEnrichmentItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
};

export default CareerEnrichmentItem;