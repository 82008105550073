import { useCallback, useState, useEffect } from "react";
import Backbone from "backbone";
import _ from "underscore";
import moment from "moment";
import AllUsersStore from "apps/users/stores/all-users-store";
import { useFluxStore } from "@evertrue/et-flux";
import Api from "entities/helpers/api";
import PageHeader from "apps/layout/components/page-header";
import ActionMenuItem from "components/controls/action-menu-item";
import {
  Loading,
  Card,
  SearchInput,
  LinkButton,
  FlexTable,
  FlexTableSortHeaderCell,
  Icon,
  Avatar,
} from "@evertrue/et-components";
import BulkListController from "apps/lists/controllers/bulk-list-controller";
import ListContactSource from "apps/lists/sources/list-contact-source.coffee";

const NUM_LISTS = 4;

const columns = (handleSort, sortReverse, users) => [
  {
    key: 1,
    header: (
      <FlexTableSortHeaderCell onClick={() => handleSort(!sortReverse)} sortOrder={sortReverse} isSorted={true}>
        List Name
      </FlexTableSortHeaderCell>
    ),
    accessor: (i) => {
      return (
        <>
          {i.private ? (
            <Icon icon="lock" className="lists-page--icon" />
          ) : (
            <Icon icon="group" className="lists-page--icon" />
          )}
          <LinkButton title={i.name} onClick={() => Backbone.history.navigate(`list/${i.id}`, true)}>
            {i.name}
          </LinkButton>
        </>
      );
    },
  },

  {
    key: 2,
    header: "Owner",
    accessor: (i) => {
      const user = users[i.user_id];
      return (
        <div className="lists-page--avatar">
          <Avatar name={user ? user.name : "User's avatar"} width={30} url={user && user.avatar} />
          <div>{user && user.name}</div>
        </div>
      );
    },
  },
  {
    key: 3,
    header: "Last Updated",
    accessor: (i) => moment(i.updated_at).format("MMMM Do, YYYY"),
  },
];

/*
  Function for grouping list and featured Lists
  */
const groupLists = (lists) => {
  let listArray = _.map(lists, (seg) => ({
    label: seg.name,
    value: seg.id,
    type: "saved_search",
  }));

  return listArray;
};

const ListsPageController = () => {
  const [loading, setLoading] = useState(true);
  const [lists, setLists] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [sortOrder, setSortOrder] = useState(true);
  const [selectableLists, setSelectableLists] = useState(lists);
  const [recentlyViewed, setRecentlyViewed] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);

  const mapState = useCallback(() => {
    return {
      users: AllUsersStore.getAllGivingTreeUsers(),
    };
  }, []);

  const { users } = useFluxStore(AllUsersStore, mapState);

  const getList = () => {
    setLoading(true);
    Api.CONTACTS.LISTS.get({
      params: { limit: 1000 },
    }).then((resp) => {
      const lists = _.where(resp.items, { type: "user_list" });
      setLists(lists);
      setLoading(false);
    });
  };

  /*
   Effect for search change
  */
  useEffect(() => {
    _.debounce(setSelectableLists(_.filter(lists, (list) => _.searchCompare(list.name, searchText))), 300);
  }, [searchText, lists]);

  useEffect(() => {}, [sortOrder]);

  /*
   Gets recently viewed list
  */
  useEffect(() => {
    Api.DNA.SETTING.get({
      urlArgs: { setting_key: "GT_recent_lists" },
      disableAlerts: true,
      params: { user_id: "me", oid: null },
    }).then((resp) => {
      const recent = _.uniq(resp.settings.GT_recent_lists.value.lists);
      const groupedLists = groupLists(lists);
      let ordered;
      if (!_.isEmpty(recent)) {
        ordered = _.compact(
          _.map(recent, (id) => {
            return _.findWhere(groupedLists, { value: id });
          })
        );
        if (ordered.length < NUM_LISTS) {
          let remaining = _.filter(groupedLists, (seg) => {
            return !_.contains(recent, seg.value);
          });
          remaining = _.sortBy(remaining, "label");
          remaining = _.first(remaining, NUM_LISTS - ordered.length);
          ordered = _.compact(ordered.concat(remaining));
        }
        setRecentlyViewed(ordered);
      } else setRecentlyViewed(_.sortBy(groupedLists, "label"));
    });
  }, [lists]);

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    ListContactSource.resetTablePagination();
  }, []);

  const listsPagesActions = () => (
      <ActionMenuItem icon="add" title="Manage Facebook Accounts" onClick={() => setShowAddModal(true)}>
        Create List
      </ActionMenuItem>
  );

  let listsSorted = _.sortBy(selectableLists, (list) => list.name.toLowerCase());
  if (!sortOrder) {
    listsSorted = listsSorted.reverse();
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          {showAddModal ? (
            <BulkListController remove={() => setShowAddModal(false)} onComplete={() => _.delay(getList, 2000)} />
          ) : null}
          <PageHeader title="Lists" subtitle={`${listsSorted.length} Lists`} actions={listsPagesActions()} />
          <div className="lists-page-controller">
            <Card className="lists-page--table">
              <div className="lists-page--search">
                <SearchInput value={searchText} onChange={(val) => setSearchText(val)} />
              </div>
              <FlexTable caption="Lists" data={listsSorted} columns={columns(setSortOrder, sortOrder, users)} />
            </Card>
            <div className="lists-page--side">
              <Card headingText="Recently Viewed Lists" className="lists-page--recent" padding="16px">
                <div className="lists-page--cardbody">
                  {recentlyViewed.map((list) => (
                    <LinkButton
                      className="lists-page--recent-item"
                      key={list.value}
                      onClick={() => Backbone.history.navigate(`list/${list.value}`, true)}
                      title={list.label}
                    >
                      {list.label}
                    </LinkButton>
                  ))}
                </div>
              </Card>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ListsPageController;
