module.exports = do ->
   _ = require("underscore").default
   Decorator = require("clientDecorator")
   {createStore} = require("@evertrue/et-flux")
   MeetingsSource = require("apps/my-trips/sources/meetings-source")
   TripContactsStore = require("apps/my-trips/stores/trip-contacts-store")
   TripContactsSource = require("apps/my-trips/sources/trip-contacts-source")
   MyTripsStore = require("apps/my-trips/stores/my-trips-store")
   AllUsersStore = require("apps/users/stores/all-users-store").default
   AllUsersSource = require("apps/users/sources/all-users-source")
   SocketSource = require("apps/notifications/sources/socket-source")

   _debounced_meeting_fetch =  _.debounce(MeetingsSource.fetchMeetingsForTrip, 300, true)
   _debounced_contacts_fetch =  _.debounce(TripContactsSource.fetchContactsForTrip, 300, true)


   # Meetings in this context include "meetings" and "timeblocks"
   createStore "MeetingsStore",
      getInitialState: ->
         meetings: {}
         is_loading: false

      firstListenerDidMount: ->
         SocketSource.bindUser("meeting_added")
         SocketSource.bindUser("meeting_updated")
         SocketSource.bindUser("meeting_deleted")

      lastListenerWillUnmount: ->
         SocketSource.unbindUser("meeting_added")
         SocketSource.unbindUser("meeting_updated")
         SocketSource.unbindUser("meeting_deleted")

      registerActions: ->
         @on MeetingsSource.actions.loading, @respondToLoading
         @on MeetingsSource.actions.fetchedMeetingsForTrip, @respondToFetch
         @on MeetingsSource.actions.savedMeeting, @respondToSaved
         @on MeetingsSource.actions.deletedMeeting, @respondToDelete

         @on SocketSource.actions.meeting_added, => @respondToSocketChange("add")
         @on SocketSource.actions.meeting_updated, => @respondToSocketChange("update")
         @on SocketSource.actions.meeting_deleted, => @respondToSocketChange("delete")

         @on TripContactsSource.actions.fetchedContactsForTrip, => @trigger()
         @on AllUsersSource.actions.changedUsers, => @trigger()

      respondToLoading: (is_loading) ->
         @setState {loading: is_loading}

      respondToFetch: (trip_id, meetings) ->
         cached_meetings = _.cloneData(@getState("meetings"))
         _.each meetings.items, (meeting) ->
            cached_meetings[meeting.id] = Decorator.MyTrips.parseMeeting(meeting)
         @setState {meetings: cached_meetings}

      respondToSaved: (meeting) ->
         meetings = _.cloneData(@getState("meetings"))
         updated_meeting = meetings[meeting.id]

         if updated_meeting
            _.extend updated_meeting, {location: {}}, meeting
         else meetings[meeting.id] = Decorator.MyTrips.parseMeeting(meeting)
         @setState {meetings: meetings}

      respondToDelete: (meeting_id) ->
         meetings = _.cloneData(@getState("meetings"))
         meetings = _.omit(meetings, meeting_id)
         @setState {meetings: meetings}

      respondToSocketChange: (type) ->
         selected_trip = MyTripsStore.getSelectedTrip()
         if selected_trip
            _debounced_meeting_fetch(selected_trip)
            _debounced_contacts_fetch(selected_trip)

      formatMeetingObject: (meeting) ->
         _.extend {}, meeting,
            contacts: @getContactsForMeeting(meeting.id)
            solicitors: @getSolicitorsForMeeting(meeting.id)

      api:
         getAll: ->
            _.groupBy @getState("meetings"), "trip_id"

         getForTrip: (trip_id) ->
            _.compact _.map @getState("meetings"), (meeting) =>
               if meeting.trip_id == trip_id
                  @formatMeetingObject(meeting)

         getLoading: ->
            @getState("loading")

         getMeeting: (meeting_id) ->
            @getState("meetings")[meeting_id]

         getContactsForMeeting: (meeting_id) ->
            meeting = @getMeeting(meeting_id)
            _.compact _.map meeting?.contact_ids, (contact_id) ->
               TripContactsStore.getTripContactById(contact_id)

         getSolicitorsForMeeting: (meeting_id) ->
            meeting = @getMeeting(meeting_id)
            _.compact _.map meeting?.solicitor_user_ids, (user_id) ->
               AllUsersStore.getUser(user_id)

         getContactCountForTrip: (trip_id) ->
            meetings = @getForTrip(trip_id)
            _.size _.uniq _.flatten _.pluck(meetings, "contact_ids")

         getBookedContacts: (trip_id) ->
            meetings = @getForTrip(trip_id)
            contacts = _.uniq _.flatten _.pluck(meetings, "contact_ids")
            _.compact _.map contacts, (contact_id) ->
               TripContactsStore.getTripContactById(contact_id)

         getUnconfirmedContacts: (trip_id) ->
            booked = _.pluck(@getBookedContacts(trip_id), "id")
            all = TripContactsStore.getContactsForTrip(trip_id)
            _.filter all, (contact) -> !_.contains(booked, contact.id)

         getMeetingsForContacts: (trip_id) ->
            meetings = @getForTrip(trip_id)
            contacts = TripContactsStore.getContactsForTrip(trip_id)
            meetings_by_contacts_map = {}
            _.each contacts, (contact) ->
               meetings_by_contacts_map[contact.id] = _.filter meetings, (meeting) ->
                  _.find meeting.contacts, (meeting_contact) -> meeting_contact.id == contact.id

            meetings_by_contacts_map
