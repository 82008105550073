import { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import numeral from "numeral";
import _ from "underscore";
import { Button, Icon, NumericInput, AdvancedCombobox, TextInput, BooleanRadio } from "@evertrue/et-components";
import CalendarPickerDropdown from "components/controls/calendars/calendar-picker-dropdown";
import { formatDateToUnixWithCurrentTime } from "base/new-utils";

const ProposalCustomField = ({
  onFormChange = () => {},
  customFields = [],
  field = {},
  currentFieldValue = "",
  disabled = false,
  label = "",
}) => {
  const [editingCurrency, setEditingCurrency] = useState(false);

  const getUpdatedCustomFields = (changeVal) => {
    if (changeVal === "" || changeVal === null) {
      return customFields.filter((customField) => customField.custom_field_property_id !== field.id);
    }

    let fieldExists = false;
    const existingFields = customFields.map((customField) => {
      if (customField.custom_field_property_id === field.id) {
        fieldExists = true;
        return { custom_field_property_id: field.id, value: changeVal };
      }

      return customField;
    });

    return fieldExists ? existingFields : [...customFields, { custom_field_property_id: field.id, value: changeVal }];
  };

  const handleChange = (changeVal) => onFormChange("custom_fields", getUpdatedCustomFields(changeVal));

  const clearButton = (
    <Button
      type="simple"
      onClick={() => {
        onFormChange(
          "custom_fields",
          customFields.filter((custom_field) => {
            return custom_field.custom_field_property_id !== field.id;
          })
        );
      }}
      label="Clear field"
    >
      <Icon icon="cancel" className="proposal-form--cancel" title="Clear field" size={1} />
    </Button>
  );

  switch (field.data_type) {
    case "LONG":
      return (
        <div className="proposal-form--form-field-wide">
          <NumericInput
            required={field.required}
            aria-label={label}
            disabled={disabled}
            placeholder="0"
            value={currentFieldValue}
            maskType="number"
            onChange={(val) => {
              const value = isNaN(val) ? null : val;
              handleChange(value);
            }}
          />
        </div>
      );

    case "DATE":
      let dateValue = null;

      if (currentFieldValue) {
        dateValue = _.isString(currentFieldValue) ? _.toNumber(currentFieldValue) : currentFieldValue;

        // if date was saved in seconds then convert to milliseconds
        if (dateValue.toString().length < 13) {
          dateValue = dateValue * 1000;
        }

        dateValue = moment(dateValue).format("YYYY-MM-DD");
      }

      return (
        <div className="proposal-form--cancel-wrapper">
          <CalendarPickerDropdown
            required={field.required}
            label={label}
            className="proposal-form--form-field-wide"
            disabled={disabled}
            value={dateValue}
            onChange={(val) => {
              const date = !val ? "" : formatDateToUnixWithCurrentTime(val);
              if (date || date === "") handleChange(date);
            }}
          />
          {dateValue ? clearButton : undefined}
        </div>
      );

    case "DOUBLE":
      return (
        <div className="proposal-form--form-field-wide">
          <NumericInput
            required={field.required}
            aria-label={label}
            disabled={disabled}
            placeholder="0"
            value={
              editingCurrency ? currentFieldValue : currentFieldValue ? numeral(currentFieldValue).format("0.00") : null
            }
            maskType="currency"
            allowDecimal={true}
            onChange={(val) => {
              setEditingCurrency(true);
              handleChange(val);
            }}
            showIcon={true}
          />
        </div>
      );

    case "STRING":
      if (field.ui_control_type === "DROPDOWN") {
        const activeValues = _.filter(field.values, (value) => !!value.active);
        const sortedActiveValues = _.sortBy(activeValues, "sort_order");
        return (
          <div className="proposal-form--cancel-wrapper">
            <AdvancedCombobox
              label={label}
              required={field.required}
              className="proposal-form--form-field-wide"
              disabled={disabled}
              title={label}
              value={_.findWhere(sortedActiveValues, { value: currentFieldValue })}
              options={_.map(sortedActiveValues, (t) => {
                return { label: t.value, value: t.value };
              })}
              onChange={(change_val) => handleChange(change_val.value)}
              searchable={sortedActiveValues.length >= 50}
            />
            {currentFieldValue ? clearButton : undefined}
          </div>
        );
      } else {
        return (
          <TextInput
            required={field.required}
            aria-label={label}
            className="proposal-form--form-field-wide"
            value={currentFieldValue}
            onChange={handleChange}
            maxLength={500}
          />
        );
      }

    case "BOOLEAN":
      let radioValue = null;

      if (_.isString(currentFieldValue)) {
        if (currentFieldValue !== "") {
          radioValue = JSON.parse(currentFieldValue);
        }
      } else {
        radioValue = currentFieldValue;
      }

      return (
        <div className="proposal-form--cancel-wrapper">
          <BooleanRadio
            required={field.required}
            disabled={disabled}
            name="BooleanRadio"
            onChange={(name, bool) => handleChange(bool)}
            value={radioValue}
          />
          {currentFieldValue === true || currentFieldValue === false ? clearButton : undefined}
        </div>
      );
    default:
      return null;
  }
};

ProposalCustomField.propTypes = {
  onFormChange: PropTypes.func,
  customFields: PropTypes.array,
  field: PropTypes.object,
  currentFieldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  disabled: PropTypes.bool,
  label: PropTypes.string,
};

export default ProposalCustomField;
