import { useState, useEffect } from "react";
import _ from "underscore";
import API from "entities/helpers/api";
import EverTrue from "app";
import { ModalBody, ModalHeaderWithActions, Loading, Button } from "@evertrue/et-components";
import { usePromiseResolver } from "@evertrue/promise-resolver";
import { useFluxStore } from "@evertrue/et-flux";
import DNAStore from "base/dna/dna-store";
import useProposalStages from "apps/proposals/hooks/use-proposal-stages";
import PropTypes from "prop-types";
import Decorator from "@evertrue/client-decorator";
import { parseProposal, validateProposal } from "apps/proposals/components/proposal-form-utils";
import ProposalFormDetails from "apps/proposals/components/proposal-form-details";
import ProposalFormAmounts from "apps/proposals/components/proposal-form-amounts";
import ProposalFormCustomFields from "apps/proposals/components/proposal-form-custom-fields";
import ProposalFormSolicitors from "apps/proposals/components/proposal-form-solicitors";
import ProposalFormDesignations from "apps/proposals/components/proposal-form-designations";
import FeatureStore from "apps/layout/stores/feature-store";
import useSolicitorTitles from "../hooks/use-solicitor-titles";
import useProposalTypes from "../hooks/use-proposal-types";
import useProposalDesignations from "../hooks/use-proposal-designations";

const featureStoreMapState = () => {
  return {
    hasCustom: FeatureStore.hasFeature("proposal_custom_fields"),
    hasProposalReporting: FeatureStore.hasFeature("proposal_reported"),
  };
};

const DNAStoreMapState = () => {
  return {
    solicitorLimit: 1 + _.toNumber(DNAStore.getByKey("proposal-export-fixed-number-of-solicitors")),
    additionalContactLimit: _.toNumber(DNAStore.getByKey("proposal-export-fixed-number-of-contacts")),
    designationLimit: _.toNumber(DNAStore.getByKey("proposal-max-designations")),
    standardFieldsConfig: DNAStore.getByKeyWithDefault({
      key: "proposal_standard_fields_config",
      options: {
        max_length: (a, b) => a < b,
      },
    }),
  };
};

export const ProposalForm = ({ proposal, primaryContactId, setProposal, closeModal, afterCreate }) => {
  const [loadingProposal, setLoadingProposal] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [solicitorTitles, loadingTitles] = useSolicitorTitles();
  const [proposalStages, loadingStages] = useProposalStages();
  const [proposalTypes, loadingTypes] = useProposalTypes();
  const [proposalDesignations, loadingDesignations] = useProposalDesignations();
  const [editedProposal, setEditedProposal] = useState(() => parseProposal(proposal));
  const { solicitorLimit, additionalContactLimit, designationLimit, standardFieldsConfig } = useFluxStore(
    DNAStore,
    DNAStoreMapState
  );
  const { hasCustom, hasProposalReporting } = useFluxStore(FeatureStore, featureStoreMapState);
  const [customFields = []] = usePromiseResolver(hasCustom ? () => API.UGC.PROPOSAL_CUSTOM_FIELDS.get() : _.noop, []);
  const disableSave = validateProposal(editedProposal, customFields, standardFieldsConfig);
  const loading = loadingProposal || loadingTitles || loadingStages || loadingTypes || loadingDesignations;

  const onFormChange = (key, value) => {
    setEditedProposal((prev) => ({ ...prev, [key]: value }));
  };

  useEffect(() => {
    let mounted = true;

    const fetchContacts = async () => {
      const ids =
        proposal && proposal.contacts
          ? proposal.contacts.filter((contact) => contact.contact_id).map((contact) => contact.contact_id)
          : [primaryContactId];

      try {
        const contacts = await API.CONTACTS.SEARCH.post({
          data: JSON.stringify({ must: [{ id: { in: ids } }] }),
          params: { limit: 250 },
        });

        const formattedContacts = contacts.items.map((contact) => {
          const parsedContact = Decorator.Contacts.parse(contact);
          return { ...parsedContact, name: Decorator.Contacts.getFullName(parsedContact) };
        });

        if (mounted) {
          onFormChange(
            "contacts",
            formattedContacts.filter((contact) => contact.id !== primaryContactId)
          );
          onFormChange(
            "primary_contact",
            formattedContacts.find((contact) => contact.id === primaryContactId)
          );
          setLoadingProposal(false);
        }
      } catch {
        if (mounted) {
          setLoadingProposal(false);
          setHasError(true);
        }
      }
    };

    if (mounted) {
      setLoadingProposal(true);
      fetchContacts();
    }

    return () => (mounted = false);
  }, [proposal, primaryContactId]);

  const saveProposal = () => {
    setLoadingProposal(true);

    const { primary_contact, proposal_id, custom_fields, ...restOfProposal } = editedProposal;

    const formattedProposal = {
      ...restOfProposal,
      custom_fields: custom_fields.map((field) => ({
        custom_field_property_id: field.custom_field_property_id,
        value: field.value,
      })),
      contacts: [
        { contact_id: primary_contact.id, type: "PRIMARY" },
        ...editedProposal.contacts.map((contact) => ({
          contact_id: contact.id,
          type: "SECONDARY",
        })),
      ],
    };

    if (proposal_id) {
      API.UGC.PROPOSAL.put({
        urlExtend: `/${proposal_id}`,
        urlArgs: { proposal_id },
        data: _.jsonStringify(formattedProposal),
      })
        .then((updatedProposal) => {
          EverTrue.Alert.success("Proposal updated successfully");
          EverTrue.track.set("proposal_action", { type: "edit_proposal" });
          setProposal(updatedProposal);
          setLoadingProposal(false);
          closeModal();
        })
        .catch(() => {
          EverTrue.Alert.error("Proposal update failed");
          setLoadingProposal(false);
        });
    } else {
      API.UGC.PROPOSAL.post({
        data: _.jsonStringify(formattedProposal),
      })
        .then(() => {
          EverTrue.Alert.success("Proposal created successfully");
          EverTrue.track.set("proposal_action", { type: "create_proposal" });
          setLoadingProposal(false);
          closeModal();
          _.delay(() => {
            if (afterCreate) afterCreate(); // Delay to make sure save finishes
          }, 1000);
        })
        .catch(() => {
          EverTrue.Alert.error("Proposal creation failed");
          setLoadingProposal(false);
        });
    }
  };

  if (hasError) {
    return (
      <div className="proposal-form--error">
        <h3>Oops, there was an error loading the proposal, please close and try again.</h3>
        <Button onClick={closeModal} className="proposal-form--error-button">
          Close
        </Button>
      </div>
    );
  }

  return (
    <>
      <ModalHeaderWithActions
        title={editedProposal.proposal_id ? "Edit Proposal" : "Add Proposal"}
        closeModal={closeModal}
        onSubmit={saveProposal}
        disableSubmit={disableSave}
      />
      <ModalBody>
        {loading && <Loading />}
        <ProposalFormDetails
          onFormChange={onFormChange}
          proposal={editedProposal}
          additionalContactLimit={additionalContactLimit}
          proposalTypes={proposalTypes}
          standardFieldsConfig={standardFieldsConfig}
        />
        <ProposalFormSolicitors
          onFormChange={onFormChange}
          proposal={editedProposal}
          solicitorLimit={solicitorLimit}
          solicitorTitles={solicitorTitles}
          standardFieldsConfig={standardFieldsConfig}
        />
        <ProposalFormAmounts
          onFormChange={onFormChange}
          proposal={editedProposal}
          proposalStages={proposalStages}
          hasProposalReporting={hasProposalReporting}
          standardFieldsConfig={standardFieldsConfig}
        />
        <ProposalFormDesignations
          onFormChange={onFormChange}
          proposal={editedProposal}
          designationLimit={designationLimit}
          designations={proposalDesignations}
          standardFieldsConfig={standardFieldsConfig}
        />
        {!!customFields.length && (
          <ProposalFormCustomFields onFormChange={onFormChange} proposal={editedProposal} customFields={customFields} />
        )}
      </ModalBody>
    </>
  );
};

ProposalForm.propTypes = {
  primaryContactId: PropTypes.number.isRequired,
  closeModal: PropTypes.func.isRequired,
  proposal: PropTypes.object,
  setProposal: PropTypes.func,
  afterCreate: PropTypes.func,
};

export default ProposalForm;
