module.exports = do ->
   _ = require("underscore").default
   {createStore} = require("@evertrue/et-flux")
   LayoutSource = require("apps/layout/sources/layout-source")
   WindowSource = require("apps/layout/sources/window-source")


   createStore "LayoutStore",
      getInitialState: ->
         page_component: undefined
         banner: undefined
         nav_is_collapsed: false
         nav_is_done_animating: false
         remounting: false

      registerActions: ->
         @on LayoutSource.actions.mountContent, (component) ->
            @setState
               page_component: component
               remounting: false

         @on LayoutSource.actions.unmountContent, ->
            @setState {page_component: undefined}

         @on LayoutSource.actions.mountBanner, (component) ->
            @setState {banner: component}

         @on LayoutSource.actions.unmountBanner, ->
            @setState {banner: undefined}

         @on LayoutSource.actions.remount, ->
            if component = _.clone @getState("page_component")
               @setState {remounting: true}
               _.delay (-> LayoutSource.mountContent(component)), 100

         @on LayoutSource.actions.toggleNav, ->
            new_state = !@getState("nav_is_collapsed")
            @setState
               nav_is_collapsed: new_state
               nav_is_done_animating: false

            LayoutSource.saveNavState(new_state)
            _.delay (-> LayoutSource.endNavAnimation()), 200

         @on LayoutSource.actions.endNavAnimation, ->
            @setState {nav_is_done_animating: true}
            _.defer (-> WindowSource.resize())

         @on LayoutSource.actions.fetchedNavState, (nav_is_collapsed) ->
            @setState {nav_is_collapsed: !!nav_is_collapsed}
            _.delay (-> LayoutSource.endNavAnimation()), 200

      api:
         getPageComponent: ->
            @getState("page_component")

         getBanner: ->
            @getState("banner")

         getNavState: ->
            @getState("nav_is_collapsed")

         getNavAnimating: ->
            @getState("nav_is_done_animating")

         getRemounting: ->
            @getState("remounting")
