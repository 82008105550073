import InteractionStore from "apps/interactions/stores/interaction-store";
import InteractionListItem from "apps/interactions/components/interaction-list-item";
import { PropTypes } from "base/react-libs";

const InteractionSnippetCard = ({ interactionId = 0, highlight, showSummary, limit }) => {
  const renderInteraction = InteractionStore.getInteraction(interactionId) || {};

  return (
    <div className="interaction-snippet-card">
      <InteractionListItem
        interaction={renderInteraction}
        highligh={highlight}
        snippetLimit={limit}
        showLabels={false}
        showMenu={false}
        showSummary={showSummary}
        showDetails={false}
        showMeta={false}
      />
    </div>
  );
};

InteractionSnippetCard.propTypes = {
  interactionId: PropTypes.number.isRequired,
  highlight: PropTypes.string,
  showSummary: PropTypes.bool,
  limit: PropTypes.number,
};

export default InteractionSnippetCard;
