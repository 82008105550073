import _ from "underscore";

//Helper for changeFocus
export const getActiveIndex = (nodes) => _.indexOf(nodes, window.document.activeElement);

//Increment prop is -1 to move back and 1 to move forward through the Accessibility tree
const changeFocus = (node, increment) => {
  const nodes = getAllFocusable(node);
  if (nodes.length > 1) {
    const index = getActiveIndex(nodes);
    if (containsActiveElement(node)) {
      if (nodes[index + increment]) {
        nodes[index + increment].focus();
      }
    }
  }
};

const changeFocusWithWrap = (node, increment) => {
  const nodes = getAllFocusable(node);
  if (nodes.length > 1) {
    const index = getActiveIndex(nodes);
    if (containsActiveElement(node)) {
      if (nodes[index + increment]) {
        nodes[index + increment].focus();
      } else {
        increment === 1 ? nodes[0].focus() : nodes[nodes.length - 1].focus();
      }
    }
  }
};

export const containsActiveElement = (node = window.document) => {
  const active = window.document.activeElement;
  return node !== active && node.contains(active);
};

// A list of all elements in a DOM node that are natively focusable
export const getAllFocusable = (node = window.document) => {
  return node.querySelectorAll(
    "button:not([disabled]),[href]:not([disabled]), input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex='-1'])"
  );
};

//Move focus to the first element in main
export const moveFocusToMain = () => {
  const main = document.getElementsByTagName("main")[0];
  const h1 = document.getElementsByTagName("h1")[0];
  const getAllFocusableMain = getAllFocusable(main)[0];
  if (h1) {
    document.getElementsByTagName("h1")[0].focus();
  } else if (getAllFocusableMain) {
    getAllFocusableMain.focus();
  } else {
    document.getElementsByTagName("main")[0].focus();
  }
};

export const sendFocus = (node) => {
  node && node.focus();
};

export const nextFocus = (node) => {
  changeFocus(node, 1);
};

export const prevFocus = (node) => {
  changeFocus(node, -1);
};

export const nextFocusWithWrap = (node) => {
  changeFocusWithWrap(node, 1);
};

export const prevFocusWithWrap = (node) => {
  changeFocusWithWrap(node, -1);
};
