import EverTrue from "app";
import PropTypes from "prop-types";
import { useGate } from "components/is-gated";
import NavigationSubItem from "apps/layout/components/navigation-sub-item";

const NavigationHomepageLink = ({ active_path = "" }) => {
  const [enabled, loaded] = useGate("signal");

  if (!loaded) return null;
  if (enabled) {
    return (
      <NavigationSubItem
        icon="home"
        label="Home"
        isActive={!!active_path.match(new RegExp(`home.*`))}
        onClick={() => {
          EverTrue.track.set("tab_view", { type: "homepage" });
          EverTrue.Navigator("/home", true);
        }}
      />
    );
  } else {
    return null;
  }
};

NavigationHomepageLink.propTypes = {
  active_path: PropTypes.string,
};

export default NavigationHomepageLink;
