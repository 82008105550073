module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   {createStore} = require("@evertrue/et-flux")
   Decorator = require("clientDecorator")
   PaginationUtils = require("mixins/pagination-utils")
   ListActivitySource = require("apps/lists/sources/list-activity-source")
   ListSource = require("apps/lists/sources/list-source")
   ListStore = require("apps/lists/stores/list-store")
   CollaboratorStore = require("apps/lists/stores/collaborator-store")
   CollaboratorSource = require("apps/lists/sources/collaborator-source")
   AllUsersStore = require("apps/users/stores/all-users-store").default
   AllUsersSource = require("apps/users/sources/all-users-source")


   createStore "ListActivityStore",
      getInitialState: ->
         list_activity: {}
         list_notes: {}
         list_created_activity: {}
         loading: false
         contacts: {}
         page: 1
         collapsed: false

      firstListenerDidMount: ->
         ListActivitySource.fetchCollapsedState()

      registerActions: ->
         @on ListActivitySource.actions.collapsed, @respondToCollapsed
         @on ListActivitySource.actions.loadingActivity, @respondToLoading
         @on ListActivitySource.actions.fetchedActivity, @respondToFetchedActivity
         @on ListActivitySource.actions.fetchedNoteActivity, @respondToNoteActivity
         @on ListActivitySource.actions.fetchedContacts, @respondToContacts

         @on ListActivitySource.actions.savedNote, @respondToSavedNote
         @on ListActivitySource.actions.deletedNote, @respondToDeletedNote

         @on ListSource.actions.fetchedLists, @respondToFetchedLists

      respondToCollapsed: (is_collapsed) ->
         @setState {collapsed: is_collapsed}

      respondToLoading: (is_loading) ->
         @setState {loading: is_loading}

      respondToFetchedActivity: (list_id, activity) ->
         target_data = Decorator.Lists.getTargetData(activity)
         @downloadActivityData(target_data.contact, _.flatten([target_data.users, target_data.collaborator]))

         current_activity = @getState("list_activity")
         list_data = _.clone(current_activity?[list_id]) || {}

         if _.isEmpty(list_data)
            # Initial Load
            list_data = activity
         else if list_data.offset >= activity.offset
            # Polling & Paged
            _.each activity.items, (item) ->
               current_list = _.findWhere list_data.items, {id: item.id}
               if current_list then _.extend current_list, item
               else list_data.items?.push(item)
         else
            # Pagination
            _.extend list_data,
               offset: activity.offset
               items: _.sortBy _.flatten([list_data.items, activity.items]), "created_at"

         current_activity[list_id] = list_data
         @setState {list_activity: current_activity}

      respondToNoteActivity: (list_id, notes) ->
         @downloadNoteData(notes)
         current_notes = @getState("list_notes")
         note_data = _.clone(current_notes?[list_id]) || {}

         if _.isEmpty(note_data)
            # Initial Load
            note_data = notes
         else if note_data.offset >= notes.offset
            # Polling & Paged
            _.each notes.items, (note) ->
               current_note = _.findWhere note_data.items, {id: note.id}
               if current_note then _.extend current_note, note
               else note_data.items.push(note)
            # Remove if deleted
            note_data.items = _.filter note_data.items, (item) ->
               _.findWhere notes.items, {id: item.id}
         else
            # Pagination
            _.extend note_data,
               offset: notes.offset
               items: _.sortBy _.push(note_data.items, notes.items), "created_at"

         current_notes[list_id] = note_data
         @setState {list_notes: current_notes}

      respondToContacts: (contacts) ->
         cached_contacts = _.clone @getState("contacts")
         _.each contacts.items, (contact) ->
            cached_contacts[contact.id] = contact
         @setState {contacts: cached_contacts}

      respondToSavedNote: (list_id, note) ->
         notes = @getState("list_notes")
         list_notes = notes?[list_id]
         saved_note = _.findWhere list_notes.items, {id: note.id}
         if saved_note then _.extend saved_note, note
         else list_notes.items = _.flatten [[note], list_notes.items]
         @setState {list_notes: notes}

         @addMentionedCollaborators(list_id, note)

      respondToDeletedNote: (list_id, deleted_id) ->
         notes = @getState("list_notes")
         list_notes = notes?[list_id]
         list_notes.items = _.filter list_notes.items, (item) ->
            item.id != deleted_id
         @setState {list_notes: notes}

      respondToFetchedLists: (lists) ->
         created_activity = {}
         _.each lists.items, (list) ->
            if list.type == "user_list"
               created_activity[list.id] =
                  target: "CREATED"
                  target_id: list.user_id
                  created_at: list.created_at
                  name: list.name
                  id: list.id

         @setState {list_created_activity: created_activity}

      # Add users that were mentioned but are not current collaborators
      addMentionedCollaborators: (list_id, note) ->
         list_owner = ListStore.getList(list_id)?.user_id
         collaborators = _.flatten [list_owner, CollaboratorStore.getUserIds(list_id)]
         user_mentions = _.compact _.map note.mentions, (mention) ->
            _.toNumber(mention.mentioned_user_id)

         new_collaborators = _.difference(user_mentions, collaborators)
         unless _.isEmpty(new_collaborators)
            CollaboratorSource.share list_id,
               skip_notification: true
               user_ids: new_collaborators

      downloadActivityData: (contacts, users) ->
         cached_contacts = _.map _.keys(@getState("contacts")), _.toNumber
         new_contacts = _.difference _.compact(_.uniq(contacts)), cached_contacts
         ListActivitySource.fetchContacts(new_contacts) unless _.isEmpty(new_contacts)
         AllUsersSource.fetchUsers(users)

      downloadNoteData: (notes) ->
         contact_ids = []
         user_ids = []
         # For each note get user and contact ids
         _.each notes?.items, (note) ->
            # Add user mention ids
            _.each note.user_mention, (mention) ->
               user_ids.push(mention.user_id) if mention.user_id
            # Add contact mention ids
            _.each note.contact_mention, (mention) ->
               contact_ids.push(mention.contact_id) if mention.contact_id
            # Add the user id of the note creator
            user_ids.push(note.creator_user_id)
         @downloadActivityData(contact_ids, user_ids)

      api:
         getActivity: (list_id) ->
            list_activity = @getState("list_activity")?[list_id]
            note_activity = @getState("list_notes")?[list_id]
            created_activity = @getState("list_created_activity")?[list_id]

            unless _.isEmpty list_activity
               parsed_activity = Decorator.Lists.getAggregation(list_activity, note_activity)

            users = AllUsersStore.getAllUsers()
            contacts = @getState("contacts")

            _getTarget = (item) ->
               if item.target == "COLLABORATOR"
                  users[item.target_id]
               else contacts[item.target_id]

            # Format activites from list activity data
            activities = _.map parsed_activity?.items, (list_item) ->
               if list_item.type == "ACTIVITY"
                  activity = list_item.value
                  _.extend {}, activity,
                     user: users[activity.user_id] || AllUsersStore.getEmptyStateUser()
                     target_data: _.clone _getTarget(activity)
                     activities: _.map activity.activities, (evnt) ->
                        _.extend {}, evnt,
                           target_data: _.clone _getTarget(evnt)
               else if list_item.type == "NOTE"
                  note = list_item.value
                  mentions = _.flatten([note.contact_mention, note.user_mention])

                  _.extend {}, note,
                     creator: users[note.creator_user_id] || AllUsersStore.getEmptyStateUser()
                     mentions: _.map mentions, (mention = {}) ->
                        if mention.user_id
                           _.extend {}, mention, data: users[mention.user_id]
                        else if mention.contact_id
                           _.extend {}, mention, data: contacts[mention.contact_id]

            # Add created_at activity if last page
            if PaginationUtils.isFinalPage(list_activity) && created_activity
               created = _.extend {}, created_activity,
                  user: users[created_activity.target_id]
                  label: "created this list"
               activities.push created

            # Merge data and sort by date
            _.sortBy(activities, "created_at").reverse()
            # Sorting activities to move the pinned comments to the top
            activities.sort (a, b) ->
               if a.interaction_type is "EverTrue Comment" and a.pinned then -1
               else if b.interaction_type is "EverTrue Comment" and b.pinned then 1
               else 0

         getLoading: ->
            @getState("loading")

         getPaging: (list_id) ->
            list_activity = _.omit @getState("list_activity")?[list_id], "items"
            note_activity = _.omit @getState("list_notes")?[list_id], "items"

            activity: PaginationUtils.paginate(list_activity)
            notes: PaginationUtils.paginate(note_activity)

         getCollapsed: ->
            @getState("collapsed")

         hasMoreToLoad: (list_id) ->
            list_activity = @getState("list_activity")?[list_id]
            note_activity = @getState("list_notes")?[list_id]
            !(PaginationUtils.isFinalPage(list_activity) && PaginationUtils.isFinalPage(note_activity))

