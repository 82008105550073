import { Component } from "react";
import $ from "jquery";
import _ from "underscore";
import EverTrue from "app";
import Api from "entities/helpers/api";
import CronofyTable from "apps/my-trips/components/cronofy-table";
import CronofyCalendarContainer from "apps/my-trips/components/cronofy-calendar-container";
import CronofyCalendarSource from "apps/my-trips/sources/cronofy-calendar-source";
import ErrorLogger from "entities/helpers/error-log-helper";
import BackHeader from "components/elements/back-header";
import { REDIRECT_URI } from "apps/my-trips/constants";

class CronofySettingsController extends Component {
  state = {
    authorize_url: "",
    loading: false,
    accounts: [],
  };

  componentDidMount() {
    const { code, state } = $.deparam(window.location.search.slice(1)) || {};

    if (code && state) this.handleAuthorizeRedirect(code, state);

    this.fetchAccounts();
    CronofyCalendarSource.calendarRefresh();
  }

  fetchAccounts = () => {
    // this 404s if no accounts
    Api.VOYAGER.CRONOFY.get({
      urlExtend: "/accounts",
      disableAlerts: true,
      success: ({ accounts = [] } = {}) => {
        this.setState({
          accounts,
          loading: false,
        });
      },
      error: () => {
        this.setState({ loading: false });
      },
    });
  };

  handleAuthorizeRedirect = (code, state) => {
    Api.VOYAGER.CRONOFY.post({
      urlExtend: "/auth/validate",
      data: _.jsonStringify({
        code,
        state,
        redirect_uri: REDIRECT_URI,
      }),
      error_message: "Connecting your service failed, please try again.",
      success: () => {
        // so we don't run these calls if someone refreshes
        window.history.pushState(null, null, "/trips/calendar");
        CronofyCalendarSource.calendarRefresh();
        this.fetchAccounts();
      },
      error: xhr => {
        // so we don't run these calls if someone refreshes
        window.history.pushState(null, null, "/trips/calendar");
        ErrorLogger.captureRequest("Error validating cronofy account", xhr);
      },
    });
  };

  addCalendar(calendarId) {
    return Api.VOYAGER.CALENDAR.put({
      urlExtend: `/${calendarId}/connect`,
      params: { sync_meetings: true },
      error: xhr => {
        ErrorLogger.captureRequest("Error adding calendar", xhr);
      },
    });
  }

  removeCalendar(calendarId) {
    return Api.VOYAGER.CALENDAR.put({
      urlExtend: `/${calendarId}/disconnect`,
      params: { delete_meetings: true },
      error: xhr => {
        ErrorLogger.captureRequest("Error removing calendar", xhr);
      },
    });
  }

  handleManageCalendars = async ({ disconnect = [], connect = [] }) => {
    try {
      this.setState({ loading: true });
      const disconnect_requests = disconnect.map(calId => this.removeCalendar(calId));
      const connect_requests = connect.map(calId => this.addCalendar(calId));
      await Promise.all([...disconnect_requests, ...connect_requests]);
      CronofyCalendarSource.calendarRefresh();
      this.setState({ loading: false });
      EverTrue.Alert.success("Updated Calendars");
    } catch (error) {
      this.setState({ loading: false });
    }
  };

  showDisconnectModal = async ({ provider_name, account_id }) => {
    const didConfirm = await AsyncConfirmModal({
      headline: `Disconnect ${provider_name}?`,
      content: `Disconnecting your ${provider_name}`,
    });

    if (didConfirm) {
      await Api.VOYAGER.ACCOUNT.delete({
        urlExtend: `/${account_id}/remove`,
      });

      this.setState({
        accounts: this.state.accounts.filter((account = {}) => account.account_id !== account_id),
      });
      EverTrue.Alert.success(`Successfully removed ${provider_name}`);
    }
  };

  handleBack() {
    EverTrue.Navigator("/trips", true);
  }

  render() {
    return (
      <div className="cronofy-settings-controller">
        <BackHeader handleBack={this.handleBack}>My Trips & Meetings</BackHeader>
        <div className="cronofy-settings-controller--main">
          <CronofyCalendarContainer
            render={({ calendars, isLoading }) => (
              <CronofyTable
                accounts={this.state.accounts}
                authorizeUrl={this.state.authorize_url}
                isLoading={this.state.loading || isLoading}
                calendars={calendars}
                showDisconnectModal={this.showDisconnectModal}
                handleManageCalendars={this.handleManageCalendars}
              />
            )}
          />
        </div>
      </div>
    );
  }
}

function AsyncConfirmModal(modalConfig) {
  return new Promise((resolve, reject) =>
    EverTrue.Alert.confirm(modalConfig, didConfirm => (didConfirm ? resolve() : reject()))
  )
    .then(() => true)
    .catch(() => false);
}

export default CronofySettingsController;
