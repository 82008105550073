import _ from "underscore";
import { Component, Fragment } from "react";
import EverTrue from "app";
import PropTypes from "prop-types";
import Decorator from "clientDecorator";
import { connect } from "@evertrue/et-flux";
import ContactCard from "apps/contact/components/contact-card/contact-card";
import ContactBusiness from "apps/contact/components/contact-card/contact-business";
import TripContactsController from "apps/my-trips/controllers/trip-contacts-controller";
import TripContactsStore from "apps/my-trips/stores/trip-contacts-store";
import MeetingsStore from "apps/my-trips/stores/meetings-store";
import MeetingController from "apps/my-trips/controllers/meeting-controller";
import AddEventModal from "apps/my-trips/components/add-event-modal";
import ActionMenu from "components/controls/action-menu";
import TripContactActions from "apps/my-trips/components/trip-contact-actions";
import MeetingsSource from "apps/my-trips/sources/meetings-source";
import { Button, ModalTrigger2, Modal2, Loading } from "@evertrue/et-components";
import MeetingForm from "apps/my-trips/components/meeting-form";
import { DNDControllerWithoutList } from "components/controls/dnd/dnd-controller";
import MyTripsUtils from "apps/my-trips/utils/my-trips-utils";

const mapStateToProps = ({ trip = {} }) => ({
  loading: TripContactsStore.getLoading(),
  total_trip_contacts: TripContactsStore.getContactCountForTrip(trip.id),
  meetings: MeetingsStore.getForTrip(trip.id),
  meetings_contact_count: MeetingsStore.getContactCountForTrip(trip.id),
  contacts: TripContactsStore.getContactsForTrip(trip.id),
});

class ItineraryController extends Component {
  static propTypes = {
    trip: PropTypes.object,
    // from stores
    loading: PropTypes.bool,
    total_trip_contacts: PropTypes.number,
    meetings: PropTypes.array,
    meetings_contact_count: PropTypes.number,
    contacts: PropTypes.array,
  };

  static defaultProps = {
    trip: {},
    meetings: [],
    contacts: [],
  };

  state = {
    is_currently_dragging: false,
    create_meeting: undefined,
  };

  componentDidMount() {
    EverTrue.track.set("trip_action", { type: "trip_itinerary" });
    EverTrue.track.setReferrer("trip_itinerary");
  }

  onReorder = (contactID, source, destination) => {
    this.setState({ is_currently_dragging: false });
    const contact_id = parseInt(contactID, 10);

    // all we get from drag n drop is the contact id, so we need to look up the
    // full contact from the trip contacts store
    const dragged_contact = _.findWhere(this.props.contacts, { id: contact_id });

    // The destination should either be "Meeting=123" (meeting and meeting id)
    // or "Date=May 12th" (desired date)
    const destination_parts = destination.split("=");

    // Check if dragging the contact into a meeting that already exists
    let meeting = {};
    if (destination_parts[0] === "Meeting") {
      meeting = _.findWhere(this.props.meetings, { id: _.toNumber(destination_parts[1]) });
    }

    // If there's a meeting to add the constituent to
    if (destination_parts[0] === "Meeting" && !_.isEmpty(meeting)) {
      let buttonLabel, title;
      const contact_already_in_meeting = _.contains(meeting.contact_ids, contact_id);

      if (contact_already_in_meeting) {
        title = "Constituent Already in Meeting";
      } else {
        title = "Add Constituent to Meeting?";
        buttonLabel = "Add";
      }

      EverTrue.Alert.confirm(
        {
          width: 480,
          type: "info",
          headline: title,
          buttonLabel,
          content: (
            <div className="meeting-calendar--drag-confirm">
              {contact_already_in_meeting ? (
                <div>
                  <strong>{Decorator.Contacts.getFullName(dragged_contact)}</strong>
                  {` has already been added to ${meeting.name}.`}
                </div>
              ) : (
                <ContactCard contact={dragged_contact}>
                  <ContactBusiness contact={dragged_contact} />
                </ContactCard>
              )}
            </div>
          ),
        },
        (e) => {
          if (e && !contact_already_in_meeting) {
            MeetingsSource.saveTripMeeting(
              this.props.trip.id,
              _.extend({}, meeting, {
                contact_ids: _.flatten([meeting.contact_ids, contact_id]),
              })
            );
          }
        }
      );
    } else {
      const date = destination_parts[1];
      const contact_name = Decorator.Contacts.getFullName(dragged_contact);
      const meetings_by_date = Decorator.MyTrips.getCalendarData(this.props.trip, this.props.meetings);
      const meetings_for_this_date = meetings_by_date[date];
      const create_meeting = MyTripsUtils.getDefaultEvent(meetings_for_this_date, date, this.props.trip) || {};
      create_meeting.name = `Meeting with ${contact_name}`;
      create_meeting.contacts = [];
      create_meeting.contacts.push(dragged_contact);
      this.setState({ create_meeting: create_meeting });
    }
  };

  renderTripContactActions = () => {
    return (
      <div className="itinerary--module-action">
        <ActionMenu label="Add Constituents..." disabled={!this.props.trip.id} buttonType="secondary">
          <TripContactActions trip={this.props.trip} />
        </ActionMenu>
      </div>
    );
  };

  handleStartDragging = (test) => {
    this.setState({ is_currently_dragging: true });
  };

  render() {
    const { trip, total_trip_contacts, loading, meetings, meetings_contact_count } = this.props;

    return (
      <Fragment>
        {/* If you drag to an existing meeting, show edit meeting modal */}
        <Modal2
          isOpen={!_.isEmpty(this.state.create_meeting)}
          onClose={() => this.setState({ create_meeting: undefined })}
        >
          <MeetingForm
            meeting={this.state.create_meeting}
            tripId={trip.id}
            remove={() => this.setState({ create_meeting: undefined })}
          />
        </Modal2>

        <DNDControllerWithoutList
          onDragStart={this.handleStartDragging}
          onReorder={this.onReorder}
          className="itinerary-controller"
        >
          <div className="itinerary--list">
            <div className="itinerary--module-header">
              <div className="itinerary--module-title">
                Trip List
                <div className="itinerary--module-subtitle">
                  <strong>{total_trip_contacts} </strong>
                  Constituents
                </div>
              </div>
              {trip.is_my_trip && !!total_trip_contacts && this.renderTripContactActions()}
            </div>
            <div className="itinerary--list-body">{loading ? <Loading /> : <TripContactsController trip={trip} />}</div>
          </div>

          <div className="itinerary--schedule">
            <div className="itinerary--module-header">
              <div className="itinerary--module-title">
                Meeting Itinerary
                <div className="itinerary--module-subtitle">
                  <strong>{meetings.length || 0}</strong> Meetings, <strong>{meetings_contact_count || 0}</strong>{" "}
                  Constituents
                </div>
              </div>
              {trip.is_my_trip && (
                <div className="itinerary--module-action">
                  <ModalTrigger2
                    closeOnOutsideClick
                    trigger={({ open }) => {
                      return (
                        <Button type="secondary" onClick={open}>
                          Add Event
                        </Button>
                      );
                    }}
                  >
                    {({ close }) => {
                      return <AddEventModal tripId={trip.id} remove={close} />;
                    }}
                  </ModalTrigger2>
                </div>
              )}
            </div>
            <div className="itinerary--schedule-body">
              <MeetingController trip={trip} isCurrentlyDragging={this.state.is_currently_dragging} />
            </div>
          </div>
        </DNDControllerWithoutList>
      </Fragment>
    );
  }
}

export default connect(ItineraryController, [TripContactsStore, MeetingsStore], mapStateToProps);
