module.exports = do ->
   _ = require("underscore").default
   $ = require("jquery")
   React = require("react")
   EverTrue = require("app")
   moment = require("moment")
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   ListSource = require("apps/lists/sources/list-source")
   ListStore = require("apps/lists/stores/list-store")
   ListContactSource = require("apps/lists/sources/list-contact-source")
   ListContactStore = require("apps/lists/stores/list-contact-store")
   SelectedContactsSource = require("apps/contact/sources/selected-contacts-source")
   SelectedContactsStore = require("apps/contact/stores/selected-contacts-store")
   MapSource = require("apps/map/sources/map-source")
   ListActivityStore = require("apps/lists/stores/list-activity-store")
   ListActivitySource = require("apps/lists/sources/list-activity-source")
   {div, span, strong, h1 } = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   Icon = createFactory (require("components/elements/icon").default)
   ActionMenu = createFactory(require("components/controls/action-menu"))
   ClickToInput = require("components/controls/click-to-input")
   EmptyStateMessage = createFactory(require("components/lists/empty-state-message"))
   ContactTableToolbar = createFactory(require("apps/contact/components/contacts/table/contact-table-toolbar").default)
   ContactFixedTable = createFactory(require("apps/contact/components/contacts/contact-fixed-table").default)
   CollaboratorIconList = require("apps/lists/components/collaborator-icon-list")
   ListActions = require("apps/lists/components/list-actions")
   ListActivity = require("apps/lists/components/list-activity")
   ListMapController = require("apps/lists/controllers/list-map-controller")
   MapContacts = require("apps/map/controllers/map-contacts-controller")
   SelectedContactsString = require("components/formatting/selected-contacts-string")
   ShowHideAnimate = require("components/show-hide-animate")
   ProgressBar = createFactory(require("components/forms/progress-bar").default)
   BulkAddStore = require("apps/layout/stores/bulk-add-store").default
   LinkButton = createFactory(require("@evertrue/et-components").LinkButton)
   Button = createFactory(require("@evertrue/et-components").Button)
   FeatureStore = require("apps/layout/stores/feature-store").default
   EmmaListFetcher = createFactory(require("apps/lists/fetchers/emma-list-fetcher").default)
   BulkListController = require("apps/lists/controllers/bulk-list-controller")


   createComponent "ListController",
      getInitialState: ->
         showModal: false
         enrichmentLoading: true

      propTypes:
         list_id: ReactLibs.PropTypes.any
         view: ReactLibs.PropTypes.oneOf ["table", "map"]

      getDefaultProps: ->
         view: "table"

      registerStores: ->
         @on ListStore, ->
            list: ListStore.getList(@props.list_id)
            list_loading: ListStore.getLoading()
            previous_list: ListStore.getLastSelectedList()

         @on ListContactStore, ->
            list_contacts: ListContactStore.getContacts(@props.list_id)
            loading: ListContactStore.getLoading()

         @on ListActivityStore, ->
            list_activity_collapsed: ListActivityStore.getCollapsed()

         @on SelectedContactsStore, ->
            selected_contacts: SelectedContactsStore.getSelectedContacts()

         @on BulkAddStore, ->
            bulk_add_status_obj: BulkAddStore.getBulkAddToList()

         @on FeatureStore, ->
            has_emma: FeatureStore.hasFeature("emma")

      UNSAFE_componentWillMount: ->
         if @state.previous_list != @props.list_id
            MapSource.setBoundingBox()

      componentDidMount: ->
         ListSource.select(@props.list_id)
         ListContactSource.filter(@props.list_id)

         # Set the event type for certain actions on lists
         _.defer((-> ListContactSource.setEvent("list_action")))

      UNSAFE_componentWillReceiveProps: (newProps) ->
         if newProps.list_id != @props.list_id
            MapSource.clearClusters()
            MapSource.setBoundingBox()
            ListSource.select(newProps.list_id)
            ListContactSource.filter(newProps.list_id)
            SelectedContactsSource.clear()
            @setState {list: ListStore.getList(newProps.list_id)}

         if newProps.view != @props.view
            if @state.list_contacts?.sortProp == "proximity_sort"
               ListContactSource.changeSort(newProps.list_id, null, null, true)

      componentWillUnmount: ->
         ListSource.select(null)
         SelectedContactsSource.clear()
         MapSource.clearClusters()

      handleSaveListName: (name) ->
         if !_.isEmpty(name) && name != @state.list?.name
            ListSource.update _.extend {}, @state.list,
               name: name
               type: "user_list"
            EverTrue.track.set "list_action", {type: "update_name"}

      handleRemove: (evnt) ->
         ListContactSource.removeContacts(@props.list_id, @state.selected_contacts)
         SelectedContactsSource.clear()

      setEnrichmentLoading: (bool) -> 
        @setState({enrichmentLoading: bool})

      handlePage: (page) ->
         ListContactSource.changePage(@props.list_id, page)
         SelectedContactsSource.clear()
         @setEnrichmentLoading(true)

      handleSort: (sort_key, reverse, silent) ->
         ListContactSource.changeSort(@props.list_id, sort_key, !!reverse, silent)
         SelectedContactsSource.clear()

      handleCollapseActivity: ->
         if @state.list_activity_collapsed
            EverTrue.track.set "list_action", {type: "list_activity_feed"}
         ListActivitySource.collapse(!@state.list_activity_collapsed)
         # Trigger resize to re-calculate the table columns & scrolling
         _.defer ->
            _.delay (-> $(window).trigger("resize")), 500

      renderPanelToggle: ->
         LinkButton
            title: "Toggle list activity"
            className: classNames "list-detail--activity-toggle",
               "is-collapsed": @state.list_activity_collapsed
            onClick: @handleCollapseActivity

            Icon
               icon: "collapse"
               size: 2
               rotate: if !@state.list_activity_collapsed then 180

            if @state.list_activity_collapsed
               span className: "list-detail--activity-toggle-label",
                  "Show Activity"

      renderAddModal: ->
         this.setState({ showModal: true });

      render: ->
         isOwner = @state.list?.user_id == EverTrue.store.user.get("id")
         progress = @state.bulk_add_status_obj?.progress
         in_progress = _.isNumber(progress) && progress != 1
         is_bulk_list_in_progress = @state.bulk_add_status_obj?.id == _.toNumber(@props.list_id) && in_progress
         div className: "list-detail",
            if !@state.list_loading && !@state.list
               EmptyStateMessage
                  icon: "lock"
                  text: "You do not have access to this list"

            else if is_bulk_list_in_progress
               progress = @state.bulk_add_status_obj?.progress * 100
               if progress == 0 then progress += 0.03
               EmptyStateMessage
                  icon: "clipboard-text"
                  text: "Adding constituents to list..."

                  div className: "list-detail--progress-bar",
                     ProgressBar
                        className: "progress-bar-info-inverse"
                        percent: progress

            else
               EmmaListFetcher
                  list_id: parseInt(@props.list_id, 10),
                  has_emma: @state.has_emma,
                  ({accounts, groups, loading, error, refresh}) =>
                     div null,
                        # List Header
                        div className: "list-detail--header",
                           div className: "list-detail--description",
                              div className: "list-detail--label",
                                 if isOwner && @state.list?.name != "Favorites"
                                    ClickToInput
                                       value: @state.list?.name
                                       onSave: @handleSaveListName
                                 else
                                    h1 tabIndex: -1, @state.list?.name

                              div className: "list-detail--info",
                                 SelectedContactsString
                                    selected: @state.selected_contacts?.length
                                    results: @state.list_contacts?.total


                                 do ->
                                    if _.notEmpty(groups)
                                       group = _.max groups, (group) ->  group.last_pushed

                                       { last_pushed, account_id } = group

                                       name = _.findWhere(accounts, id: account_id)?.name  || "Unknown Account"

                                       date = moment(last_pushed || "-").format("MM/DD/YY h:mma")

                                       span className: "emma-list-timestamp",
                                          span className: "emma-list--spacer", "|"
                                          "Last sent to ",
                                          strong null, "Emma: "
                                          name
                                          if last_pushed then span null, " on ", date
                                    else null

                           div className: "list-detail--actions",
                              ActionMenu width: (if @state.selected_contacts?.length then 400),
                                 ListActions
                                    list: @state.list
                                    resultCount: @state.list_contacts?.total
                                    selectedContacts: @state.selected_contacts
                                    onRemove: @handleRemove
                                    emma_groups: groups
                                    emma_accounts: accounts
                                    onEmmaChange: refresh

                        div className: "list-detail--sub-header",
                           CollaboratorIconList list_id: @props.list_id

                        # List Table & Map
                        div className: "list-detail--content fixed-page-wrapper",
                           ContactTableToolbar
                              contacts: @state.list_contacts
                              listActionLabel: "Add to another list"
                              onPage: @handlePage
                              allowAddAll: false

                              Button
                                 type: "destroy"
                                 disabled: _.isEmpty(@state.selected_contacts)
                                 onClick: @handleRemove
                                 "Remove from list"

                              if @props.view == "table"
                                 Button
                                    type: "simple"
                                    onClick: (value) =>
                                       route = "/list/#{@props.list_id}/map"
                                       EverTrue.Navigator(route, true)
                                       return undefined
                                    Icon icon: "map gt-icon-lg"
                                    "Map"
                              else
                                 Button
                                    type: "simple"
                                    onClick: (value) =>
                                       route = "/list/#{@props.list_id}"
                                       EverTrue.Navigator(route, true)
                                       return undefined
                                    Icon icon: "table-large gt-icon-lg"
                                    "Table"

                           div className: "list-detail--body fixed-page-wrapper",
                              if this.state.showModal  
                                 BulkListController list: @state.list, remove: () => this.setState({showModal: false})
                              if @props.view != "map"
                                 div null,
                                    ContactFixedTable
                                       className: classNames "list-detail--contact-table",
                                          "is-activity-collapsed": @state.list_activity_collapsed
                                       contacts: @state.list_contacts
                                       loading: @state.loading
                                       onPage: @handlePage
                                       onSort: @handleSort
                                       emptyStateMessage: "No constituents have been added to this list."
                                       actionLabel: "+ Add Constiutents"
                                       onAction: @renderAddModal
                                       setEnrichmentLoading: @setEnrichmentLoading
                                       enrichmentLoading: @state.enrichmentLoading

                                    ShowHideAnimate
                                       show: @state.list_activity_collapsed
                                       className: "list-detail--activity-panel is-collapsed"
                                       animateInClass: "slideInRight"
                                       animateOutClass: "slideOutRight"
                                       @renderPanelToggle()

                                    ShowHideAnimate
                                       show: !@state.list_activity_collapsed
                                       className: "list-detail--activity-panel"
                                       animateInClass: "slideInRight"
                                       animateOutClass: "slideOutRight"

                                       div className: "list-detail--activity",
                                          @renderPanelToggle()
                                          ListActivity list_id: @props.list_id
                              else
                                 div null,
                                    MapContacts
                                       contacts: @state.list_contacts
                                       loading: @state.loading
                                       onSort: @handleSort

                                    ListMapController
                                       list_id: @props.list_id
