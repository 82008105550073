module.exports = do ->
   _ = require("underscore").default
   moment = require("moment-timezone")

   US_STANDARD = [
      "America/New_York"
      "America/Chicago"
      "America/Denver"
      "America/Los_Angeles"
   ]

   OTHER_ZONES = _.filter _.uniq(moment.tz.names()), (zone) ->
      !(zone.match(/^US/) || _.contains(US_STANDARD, zone))

   _formatZoneDisplay = (zone) ->
      abbr = moment.tz(zone).zoneName()
      abbr + " - " + zone

   _formatZoneItem = (zone) ->
      {value: zone, label: _formatZoneDisplay(zone)}

   _formatZoneItemLegacy = (zone) ->
      {val: zone, val_display: _formatZoneDisplay(zone)}

   LEGACY_LIST = _.map(US_STANDARD, _formatZoneItemLegacy).concat([{val_display: "---"}],
      _.map(OTHER_ZONES, _formatZoneItemLegacy)
   )

   GROUPED_LIST = [
      label: "Standard USA"
      items: _.map(US_STANDARD, _formatZoneItem)
   ,
      label: "All Timezones"
      items: _.sortBy _.map(OTHER_ZONES, _formatZoneItem), "label"
   ]


   TimezoneHelper =
      findSetValue: (val) ->
         {label: _formatZoneDisplay(val), value: val}

      getFormattedList: ->
         LEGACY_LIST

      getFormattedGroups: ->
         GROUPED_LIST

   return TimezoneHelper
