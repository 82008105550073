module.exports = do ->
   React = require("react")
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   {div, span, h3} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   HelpTip = createFactory require("components/tooltip/helptip").default

   createComponent "KPICard",
      propTypes:
         title: ReactLibs.PropTypes.node.isRequired
         value: ReactLibs.PropTypes.node.isRequired
         width: ReactLibs.PropTypes.number
         helpTip: ReactLibs.PropTypes.string

      getDefaultProps: ->
         width: 25

      render: ->
         div className: classNames("kpi-card", @props.className), style: {width: @props.width + "%"},
            span className: "kpi-card--title",
               @props.title
               if @props.helpTip
                  HelpTip help_key: @props.helpTip

            h3 className: "kpi-card--value fs-exclude-data",
               @props.value

            @props.children
