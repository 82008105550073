module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   str = require("underscore.string")
   EverTrue = require("app")
   {createComponent} = require("@evertrue/et-flux")
   {div, h5, strong} = ReactLibs.DOMFactories
   AllUsersStore = require("apps/users/stores/all-users-store").default
   MyTripsSource = require("apps/my-trips/sources/my-trips-source")
   MyTripsStore = require("apps/my-trips/stores/my-trips-store")
   {createFactory} = require("base/new-utils")
   MyTripsActions = createFactory(require("apps/my-trips/components/my-trips-actions").default)
   TripCard = require("apps/my-trips/components/trip-card")
   TripForm = require("apps/my-trips/components/trip-form")
   ActionMenu = createFactory(require("components/controls/action-menu"))
   AdvancedCombobox = createFactory(require("@evertrue/et-components").AdvancedCombobox)
   SortDropdown = require("components/controls/sort-dropdown")
   Searchbox = createFactory require("components/forms/search-box")
   Loading = require("components/elements/loading")
   EmptyStateMessage = createFactory(require("components/lists/empty-state-message"))
   ModalTrigger = createFactory require("components/modals/modal-trigger")
   CronofyCalendarContainer = createFactory(require("apps/my-trips/components/cronofy-calendar-container").default)

   createComponent "MyTripsController",
      getInitialState: ->
         sort_order: "desc"
         search_query: ""
         search_results: {}

      registerStores: ->
         @on MyTripsStore, ->
            trips: MyTripsStore.getTrips()
            selected_user: MyTripsStore.getSelectedUser()
            loading: MyTripsStore.getLoading()
            trips_by_date: MyTripsStore.getAllTripsByDate()
            current_user_platform_data: MyTripsStore.getCurrentUserData()

         @on AllUsersStore, ->
            users: AllUsersStore.getAllGivingTreeUsers()

      componentDidMount: ->
         MyTripsSource.fetchCurrentUserData()
         if user = EverTrue.UrlManager.get("trip_user")
            MyTripsSource.setUser(user)
         else if !@state.selected_user
            if user = EverTrue.store?.user?.get("id")
               MyTripsSource.setUser(user)
         MyTripsSource.fetchAll(user)

         EverTrue.track.set "viewed", {type: "my_trips"}
         EverTrue.track.setReferrer("my_trips")

      handleSort: ->
         sort_order = if @state.sort_order == "desc" then "asc" else "desc"
         @setState {sort_order: sort_order}
         MyTripsSource.fetchTrips(@state.selected_user, sort_order)

      handleSearch: (query) ->
         results =
            if !_.isEmpty(query)
               _.filter @state.trips, (trip) ->
                  str.include(trip.name.toLowerCase(), query.toLowerCase())
            else
               {}
         @setState {search_query: query, search_results: results}

      renderUserDropdown: (trips) ->
         current_user = EverTrue.store?.user?.toJSON()
         options = _.map @state.users, (user) ->
            {value: user.id, label: user.name}

         if current_user && !_.findWhere(options, {value: current_user.id})
            options.push {value: current_user.id, label: current_user.name}

         div className: "my-trips--user-dropdown",
            AdvancedCombobox
               className: "et--advanced-combobox-title"
               value: _.findWhere options, {value: @state.selected_user}
               searchable: true
               options: _.sortBy(options, "label")
               formatSelection: (label) ->
                  _.pluralizePossession(label) + " Trips & Meetings"
               onChange: (user) ->
                  MyTripsSource.setUser(user.value)
                  MyTripsSource.fetchAll(user.value)
                  EverTrue.UrlManager.set("trip_user", user.value)

            strong null, trips.length
            " trips & meetings"

      render: ->
         user_name = _.findWhere(@state.users, {id: @state.selected_user})?.name
         trips = if _.isEmpty(@state.search_results) then @state.trips else @state.search_results
         {upcoming_trips, past_trips} = @state.trips_by_date

         div className: "my-trips",
            CronofyCalendarContainer() # preload b/c form uses the data
            div className: "my-trips--header",
               div className: "my-trips--title",
                  @renderUserDropdown(trips)

               ActionMenu className: "my-trips--actions",
                  MyTripsActions {user: @state.selected_user, current_user_platform_data: @state.current_user_platform_data}

            div className: "my-trips--controls",
               Searchbox
                  className: "events-controller--searchbox"
                  placeholder: "Search by Trip or Meeting Name"
                  value: @state.search_query
                  onKeyUp: (val) => @handleSearch(val)

               SortDropdown
                  className: "pull-right"
                  options: [{label: "Start Date", value: "date"}]
                  sortProp: "date"
                  sortReverse: @state.sort_order == "desc"
                  onSort: @handleSort

            div className: "my-trips--body",
               if @state.loading
                  Loading(text: "Loading Trips...")
               else if _.isEmpty(trips)
                  div className: "my-trips--empty",
                     if @state.selected_user == EverTrue.store.user.get("id")
                        div null,
                           EmptyStateMessage
                              icon: "my-trips"
                              text: "You don't have any trips or meetings"
                              "Trips help you organize your meetings more efficiently on the road."

                           ModalTrigger modal: TripForm({navigateToTrip: true}), title: "Create a trip", buttonType: "secondary",
                              "Create a Trip"
                     else
                        div null,
                           EmptyStateMessage
                              icon: "my-trips"
                              text: "#{user_name || "User"} has not added any trips."
                              "Once a trip is created, it will show up here."
               else if !_.isEmpty(@state.search_results)
                  div null,
                     div className: "my-trips--upcoming clearfix",
                        h5 className: "my-trips--time-heading",
                           "Search Results"
                        div className: "my-trips--cards",
                           @state.search_results.map (trip) =>
                              TripCard key: trip.id, tripId: trip.id, current_user_platform_data: @state.current_user_platform_data
               else
                  div null,
                     if !_.isEmpty(@state.search_query)
                        div className: "my-trips--upcoming clearfix",
                              h5 className: "my-trips--time-heading__no-search-results",
                                 "No Results for Current Search"
                     if upcoming_trips.length
                        div className: "my-trips--upcoming clearfix",
                           h5 className: "my-trips--time-heading",
                              "Upcoming"
                           div className: "my-trips--cards",
                           upcoming_trips.map (trip) =>
                              TripCard key: trip.id, tripId: trip.id, current_user_platform_data: @state.current_user_platform_data
                     if past_trips.length
                        div className: "my-trips--past clearfix",
                           h5 className: "my-trips--time-heading",
                              "Past"
                           div className: "my-trips--cards",
                           past_trips.map (trip) =>
                              TripCard key: trip.id, tripId: trip.id, current_user_platform_data: @state.current_user_platform_data
