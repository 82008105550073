import { Button } from "@evertrue/et-components";
import { PropTypes } from "base/react-libs";
import { formatDateFromTimestampWeekday } from "apps/journeys/utils/helpers/dates";
import EverTrue from "app";
import HomepageCard from "../homepage-card";
import AddTaskButton from "apps/tasks/components/create/add-task-button";
import OtherTaskCount from "./other-task-count";
import JourneyTaskCount from "./journey-task-count";
import TripsAndMeetings from "./trips-meetings";

const TodayContent = ({
  todayJourneyCount = {},
  loadingJourneysTodayCount,
  loadingTasksTodayCount,
  todayTaskCount = {},
  tripsAndMeetingDetails,
  optionalStateManagementFunction = () => {},
}) => {
  return (
    <HomepageCard
      leftHeading={"Today"}
      rightHeading={formatDateFromTimestampWeekday(Math.floor(new Date().getTime()))}
      body={
        <>
          <JourneyTaskCount
            todayJourneyCount={todayJourneyCount}
            loadingJourneysTodayCount={loadingJourneysTodayCount}
          />
          <div className="homepage-today-cadence-steps--view">
            <Button
              onClick={() => {
                EverTrue.track.set("home_screen", { type: "cadences" });
                EverTrue.UrlManager.navigate("/cadences", true, false);
              }}
            >
              View Cadences
            </Button>
          </div>
          <OtherTaskCount todayTaskCount={todayTaskCount} loadingTasksTodayCount={loadingTasksTodayCount} />
          <div className="homepage-today-other-tasks">
            <div className="homepage-today-other-tasks--view">
              <Button
                onClick={() => {
                  EverTrue.track.set("home_screen", { type: "view_task" });
                  EverTrue.UrlManager.navigate("/tasks", true, false);
                }}
              >
                View Tasks
              </Button>
            </div>
            <div className="homepage-today-other-tasks--add">
              <AddTaskButton
                buttonType={"secondary"}
                optionalStateManagementFunction={optionalStateManagementFunction}
              />
            </div>
          </div>
          <hr></hr>
          <TripsAndMeetings tripsAndMeetingDetails={tripsAndMeetingDetails} nextFive={false} />
        </>
      }
    />
  );
};

TodayContent.propTypes = {
  todayJourneyCount: PropTypes.object,
  loadingJourneysTodayCount: PropTypes.bool,
  loadingTasksTodayCount: PropTypes.bool,
  todayTaskCount: PropTypes.object,
  optionalStateManagementFunction: PropTypes.func,
  tripsAndMeetingDetails: PropTypes.any,
};

export default TodayContent;
