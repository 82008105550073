import { useContext } from "react";
import PropTypes from "prop-types";
import { Icon, Button } from "@evertrue/et-components";
import EverTrue from "app";
import { FiltersDataContext } from "apps/query-builder/contexts/filters-data-context";

const RecentFilters = ({ recentFilters = [], onSelection }) => {
  const { filters } = useContext(FiltersDataContext);

  const handleClick = (compound_id) => {
    EverTrue.track.set("filter_v2_action", {
      type: "recently_viewed_used",
    });

    const filter = filters.find((filter) => filter.compound_id === compound_id);

    if (filter) {
      onSelection(filter);
    } else {
      EverTrue.Alert.error("Filter no longer exists, please select another");
    }
  };

  return (
    <>
      {recentFilters.length > 0 && (
        <div className="filter-picker--recent">
          <div className="filter-picker--recent-heading">Recently Used</div>
          <ul>
            {recentFilters.map((filter) => (
              <li
                key={filter.compound_id}
                className="filter-picker--recent-link"
                onClick={() => handleClick(filter.compound_id)}
              >
                <Button type="simple">
                  <Icon className="filter-picker--list-icon" icon={filter.icon} />
                  {filter.group} <Icon icon="chevron-right" /> {filter.label}
                </Button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

RecentFilters.propTypes = {
  recentFilters: PropTypes.array,
  onSelection: PropTypes.func,
};

export default RecentFilters;
