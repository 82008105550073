_ = require("underscore").default
React = require("react")
{div, span, strong, button} = ReactLibs.DOMFactories
classNames = require("classnames")
{createFactory} = require("base/new-utils")
EllipsisOverflow = createFactory require("components/formatting/ellipsis-overflow")
Icon = createFactory (require("components/elements/icon").default)
Avatar = require("components/elements/avatar")
EngagementIcon = require("apps/profile/components/engagement-icon")
{getTabByEngagementAction} = require("apps/events/utils/engagement-config")
ReadMore = createFactory require("components/formatting/readmore")

ModalTrigger = createFactory require("components/modals/modal-trigger")
EventbriteMatchModal = createFactory(require("apps/events/components/unmatched-engagers/eventbrite-match-modal").default)

isDisabled = (profile) -> profile.matchStatus != "UNMATCHED"

module.exports = ({ onMatchCompleted }) ->
   # this config is a function that accepts the `onMatchCompleted` prop
   # to allow its behavior to be customized by the component that uses into it

   [
      label: "Eventbrite Email"
      key: "eventbrite-email"
      width: 250
      row: ({profile, engagements} = {})->
         disabled = isDisabled(profile)
         div className: classNames("events-unmatched-table--profile", "disabled": disabled),
            Avatar className: "match-result--avatar fs-exclude-data", name: profile.name
            div className: "events-unmatched-table--profile-text",
               EllipsisOverflow null,
                  strong className: "fs-exclude-data", profile.email
               span className: "fs-exclude-data",  profile.name
   ,
      label: "Event Response"
      key: "response"
      width: 150
      row: ({profile, engagements} = {}) ->
         disabled = isDisabled(profile)
         div className: classNames("disabled": disabled),
            ReadMore key: ("" + profile.id + "- " + _.size engagements), height: 45,
               _.map engagements, (engagement) ->
                  div key: engagement.id,
                     EngagementIcon
                        className: "events-unmatched-table--event-response-icon"
                        size: 18
                        icon: engagement.engagementAction
                     span className: "events-unmatched-table--event-response-label",
                        getTabByEngagementAction(engagement.engagementAction)
   ,
      label: ""
      key: "match-status"
      width: 300
      row: ({profile, engagements} = {}) ->
         disabled = isDisabled(profile)
         div className: classNames("events-unmatched-table--status", "disabled": disabled),
            switch profile.matchStatus
               when "UNMATCHED"
                  ModalTrigger
                     buttonType: "secondary"
                     title: "Match to Constituent"
                     modal: EventbriteMatchModal({ profile, onMatchCompleted })
                     Icon icon: "link", size: 1
                     "Match to Constituent"
               when  "MATCH_QUEUED"
                  span null,
                     "Constituent Match Pending"
               else
                  span null,
                     "There was an error matching this profile"
   ]
