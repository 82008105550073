import Api from "entities/helpers/api";
import EverTrue from "app";

export const getVoipUserSettingsByOwnerUserId = async (userId) => {
  return (
    (await Api.OUTREACH.VOIP_USER_SETTINGS_BY_USER_ID.get({
      urlArgs: { owner_user_id: userId },
      success: (resp) => {
        return resp;
      },
    })) || {}
  );
};

export const validateUserPhoneNumber = async (phone) => {
  return await Api.OUTREACH.VALIDATE_PHONE.post({
    params: { phone },
    success: (resp) => {
      return resp;
    },
    error() {
      return EverTrue.Alert.error(`Unable to validate phone number ${phone}. please try again`);
    },
  });
};

export const validationNumberRequest = async (callSid) => {
  return await Api.OUTREACH.VALIDATION_REQUEST.get({
    urlArgs: { callSid },
    success: (resp) => {
      return resp;
    },
    error() {
      return EverTrue.Alert.error("Phone number validation failed, please try again");
    },
  });
};
