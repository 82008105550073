module.exports = do ->
   _ = require("underscore").default
   {createStore} = require("@evertrue/et-flux")
   FullContactSource = require("apps/contact/sources/full-contact-source")

   createStore "FullContactStore",
      getInitialState: ->
         full_contacts: {}
         is_loading: false

      registerActions: ->
         @on FullContactSource.actions.fetchedFullContact, @respondToFetchedFullContact
         @on FullContactSource.actions.loading, (loading) =>
            @setState is_loading: loading

      respondToFetchedFullContact: (contact) ->
         full_contacts = _.clone @getState("full_contacts")
         full_contacts[contact.id] = contact
         @setState {full_contacts: full_contacts}

      api:
         getFullContact: (id) ->
            @getState("full_contacts")?[id]

         getIsLoading: ->
            @getState("is_loading")
