module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   EverTrue = require("app")
   Contacts = require("entities/contacts/contacts")
   AffiliationInvitations = require("entities/auth/affiliation-invitations")
   Backbone = require("backbone")

   InviteStore = ->
      invitations = {}
      all_invitations = {}
      contacts = {}

      _getUserContacts = ->
         ids = invitations.getContactIds()
         contacts.fetchBulk ids,
            success: ->
               invitations.each (invite) ->
                  contact = contacts.get(invite.get("contact_id"))
                  invite.set("contact", contact?.toJSON(), {silent: true}) if contact
               invitations.trigger("reset")

      _applyExtraData = ->
         invitations.each (invite) ->
            roles = _.map invite.get("role_ids"), (id) -> _.toNumber(id)
            invite.set
               current_role_id: _.first(roles)
               current_status: invite.get("created_at")


      api = $.extend true, {}, Backbone.Events,
         initialize: ->
            contacts = new Contacts()
            invitations = new AffiliationInvitations()

         bindToUpdates: (callback) ->
            @listenTo invitations, "change reset sort", ->
               callback?(invitations.toCollectionWrapper())

         unbind: ->
            @stopListening()

         getInvites: ->
            invitations.fetch
               reset: true
               success: ->
                  all_invitations = invitations.clone()
                  _applyExtraData()

         filter: (query) ->
            base_invitations = all_invitations.toJSON()
            if _.isEmpty(query)
               filtered = base_invitations
            else
               filtered = _.filter base_invitations, (invite) ->
                  !!invite.email.toLowerCase().match(/// \b#{_.regexEscape(query.toLowerCase())} ///)
            invitations.reset(filtered)

         sort: (sort_key, reverse) ->
            if sort_key == "name" then sort_key = "email"
            invitations.comparator = (model) -> model.get(sort_key)
            invitations.comparator = _.reverseSortBy(invitations.comparator) if reverse
            invitations.sort()

         resend: (invite) ->
            @delete(invite.id, true).then =>
               @invite(invite.email, invite.current_role_id)

         delete: (id, silent) ->
            invite = invitations.get(id)
            invite.destroy
               urlExtend: "/#{id}"
               success_message: "Deleted invitation for #{invite.get("email")}" unless silent
               success: ->
                  invitations.remove(invite)
                  invitations.trigger("reset")

         invite: (input, role) ->
            emails = input.split(",")
            emails = _.map emails, (e) -> e.trim()
            invitations.sendBulk(emails, [role]).then(=>
               EverTrue.Alert.success("Invites Sent")
               @getInvites()
            ).fail(->
               EverTrue.Alert.error("Could not send invites")
            )

         hasAvailableInviteSeats: (users, invites, emails) ->
            total_seats = EverTrue.store?.dna?.get("ET.Org.GivingTreeSeats")
            return true if _.isUndefined(total_seats)

            registered_users_count = users?.items?.length || 0
            already_invited_users_count = invites?.items?.length || 0
            invite_count = emails.split(",")?.length

            !!((registered_users_count + already_invited_users_count + invite_count) <= total_seats)

   InviteStore()
