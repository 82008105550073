module.exports = do ->
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories
   KpiCard = require("components/containers/kpi-card")
   numeral = require("numeral")

   createComponent "CompanyGiving",
      render: ->
         data = @props.data || {}

         div className: "company-giving-cards fs-exclude-data",
            KpiCard
               title: "Average Lifetime Giving"
               value: numeral(data.average).format("$0,0")
               width: 33

            KpiCard
               title: "Median Lifetime Giving"
               value: numeral(data.median).format("$0,0")
               width: 33

            KpiCard
               title: "Total Lifetime Giving"
               value: numeral(data.total).format("$0,0")
               width: 33

