module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   EverTrue = require("app")
   Backbone = require("backbone")
   MobileRedirect = require("base/mobile-redirect")
   OrgSource = require("base/org/org-source").default

   # Allow action to delay route, for saving edits, etc.
   _wrap_execute = (callback) -> callback?()


   class BaseRouter extends Backbone.Router
      initialize: ->
         @on "route", -> EverTrue.vent.trigger("route:change")

      noAuthRoutes: ["login", "login/invite/:token", "lids/access", "status", "logout"]

      execute: (callback, args) ->
         _wrap_execute =>
            EverTrue.vent.trigger("before:route:change")
            EverTrue.execute "route:cleanup"
            $("#region-page").addClass("page-container")
            super callback, args

      delayExecute: (wrap) ->
         if _.isFunction(wrap)
            _wrap_execute = wrap

      clearDelay: ->
         _wrap_execute = (callback) -> callback?()

      before: (route, params) ->
         EverTrue.execute("setBrowserTitle", null)
         full_path = window.location.pathname + window.location.search
         # set oid from url params (if redirected from auth-web)
         if _.isArray(params) and params.length and !EverTrue.store.org.get("id")
            _.each params, (param) ->
               if param
                  data = $.deparam(param) || {}
                  if data.oid
                     EverTrue.store.org.set(id: data.oid)

         if analytics_referrer = EverTrue.UrlManager.get("referrer")
            EverTrue.track.setReferrer(analytics_referrer)

         if EverTrue.request("isMobile?") && !_.contains(["login/invite/:token", "lids/access"], route)
            MobileRedirect(full_path, window.history.length)

         if route in @noAuthRoutes
            return true
         else if !EverTrue.request "isAuthorized?"
            EverTrue.execute "setPageReferrer"
            EverTrue.execute "resetApp"
            return false
         else if !EverTrue.store.session.get("oid")
            if route == "login/org"
               return true
            else
               EverTrue.Navigator "/login/org", true
               return false

         @enterWithOrg()

         EverTrue.execute "enterApp"
         if _.isFunction @beforeRoute then @beforeRoute()
         if _.isFunction @beforeRouteFilter
            run_route = @beforeRouteFilter()
            EverTrue.Navigator("/", true) unless run_route
            return run_route
         return true

      after: (route, params) ->
         # Destroy Contact Cache
         EverTrue.store?.destroy("contactCache")

      cleanup: ->
         # Remove all messages
         @stopListening()
         if @currentView
            if _.isFunction @currentView.unmount then @currentView.unmount()
            else EverTrue.page.unmount()
            @currentView = undefined

      enterWithOrg: ->
         # Login to org if url contains oid, for support from emails
         url_oid = _.toNumber EverTrue.UrlManager.get("oid")
         current_oid = EverTrue.store.org?.get("id")
         user = EverTrue.store?.user

         if url_oid && current_oid && current_oid != url_oid
            if user?.hasGivingTreeAffiliationTo(url_oid) || user?.isSuperUser()
               EverTrue.execute("setPageReferrer")
               OrgSource.change({id: url_oid})
