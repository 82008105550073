import Api from "entities/helpers/api";
import _ from "underscore";
import EmptyStateMessage from "components/lists/empty-state-message";
import PoolProspectCard from "apps/volunteers/components/pool-prospect-card";
import PoolAssignmentsStore from "apps/volunteers/stores/pool-assignments-store";
import { LinkButton } from "@evertrue/et-components";
import Icon from "components/elements/icon";
import { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import PoolProspectsSource from "apps/volunteers/sources/pool-prospects-source";
import PoolAssignmentsSource from "apps/volunteers/sources/pool-assignments-source";
import EverTrue from "app";
import Decorator from "@evertrue/client-decorator";

const delayedRefetchSolicitor = (poolId, contactId, fetchSolicitors) => {
  return window.setTimeout(() => {
    return fetchSolicitors(poolId, [contactId]);
  }, 1000);
};

const handlePaginate = poolId => {
  PoolProspectsSource.paginate(poolId);
};

const handleRemoveFromPool = (assignment, fetchSolicitors) => {
  EverTrue.Alert.confirm(
    {
      headline: "Are you sure?",
      content:
        "If this prospect is currently assigned to a volunteer, removing them from the pool will also remove that assignment.",
    },
    function(did_confirm) {
      if (did_confirm) {
        const { pool_id, prospect_contact_id, solicitor_contact_id } = assignment;
        return PoolProspectsSource.promise
          .removeContact(pool_id, prospect_contact_id, solicitor_contact_id)
          .then(() => delayedRefetchSolicitor(pool_id, solicitor_contact_id, fetchSolicitors));
      }
    }
  );
};

const handleSelect = (poolId, contactId) => {
  PoolProspectsSource.select(poolId, contactId);
};

const handleUnassign = (assignment, solicitor_contact, poolId, fetchSolicitors) => {
  EverTrue.Alert.confirm(
    {
      width: 600,
      headline: "Remove Assignment?",
      content: (
        <div className="pool-prospects--unassign">
          <span className="pool-prospects--unassign-header">Are you sure you want to remove this assignment?</span>
          <div className="pool-prospects--remove-assignment-contact-info">
            <span>
              <strong>{Decorator.Contacts.getDetailedName(assignment.contact)}</strong>
            </span>
            {<span>Prospect</span>}
          </div>
          <Icon icon="arrow-right" size={1} />
          <div className="pool-prospects--remove-assignment-contact-info">
            <span>
              <strong>{solicitor_contact.name_full}</strong>
            </span>
            <span>Volunteer Solicitor</span>
          </div>
        </div>
      ),
    },
    did_confirm => {
      if (did_confirm) {
        return PoolAssignmentsSource.promise
          .unassign(poolId, assignment.solicitor_contact_id, assignment.assignment_id)
          .then(() => delayedRefetchSolicitor(poolId, assignment.solicitor_contact_id, fetchSolicitors));
      }
    }
  );
};

const PoolProspectsList = props => {
  const {
    prospects,
    loading,
    prospect_assignment_index,
    has_joint,
    selected_solicitor,
    has_more_prospects,
    selected,
    pool,
    fetchSolicitors,
  } = props;

  const [solicitorContacts, setSolicitorContacts] = useState([]);
  const assignedSolicitorsCountRef = useRef(0);

  useEffect(() => {
    let assignedSolicitorContactIds = [];
    //Check to make sure prospect_assignment_index is filled in because if it is not,
    //then there is nothing to map since it holds all the assignment info which contains
    //the solicitor contact id we need.
    if (prospects && Object.entries(prospect_assignment_index).length > 0) {
      _.each(prospects.items, prospect => {
        const assignment = _.extend({}, prospect_assignment_index[prospect.id], { prospect });
        //Only add solicitor contact ids if they are unique.
        if (
          assignment.solicitor_contact_id &&
          !_.contains(assignedSolicitorContactIds, assignment.solicitor_contact_id)
        ) {
          assignedSolicitorContactIds.push(assignment.solicitor_contact_id);
        }
      });
    }

    //Check to see when there is a unique change in the solicitors assigned count. If so,
    //make the request to graphql to get the names.
    if (
      prospects &&
      assignedSolicitorContactIds.length !== assignedSolicitorsCountRef.current &&
      Object.entries(prospect_assignment_index).length > 0
    ) {
      Api.GRAPHQL.GRAPHQL.post({
        data: JSON.stringify({
          operationName: null,
          query: String.raw`${getContactsFullName}`,
          variables: { ids: _.filter(assignedSolicitorContactIds, id => id !== undefined) },
        }),
      }).then((resp = {}) => {
        const respContacts = resp.data.contacts || [];
        if (respContacts && respContacts.length > 0) {
          const contactIdsWithName = {};
          respContacts.forEach(respContact => {
            contactIdsWithName[respContact.id] = {
              id: respContact.id,
              name_full: respContact.contact_attributes.name_full,
            };
          });
          setSolicitorContacts(contactIdsWithName);
        }
      });
      assignedSolicitorsCountRef.current = assignedSolicitorContactIds.length;
    }
  }, [prospects, prospect_assignment_index]);

  if (_.isEmpty(prospects != null ? prospects.items : undefined)) {
    if (!loading) {
      return (
        <EmptyStateMessage icon="group" text="No Prospects">
          Add constituents as prospects to be solicited by your volunteers.
        </EmptyStateMessage>
      );
    }
  } else {
    return (
      <div>
        {prospects &&
          _.compact(
            _.map(prospects.items, (contact, index) => {
              const assignment = _.extend({}, prospect_assignment_index[contact.id], { contact });

              if (assignment) {
                return (
                  <PoolProspectCard
                    data={assignment}
                    secondary_contact_id={
                      assignment.secondary_prospect_contact_ids ? assignment.secondary_prospect_contact_ids[0] : null
                    }
                    hasJoint={has_joint}
                    key={assignment.compound_id || _.randomKey()}
                    pool={pool}
                    selected_solicitor={selected_solicitor}
                    solicitor={solicitorContacts[assignment.solicitor_contact_id]}
                    selected={_.contains(selected, contact.id)}
                    loading={!loading && PoolAssignmentsStore.getLoading(assignment.assignment_id)}
                    onRemove={() => handleRemoveFromPool(assignment, fetchSolicitors)}
                    onRemoveAssignment={solicitor => handleUnassign(assignment, solicitor, pool.id, fetchSolicitors)}
                    onClick={() => handleSelect(pool.id, contact.id)}
                    fetchSolicitors={fetchSolicitors}
                  />
                );
              }
            })
          )}
        {has_more_prospects && (
          <LinkButton title="Load more" className="pool-prospects--pagination" onClick={() => handlePaginate(pool.id)}>
            {loading ? <Icon icon="spinner" spin={true} /> : "View More"}
          </LinkButton>
        )}
      </div>
    );
  }

  return null;
};

const getContactsFullName = String.raw`
  query($ids: [BigInt!]) {
    contacts(ids: $ids) {
      id
      contact_attributes {
        name_full
      }
    }
  }
`;

PoolProspectsList.propTypes = {
  pool: PropTypes.object.isRequired,
  fetchSolicitors: PropTypes.func,
  selected: PropTypes.array,
  loading: PropTypes.bool,
  selected_solicitor: PropTypes.object,
  prospects: PropTypes.object,
  prospect_assignment_index: PropTypes.object,
  has_joint: PropTypes.bool,
  has_more_prospects: PropTypes.bool,
};

export default PoolProspectsList;
