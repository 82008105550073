import { createRef, Component } from "react";
import _ from "underscore";
import PropTypes from "prop-types";
import { connect } from "@evertrue/et-flux";
import { AdvancedCombobox } from "@evertrue/et-components";
import Decorator from "@evertrue/client-decorator";
import PoolProspectsSource from "apps/volunteers/sources/pool-prospects-source";
import PoolProspectsStore from "apps/volunteers/stores/pool-prospects-store";
import PoolSolicitorsStore from "apps/volunteers/stores/pool-solicitors-store";
import PoolAssignmentsStore from "apps/volunteers/stores/pool-assignments-store";
import PoolAssignmentsSource from "apps/volunteers/sources/pool-assignments-source";
import FeatureStore from "apps/layout/stores/feature-store";
import Loading from "components/elements/loading";
import ActionMenuItem from "components/controls/action-menu-item";
import ListPicker from "apps/lists/components/list-picker";
import Modal from "components/modals/modal";
import PoolContactList from "apps/volunteers/components/pool-contact-list";
import SolicitorSelector from "apps/volunteers/components/solicitor-selector";
import CountOf from "components/formatting/count-of";
import BulkAddStore from "apps/layout/stores/bulk-add-store";
import { WithSolicitorResolver } from "apps/volunteers/components/solicitor-resolver";
import PoolProspectsList from "apps/volunteers/hooks/pool-prospects-list";

const _debounceSearch = _.debounce(PoolProspectsSource.updateFilter, 200);

const mapStateToProps = ({ pool }) => ({
  prospects: PoolProspectsStore.getForPool(pool.id),
  prospects_total: PoolProspectsStore.getTotalForPool(pool.id),
  loading: PoolProspectsStore.getLoading(pool.id),
  selected: PoolProspectsStore.getSelected(),
  filters: PoolProspectsStore.getFilters(),
  has_more_prospects: PoolProspectsStore.hasMoreToLoad(pool.id),
  solicitors: PoolSolicitorsStore.getSolicitorsIndex(pool.id),
  selected_solicitor: PoolSolicitorsStore.getSelected(pool.id),
  prospect_assignment_index: PoolAssignmentsStore.getAssignmentIndex(pool.id),
  loading_assignments: PoolAssignmentsStore.getLoadingAll(),
  has_joint: FeatureStore.hasFeature("joint_assignment"),
  bulk_add_obj: BulkAddStore.getIsBulkAddToPoolInProgress(pool.id),
});

let listRef = createRef();

class PoolProspectsController extends Component {
  componentDidMount() {
    if (this.props.pool.id) {
      PoolProspectsSource.fetch(this.props.pool.id);
      PoolProspectsSource.fetchTotal(this.props.pool.id);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // Clear filtering on selected volunteer if active
    if (prevProps.selected_solicitor && !this.props.selected_solicitor) {
      if (
        (this.props.filters != null ? this.props.filters.assigned : undefined) ===
        (prevProps.selected_solicitor != null ? prevProps.selected_solicitor.id : undefined)
      ) {
        PoolProspectsSource.updateFilter(this.props.pool.id, "assigned", undefined);
      }
    }
  }

  componentWillUnmount() {
    PoolProspectsSource.clearSelection(this.props.pool.id);
    PoolProspectsSource.updateFilter(this.props.pool.id, "assigned");
    PoolProspectsSource.updateFilter(this.props.pool.id, "full_name");
  }

  handleSearch(val) {
    _debounceSearch(this.props.pool.id, "full_name", val);
  }

  handleAddToPool(contact_ids) {
    PoolProspectsSource.addContacts(this.props.pool.id, contact_ids);
  }

  delayedRefetchSolicitor(contactId) {
    return window.setTimeout(() => {
      return this.props.fetchSolicitors(this.props.pool.id, [contactId]);
    }, 1000);
  }

  handleAssign({ contact }) {
    PoolAssignmentsSource.promise
      .assign(this.props.pool.id, contact != null ? contact.id : undefined, this.props.selected)
      .then(() => {
        return this.delayedRefetchSolicitor(contact != null ? contact.id : undefined);
      });
    PoolProspectsSource.clearSelection(this.props.pool.id);
  }

  renderActions({ pool }) {
    return (
      <ActionMenuItem
        title="Add Prospects from a List"
        icon="playlist-add"
        modal={
          <Modal width={400}>
            <ListPicker
              header="Add prospects from a list"
              limit={1000}
              onSelect={(list) => PoolProspectsSource.addContactsFromList(pool.id, list)}
            />
          </Modal>
        }
      >
        Add Prospects from a List
      </ActionMenuItem>
    );
  }

  renderTitle({ selected_solicitor, filters, prospects, prospects_total, pool }) {
    const options = [
      { value: undefined, label: "Show All" },
      { value: false, label: "Show Unassigned" },
      { value: true, label: "Show Assigned" },
    ];

    if (selected_solicitor) {
      const name = Decorator.Contacts.getDetailedName(selected_solicitor);
      options.push({
        value: selected_solicitor.id,
        label: `Show Assigned to ${name}`,
      });
    }

    const prospect_totals = !_.isEmpty(filters) && (
      <CountOf dynamicTotal={prospects != null ? prospects.total : undefined} staticTotal={prospects_total} />
    );

    return (
      <div>
        Prospects {prospect_totals}
        <div className="pool-prospects--filter">
          <AdvancedCombobox
            compact={true}
            className="advanced-combobox-title"
            value={{ value: filters != null ? filters.assigned : undefined }}
            options={options}
            title="prospect filters"
            onChange={(selected) => {
              PoolProspectsSource.updateFilter(pool.id, "assigned", selected.value);
              return this.list != null ? (this.list.scrollTop = 0) : undefined;
            }}
          />
        </div>
      </div>
    );
  }

  render() {
    const { bulk_add_obj, selected, loading, loading_assignments, pool } = this.props;

    const is_bulk_add =
      (bulk_add_obj != null ? bulk_add_obj.in_progress : undefined) &&
      (bulk_add_obj != null ? bulk_add_obj.type : undefined) === "prospects";

    return (
      <div className="pool-prospects">
        <PoolContactList
          className="pool-prospects--list"
          title={this.renderTitle(this.props)}
          label="Prospects"
          actions={this.renderActions(this.props)}
          ref={listRef}
          controls={
            <SolicitorSelector
              key={pool.id}
              poolId={pool.id}
              selected={selected}
              onAssign={this.handleAssign.bind(this)}
            />
          }
          onAdd={this.handleAddToPool.bind(this)}
          onSearch={this.handleSearch.bind(this)}
        >
          {(loading || loading_assignments || is_bulk_add) && (
            <Loading text={is_bulk_add ? "Adding Prospects to Pool" : ""} />
          )}
          <div className="pool-contact--list-content">{<PoolProspectsList {...this.props} />}</div>
        </PoolContactList>
      </div>
    );
  }
}

PoolProspectsController.propTypes = {
  pool: PropTypes.object.isRequired,
  bulk_add_obj: PropTypes.object,
  fetchSolicitors: PropTypes.func,
  selected: PropTypes.array,
  loading: PropTypes.bool,
  loading_assignments: PropTypes.bool,
  selected_solicitor: PropTypes.object,
  filters: PropTypes.object,
  prospects: PropTypes.object,
  prospect_assignment_index: PropTypes.object,
};

export default connect(
  WithSolicitorResolver(PoolProspectsController),
  [FeatureStore, PoolProspectsStore, PoolSolicitorsStore, PoolAssignmentsStore, BulkAddStore],
  mapStateToProps
);
