import { lazy, Suspense } from "react";
import { Loading } from "@evertrue/et-components";

const PhoneInput = lazy(() => import("components/forms/phone-input"));

const LazyPhoneInput = props => {
  return (
    <Suspense fallback={<Loading />}>
      <PhoneInput {...props} />
    </Suspense>
  );
};

export default LazyPhoneInput;
