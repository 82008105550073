import { useContext } from "react";
import EmailTemplatesModal from "../components/email-templates-modal";
import CreateOrUpdateEmailTemplateModal from "../components/create-or-update-email-template-modal";
import PropTypes from "prop-types";
import { EmailTemplatesContext } from "../hooks/email-templates-context";

const EmailTemplatesModalController = ({ closeModal, emailBodyRef, setSubject, contact }) => {
  const { selectedTemplate, setSelectedTemplate, showEditView, setShowEditView } = useContext(EmailTemplatesContext);

  if (showEditView) {
    return (
      <CreateOrUpdateEmailTemplateModal
        originalTemplate={selectedTemplate}
        setShowEditView={setShowEditView}
        setSelectedTemplate={setSelectedTemplate}
        contact={contact}
      />
    );
  } else {
    return (
      <EmailTemplatesModal
        closeModal={closeModal}
        setShowEditView={setShowEditView}
        setSelectedTemplate={setSelectedTemplate}
        selectedTemplate={selectedTemplate}
        showEditView={showEditView}
        emailBodyRef={emailBodyRef}
        setSubject={setSubject}
        contact={contact}
      />
    );
  }
};

EmailTemplatesModalController.propTypes = {
  closeModal: PropTypes.func,
  emailBodyRef: PropTypes.object,
  setSubject: PropTypes.func,
  contact: PropTypes.object,
};

export default EmailTemplatesModalController;
