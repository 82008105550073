import { PropTypes } from "base/react-libs";
import { useContext } from "react";
import { useFluxStore } from "@evertrue/et-flux";
import { GlobalContactSearchContext } from "apps/layout/controllers/global-contact-search-context";
import FeatureStore from "apps/layout/stores/feature-store";
import FilterStore from "apps/filters/stores/filter-store";
import Loading from "components/elements/loading";
import SelectContactCheckBox from "apps/contact/components/contacts/select-contact-checkbox";
import ScoreProgress from "apps/contact/components/contacts/score-progress";
import SortDropdown from "components/controls/sort-dropdown";
import MapContactCard from "apps/search/components/map-contact-card";
import ContactGiving from "apps/contact/components/contact-card/contact-giving";

const featureStoreMapState = () => ({
  hasScores: FeatureStore.hasFeature("scores"),
});

const filterStoreMapState = () => {
  const types = FilterStore.getActiveFilterByKey("address_type") || [];

  return {
    allowedAddressTypes: types.map((filter) => filter.value),
  };
};

const MapContactList = ({ contacts = {}, loading }) => {
  const { items } = contacts;
  const { allowedAddressTypes } = useFluxStore(FilterStore, filterStoreMapState); // TODO: can we get this information from somewhere else?
  const { hasScores } = useFluxStore(FeatureStore, featureStoreMapState);
  const { updateTableSettings } = useContext(GlobalContactSearchContext);
  const OPTIONS = [
    { label: "Distance from map center", value: "maps.distance" },
    { label: "EverTrue Score", value: "score.score" },
    { label: "Lifetime Giving", value: "giving.lifetime_amount" },
  ];

  const handleMouseOver = (contact) => {
    const { addresses } = contact;
    if (!addresses) return;

    const latLangs = addresses
      .map((address) => {
        const { type, lat, lng } = address;
        if (!type || !lat || !lng) return null;
        const shouldDisplayAddress = !allowedAddressTypes.length || allowedAddressTypes.include(type.value);
        if (lat.value && lng.value && shouldDisplayAddress) return { lat: lat.value, lng: lng.value };

        return null;
      })
      .filter((el) => !!el); // filter out nulls

    if (latLangs.length) highlightIcon(latLangs);
  };

  function highlightIcon(latLangs) {
    // TODO: HIGHLIGHT ICON ON MAP USING THE LATLANGS ARRAY AS INPUT
    return;
  }

  const contactScoreOrIndex = (contact) => {
    let { score } = contact;
    let scoreVal;

    if (score && score.score) scoreVal = score.score.value;

    return (
      <div className="map-contacts--score">
        {hasScores && (
          <>
            <span className="text=label">EverTrue Score:</span>
            <ScoreProgress score={scoreVal} />
          </>
        )}
      </div>
    );
  };

  return (
    <div className="map-contacts--list" tabIndex={-1}>
      {loading || !items ? ( // TODO: empty state?
        <Loading text="Loading..." position="top" className={"loader-icon"} />
      ) : (
        <div>
          <div className="map-contacts--head">
            <SortDropdown
              options={OPTIONS}
              sortProp={contacts.sortProp || "score.score"} // TODO: different default sort?
              sortReverse={contacts.sortReverse}
              onSort={(sortProp, sortReverse) => updateTableSettings({ sortProp, sortReverse })}
            />
          </div>
          <div className="map-contacts--body">
            <table>
              <tbody>
                {items.map((contact) => {
                  if (!contact) return null;

                  return (
                    <tr className="map-contacts--contact" key={contact.id}>
                      <td className="map-contacts--checkbox">
                        <SelectContactCheckBox id={contact.id} name={contact.name} />
                      </td>
                      <td className="map-contacts--card">
                        <MapContactCard contact={contact} onMouseOver={() => handleMouseOver(contact)}>
                          <ContactGiving contact={contact} />
                          {contactScoreOrIndex(contact)}
                        </MapContactCard>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

MapContactList.propTypes = {
  contacts: PropTypes.object,
  loading: PropTypes.bool,
};

export default MapContactList;
