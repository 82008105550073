import EverTrue from "app";
import PropTypes from "prop-types";
import { useGate } from "components/is-gated";
import NavigationSubItem from "apps/layout/components/navigation-sub-item";
import { useIdentity } from "base/identity-resolver";

const NavigationTableauLink = ({ active_path = "" }) => {
  const [enabled, loaded] = useGate("tableau_enabled");
  const { isSuperUser } = useIdentity();

  if (!loaded && !isSuperUser) return null;
  if (enabled || isSuperUser) {
    return (
      <NavigationSubItem
        icon="insights"
        label="Clarity"
        isActive={!!active_path.match(new RegExp(`clarity.*`))}
        onClick={() => {
          EverTrue.track.set("tab_view", { type: "clarity" });
          EverTrue.Navigator("/clarity", true);
        }}
      />
    );
  } else {
    return null;
  }
};

NavigationTableauLink.propTypes = {
  active_path: PropTypes.string,
};

export default NavigationTableauLink;
