import { Component } from "react";
import EverTrue from "app";
import PropTypes from "prop-types";
import PoolsSource from "apps/volunteers/sources/pools-source";
import PoolFormModalBody from "apps/volunteers/components/pool-form-modal-body";
import ErrorLogger from "entities/helpers/error-log-helper";

class CreatePoolFormModal extends Component {
  static propTypes = {
    afterCreateCb: PropTypes.func,
    pool: PropTypes.object,
  };

  render() {
    return (
      <PoolFormModalBody
        {...this.props} // forward sneaky props
        header="Create Pool"
        buttonLabel="Create"
        pool={this.props.pool}
        handleSubmit={async ({ temp_pool }) => {
          try {
            const pool_id = await PoolsSource.promise.create(temp_pool);
            if (this.props.afterCreateCb) {
              this.props.afterCreateCb(pool_id);
            } else {
              EverTrue.Navigator(`/volunteers/pool/${pool_id}`);
            }
          } catch (error) {
            console.log({ error });
            ErrorLogger.captureRequest("Error creating pool", error);
          }
        }}
      />
    );
  }
}

export default CreatePoolFormModal;
