import { useState, useRef } from "react";
import EverTrue from "app";
import Decorator from "clientDecorator";
import PropTypes from "prop-types";
import FormField from "components/forms/form-field";
import { AdvancedCombobox } from "@evertrue/et-components";
import Icon from "components/elements/icon";
import Loading from "components/elements/loading";
import useAIEmailTemplate from "apps/proposals/hooks/use-ai-email-template";

const AIEmailTemplate = ({ title, contact, promptKey }) => {
  const { id: contactId } = contact;
  const [selectedEmail, setSelectedEmail] = useState("");
  const { emailText, isStreaming } = useAIEmailTemplate({ promptKey, contactId });

  const contactInfo = Decorator.Profile.getContactInfo(contact);
  const primaryEmail = contactInfo.emails.find((emailInfo) => emailInfo.primary);

  const emailOptions = contactInfo.emails.map((emailInfo) => ({
    value: emailInfo.email,
    label: (
      <div className="custom-option">
        <span className="custom-option-left">{emailInfo.email}</span>
        <span className="custom-option-right">
          {emailInfo.primary ? `(${emailInfo.type})Primary` : `${emailInfo.type}`}
        </span>
      </div>
    ),
  }));

  /**
   * CustomFormatSelection is used to show the selection label in our own custom way.
   * By default the selection will be the primary email of the constituent
   */
  const customFormatSelection = (selectionText, selection, placeholder, open) => {
    const createSelectedHeader = (type, email) => (
      <div className="dropdown-selected-header" style={{ alignItems: "center", display: "flex" }}>
        <div className="dropdown-selected-text">
          {type && <span className="selected-text--type">{type}</span>}
          <span ref={emailRef} className="selected-text--email">
            {email}
          </span>
        </div>
        <div style={{ marginLeft: "auto" }}>
          <span className="gt-chevron-icon">{open ? <Icon icon="chevron-up" /> : <Icon icon="chevron-down" />}</span>
        </div>
      </div>
    );

    if (selection) {

      const emailAndType = selectionText.props.children.reduce((result, child) => {
        if (child.props.className === "custom-option-left") {
          result.email = child.props.children;
        } else if (child.props.className === "custom-option-right") {
          result.type = child.props.children;
        }
        return result;
      }, {});

      const { email, type } = emailAndType;

      if (email && type) {
        return createSelectedHeader(type, email);
      }

    } else if (primaryEmail) {
      const { type, email } = primaryEmail;
      return createSelectedHeader(`(${type})Primary`, email);
    } else {
      return createSelectedHeader(null, placeholder);
    }
  };

  const emailTextRef = useRef(null);
  const emailRef = useRef(null);

  const handleCopyToClipboard = async (text, successMessage, errorMessage) => {
    try {
      await navigator.clipboard.writeText(text);
      EverTrue.Alert.success(successMessage);
    } catch {
      EverTrue.Alert.error(errorMessage);
    }
  };

  const handleTemplateCopy = () => {
    const emailText = emailTextRef.current.innerText;
    handleCopyToClipboard(
      emailText,
      `${title} email copied to clipboard!`,
      "There was a problem copying the email template. Please manually copy it."
    );
  };

  const handleEmailCopy = () => {
    const email = emailRef.current.innerText;
    handleCopyToClipboard(
      email,
      "Email Address copied to clipboard!",
      "There was a problem copying the email address. Please manually copy it."
    );
  };

  return (
    <div className="email-template-ai">
      <p className="email-template-ai--description">
        Customize your template: This AI-generated email template is a solid starting point, but it's designed for your
        personal touch. Copy-paste the email suggestion into your preferred email service. Then, tweak and adjust it to match your unique message.
      </p>
      <div className="email-template-ai--container">
        <div className="email-template-ai--container-header">
          <button className="email-template-ai--copy-button" onClick={handleTemplateCopy}>
            <img src="/images/copy_clip_icon.png" alt="Copy Icon" className="email-template-ai--copy-icon fs-exclude-data" />
            Copy Email
          </button>
        </div>
        {isStreaming && !emailText ? (
          <div className="loader-div">
            <Loading spinner_size="small" position="center" />
          </div>
        ) : (
          <p ref={emailTextRef} className="email-template-ai--text fs-exclude-data">
            {emailText}
          </p>
        )}
      </div>
      <FormField inlineLabel={false} label="Email Address">
        <div className="email-template--dropdown fs-exclude-data">
          <AdvancedCombobox
            className="et--advanced-combobox--compact"
            value={selectedEmail}
            options={emailOptions}
            onChange={(selectedOption) => {
              setSelectedEmail(selectedOption);
            }}
            placeholder="Select email address.."
            formatSelection={customFormatSelection}
          />
          <div className="copy-text" onClick={handleEmailCopy}>
            Copy
          </div>
        </div>
      </FormField>
    </div>
  );
};

AIEmailTemplate.propTypes = {
  title: PropTypes.string,
  contact: PropTypes.object,
  promptKey: PropTypes.string,
};

export default AIEmailTemplate;
