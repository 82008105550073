module.exports = do ->
   _ = require("underscore").default

   # identify LI standard LI properties but don't pick up custom fields with the string "linkedin"
   isLinkedinFacet = (key = "") ->
      _.first(key.split(".")) in ["linkedin", "linkedin_positions"]

   # identify LI standard LI properties but don't pick up custom fields with the string "linkedin"
   isEnrichedFacet = (key = "") ->
      _.first(key.split(".")) in ["enrichment"]

   FacetUtils =
      LINKEDIN_PREFIX: "li"
      SELECTED_PREFIX: "sel"
      ENRICHED_PREFIX: "en"

      wrapValue: (key, value) ->
         if isLinkedinFacet(key)
            @wrapLinkedinValue(value)
         else if isEnrichedFacet(key)
            @wrapEnrichedValue(value)
         else value

      wrapLinkedinValue: (value) ->
         "#{@LINKEDIN_PREFIX}(#{value})"

      wrapEnrichedValue: (value) ->
         "#{@ENRICHED_PREFIX}(#{value})"

      parseToGroups: (facet_object, config) ->
         _.compact _.map facet_object, (facets, key)  =>
            label = _.findWhere(config?.facets.fields, {value: key} )?.group_label
            prefix =
               if isLinkedinFacet(key) then @LINKEDIN_PREFIX
               else if isEnrichedFacet(key) then @ENRICHED_PREFIX else undefined
            items = @parseToOptions(facets, prefix)
            return if _.isEmpty(items)
            {label, value: key, items: items}

      parseToOptions: (facets, prefix) ->
         _.map facets, (facet) =>
            label: facet.label || facet.value
            value: if prefix then "#{prefix}(#{facet.value})" else facet.value
            icon: if (prefix == @LINKEDIN_PREFIX || @isLinkedin(facet.value)) then "fa fa-fw fa-linkedin-square"

      parseToQuery: (list) ->
         _.map list, (item) => @parseValueToString(item)

      parseValueToString: (value) ->
         linkedin_item = value.match(/// ^#{@LINKEDIN_PREFIX}\((.*)\) ///)
         enriched_item = value.match(/// ^#{@ENRICHED_PREFIX}\((.*)\) ///)
         selected_item = value.match(/// ^#{@SELECTED_PREFIX}\((.*)\) ///)

         if linkedin_item then linkedin_item[1]
         else if enriched_item then enriched_item[1]
         else if selected_item then selected_item[1]
         else value

      isLinkedin: (value) ->
         !!value?.match(/// ^#{@LINKEDIN_PREFIX}\((.*)\) ///)

      isEnriched: (value) ->
         !!value?.match(/// ^#{@ENRICHED_PREFIX}\((.*)\) ///)

      filterSelected: (facets, selected) ->
         values = _.pluck(selected, "value")
         _formatItems = (items) ->
            _.filter items, (item) -> !_.contains(values, item.value)

         if _.first(facets)?.items
            _.compact _.map _.clone(facets), (group) ->
               group.items = _formatItems(group.items)
               if _.isEmpty(group.items) then undefined else group
         else
            _formatItems(facets)
