module.exports = do ->
   React = require("react")
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   {div, i, span} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   Dropdown = createFactory require("components/controls/dropdown")


   # TODO: Is this name ok?
   createComponent "AdvancedDropdown",
      propTypes:
         label: ReactLibs.PropTypes.string
         header: ReactLibs.PropTypes.any
         placeholder: ReactLibs.PropTypes.string
         disabled: ReactLibs.PropTypes.bool
         onOpen: ReactLibs.PropTypes.func
         onClose: ReactLibs.PropTypes.func
         focus: ReactLibs.PropTypes.bool

      getDefaultProps: ->
         label: "Select"

      getInitialState: ->
         is_open: false

      handleOnOpen: ->
         @setState {is_open: true}
         @props.onOpen?()

      handleOnClose: ->
         @setState {is_open: false}
         @props.onClose?()

      # Used through refs to close dropdown
      handleClose: ->
         if @depracated_is_mounted
            @refs.dropdown?.handleOutsideClick?()

      renderHeader: ->
         div className: "advanced-dropdown--header",
            if @props.header then @props.header
            else if @props.placeholder
               span className: "text-placeholder",
                  @props.placeholder

            if @state.open then i className: "fa fa-fw fa-angle-up"
            else i className: "fa fa-fw fa-angle-down"

      render: ->
         Dropdown
            label: @props.label
            className: classNames(@props.className, "advanced-dropdown", {"is-opened-up": @state.reverse_open || @props.position == "up"})
            header: @renderHeader()
            stay_open: true
            onOpen: @handleOnOpen
            onClose: @handleOnClose
            disabled: @props.disabled
            ref: "dropdown"
            focus: @props.focus

            div className: "advanced-dropdown--body",
               @props.children
