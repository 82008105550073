module.exports = do ->

   _ = require("underscore").default
   React = require("react")
   EverTrue = require("app")
   {div} = ReactLibs.DOMFactories
   MfaModal = require("base/mfa/mfa-modal")
   MfaStore = require("base/mfa/mfa-store")
   MfaSource = require("base/mfa/mfa-source")
   classSet = require("classnames")
   {createComponent} = require("@evertrue/et-flux")

   createComponent "MfaController",
      registerStores: ->
         @on MfaStore, ->
            status: MfaStore.getStatus()
            error: MfaStore.getError()
            resend_status: MfaStore.getResendStatus()
            number: MfaStore.getNumber()
            show_trust_device: MfaStore.showTrustDevice()
            mfa_prompt_text: MfaStore.getPromptText()

      handleVerify: (code, trust_device) ->
         if @state.show_trust_device
            MfaSource.verify(code, trust_device)
         else
            MfaSource.verify(code)

      handleResend: ->
         MfaSource.requestAdditionalCode()

      render: ->
         div className: classSet("mfa-controller", "is-active": @state.status isnt "inactive"),
            if @state.status isnt "inactive"
               MfaModal
                  status: @state.status
                  error: @state.error
                  resend_status: @state.resend_status
                  number: @state.number
                  showTrustDevice: @state.show_trust_device
                  onVerify: @handleVerify
                  onResend: @handleResend
                  prompt_text: @state.mfa_prompt_text
