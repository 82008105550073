import { Modal } from "@evertrue/et-components";
import EnrichmentModalHeader from "./enrichment-modal-header";
import { PropTypes } from "base/react-libs";
import EnrichmentList from "./enrichment-list";
import moment from "moment";

const ACCESSOR = "type";

const WealthIndicatorsModal = ({
  isOpen,
  closeModal,
  netWorths,
  physicalAssets,
  wealthIndicators,
  shouldShowWealthData,
  simplifiedNetWorthsArray = [],
}) => {
  const lastChange =
    netWorths[0] && netWorths[0].updated_at && shouldShowWealthData
      ? `Last noteworthy change ${moment(new Date(netWorths[0].updated_at)).format("MMM DD, YYYY")}`
      : "";
  const lastCalculated =
    netWorths[0] && netWorths[0].net_worth_last_updated && shouldShowWealthData
      ? `Net worth calculated ${moment(new Date(netWorths[0].net_worth_last_updated)).format("MMM DD, YYYY")}`
      : "";

  return (
    <Modal isOpen={isOpen} closeModal={closeModal} size="500px">
      <EnrichmentModalHeader
        headerText={"Wealth and Assets"}
        closeModal={closeModal}
        subheader={`${lastChange} ${lastCalculated && lastChange && String.fromCharCode(183)} ${lastCalculated}`}
      />
      <div className="profile-enrichment--more-modal">
        {simplifiedNetWorthsArray.length > 0 && (
          <EnrichmentList
            title="Net Worth"
            list={simplifiedNetWorthsArray}
            accessor="value"
            limit={simplifiedNetWorthsArray.length}
            className="profile--enrichment-wealth--networth"
          />
        )}
        {physicalAssets.length > 0 && (
          <EnrichmentList
            title="Physical Assets"
            list={physicalAssets}
            accessor={ACCESSOR}
            limit={physicalAssets.length}
          />
        )}
        {wealthIndicators.length > 0 && (
          <EnrichmentList
            title="Wealth Indicators"
            list={wealthIndicators}
            accessor={ACCESSOR}
            limit={wealthIndicators.length}
          />
        )}
      </div>
    </Modal>
  );
};

WealthIndicatorsModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  netWorths: PropTypes.array,
  physicalAssets: PropTypes.array,
  wealthIndicators: PropTypes.array,
  shouldShowWealthData: PropTypes.bool,
  simplifiedNetWorthsArray: PropTypes.array,
};

export default WealthIndicatorsModal;
