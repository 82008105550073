import EverTrue from "app";
import PropTypes from "prop-types";
import { useGate } from "components/is-gated";
import NavigationSubItem from "apps/layout/components/navigation-sub-item";

const NavigationAISearchLink = ({ active_path = "" }) => {
  const [enabled, loaded] = useGate("ai_search");
  const [enabledUnreleased, loadedUnreleased] = useGate("unreleased_feature");
  const hasAiPath = !!active_path.match(/ai.*/);

  if (!loaded || !loadedUnreleased) return null;
  if (enabled && enabledUnreleased) {
    return (
      <div className="ai-base-nav--main-group">
        <NavigationSubItem
          icon="ai-search"
          label="AI Search"
          isActive={hasAiPath}
          onClick={() => {
            EverTrue.track.set("tab_view", { type: "ai_search" });
            EverTrue.Navigator("/ai/search", true);
          }}
          iconKit="gt2"
        />
      </div>
    );
  } else {
    return null;
  }
};

NavigationAISearchLink.propTypes = {
  active_path: PropTypes.string,
};

export default NavigationAISearchLink;
