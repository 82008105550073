module.exports = do ->
   _ = require("underscore").default
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")


   createSource "ProposalSource",
      actions:
         loading: true
         fetchedProposal: (obj) ->
            @require _.isObject(obj), "obj should be paged object"
         fetchedInteractions: (obj) ->
            @require _.isPagedObject(obj), "obj should be paged object"
         loadingInteractions: true
         fetchedBulk: true
         fetchCache: true
         changedProposals: true

      api:
         fetchProposal: (id) ->
            @actions.loading(true)

            Api.SEARCH.PROPOSAL.post
               data: _.jsonStringify {must: [{id: match: id}]}
               params: limit: 1
               success: (resp) =>
                  @actions.fetchedProposal(resp?.items?[0])
                  @actions.loading(false)
               error: =>
                  @actions.loading(false)

         #This is an optimistic update of the proposal object.
         #If new proposal is a known entity, it doesn't have to be fetched.
         #The state for it can be updated directly.
         updateProposal: (proposal) ->
            @actions.fetchedProposal(proposal)

         fetchProposalInteractions: (id, page_data) ->
            @actions.loadingInteractions(true)
            limit = page_data?.limit || 10

            Api.SEARCH.CONTACT_NOTE.post
               data: _.jsonStringify
                  must: [{proposal_id: {match: id}}]
                  sort: [{date_occurred: {order: "desc"}}]
               params:
                  limit: limit
                  offset: if page_data then page_data.offset + limit else 0
               success: (resp) =>
                  @actions.fetchedInteractions(resp)
                  @actions.loadingInteractions(false)
               error: =>
                  @actions.loadingInteractions(false)

         fetchCache: (proposal_ids) ->
            @actions.fetchCache(proposal_ids)

         fetchBulk: (proposal_ids) ->
            Api.SEARCH.PROPOSAL.post
               data: _.jsonStringify
                  must: [
                     {id: in: _.makeArray(proposal_ids)}
                  ]
               success: (resp) =>
                  @actions.fetchedBulk(resp.items)

         changeProposalCache: ->
            @actions.changedProposals()
