import PropTypes from "prop-types";

export default function InteractionHyperlink({ linkResult = {}, links }) {
  const { plainLinks, emailLinks } = linkResult;
  return (
    <>
      {plainLinks &&
        plainLinks.map((l) => (
          <li key={l}>
            <a href={l} target="_blank" rel="noreferrer" className="link-list">
              {l}
            </a>
          </li>
        ))}
      {links &&
        links.map((link) => (
          <li key={link}>
            <a href={link} target="_blank" rel="noreferrer" className="link-list-interaction">
              {link}
            </a>
          </li>
        ))}
      {emailLinks &&
        emailLinks.map((email) => (
          <li key={email}>
            <a href={`mailto:${email}`} target="_blank" rel="noreferrer" className="link-list">
              {email}
            </a>
          </li>
        ))}
    </>
  );
}

InteractionHyperlink.propTypes = {
  linkResult: PropTypes.any,
  links: PropTypes.string,
};
