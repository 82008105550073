module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   {array, func} = ReactLibs.PropTypes
   {createComponent} = require("@evertrue/et-flux")
   {ul, li} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   Icon = createFactory (require("components/elements/icon").default)
   classNames = require("classnames")

   createComponent "TagList",
      propTypes:
         items: array # {name: string, isError: bool, id: string/number}
         onRemove: func # args:  (item)

      render: ->
         ul className: classNames("tag-input--tags tag-list", @props.className),
            _.map @props.items, (item) =>
               li
                  key: "tag_" + item.id + (item.name || "")
                  className: classNames("tag-list--item", item.className, "is-error": item.isError)
                  item.name
                  Icon
                     icon: "cancel"
                     size: 1
                     after: true
                     onClick: => @props.onRemove?(item)
