/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import useDataStream from "entities/helpers/use-data-stream";
import EverTrue from "app";

const useAIEmailTemplate = ({ promptKey, contactId }) => {
  const { openDataStream, response, isStreaming } = useDataStream();
  const [emailText, setEmailText] = useState("");

  useEffect(() => {
    async function getData() {
      try {
        const options = {
          method: "POST",
          endpoint:
            `ai/v1/completions`,
          params: {
            append_request_id: false,
          },
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
          },
          data: JSON.stringify({
            prompt: {
              key: promptKey,
              args: {
                id: contactId,
              },
            },
          }),
        }
        await openDataStream(options);
      }
      catch (err) {
        EverTrue.Alert.error("AI email generation failed.");
      }
    }
    getData();
  }, []);

  useEffect(() => {
    setEmailText((prevEmailText) => prevEmailText + response);
  }, [response]);

  return {
    emailText,
    isStreaming,
  };
};

export default useAIEmailTemplate;
