module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   EverTrue = require("app")
   AuthApi = require("entities/auth/auth-api")

   class AffiliationInvite extends AuthApi.Model
      urlRoot: "auth/affiliation_invitations"

      defaults:
         contact:
            avatar: undefined
         avatar: undefined
         is_invite: true

      getServerAttributes: ->
         _.pick @toJSON(), "role_ids", "email"


   class AffiliationInvites extends AuthApi.Collection
      urlRoot: "auth/affiliation_invitations"
      model: AffiliationInvite

      # Filter only pending invites
      parse: (data) ->
         _.filter data, (data) -> !data.accepted_at

      send: (email, roles, options={}) ->
         model = new @model
            email: email
            role_ids: roles
         model.save(null, options)

      sendBulk: (emails, roles, options) ->
         requests = _.map emails, (email) =>
            @send email, roles, $.extend {}, options,
               disableAlerts: true
         $.when.apply(@, requests)
