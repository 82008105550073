import _ from "underscore";
import EverTrue from "app";
import { useState, useContext, useEffect, Fragment } from "react";
import Api from "entities/helpers/api";
import { usePromiseResolver } from "@evertrue/promise-resolver";
import { Modal2, Loading } from "@evertrue/et-components";
import ProspectStageColumn from "apps/portfolio-performance/components/prospect-stage-column";
import PortfolioMetaDataContext from "apps/portfolio-performance/contexts/portfolio-meta-data-context";
import ProspectSelectionContext from "apps/portfolio-performance/contexts/prospect-selection-context";
import { GlobalPortfolioContext } from "apps/portfolio-performance/contexts/global-portfolio-context";
import { DNDControllerWithoutList } from "components/controls/dnd/dnd-controller";
import ConfirmStageMoveModals from "apps/portfolio-performance/components/confirm-stage-move-modals";
import { useContactAssignmentContext } from "../contexts/contact-assignment-context";
import { useIdentity } from "base/identity-resolver";
import EmptyStateMessage from "components/lists/empty-state-message";

const PortfolioStageController = () => {
  const { isOwner, isSuperUser } = useIdentity();
  const [loading, setLoading] = useState(false);
  const [show_stage_change_modal, setShowStageChangeModal] = useState(false);
  const [assignment_being_changed, setAssignmentBeingChanged] = useState({});
  const {
    team,
    stages,
    stagesData,
    solicitor = {},
    loading_solicitor,
    total,
    teams,
  } = useContext(PortfolioMetaDataContext);
  const { selected_all, selected_columns, onSelectColumn, onSelectContact } = useContext(ProspectSelectionContext);
  const { assignmentType } = useContext(GlobalPortfolioContext);
  const { prospects_with_assignments_by_stage } = useContactAssignmentContext();
  const isLoading = loading || loading_solicitor;

  useEffect(() => {
    EverTrue.track.setReferrer("portfolio_performance");
    EverTrue.track.set("viewed", { type: "portfolio_performance" });
  }, []);

  // Load average times in stages for columns
  const [avgTimesInStage = {}] = usePromiseResolver(() => {
    if (team.id && solicitor.id) {
      return Api.VOLUNTEER.AVG_TIME_IN_STAGE.get({
        urlArgs: {
          pool_id: team.id,
          solicitor_contact_id: solicitor.id,
        },
      }).then((stages) => {
        const avg_times_by_stage = {};
        stages.forEach(({ stage, average_seconds_in_stage }) => {
          avg_times_by_stage[stage] = average_seconds_in_stage;
        });
        return avg_times_by_stage;
      });
    }
  }, [team.id, solicitor.id]);

  const handleChangeAssignmentStage = (contact_id, old_stage, new_stage) => {
    const { id, stage_group_id } = stagesData.find((item) => item.stage === new_stage);
    // the DND cards use the contact id as it's key/identifier b/c we render contacts before assignments
    // and if we try to use assignment_id we will get some key warnings that could be a problem b/c of DnD
    const contact_assignment = (prospects_with_assignments_by_stage[old_stage] || []).find(
      (obj) => obj.id === parseInt(contact_id, 10)
    );
    const { assignment: { assignment_id } = {} } = contact_assignment;
    if (!assignment_id) return;
    const data = {
      assignment_id,
      stage: new_stage,
      stage_id: id,
      stage_group_id: stage_group_id,
      prospect_contact_id: contact_id,
      solicitor_contact_id: solicitor.id,
    };
    setAssignmentBeingChanged(data);
    setShowStageChangeModal(true);
    setLoading(true);
  };
  if (_.isEmpty(stages) && !isLoading) {
    return <div className="portfolio-performance--no-stages">No stages have been set.</div>;
  } else if (!isLoading && !teams.length) {
    return <EmptyStateMessage icon="ban" text="You do not have any teams" />;
  } else {
    return (
      <Fragment>
        <div
          className={`portfolio-performance--body-wrap ${isOwner || isSuperUser ? "is-owner" : null} `}
          style={{
            top: `${(isOwner || isSuperUser) ? "225px" : "185px"}`,
          }}
        >
          <DNDControllerWithoutList className="portfolio-performance--body" onReorder={handleChangeAssignmentStage}>
            {isLoading ? (
              <Loading />
            ) : (
              _.map(stages, (stage) => (
                <ProspectStageColumn
                  key={stage}
                  team={team}
                  stage={stage}
                  avg_time={avgTimesInStage[stage]}
                  portfolioHasProspects={!!total}
                  selected_all={selected_all || (selected_columns[stage] ? selected_columns[stage].all : false)}
                  selected_ids={selected_columns[stage] ? selected_columns[stage].ids : []}
                  onSelectColumn={onSelectColumn}
                  onSelectContact={onSelectContact}
                  onSetLoading={(loading) => setLoading(loading)}
                />
              ))
            )}
          </DNDControllerWithoutList>
        </div>
        <Modal2 isOpen={show_stage_change_modal} onClose={() => setShowStageChangeModal(false)}>
          <ConfirmStageMoveModals
            assignment_being_changed={assignment_being_changed}
            onCancel={() => {
              setShowStageChangeModal(false);
              setLoading(false);
            }}
            onStopLoading={() => setLoading(false)}
            assignmentType={assignmentType}
          />
        </Modal2>
      </Fragment>
    );
  }
};

export default PortfolioStageController;
