import { isFunction } from "underscore";
import { Component } from "react";
import HelpTip from "components/tooltip/helptip";
import Icon from "components/elements/icon";
import classNames from "classnames";

class Module extends Component {
  static propTypes = {
    icon: ReactLibs.PropTypes.string,
    header: ReactLibs.PropTypes.oneOfType([
      ReactLibs.PropTypes.string,
      ReactLibs.PropTypes.object,
      ReactLibs.PropTypes.func,
    ]),
    noMargin: ReactLibs.PropTypes.oneOfType([ReactLibs.PropTypes.string, ReactLibs.PropTypes.bool]),
    help_key: ReactLibs.PropTypes.string,
    help_position: ReactLibs.PropTypes.string,
    className: ReactLibs.PropTypes.string,
    children: ReactLibs.PropTypes.any,
    additional_header_content: ReactLibs.PropTypes.any,
    graytitle: ReactLibs.PropTypes.bool, // Added prop for gray900 title
  };

  static defaultProps = {
    icon: "",
    header: "",
    noMargin: false,
    help_key: "",
    help_position: "",
    className: "",
    children: null,
    additional_header_content: "",
    graytitle: false,
  };

  renderIcon() {
    const { icon } = this.props;
    if (icon.match("fa fa-")) {
      return <i className={classNames("module--icon", icon)} />;
    }
    return <Icon icon={icon} className="module--icon" />;
  }

  renderAdditionalContent() {
    const content = this.props.additional_header_content;
    if (isFunction(content)) {
      return content();
    }
    return content;
  }

  renderHeader() {
    const { icon, header, help_key, graytitle } = this.props;

    if (isFunction(header)) {
      return <div className="module--header">{header()}</div>;
    } else if (header || icon) {
      return (
        <div className={classNames("module--header", { "has-icon": !!icon })}>
          <h1 className={classNames("module--header-title", { "module--header-title-gray": graytitle })} tabIndex={-1}>
            {icon && this.renderIcon()}
            {header}
            {help_key ? <HelpTip help_key={help_key} position={this.props.help_position} /> : null}
          </h1>
          {this.renderAdditionalContent()}
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <div className={classNames("module", this.props.className)}>
        {this.renderHeader()}
        <div className={classNames({ "module--body": !this.props.noMargin })}>{this.props.children}</div>
      </div>
    );
  }
}

export default Module;
