module.exports = do ->
   _ = require("underscore").default
   {createComponent} = require("@evertrue/et-flux")
   React = require("react")
   {createFactory} = require("base/new-utils")
   AdvancedCombobox = createFactory(require("@evertrue/et-components").AdvancedCombobox)

   createComponent "LabelSelector",
      propTypes:
         className: ReactLibs.PropTypes.string
         value: ReactLibs.PropTypes.array
         options: ReactLibs.PropTypes.array
         default_label: ReactLibs.PropTypes.string
         searchable: ReactLibs.PropTypes.bool
         allowUnselectAll: ReactLibs.PropTypes.bool
         allowChangeAll: ReactLibs.PropTypes.bool
         onChange: ReactLibs.PropTypes.func

      getDefaultProps: ->
         default_label: "All Labels"
         searchable: true

      handleLabelSelection: (labels) ->
         if labels.length > 1 && _.last(labels)?.value == "all"
            label_values = []
         else
            label_values = _.reject labels, (label) -> label?.value == "all"

         if _.isFunction(@props.onChange)
            @props.onChange(label_values)

      render: ->
         all_value = {label: @props.default_label, value: "all"}
         options = [label: "", items: [all_value]]
         options = options.concat(@props.options)

         AdvancedCombobox
            className: @props.className
            options: options
            onChange: @handleLabelSelection
            value: if _.isEmpty(@props.value) then [all_value] else @props.value
            multiple: true
            grouped: true
            position: @props.position
            allowUnselectAll: @props.allowUnselectAll
            allowChangeAll: @props.allowChangeAll
            searchable: @props.searchable
            onSearch: @props.onSearch
            header: @props.header
