const _ = require("underscore").default;
const EverTrue = require("app");
const { createSource } = require("@evertrue/et-flux");

let _initialized = false;

module.exports = createSource("SelectedContactsSource", {
  actions: {
    clearContacts: true,
    selectContacts(contacts) {
      this.require(_.isArray(contacts), "contacts should be array");
    },
    unselectContacts(contacts) {
      this.require(_.isArray(contacts), "contacts should be array");
    },
    toggleSelection(contacts, isSelected) {
      this.require(_.isArray(contacts), "contacts should be array");
      this.require(_.isArray(isSelected), "isSelected should be bool");
    },
    toggleSelectedIndicator(bool) {
      this.require(_.isBoolean(bool), "bool should be bool");
    },
    selectAll(bool) {
      this.require(_.isBoolean(bool), "bool should be bool");
    },
  },

  init() {
    if (_initialized) return;

    _initialized = true;
    EverTrue.vent.on("route:change", this.clear);
  },

  api: {
    select(ids) {
      this.init();
      this.actions.selectContacts(_.makeArray(ids));
    },
    
    unselect(ids) {
      this.actions.unselectContacts(_.makeArray(ids));
    },
    
    toggleSelection(ids, isSelected) {
      if(isSelected){
        this.select(ids)
      } else {
        this.unselect(ids)
      }
    },

    clear() {
      this.actions.clearContacts();
    },

    toggleSelectedIndicator(bool) {
      this.actions.toggleSelectedIndicator(bool);
    },

    selectAll(bool) {
      this.actions.selectAll(bool);
    },

    clearAllSelectedState() {
      this.selectAll(false);
      this.toggleSelectedIndicator(false);
      this.clear();
    },
  },
});
