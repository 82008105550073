module.exports = do ->
   _ = require("underscore").default
   classNames = require("classnames")
   React = require("react")
   {h3, span, div} = ReactLibs.DOMFactories
   Loading = require("components/elements/loading")
   {createFactory} = require("base/new-utils")
   IconButton = createFactory(require("@evertrue/et-components").IconButton)
   Button = createFactory(require("@evertrue/et-components").Button)


   ModalConfirm = ReactLibs.createClass
      displayName: "ModalConfirm"
      propTypes:
         type: ReactLibs.PropTypes.string
         actionButtonType: ReactLibs.PropTypes.string
         header: ReactLibs.PropTypes.any
         buttonLabel: ReactLibs.PropTypes.any
         cancelLabel: ReactLibs.PropTypes.any
         hasMargin: ReactLibs.PropTypes.bool
         onSubmit: ReactLibs.PropTypes.func
         onCancel: ReactLibs.PropTypes.func
         hideOnSubmit: ReactLibs.PropTypes.bool
         hide_close_icon: ReactLibs.PropTypes.bool
         is_saving: ReactLibs.PropTypes.bool
         hideCancelButton: ReactLibs.PropTypes.bool,

      getDefaultProps: ->
         hide_close_icon: false
         hideOnSubmit: true
         disableButton: false
         disableCancelButton: false
         hideCancelButton: false
         style: {}
         actionButtonType: "default"

      handleSubmit: ->
         if _.isFunction(@props.onSubmit) and !@props.disableButton
            @props.onSubmit()
            @handleHide() if @props.hideOnSubmit

      handleCancel: ->
         if !@props.disableCancelButton
            @props.onCancel?()
            @handleHide()

      handleHide: ->
         if _.isFunction @props.requestHide
            @props.requestHide()

      render: ->
         buttonActionClass = classNames
            "modal-confirm--submit": true

         buttonCancelClass = classNames
            "modal-confirm--cancel": true

         bodyClass = classNames "modal-confirm--body",
            "modal-confirm--content": @props.hasMargin


         div className: "modal-confirm " + (@props.className || ""), style: @props.style,
            div className: "modal-confirm--header",
               h3 null,
                  @props.header
                  if !@props.hide_close_icon
                     IconButton title: "Close modal", className: "modal-confirm--close", icon: "close", onClick: @handleCancel

            div className: bodyClass,
               if _.isFunction(@props.render)
                  @props.render(hide: @props.requestHide)

               else if @props.children.type
                  React.cloneElement @props.children,
                     requestHide: @props.requestHide

               else @props.children

            if @props.onSubmit
               div className: "modal-confirm--footer",
                  if !@props.hideCancelButton
                     Button type: "simple", className: buttonCancelClass, onClick: @handleCancel, disabled: @props.disableCancelButton,
                        @props.cancelLabel || "Cancel"

                  Button type: @props.actionButtonType, className: buttonActionClass, onClick: @handleSubmit, disabled: @props.disableButton,
                     span null,
                        if @props.is_saving
                           Loading spinner_size: "small", position: "center"
                        @props.buttonLabel

                  if @props.helperText
                     div className: "modal-confirm--helper-text",
                        @props.helperText


   return ModalConfirm
