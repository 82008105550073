import { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Icon from "components/elements/icon";
import AddToPoolModal from "apps/volunteers/components/add-to-pool-modal";
import SearchBox from "components/forms/search-box";
import ActionMenu from "components/controls/action-menu";
import ActionMenuItem from "components/controls/action-menu-item";

class PoolContactList extends Component {
  static propTypes = {
    title: PropTypes.any,
    label: PropTypes.any,
    actions: PropTypes.any,
    actionLabel: PropTypes.any,
    addLimit: PropTypes.number,
    controls: PropTypes.any,
    search: PropTypes.string,
    onSearch: PropTypes.func,
    onAdd: PropTypes.func,
    className: PropTypes.string,
    children: PropTypes.node,
    innerRef: PropTypes.func,
  };

  static defaultProps = { label: "Contacts" };

  renderActionMenu = () => {
    return (
      <ActionMenu
        className="inline"
        label={
          <span>
            <Icon icon="group-add" />
            {this.props.actionLabel ? this.props.actionLabel : undefined}
          </span>
        }
        buttonClass="pool-contact-list--add"
        buttonType="secondary"
      >
        <ActionMenuItem
          icon="group-add"
          modal={
            <AddToPoolModal
              header={`Add ${this.props.label} to Pool`}
              limit={this.props.addLimit}
              onSubmit={this.props.onAdd}
            />
          }
          title={`Add ${this.props.label}`}
        >
          {`Add ${this.props.label}`}
        </ActionMenuItem>
        {this.props.actions}
      </ActionMenu>
    );
  };

  render() {
    return (
      <div className={classNames("pool-contact-list", this.props.className)}>
        <div className="pool-contact-list--header">
          <div className="pool-contact-list--header-title">{this.props.title}</div>
          <div className="pool-contact-list--header-actions">
            {this.props.controls && <div className="pool-contact-list--header-controls">{this.props.controls}</div>}
            {this.props.onSearch ? (
              <SearchBox
                className="pool-contact-list--header-search"
                value={this.props.search}
                placeholder="Search..."
                onChange={this.props.onSearch}
              />
            ) : undefined}
            {this.renderActionMenu()}
          </div>
        </div>
        <div
          className="pool-contact-list--body"
          ref={(e) => (typeof this.props.innerRef === "function" ? this.props.innerRef(e) : undefined)}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default PoolContactList;
