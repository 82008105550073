module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   EverTrue = require("app")
   {createComponent} = require("@evertrue/et-flux")
   OrgStore = require("base/org/org-store").default
   OrgSource = require("base/org/org-source").default
   Decorator = require("clientDecorator")
   Loading = require("components/elements/loading")
   {createFactory} = require("base/new-utils")
   OrgPicker = createFactory(require("@evertrue/et-components").OrgPicker)
   {div, span} = ReactLibs.DOMFactories

   createComponent "OrgSelector",
      propTypes:
         value: ReactLibs.PropTypes.any
         user: ReactLibs.PropTypes.object
         onChange: ReactLibs.PropTypes.func

      getDefaultProps: ->
         user: {}
         value: {}

      registerStores: ->
         @on OrgStore, ->
            orgs: OrgStore.getOrgs()
            loading: OrgStore.getLoading()

      componentDidMount: ->
         OrgSource.fetchForUser(@props.user)

      componentDidUpdate: (prevProps) ->
         if !_.isEqual(prevProps.user?.id, @props.user?.id)
            OrgSource.fetchForUser(@props.user)

      formatOrganizations: (orgs) ->
         _.chain(orgs)
            .filter (org = {}) ->
               org.name && (EverTrue.store?.org?.get("id") != org.id)
            .map (org = {}) =>
               hasAffiliationTo = Decorator.User.hasAffiliationTo(@props.user, org.id)
               {super_user} = @props.user
               label = if super_user
                  "#{org.name}"
               else org.name
               _.extend {
                  value: org.id, label,
                  icon: if !hasAffiliationTo then "fa fa-fw fa-warning icon-muted" else null
               }, org
            .sortBy("label")
            .value()

      render: ->

         div className: "org-selector",
            if _.isEmpty(@state.orgs) && !@state.loading
               span null,
                  "You do not have access to any EverTrue organizations. Please contact an EverTrue Admin for assistance."
            else
               div className: "loading-container",
                  if @state.loading
                     Loading()

               OrgPicker
                  orgs: @formatOrganizations(@state.orgs)
                  user: @props.user
                  handleChange: @props.onChange
