import { useState, useEffect } from "react";
import useDataStream from "entities/helpers/use-data-stream.js";
import Api from "entities/helpers/api";
import EverTrue from "app";
import PropTypes from "prop-types";

const useChat = (contactId, currentIndex, responseVersion = 2) => {
  const { openDataStream, response, isStreaming } = useDataStream();
  const [chat, setChat] = useState({});
  const [aiResponse, setAiResponse] = useState({});

  const createChat = async () => {
    await Api.AI.CREATE_CHAT.post({
      success: ({ chat }) => {
        setChat(chat);
      },
      error() {
        EverTrue.Alert.error("Failed to initialize AI-Profile chat.");
      },
    });
  };

  const sendMessage = async (userInput) => {
    const { id: chatId } = chat;
    try {
      const options = {
        method: "POST",
        endpoint: `ai/v1/chats/${chatId}/messages`,
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
        },
        data: JSON.stringify({
          message: userInput,
          prompt: { args: { id: contactId } },
        }),
        params: {
          "ET-Response-Version": responseVersion,
        },
      };
      await openDataStream(options);
    } catch (error) {
      EverTrue.Alert.error("AI-Profile Chat response failed!");
    }
  };

  useEffect(() => {
    setAiResponse((prevResponses) => {
      const currentResponse = response;
      const newResponses = [...(prevResponses[currentIndex] || []), currentResponse];

      return {
        ...prevResponses,
        [currentIndex]: newResponses,
      };
    });
  }, [response, currentIndex]);

  return { createChat, sendMessage, aiResponse, isStreaming, chat };
};

useChat.propTypes = {
  contactId: PropTypes.number,
  currentIndex: PropTypes.number,
  responseVersion: PropTypes.number,
};

export default useChat;
