module.exports = do ->
   _ = require("underscore").default
   Api = require("entities/helpers/api")
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories
   ListStore = require("apps/lists/stores/list-store")
   {createFactory} = require("base/new-utils")
   AdvancedCombobox = createFactory(require("@evertrue/et-components").AdvancedCombobox)
   ModalConfirm = createFactory require("components/modals/modal-confirm")

   _processLists = (lists) ->
      _.map lists, (list_group) ->
         _.extend {}, list_group,
            value: list_group.id
            items: _.map list_group.items, (list) ->
               {value: list.id, label: list.name, group: list_group.label}


   createComponent "ListPicker",
      propTypes:
         header: ReactLibs.PropTypes.string
         limit: ReactLibs.PropTypes.number
         listId: ReactLibs.PropTypes.number
         onSelect: ReactLibs.PropTypes.func

      getInitialState: ->
         list_id: @props.listId
         loading: false
         warning: undefined

      registerStores: ->
         @on ListStore, ->
            lists: _processLists(ListStore.getLists())

      UNSAFE_componentWillReceiveProps: (newProps) ->
         if newProps.listId != @props.listId
            @setState {list_id: newProps.listId}

      handleSelect: ->
         @props.onSelect(ListStore.getList(@state.list_id))
         @props.requestHide()

      handleChange: (list) ->
         @setState {list_id: list.value, loading: true, warning: undefined}
         if @props.limit
            Api.CONTACTS.LIST_CONTACTS.get
               urlArgs: {id: list.value}
               params: {limit: 1}
               success: (data) =>
                  if data.total > @props.limit
                     @setState
                        loading: false
                        warning: "This list has #{data.total} constituents.
                        You've reached the max amount of allowed constituents,
                        only #{@props.limit} will be added."
               error: =>
                  @setState {loading: false}

      render: ->
         selected_list = _.findWhere _.flatten(_.pluck(@state.lists, "items")), {value: @state.list_id}

         ModalConfirm
            className: "list-picker"
            header: @props.header || "Select a List"
            buttonLabel: "Select"
            hasMargin: true
            saving: @state.loading
            disableButton: !@state.list_id
            onSubmit: @handleSelect
            onCancel: @props.requestHide

            div className: "clearfix",
               AdvancedCombobox
                  placeholder: "Select List...."
                  value: selected_list
                  grouped: true
                  searchable: true
                  options: @state.lists
                  onChange: @handleChange

               if @state.warning
                  div className: "list-picker--warning",
                     @state.warning
