import { Icon } from "@evertrue/et-components";
import { PropTypes } from "base/react-libs";
import { fetchContactsById } from "apps/volunteers/vms-queries";
import { useState, useEffect } from "react";
import { trackJourneysAction } from "apps/journeys/utils/helpers/mixpanel";
import Household from "./details/contact-summary-household";
import Decorator from "clientDecorator";
import JourneyContactCard from "apps/journeys/components/journey-contact-card";
import Interactions from "./details/contact-summary-interactions";
import PinnedComments from "./details/contact-summary-pinned-comments";
import GiftHistory from "./details/contact-summary-gift-history";
import TaskCompletion from "./details/contact-summary-task-completion";

// Takes in a Contact Object from GQL
// Fetches "full" contact object from Contact Search API
// Passes this object through Decorator
const ContactSummary = ({ contactFromGql }) => {
  const [contact, setContact] = useState();

  useEffect(() => {
    const getAndSetContactDetails = async (id) => {
      const { items } = await fetchContactsById({ contactIds: [id] });
      const [fullContact] = items;
      const decoratedContact = Decorator.Contacts.parse(fullContact);
      setContact(decoratedContact);
    };
    if (!contactFromGql) return;
    getAndSetContactDetails(contactFromGql && contactFromGql.id);
  }, [contactFromGql]);

  let linkedInUrl, houseHoldMembers, completedAt;
  if (contact && contactFromGql) {
    const { linkedin: { public_profile_url: { value: linked_in_url = "" } = {} } = {} } = contact;
    const { completed_at, household_members = [] } = contactFromGql; // TODO: this will fail right now

    linkedInUrl = linked_in_url;
    completedAt = completed_at;
    houseHoldMembers = household_members;
  }

  return (
    <div className="contact-summary">
      {contact ? (
        <div className="contact-summary--content">
          <div className="contact-summary--header">
            <div style={{ flex: 1 }}>
              <JourneyContactCard contact={contactFromGql} avatarSize={60} padding={16} />
            </div>
            {houseHoldMembers.length > 0 && <Household contact={contactFromGql} />}
          </div>
          <div className="contact-summary--body">
            {linkedInUrl && (
              <div
                onClick={() => {
                  trackJourneysAction("click_linkedin");
                }}
              >
                <a href={linkedInUrl} target="_blank" rel="noreferrer" className="linkedin-link">
                  <Icon icon="linkedin-2020" className="linkedin-icon" />
                  LinkedIn
                </a>
              </div>
            )}
            {!!completedAt && <TaskCompletion />}
            <GiftHistory contact={contact} />
            <PinnedComments contact={contact} />
            <Interactions contact={contact} />
          </div>
        </div>
      ) : null}
    </div>
  );
};

ContactSummary.propTypes = {
  contactFromGql: PropTypes.object,
};

export default ContactSummary;
