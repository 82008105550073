import { PropTypes } from "base/react-libs";
import DueDatePicker from "../../top-nav/due-date-picker";
import JourneysTabNav from "./journeys-tab-nav";
import JourneysWithStepsDueToggle from "./journeys-with-steps-due-toggle";

const JourneysTableFilters = ({
  tabs = [],
  selectedTab = {},
  setSelectedTab = () => {},
  selectedDueDate,
  setSelectedDueDate = () => {},
  onlyJourneysWithStepsDue = false,
  setOnlyJourneysWithStepsDue = () => {},
}) => {
  return (
    <>
      <JourneysTabNav tabs={tabs} selectedTab={selectedTab} onSelect={(tab) => setSelectedTab(tab)} />
      <div className="journeys-table-filters">
        <DueDatePicker selectedDueDate={selectedDueDate} setSelectedDueDate={setSelectedDueDate} />
        <JourneysWithStepsDueToggle
          onlyJourneysWithStepsDue={onlyJourneysWithStepsDue}
          setOnlyJourneysWithStepsDue={setOnlyJourneysWithStepsDue}
        />
      </div>
    </>
  );
};

JourneysTableFilters.propTypes = {
  tabs: PropTypes.array,
  selectedTab: PropTypes.object,
  setSelectedTab: PropTypes.func,
  selectedDueDate: PropTypes.number,
  setSelectedDueDate: PropTypes.func,
  onlyJourneysWithStepsDue: PropTypes.bool,
  setOnlyJourneysWithStepsDue: PropTypes.func,
};

export default JourneysTableFilters;
