import { Fragment } from "react";
import PropTypes from "prop-types";
import Modal from "components/modals/modal";
import ModalConfirm from "components/modals/modal-confirm";
import ContactCard from "apps/contact/components/contact-card/contact-card";
import { LinkButton } from "@evertrue/et-components";
import EverTrue from "app";

const PoolMembershipModal = ({ contact, remove, onUnmount, memberships = [] }) => {
  const handleLinkClick = pool_id => {
    EverTrue.Navigator(`/volunteers/pool/${pool_id}`, true);
    remove();
  };

  return (
    <Modal width={450} keepOpen remove={remove} onUnmount={onUnmount}>
      <ModalConfirm header="Pool Membership">
        <Fragment>
          <div className="pool-membership-modal">
            <ContactCard contact={contact} showClassyear />
            <hr className="pool-membership-modal--line" />

            <div className="pool-membership-modal--pools">
              <span className="pool-membership-modal--pool-label">Pools:</span>
              <div className="pool-membership-modal--pool-section">
                {memberships.map((obj, index, self) => {
                  const { pool_name, pool_id, assignment_count = 0 } = obj;
                  return (
                    <div key={obj.id} className="pool-membership-modal--assignment-section">
                      <LinkButton onClick={() => handleLinkClick(pool_id)} title={`Link to pool ${pool_name}`}>
                        {pool_name}
                      </LinkButton>
                      <div>
                        {assignment_count} Assignment{assignment_count === 1 ? "" : "s"}
                      </div>
                      {index === self.length - 1 || <hr />}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Fragment>
      </ModalConfirm>
    </Modal>
  );
};

PoolMembershipModal.propTypes = {
  contact: PropTypes.object,
  onUnmount: PropTypes.func,
  remove: PropTypes.func,
  memberships: PropTypes.array,
};

export default PoolMembershipModal;
