import { Link } from "@evertrue/et-components";
import RecommendedActionsSearches from "apps/home/components/recommended-actions/sections/filters/recommend-actions-searches";
import { convertFilterToUrl } from "./filters/filters-parser-util";
import { PropTypes } from "base/react-libs";

const SearchRecommendations = ({ windfallEnabled }) => {
  const featuredSearches = RecommendedActionsSearches;

  const links = featuredSearches
    .flatMap((section) =>
      section.segments.map((segment) => {
        const url = convertFilterToUrl(segment.filter, "contact");

        if (windfallEnabled === segment.windfall || segment.default) {
          return (
            <Link key={segment.id} href={url} title={segment.title}>
              {segment.title}
            </Link>
          );
        }
        return null;
      })
    )
    .filter(Boolean); // Filter out any null values

  return (
    <div className="homepage-recommendations-windfall-content">
      <h2 className="homepage-recommendations-windfall-title">
        We don’t see any assigned constituents with these characteristics today.
      </h2>
      <div className="homepage-recommendations-subtitle">
        Dive into your database with a search of{" "}
        {links.reduce((acc, link, index) => {
          if (index === 0) {
            return [link];
          }
          return [...acc, " or ", link];
        }, [])}
      </div>
    </div>
  );
};

SearchRecommendations.propTypes = {
  windfallEnabled: PropTypes.bool,
};

export default SearchRecommendations;
