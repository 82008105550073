import { useMemo } from "react";
import { PropTypes } from "base/react-libs";
import { getCounterIconComponent } from "apps/journeys/utils/helpers/icon-fetch";
import { countTasksForJourney, percentOfStepTasksComplete } from "apps/journeys/utils/helpers/counters";
import ProgressBarWithLabel from "components/forms/progress-bar-with-label";
import JourneyDetailTableToggle from "./journey-detail-table-toggle";

const JourneyDetailTableHeader = ({ journey = {}, tableView, setTableView = () => {} }) => {
  const { steps } = journey;
  const string = JSON.stringify(steps);
  const completionPercentage = useMemo(() => {
    if (string) return percentOfStepTasksComplete(steps);
    else return 0;
  }, [steps, string]);

  const counts = useMemo(() => {
    if (string) {
      const counts = countTasksForJourney(journey);
      delete counts.Completed; // don't visualize completed tasks here
      return counts;
    } else return {};
  }, [journey, string]);

  const mappedEntries = () => {
    const entries = [];
    const { Total, ...rest } = counts;
    for (const name in rest) {
      const count = counts[name];
      entries.push(
        <div key={`${name}${count}`} className="task-counter-icon">
          {getCounterIconComponent(name)}
          {count}
        </div>
      );
    }

    return entries;
  };

  const mappedTotal = () => {
    const { Total = 0 } = counts;
    return (
      <div className="journeys-task-table-header--item" style={{ flex: 1, paddingLeft: "4px" }}>
        Total: {Total}
      </div>
    );
  };

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div className="journeys-task-table-header">
        {mappedTotal()}
        <div className="journeys-task-table-header--item journeys-task-table-header--icon-group" style={{ flex: 2 }}>
          {mappedEntries()}
        </div>
        <ProgressBarWithLabel
          containerStyle={{ flex: 2 }}
          width={100}
          weight="thin"
          title="Progress"
          inlineLabel={true}
          showPercent={true}
          progressColor="#73c166"
          percent={completionPercentage}
        />
      </div>
      <JourneyDetailTableToggle tableView={tableView} setTableView={setTableView} />
    </div>
  );
};

JourneyDetailTableHeader.propTypes = {
  journey: PropTypes.object,
  tableView: PropTypes.string,
  setTableView: PropTypes.func,
};

export default JourneyDetailTableHeader;
