module.exports = do ->
   React = require("react")
   {strong, div, span} = ReactLibs.DOMFactories
   Avatar = require("components/elements/avatar")
   {createFactory} = require("base/new-utils")
   LinkButton = createFactory(require("@evertrue/et-components").LinkButton)

   UserCard = ReactLibs.createClass
      displayName: "UserCard"

      propTypes:
         user: ReactLibs.PropTypes.object
         disableEmail: ReactLibs.PropTypes.bool
         onClick: ReactLibs.PropTypes.func

      render: ->
         user = @props.user || {}

         div className: "user-card",
            Avatar
               className: "user-card--avatar"
               url: user.avatar
               width: 40
               name: user.name

            div className: "user-card--details", title: "#{user.email} (#{user.id})",
               if user.removed
                  strong className: "user-card--removed-user text-removed-user", user.name
               else if @props.onClick
                  LinkButton title: "Take action on user #{user.name}",  onClick:((=> @props.onClick?(user))),
                     strong className: "user-card--name", user.name
               else
                  strong className: "user-card--name", user.name

               unless @props.disableEmail
                  div className: "user-card--email", user.email

               if user.saml_user_id
                  div className: "user-card--saml ellipsis-overflow",
                     span null, "UID: "
                     user.saml_user_id

               if @props.children
                  @props.children
