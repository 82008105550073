import { useState, useEffect } from "react";
import moment from "moment";
import _ from "underscore";
import PropTypes from "prop-types";
import Api from "entities/helpers/api";
import { SearchInput, FlexTable, FlexTableSortHeaderCell } from "@evertrue/et-components";
import SortDropdown from "components/controls/sort-dropdown";
import PageHeader from "apps/layout/components/page-header";
import FacebookEngagementFilters from "../components/facebook-engagement-filters";
import RecentEngagement from "../components/recent-engagement";
import ModalTrigger from "components/modals/modal-trigger";
import Modal from "components/modals/modal";
import MatchFacebookModal from "apps/facebook/components/match-facebook-modal";
import ErrorLogger from "entities/helpers/error-log-helper";
import ActionMenuItem from "components/controls/action-menu-item";

const FB_IMAGE_URL = "/images/FB-f-Logo__blue_50.png";
const LIMIT = 50;

const getDateTime = (range) => {
  switch (range) {
    case "l-7-d":
      return moment().subtract(7, "days").valueOf();
    case "l-30-d":
      return moment().subtract(30, "days").valueOf();
    case "l-3-m":
      return moment().subtract(3, "months").valueOf();
    case "l-12-m":
      return moment().subtract(12, "months").valueOf();
    default:
      return moment().subtract(3, "months").valueOf();
  }
};

const getDateLabel = (range) => {
  switch (range) {
    case "l-7-d":
      return "7 days";
    case "l-30-d":
      return "30 days";
    case "l-3-m":
      return "3 months";
    case "l-12-m":
      return "year"
    default:
      return "3 months";
  }
};

const FacebookUnmatchedEngagersController = ({ page_id, page_remote_id }) => {
  const [loading, setLoading] = useState(false);
  const [unmatchedEngagers, setUnmatchedEngagers] = useState([]);
  const [engagersTotal, setEngagersTotal] = useState(0);
  const [payloadTotal, setPayloadTotal] = useState(0);
  const [pageName, setPageName] = useState("");
  const [pictureURL, setPictureURL] = useState(FB_IMAGE_URL);
  const [searchText, setSearchText] = useState("");
  const [sortProp, setSortProp] = useState("reactions");
  const [sortReverse, setSortReverse] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeFilters, setActiveFilters] = useState({ date: "l-3-m" });
  const [lastMatchedId, setLastMatchedId] = useState(null);

  const columns = [
    {
      key: 1,
      header: "Unmatched Engager Name",
      accessor: (i) => <strong>{i.name}</strong>,
    },

    {
      key: 2,
      header: (
        <FlexTableSortHeaderCell
          onClick={() => handleSort("recent_engagement")}
          sortOrder={sortReverse}
          isSorted={sortProp === "recent_engagement"}
        >
          Recent Engagement
        </FlexTableSortHeaderCell>
      ),
      accessor: (i) => <RecentEngagement engagement={i} />,
      headerClassName: "facebook-pages-table-head--center",
      cellClassName: "facebook-pages-table--center",
    },
    {
      key: 3,
      header: (
        <FlexTableSortHeaderCell
          onClick={() => handleSort("reactions")}
          sortOrder={sortReverse}
          isSorted={sortProp === "reactions"}
        >
          Reactions
        </FlexTableSortHeaderCell>
      ),

      accessor: (i) => (i.reactions && i.reactions.toLocaleString()) || 0,
      headerClassName: "facebook-pages-table-head--metric",
      cellClassName: "facebook-pages-table--metric",
    },
    {
      key: 4,
      header: (
        <FlexTableSortHeaderCell
          onClick={() => handleSort("comments")}
          sortOrder={sortReverse}
          isSorted={sortProp === "comments"}
        >
          Comments
        </FlexTableSortHeaderCell>
      ),

      accessor: (i) => {
        return (
          <>
            {(i.comments && i.comments.toLocaleString()) || 0}
            <img
              src="/images/fb-reactions-comment.png"
              height="18px"
              width="18px"
              style={{ marginLeft: 15 }}
              alt="facebook comment"
            />
          </>
        );
      },
      headerClassName: "facebook-pages-table-head--metric",
      cellClassName: "facebook-pages-table--metric",
    },
    {
      key: 5,
      header: "Match to Constituent",
      accessor: (i) => {
        const matchModal = (
          <Modal width={570}>
            <MatchFacebookModal
              page={{ id: page_id, remote_id: page_remote_id, page_name: pageName, picture_url: pictureURL }}
              profile={{ id: i.profile_remote_id, name: i.name }}
              postUrl={i.recent_engagement.url}
              engagement={i.recent_engagement}
              onMatch={(profile_remote_id) => {
                setLastMatchedId(profile_remote_id);
              }}
            />
          </Modal>
        );
        return (
          <ModalTrigger
            buttonType="secondary"
            modal={matchModal}
            title="Match to constituent"
            style={{ width: 125, cursor: "pointer" }}
          >
            Match
          </ModalTrigger>
        );
      },
      headerClassName: "facebook-pages-table-head--center",
      cellClassName: "facebook-pages-table--center",
    },
  ];

  useEffect(() => {
    setCurrentPage(1);
  }, [activeFilters]);

  useEffect(() => {
    setLoading(true);

    Api.ENGAGEMENTS.UNMATCHED_ENGAGERS.get({
      urlArgs: { page_id },
      params: _.compactObject({
        from: getDateTime(activeFilters.date),
        limit: LIMIT,
        offset: (currentPage - 1) * LIMIT,
        search: searchText,
        sort: sortProp,
        sort_order: sortReverse ? "desc" : "asc",
      }),
      success: (res) => {
        const unmatchedProfiles = _.reject(
          res.unmatched_engagers,
          (engager) => engager.profile_remote_id === lastMatchedId
        );
        setPictureURL(res.picture_url);
        setUnmatchedEngagers(unmatchedProfiles);
        setEngagersTotal(res.total_unmatched_engagers);
        setPayloadTotal(res.total);
        setPageName(res.page_name);
        setLoading(false);
      },
      error: (xhr) => {
        ErrorLogger.captureRequest("Fetch Facebook Unmatched Engagers Error", xhr);
        setLoading(false);
      },
    });
  }, [page_id, searchText, sortProp, sortReverse, currentPage, activeFilters, lastMatchedId]);

  const handleSort = (prop) => {
    if (prop === sortProp) {
      setSortReverse(!sortReverse);
    } else {
      setSortReverse(true);
    }
    setCurrentPage(1);
    setSortProp(prop);
  };

  const renderSubtitle = () => {
    return (
      <div>
        <strong>{engagersTotal && engagersTotal.toLocaleString()}</strong> unmatched engagers in the{" "}
        <strong>last {getDateLabel(activeFilters.date)}</strong>
      </div>
    );
  };

  const facebookPagesActions = () => (
    <div>
      <ActionMenuItem icon="settings" title="Manage Facebook Accounts" href="/facebook/accounts">
        Manage Facebook Accounts
      </ActionMenuItem>
    </div>
  );

  return (
    <div className="facebook-unmatched-engagers-controller">
      <div className="facebook-header-wrapper">
        <PageHeader
          back
          image={<img src={pictureURL} alt={`${pageName}'s Facebook profile`} width="40" height="40" />}
          title={pageName}
          subtitle={renderSubtitle()}
          actions={facebookPagesActions()}
          backButtonHref="/facebook/pages"
        />
      </div>
      <div className="facebook-unmatched-engagers-filters">
        <SearchInput
          value={searchText}
          onChange={(val) => setSearchText(val)}
          width={350}
          placeholder="Search by Facebook engager name"
        />
        <div style={{ display: "flex", justifyContent: "space-between", minWidth: 455 }}>
          <FacebookEngagementFilters onApply={setActiveFilters} activeFilters={activeFilters} />
          <SortDropdown
            options={[
              { label: "Recent Engagement", value: "recent_engagement" },
              { label: "Reactions", value: "reactions" },
              { label: "Comments", value: "comments" },
            ]}
            sortProp={sortProp}
            sortReverse={sortReverse}
            onSort={handleSort}
          />
        </div>
      </div>
      <div className="facebook-unmatched-engagers-content">
        <div className="facebook-pages-table">
          <FlexTable
            data={unmatchedEngagers}
            columns={columns}
            caption="List of Facebook pages with meta data"
            handlePageChange={(page) => setCurrentPage(page)}
            total={payloadTotal}
            limit={LIMIT}
            page={currentPage}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

FacebookUnmatchedEngagersController.propTypes = {
  page_id: PropTypes.string,
  page_remote_id: PropTypes.string,
};

export default FacebookUnmatchedEngagersController;
