import PropTypes from "prop-types";
import EngagementCard from "./tv-engagement-card.js";
import EmptyStateMessage from "components/lists/empty-state-message-tv";
import TVIconViewed from "../../../../images/tv-icon1.svg";


const EngagementCardThankview = ({ contact = {}, engagement_data }) => {

const normalizeKeys = (engagement) => {
  const normalized = {};
  Object.keys(engagement).forEach(key => {
    const normalizedKey = key.toLowerCase().replace("thankview ", "");
    normalized[normalizedKey] = engagement[key];
  });
  return normalized;
};

// Filter engagements based on significant interactions
const filteredEngagements = engagement_data.map(normalizeKeys).filter(
  ({ clicked, completed, watched, "cta clicked": ctaClicked, views, downloads, shares }) =>
    clicked === "true" ||
    completed === "true" ||
    watched === "true" ||
    ctaClicked === "true" ||
    parseInt(views, 10) > 0 ||
    parseInt(downloads, 10) > 0 ||
    parseInt(shares, 10) > 0
);

  if (filteredEngagements.length === 0) {
    return (
      <EmptyStateMessage
        className="empty-state-message--thankview"
        icon="logo"
        iconKit="tv"
        text="No ThankView Engagements Found."
      />
    );
  }

  return filteredEngagements.map((engagement) => {
    const interactions = [
      engagement["clicked"] === "true" && "Clicked",
      engagement["completed"] === "true" && "Completed",
      engagement["watched 50%"] === "true" && "Watched",
      engagement["cta clicked"] === "true" && "CTA Clicked",
      parseInt(engagement["views"], 10) > 0 && "Viewed",
      parseInt(engagement["downloads"], 10) > 0 && "Downloaded",
      parseInt(engagement["shares"], 10) > 0 && "Shared",
    ].filter(Boolean);  

    return (
      <EngagementCard
        key={engagement.id}
        id={engagement.id}
        title={engagement["Thankview Campaign"]}
        contents={engagement["Thankview Campaign Type"]}
        date={engagement.date_occurred}  
        comment={engagement.Reply}
        replied={engagement.Reply && engagement.Reply.length > 0}
        contact={contact}
        icon={<img src={TVIconViewed} alt="TVIconViewed" height="40" width="40" />}
        interactions={interactions}
      />
    );
  });
};

EngagementCardThankview.propTypes = {
  contact: PropTypes.object,
  engagement_data: PropTypes.array,
};

export default EngagementCardThankview;