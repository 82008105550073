module.exports = do ->
   _ = require("underscore").default
   {createSource} = require("@evertrue/et-flux")

   # Most of the segment data is fetched from ListSource since they
   # are retrieved from the same endpoint and fetched at the same time
   createSource "SegmentSource",
      actions:
         applySegment: (object_or_id) ->
            @require _.isObject(object_or_id) || _.isNumber(object_or_id), "object_or_id should be number or object"

      api:
         applySegment: (object_or_id) ->
            @actions.applySegment(object_or_id)
