import MoveModal from "./common/move-modal";
import { PropTypes } from "base/react-libs";
import { useIdentity } from "base/identity-resolver";
import { useState, useEffect } from "react";
import { trackJourneysAction } from "apps/journeys/utils/helpers/mixpanel";
import { moveConstituentToJourney } from "apps/journeys/utils/api-requests/modal-actions";
import { fetchAllJourneysForAllJourneyGroups } from "apps/journeys/utils/api-requests/journey-index";

const MoveToJourneyModal = ({
  isOpen,
  onClose,
  currentJourney,
  contact,
  title = "Move Constituent",
  optionalStateManagementFunction = () => { },
}) => {
  const { user, org } = useIdentity();
  const [journeysList, setJourneysList] = useState([]);

  useEffect(() => {
    const getAndSetJourneys = async () => {
      const journeysListResponse = await fetchAllJourneysForAllJourneyGroups();
      setJourneysList(journeysListResponse.map((j) => ({ label: j.name, value: j.id })));
    };
    if (isOpen) getAndSetJourneys();
  }, [user.id, isOpen]);

  const handleSubmit = async (selectedNewJourney) => {
    trackJourneysAction("move_cadence");
    await moveConstituentToJourney(
      currentJourney.id,
      contact.id,
      user.id,
      org.id,
      selectedNewJourney.value,
      selectedNewJourney.label
    );
    optionalStateManagementFunction("removeContact", { contactIdToRemove: contact.id });
    onClose();
  };

  return (
    <MoveModal
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      handleSubmit={handleSubmit}
      contact={contact}
      options={journeysList}
      activeJourneys={[{ journey_name: currentJourney.name, journey_id: currentJourney.id }]} //map to active_cadence object
    />
  );
};

MoveToJourneyModal.propTypes = {
  title: PropTypes.string,
  currentJourney: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  contact: PropTypes.object,
  optionalStateManagementFunction: PropTypes.func,
};

MoveToJourneyModal.defaultProps = {
  contact: {},
};

export default MoveToJourneyModal;
