/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useContext } from "react";
import { GlobalContactSearchContext } from "apps/layout/controllers/global-contact-search-context";
import { getContactsLngLat } from "apps/search/utils/map-helpers";

// Hook to manage the contacts map
const useMapContacts = (contacts, map, clearMap, createMapMarkers) => {
  const {
    setMapMarkers,
    mapMarkers,
    tableSettings,
    updateMapBounds,
    setMapRadius,
    setSelectedAddressTypes,
    setMapFilters,
    updateTableSettings,
  } = useContext(GlobalContactSearchContext);

  useEffect(() => {
    const { page } = tableSettings;
    if (!mapMarkers.page) {
      setMapMarkers({ ...mapMarkers, [page]: getContactsLngLat(contacts.items) });
    }
  }, [contacts.items, setMapMarkers]);

  useEffect(() => {
    createMapMarkers(
      map,
      Object.values(mapMarkers).reduce((accum, page) => accum.concat(page), [])
    );
  }, [map, mapMarkers, createMapMarkers]);

  useEffect(() => {
    if (JSON.stringify(mapMarkers) === "{}") {
      clearMap();
    }
  }, [mapMarkers, clearMap]);

  const handleMapChange = useCallback(() => {
    clearMap();
    updateTableSettings({ page: 1 });
    updateMapBounds(map);
  }, [map, clearMap, updateMapBounds]);

  const handleInit = useCallback(() => {
    updateTableSettings({ page: 1 });
    updateMapBounds(map);
  }, [map, updateMapBounds]);

  const handleRadiusChange = useCallback((val) => {
    clearMap();
    setMapMarkers({});
    setMapRadius(val);
  }, []);

  const updateMapFilters = useCallback(
    (filtersArray, selectedValues) => {
      setSelectedAddressTypes(selectedValues);
      setMapFilters(filtersArray);
      clearMap();
      setMapMarkers({});
    },
    [setSelectedAddressTypes]
  );
  return {
    handleMapChange,
    handleInit,
    handleRadiusChange,
    updateMapFilters,
  };
};

export default useMapContacts;
