import PropTypes from "prop-types";
import { PieChart } from "react-minimal-pie-chart";

const PieChartGraph = ({ className = "", totalValue, data, startAngle = -90, ...rest }) => {
  // This is to handle percent rounding when only a single value is provided
  // If the value is less than 2% of the total, then we want to set the value to 2%
  // If it's between 95% and 99% then we want to set the value to 95%
  // This is to prevent showing a pie chart with a essentially non-visible sliver
  // Or showing a pie chart that looks to be 100% when it's 95% - 99% full

  const dataClone = [...data];
  if (data.length === 1) {
    const percentValue = data[0].value / totalValue;
    if (percentValue < 0.02 && percentValue > 0) {
      dataClone[0].value = Math.floor(totalValue * 0.02);
    } else if (percentValue > 0.95 && percentValue < 0.99) {
      dataClone[0].value = Math.round(totalValue * 0.95);
    }
  }

  return <PieChart className={className} totalValue={totalValue} data={dataClone} startAngle={startAngle} {...rest} />;
};

PieChartGraph.propTypes = {
  className: PropTypes.string,
  totalValue: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
  startAngle: PropTypes.number,
};

export default PieChartGraph;
