module.exports = do ->
   _ = require("underscore").default
   {createComponent, connect} = require("@evertrue/et-flux")
   React = require("react")
   {span, div} = ReactLibs.DOMFactories
   {any, func} = ReactLibs.PropTypes
   ComboboxWithImages = require("components/forms/combobox-with-images")
   Api = require("entities/helpers/api")
   SortUtils = require("mixins/sort-utils")
   moment = require("moment")
   EventIcon = require("apps/events/components/event-icon")
   FeatureStore = require("apps/layout/stores/feature-store").default

   formatQuery = ({ searchString, hasFacebook, hasEventbrite }) ->
      must: _.compact [
         if searchString then name: {wildcard: searchString + "*"}
         { "source": in: _.compact [
            if hasFacebook then "facebook"
            if hasEventbrite then "eventbrite"
         ] }
      ]
      sort: SortUtils.getQuery("start_time", true)

   renderIcon = (item = {}) ->
      {event} = item
      EventIcon
         size: 32
         className: "event-filter-dropdown--icon"
         event: event

   renderLabel = (item = {}) ->
      {event} = item
      span className: "event-filter-dropdown--label",
         div className: "event-filter-dropdown--name ellipsis-overflow",
            event?.name
         div className: "event-filter-dropdown--date",
            if time = event?.start_time
               moment(time).format("MM/D/YYYY")
            else "~"

   createComponent "EventFilterDropdown",
      propTypes:
         value: any
         onChange: func

      getInitialState: ->
         quicksearch: ""
         events: []
         loading: false

      mounted: false

      registerStores: ->
         @on FeatureStore, ->
            hasFacebook: FeatureStore.hasFeature("events_facebook")
            hasEventbrite: FeatureStore.hasFeature("events_eventbrite")

      componentDidMount: ->
         _.defer @fetchEvents
         @debounceSearch = _.debounce(@fetchEvents, 300)
         @mounted = true

      componentWillUnmount: ->
         @mounted = false

      set: (state) ->
         if @mounted then @setState(state)

      fetchEvents: (quicksearch = "") ->
         search = quicksearch.trim()
         @set loading: true, quicksearch: search

         query = formatQuery({
            searchString: search,
            hasFacebook: @state.hasFacebook
            hasEventbrite: @state.hasEventbrite
         })

         Api.EVENTS.EVENTS.post
            data: _.jsonStringify query
            params: limit: 50
            success: (resp) =>
               @set events: resp.items, loading: false
            error: =>
               @set events: [], loading: false

      handleChange: (selected) ->
         items = _.map selected, (item) -> _.pick item, ["label", "value"]
         @props.onChange(items)

      render: ->
         {events} = @state
         values = _.map @props.value, (item) ->
            _.extend {}, item, {event: _.findWhere(events, id: _.toNumber(item.value))}

         options = _.map events, (event) ->
            value: "" + event.id, label: event.name, event: event

         ComboboxWithImages
            className: "event-filter-dropdown"
            options: options
            value: values
            multiple: true
            placeholder: "All Events"
            loading: @state.loading
            onChange: @handleChange
            searchable: true
            allowSelectAll: true
            onSearch: @debounceSearch
            renderLabel: renderLabel
            renderIcon: renderIcon


