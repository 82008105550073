import Api from "entities/helpers/api";
import EverTrue from "app";
import ErrorLogger from "entities/helpers/error-log-helper";

export const moveConstituentToJourney = async (oldJourneyId, contactId, userId, oid, newJourneyId, newJourneyLabel) => {
  const payload = {
    contact_id: contactId,
    user_id: userId,
    oid: oid,
  };
  if (newJourneyId) payload.new_journey = { id: newJourneyId };
  if (oldJourneyId)
    payload.old_journey = {
      id: oldJourneyId,
      reason: newJourneyId ? "SKIPPED_MOVED_JOURNEY" : "SKIPPED_REMOVED_FROM_JOURNEY",
    };
  await Api.JOURNEYS.CONTACT_JOURNEY.post({
    data: JSON.stringify(payload),
    success: () => {
      if (payload.new_journey) {
        return EverTrue.Alert.success(`Successfully moved constituent into ${newJourneyLabel} cadence.`);
      }
      return EverTrue.Alert.success("Successfully removed constituent from cadence.");
    },
    error: (e) => {
      ErrorLogger.error("Error moving constituent", { extra: { error: e, page_name: "Cadences" } });
    },
  });
};

// bulk add endpoint
export const bulkAddConstituentsToJourney = async (contactIds, userId, oid, newJourneyId, label) => {
  if (contactIds.length > 0) {
    const payload = contactIds.map((contactId) => ({
      contact_id: contactId,
      user_id: userId,
      oid: oid,
      new_journey: { id: newJourneyId },
    }));
    await Api.JOURNEYS.BULK_ADD_CONSTITUENTS.post({
      data: JSON.stringify(payload),
      success: () => {
        return EverTrue.Alert.success(`Successfully moved constituents into ${label} cadence.`);
      },
      error: (e) => {
        EverTrue.Alert.error(`Unable to move constituents into ${label} cadence.`);
        ErrorLogger.error("Error adding constituents", { extra: { error: e, page_name: "JourneySelector" } });
      },
    });
  } else {
    EverTrue.Alert.error("No constituent is selected, Please select atleast 1 constituent");
  }
};

export const createJourney = async (payload, sort_order) => {
  const data = {
    ...payload,
    sort_order: sort_order,
    duration_in_days: 0,
    number_of_steps: 0,
    active: true,
  };

  return await Api.JOURNEYS.JOURNEYS.post({
    data: JSON.stringify(data),
    success: (response) => {
      EverTrue.Alert.success("Cadence created successfully.");
      return response;
    },
    error: (e) => {
      ErrorLogger.error("Error creating cadence", { extra: { error: e, page_name: "Cadences" } });
    },
  });
};

const getJourneyById = async (journeyId) => {
  return await Api.JOURNEYS.JOURNEY.get({
    urlArgs: { id: journeyId },
    success: (resp) => {
      return resp;
    },
    error() {
      return EverTrue.Alert.error("Cadence could not be fetched. Please try again.");
    },
  });
};

export const editJourney = async (payload) => {
  const existingJourney = await getJourneyById(payload.id);
  const data = { ...existingJourney, ...payload };
  return await Api.JOURNEYS.JOURNEY.put({
    data: JSON.stringify(data),
    urlArgs: { id: existingJourney.id },
    success: (response) => {
      EverTrue.Alert.success("Cadence updated successfully.");
      return response;
    },
    error: (e) => {
      ErrorLogger.error("Error updating cadence", { extra: { error: e, page_name: "Cadences" } });
    },
  });
};

export const deactivateJourney = async (journeyId) => {
  return await Api.JOURNEYS.JOURNEY.delete({
    urlArgs: { id: journeyId },
    success: (response) => {
      EverTrue.Alert.success("Cadence has been deleted");
      return response;
    },
    error(e) {
      ErrorLogger.error("Delete Cadence Error", { extra: { error: e, page_name: "Cadence" } });
      return EverTrue.Alert.error("Error Deleting Cadence, try again later");
    },
  });
};
