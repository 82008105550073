import { PropTypes } from "base/react-libs";
import { Card } from "@evertrue/et-components";
import StepsTable from "./step-view/steps-table";
import ActiveConstituentsTable from "./active-constituent-view/active-constituents-table";

const JourneyDetailTableBody = ({
  user = {},
  journey = {},
  tableView = "steps",
  userIsCreator = false,
  openContactSummary = () => {},
  optionalStateManagementFunction = () => {},
}) => {
  return (
    <Card className="journey-detail-table--body">
      {tableView === "steps" ? (
        <StepsTable
          journey={journey}
          userIsCreator={userIsCreator}
          openContactSummary={openContactSummary}
          optionalStateManagementFunction={optionalStateManagementFunction}
        />
      ) : (
        <ActiveConstituentsTable
          user={user}
          journey={journey}
          openContactSummary={openContactSummary}
          optionalStateManagementFunction={optionalStateManagementFunction}
        />
      )}
    </Card>
  );
};

JourneyDetailTableBody.propTypes = {
  user: PropTypes.object,
  journey: PropTypes.object.isRequired,
  tableView: PropTypes.string,
  openContactSummary: PropTypes.func,
  userIsCreator: PropTypes.bool,
  optionalStateManagementFunction: PropTypes.func,
};

export default JourneyDetailTableBody;
