module.exports = do ->
   $ = require("jquery")
   React = require("react")
   EverTrue = require("app")
   BaseRouter = require("base/base-router")
   FacebookPostController = require("apps/facebook/controllers/facebook-post-controller")
   {createFactory} = require("base/new-utils")
   FacebookPostsController = createFactory require("apps/facebook/controllers/facebook-posts-controller")
   FacebookAccountsController = createFactory require("apps/facebook/controllers/facebook-accounts-controller").default
   FacebookPagesController = createFactory require("apps/facebook/controllers/facebook-pages-controller").default
   FacebookUnMatchedEngagersController = createFactory require("apps/facebook/controllers/facebook-unmatched-engagers-controller").default


   # facebook/63589644737/posts/10151554364139738
   class FacebookRouter extends BaseRouter
      routes:
         "facebook/:type": "index"
         "facebook/:page_id/posts/:post_id": "show"
         "facebook/unmatched-engagers/:post_id/:page_remote_id": "unmatchedEngagers"

      beforeRoute: ->
         $(document).scrollTop(0)

      index: (type) ->
         EverTrue.execute "removePageContainer"
         EverTrue.execute "setBrowserTitle", "Facebook Posts"

         if type is "pages"
            EverTrue.track.set "viewed", {type: "facebook_pages"}
            EverTrue.track.setReferrer("facebook_pages")
            @currentView = FacebookPagesController view_type: type

         else if type is "posts"
            EverTrue.track.set "viewed", {type: "facebook_posts"}
            EverTrue.track.setReferrer("facebook_posts")
            @currentView = FacebookPostsController view_type: type

         else if type is "accounts"
            EverTrue.execute "setBrowserTitle", "Manage Facebook Account"
            params = EverTrue.UrlManager.toJSON()
            @currentView = FacebookAccountsController({params})

         else
            EverTrue.execute "setBrowserTitle", "Facebook Pages"
            EverTrue.track.set "viewed", {type: "facebook_pages"}
            EverTrue.track.setReferrer("facebook_pages")
            @currentView = FacebookPagesController view_type: type

         EverTrue.page.mount @currentView

      show: (page_id, post_id) ->
         EverTrue.execute "setBrowserTitle", "Facebook Post"
         EverTrue.track.setReferrer("post_details")

         @currentView = FacebookPostController post_id: "#{page_id}_#{post_id}"
         EverTrue.page.mount @currentView

      unmatchedEngagers: (page_id, page_remote_id) ->
         EverTrue.track.set "viewed", {type: "facebook_unmatched_engagers"}
         EverTrue.track.setReferrer("facebook_unmatched_engagers")
         @currentView = FacebookUnMatchedEngagersController page_id: page_id, page_remote_id: page_remote_id
         EverTrue.page.mount @currentView
