import { useState, useEffect, useContext } from "react";
import _ from "underscore";
import Decorator from "@evertrue/client-decorator";
import Api from "entities/helpers/api";
import { CachedContactUserProvider } from "apps/portfolio-performance/contexts/cached-contact-user-context";
import PortfolioNav from "apps/portfolio-performance/controllers/portfolio-nav";
import PageHeader from "apps/layout/components/page-header";
import { SolicitorDataProvider } from "apps/portfolio-performance/contexts/solicitor-data-context";
import SolicitorManagementTable from "apps/portfolio-performance/components/solicitor-management-table";
import { Button, SearchInput, useOpenClose, Modal } from "@evertrue/et-components";
import AddNewSolicitorsModal from "apps/portfolio-performance/components/add-new-solicitors-modal";
import { GlobalPortfolioContext } from "../contexts/global-portfolio-context";

const LIMIT = 20;

const PortfolioPerformanceSolicitorController = (props) => {
  const { assignmentType } = useContext(GlobalPortfolioContext);
  const [search_text, setSearchText] = useState(null);
  const [solicitors, setSolicitors] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [sort_order, setSortOrder] = useState(true);
  const [isModalOpen, openModal, closeModal] = useOpenClose();
  const contact_ids = _.pluck(solicitors, "id");

  useEffect(() => {
    fetchSolicitors();
    // eslint-disable-next-line
  }, [search_text, sort_order, page, assignmentType]);

  const fetchSolicitors = () => {
    const offset = (page - 1) * LIMIT;
    setLoading(true);

    Api.CONTACTS.SEARCH.post({
      params: { limit: LIMIT, offset },
      data: _.jsonStringify({
        must: !search_text
          ? []
          : _.map(search_text.split(" "), (search_part) => ({
              name_first: { wildcard: `${search_part}*` },
              name_last: { wildcard: `${search_part}*` },
            })),
        sort: [
          { name_first: { order: sort_order ? "asc" : "desc" } },
          { name_last: { order: sort_order ? "asc" : "desc" } },
        ],
        has_child: [
          {
            type: "assignment",
            query: {
              must: [{ parent_role: { match: "solicitor" } }, { pool_type: { match: assignmentType } }],
            },
          },
        ],
      }),
    })
      .then((resp) => {
        const contacts = _.map(resp.items, (item) => Decorator.Contacts.parse(item));
        setSolicitors(contacts);
        setTotal(resp.total);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  // CachedContactUserProvider is used in the solicitor table to cache users by contact id
  return (
    <CachedContactUserProvider>
      <PageHeader title={"Solicitors"} />
      <PortfolioNav tab="solicitors" />

      <SolicitorDataProvider contactIds={contact_ids}>
        <div className="solicitor-management">
          <div className="contact-table-toolbar--actions team-management--top">
            <SearchInput
              value={search_text}
              onChange={(val) => {
                setPage(1);
                setSearchText(val);
              }}
              width={300}
              placeholder="Search by name..."
            />

            <Button type="secondary" title="Add new solicitor" onClick={openModal}>
              New Solicitor
            </Button>
            <Modal isOpen={isModalOpen} closeModal={closeModal}>
              <AddNewSolicitorsModal close={closeModal} refreshData={fetchSolicitors} assignmentType={assignmentType} excludeExisting={true} />
            </Modal>
          </div>

          <SolicitorManagementTable
            total={total}
            limit={LIMIT}
            page={page}
            loading={loading}
            search_text={search_text}
            solicitors={solicitors}
            sort_prop="name"
            sort_order={sort_order}
            onSort={() => {
              const new_sort_order = !sort_order;
              setSortOrder(new_sort_order);
            }}
            onPageChange={(page) => {
              setPage(page);
            }}
            refreshData={fetchSolicitors}
          />
        </div>
      </SolicitorDataProvider>
    </CachedContactUserProvider>
  );
};

export default PortfolioPerformanceSolicitorController;
