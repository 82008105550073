module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   $ = require("jquery")
   Api = require("entities/helpers/api")
   Decorator = require("clientDecorator")


   ContactsAutoComplete = ReactLibs.createClass
      displayName: "ContactsAutoComplete"
      propTypes:
         initFetch: ReactLibs.PropTypes.bool
         query: ReactLibs.PropTypes.object
         limit: ReactLibs.PropTypes.number
         render: ReactLibs.PropTypes.func

      autocomplete_request: undefined

      getInitialState: ->
         key: _.randomKey()
         contacts: []
         loading: false

      componentDidMount: ->
         @is_mounted = true
         if @props.initFetch
            @fetchAutocomplete("", @props.query, @props.limit)

      componentWillUnmount: ->
         @setState {contacts: []}
         @is_mounted = false


      fetchAutocomplete: (name, query = {}, limit) ->
         @autocomplete_request?.abort()
         @setState {loading: true}
         full_query = $.extend true, {}, Decorator.Filters.getQueryJSON({ full_name: name }), query

         Api.CONTACTS.SEARCH.post
            data: _.jsonStringify full_query
            params: {offset: 0, limit: limit}
            beforeSend: (xhr) =>
               @autocomplete_request = xhr
            success: (resp = {}) =>
               @autocomplete_request = undefined
               if @is_mounted
                  @setState {
                     contacts: _.map(resp.items, Decorator.Contacts.parse)
                     loading: false
                  }
            error: ->
               @autocomplete_request = undefined

      handleSearch: (name = "") ->
         @fetchAutocomplete(name, @props.query, @props.limit)

      render: ->
         @props.render(
            contacts: @state.contacts
            onSearch: @handleSearch
            loading: @state.loading
         )
