import { Component } from "react";
import PropTypes from "prop-types";
import _ from "underscore";
import Api from "entities/helpers/api";

class IsVolunteerResolver extends Component {
  static defaultProps = {
    id: null,
    render: () => null,
  };

  static propTypes = {
    id: PropTypes.number,
    render: PropTypes.func,
  };

  state = {
    volunteer_user: {},
  };

  componentDidMount() {
    this.fetchUser(this.props.id);
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.id, this.props.id)) {
      this.fetchUser(this.props.id);
    }
  }

  fetchUser(id) {
    const query = {
      must: [
        {
          id: {
            match: id,
          },
        },
      ],
      has_child: [
        {
          type: "assignment",
          query: {
            must: [
              {
                parent_role: {
                  match: "solicitor",
                },
              },
            ],
          },
        },
      ],
    };

    Api.CONTACTS.SEARCH.post({
      data: _.jsonStringify(query),
      params: { limit: 1 },
    }).then((resp = {}) => {
      this.setState({ volunteer_user: resp.items[0] });
    });
  }

  render() {
    const { volunteer_user } = this.state;
    return this.props.render({ volunteer_user });
  }
}

export default IsVolunteerResolver;
