import { Component } from "react";
import Api from "entities/helpers/api";
import _ from "underscore";
import { EVENT_FILTERS } from "apps/events/utils/engagement-config";

const FILTERS = _.filter(EVENT_FILTERS, filter => _.includes(filter.type, "eventbrite"));

class EventbriteDetailResolver extends Component {
  static propTypes = {
    event: ReactLibs.PropTypes.object,
    include_matched: ReactLibs.PropTypes.bool,
    include_unmatched: ReactLibs.PropTypes.bool,
    include_filters: ReactLibs.PropTypes.bool,
    render: ReactLibs.PropTypes.func.isRequired,
  };

  static defaultProps = {
    event: {},
    include_matched: true,
    include_unmatched: true,
    include_filters: true,
    render: _.noop,
  };

  state = {
    matchedCounts: {},
    unmatchedCounts: {},
    error: null,
  };

  xhr = undefined;

  componentDidMount() {
    const { event } = this.props;
    this.fetchEngagementCounts(event);
  }

  componentWillUnmount() {
    this.unmounted = true;
    this.abortXHR();
    this.xhr = undefined;
  }

  componentDidUpdate(prevProps) {
    const { event_id } = this.props.event;
    if (prevProps.event.event_id !== event_id) {
      this.fetchEngagementCounts(event_id);
    }
  }

  abortXHR = () => {
    if (this.xhr && _.isFunction(this.xhr.abort)) this.xhr.abort();
  };

  setMatchedCount(key, value) {
    if (!this.unmounted) {
      this.setState({ matchedCounts: _.extend({}, this.state.matchedCounts, { [key]: value }) });
    }
  }

  setUnmatchedCount(key, value) {
    if (!this.unmounted) {
      this.setState({ unmatchedCounts: _.extend({}, this.state.unmatchedCounts, { [key]: value }) });
    }
  }

  fetchEngagementCounts(event = {}) {
    if (event.source === "eventbrite") {
      if (this.props.include_matched) {
        this.fetchEventbriteMatchedEngagersCounts(event.id);
      }

      if (this.props.include_unmatched) {
        this.fetchEventbriteUnmatchedEngagersCounts(event.id);
      }
    }
  }

  fetchEventbriteUnmatchedEngagersCounts(event_id) {
    const filters = this.props.include_filters ? FILTERS : [_.findWhere(FILTERS, { route_key: "all" })];
    _.each(filters, ({ route_key, engagement_actions }) => {
      Api.EVENTS.EVENTBRITE_PROFILES.get({
        params: _.compactObject({
          response_type: route_key !== "all" ? engagement_actions : undefined,
          event_id: [event_id],
          limit: 0,
          match_status: ["UNMATCH_QUEUED", "MATCH_QUEUED", "UNMATCHED", "ERROR"],
        }),
      }).then((resp = {}) => {
        this.setUnmatchedCount(route_key, resp.total);
      });
    });
  }

  fetchEventbriteMatchedEngagersCounts(event_id) {
    const filters = this.props.include_filters ? FILTERS : [_.findWhere(FILTERS, { route_key: "all" })];
    _.each(filters, ({ route_key, engagement_actions }) => {
      const contact_query = {
        has_child: [
          {
            type: "contact_event_engagement",
            query: {
              must: _.compact([
                { "event.id": { match: event_id } },
                route_key !== "all" ? { engagement_action: { in: engagement_actions } } : undefined,
                { contact_id: { exists: true } },
              ]),
            },
          },
        ],
      };
      Api.CONTACTS.SEARCH.post({
        params: { limit: 0 },
        data: _.jsonStringify(contact_query),
      }).then((resp = {}) => {
        this.setMatchedCount(route_key, resp.total);
      });
    });
  }

  render() {
    const { matchedCounts, unmatchedCounts } = this.state;
    return this.props.render({ matchedCounts, unmatchedCounts });
  }
}

export default EventbriteDetailResolver;
