import PropTypes from "prop-types";
import { createContext, useState } from "react";

const GlobalPortfolioContext = createContext(() => {});

const GlobalPortfolioProvider = ({ children }) => {
  const [assignmentType, setAssignmentType] = useState("TEAM");

  return (
    <GlobalPortfolioContext.Provider
      value={{
        assignmentType,
        setAssignmentType,
      }}
    >
      {children}
    </GlobalPortfolioContext.Provider>
  );
};

GlobalPortfolioProvider.propTypes = {
  children: PropTypes.any,
};

export { GlobalPortfolioProvider, GlobalPortfolioContext };
