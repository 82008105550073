module.exports = do ->
   _ = require("underscore").default
   classNames = require("classnames")
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {rect} = ReactLibs.DOMFactories
   colors = require("base/colors")

   DEFAULT_COLOR = colors.green300


   createComponent "Bar",
      propTypes:
         value: ReactLibs.PropTypes.number
         width: ReactLibs.PropTypes.number
         height: ReactLibs.PropTypes.number
         x: ReactLibs.PropTypes.number
         y: ReactLibs.PropTypes.number
         color: ReactLibs.PropTypes.string
         label: ReactLibs.PropTypes.string
         onMouseOver: ReactLibs.PropTypes.func
         onMouseOut: ReactLibs.PropTypes.func
         onClic: ReactLibs.PropTypes.func

      getDefaultProps: ->
         width: 0
         height: 0
         style: {fill: DEFAULT_COLOR, opacity: ".2"}

      getInitialState: ->
         is_hovered: false

      # TODO: Consistent event handle arguments, with other chart components
      handleMouseOver: (e) ->
         @setState is_hovered: true
         position = {x: @props.x, y: @props.y}
         @props.onMouseOver?(@props.value, @props.label, @props.width, position, e)

      handleMouseOut: (e) ->
         @setState is_hovered: false
         @props.onMouseOut?()

      render: ->
         rect
            ref: "rect"
            className: classNames("charting--bar", @props.className,
               "is-hovered": @state.is_hovered)
            style: @props.style
            width: @props.width
            height: @props.height
            x: @props.x
            y: @props.y
            onMouseEnter: @handleMouseOver
            onMouseLeave: @handleMouseOut
            onClick: @props.onClick


