moment = require("moment")
numeral = require("numeral")
str = require("underscore.string")

# General Acitivty

formatDate = (date) ->
   if not date then "-"
   else if moment(date).isAfter(moment().subtract(7, "day").format("YYYY-MM-DD"))
      moment(date).local().fromNow()
   else
      moment(date).local().format("MMM D, YYYY")

# Gifts

formatGiftAmount = (amount) ->
   numeral(amount).format("$0,000[.]00")

formatGiftType = (type = "") ->
   switch type.toLowerCase()
      when "hard_credit" then "Hard Credit"
      when "soft_credit" then "Soft Credit"
      when "pledge" then "Pledge"
      else str.titleize(str.humanize(type))

module.exports = {formatDate, formatGiftAmount, formatGiftType}
