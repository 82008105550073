module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   EverTrue = require("app")
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories
   FilterSource = require("apps/filters/sources/filter-source")
   FilterStore = require("apps/filters/stores/filter-store")
   MapStore = require("apps/map/stores/map-store")
   MapSource = require("apps/map/sources/map-source")
   MapUtils = require("apps/map/map-utils")
   GeolocationStore = require("apps/contact/stores/geolocation-store")
   SegmentStore = require("apps/filters/stores/segment-store")
   {createFactory} = require("base/new-utils")
   MapWithClusters = createFactory require("apps/map/components/map-with-clusters").default
   ClusterContactSource = require("apps/map/sources/cluster-contact-source")
   MapAddressFilter = require("apps/map/components/map-address-filter")
   MapRadiusDropdown = createFactory(require("apps/map/components/map-radius-dropdown").default)
   Button = createFactory require("@evertrue/et-components").Button

   INIT_ZOOM = 2
   MIN_RADIUS_ZOOM = 8

   _cluster_query = (box) ->
      MapUtils.getClusterQuery(box)

   _radiusStringToNumber = (string="") ->
      _.toNumber(string.replace("mi", ""))

   _round = (num) -> if num then Number(num).toFixed(2) else NaN

   _isEquivalentLatLon = (obj1={}, obj2={}) ->
      !!(_round(obj1.lat) is _round(obj2.lat) and _round(obj1.lon) is _round(obj2.lon))

   createComponent "MapController",
      getInitialState: ->
         # Needs to be undefined so map address filter will initially load types from filters
         address_types: undefined
         # set state to update map's zoom, bounds, center
         set_zoom: undefined
         set_bounds: undefined
         set_center: undefined
         set_location_marker: undefined

      registerStores: ->
         @on MapStore, ->
            zoom: MapStore.getZoom()
            clusters: MapStore.getClusters()
            loading_clusters: MapStore.getLoadingClusters()
            map: MapStore.getMap()
            map_center: MapStore.getLatLng()
            bounding_box: MapStore.getBoundingBox()

         @on FilterStore, ->
            active_map_filter: FilterStore.getActiveFilterByKey("map")
            operator: FilterStore.getOperator()

         @on GeolocationStore, ->
            geolocation: GeolocationStore.getGeolocation()
            center: GeolocationStore.getLocation()

         @on SegmentStore, ->
            saved_segment: SegmentStore.getSelectedSegment()

      UNSAFE_componentWillMount: ->
         # Force the map to default to correct bounds from URL, needs to happen before mount
         filters = EverTrue.UrlManager.get("filters")
         map_filter = _.findWhere filters, {filter_id: "0.0"}
         if map_filter?.value
            @setState {bounding_box: map_filter?.value}

      componentDidUpdate: (prevProps, prevState) ->
         prev_radius = prevState.active_map_filter?.radius
         curr_radius = @state.active_map_filter?.radius

         if @state.saved_segment?.id and (@state.saved_segment.id isnt prevState.saved_segment?.id)
            @updateForSavedSegment()

         if (!prev_radius and curr_radius) or (curr_radius and curr_radius isnt prev_radius)
            @showRadiusOnMap()
         else if prev_radius and !curr_radius
            @handleUnsetRadius()

         if prevState.active_map_filter and !@state.active_map_filter
            @setState set_zoom: INIT_ZOOM

      updateForSavedSegment: ->
         filter_val = _.findWhere(@state.saved_segment?.filters, {filter_id: "0.0"})?.value
         if filter_val?.radius and !_isEquivalentLatLon(filter_val, @state.map_center)
            @setState set_center: MapUtils.radiusToLatLng(filter_val)
         else
            @setState set_bounds: filter_val

      showRadiusOnMap: ->
         radius = _radiusStringToNumber @state.active_map_filter?.radius

         zoom = MapUtils.getZoomForRadius(radius)
         @setState set_zoom: zoom

         @circle?.setMap(null)
         @circle = new google.maps.Circle
            strokeColor: "#5598ab",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#5598ab",
            fillOpacity: 0.35,
            map: @state.map,
            center: MapUtils.radiusToLatLng(@state.active_map_filter)
            radius: radius * 1609.34 # to meters

      updateMapFilters: (bounds) ->
         FilterSource.updateMapFilter(bounds)
         FilterSource.fetchFilteredClusters()

      handleMapInit: (bounds) ->
         if @state.active_map_filter?.radius
            @showRadiusOnMap()
            FilterSource.fetchFilteredClusters()
            MapSource.setBoundingBox(bounds)
         else
            MapSource.setBoundingBox(bounds)
            FilterSource.fetchFilteredClusters()

      handleMapChange: (zoom, bounds) ->
         {set_zoom, set_center, set_bounds, set_location_marker} = @state
         # reset so if set again to same value, map component will detect change
         if set_zoom or set_center or set_bounds or set_location_marker
            @setState
               set_zoom: undefined
               set_center: undefined
               set_bounds: undefined
               set_location_marker: undefined

         if @state.active_map_filter?.radius
            MapSource.setBoundingBox(bounds)
            FilterSource.fetchFilteredClusters()
            @props.onMapChange?()
         else
            MapSource.setBoundingBox(bounds)
            @updateMapFilters(bounds)
            @props.onMapChange?()

      handleClusterOpen: (key, cluster) ->
         if @state.operator == "or"
            query = FilterStore.getActiveFiltersQuery()
            query.must ?= []
            query.must.push {"addresses.location": cluster.geobox}
         else
            query = FilterStore.getActiveFiltersQueryWithoutMap(_cluster_query(cluster.geobox))
         ClusterContactSource.fetch(key, query)

      handleSetRadius: (radius) ->
         object = _.cloneData(@state.map_center)
         object.radius = radius + "mi"
         @updateMapFilters(object)

         google_formatted_center = _.extend {}, object, {lng: object.lon}
         @setState set_location_marker: google_formatted_center
         @props.onMapChange?()

      handleUnsetRadius: ->
         if @circle then @circle.setMap(null)
         bounds = MapUtils.boundsToBox(@state.map.getBounds())
         @updateMapFilters(bounds)
         @props.onMapChange?()

      render: ->
         is_geolocation = @state.geolocation && _.isEqual(@state.geolocation, @state.center) && !@state.saved_segment
         radius_from_filter = @state.active_map_filter?.radius
         can_set_radius = (_.notEmpty(@state.map_center) && @state.zoom >= MIN_RADIUS_ZOOM) || radius_from_filter
         default_zoom = switch
            when radius_from_filter then MapUtils.getZoomForRadius(_radiusStringToNumber radius_from_filter)
            when is_geolocation then 11

         div className: "map-search-controller",
            Button type: "simple", onClick: @props.onSendContactsFocus, title: "Map shows list of constituents in the map area, but is inaccessible. Go to list of constituents in the map"
            MapWithClusters
               clusters: @state.clusters
               loading: @state.loading_clusters
               defaultZoom: default_zoom
               defaultBounds: @state.bounding_box
               setBounds: @state.set_bounds
               setCenter: @state.set_center
               setZoom: @state.set_zoom
               setLocationMarker: @state.set_location_marker
               onMapInit: @handleMapInit
               onMapChange: @handleMapChange
               onClusterOpen: @handleClusterOpen
               onLocationChange: @handleUnsetRadius

               div className: "map-search-controller--filters",
                  MapRadiusDropdown
                     value: radius_from_filter
                     disabled: !can_set_radius
                     onChange: @handleSetRadius
                     onClear: @handleUnsetRadius

                  MapAddressFilter
                     selected_types: @state.address_types
                     onApply: (filters) =>
                        FilterSource.set(filters)
                        FilterSource.fetchFilteredClusters()
                        @props.onMapChange?()
                     onSelectType: (address_types) =>
                        @setState address_types: address_types
