/*eslint-disable */
const ReactLibs = require("ReactLibs");
!(function(e, t) {
  "object" == typeof exports && "object" == typeof module
    ? (module.exports = t(require("moment"), require("react")))
    : "function" == typeof define && define.amd
      ? define(["moment", "react"], t)
      : "object" == typeof exports
        ? (exports.DatePicker = t(require("moment"), require("react")))
        : (e.DatePicker = t(e.moment, e.react));
})(this, function(e, t) {
  return (function(e) {
    function t(r) {
      if (n[r]) return n[r].exports;
      var a = (n[r] = { exports: {}, id: r, loaded: !1 });
      return e[r].call(a.exports, a, a.exports, t), (a.loaded = !0), a.exports;
    }
    var n = {};
    return (t.m = e), (t.c = n), (t.p = ""), t(0);
  })([
    function(e, t, n) {
      "use strict";
      function r(e) {
        if (Array.isArray(e)) {
          for (var t = 0, n = Array(e.length); t < e.length; t++) n[t] = e[t];
          return n;
        }
        return Array.from(e);
      }
      function a() {}
      var o =
          Object.assign ||
          function(e) {
            for (var t = 1; t < arguments.length; t++) {
              var n = arguments[t];
              for (var r in n) Object.prototype.hasOwnProperty.call(n, r) && (e[r] = n[r]);
            }
            return e;
          },
        i = n(5),
        s = n(2),
        l = n(1),
        u = n(6),
        h = n(12),
        c = n(13),
        d = n(10),
        p = n(11),
        f = n(4),
        m = function(e, t) {
          return Object.prototype.hasOwnProperty.call(e, t);
        },
        v = n(3),
        y = { month: h, year: c, decade: d },
        g = ReactLibs.createClass({
          displayName: "DatePicker",
          propTypes: {
            todayText: ReactLibs.PropTypes.string,
            gotoSelectedText: ReactLibs.PropTypes.string,
            renderFooter: ReactLibs.PropTypes.func,
            onChange: ReactLibs.PropTypes.func,
            date: ReactLibs.PropTypes.any,
            viewDate: ReactLibs.PropTypes.any,
          },
          getViewOrder: function() {
            return this.props.viewOrder || ["month", "year", "decade"];
          },
          getDefaultProps: function() {
            var e = l({}, u(), {
              highlightWeekends: !1,
              weekNumberName: "",
              isDatePicker: !0,
              navOnDateClick: !0,
              highlightRangeOnMouseMove: !0,
              defaultStyle: { boxSizing: "border-box" },
              onRangeChange: function() {},
            });
            return delete e.viewDate, delete e.date, e;
          },
          getInitialState: function() {
            return {
              view: this.props.defaultView,
              viewDate: this.props.defaultViewDate,
              defaultDate: this.props.defaultDate,
              defaultRange: this.props.defaultRange,
            };
          },
          getViewName: function() {
            var e = null != this.props.view ? this.props.view : this.state.view;
            return e || "month";
          },
          addViewIndex: function(e) {
            var t = this.getViewName(),
              n = this.getViewOrder(),
              r = n.indexOf(t);
            return (r += e), r % n.length;
          },
          getNextViewName: function() {
            return this.getViewOrder()[this.addViewIndex(1)];
          },
          getPrevViewName: function() {
            return this.getViewOrder()[this.addViewIndex(-1)];
          },
          getView: function() {
            var e = this.props.views || y;
            return e[this.getViewName()] || e.month;
          },
          getViewFactory: function() {
            var e = this.getView();
            return (
              i.createFactory &&
                e &&
                e.prototype &&
                "function" == typeof e.prototype.render &&
                ((e.__factory = e.__factory || i.createFactory(e)), (e = e.__factory)),
              e
            );
          },
          getViewDate: function() {
            var e = m(this.props, "viewDate") ? this.props.viewDate : this.state.viewDate;
            return (
              (e = e || this.viewMoment || this.getDate() || new Date()),
              s.isMoment(e) && (e = +e),
              (e = this.toMoment(e))
            );
          },
          getDate: function() {
            var e;
            return (e = m(this.props, "date") ? this.props.date : this.state.defaultDate), e ? this.toMoment(e) : null;
          },
          getRange: function() {
            var e,
              t = this;
            return (
              m(this.props, "range")
                ? (e = this.props.range)
                : this.state.defaultRange && (e = this.state.defaultRange),
              e
                ? e.map(function(e) {
                    return e ? t.toMoment(e) : null;
                  }) || null
                : null
            );
          },
          render: function() {
            var e = (this.p = l({}, this.props));
            this.toMoment = function(t, n) {
              return f(t, n || e.dateFormat, { locale: e.locale });
            };
            var t = this.getViewFactory();
            (e.date = this.getDate()), (e.range = this.getRange());
            var n = null == e.date ? "" : e.date.format(this.props.dateFormat);
            (e.viewDate = this.viewMoment = this.getViewDate()),
              (e.locale = this.props.locale),
              (e.localeData = s.localeData(e.locale)),
              (e.renderDay = this.props.renderDay),
              (e.onRenderDay = this.props.onRenderDay);
            var r = (this.props.className || "") + " date-picker";
            e.style = this.prepareStyle(e);
            var a = e,
              a = u(e);
            return (
              (a.toMoment = this.toMoment),
              (a.highlightWeekends = this.props.highlightWeekends),
              (a.weekNumbers = this.props.weekNumbers),
              (a.weekNumberName = this.props.weekNumberName),
              (a.dateString = n),
              (a.localeData = e.localeData),
              (a.onSelect = this.handleSelect),
              (a.onChange = this.handleChange),
              (a.onWeekChange = this.props.onWeekChange),
              (a.renderWeekNumber = this.props.renderWeekNumber),
              (a.highlightRangeOnMouseMove = this.props.highlightRangeOnMouseMove),
              (a.range = e.range),
              i.createElement(
                "div",
                o({}, this.props, { className: r, style: e.style }),
                this.renderHeader(t, e),
                i.createElement("div", { className: "dp-body", style: { flex: 1 } }, t(a)),
                this.renderFooter(e)
              )
            );
          },
          prepareStyle: function(e) {
            return l({}, e.defaultStyle, e.style);
          },
          renderFooter: function(e) {
            if (!this.props.hideFooter) {
              this.props.today && console.warn('Please use "todayText" prop instead of "today"!'),
                this.props.gotoSelected &&
                  console.warn('Please use "gotoSelectedText" prop instead of "gotoSelected"!');
              var t,
                n = this.props.todayText || "Today",
                r = this.props.gotoSelectedText || "Go to selected",
                a = {
                  todayText: n,
                  gotoSelectedText: r,
                  gotoToday: this.gotoNow,
                  gotoSelected: this.gotoSelected.bind(this, e),
                  date: e.date,
                  viewDate: e.viewDate,
                };
              return (
                "function" == typeof this.props.footerFactory && (t = this.props.footerFactory(a)),
                void 0 !== t
                  ? t
                  : i.createElement(
                      "div",
                      { className: "dp-footer" },
                      i.createElement(
                        "div",
                        {
                          tabIndex: "1",
                          role: "link",
                          className: "dp-footer-today",
                          onClick: a.gotoToday,
                          onKeyUp: v(a.gotoToday),
                        },
                        n
                      ),
                      i.createElement(
                        "div",
                        {
                          tabIndex: "1",
                          role: "link",
                          className: "dp-footer-selected",
                          onClick: a.gotoSelected,
                          onKeyUp: v(a.gotoSelected),
                        },
                        r
                      )
                    )
              );
            }
          },
          gotoNow: function() {
            this.gotoDate(+new Date());
          },
          gotoSelected: function(e) {
            this.gotoDate(e.date || +new Date());
          },
          gotoDate: function(e) {
            this.setView("month"), this.setViewDate(e);
          },
          getViewColspan: function() {
            var e = { month: 5, year: 2, decade: 2 };
            return e[this.getViewName()];
          },
          renderHeader: function(e, t) {
            if (!this.props.hideHeader) {
              t = t || this.props;
              var n = this.getViewDate(),
                r = this.getView().getHeaderText(n, t),
                a = this.getViewColspan(),
                o = this.props.navPrev,
                s = this.props.navNext;
              return i.createElement(
                p,
                {
                  prevText: o,
                  nextText: s,
                  colspan: a,
                  onPrev: this.handleNavPrev,
                  onNext: this.handleNavNext,
                  onChange: this.handleViewChange,
                },
                r
              );
            }
          },
          handleRenderDay: function(e) {
            return (this.props.renderDay || a)(e) || [];
          },
          handleViewChange: function() {
            this.setView(this.getNextViewName());
          },
          setView: function(e) {
            "function" == typeof this.props.onViewChange && this.props.onViewChange(e),
              null == this.props.view && this.setState({ view: e });
          },
          setViewDate: function(e) {
            e = this.toMoment(e);
            var t = this.props.onViewDateChange;
            if ("function" == typeof t) {
              var n = e.format(this.props.dateFormat),
                r = this.getViewName();
              t(n, e, r);
            }
            m(this.props, "viewDate") || this.setState({ viewDate: e });
          },
          getNext: function() {
            var e = this.getViewDate(),
              t = this.toMoment;
            return {
              month: function() {
                return t(e).add(1, "month");
              },
              year: function() {
                return t(e).add(1, "year");
              },
              decade: function() {
                return t(e).add(10, "year");
              },
            }[this.getViewName()]();
          },
          getPrev: function() {
            var e = this.getViewDate(),
              t = this.toMoment;
            return {
              month: function() {
                return t(e).add(-1, "month");
              },
              year: function() {
                return t(e).add(-1, "year");
              },
              decade: function() {
                return t(e).add(-10, "year");
              },
            }[this.getViewName()]();
          },
          handleNavigation: function(e, t) {
            var n = -1 == e ? this.getPrev() : this.getNext();
            if ((this.setViewDate(n), "function" == typeof this.props.onNav)) {
              var r = n.format(this.props.dateFormat),
                a = this.getViewName();
              this.props.onNav(r, n, a, e, t);
            }
          },
          handleNavPrev: function(e) {
            this.handleNavigation(-1, e);
          },
          handleNavNext: function(e) {
            this.handleNavigation(1, e);
          },
          handleChange: function(e, t) {
            if (((e = this.toMoment(e)), this.props.navOnDateClick)) {
              var n = this.toMoment(this.getViewDate()),
                r = n.format("YYYY-MM"),
                o = e.format("YYYY-MM");
              o > r ? this.handleNavNext(t) : r > o && this.handleNavPrev(t);
            }
            var i = e.format(this.props.dateFormat);
            m(this.props, "date") || this.setState({ defaultDate: i }),
              (this.props.onChange || a)(i, e, t),
              this.p.range && this.handleRangeChange(e, t);
          },
          handleRangeChange: function(e) {
            var t = this,
              n = this.p.range;
            (n = n.length < 2 ? [].concat(r(n), [e]) : [e]),
              n.sort(function(e, t) {
                return e - t;
              }),
              this.props.range || this.setState({ defaultRange: n });
            var a = n.map(function(e) {
              return e.format(t.props.dateFormat);
            });
            this.props.onRangeChange(a, n);
          },
          handleSelect: function(e, t) {
            var n = this.getViewName(),
              r = { decade: "year", year: "month" }[n],
              a = e.get(r),
              o = this.toMoment(this.getViewDate()).set(r, a),
              i = this.getPrevViewName();
            if ((this.setViewDate(o), this.setView(i), "function" == typeof this.props.onSelect)) {
              var s = o.format(this.props.dateFormat);
              this.props.onSelect(s, o, i, t);
            }
          },
        });
      g.views = y;
      var w = ReactLibs.PropTypes;
      (g.propTypes = {
        highlightWeekends: w.bool,
        onChange: w.func,
        onNav: w.func,
        onSelect: w.func,
        renderDay: w.func,
        onRenderDay: w.func,
        defaultView: w.string,
        view: w.string,
        onViewDateChange: w.func,
        onViewChange: w.func,
        navOnDateClick: w.bool,
        highlightRangeOnMouseMove: w.bool,
      }),
        (e.exports = g);
    },
    function(e, t) {
      "use strict";
      function n(e) {
        if (null === e || void 0 === e) throw new TypeError("Object.assign cannot be called with null or undefined");
        return Object(e);
      }
      var r = Object.prototype.hasOwnProperty,
        a = Object.prototype.propertyIsEnumerable;
      e.exports =
        Object.assign ||
        function(e, t) {
          for (var o, i, s = n(e), l = 1; l < arguments.length; l++) {
            o = Object(arguments[l]);
            for (var u in o) r.call(o, u) && (s[u] = o[u]);
            if (Object.getOwnPropertySymbols) {
              i = Object.getOwnPropertySymbols(o);
              for (var h = 0; h < i.length; h++) a.call(o, i[h]) && (s[i[h]] = o[i[h]]);
            }
          }
          return s;
        };
    },
    function(t, n) {
      t.exports = e;
    },
    function(e, t) {
      "use strict";
      e.exports = function(e) {
        return function(t) {
          "Enter" == t.key && e(t);
        };
      };
    },
    function(e, t, n) {
      "use strict";
      var r = n(2),
        a = n(8);
      e.exports = function(e, t, n) {
        var o = !(!n || !n.strict),
          i = n && n.locale;
        return (
          (t = t || a.dateFormat), "string" == typeof e ? r(e, t, i, o) : r(null == e ? new Date() : e, void 0, i, o)
        );
      };
    },
    function(e, n) {
      e.exports = t;
    },
    function(e, t, n) {
      "use strict";
      function r(e, t, n) {
        return (
          e &&
            n.forEach(function(n) {
              t[n] = e[n];
            }),
          t
        );
      }
      var a = n(1),
        o = n(8),
        i = Object.keys(o);
      e.exports = function(e, t) {
        var n = i;
        return t && (n = Object.keys(t)), (t = t || o), e ? r(e, a({}, t), n) : a({}, t);
      };
    },
    function(e, t) {
      "use strict";
      var n = (function() {
        function e(e, t) {
          var n = [],
            r = !0,
            a = !1,
            o = void 0;
          try {
            for (
              var i, s = e[Symbol.iterator]();
              !(r = (i = s.next()).done) && (n.push(i.value), !t || n.length !== t);
              r = !0
            );
          } catch (l) {
            (a = !0), (o = l);
          } finally {
            try {
              !r && s["return"] && s["return"]();
            } finally {
              if (a) throw o;
            }
          }
          return n;
        }
        return function(t, n) {
          if (Array.isArray(t)) return t;
          if (Symbol.iterator in Object(t)) return e(t, n);
          throw new TypeError("Invalid attempt to destructure non-iterable instance");
        };
      })();
      e.exports = function(e, t) {
        var r = n(t, 2),
          a = r[0],
          o = r[1];
        return e && o ? a.isSameOrBefore(e) && o.isSameOrAfter(e) : !1;
      };
    },
    function(e, t, n) {
      "use strict";
      var r = n(14);
      e.exports = {
        weekDayNames: r,
        weekStartDay: null,
        locale: null,
        dayFormat: "D",
        monthFormat: "MMMM",
        yearFormat: "YYYY",
        navPrev: "‹",
        navNext: "›",
        view: null,
        date: null,
        minDate: null,
        maxDate: null,
        viewDate: null,
        dateFormat: "YYYY-MM-DD",
        onRenderDay: null,
        renderDay: null,
        alwaysShowPrevWeek: !1,
      };
    },
    function(e, t, n) {
      "use strict";
      function r(e, t) {
        return o(e).format(t);
      }
      var a = n(8),
        o = n(4);
      e.exports = {
        day: function(e, t) {
          return r(e, t || a.dayFormat);
        },
        month: function(e, t) {
          return r(e, t || a.monthFormat);
        },
        year: function(e, t) {
          return r(e, t || a.yearFormat);
        },
      };
    },
    function(e, t, n) {
      "use strict";
      function r() {}
      var a,
        o = (function() {
          function e(e, t) {
            var n = [],
              r = !0,
              a = !1,
              o = void 0;
            try {
              for (
                var i, s = e[Symbol.iterator]();
                !(r = (i = s.next()).done) && (n.push(i.value), !t || n.length !== t);
                r = !0
              );
            } catch (l) {
              (a = !0), (o = l);
            } finally {
              try {
                !r && s["return"] && s["return"]();
              } finally {
                if (a) throw o;
              }
            }
            return n;
          }
          return function(t, n) {
            if (Array.isArray(t)) return t;
            if (Symbol.iterator in Object(t)) return e(t, n);
            throw new TypeError("Invalid attempt to destructure non-iterable instance");
          };
        })(),
        i = n(5),
        s = n(2),
        l = n(1),
        u = n(9),
        h = n(6),
        c = (n(4), n(3)),
        l = n(1),
        d = n(7),
        p = ReactLibs.createClass({
          displayName: "DecadeView",
          getDefaultProps: function() {
            return h();
          },
          getYearsInDecade: function(e) {
            var t = s(e).get("year"),
              n = t % 10;
            t = t - n - 1;
            for (var r = [], a = 0, o = s(t, "YYYY").startOf("year"); 12 > a; a++) r.push(s(o)), o.add(1, "year");
            return r;
          },
          render: function() {
            a = +s().startOf("day");
            var e = l({}, this.props),
              t = (e.viewMoment = s(this.props.viewDate));
            this.props.range || (e.moment = s(e.date).startOf("year"));
            var n = this.getYearsInDecade(t);
            return i.createElement("div", { className: "dp-table dp-decade-view" }, this.renderYears(e, n));
          },
          renderYears: function(e, t) {
            for (
              var n = t.map(function(t, n, r) {
                  return this.renderYear(e, t, n, r);
                }, this),
                r = t.length,
                a = [],
                o = Math.ceil(r / 4),
                s = 0;
              o > s;
              s++
            )
              a.push(n.slice(4 * s, 4 * (s + 1)));
            return a.map(function(e, t) {
              return i.createElement("div", { key: "row" + t, className: "dp-row" }, e);
            });
          },
          renderYear: function(e, t, n, r) {
            var a = u.year(t, e.yearFormat),
              l = ["dp-cell dp-year"],
              h = +t;
            if (e.range) {
              var p = t,
                f = s(p).endOf("year"),
                m = o(e.range, 2),
                v = m[0],
                y = m[1];
              (d(p, e.range) || d(f, e.range) || (v && d(v, [p, f])) || (y && d(y, [p, f]))) && l.push("dp-in-range");
            }
            h != e.moment || e.range || l.push("dp-value"),
              n || l.push("dp-prev"),
              n == r.length - 1 && l.push("dp-next");
            var g = this.handleClick.bind(this, e, t);
            return i.createElement(
              "div",
              { role: "link", tabIndex: "1", key: a, className: l.join(" "), onClick: g, onKeyUp: c(g) },
              a
            );
          },
          handleClick: function(e, t, n) {
            (n.target.value = t), (e.onSelect || r)(t, n);
          },
        });
      (p.getHeaderText = function(e, t) {
        var n = s(e).get("year"),
          r = n % 10;
        return (n = n - r - 1), n + " - " + (n + 11);
      }),
        (e.exports = p);
    },
    function(e, t, n) {
      "use strict";
      var r = n(5),
        a = ReactLibs.PropTypes,
        o = n(3);
      e.exports = ReactLibs.createClass({
        displayName: "DatePickerHeader",
        propTypes: { onChange: a.func, onPrev: a.func, onNext: a.func, colspan: a.number, children: a.node },
        render: function() {
          var e = this.props;
          return r.createElement(
            "div",
            { className: "dp-header" },
            r.createElement(
              "div",
              { className: "dp-nav-table" },
              r.createElement(
                "div",
                { className: "dp-row" },
                r.createElement(
                  "div",
                  {
                    tabIndex: "1",
                    role: "link",
                    className: "dp-prev-nav dp-nav-cell dp-cell",
                    onClick: e.onPrev,
                    onKeyUp: o(e.onPrev),
                  },
                  e.prevText
                ),
                r.createElement(
                  "div",
                  {
                    tabIndex: "1",
                    role: "link",
                    className: "dp-nav-view dp-cell",
                    colSpan: e.colspan,
                    onClick: e.onChange,
                    onKeyUp: o(e.onChange),
                  },
                  e.children
                ),
                r.createElement(
                  "div",
                  {
                    tabIndex: "1",
                    role: "link",
                    className: "dp-next-nav dp-nav-cell dp-cell",
                    onClick: e.onNext,
                    onKeyUp: o(e.onNext),
                  },
                  e.nextText
                )
              )
            )
          );
        },
      });
    },
    function(e, t, n) {
      "use strict";
      function r() {}
      Object.defineProperty(t, "__esModule", { value: !0 });
      var a,
        o = (function() {
          function e(e, t) {
            var n = [],
              r = !0,
              a = !1,
              o = void 0;
            try {
              for (
                var i, s = e[Symbol.iterator]();
                !(r = (i = s.next()).done) && (n.push(i.value), !t || n.length !== t);
                r = !0
              );
            } catch (l) {
              (a = !0), (o = l);
            } finally {
              try {
                !r && s["return"] && s["return"]();
              } finally {
                if (a) throw o;
              }
            }
            return n;
          }
          return function(t, n) {
            if (Array.isArray(t)) return t;
            if (Symbol.iterator in Object(t)) return e(t, n);
            throw new TypeError("Invalid attempt to destructure non-iterable instance");
          };
        })(),
        i = n(5),
        s = n(2),
        l = n(1),
        u = n(9),
        h = n(6),
        c = n(3),
        d = n(4),
        p = n(7),
        f = ReactLibs.createClass({
          displayName: "MonthView",
          formatAsDay: function(e, t) {
            return e.format(t || "D");
          },
          getDefaultProps: function() {
            return h();
          },
          getInitialState: function() {
            return { range: null };
          },
          getWeekStartMoment: function(e) {
            var t = this.weekStartDay,
              n = this.toMoment(e).day(t);
            return n;
          },
          getDaysInMonth: function(e) {
            var t = this.toMoment(e).startOf("month"),
              n = this.toMoment(e)
                .startOf("month")
                .add(-1, "days"),
              r = this.getWeekStartMoment(t),
              a = [],
              o = 0;
            for (!n.isBefore(r) || (!this.props.alwaysShowPrevWeek && r.isSame(t)) || r.add(-1, "weeks"); 42 > o; o++)
              a.push(this.toMoment(r)), r.add(1, "days");
            return a;
          },
          render: function() {
            var e = l({}, this.props);
            (this.toMoment = function(t, n) {
              return d(t, n || e.dateFormat, { locale: e.locale });
            }),
              (a = +this.toMoment().startOf("day"));
            var t = e.dateFormat,
              n = (e.viewMoment = this.toMoment(e.viewDate, t)),
              r = e.weekStartDay;
            null == r && (r = e.localeData._week ? e.localeData._week.dow : null),
              (this.weekStartDay = e.weekStartDay = r),
              e.minDate && s.isMoment(e.minDate) && e.minDate.startOf("day"),
              e.minDate && (e.minDate = +this.toMoment(e.minDate, t)),
              e.maxDate && (e.maxDate = +this.toMoment(e.maxDate, t)),
              (this.monthFirst = this.toMoment(n).startOf("month")),
              (this.monthLast = this.toMoment(n).endOf("month")),
              e.date && (e.moment = this.props.range ? null : this.toMoment(e.date).startOf("day"));
            var o = this.getDaysInMonth(n);
            return i.createElement(
              "div",
              {
                className: "dp-table dp-month-view",
                onMouseLeave: e.highlightRangeOnMouseMove && this.handleViewMouseLeave,
              },
              this.renderWeekDayNames(),
              this.renderDays(e, o)
            );
          },
          handleViewMouseLeave: function() {
            this.state.range && this.setState({ range: null });
          },
          renderWeekNumber: function m(e, t) {
            var n,
              r = t[0],
              a = r.weeks(),
              o = { key: "week", className: "dp-cell dp-weeknumber", week: a, days: t, date: r, children: a },
              m = e.renderWeekNumber;
            return m && (n = m(o)), void 0 === n && (n = i.createElement("div", o)), n;
          },
          renderDays: function(e, t) {
            for (
              var n,
                r,
                a = t.map(function(t) {
                  return this.renderDay(e, t);
                }, this),
                o = t.length,
                s = [],
                l = Math.ceil(o / 7),
                u = 0;
              l > u;
              u++
            )
              (n = 7 * u),
                (r = 7 * (u + 1)),
                s.push([e.weekNumbers && this.renderWeekNumber(e, t.slice(n, r))].concat(a.slice(n, r)));
            return s.map(function(e, t) {
              return i.createElement("div", { key: "row" + t, className: "dp-week dp-row" }, e);
            });
          },
          renderDay: function(e, t) {
            var n,
              r = u.day(t, e.dayFormat),
              l = ["dp-cell dp-day"],
              h = +t,
              d = this.toMoment(t),
              f = this.handleClick.bind(this, e, t, h),
              m = this.state.range || this.props.range;
            h == a
              ? l.push("dp-current")
              : h < this.monthFirst
                ? l.push("dp-prev")
                : h > this.monthLast && l.push("dp-next"),
              e.minDate && t < e.minDate && (l.push("dp-disabled dp-before-min"), (n = !0));
            var v;
            if (
              (e.maxDate && t > e.maxDate && (l.push("dp-disabled dp-after-max"), (v = !0)),
              h == e.moment && l.push("dp-value"),
              m)
            ) {
              var y = d,
                g = s(y).endOf("day"),
                w = o(m, 2),
                D = w[0],
                k = w[1];
              (p(y, m) || p(g, m) || (D && p(D, [y, g])) || (k && p(k, [y, g]))) && l.push("dp-in-range");
            }
            var N = d.day();
            (0 === N || 6 === N) && (l.push("dp-weekend"), e.highlightWeekends && l.push("dp-weekend-highlight"));
            var x = {
              role: "link",
              tabIndex: 1,
              key: r,
              text: r,
              date: d,
              moment: d,
              className: l.join(" "),
              style: {},
              onClick: f,
              onKeyUp: c(f),
              children: r,
            };
            e.range && e.highlightRangeOnMouseMove && (x.onMouseEnter = this.handleDayMouseEnter.bind(this, x)),
              n && ((x.isDisabled = !0), (x.beforeMinDate = !0)),
              v && ((x.isDisabled = !0), (x.afterMaxDate = !0)),
              "function" == typeof e.onRenderDay && (x = e.onRenderDay(x));
            var b = ReactLibs.DOMFactories.div,
              M = e.renderDay || b,
              S = M(x);
            return void 0 === S && (S = b(x)), S;
          },
          handleDayMouseEnter: function(e) {
            var t = this.props.range;
            if (t && 1 == t.length) {
              var n = o(t, 1),
                r = n[0];
              this.setState({
                range: [r, e.date].sort(function(e, t) {
                  return e - t;
                }),
              });
            } else this.state.range && this.setState({ range: null });
          },
          getWeekDayNames: function(e) {
            e = e || this.props;
            var t = e.weekDayNames,
              n = this.weekStartDay;
            if ("function" == typeof t) t = t(n, e.locale);
            else if (Array.isArray(t)) {
              t = [].concat(t);
              for (var r = n; r > 0; ) t.push(t.shift()), r--;
            }
            return t;
          },
          renderWeekDayNames: function() {
            var e = this.props.weekNumbers ? [this.props.weekNumberName] : [],
              t = e.concat(this.getWeekDayNames());
            return i.createElement(
              "div",
              { className: "dp-row dp-week-day-names" },
              t.map(function(e, t) {
                return i.createElement("div", { key: t, className: "dp-cell dp-week-day-name" }, e);
              })
            );
          },
          handleClick: function(e, t, n, a) {
            (e.minDate && n < e.minDate) ||
              (e.maxDate && n > e.maxDate) ||
              ((a.target.value = t), (e.onChange || r)(t, a));
          },
        });
      (f.getHeaderText = function(e, t) {
        return d(e, null, { locale: t.locale }).format("MMMM YYYY");
      }),
        (t["default"] = f),
        (e.exports = t["default"]);
    },
    function(e, t, n) {
      "use strict";
      function r() {}
      var a,
        o = (function() {
          function e(e, t) {
            var n = [],
              r = !0,
              a = !1,
              o = void 0;
            try {
              for (
                var i, s = e[Symbol.iterator]();
                !(r = (i = s.next()).done) && (n.push(i.value), !t || n.length !== t);
                r = !0
              );
            } catch (l) {
              (a = !0), (o = l);
            } finally {
              try {
                !r && s["return"] && s["return"]();
              } finally {
                if (a) throw o;
              }
            }
            return n;
          }
          return function(t, n) {
            if (Array.isArray(t)) return t;
            if (Symbol.iterator in Object(t)) return e(t, n);
            throw new TypeError("Invalid attempt to destructure non-iterable instance");
          };
        })(),
        i = n(5),
        s = n(2),
        l = n(9),
        u = n(6),
        h = n(4),
        c = n(3),
        d = n(1),
        p = n(7),
        f = ReactLibs.createClass({
          displayName: "YearView",
          getDefaultProps: function() {
            return u();
          },
          getMonthsInYear: function(e) {
            for (var t = s(e).startOf("year"), n = [], r = 0; 12 > r; r++) n.push(s(t)), t.add(1, "month");
            return n;
          },
          render: function() {
            a = +s().startOf("day");
            var e = d({}, this.props),
              t = (e.viewMoment = s(this.props.viewDate));
            this.props.range || (e.moment = s(e.date).startOf("month"));
            var n = this.getMonthsInYear(t);
            return i.createElement("div", { className: "dp-table dp-year-view" }, this.renderMonths(e, n));
          },
          renderMonths: function(e, t) {
            for (
              var n = t.map(function(t) {
                  return this.renderMonth(e, t);
                }, this),
                r = t.length,
                a = [],
                o = Math.ceil(r / 4),
                s = 0;
              o > s;
              s++
            )
              a.push(n.slice(4 * s, 4 * (s + 1)));
            return a.map(function(e, t) {
              return i.createElement("div", { key: "row" + t, className: "dp-row" }, e);
            });
          },
          renderMonth: function(e, t) {
            var n = l.month(t, e.monthFormat),
              r = ["dp-cell dp-month"],
              a = +t;
            if (e.range) {
              var u = t,
                h = s(u).endOf("month"),
                d = o(e.range, 2),
                f = d[0],
                m = d[1];
              (p(u, e.range) || p(h, e.range) || (f && p(f, [u, h])) || (m && p(m, [u, h]))) && r.push("dp-in-range");
            }
            a == e.moment && r.push("dp-value");
            var v = this.handleClick.bind(this, e, t);
            return i.createElement(
              "div",
              { tabIndex: "1", role: "link", key: n, className: r.join(" "), onClick: v, onKeyUp: c(v) },
              n
            );
          },
          handleClick: function(e, t, n) {
            (n.target.value = t), (e.onSelect || r)(t, n);
          },
        });
      (f.getHeaderText = function(e, t) {
        return h(e, null, { locale: t.locale }).format("YYYY");
      }),
        (e.exports = f);
    },
    function(e, t, n) {
      "use strict";
      var r = n(2),
        a =
          1 *
          r()
            .startOf("week")
            .format("d");
      e.exports = function(e, t) {
        var n;
        if (t) {
          var o = r.localeData(t);
          n = o && o._weekdaysShort ? o._weekdaysShort : n;
        }
        n = (n || r.weekdaysShort()).concat();
        for (var i = n, s = null == e ? a : e; s > 0; ) i.push(i.shift()), s--;
        return i;
      };
    },
  ]);
});
