import _ from "underscore";
import EverTrue from "app";
import BaseRouter from "base/base-router";
import PortfolioPerformanceController from "apps/portfolio-performance/controllers/portfolio-performance-controller";
import PortfolioPerformanceSolicitorController from "apps/portfolio-performance/controllers/portfolio-performance-solicitor-controller";
import PortfolioPerformanceTeamController from "apps/portfolio-performance/controllers/portfolio-performance-team-controller";
import { PoolDataProvider } from "apps/volunteers/pool-data-context";

class PortfolioPerformanceRouter extends BaseRouter {
  static initClass() {
    this.prototype.routes = {
      "portfolio-performance(/)": "assignments",
      "portfolio-performance/portfolios": "assignments",
      "portfolio-performance/portfolios/:team_id": "assignments",
      "portfolio-performance/portfolios/:team_id/solicitor/:solicitor_id": "assignments",
      "portfolio-performance/solicitors": "solicitors",
      "portfolio-performance/teams": "teams",
    };
  }

  assignments(orig_team_id, orig_solicitor_id) {
    EverTrue.execute("setBrowserTitle", "Portfolio & Performance");
    const parsed_team_id = orig_team_id ? parseInt(orig_team_id, 10) : null;
    const parsed_solicitor_id = orig_solicitor_id ? parseInt(orig_solicitor_id, 10) : null;
    const team_id = _.isNaN(parsed_team_id) ? null : parsed_team_id;
    const solicitor_id = _.isNaN(parsed_solicitor_id) ? null : parsed_solicitor_id;

    this.currentView = <PortfolioPerformanceController team_id={team_id} solicitor_id={solicitor_id} />;
    EverTrue.page.mount(this.currentView);
  }

  solicitors() {
    EverTrue.execute("setBrowserTitle", "Portfolio & Performance | Solicitors");
    this.currentView = <PortfolioPerformanceSolicitorController />;
    EverTrue.page.mount(this.currentView);
  }

  teams() {
    // pool data resolver is used to load team settings via usePoolDataResolver
    EverTrue.execute("setBrowserTitle", "Portfolio & Performance | Teams");
    this.currentView = (
      <PoolDataProvider>
        <PortfolioPerformanceTeamController />
      </PoolDataProvider>
    );
    EverTrue.page.mount(this.currentView);
  }
}

PortfolioPerformanceRouter.initClass();
export default PortfolioPerformanceRouter;
