import { Component } from "react";
import _ from "underscore";
import PropTypes from "prop-types";
import FacebookPageMatching from "./facebook-page-matching";
import ContactCard from "apps/contact/components/contact-card/contact-card";
import ModalConfirm from "components/modals/modal-confirm";
import ProfileMatchingSource from "apps/contact/sources/profile-matching-source";
import FacebookConnectedPagesResolver from "apps/facebook/components/facebook-connected-pages-resolver";
import Loading from "components/elements/loading";
import { Icon } from "@evertrue/et-components";

class FacebookMatchManage extends Component {
  static propTypes = {
    contact: PropTypes.object.isRequired,
    requestHide: PropTypes.func,
  };

  static defaultProps = {
    contact: {},
    requestHide() {},
  };

  state = {
    unmatchingId: null,
    loading: false,
  };

  setMatchingId = (psid) => {
    if (psid === this.state.unmatchingId) {
      this.setState({ unmatchingId: null });
    } else {
      this.setState({ unmatchingId: psid });
    }
  };

  renderPages = (engagedPages) => {
    if (engagedPages.length > 0) {
      return _.map(engagedPages, (engagedPage) => {
        if (engagedPage.page && engagedPage.mostRecentEngagement) {
          const { mostRecentEngagement } = engagedPage;
          const psid = mostRecentEngagement.profile.remote_id;
          return (
            <FacebookPageMatching
              key={psid}
              pageWithEngagement={engagedPage}
              postUrl={mostRecentEngagement.content.public_url}
              isUnmatching={this.state.unmatchingId === psid}
              onToggle={() => this.setMatchingId(psid)}
              isDisabled={this.state.unmatchingId && this.state.unmatchingId !== psid}
            />
          );
        }
      });
    } else {
      return (
        <div className="empty-state-message">
          Sorry, we are having trouble loading this constituent's social engagements. Please try again later.
        </div>
      );
    }
  };

  renderUnmatchWarning = () => {
    if (this.state.unmatchingId) {
      return (
        <div className="match-facebook-modal-warning">
          <Icon className="match-facebook-modal-warning--icon" icon="warning" />
          <div style={{ lineHeight: "18px" }}>
            Unmatching will result in losing engagement data for this page. Engagement data on matched pages will not be
            affected.
          </div>
        </div>
      );
    }
  };

  cleanupUnmatch = () => {
    this.setState({ loading: false });
    this.props.requestHide();
  };

  handleUnmatch = () => {
    this.setState({ loading: true });
    ProfileMatchingSource.unmatch(this.props.contact.id, "facebook", this.state.unmatchingId);
    _.delay(this.cleanupUnmatch, 2000);
  };

  render() {
    const { contact } = this.props;
    return (
      <FacebookConnectedPagesResolver
        contact={contact}
        render={({ engagedPages, loading }) => {
          return (
            <ModalConfirm
              actionButtonType="destroy"
              header="Manage Facebook Page Match"
              disableButton={!this.state.unmatchingId}
              buttonLabel={"Unmatch"}
              onSubmit={this.handleUnmatch}
              onCancel={this.props.requestHide}
            >
              <div className="match-facebook-list">
                <div className="match-facebook-modal--contact">
                  <ContactCard contact={contact} />
                </div>
                <div className="match-facebook-modal--section">
                  {engagedPages.length > 0 ? (
                    <div className="match-facebook-modal-text-title">Matched Facebook Pages</div>
                  ) : null}

                  {this.renderUnmatchWarning()}
                  {loading || this.state.loading ? <Loading /> : this.renderPages(engagedPages)}
                </div>
              </div>
            </ModalConfirm>
          );
        }}
      />
    );
  }
}

export default FacebookMatchManage;
