module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   {span, a, h1} = ReactLibs.DOMFactories
   {array, func, string} = ReactLibs.PropTypes
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")

   createComponent "FiltersLabel",
      propTypes:
         labels: array
         onClick: func
         defaultValue: string
         prefix: string
         className: string

      render: ->
         {labels, onClick, defaultValue, prefix, className} = @props

         span className: classNames("filters-label", className),
            if _.isEmpty(labels)
               h1 tabIndex: -1, className: "filters-label--default", defaultValue
            else

               prefix_item = if prefix then span className: "filters-label--prefix", prefix

               items = _.flatten _.map labels, (label, index) ->
                  link = a className: "filters-label--item", href: "#", onClick: onClick, label
                  divider = if index < _.size(labels) - 1
                     span className: "filters-label--divider", "and"
                  [link, divider]

               all_items = _.compact [prefix_item].concat(items)

               span className: "filters-label--items", all_items...
