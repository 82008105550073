module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   EventsHelper = require("entities/helpers/events-whitelist").default
   EventsReferrerHelper = require("entities/helpers/event-referrer-helper").default

   class AnalyticsHelper
      currentToken: undefined
      app_started: false
      tokens:
         internal: "e59ea3f0fde9db5892bd17c52b9d33ba"
         external: "f453671950ad65ee683e6ab78b0cc258"

      constructor: ->
         if EverTrue.config.stage_analytics_tracking
            defaultToken = @tokens.internal
         else defaultToken = @tokens.external
         mixpanel?.init(@currentToken || defaultToken)

      update: ->
         mixpanel?.set_config({token: @currentToken})

      reset: ->
         @referrers = []
         EverTrue.track.setToken()
         mixpanel?.reset?()

      pause: ->
         @paused = true

      restart: ->
         @paused = false

      isInternal: (user) ->
         if !user then mixpanel?.get_property?("is_internal_user")
         else !!user?.isInternal()

      setFullStoryUser: (user) ->
         try
            FS?.identify? user.get("id"),
               displayName: user.get("name")
               email: user.get("email")
               internalUser_bool: user.isInternal() || user.isSuperUser()
               environment_str: EverTrue.config.environment_data

            # Disable FullStory console tracking so that errors link to correct file
            if EverTrue.config.disable_fs_console
               FS?.disableConsole?()
         finally

      setUserData: (user) ->
         return unless EverTrue.config.stage_analytics_tracking or EverTrue.config.prod_analytics_tracking
         return if @app_started
         return unless user.get("id")
         @setToken(user)

         mixpanel?.identify(user.get("id"))
         mixpanel?.people.set
            $email: user.get("email")
            $name: user.get("name")

         mixpanel?.register
            name: user.get("name")
            user_id: user.get("id")
            is_internal_user: @isInternal(user)
            browser_version: navigator.sayswho()
            platform: "web"

         @app_started = true

      updateMixpanel: (user, impersonated_user) ->
         is_impersonator = false
         impersonator_id = null
         if impersonated_user?.get("id")
            is_impersonator = true
            impersonator_id = impersonated_user?.get("id")

         if @isInternal(user)
            mixpanel?.register
               is_impersonator: is_impersonator
               impersonator_id: impersonator_id

      setOrgData: (org, user) ->
         return unless @currentToken && org.get("slug")
         mixpanel?.register
            oid: org.get("slug")

         mixpanel?.people.set
            roles: _.pluck(user.getRoles(), "name")
            oid: org.get("slug")

      setToken: (user) ->
         return unless EverTrue.config.stage_analytics_tracking or EverTrue.config.prod_analytics_tracking

         if EverTrue.config.stage_analytics_tracking
            @currentToken = @tokens.internal
         else
            @currentToken = @tokens.external
         @update()

      set: (event_key, params={}) ->
         return unless EverTrue.config.stage_analytics_tracking or EverTrue.config.prod_analytics_tracking
         return if @paused

         # Dont send undocumented events
         evnt = EventsHelper[event_key]
         if !evnt
            @warn("Event '#{event_key}' not whitelisted.")
            return null

         # Dont send undocumented params
         valid_param_keys =_.keys(_.omit(params, "type", "referrer"))
         if !_.isEmpty(_.difference(valid_param_keys, evnt?.props))
            @warn("#{event_key} is sending undocumented parameters", evnt?.props)
         send_params = _.extend({}, _.pick(params, evnt?.props))

         # Dont send events with undocumented types
         if evnt?.type
            if !params.type
               @warn("'#{event_key}'' event is not sending a type.", params)
               return
            else
               type_value = evnt.type[params.type]
               if !type_value
                  @warn("'#{event_key}' event type is not whitelisted.", "'#{params.type}'")
                  return
               else
                  send_params.type = type_value

         refer = @getReferrer(event_key, params)
         if refer then send_params.referrer = refer

         evnt_name = evnt?.name || event_key
         mixpanel?.people?.increment(evnt_name)
         mixpanel?.track(evnt_name, send_params)

      referrerHistory: []
      setReferrer: (key) ->
         if key and key != "$last"
            @referrerHistory.push(key)
            @referrerHistory = _.last(@referrerHistory, 4)

      getReferrer: (evnt, params) ->
         eventsWithReferrers = _.map EventsHelper, (evnt, key) -> key if evnt.hasReferrer
         if evnt not in _.compact(eventsWithReferrers) then return null

         if params.referrer then @setReferrer(params.referrer)
         referrer = _.last(@referrerHistory)

         if referrer then return EventsReferrerHelper[referrer] || referrer
         if !params.referrer then return EventsReferrerHelper["default"]

      warn: (message, details) ->
         if EverTrue.config.stage_analytics_tracking
            console.warn("MIXPANEL ANALYTICS: #{message}", details || "")
