module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   EverTrue = require("app")
   {createComponent} = require("@evertrue/et-flux")
   {div, button, img, span, br} = ReactLibs.DOMFactories
   {string, func, bool} = ReactLibs.PropTypes
   CodeInput = require("components/forms/code-input")
   MfaSource = require("base/mfa/mfa-source")
   {createFactory} = require("base/new-utils")
   Checkbox = createFactory(require("@evertrue/et-components").Checkbox)
   HelpTip = createFactory require("components/tooltip/helptip").default
   classNames = require("classnames")
   LinkButton = createFactory(require("@evertrue/et-components").LinkButton)

   status = (text) -> span className: "mfa-modal--resend-status", text

   createComponent "MfaModal",
      propTypes:
         status: string
         error: string
         resend_status: string
         number: string
         showTrustDevice: bool
         onVerify: func # arguments: (code, trust)
         onResend: func # no arguments
         prompt_text: string

      getDefaultProps: ->
         prompt_text: "2-Step Verification Required"

      getInitialState: ->
         input_code: ""
         trust_device: !!(EverTrue.store.session.get("trusted_device") || EverTrue.store.mfa_trust_device)

      verifyIsEnabled: ->
         _.size(@state.input_code) is 6 and @props.status is "prompting"

      handleTrustChange: (isChecked, event) ->
         @setState trust_device: !!isChecked

      handlePressEnter: ->
         if @verifyIsEnabled() then @handleVerify()

      handleVerify: ->
         EverTrue.store.push("mfa_trust_device", @state.trust_device, cache: true)
         @props.onVerify?(@state.input_code, @state.trust_device)

      render: ->
         disabled = not @verifyIsEnabled()

         div className: "mfa-modal",
            div className: "mfa-modal--header",
               img className: "mfa-modal--header-phone-image", src: "/images/iphone-code.png"
            if @props.status is "code-unsent"
               div className: "mfa-modal--content",
                  div className: "mfa-modal--heading",
                     @props.prompt_text
                  div className: "mfa-modal--subtext",
                     "A verification code is required"
                     br()
                     "to continue."
                  button
                     className: "mfa-modal--button btn btn-large"
                     onClick: @props.onResend
                     switch @props.resend_status
                        when "requesting" then "Sending..."
                        else "Send Code"
                  div className: "mfa-modal--note",
                     "A text message with a verification code will be sent to: #{@props.number}"
            else
               div className: "mfa-modal--content fs-exclude-data",
                  div className: "mfa-modal--subtext",
                     "A text message with a verification code has been sent to: #{@props.number}"
                  div className: "mfa-modal--text",
                     "Enter Code"
                  CodeInput
                     className: classNames("mfa-modal--input", "is-error": @props.error),
                     onChange: ((code) => @setState input_code: code)
                     onEnter: @handlePressEnter

                  if @props.error
                     div className: "mfa-modal--error-text",
                        @props.error

                  if @props.showTrustDevice
                     Checkbox
                        checked: @state.trust_device
                        onChange: @handleTrustChange
                        span
                           className: "mfa-modal--trust-label",
                           "Trust this device?"
                           HelpTip help_key: "trust_device"
                  button
                     className: "mfa-modal--button btn btn-large"
                     disabled: disabled
                     onClick: @handleVerify
                     switch @props.status
                        when "requesting" then "Authenticating..."
                        else "Verify"

                  div className: "mfa-modal--resend-link-area",
                     switch @props.resend_status
                        when "requesting" then status "Requesting new code..."
                        when "success" then status "Code sent."
                        when "error" then status "Problem Sending Code."
                        when "rate-limited" then status "Requests pending, please wait..."
                        else
                           LinkButton
                              title: "Resend code"
                              className: "mfa-modal--resend-link"
                              onClick: @props.onResend
                              "Resend Code"
