import _ from "underscore";
import { Component } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { ModalConfirm } from "@evertrue/et-components";
import DatePickerDropdown from "components/calendar/date-picker-dropdown";

class UpdateTimeInStageModal extends Component {
  static propTypes = {
    assignment: PropTypes.object,
    close: PropTypes.func,
    onSubmit: PropTypes.func,
  };

  static defaultProps = {
    assignment: {},
    close: _.noop,
    onSubmit: _.noop,
  };

  state = {
    start_date: this.props.assignment.stage_start_date || moment.now(),
  };

  handleSubmit = () => {
    this.props.onSubmit(this.state.start_date);
    this.props.close();
  };

  handleChangeDate = start_date => {
    this.setState({ start_date });
  };

  render() {
    const { assignment } = this.props;
    return (
      <ModalConfirm
        style={{ minWidth: "400px" }}
        buttonLabel="Save"
        onSubmit={this.handleSubmit}
        header="Update time in stage"
        onCancel={this.props.close}
      >
        <div className="update-time-in-stage-modal--body">
          <div className="update-time-in-stage-modal--top-text">
            <span className="update-time-in-stage-modal--light-text">Current time in stage: </span>
            {assignment.stage_start_date ? moment(assignment.stage_start_date).fromNow(true) : "-"}
          </div>
          <strong>Start Date</strong>
          <DatePickerDropdown date={this.state.start_date} onDayClick={this.handleChangeDate} />
        </div>
      </ModalConfirm>
    );
  }
}

export default UpdateTimeInStageModal;
