import PropTypes from "prop-types";

const FilterPickerCount = ({ type="", filterResults = {} }) => {
  const getGroupCount = (filtersList) => {
    // the search view operates across categories, so the group value is present at the second
    // level instead of the first. use the appropriate function for group count depending on type
    if (type === "search") {
      return Object.keys(filtersList).map((k) => {
        return Object.keys(filtersList[k]).length;
      }).reduce((acc, val) => {
        return acc + val;
      }, 0);
    } else {
      return Object.keys(filtersList).length;
    }
  };

  const getFilterCount = (filtersList) => {
    let filterCount = 0;

    for (let k in filtersList) {
      if (Array.isArray(filtersList)) {
        // leaf-level objects. can sum here
        filterCount = filtersList.length;
      } else {
        // branch-level objects. need to go one level deeper
        filterCount += getFilterCount(filtersList[k]);
      }
    }

    return filterCount;
  };

  return <div className="filter-picker--count">
    Showing <strong>{getFilterCount(filterResults)}</strong> filters from <strong>{getGroupCount(filterResults)}</strong> groups
  </div>;
};

FilterPickerCount.propTypes = {
  type: PropTypes.oneOf(["search", "category"]),
  filterResults: PropTypes.object,
};

export default FilterPickerCount;
