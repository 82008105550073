module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   AuthApi = require("entities/auth/auth-api")
   QueryParser = require("entities/search/query-parser")
   Query = require("entities/search/query-parts/query")
   Parameter = require("entities/search/query-parts/parameter-query")
   Property = require("entities/search/query-parts/property-query")
   ChildDoc = require("entities/search/query-parts/child-doc-query")
   FilterConfig = require("apps/filters/filter-config")
   FacetUtils = require("apps/filters/utils/facet-utils")
   moment = require("moment")

   getAddressTypes = ->
      types = EverTrue.store.facets?["addresses.type"]
      home_types = _.compact _.map types, (type) ->
         if !!type?.value?.match(/(home)/i) then type.value

      if !_.isEmpty(home_types)
         Property("type", home_types, {type: "contains", parent: "addresses", parent_type: "instance"})

   dateLabelFn = (facet_key, value) ->
      facets = EverTrue.staticFacets
      param = _.findWhere facets[facet_key + "_control"], {value: value?.parameter}
      val = _.findWhere facets[facet_key + "_time"], {value: value?.value}
      label = val?.label
      if _.isObject(value?.range) #range
         from = moment(value.range.gte).format("MM/DD/YYYY") if value.range.gte
         to = moment(value.range.lte).format("MM/DD/YYYY") if value.range.lte
         label = "between #{from || ""} #{if from && to then "-" else ""} #{to || ""}"
      param?.label + " " + label

   getQueryObjectForDate = (value) ->
      # expects value = {parameter: "...", value: "...", range: {}}
      if value.range # range of dates
         val = _.clone(value.range)
         val.gte = moment(val.gte).format("YYYY-MM-DD") if val.gte
         val.lte = moment(val.lte).format("YYYY-MM-DD") if val.lte
      else
         val = {gte: value.value}
      val

   _formatOrProperties = (prop_values) ->
      properties = _.compact _.map prop_values, (val, key) ->
         unless _.isEmpty(val)
            Property(key, val, {type: "contains"})

      # Default property check require for parsing saved searches
      query_prop = properties.shift() || Property(_.keys(prop_values), null, {type: "contains"})
      if properties.length && query_prop
         query_prop.orProperties(properties)
      query_prop


   class Filters extends AuthApi.Model
      type_map:
         string: "contains"
         number: "coerce"
         datetime: "contains"
         date_string: "contains"
         "boolean": "equals"
         currency: "coerce"

      aliases: {}

      configFlexFields: (fields, options={}) ->
         _.each fields, (field) =>
            @config[field.name] ?=
               label: field.description
               query_fn: (value) =>
                  Query [
                     Parameter "must", [
                        Property(@propName(field), value, {type: @type_map[field.data_type]})
                     ]
                  ]

            #TODO: Remove when platform has supported better dates
            if field.data_type == "date_string"
               @config[field.name].label_fn = (value) ->
                  values = _.map _.makeArray(value), (val) ->
                     moment(new Date(val)).format("MM/DD/YYYY")
                  values.join(", ")

         @trigger("change:config") unless options.silent

      validateConfig: ->
         _.all @toJSON(), (value, key) => !!@config[key]

      propName: (prop) ->
         name = prop.list_name + "." + prop.name
         if prop.data_type in ["string", "date_string", "datetime"]
            name += ".untouched"
         name

      getProperty: (key) ->
         @config[key]?.properties || @config[key]?.property

      parse: (query, alias, options={}) ->
         parser = new QueryParser(@config)
         parser.run(query, alias, options.disableSort)
         @clear({silent: true})
         @set(parser.getModel())

      # Convert Legacy Filter Object into new Filter Array for UI_V2
      parseLegacy: (filter_config) ->
         count = 0
         model = @toJSON()
         new_filters = _.compact _.map model, (value, key) ->
            # Conversion from old location filter, make the best possible guess
            # LinkedIn Locations tend to be longer and contain the word "Area"
            if key == "location"
               li_guesses = _.filter value, (val) ->
                  val.match(/\bArea/) || val.split(" ")?.length > 2
               if li_guesses.length >= (value.length / 2) then key = "li_location"
               else if !_.has(model, "address_city") then key = "address_city"
            else if key == "giving_history"
               if value in ["giving.lybunt", "giving.sybunt"]
                  key = "giving_pattern"
                  value += ":true"
               else
                  value = !value.match("must_not")

            config = filter_config?[key] || FilterConfig[key]
            return unless config
            count += 1

            _mapListWithGroups = ->
               _.flatten _.map _.makeArray(value), (val) ->
                  if _.size(config?.facets) > 1
                     groups = _.keys(config.facets)
                     [li_group, group_key] = _.partition groups, (group) ->
                        group.toLowerCase().match("linkedin")
                     group_key = li_group if FacetUtils.isLinkedin(val)
                     label = FacetUtils.parseValueToString(val)
                     {label: label, value: val, group: group_key[0]}
                  else
                     {label: val, value: val}

            switch config.component_type
               when "basic_select"
                  value =
                     parameter: {value: if value.match("must_not") then "must_not" else "must"}
                     value: {value: value}
               when "list"
                  value = _mapListWithGroups()
               when "suggestion_list"
                  value = _mapListWithGroups()
               when "multi_select_with_not"
                  value =
                     parameter: value: "must"
                     value: _.map _.makeArray(value), (val) ->
                        {label: val, value: val}
               when "date_range"
                  value =
                     parameter: {value: value?.parameter}
                     value: value?.value
                     range: value?.range
               else
                  if key in ["unrated", "unassigned"]
                     value = !value #inversed these filters

            {filter_id: config?.filter_id, filter_row_id: count, value: value}
         new_filters

      query: (options={}) ->
         fullQuery = Query()
         @aliases = {}

         _.each @toJSON(), (value, key) =>
            query = @config[key]?.query_fn?(value)
            fullQuery.merge(query) unless _.isEmpty(query)
            if @config[key]?.aliased then @aliases[key] = value

         @trigger("query") unless options.silent
         fullQuery.toJSON()

      config:
         capacity_score:
            label: "Capacity Rating"
            query_fn: (value) ->
               Query [
                  Parameter "must", [
                     Property "giving.capacity_score", value, {type: "contains"}
                  ]
               ]

         unrated:
            label:
               true: "Is Unrated"
               false: "Has Capacity Rating"
            aliased: true
            query_fn: (value) ->
               query_value = if value and value == "true" || value is true then false else true
               Query [
                  Parameter "must", [
                     Property "giving.capacity_score", query_value, {type: "exists"}
                  ]
               ]

         median_income:
            label: "Neighborhood Median Income"
            query_fn: (value) ->
               Query [
                  Parameter "must", [
                     Property "income_median", value, {type: "coerce", parent: "addresses", parent_type: "instance"}
                  ]
               ]

         median_home_value:
            label: "Neighborhood Median Home Value"
            query_fn: (value) ->
               address_prop = getAddressTypes()

               properties = [Property("house_value_median", value, {type: "coerce", parent: "addresses", parent_type: "instance"})]
               properties.push(address_prop) if !_.isEmpty(address_prop)

               Query [
                  Parameter "must", properties
               ]

         wealthy_neighborhood:
            label:
               true: "Lives in a wealthy neighborhood"
               false: "Does Not Live in a wealthy neighborhood"
            aliased: true
            query_fn: (value) ->
               param_type = if value and value == "true" || value is true then "must" else "must_not"
               address_prop = getAddressTypes()

               properties = [Property("house_value_median",  {gte: 1000000}, {type: "coerce", parent: "addresses", parent_type: "instance"})]
               properties.push(address_prop) if !_.isEmpty(address_prop)

               Query [
                  Parameter param_type, properties
               ]

         engagement_score:
            label: "Engagement Score"
            query_fn: (value) ->
               Query [
                  Parameter "must", [
                     Property "giving.engagement_score", value, {type: "contains"}
                  ]
               ]

         community_app_engagement:
            label:
               true: "Engaged on EverTrue Community App"
               false: "Not Engaged on EverTrue Community App"
            aliased: true
            query_fn: (value) ->
               property = if value and value == "true" || value is true then "must" else "must_not"
               Query [
                  Parameter property, [
                     Property "identities.type", 4, {type: "equals"}
                  ]
               ]

         facebook_engagement:
            label:
               true: "Engaged on Facebook"
               false: "Not Engaged on Facebook"
            aliased: true
            query_fn: (value) ->
               property = if value and value == "true" || value is true then "must" else "must_not"
               Query [
                  Parameter property, [
                     Property [
                        "facebook.like_count",
                        "facebook.comment_count"
                     ], {gt: 0}, {type: "coerce"}
                  ]
               ]

         last_fb_engagement_date:
            aliased: true
            label: "Last Facebook Engagement Date"
            label_fn: (value) ->
               dateLabelFn("last_fb_engagement_date", value)
            query_fn: (value) ->
               val = getQueryObjectForDate(value)
               Query [
                  Parameter value.parameter, [
                     Property "facebook.last_engagement_date", val, {type: "object"}
                  ]
               ]

         facebook_likes:
            label: "Facebook Likes"
            query_fn: (value) ->
               Query [
                  Parameter "must", [
                     Property "facebook.like_count", value, {type: "coerce"}
                  ]
               ]

         facebook_comments:
            label: "Facebook Comments"
            query_fn: (value) ->
               Query [
                  Parameter "must", [
                     Property "facebook.comment_count", value, {type: "coerce"}
                  ]
               ]

         giving_history:
            aliased: true
            label: "Giving History"
            label_fn: (value) ->
               option = _.findWhere EverTrue.staticFacets["giving_history"], {value: value}
               option?.label || ""
            query_fn: (value) ->
               [property, param]  = value?.split?(":") || []
               if property is "giving.lifetime_amount"
                  val = {gt: 0}
                  type = "coerce"
               else
                  val = true
                  type = "equals"

               Query [
                  Parameter (param || "must"), [
                     Property property, val, {type: type}
                  ]
               ]

         lifetime_giving:
            label: "Lifetime Giving"
            query_fn: (value) ->
               Query [
                  Parameter "must", [
                     Property "giving.lifetime_amount", value, {type: "coerce"}
                  ]
               ]

         last_gift:
            label: "Last Gift"
            query_fn: (value) ->
               Query [
                  Parameter "must", [
                     Property "giving.last_gift_amount", value, {type: "coerce"}
                  ]
               ]

         largest_gift:
            label: "Largest Gift"
            query_fn: (value) ->
               Query [
                  Parameter "must", [
                     Property "giving.largest_gift_amount", value, {type: "coerce"}
                  ]
               ]

         last_gift_date:
            aliased: true
            label: "Last Gift Date"
            label_fn: (value) ->
               dateLabelFn("last_gift_date", value)
            query_fn: (value) ->
               val = getQueryObjectForDate(value)
               val.lte = "now" unless val.lte

               Query [
                  Parameter value.parameter, [
                     Property "giving.last_gift_date", val, {type: "object"}
                  ]
               ]

         linkedin_match:
            label:
               true: "Matched on Linkedin"
               false: "Not Matched on Linkedin"
            query_fn: (value) ->
               property = if value and value == "true" || value is true then "must" else "must_not"
               Query [
                  Parameter property, [
                     Property "linkedin.uid", true, {type: "exists"}
                  ]
               ]

         donor:
            label:
               true: "Is a Donor"
               false: "Is NOT a Donor"
            aliased: true
            query_fn: (value) ->
               property = if value and value == "true" || value is true then "must" else "must_not"
               Query [
                  Parameter property, [
                     Property "giving.lifetime_amount", {gt: 0}, {type: "coerce"}
                  ]
               ]

         donor_score:
            label: "Donor Score"
            query_fn: (value) ->
               Query [
                  Parameter "must", [
                     Property "giving.donor_score", value, {type: "contains"}
                  ]
               ]

         industry:
            label: "Industry"
            query_fn: (value) ->
               [linkedin, customer] = _.partition value, (val) ->
                  FacetUtils.isLinkedin(val)

               properties = _formatOrProperties
                  "employments.industry.untouched": FacetUtils.parseToQuery(customer)
                  "linkedin.industry_description.untouched": FacetUtils.parseToQuery(linkedin)

               Query [
                  Parameter "must", [properties]
               ]

         company:
            label: "Company"
            query_fn: (value) ->
               [linkedin, customer] = _.partition value, (val) ->
                  FacetUtils.isLinkedin(val)

               properties = _formatOrProperties
                  "employments.company.untouched": FacetUtils.parseToQuery(customer)
                  "linkedin_positions.company_name.untouched": FacetUtils.parseToQuery(linkedin)

               Query [
                  Parameter "must", [properties]
               ]

         li_headline:
            label: "LinkedIn Headline"
            aliased: true
            label_fn: (value) ->
               value.join?(", ")
            query_fn: (value) ->
               _isAnalyzed = (val) -> !val.match("contains: ")
               props =
                  "linkedin.resolved_title": _.filter value, _isAnalyzed
                  "linkedin.headline": _.compact _.map value, (val) ->
                     val.replace("contains: ", "").replace(/\"/g, "") unless _isAnalyzed(val)

               properties = _.compact _.map props, (val, key) ->
                  unless _.isEmpty(val)
                     Property(key, val, type: "contains")

               # Default property check require for parsing saved searches
               query_prop = properties.shift() || Property(_.keys(props), null, {type: "contains"})
               if properties.length && query_prop
                  query_prop.orProperties(properties)

               Query [
                  Parameter "must", [query_prop]
               ]

         title:
            label: "Title"
            query_fn: (value) ->
               Query [
                  Parameter "must", [
                     Property "employments.title.untouched", value, {type: "contains"}
                  ]
               ]

         function:
            label: "Function"
            query_fn: (value) ->
               Query [
                  Parameter "must", [
                     Property "employments.function.untouched", value, {type: "contains"}
                  ]
               ]

         assignee:
            label: "Assigned To"
            query_fn: (value) ->
               Query [
                  Parameter "must", [
                     Property "assignees.name.untouched", value, {type: "contains"}
                  ]
               ]

         unassigned:
            label:
               true: "Is NOT Assigned"
               false: "Is Assigned"
            aliased: true
            query_fn: (value) ->
               param_type = if value and value == "true" || value is true then "must_not" else "must"
               Query [
                  Parameter param_type, [
                     Property "assignees.name", true, {type: "exists"}
                  ]
               ]

         year:
            label: "Graduation Year"
            query_fn: (value) ->
               Query [
                  Parameter "must", [
                     Property "year", value, {type: "object"}
                  ]
               ]

         reunion_year:
            aliased: true
            label: "Reunion Year Group"
            label_fn: (value) ->
               groups = _.compact _.map value, (val) ->
                  group = _.findWhere EverTrue.staticFacets["reunion_year"], {value: val}
                  group?.label
               groups.join(", ")
            query_fn: (values) ->
               current_year = moment(new Date()).year()
               first_year = current_year - 150
               groups = _.flatten _.map values, (value) -> value?.split?("and") || []
               reunions = _.compact _.map [first_year..current_year], (year) ->
                  if (_.last(year.toString()) in groups) then parseInt(year)

               Query [
                  Parameter "must", [
                     Property "year", reunions, {type: "contains"}
                  ]
               ]

         education:
            label: "Education"
            query_fn: (value) ->
               Query [
                  Parameter "must", [
                     Property "school_name.untouched", value, {type: "contains", parent: "educations", parent_type: "instance"}
                  ]
               ]

         degree:
            label: "Degree"
            query_fn: (value) ->
               Query [
                  Parameter "must", [
                     Property "degree_key.untouched", value, {type: "contains", parent: "educations", parent_type: "instance"}
                  ]
               ]

         major:
            label: "Major"
            query_fn: (value) ->
               Query [
                  Parameter "must", [
                     Property "majors.untouched", value, {type: "contains", parent: "educations", parent_type: "instance"}
                  ]
               ]

         location:
            label: "LinkedIn Location"
            query_fn: (value) ->
               Query [
                  Parameter "must", [
                     Property "linkedin.location.untouched", value, {type: "contains"}
                  ]
               ]

         address_city:
            label: "City"
            query_fn: (value) ->
               Query [
                  Parameter "must", [
                     Property "city.untouched", value, {type: "contains", parent: "addresses", parent_type: "instance"}
                  ]
               ]

         address_state:
            label: "State"
            query_fn: (value) ->
               Query [
                  Parameter "must", [
                     Property "state.untouched", value, {type: "contains", parent: "addresses", parent_type: "instance"}
                  ]
               ]

         address_type:
            label: "Address Type"
            query_fn: (value) ->
               Query [
                  Parameter "must", [
                     Property "type", value, {type: "contains", parent: "addresses", parent_type: "instance"}
                  ]
               ]

         zipcode:
            label: "Zip Code"
            query_fn: (value) ->
               Query [
                  Parameter "must", [
                     Property "zip_code", value, {type: "contains", parent: "addresses", parent_type: "instance"}
                  ]
               ]

         like:
            label: "Similar To"
            query_fn: (value) ->
               Query [
                  Parameter "like", value
               ]

         map:
            label: override: "Map Coordinates"
            query_fn: (value) ->
               Query [
                  Parameter "must", [
                     Property "location", value, {type: "object", parent: "addresses", parent_type: "instance"}
                  ]
               ]

         full_name:
            label: "Name"
            query_fn: (value) ->
               names = if value then value?.split?(" ") else [value]
               properties = _.map names, (name) ->
                  Property [
                     "name_first",
                     "name_last",
                     "name_nick",
                     "name_maiden",
                     "name_suffix",
                     "name_prefix"
                  ], name, {type: "wildcard"}

               Query [Parameter("must", properties)]

         full_name_should:
            query_fn: (value) ->
               names = if value then value?.split?(" ") else [value]
               properties = _.map names, (name) ->
                  Property [
                     "name_first",
                     "name_last",
                     "name_nick",
                     "name_maiden",
                     "name_suffix",
                     "name_prefix"
                  ], name, {type: "wildcard"}

               Query [Parameter("should", properties)]

         # Specifically for Exporting Facebook Engagement Posts
         # Since the component"s use Filters to render the display
         contact_post_engagers:
            label: override: "Matched Constituents for Facebook post"
            query_fn: (value) ->
               Query [
                  ChildDoc "social", Query [
                     Parameter "must", [
                        Property("engagement.type", value.types, {type: "contains"}),
                        Property("content.remote_id", value.post_id)
                     ]
                  ]
               ]

         facebook_posts:
            query_fn: (value) ->
               Query [
                  Parameter "must", [
                     Property "remote_id", value, {type: "contains"}
                  ]
               ]

         score_et:
            label: "EverTrue Score"
            query_fn: (value) ->
               Query [
                  Parameter "must", [
                     Property "score.score", value, {type: "coerce"}
                  ]
               ]

         score_professional:
            label: "Professional Fit"
            query_fn: (value) ->
               Query [
                  Parameter "must", [
                     Property "score.professional", value
                  ]
               ]

         score_demographics:
            label: "Demographic Fit"
            query_fn: (value) ->
               Query [
                  Parameter "must", [
                     Property "score.demographics", value
                  ]
               ]

         score_giving:
            label: "Giving Score"
            query_fn: (value) ->
               Query [
                  Parameter "must", [
                     Property "score.giving", value
                  ]
               ]

         score_social:
            label: "Social Activity"
            query_fn: (value) ->
               Query [
                  Parameter "must", [
                     Property "score.social", value
                  ]
               ]
