import { useState } from "react";
import { Radio, Icon, Button } from "@evertrue/et-components";
import EverTrue from "app";
import PropTypes from "prop-types";

const EmailSignature = ({
  signature = {},
  selectedSignature = {},
  setSelectedSignature,
  updateSignature,
  handleDeleteSignature,
  defaultSignature,
}) => {
  const [edtingSignatureId, setEdtingSignatureId] = useState(null);
  const [editingSignatureName, setEditingSignatureName] = useState("");
  const { id, signature_name, signature_body } = signature;
  const { signature_name: selectedSignatureName, id: selectedSignatureId } = selectedSignature;

  const handleDelete = (name) => {
    EverTrue.Alert.confirm(
      {
        headline: "Delete Signature",
        content: `Are you sure you want to delete the signature ${name}?`,
        buttonLabel: "Delete",
      },
      async (confirmed) => {
        if (confirmed) {
          await handleDeleteSignature(id);
        }
      }
    );
  };

  return (
    <div
      className="email-signature-library--item"
      style={{ justifyContent: edtingSignatureId === id ? "flex-start" : "space-between" }}
    >
      <div className="email-signature--radio">
        <Radio
          label={edtingSignatureId === id ? "" : signature_name}
          name="signatures"
          checked={selectedSignatureId === id}
          value={selectedSignatureName}
          onChange={() => setSelectedSignature({ id, signature_name, signature_body })}
        />
        {defaultSignature && defaultSignature.id === id && <span className="default-signature-label">Default</span>}
      </div>
      {edtingSignatureId === id ? (
        <div className="text-input-form">
          <input
            value={editingSignatureName}
            onChange={({ target: { value } }) => setEditingSignatureName(value)}
            style={{ width: "76%" }}
            maxlength="255"
          ></input>
          <div>
            <Button
              type="simple"
              onClick={async () => {
                await updateSignature(id, { signature_name: editingSignatureName });
                setEdtingSignatureId({});
              }}
              disabled={editingSignatureName.trim() === ""}
            >
              Save
            </Button>
            <Icon
              icon="clear"
              onClick={() => setEdtingSignatureId(null)}
              isReachable={true}
              onKeyUp={() => setEdtingSignatureId(null)}
            />
          </div>
        </div>
      ) : signature.id !== 0 ? (
        <div className="email-template--icon-container">
          <Icon
            icon="edit"
            onClick={() => {
              setEditingSignatureName(signature_name);
              setEdtingSignatureId(id);
            }}
            isReachable={true}
            onKeyUp={() => {
              setEditingSignatureName(signature_name);
              setEdtingSignatureId(id);
            }}
          />
          <Icon
            icon="trash"
            onClick={(e) => {
              handleDelete(signature_name);
              e.stopPropagation();
            }}
            isReachable={true}
            onKeyUp={(e) => {
              handleDelete(signature_name);
              e.stopPropagation();
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

EmailSignature.propTypes = {
  signature: PropTypes.object,
  selectedSignature: PropTypes.object,
  setSelectedSignature: PropTypes.func.isRequired,
  updateSignature: PropTypes.func.isRequired,
  handleDeleteSignature: PropTypes.func.isRequired,
  defaultSignature: PropTypes.object,
};

export default EmailSignature;
