module.exports = do ->
   _ = require("underscore").default
   $ = require("jquery")
   EverTrue = require("app")
   {createStore} = require("@evertrue/et-flux")
   Query = require("entities/search/query-parts/query")
   Filters = require("entities/search/filters")
   FilterConfigStore = require("apps/filters/stores/filter-config-store")
   FilterSource = require("apps/filters/sources/filter-source")
   PropertySource = require("apps/contact/sources/property-source")
   UrlSource = require("apps/layout/sources/url-source")
   MapStore = require("apps/map/stores/map-store")
   MapSource = require("apps/map/sources/map-source")
   FilterParser = require("apps/filters/utils/filter-parser-utils")
   ErrorLogger = require("entities/helpers/error-log-helper")
   MapUtils = require("apps/map/map-utils")

   _emptyFilters = ->
      [{filter_row_id: 0}]

   _cluster_query = (box) ->
      MapUtils.getClusterQuery(box)

   _trackError = (id, filter_rep, criteria) ->
      ErrorLogger.warn "Filter Config Error",
         {extra: {hash_id: id, filter: filter_rep, criteria: criteria}}
      return undefined

   _getMergedQuery = (filters, operator, base_query) ->
      fullQuery = Query(base_query)

      _.each filters, (filter) ->
         conf = FilterConfigStore.getConfigById(filter.filter_id)
         query = conf?.query_fn(filter.value)
         unless _.isEmpty(query)
            unless conf.key == "map" && operator == "or"
               fullQuery.merge(query)
      fullQuery


   createStore "FilterStore",
      getInitialState: ->
         active_filters: []
         temp_filters: [{filter_row_id: 0}]
         operator: "and"
         temp_operator: "and"
         loading: false

      registerActions: ->
         @on FilterSource.actions.setFilters, @respondToSetFilters
         @on FilterSource.actions.parseAndSet, @respondToParseAndSet

         @on FilterSource.actions.addFilter, @respondToAdd
         @on FilterSource.actions.updatedFilter, @respondToUpdate
         @on FilterSource.actions.removedFilter, @respondToRemove
         @on FilterSource.actions.updatedMapFilter, @respondToUpdateMap
         @on FilterSource.actions.updatedOperator, @respondToOperator
         @on FilterSource.actions.clearedFilters, @respondToClear
         @on FilterSource.actions.cancel, @respondToCancel
         @on FilterSource.actions.loading, (is_loading) =>
            @setState {loading: is_loading}

         @on FilterSource.actions.fetchFilteredClusters, =>
            MapSource.fetchClusters(MapStore.getZoom(), {query: @getMapFilterQuery()})

         @on UrlSource.actions.initializedUrl, (url) =>
            @respondToChangedFilters(url.filters)
            operator = url.operator || "and"
            @setState {operator: operator, temp_operator: operator}

         @on UrlSource.actions.fetchedFilters, (filters) =>
            @respondToChangedFilters(filters)

         @on UrlSource.actions.fetchedOperator, (operator) =>
            @setState {operator: operator || "and"}

         @on PropertySource.actions.fetchedSchema, @respondToFetchedSchema

      respondToChangedFilters: (filters) ->
         filters = @parseLegacyFilters(filters)
         filters = _.map filters, (filter) ->
            _.extend {}, filter, {filter_row_id: _.toNumber(filter.filter_row_id)}

         if _.isEmpty(filters) then temp = _emptyFilters()
         else temp = _.map filters, (f) -> _.clone(f)


         @setState
            temp_filters: temp
            active_filters: filters
            loading: false

      respondToSetFilters: (filters) ->
         @respondToChangedFilters(filters)
         @setState {operator: @getState("temp_operator")}
         UrlSource.updateFilters(@getState("active_filters"))
         UrlSource.updateOperator(@getState("operator"))

      respondToClear: ->
         @setState
            temp_filters: _emptyFilters()
            temp_operator: "and"

      respondToParseAndSet: (hash_id, filter_rep, criteria) ->

         filters = _.compact _.map filter_rep, (rep, index) ->
            if conf = FilterConfigStore.getConfigByKey(rep.id)
               filter_id: conf.filter_id
               value: FilterParser.parse(conf, rep.criteria)
               filter_row_id: index
            else _trackError(hash_id, rep, criteria)

         operator = FilterParser.parseSearchOperator(criteria, filter_rep)
         @setState {operator: operator}

         FilterSource.updateOperator(operator)
         FilterSource.set(filters)
         if _.any(filter_rep, (rep) -> rep.id == "map")
            EverTrue.UrlManager.navigate("/contact/map/#{hash_id}", true)

      respondToAdd: ->
         filters = @getState("temp_filters")
         max_row_id = _.max filters, (filter) ->
            _.toNumber(filter.filter_row_id)

         filters.push {filter_row_id: _.toNumber(max_row_id.filter_row_id) + 1}
         @setState {temp_filters: filters}

      respondToUpdate: (filter_row_id, filter_obj) ->
         filters = _.clone(@getState("temp_filters"))
         filter = _.findWhere filters, {filter_row_id: filter_row_id}

         if filter then _.extend filter, {}, filter_obj
         else filters.push _.extend({filter_row_id: filter_row_id}, filter_obj)
         if !@canSelectOperator(filters) && @getState("temp_operator") == "or"
            FilterSource.updateOperator("and")

         filters = if _.isEmpty(filters) then _emptyFilters else filters
         @setState {temp_filters: filters}

      respondToRemove: (filter_row_id) ->
         temp_filters = _.reject @getState("temp_filters"), (filter) ->
            _.toNumber(filter.filter_row_id) == _.toNumber(filter_row_id)

         @setState
            temp_filters: if _.isEmpty(temp_filters) then _emptyFilters() else temp_filters

      respondToUpdateMap: (map_object) ->
         map_config = FilterConfigStore.getConfig()?.map
         filters = _.map @getState("temp_filters"), (filter) -> $.extend true, {}, filter
         filter = _.findWhere filters, {filter_id: map_config.filter_id}

         if filter
            filter.value = map_object
         else
            filters = _.filter filters, (filter) -> !!filter.filter_id
            max_row = _.max filters, (filter) -> _.toNumber(filter.filter_row_id)
            filters.push
               filter_row_id: _.toNumber(max_row.filter_row_id || 0) + 1
               filter_id: map_config.filter_id
               value: map_object

         FilterSource.set(filters)

      respondToOperator: (key) ->
         @setState {temp_operator: key || "and"}

      respondToCancel: ->
         active = _.map @getState("active_filters"), _.clone
         @setState
            temp_filters: if _.isEmpty(active) then _emptyFilters() else active
            temp_operator: @getState("operator") || "and"

      respondToFetchedSchema: (schema) ->
         # Legacy filters need to update when schema is fetched to apply custom fields
         url_filters = EverTrue.UrlManager.get("filters")
         if !_.isEmpty(url_filters) && !_.isArray(url_filters) && !_.isString(url_filters)
            filters = @parseLegacyFilters(url_filters)
            @setState {temp_filters: filters, active_filters: filters}

      parseLegacyFilters: (filters) ->
         if filters && !_.isArray(filters)
            filters = _.extend {}, filters
            custom_fields = FilterConfigStore.getCustomFields()
            _.each filters, (filter, key) ->
               if custom_fields["#{key}_list.#{key}"]
                  filters = _.omit(filters, key)
                  filters["custom:#{key}"] = filter

            filter_model = new Filters(filters)
            filter_model.parseLegacy(FilterConfigStore.getConfig())
         else filters

      getMapFilterQuery: ->
         if @getState("operator") == "or"
            map_query = Query(_cluster_query(MapStore.getBoundingBox())).toJSON()?.must

            _query = _getMergedQuery(@getActiveFilters(), @getState("operator"))

            query = _query?.toOrJSON?() || {must: []}
            query.must = (query.must || []).concat(map_query)
            query
         else
            base_query = _cluster_query(MapStore.getBoundingBox())
            @getActiveFiltersQuery(base_query)

      api:
         getOperator: ->
            @getState("temp_operator")

         getActiveOperator: ->
            @getState("operator")

         getCurrentFilters: ->
            _.sortBy @getState("temp_filters"), (filter) ->
               if filter.filter_id == "0.0" then 100 else filter.filter_row_id

         getCurrentFilterFacets: ->
            _.compact _.flatten _.map @getCurrentFilters(), (filter) ->
               conf = FilterConfigStore.getConfigById(filter.filter_id)
               unless conf?.component_type == "date_range"
                  conf?.facets

         getActiveFilters: ->
            @getState("active_filters")

         getActiveFilterKeys: ->
            config = FilterConfigStore.getConfig()
            _.map @getState("active_filters"), (filter) ->
               _.findKey config, (conf) -> conf.filter_id == filter.filter_id

         getActiveFilterByKey: (key) ->
            @getActiveFilterObjectByKey(key)?.value

         getActiveFilterObjectByKey: (key) ->
            config = FilterConfigStore.getConfigByKey(key)
            _.findWhere @getState("active_filters"), {filter_id: config?.filter_id}

         getActiveFiltersQuery: (base_query) ->
            fullQuery = _getMergedQuery(@getActiveFilters(), @getState("operator"), base_query)
            map_value = @getActiveFilterByKey("map")
            map_config = FilterConfigStore.getConfigByKey("map")

            if @getState("operator") == "or"
               query = fullQuery.toOrJSON()
               if map_value
                  map_query = map_config?.query_fn(map_value)?.toJSON()?.must
                  if map_query
                     query.must ?= []
                     query.must = query.must.concat map_query
                  query
               else query
            else
               fullQuery.toJSON()

         getActiveFiltersQueryObject: ->
            _getMergedQuery(@getActiveFilters(), @getState("operator"))

         getActiveFiltersQueryWithoutMap: (base_query) ->
            filters_no_map = _.without @getActiveFilters(), @getActiveFilterObjectByKey("map")
            _getMergedQuery(filters_no_map, @getState("operator"), base_query)?.toJSON()

         getEachFilterQuery: ->
            _.compact _.map @getActiveFilters(), (filter) ->
               if conf = FilterConfigStore.getConfigById(filter.filter_id)
                  {id: conf?.key, version: 1, criteria: conf?.query_fn(filter.value)?.toJSON()}

         getLoading: ->
            @getState("loading")

         hasUnappliedFilters: ->
            # Filter out empty / unselected temp filters
            current = _.filter(@getCurrentFilters(), "filter_id")
            _.deepArrayCompare(current, @getActiveFilters(), {exclude: "filter_row_id"}) ||
                  @getOperator() != @getActiveOperator()

         hasSelectedFilter: ->
            _.some @getState("temp_filters"), (filter) ->
               !!filter.filter_id

         canSaveNotifications: (filters) ->
            !_getMergedQuery(filters, @getState("operator")).hasChildParam()

         canSelectOperator: (filters) ->
            filters ?= @getCurrentFilters()
            hasNewResults = _.findWhere(filters, {filter_id: "0.02"})
            hasEvents = _.findWhere(filters, filter_id: "2.90")
            hasGiftTransaction = _.findWhere(filters, filter_id: "3.90")
            hasCategorizedGiving = _.findWhere(filters, filter_id: "3.91")
            hasGlobalSearch = _.findWhere(filters, {filter_id: "0.015"})
            hasMapFilter = _.findWhere(filters, {filter_id: "0.0"})
            fullQuery = _getMergedQuery(filters, @getState("temp_operator"))
            keys = fullQuery.getAllProperties()

            # must_not + must queries cannot use "OR" because of the way the search DSL is formatted
            # allowing 'has_child' and 'or' would effect the way filters are built for OR interactions search
            if _.any [
               keys.length != _.uniq(keys).length
               hasEvents
               hasGiftTransaction
               hasCategorizedGiving
               hasNewResults
               hasGlobalSearch &&
               (hasMapFilter && _.size(filters) == 2)
               fullQuery.hasParam("must_not")
               fullQuery.hasChildParam()
            ]
               return false
            else
               return true
