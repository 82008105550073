import { ListboxSelect } from "@evertrue/et-components";
import { useState, useEffect } from "react";
import ActivityTable from "./activity/activity-table";
import CardAnalytics from "components/containers/card-analytics";
import {
  uniqueContactJourneyCountByDate,
  getCompletedAndOverdueTasksByDate,
} from "apps/reporting/utils/api-requests/reporting";
import ReportingDonutChart from "./reporting-donut-chart";
import CustomDateRangePicker from "./custom-date-range-picker";
import ReportingChartLegend from "./reporting-chart-legend";
import { useIdentity } from "base/identity-resolver";
import PropTypes from "prop-types";
import { isPastDate, daysFromNow } from "apps/tasks/utils/utils";
import { formatDateFromTimestamp, getWeekBoundaries } from "apps/journeys/utils/helpers/dates";

const ReportingDashboard = ({ tab }) => {
  const selectOptions = [
    { label: "Today", value: "today" },
    { label: "This week", value: "weekly" },
    { label: "Custom date range", value: "custom" },
  ];

  const [selectedOption, setSelectedOption] = useState({ label: "Today", value: "today" });
  const [startDate, setStartDate] = useState(new Date().valueOf());
  const [endDate, setEndDate] = useState(new Date().valueOf());
  const [constituentsCount, setConstituentsCount] = useState(0);
  const [completedTasks, setCompletedTasks] = useState([]);
  const [skippedTasks, setSkippedTasks] = useState([]);
  const [overdueTasks, setOverdueTasks] = useState([]);
  const [activityData, setActivityData] = useState({ lastColumn: {}, tableData: [] });
  const { user } = useIdentity();

  const analytics = [
    {
      label: "New constituents added to a cadence",
      value: constituentsCount,
    },
  ];

  /**
   * Logic used for the date picker, assuring the start date is before or equal to end date */
  const validateAndSetDate = (date, dateType) => {
    let formattedSelectedDate = new Date(date).setHours(0, 0, 0, 0).valueOf();
    let currentDate = new Date().setHours(0, 0, 0, 0).valueOf();
    if (dateType === "startDate") {
      let isValidStartDate = endDate
        ? formattedSelectedDate <= new Date(endDate)
        : formattedSelectedDate <= currentDate;
      if (isValidStartDate) {
        setStartDate(date);
      }
    } else {
      let isValidEndDate =
        formattedSelectedDate >= new Date(startDate).setHours(0, 0, 0, 0) && formattedSelectedDate <= currentDate;
      if (isValidEndDate) {
        setEndDate(date);
      }
    }
  };

  useEffect(() => {
    if (selectedOption.value === "weekly") {
      const { monday, sunday } = getWeekBoundaries();
      setStartDate(monday);
      setEndDate(sunday);
    } else if (selectedOption.value === "today") {
      setStartDate(new Date().setHours(0, 0, 0, 0).valueOf());
      setEndDate(new Date().setHours(23, 59, 59, 999).valueOf());
    } else if (selectedOption.value === "custom") {
      setStartDate(null);
      setEndDate(null);
    }
  }, [selectedOption]);

  useEffect(() => {
    const getAndSetConstituentsCount = async () => {
      const count = await uniqueContactJourneyCountByDate(
        formatDateForQuery(startDate),
        formatDateForQuery(endDate),
        user.id,
        constituentsCount
      );
      setConstituentsCount(count);
    };

    getAndSetConstituentsCount();
  }, [startDate, endDate, user.id, constituentsCount]);

  const formatDateForQuery = (date) => {
    const toDate = date instanceof Date ? date : new Date(date);
    return toDate.valueOf();
  };

  useEffect(() => {
    const getAndSetTasks = async () => {
      if (startDate != null && endDate != null) {
        const data = await getCompletedAndOverdueTasksByDate(
          formatDateForQuery(startDate),
          formatDateForQuery(endDate),
          user.id
        );
        setCompletedTasks(data.filter((task) => task.status === "COMPLETED" && task.owner_user_id === user.id));
        setSkippedTasks(
          data.filter(
            ({ status, owner_user_id }) => status && status.startsWith("SKIPPED") && owner_user_id === user.id
          )
        );
        setOverdueTasks(
          data
            .filter(
              ({ status, due_date, owner_user_id }) =>
                status === "IN_PROGRESS" && isPastDate(Date.parse(due_date) / 1000) && owner_user_id === user.id
            )
            .sort((a, b) => Date.parse(b.due_date) - Date.parse(a.due_date))
        );
      }
    };
    getAndSetTasks();
  }, [startDate, endDate, user.id]);

  useEffect(() => {
    const getAndSetData = async () => {
      setActivityData(() => {
        switch (tab) {
          case "completed":
            return {
              lastColumn: {
                key: 4,
                header: "Completed On",
                accessor: (data) => (data.completed_at ? formatDateFromTimestamp(data.completed_at) : ""),
              },
              tableData: completedTasks,
            };

          case "skipped":
            return {
              lastColumn: {
                key: 4,
                header: "Skipped On",
                accessor: (data) => (data.updated_at ? formatDateFromTimestamp(data.updated_at) : ""),
              },
              tableData: skippedTasks,
            };

          case "overdue":
            return {
              lastColumn: {
                key: 4,
                header: "Went Overdue By",
                accessor: (data) => {
                  if (data.due_date) {
                    const due = daysFromNow(Date.parse(data.due_date) / 1000);
                    return due.msg.replace(/[{()}]/g, "") || "-";
                  } else return "-";
                },
              },
              tableData: overdueTasks,
            };

          default:
            return { lastColumn: {}, tableData: [] };
        }
      });
    };
    getAndSetData();
  }, [completedTasks, skippedTasks, overdueTasks, tab]);

  return (
    <div style={{ marginLeft: "10px" }}>
      <div className="reporting--date-picker-wrapper">
        <ListboxSelect
          size="medium"
          label="Reporting"
          options={selectOptions}
          onChange={(val) => setSelectedOption(val)}
          value={selectedOption}
        />

        {selectedOption.value === "custom" && (
          <CustomDateRangePicker
            startdate={new Date(startDate).valueOf()}
            enddate={new Date(endDate).valueOf()}
            onStartClick={(date) => {
              validateAndSetDate(date, "startDate");
            }}
            onEndClick={(date) => {
              validateAndSetDate(date, "endDate");
            }}
          />
        )}
      </div>

      <div className="reporting--activity-data-wrapper">
        <div className="reporting--donut-chart-wrapper">
          <ReportingDonutChart
            completedTasks={completedTasks.length}
            skippedTasks={skippedTasks.length}
            overdueTasks={overdueTasks.length}
          />
          <ReportingChartLegend
            completedTasks={completedTasks.length}
            skippedTasks={skippedTasks.length}
            overdueTasks={overdueTasks.length}
          />
        </div>
        <CardAnalytics data={analytics} />
      </div>
      <ActivityTable tab={tab} data={activityData} />
    </div>
  );
};

ReportingDashboard.propTypes = {
  tab: PropTypes.string,
};

export default ReportingDashboard;
