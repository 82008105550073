module.exports = do ->
   _ = require("underscore").default
   {createComponent} = require("@evertrue/et-flux")
   React = require("react")
   {div} = ReactLibs.DOMFactories
   {object, func} = ReactLibs.PropTypes
   {createFactory} = require("base/new-utils")
   AdvancedCombobox = createFactory(require("@evertrue/et-components").AdvancedCombobox)

   ADV_PARAMS = ["must", "must_not"]

   OPTIONS = [
      label: "Constituents who engaged positively with..."
      items: [
         value: "upcoming_interest"
         label: "Future events"
      ,
         value: "one_month_interest"
         label: "Events from the last month"
      ,
         value: "one_year_interest"
         label: "Events from the last year"
      ,
         value: "any"
         label: "Any event"
      ]
   ,
      label: "Advanced Events Filters"
      items: [
         value: "must"
         label: "Constituents who engaged with..."
      ,
         value: "must_not"
         label: "Constituents who did not engage with..."
      ]
   ]

   FLAT_OPTIONS = _.flatten _.pluck(OPTIONS, "items")

   createComponent "EventEngagementParamSelector",
      propTypes:
         input: object
         state: object
         onChange: func

      handleChange: (new_param) ->
         # if we change between adv params, don't clear other values,
         # but for simple params wipe it

         value = @props.input.value
         new_value =
            if value in ADV_PARAMS and new_param in ADV_PARAMS
               _.pick(_.extend({}, value, {param: new_param}), _.notEmpty)
            else {param: new_param}

         @props.onChange(null, new_value)

      render: ->
         current_param = @props.input.value?.param
         value = _.findWhere(FLAT_OPTIONS, value: current_param)
         div style: {width: "375px"},
            AdvancedCombobox
               grouped: true
               placeholder: "Select Events Filter..."
               options: OPTIONS
               value: value
               onChange: (val) =>
                  @handleChange(val.value)
