import { useIdentity } from "base/identity-resolver";
import { useEffect, useState } from "react";
import JourneysTable from "../components/index/table/journeys-table";
import JourneysBanner from "../components/top-nav/journeys-banner";
import JourneysTableFilters from "../components/index/journeys-table-filters/journeys-table-filters";
import { fetchJourneysForTable } from "../utils/api-requests/journey-index";
import { deleteFavoriteJourney, createFavoriteJourney } from "../utils/api-requests/journey-index";
import JourneyPauseContainer from "../components/top-nav/journeys-pause-container";
import { createJourney } from "../utils/api-requests/modal-actions";
import EverTrue from "app";
import JourneySideBarConntainer from "../components/top-nav/journey-side-bar-container";

const today = new Date();

const TABS = [
  { id: 1, label: "Your Cadences" },
  { id: 2, label: "Your Favorites" },
  { id: 3, label: "All Cadences" },
];

const IN_PROGRESS_STATUSES = ["IN_PROGRESS"];

const JourneyIndexController = () => {
  const { user } = useIdentity();
  const [journeys, setJourneys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDueDate, setSelectedDueDate] = useState(today.getTime());
  const [selectedTab, setSelectedTab] = useState(TABS[0]);
  const [onlyJourneysWithStepsDue, setOnlyJourneysWithStepsDue] = useState(false);
  const [viewSideBar, setViewSideBar] = useState(false);
  const [activeSideBarConstituentsList, setActiveSideBarConstituentsList] = useState([]);
  const [selectedJourney, setSelectedJourney] = useState({});

  useEffect(() => {
    const getAndSetJourneys = async () => {
      try {
        setLoading(true);
        const journeysListResponse = await fetchJourneysForTable(
          selectedTab.id,
          selectedDueDate,
          user.id,
          IN_PROGRESS_STATUSES
        );
        if (onlyJourneysWithStepsDue) {
          const filtered = journeysListResponse.filter(({ tasks_due_counts = {} }) => tasks_due_counts.total > 0);
          setJourneys(filtered);
        } else {
          setJourneys(journeysListResponse);
        }
      } finally {
        setLoading(false);
      }
    };
    getAndSetJourneys();
  }, [user.id, selectedDueDate, selectedTab, onlyJourneysWithStepsDue]);

  const updateTableState = (action = "", journey = {}) => {
    const journeysCopy = [...journeys];
    const { id: journeyId } = journey;
    const indexToUpdate = journeys.findIndex((j) => j.id === journeyId);

    switch (action) {
      case "update":
        journeysCopy[indexToUpdate] = journey;
        break;
      case "remove":
        journeysCopy.splice(indexToUpdate, 1);
        break;
      default:
        break;
    }
    setJourneys(journeysCopy);
  };

  const toggleFavorite = async (journey = {}) => {
    const { id: journeyId, favorite } = journey;
    const { id: favoriteId = null } = favorite || {};
    // if the journey has a favorite already
    if (favoriteId) {
      // remove it
      await deleteFavoriteJourney(favoriteId);
      journey.favorite = null;
      // if we are only showing favorites
      if (selectedTab.id === 2) {
        // remove the item from the table
        updateTableState("remove", journey);
      } else {
        // else just grey out the star
        updateTableState("update", journey);
      }
    } else {
      // create a favorite
      const newJourneyFavorite = await createFavoriteJourney(journeyId);
      journey.favorite = newJourneyFavorite;
      updateTableState("update", journey);
    }
  };

  const handleCreateJourney = async (payload) => {
    const journey = await createJourney(payload, journeys.length + 1);
    EverTrue.Navigator(`/cadences/${journey.id}`, true);
  };

  const handleOpenSideBar = (journey) => {
    const { contact_journeys } = journey;
    if (!!contact_journeys.length) {
      setActiveSideBarConstituentsList(
        contact_journeys.reduce((contactNames, contactObj) => {
          const { contact } = contactObj;
          if (!contactNames.includes(contact.contact_attributes.name_full)) {
            return [...contactNames, contact.contact_attributes.name_full];
          }
          return contactNames;
        }, [])
      );
    }
    setSelectedJourney(journey);
    setViewSideBar(true);
  };

  const handleCloseSideBar = () => {
    setActiveSideBarConstituentsList([]);
    setSelectedJourney({});
    setViewSideBar(false);
  };

  const handleTab = (tabId) => {
    const tab = TABS.find((t) => t.id === tabId);
    if (tab) setSelectedTab(tab);
  };

  return (
    <div>
      <JourneysBanner onDetailPage={false} handleCreateJourney={handleCreateJourney} />
      <JourneysTableFilters
        tabs={TABS}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        selectedDueDate={selectedDueDate}
        setSelectedDueDate={setSelectedDueDate}
        onlyJourneysWithStepsDue={onlyJourneysWithStepsDue}
        setOnlyJourneysWithStepsDue={setOnlyJourneysWithStepsDue}
      />
      <JourneyPauseContainer />
      <JourneysTable
        journeys={journeys}
        loading={loading}
        toggleFavorite={toggleFavorite}
        handleOpenSideBar={handleOpenSideBar}
        selectedTab={selectedTab}
        onlyJourneysWithStepsDue={onlyJourneysWithStepsDue}
        handleTab={handleTab}
      />
      <JourneySideBarConntainer
        viewSideBar={viewSideBar}
        activeSideBarConstituentsList={activeSideBarConstituentsList}
        handleCloseSideBar={handleCloseSideBar}
        selectedJourney={selectedJourney}
      />
    </div>
  );
};

export default JourneyIndexController;
