module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   AuthApi = require("entities/auth/auth-api")
   PaginatedCollectionMixin = require("mixins/pagination-mixin")
   SearchQueryCollectionMixin = require("mixins/search-query-mixin")
   SortedCollectionMixin = require("mixins/sorted-mixin")
   Query = require("entities/search/query-parts/query")
   Parameter = require("entities/search/query-parts/parameter-query")
   Property = require("entities/search/query-parts/property-query")

   class SocialContent extends AuthApi.Collection
      @include [PaginatedCollectionMixin, SearchQueryCollectionMixin, SortedCollectionMixin]
      urlRoot: "search/v2/social_content/search"
      limit: 5

      initialize: (social_type) ->
         if social_type
            @setNetworkType(social_type)

      parse: (data) ->
         @boundaries(data)
         _.map data.items, (post) ->
            post.id = post.remote_id
            post

      fetch: (options={}) ->
         @sortProp = "created_at"
         @sortReverse = true

         opts = _.clone(options)
         opts.params = $.extend true, {}, @paginate(options.params || {})
         opts.data = @doSort(opts.data)
         opts = @queryOptions(opts)
         super opts

      config:
         fb_pages:
            query_fn: (value) ->
               return if !value || _.isEmpty(value)
               ids = _.map value, (option) -> option.value
               Query [
                  Parameter "must", [
                     Property "page_remote_id", ids, {type: "contains"}
                  ]
               ]

         contents:
            query_fn: (value) ->
               return if !value || _.isEmpty(value)
               Query [
                  Parameter "must", _.map(value.split(" "), (val) ->
                     Property "contents", val, {type: "equals"}
                  )
               ]

         date:
            query_fn: (value) ->
               return if !value || _.isEmpty(value)
               date_object = {gte: value.date_from, lte: value.date_to}
               Query [
                  Parameter "must", [
                     Property "created_at", date_object, {type: "object"}
                  ]
               ]
