import PropTypes from "prop-types";
import { Link } from "@evertrue/et-components";
import moment from "moment";
import EnrichmentConfidenceScore from "./enrichment-confidence-score";
import CareerEnrichmentItem from "./career-enrichment-item";

const CareerEnrichmentCard = ({
    data: {
      start_month,
      start_year,
      end_month,
      end_year,
      company_name,
      industry,
      title,
      revenue_range,
      size_range,
      category,
      confidence_score,
      source,
    } = {} }) => {
  const formattedStartMonth = moment(`${start_month}`).format("MMM");
  const formattedEndMonth = moment(`${end_month}`).format("MMM");

  const startString = () => {
    if (start_month && start_year) return `${formattedStartMonth} ${start_year}`;
    else if (!start_month && start_year) return start_year;
    else if (start_month && !start_year) return start_month;
    return "";
  };

  const endString = () => {
    if (end_month && end_year) return `${formattedEndMonth} ${end_year}`;
    else if (!end_month && end_year) return end_year;
    else if (end_month && !end_year) return end_month;
    return "";
  };

  const confidence_value = confidence_score ? <EnrichmentConfidenceScore score={confidence_score} source={source} /> : "N/A";

  return (
    <table className="career-template">
      <tbody>
        {company_name && <CareerEnrichmentItem
          label={"Company Name"}
          value={<Link
            className="career-template--name fs-exclude-data"
            title={company_name}
            href={`/company?name=${company_name}`}
            data-refer="person"
          >
            {company_name}
          </Link>}
          />
        }
        {(start_month || start_year) && <CareerEnrichmentItem label={"Start Date"} value={startString()} />}
        {(end_month || end_year) && <CareerEnrichmentItem label={"End Date"} value={endString()} />}
        {title && <CareerEnrichmentItem label={"Role"} value={title} />}
        {industry && <CareerEnrichmentItem label={"Industry"} value={industry} />}
        {revenue_range && <CareerEnrichmentItem label={"Revenue Range"} value={revenue_range} />}
        {size_range && <CareerEnrichmentItem label={"Size Range"} value={size_range} />}
        {category && <CareerEnrichmentItem label={"Category"} value={category} />}
        {<CareerEnrichmentItem
            label={"Confidence Score"}
            value={confidence_value}
          />}
      </tbody>
    </table>
  );
};

CareerEnrichmentCard.propTypes = {
  data: PropTypes.object,
};
export default CareerEnrichmentCard;
