import { PropTypes } from "base/react-libs";
import { Icon, Button } from "@evertrue/et-components";
import { useEffect, useState } from "react";
import { fetchContactInfoById } from "apps/home/utils/api-requests/recommended-actions/use-recommended-actions-requests";
import RecommendedActionRow from "./recommended-action-row";
import EmptyRecommendedSection from "./empty-recommended-section";

const RecommendedActionSection = ({
  title,
  subtitle,
  recommendedActions = [],
  icon,
  emailButtonLabel,
  iconKit = "gt2",
  type = "gift",
}) => {
  const [recommendations, setRecommendations] = useState([]);
  const [showAll, setShowAll] = useState(false);

  const handleViewClick = (recommendation) => {
    const recommendationsCopy = [...recommendations];
    const filtered = recommendationsCopy.filter((rec) => rec.id !== recommendation.id);
    setRecommendations(filtered);
  };

  useEffect(() => {
    const fetchAllContacts = async () => {
      if (!recommendedActions.length) return;

      const actionsWithContacts = await Promise.all(
        recommendedActions.map(async (action) => {
          const contact = await fetchContactInfoById(action.contact_id, false);
          action.contact = contact || {};
          return action;
        })
      );
      //filter out the ones that have been completed
      const filteredRecs = actionsWithContacts.filter((action) => !!action.contact.id);
      // sort recommendations by reverse chronological order
      const sortedRecs = filteredRecs.sort((a, b) => {
        const { contact: { giving: { last_gift_date: dateA } = {} } = {} } = a;
        const { contact: { giving: { last_gift_date: dateB } = {} } = {} } = b;
        if (dateA && dateB) {
          return new Date(dateB) - new Date(dateA);
        } else return 0;
      });
      setRecommendations(sortedRecs);
    };
    fetchAllContacts();
  }, [recommendedActions]);

  return (
    <>
      <div className="homepage-recommendations-section-title">
        <Icon icon={icon} iconKit={iconKit} />
        <h6>{title}</h6>
      </div>
      <div>
        {recommendations && recommendations.length > 0 ? (
          <>
            {recommendations.slice(0, showAll ? recommendations.length : 3).map((recommendation, index) => (
              <RecommendedActionRow
                recommendation={recommendation}
                key={recommendation.id}
                type={type}
                first={index === 0}
                subtitle={subtitle}
                emailButtonLabel={emailButtonLabel}
                handleViewClick={handleViewClick}
              />
            ))}
            {recommendations.length > 3 && (
              <div style={{ paddingBottom: "8px" }}>
                <Button type="simple" onClick={() => setShowAll(!showAll)}>
                  {showAll ? "View Less" : "View All"}
                </Button>
              </div>
            )}
          </>
        ) : (
          <EmptyRecommendedSection subtitle={subtitle} />
        )}
      </div>
    </>
  );
};

RecommendedActionSection.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  recommendedActions: PropTypes.array,
  icon: PropTypes.string,
  emailButtonLabel: PropTypes.string,
  iconKit: PropTypes.string,
  type: PropTypes.string,
};

export default RecommendedActionSection;
