import EverTrue from "app";
import Api from "entities/helpers/api";

export const deleteBookmark = async (list_id, setSubmitting) => {
  try {
    setSubmitting(true);
    await Api.HUB.SEARCHES.delete({
      urlArgs: { id: list_id },
    });
    setSubmitting(false);
    EverTrue.Alert.success("Bookmark Search Deleted Successfully");
    EverTrue.Navigator("constituents");
  } catch {
    setSubmitting(false);
    EverTrue.Alert.error("Problem bookmarking your search, please try again later");
  }
};
