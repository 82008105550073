import { PieChart } from "react-minimal-pie-chart";
import PropTypes from "prop-types";
import { Icon } from "@evertrue/et-components";

const ReportingDonutChart = ({ completedTasks, skippedTasks, overdueTasks }) => {
  const isEmpty = completedTasks === 0 && skippedTasks === 0 && overdueTasks === 0;
  const hasOnlyOneSlice = (
    (completedTasks === 0 && skippedTasks === 0) ||
    (skippedTasks === 0 && overdueTasks === 0) ||
    (completedTasks === 0 && overdueTasks === 0)
  );
  const paddingAngle = hasOnlyOneSlice ? 0 : 1;

  const returnComponent = isEmpty ? (
      <div className="reporting--empty-chart-info">
        <Icon icon="reporting" iconKit="gt2" size={4} className="reporting--empty-chart-icon"/>
        <p>
          You don't have any activity based on your selected date range.
          To see your past activity, update the date range.
        </p>
      </div>
    ) : (
      <PieChart
        data={[
          { title: 'Completed', value: completedTasks, color: '#73C166' },
          { title: 'Skipped', value: skippedTasks, color: '#00A0CC' },
          { title: 'Overdue', value: overdueTasks, color: '#DE1B2C' },
        ]}
        lineWidth={40}
        paddingAngle={paddingAngle}
      />
    );

  return returnComponent;
};

ReportingDonutChart.propTypes = {
  completedTasks: PropTypes.number,
  skippedTasks: PropTypes.number,
  overdueTasks: PropTypes.number,
};

export default ReportingDonutChart;
