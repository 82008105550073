import PropTypes from "prop-types";
import _ from "underscore";
import PoolsSource from "apps/volunteers/sources/pools-source";
import PoolFormModalBody from "apps/volunteers/components/pool-form-modal-body";
import EverTrue from "app";

const EditPoolFormModal = ({ pool: { id: pool_id } = {}, pool, ...rest }) => {
  return (
    <PoolFormModalBody
      {...rest} // forward sneaky props
      pool={pool}
      header="Edit Pool"
      buttonLabel="Save"
      handleSubmit={({ temp_pool, goals, settings }) => {
        if (!_.isEqual(temp_pool, pool)) {
          // fires a toast so don't fire 2
          PoolsSource.save(temp_pool);
        } else {
          EverTrue.Alert.success("Pool updates saved.");
        }
      }}
    />
  );
};

EditPoolFormModal.propTypes = {
  pool: PropTypes.object,
};

export default EditPoolFormModal;
