module.exports = do ->
   _ = require("underscore").default
   {createComponent} = require("@evertrue/et-flux")
   React = require("react")
   {div} = ReactLibs.DOMFactories
   moment = require("moment")
   classNames = require("classnames")
   {createFactory} = require("base/new-utils")
   AdvancedCombobox = createFactory(require("@evertrue/et-components").AdvancedCombobox)
   CalendarDropdown = createFactory(require("components/controls/calendar-dropdown"))

   DATE_STATE_OPTIONS = [
      {label: "Lifetime Engagement", value: "all"}
      {label: "Pick Specific Dates", value: "range"}
   ]

   DATE_FORMAT = "YYYY-MM-DD"
   DATE_SHORTCUTS = [
      {id: "now-1w", format: [1, "week"], label: "in past week"}
      {id: "now-1M", format: [1, "month"], label: "in past month"}
      {id: "now-3M", format: [3, "months"], label: "in past 3 months"}
      {id: "now-1y", format: [1, "year"], label: "in past year"}
   ]

   createComponent "DateSelector",
      propTypes:
         date: ReactLibs.PropTypes.object
         onChange: ReactLibs.PropTypes.func
         className: ReactLibs.PropTypes.string
         default: ReactLibs.PropTypes.string
         calendar_position: ReactLibs.PropTypes.string

      renderDateRangeOptions: ->
         current_date = new Date()
         _.compact _.map DATE_SHORTCUTS, (shortcut) ->
            [number, string] = shortcut.format || []
            id: shortcut.id
            date_from: moment(current_date).subtract(number, string).format(DATE_FORMAT)
            date_to: moment(current_date).format(DATE_FORMAT)
            title: shortcut.label

      render: ->
         date = @props.date
         range_options = @renderDateRangeOptions()
         date_options = _.cloneData DATE_STATE_OPTIONS
         if @props.default
            date_options[0].label = @props.default

         div className: "date-selector",
            div className: classNames(@props.className, "is-active": !!date),
               AdvancedCombobox
                  value: if date then date_options[1] else date_options[0]
                  options: _.map date_options, _.clone
                  onChange: (val) =>
                     if val?.value == "all" then @props.onChange(undefined)
                     else @props.onChange(_.pick(_.last(range_options), "date_from", "date_to"))

            if date
               CalendarDropdown
                  date_from: moment(date.date_from).format(DATE_FORMAT)
                  date_to: moment(date.date_to).format(DATE_FORMAT)
                  date_range: range_options
                  position: @props.calendar_position
                  onChange: (date_from, date_to) =>
                     @props.onChange({date_from: date_from, date_to: date_to})
