import _ from "underscore";
import $ from "jquery";
import { Component } from "react";
import { connect } from "@evertrue/et-flux";
import LoginStore from "apps/login/stores/login-store";
import { Loading } from "@evertrue/et-components";
import Config from "config/env";
import EverTrue from "app";

const mapStateToProps = () => ({
  invite_token: LoginStore.getInviteToken(),
  error: LoginStore.getError(),
});

class Login extends Component {
  static propTypes = {
    invite_token: ReactLibs.PropTypes.string,
    error: ReactLibs.PropTypes.string,
  };

  componentDidMount() {
    const accounts_root = `${Config.getAccountsBase()}evertrue`;
    const { invite_token, error } = this.props;
    const { origin } = window.location;

    if (invite_token) {
      window.location = `${accounts_root}/account/register/${invite_token}`;
    } else {
      let origin_params = EverTrue.store.page_referrer.includes("/help") ? EverTrue.store.page_referrer : ""
      const params = _.compactObject({
        error: error,
        redirect: Config.isDevelopment() || origin_params ? encodeURIComponent(origin + origin_params) : null,
      });

      const params_string = _.notEmpty(params) ? "?" + $.param(params) : "";

      const url = `${accounts_root}/enter-email${params_string}`;

      window.location = url;
    }
  }

  render() {
    return <Loading />;
  }
}

export default connect(
  Login,
  [LoginStore],
  mapStateToProps
);
