import _ from "underscore";
import PropTypes from "prop-types";
import { useContext } from "react";
import { IconButton } from "@evertrue/et-components";
import FilterDropdown from "apps/query-builder/components/filter-picker/filter-dropdown";
import OperatorValue from "apps/query-builder/components/logic-group/operator-value";
import { FiltersDataContext } from "apps/query-builder/contexts/filters-data-context";
import colors from "base/colors";

const propTypes = {
  filter: PropTypes.shape({
    compound_id: PropTypes.string,
    operator: PropTypes.string,
    value: PropTypes.any,
    required: PropTypes.any,
  }),
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
};

const RulesRow = ({ filter, onChange, onRemove }) => {
  const { filters } = useContext(FiltersDataContext);

  const getFilterById = (compound_id) => {
    return _.find(filters, { compound_id: compound_id });
  };

  // used when a new filter is mounted to pre-select an operator
  // If the equals operator is present, then it should always select that first
  const getDefaultOperator = (compound_id) => {
    const selected = getFilterById(compound_id) || {};
    const equalsOp = selected.operators.find((op) => op.label === "equals");
    return equalsOp || _.first(selected.operators);
  };

  const selectedFilter = getFilterById(filter && filter.compound_id) || {};

  return (
    <div className="query-builder--row">
      <FilterDropdown
        isDisabled={filter.required}
        selectedFilter={filter}
        onSelection={({ label, compound_id, value, category, group }) =>
          onChange({
            label,
            compound_id,
            operator: getDefaultOperator(compound_id).operator_query_key,
            value,
            category,
            group,
            ui_component: getDefaultOperator(compound_id).ui_component,
          })
        }
      />

      {filter && (
        <div className="query-builder--operator-value">
          <OperatorValue
            filter={selectedFilter}
            isDisabled={filter.required}
            value={filter}
            onChange={(update) => {
              const newFilter = Object.assign({}, filter, update);
              onChange(newFilter);
            }}
          />
          {!_.isEmpty(filter) && (
            <IconButton
              className="query-builder--filter-remove"
              title="remove filter"
              icon="trash"
              style={{ color: colors.gray500 }}
              size={1}
              onClick={onRemove}
              disabled={filter.required}
            />
          )}
        </div>
      )}
    </div>
  );
};

RulesRow.propTypes = propTypes;
export default RulesRow;
