module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   Facebook = require("entities/external/facebook")

   FacebookMixin =
      formatContentId: (post_id) ->
         "#{EverTrue.store.org.get("id")}-facebook-" + post_id

      availEngagementTypes: (bool_all) ->
         ["like", "comment"]

      getPages: (callback) ->
         page_ids = _.map EverTrue.store?.dna?.get("ET.Profile.FacebookPagesForLikes"), (id) -> id.trim()
         page_data = new Facebook()
         page_data.fetchPages page_ids, ->
            pages = _.filter page_data.toJSON(), (page) -> !page.first_name
            callback?(_.sortBy(pages, "name"))

      filterSocialSearchResults: (posts, type) ->
         if type != "all" and type != null
            posts = _.filter posts, (post) ->
               post.engagement?.type == type
         else
            posts

      filterEngagedPosts: (posts, type) ->
         if type != "all" and type != null
            posts = _.filter posts, (post) ->
               if type == "like"
                  post.like >= 1
               else if type == "comment"
                  post.comment?.length > 0
            posts
         else
            posts
