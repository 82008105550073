import _ from "underscore";
import { createStore } from "@evertrue/et-flux";
import PoolsSource from "apps/volunteers/sources/pools-source";
import OrgSource from "base/org/org-source";
import PaginationUtils from "mixins/pagination-utils";
import SocketSource from "apps/notifications/sources/socket-source";

const DEFAULT_GIVING_CATEGORIES = [
  { label: "Total giving data (default)", value: null },
  { label: "No giving data", value: "EverTrueNoData" },
];

const _getDefaults = () => ({
  pools: {},
  page: 1,
  filters: { name: "" },
  org_pool_total: null,
  pools_search_total: null,
  giving_categories: [],
  loading: false,
  loading_categories: false,
  ids_dont_fetch: new Set(),
  limit: 12,
  bulk_add_to_pool: {},
  ids_loading: [],
});

export default createStore("PoolsStore", {
  getInitialState() {
    return _getDefaults();
  },

  firstListenerDidMount() {
    SocketSource.bindOrg("pool_change");
  },

  lastListenerWillUnmount() {
    // Note lastListenerWillUnmount is buggy but hoping this cleans up properly
    SocketSource.unbindOrg("pool_change");
  },

  registerActions() {
    this.on(PoolsSource.actions.loading, this.respondToLoading);
    this.on(PoolsSource.actions.fetchedPools, this.respondToFetchedPools);
    this.on(PoolsSource.actions.fetchedPoolById, this.respondToFetchedPoolById);

    this.on(PoolsSource.actions.paginate, this.respondToPaginate);
    this.on(PoolsSource.actions.filter, this.respondToFilter);
    this.on(PoolsSource.actions.deleted, this.respondToDelete);

    this.on(PoolsSource.actions.clearPools, () => this.setState({ pools: {}, filters: {} }));
    this.on(PoolsSource.actions.fetchedGivingCategories, this.respondToFetchedGivingCategories);
    this.on(PoolsSource.actions.loadingCategories, this.respondToLoadingCategories);
    this.on(PoolsSource.actions.fetchIfNotCached, pool_id => {
      if (!this.state.pools[pool_id] && !this.state.ids_dont_fetch.has(pool_id)) {
        const updated = new Set(this.state.ids_dont_fetch);
        updated.add(pool_id);
        this.setState({ ids_dont_fetch: updated });
        PoolsSource.fetchPoolById(pool_id, true);
      }
    });

    this.on(PoolsSource.actions.fetchedPoolByIdError, pool_id => {
      const updated = new Set(this.state.ids_dont_fetch);
      updated.add(pool_id);
      this.setState({
        ids_dont_fetch: updated,
        ids_loading: this.state.ids_loading.filter(i => i !== pool_id),
      });
    });
    this.on(PoolsSource.actions.setLimit, limit => {
      this.setState({ limit });
    });

    this.on(PoolsSource.actions.bulkFetchIfNotCached, ids => {
      const new_ids = ids.filter(pool_id => {
        return !this.state.pools[pool_id] && !this.state.ids_dont_fetch.has(pool_id);
      });
      if (new_ids.length) {
        const updated = new Set(this.state.ids_dont_fetch);
        new_ids.forEach(pool_id => updated.add(pool_id));
        this.setState({ ids_dont_fetch: updated });
        PoolsSource.bulkFetchPoolsById(new_ids);
      }
    });

    this.on(PoolsSource.actions.loadingByIds, (ids, bool) => {
      const ids_loading = bool
        ? [...this.state.ids_loading, ...ids]
        : this.state.ids_loading.filter(id => !ids.includes(id));

      this.setState({ ids_loading });
    });

    this.on(PoolsSource.actions.fetchedMorePools, fetchedPools => {
      const pools = { ...this.state.pools, ..._.indexBy(fetchedPools.items, "id") };
      const ids_loading = _.without(this.state.ids_loading, ..._.pluck(pools, "id"));
      this.setState({ pools, ids_loading });
    });

    this.on(OrgSource.actions.newOrg, () => this.setState(_getDefaults()));

    this.on(SocketSource.actions.pool_change, ({ action, id }) => {
      if (action === "deleted") {
        this.respondToDelete();
      } else {
        PoolsSource.fetchPoolById(id);
      }
    });

    this.on(SocketSource.actions.assignment_change, this.respondToDataChange);
    this.on(SocketSource.actions.prospect_change, this.respondToDataChange);
    this.on(SocketSource.actions.solicitor_change, this.respondToDataChange);
    this.on(SocketSource.actions.joint_assignment_change, this.respondToDataChange);
    this.on(SocketSource.actions.bulk_add_prospects_to_pool_from_search_criteria, this.respondToPoolSocket);
    this.on(SocketSource.actions.bulk_add_solicitors_to_pool_from_search_criteria, this.respondToPoolSocket);
  },

  respondToLoading(is_loading) {
    this.setState({ loading: is_loading });
  },

  respondToFetchedPools(fetchedPools, initialFetch) {
    const pools = fetchedPools || {};
    const pool_map = _.indexBy(fetchedPools.items, "id");
    const ids_loading = _.without(this.state.ids_loading, ..._.pluck(pool_map, "id"));

    this.setState({
      pools: pool_map,
      pools_search_total: fetchedPools.total,
      limit: fetchedPools.limit,
      ids_loading,
      ...(initialFetch && { org_pool_total: pools.total }),
    });
  },

  respondToPaginate(page, initialFetch) {
    this.setState({ page });
    this.fetchPoolsWithData(initialFetch);
  },

  respondToFilter(filters) {
    this.setState({ filters, page: 1 });
    this.fetchPoolsWithData();
  },

  respondToDelete() {
    this.fetchPoolsWithData();
  },

  respondToFetchedPoolById(id, pool) {
    const updated = new Set(this.state.ids_dont_fetch);
    updated.delete(id);

    this.setState({
      pools: { ...this.state.pools, [id]: pool },
      ids_dont_fetch: updated,
      ids_loading: this.state.ids_loading.filter(i => i !== id),
    });
  },

  respondToFetchedGivingCategories(categories) {
    const fetched_categories = _.map(categories, category => ({ label: category.value, value: category.value }));
    this.setState({ giving_categories: fetched_categories });
  },

  respondToLoadingCategories(loading) {
    this.setState({ loading_categories: loading });
  },

  respondToDataChange({ pool_id }) {
    // TODO: this runs way too much, websocket initiated fetching needs to be removed/refactored
    const { id, progress } = this.state.bulk_add_to_pool;
    const is_pool = id === pool_id;
    // if bulk job is running prevent this from fetching like crazy
    if (is_pool && _.isNumber(progress) && progress !== 1) return;

    if (this.hasListeners()) {
      _.wait(1000, () => PoolsSource.fetchPoolById(pool_id));
    }
  },

  respondToPoolSocket(response = {}) {
    this.setState({
      bulk_add_to_pool: {
        ...this.state.bulk_add_to_pool,
        progress: response.progress,
        id: response.pool_id,
      },
    });
  },

  fetchPoolsWithData(initialFetch) {
    const { limit, filters, org_pool_total, pools, page } = this.state;
    const data = {
      ...pools,
      page: this.getState("page"),
      limit,
      total: org_pool_total,
    };

    const without_total_params = {
      filters,
      offset: (page - 1) * limit,
      limit,
    };
    // PaginationUtils.getParams assumes theres a total, so if there isn't the use different calculation
    const params = org_pool_total
      ? _.extend(this.getState("filters"), PaginationUtils.getParams(data))
      : without_total_params;

    PoolsSource.fetchPools(params, initialFetch);
  },

  api: {
    getLoading() {
      return this.getState("loading");
    },

    getPoolsAsArray() {
      return _.toArray(this.state.pools);
    },

    getPage() {
      return this.getState("page");
    },

    getFilters() {
      return this.getState("filters");
    },

    getPoolById(id) {
      return this.state.pools[id];
    },

    getGivingCategories() {
      return _.union(DEFAULT_GIVING_CATEGORIES, this.getState("giving_categories"));
    },

    getLoadingCategories() {
      return this.getState("loading_categories");
    },

    getPoolsByIds() {
      return this.state.pools;
    },

    getOrgPoolTotal() {
      return this.state.org_pool_total;
    },
    getLimit() {
      return this.state.limit;
    },
    getPoolsSearchTotal() {
      return this.state.pools_search_total;
    },
    getIdsLoading() {
      return this.state.ids_loading;
    },
  },
});
