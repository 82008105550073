module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   OperatorQuery = require("entities/search/query-parts/operator-query")

   PropertyQuery = (prop, value, options={}) ->
      property_object = {}
      isNestedType = options.is_nested_type || false
      type = options.type

      property = prop
      properties = if _.isArray(prop) then prop else [prop]

      {
         value: -> value
         parent: -> options.parent

         isNestedType: ->
            @toJSON()
            options.is_nested_type || isNestedType

         keys: (withoutParent) ->
            return properties if withoutParent || !options.parent
            _.map properties, (prop) -> "#{options.parent}.#{prop}"

         toJSON: (opts={}) ->
            _.each properties, (p) ->
               property = if options.parent && !opts.exclude_parent then "#{options.parent}.#{p}" else p
               operator = OperatorQuery(property, value, type)
               $.extend true, property_object, operator.toJSON()
               isNestedType = operator.isNestedType()
            property_object

         toJSONwithParent: ->
            object = @toJSON({exclude_parent: true})
            parent_operator = OperatorQuery(options.parent, object, options.parent_type)
            parent_operator.toJSON()

         orProperties: (properties) ->
            more_properties = []
            _.each properties, (prop) ->
               more_properties.push prop.properties
               $.extend true, property_object, prop.toJSON()
            properties = _.flatten([properties, more_properties])
            return @
     }

   return PropertyQuery

