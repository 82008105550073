module.exports = do ->
   _ = require("underscore").default
   config = require("config/env")
   EverTrue = require("app")
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")
   Decorator = require("clientDecorator")
   ErrorLogger = require("entities/helpers/error-log-helper")

   _isStringOrNum = (id) ->
      _.isString(id) || _.isNumber(id)


   createSource "ProfileSource",
      actions:
         fetchedProfile: (profile) ->
            @require _.isObject(profile), "profile data should be an object"
         fetchedLIDS: (id, profile) ->
            @require _isStringOrNum(id), "id should be a string or number"
            @require _.isObject(profile), "LIDS profile data should be an object"
         errorFetchingProfile: (id) ->
            @require _isStringOrNum(id), "id should be a string or a number"
         errorFetchingLIDS: (id) ->
            @require _isStringOrNum(id), "id should be a string or a number"
         loadingLIDS: (is_loading) ->
            @require _.isBoolean(is_loading), "is_loading should be a boolean"
         loadingProfile: (is_loading) ->
            @require _.isBoolean(is_loading), "is_loading should be a boolean"
         selectedSection: (key) ->
            @require _.isString(key) || _.isUndefined(key), "selection key should be string or undefined"
         fetchedCommunitySessions: (id, session_count) ->
            @require _isStringOrNum(id), "id should be a string or a number"
            @require _.isNumber(session_count) || _.isUndefined(session_count), "session_count should be a number"
         loadingOnlineGiving: (id) ->
            @require _isStringOrNum(id), "id should be a string or a number"
         fetchedOnlineGiving: (id, events) ->
            @require _isStringOrNum(id), "id should be a string or a number"
            @require _.isArray(events.items), "online gift list should be an array"
         errorFetchingOnlineGiving: (id) ->
            @require _isStringOrNum(id), "id should be a string or a number"
         fetchedLastContactDate: (id, date) ->
            @require _isStringOrNum(id), "id should be a string or a number"
            @require _.isNumber(date), "date should be a timestamp"

      api:
         fetch: (id) ->
            @actions.loadingProfile(true)

            Api.CONTACTS.CONTACT.get
               urlArgs: id: id
               disableAlerts: true
               success: (resp) =>
                  data = Decorator.Contacts.parse(resp)
                  @actions.fetchedProfile(data)
                  @actions.loadingProfile(false)
               error: =>
                  @actions.errorFetchingProfile(id)
                  @actions.loadingProfile(false)

         fetchLIDS: (id) ->
            @actions.loadingLIDS(true)

            Api.LIDS.SOCIAL_PROFILE.get
               urlExtend: "/#{id}"
               disableAlerts: true
               success: (resp) =>
                  @actions.fetchedLIDS(id, resp)
                  @actions.loadingLIDS(false)
               error: (xhr, statusText, error) =>
                  @actions.errorFetchingLIDS(id)
                  @actions.loadingLIDS(false)

         fetchCommunitySessions: (contact_id, user_id) ->
            Api.SEARCH.SESSION.post
               data: _.jsonStringify
                  must: [
                     {application_id: match: config.getApplicationId("community")},
                     {user_id: match: user_id}
                  ]
               success: (resp) =>
                  @actions.fetchedCommunitySessions(contact_id, resp.total)

         fetchGifts: (contact_id) ->
            @actions.loadingOnlineGiving(contact_id)
            Api.GIVE.CONTACT_APPEAL_EVENTS.post
               data: _.jsonStringify
                  must: [{contact_id: match: contact_id}, {type: match: "completed"}]
                  sort: ["enqueued_at": order: "desc"]
               success: (resp) =>
                  @actions.fetchedOnlineGiving(contact_id, resp)
               error: (xhr, statusText, error) =>
                  @actions.errorFetchingOnlineGiving(contact_id, error)

         fetchLastContactDate: (contact_id) ->
            Api.SEARCH.LAST_CONTACT_DATE.post
               data: _.jsonStringify
                  ids: [contact_id]
                  exclude: ["Note", "EverTrue Comment"]
               success: (resp) =>
                  last_contact_date = _.first(resp.items)?.date
                  if last_contact_date
                     @actions.fetchedLastContactDate(contact_id, last_contact_date)

         selectSection: (key) ->
            @actions.selectedSection(key)

         recordProfileView: (contact_id) ->
            if not EverTrue.request "isImpersonating?"
               Api.CONTACTS.RECORD_PROFILE_VIEW.post
                  urlArgs: {id: contact_id}
                  disableAlerts: true
