/* eslint-disable react/jsx-indent, indent */
import { Component } from "react";
import PropTypes from "prop-types";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import DraggableItem from "components/controls/dnd/draggable-item";
import _ from "underscore";

const DNDList = ({
  id,
  list: { items = [], non_draggable_items = [], grouped = false, emptyStateComponent, disabled = false },
  className = "",
} = {}) => {
  const groupedItems = grouped && _.groupBy(items, "group");
  return (
    <Droppable droppableId={id}>
      {(provided, snapshot) => (
        <ul
          ref={provided.innerRef}
          className={`dnd-controller--list-${id}${
            snapshot.isDraggingOver ? " dnd-controller--dragged-over-list" : ""
          } ${className}`}
          {...provided.droppableProps}
        >
          {!items.length && emptyStateComponent}
          {items.length && !grouped
            ? items.map((item, index) => (
                <DraggableItem item={item} key={item.key || item.value} disabled={disabled} index={index} />
              ))
            : _.map(groupedItems, (innerItems = [], key, index) => (
                <div key={key}>
                  <span className="drag-list--header">{key}</span>
                  {innerItems.map((innerItem) => (
                    <DraggableItem
                      item={innerItem}
                      key={innerItem.key || innerItem.value}
                      disabled={disabled}
                      index={index}
                    />
                  ))}
                </div>
              ))}
          {non_draggable_items.length
            ? non_draggable_items.map((item, index) => <div key={item.key}>{item.component}</div>)
            : null}
          {provided.placeholder}
        </ul>
      )}
    </Droppable>
  );
};

DNDList.propTypes = {
  id: PropTypes.string,
  list: PropTypes.object,
  className: PropTypes.string,
};

class DNDController extends Component {
  static propTypes = {
    lists: PropTypes.object,
    onReorder: PropTypes.func,
    className: PropTypes.string,
  };

  static defaultProps = {
    lists: {},
    onReorder: _.noop,
    className: "",
  };

  handleSameListDrag(result, list) {
    const list_id = result.source.droppableId;
    const start_index = result.source.index;
    const end_index = result.destination.index;
    const edited_list = [...list];

    const [removed] = edited_list.splice(start_index, 1);
    edited_list.splice(end_index, 0, removed);
    const lists_map = _.mapObject(this.props.lists, ({ items }) => items);
    // onReorder just passes up an obj like {available: [items]}
    this.props.onReorder({ ...lists_map, [list_id]: edited_list });
  }

  handleSeparateListDrag(result, sourceList, destinationList) {
    const destination_id = result.destination.droppableId;
    const source_id = result.source.droppableId;
    const draggedItem = _.findWhere(sourceList, { value: result.draggableId });
    const dest_items = [
      ...destinationList.slice(0, result.destination.index),
      draggedItem,
      ...destinationList.slice(result.destination.index),
    ];
    const source_items = sourceList.filter((item) => item.value !== result.draggableId);
    const lists_map = _.mapObject(this.props.lists, ({ items }) => items);
    // onReorder just passes up an obj like {available: [items]}
    this.props.onReorder({ ...lists_map, [destination_id]: dest_items, [source_id]: source_items });
  }

  handleDragWithoutLists(result = {}) {
    this.props.onReorder(result.draggableId, result.source.droppableId, result.destination.droppableId);
  }

  onDragEnd = (result) => {
    if (!result.destination) return;

    if (!_.isEmpty(this.props.lists)) {
      const source_list = this.props.lists[result.source.droppableId];
      const destination_list = this.props.lists[result.destination.droppableId];
      if (result.source.droppableId === result.destination.droppableId) {
        if (destination_list.notSortable) return;
        this.handleSameListDrag(result, destination_list.items);
      } else {
        this.handleSeparateListDrag(result, source_list.items, destination_list.items);
      }
    } else {
      this.handleDragWithoutLists(result);
    }
  };

  render() {
    const { lists, className } = this.props;
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <div className={className}>
          {_.isEmpty(lists)
            ? this.props.children // eslint-disable-line
            : _.map(lists, (list, key) => (
                <div className="dnd-controller--drag-list" key={key}>
                  <DNDList id={key} list={list} />
                </div>
              ))}
        </div>
      </DragDropContext>
    );
  }
}

class DNDControllerWithoutList extends Component {
  static propTypes = {
    children: PropTypes.any,
    onReorder: PropTypes.func,
    onDragStart: PropTypes.func,
    className: PropTypes.string,
  };

  static defaultProps = {
    onReorder: _.noop,
    onDragStart: _.noop,
    className: "",
  };

  handleDragWithoutLists = (result = {}) => {
    const { source = {}, destination = {} } = result;
    if (destination && destination.droppableId !== source.droppableId) {
      this.props.onReorder(result.draggableId, source.droppableId, destination.droppableId);
    }
  };

  render() {
    const { className } = this.props;
    return (
      <DragDropContext onDragEnd={this.handleDragWithoutLists} onDragStart={this.props.onDragStart}>
        <div className={className}>{this.props.children}</div>
      </DragDropContext>
    );
  }
}

export { DNDController, DNDControllerWithoutList, DNDList };
