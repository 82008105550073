module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   Api = require("entities/helpers/api")

   return {
      formatLink: (url, options = {}) ->
         {impersonate_user_id, oid, session} = Api._getState()
         {baseRoute, appKey} = Api._getConfig()
         {urlRootOverride} = options

         full_url = (urlRootOverride || baseRoute) + url

         base_params =
            oid: oid
            app_key: appKey
            auth: session
            auth_provider: "evertrueauthtoken"
            auth_impersonate: impersonate_user_id

         full_params = _.compactObject(_.extend({}, base_params, options.params))

         if _.notEmpty(full_params)
            full_url += "?" + decodeURIComponent $.param(full_params)
         full_url
   }
