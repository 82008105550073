module.exports = do ->
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {div, span} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   Icon = createFactory (require("components/elements/icon").default)


   createComponent "PhoneTemplate",
      propTypes:
         data: ReactLibs.PropTypes.object # TODO: Check for expected format
         hideIcon: ReactLibs.PropTypes.bool

      render: ->
         div className: "phone-template",
            if @props.hideIcon then div className: "phone-template--no-icon"
            else Icon icon: "call", className: "phone-template--icon"

            if !@props.data?.phone
               div className: "phone-template--data is-empty",
                  "Not provided"
            else
               div className: "phone-template--data",
                  div className: "phone-template--label",
                     @props.data?.type || "Other"
                     if @props.data?.primary then " (Primary)"

                  span className: "fs-exclude-data", @props.data?.phone
