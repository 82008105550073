import { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import { Modal, ModalHeaderCustom, ModalBody, Loading, Button } from "@evertrue/et-components";
import AddUser from "apps/search/components/add-user";
import useFetchDynamicListPermissions from "apps/dynamic-lists/hooks/use-fetch-dynamic-list-permissions";
import Api from "entities/helpers/api";
import EverTrue from "app";
import { PERMISSIONS } from "apps/dynamic-lists/utils/dynamic-list-enums";
import EditUserSharingPermissions from "apps/search/components/edit-user-sharing-permissions";

const DynamicListSharingModal = ({ isOpen, onClose, id }) => {
  const { permissions, isLoading } = useFetchDynamicListPermissions(id);
  const [userPermissions, setUserPermissions] = useState([]);

  useEffect(() => {
    if (permissions.user_permissions) {
      setUserPermissions(permissions.user_permissions);
    }
  }, [permissions.user_permissions]);

  const handleAddUser = async (option) => {
    const payload = {
      name: option.label,
      user_id: option.value,
      permission: PERMISSIONS.VIEW,
    };

    try {
      const results = await Api.HUB.SEARCH_LIST_USER_PERMISSIONS.post({
        urlArgs: { search_id: id, user_id: option.value },
        data: JSON.stringify(payload),
      });
      setUserPermissions(results);
    } catch {
      EverTrue.Alert.error("There was a problem adding user.");
    }
  };

  return (
    <Modal isOpen={isOpen} closeModal={onClose} size="small">
      <ModalHeaderCustom>
        <h3>Share Dynamic List</h3>
        <Button onClick={onClose}>Done</Button>
      </ModalHeaderCustom>
      <ModalBody>
        <div className="bookmark-modal">
          {isLoading ? (
            <Loading className="bookmark-modal--loading" />
          ) : (
            <>
              <div className="bookmark-sharing-modal--heading">Visibility &amp; Access</div>
              <AddUser
                searchId={id}
                setUserPermissions={setUserPermissions}
                excludedUserIds={userPermissions.flatMap((permission) => permission.user_id)}
                handleOnChange={handleAddUser}
              />
              <ul>
                {userPermissions
                  .filter((user) => user.permission !== "OWNER")
                  .map((user) => (
                    <EditUserSharingPermissions
                      key={user.user_id}
                      user={user}
                      searchId={id}
                      setUserPermissions={setUserPermissions}
                      referrer="dynamicList"
                    />
                  ))}
              </ul>
            </>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

DynamicListSharingModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default DynamicListSharingModal;
