import { Component } from "react";
import { AdvancedCombobox } from "@evertrue/et-components";

class InviteTableInput extends Component {
  static propTypes = {
    inputData: ReactLibs.PropTypes.string,
    inputConfig: ReactLibs.PropTypes.object,
    rowEmpty: ReactLibs.PropTypes.bool,
    rowError: ReactLibs.PropTypes.object,
    onChange: ReactLibs.PropTypes.func, // args (value, key)
  };

  static defaultProps = {
    inputData: "",
    inputConfig: {},
    rowEmpty: false,
    rowError: {},
    onChange: () => {},
  };

  state = {
    error: "",
  };

  checkIfError = (value) => {
    // If we send it a value, meaning on inputChange, we call the validator on our live input
    // Otherwise, we use this.props.inputData onBlur
    const isError = this.props.inputConfig.validator(this.props.inputData || value);
    if (isError.status === "ERROR" && !this.props.rowEmpty) {
      this.setState({ error: isError.message });
    } else {
      this.setState({ error: "" });
    }
  };

  checkForInputBlockError = () => {
    const { error_code } = this.props.rowError;
    const { platformErrorCode } = this.props.inputConfig;
    if (platformErrorCode && error_code === platformErrorCode) {
      return true;
    }
    return false;
  };

  handleDropdownChange = (value) => {
    this.props.onChange(value.label, this.props.inputConfig.key);
  };

  handleInputChange = (event) => {
    this.checkIfError(event.target.value);
    this.props.onChange(event.target.value, this.props.inputConfig.key);
  };

  renderDropdownOptions(options) {
    return options.map((option) => ({ label: option, key: option }));
  }

  renderInlineError() {
    if ((this.state.error.length > 0 && !this.props.rowEmpty) || this.checkForInputBlockError()) {
      return <span className="inline-error">{this.state.error || this.props.rowError.error}</span>;
    }
    return false;
  }

  render() {
    const { inputConfig, inputData } = this.props;
    const isError = (this.state.error && !this.props.rowEmpty) || this.checkForInputBlockError();
    const dropdownValue = { label: inputData, value: inputData };

    if (inputConfig.type === "dropdown") {
      return (
        <td className={isError ? "error" : ""}>
          <AdvancedCombobox
            className="advanced-combobox-secondary"
            placeholder={inputConfig.placeholder}
            value={dropdownValue}
            searchable={false}
            options={this.renderDropdownOptions(inputConfig.options)}
            onChange={this.handleDropdownChange}
            type={inputConfig.type}
          />
          {this.renderInlineError()}
        </td>
      );
    }

    return (
      <td key={inputConfig.placeholder} className={isError ? "error" : ""}>
        <input
          placeholder={inputConfig.placeholder}
          value={inputData}
          onChange={this.handleInputChange}
          onBlur={this.checkIfError}
          type={inputConfig.type || "text"}
          className={isError ? "error" : ""}
        />
        {this.renderInlineError()}
      </td>
    );
  }
}

export default InviteTableInput;
