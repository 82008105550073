module.exports = do ->
   _ = require("underscore").default
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")

   createSource "ProfileEventsSource",
      actions:
         profileEventEngagementsLoading: true
         profileEventEngagementsSuccess: true
         profileEventEngagementsError: true

      api:
         fetchProfileEventEngagements: (contact_id) ->
            query =
               must: [contact_id: match: contact_id]
               facet: [field: "engagement_action"]
               sort: ["engaged_at": {order: "desc"}]

            @actions.profileEventEngagementsLoading(contact_id)
            Api.EVENTS.ENGAGEMENTS.post
               params: limit: 10
               data: _.jsonStringify(query)
               success: (resp) =>
                  @actions.profileEventEngagementsSuccess(contact_id, resp)
               error: =>
                  @actions.profileEventEngagementsError(contact_id)
