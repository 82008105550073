import { useIdentity } from "base/identity-resolver";
import { useState, useEffect, useCallback } from "react";
import { setTimestampToMidnightAndRemoveMilliseconds } from "apps/journeys/utils/helpers/dates";
import {
  getJourneyTimeoutsForUser,
  createJourneyTimeout,
  updateJourneyTimeout,
  cancelJourneyTimeout,
} from "../utils/api-requests/use-journey-timeouts-requests";

// Custom Hook to handle all Cadence Pausing/Journey Timeout API Requests
const useJourneyTimeoutsApi = () => {
  const { user = {}, org } = useIdentity();
  const [inProgressJourneyTimeout, setInProgressJourneyTimeout] = useState(null);
  const [futureJourneyTimeout, setFutureJourneyTimeout] = useState(null);
  const [businessDaysUntilFutureTimeout, setBusinessDaysUntilFutureTimeout] = useState(0);

  // Function to fetch and set user timeouts
  const getAndSetUserTimeouts = useCallback(async () => {
    if (user.id) {
      const { activeJourneyTimeout = null, scheduledJourneyTimeouts = [] } = await getJourneyTimeoutsForUser(user.id);

      setInProgressJourneyTimeout(activeJourneyTimeout);
      setFutureJourneyTimeout(null);
      if (!activeJourneyTimeout && scheduledJourneyTimeouts.length) {
        const sortedScheduledTimeouts = scheduledJourneyTimeouts.sort((a, b) => a.pause_start - b.pause_start);
        setFutureJourneyTimeout(sortedScheduledTimeouts[0]);
      }
    }
  }, [user.id]);

  // Populate state on initial render
  useEffect(() => {
    getAndSetUserTimeouts();
  }, [getAndSetUserTimeouts]);

  // API Calls
  const handleCreate = async (start, end) => {
    await createJourneyTimeout(buildPayload(start, end));
    await getAndSetUserTimeouts();
  };

  const handleUpdate = async (start, end) => {
    await updateJourneyTimeout(
      buildPayload(start, end),
      inProgressJourneyTimeout ? inProgressJourneyTimeout.id : futureJourneyTimeout.id
    );
    await getAndSetUserTimeouts();
  };

  const handleCancel = async () => {
    // should only be called for FUTURE timeout
    await cancelJourneyTimeout(futureJourneyTimeout.id);
    await getAndSetUserTimeouts();
  };

  const handleResume = async () => {
    const payload = {
      ...inProgressJourneyTimeout,
      pause_end: setTimestampToMidnightAndRemoveMilliseconds(new Date()), // pause_end: midnight today
    };

    await updateJourneyTimeout(payload, inProgressJourneyTimeout.id);
    await getAndSetUserTimeouts();
  };

  // Helper function to build API Payload
  function buildPayload(startDate, endDate) {
    return {
      owner_user_id: user.id,
      oid: org.id,
      pause_start: setTimestampToMidnightAndRemoveMilliseconds(startDate),
      pause_end: setTimestampToMidnightAndRemoveMilliseconds(endDate),
    };
  }

  useEffect(() => {
    if (futureJourneyTimeout) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const pauseStart = new Date(futureJourneyTimeout.pause_start * 1000);
      pauseStart.setHours(0, 0, 0, 0);

      let totalBusinessDays = 0;

      for (let current = new Date(today); current <= pauseStart; current.setDate(current.getDate() + 1)) {
        const dayOfWeek = current.getDay();
        if (dayOfWeek >= 1 && dayOfWeek <= 5) {
          totalBusinessDays++;
        }
      }
      setBusinessDaysUntilFutureTimeout(totalBusinessDays);
    }
  }, [futureJourneyTimeout]);

  return {
    inProgressJourneyTimeout,
    futureJourneyTimeout,
    handleCreate,
    handleUpdate,
    handleCancel,
    handleResume,
    businessDaysUntilFutureTimeout,
  };
};

export default useJourneyTimeoutsApi;
