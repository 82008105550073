module.exports = do ->
   _ = require("underscore").default
   {createStore} = require("@evertrue/et-flux")
   EventDetailSource = require("apps/events/sources/event-detail-source")
   SortUtils = require("mixins/sort-utils")

   LOADING = "LOADING"
   SUCCESS = "SUCCESS"
   ERROR = "ERROR"

   createStore "EventDetailStore",
      getInitialState: ->
         events: {}
         events_status: {}
         contacts: {}
         counts: {}
         loading: false
         page: 1
         constituent_search: ""
         event_id: null
         filters: ["all"]
         contacts_sort: SortUtils.getDefaultSort("name_last")

      registerActions: ->
         @on EventDetailSource.actions.loadingEvent, (event_id) ->
            @setStatus(event_id, LOADING)

         @on EventDetailSource.actions.fetchedEvent, (event_id, event) ->
            @mergeState("events", _.toObject(event_id, event))
            @setStatus(event_id, SUCCESS)

         @on EventDetailSource.actions.eventError, (event_id) ->
            @setStatus(event_id, ERROR)

         @on EventDetailSource.actions.fetchedContacts, (event_id, contacts, query) ->
            _.extend contacts, page: @getState("page")
            @mergeState("contacts", _.toObject(event_id, contacts))
            @setState loading: false, filters_query: query

         @on EventDetailSource.actions.fetchedEngagementCount, @respondToNewCount
         @on EventDetailSource.actions.loading, (is_loading) ->
            @setState loading: is_loading

         @on EventDetailSource.actions.newPage, (page) ->
            @setState page: page
            @fetchContacts()

         @on EventDetailSource.actions.newEvent, (event_id, filters) ->
            old_event_id = @getState("event_id")
            @setState page: 1, event_id: event_id, filters: filters, constituent_search: ""

            if event_id and event_id isnt old_event_id
               EventDetailSource.clearConstituentSearch()
               EventDetailSource.fetchEventById(event_id)
            @fetchContacts()

         @on EventDetailSource.actions.newConstituentQuickSearch, (search) ->
            previous_search = @getState("constituent_search")
            @setState constituent_search: search
            @setState page: 1
            if previous_search != search.trim()
               @fetchContacts()

         @on EventDetailSource.actions.clearConstituentSearch, ->
            @setState constituent_search: ""
            @setState page: 1
            @fetchContacts()

         @on EventDetailSource.actions.newSort, (key, reverse) ->
            contacts_sort = {sortProp: key, sortReverse: reverse}
            @setState contacts_sort: contacts_sort
            @fetchContacts()

      setStatus: (event_id, status) ->
         @mergeState("events_status", _.toObject(event_id, status))

      respondToNewCount: (event_id, filter, count) ->
         current_count = @getState("counts")
         if counts = @getState("counts")[event_id]
            new_count =  _.extend counts, _.toObject(filter, count)
            current_count[event_id] = new_count
            final_count = current_count
         else
            current_count[event_id] =  _.toObject(filter, count)
            final_count = current_count
         @setState counts: final_count

      fetchContacts: ->
         event_id = @getState("event_id")
         filters = @getState("filters")
         page = @getState("page")
         constituent_search = @getState("constituent_search")
         sort_params = @getState("contacts_sort")

         options =
            page: page
            sort_params: sort_params
            quick_search: constituent_search

         EventDetailSource.fetchContactsForEvent(event_id, filters, options)

      api:
         getEventById: (event_id) ->
            @getState("events")[event_id]

         getEventIsLoadingById: (event_id) ->
            @getState("events_status")[event_id] is LOADING

         gethasErrorById: (event_id) ->
            @getState("events_status")[event_id] is ERROR

         getContactsByEventId: (event_id) ->
            contactData = @getState("contacts")[event_id]
            _.extend {}, contactData, @getState("contacts_sort")

         getEngagementCounts: (event_id) ->
            @getState("counts")[event_id]

         getContactsLoading: ->
            @getState("loading")

         getConstituentSearch: ->
            @getState("constituent_search")

         getActiveFilters: ->
            @getState("filters")

         getActiveFiltersQuery: ->
            @getState("filters_query")
