import { PropTypes } from "base/react-libs";

const CompleteTaskToggle = ({ size = "big", task = {}, onClick = () => {}, isClickable = true }) => {
  return (
    <span
      aria-label="task-completion-toggle"
      className={
        task.completed_at
          ? `${isClickable ? "checked-circle--clickable" : "checked-circle"} checked-circle--${size}`
          : `${isClickable ? "check-circle--clickable" : "check-circle"} check-circle--${size}`
      }
      onClick={() => {
        onClick(task);
      }}
    />
  );
};

CompleteTaskToggle.propTypes = {
  size: PropTypes.string,
  task: PropTypes.object,
  onClick: PropTypes.func,
  isClickable: PropTypes.bool,
};

export default CompleteTaskToggle;
