import { Fragment, Component } from "react";
import PropTypes from "prop-types";
import JointContactCard from "apps/volunteers/components/joint-contact-card";
import StageResolver from "apps/assignments/components/stage-resolver";
import PoolAssignmentsSource from "apps/volunteers/sources/pool-assignments-source";
import Loading from "components/elements/loading";
import _ from "underscore";
import { ModalConfirm, Radio } from "@evertrue/et-components";

class UpdateStageModal extends Component {
  static propTypes = {
    assignment: PropTypes.object,
    secondary: PropTypes.object,
    pool: PropTypes.object,
    close: PropTypes.func,
    onSubmit: PropTypes.func,
    type: PropTypes.string,
  };

  static defaultProps = {
    assignment: {},
    secondary: {},
    pool: {},
  };

  state = {
    selected_stage: this.props.assignment.assignment_stage || undefined,
  };

  componentDidUpdate(prevProps) {
    const { assignment_stage } = this.props.assignment;
    if (assignment_stage && prevProps.assignment.assignment_stage !== assignment_stage) {
      this.setState({
        selected_stage: this.props.assignment.assignment_stage || undefined,
      });
    }
  }

  handleSubmit = () => {
    const { assignment_id, compound_id } = this.props.assignment;
    // portfolio performance uses the onSubmit to update stage
    if (this.props.onSubmit) {
      this.props.onSubmit(assignment_id, this.state.selected_stage);
      // this updateState is used for volunteer stage changes
    } else {
      PoolAssignmentsSource.updateStage(assignment_id, compound_id, this.state.selected_stage);
    }
    this.props.close();
  };

  handleChange = (selected_stage) => {
    this.setState({ selected_stage });
  };

  render() {
    const { assignment, secondary, pool } = this.props;
    const is_button_disabled = !this.state.selected_stage || assignment.assignment_stage === this.state.selected_stage;
    return (
      <ModalConfirm
        style={{ minWidth: "400px" }}
        buttonLabel="Save"
        disableSave={is_button_disabled}
        onSubmit={this.handleSubmit}
        header="Update Stage"
        onCancel={this.props.close}
      >
        <Fragment>
          <JointContactCard
            is_joint={!_.isEmpty(secondary)}
            showNameLink={false}
            contact={assignment.contact}
            secondary={secondary}
          />
          <div className="update-stage-modal--body">
            <div>Stages:</div>
            <StageResolver stageGroupId={pool.stage_group_id} type={this.props.type}>
              {({ stagesForGroup, loading, hasStagesFeature }) => {
                // stages are saved to assignmentObject as a string
                if (!hasStagesFeature) return null;
                if (loading) return <Loading />;

                return stagesForGroup.length ? (
                  <fieldset>
                    {stagesForGroup.map((stage) => (
                      <div key={stage.id} className="update-stage-modal--radios">
                        <Radio
                          name="stages"
                          onChange={() => this.handleChange(stage.stage)}
                          checked={stage.stage === this.state.selected_stage}
                          label={stage.stage}
                        />
                      </div>
                    ))}
                  </fieldset>
                ) : (
                  <div>Please contact your EverTrue owner to create stages.</div>
                );
              }}
            </StageResolver>
          </div>
        </Fragment>
      </ModalConfirm>
    );
  }
}

export default UpdateStageModal;
