import _ from "underscore";
import moment from "moment";
import { ProposalFormField } from "./proposal-form-utils";
import { AdvancedCombobox, ContactCard, TextInput, Button, Icon } from "@evertrue/et-components";
import ContactTagList from "apps/contact/components/contact-tag-list";
import CalendarPickerDropdown from "components/controls/calendars/calendar-picker-dropdown";
import PropTypes from "prop-types";
import { formatDateToUnixWithCurrentTime } from "base/new-utils";
import CharacterCounter from "apps/interactions/components/character-counter";
import { PROPOSAL_CHARACTER_LIMIT } from "apps/proposals/utils";

export const ProposalFormDetails = ({
  onFormChange,
  proposal,
  additionalContactLimit,
  proposalTypes,
  standardFieldsConfig,
}) => {
  const { primary_contact } = proposal;
  const additionalContactText = additionalContactLimit > 0 ? ` (max ${additionalContactLimit})` : "";
  const { description, title, created_date, proposal_type } = standardFieldsConfig;

  return (
    <>
      <ContactCard
        contact={primary_contact}
        showNameLink={false}
        showClassYear={true}
        className="proposal-form--contact-card"
      />
      <div className="proposal-form--row">
        {additionalContactLimit > 0 ? (
          <ProposalFormField label={`Additional Prospects${additionalContactText}`}>
            <ContactTagList
              placeholder="Search for a prospect to add..."
              contactsFilterFunction={(contactFilter = {}) => contactFilter.id !== primary_contact.id}
              selected={proposal.contacts}
              onSelect={(selectedContacts) => {
                // if more than the limit of additional contacts has been selected, dont save them
                if (additionalContactLimit && selectedContacts.length > additionalContactLimit) {
                  return;
                }
                if (selectedContacts) {
                  onFormChange("contacts", selectedContacts);
                }
              }}
              focusOnMount={false}
            />
          </ProposalFormField>
        ) : null}
      </div>
      <div className="proposal-form--row proposal-text-container">
        <ProposalFormField label="Title" required={true}>
          <TextInput
            aria-label="Title"
            required={true}
            value={proposal.title}
            placeholder={"Add title..."}
            onChange={(title) => {
              onFormChange("title", title);
            }}
          />
        </ProposalFormField>
      </div>
      <div className="proposal-character-counter">
        <CharacterCounter limit={title.max_length} count={proposal.title.length} />
      </div>
      <div
        className="proposal-form--row proposal-text-container"
        style={{ flexDirection: "column", alignItems: "flex-start" }}
      >
        {description.required && (
          <label className="form-field--label-wrap" style={{ marginBottom: "2px" }}>
            <span className="form-field--required">Required</span>
          </label>
        )}
        <div className="proposal-form--textarea">
          <textarea
            rows="4"
            aria-label="description"
            value={proposal.description}
            placeholder={"Add description..."}
            onChange={(description) => {
              onFormChange("description", description.target.value);
            }}
          />
        </div>
      </div>
      <div className="proposal-character-counter">
        {description.max_length < PROPOSAL_CHARACTER_LIMIT.DESCRIPTION && (
          <CharacterCounter limit={description.max_length} count={proposal.description.length} />
        )}
      </div>
      <div className="proposal-form--row">
        <ProposalFormField
          inlineLabel={false}
          label="Date Created"
          className="proposal-form--form-field-date-picker"
          required={created_date.required}
        >
          <CalendarPickerDropdown
            label="Date Created"
            className="proposal-form--form-field-date-picker"
            value={proposal.created_date ? moment(proposal.created_date).format("YYYY-MM-DD") : null}
            onChange={(val) => {
              const date = !val ? "" : formatDateToUnixWithCurrentTime(val);
              if (date || date === "") onFormChange("created_date", date);
            }}
            hideClear
          />
        </ProposalFormField>
        <ProposalFormField inlineLabel={false} label="Type" required={proposal_type.required}>
          <div className="proposal-form--cancel-wrapper">
            <AdvancedCombobox
              className="proposal-form--form-field-dropdown"
              disabled={!proposalTypes.length}
              label={proposal.proposal_type ? proposal.proposal_type + " selected" : "Select proposal type"}
              value={_.findWhere(proposalTypes, { value: proposal.proposal_type })}
              options={proposalTypes}
              placeholder={proposalTypes.length ? "Select a proposal type..." : "No types configured"}
              onChange={(type) => {
                onFormChange("proposal_type", type.value);
              }}
            />
            {proposal.proposal_type && (
              <Button type="simple" onClick={() => onFormChange("proposal_type", null)} label="Remove proposal type">
                <Icon icon="cancel" className="proposal-form--cancel" title="Remove proposal type" size={1} />
              </Button>
            )}
          </div>
        </ProposalFormField>
      </div>
    </>
  );
};

ProposalFormDetails.propTypes = {
  onFormChange: PropTypes.func,
  proposal: PropTypes.object,
  additionalContactLimit: PropTypes.number,
  proposalTypes: PropTypes.array,
  standardFieldsConfig: PropTypes.object.isRequired,
};

export default ProposalFormDetails;
