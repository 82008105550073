import PropTypes from "prop-types";
import { connect } from "@evertrue/et-flux";
import SelectedContactsStore from "apps/contact/stores/selected-contacts-store";
import SelectedContactsSource from "apps/contact/sources/selected-contacts-source";

const propTypes = {
  showSelectedIndicator: PropTypes.bool,
  numberSelectedContacts: PropTypes.number,
  formatedTotalResultsCount: PropTypes.string,
  allSelected: PropTypes.bool,
};

const mapStateToProps = () => ({ showSelectedIndicator: SelectedContactsStore.getShowSelectedIndicator() });

const SelectAllIndicator = ({
  allSelected,
  showSelectedIndicator,
  numberSelectedContacts,
  formatedTotalResultsCount,
}) => {
  return showSelectedIndicator ? (
    <div className="contacts-fix-table--selected-message">
      {!allSelected ? (
        <div>
          All <strong>{numberSelectedContacts}</strong> Constituents on this page are selected.{" "}
          <span
            role="button"
            tabIndex={0}
            className="contacts-fix-table--selection"
            onClick={() => SelectedContactsSource.selectAll(true)}
          >
            Select all <strong>{formatedTotalResultsCount}</strong> Constituents
          </span>
        </div>
      ) : (
        <div>
          {"  "}
          All <strong> {formatedTotalResultsCount}</strong> are selected.{" "}
          <span
            role="button"
            tabIndex={0}
            className="contacts-fix-table--selection"
            onClick={SelectedContactsSource.clearAllSelectedState}
          >
            Clear Selection
          </span>
        </div>
      )}
    </div>
  ) : null;
};

SelectAllIndicator.propTypes = propTypes;

export default connect(
  SelectAllIndicator,
  [SelectedContactsStore],
  mapStateToProps
);
