module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   EverTrue = require("app")


   class PropertyHelper
      groups: [
         "name",
         "year",
         "addresses",
         "educations",
         "emails",
         "phones",
         "employments",
         "linkedin",
         "facebook",
         "giving",
         "score"
      ]

      groupOverrides:
         linkedin_positions: "linkedin",
         giving_annual_donations: "giving"
         dt_deceased: "deceased"

      # Loop through each schema object and pull out the properties,
      # Set the list and containing_object data to parent_object
      _propertyLoop: (schema, callback) ->
         _.each $.extend(true, {}, schema), (list) ->
            parent = $.extend(true, {}, list)

            if list.containing_object and list.containing_object.properties
               properties = list.containing_object.properties
            else if list.properties
               properties = list.properties
            else
               properties = [list]

            delete parent.containing_object.properties  if parent.containing_object

            _.each properties, (prop) ->
               prop.list_name = list.name
               prop.object_name = (if (list.containing_object) then list.containing_object.name else `undefined`)
               prop.parent_object = $.extend(true, {}, parent)
               callback.apply self, [list, prop]

      toPropLookup: (data) ->
         lookup = {}
         @_propertyLoop data, (list, prop) ->
            if prop.name is "value"
               lookup[prop.list_name] = prop
            else
               lookup[prop.list_name + "." + prop.name] = prop
         lookup

      toPropArray: (schema) ->
         array = []
         @_propertyLoop schema, (list, prop) -> array.push prop
         array

      toGroupArray: (schema, groupFlex) ->
         group_array = []
         lookup = @toGroupLookup(schema, groupFlex)
         _.each @groups, (group) ->
            group_array.push {group_name: group, properties: lookup[group]}
            delete lookup[group]

         _.each lookup, (group, name) ->
            group_array.push {group_name: name, properties: group}
         group_array

      toGroupLookup: (schema, groupFlex) ->
         properties = @toPropLookup(schema)
         _.each properties, (prop) =>
            if prop.name.match(/^name(.)+$/) then @groupOverrides[prop.name] = "name"

         grouped = _.groupBy(properties, "list_name")

         # Group Flex fields under custom_fields and remove from grouped object
         if groupFlex
            grouped.custom_fields = @toPropLookup @getFlex(schema)
            _.each grouped.custom_fields, (field) -> delete grouped[field.list_name]

         # Group the group overrides correctly
         _.each @groupOverrides, (group, field) ->
            grouped[group] = _.flatten [grouped[group], grouped[field]]
            delete grouped[field]
         grouped

      getFlex: (schema) ->
         flex = []
         @_propertyLoop schema, (list, prop) ->
            flex.push list unless list["default"]
         flex

      filterPermission: (fields) ->
         filtered = {}
         _.each fields, (field, key) =>
            filtered[key] = field if @checkAccess(field)
         filtered

      hasAccessTo: (properties, prop_key, write) ->
         if write
            @checkAccess(properties[prop_key], true)
         else
            !!properties[prop_key]

      checkAccess: (field, writable) ->
         return false unless field
         return false if field.deleted
         app = _.contains field.app_keys, EverTrue.config.app_keys.givingtree

         if EverTrue.store.user?.isSuperUser()
            permissions = [{}] #force read permission for all
         else
            roles = _.pluck(EverTrue.store.user?.getRoles(), "id")
            permissions = _.filter field.permissions, (perm) ->
               _.contains(roles, perm.actor_role_id)

         if writable
            write_permission = _.any permissions, (perm) -> perm.writable
            (!_.isEmpty(permissions)) && app && write_permission
         else
            (!_.isEmpty(permissions)) && app

   new PropertyHelper()
