import { PropTypes } from "base/react-libs";
import { useEffect, useState } from "react";
import TaskCount from "../../../journeys/components/task-count/task-count";
import checkIcon from "images/check.svg";

const OtherTaskCounter = ({ todayTaskCount, loadingTasksTodayCount }) => {
  const [title, setTitle] = useState("");
  const [showIcon, setShowIcon] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    const { completed, ...rest } = todayTaskCount;
    const { total } = todayTaskCount;
    let newTitle = "";
    if (total === 0 && completed === 0) {
      newTitle = "You have no other tasks due";
      setShowIcon(false);
    } else if (total > 0) {
      newTitle = null;
      setShowIcon(false);
    } else {
      newTitle = "You completed all of your other tasks!";
      setShowIcon(true);
    }
    setTitle(newTitle);
    setData(rest);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [todayTaskCount.total, todayTaskCount.completed]);

  return (
    <TaskCount
      title={"Other Tasks"}
      subTitle={title}
      icon={checkIcon}
      showIcon={showIcon}
      data={data}
      loadingCounts={loadingTasksTodayCount}
    />
  );
};

OtherTaskCounter.propTypes = {
  todayTaskCount: PropTypes.object,
  loadingTasksTodayCount: PropTypes.bool,
};

export default OtherTaskCounter;
