import { Component } from "react";
import { connect } from "@evertrue/et-flux";
import _ from "underscore";
import WithOrg from "base/org/with-org";
import VolunteerUserSource from "apps/volunteers/sources/volunteer-user-source";
import VolunteerUserStore from "apps/volunteers/stores/volunteer-user-store";

const mapStateToProps = () => ({
  users: VolunteerUserStore.getUsers(),
});

class VolunteerUserResolver extends Component {
  static defaultProps = {
    org: {},
    contactIds: [],
    render: () => null,
    users: {},
  };

  static propTypes = {
    contactIds: ReactLibs.PropTypes.array,
    render: ReactLibs.PropTypes.func,
    org: ReactLibs.PropTypes.object,
    users: ReactLibs.PropTypes.object,
  };

  componentDidMount() {
    this.fetchUsers(this.props.contactIds);
  }

  componentDidUpdate(prevProps) {
    const new_ids = _.difference(this.props.contactIds, prevProps.contactIds);
    if (new_ids.length) {
      this.fetchUsers(this.props.contactIds);
    }
  }

  fetchUsers(contactIds) {
    const { roles, id } = this.props.org;

    if (contactIds.length) {
      VolunteerUserSource.fetchIfNotCached(contactIds, roles, id);
    }
  }

  render() {
    return this.props.render({ volunteer_users: this.props.users });
  }
}

export default _.compose(
  WithOrg,
  connect
)(VolunteerUserResolver, [VolunteerUserStore], mapStateToProps);
