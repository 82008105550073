module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   EverTrue = require("app")
   React = require("react")
   ReactDOM = require("react-dom")
   ReactDOMServer = require("react-dom/server")
   classNames = require("classnames")
   str = require("underscore.string")
   UsersSearch = require("entities/search/user-search")
   ContactStore = require("apps/contact/stores/contact-store")
   OutsideClickMixin = require("mixins/outside-click-mixin")
   ContactFormatMixin = require("mixins/contact-format-mixin")
   CommentMixin = require("mixins/comment-mixin")
   require("jquery.caret")
   require("at.js")
   require("editable-content-caret")
   cache = []
   {div} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   ContactCard = createFactory(require("apps/contact/components/contact-card/contact-card"))
   UserCard = createFactory require("apps/users/components/user-card")
   Tooltip = require("components/overlays/tooltip")
   RoleHelper = require("entities/helpers/role-helper")
   Avatar = require("components/elements/avatar")
   createReactClass = require("create-react-class")
   Button = createFactory(require("@evertrue/et-components").Button)
   IconButton = createFactory(require("@evertrue/et-components").IconButton)
   Checkbox = createFactory require("@evertrue/et-components").Checkbox



   createReactClass
      displayName: "CommentTextarea",
      mixins: [OutsideClickMixin]
      propTypes:
         comment: ReactLibs.PropTypes.object
         disabled: ReactLibs.PropTypes.bool
         onSave: ReactLibs.PropTypes.func
         onCancel: ReactLibs.PropTypes.func
         onBlur: ReactLibs.PropTypes.func
         open: ReactLibs.PropTypes.bool
         hideAvatar: ReactLibs.PropTypes.bool
         disableCheckbox: ReactLibs.PropTypes.bool

      getInitialState: ->
         active: false
         submitReady: !_.isEmpty(@props.comment?.text)
         data: {user: [], contact: []}
         pinned: @props.comment?.pinned || false
         previous: @props.comment?.pinned || false

      componentDidMount: ->
         cache = []
         autocompleteRequests = []

         $target = $(ReactDOM.findDOMNode(@)).find(".note-textarea--input")
         $target.on "keyup", (evnt, flag, query) ->
            $(".atwho-view").each (index, el) ->
               $el = $(el)
               if $el.css("display") == "block"
                  $el.scrollToHighlight(".cur")

         if @props.comment.text
            commentHtml = @_parseComment()
            $target.html(commentHtml)
            $target.editableContentCursor("setCaretAt", $target.text().length)

         contactRefOptions = $.extend true, {}, @_autocompleteOptions("$"),
            insertTpl: @_mentionTpl("contact", "comment--ref")
            displayTpl: """
               <li data-value="<@contact:${id}:${alias}>">
                  <table className="table">
                     <tbody> ${template} </tbody>
                  </table>
               </li>
            """
            callbacks:
               sorter: (query, items, search_key) ->
                  # The default sorter filters out items matched to "search_key"
                  # since remote_filter is doing that for us, we're just returning items
                  items
               remoteFilter: (query, callback) ->
                  ContactStore.autocomplete query,
                     success: (resp) ->
                        data = _.map resp.toJSON(), (contact) ->
                           template = ContactCard contact: contact
                           $markup = $(ReactDOMServer.renderToStaticMarkup(template))
                           $markup.find(".contact-card--name").attr("href", "#")
                           name = ContactFormatMixin.detailedName(contact)
                           contact_data =
                              id: contact.id,
                              alias: str.classify(name)
                              full_name: name.trim()
                              template: $markup.html()
                        cache = resp.toJSON()
                        callback(data)

         userMentionOptions = $.extend true, {}, @_autocompleteOptions("@"),
            insertTpl: @_mentionTpl("user", "comment--mention")
            displayTpl: """
               <li data-value="<@user:${id}:${alias}>">
                  ${template}
               </li>
            """
            callbacks:
               remoteFilter: (query, callback) ->
                  _.each autocompleteRequests, (req) -> req.abort()
                  autocompleteRequests = []
                  users = new UsersSearch()
                  users.fetch
                     reset: true
                     search_query:
                        giving_tree_roles: true
                        name_search: query
                     beforeSend: (xhr, opts) ->
                        autocompleteRequests.push(xhr)
                     error: (collection, xhr, options, err) ->
                        unless xhr.status == 401 || xhr.status == 0
                           Raven.captureMessage "User Search Error: comments",
                              extra: {error: xhr.responseJSON, data: options.data}
                     success: (resp, server_resp) ->
                        accessible_users = _.reject resp.getAccessibleUsers(), (user) ->
                           user.id == EverTrue.store.user.get("id")

                        if _.isEmpty(query) && _.isEmpty(accessible_users)
                           Raven.captureMessage "User Search Error: comments",
                              extra:
                                 error: "no accessible_users"
                                 data: server_resp
                                 roles: RoleHelper.toArray()

                        data = _.map accessible_users, (user) ->
                           component = UserCard user: user, disableEmail: true
                           user_data =
                              id: user.id,
                              alias: str.classify(user.name)
                              full_name: user.name
                              email: user.email
                              avatar: user.avatar
                              template: ReactDOMServer.renderToStaticMarkup(component)
                        cache = resp.toJSON()
                        callback(data)

         $target.atwho(contactRefOptions).atwho(userMentionOptions)

      UNSAFE_componentWillReceiveProps: (newProps) ->
         if newProps.comment.text != @props.comment.text
            commentHtml = @_parseComment(newProps)
            $target = $(ReactDOM.findDOMNode(@)).find(".note-textarea--input")
            $target.html(commentHtml)
            $target.editableContentCursor("setCaretAt", $target.text().length)
            @setState {submitReady: !_.isEmpty(newProps.comment.text)}

      componentWillUnmount: ->
         cache = []

      _parseComment: (props) ->
         props ?= @props
         if props.comment?.text
            data = @state.data
            content = CommentMixin.parse props.comment.text, (full_match, match, type, id, alias) ->
               currentMention = _.find props.comment.mentions, (mention) ->
                  if type is "user" then mention_id = mention.user_id
                  else mention_id = mention.contact_id
                  parseInt(mention_id, 10) == parseInt(id, 10)

               return "" unless currentMention
               if type is "user" then displayName = currentMention?.data?.name
               else displayName = ContactFormatMixin.detailedName(currentMention?.data)
               displayName ?= _.escape(str.titleize(str.humanize(alias))) || ""

               data[type].push currentMention.data
               className = if type is "user" then "comment--mention" else "comment--ref"

               if /firefox/i.test(navigator.userAgent)
                  "<input class='mention #{className}' type='button' disabled='disabled' data-value='#{full_match}' value='#{displayName}' />"
               else
                  "<span contentEditable='false' class='mention #{className}' data-value='#{full_match}'>#{displayName}</span>"

            @setState {data: data}
            #Enforcing a space at the end of this prevents a bug with setting the cursor position
            content.trim() + " "

      _mentionTpl: (type, className) ->
         # Firefox bug with contentEditable https://bugzilla.mozilla.org/show_bug.cgi?id=685445
         # Hack to use input=button so that deleting mentions works correctly
         if /firefox/i.test(navigator.userAgent)
            "<input class='mention #{className}' type='button' disabled='disabled' data-value='<@#{type}:${id}:${alias}>' value='${full_name}' />"
         else
            "<span contentEditable='false' class='mention #{className}' data-value='<@#{type}:${id}:${alias}>'>${full_name}</span>"

      _autocompleteOptions: (at) ->
         options =
            at: at
            limit: 100
            searchKey: "full_name"
            suffix: " "
            callbacks:
               matcher: (flag, subtext, should_start_with_space) ->
                  # Mostly copied from at.js default, but changed to allow spaces
                  flag = flag.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&") # escape RegExp
                  flag = "(?:^|\\s)" + flag if should_start_with_space
                  _a = decodeURI("%C3%80") # À
                  _y = decodeURI("%C3%BF") # ÿ
                  regexp = new RegExp "#{flag}([A-Za-z#{_a}-#{_y}0-9\\s_\+\-]*)$|#{flag}([^\\x00-\\xff]*)$","gi"
                  match = regexp.exec subtext
                  if match then match[2] || match[1] else null
               beforeInsert: (value, $li) =>
                  # Get contact/user information, so we don't need to do additional lookups
                  [type, id] = CommentMixin.parseDSL($li.data("value"))
                  contact = _.findWhere cache, {id: parseInt(id, 10)}
                  if contact
                     data = @state.data
                     data[type].push(contact)
                     @setState {data: data}
                  value
               highlighter: (li, query) ->
                  words = _.map query.trim().split(" "), (str) -> str.trim()
                  $el = $("<div>#{li}</div>")
                  $name = $el.find(".contact-card--name strong")
                  $name.highlight(words, "search-result-key")
                  $el.html()
               insertingWrapper: ($inputor, content, suffix) ->
                  "<span contentEditable='true'>#{content}#{suffix}</span>"

      handleSave: ->
         $el = $(@refs.textfield)
         mentions = []
         user_mentions = []
         contact_mentions = []

         # Clone element and find/replace all instances of mentions
         # use the data-value attribute to set the mention dsl & data
         $elClone = $el.clone()
         $elClone.find(".mention").each (index, mention) =>
            $mention = $(mention)
            dsl = $mention.data("value")
            [type, id, alias] = CommentMixin.parseDSL(dsl)
            if type is "user"
               user_mentions.push
                  alias: alias
                  user_id: _.toNumber(id)
            else if type is "contact"
               contact_mentions.push
                  alias: alias
                  contact_id: _.toNumber(id)
            mentions.push
               alias: alias
               mentioned_user_id: id if type is "user"
               mentioned_contact_id: id if type is "contact"
               data: _.findWhere(@state.data[type], {id: parseInt(id, 10)}) if @state.data[type]
            $mention.replaceWith(dsl)

         # Convert html linebreaks into escaped text so
         # comment get's saved with the correct formatting
         formatting = $elClone.html()
            .replace(/<div><br><\/div>/g, "\n")
            .replace(/<br>/g, "\n")
            .replace(/<p>/g, "\n")
            .replace(/<div>/g, "\n")
            .replace(/<\/div>/g, "")
            .replace(/<\/p>/g, "")
         $elClone.html(formatting)

         comment = _.cloneData @props.comment || {}
         comment.text = $elClone.text()
         comment.mentions = mentions
         comment.user_mention = user_mentions
         comment.contact_mention = contact_mentions
         comment.pinned = @state.pinned

         if _.isFunction @props.onSave
            @props.onSave(comment, @state.data)

         if @state.previous != @state.pinned
            if @state.pinned
               EverTrue.track.set 'pinned_comment_action',
                  type: "pinned_comment"
            else
               EverTrue.track.set 'pinned_comment_action',
                  type: "unpinned_comment"

         $el.blur()
         $el.html("")
         @setState {active: false, submitReady: false, pinned: false}

      handleClick: (at) ->
         # Get current position of the cursor
         $target = $(ReactDOM.findDOMNode(@)).find(".note-textarea--input")
         caretPosition = $target.caret("pos")
         $target.editableContentCursor "setContentAt", caretPosition, at, =>
            @setState {submitReady: true} if !@state.submitReady
            $target.trigger("keyup")

      handleMentionClick: (e) ->
         @handleClick("@")

      handleCheckBox: () -> 
         @setState( {pinned: !@state.pinned} )

      handleRefClick: (e) ->
         @handleClick("$")

      handleCancel: ->
         @setState {active: false, submitReady: false, data: {user: [], contact: []}, pinned:false}
         $target = $(ReactDOM.findDOMNode(@)).find(".note-textarea--input")
         $target.blur().empty()
         @props.onCancel() if _.isFunction(@props.onCancel)

      handleFocus: ->
         @setState({active: true}) if !@state.active

      handleOutsideClick: (e) ->
         parent = $(e.target).closest(".atwho-view")

         if parent.hasClass("atwho-view")
            e.stopPropagation()
         else
            @setState {active: !!@state.submitReady}
            @props.onBlur() if _.isFunction(@props.onBlur)

      handleSubmitReady: (e) ->
         if !_.isEmpty $(e.currentTarget).text().trim()
            @setState {submitReady: true}
         else
            $(@refs.textfield).empty()
            @setState {submitReady: false}

      render: ->
         textareaClasses = classNames
            "note-textarea--input": true
            "is-active": !!@state.active
            "is-open": @props.open

         div className: classNames(@props.className, "note-textarea"), onClick: @handleInsideClick,
            if @props.disabled
               div className: "loading-overlay"

            if !@props.hideAvatar
               Avatar
                  className: "note-textarea--avatar"
                  url: EverTrue.store?.user?.get("avatar")
                  name: EverTrue.store?.user?.get("name")

            div className: "note-textarea--input-wrapper",
               div
                  id: "note-textbox-ref"
                  className: textareaClasses
                  contentEditable: true
                  placeholder: "Add a comment (e.g. @Sarah could we get a rating on $Paul's wife)..."
                  onFocus: @handleFocus
                  onKeyUp: @handleSubmitReady
                  ref: "textfield"

               if @state.active || @props.open
                  div className: "note-textarea--mentions",
                     Tooltip
                        content: "Mention Colleague",
                        type: "dark",
                        width: 135,
                        className: "note-textarea--action"
                        children: ({open, close}) =>
                           IconButton title: "Mention a colleague", icon: "mention", onFocus: open, onBlur: close, className: "note-textarea--action note-textarea--mentionlink", onClick: @handleMentionClick
                     Tooltip
                        content: "Link constituent",
                        type: "dark",
                        width: 125,
                        className: "note-textarea--action note-textarea--link"
                        children: ({open, close}) =>
                           IconButton title: "Link a constituent", icon: "link", size: 1, onFocus: open, onBlur: close, className: "note-textarea--action note-textarea--mentionlink", onClick: @handleRefClick,

            div className: "checkbox",
               Checkbox
                  disabled: @props.disableCheckbox
                  checked:@state.pinned
                  onChange: @handleCheckBox
                  label:"Checkbox"
                  "Pin this comment"
               


            if @state.active || @props.open
               div className: "note-textarea--form-actions",
                  Button
                     type: "simple"
                     title: "Cancel Comment"
                     onClick: @handleCancel
                     "Cancel"

                  Button
                     className: "note-textarea--form-save"
                     title: "Save the Comment"
                     disabled: !@state.submitReady
                     onClick: @handleSave
                     if @props.comment.text then "Save" else "Post Comment"
