import { createStore } from "@evertrue/et-flux";
import DynamicListSource from "apps/dynamic-lists/sources/dynamic-list-source";

const _getDefaultState = () => ({
  clearFilters: false,
  dynamicListAlertPref: {},
});

export default createStore("DynamicListStore", {
  getInitialState() {
    return _getDefaultState();
  },

  registerActions() {
    this.on(DynamicListSource.actions.setClearFilters, (bool) => {
      this.setState({ clearFilters: bool });
    });
    this.on(DynamicListSource.actions.setDynamicListAlertPref, (obj) => {
      this.setState({
        dynamicListAlertPref: obj,
      });
    });
  },

  api: {
    getClearFilters() {
      return this.state.clearFilters;
    },
    getDynamicListAlertPref() {
      return this.state.dynamicListAlertPref;
    },
  },
});
