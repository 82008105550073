/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react";
import ChatInputField from "apps/constituents/ai-profile-chat/components/chat-input-field";
import ChatMessages from "apps/constituents/ai-profile-chat/components/chat-messages";
import PropTypes from "prop-types";
import useChat from "apps/constituents/ai-profile-chat/hooks/useChat";
import Loading from "components/elements/loading";
import { Icon } from "@evertrue/et-components";
import { PageHeader } from "@evertrue/et-components";

const AiSearchHome = ({ contactId }) => {
  const [userInput, setUserInput] = useState("");
  const [userMessages, setUserMessages] = useState([]);
  const [suggestedSearchClicked, setSuggestedSearchClicked] = useState(false);
  const requestIndexRef = useRef(0);
  const currentRequestIndexRef = useRef(0);
  const { createChat, sendMessage, aiResponse, isStreaming, chat } = useChat(contactId, requestIndexRef.current, 3);
  const [submitted, setSubmitted] = useState(false);
  const [searchTitle, setSearchTitle] = useState("");
  const [tags, setTags] = useState([]);

  const { request_id: requestId } = chat;

  const suggestedSearches = [
    "Who are my top lifetime giving donors?",
    "Constituents who gave >$5000 in last 24 months and had no interactions in the last 6 months",
    "Who from my portfolio has mentioned children graduating from college this year?",
  ];

  useEffect(() => {
    createChat();
    // TODO: set the title and tags dynamically from the chat API response
    setSearchTitle("Top Lifetime Giving Donors");
    setTags(["Constituents", "Signal", "Lifetime Giving"]);
  }, []);

  const handleInput = (input) => {
    setUserInput(input);
  };

  const handleSuggestedSearchClick = (text) => {
    setUserInput(text);
    setSuggestedSearchClicked(true);
  };

  useEffect(() => {
    if (suggestedSearchClicked) {
      handleSubmit();
      setSuggestedSearchClicked(false);
    }
  }, [userInput, suggestedSearchClicked]);

  const handleSubmit = () => {
    const currentIndex = currentRequestIndexRef.current;
    requestIndexRef.current = currentIndex;
    sendMessage(userInput);
    setUserMessages((prevMessages) => [...prevMessages, { content: userInput, index: currentIndex }]);
    setUserInput("");
    setSubmitted(true);
    currentRequestIndexRef.current++;
  };

  return (
    <>
      <div className="header-container">
        <div className="title-container">
          <PageHeader title="AI Search" className="ai-search-title" />
        </div>
        {submitted && (
          <div className="ai-search-title">
            <div className="title-icon">
              <Icon icon="nls-ai-spark" iconKit="gt2" style={{ color: "#A54AB5" }} />
            </div>
            <div className="title">{searchTitle}</div>
          </div>
        )}
      </div>

      {/* tags */}
      {submitted && (
        <div className="tags-wrapper">
          <div className="tag-title">Tags:</div>
          <div className="tag-container">
            {tags.map((tag) => (
              <span key={tag} className="tag">
                {tag}
              </span>
            ))}
          </div>
        </div>
      )}

      {/* chat bot with input box and chat messages */}
      {Object.keys(chat).length > 0 ? (
        <div className={`chat-bot${submitted ? "-submitted" : ""}`}>
          <ChatMessages
            userMessageRequests={userMessages}
            aiResponses={aiResponse}
            isStreaming={isStreaming}
            suggestedSearches={suggestedSearches}
            handleSuggestedSearchClick={handleSuggestedSearchClick}
          />

          {!submitted && (
            <>
              <ChatInputField
                userInput={userInput}
                handleInput={handleInput}
                onSubmit={handleSubmit}
                leftIcon="ai-search"
                leftIconKit="gt2"
                placeholder="Type here to search..."
              />
              <div className="chat-bot-warning">
                <Icon icon="warning" style={{ fontSize: "11px" }} />
                <p>AI responses can be inaccurate or misleading</p>
              </div>
              <div className="request-id">
                {requestId && (
                  <p>
                    <small>{`Request ID: ${requestId}`}</small>
                  </p>
                )}
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="loader-div">
          <Loading spinner_size="small" position="center" />
        </div>
      )}
      {/* Contacts list will be diaplyed here in future */}
      {submitted && <div className="nls-search-list-view">Coming Soon</div>}
    </>
  );
};

AiSearchHome.propTypes = {
  contactId: PropTypes.number,
};

export default AiSearchHome;
