import { Component } from "react";
import PropTypes from "prop-types";
import _ from "underscore";
import { connect } from "@evertrue/et-flux";
import PoolsStore from "apps/volunteers/stores/pools-store";
import PoolsSource from "apps/volunteers/sources/pools-source";
import PageHeader from "apps/layout/components/page-header";
import Loading from "components/elements/loading";
import Breadcrumbs from "components/elements/breadcrumbs";
import PoolActions from "apps/volunteers/components/pool-actions";
import PoolSolicitorsStore from "apps/volunteers/stores/pool-solicitors-store";
import PoolProspectsStore from "apps/volunteers/stores/pool-prospects-store";
import Nav from "components/lists/nav";
import NavItem from "components/lists/nav-item";
import PoolAssignmentController from "apps/volunteers/controllers/pool-assignment-controller";
import PoolReportController from "apps/volunteers/controllers/pool-report-controller";
import PoolFormModal from "apps/volunteers/components/pool-form-modal";
import DeletePoolModal from "apps/volunteers/components/delete-pool-modal";
import IsGated from "components/is-gated";
import SolicitorAuthResolver from "../components/solicitor-auth-resolver";
import PoolSolicitorsSource from "apps/volunteers/sources/pool-solicitors-source";
import PoolProspectsSource from "apps/volunteers/sources/pool-prospects-source";
import str from "underscore.string";
import { Modal2 } from "@evertrue/et-components";

const mapStateToProps = (props) => ({
  pool: PoolsStore.getPoolById(props.id),
  page: PoolsStore.getPage(),
  loading: PoolsStore.getLoading(),
  solicitors_total: PoolSolicitorsStore.getStaticTotal(props.id),
  prospects_total: PoolProspectsStore.getTotalForPool(props.id),
  solicitors: PoolSolicitorsStore.getForPool(props.id),
});

const TABS = ["assignment", "pool-report"];

class PoolController extends Component {
  static propTypes = {
    id: PropTypes.number,
    pool: PropTypes.object,
    page: PropTypes.number,
    loading: PropTypes.bool,
    solicitors_total: PropTypes.number,
    prospects_total: PropTypes.number,
    solicitors: PropTypes.object,
    tab: PropTypes.string,
  };

  static defaultProps = {
    pool: {},
  };

  state = {
    tab: TABS.find((i) => i === this.props.tab) || TABS[0],
    is_edit_modal_open: false,
    is_delete_modal_open: false,
  };

  componentDidMount() {
    PoolsSource.fetchGivingCategories();
    PoolsSource.fetchIfNotCached(this.props.id);
    // TODO: remove all cdms where it's fetching this
    PoolSolicitorsSource.fetchSample(this.props.id);
    PoolProspectsSource.fetchTotal(this.props.id);
  }

  renderBreadcrumbs = () => {
    return [
      { id: 0, label: "Pools", link: `/volunteers?poolPage=${this.props.page}` },
      { id: 1, label: this.props.pool != null ? this.props.pool.name : undefined },
    ];
  };
  render() {
    const pool = {
      id: this.props.id,
      total_prospect_count: this.props.prospects_total,
      total_solicitor_count: this.props.solicitors_total,
      ...this.props.pool,
    };

    const contact_ids = _.uniq(_.pluck(this.props.solicitors.items, "id"));
    return (
      <>
        <Modal2 isOpen={this.state.is_edit_modal_open} onClose={() => this.setState({ is_edit_modal_open: false })}>
          <PoolFormModal pool={this.props.pool} onClose={() => this.setState({ is_edit_modal_open: false })} />
        </Modal2>

        <DeletePoolModal
          pool={this.props.pool}
          isOpen={this.state.is_delete_modal_open}
          closeModal={() => this.setState({ is_delete_modal_open: false })}
        />

        {/* kicks of a fetch for data that's cached */}
        <SolicitorAuthResolver contactIds={contact_ids} poolId={pool.id} render={() => null} />
        <IsGated feature="vms_insights">
          {({ show }) => (
            <div className="pool-detail">
              <div>
                <PageHeader
                  title={Breadcrumbs({ items: this.renderBreadcrumbs() })}
                  backButtonHref={`/volunteers?poolPage=${this.props.page}`}
                  actions={
                    <PoolActions
                      openEditPoolModal={() => this.setState({ is_edit_modal_open: true })}
                      openDeletePoolModal={() => this.setState({ is_delete_modal_open: true })}
                      pool={this.props.pool}
                    />
                  }
                />

                {show && (
                  <div className="pool-detail--tabs">
                    <Nav
                      active={this.state.tab}
                      onSelect={(tab) => this.setState({ tab })}
                      className="volunteers-controller--nav"
                    >
                      {TABS.map((tab) => (
                        <NavItem
                          key={tab}
                          activeWhen={tab}
                          href={`/volunteers/pool/${pool.id}/${tab}`}
                          label={str.humanize(tab)}
                        />
                      ))}
                    </Nav>
                  </div>
                )}
              </div>
              <div className="pool-detail--body" style={{ ...(!show && { top: 75 }) }}>
                {this.props.loading ? (
                  <Loading />
                ) : (
                  {
                    [TABS[0]]: <PoolAssignmentController pool={pool} solicitors={this.props.solicitors} />,
                    [TABS[1]]: <PoolReportController pool={pool} />,
                  }[this.state.tab]
                )}
              </div>
            </div>
          )}
        </IsGated>
      </>
    );
  }
}

export default connect(PoolController, [PoolProspectsStore, PoolSolicitorsStore, PoolsStore], mapStateToProps);
