module.exports = do ->
   _ = require("underscore").default
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")
   ErrorLogger = require("entities/helpers/error-log-helper")

   createSource "ImportSource",
      actions:
         loading: true
         fetchedLastImport: (timestamp) ->
            @require (_.isNumber(timestamp) || _.isUndefined(timestamp)), "timestamp should be millisecond time or undefined"

      api:
         fetchLastImport: ->
            @actions.loading(true)
            Api.IMPORTER.LATEST.get
               success: (response) =>
                  @actions.loading(false)
                  @actions.fetchedLastImport(response?.status_started_at)
               error: =>
                  @actions.loading(false)
