module.exports = do ->
   React = require("react")
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   {span, a} = ReactLibs.DOMFactories

   createComponent "ActiveFiltersHeader",
      propTypes:
         filter_labels: ReactLibs.PropTypes.array
         onClick: ReactLibs.PropTypes.func
         className: ReactLibs.PropTypes.string

      render: ->
         filter_labels = @props.filter_labels

         if filter_labels.length
            span className: classNames(@props.className, "active-filters-header"),
               span className: "active-filters-header--text", "by"
               a className: "active-filters-header--label", onClick: @props.onClick,
                  filter_labels[0]

               if filter_labels.length > 1
                  span className: "active-filters-header--text", "and"

               if filter_labels.length is 2
                  a className: "active-filters-header--label", onClick: @props.onClick,
                     filter_labels[1]
               else if filter_labels.length > 2
                  a className: "active-filters-header--label", onClick: @props.onClick,
                     "#{filter_labels.length - 1} more..."

