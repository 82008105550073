module.exports = do ->
   _ = require("underscore").default
   moment = require("moment")
   numeral = require("numeral")
   React = require("react")
   EverTrue = require("app")
   Decorator = require("clientDecorator")
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   {div, strong, img} = ReactLibs.DOMFactories
   ProfileFacebookStore = require("apps/profile/stores/profile-facebook-store")
   ProfileFacebookSource = require("apps/profile/sources/profile-facebook-source")
   ProfileSource = require("apps/profile/sources/profile-source")
   ProfileStore = require("apps/profile/stores/profile-store")
   SimilarProfileSource = require("apps/profile/sources/similar-profile-source")
   SimilarProfileStore = require("apps/profile/stores/similar-profile-store")
   {createFactory} = require("base/new-utils")
   ProfileCardSubtitle = createFactory require("apps/profile/components/profile-card-subtitle").default
   TransactionalGiftUtils = require("apps/contact/utils/transactional-gift-utils")
   MyPortfolioConfig = require("apps/major-gifts/my-portfolio-config")
   FacebookReactions = require("apps/facebook/helpers/facebook-reactions-utils")
   FeatureStore = require("apps/layout/stores/feature-store").default
   DNAStore = require("base/dna/dna-store").default
   Avatar = require("components/elements/avatar")
   Loading = require("components/elements/loading")
   ReactSparklines = require("react-sparklines")
   Sparklines = createFactory ReactSparklines.Sparklines
   SparklinesLine = createFactory ReactSparklines.SparklinesLine
   EllipsisOverflow = createFactory require("components/formatting/ellipsis-overflow")
   EngagementIconList = require("apps/profile/components/engagement-icon-list")
   str = require("underscore.string")
   KeyPressManager = createFactory(require("components/helpers/KeyPressManager").default)
   VolunteerMembership = createFactory(require("apps/profile/components/templates/volunteer-membership").default)
   colors = require("base/colors")

   CONNECTION_COUNT = 5

   KeyMetricsSection = (props) ->
      KeyPressManager className: "key-metrics--col", tabIndex: 0, onClick: props.onClick, onEnter: props.onClick,
         props.children

   KeyMetricsSection = createFactory(KeyMetricsSection)


   createComponent "KeyMetrics",
      propTypes:
         contact: ReactLibs.PropTypes.object
         totalAssignees: ReactLibs.PropTypes.array

      registerStores: ->
         @on ProfileStore, ->
            last_contact_date: ProfileStore.getLastContactDate(@props.contact?.id)
            is_matched_facebook: ProfileStore.hasFacebookMatch(@props.contact?.id)

         @on FeatureStore, ->
            has_last_contact_date: FeatureStore.hasFeature("major_gifts")
            has_rm_assignments: FeatureStore.hasFeature("rm_assignments")

         @on ProfileFacebookStore, ->
            reactions: ProfileFacebookStore.getReactionsForContact(@props.contact?.id)

         @on SimilarProfileStore, ->
            similar: _.sortBy SimilarProfileStore.getAll(@props.contact.id), (c) -> !c.avatar
            loading_similar: SimilarProfileStore.getLoading()

         @on DNAStore, ->
            fiscal_year_start: DNAStore.getFiscalYearStart()

      componentDidMount: ->
         @fetchDataForContact(@props.contact)

      UNSAFE_componentWillReceiveProps: (newProps) ->
         if newProps.contact?.id != @props.contact?.id
            if newProps.contact?.id
               @fetchDataForContact(newProps.contact)

      fetchDataForContact: (contact) ->
         if contact?.id
            ProfileFacebookSource.fetchReactionStats(contact.id)
            ProfileSource.fetchLastContactDate(contact.id)
            SimilarProfileSource.fetch(contact.id)

      getGivingTrendData: ->
         data = TransactionalGiftUtils.formatNonTransactionalGiftData(@props.contact?.giving_annual_donations)
         _.pluck(data, "y")

      handleNavigate: (tab) ->
         link = "/contact/#{@props.contact.id}/#{tab}"
         Backbone.history.navigate(link, {trigger: true, replace: true})

      renderGiftHelp: (prop_key) ->
         "profile_#{prop_key}_import"

      renderGivingPattern: ->
         giving_pattern = @props.contact?.giving?.giving_pattern?.value

         if giving_pattern == "lybunt" || giving_pattern == "sybunt"
            giving_pattern.toUpperCase()
         else if giving_pattern == "gave this year"
            str.titleize(giving_pattern)
         else "Never Gave"

      renderReactions: (reaction_keys) ->
         zIndex = reaction_keys.length
         _.map reaction_keys, (reaction_key, index) ->
            img
               key: reaction_key
               className: "key-metrics--facebook-icon"
               src: FacebookReactions.getIconFor(reaction_key)
               style: {zIndex: zIndex - index}

      render: ->
         giving = @props.contact?.giving
         giving_trend = @getGivingTrendData()

         # Force some empty state values for sparkline.
         if giving_trend.length == 1 then giving_trend = [0].concat(giving_trend)
         else if _.isEmpty(giving_trend) then giving_trend = [0, 0]

         assignee = _.first(@props.totalAssignees)
         if assignee_user = assignee?.user_id?.value
            assignee_url = EverTrue.store.user.getAvatarUrl(assignee_user)
         last_date_format = MyPortfolioConfig.getFormattedTimeInStage(@state.last_contact_date)

         comment_count = @props.contact?.facebook?.comment_count?.value || 0
         total_count = @props.contact?.facebook?.total_engagement_count?.value || 0
         reaction_count = (total_count - comment_count) || 0
         reaction_keys = _.without(_.keys(@state.reactions), "comment")


         div className: "key-metrics clearfix", ref: "key_metrics",
            # Lifetime Giving
            KeyMetricsSection onClick: (=> @handleNavigate("giving")),
               ProfileCardSubtitle label: "Lifetime Giving", help_key: @renderGiftHelp("lifetime")
               div className: classNames("key-metrics--lifetime-giving fs-exclude-data", "is-empty": !giving?.lifetime_amount?.value),
                  strong null, _.currencyFormatter(giving?.lifetime_amount?.value)
                  "In total gifts"
               div className: "fs-exclude-data",
                  Sparklines data: giving_trend, height: 40, style: {maxHeight: 40},
                     SparklinesLine color: colors.green300, style: {fill: "none", strokeWidth: 2}

            # Last Gift
            KeyMetricsSection onClick: (=> @handleNavigate("giving")),
               ProfileCardSubtitle label: "Last Gift", help_key: @renderGiftHelp("last_gift")
               div className: "key-metrics--last-gift fs-exclude-data",
                  strong null, _.currencyFormatter(giving?.last_gift_amount?.value)
                  if giving?.last_gift_date?.value
                     div className: "fs-exclude-data", moment(new Date(giving?.last_gift_date?.value)).fromNow()
                  else "—"

               div className: "key-metrics--giving-pattern ellipsis-overflow",
                  "Giving Pattern: "
                  strong null, @renderGivingPattern()

            # Assignee
            if assignee
               KeyMetricsSection onClick: (=> @handleNavigate("bio")),
                  ProfileCardSubtitle label: "Assignment"

                  div className: "key-metrics--assignee clearfix",
                     Avatar url: assignee_url, width: 50, name: assignee?.name?.value || "Assignee name not available"
                     div className: "key-metrics--assignee-text",
                        # if the assignee is coming from the assignments endpoint (teams and volunteers)
                        if assignee.is_assignment
                           div null,
                              EllipsisOverflow null,
                                 strong null, assignee.name
                              div null, assignee.membership.assignment_title
                        else if assignee.is_volunteer_assignment
                           div null,
                              EllipsisOverflow null,
                                 strong null, assignee.name
                              VolunteerMembership membership: assignee.membership, show_only_role: true
                        # else if the assignment is on the contact
                        else
                           div null,
                              EllipsisOverflow null,
                                 strong null, assignee.name?.value
                              div null, assignee.type?.value
                        div null,
                           if assignee_count = @props.totalAssignees.length - 1
                              "(+#{assignee_count} more)"

                  if @state.has_last_contact_date and !@state.has_rm_assignments
                     div null,
                        "Last Contact: "
                        strong null, if last_date_format then last_date_format + " ago" else "n/a"


            # Facebook Engagement
            KeyMetricsSection onClick: (=> @handleNavigate("posts")),
               ProfileCardSubtitle label: "Facebook"

               div null,
                  div className: "key-metrics--facebook",
                     div null,
                        strong className: "key-metrics--reactions-count",
                           numeral(reaction_count).format("0k")
                        if _.isEmpty(reaction_keys)
                           EngagementIconList icons: ["sad"], size: 30, disabled: true
                        else
                           EngagementIconList icons: reaction_keys, size: 30

                     if !@state.is_matched_facebook
                        div className: "key-metrics--facebook-unmatched",
                           "Constituent not matched"
                     else
                        div null, _.plural(reaction_count, "Reaction", "Reactions")


                  if @state.is_matched_facebook
                     div className: "key-metrics--facebook",
                        div null,
                           strong className: "key-metrics--reactions-count",
                              numeral(comment_count).format("0k")
                           EngagementIconList icons: ["comment"], size: 30
                        div null,
                           _.plural(comment_count, "Comment", "Comments")
