import PropTypes from "prop-types";
import { Icon, ModalHeaderCustom, Button } from "@evertrue/et-components";

const EnrichmentModalHeader = ({ headerText, updateDate, closeModal = () => {}, subheader }) => {
  return (
    <ModalHeaderCustom>
      <div className="profile-enrichment--modal-header">
        {headerText}
        <Button type="simple" onClick={closeModal}>
          <Icon className="et--modal-confirm--close" icon="close" onKeyDown={closeModal} />
        </Button>
        {(updateDate || subheader) && (
          <div className="profile--enrichment-meta-modal">
            {subheader || `Data was last added to this section on ${updateDate}`}
          </div>
        )}
      </div>
    </ModalHeaderCustom>
  );
};

EnrichmentModalHeader.propTypes = {
  headerText: PropTypes.string,
  updateDate: PropTypes.string,
  closeModal: PropTypes.func,
  subheader: PropTypes.string,
};

export default EnrichmentModalHeader;
