module.exports = do ->
   _ = require("underscore").default
   {createSource} = require("@evertrue/et-flux")


   createSource "ExportFieldSource",
      actions:
         selectedCategory: (group_key) ->
            @require _.isString(group_key), "group_key is string"
         unselectedCategory: (group_key) ->
            @require _.isString(group_key), "group_key is string"
         selectedField: (cat, field_group) ->
            @require _.isString(cat), "cat is string"
            @require _.isString(field_group), "field_group is string"
         unselectedField: (cat, field_group) ->
            @require _.isString(cat), "cat is string"
            @require _.isString(field_group), "field_group is string"

      api:
         selectCategory: (group_key) ->
            @actions.selectedCategory(group_key)

         unselectCategory: (group_key) ->
            @actions.unselectedCategory(group_key)

         selectField: (cat, field_group) ->
            @actions.selectedField(cat, field_group)

         unselectField: (cat, field_group) ->
            @actions.unselectedField(cat, field_group)
