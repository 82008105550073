import { Pagination } from "@evertrue/et-components";
import useTotalCount from "apps/query-builder/hooks/use-total-count";
import { PropTypes } from "base/react-libs";
import ResultsCount from "components/charts/results-count";
import { connect } from "@evertrue/et-flux";
import SelectedContactsStore from "apps/contact/stores/selected-contacts-store";
import SelectedCount from "components/charts/selected-count";

const mapStateToProps = () => ({
  selected: SelectedContactsStore.getSelectedContacts(),
});

const TableToolbar = ({ page, offset, limit, onPage, resultCount, selected }) => {
  const [totalCount] = useTotalCount();
  return (
    <div className="table-toolbar">
      {resultCount > 0 &&
        (selected.length >= 1 ? (
          <SelectedCount
            className="table-toolbar-result-count"
            totalCount={totalCount}
            resultCount={resultCount}
            start={offset}
            limit={limit}
            selected={selected}
          />
        ) : (
          <ResultsCount
            className="table-toolbar-result-count"
            totalCount={totalCount}
            resultCount={resultCount}
            start={offset}
            limit={limit}
          />
        ))}

      <Pagination
        className="table-toolbar--pagination"
        page={page}
        total={Math.ceil(resultCount / limit)}
        onChange={(page) => {
          onPage(page);
        }}
      />
    </div>
  );
};

TableToolbar.propTypes = {
  page: PropTypes.number.isRequired,
  offset: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  onPage: PropTypes.func.isRequired,
  resultCount: PropTypes.number.isRequired,
  selected: PropTypes.array,
};

export default connect(TableToolbar, [SelectedContactsStore], mapStateToProps);
