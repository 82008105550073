export const INTERACTION_FIELDS = Object.freeze({
  solicitor: "solicitor",
  date_occurred: "date_occurred",
});

export const DXO_INTERACTION_FIELDS = Object.freeze({
  solicitor: "solicitor",
  ask_date: "ask_date",
  dxo_text: "text",
});
