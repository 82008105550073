module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   React = require("react")
   {thead, tr, th, span, i} = ReactLibs.DOMFactories
   {array, number, func, object, bool} = ReactLibs.PropTypes
   {createFactory} = require("base/new-utils")
   LinkButton = createFactory(require("@evertrue/et-components").LinkButton)

   ReactLibs.createClass
      displayName: "PagedColumnTableHeader"

      propTypes:
         columns: array
         columnWidth: number
         visibleColumns: array
         sortData: object
         onSort: func
         onPrev: func
         onNext: func
         resizeLastColumn: bool

      renderPrevious: (index) ->
         if @props.onPrev
            numColumns = _.size(@props.columns)
            numVisible = _.size(@props.visibleColumns)
            sticky = _.filter @props.columns, (col) -> col.sticky
            if (numColumns isnt numVisible) and (index is _.size(sticky) - 1)
               atTheStart = @props.beginIndex is 0
               classes = EverTrue.classSet("paged-col-table--btn", "is-disabled": atTheStart)

               LinkButton title: "Go to previous", className: classes, onClick: @props.onPrev,
                  i className: "fa fa-caret-left fa-2x"

      renderNext: (index) ->
         if @props.onNext
            numColumns = _.size(@props.columns)
            numVisible = _.size(@props.visibleColumns)
            if (numColumns isnt numVisible) and (index is numVisible - 1)
               atTheEnd = @props.beginIndex is (numColumns - numVisible)
               classes = EverTrue.classSet("paged-col-table--btn", "is-disabled": atTheEnd)

               LinkButton title: "Go to next", className: classes, onClick: @props.onNext,
                  i className: "fa fa-caret-right fa-2x"

      renderColumns: ->
         _.map @props.visibleColumns, (config, index) =>
            headerClass = EverTrue.classSet(config.className, "is-sticky": config.sticky)
            last_item = @props.resizeLastColumn && index == (@props.visibleColumns.length - 1)

            size_styles =
               width: if last_item then "auto" else config.width
               minWidth: if last_item && config.width != "auto" then (@props.columnWidth || 175)
               maxWidth: (config.width || @props.columnWidth || 175)

            sort = @props.sortData || {}

            sort_classes = EverTrue.classSet
               "fa table-sort--icon": true
               "fa-sort-up": sort.sortProp == config.prop && !sort.sortReverse
               "fa-sort-down": sort.sortProp == config.prop && sort.sortReverse
               "fa-unsorted": sort.sortProp != config.prop

            sortable = _.isFunction(@props.onSort) && config.prop
            th className: headerClass, style: size_styles, key: (config.key || config.prop || index),
               if sortable
                  LinkButton title: "Sort on #{config.label}", onClick: (=> @props.onSort?(config.prop)),
                     span className: "paged-col-table--header-label", config.label
                     i className: sort_classes
                     config.help_tip if config.help_tip?
               else
                  span className: "ellipsis-overflow", config.label

               @renderPrevious(index)
               @renderNext(index)

      render: ->
         thead className: "paged-col-table--header table--header",
            tr null, @renderColumns()
