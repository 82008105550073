import getGoogleApi from "apps/search/utils/get-google-api";

const google = getGoogleApi();
const DEFAULT_CENTER = { lat: 40.245992, lng: -96.502414 };

const createMap = (div, setMap, mapSettings = { center: DEFAULT_CENTER, zoom: 3 }) => {
  const MIN_ZOOM = 3;
  const MAX_ZOOM = 17;
  const { center, zoom } = mapSettings;

  if (!div) return;

  
  const map = new google.Map(div, {
    center: center,
    mapTypeId: google.MapTypeId.ROADMAP,
    zoom: zoom,
    maxZoom: MAX_ZOOM,
    minZoom: MIN_ZOOM,
    disableDefaultUI: true,
    gestureHandling: "cooperative",
  });

  google.event.addListenerOnce(map, "idle", () => {
    setMap(map);
  });
};

export default createMap;
