_ = require("underscore").default
React = require("react")
{createComponent} = require("@evertrue/et-flux")
{object, func} = ReactLibs.PropTypes
moment = require("moment")
React = require("react")
{createFactory} = require("base/new-utils")
CalendarDropdown = createFactory(require("components/controls/calendar-dropdown"))
RangeDropdown = require("components/controls/range-dropdown")
FiltersBlock = require("apps/filters/components/filters-block")
AdvancedCombobox = createFactory(require("@evertrue/et-components").AdvancedCombobox)

# enabling a particular filter property should only be needed
# during development of the event filters epic.
_msubtract = (n, string) ->
   moment().subtract(n, string).format("YYYY-MM-DD")

BOOL_DROPDOWN_OPTIONS = [
               {label: "N/A", value: "any"},
               {label: "Yes", value: "yes"},
               {label: "No", value: "no"},
            ]

renderOptions = ->
   today = moment().format("YYYY-MM-DD")

   [
      {title: "In Past 7 Days", date_from: _msubtract(7, "days"), date_to: today}
      {title: "In Past Month", date_from: _msubtract(1, "months"), date_to: today}
      {title: "In Past 3 Months", date_from: _msubtract(3, "months"), date_to: today}
      {title: "In Past Year", date_from: _msubtract(1, "years"), date_to: today}
      {title: "Any Date", id: "all-time"}
   ]

getSubSearchConfig = (category) ->
   label = category || "Categorized"
   [{
      label: "Last #{label} Gift Date"
      key: "last_gift_date"
      render: ({value, onChange}) ->
         {gte, lte} = value || {}
         CalendarDropdown
            date_range: renderOptions()
            date_from: gte
            date_to: lte
            onChange: (date_from, date_to) ->
               onChange(_.compactObject(gte: date_from, lte: date_to))
   },{
      label: "Total #{label} Giving"
      key: "lifetime_amount"
      render: ({value, onChange}) ->
         {gte, lte} = value || {}
         RangeDropdown
            gte: gte
            lte: lte
            isCurrency: true
            onChange: ({gte, lte} = {}) ->
               onChange _.compactObject({gte, lte})
   },{
      label: "#{label} Pledge Balance"
      key: "total_pledge_balance"
      gated: "imported_pledge_balance"
      render: ({value, onChange}) ->
         {gte, lte} = value || {}
         RangeDropdown
            gte: gte
            lte: lte
            isCurrency: true
            onChange: ({gte, lte} = {}) ->
               onChange _.compactObject({gte, lte})

   },{
      label: "Recurring #{label} Payment?"
      key: "has_recurring_payments"
      gated: "recurring_payment_field"
      render: ({value, onChange}) ->
         if !value
            current_val = BOOL_DROPDOWN_OPTIONS[0]
         else
            current_val = _.findWhere BOOL_DROPDOWN_OPTIONS, {value: value}

         AdvancedCombobox
            options: BOOL_DROPDOWN_OPTIONS
            value: current_val
            onChange: (val) ->
               if val.value is "any" then onChange null
               else onChange val.value
   }]

module.exports =
   createComponent "CategorizedGivingFiltersBlock",
      propTypes:
         input: object
         state: object
         onChange: func # args: (null, value) # filters config format

      handleChange: (key, value) ->
         current_value = @props.input?.value
         combined_value = _.extend({}, current_value, _.toObject(key, value))
         filtered_value = _.pick(combined_value, _.notEmpty)
         @props.onChange(null, filtered_value)

      renderFilters: ->
         {input, onChange} = @props
         category = input.value?.category

         shown_filters = getSubSearchConfig(category)

         _.map shown_filters, ({key, label, enabled, gated, render}) =>
            value = input?.value?[key]
            onChange = (val) => @handleChange(key, val)
            { enabled, gated, label, component: render?({value, onChange}) }

      render: ->
         FiltersBlock
            filters: @renderFilters()
