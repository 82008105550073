module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   {div, span, h1} = ReactLibs.DOMFactories
   {createComponent} = require("@evertrue/et-flux")
   {createFactory} = require("base/new-utils")
   Datalist = createFactory require("components/elements/datalist")
   ModalTrigger = createFactory require("components/modals/modal-trigger")
   ModalTrigger2 = createFactory require("@evertrue/et-components").ModalTrigger2
   ButtonTooltip = createFactory require("@evertrue/et-components").ButtonTooltip
   ModalConfirm = createFactory require("components/modals/modal-confirm")
   Modal = createFactory require("components/modals/modal")
   ContactAssignee = require("apps/profile/components/templates/contact-assignee")
   TopFieldsSelector = require("apps/profile/components/top-fields-selector")
   EmptyStateMessage = createFactory(require("components/lists/empty-state-message"))
   ProfileCardHeader = createFactory require("apps/profile/components/profile-card-header").default
   ProfileCardSubtitle = createFactory require("apps/profile/components/profile-card-subtitle").default
   Icon = createFactory (require("components/elements/icon").default)
   Tooltip = require("components/overlays/tooltip")
   TopFieldsStore = require("apps/profile/stores/top-fields-store")
   FeatureStore = require("apps/layout/stores/feature-store").default
   DonorInfoAll = require("apps/profile/components/donor-info-all")
   AssignContactModal = require("apps/contact/components/profile/assign-contact-modal")
   RmAssignContactModal = createFactory require("apps/contact/components/profile/rm-assign-contact-modal").default
   Gated = createFactory require("components/gated")
   Assignment = createFactory require("apps/profile/components/templates/assignment").default
   VolunteerAssignment = createFactory require("apps/profile/components/templates/volunteer-assignment").default
   IdentityResolver = createFactory(require("base/identity-resolver").default)

   createComponent "DonorInfo",
      propTypes:
         contact: ReactLibs.PropTypes.object
         totalAssignees: ReactLibs.PropTypes.array

      registerStores: ->
         @on TopFieldsStore, ->
            top_contact_data: TopFieldsStore.getTopForContact(@props.contact)
            has_editable_fields: TopFieldsStore.hasEditableFields()

         @on FeatureStore, ->
            has_rm_assignments: FeatureStore.hasFeature("rm_assignments")

      UNSAFE_componentWillReceiveProps: (newProps) ->
         if !_.isEqual(newProps.contact, @props.contact)
            @setState {top_contact_data: TopFieldsStore.getTopForContact(newProps.contact)}

      renderCustomizeModal: (custom_fields) ->
         Modal width: 660, animationClass: false,
            TopFieldsSelector()

      renderAllModal: ->
         Modal width: 480,
            ModalConfirm header: "Donor Info",
               div className: "donor-info",
                  DonorInfoAll(_.extend({}, @props))

      renderTitle: (title, count) ->
         h1 className: "donor-info--title", style: { fontSize: '14px', display: 'inline' },
            title
            if count > 0
               ModalTrigger modal: @renderAllModal(), title: "View #{count} More",
                  span className: "donor-info--title-more",
                     if count then " (+#{count} more)" else ""

      render: ->
         first_assignee = _.first(@props.totalAssignees)

         div className: "donor-info",
            ProfileCardHeader title: "Donor Info",
               ModalTrigger
                  title: "View All Donor Info"
                  className: "pull-right"
                  modal: @renderAllModal()
                  "View All"

            # ASSIGNEE
            div className: "donor-info--section",
               ProfileCardSubtitle label: @renderTitle("Assigned To", @props.totalAssignees?.length - 1),
                  IdentityResolver null,
                     ({ isOwner, isSuperUser }) =>
                        div null,
                           if (isOwner || isSuperUser) && !@state.has_rm_assignments
                              Gated feature: "write_assignee", el: "span",
                                 Tooltip
                                    className: "donor-info--tooltip"
                                    type: "dark"
                                    width: 84
                                    position: "top"
                                    content: "Edit",
                                    children: ({open, close}) =>
                                       ModalTrigger modal: Modal(width: 666, keepOpen: true, AssignContactModal(contact: @props.contact)), title: "Edit Assignee", onFocus: open, onBlur: close,
                                          Icon icon: "edit"

                           if (isOwner || isSuperUser) && @state.has_rm_assignments
                              ModalTrigger2
                                 closeOnOutsideClick: false
                                 trigger: ({ open }) ->
                                    ButtonTooltip
                                       className: "donor-info--tooltip"
                                       onTriggerClick: open
                                       trigger: Icon icon: "edit"
                                       children: "Edit"
                                 children: ({ close }) =>
                                    RmAssignContactModal
                                       close: close
                                       contact: @props.contact
                                       assignments: @props.totalAssignees

               if _.isEmpty(@props.totalAssignees)
                  EmptyStateMessage
                     icon: "person"
                     size: "small"
                     "Constituent not assigned"
               else
                  if first_assignee.is_assignment
                     Assignment assignment: first_assignee
                  else if first_assignee.is_volunteer_assignment
                     VolunteerAssignment assignment: first_assignee
                  else
                     ContactAssignee data: first_assignee

            # TOP FIELDS
            div className: "donor-info--section",
               ProfileCardSubtitle label: "My Top Fields",
                  ModalTrigger modal: @renderCustomizeModal(), title: "Edit donor info",
                     if @state.has_editable_fields
                        Tooltip
                           className: "donor-info--tooltip"
                           type: "dark"
                           width: 84
                           position: "top"
                           content: "Customize"
                           Icon icon: "settings"

               _.map @state.top_contact_data, (field) ->
                  Datalist label: field.label, key: field.key,
                     if _.isEmpty(field.value) then span className: "donor-info--empty-field", "Not provided"
                     else _.map field.value, (val) -> div key: val, span className: "fs-exclude-data", val
