module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   {createStore} = require("@evertrue/et-flux")
   LayoutSource = require("apps/layout/sources/layout-source")

   # Store for managing overlays (like popovers and tooltips)
   # Separated from LayoutStore to prevent unnecessary renders
   # which had caused performance issues.
   createStore "OverlayStore",
      getInitialState: ->
         overlays: []

      registerActions: ->
         @on LayoutSource.actions.overlay, (component, properties) ->
            overlays = @getState("overlays")
            if selected = _.findWhere(overlays, {key: properties.key})
               _.extend selected, component: component, position: properties
            else
               overlays.push
                  component: component
                  position: properties
                  key: properties.key || _.randomKey()
            @setState {overlays: overlays}

         @on LayoutSource.actions.removeOverlay, (key) ->
            # Remove all overlays if key is not defined
            overlays = _.filter @getState("overlays"), (overlay) ->
               if _.isUndefined(key) then false else overlay.key != key
            @setState {overlays: overlays}

      api:
         getOverlays: ->
            @getState("overlays")
