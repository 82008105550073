import moment from "moment";
import numeral from "numeral";
import PropTypes from "prop-types";
import { Checkbox, Icon, Link, Card } from "@evertrue/et-components";
import Avatar from "components/elements/avatar";
import ProspectCardMenu from "apps/portfolio-performance/components/prospect-card-menu";
import { getFormattedTimeInStage } from "apps/portfolio-performance/portfolio-utils";
import { useGate } from "components/is-gated";
import EllipsisOverflow from "components/formatting/ellipsis-overflow"
import SelectedContactsSource from "apps/contact/sources/selected-contacts-source";

const ProspectCard = (props) => {
  const { contact = {}, assignment = {}, stage = false, selected, team = {}, lastInteractionDate } = props;
  const contact_giving = contact.giving || {};
  const last_gift_date = contact_giving.last_gift_date
    ? moment(contact_giving.last_gift_date, "YYYY-MM-DD").format("MMM DD, YYYY")
    : null;
  const lifetime_value = contact_giving.lifetime_amount || 0;

  const formatted_stage_start_date = getFormattedTimeInStage(assignment);

  const [rmKeyProspectEnabled] = useGate("rm_key_prospect");
  const [hasProposalReads] = useGate("proposal_reads");

  return (
    <Card className="portfolio-performance--card" hoverTransform="draggable" cardSize="small">
      <div className="portfolio-performance--card-top">
        <div className="portfolio-performance--card-top-left">
          <Checkbox
            className="portfolio-performance--card-checkbox"
            checked={selected}
            onChange={() => {
              SelectedContactsSource.toggleSelection(contact.id, !selected)
              props.onSelectContact(contact.id)}
            }
            label={`Select prospect ${contact.name}`}
          />
          <Link title={`Link to ${contact.name}'s profile`} href={`/contact/${contact.id}`}>
            <span className="portfolio-performance--card-name fs-exclude-data">{contact.name}</span>
          </Link>
        </div>
        <ProspectCardMenu
          onSetLoading={props.onSetLoading}
          updateProspectWithAssignmentsByStage={props.updateProspectWithAssignmentsByStage}
          contact={contact}
          team={team}
          assignment={assignment}
          stage={stage}
        />
      </div>

      <div className="portfolio-performance--card-content">
        <div className="portfolio-performance--card-avatar-wrap">
          {assignment.proposal_count > 0 && hasProposalReads ? (
            <Icon icon="proposal" className="portfolio-performance--card-proposal-icon" />
          ) : null}
          <Avatar
            className="portfolio-performance--card-avatar fs-exclude-data"
            name={contact.name || "unknown contact"}
            url={contact.avatar}
          />
        </div>
        <div>
          <div>
            <span className="portfolio-performance--card-content-label">Lifetime Giving: </span>
            {numeral(lifetime_value).format("$0,0")}
          </div>
          <div>
            <span className="portfolio-performance--card-content-label">Last Gift Date: </span>
            {last_gift_date ? last_gift_date : "-"}
          </div>

          <div>
            <span className="portfolio-performance--card-content-label">Last Contact: </span>
            {lastInteractionDate ? moment(lastInteractionDate).fromNow() : "-"}
          </div>

          <div>
            <span className="portfolio-performance--card-content-label">Time in Stage: </span>
            {formatted_stage_start_date}
          </div>
        </div>
      </div>

      {(assignment.assignment_title || assignment.key_prospect) && (
        <div className="portfolio-performance--card-footer">
          <div className="portfolio-performance--card-footer-left">
            {assignment.assignment_title && <EllipsisOverflow>{assignment.assignment_title}</EllipsisOverflow>}
          </div>
          <div className="portfolio-performance--card-footer-right">
            {rmKeyProspectEnabled && assignment.key_prospect && (
              <Icon icon="key-relationship" className="portfolio-performance--card-key-prospect-icon" />
            )}
          </div>
        </div>
      )}
    </Card>
  );
};

ProspectCard.propTypes = {
  team: PropTypes.object,
  stage: PropTypes.string,
  contact: PropTypes.object,
  assignment: PropTypes.object,
  lastInteractionDate: PropTypes.number,
  selected: PropTypes.bool,
  onSelectContact: PropTypes.func,
  onSetLoading: PropTypes.func,
  updateProspectWithAssignmentsByStage: PropTypes.func,
};

export default ProspectCard;
