import PropTypes from "prop-types";
import { connect } from "@evertrue/et-flux";
import FeatureStore from "apps/layout/stores/feature-store";
import ListStore from "apps/lists/stores/list-store";
import ProfileStore from "apps/profile/stores/profile-store";
import MembershipStore from "apps/profile/stores/membership-store";
import ListContactSource from "apps/lists/sources/list-contact-source";
import { ActionsMenu, Modal, ModalHeader } from "@evertrue/et-components";
import ListSelectorController from "apps/lists/controllers/lists-selector-controller";
import TripsSelectorController from "apps/my-trips/controllers/trips-selector-controller";
import InteractionForm from "apps/interactions/components/create/interaction-form";
import ProposalFormLegacyModalWrapper from "apps/profile/components/proposal-form-legacy-modal-wrapper";
import CDCModalController from "apps/profile/components/cdc/cdc-modal-controller";
import FacebookMatchManage from "apps/facebook/components/facebook-match-manage";
import AiProfileChatDrawer from "components/drawer/ai-profile-chat-drawer";
import colors from "@evertrue/et-variables/src/styles/variables.scss";
import AddToJourneyModal from "apps/journeys/components/modals/add-to-journey-modal";
import RemoveFromJourneyModal from "apps/journeys/components/modals/remove-from-journey-modal";
import { useIdentity } from "base/identity-resolver";
import { mapContactObjectToGraphqlShape } from "apps/journeys/utils/helpers/utils";

const mapStateToProps = ({ contact = {} }) => ({
  hasTrips: FeatureStore.hasFeature("my_trips"),
  hasInteractionWrites: FeatureStore.hasInteractionWrites(),
  hasCdcProfile: FeatureStore.hasFeature("cdc_profile"),
  hasProposalWrites: FeatureStore.hasFeature("proposal_writes"),
  favorite: ListStore.getFavoriteList(),
  favoriteMembership: !!MembershipStore.getFavoriteMembership(contact.id),
  hasFbMatch: ProfileStore.hasFacebookMatch(contact.id),
  hasAI: FeatureStore.hasFeature("ai_profile_chat"),
  hasCadences: FeatureStore.hasFeature("cadences"),
});

const ProfileActionsMenu = ({
  contact = {},
  afterProposalSave = () => { },
  hasTrips = false,
  hasInteractionWrites = false,
  hasCdcProfile = false,
  hasProposalWrites = false,
  favorite = {},
  favoriteMembership = false,
  hasFbMatch = false,
  hasAI = false,
  activeJourneys = [],
  hasCadences = false,
  refreshProfile = () => { },
}) => {
  const {
    user: { id: currentUserId },
  } = useIdentity();

  const handleFavorite = () => {
    const id = contact.id;
    const options = { active_contact_id: id };
    if (favoriteMembership && favorite.id) {
      ListContactSource.removeContacts(favorite.id, [id], options);
    } else {
      ListContactSource.addContacts(favorite.id, [id], options);
    }
  };

  return (
    <ActionsMenu
      options={[
        ...(hasAI
          ? [
            {
              id: 8,
              icon: "ai-chat",
              iconKit: "gt2",
              iconStyle: { color: colors.purple500 },
              label: "AI-Profile Chat",
              mountOnClick: ({ is_open, close }) => {
                return (
                  <AiProfileChatDrawer
                    contactId={contact.id}
                    is_open={is_open}
                    close={close}
                    drawerTitle="AI-Profile Chat"
                  />
                );
              },
            },
          ]
          : []),
        {
          id: 1,
          icon: "playlist-add",
          label: "Add Constituent to List",
          mountOnClick: ({ is_open, close }) => (
            <Modal isOpen={is_open} closeModal={close} size="small">
              <ModalHeader title="Add Constituent to List" closeModal={close} />
              <ListSelectorController contactIds={[contact.id]} membership={contact.id} requestHide={close} />
            </Modal>
          ),
        },
        ...(hasTrips
          ? [
            {
              id: 2,
              icon: "my-trips",
              label: "Add Constituent to Trip",
              mountOnClick: ({ is_open, close }) => (
                <Modal isOpen={is_open} closeModal={close} size="small">
                  <ModalHeader title="Add Constituent to Trip" closeModal={close} />
                  <TripsSelectorController contactIds={[contact.id]} membership={contact.id} requestHide={close} />
                </Modal>
              ),
            },
          ]
          : []),
        {
          id: 3,
          icon: "edit",
          label: hasInteractionWrites ? "Add Interaction" : "Add Comment",
          mountOnClick: ({ is_open, close }) => (
            <Modal isOpen={is_open} closeModal={close}>
              <InteractionForm contact={contact} referrer="profile_action_menu" requestHide={close} />
            </Modal>
          ),
        },
        ...(hasProposalWrites
          ? [
            {
              id: 4,
              icon: "proposal",
              label: "Add Proposal",
              mountOnClick: ({ is_open, close }) => (
                <Modal isOpen={is_open} closeModal={close}>
                  <ProposalFormLegacyModalWrapper
                    primaryContactId={contact.id}
                    referrer={"profile_action_menu"}
                    afterCreate={afterProposalSave}
                    requestHide={close}
                  />
                </Modal>
              ),
            },
          ]
          : []),
        {
          id: 5,
          icon: "star",
          label: favoriteMembership ? "Remove from Favorites" : "Favorite",
          onClick: handleFavorite,
        },
        ...(hasCdcProfile
          ? [
            {
              id: 6,
              icon: "upload-contact",
              label: "Suggest Contact Update",
              mountOnClick: ({ is_open, close }) => (
                <Modal isOpen={is_open} closeModal={close} size="large">
                  <CDCModalController contact={contact} onClose={close} referrer="profile_action_menu" />
                </Modal>
              ),
            },
          ]
          : []),

        ...(hasFbMatch
          ? [
            {
              id: 7,
              icon: "link",
              label: "Manage Facebook Page Match",
              mountOnClick: ({ is_open, close }) => (
                <Modal isOpen={is_open} closeModal={close}>
                  <FacebookMatchManage contact={contact} isFbMatched={hasFbMatch} requestHide={close} />
                </Modal>
              ),
            },
          ]
          : []),

        ...(hasCadences && activeJourneys.length < 5
          ? [
            {
              id: 9,
              icon: "add",
              label: "Add to Cadence",
              mountOnClick: ({ is_open, close }) => (
                <AddToJourneyModal
                  contact={contact}
                  onClose={close}
                  isOpen={is_open}
                  activeJourneys={activeJourneys}
                  refreshProfile={refreshProfile}
                />
              ),
            },
          ]
          : []),
        ...(hasCadences && activeJourneys.some(({ owner_user_id }) => currentUserId === owner_user_id)
          ? [
            {
              id: 10,
              icon: "cancel-circle",
              label: "Remove from Cadence",
              mountOnClick: ({ is_open, close }) => (
                <RemoveFromJourneyModal
                  onClose={close}
                  isOpen={is_open}
                  contact={mapContactObjectToGraphqlShape(contact)}
                  currentJourneys={activeJourneys.filter(({ owner_user_id }) => currentUserId === owner_user_id)}
                  refreshProfile={refreshProfile}
                />
              ),
            },
          ]
          : []),
      ]}
    />
  );
};

ProfileActionsMenu.propTypes = {
  contact: PropTypes.object,
  afterProposalSave: PropTypes.func,
  hasTrips: PropTypes.bool,
  hasInteractionWrites: PropTypes.bool,
  hasCdcProfile: PropTypes.bool,
  hasProposalWrites: PropTypes.bool,
  favorite: PropTypes.object,
  favoriteMembership: PropTypes.bool,
  hasFbMatch: PropTypes.bool,
  hasAI: PropTypes.bool,
  activeJourneys: PropTypes.array,
  hasCadences: PropTypes.bool,
  refreshProfile: PropTypes.func,
};

export default connect(ProfileActionsMenu, [FeatureStore, ListStore, MembershipStore, ProfileStore], mapStateToProps);
