import PropTypes from "prop-types";
import moment from "moment";
import FacebookReactionsUtils from "apps/facebook/helpers/facebook-reactions-utils";
import { Link } from "@evertrue/et-components";

const RecentEngagement = ({ engagement = {} }) => {
  const date = moment(new Date(engagement.recent_engagement.engagement_date)).format("MM/DD/YYYY");
  const type = engagement.recent_engagement.type;

  return (
    <div className="recent-engagement">
      {engagement.recent_engagement ? (
        <>
          <img
            src={FacebookReactionsUtils.getIconFor(type)}
            alt={`Facebook ${type}`}
            height="18"
            width="18"
            style={{ marginRight: 10 }}
          />
          <div>
            {date}
            <Link
              href={engagement.recent_engagement.url}
              style={{ display: "block" }}
              target="_blank"
              title="View Post"
            >
              View Post
            </Link>
          </div>
        </>
      ) : null}
    </div>
  );
};

RecentEngagement.propTypes = {
  engagement: PropTypes.object,
};

export default RecentEngagement;
