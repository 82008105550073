import _ from "underscore";
import { Component } from "react";
import PageHeader from "apps/layout/components/page-header";
import InviteTable from "apps/settings/invite-team-settings/components/invite-table";
import InviteTableSource from "apps/settings/invite-team-settings/sources/invite-team-source";
import InviteTeamStore from "apps/settings/invite-team-settings/stores/invite-team-store";
import { connect } from "@evertrue/et-flux";
import Module from "components/module";
import { Modal, ModalHeader, ModalBody } from "@evertrue/et-components";

const errorMsg = (status, message = "Invalid Input") => ({ status, message });

const TABLE_CONFIG = [
  {
    label: "First Name",
    placeholder: "First Name",
    type: "text",
    key: "first_name",
    validator: (value) => {
      if (value.length > 0) {
        return errorMsg("VALID");
      }
      return errorMsg("ERROR", "Enter a first name");
    },
  },
  {
    label: "Last Name",
    placeholder: "Last Name",
    type: "text",
    key: "last_name",
    validator: (value) => {
      if (value.length > 0) {
        return errorMsg("VALID");
      }
      return errorMsg("ERROR", "Enter a last name");
    },
  },
  {
    label: "Email",
    placeholder: "E.g. jon@evertrue.com",
    type: "email",
    key: "email",
    validator: (value) => {
      if (_.validateEmail(value)) {
        return errorMsg("VALID");
      }
      return errorMsg("ERROR", "Enter a valid email address");
    },
    platformErrorCode: 105,
  },
  {
    label: "UID",
    placeholder: "E.g. 808818807",
    type: "text",
    key: "saml_user_id",
    validator: (value) => {
      if (value.length > 0) {
        return errorMsg("VALID");
      }
      return errorMsg("ERROR", "Enter a user ID");
    },
    platformErrorCode: 106,
  },
  {
    label: "Role",
    placeholder: "User",
    type: "dropdown",
    options: ["User", "Owner"],
    key: "role_name",
    inlineError: "A role must be selected",
    validator: (value) => {
      if (value === "Owner" || value === "User") {
        return errorMsg("VALID");
      }
      return errorMsg("ERROR", "Select a role");
    },
  },
];

const DEFAULT_ROLE = "User";

const NUM_OF_ROWS = 4;

const notEmpty = (row) => {
  const filteredRow = _.filter(TABLE_CONFIG, ({ key }) => key !== "role_name");
  return _.some(filteredRow, ({ key }) => row[key]);
};

const isValid = (row) =>
  _.every(TABLE_CONFIG, ({ key, validator }) => {
    const value = row[key];
    return validator ? validator(value).status === "VALID" : true;
  });

const mapStateToProps = () => ({
  errors: InviteTeamStore.getErrorMessages(),
  showSupportModal: InviteTeamStore.getSupportError(),
  // formSubmitted is when all entries in the form have been successfully submitted and the table is cleared out
  formSubmitted: InviteTeamStore.getIsFormSubmitState(),
  bulkSuccessfulInvites: InviteTeamStore.getSuccessfulInvites(),
  isLoading: InviteTeamStore.getIsLoadingState(),
});

class InviteTeamController extends Component {
  static propTypes = {
    errors: ReactLibs.PropTypes.array,
    bulkSuccessfulInvites: ReactLibs.PropTypes.array,
    isLoading: ReactLibs.PropTypes.bool,
    formSubmitted: ReactLibs.PropTypes.bool,
    showSupportModal: ReactLibs.PropTypes.bool,
  };

  static defaultProps = {
    errors: [],
    bulkSuccessfulInvites: [],
    isLoading: false,
    formSubmitted: false,
  };

  state = {
    tableData: this.getRowSet(NUM_OF_ROWS),
    errors: this.props.errors,
    bulkSuccessfulInvites: this.props.bulkSuccessfulInvites,
    showModal: false,
  };

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.formSubmitted, prevProps.formSubmitted) && this.props.formSubmitted === true) {
      this.setState({
        tableData: this.getRowSet(NUM_OF_ROWS),
        errors: this.props.errors,
        bulkSuccessfulInvites: this.props.bulkSuccessfulInvites,
      });
    }

    if (!_.isEqual(this.props.bulkSuccessfulInvites, prevProps.bulkSuccessfulInvites)) {
      if (this.props.bulkSuccessfulInvites.length > 0) {
        this.setState({
          tableData: _.map(this.props.errors, (error) => error.invitation),
          bulkSuccessfulInvites: this.props.bulkSuccessfulInvites,
        });
      }
    }

    if (!_.isEqual(this.props.errors, prevProps.errors) && !_.isEqual(this.props.errors, [])) {
      this.setState({ tableData: _.map(this.props.errors, (error) => error.invitation), errors: this.props.errors });
    }

    if (!_.isEqual(this.props.showSupportModal, prevProps.showSupportModal)) {
      this.setState({ showModal: this.props.showSupportModal });
    }
  }

  componentWillUnmount() {
    InviteTableSource.clearErrors();
  }

  getRowSet(numRows) {
    return _.map(_.range(0, numRows), (i) => this.getEmptyRow(i));
  }

  getEmptyRow() {
    return { first_name: "", last_name: "", email: "", saml_user_id: "", role_name: DEFAULT_ROLE };
  }

  getNonEmptyRows() {
    return this.state.tableData.filter(notEmpty);
  }

  handleChange = (value, key, index) => {
    const tableData = this.state.tableData;
    tableData[index][key] = value;
    this.setState({ tableData });
  };

  handleSubmit = () => {
    const validRows = this.getNonEmptyRows();
    const payload = {};
    if (validRows.length === 1) {
      payload.affiliation_invitation = validRows[0];
      InviteTableSource.sendInvite(payload);
    } else if (validRows.length > 1) {
      payload.affiliation_invitations = validRows;
      InviteTableSource.sendInvites(payload);
    }
  };

  handleClear = () => {
    InviteTableSource.clearErrors();
    const tableData = this.getRowSet(NUM_OF_ROWS);
    this.setState({ tableData, errors: [], bulkSuccessfulInvites: [] });
  };

  handleAddMore = () => {
    const newRowData = this.getRowSet(NUM_OF_ROWS);
    this.setState({ tableData: [...this.state.tableData, ...newRowData] });
  };

  renderSupportModal() {
    return (
      <Modal
        isOpen={this.state.showModal}
        closeModal={() => this.setState({ showModal: false })}
        closeOnOutsideClick={true}
      >
        <ModalHeader
          title="Oops! There was a problem processing your request"
          closeModal={() => this.setState({ showModal: false })}
        />
        <ModalBody>
          Please contact <a href="mailto:genius@evertrue.com">genius@evertrue.com</a>
        </ModalBody>
      </Modal>
    );
  }

  render() {
    const nonEmptyRows = this.getNonEmptyRows();
    const canSubmit = nonEmptyRows.length > 0 && _.every(nonEmptyRows, isValid);
    return (
      <div>
        {this.renderSupportModal()}
        <PageHeader back title="Invite Team Members" backButtonHref="/settings/manage_team" />
        <div className="invite fixed-page-wrapper">
          <Module header="Send invite by email">
            <InviteTable
              tableConfig={TABLE_CONFIG}
              tableData={this.state.tableData}
              numInvites={nonEmptyRows.length}
              canSubmit={canSubmit}
              blockErrors={this.state.errors}
              bulkSuccessfulInvites={this.state.bulkSuccessfulInvites}
              isLoading={this.props.isLoading}
              formSubmitted={this.props.formSubmitted}
              onChange={this.handleChange}
              onClear={this.handleClear}
              onAdd={this.handleAddMore}
              onSubmit={this.handleSubmit}
            />
          </Module>
        </div>
      </div>
    );
  }
}

export default connect(InviteTeamController, [InviteTeamStore], mapStateToProps);
