module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   EverTrue = require("app")
   {createStore} = require("@evertrue/et-flux")
   NotificationSource = require("apps/notifications/sources/notification-source")
   ExportStore = require("apps/export/stores/export-store")
   ListSource = require("apps/lists/sources/list-source")
   SocketSource = require("apps/notifications/sources/socket-source")
   AllUsersStore = require("apps/users/stores/all-users-store").default
   AllUsersSource = require("apps/users/sources/all-users-source")

   createStore "NotificationStore",
      getInitialState: ->
         notifications: []
         unread_count: undefined
         comments: []
         notification_contacts: {}
         loading_activity: false
         mentioned_contacts: {}

      firstListenerDidMount: ->
         SocketSource.bindUser("refresh_inbox")

      registerActions: ->
         @on NotificationSource.actions.fetchedNotifications, @respondToFetched
         @on NotificationSource.actions.updatedNotification, @respondToUpdatedNotification
         @on NotificationSource.actions.updatedCount, @respondToCountUpdate

         @on NotificationSource.actions.fetchedContacts, @respondToContacts
         @on NotificationSource.actions.fetchedMentionedContacts, @respondToMentionedContacts
         @on SocketSource.actions.refresh_inbox, -> NotificationSource.fetch()

      respondToFetched: (notifications) ->
         recent_cached = _.first(@getState("notifications"))
         recent_new = _.first(notifications)
         hasChanged = recent_cached?.id != recent_new?.id

         if hasChanged || !recent_cached
            @setState {notifications: notifications}

            hasListChange = _.some notifications, (noti) ->
               activity = noti.activity
               is_list_note = activity?.type == "mention" && activity?.content?.note?.target_type == "LIST"
               !noti.read_at && (activity?.type == "list" || is_list_note)
            ListSource.fetch() if hasListChange

         mentions = _.filter notifications, (noti) ->
            noti.activity?.type == "mention"
         @downloadMentionData(mentions)

      respondToCountUpdate: (count) ->
         EverTrue.execute "setTitleCount", count
         @setState {unread_count: count}

      respondToUpdatedNotification: (notification) ->
         notifications = @getNotifications()
         noti = _.findWhere notifications, {id: notification.id}
         if noti
            $.extend true, noti, notification
            @setState {notifications: notifications}

      respondToComments: (comments) ->
         notifications = @getNotifications()
         _.map notifications, (noti) ->
            comment = _.findWhere comments, {id: noti?.activity?.content?.note?.id}
            noti?.activity?.content?.note = comment if comment
            noti
         @setState {notifications: notifications}

      respondToContacts: (notification_id, contacts_resp) ->
         contacts = _.clone @getState("notification_contacts")
         contacts[notification_id] = contacts_resp
         @setState {notification_contacts: contacts}

      respondToMentionedContacts: (contacts) ->
         cached_contacts = _.clone @getState("mentioned_contacts")
         notifications = _.cloneData @getState("notifications")
         _.each contacts.items, (contact) ->
            cached_contacts[contact.id] = contact
         @setState {mentioned_contacts: cached_contacts}

      downloadMentionData: (mentions) ->
         contact_ids = []
         user_ids = []

         _.each mentions, (mention) ->
            note = mention.activity?.content?.note

            _.each note.user_mention, (user_mention) ->
               if user_mention.user_id
                  user_ids.push(user_mention.user_id)
            _.each note.contact_mention, (contact_mention) ->
               if contact_mention.contact_id
                  contact_ids.push(contact_mention.contact_id)

            # TODO - remove after format has been updated on platform
            if note.mentions
               _.each note.mentions, (mention) ->
                  if mention.mentioned_user_id
                     user_ids.push mention.mentioned_user_id
                  if mention.mentioned_contact_id
                     contact_ids.push mention.mentioned_contact_id

         cached_contacts = _.map _.keys(@getState("mentioned_contacts")), _.toNumber
         new_contacts = _.difference _.compact(_.uniq(contact_ids)), cached_contacts
         NotificationSource.fetchMentionedContacts(new_contacts) unless _.isEmpty(new_contacts)
         AllUsersSource.fetchUsers(user_ids)

      getFormattedMention: (note) ->
         users =  AllUsersStore.getAllUsers()
         contacts = @getState("mentioned_contacts")

         mentions = _.flatten([note.contact_mention, note.user_mention])
         mentions = _.map mentions, (mention) ->
            if mention?.user_id
               _.extend {}, mention, data: users[mention.user_id]
            else if mention?.contact_id
               _.extend {}, mention, data: contacts[mention.contact_id]

         # TODO: old format - will be need to be removed once no longer returned
         # in this format from the backend
         if note.mentions
            mentions = _.map note.mentions, (mention) ->
               if mention.mention_type is "contact"
                  mention.type = "contact"
                  mention.contact_id = mention.mentioned_contact_id
                  mention.data = contacts[mention.mentioned_contact_id]
               else if mention.mention_type is "user"
                  mention.type = "user"
                  mention.user_id = mention.mentioned_user_id
                  mention.data = users[mention.mentioned_user_id]
               mention

         note.mentions = mentions if !_.isEmpty(mentions)
         note

      api:
         getNotifications: ->
            notifications = _.cloneData @getState("notifications")
            _.map notifications, (noti) =>
               noti = _.extend {}, noti, activity_type: @getActivityType(noti)
               if noti.activity?.type == "mention"
                  noti.activity?.content?.note = @getFormattedMention(noti.activity?.content?.note)
               noti

         getUnreadCount: ->
            @getState("unread_count")

         getContacts: (id) ->
            @getState("notification_contacts")?[_.toNumber(id)]

         getLoadingContacts: (id) ->
            _.isEmpty(@getState("notification_contacts")?[_.toNumber(id)])

         get: (id) ->
            _.findWhere @getNotifications(), {id: id}

         getActivity: (id) ->
            noti = @get(id)
            noti?.activity

         getActivityType: (noti) ->
            type = noti?.activity?.type
            type = "search_diff" if noti?.activity?.content?.search?.query
            type = "facebook" if noti?.message?.match("Facebook") && type == "search_query"
            type = "linkedin" if noti?.message?.toLowerCase()?.match("linkedin") && type == "search_query"
            type

         getActivityUrl: (id) ->
            noti = @get(id)
            return unless noti

            type = @getActivityType(noti) || "unavailable"
            content = noti.activity?.content

            switch type
               when "mention"
                  id = content?.note?.target_id || content?.note?.contact_id
                  if content.note && id
                     if content.note.target_type
                        switch content.note.target_type
                           when "LIST" then "/list/#{id}"
                           when "CONTACT" then "/contact/#{id}"
                     else if content.note.contact_id then "/contact/#{id}"
                     else if content.note.list_id then "/list/#{id}"

               when "list"
                  id = content?.list_id
                  "/list/#{id}" if id

               when "dynamic_list_v2"
                  id = content.links[0]?.id
                  "/dynamic-lists/#{id}" if id

               when "search_diff"
                  id = noti.activity?.id
                  "/searches/activity/#{id}" if id

               when "export"
                  id = content?.export_id
                  export_obj = ExportStore.getById(id)
                  export_obj?.download_link || "data/exports"

               when "share_search"
                  if content?.hash_id
                     "/contact/search/#{content.hash_id}?sharer_id=#{content.added_by_user_id}"
                  else false

               when "unavailable"
                  false
               when "rm_gift"
                  if Array.isArray(content.gifts)
                     "/portfolio-performance"
                  else
                     "/contact/#{content.contact_id}"

               else
                  if content?.search?.contact_ids?.length == 1
                     "/contact/#{_.first(content.search.contact_ids)}"
                  else "/notification/#{noti.id}"
