CategorizedGivingFiltersUtils = require("apps/filters/categorized-giving-filter/categorized-giving-filters-utils")
CategorizedGivingFiltersBlock = require("apps/filters/categorized-giving-filter/categorized-giving-filters-block")
CategorizedGivingParamSelector = require("apps/filters/categorized-giving-filter/categorized-giving-param-selector")


FACET = "giving_categories.label.untouched"

CategorizedGivingConfig =
   help: "categorized_giving_filters"
   facets: fields:[{value:FACET}]

   component: (input, state, onChange) ->

      CategorizedGivingParamSelector
         categories: state.facets?[FACET]
         input: input
         state: state
         onChange: onChange

   sub_component: (input, state, onChange) ->
      { category, param } = input?.value || {}

      if category and param in ["must", "must_not"]
         CategorizedGivingFiltersBlock
            input: input
            state: state
            onChange: onChange

   parse_fn: (criteria) ->
      CategorizedGivingFiltersUtils.parseCriteria(criteria)

   query_fn: (value) ->
      CategorizedGivingFiltersUtils.getQuery(value)


module.exports = CategorizedGivingConfig
