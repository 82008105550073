import { Icon } from "@evertrue/et-components";

const InteractionSubstantiveLabel = () => (
  <div>
    <Icon icon="mark" size={1} className="interaction-substantive--icon" />
    <span className="interaction-substantive--text">Substantive</span>
  </div>
);

export default InteractionSubstantiveLabel;
