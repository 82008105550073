import MaskedInput from "react-text-mask";
import PropTypes from "prop-types";

const propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
};

const ControlledMaskedInput = ({ onChange, value, ...rest }) => {
  return (
    <MaskedInput
      type="text"
      onChange={({ target: { value: inputVal } }) => {
        onChange(inputVal);
      }}
      value={value}
      guide={false}
      placeholderChar=" "
      {...rest}
    />
  );
};

ControlledMaskedInput.propTypes = propTypes;

export default ControlledMaskedInput;
