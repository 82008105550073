import { PropTypes } from "base/react-libs";
import { useEffect, useState } from "react";
import { updateTasksAfterTimeout } from "apps/home/utils/helpers";
import { BarChart, Bar, XAxis, Legend, Tooltip, LabelList } from "recharts";
import CustomLegend from "./custom-legend";
import CustomTooltip from "./custom-tooltip";

const DaysBarChart = ({ nextFiveDaysCounts = [], futureJourneyTimeout = null, inProgressJourneyTimeout = null }) => {
  const [journeysForTimeout, setJourneysForTimeout] = useState([]);

  useEffect(() => {
    const pause = futureJourneyTimeout || inProgressJourneyTimeout;
    if (nextFiveDaysCounts && nextFiveDaysCounts.length && pause) {
      setJourneysForTimeout(updateTasksAfterTimeout(nextFiveDaysCounts, pause.pause_start, pause.pause_end));
    }
  }, [nextFiveDaysCounts, futureJourneyTimeout, inProgressJourneyTimeout]);

  return (
    <BarChart
      width={500}
      height={300}
      data={futureJourneyTimeout || inProgressJourneyTimeout ? journeysForTimeout : nextFiveDaysCounts}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 30,
      }}
    >
      {nextFiveDaysCounts && nextFiveDaysCounts.length && (
        <XAxis dataKey="name" tickLine={false} tick={{ fill: "#454545" }} />
      )}
      <Tooltip content={<CustomTooltip />} />
      <Legend
        iconSize={0}
        layout="horizontal"
        verticalAlign="top"
        align="center"
        wrapperStyle={{ padding: "10px" }}
        formatter={CustomLegend}
      />
      <Bar dataKey="Emails" fill="#00C0F5" name="Emails" />
      <Bar dataKey="Calls" fill="#A54AB5" name="Calls">
        <LabelList dataKey="Total" position="bottom" style={{ fontWeight: "bold", transform: "translateY(35px)" }} />
      </Bar>
      <Bar dataKey="Other" fill="#454545" name="Other" />
    </BarChart>
  );
};

DaysBarChart.propTypes = {
  nextFiveDaysCounts: PropTypes.array,
  futureJourneyTimeout: PropTypes.object,
  inProgressJourneyTimeout: PropTypes.object,
};

export default DaysBarChart;
