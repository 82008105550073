{createComponent} = require("@evertrue/et-flux")
{number, func} = ReactLibs.PropTypes

GiveContactsStore = require("apps/contact/stores/give-contacts-store")
GiveContactsSource = require("apps/contact/sources/give-contacts-source")


module.exports =
   createComponent "ContactResolver",
      propTypes:
         contact_id: number
         render: func # arg {parsed_contact, raw_contact, contact_id, loading, has_error}

      getDefaultProps: ->
         render: -> null

      registerStores: ->
         @on GiveContactsStore, "contact_id", (props) ->
            parsed_contact: GiveContactsStore.getContactRecord(props.contact_id)

      componentDidMount: ->
         if id = @props.contact_id
            GiveContactsSource.loadContacts(id)

      componentDidUpdate: (prevProps) ->
         if contact_id = @props.contact_id
            if contact_id isnt prevProps.contact_id
               GiveContactsSource.loadContacts(contact_id)

      render: ->
         parsed_contact = @state.parsed_contact || {}
         raw_contact = {}
         contact_id =  @state.parsed_contact?.id
         loading = true
         has_error = false
         @props.render({parsed_contact, raw_contact, contact_id, loading, has_error}) || null
