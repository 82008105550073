import { Component } from "react";
import InviteTableRow from "apps/settings/invite-team-settings/components/invite-table-row";
import Icon from "components/elements/icon";
import { Button } from "@evertrue/et-components";
import Loading from "components/elements/loading";
import _ from "underscore";

class InviteTable extends Component {
  static propTypes = {
    tableConfig: ReactLibs.PropTypes.array,
    tableData: ReactLibs.PropTypes.array,
    numInvites: ReactLibs.PropTypes.number,
    canSubmit: ReactLibs.PropTypes.bool,
    bulkSuccessfulInvites: ReactLibs.PropTypes.array,
    blockErrors: ReactLibs.PropTypes.array,
    isLoading: ReactLibs.PropTypes.bool,
    onChange: ReactLibs.PropTypes.func, // args (value, key, index)
    onClear: ReactLibs.PropTypes.func,
    onAdd: ReactLibs.PropTypes.func,
    onSubmit: ReactLibs.PropTypes.func,
  };

  static defaultProps = {
    tableConfig: [],
    tableData: [],
    numInvites: 0,
    canSubmit: false,
    bulkSuccessfulInvites: [],
    blockErrors: [],
    isLoading: false,
    onChange: () => {},
    onClear: () => {},
    onAdd: () => {},
    onSubmit: () => {},
  };

  state = {
    showSuccessfulInvites: true,
  };

  handleChange = (value, key, index) => {
    this.props.onChange(value, key, index);
  };

  handleSuccessClose = () => {
    this.setState({ showSuccessfulInvites: false });
  };

  renderSuccessfulInvites() {
    const successes = this.props.bulkSuccessfulInvites.map((successData) => (
      <div key={successData.invitation.id}>{`${successData.user.name} - ${successData.user.email}`}</div>
    ));
    if (successes.length > 0 && this.state.showSuccessfulInvites) {
      return (
        <div className="invite--block-success">
          <a role="button" tabIndex={0} className="invite-block-success-close" onClick={this.handleSuccessClose}>
            <Icon icon="close" size={1} />
          </a>
          <div>{_.plural(successes.length, "Invite", "Invites")} successfully sent to </div>
          {successes}
        </div>
      );
    }
    return false;
  }

  renderErrors() {
    const errors = this.props.blockErrors.map((errorData) => (
      <div key={errorData.invitation.saml_user_id}>{errorData.error}</div>
    ));
    if (errors.length > 0) {
      return (
        <div className="invite--block-error">
          <div>
            The following {_.plural(errors.length, "error", "errors")} occurred and we were unable to send the invite
          </div>
          {errors}
        </div>
      );
    }
    return false;
  }

  renderTableHeader() {
    return this.props.tableConfig.map((node) => <th key={node.label}>{node.label}</th>);
  }

  generateKey(index) {
    return index;
  }

  renderTableRows() {
    return this.props.tableData.map((row, index) => (
      <InviteTableRow
        key={this.generateKey(index)}
        rowData={row}
        rowConfig={this.props.tableConfig}
        onChange={this.handleChange}
        rowIndex={index}
        blockErrors={this.props.blockErrors}
      />
    ));
  }

  renderLoading() {
    if (this.props.isLoading) {
      return <Loading text="Sending Invite(s)" />;
    }
    return null;
  }

  renderAddMore() {
    return (
      <a role="button" tabIndex={0} className="invite--add" onClick={this.props.onAdd}>
        <Icon icon="add-circle-outline" size={1} before />
        Add more team members
      </a>
    );
  }

  render() {
    const { numInvites, canSubmit } = this.props;
    return (
      <div>
        {this.renderSuccessfulInvites()}
        {this.renderErrors()}
        {this.renderLoading()}
        <div className="invite--table">
          <table className="table">
            <thead className="table--header">
              <tr>{this.renderTableHeader()}</tr>
            </thead>
            <tbody>{this.renderTableRows()}</tbody>
          </table>
          {this.renderAddMore()}
        </div>
        <Button className="invite--submit" disabled={!canSubmit} onClick={this.props.onSubmit}>
          Invite {numInvites > 0 ? numInvites : null} users
        </Button>
        <Button type="secondary" className="invite--clear" onClick={this.props.onClear}>
          Clear All
        </Button>
      </div>
    );
  }
}

export default InviteTable;
