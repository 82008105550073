React = require("react")
{createComponent} = require("@evertrue/et-flux")
{div} = ReactLibs.DOMFactories
EventDetailSource = require("apps/events/sources/event-detail-source")
EventDetailStore = require("apps/events/stores/event-detail-store")
ButtonSelect = require("components/controls/button-select")
ControlledSearchbox = require("components/forms/controlled-search-box")
{createFactory} = require("base/new-utils")
ContactExportButton = createFactory(require("apps/contact/components/contacts/contact-export-button").default)

module.exports =
   createComponent "EventsUnmatchedToolbar",

      registerStores: ->
         @on EventDetailStore, ->
            constituent_search: EventDetailStore.getConstituentSearch()

      render: ->
         div className: "events-unmatched-toolbar",
            ControlledSearchbox
               className: "events-unmatched-toolbar--search"
               placeholder: "Search"
               value: @state.constituent_search
               onChange: (query) -> EventDetailSource.setConstituentQuickSearch(query)
               onClear: -> EventDetailSource.clearConstituentSearch()

            div className: "events-unmatched-toolbar--toggle",
               div className: "button-select button-select-v2",
                  ButtonSelect
                     className: "button-select-v2"
                     selected: "unmatched"
                     buttons: [
                        {value: "matched", label: "Matched", gt_icon: "link"}
                        {value: "unmatched", label: "Unmatched", gt_icon: "unlink"}
                     ]
                     onSelect: @props.handleToggleChange
