import PropTypes from "prop-types";
import { ListboxSelect } from "@evertrue/et-components";
import useFetchAllUsers from "apps/search/hooks/use-fetch-all-users";
import SessionStore from "base/session/session-store";

const AddUser = ({ excludedUserIds = [], handleOnChange = () => {}, value, disabled }) => {
  const currentUser = SessionStore.getUser();
  const { users, fetchUsers } = useFetchAllUsers();

  const options = users
    .filter((user) => !excludedUserIds.includes(user.id))
    .map((user) => {
      return { label: user.id === currentUser.id ? user.name + " (You)" : user.name, value: user.id };
    });

  let searchQueryTimeout = null;

  return (
    <ListboxSelect
      size="medium"
      emptyValue="Add User..."
      label="Add User"
      options={options}
      onChange={handleOnChange}
      onSearch={(search) => {
        clearTimeout(searchQueryTimeout);
        searchQueryTimeout = setTimeout(() => {
          fetchUsers(search, 0);
        }, 500);
      }}
      scrollFetch={(search) => {
        fetchUsers(search, users.length);
      }}
      value={value}
      disabled={disabled}
    />
  );
};

AddUser.propTypes = {
  excludedUserIds: PropTypes.array,
  handleOnChange: PropTypes.func,
  value: PropTypes.object,
  disabled: PropTypes.bool,
};

export default AddUser;
