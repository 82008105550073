import { Button } from "@evertrue/et-components";
import PropTypes from "prop-types";
import EmailSignature from "./email-signature";

const EmailSignaturesList = ({
  selectedSignature = {},
  setSelectedSignature,
  signatures = [],
  handleAddSignature,
  setUserSignatures,
  updateSignature,
  handleDeleteSignature,
  isModalOpen,
  openModal,
  closeModal,
  defaultSignature,
}) => {
  return (
    <div className="email-signature-library">
      <div>
        <div className="email-signature-library--header">You can create up to 3 customized email signatures</div>
        <div className="email-signature-library--list">
          {!!signatures.length &&
            signatures.map((signature) => (
              <div key={signature.id}>
                <EmailSignature
                  signature={signature}
                  selectedSignature={selectedSignature}
                  setSelectedSignature={setSelectedSignature}
                  setUserSignatures={setUserSignatures}
                  updateSignature={updateSignature}
                  handleDeleteSignature={handleDeleteSignature}
                  defaultSignature={defaultSignature}
                />
              </div>
            ))}
        </div>
      </div>
      <Button
        type="secondary"
        disabled={signatures.length > 3}
        className="email-signatures--add-btn"
        onClick={isModalOpen ? closeModal : openModal}
      >
        Add Signature
      </Button>
    </div>
  );
};

EmailSignaturesList.propTypes = {
  selectedSignature: PropTypes.object,
  setSelectedSignature: PropTypes.func.isRequired,
  signatures: PropTypes.array,
  handleAddSignature: PropTypes.func.isRequired,
  setUserSignatures: PropTypes.func.isRequired,
  updateSignature: PropTypes.func.isRequired,
  handleDeleteSignature: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  defaultSignature: PropTypes.object,
};

export default EmailSignaturesList;
