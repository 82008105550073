import { Fragment, Component } from "react";
import classNames from "classnames";
import FormField from "components/forms/form-field";
import TimeBlockForm from "apps/my-trips/components/timeblock-form";
import MeetingForm from "apps/my-trips/components/meeting-form";
import { ModalConfirm, Icon, Radio } from "@evertrue/et-components";

class AddEventModal extends Component {
  static propTypes = {
    event: ReactLibs.PropTypes.object,
    tripId: ReactLibs.PropTypes.number,
    remove: ReactLibs.PropTypes.func,
  };

  static defaultProps = {
    event: {}, // may have default times based on where triggered from
    tripId: null,
  };

  state = {
    title: "",
    selected_type: "meeting",
  };

  componentDidMount() {
    if (this.titleInput) {
      this.titleInput.focus();
    }
  }

  handleChangeTitle = (title) => {
    this.setState({ title });
  };

  render() {
    const { selected_type, title } = this.state;
    if (this.state.edit_meeting_details) {
      if (this.state.selected_type === "timeblock") {
        return (
          <TimeBlockForm
            title={this.state.title}
            headerVerb="Add"
            timeblock={this.props.event}
            tripId={this.props.tripId}
            remove={this.props.remove}
          />
        );
      } else {
        return (
          <MeetingForm
            title={this.state.title}
            headerVerb="Create"
            meeting={this.props.event}
            tripId={this.props.tripId}
            remove={this.props.remove}
          />
        );
      }
    } else {
      return (
        <ModalConfirm
          header="Add Event"
          buttonLabel="Next"
          onSubmit={() => this.setState({ edit_meeting_details: true })}
          onCancel={this.props.remove}
          disableSave={!this.state.title}
          hasMargin
        >
          <Fragment>
            <FormField label="Title*">
              <input
                type="text"
                ref={(e) => {
                  this.titleInput = e;
                }}
                placeholder="Enter event name..."
                value={title}
                onChange={(e) => this.handleChangeTitle(e.currentTarget.value)}
              />
            </FormField>
            <div className="add-event-subtitle">Select your event type</div>
            <fieldset>
              <div className="event-type-row">
                <Radio
                  checked={selected_type === "meeting"}
                  onChange={() => this.setState({ selected_type: "meeting" })}
                  className="event-type-row--radio"
                  name="addEvent"
                >
                  <div className="event-type-row--content">
                    <Icon
                      icon="meeting"
                      className={classNames("event-type-row--icon", {
                        "is-selected": selected_type === "meeting",
                      })}
                    />
                    <div className="event-type-row--text">
                      <div className="event-type-row--text-title">Meeting</div>
                      <div>Schedule a Meeting</div>
                    </div>
                  </div>
                </Radio>
              </div>
              <div className="event-type-row">
                <Radio
                  checked={selected_type === "timeblock"}
                  onChange={() => this.setState({ selected_type: "timeblock" })}
                  className="event-type-row--radio"
                  name="addEvent"
                >
                  <div className="event-type-row--content">
                    <Icon
                      icon="timeblock"
                      className={classNames("event-type-row--icon", {
                        "is-selected": selected_type === "timeblock",
                      })}
                    />
                    <div className="event-type-row--text">
                      <div className="event-type-row--text-title">Time Block</div>
                      <div>Block out trip time for travel/flight/hotel/etc</div>
                    </div>
                  </div>
                </Radio>
              </div>
            </fieldset>
          </Fragment>
        </ModalConfirm>
      );
    }
  }
}

export default AddEventModal;
