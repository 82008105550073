module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   EverTrue = require("app")
   {div, button, h2, h3, p} = ReactLibs.DOMFactories
   {createStore} = require("@evertrue/et-flux")
   SecuritySource = require("apps/settings/security-settings/sources/security-source")
   UserSource = require("base/user/user-source")

   # mfa_status: [disabled, activated, enabled]

   getWizardDefaults = ->
      code_in_flight: false
      wizard_step: 0
      unable_to_send_code: false
      error_message: ""

   createStore "SecurityStore",
      getInitialState: ->
         _.extend {initialized: false, mfa_status: undefined, mfa_number: "", active_sessions: []}, getWizardDefaults()

      firstListenerDidMount: ->
         SecuritySource.refreshUserMFAStatus()

      registerActions: ->
         @on SecuritySource.actions.currentUserMFAStatus, (mfa_state, otp_mobile) ->
            @setState mfa_status: mfa_state, mfa_number: otp_mobile, initialized: true

         @on SecuritySource.actions.codeInFlight, (status) ->
            @setState
               code_in_flight: status

         @on SecuritySource.actions.newWizardStep, (step) ->
            if step is 0 then @setState getWizardDefaults()
            @setState wizard_step: step

         @on SecuritySource.actions.unableToRequestCode, (status) ->
            @setState
               unable_to_send_code: status

         @on SecuritySource.actions.enableMFAError, (error_message) ->
            @setState error_message: error_message

         @on UserSource.actions.newUser, ->
            @setState _.extend {initialized: false, mfa_status: undefined, mfa_number: ""}, getWizardDefaults()

         @on SecuritySource.actions.activeSessions, (sessions) ->
            sortedSessions = _.sortBy sessions, (session) -> session.last_activity_at
            [currentSession, allSessions] = _.partition(sortedSessions, (session) -> session.is_current)

            allSessions.push(currentSession)

            @setState active_sessions: _.flatten(allSessions.reverse())

         @on SecuritySource.actions.destroySessionSuccess, (sessionKey) ->
            newSessions = _.clone @getActiveSessions()

            @setState
               active_sessions: _.filter newSessions, (session) ->
                  session.key != sessionKey

      api:
         getCurrentUser: ->
            @getState("current_user")

         getUserMFAStatus: ->
            @getState("mfa_status")

         getCodeSentStatus: ->
            @getState("code_in_flight")

         getWizardStep: ->
            @getState("wizard_step")

         getWizardCompletion: ->
            @getState("wizard_complete")

         getMFAPhoneNumber: ->
            @getState("mfa_number")

         getPhoneSubmissionError: ->
            @getState("unable_to_send_code")

         getDeviceStatus: ->
            @getState("remembered_device")

         getErrorMessage: ->
            @getState ("error_message")

         getIsInitialized: ->
            @getState("initialized")

         getActiveSessions: ->
            @getState("active_sessions")
