import { PropTypes } from "base/react-libs";
import RecommendedActionSection from "./recommended-action-section";

const RecentGiftRecommendedActions = ({ enabled, giftAmount, recommendedActions = [] }) => {
  if (!enabled) return null;
  return (
    <RecommendedActionSection
      title="Made a gift in the past 15 days"
      subtitle="Recent Gift"
      recommendedActions={recommendedActions}
      icon="gift"
      type="gift"
      emailButtonLabel="Say Thanks"
    />
  );
};

RecentGiftRecommendedActions.propTypes = {
  enabled: PropTypes.bool,
  giftAmount: PropTypes.number,
  recommendedActions: PropTypes.array,
};

export default RecentGiftRecommendedActions;
