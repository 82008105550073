import PropTypes from "prop-types";
import str from "underscore.string";
import moment from "moment";
import EnrichmentConfidenceScore from "./enrichment-confidence-score";

const LifeChanges = ({ enrichmentData, limit }) => {
  const lifeChangeEvents = enrichmentData.wealth_life_events.concat(enrichmentData.employment_events);

  if (!lifeChangeEvents || lifeChangeEvents.length === 0) return null;

  const sortedLifeChangeEvents = lifeChangeEvents.sort((a,b) =>
    moment(b.recorded_at) - moment(a.recorded_at)
  );

  if(limit == null) {
    limit = lifeChangeEvents.length;
  }

  return (
    <div className="profile--enrichment-group">
      <div className="profile--enrichment-group-title">Noteworthy Events</div>
      {sortedLifeChangeEvents.slice(0, limit).map((ev) => (
        <div key={ev.id} className="bio-info--item">
          <div>
            {`${str.capitalize(ev.type)} | Reported on ${moment(ev.recorded_at ? ev.recorded_at : ev.updated_at).format("MMM D, YYYY")}`}
          </div>
          {ev.confidence_score && ev.source && (
          <div className="bio-info--confidence-score">
                {"Confidence Score: "}
                <EnrichmentConfidenceScore
                  score={ev.confidence_score}
                  source={ev.source}
                />
          </div>
          )}
        </div>
      ))}
    </div>
  );
};

LifeChanges.propTypes = {
  enrichmentData: PropTypes.object,
  limit: PropTypes.number,
};

export default LifeChanges;
