import PropTypes from "prop-types";
import Api from "entities/helpers/api";
import { usePromiseResolver } from "@evertrue/promise-resolver";

export const AssignmentTitleResolver = (props = {}) => {
  const [assignment_options, loading] = usePromiseResolver(() => {
    return Api.ASSIGNMENTS.ASSIGNMENT_TITLES.get().then((resp = {}) => {
      const titles = resp.items || [];
      return titles.map(title_obj => ({
        value: title_obj.title,
        label: title_obj.title,
        active: title_obj.active,
      }));
    });
  }, []);

  return { assignment_options, loading };
};

AssignmentTitleResolver.propTypes = {
  searchText: PropTypes.string,
};
