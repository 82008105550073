module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   {createStore} = require("@evertrue/et-flux")
   UserSource = require("base/user/user-source")
   ListSource = require("apps/lists/sources/list-source")
   ListContactSource = require("apps/lists/sources/list-contact-source")
   GateSource = require("apps/layout/sources/gate-source")
   OrgSource = require("base/org/org-source").default
   SocketSource = require("apps/notifications/sources/socket-source")

   _getDefaults = ->
      lists: []
      owners: []
      loading: false
      viewed_list_ids: undefined
      selected_list: undefined
      last_selected: undefined
      bulk_add_in_progress: false
      list_id_for_bulk_add_in_progress: undefined

   createStore "ListStore",
      getInitialState: ->
         _getDefaults()

      firstListenerDidMount: ->
         SocketSource.bindOrg("list_collaborator_change")
         SocketSource.bindUser("bulk_add_to_list_progress")

      registerActions: ->
         @on ListSource.actions.loadingLists, @respondToLoadingLists
         @on ListSource.actions.fetchedLists, @respondToFetchedLists
         @on ListSource.actions.updatedList, @respondToSavedList
         @on ListSource.actions.createdList, @respondToSavedList
         @on ListSource.actions.deletedList, @respondToDeletedList
         @on ListSource.actions.selectedList, @respondToSelectedList
         @on ListSource.actions.resetLists, @respondToReset
         @on ListSource.actions.fetchedFavorites, @respondToFetchedFavorites

         @on ListSource.actions.fetchedViewedLists, @respondToFetchViewed
         @on ListSource.actions.fetchedListOwners, @respondToFetchOwners

         @on ListContactSource.actions.addedContacts, @respondToAddContacts

         @on UserSource.actions.newUser, (user, impersonating) =>
            @setState {lists: [], favorites: {}}
            if EverTrue.store.org?.get("id")
               ListSource.fetch()
               ListSource.fetchFavorites()

         @on OrgSource.actions.newOrg, ->
            @respondToReset()
            ListSource.fetch()
            ListSource.fetchFavorites()

         @on GateSource.actions.fetchedGates, @respondToGates
         @on SocketSource.actions.list_collaborator_change, -> ListSource.fetch()
         @on SocketSource.actions.bulk_add_to_list_progress, @respondToBulkAddProgress
         @on ListContactSource.actions.bulkAddToListInProgress, @respondToBulkAddStart

      respondToLoadingLists: (is_loading) ->
         @setState {loading: is_loading}

      respondToFetchedLists: (data) ->
         lists = _.filter data.items, (list) ->
            list.type == "user_list"

         @setState {lists: lists}

         # Fetch List Owners
         @downloadOwners()

      respondToGates: ->
         if !@getState("loading") && !@getState("owners")
            @downloadOwners()

      respondToSavedList: (list, contact_ids) ->
         return unless list.type == "user_list"

         lists = _.clone(@getState("lists")) || []
         item = _.findWhere lists, {id: list.temp_id || list.id}

         if item then _.extend(item, _.omit(list, "optimistic"))
         else
            lists.push(list)
            EverTrue.track.set "list_action", {type: "create"}

         @setState {lists: lists}

         unless list.id == "temp" || list.optimistic
            ListSource.fetch()
            @saveRecentlyViewed(list.id)

         if !_.isEmpty(contact_ids) && list.id != "temp"
            # This is a hack for supporting the new profile
            options = {active_contact_id: _.first(contact_ids)} if _.size(contact_ids) == 1
            ListContactSource.addContacts(list.id, contact_ids, options)

      respondToDeletedList: (id) ->
         lists = @getState("lists")
         deleted_list = _.findWhere lists, {id: id}

         if deleted_list
            EverTrue.track.set "list_action", {type: "delete"}

         @setState
            lists: _.filter lists, (list) -> list.id != id

      respondToSelectedList: (list_id) ->
         @setState {selected_list: list_id, last_selected: @getState("selected_list")}
         @saveRecentlyViewed(list_id)

      respondToReset: ->
         @setState _getDefaults()

      respondToFetchedFavorites: (data) ->
         lists = @getState("lists")
         fav_list = _.findWhere lists, id: _.toNumber(data?.id)

         if fav_list
            _.extend fav_list, data
         else
            _.push lists, data

         @setState
            lists: lists

      respondToFetchOwners: (users) ->
         users_map = {}
         _.each users, (user) ->
            users_map[user.id] = user
         @setState {owners: users_map}

      respondToAddContacts: (list_id) ->
         @saveRecentlyViewed(list_id)

      respondToFetchViewed: (viewed) ->
         @setState
            viewed_list_ids: viewed
            has_loaded_recent: true

         # Run this for loading lists that are "selected" before DNA is fetched here
         @saveRecentlyViewed(@getState("selected_list"))

      downloadOwners: ->
         lists = @getState("lists")
         user_ids = _.uniq _.pluck lists, "user_id"
         new_users = _.difference user_ids, _.keys(@getState("owners"))
         unless _.isEmpty(new_users)
            ListSource.fetchListOwners(new_users)

      saveRecentlyViewed: (list_id) ->
         if list_id && @getState("has_loaded_recent")
            viewed = _.clone(@getState("viewed_list_ids")) || {}
            viewed.lists ?= []
            viewed.lists.unshift(_.toNumber(list_id))
            viewed.lists = _.first(_.uniq(_.compact(viewed.lists)), 5)
            @setState {viewed_list_ids: viewed}
            ListSource.saveView(viewed)

      # when API calls fires to start job
      respondToBulkAddStart: (list) ->
         @setState {
            list_id_for_bulk_add_in_progress: list.value
            bulk_add_in_progress: true
         }

      # on socket/job progress so can track, reset & fetch
      respondToBulkAddProgress: (response) ->
         selected_list = @getState("selected_list")
         {object_id, progress} = response
         if selected_list == object_id && progress < 1
            @setState {
               bulk_add_in_progress: true
            }

         else if selected_list == object_id && progress == 1
            ListContactSource.fetchContacts(selected_list)
            @setState {
               bulk_add_in_progress: false
               list_id_for_bulk_add_in_progress: undefined
            }


      api:
         getMyLists: ->
            lists = _.filter @getJoinedLists(), (list) ->
               list.user_id == EverTrue.store.user.get("id")

            # Sort lists bubbling "favorites" to the top and ordering by name after
            favorites = _.findWhere lists, {name: "Favorites"}
            _.flatten _.compact [favorites, _.sortBy(_.without(lists, favorites), "name")]

         getSharedLists: ->
            _.filter @getJoinedLists(), (list) ->
               list.user_id != EverTrue.store.user.get("id")

         getLists: ->
            shared_lists = @getSharedLists()
            my_lists = @getMyLists()
            grouped = []

            unless _.isEmpty(my_lists)
               grouped.push {id: "my_lists", label: "Your Lists", items: my_lists}

            unless _.isEmpty(shared_lists)
               grouped.push
                  id: "shared_lists"
                  label: "Shared with you"
                  items: shared_lists
            grouped

         getJoinedLists: ->
            users = @getState("owners")
            _.map @getState("lists"), (li) ->
               owner_name = users[li?.user_id]?.name
               if li?.name == "Favorites" && li?.user_id != EverTrue.store.user?.id && owner_name
                  name = "Favorites (#{owner_name})"
               else name = li?.name
               _.extend {}, li,
                  owner_name: owner_name
                  name: name

         getList: (id) ->
            _.findWhere @getJoinedLists(), {id: _.toNumber(id)}

         getLoading: ->
            @getState("loading")

         getSelectedList: ->
            id = @getState("selected_list")
            @getList(id) || {id: id}

         getLastSelectedList: ->
            @getState("last_selected")

         getRecentLists: ->
            viewed = _.uniq @getState("viewed_list_ids")?.lists
            all_lists = @getJoinedLists()

            if !_.isEmpty(viewed) && all_lists.length > 5
               ordered_lists = _.compact _.map viewed, (id) ->
                  _.findWhere all_lists, {id: id}

               if ordered_lists.length < 5
                  remaining = _.filter all_lists, (li) -> !_.contains viewed, li.id
                  remaining = _.sortBy remaining, "name"
                  remaining = _.first(remaining, 5 - ordered_lists.length)
                  ordered_lists = _.compact _.flatten [ordered_lists, remaining]
               ordered_lists
            else
               _.sortBy all_lists, "name"

         getFavoriteList: ->
            _.find @getJoinedLists(), (list) ->
               list.user_id == EverTrue.store.user.get("id") && list.name == "Favorites"

         hasLists: ->
            !_.isEmpty @getState("lists")

         doesSelectedListHaveBulkAddInProgress: ->
            @getState("list_id_for_bulk_add_in_progress") == @getState("selected_list")
