import PropTypes from "prop-types";
import { Link } from "@evertrue/et-components";

const FacebookPageLink = ({ page, postUrl, engagement }) => {
  return (
    <div className="match-facebook-modal-post-info">
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          className="match-facebook-modal-page-image"
          alt={page.page_name}
          width="40"
          height="40"
          src={page && page.picture_url}
        />
        <Link
          title={`View ${page.page_name}'s Facebook Page`}
          href={`http://www.facebook.com/${page.remote_id}`}
          target="_blank"
        >
          {page.page_name}
        </Link>
      </div>

      <Link href={postUrl} title="Recent Post" target="_blank" style={{ display: "flex" }}>
        Recent Post
        <img
          alt="facebook post"
          height="18"
          width="18"
          src={`/images/fb-reactions-${engagement.type}.png`}
          style={{ marginLeft: "7px" }}
        />
      </Link>
    </div>
  );
};

FacebookPageLink.propTypes = {
  page: PropTypes.object.isRequired,
  postUrl: PropTypes.string.isRequired,
  engagement: PropTypes.object.isRequired,
};

FacebookPageLink.defaultProps = {
  page: {},
  engagement: {},
};

export default FacebookPageLink;
