import { useState, useEffect } from "react";
import _ from "underscore";
import Api from "entities/helpers/api";
import EverTrue from "app";
import ErrorLogger from "entities/helpers/error-log-helper";

const useFilters = (searchTerm = "") => {
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState([]);
  const [categorizedFilters, setCategorizedFilters] = useState([]);

  useEffect(() => {
    if (searchTerm !== "") {
      EverTrue.track.set("filter_v2_action", {
        type: "search_filters",
        search_term: searchTerm,
      });
    }
    const loadFilters = async () => {
      setLoading(true);
      try {
        const resp = await Api.HUB.FILTERS.get({
          params: searchTerm ? { query: searchTerm } : null,
        });
        if (!searchTerm) {
          setFilters(resp);
        }
        setCategorizedFilters(() => makeCategories(resp));
        setLoading(false);
      } catch {
        setLoading(false);
        ErrorLogger.captureRequest("Error fetching filters");
      }
    };

    loadFilters();
  }, [searchTerm]);

  const makeCategories = (filters) => {
    const categories = _.groupBy(filters, "category");

    Object.keys(categories).forEach((category) => {
      categories[category] = _.groupBy(categories[category], "group");
    });

    return categories;
  };

  return { filtersLoading: loading, categorizedFilters, filters };
};

export default useFilters;
