module.exports = do ->
   _ = require("underscore").default
   ContactColumnStore = require("apps/contact/stores/contact-column-store")
   ColumnSource = require("apps/contact/sources/column-source")
   {div} = ReactLibs.DOMFactories
   {createComponent} = require("@evertrue/et-flux")
   SortSelector = require("components/controls/sort-selector/sort-selector")


   createComponent "ColumnSelector",
      registerStores: ->
         @on ContactColumnStore, ->
            all_columns: ContactColumnStore.getColumns()
            active_columns: ContactColumnStore.getAppliedColumns()
            initial_active_columns: ContactColumnStore.getAppliedColumns()

      trackNewActiveColumns: (newColumns) ->
         colKeys = newColumns.map((col) => col.key)
         EverTrue.track.set "move_to_active",
            type: "move_to_active_column"
            params: colKeys

      handleSave: (active_columns) ->
         new_active_columns = active_columns.filter((firstArrItem) =>
            !@state.initial_active_columns.some((secondArrItem) => firstArrItem.key == secondArrItem.key));
         if new_active_columns.length > 0
            @trackNewActiveColumns(new_active_columns)
         ColumnSource.update(active_columns)
         @handleClose()

      handleClose: ->
         if _.isFunction @props.requestHide
            @props.requestHide()

      render: ->
         div className: "column-selector",
            SortSelector
               options: @state.all_columns
               value: @state.active_columns
               header: "Edit Columns"
               action: "Save Columns"
               activeInstructionContent: div null, "Drag and drop to prioritize the order."
               onSave: @handleSave
               onCancel: @handleClose
