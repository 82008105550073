import { PropTypes } from "base/react-libs";
import { useEffect } from "react";
import { Button, useOpenClose, Icon } from "@evertrue/et-components";
import { useGate } from "components/is-gated";
import { trackTaskAction } from "apps/tasks/utils/utils";
import AddTask from "apps/tasks/components/create/add-task";

const AddTaskButton = ({
  contact = null,
  buttonType = "default",
  optionalStateManagementFunction = () => { },
  activeModal,
  setActiveModal,
}) => {
  const [isModalOpen, openModal, closeModal] = useOpenClose();
  const [enabled, loaded] = useGate("task_management");

  /** track the active modal when navigating from profile-header to prevent overlap*/
  useEffect(() => {
    if (!setActiveModal && !activeModal) return;
    if (isModalOpen && setActiveModal) {
      setActiveModal("task");
    }
  }, [isModalOpen, activeModal, setActiveModal]);

  const closeAndClearModal = () => {
    if (setActiveModal) {
      setActiveModal(null);
    }
    closeModal();
  };

  const renderButton = () => {
    const buttonClass = isModalOpen ? "round-task-button-focused" : "round-button";
    if (buttonType === "icon-btn")
      return (
        <Button type="secondary" onClick={isModalOpen ? closeAndClearModal : openModal} className={buttonClass}>
          {isModalOpen ? (
            <Icon icon="tasks" iconKit="gt2" style={{ color: "#A54AB5" }} />
          ) : (
            <Icon icon="tasks" iconKit="gt2" style={{ color: "#007C9E" }} />
          )}
        </Button>
      );
    else if (buttonType === "icon")
      return (
        <Icon
          icon="tasks"
          iconKit="gt2"
          onClick={() => {
            trackTaskAction("add_task");
            isModalOpen ? closeAndClearModal() : openModal();
          }}
          isReachable={true}
          onKeyUp={() => {
            trackTaskAction("add_task");
            isModalOpen ? closeAndClearModal() : openModal();
          }}
          style={{ cursor: "pointer" }}
        />
      );
    else
      return (
        <Button onClick={isModalOpen ? closeAndClearModal : openModal} type={buttonType}>
          + Add Task
        </Button>
      );
  };
  if (!loaded) return null;
  if (enabled) {
    return (
      <>
        <AddTask
          isOpen={isModalOpen}
          closeModal={closeAndClearModal}
          contact={contact}
          handleClose={closeAndClearModal}
          optionalStateManagementFunction={optionalStateManagementFunction}
        />
        {renderButton()}
      </>
    );
  }
};
AddTaskButton.propTypes = {
  contact: PropTypes.object,
  buttonType: PropTypes.string,
  optionalStateManagementFunction: PropTypes.func,
  activeModal: PropTypes.string,
  setActiveModal: PropTypes.func,
};
export default AddTaskButton;
