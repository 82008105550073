module.exports = do ->
   _ = require("underscore").default
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")
   ErrorLogger = require("entities/helpers/error-log-helper")
   LIMIT = 500
   { fetchThankviewEngagements } = require("../../tasks/utils/fetch-helpers.js")
   { cleanData } = require("../../tasks/utils/clean-data.js")

   createSource "AllEngagementsSource",
      actions:
         loading: true
         fetchingEventsError: true
         fetchingFBEngagementsError: true
         fetchedFBEngagements: (contact_id, facebook_engagements, stats) ->
            @require _.isFinite(contact_id), "contact_id should be a number"
            @require _.isArray(facebook_engagements), "facebook_engagements should be an array"
            @require _.isObject(stats), "stats should be an object"
         fetchedEvents: (contact_id, event_engagements, stats) ->
            @require _.isFinite(contact_id), "contact_id should be a number"
            @require _.isArray(event_engagements), "event_engagements should be an array"
            @require _.isObject(stats), "stats should be an object"
         fetchedTVEngagements: (contact_id, tv_engagements, stats) ->
            @require _.isFinite(contact_id), "contact_id should be a number"
            @require _.isArray(tv_engagements), "tv_engagements should be an array"
         applyFilters: (id, filter_object) ->
            @require _.isFinite(id), "id should be a number"
            @require _.isObject(filter_object), "filter_object should be an object"
         loadMore: (id) ->
            @require _.isFinite(id), "id should be a number"
         changeSort: (id, sort_key) ->
            @require _.isFinite(id), "id should be a number"
            @require _.isString(sort_key), "sort_key should be a string"



      api:
         fetchAllEngagements: (contact_id) ->
            @actions.loading()
            @fetchFacebookEngagements(contact_id, 0)
            @fetchEventEngagements(contact_id, 0)
            @fetchTVEngagements(contact_id)

         fetchFacebookEngagements: (contact_id, offset = 0) ->
            query = must:[contact_id: match: contact_id]
            Api.SEARCH.SOCIAL.post
               data: _.jsonStringify(query)
               params:
                  offset: offset
                  limit: LIMIT
               success: (resp) =>
                  stats = _.omit resp, "items"
                  facebook_engagements = _.map resp.items, (engagement) ->
                     id: engagement.engagement.compound_id
                     type: "facebook"
                     engagement_date: engagement.engagement.created_at
                     data: engagement
                  @actions.fetchedFBEngagements(contact_id, facebook_engagements, stats)
               error: (xhr) =>
                  @actions.fetchingFBEngagementsError()
                  ErrorLogger.captureRequest("Error loading facebook engagement data", xhr)

         fetchEventEngagements: (contact_id, offset = 0) ->
            query =
               must:[contact_id: match: contact_id]
               sort: ["engaged_at": {order: "desc"}]
            Api.EVENTS.ENGAGEMENTS.post
               data: _.jsonStringify(query)
               params:
                  offset: offset
                  limit: LIMIT
               success: (resp) =>
                  stats = _.omit resp, "items"
                  event_engagements = _.map resp.items, (event) ->
                     id: event.compound_id
                     type: "event"
                     engagement_date: event.engaged_at
                     data: event
                  @actions.fetchedEvents(contact_id, event_engagements, stats)
               error: (xhr) =>
                  @actions.fetchingEventsError()
                  ErrorLogger.captureRequest("Error loading event engagement data", xhr)
                  
         fetchTVEngagements: (contact_id) ->
            fetchThankviewEngagements(contact_id).then (engagements) =>
               cleanedEngagements = cleanData(engagements)
               @actions.fetchedTVEngagements(contact_id, cleanedEngagements)
            
         applyFilters: (id, filter_object) ->
            @actions.applyFilters(id, filter_object)

         changeSort: (id, sort_key) ->
            @actions.changeSort(id, sort_key)

         loadMore: (id) ->
            @actions.loadMore(id)