React = require("react")
_ = require("underscore").default
{createComponent} = require("@evertrue/et-flux")
{div} = ReactLibs.DOMFactories
{createFactory} = require("base/new-utils")
IsGated = createFactory require("components/is-gated").default
EventDetailSource = require("apps/events/sources/event-detail-source")
EventDetailStore = require("apps/events/stores/event-detail-store")
SelectedContactsStore = require("apps/contact/stores/selected-contacts-store")
ButtonSelect = require("components/controls/button-select")
ContactFixedTable = createFactory(require("apps/contact/components/contacts/contact-fixed-table").default)
ContactTableToolbar = createFactory(require("apps/contact/components/contacts/table/contact-table-toolbar").default)
ControlledSearchbox = require("components/forms/controlled-search-box")
ContactExportButton = createFactory(require("apps/contact/components/contacts/contact-export-button").default)
Pagination = require("components/controls/pagination")
SelectedContactsSource = require("apps/contact/sources/selected-contacts-source")
Layout = createFactory require("components/layout").default

module.exports = createComponent "EventsMatchedController",
   registerStores: ->
      @on EventDetailStore, "event_id", (props) ->
         contacts: EventDetailStore.getContactsByEventId(props.event_id)
         loading: EventDetailStore.getContactsLoading()
         constituent_search: EventDetailStore.getConstituentSearch()

      @on SelectedContactsStore, ->
         selectAllIndicatorVisible: SelectedContactsStore.getShowSelectedIndicator()

   handlePageChange: (page) ->
      EventDetailSource.setPage(page)
      SelectedContactsSource.clearAllSelectedState()

   renderPagination: ->
      {contacts} = @state
      if (contacts?.total / contacts?.limit) > 1
         div className: "events-matched-controller--footer",
            Pagination
               className:"events-matched-controller--pagination"
               page: _.toNumber(contacts?.page)
               total: contacts?.total / contacts?.limit
               onChange: @handlePageChange

   handleSort: (sortKey, sortReverse) ->
         EventDetailSource.sort(sortKey, sortReverse)

   render: ->
      { event_id, filters_query, activeFilters, selectedFilterCount, eventSource } = @props
      { contacts } = @state

      header =
         ContactTableToolbar
            contacts: contacts
            listActionLabel: "Add all"
            onPage: @handlePage
            hide_pagination: true
            allowAddAll: true
            totalResultsCount: selectedFilterCount
            filterParams: filters_query
            div className: "events-matched-controller--controls",

               div className: "events-matched-controller--left",
                  ControlledSearchbox
                     className: "events-matched-controller--searchbox",
                     placeholder: "Search for constituent..."
                     value: @state.constituent_search
                     onChange: (query) -> EventDetailSource.setConstituentQuickSearch(query)
                     onClear: -> EventDetailSource.clearConstituentSearch()

               div className: "events-matched-controller--controls-right",
                  ContactExportButton
                     count: selectedFilterCount
                     event_id: event_id
                     filters: activeFilters

                  if eventSource == "eventbrite"
                     IsGated feature: "events_eventbrite",
                        div className: "events-matched-controller--toggle",
                           div className: "button-select button-select-v2",
                              ButtonSelect
                                 className: "button-select-v2"
                                 selected: "matched"
                                 buttons: [
                                    {value: "matched", label: "Matched", gt_icon: "link"}
                                    {value: "unmatched", label: "Unmatched", gt_icon: "unlink"}
                                 ]
                                 onSelect: @props.handleToggleChange

      div className: "events-matched-controller",
         Layout { header },
            div className: "events-page-controller--matched-table",
               ContactFixedTable
                  contacts: contacts
                  extraColumns: ["event_response", ""]
                  loading: @state.loading
                  onPage: @handlePage
                  onSort: @handleSort
                  footer: @renderPagination()
