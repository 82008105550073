module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")

   _key_throttle = {}
   _registered = false

   createSource "GateSource",
      actions:
         clearedGates: true
         loadingGates: true
         refreshUserGates: true
         errorFetchingGates: (key) ->
            @require _.isString(key) || _.isUndefined(key), "key should be string or undefined"
         fetchedGates: (gates, key) ->
            @require _.isObject(gates), "gates should be object"
            @require _.isString(key) || _.isUndefined(key), "key should be string or undefined"
         fetchedDefaults: (defaults) ->
            @require _.isObject(defaults), "defaults should be object"

      register: ->
         return if _registered
         EverTrue.vent.on "org:initialized", -> _key_throttle = {}
         _registered = true

      promise:
         fetchAll: ->
            @actions.loadingGates(true)

            Api.DNA.GATES.get
               params:
                  platform: "web"
                  user_id: "me"
               success: (resp) =>
                  @actions.loadingGates(false)
                  @actions.fetchedGates(resp)
               error: =>
                  @actions.errorFetchingGates()

      api:
         fetch: (key) ->
            @register()
            # Throttle request for individual keys
            current_time = new Date().getTime()
            return if key && _key_throttle[key] && (current_time - _key_throttle[key]) < 20000
            _key_throttle[key] = current_time

            @actions.loadingGates(true)

            Api.DNA.GATES.get
               urlExtend: if key then "/#{key}" else undefined
               params:
                  platform: "web"
                  user_id: "me"
               success: (resp) =>
                  @actions.loadingGates(false)
                  @actions.fetchedGates(resp, key)
               error: =>
                  @actions.errorFetchingGates(key)

         fetchDefaults: ->
            Api.DNA.GATES.get
               params: oid: null
               success: (resp) =>
                  @actions.fetchedDefaults(resp)
               error: =>
                  @actions.errorFetchingGates()

         refreshUserGates: ->
            @actions.refreshUserGates()

