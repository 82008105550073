module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   moment = require("moment")
   classSet = require("classnames")
   React = require("react")
   EverTrue = require("app")
   {createComponent} = require("@evertrue/et-flux")
   Decorator = require("clientDecorator")
   ListContactStore = require("apps/lists/stores/list-contact-store")
   ListActivitySource = require("apps/lists/sources/list-activity-source")
   ListActivityStore = require("apps/lists/stores/list-activity-store")
   {div, ul, li, strong, span} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   Module = createFactory require("components/module").default
   UserCard = createFactory require("apps/users/components/user-card")
   CommentTextarea = createFactory(require("apps/profile/components/interactions/comment-textarea"))
   CommentListItem = createFactory require("apps/contact/components/profile/comments/comments-list-item")
   ContactCard = createFactory(require("apps/contact/components/contact-card/contact-card"))
   ActivityCardGroup = require("apps/lists/components/activity-card-group")
   ActivityFacepile = require("apps/lists/components/activity-facepile")
   str = require("underscore.string")
   LinkButton = createFactory(require("@evertrue/et-components").LinkButton)

   createComponent "ListActivity",
      getInitialState: ->
         view: "feed"
         comment: {}

      registerStores: ->
         @on ListActivityStore, ->
            feed: ListActivityStore.getActivity(@props.list_id)
            hasMoreToLoad: ListActivityStore.hasMoreToLoad(@props.list_id)
            paging: ListActivityStore.getPaging(@props.list_id)

         @on ListContactStore, ->
            list_contacts: ListContactStore.getContacts(@props.list_id)

      componentDidMount: ->
         ListActivitySource.fetch(@props.list_id)

      UNSAFE_componentWillReceiveProps: (newProps) ->
         if newProps.list_id != @props.list_id
            ListActivitySource.fetch(newProps.list_id)

      handleSave: (comment) ->
         ListActivitySource.saveComment(@props.list_id, comment)
         @handleCancel()

      handleDelete: (comment_id) ->
         EverTrue.Alert.confirm "Are you sure you want to delete this comment?", (e) =>
            if e
               ListActivitySource.deleteComment(@props.list_id, comment_id)

      handleCancel: ->
         @setState {comment: {}}

      handlePaginate: ->
         ListActivitySource.fetch(@props.list_id, @state.paging)

      renderModuleHeader: ->
         div className: "list-activity--header",
            "List Activity"

      render: ->
         current_user = EverTrue.store?.user?.get("id")

         Module
            className: "list-activity--module"
            header: @renderModuleHeader
            noMargin: true

            div className: "list-activity",

               CommentTextarea
                  className: "list-activity--comment-box list-activity--comment-box--new"
                  comment: @state.comment
                  onSave: @handleSave
                  onCancel: @handleCancel
                  hideAvatar: true

               ul className: "list-activity--feed",
                  _.map @state.feed, (item) =>
                     if item.interaction_type == "EverTrue Comment"
                        li key: item.id, className: "list-activity--feed-item",
                           div className: "list-activity--label",
                              strong className: classSet({"is-empty": _.isEmpty(item.creator), "text-removed-user": item.creator?.removed}),
                                 Decorator.User.getName(item.creator || {}, current_user) || ""
                              span null, " added a note on #{moment(item.created_at).format("MMM D")}"

                           ul null,
                              CommentListItem
                                 key: "comment-#{item.id}"
                                 comment: item
                                 onDelete: @handleDelete
                                 onUpdate: @handleSave

                     else
                        li key: item.id, className: "list-activity--feed-item",
                           div className: "list-activity--label",
                              strong className: classSet({"is-empty": _.isEmpty(item.user), "text-removed-user": item.user?.removed}),
                                 Decorator.User.getName(item.user || {}, current_user) || ""
                              span null, " #{item.label} on #{moment(item.created_at).format("MMM D")}"

                           if item.target == "COLLABORATOR"
                              ActivityCardGroup
                                 className: "list-activity--target"
                                 items: _.filter item.activities, (activity) -> !!activity.target_data
                                 component: (activity) =>
                                    UserCard
                                       user: activity.target_data
                                       onClick: (user) =>
                                          @setState
                                             comment:
                                                text: "<@user:#{user.id}:#{str.classify(user.name)}> "
                                                mentions: [
                                                   {alias: str.classify(user.name), user_id: user.id, type: "user"}
                                                ]

                                          _.defer ->
                                             $(".note-textarea--input").focus()
                                             $(".list-page--viewport").scrollTop(0)
                                             $(".list-activity").scrollTop(0)

                           else if item.target == "CONTACT"
                              div null,
                                 if item.activities?.length < 3
                                    _.map item.activities, (activity) ->
                                       div className: "list-activity--constituents", key: activity.target_id,
                                          ContactCard
                                             contact: activity.target_data || {}
                                             referrer: "list_activity"
                                             showRemoteId: true
                                             includeMiddle: true
                                 else
                                    div className: "list-activity--facepile",
                                       ActivityFacepile
                                          list: _.map item.activities, (activity) -> activity.target_data
                                          label: "#{Decorator.User.getName(item.user || {}, current_user)} #{item.label} on #{moment(item.created_at).format("MMM D")}"
                                          icon: if item.type == "ADDED" then "fa-user-plus" else "fa-user-times"

                           else if item.target == "CREATED"
                              div className: "list-activity--card",
                                 strong null, item.name

               if @state.hasMoreToLoad
                  div className: "list-activity--paging",
                     LinkButton title: "view more list activities", onClick: @handlePaginate, "View More"
