import PropTypes from "prop-types";
import { CachedContactsProvider } from "@evertrue/vms-insights";
import { fetchContactsById } from "apps/volunteers/vms-queries";
import { GlobalContactSearchProvider } from "apps/layout/controllers/global-contact-search-context";
import { FiltersDataProvider } from "apps/query-builder/contexts/filters-data-context";
import { ListDataProvider } from "apps/layout/controllers/list-data-context";
import { GlobalPortfolioProvider } from "apps/portfolio-performance/contexts/global-portfolio-context";
import { GlobalJourneyProvider } from "apps/journeys/context/global-journey-context";
import { TasksNotificationProvider } from "apps/tasks/hooks/tasks-notification-context.js";
import { GlobalOutreachProvider } from "components/emailer/email-modal-context";
import { ContactModalProvider } from "apps/profile/contexts/contact-modal-context";

// Make sure this state really needs to be global before adding another Provider!
const GlobalProviderController = ({ user = {}, ...props }) => {
  return (
    <CachedContactsProvider fetchContactsById={fetchContactsById}>
      <GlobalJourneyProvider>
        <GlobalContactSearchProvider>
          <GlobalPortfolioProvider>
            <ListDataProvider>
              <FiltersDataProvider>
                <GlobalOutreachProvider>
                  <ContactModalProvider>
                    <TasksNotificationProvider>{props.children}</TasksNotificationProvider>
                  </ContactModalProvider>
                </GlobalOutreachProvider>
              </FiltersDataProvider>
            </ListDataProvider>
          </GlobalPortfolioProvider>
        </GlobalContactSearchProvider>
      </GlobalJourneyProvider>
    </CachedContactsProvider>
  );
};

GlobalProviderController.propTypes = {
  user: PropTypes.object,
  children: PropTypes.any,
};
export default GlobalProviderController;
