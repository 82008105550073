// WARNING: DO NOT USE THIS - It will be deprecated and should only be used in the FilerCategoryList component
const deprecatedIconMap = {
  Activity: "activity",
  Capacity: "capacity",
  Engagement: "engagement",
  Demographics: "demographics",
  School: "school",
  "Contact Info": "contact-info",
  Giving: "giving",
  "Custom Fields": "custom-fields",
};

export default deprecatedIconMap;
