import _ from "underscore";
import { useContext } from "react";
import PropTypes from "prop-types";
import { connect } from "@evertrue/et-flux";
import Api from "entities/helpers/api";
import ActionMenuItem from "components/controls/action-menu-item";
import Modal from "components/modals/modal";
import MapStore from "apps/map/stores/map-store";
import { getContactsForPortfolioQuery } from "apps/portfolio-performance/portfolio-queries";
import PortfolioMetaDataContext from "apps/portfolio-performance/contexts/portfolio-meta-data-context";
import ExportSearchController from "apps/export/controllers/export-search-controller";
import ExportSource from "apps/export/sources/export-source";
import ContactAssignmentContext from "apps/portfolio-performance/contexts/contact-assignment-context";
import ExportFieldStore from "apps/export/stores/export-field-store";

const mapStateToProps = () => ({
  bounding_box: MapStore.getBoundingBox(),
});

const PortfolioPerformanceActions = (props) => {
  const { total, team, solicitor, stages, sort_prop, sort_reverse } = useContext(PortfolioMetaDataContext);
  const { filters } = useContext(ContactAssignmentContext);

  const query = getContactsForPortfolioQuery(
    sort_prop,
    sort_reverse,
    team.id,
    solicitor.id,
    _.makeArray(stages),
    props.tab === "stage" ? null : props.bounding_box,
    filters
  );

  const handleExport = (property_ids) => {
    const data = {
      name: "team_assignment_records",
      search: query,
    };

    const exportParams = ExportFieldStore.getExportParams(property_ids);

    Api.CONTACTS.EXPORTS.post({
      params: exportParams,
      data: _.jsonStringify(data),
    }).then(() => {
      ExportSource.start();
    });
  };
  return (
    <div>
      <ActionMenuItem
        disable={!total}
        icon={total > 0 ? "cloud-download" : null}
        modal={
          <Modal>
            <ExportSearchController
              onExport={(property_ids) => handleExport(property_ids)}
              exportText={`You are about to export ${total} records for constituents in this portfolio.`}
            />
          </Modal>
        }
        title={`Export ${total} Prospects`}
      >
        {total ? `Export ${total} Prospects` : "No Prospects to Export"}
      </ActionMenuItem>
    </div>
  );
};

PortfolioPerformanceActions.propTypes = {
  tab: PropTypes.string,
  bounding_box: PropTypes.object,
};

export default connect(PortfolioPerformanceActions, [MapStore], mapStateToProps);
