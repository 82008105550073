export const PERMISSIONS = Object.freeze({
  VIEW: "VIEW",
  EDIT: "EDIT",
  OWNER: "OWNER",
});

export const UPDATE_PROP = Object.freeze({
  DSL_CRITERIA: "dsl_criteria",
  NAME: "name",
});

export const LIST_STATUS = Object.freeze({
  COMPLETED: "Completed",
  QUEDED: "Queued",
  RUNNING: "Running",
  FAILED: "Failed",
});

export const LIST_TAG_STAT = Object.freeze({
  COMPLETED: "COMPLETED",
  RUNNING: "RUNNING",
  FAILED: "FAILED",
  ACCESS_DENIED: "ACCESS_DENIED",
  DELETED: "DELETED",
});

export const LIST_ORDER = Object.freeze({
  ASC: "ASC",
  DESC: "DESC",
});

export const FREQUENCY = Object.freeze({
  DAILY: "Daily",
  WEEKLY: "Weekly",
  BIWEEKLY: "Biweekly",
  MONTHLY: "Monthly",
  daily: "Daily",
  weekly: "Weekly",
  biweekly: "Biweekly",
  monthly: "Monthly",
});
