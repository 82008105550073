module.exports = do ->
   React = require("react")
   EverTrue = require("app")
   {createComponent} = require("@evertrue/et-flux")
   {div, h3, strong, span} = ReactLibs.DOMFactories
   AdminNav = require("apps/admin/components/admin-nav")


   createComponent "AdminWrapper",
      propTypes:
         active: ReactLibs.PropTypes.string
         title: ReactLibs.PropTypes.string

      render: ->
         div className: "admin-page",
            div className: "admin-page--header",
               div className: "main-sub-header",
                  h3 className: "inline", "Feature Flags Admin"

                  if EverTrue.config.environment_data
                     span className: "badge-warning",
                        "#{EverTrue.config.environment_data}"

                  div null, @props.subtitle

               AdminNav active: @props.active

            div className: "admin-page--body",
               @props.children

