import TaskForm from "./task-form";
import { Modal, useOpenClose } from "@evertrue/et-components";
import { PropTypes } from "base/react-libs";
import AddToCalendarModal from "./add-to-calendar-modal";
import { useState } from "react";

const AddTask = ({ isOpen, closeModal, contact, handleClose, optionalStateManagementFunction, taskObj }) => {
  const [isAddToCalendarModalOpen, openAddToCalendarModal, closeAddToCalendarModal] = useOpenClose();
  const [taskFormData, setTaskFormData] = useState({});

  return (
    <>
      <Modal isOpen={isOpen} closeModal={closeModal}>
        <TaskForm
          contact={contact}
          handleClose={handleClose}
          optionalStateManagementFunction={optionalStateManagementFunction}
          taskObj={taskObj}
          openAddToCalendarModal={openAddToCalendarModal}
          setTaskFormData={setTaskFormData}
        />
      </Modal>
      <AddToCalendarModal
        isOpen={isAddToCalendarModalOpen}
        closeModal={closeAddToCalendarModal}
        calendarParams={taskFormData}
      />
    </>
  );
};

AddTask.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  contact: PropTypes.object,
  handleClose: PropTypes.func,
  optionalStateManagementFunction: PropTypes.func,
  taskObj: PropTypes.object,
};

export default AddTask;
