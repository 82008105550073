_ = require("underscore").default
{createComponent} = require("@evertrue/et-flux")
React = require("react")
{div} = ReactLibs.DOMFactories
{object, func} = ReactLibs.PropTypes
{createFactory} = require("base/new-utils")
AdvancedCombobox = createFactory(require("@evertrue/et-components").AdvancedCombobox)

OPTIONS = [
   value: "must"
   label: "Constituent HAS made a gift of..."
,
   value: "must_not"
   label: "Constituent HAS NOT made a gift of..."
]

module.exports =
   createComponent "GiftTransactionParamSelector",
      propTypes:
         input: object
         state: object
         onChange: func

      componentDidMount: ->
         if _.isEmpty(@props.input.value)
            @props.onChange(null, param: "must")

      handleChange: (new_param) ->
         # don't clear values when switching negation
         value = @props.input.value
         new_value = _.pick(_.extend({}, value, {param: new_param}), _.notEmpty)
         @props.onChange(null, new_value)

      render: ->
         current_param = @props.input.value?.param
         value = _.findWhere(OPTIONS, value: current_param)
         div style: {width: "400px"},
            AdvancedCombobox
               placeholder: "Select Gift Transaction Filter..."
               options: OPTIONS
               value: value
               onChange: (val) =>
                  @handleChange(val.value)
