import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import ProfileCardSubtitle from "./profile-card-subtitle";

function percentage(percent, isCta) {
  const comp = (
    <div className={isCta ? "profile-engagement-metrics-tv-ctalabel" : "profile-engagement-metrics-tv-label"}>
      {percent ? percent : "--"}
    </div>
  );
  return comp;
}

function renderMetric({ number, percent, caption, isCta }) {
  return (
    <div className="profile-engagement-metrics--metric">
      <div className="profile-engagement-metrics-tv">
        <span className="profile-engagement-metrics--count">{number}</span>
        {percent && percentage(percent, isCta)}
      </div>
      <div className="profile-engagement-metrics--caption">{caption}</div>
    </div>
  );
}

function renderMetrics(thankviewMetrics) {
  const defaultCount = "--";
  const metrics = [
    { key: "sent", caption: "ThankViews Sent" },
    { key: "viewed", caption: "ThankViews Viewed", isPercent: true, base: "sent" },
    { key: "replied_to", caption: "ThankViews Replied To" },
    { key: "completed", caption: "ThankViews Completed", isPercent: true, base: "viewed" },
    { key: "shared", caption: "ThankViews Shared" },
    { key: "cta_interactions", caption: "CTA Interactions", isPercent: true, base: "viewed", isCta: true },
  ];

  return metrics.map((metric, index) => (
    <div className={`profile-engagement-metrics--column is-column-${(index % 2) + 1}`} key={metric.key}>
      {renderMetric({
        number: thankviewMetrics ? thankviewMetrics[metric.key].value : defaultCount,
        percent: metric.isPercent ? calculatePercentage(thankviewMetrics, metric.key, metric.base) : undefined,
        isCta: metric.isCta || false,
        caption: metric.caption,
      })}
    </div>
  ));
}

export function calculatePercentage(thankviewMetrics, metricKey, baseKey) {
  if (!thankviewMetrics || thankviewMetrics[metricKey].value === 0) {
    return "--";
  }

  const baseValue = thankviewMetrics[baseKey].value;
  if (baseValue > 0) {
    const percentage = (thankviewMetrics[metricKey].value / baseValue) * 100;
    return `${percentage.toFixed(1)}%`;
  }

  return "--";
}

function ThankViewMetricsCard({ data }) {
  const [thankviewMetrics, setThankviewMetrics] = useState(null);
  useEffect(() => {
    setThankviewMetrics(data.thankview_metrics);
  }, [data]);

  return (
    <>
      <>
        <ProfileCardSubtitle label="ThankView Videos" help_key="tv_engagements" help_position="left-top-aligned" />
        <div className="profile-engagement-metrics--content margin-bottom-dashboard">
          {renderMetrics(thankviewMetrics)}
        </div>
      </>
    </>
  );
}

ThankViewMetricsCard.propTypes = {
  data: PropTypes.object,
};

export default ThankViewMetricsCard;
