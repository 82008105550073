import { PropTypes } from "base/react-libs";
import RecommendedActionSection from "./recommended-action-section";

const GiftAnniversaryRecommendedActions = ({ enabled, recommendedActions = [] }) => {
  if (!enabled) return null;
  return (
    <RecommendedActionSection
      title="Upcoming gift anniversary in the next 30 days"
      subtitle="Last Gift"
      recommendedActions={recommendedActions}
      icon={"anniversary"}
      type="gift"
      emailButtonLabel="Say Thanks"
    />
  );
};

GiftAnniversaryRecommendedActions.propTypes = {
  enabled: PropTypes.bool,
  recommendedActions: PropTypes.array,
};

export default GiftAnniversaryRecommendedActions;
