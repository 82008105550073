import { PropTypes } from "base/react-libs";
import { useState, useEffect, useMemo } from "react";
import { percentOfTasksComplete } from "apps/journeys/utils/helpers/counters";
import TaskRowDetails from "./task-row-details";
import StepRowSummary from "./step-row-summary";

const StepRow = ({
  step = {},
  journey,
  isFinalStep,
  userIsCreator,
  openContactSummary,
  optionalStateManagementFunction = () => {},
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  useEffect(() => setIsExpanded(false), [step]);
  const string = JSON.stringify(step.tasks);
  const completionPercentage = useMemo(() => {
    if (string) return percentOfTasksComplete(step.tasks);
    else return 0;
  }, [step, string]);

  return (
    <div className="journeys-task-table--row">
      <StepRowSummary
        step={step}
        journey={journey}
        isExpanded={isExpanded}
        userIsCreator={userIsCreator}
        setIsExpanded={setIsExpanded}
        completionPercentage={completionPercentage}
        optionalStateManagementFunction={optionalStateManagementFunction}
      />
      {isExpanded && (
        <TaskRowDetails
          step={step}
          journey={journey}
          isFinalStep={isFinalStep}
          openContactSummary={openContactSummary}
          optionalStateManagementFunction={optionalStateManagementFunction}
        />
      )}
    </div>
  );
};

StepRow.propTypes = {
  step: PropTypes.object,
  journey: PropTypes.object,
  isFinalStep: PropTypes.bool,
  userIsCreator: PropTypes.bool,
  openContactSummary: PropTypes.func,
  optionalStateManagementFunction: PropTypes.func,
};

export default StepRow;
