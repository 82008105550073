module.exports = do ->
   React = require("react")
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   {div, input} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   Icon = createFactory (require("components/elements/icon").default)
   EllipsisOverflow = createFactory require("components/formatting/ellipsis-overflow")
   OutsideClick = createFactory require("@evertrue/et-components").OutsideClick


   createComponent "ClickToInput",
      propTypes:
         value: ReactLibs.PropTypes.string
         onSave: ReactLibs.PropTypes.func
         disable: ReactLibs.PropTypes.bool

      getInitialState: ->
         editMode: false

      handleLabelClick: ->
         unless @props.disable
            @setState {editMode: true}

      handleOutsideClick: ->
         if @state.editMode
            @props.onSave?(@refs.input.value)
            @setState {editMode: false}

      componentDidUpdate: ->
         if @state.editMode
            @refs.input.focus()

      render: ->
         OutsideClick onOutsideClick: this.handleOutsideClick,
            div className: classNames("click-to-input", "is-edit": @state.editMode, "is-disabled": @props.disable),
               if @state.editMode
                  input
                     type: "text"
                     ref: "input"
                     defaultValue: @props.value
                     className: "click-to-input--input"
                     onKeyUp: (e) =>
                        if e.key == "Enter"
                           @props.onSave?(e.target.value)
                           @setState {editMode: false}
               else
                  div className: "click-to-input--label", onClick: @handleLabelClick,
                        EllipsisOverflow className: "click-to-input--text", component: "h1", @props.value
                        Icon className: "click-to-input--pencil", icon: "edit"

               @props.children
