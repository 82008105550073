module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   React = require("react")
   {createFactory} = require("base/new-utils")
   PagedColumnTableHeader = createFactory require("components/tables/paged-col-table-header")
   PagedColumnTableBody = createFactory require("components/tables/paged-col-table-body")
   {table} = ReactLibs.DOMFactories

   PagedColumnTable = ReactLibs.createClass
      propTypes:
         className: ReactLibs.PropTypes.string
         columns: ReactLibs.PropTypes.array
         columnWidth: ReactLibs.PropTypes.oneOfType [
            ReactLibs.PropTypes.string
            ReactLibs.PropTypes.number
         ]
         rows: ReactLibs.PropTypes.func
         visible: ReactLibs.PropTypes.oneOfType [
            ReactLibs.PropTypes.number
            ReactLibs.PropTypes.string
         ]
         onRowClick: ReactLibs.PropTypes.func
         onSort: ReactLibs.PropTypes.func
         sortable: ReactLibs.PropTypes.oneOfType [
            ReactLibs.PropTypes.bool
            ReactLibs.PropTypes.string
         ]
         emptyMessage: ReactLibs.PropTypes.any

      getInitialState: ->
         start: 0

      componentDidMount: ->
         $(window).resize @respond
         @respond()

      componentWillUnmount: ->
         clearTimeout(@responsiveDelay)
         $(window).off "resize", @respond

      UNSAFE_componentWillReceiveProps: ->
         @respond()

      respond: ->
         clearTimeout(@responsiveDelay)
         @responsiveDelay = _.delay =>
            # Get Width of Table and subtract sticky columns to determine space for other columns
            tableWidth = $(@refs.fullTable).width()
            numOfStickyCols = 0
            stickyColWidthTotal = _.reduce @props.columns, (memo, col) ->
               if col.sticky
                  numOfStickyCols += 1
                  memo + (col.width || 0)
               else memo
            , 0
            columnSpace = tableWidth - stickyColWidthTotal

            # Get all the columns that will fit within colSpace
            columnsWidth = 0
            visibleColumns = _.filter @props.columns, (config) =>
               nextWidth = columnsWidth + parseInt(config.width || (@props.columnWidth || 175))
               return false if (nextWidth >= columnSpace) || config.sticky
               columnsWidth = nextWidth
               true

            # Reset the starting column if paged to the end
            numberOfCols = (visibleColumns.length + numOfStickyCols)
            start = @state.start
            if @props.columns.length <= (@state.start + numberOfCols)
               start = @props.columns.length - numberOfCols

            @setState visible: numberOfCols, start: start
         , 200

      getVisibleColumns: ->
         sticky = _.filter @props.columns, (col) -> col.sticky
         start = @state.start + sticky.length
         last = start + ((@state.visible || @props.visible) - sticky.length - 1)
         headers = _.flatten [sticky, @props.columns[start..last]]

      handleNext: ->
         last = @props.columns.length - (@state.visible || @props.visible)
         @setState start: Math.min(@state.start + 1, last)

      handlePrev: ->
         @setState start: Math.max(@state.start - 1, 0)

      render: ->
         classes = "table table-hover table-sort paged-col-table #{@props.className}"
         sortProp = @props.sortData?.sortProp

         table className: classes, ref: "fullTable",
            PagedColumnTableHeader
               visibleColumns: @getVisibleColumns()
               columns: @props.columns
               beginIndex: @state.start
               sortData: @props.sortData
               columnWidth: @props.columnWidth
               onPrev: @handlePrev
               onNext: @handleNext
               onSort: @props.onSort

            PagedColumnTableBody
               data: @props.data
               syncing: @props.syncing
               sortProp: sortProp
               emptyMessage: @props.emptyMessage
               cells: @props.rows
               columns: @getVisibleColumns()
               onRowClick: @props.onRowClick
