module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")

   COLUMN_SETTINGS_KEY = "GT_applied_prospect_columns"

   createSource "ProspectColumnSource",
      actions:
         fetchedColumns: (cols) ->
            @require _.isObject(cols) or _.isUndefined(cols), "cols should be an object or undefined"
         updatedColumns: (cols) ->
            @require _.isObject(cols), "cols should be an object"

      api:
         fetch: ->
            Api.DNA.SETTING.get
               urlArgs: {setting_key: COLUMN_SETTINGS_KEY}
               disableAlerts: true
               success: (response) =>
                  columns = response?.settings?[COLUMN_SETTINGS_KEY]
                  @actions.fetchedColumns(columns?.value)
               error: (err) =>
                  Raven.captureMessage "Error Fetching DNA Setting 'GT_applied_prospect_columns'", extra: {error: err}
                  @actions.fetchedColumns()

         update: (new_columns) ->
            # Optimistically update columns before dealing with DNA
            # DNA saving can be behind the scenes & w/o UI display
            @actions.updatedColumns(new_columns)
            Api.DNA.SETTING.put
               urlArgs: {setting_key: COLUMN_SETTINGS_KEY}
               disableAlerts: true
               params:
                  oid: EverTrue.store?.org?.get("id")
               data: _.jsonStringify
                  value: _.map(new_columns, (col) -> _.omit(col, ["value", "component"]))
               success: (response) =>
                  columns = response?.settings[COLUMN_SETTINGS_KEY]
                  @actions.updatedColumns(columns?.value)
