_ = require("underscore").default
React = require("react")
{div} = ReactLibs.DOMFactories
{createComponent} = require("@evertrue/et-flux")
{object, func} = ReactLibs.PropTypes
EngagementIcon = require("apps/profile/components/engagement-icon")
{createFactory} = require("base/new-utils")
ModalConfirm = createFactory require("components/modals/modal-confirm")
GiftsFeed = createFactory require("apps/profile/components/activity/gifts-feed").default
ActivityHelpers = require("apps/profile/components/activity/activity-helpers")
RelatedGiftsResolver = require("apps/profile/components/related-gifts-resolver")

renderGiftProperty = (label, value) ->
   div key: label, className: "gift-details-modal--fact",
      div className: "gift-details-modal--fact-label", label
      div className: "gift-details-modal--fact-value", value

formatListValue = (list = []) ->
   if _.notEmpty(list)
      _.map(list, (item) -> item.split(";").join("; ")).join(", ")
   else
      "Not provided"

module.exports =
   createComponent "GiftDetailsModal",
      propTypes:
         gift: object
         onHide: func

      getInitialState: ->
         show_id: false

      getDefaultProps: ->
         gift: {}
         onHide: _.noop

      handleToggleId: ->
         @setState (state) -> show_id: !state.show_id

      renderSummary: ->
         {occurred_at, type, amount, remote_id} = @props.gift

         div className: "gift-details-modal--summary",
            EngagementIcon
               className: "gift-details-modal--icon"
               size: "40px"
               icon: "gift"
            div className: "gift-details-modal--summary-content",
               div className: "gift-details-modal--type",
                  ActivityHelpers.formatGiftType(type)

               div
                  className: "gift-details-modal--date"
                  onDoubleClick: @handleToggleId
                  if @state.show_id
                     "GIFT ID: #{remote_id}"
                  else
                     ActivityHelpers.formatDate(occurred_at)

            div className: "gift-details-modal--amount",
               ActivityHelpers.formatGiftAmount(amount)

      renderNameLink: (gift) ->
         if gift.contact_id
            "/contact/#{gift.contact_id}/gifts"
         else null

      renderRelatedGifts: (gifts, loading) ->
         GiftsFeed
            items: gifts
            feedTitle: "RELATED GIFTS"
            loading: loading
            renderNameLink: @renderNameLink

      renderContents: ->
         {designations, designation_categories, campaigns, legal_credit, transaction_type} = @props.gift

         div className: "gift-details-modal--contents",
            @renderSummary()
            div className: "gift-details-modal--details",
               renderGiftProperty "Designations",
                  formatListValue(designations)

               renderGiftProperty "Designation Categories",
                  formatListValue(designation_categories)

               renderGiftProperty "Campaign Credit",
                  formatListValue(campaigns)

               renderGiftProperty "Transaction Type",
                  transaction_type || "Not provided"

               renderGiftProperty "Legal Credit",
                  if legal_credit then "Y" else "N"

               if @props.gift.related_gift_remote_id && @props.gift.type
                  RelatedGiftsResolver
                     related_gift_remote_id: @props.gift.related_gift_remote_id
                     type: @props.gift.type
                     render:({gifts, loading}) =>
                        @renderRelatedGifts(gifts, loading)

      render: ->
         ModalConfirm
            className: "gift-details-modal",
            onCancel: @props.onHide
            header: "Gift Detail"
            render: ({onHide}) =>
               @renderContents()









