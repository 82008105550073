import moment from "moment";
import numeral from "numeral";
import str from "underscore.string";

const formatDate = (date) => {
  if (!date) {
    return "-";
  } else if (moment(date).isAfter(moment().subtract(7, "day").format("YYYY-MM-DD"))) {
    return moment.utc(date).local().fromNow();
  } else {
    return moment.utc(date).format("MM/DD/YYYY");
  }
}

const formatFullDate = (date) => {
  if (!date) return "-";
  else return moment.utc(date).format("MMMM, DD, YYYY");
}

const formatTimestampToDate = (timestamp) => {
  if (!timestamp) return "-";
  return moment.utc(Number(timestamp)).format("MMMM, DD, YYYY");
}

const formatGiftAmount = (amount)  => {
  return numeral(amount).format("$0,000[.]00");
}

const formatGiftType = (type) => {
  switch (type.toLowerCase()) {
    case "hard_credit":
      return "Hard Credit";
    case "soft_credit":
      return "Soft Credit";
    case "pledge":
      return "Pledge";
    default:
      return str.humanize(type);
  }
}

export { formatDate, formatFullDate, formatTimestampToDate, formatGiftAmount, formatGiftType };
