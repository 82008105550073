import _ from "underscore";
import str from "underscore.string";
import PropTypes from "prop-types";
import { AdvancedCombobox, ModalFormBase, Checkbox, OverlayActions, BooleanRadio } from "@evertrue/et-components";
import GivingCategorySelector from "apps/volunteers/components/giving-category-selector";
import FormField from "components/forms/form-field";
import StageGroupSelector from "apps/volunteers/components/stage-group-selector";
import ConfirmResetPoolsModal from "apps/volunteers/components/confirm-reset-pools-modal";
import StageResolver from "apps/assignments/components/stage-resolver";

window.OverlayActions = OverlayActions;

const propTypes = {
  pools: PropTypes.array,
  handleSubmit: PropTypes.func,
  onClose: PropTypes.func,
};

const defaultProps = {
  pools: [],
};

const ResetPoolsModal = ({ pools, onClose, ...rest }) => {
  const pools_text = `pool${pools.length > 1 ? "s" : ""}`;
  return (
    <ModalFormBase
      {...rest}
      onClose={onClose}
      onUnmount={onClose}
      submitLabel={`Reset ${str.titleize(pools_text)}`}
      header={`Reset Pools (${pools.length} selected)`}
      style={{ width: 500 }}
      handleSubmit={(selected) => {
        const key = "my-key";
        onClose();
        OverlayActions.launchModal(
          <ConfirmResetPoolsModal selected={selected} pools={pools} onClose={() => OverlayActions.removeModal(key)} />,
          key
        );
      }}
      disableFunc={({
        unassign,
        stage,
        giving_category,
        self_selection,
        selection_mode,
        selected_stage_group,
        selected_stage,
        category_obj,
      }) => {
        const is_something_checked = [unassign, stage, giving_category, self_selection].some((i) => i);
        if (is_something_checked) {
          if (stage && selected_stage.stage && _.isEmpty(selected_stage_group)) {
            // not sure if possible to get here!
            return true;
          }
          if (giving_category && _.isEmpty(category_obj)) {
            return true;
          }
          if (self_selection && _.isUndefined(selection_mode)) {
            return true;
          }
          return false;
        }
        return true;
      }}
      data={{
        unassign: false,
        stage: false,
        giving_category: false,
        self_selection: false,
        selection_mode: undefined,
        selected_stage_group: {},
        selected_stage: {},
        category_obj: { value: null }, // for default (all data)
      }}
      render={({ state, setState }) => {
        const selected_group_stages = (!_.isEmpty(state.selected_stage_group) ? state.selected_stage_group.stages : [])
          .map((stage) => ({ ...stage, label: stage.stage, value: stage.stage, id: stage.id }))
          .concat({
            label: "No Stage Updates",
            value: null,
            id: null,
          });
        return (
          <div className="reset-pools-modal">
            <strong> Select the following options to bulk reset pools:</strong>
            <div className="reset-pools-modal--checkbox-section">
              <Checkbox
                onChange={(bool) => setState({ unassign: bool })}
                checked={state.unassign}
                label="Unassign All Assignments"
              >
                Unassign All Assignments
              </Checkbox>
              <Checkbox
                label="Allow volunteers to choose assignments"
                checked={state.self_selection}
                onChange={(bool) => {
                  setState({ self_selection: bool });
                  if (!bool) {
                    setState({ selection_mode: undefined });
                  }
                }}
              >
                Allow volunteers to choose assignments
              </Checkbox>
              <div className="reset-pools-modal--sub-section">
                <BooleanRadio
                  className="reset-pools-modal--radios"
                  name="selection_mode"
                  onChange={(_name, bool) => setState({ selection_mode: bool ? "SOLICITOR_SELECTION" : "disabled" })}
                  disabled={!state.self_selection}
                  value={
                    typeof state.selection_mode === "undefined"
                      ? undefined
                      : state.selection_mode === "SOLICITOR_SELECTION"
                  }
                />
              </div>
              <StageResolver>
                {({ stageGroups, hasStagesFeature }) => {
                  if (!hasStagesFeature) return null;
                  return !_.isEmpty(stageGroups) ? (
                    <div>
                      <Checkbox
                        label="Stage"
                        onChange={(bool) => {
                          setState({
                            stage: bool,
                            ...(!bool && {
                              selected_stage: {},
                              selected_stage_group: {},
                            }),
                          });
                        }}
                        checked={state.stage}
                      >
                        Stage
                      </Checkbox>
                      <FormField
                        className="reset-pools-modal--sub-section"
                        label={`Update all ${pools_text} to use stages from:`}
                        inlineLabel={false}
                      >
                        <div className="reset-pools-modal--stage-selector ">
                          <StageGroupSelector
                            placeholder="Select Stage Group"
                            disabled={!state.stage}
                            onChange={(selected_stage_group) => {
                              if (!_.isEmpty(selected_stage_group)) {
                                setState({ selected_stage: { id: null } });
                              }
                              setState({ selected_stage_group });
                            }}
                            selectedStageGroup={{ id: state.selected_stage_group.id }}
                          />
                          <FormField label="Reset all prospects to:" inlineLabel={false}>
                            <AdvancedCombobox
                              placeholder="Select Stage"
                              disabled={_.isEmpty(state.selected_stage_group)}
                              options={_.uniq(selected_group_stages, (stage) => stage.stage)}
                              onChange={(stage) => setState({ selected_stage: _.omit(stage, ["label", "value"]) })}
                              value={_.findWhere(selected_group_stages, { id: state.selected_stage.id })}
                            />
                          </FormField>
                        </div>
                      </FormField>
                    </div>
                  ) : null;
                }}
              </StageResolver>
              <Checkbox
                label="Giving Category"
                onChange={(bool) => {
                  setState({ giving_category: bool, category_obj: { value: null } });
                  if (!bool) setState({ category_obj: {} });
                }}
                checked={state.giving_category}
              >
                Giving Category
              </Checkbox>
              <FormField
                className="reset-pools-modal--sub-section"
                label={`Update all ${pools_text} to:`}
                inlineLabel={false}
              >
                <GivingCategorySelector
                  disabled={!state.giving_category}
                  value={state.category_obj.value}
                  onChange={(category_obj) => {
                    setState({ category_obj });
                  }}
                />
              </FormField>
            </div>
          </div>
        );
      }}
    />
  );
};

ResetPoolsModal.propTypes = propTypes;
ResetPoolsModal.defaultProps = defaultProps;

export default ResetPoolsModal;
