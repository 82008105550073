import { Button } from "@evertrue/et-components";
import Icon from "components/elements/icon";

const BackHeader = ({ children, handleBack }) => (
  <div className="back-header">
    <Button type="simple" title="Go back" onClick={handleBack}>
      <Icon icon="chevron-left" size={2} title="Go Back" />
    </Button>
    <div className="back-header--header-text">{children}</div>
  </div>
);

function go_back() {
  window.history.back();
}

BackHeader.propTypes = {
  handleBack: ReactLibs.PropTypes.func,
  children: ReactLibs.PropTypes.oneOfType([ReactLibs.PropTypes.element, ReactLibs.PropTypes.string]),
};

BackHeader.defaultProps = {
  handleBack: go_back,
  children: () => null,
};

export default BackHeader;
