import { useState } from "react";
import { PropTypes } from "base/react-libs";
import { useIdentity } from "base/identity-resolver";
import { trackJourneysAction } from "apps/journeys/utils/helpers/mixpanel";
import { moveConstituentToJourney } from "apps/journeys/utils/api-requests/modal-actions";
import { Modal, ModalHeaderWithActions, ModalBody, ListboxSelect } from "@evertrue/et-components";
import EverTrue from "app";
import FormField from "components/forms/form-field";
import JourneyContactCard from "apps/journeys/components/journey-contact-card";
import { createJourneyMove } from "apps/tasks/utils/journey-move-helper.js";

const ReasonOptions = [
  { label: "Adjusting cultivation strategy", value: 1 },
  { label: "Event/trip/giving day has concluded", value: 2 },
  { label: "Opted out", value: 3 },
  { label: "Unresponsive", value: 4 },
  { label: "Bad contact information", value: 5 },
  { label: "Transition to another portfolio", value: 6 },
  { label: "Other", value: 7 },
];

const RemoveFromJourneyModal = ({
  isOpen,
  onClose,
  contact = {},
  currentJourneys = [],
  optionalStateManagementFunction = () => {},
  refreshProfile = () => {},
}) => {
  const { journey_id, journey_name } = currentJourneys[0] || {}; // default form to first active journey
  const [currentJourney, setCurrentJourney] = useState({ label: journey_name, value: journey_id });
  const [reason, setReason] = useState();
  const [reasonDetails, setReasonDetails] = useState("");
  const { user, org } = useIdentity();

  const handleReasonChange = (newReason) => {
    // Reset reasonDetails if the reason is changed to something else
    if (newReason.value !== 7) {
      setReasonDetails("");
    }
    setReason(newReason);
  };

  const handleSubmit = async () => {
    try {
      const journeyId = currentJourney.value;
      await moveConstituentToJourney(journeyId, contact.id, user.id, org.id);
      await createJourneyMove(contact, "REMOVED_FROM_CADENCE", reason, reasonDetails, journeyId);
      trackJourneysAction("remove_cadence");
      optionalStateManagementFunction("removeContact", { contactIdToRemove: contact.id });
      refreshProfile((prev) => !prev);
      onClose();
    } catch (e) {
      EverTrue.Alert.error("Error removing constituent from cadence.");
    }
  };

  return (
    <Modal isOpen={isOpen} closeModal={onClose}>
      <ModalHeaderWithActions
        title={"Remove from Cadence"}
        closeModal={onClose}
        onSubmit={handleSubmit}
        submitLabel="Remove"
        disableSubmit={!reason || (reason.value === 7 && !reasonDetails)}
      />
      <ModalBody scrollable={false}>
        <p className="journey-modal--warning">
          You will be removing the constituent who has indicated they no longer want contact or are identified as bad
          fits for the program.
        </p>
        <p>Are you sure you want to remove this constituent completely from their cadence?</p>

        <JourneyContactCard contact={contact} avatarSize={60} />

        <FormField label="Current Cadence" boldLabel={true} inlineLabel={false}>
          <ListboxSelect
            emptyValue="Select Cadence"
            size="medium"
            options={currentJourneys.map(({ journey_name, journey_id }) => ({
              label: journey_name,
              value: journey_id,
            }))}
            onChange={setCurrentJourney}
            value={currentJourney}
            disabled={currentJourneys.length === 1} //no need to select if only one journey is present
            label="cadences"
          />
        </FormField>

        <FormField label="Reason" boldLabel={true} inlineLabel={false}>
          <ListboxSelect
            emptyValue="Select Reason..."
            showLabelIfMultipleSelected={true}
            size="medium"
            options={ReasonOptions}
            onChange={handleReasonChange}
            value={reason}
            label="reasons"
          />
        </FormField>
        {reason && reason.value === 7 && (
          <FormField label="Details" inlineLabel={false}>
            <input type="text" value={reasonDetails} onChange={(e) => setReasonDetails(e.currentTarget.value)} />
          </FormField>
        )}
      </ModalBody>
    </Modal>
  );
};

RemoveFromJourneyModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  contact: PropTypes.object,
  currentJourneys: PropTypes.array,
  optionalStateManagementFunction: PropTypes.func,
  refreshProfile: PropTypes.func,
};

export default RemoveFromJourneyModal;
