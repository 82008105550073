import MapRadius from "./map-radius";
import MapSearchBar from "./map-search-bar";
import MapZoomControl from "./map-zoom-control";
import { PropTypes } from "base/react-libs";

// Pass map filters as children to add map filters
const MapToolbar = ({ map, onMapCoordinateChange, children, onMapRadiusChange, radius }) => {
  return (
    <div className="map-toolbar">
      <div className="map-toolbar--top-left">
        <MapZoomControl map={map} />
      </div>
      <div className="map-toolbar--top-right">
        {children}
        <MapRadius map={map} onMapRadiusChange={onMapRadiusChange} radius={radius} />
        <MapSearchBar map={map} onMapCoordinateChange={onMapCoordinateChange} />
      </div>
    </div>
  );
};

MapToolbar.propTypes = {
  map: PropTypes.object,
  children: PropTypes.object,
  onMapCoordinateChange: PropTypes.func,
  onMapRadiusChange: PropTypes.func,
  radius: PropTypes.string,
};

export default MapToolbar;
