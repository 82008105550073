module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   EverTrue = require("app")
   {createComponent} = require("@evertrue/et-flux")
   {div, strong, ul, li, span} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   Modal = createFactory require("components/modals/modal")
   ModalTrigger = createFactory require("components/modals/modal-trigger")
   ImpersonationModal = require("apps/impersonation/impersonation")
   ChangeOrgModal = require("apps/login/components/login-org-change")
   Avatar = require("components/elements/avatar")
   Icon = createFactory (require("components/elements/icon").default)
   GetMobileApp = require("apps/layout/components/get-mobile-app")
   redacter = require("base/redacter").default
   classNames = require("classnames")
   Popover2 = createFactory(require("@evertrue/et-components").Popover2)
   Button = createFactory(require("@evertrue/et-components").Button)
   SessionStore = require("base/session/session-store")
   resetFiltersEnvironment = require("base/reset-filters-environment").default


   _get_conditional_gates = (org, user) ->
      is_impersonating = EverTrue.request "isImpersonating?"
      avail_orgs = user?.getGivingTreeAffiliations()
      org_id = org.get("id")

      gates =
         impersonation: !!user.isSuperUser() && !(is_impersonating || !org_id)
         admin: user.isSuperUser() && org_id
         change_org: (avail_orgs.length > 1 || user.isSuperUser()) && !(is_impersonating || !org_id)
         change_env: EverTrue.config.switch_environment && !is_impersonating
         has_importer: user.hasConsole() || user.isSuperUser()
      gates


   createComponent "HeaderUserDropdown",
      propTypes:
         header: ReactLibs.PropTypes.func

      registerStores: ->
         @on SessionStore, ->
            user: SessionStore.getUser()

      getInitialState: ->
         is_open: false
         redacted: redacter.getRedacted()

      componentDidMount: ->
         redacter.bind(@onChange)

      componentWillUnmount: ->
         redacter.unbind(@onChange)

      onChange: ->
         @setState redacted: redacter.getRedacted()

      handleEnvSwitch: (e) ->
         resetFiltersEnvironment()
         e.preventDefault()
         e.stopPropagation()
         EverTrue.execute("switchEnvironment")

      handleLogout: (e) ->
         e.preventDefault()
         e.stopPropagation()
         EverTrue.execute "logout"

      render: ->
         session_type = EverTrue.store.session.get("type") || "UNSCOPED"
         session_oid = EverTrue.store.session.get("oid") || "N/A"
         user = EverTrue.store.user
         org = EverTrue.store.org

         menu_gates = _get_conditional_gates(org, user)
         internal_user = user.isInternal()
         isNotAffiliated = !user.hasGivingTreeAffiliationTo(org.get("id"))

         roles = _.pluck(user.getRoles(), "name") || []
         roles.push("Super User") if user.isSuperUser()

         user = user.toJSON()
         org = org.toJSON()

         isStageEnv = EverTrue.config.environment_data == "Stage"

         if isStageEnv
            alt_environment = "Production"
            change_password_link = "https://stage-accounts.evertrue.com/evertrue/account/password/change/"+org.slug
         else
            alt_environment = "Stage"
            change_password_link = "https://accounts.evertrue.com/evertrue/account/password/change/"+org.slug

         span className: "header-user-dropdown",
         # TODO: replace w/ ReachMenuButton from et-components for a11y
            Popover2
               contentWidth: 300
               trigger: ({togglePopover, is_open}) =>
                  Button type: "simple", title: "options for #{@state.user?.name}", className: "header-user-dropdown--trigger", onClick: togglePopover,
                     @state.user?.name
                     strong className: "header-user-dropdown--toggle",
                        Icon icon: if is_open then "chevron-up" else "chevron-down"
               children: =>
                  div className: "header-user-dropdown--body",

                     # User Avatar & Info
                     div className: "header-user-dropdown--info",
                        Avatar url: @state.user.user_profile_picture_url, className: "header-user-dropdown--avatar", ref: "avatar", name: user.name
                        div className: "header-user-dropdown--name",
                           strong null, user.name
                           div null, org.name

                           if internal_user
                              div className: "header-user-dropdown--org-info text-label",
                                 span null, org.id + " • " + org.slug
                                 if isNotAffiliated
                                    Icon icon: "report-problem", className: "icon-muted"


                     # User Details for Internal Users
                     if internal_user
                        div className: "header-user-dropdown--user-state",
                           strong null, "User:"
                           div null,
                              span className: "text-label", "ID: "
                              span null, user.id
                           div null,
                              span className: "text-label", "Email: "
                              span className: "fs-exclude-data", user.email
                           div null,
                              span className: "text-label", "Roles: "
                              span null, roles.join(", ")


                     ul className: "menu",
                        if menu_gates.change_env
                           li null,
                              Button type: "simple", title: "Toggle dev environment", onClick: @handleEnvSwitch,
                                 Icon icon: "exit-to-app"
                                 "Switch to #{alt_environment}"

                        if menu_gates.change_org
                           li null,
                              ModalTrigger modal: Modal(width: 450, ChangeOrgModal()), title: "Change organizations",
                                 Icon icon: "switch"
                                 "Change organizations"

                        if menu_gates.impersonation
                           li null,
                              ModalTrigger modal: Modal(width: 650, ImpersonationModal()), title: "Impersonate user",
                                 Icon icon: "account-switch"
                                 "Impersonate user"

                        li null,
                           Button type: "simple", href: "/settings", title: "Settings",
                              Icon icon: "settings"
                              "Settings"

                        li null,
                           Button type: "simple", href: "/data/exports", title: "Recent Exports",
                              Icon icon: "cloud-download"
                              "Recent Exports"

                        if menu_gates.has_importer
                           li null,
                              Button
                                 type: "simple"
                                 href: EverTrue.config.consoleBase
                                 title: "Console"
                                 hrefTarget: "_blank"
                                 "data-bypass": true
                                 Icon icon: "table-large"
                                 "Go To Console"

                        if menu_gates.admin
                           li null,
                              Button type: "simple", href: "/admin/gates/active", title: "super user admin",
                                 Icon icon: "build"
                                 "SuperUser Admin"

                        if menu_gates.admin
                           li null,
                              div null,
                                 Icon(icon: "misc")
                                 _.map redacter.options, ({label, key, className}) =>
                                    Button
                                       type: "simple"
                                       key: "style-" + key
                                       className: classNames("header-user-dropdown--redaction-button",
                                          "is-active": @state.redacted == key
                                       ),
                                       onClick: (-> redacter.set(key))
                                       span className: classNames("header-user-dropdown--redaction-example", className),
                                          label

                        li null,
                           ModalTrigger modal: Modal(width: 432, GetMobileApp()), title: "Get App Store Link",
                              Icon icon: "mobile"
                              "Get App Store Link"

                        if isStageEnv && user.password_set_at
                           li null,
                              Button type: "simple", href: change_password_link , title: "Change Password", "data-bypass": true,
                                 Icon icon: "change-password"
                                 "Change Password"

                        li null,
                           Button type: "simple", title: "sign out", onClick: @handleLogout,
                              Icon icon: "logout"
                              "Sign Out"
