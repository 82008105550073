import { Component } from "react";
import Api from "entities/helpers/api";
import _ from "underscore";
import Decorator from "clientDecorator";

class ContactsResolver extends Component {
  static propTypes = {
    query: ReactLibs.PropTypes.object,
    params: ReactLibs.PropTypes.object,
    render: ReactLibs.PropTypes.func,
  };

  state = {
    contacts: [],
    loading: false,
    total: null,
  };

  xhr = undefined;

  componentDidMount() {
    this.fetchContacts(this.props.params, this.props.query);
  }

  componentWillUnmount() {
    this.abortXHR();
    this.xhr = undefined;
  }

  abortXHR = () => {
    if (this.xhr && _.isFunction(this.xhr.abort)) this.xhr.abort();
  };

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.params, prevProps.params) || !_.isEqual(this.props.query, prevProps.query)) {
      this.fetchContacts(this.props.params, this.props.query);
    }
  }

  fetchContacts(params, query) {
    if (params && query) {
      this.setState({ loading: true });
      this.abortXHR();
      Api.CONTACTS.SEARCH.post({
        data: _.jsonStringify(query),
        params,
        beforeSend: xhr => {
          this.xhr = xhr;
        },
      }).then((resp = {}) => {
        this.setState({
          contacts: resp.items.map(item => Decorator.Contacts.parse(item)),
          total: resp.total,
          loading: false,
        });
      });
    }
  }

  render() {
    const { contacts, total, loading } = this.state;
    return this.props.render({
      contacts,
      total,
      loading,
    });
  }
}

export default ContactsResolver;
