import { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import EverTrue from "app";
import { Modal, ModalHeaderCustom, ModalBody, Loading, Button } from "@evertrue/et-components";
import AddUser from "apps/search/components/add-user";
import EditUserSharingPermissions from "apps/search/components/edit-user-sharing-permissions";
import BookmarkOrgSharing from "apps/search/components/bookmark-org-sharing";
import useFetchSearchPermissions from "apps/search/hooks/use-fetch-search-permissions";
import { BOOKMARK_PERMISSIONS } from "apps/search/utils/search-helper";
import Api from "entities/helpers/api";

const BookMarkSharingModal = ({ isOpen, onClose, id }) => {
  const { permissions, isLoading } = useFetchSearchPermissions(id);
  const [userPermissions, setUserPermissions] = useState([]);

  useEffect(() => {
    EverTrue.track.set("filter_v2_action", {
      type: "bookmark_open_share",
    });
  }, []);

  useEffect(() => {
    if (permissions.user_permissions) {
      setUserPermissions(permissions.user_permissions);
    }
  }, [permissions.user_permissions]);

  const handleAddUser = async (option) => {
    const payload = {
      name: option.label,
      user_id: option.value,
      permission: BOOKMARK_PERMISSIONS.VIEW,
    };

    try {
      const results = await Api.HUB.SEARCH_USER_PERMISSIONS.post({
        urlArgs: { search_id: id, user_id: option.value },
        data: JSON.stringify(payload),
      });

      EverTrue.track.set("filter_v2_action", {
        type: "bookmark_share_user",
        permission: BOOKMARK_PERMISSIONS.VIEW,
      });

      setUserPermissions(results);
    } catch {
      EverTrue.Alert.error("There was a problem adding user.");
    }
  };

  return (
    <Modal isOpen={isOpen} closeModal={onClose} size="small">
      <ModalHeaderCustom>
        <h3>Share Bookmark</h3>
        <Button onClick={onClose}>Done</Button>
      </ModalHeaderCustom>
      <ModalBody>
        <div className="bookmark-modal">
          {isLoading ? (
            <Loading className="bookmark-modal--loading" />
          ) : (
            <>
              <div className="bookmark-sharing-modal--heading">Shareable Link</div>
              <BookmarkOrgSharing id={id} orgPermissions={permissions.org_permission} />
              <div className="bookmark-sharing-modal--heading">Visibility &amp; Access</div>
              <AddUser
                searchId={id}
                setUserPermissions={setUserPermissions}
                excludedUserIds={userPermissions.flatMap((permission) => permission.user_id)}
                handleOnChange={handleAddUser}
              />
              <ul>
                {userPermissions
                  .filter((user) => user.permission !== "OWNER")
                  .map((user) => (
                    <EditUserSharingPermissions
                      key={user.user_id}
                      user={user}
                      searchId={id}
                      setUserPermissions={setUserPermissions}
                      referrer="bookmark"
                    />
                  ))}
              </ul>
            </>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

BookMarkSharingModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default BookMarkSharingModal;
