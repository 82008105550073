import { PropTypes } from "base/react-libs";
import { Button } from "@evertrue/et-components";

const TwilioCallButton = ({ device, activeCall, startCall, endCall }) => {
  return activeCall ? (
    <Button onClick={endCall} type="destroy" className="red-button">
      End
    </Button>
  ) : (
    <Button disabled={!!!device} onClick={startCall}>
      Call
    </Button>
  );
};

TwilioCallButton.propTypes = {
  device: PropTypes.object,
  activeCall: PropTypes.object,
  startCall: PropTypes.func,
  endCall: PropTypes.func,
};
export default TwilioCallButton;
