import { useCallback } from "react";
import _ from "underscore";
import PropTypes from "prop-types";
import { useFluxStore } from "@evertrue/et-flux";
import { AdvancedCombobox } from "@evertrue/et-components";
import AllUsersStore from "apps/users/stores/all-users-store";

// This is only used in filters
const InteractionSolicitorFilter = props => {
  // get all users from AllUsersStore
  const mapState = useCallback(() => {
    return {
      users: AllUsersStore.getAllGivingTreeUsers(),
    };
  }, []);
  const { users } = useFluxStore(AllUsersStore, mapState);

  // combine facets of users from interactions and all users from AllUsersStore
  const user_names = _.pluck(_.values(users), "name");
  const facet_names = _.pluck(props.facets, "value");
  const combined_user_names = _.union(user_names, facet_names).sort();
  const options = combined_user_names.map(user_name => ({ label: user_name, value: user_name }));

  return (
    <AdvancedCombobox
      value={props.value}
      searchable
      multiple
      options={options}
      onChange={props.onChange}
      allowChangeAll
      allowUnselectAll
    />
  );
};

InteractionSolicitorFilter.propTypes = {
  value: PropTypes.array,
  facets: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

InteractionSolicitorFilter.defaultProps = {
  facets: [],
};

export default InteractionSolicitorFilter;
