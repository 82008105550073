import { Icon, useOpenClose } from "@evertrue/et-components";
import HomepageCard from "../homepage-card";
import RecentGiftRecommendedActions from "./sections/recent-gift-recommended-actions";
import CareerMovesRecommendedActions from "./sections/career-moves-recommended-actions";
import GiftAnniversaryRecommendedActions from "./sections/gift-anniversary-recommended-actions";
import useRecommendedActionsUserSettings from "./hooks/use-recommended-action-user-settings";
import RecommendedActionUserSettingsModal from "./recommended-action-user-settings-modal";
import { useGate } from "components/is-gated";
import { useState, useEffect } from "react";
import SearchRecommendations from "./sections/recommended-searches";
import useRecommendedActions from "./hooks/use-recommended-actions";

const RecommendedActions = () => {
  const [isRecommendationsModalOpen, openRecommendationsModal, closeRecommendationsModal] = useOpenClose();
  const {
    submitUserSettings,
    enableGiftSection,
    enableAnniversarySection,
    enableCareerMovesSection,
    giftAmount,
    setUpdateId,
    setEnableGiftSection,
    setEnableAnniversarySection,
    setEnableCareerMovesSection,
    setGiftAmount,
  } = useRecommendedActionsUserSettings();
  const [hasWindfall] = useGate("windfall");
  const [enableRecommendedSearchSuggestions, setEnableRecommendedSearchSuggestions] = useState(false);

  //fetching details from the hook directly
  const { recommendedActions: careerMoveRecommendedActions } = useRecommendedActions(
    "careerMove",
    enableCareerMovesSection
  );
  const { recommendedActions: giftAnniversaryRecommendedActions } = useRecommendedActions(
    "giftAnniversary",
    enableAnniversarySection
  );
  const { recommendedActions: recentGiftRecommendedActions } = useRecommendedActions(
    "recentGift",
    enableGiftSection,
    giftAmount
  );

  useEffect(() => {
    // Check if any enabled section has data
    const hasDataInEnabledSections =
      (enableGiftSection && recentGiftRecommendedActions && recentGiftRecommendedActions.length > 0) ||
      (enableCareerMovesSection && careerMoveRecommendedActions && careerMoveRecommendedActions.length > 0) ||
      (enableAnniversarySection && giftAnniversaryRecommendedActions && giftAnniversaryRecommendedActions.length > 0);

    // If no enabled sections have data, show the SearchRecommendations section
    setEnableRecommendedSearchSuggestions(!hasDataInEnabledSections);
  }, [
    recentGiftRecommendedActions,
    careerMoveRecommendedActions,
    giftAnniversaryRecommendedActions,
    enableGiftSection,
    enableCareerMovesSection,
    enableAnniversarySection,
  ]);

  return (
    <>
      <h1 className="homepage-card-title">Your Assigned Constituents</h1>
      <HomepageCard
        leftHeading={
          <span className="homepage-recommendations-card-left">
            Recommended Actions
            <span className="homepage-recommendations-card-subtitle">
              Here are your assigned constituents with whom we recommend establishing a touchpoint.
            </span>
          </span>
        }
        rightHeading={
          <Icon icon="gear" size={1} onClick={openRecommendationsModal} className="homepage-settings-icon" />
        }
        body={
          <div>
            {enableRecommendedSearchSuggestions ? (
              <SearchRecommendations windfallEnabled={hasWindfall} />
            ) : (
              <>
                {enableGiftSection && (
                  <RecentGiftRecommendedActions
                    enabled={enableGiftSection}
                    giftAmount={giftAmount}
                    recommendedActions={recentGiftRecommendedActions}
                  />
                )}
                {enableCareerMovesSection && (
                  <CareerMovesRecommendedActions
                    enabled={enableCareerMovesSection}
                    recommendedActions={careerMoveRecommendedActions}
                  />
                )}
                {enableAnniversarySection && (
                  <GiftAnniversaryRecommendedActions
                    enabled={enableAnniversarySection}
                    recommendedActions={giftAnniversaryRecommendedActions}
                  />
                )}
              </>
            )}
          </div>
        }
      />
      <RecommendedActionUserSettingsModal
        isOpen={isRecommendationsModalOpen}
        onClose={closeRecommendationsModal}
        submitUserSettings={submitUserSettings}
        enableGiftSection={enableGiftSection}
        enableAnniversarySection={enableAnniversarySection}
        enableCareerMovesSection={enableCareerMovesSection}
        giftAmount={giftAmount}
        setUpdateId={setUpdateId}
        setEnableGiftSection={setEnableGiftSection}
        setEnableAnniversarySection={setEnableAnniversarySection}
        setEnableCareerMovesSection={setEnableCareerMovesSection}
        setGiftAmount={setGiftAmount}
      />
    </>
  );
};

export default RecommendedActions;
