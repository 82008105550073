import PropTypes from "prop-types";

const EventSearchSummary = ({ count }) => {
  return (
    <span>
      <strong>{count}</strong> engagements on <strong>All Events</strong>
    </span>
  );
};

EventSearchSummary.propTypes = {
  count: PropTypes.number,
};

export default EventSearchSummary;
