import { PropTypes } from "base/react-libs";
import { useOpenClose } from "@evertrue/et-components";
import { useEffect, useState } from "react";
import StepRow from "apps/journeys/components/detail/journey-detail-table/step-view/step-row/step-row";
import JourneyTaskModal from "apps/journeys/components/modals/journey-task-modal";
import EmptyStateMessage from "components/lists/empty-state-message";
import AddJourneyTaskButton from "apps/journeys/components/top-nav/add-journey-task-button";

const StepsTable = ({
  journey = {},
  userIsCreator = false,
  openContactSummary = () => {},
  optionalStateManagementFunction = () => {},
}) => {
  const emptySubtext = (
    <div>
      <p>
        Now, add steps for each constituent to progress through. Think about your ideal spacing and outreach type, and
        customize the cadence to your specific needs. Make sure to add enough details to the description of each step to
        help your team understand the intention behind each action!
      </p>
      <p>
        For inspiration and proven outreach strategies, check out the latest articles on the{" "}
        <a href="https://www.evertrue.com/blog/" target="_blank" rel="noreferrer">
          EverTrue blog
        </a>
      </p>
    </div>
  );
  const { steps: journeySteps } = journey;
  const [isOpen, openModal, closeModal] = useOpenClose();

  const generateEmptyStateMessage = () => {
    if (Object.keys(journey).length && !(journeySteps && journeySteps.length)) {
      return "This Cadence does not have any steps yet.";
    } else return "Please select a Cadence.";
  };

  //Groups journey steps by day number & asign to state
  // takes in [ {day_number: 1, ...}, {day_number: 1, ...}, {day_number: 2, ...}]
  // returns {1: [{day_number: 1, ...}, {day_number: 1, ...}], 2: [{day_number: 2, ...}]
  const [groupedSteps, setGroupedSteps] = useState([]);
  useEffect(() => {
    if (journeySteps) {
      const arrangedData = {};
      journeySteps.forEach((step) => {
        const { day_number } = step;
        if (!arrangedData[day_number]) {
          arrangedData[day_number] = [];
        }
        arrangedData[day_number].push(step);
      });
      setGroupedSteps(arrangedData);
    }
  }, [journeySteps]);

  return (
    <>
      {Object.keys(journey).length && journeySteps && journeySteps.length ? (
        <>
          {groupedSteps &&
            Object.entries(groupedSteps).map(([day, steps], i) => (
              <div key={`day-${day}`}>
                <div className="journeys-task-table-row--header">Day {day}</div>
                {steps &&
                  steps.map((step, subIndex) => (
                    <StepRow
                      key={`task-${step.id}`}
                      step={step}
                      journey={journey}
                      userIsCreator={userIsCreator}
                      isFinalStep={!!(i === Object.entries(groupedSteps).length - 1) && subIndex === steps.length - 1} // final step of final day
                      openContactSummary={openContactSummary}
                      optionalStateManagementFunction={optionalStateManagementFunction}
                    />
                  ))}
              </div>
            ))}
          {userIsCreator && (
            <AddJourneyTaskButton journey={journey} optionalStateManagementFunction={optionalStateManagementFunction} />
          )}
        </>
      ) : (
        <>
          {userIsCreator ? (
            <>
              <EmptyStateMessage
                className="journeys-task-table--empty-state"
                iconSize={2}
                icon="cadences"
                iconKit="gt2"
                text="You’ve successfully created a Cadence!"
                actionLabel="Add Step"
                children={emptySubtext}
                onAction={isOpen ? closeModal : openModal}
              >
                {emptySubtext}
              </EmptyStateMessage>
              <JourneyTaskModal
                isOpen={isOpen}
                onClose={closeModal}
                journey={journey}
                optionalStateManagementFunction={optionalStateManagementFunction}
              />
            </>
          ) : (
            <EmptyStateMessage size="page" text={generateEmptyStateMessage()} />
          )}
        </>
      )}
    </>
  );
};

StepsTable.propTypes = {
  journey: PropTypes.object.isRequired,
  openContactSummary: PropTypes.func,
  userIsCreator: PropTypes.bool,
  optionalStateManagementFunction: PropTypes.func,
};

export default StepsTable;
