import _ from "underscore";
import { createRef, Component, Fragment } from "react";
import EverTrue from "app";
import { connect } from "@evertrue/et-flux";
import PropTypes from "prop-types";
import { ActionsMenu, Button, Checkbox, Icon, Modal2 } from "@evertrue/et-components";
import PoolsStore from "apps/volunteers/stores/pools-store";
import PoolsSource from "apps/volunteers/sources/pools-source";
import PoolCard from "apps/volunteers/components/pool-card";
import EmptyStateMessage from "components/lists/empty-state-message";
import Loading from "components/elements/loading";
import Pagination from "components/controls/pagination";
import SearchBox from "components/forms/search-box";
import VolunteerPoolsTableController from "apps/volunteers/controllers/volunteer-pools-table-controller";
import ResetPoolsModal from "apps/volunteers/components/reset-pools-modal";
import BulkDeletePoolsModal from "apps/volunteers/components/bulk-delete-pools-modal";

const _debouncedSearch = _.debounce(PoolsSource.search, 300);

const BUTTON_OPTIONS = [
  { value: "cards", label: "Cards", icon: "cards-view" },
  { value: "table", label: "Table", icon: "table-large" },
];
const CARD_LIMIT = 12;
const TABLE_LIMIT = 50;

const mapStateToProps = () => ({
  pools: PoolsStore.getPoolsAsArray(),
  page: PoolsStore.getPage(),
  query: PoolsStore.getFilters(),
  loading: PoolsStore.getLoading(),
  limit: PoolsStore.getLimit(),
  pools_search_total: PoolsStore.getPoolsSearchTotal(),
});

class VolunteersPoolsController extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    pools: PropTypes.array,
    page: PropTypes.number,
    query: PropTypes.object,
    limit: PropTypes.number,
    pools_search_total: PropTypes.number,
    openCreatePoolModal: PropTypes.func.isRequired,
  };

  searchBoxRef = createRef();

  state = {
    selected: BUTTON_OPTIONS[0].value,
    selected_pools: [],
  };

  componentDidMount() {
    // set page from url, which triggers a fetch
    const page = EverTrue.UrlManager.get("poolPage");
    if (page) {
      PoolsSource.paginate(_.toNumber(page), true);
    } else {
      PoolsSource.fetchPools(undefined, true);
    }
    PoolsSource.fetchGivingCategories();
  }

  componentWillUnmount() {
    // Clear all pools so when one is deleted by
    // another user, we don't request data that doesn't exsit.
    PoolsSource.clear();
    PoolsSource.setLimit(CARD_LIMIT);
  }

  handlePageChange = (page) => {
    PoolsSource.paginate(page);
    EverTrue.UrlManager.set({ poolPage: page });
  };

  renderPagignation = () => (
    <div className="pools--pagination">
      <Pagination
        page={this.props.page}
        total={Math.ceil(this.props.pools_search_total / this.props.limit)}
        onChange={this.handlePageChange}
      />
    </div>
  );

  renderContent = () => {
    if (this.props.loading) {
      return <Loading />;
    } else if (_.isEmpty(this.props.pools)) {
      return (
        <div className="pools--empty">
          <EmptyStateMessage icon="group" text="No Pools Found">
            A pool is defined by a group of solicitable individuals and the volunteers responsible for soliciting them.
          </EmptyStateMessage>
          <Button title="create pool" onClick={this.props.openCreatePoolModal}>
            Create Pool
          </Button>
        </div>
      );
    } else if (this.props.pools_search_total === 0) {
      return (
        <div className="pools--empty">
          <EmptyStateMessage text="Your search didn't return any pools" />
        </div>
      );
    }

    return (
      <div>
        {this.state.selected !== "table" ? (
          <Fragment>
            <div className="pools--cards">
              {_.map(this.props.pools, (pool) => (
                <PoolCard key={pool.id} pool={pool} />
              ))}
            </div>
            {this.renderPagignation()}
          </Fragment>
        ) : (
          <VolunteerPoolsTableController
            selectedPools={this.state.selected_pools}
            handleSelectPool={(pool, bool) => {
              if (bool) {
                this.setState((state) => ({ selected_pools: [...state.selected_pools, pool] }));
              } else {
                this.setState((state) => ({
                  selected_pools: state.selected_pools.filter((selected_pool) => selected_pool.id !== pool.id),
                }));
              }
            }}
            footer={this.renderPagignation()}
            pools={this.props.pools}
          />
        )}
      </div>
    );
  };

  render() {
    const num_of_selected_pools = this.state.selected_pools.length;
    return (
      <div className="pools">
        <div className="pools--controls">
          {this.state.selected === "table" && (
            <div className="pools--bulk-actions" data-testid="actions-container">
              <Checkbox
                label="select pools"
                partial={!!(num_of_selected_pools && num_of_selected_pools < this.props.pools.length)}
                onChange={(bool) =>
                  this.setState({
                    selected_pools: bool ? this.props.pools : [],
                  })
                }
                checked={num_of_selected_pools ? this.props.pools.length === this.state.selected_pools.length : false}
              />
              <ActionsMenu
                disabled={!Boolean(num_of_selected_pools)}
                menuClassName="pool--bulk-menu"
                actionsText="Bulk Actions"
                trigger={({ is_open }) => (
                  <>
                    Bulk Actions
                    <Icon icon={is_open ? "chevron-up" : "chevron-down"} />
                  </>
                )}
                options={[
                  {
                    id: 1,
                    label: "Delete Selected Pools",
                    mountOnClick: ({ close, is_open }) => (
                      <BulkDeletePoolsModal isOpen={is_open} closeModal={close} pools={this.state.selected_pools} />
                    ),
                  },
                  {
                    id: 2,
                    label: "Reset Pools",
                    mountOnClick: ({ close, is_open }) => (
                      <Modal2 onClose={close} isOpen={is_open}>
                        <ResetPoolsModal onClose={close} onUnmount={close} pools={this.state.selected_pools} />
                      </Modal2>
                    ),
                  },
                ]}
              />
            </div>
          )}

          <SearchBox
            onClear={() => PoolsSource.search("")}
            value={this.props.query.name}
            placeholder="Search by pool name..."
            ref={this.searchBoxRef}
            onChange={_debouncedSearch}
          />

          {this.state.selected === "table" ? (
            <Button
              className="pools--button-select"
              type="simple"
              onClick={() => {
                PoolsSource.paginate(1);
                PoolsSource.setLimit(CARD_LIMIT);
                PoolsSource.fetchPools({ limit: CARD_LIMIT });
                this.setState({ selected: "cards", selected_pools: [] });
                PoolsSource.search("");
                // hack for uncontrolled searchbox
                this.searchBoxRef.current.handleClear();
              }}
            >
              <Icon icon="cards-view gt-icon-lg" />
              Cards
            </Button>
          ) : (
            <Button
              className="pools--button-select"
              type="simple"
              onClick={() => {
                PoolsSource.paginate(1);
                PoolsSource.setLimit(TABLE_LIMIT);
                PoolsSource.fetchPools({ limit: TABLE_LIMIT });
                this.setState({ selected: "table", selected_pools: [] });
                PoolsSource.search("");
                // hack for uncontrolled searchbox
                this.searchBoxRef.current.handleClear();
              }}
            >
              <Icon icon="table-large gt-icon-lg" />
              Table
            </Button>
          )}
        </div>
        <div className="pools--body">{this.renderContent()}</div>
      </div>
    );
  }
}

export default connect(VolunteersPoolsController, [PoolsStore], mapStateToProps);
