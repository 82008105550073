module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   moment = require("moment")
   {createComponent} = require("@evertrue/et-flux")
   {div, strong, span} = ReactLibs.DOMFactories
   ReportTable = require("apps/my-team/components/report-table")
   BarChart = require("components/charts/bar-chart")
   Legend = require("components/charts/legend")
   AreaChart = require("components/charts/area-chart")
   Loading = require("components/elements/loading")
   {createFactory} = require("base/new-utils")
   Icon = createFactory (require("components/elements/icon").default)
   ProfileCardHeader = createFactory require("apps/profile/components/profile-card-header").default
   str = require("underscore.string")
   LinkButton = createFactory(require("@evertrue/et-components").LinkButton)

   createComponent "ReportPageController",
      propTypes:
         title: ReactLibs.PropTypes.any.isRequired
         legend: ReactLibs.PropTypes.array
         loading: ReactLibs.PropTypes.bool
         chartData: ReactLibs.PropTypes.array
         chartType: ReactLibs.PropTypes.oneOf ["area", "bar"]
         chartProps: ReactLibs.PropTypes.object
         tableCellFormat: ReactLibs.PropTypes.func

      getDefaultProps: ->
         defaultHeight: 350

      getInitialState: ->
         selected_legend: "all"
         view_all: false

      UNSAFE_componentWillReceiveProps: (newProps) ->
         if !_.isEqual(newProps.legend, @props.legend)
            @setState {selected_legend: "all"}

      handleViewAllToggle: ->
         @setState {view_all: !@state.view_all}

      renderTootlip: (item) ->
         if @props.chartType == "area"
            div className: "report-page--tooltip",
               strong
                  className: "report-page--tooltip-value"
                  style: {color: item.style?.fill}
                  item.y
               div null,
                  strong style: {color: item.style?.fill}, item.series
               div className: "text-label",
                  moment(_.toNumber(item.x)).format("MMM YYYY")
         else
            div className: "report-page--tooltip",
               strong
                  className: "report-page--tooltip-value"
                  style: {color: item.style?.fill}
                  if @props.chartProps?.xFormat
                     @props.chartProps.xFormat(item.x)
                  else item.x
               div null,
                  strong style: {color: item.style?.fill}, item.series
               div className: "text-label", item.y

      render: ->
         chart_height = @props.defaultHeight
         chart_default_width = 600

         # Filter series based on label filters
         chart_data = @props.chartData
         if @state.selected_legend != "all"
            selected_legend_values = _.pluck(@state.selected_legend, "value")
            chart_data = _.filter @props.chartData, (data) ->
               _.contains(selected_legend_values, data.series)

         y_axis_values = _.uniq(_.pluck(chart_data, "y"))
         if _.size(y_axis_values) > 15
            chart_overflows = true
            if @state.view_all
               chart_height = _.size(y_axis_values) * 22
            else
               initial_data = _.first(y_axis_values, 15)
               chart_data = _.filter chart_data, (data) ->
                  _.contains(initial_data, data.y)


         div className: "report-page",
            div className: "report-page--chart",
               div className: "report-page--title",
                  ProfileCardHeader
                     title: @props.title
                     icon: "insert-chart"

               div className: "report-page--question",
                  @props.question

               if @props.loading then Loading()

               switch @props.chartType
                  when "area"
                     AreaChart _.extend {},
                        data: chart_data
                        height: chart_height
                        defaultWidth: chart_default_width
                        tooltipContent: @renderTootlip
                        xFormat: (value, interval) ->
                           if interval in ["day", "week"]
                              moment(value).format("MMM DD, YYYY")
                           else moment(value).format("MMM YYYY")
                     , @props.chartProps
                  else
                     BarChart _.extend {},
                        data: chart_data
                        height: chart_height
                        horizontal: true
                        stacked: true
                        defaultWidth: chart_default_width
                        padding: {left: 150}
                        tooltipContent: @renderTootlip
                        yFormat: (value) ->
                           str.prune(value, 20)
                     , @props.chartProps

               if chart_overflows
                  LinkButton
                     title: "Toggle additional data"
                     className: "report-page--view-all"
                     onClick: @handleViewAllToggle

                     if @state.view_all
                        span null,
                           "View Less"
                           Icon icon: "chevron-up"
                     else
                        span null,
                           "View More"
                           Icon icon: "chevron-down"

               if @props.legend
                  Legend
                     className: "report-page--legend"
                     series: @props.legend
                     value: if @state.selected_legend == "all" then @props.legend else @state.selected_legend
                     onChange: (val) =>
                        @setState {selected_legend: val}

            div className: "report-page--control",
               if @props.onExport
                  LinkButton title: "Download report data", onClick: @props.onExport,
                     Icon icon: "cloud-download", before: true
                     "Export Report Data"

            div className: "report-page--table",
               if @props.loading then Loading()

               ReportTable
                  data: @props.tableData
                  cellFormat: @props.tableCellFormat
