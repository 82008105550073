import { PropTypes } from "base/react-libs";
import numeral from "numeral";
import PieChartGraph from "apps/query-builder/components/pie-chart-graph";
import colors from "base/colors";

const ResultsCount = ({ totalCount, resultCount, start, limit, label = "", className }) => {
  const fromRecord = start + 1;
  const toRecord = resultCount < start + limit ? resultCount : start + limit;
  const recordRangeText = resultCount <= 1 ? `${resultCount} of` : `${fromRecord} - ${toRecord} of`;

  return (
    <div className={`results-count ${className}`}>
      <PieChartGraph
        className="table-toolbar-pie filters-pie-chart"
        totalValue={totalCount}
        data={[{ title: "Filtered Amount", value: resultCount, color: colors.green200 }]}
        startAngle={-90}
      />
      {typeof start !== "undefined" && typeof limit !== "undefined" ? recordRangeText : null}
      <strong className="table-toolbar-amount">{numeral(resultCount).format("0,0")}</strong>
      {label && <div className="results-count-label">{label}</div>}
    </div>
  );
};

ResultsCount.propTypes = {
  totalCount: PropTypes.number.isRequired,
  resultCount: PropTypes.number.isRequired,
  start: PropTypes.number,
  limit: PropTypes.number,
  label: PropTypes.string,
  className: PropTypes.string,
};

export default ResultsCount;
