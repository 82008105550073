import moment from "moment";
import EverTrue from "app";
import _ from "underscore";

//hardcoded for now, double render on form causing validation to break
const ACTION_TYPES = [
  { value: "OTHER", label: "Other" },
  { value: "RESEARCH", label: "Research" },
  { value: "CALL", label: "Call" },
  { value: "THANK_VIEW", label: "ThankView" },
  { value: "LINKEDIN", label: "LinkedIn" },
  { value: "PRINT_MAIL", label: "Print Mail (e.g. Pledgemine, other)" },
  { value: "TEXT", label: "Text" },
  { value: "EMAIL", label: "Email" },
];

export const isPastDate = (date) => {
  const dueDate = moment.unix(date).format("YYYY-MM-DD");
  return moment(dueDate).isBefore(moment().format("YYYY-MM-DD"));
};

export const daysFromNow = (date) => {
  if (date) {
    const daysFromNow = moment.unix(date).startOf("day").diff(moment().startOf("day"), "days");

    switch (true) {
      case daysFromNow === 0:
        return { date: "Today" };
      case daysFromNow === 1:
        return { date: "Tomorrow" };
      case daysFromNow > 1:
        return { date: moment.unix(date).format("ddd MMM D, YYYY") };
      case daysFromNow === -1:
        return { date: moment.unix(date).format("ddd MMM D, YYYY"), msg: `${Math.abs(daysFromNow)} day overdue` };
      case daysFromNow < 0:
        return { date: moment.unix(date).format("ddd MMM D, YYYY"), msg: `${Math.abs(daysFromNow)} days overdue` };
      default:
        return "-";
    }
  }
};

export const formattedDate = (date) => {
  return moment.unix(date).format("MMM D, YYYY");
};

export const getEmptyStateMessageText = (userType, statuses) => {
  if (!userType) {
    if (statuses.includes("COMPLETED")) {
      return "There are no completed tasks for this constituent";
    } else {
      return "You’re at a Fresh Start!";
    }
  } else {
    if (statuses.includes("COMPLETED")) {
      if (userType === "OWNER") {
        return "You haven’t completed any tasks yet";
      } else {
        return "You don't have any completed tasks assigned to others";
      }
    } else {
      if (userType === "OWNER") {
        return "You’re at a Fresh Start!";
      } else {
        return "You haven’t assigned any tasks yet";
      }
    }
  }
};

export const getEmptyStateMessageSubtext = (userType, statuses) => {
  if (!userType) {
    if (statuses.includes("COMPLETED")) {
      return "Tasks marked as complete will appear here.";
    } else {
      return `Begin by clicking "Add Task" to create a task for this profile.`;
    }
  } else {
    if (statuses.includes("COMPLETED")) {
      return "Tasks marked as complete will appear here.";
    } else {
      if (userType === "OWNER") {
        return `Begin by clicking "Add Task" to create a task.`;
      } else {
        return `Begin by clicking "Add Task" to create a task for others in your team.`;
      }
    }
  }
};

export const trackTaskAction = (type, condition) => {
  const track = (type) => {
    EverTrue.track.set("tasks", { type });
  };
  const { pathname } = window.location;

  switch (type) {
    case "complete_task":
      if (pathname === "/tasks") {
        track("complete_task_from_task_screen");
      } else if (pathname.includes("/contact")) {
        track("complete_task_from_profile");
      }
      break;
    case "edit_task":
      if (pathname === "/tasks") {
        track("edit_task_task_page");
      } else if (pathname.includes("/contact")) {
        track("edit_task_profile");
      }
      break;
    case "delete_task":
      if (pathname === "/tasks") {
        track("delete_task_task_page");
      } else if (pathname.includes("/contact")) {
        track("delete_task_profile");
      }
      break;
    case "Completed Tasks":
      if (pathname === "/tasks") {
        track("view_complete_task_task_page");
      } else if (pathname.includes("/contact")) {
        track("view_complete_tasks_profile");
      }
      break;
    case "Incomplete Tasks":
      if (pathname === "/tasks") {
        track("view_inprogress_tasks_task_page");
      } else if (pathname.includes("/contact")) {
        track("view_inprogress_tasks_profile");
      }
      break;
    case "All Tasks":
      if (pathname === "/tasks") {
        track("view_all_tasks_task_page");
      } else if (pathname.includes("/contact")) {
        track("view_all_tasks_profile");
      }
      break;
    case "update_notification":
      if (condition) {
        track("create_task_email_notification");
      } else {
        track("disable_task_email_notification");
      }
      break;
    case "add_task":
      if (pathname.includes("home")) {
        EverTrue.track.set("home_screen", { type: "add_task" });
      }
      break;
    default:
      track(type);
      break;
  }
};

/**
 * Takes optional arguments, destructures, and sets defaults for task form.
 */
export const buildTaskDefaults = (taskObj, user = {}, profileContact) => {
  const { id: userId, name: userName } = user;
  const {
    id,
    title = "",
    description,
    action_type,
    owner_user_id = null,
    due_date,
    contact_journey_id,
    contact,
    owner_info: { ownerName = "" } = {},
  } = taskObj;

  const owner = { value: owner_user_id, label: ownerName };
  //if owner is current user add (You) to name
  if (owner_user_id === userId) {
    owner.label = userName + " (You)";
  } else if (owner_user_id === null) {
    owner.value = userId;
    owner.label = userName + " (You)";
  }

  const targetContact = getContactData(contact, profileContact);
  const actionType = action_type ? ACTION_TYPES.find(({ value }) => value === action_type) : null;

  const taskDefaults = {
    title,
    description: description || "",
    due_date: due_date ? due_date * 1000 : moment.now(),
    owner,
    actionType,
    targetContact,
  };

  const suggestedTaskDefaults = {
    title,
    description: description || "",
    due_date: moment().add(1, "week").valueOf(),
    owner,
    actionType,
    targetContact,
  };

  return {
    id,
    contact_journey_id,
    taskDefaults,
    suggestedTaskDefaults,
  };
};

/**
 * Destructures form values and creates a payload
 * Updates due_date to unix timestamp in seconds
 */
export const setTaskPayload = (formValues) => {
  const {
    title,
    description,
    due_date,
    targetContact,
    actionType: { value: action_type } = {},
    owner: { value: owner_user_id = null } = {},
  } = formValues;

  // Constuent picker takes null object, so this is the workaround.
  const target_contact_id = targetContact ? targetContact.id : null;

  const payload = {
    title,
    description,
    action_type,
    owner_user_id,
    target_contact_id,
    due_date,
    completed_at: null,
    deleted_at: null,
  };

  // update due_date
  if (due_date) {
    const dueDateInSeconds = Math.floor(due_date / 1000);
    let formattedTime = moment
      .unix(dueDateInSeconds)
      .hour(12 + moment.unix(dueDateInSeconds).utcOffset() / 60)
      .minute(0)
      .second(0);
    formattedTime = Math.floor(formattedTime.valueOf() / 1000);
    payload.due_date = formattedTime;
  }
  payload.title = payload.title.trim();
  payload.description = payload.description.trim();

  return payload;
};

// Returns contact data for task to match GQL schema
const getContactData = (taskContactObject, profileContactObject) => {
  if (!_.isEmpty(taskContactObject)) {
    const {
      id,
      contact_attributes: { name_full = "" },
    } = taskContactObject;
    const avatar = taskContactObject.profile_image ? taskContactObject.profile_image.url : "";
    return { id, name: name_full, avatar };
  } else if (!_.isEmpty(profileContactObject)) {
    const { id, name, avatar } = profileContactObject;
    return { id, name, avatar };
  } else {
    return null;
  }
};

const containsAll = (arr1, arr2) => arr2.every((arr2Item) => arr1.includes(arr2Item));

export const arraysMatch = (arr1, arr2) => containsAll(arr1, arr2) && containsAll(arr2, arr1);
