import { useFluxStore } from "@evertrue/et-flux";
import OrgStore from "base/org/org-store";

const mapStateToProps = () => ({
  org: OrgStore.getCurrentOrg(),
});
export const useOrg = () => {
  const { org } = useFluxStore(OrgStore, mapStateToProps);
  return org;
};

const OrgResolver = ({ render }) => {
  const org = useOrg();
  return render({ org });
};
OrgResolver.displayName = "OrgResolver";
OrgResolver.propTypes = {
  render: ReactLibs.PropTypes.func.isRequired,
  org: ReactLibs.PropTypes.object,
};
OrgResolver.defaultProps = {
  render: () => null,
  org: {},
};

export { OrgResolver };

const WithOrg = WrappedComponent => props => (
  <OrgResolver render={({ org }) => <WrappedComponent org={org} {...props} />} />
);

export default WithOrg;
