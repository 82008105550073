module.exports = do ->
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   AlertStore = require("apps/layout/stores/alert-store")
   {div} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   Modal = createFactory require("components/modals/modal")
   ModalConfirm = createFactory require("components/modals/modal-confirm")
   AlertController = require("apps/layout/controllers/alert-controller")
   ExportToastController = require("apps/export/controllers/export-toast-controller")
   MfaController = require("base/mfa/mfa-controller")
   MyPortfolioSlidesController = require("apps/major-gifts/controllers/my-portfolio-slides-controller")
   OverlayController = require("apps/layout/controllers/overlay-controller")
   SessionStore = require("base/session/session-store")
   FeatureStore = require("apps/layout/stores/feature-store").default
   LinkedinPromptController = require("apps/login/controllers/linkedin-prompt-controller")
   BulkAddController = createFactory(require("apps/layout/controllers/bulk-add-controller").default)
   OrgStore = require("base/org/org-store").default
   BeamerConnector = createFactory require("base/beamer/beamer-connector").default
   ETComponentsOverlayController = createFactory(require("@evertrue/et-components").OverlayController)
   {Loading} = require("@evertrue/et-components")
   LoadingElement = createFactory(Loading)
   SuspenseElement = createFactory(React.Suspense)

   # Global App elements that could be displayed on any page
   createComponent "MetaController",
      registerStores: ->
         @on AlertStore, ->
            confirm: AlertStore.getConfirm()

         @on SessionStore, ->
            is_authed: !!SessionStore.getToken()
            lids_is_unauthorized: SessionStore.getIsLidsUnauthorized()

         @on FeatureStore, ->
            has_linkedin_reauth: FeatureStore.hasFeature("linkedin_reauth")

         @on OrgStore, ->
            is_sso_org: OrgStore.getCurrentOrg() && OrgStore.getCurrentOrg().sso_method == "saml"

      render: ->
         {confirm, has_linkedin_reauth,is_authed,lids_is_unauthorized, is_sso_org} = @state
         showPrompt = has_linkedin_reauth && is_authed && lids_is_unauthorized && !is_sso_org
         div null,
            SuspenseElement fallback: LoadingElement(),
               AlertController()
               ExportToastController()
               MyPortfolioSlidesController()
               OverlayController()
               ETComponentsOverlayController()
               BulkAddController()
               if showPrompt then LinkedinPromptController()
               MfaController()
               BeamerConnector()

               if confirm
                  {width, type, headline, buttonLabel, cancelLabel, onConfirm, onCancel, content} = confirm
                  div className: "modal-container",
                     Modal
                        width: width || 384 # design spec for modal alert
                        keepOpen: true
                        ModalConfirm
                           type: type
                           header: headline
                           buttonLabel: buttonLabel || "OK"
                           cancelLabel: cancelLabel
                           hasMargin: true
                           hide_close_icon: true
                           onSubmit: onConfirm
                           onCancel: onCancel

                           content
