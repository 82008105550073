import { createContext, useContext, Component } from "react";
import PropTypes from "prop-types";
import Api from "entities/helpers/api";
import _ from "underscore";
import { getContactsForPortfolioQuery } from "apps/portfolio-performance/portfolio-queries";
import { lastInteractionQuery } from "apps/portfolio-performance/hooks/use-last-interaction";

const LIMIT = 15;
const ContactAssignmentContext = createContext(() => {});

const gql = String.raw;

const graphql_query = gql`
  query ($ids: [BigInt!]) {
    contacts(ids: $ids) {
      id
      profile_image {
        url
        type
      }
      phones {
        type
        phone
        primary
      }
      emails {
        type
        email
        primary
      }
      addresses {
        type
        primary
        address_1
        address_2
        address_3
        city
        state
        zip_code
        country
      }
      contact_attributes {
        name_full
      }
      giving {
        lifetime_amount
        last_gift_date
      }
    }
  }
`;

const parseContact = (contact) => {
  // on the contact card it can look for contact.name or contact.avatar but ideally
  // we should update graphql to give a detailed name like what the contact card was using previously
  // return { ...contact, name: contact.contact_attributes.name_full, avatar: contact.profile_image_url };

  return {
    ...contact,
    name: contact.contact_attributes.name_full,
    avatar: contact.profile_image ? contact.profile_image.url : null,
    phones: contact.phones.map((oldPhone) => {
      return {
        phone: { value: oldPhone.phone },
        primary: { value: oldPhone.primary },
        type: { value: oldPhone.type },
      };
    }),
    emails: contact.emails.map((oldEmail) => {
      return {
        email: { value: oldEmail.email },
        primary: { value: oldEmail.primary },
        type: { value: oldEmail.type },
      };
    }),
    addresses: contact.addresses.map((oldAddress) => {
      return {
        address_1: { name: "address_1", value: oldAddress.address_1 },
        address_2: { name: "address_2", value: oldAddress.address_2 },
        address_3: { name: "address_3", value: oldAddress.address_3 },
        city: { name: "city", value: oldAddress.city },
        country: { name: "country", value: oldAddress.country },
        state: { name: "state", value: oldAddress.state },
        zip_code: { name: "zip_code", value: oldAddress.zip_code },
        primary: { name: "primary", value: oldAddress.primary },
        type: { name: "type", value: oldAddress.type },
      };
    }),
  };
};

class ContactAssignmentProvider extends Component {
  static propTypes = {
    sortProp: PropTypes.string,
    sortReverse: PropTypes.bool,
    team: PropTypes.object,
    solicitor_id: PropTypes.number,
    children: PropTypes.any,
    setTotal: PropTypes.func,
    stages: PropTypes.array,
  };

  static defaultProps = {
    team: {},
    solicitor_id: null,
    sortReverse: false,
    stages: [],
    setTotal: _.noop,
  };

  is_mounted = null;

  componentDidMount() {
    this.is_mounted = true;
  }

  componentWillUnmount() {
    this.is_mounted = false;
  }

  safeSetState = (...args) => {
    if (this.is_mounted) this.setState(...args);
  };

  // this is only used by the map could be moved to that controller

  // fetch contacts and assignments for several stages at once
  fetchContactsByStages = async ({
    sort_prop = this.props.sortProp,
    sort_reverse = this.props.sortReverse,
    pool_id = this.props.team.id,
    solicitor_id = this.props.solicitor_id,
    stages,
    bounds,
    offset,
    center,
    radius,
  }) => {
    if (!solicitor_id || !pool_id) {
      return;
    }
    this.setState({ loading: true });
    const query = getContactsForPortfolioQuery(
      sort_prop,
      sort_reverse,
      pool_id,
      solicitor_id,
      _.makeArray(stages),
      bounds,
      this.state.filters,
      center,
      radius
    );

    // load contacts
    const { items: contacts, total } = await this.fetchContacts({ query, offset });

    // fetch assignments
    this.fetchAssignments({
      poolId: pool_id,
      contactIds: contacts.map((contact) => contact.id),
      solicitorContactId: solicitor_id,
    }).then((assignments_resp) => {
      const prospects_with_assignments = [];
      _.each(contacts, (contact) => {
        if (!_.findWhere(prospects_with_assignments, { id: contact.id })) {
          prospects_with_assignments.push({
            id: contact.id,
            contact: contact,
            assignment: _.findWhere(assignments_resp.items, { prospect_contact_id: contact.id }),
          });
        }
      });
      // return { prospect_assignment_results: prospects_with_assignments, total: search_resp.total };
      if (this.is_mounted) {
        this.props.setTotal(total);
        this.setState({
          prospects_with_assignments: prospects_with_assignments,
          total,
          loading: false,
        });
      }
    });
  };

  // fetch contacts and assignments for a single stage
  fetchContactsByStage = async ({
    sort_prop = this.props.sortProp,
    sort_reverse = this.props.sortReverse,
    pool_id = this.props.team.id,
    solicitor_id = this.props.solicitor_id,
    stage,
    bounds,
    offset,
    more = false,
  }) => {
    if (!solicitor_id || !pool_id) {
      return;
    }

    // set initial states
    this.setState((prev) => {
      const state = { loading_by_stage: { ...prev.loading_by_stage, [stage]: true } };
      // if we aren't loading more, reset state so we get loading spinner over entire column
      if (!more) {
        state.prospects_with_assignments_by_stage = { ...prev.prospects_with_assignments_by_stage, [stage]: [] };
      }
      return state;
    });

    const query = getContactsForPortfolioQuery(
      sort_prop,
      sort_reverse,
      pool_id,
      solicitor_id,
      _.makeArray(stage),
      bounds,
      this.state.filters
    );

    // load contacts
    const { items: contacts, total } = await this.fetchContacts({ query, offset });

    // set state to let cards start rendering
    this.safeSetState((prev) => {
      const totalObj = { ...prev.total_by_stage, [stage]: total };
      this.props.setTotal(Object.values(totalObj).reduce((acc, curr) => curr + acc, 0));

      // data is stored like {id: 1, contact, assignment}
      const formatted_contacts = contacts.map((contact) => ({ id: contact.id, contact }));
      const contacts_by_stage = more
        ? [...prev.prospects_with_assignments_by_stage[stage], ...formatted_contacts]
        : formatted_contacts;

      return {
        prospects_with_assignments_by_stage: {
          ...prev.prospects_with_assignments_by_stage,
          [stage]: contacts_by_stage,
        },
        total_by_stage: totalObj,
        loading_by_stage: { ...prev.loading_by_stage, [stage]: false },
      };
    });

    if (contacts.length) {
      const contactIds = contacts.map((contact) => contact.id);

      const [assignmentsResp, interactionsResponse] = await Promise.all([
        this.fetchAssignments({ poolId: pool_id, contactIds, solicitorContactId: solicitor_id }),
        this.fetchLastContactDates(solicitor_id, pool_id, contactIds),
      ]);

      const formattedContacts = assignmentsResp.items.reduce((obj, item) => {
        return { ...obj, [item.prospect_contact_id]: item };
      }, {});

      const formattedInteractions = interactionsResponse.data.solicitor.assignments.reduce((obj, item) => {
        return { ...obj, [item.prospect_contact_id]: item.prospect.last_contact_date };
      }, {});

      this.safeSetState((prev) => {
        return {
          prospects_with_assignments_by_stage: {
            ...prev.prospects_with_assignments_by_stage,
            [stage]: (prev.prospects_with_assignments_by_stage[stage] || []).map((obj) => {
              return {
                assignment: formattedContacts[obj.id],
                lastInteractionDate: formattedInteractions[obj.id],
                ...obj,
              };
            }),
          },
        };
      });
    }
  };

  fetchMoreContactsByStage = (obj) => {
    this.fetchContactsByStage({ ...obj, more: true });
  };

  fetchContacts = ({ query, offset = 0 }) => {
    return Api.SEARCH.CONTACTS.post({
      data: _.jsonStringify(query),
      params: { limit: LIMIT, offset },
    }).then((search_resp = {}) => {
      const contact_ids = search_resp.items || [];
      // use the contact ids to retrieve the full name from graph ql without having to load the whole contact obj
      return Api.GRAPHQL.GRAPHQL.post({
        data: JSON.stringify({
          operationName: null,
          query: String.raw`${graphql_query}`,
          variables: { ids: contact_ids },
        }),
      }).then((resp = {}) => {
        const contacts_resp = resp.data.contacts || [];
        return { ...search_resp, items: contacts_resp.map(parseContact) };
      });
    });
  };

  fetchAssignments = ({ poolId, contactIds, solicitorContactId }) => {
    return Api.ASSIGNMENTS.SEARCH.post({
      params: { include_proposals: true },
      data: _.jsonStringify({
        must: [
          { pool_id: { match: poolId } },
          { parent_role: { match: "prospect" } },
          { prospect_contact_id: { in: contactIds } },
          { solicitor_contact_id: { match: solicitorContactId } },
        ],
      }),
    });
  };

  fetchContactsByStageIfNotCached = ({ stage }) => {
    if (!this.state.prospects_with_assignments_by_stage[stage]) {
      this.fetchContactsByStage({ stage });
    }
  };

  fetchLastContactDates = (id, poolId, contactIds) => {
    return Api.GRAPHQL.GRAPHQL.post({
      data: JSON.stringify({
        operationName: null,
        query: String.raw`${lastInteractionQuery}`,
        variables: { id, poolId, prospectIds: contactIds },
      }),
    });
  };

  clearProspectsWithAssignments = () => {
    // setTotal is for sending the total up to portfolio meta data
    this.props.setTotal(0);
    this.setState({ prospects_with_assignments: [], prospects_with_assignments_by_stage: {}, total: 0 });
  };

  /**
   * This updates the key prospect attribute optimistically by
   * assuming the action is going to go through.
   *
   * @param stageName
   * @param assignment
   */
  updateProspectWithAssignmentsByStage = (stageName, assignment) => {
    if (!assignment) return;

    this.setState((prevState) => ({
      prospects_with_assignments_by_stage: {
        ...prevState.prospects_with_assignments_by_stage,
        [stageName]: prevState.prospects_with_assignments_by_stage[stageName].map((assignmentItem) => {
          return assignmentItem.assignment.assignment_id === assignment.assignment_id
            ? { ...assignmentItem, assignment }
            : assignmentItem;
        }),
      },
    }));
  };

  setFilters = (filters) => {
    this.setState({ filters: filters }, () => {
      const { stages } = this.props;
      stages.forEach((stage) => {
        this.fetchContactsByStage({ stage });
      });
    });
  };

  state = {
    fetchContactsByStage: this.fetchContactsByStage,
    fetchMoreContactsByStage: this.fetchMoreContactsByStage,
    fetchContactsByStages: this.fetchContactsByStages,
    fetchContactsByStageIfNotCached: this.fetchContactsByStageIfNotCached,
    updateProspectWithAssignmentsByStage: this.updateProspectWithAssignmentsByStage,
    clearProspectsWithAssignments: this.clearProspectsWithAssignments,
    setFilters: this.setFilters,
    prospects_with_assignments_by_stage: {},
    loading_by_stage: {},
    total_by_stage: {},
    prospects_with_assignments: [],
    loading: false,
    total: 0,
    //Default filters are set on components using this state
    filters: undefined,
  };

  render() {
    return (
      <ContactAssignmentContext.Provider value={this.state}>{this.props.children}</ContactAssignmentContext.Provider>
    );
  }
}

const useContactAssignmentContext = () => {
  const context = useContext(ContactAssignmentContext);
  if (!context)
    throw new Error("useContactAssignmentContext  must be used in a child of ContactAssignmentContextProvider");
  return context;
};
export { ContactAssignmentProvider, useContactAssignmentContext };

export default ContactAssignmentContext;
