do ($ = jQuery) ->

   class EditableContentCursor
      constructor: (target) ->
         @targetNode = target
         @$target = $(target)

      # Loop through the element's childNodes and determine
      # if the position is within that node, by comparing the text length
      # returns: 'node' where position is & 'index' of node where position is
      getNodeAtPosition: (target, pos) ->
         count = 0
         caretNode = _.last(target.childNodes)
         nodeIndex = 0

         _.each target.childNodes, (node) ->
            start_index = count
            count += $(node).text().length
            if pos >= start_index && pos <= count
               caretNode = node
               nodeIndex = pos - start_index

         {node: caretNode, index: nodeIndex}

      # Recursivly find a Text child node
      # to determine where the cursor should be
      recursiveCaretNode: (node, pos, callback) ->
         nodeData = @getNodeAtPosition(node, pos)
         if nodeData?.node?.childNodes?.length > 0
            @recursiveCaretNode(nodeData.node, nodeData.index, callback)
         else
            callback(nodeData)

      # Get the Range of nodes and set selection to index
      # Focus on the element to set cursor
      focusSelection: (nodeData) ->
         return unless nodeData.node
         if document.createRange and window.getSelection
            range = document.createRange()
            sel = window.getSelection()

            range.setStart(nodeData.node, nodeData.index)
            range.collapse(true)
            sel.removeAllRanges()
            sel.addRange(range)
            @$target.focus()
         else
            textRange = document.body.createTextRange()
            textRange.moveToElementText el
            textRange.collapse true
            textRange.moveStart "character", pos
            textRange.select()

      # Set the cursort at the index passed into the function
      setCaretAt: (index) ->
         @recursiveCaretNode @targetNode, index, (nodeData) =>
            # if node is not editable, set to the beginning of the next node
            if $(nodeData.node).parent().attr("contentEditable") == "false"
               nodeData = {node: $(nodeData.node)?.parent()?[0]?.nextSibling, index: 0}
            @focusSelection(nodeData)

      # Set param 'content' at the 'index' passed into the function
      setContentAt: (index, content, callback) ->
         initialCaretPosition = index
         @recursiveCaretNode @targetNode, index, (nodeData) =>
            caretNode = nodeData?.node

            # Add #{content} at the position of the cursor
            if !caretNode
               @$target.append(content)
               @setCaretAt(@$target.text().length)
               callback?()
            else
               text_part1 = $(caretNode).text().substring(0, nodeData.index)
               text_part2 = $(caretNode).text().substring(nodeData.index)
               caretNode?.nodeValue = "#{text_part1}#{content}#{text_part2}"

               # Set the cursor to be placed after the #{content}
               @setCaretAt(initialCaretPosition + content.length)
               callback?()

   # Return jQuery plugin for the editable content cursor
   $.fn.editableContentCursor = (action, params...) ->
      cursor = new EditableContentCursor(@[0])
      cursor[action]?.apply(cursor, params)
