module.exports = do ->
   _ = require("underscore").default
   str = require("underscore.string")
   EverTrue = require("app")
   {createStore} = require("@evertrue/et-flux")
   FacetSource = require("apps/filters/sources/facet-source")
   UrlSource = require("apps/layout/sources/url-source")
   OrgSource = require("base/org/org-source").default
   PropertySource = require("apps/contact/sources/property-source")
   FilterConfigStore = require("apps/filters/stores/filter-config-store")
   FilterStore = require("apps/filters/stores/filter-store")
   FilterSource = require("apps/filters/sources/filter-source")
   ReunionYearOptions = require("apps/filters/stores/reunion-year-options")
   FeatureStore = require("apps/layout/stores/feature-store").default


   MAX_FACET_SIZE = 250

   _localOptions =
      reunion_year: ReunionYearOptions

      tr_gift_date_control: [
         {value: "must", label: "was"}
         {value: "must_not", label: "was not"}
      ]

      tr_gift_date_time: [
         {id: "now-1w/d", format: [1, "week"], label: "in past week"}
         {id: "now-1M/d", format: [1, "month"], label: "in past month"}
         {id: "now-3M/d", format: [3, "month"], label: "in past 3 months"}
         {id: "now-1y/d", format: [1, "year"], label: "in past year"}
         {id: "now-5y/d", format: [5, "years"], label: "in past 5 years"}
         {id: "range", label: "between specific dates..."}
      ]

      tr_gift_types: [
         {value: "hard_credit", label: "Hard Credit"}
         {value: "soft_credit", label: "Soft Credit"}
         {value: "pledge", label: "Pledge"}
      ]

      last_fb_engagement_date_control: [
         {value: "must", label: "has engaged"}
         {value: "must_not", label: "has not engaged"}
      ]

      last_fb_engagement_date_time: [
         {id: "now-1w/d", format: [1, "week"], label: "in past week"}
         {id: "now-1M/d", format: [1, "month"], label: "in past month"}
         {id: "now-3M/d", format: [3, "months"], label: "in past 3 months"}
         {id: "now-1y/d", format: [1, "year"], label: "in past year"}
         {id: "range", label: "between specific dates..."}
      ]

      facebook_engagement_date_control: [
         {value: "must", label: "engaged"}
         {value: "must_not", label: "did not engage"}
      ]

      facebook_engagement_date_time: [
         {id: "now-1w/d", format: [1, "week"], label: "in past week"}
         {id: "now-1M/d", format: [1, "month"], label: "in past month"}
         {id: "now-3M/d", format: [3, "months"], label: "in past 3 months"}
         {id: "now-1y/d", format: [1, "year"], label: "in past year"}
         {id: "range", label: "between specific dates..."}
      ]

      graduway_engagement_date_control: [
         {value: "must", label: "engaged"}
         {value: "must_not", label: "did not engage"}
      ]

      graduway_engagement_date_time: [
         {id: "now-1w/d", format: [1, "week"], label: "in past week"}
         {id: "now-1M/d", format: [1, "month"], label: "in past month"}
         {id: "now-3M/d", format: [3, "months"], label: "in past 3 months"}
         {id: "now-1y/d", format: [1, "year"], label: "in past year"}
         {id: "range", label: "between specific dates..."}
      ]

      interaction_date_control: [
         {value: "must", label: "was"}
         {value: "must_not", label: "was not"}
      ]

      interaction_date_time: [
         {id: "now-1w/d", format: [1, "week"], label: "in past week"}
         {id: "now-1M/d", format: [1, "month"], label: "in past month"}
         {id: "now-3M/d", format: [3, "months"], label: "in past 3 months"}
         {id: "now-1y/d", format: [1, "year"], label: "in past year"}
         {id: "range", label: "between specific dates..."}
      ]

      proposal_ask_date_control: [
         {value: "must", label: "is"}
         {value: "must_not", label: "is not"}
      ]

      proposal_ask_date_time: [
         {id: "now+1M", format: [1, "month"], label: "in next month"}
         {id: "now+3M", format: [3, "months"], label: "in next 3 months"}
         {id: "now+1y", format: [1, "year"], label: "in next year"}
         {id: "now-1M/d", format: [1, "month"], label: "in past month"}
         {id: "now-3M/d", format: [3, "months"], label: "in past 3 months"}
         {id: "now-1y/d", format: [1, "year"], label: "in past year"}
         {id: "range", label: "between specific dates..."}
      ]

      custom_date_control: [
         {value: "must", label: "is"}
         {value: "must_not", label: "is not"}
      ]

      custom_date_time: [
         {id: "now-1w/d", format: [1, "week"], label: "in past week"}
         {id: "now-1M/d", format: [1, "month"], label: "in past month"}
         {id: "now-3M/d", format: [3, "month"], label: "in past 3 months"}
         {id: "now-1y/d", format: [1, "year"], label: "in past year"}
         {id: "now-5y/d", format: [5, "years"], label: "in past 5 years"}
         {id: "range", label: "between specific dates..."}
      ]


   # Legacy dependency: Holds local facets to prevent circular dependency
   EverTrue.staticFacets = _localOptions

   _sortFacetObject = (facet_obj) ->
      _.mapObject facet_obj, (facets) ->
         _.sortBy facets, (f) -> f.value


   createStore "FacetStore",
      getInitialState: ->
         loading: false
         searching: false
         facets:
            contact: {}
            gift: {}
            proposal: {}
            local: {}
         queried_facets:
            contact: {}
            gift: {}
            proposal: {}
            local: {}

      firstListenerDidMount: ->
         FacetSource.fetch(["addresses.type"])

      registerActions: ->
         @on FacetSource.actions.clearFacets, @respondToClear
         @on FacetSource.actions.loadingFacets, @respondToLoading
         @on FacetSource.actions.fetchedFacets, @respondToFetchedFacets
         @on FacetSource.actions.searchingFacets, @respondToSearching
         @on FacetSource.actions.searchedFacets, @respondToSearchedFacets
         @on FacetSource.actions.fetchActiveFilterFacets, @respondToFilterChange

         @on FilterSource.actions.updatedFilter, @respondToFilterChange

         @on UrlSource.actions.initializedUrl, @respondToInit
         @on PropertySource.actions.fetchedSchema, @respondToInit
         @on OrgSource.actions.newOrg, @respondToClear

      respondToLoading: (isLoading) ->
         @setState {loading: isLoading}

      respondToSearching: (isSearching) ->
         @setState {searching: isSearching}

      respondToFetchedFacets: (data, type) ->
         EverTrue.store.push "facets", data.facets
         if @getState("facets")[type]
            facet_obj = _.toObject type, _.extend @getState("facets")[type], _sortFacetObject(data.facets)
         else
            facet_obj = _.toObject type, _sortFacetObject(data.facets)
         @setState
            facets: _.extend @getState("facets"), facet_obj
            queried_facets: _.extend @getState("queried_facets"), _.toObject(type, [])

      respondToSearchedFacets: (keys, term, type, data) ->
         results = _sortFacetObject(data.facets)
         has_missing_keys = !_.every(keys, (key) -> data.facets?[type]?[key])
         if !_.isEmpty(term) && has_missing_keys
            results ?= {}
            _.each keys, (k) -> results[k] ?= []
         @setState {queried_facets: _.toObject(type, results)}

      respondToClear: ->
         @setState {facets: {}, queried_facets: {}}

      respondToInit: ->
         @respondToFilterChange()

      respondToFilterChange: ->
         facet_types = {}

         _.each FilterStore.getCurrentFilterFacets(), (facet_config) ->
            type = facet_config?.type || "contact"
            _.each facet_config.fields, (facet) ->
               hasGates = _.every(facet.gated, (gate) -> FeatureStore.hasFeature(gate))
               if hasGates
                  facet_types[type] ?= []
                  facet_types[type] = facet_types[type].concat facet

         current_facets = @getState("facets")
         _.each facet_types, (fields, type) ->
            fieldValues = _.pluck(fields, "value")
            unless type == "local"
               new_keys = _.difference(fieldValues, _.keys(current_facets[type]))
               if !_.isEmpty(new_keys)
                  FacetSource.fetch(fieldValues, type)

      getLocalOptions: (key) ->
         if _.isFunction _localOptions[key]
            _localOptions[key]?()
         else _localOptions[key]

      api:
         getFacets: ->
            @getState("facets")

         getLoading: ->
            @getState("loading") || @getState("searching")

         getByKey: (key, type="contact") ->
            @getLocalOptions(key) || @getState("facets")?[type]?[key]

         getQueryParams: (filter_id) ->
            facet_config = FilterConfigStore.getConfigById(filter_id)?.facets || {}
            type: facet_config?.type || "contact"
            fields: facet_config.fields

         getForFilter: (filter_id, useQueryFacet = true) ->
            {type, fields} = @getQueryParams(filter_id)
            facet_values_obj = {}
            _.each fields, (field) =>
               hasGates = _.every(field.gated, (gate) -> FeatureStore.hasFeature(gate))
               if hasGates
                  if type == "local"
                     facet = @getLocalOptions(field.value)
                  else
                     if useQueryFacet
                        facet = @getState("queried_facets")?[type]?[field.value] || @getState("facets")?[type]?[field.value]
                     else facet = @getState("facets")?[type]?[field.value]
                  facet_values_obj[field.value] = facet unless _.isUndefined(facet)

            facet_values_obj

         getSearch: (filter_id, query) ->

            {type, fields} = @getQueryParams(filter_id)

            facet_values_obj = @getForFilter(filter_id, false)

            has_all_facets = _.every facet_values_obj, (facets) ->
               facets?.length < MAX_FACET_SIZE

            allowedFields = _.filter fields, (field) ->
               _.every(field.gated, (gate) -> FeatureStore.hasFeature(gate))


            if has_all_facets
               _.mapObject facet_values_obj, (facets) ->
                  _.filter facets, (data) ->
                     _.searchCompare(data.value, query)
            else
               if query
                  FacetSource.search(allowedFields, query, type)
               else
                  if _.isEmpty(facet_values_obj)
                     FacetSource.fetch(allowedFields, type)
                  else facet_values_obj
