module.exports = do ->
   moment = require("moment")

   MyPortfolioConfig =

      formatLabel: (number, label) ->
         number = Math.floor(number)
         if number == 1
            "#{number} #{label}"
         else
            "#{number} #{label}s"

      getFormattedTimeInStage: (time) ->
         return unless time
         current_time = moment().valueOf()

         one_week_ago = moment(current_time).subtract(1, "week").valueOf()
         one_month_ago = moment(current_time).subtract(1, "month").valueOf()
         two_yrs_ago = moment(current_time).subtract(2, "years").valueOf()
         five_yrs_ago = moment(current_time).subtract(5, "years").valueOf()

         days = moment(current_time).diff(time, "days", true)
         weeks = moment(current_time).diff(time, "weeks", true)
         months = moment(current_time).diff(time, "months", true)
         years = moment(current_time).diff(time, "years", true)

         if time > one_week_ago
            @formatLabel(days, "day")
         else if time > one_month_ago
            @formatLabel(weeks, "wk")
         else if time > two_yrs_ago
            @formatLabel(months, "mo")
         else if time > five_yrs_ago
            months = months % 12
            @formatLabel(years, "yr") + ", " + @formatLabel(months, "mo")
         else if time <= five_yrs_ago
            "5+ years"

   return MyPortfolioConfig

