module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   EverTrue = require("app")
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")


   createSource "AssignProspectSource",
      actions:
         add: (user_id, contacts, assignee_data) ->
            @require _.isNumber(user_id), "user_id should be a number"
            @require _.isArray(contacts), "contact should be an array"
            @require _.isObject(assignee_data), "assignee_data should be an object"
         change: (user_id, contacts, assignee_data) ->
            @require _.isNumber(user_id), "user_id should be a number"
            @require _.isArray(contacts), "contact should be an array"
            @require _.isObject(assignee_data), "assignee_data should be an object"
         remove: (user_id, contacts) ->
            @require _.isNumber(user_id), "user_id should be a number"
            @require _.isArray(contacts), "contact should be an array"
         updateForContact: (full_contact, assignees) ->
            @require _.isObject(full_contact), "full_contact should be an object"
            @require _.isArray(assignees), "assignees should be an array"
         savingToContact: true
         changedAssignment: true
         changedRemoteIds: true

      fetchContact: (contact_id) ->
         promise = $.Deferred()
         Api.CONTACTS.CONTACT.get
            urlArgs: {id: contact_id}
            success: (full_contact) ->
               promise.resolve(full_contact)
            error: (xhr, statusText, error) ->
               Raven?.captureMessage "Error Fetching Full Contact", {extra: {error: error, statusText: statusText}}
               promise.fail(error)
         promise.promise()

      updateContact: (data) ->
         promise = $.Deferred()
         Api.CONTACTS.CONTACT.put
            urlArgs: {id: data.id}
            data: _.jsonStringify(data)
            headers: {"ET-Update-Source": "GIVING_TREE"}
            error_message: "Error updating this constituent's assignment. Please refresh and try again."
            success: (contact) -> promise.resolve(contact)
            error: (xhr, statusText, error) ->
               Raven?.captureMessage "Error Updating Contact Assignee Info", {extra: {error: error, statusText: statusText}}
               promise.fail(error)
         promise.promise()

      createRemoteId: (user, affiliation) ->
         if !affiliation then return
         promise = $.Deferred()
         Api.AUTH.AFFIL.put
            urlExtend: "/#{affiliation.id}"
            data: _.jsonStringify {remote_user_id: (user.name + user.id).replace(/\s/g, "")}
            success: -> promise.resolve()
            error: (xhr, statusText, error) ->
               Raven?.captureMessage "Error Updating Remote ID", {extra: {error: error, statusText: statusText}}
               promise.fail(error)
         promise.promise()

      api:
         saveDataToContact: (contacts_updates, remote_id_updates) ->
            @actions.savingToContact(true)

            updates = _.makeArray(contacts_updates)
            remote_id_requests = _.map remote_id_updates, (obj) =>
               @createRemoteId(obj.user, obj.affiliation)

            # Save Any Remote ID Updates
            $.when.apply(@, remote_id_requests)
               .then =>
                  @actions.changedRemoteIds()

                  # Save Assignment Data to Contacts
                  $.when.apply(@, _.map(updates, @updateContact))
                     .then (contacts...) =>
                        # Add a delay for making sure data get's to ES
                        _.delay =>
                           @actions.savingToContact(false)
                           @actions.changedAssignment(_.pluck(updates, "id"))
                           if updates.length > 1
                              EverTrue.Alert.success "#{contacts.length} of #{updates.length} Constituents Updated Successfully."
                           else EverTrue.Alert.success "Constituent Status Updated Successfully."
                        , 1000
                     .fail => @actions.savingToContact(false)
               .fail => @actions.savingToContact(false)

         updateForContact: (contact_id, assignees) ->
            @fetchContact(contact_id).then (full_contact) =>
               @actions.updateForContact(full_contact, assignees)

         add: (user_id, contact_ids, assignee_data={}) ->
            requests = _.map contact_ids, @fetchContact
            $.when.apply(@, requests).then (contacts...) =>
               @actions.add(user_id, contacts, assignee_data)

         change: (user_id, contact_ids, assignee_data={}) ->
            requests = _.map contact_ids, @fetchContact
            $.when.apply(@, requests).then (contacts...) =>
               @actions.change(user_id, contacts, assignee_data)

         remove: (user_id, contact_ids) ->
            requests = _.map contact_ids, @fetchContact
            $.when.apply(@, requests).then (contacts...) =>
               @actions.remove(user_id, contacts)
