import { PropTypes } from "base/react-libs";
import classNames from "classnames";
import moment from "moment";
import Icon from "components/elements/icon";
import CommentText from "apps/interactions/components/lazy-comment-text";
import InteractionUtils from "apps/interactions/interaction-utils";
import { fetchContactsById, fetchUsersById } from "apps/volunteers/vms-queries";
import { useEffect, useState } from "react";
import EverTrue from "app";

function transformToDict(objList) {
  const result = {};
  objList.forEach((obj) => {
    result[obj.name] = obj;
  });
  return result;
}

const PinnedComment = ({ comment }) => {
  const [contactData, setContactData] = useState(null);
  const [usersData, setUsersData] = useState(null);

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        if (comment.contact_mention && comment.contact_mention.length) {
          const data = await fetchContactsById({
            contactIds: comment.contact_mention.map((mention) => mention.contact_id),
          });
          setContactData(data);
        }
      } catch (_error) {
        EverTrue.Alert.error("There was an issue fetching Contacts.");
      }
    };

    const fetchUsers = async () => {
      try {
        if (comment.user_mention.length) {
          const userIdList = comment.user_mention.map((mention) => mention.user_id);
          const users = await fetchUsersById(userIdList);
          setUsersData(users);
        }
      } catch (_error) {
        EverTrue.Alert.error("There was an issue fetching Users.");
      }
    };

    fetchContacts();
    fetchUsers();
  }, [comment.contact_mention, comment.user_mention]);

  const isEmptyComment = (c) => {
    if (!c.text || !c.text.trim()) return "is-empty";
  };

  const formattedDate = () => moment(comment.date_occurred || comment.created_at).format("MMM Do, YYYY");

  const userMentionArray = comment.user_mention.map((mention) => {
    const user = usersData && usersData.find((user) => user.id === mention.user_id);
    const userName = user ? user.name : "";
    return { ...mention, data: { type: "user", id: mention.user_id, name: userName } };
  });

  const contactMentionArray = comment.contact_mention.map((mention) => {
    const contact = contactData ? contactData.items.find((contact) => contact.id === mention.contact_id) : null;
    let parsedProperties;
    if (contact && contact.properties) parsedProperties = transformToDict(contact.properties);
    return { ...mention, data: { type: "contact", id: mention.contact_id, properties: parsedProperties } };
  });

  return (
    <div className="pinned-comment">
      <div className="pinned-comment--image">
        <div className="pinned-comment--icon">
          <Icon icon="pin-icon" />
        </div>
      </div>
      <div className="pinned-comment--content">
        <div>
          <div className={classNames("pinned-comment--body", "is-gt-comment", isEmptyComment(comment))}>
            <div className="clearfix">
              <span className="pinned-comment--details">
                Added on {formattedDate()} by {comment.author.name}
              </span>
            </div>
            <div className="pinned-comment--text fs-exclude-data">
              <CommentText
                text={InteractionUtils.renderText(comment, false, null)}
                mentions={[...userMentionArray, ...contactMentionArray]}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

PinnedComment.propTypes = {
  comment: PropTypes.object.isRequired,
};

export default PinnedComment;
