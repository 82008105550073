module.exports = do ->
   React = require("react")
   EverTrue = require("app")
   {createComponent} = require("@evertrue/et-flux")
   {div, span, img} = ReactLibs.DOMFactories
   {object} = ReactLibs.PropTypes
   UserSource = require("base/user/user-source")
   Avatar = require("components/elements/avatar")
   {createFactory} = require("base/new-utils")
   Button = createFactory(require("@evertrue/et-components").Button)

   createComponent "ImpersonationHeader",
      propTypes:
         user: object

      getDefaultProps: ->
         user: {}

      handleCancel: ->
         UserSource.endImpersonation()

      render: ->
         {name, avatar} = @props.user

         div className: "impersonation-header",
            div className: "impersonation-wrapper clearfix",
               Button type: "destroy", className: "pull-left end-impersonation-btn btn", onClick: @handleCancel,
                  "End Impersonation"

               div className: "pull-left vertical-line"

               Avatar url: avatar, width: 40, className: "main-nav--avatar impersonation-avatar pull-left", name: name

               div className: "impersonation-names pull-left",
                  div className: "emphasis", name
                  span className: "impersonation-names--org", EverTrue.store.org.get("name")

            div className: "pull-left impersonation-text",
               "You are currently impersonating"
