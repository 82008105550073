import PropTypes from "prop-types";
import { Component } from "react";
import numeral from "numeral";

class ContactGiving extends Component {
  static propTypes = {
    contact: PropTypes.object,
    showLifetime: PropTypes.bool,
    showLastGiftDate: PropTypes.bool,
  };

  static defaultProps = {
    contact: {},
    showLifetime: true,
    showLastGiftDate: false,
  };

  render() {
    const giving = this.props.contact.giving || {};
    const lifetime_amount = giving.lifetime_amount || {};
    const lifetime_value = lifetime_amount.value || 0;
    const last_gift_date = giving.last_gift_date || {};

    return (
      <div>
        {this.props.showLifetime && (
          <div>
            <span className="contact-card--label">{`Lifetime: `}</span>
            <span className="contact-card--value fs-exclude-data">{numeral(lifetime_value).format("$0,0")}</span>
          </div>
        )}
        {this.props.showLastGiftDate && (
          <div>
            <span className="contact-card--label">Last Gift Date: </span>
            <span className="contact-card--value fs-exclude-data">{last_gift_date.value || "N/A"}</span>
          </div>
        )}
      </div>
    );
  }
}

export default ContactGiving;
