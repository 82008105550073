import { useContext } from "react";
import { PropTypes } from "base/react-libs";
import { TasksContext } from "apps/tasks/hooks/tasks-context";
import SortDropdown from "components/controls/sort-dropdown";
import { Pagination } from "@evertrue/et-components";

const TasksToolbar = ({ numTasks = 0, userType, filterStatus = {} }) => {
  const getSortOptions = (userType) => {
    let sortOptions = [{ label: "Action Type", value: "action_type" }];

    if (filterStatus.statuses) {
      if (!filterStatus.statuses || !filterStatus.statuses.includes("IN_PROGRESS")) {
        sortOptions.push({ label: "Completed At", value: "completed_at" });
      }
      if (!filterStatus.statuses || !filterStatus.statuses.includes("COMPLETED")) {
        sortOptions.push({ label: "Due Date", value: "due_date" });
      }
    }

    if (userType) {
      sortOptions.push({ label: "Constituent", value: "target_contact_name" });
    }
    if (userType !== "OWNER") {
      sortOptions.push({ label: "Assignee", value: "owner_user_name" });
    }
    if (userType !== "CREATOR_BUT_NOT_OWNER") {
      sortOptions.push({ label: "Created By", value: "creator_user_name" });
    }
    return sortOptions;
  };

  const { tableSettings, updateTableSettings } = useContext(TasksContext);
  return (
    <div className="tasks-toolbar space-between">
      <strong style={{ margin: 0, alignSelf: "end" }}>
        {numTasks} Total {numTasks === 1 ? "Task" : "Tasks"}
      </strong>

      <Pagination
        className="task-table-pagination"
        page={tableSettings.page}
        total={Math.ceil(numTasks / 50)}
        onChange={(page) => {
          updateTableSettings({ page });
        }}
      />

      {numTasks > 0 && (
        <SortDropdown
          options={getSortOptions(userType)}
          sortProp={tableSettings.sortProp}
          sortReverse={tableSettings.sortReverse}
          onSort={(sortProp, sortReverse) => {
            updateTableSettings({ sortProp, sortReverse });
          }}
        />
      )}
    </div>
  );
};

TasksToolbar.propTypes = {
  numTasks: PropTypes.number,
  userType: PropTypes.string,
  filterStatus: PropTypes.object,
};

export default TasksToolbar;
