module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")

   _lastFetched = 0
   _throttle = 5000

   _ignored_fields = [
      "avatar"
      "custom_fields"
      "linkedin"
      "linkedin_positions"
      "twitter"
      "fullcontact"
      "fullcontact_profiles"
      "news_mentions"
      "gifts"
      "gifts_profiles"
      "gift_labels"
      "soft_credits"
      "ignored_uids"
      "evertruescore_list"
      "profscore_list"
      "demoscore_list"
      "socialscore_list"
      "eventsscore_list"
      "givingscore_list"
   ]


   createSource "PropertySource",
      actions:
         loadingSchema: true
         errorSchema: true
         fetchedSchema: (data) ->
            @require _.isArray(data), "schema should be array"

      init: ->
         EverTrue.vent.on "storeReset", =>
            _lastFetched = 0
            @fetch()

         EverTrue.vent.on "org:initialized", =>
            _lastFetched = 0
            @fetch()

         @_initalized = true

      filterOutInternal: (schema) ->
         _.reject schema, (props) -> props.name in _ignored_fields

      api:
         forceFetch: ->
            _lastFetched = 0
            @fetch()

         fetch: ->
            now = new Date().getTime()
            return unless (now - _lastFetched) > _throttle

            @init() unless @_initalized
            _lastFetched = now

            @actions.loadingSchema(true)
            Api.CONTACTS.SCHEMA.get
               success: (resp) =>
                  # TODO: Maybe add an action here for "fetchedCustomFields"
                  @actions.loadingSchema(false)
                  @actions.fetchedSchema(@filterOutInternal(resp))
               error: (xhr, textStatus, err) =>
                  @actions.loadingSchema(false)
                  @actions.errorSchema()
                  Raven?.captureMessage("Properties Schema Error: #{err?.message}")

