module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   EverTrue = require("app")
   AuthApi = require("entities/auth/auth-api")

   class Gate extends AuthApi.Model
      urlRoot: ->
         if @get("name") then "dna/gates/#{@get("name")}"
         else "dna/gates"

      parse: (data) ->
         if data.features
            _.first(@parseFeatures(data.features, data.scopes))
         else data

      parseFeatures: (features, scopes={}) ->
         _.map features, (feature_data, key) =>
            feature = feature_data
            feature.name = key
            feature.platform = scopes.platform
            feature.user_id = scopes.user_id
            feature.oid = scopes.oid
            feature.id = @formatId(feature)
            feature

      formatId: (feature) ->
         id = feature.name
         id += ":#{feature.platform}" if feature.platform
         id += ":#{feature.user_id}" if feature.user_id
         id += ":#{feature.oid}" if feature.oid
         id

      fetch: (options) ->
         opts = $.extend true, {},
            params: {platform: "web"}
         , options
         super opts

      save: (attrs, options) ->
         opts = $.extend true, {},
            type: "PUT"
            params: {platform: "web"}
         , options
         super attrs, opts

      updateDefault: (options) ->
         @sync "update", @, $.extend(true, {},
            attrs: {}
            urlExtend: "/default"
            params:
               enabled: @get("enabled")
               oid: null
         , options)

      deprecate: (options) ->
         @destroy $.extend(true, {},
            urlExtend: "/deprecate"
         , options)

      getServerAttributes: ->
         _.omit @attributes, ["name", "id"]

