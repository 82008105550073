module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   EverTrue = require("app")
   React = require("react")
   moment = require("moment")
   {div, h3, hr, ul, li, strong} = ReactLibs.DOMFactories

   MonthDayList = ReactLibs.createClass
      propTypes:
         list: ReactLibs.PropTypes.array.isRequired
         displayListItem: ReactLibs.PropTypes.func.isRequired
         infiniteScroll: ReactLibs.PropTypes.bool
         loadMore: ReactLibs.PropTypes.func
         scrollElement: ReactLibs.PropTypes.any
         containerElement: ReactLibs.PropTypes.any

      getDefaultProps: ->
         scrollElement: EverTrue.WINDOW
         containerElement: EverTrue.DOCUMENT

      componentDidMount: ->
         if @props.infiniteScroll
            $(@props.scrollElement).on "scroll", @_infiniteScroll

      componentWillUnmount: ->
         if @props.infiniteScroll
            $(@props.scrollElement).off "scroll", @_infiniteScroll

      _infiniteScroll: ->
         if $(@props.scrollElement).scrollTop() + $(@props.scrollElement).height() >= ($(@props.containerElement).height() - 300)
            @props.loadMore()

      displayListItems: (list) ->
         _.map list, (item) =>
            li className: "social-interaction", key: item.id,
               @props.displayListItem(item)

      months: ->
         _.map @props.list, (monthData) =>
            div key: "month_#{monthData.month}",
               h3 className: "social-interaction--month",
                  moment(monthData.month).format("MMMM YYYY")
               @days(monthData.data)

      days: (days) ->
         _.map days, (dayData) =>
            div key: "day_#{dayData.day}",
               strong null, moment(dayData.day).format("MMMM D")
               hr className: "horizontal-divider"
               ul null,
                  @displayListItems(dayData.data)

      render: ->
         div className: @props.className || "",
            @months()

