import PropTypes from "prop-types";
import { createContext, useState, useCallback, useEffect } from "react";
import useTableSettings from "hooks/use-table-settings";
import { useIdentity } from "base/identity-resolver";
import { fetchTasksByAppliedFilters, fetchAllTasksCount } from "apps/tasks/utils/fetch-helpers";

const TasksContext = createContext(() => {});

const TasksProvider = ({ referrer, id, children }) => {
  const { user } = useIdentity();

  const getDefaultFilters = useCallback(() => {
    if (referrer === "contact") {
      return {
        target_contact_id: id,
        statuses: ["IN_PROGRESS", "PAUSED"],
      };
    } else {
      if (children.props.userType === "OWNER") {
        return {
          owner_user_id: user.id,
          statuses: ["IN_PROGRESS", "PAUSED"],
        };
      } else if (children.props.userType === "CREATOR_BUT_NOT_OWNER") {
        return {
          creator_user_id: user.id,
          statuses: ["IN_PROGRESS", "PAUSED"],
          exclude: [{ field: "owner_user_id", value: user.id }],
        };
      }
    }
  }, [children.props.userType, id, referrer, user.id]);

  const { tableSettings, updateTableSettings, resetTable } = useTableSettings({
    sortProp: "due_date",
    page: 1,
    sortReverse: false,
  });
  const [tasks, setTasks] = useState([]);
  const [total, setTotal] = useState(0);
  const [allTasksCount, setAllTasksCount] = useState();
  const [allTasksCountLoading, setAllTasksCountLoading] = useState();
  const [removedLastTask, setRemovedLastTask] = useState(false);
  const [loading, setLoading] = useState(true);
  const offset = (tableSettings.page - 1) * 50;
  const LIMIT = 75;
  const [taskFilters, setTaskFilters] = useState(getDefaultFilters());

  const getAndSetTasks = useCallback(
    async (taskFilters) => {
      const { sortReverse, sortProp } = tableSettings;
      setLoading(true);
      let tasks;
      try {
        tasks = await fetchTasksByAppliedFilters({
          ...taskFilters,
          offset,
          limit: LIMIT,
          sort: [sortProp, sortReverse ? "DESC" : "ASC"],
        });

        setTasks(tasks.items);
        setTotal(tasks.total);
        setLoading(false);
      } catch {
        setLoading(false);
      }
    },
    [offset, tableSettings]
  );

  const removeTaskFromState = async (task) => {
    if (tasks.length === 1) {
      setRemovedLastTask(true);
      setAllTasksCount(0);
    }

    const updatePagination = (tasks) => {
      if (tasks.length === 0 && tableSettings.page > 1) {
        updateTableSettings({ page: tableSettings.page - 1 });
      }
    };

    const handleReserveTasks = async (tasks) => {
      const tasksLength = tasks.length;
      if (!(tasksLength % 50) && total > tasksLength) {
        const nextTasks = await fetchTasksByAppliedFilters({
          ...taskFilters,
          offset: offset + tasksLength,
          limit: 25,
          sort: [tableSettings.sortProp, tableSettings.sortReverse ? "DESC" : "ASC"],
        });

        setTasks([...tasks, ...nextTasks.items]);
      }
    };

    setTotal(total - 1);

    const filteredTasks = tasks.filter((t) => t.id !== task.id);
    setTasks(filteredTasks);

    updatePagination(filteredTasks);
    if (total - 1 > 0) handleReserveTasks(filteredTasks);
  };

  const getAllTasksCount = useCallback(async () => {
    setAllTasksCountLoading(true);
    try {
      const resp = await fetchAllTasksCount({
        user_id: user.id,
      });
      setAllTasksCount(resp.total);
      setAllTasksCountLoading(false);
    } catch {
      setAllTasksCountLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id]);

  const updateTaskFilters = (filters) => {
    setTaskFilters(filters);
    updateTableSettings({ page: 1 });
  };

  useEffect(() => {
    getAllTasksCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TasksContext.Provider
      value={{
        tableSettings,
        updateTableSettings,
        resetTable,
        tasks,
        total,
        loading,
        setLoading,
        removeTaskFromState,
        getAndSetTasks,
        setTasks,
        userId: user.id,
        referrer,
        taskFilters,
        setTaskFilters,
        getDefaultFilters,
        allTasksCount,
        setAllTasksCount,
        allTasksCountLoading,
        removedLastTask,
        updateTaskFilters,
      }}
    >
      {children}
    </TasksContext.Provider>
  );
};

TasksProvider.propTypes = {
  children: PropTypes.any,
  referrer: PropTypes.string,
  id: PropTypes.number,
};

export { TasksProvider, TasksContext };
