module.exports = do ->
   _ = require("underscore").default
   numeral = require("numeral")
   React = require("react")
   EverTrue = require("app")
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories
   FeatureStore = require("apps/layout/stores/feature-store").default
   MyPortfolioSource = require("apps/major-gifts/sources/my-portfolio-source")
   AssignProspectSource = require("apps/major-gifts/sources/assign-prospect-source")
   {createFactory} = require("base/new-utils")
   Modal = createFactory require("components/modals/modal")
   ModalConfirm = createFactory require("components/modals/modal-confirm")
   ExportSearchController = createFactory require("apps/export/controllers/export-search-controller")
   ListSelectorController = createFactory(require("apps/lists/controllers/lists-selector-controller").default)
   ProspectColumnSelector = require("apps/major-gifts/components/prospect-column-selector")
   MyPortfolioStatusModal = require("apps/major-gifts/components/my-portfolio-status-modal")
   ActionMenuItem = createFactory(require("components/controls/action-menu-item").default)

   _stringDisplay = (count) ->
      if count == 1 then "Constituent" else "Constituents"



   createComponent "ProspectActions",
      propTypes:
         solicitor_id: ReactLibs.PropTypes.number
         total_count: ReactLibs.PropTypes.number
         selected_contacts: ReactLibs.PropTypes.array

      registerStores: ->
         @on FeatureStore, ->
            has_write_assignee: FeatureStore.hasFeature("write_assignee")

      handleExport: (property_ids) ->
         MyPortfolioSource.startExport(property_ids)

      renderExportModal: ->
         Modal width: 600, keepOpen: true,
            ExportSearchController
               onExport: @handleExport
               exportText: "You are about to export #{@props.total_count} records"

      renderStatusModal: ->
         Modal width: 460, keepOpen: true,
            MyPortfolioStatusModal
               total: @props.selected_contacts?.length
               onSubmit: @handleChangeStatus

      handleChangeStatus: (status) ->
         AssignProspectSource.change(@props.solicitor_id, @props.selected_contacts, {status: status})

      handleRemove: ->
         EverTrue.Alert.confirm
            width: 440
            headline: "Remove from My Portfolio?"
            content: "Removing from your portfolio will also remove your
            assignment to this prospect. Continue removing assignment?"
         , (e) =>
            if e
               AssignProspectSource.remove(@props.solicitor_id, @props.selected_contacts)

      render: ->
         user_model = EverTrue.store.user
         is_my_portfolio = @props.solicitor_id == user_model.get("id")
         is_ownerish = user_model.isOwnerOrSuperUser()
         has_selected_contacts = !_.isEmpty(@props.selected_contacts)

         count = @props.selected_contacts?.length
         count_display = _stringDisplay(count)
         total = @props.total_count

         div null,
            # if has_selected_contacts
            #    ActionMenuItem
            #       title: "Add #{count} #{count_display} to list"
            #       modal: Modal width: 400,
            #          ModalConfirm header: "Add Constituents to List",
            #             ListSelectorController contactIds: @props.selected_contacts
            #       icon: "playlist-add"
            #       "Add #{count} #{count_display} to list"

            if (has_selected_contacts && @state.has_write_assignee) && (is_my_portfolio || is_ownerish)
               ActionMenuItem
                  title: "Change Status for #{count} #{count_display}"
                  modal: @renderStatusModal()
                  icon: "columns"
                  "Change Status for #{count} #{count_display}"

            if total > 0
               ActionMenuItem
                  title: "Export #{numeral(total).format("0,0")} #{_stringDisplay(total)}"
                  icon: "cloud-download"
                  modal: @renderExportModal()
                  "Export #{numeral(total).format("0,0")} #{_stringDisplay(total)}"

            if is_ownerish
               ActionMenuItem
                  title: "Edit Stage Order"
                  icon: "settings"
                  modal: Modal {width: 660, animationClass: false}, ProspectColumnSelector()
                  "Edit Stage Order"

            if has_selected_contacts && is_my_portfolio && @state.has_write_assignee
               ActionMenuItem
                  title: "Remove from My Portfolio"
                  icon: "delete"
                  className: "text-danger"
                  onClick: @handleRemove
                  "Remove from My Portfolio"
