module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   BaseRouter = require("base/base-router")
   MyTeamController = require("apps/my-team/controllers/my-team-controller")

   class MyTeamRouter extends BaseRouter
      routes:
         "team/performance": "myTeam"
         "team/performance/:report_key": "myTeamReport"

      myTeam: ->
         EverTrue.execute "setBrowserTitle", "Team"
         @currentView = MyTeamController()
         EverTrue.page.mount @currentView

      myTeamReport: (report_key) ->
         EverTrue.execute "setBrowserTitle", "Team"

         @currentView = MyTeamController
            report: report_key
         EverTrue.page.mount @currentView

   MyTeamRouter
