import { Component } from "react";
import PropTypes from "prop-types";

// DO NOT USE THIS; THIS IS DEPRECATED. USE THE ONE IN ET-COMPONENTS
// OR USE PROGRESS-BAR-WITH-LABEL
// TODO: DELETE THIS AND ALL USAGE OF IT IN THE APP 

class ProgressBar extends Component {
  static propTypes = {
    percent: PropTypes.number,
    width: PropTypes.any,
    className: PropTypes.string,
    backgroundColor: PropTypes.string,
  };

  static defaultProps = {
    percent: 0,
    width: "200px",
    backGroundColor: "#d8d8d8",
  };

  render() {
    let percentWidth = this.props.percent + "%";
    if (this.props.percent <= 1) {
      percentWidth = this.props.percent * 100 + "%";
    }

    return (
      <div className={`progress-bar ${this.props.className || ""}`} style={{ width: this.props.width }}>
        <div className="progress-bar--outer" style={{ backgroundColor: this.props.backgroundColor }}>
          <div className="progress-bar--inner" style={{ width: percentWidth }} />
        </div>
      </div>
    );
  }
}

export default ProgressBar;
