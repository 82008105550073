import RichTextEditor from "components/emailer/rich-text-editor";
import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Button } from "@evertrue/et-components";

const EmailSignaturesForm = ({ selectedSignature = {}, updateSignature, isSaved, setIsSaved, contact }) => {
  const { signature_body, id } = selectedSignature;
  const [body, setSignatureBody] = useState(signature_body);

  useEffect(() => {
    setSignatureBody(signature_body);
  }, [signature_body]);

  const quillRef = useRef(null);

  return (
    <div className="email-signature-form">
      <p>
        <strong>Customize Your Signature</strong>
      </p>
      <div>
        <>
          <RichTextEditor
            quillRef={quillRef}
            className="email-signature--editor"
            placeholderText="Add Signature..."
            editorBody={body}
            setEditorBody={setSignatureBody}
            onTextChange={() => setIsSaved(false)}
            isSignatureForm={true}
            contact={contact}
          />

          <div className="email-signature-body-footer">
            <div>
              {isSaved ? (
                <Button type="simple" disabled={true} className={"saved-btn"}>
                  &#x2713; Saved
                </Button>
              ) : (
                <Button
                  type="simple"
                  onClick={async () => {
                    await updateSignature(id, { signature_body: body });
                    setIsSaved(true);
                  }}
                  disabled={selectedSignature.id === 0}
                >
                  Save
                </Button>
              )}
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

EmailSignaturesForm.propTypes = {
  selectedSignature: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    body: PropTypes.string,
  }).isRequired,
  updateSignature: PropTypes.func.isRequired,
  isSaved: PropTypes.bool.isRequired,
  setIsSaved: PropTypes.func.isRequired,
  contact: PropTypes.object,
};

export default EmailSignaturesForm;
