
$ = require("jquery")
_ = require("underscore").default
React = require("react")
EverTrue = require("app")
{connect} = require("@evertrue/et-flux")
FilterConfigStore = require("apps/filters/stores/filter-config-store")
FilterSource = require("apps/filters/sources/filter-source")
{createFactory} = require("base/new-utils")
LinkButton = createFactory(require("@evertrue/et-components").LinkButton)

mapStateToProps = -> ({config: FilterConfigStore.getConfig()})

SearchLink = ReactLibs.createClass
   displayName: "SearchLink",
   propTypes:
      urlBase: ReactLibs.PropTypes.string
      filters: ReactLibs.PropTypes.object
      config: ReactLibs.PropTypes.object

   handleClick: (e) ->
      # This is required to update the map before it renders
      # So that you don't get into a race condition
      if @props.filters?.map
         FilterSource.updateMapFilter(@props.filters.map)
      EverTrue.Navigator(@getSearchLink(), true)
      @props.onClick?(e)

   getSearchLink: ->
      index = -1
      filters_array = _.map @props.filters, (value, key) =>
         index += 1
         filter_row_id: index.toString()
         filter_id: @props.config?[key]?.filter_id
         value: value

      search_link = @props.urlBase || "/contact"
      if @props.filters?.map then search_link += "/map"
      search_link + "?#{$.param({filters: filters_array})}"

   render: ->
      LinkButton
         title: "Go to search link"
         className: @props.className
         onClick: @handleClick
         @props.children

module.exports = connect(SearchLink, [FilterConfigStore], mapStateToProps)
