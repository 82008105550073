module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   classNames = require("classnames")
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {input, div} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   Icon = createFactory (require("components/elements/icon").default)


   createComponent "TextInput",
      propTypes:
         value: ReactLibs.PropTypes.any
         placeholder: ReactLibs.PropTypes.string
         onChange: ReactLibs.PropTypes.func

      handleChange: (e) ->
         input_value = @refs.input.value

         if _.isFunction(@props.onChange) && !!$.trim(input_value)
            @props.onChange(input_value, e)
         else if _.isFunction(@props.onChange) && !$.trim(input_value)
            @props.onChange(undefined, e)

      handleClear: (e) ->
         @props.onChange?(undefined, e)

      render: ->
         div className: classNames(@props.className, "filter-text-input"),
            input
               className: "filter-text-input--textbox"
               ref: "input"
               type: "text"
               placeholder: @props.placeholder
               value: @props.value?.value
               onChange: @handleChange

            if @props.value?.value
               Icon
                  icon: "cancel"
                  className: "filter-text-input--clear"
                  onClick: @handleClear
