import { useState } from "react";
import PropTypes from "prop-types";
import numeral from "numeral";
import EverTrue from "app";
import useResultCount from "apps/query-builder/hooks/use-result-count";
import useTotalCount from "apps/query-builder/hooks/use-total-count";
import QueryBuilder from "apps/query-builder/components/query-builder";
import QuickFilters from "apps/query-builder/components/quick-filters";
import PieChartGraph from "apps/query-builder/components/pie-chart-graph";
import { Button, Icon } from "@evertrue/et-components";
import colors from "base/colors";
import { updateList } from "apps/dynamic-lists/utils/list-actions";
import { UPDATE_PROP } from "apps/dynamic-lists/utils/dynamic-list-enums";

const propTypes = {
  closeModal: PropTypes.func,
  contactFilters: PropTypes.object,
  list_id: PropTypes.number,
};

const ModifyQueryController = ({ closeModal, contactFilters, list_id }) => {
  const [tempFilters, setTempFilters] = useState(contactFilters);
  const [totalCount] = useTotalCount();
  const [resultCount, isValid] = useResultCount(tempFilters);

  const { quick_filters } = tempFilters;

  const handleSubmit = async (list_id, dsl_criteria) => {
    EverTrue.Alert.confirm(
      {
        headline: "Update The List?",
        content:
          "The list will be updated using the new search criteria. It will not be viewable while the process is running.",
        buttonLabel: "Update List",
      },
      (confirmed) => {
        if (confirmed) {
          updateList(list_id, UPDATE_PROP.DSL_CRITERIA, dsl_criteria);
        }
      }
    );
  };

  return (
    <div className="filters-modal">
      <div className="filters-modal-header" style={{ marginBottom: "15px" }}>
        <div className="filters-modal-count">
          <PieChartGraph
            className="filters-pie-chart"
            totalValue={totalCount}
            data={[{ title: "Filtered Amount", value: resultCount, color: colors.green200 }]}
            startAngle={-90}
          />
          {`${numeral(resultCount).format("0,0")} Constituents`}
        </div>

        <div>
          <Button type="simple" onClick={closeModal} className="filters-modal-cancel">
            Cancel
          </Button>
          <Button
            type="secondary"
            disabled={!isValid}
            onClick={() => {
              EverTrue.track.set("filter_v2_action", {
                type: "apply_filters",
                selected_filter: tempFilters,
              });
              handleSubmit(list_id, tempFilters);
            }}
          >
            Apply
          </Button>
        </div>
      </div>
      <div className="modify-query-warning">
        <Icon icon="warning" size={1} style={{ color: "rgb(234, 197, 54)", marginRight: "8px" }} />
        <h5>Modifying search criteria will trigger an update to the dynamic list</h5>
      </div>
      <div className="filters-modal-quick" style={{ marginTop: "75px" }}>
        <QuickFilters
          appliedFilters={tempFilters}
          onChange={(change) => {
            setTempFilters({ ...tempFilters, quick_filters: change });
          }}
        />
        {quick_filters.length > 0 && (
          <Button
            type="simple"
            onClick={() => {
              setTempFilters({ ...tempFilters, quick_filters: [] });
            }}
          >
            <Icon icon="clear" />
            Reset
          </Button>
        )}
      </div>

      <QueryBuilder
        appliedFilters={tempFilters}
        onChange={(change) => {
          setTempFilters({ ...change });
        }}
      />
    </div>
  );
};

ModifyQueryController.propTypes = propTypes;
export default ModifyQueryController;
