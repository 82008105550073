import Api from "entities/helpers/api";
import _ from "underscore";
import PropTypes from "prop-types";
import { usePromiseResolver } from "@evertrue/promise-resolver";

const VolunteerMembership = ({ membership, show_only_role }) => {
  const [solicitor_data = []] = usePromiseResolver(() => {
    return Api.VOLUNTEER.SOLICITORS.post({
      urlExtend: "/bulk",
      params: { pool_id: membership.pool_id },
      data: _.jsonStringify([{ contact_id: membership.solicitor_contact_id }]),
    });
  }, []);
  const solicitor_info = solicitor_data[0] || {};

  // show only role is used in the key metrics because we dont ned the additional data
  // but do need the solicitor role request
  if (show_only_role) {
    return <div className="assignee-template--label">{solicitor_info.role}</div>;
  } else {
    return (
      <div>
        <div className="assignee-template--label">{membership.stage}</div>
        <div className="assignee-template--label">{solicitor_info.role}</div>
        <div className="assignee-template--degree">{membership.pool_name}</div>
      </div>
    );
  }
};

VolunteerMembership.propTypes = {
  membership: PropTypes.object,
  solicitor_contact_id: PropTypes.number,
  show_only_role: PropTypes.bool,
};

export default VolunteerMembership;
