module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   EverTrue = require("app")
   User = require("entities/auth/user")
   AuthApi = require("entities/auth/auth-api")
   PaginatedCollectionMixin = require("mixins/pagination-mixin")
   SortedCollectionMixin = require("mixins/sorted-mixin")
   SearchQueryCollectionMixin = require("mixins/search-query-mixin")
   Query = require("entities/search/query-parts/query")
   Parameter = require("entities/search/query-parts/parameter-query")
   Property = require("entities/search/query-parts/property-query")
   RoleHelper = require("entities/helpers/role-helper")


   class UserSearch extends AuthApi.Collection
      @include [PaginatedCollectionMixin, SearchQueryCollectionMixin]
      urlRoot: "search/v2/users/search"
      model: User

      parse: (data) ->
         @boundaries(data)
         data?.items || []

      # This method uses mixins to add additional functionality to
      # the requests options for sorting, paging and querying.
      fetch: (options={}) ->
         opts = _.clone(options)
         opts.params = $.extend true, {}, @paginate(options.params || {})
         opts = @queryOptions(opts)
         super opts

      getAccessibleUsers: ->
         return @toJSON()

      getContactIds: ->
         _.compact @map (user) -> user.getContact()

      comparator: (model) ->
         model.get("name")

      config:
         giving_tree_roles:
            query_fn: (value) ->
               return if value == false
               Query [
                  Parameter "must", [
                     Property "affiliations.affiliation_roles.role_id", RoleHelper.toArray(), {type: "contains"}
                  ]
               ]

         search:
            query_fn: (value) ->
               Query [
                  Parameter "must", [
                     Property ["name", "email"], "*#{value}*", {type: "wildcard"}
                  ]
               ]

         name_search:
            query_fn: (value) ->
               Query [
                  Parameter "must", [
                     Property "name", value, {type: "wildcard"}
                  ]
               ]

         bulk:
            query_fn: (value) ->
               Query [
                  Parameter "must", [
                     Property "id", value, {type: "contains"}
                  ]
               ]
