module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   {createSource} = require("@evertrue/et-flux")
   Decorator = require("clientDecorator")
   Api = require("entities/helpers/api")
   ErrorLogger = require("entities/helpers/error-log-helper")
   ExportSource = require("apps/export/sources/export-source")
   SortUtils = require("mixins/sort-utils")
   EngagementUtils = require("apps/events/utils/engagement-config")
   ExportFieldStore = require("apps/export/stores/export-field-store")

   {EVENT_FILTERS, getEngagementActionsByFilterKeys} = EngagementUtils

   LIMIT = 50
   _contacts_xhr = undefined
   _actions_xhr = undefined
   _abort = ->
      _contacts_xhr?.abort?()
      _actions_xhr?.abort?()

   renderQuery = (event_id, filter_keys, options = {}) ->
      {quick_search, sort_params} = options
      actions = getEngagementActionsByFilterKeys(filter_keys)
      query =
         has_child: [
            type: "contact_event_engagement",
            query: must: _.compact [
               { "event.id": match: event_id},
               if _.notEmpty(actions) then {"engagement_action": in: actions}
            ]
         ]
         sort: SortUtils.getQueryFromObj(sort_params)

      if quick_search
         _.extend query,
            Decorator.Filters.getQuery({full_name: quick_search}).toJSON()

      return query

   formatContacts = (contacts_resp = {}, actions = []) ->
      responses = _.groupBy(actions, "contact_id")
      _.each contacts_resp.items, (contact) ->
         actions = _.uniq(_.pluck(responses[contact.id], "engagement_action"))
         _.extend(contact, event_engagement_actions: actions)
      return contacts_resp

   createSource "EventDetailSource",
      actions:
         loadingEvent: (event_id) ->
            @require _.isFinite(event_id), "event_id should be a number"
         fetchedEvent: (event_id, event) ->
            @require _.isFinite(event_id), "event_id should be a number"
            @require _.isObject(event), "event should be an object"
         eventError: (event_id) ->
            @require _.isFinite(event_id), "event_id should be a number"
         fetchedContacts: (event_id, contacts) ->
            @require _.isFinite(event_id), "event_id should be a number"
            @require _.isObject(contacts), "contacts should be an object"
         fetchedEngagementCount: (event_id, filter, count) ->
            @require _.isFinite(event_id), "event_id should be a number"
            @require _.isString(filter), "filter should be a string"
            @require _.isFinite(count), "count should be a number"
         loading: (isLoading) ->
            @require _.isBoolean(isLoading), "isLoading should be true or false"
         newPage: (page) ->
            @require _.isFinite(page), "page should be a number"
         newConstituentQuickSearch: (search) ->
            @require _.isString(search), "search should be a string"
         clearConstituentSearch: true
         newEvent: (event_id, filters) ->
            @require _.isFinite(event_id), "event_id should be a number"
            @require _.isArray(filters), "filters should be an array"
         newSort: (key, reverse) ->
            @require _.isString(key), "key should be a string"
            @require _.isBoolean(reverse), "reverse should be true or false"
         export: (property_ids) ->
            @require _.isArray(property_ids), "property_ids should be an array"
         exported: true



      fetchEventEngagementActionCounts: (event_id) ->
         _.each EVENT_FILTERS, ({route_key, engagement_actions}) =>
            contact_query = has_child: [
               type: "contact_event_engagement"
               query: must: _.compact [
                  {"event.id": match: event_id},
                  if _.notEmpty(engagement_actions)
                     {"engagement_action": in: engagement_actions}
                  {"contact_id": exists: true}
               ]
            ]
            Api.CONTACTS.SEARCH.post
               data: _.jsonStringify(contact_query)
               params: offset: 0, limit: 0
               success: (resp) =>
                  @actions.fetchedEngagementCount(event_id, route_key, resp.total)
               error: (xhr) ->
                  ErrorLogger.captureRequest("Error loading engagement counts", xhr)

      fetchEventData: (event_id) ->
         @actions.loadingEvent(event_id)
         Api.EVENTS.EVENTS.post
            data: _.jsonStringify(must:[{id: match: event_id}])
            success: (event) =>
               if event.items.length > 1
                  ErrorLogger.warn "Returned more than one event",
                     tags: {event_id: event_id}
               @actions.fetchedEvent(event_id, event.items?[0])
            error: (xhr) ->
               ErrorLogger.captureRequest("Error loading event data", xhr)

      api:
         fetchEventById: (event_id) ->
            @fetchEventData(event_id)
            @fetchEventEngagementActionCounts(event_id)

         fetchContactsForEvent: (event_id, filters, options = {}) ->
            query = renderQuery(event_id, filters, options)
            _abort()
            @actions.loading(true)
            _contacts_xhr = Api.CONTACTS.SEARCH.post
               data: _.jsonStringify(query)
               params: offset: LIMIT * ((options.page || 1) - 1), limit: LIMIT
               success: (resp) =>
                  resp.items = _.map(resp.items, Decorator.Contacts.parse)
                  contact_ids = _.pluck(resp.items, "id")
                  if _.isEmpty(contact_ids)
                     @actions.fetchedContacts(event_id, resp)
                  else
                     _actions_xhr = Api.EVENTS.ENGAGEMENTS.post
                        data: _.jsonStringify(must: [
                           {"event.id": match: event_id},
                           {"contact_id": in: contact_ids}
                        ])
                        params: limit: 500
                        success: ({items}) =>
                           formated_contacts = formatContacts(resp, items)
                           @actions.fetchedContacts(event_id, formated_contacts, query)

         setPage: (page) ->
            @actions.newPage(page)

         setEvent: (event_id, filters) ->
            @actions.newEvent(event_id, filters)

         setConstituentQuickSearch: (search) ->
            @actions.newConstituentQuickSearch(search)

         clearConstituentSearch: ->
            @actions.clearConstituentSearch()

         sort: (key, reverse) ->
            @actions.newSort(key, reverse)

         export: (property_ids, event_id, filter = ["all"]) ->
            exportParams = ExportFieldStore.getExportParams(property_ids);

            data =
               name: "Event"
               search: renderQuery(event_id, filter)

            Api.CONTACTS.EXPORTS.post
               disableAlerts: true
               params: exportParams
               data: _.jsonStringify(data)
               success: =>
                  ExportSource.start()
                  @actions.exported()
               error: (xhr) ->
                  EverTrue.Alert.error("Problem starting export")
                  ErrorLogger.captureRequest("Problem starting export", xhr)









