import PropTypes from "prop-types";
import DatePickerDropdown from "components/calendar/date-picker-dropdown";

const CustomDateRangePicker = ({ startdate, enddate, onStartClick, onEndClick }) => {
  const disableDays = enddate ? { after: new Date(enddate) } : { after: new Date() };

  return (
    <>
      <div className="reporting--date-picker">
        Start Date
        <DatePickerDropdown
          date={startdate}
          onDayClick={onStartClick}
          triggerClassName={"task-calendar"}
          chevronClassName={"date-picker-chevron"}
          disabledDays={disableDays}
          allowClear
        />
      </div>

      <div className="reporting--date-picker">
        End Date
        <DatePickerDropdown
          date={enddate}
          onDayClick={onEndClick}
          triggerClassName={"task-calendar"}
          chevronClassName={"date-picker-chevron"}
          disabledDays={{
            before: new Date(startdate),
            after: new Date(),
          }}
          allowClear
        />
      </div>
    </>
  );
};

CustomDateRangePicker.propTypes = {
  startdate: PropTypes.any,
  enddate: PropTypes.any,
  onStartClick: PropTypes.func,
  onEndClick: PropTypes.func,
};

export default CustomDateRangePicker;
