module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   AllUsersStore = require("apps/users/stores/all-users-store").default
   ShareSegmentSource = require("apps/filters/sources/share-segment-source")
   ShareSegmentStore = require("apps/filters/stores/share-segment-store")
   FilterStore = require("apps/filters/stores/filter-store")
   ContactQueryStore = require("apps/contact/stores/contact-query-store")
   {textarea, div, span} = ReactLibs.DOMFactories
   TagInput = require("components/controls/tag-input")
   CopyInput = require("components/forms/copy-input")
   {createFactory} = require("base/new-utils")
   Module = createFactory require("components/module").default
   UserCard = createFactory require("apps/users/components/user-card")
   Checkbox = createFactory(require("@evertrue/et-components").Checkbox)
   Icon = createFactory (require("components/elements/icon").default)
   Button = createFactory(require("@evertrue/et-components").Button)

   updateFilterCriteria = () ->
      activeFilters = FilterStore.getActiveFilters()
      activeFiltersQueryResult = FilterStore.getActiveFiltersQuery()
      filterCriteria = _.extend({}, activeFiltersQueryResult, ContactQueryStore.getSortQuery())
      filterQuery = FilterStore.getEachFilterQuery()

      if activeFilters.length > 0 && activeFilters[0]?.filter_id == "3.5" && activeFilters[0]?.value?.in
         if activeFiltersQueryResult.must?
            activeFiltersQueryResult.must[0]["giving.last_gift_date"].in = activeFilters[0]?.value?.in
         else if activeFiltersQueryResult.must_not?
            activeFiltersQueryResult.must_not[0]["giving.last_gift_date"].in = activeFilters[0]?.value?.in
         filterCriteria = _.extend({}, activeFiltersQueryResult, ContactQueryStore.getSortQuery())

         if filterQuery.length > 0
            if filterQuery[0]?.criteria?.must?
               filterQuery[0].criteria.must[0]["giving.last_gift_date"]["in"] = activeFilters[0]?.value?.in
            else if filterQuery[0]?.criteria?.must_not?
               filterQuery[0].criteria.must_not[0]["giving.last_gift_date"]["in"] = activeFilters[0]?.value?.in
      {filterCriteria, filterQuery}

   createComponent "ShareSegmentController",
      getInitialState: ->
         notify_enabled: true
         just_copied: false
         query: undefined
         tags: []

      registerStores: ->
         @on ShareSegmentStore, ->
            shareable_link: ShareSegmentStore.getActiveLink()

         @on AllUsersStore, ->
            users: AllUsersStore.getAllGivingTreeUsers()

      UNSAFE_componentWillMount: ->
         {filterCriteria, filterQuery} = updateFilterCriteria()
         
         ShareSegmentSource.createShareLink
            criteria: filterCriteria
            filters: filterQuery

      componentWillUnmount: ->
         ShareSegmentSource.clearActiveLink()
         clearTimeout(@justCopiedTimeout)

      handleNotifiyChange: ->
         @setState {notify_enabled: !@state.notify_enabled}

      handleShare: ->
         {filterCriteria, filterQuery} = updateFilterCriteria()

         user_ids = _.pluck(@state.tags, "id")
         ShareSegmentSource.sendShareLink user_ids,
            criteria: filterCriteria
            filters: filterQuery
            skip_email_notification: !@state.notify_enabled
            message: @refs.note?.value

         @props.requestHide()

      handleCopy: ->
         @setState {just_copied: true}
         @justCopiedTimeout = _.delay =>
            @setState {just_copied: false} if @depracated_is_mounted
         , 5000

      renderJustSaved: ->
         if @state.just_copied
            span className: "share-segment--copied",
               Icon icon: "check", size: 1
               "Copied to clipboard"

      render: ->
         div className: "share-segment",
            div className: "share-segment--description",
               "Share your current search criteria. When shared searches are applied,
               constituent results may vary based on changes to underlying data."

            Module header: "Sharing Link", icon: "gt-icon gt-icon-link", additional_header_content: @renderJustSaved,
               CopyInput
                  url: @state.shareable_link
                  onCopy: @handleCopy

            Module header: "Share With EverTrue Users", icon: "gt-icon gt-icon-share",
               div className: "share-segment--invite-body loading-container",
                  TagInput
                     ref: "invites"
                     placeholder: "Search colleagues..."
                     selected: @state.tags
                     options: _.filter @state.users, (user) =>
                        query = @state.query
                        return false if _.isEmpty(query?.trim())
                        return false if _.findWhere @state.tags, {id: user.id}
                        _.searchCompare(user.name, query) || _.searchCompare(user.email, query)
                     onType: (query) =>
                        @setState {query: query}
                     onTag: (tags) =>
                        @setState {tags: tags, query: undefined}
                     itemContent: (user) ->
                        UserCard user: user

                  Checkbox
                     className: "share-segment--notify"
                     defaultChecked: @state.notify_enabled
                     onChange: @handleNotifiyChange
                     ref: "notify"
                     "Notify by email"

                  if @state.notify_enabled
                     textarea
                        className: "share-segment--note"
                        placeholder: "Add a note (optional)..."
                        ref: "note"

                  div className: "clearfix",
                     Button
                        disabled: _.isEmpty(@state.tags)
                        className: classNames
                           "pull-right": true
                        onClick: @handleShare
                        " Share "
