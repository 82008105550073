module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   {div, i} = ReactLibs.DOMFactories
   Popover = require("components/overlays/popover")
   {createFactory} = require("base/new-utils")
   Range = createFactory require("components/forms/range")
   numeral = require("numeral")
   FocusLock = createFactory(require("react-focus-lock").default)


   createComponent "RangeDropdown",
      propTypes:
         onChange: ReactLibs.PropTypes.func # args: ({gte, lte})
         gte: ReactLibs.PropTypes.oneOfType [
            ReactLibs.PropTypes.string
            ReactLibs.PropTypes.number
         ]
         lte: ReactLibs.PropTypes.oneOfType [
            ReactLibs.PropTypes.string
            ReactLibs.PropTypes.number
         ]
         title: ReactLibs.PropTypes.any
         position: ReactLibs.PropTypes.string
         isCurrency: ReactLibs.PropTypes.bool

      handleKeyPress: (event) ->
         if event.key is "Enter"
            _.defer => @popoverRef?.handleClose?()

      handleChange: (name, value = {}) ->
         @props.onChange(_.compactObject(value))

      formatDisplay: (num) ->
         if @props.isCurrency
            numeral(num).format("$0,0")
         else
            numeral(num).format("0,0")

      renderTitle: ->
         {gte, lte} = @props

         if gte and lte
            @formatDisplay(gte) + " - " + @formatDisplay(lte)
         else if gte
            "Above " + @formatDisplay(gte)
         else if lte
            "Below " + @formatDisplay(lte)
         else
            "Any Amount"

      render: ->
         {gte, lte} = @props

         Popover
            ref: (node) => @popoverRef = node
            className: classNames(@props.className, "range-dropdown")
            position: @props.position
            trigger: div className: "range-dropdown--header",
               @props.title || @renderTitle()
               i className: "fa fa-fw fa-angle-down"
            onClose: @handleClose

            FocusLock null,
               div className: classNames("range-dropdown--wrap", if @props.className then @props.className + "--body"),
                  Range
                     name: "range-dropdown"
                     min: @formatDisplay(0)
                     max: "any"
                     value: {gte, lte}
                     showClear: true
                     mask: if @props.isCurrency then {prefix: "$"}
                     onChange: @handleChange
                     onKeyPress: @handleKeyPress
