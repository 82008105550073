import { useContext } from "react";
import { PropTypes } from "base/react-libs";
import QuickFilters from "apps/query-builder/components/quick-filters";
import QueryBuilderModal from "apps/query-builder/components/query-builder-modal";
import { GlobalContactSearchContext } from "apps/layout/controllers/global-contact-search-context";
import { ListDataContext } from "apps/layout/controllers/list-data-context";
import BookmarkedSearches from "./bookmarked-searches";
import MoreFiltersModal from "apps/query-builder/components/more-filters-modal";
import IsGated from "components/is-gated";

const FiltersBarController = ({ onApply = () => {}, children, shouldSetModified = false, list_id }) => {
  const { contactFilters, setContactFilters, setModified, setMapMarkers, filterCount, setIsAllSelectedCheckbox } =
    useContext(GlobalContactSearchContext);
  const { resetTable } = useContext(ListDataContext);

  const updateContactFilters = (filters) => {
    setContactFilters(filters);

    if (shouldSetModified) {
      setModified(true);
    }
    resetTable();
    setMapMarkers({});
  };

  const handleUpdate = (change) => {
    updateContactFilters(change);
    onApply();
  };

  const handleQuickFilterChange = (change) => {
    const filtersCopy = { ...contactFilters };
    filtersCopy.quick_filters = change;
    updateContactFilters(filtersCopy);
  };

  return (
    <div className="filters-bar">
      <div className="filters-bar-filter-actions">
        <QuickFilters onChange={(change) => handleQuickFilterChange(change)} appliedFilters={contactFilters} />
        <QueryBuilderModal
          onApply={(change) => handleUpdate(change)}
          list_id={list_id}
          filterCount={filterCount}
          contactFilters={contactFilters}
          setIsAllSelectedCheckbox={setIsAllSelectedCheckbox}
        />
        <IsGated feature="unreleased_feature">
          <MoreFiltersModal />
        </IsGated>
        {window.location.pathname === "/constituents" && <BookmarkedSearches />}
        {children}
      </div>
    </div>
  );
};

FiltersBarController.propTypes = {
  onApply: PropTypes.func,
  children: PropTypes.node,
  shouldSetModified: PropTypes.bool,
  list_id: PropTypes.string,
};

export default FiltersBarController;
