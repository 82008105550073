import { connect } from "@evertrue/et-flux";
import SelectedContactsStore from "apps/contact/stores/selected-contacts-store";

const mapStateToProps = () => ({
  selected: SelectedContactsStore.getSelectedContacts(),
});

const SelectedContactsResolver = ({ render, selected }) => render({ selected });

export default connect(
  SelectedContactsResolver,
  [SelectedContactsStore],
  mapStateToProps
);
