import { useState, useEffect, useCallback } from "react";
import Api from "entities/helpers/api";

const useFacets = (filterId) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const getFacets = useCallback(
    async (search, after) => {
      if (!filterId) return;
      const params = {
        limit: 50,
        ...(search && { search: encodeURIComponent(search) }),
        ...(after && { after: encodeURIComponent(after) }),
      };

      try {
        const facets = await Api.HUB.FILTER_OPTIONS.get({ urlArgs: { filter_id: filterId }, params });
        if (after) {
          setOptions((prev) => [...prev, ...facets]);
        } else {
          setOptions(facets);
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log({ e });
      }
    },
    [filterId]
  );

  useEffect(() => {
    setLoading(true);
    getFacets();
  }, [getFacets]);

  return { options, getFacets, loading };
};

export default useFacets;
