module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   str = require("underscore.string")
   React = require("react")
   ReactDOM = require("react-dom")
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories
   OutsideClickMixin = require("mixins/outside-click-mixin")
   WindowOverflowMixin = require("mixins/window-overflow-mixin")
   {getAllFocusable} = require("base/accessibility-utils")
   colors = require("base/colors")

   ARROW_BORDER_SIZE = 1
   OVERFLOW_MARGIN = 10


   createComponent "PopoverOverlay",
      mixins: [OutsideClickMixin]
      propTypes:
         position: ReactLibs.PropTypes.oneOf([
            "top"
            "bottom"
            "left"
            "right"
            "bottom-right-aligned"
            "bottom-left-aligned"
            "left-top-aligned"
            "right-top-aligned"
         ])
         width: ReactLibs.PropTypes.number
         height: ReactLibs.PropTypes.number
         origin: ReactLibs.PropTypes.object
         arrowSize: ReactLibs.PropTypes.number
         arrowColor: ReactLibs.PropTypes.string
         arrowBorderColor: ReactLibs.PropTypes.string
         arrowPosition: ReactLibs.PropTypes.object
         onClose: ReactLibs.PropTypes.func

      getDefaultProps: ->
         position: "bottom"
         origin: {width: 175, height: 10, top: 0, left: 0}
         arrowSize: 11
         arrowColor: "#fff"
         arrowBorderColor: colors.blue300

      getInitialState: ->
         height: 100
         width: @props.width || 175
         content_position: {}
         is_overflow: false
         arrow_position: {}
         arrow_position_adjusted: {}
         position: @props.position

      componentDidMount: ->
         @setPosition(@refs.content)
         elements = getAllFocusable(@refs.content)
         elements[0]?.focus()

      UNSAFE_componentWillReceiveProps: (newProps) ->
         if @props.position != newProps.position
            @setState {position: newProps.position}, =>
               @setPosition(@refs.content)

      handleOutsideClick: ->
         @props.onClose?()

      handleChangePosition: ->
         $content = $(@refs.content)
         @setContentPosition($content.width(), $content.height())
         @setArrowPosition()

      setPosition: (node) ->
         if WindowOverflowMixin.checkWindowHeightOverflow(node, @props.arrowSize * 2)
            if @state.position == "bottom"
               @setState {position: "top"}, =>
                  @handleChangePosition()
         else @handleChangePosition()

      setArrowPosition: ->
         {width, height} = @props.origin
         size = @props.arrowSize
         pos = @state.position.split("-")[0]

         position = switch pos
            when "top"
               {left: (width / 2) - size, top: -size}
            when "bottom"
               {left: (width / 2) - size, top: height}
            when "right"
               {left: width, top: (height / 2) - size}
            when "left"
               {left: -size , top: (height / 2) - size}
            else {}
         arrow_position = _.extend {left: position.left, top: position.top}, @props.arrowPosition
         @setState arrow_position: arrow_position
         @setArrowBorderAdjust(arrow_position)

      setArrowBorderAdjust: (arrow_position) ->
         pos = @state.position.split("-")[0]
         arrow_position_adjusted = switch pos
            when "top" then {top: arrow_position.top - ARROW_BORDER_SIZE}
            when "bottom" then {top: arrow_position.top + ARROW_BORDER_SIZE}
            when "left" then {left: arrow_position.left - ARROW_BORDER_SIZE}
            when "right" then {left: arrow_position.left + ARROW_BORDER_SIZE}
            else {}
         @setState arrow_position_adjusted: arrow_position_adjusted

      setContentPosition: (width, height) ->
         origin = @props.origin
         width = @props.width || width # width of the popover content
         height = @props.height || height
         arrow = @props.arrowSize
         top_aligned_margin = -10 # to position the arrow a little below the top of the popover

         position = switch @state.position
            when "top"
               natural_top = height + arrow
               limit_top = @props.origin?.top - 12
               is_overflow = natural_top > limit_top
               top = if is_overflow then limit_top else natural_top

               {left: (origin.width / 2) - (width / 2), top: -top}
            when "bottom"
               {left: (origin.width / 2) - (width / 2), top: origin.height + arrow}
            when "right"
               {left: origin.width + arrow, top: (origin.height / 2) - (height / 2)}
            when "left"
               {left: -(width + arrow), top: (origin.height / 2) - (height / 2)}
            when "bottom-left-aligned"
               {left: 0, top: origin.height + arrow}
            when "bottom-right-aligned"
               {left: -width + origin.width, top: origin.height + arrow}
            when "left-top-aligned"
               {left: -(width + arrow), top: top_aligned_margin}
            when "right-top-aligned"
               {left: origin.width + arrow, top: top_aligned_margin}
            else
               {}

         # Adjust Content container for window overflow
         if @state.position in ["top", "bottom"]
            window_width = $(window).outerWidth()
            offset_left = @props.origin.left + position.left
            offset_right = @props.origin.left + position.left + width

            if offset_left < 0
               position.left += Math.abs(offset_left) + OVERFLOW_MARGIN
            else if offset_right > window_width
               position.left -= (offset_right - window_width) + OVERFLOW_MARGIN

         else if @state.position in ["left", "right"]
            window_height = $(window).outerHeight()
            offset_top = @props.origin.top + position.top
            offset_bottom = @props.origin.top + position.top + height

            if offset_top < 0
               position.top += Math.abs(offset_top) + OVERFLOW_MARGIN
            else if offset_bottom > window_height
               position.top -= (offset_bottom - window_height) + OVERFLOW_MARGIN

         @setState
            content_position: {top: position.top, left: position.left}
            is_overflow: is_overflow

      render: ->
         position = @state.position.split("-")[0]

         div className: classNames("popover-overlay", @props.className),
            div
               ref: "content"
               className: "popover-overlay--content"
               onClick: @handleInsideClick
               style: @state.content_position

               @props.children

            if @props.arrowSize > 0 and not @state.is_overflow
               div null,
                  div
                     className: "popover-overlay--arrow is-#{position}"
                     style: _.extend {}, @state.arrow_position,
                        borderWidth: @props.arrowSize,
                        _.toObject("border#{str.titleize(position)}Color", @props.arrowBorderColor)

                  div
                     className: "popover-overlay--arrow is-#{position}"
                     style: _.extend {}, @state.arrow_position,
                        @state.arrow_position_adjusted,
                        borderWidth: @props.arrowSize,
                        _.toObject("border#{str.titleize(position)}Color", @props.arrowColor)
