import { useState, useContext } from "react";
import { FlexTable, useOpenClose, ButtonTooltip, Icon, Link } from "@evertrue/et-components";
import TasksToolbar from "./tasks-toolbar";
import DeleteModal from "./delete-modal";
import {
  daysFromNow,
  formattedDate,
  getEmptyStateMessageText,
  getEmptyStateMessageSubtext,
} from "apps/tasks/utils/utils";
import Avatar from "components/elements/avatar";
import useTasks from "apps/tasks/hooks/use-tasks";
import { PropTypes } from "base/react-libs";
import { TasksContext } from "apps/tasks/hooks/tasks-context";
import CompleteTaskToggle from "../complete-task-toggle";
import AddTaskButton from "../create/add-task-button";
import StrikeThroughWrapper from "components/containers/strikethrough-wrapper";
import { useTasksNotificationContext } from "apps/tasks/hooks/tasks-notification-context.js";
import { useIdentity } from "base/identity-resolver";
import TaskName from "./task-name";
import AddTask from "apps/tasks/components/create/add-task";
import AddToCalendarModal from "apps/tasks/components/create/add-to-calendar-modal";

const TasksTable = ({ tasks = [], loading, total, tab = "", userType, taskFilters = {}, contact = {} }) => {
  const { getAndSetGlobalTasksDueCount } = useTasksNotificationContext();
  const { referrer } = useContext(TasksContext);
  const { user } = useIdentity();
  const { deleteTask, toggleCompletionStatus, parseActionType } = useTasks();
  const [selectedTask, setSelectedTask] = useState({});
  const [isOpen, openModal, closeModal] = useOpenClose();
  const [isAddToCalendarModalOpen, openAddToCalendarModal, closeAddToCalendarModal] = useOpenClose();
  const [isTaskModalOpen, setTaskModalOpen] = useState(false);

  const columns = [
    {
      key: 1,
      headerStyle: { flexGrow: 1 },
      header: "",
      cellClassName: "task-icon-col",
      accessor: (data) => {
        if (data && data.status === "PAUSED") {
          return (
            <ButtonTooltip
              children="disabled because this task is currently paused"
              trigger={<Icon icon="pause" size={2} className="paused-task-icon" />}
            />
          );
        }
        if (data.owner_user_id === user.id) {
          return <CompleteTaskToggle task={data} onClick={toggleCompletionStatus} ownerUserId={data.owner_user_id} />;
        } else {
          return (
            <ButtonTooltip
              children="Complete Task option is disabled because you are not the owner of this task"
              contentPadding={"4px 8px"}
              trigger={<span className="dash-task-circle" />}
            />
          );
        }
      },
    },
    {
      key: 2,
      width: 260,
      header: "Task Name",
      accessor: (task) => {
        const { title, completed_at, metadata = {} } = task;
        return (
          <TaskName
            title={title}
            completed_at={completed_at}
            metadata={metadata}
            onClick={() => {
              setSelectedTask(task);
              isTaskModalOpen ? setTaskModalOpen(false) : setTaskModalOpen(true);
            }}
          />
        );
      },
    },
    {
      key: 3,
      width: 200,
      header: "Constituent",
      accessor: ({ contact = {}, target_contact_id, completed_at }) => {
        let name_full, url;
        // workaorund until we can fix GQL to not return null for empty fields
        if (contact !== null) {
          name_full = contact.contact_attributes ? contact.contact_attributes.name_full : "";
          url = contact.profile_image && contact.profile_image.url ? contact.profile_image.url : "";
        }
        return (
          <StrikeThroughWrapper hasStrikeThrough={!!completed_at}>
            {target_contact_id ? (
              <span style={{ display: "flex", alignItems: "center" }}>
                <Link title={`${name_full}'s Profile`} href={`/contact/${target_contact_id}`}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Avatar
                      className="new-contact-card--avatar fs-exclude-data"
                      name={name_full}
                      url={url || ""}
                      width={40}
                    />
                    <div className="journeys-contact-card-name table-toolbar-amount fs-exclude-data">{name_full}</div>
                  </div>
                </Link>
              </span>
            ) : (
              <span>-</span>
            )}
          </StrikeThroughWrapper>
        );
      },
    },
    ...(!taskFilters.statuses.includes("COMPLETED")
      ? [
          {
            key: 4,
            header: "Due Date",
            accessor: ({ due_date, completed_at }) => {
              if (due_date) {
                const due = daysFromNow(due_date);
                return (
                  <StrikeThroughWrapper hasStrikeThrough={!!completed_at}>
                    <span className="tasks--due-date">
                      {due.msg ? <p style={{ color: "red" }}>{due.msg}</p> : <p>{due.date}</p>}
                    </span>
                  </StrikeThroughWrapper>
                );
              } else return <span>-</span>;
            },
          },
        ]
      : []),
    {
      key: 5,
      header: "Action Type",
      accessor: ({ action_type, completed_at }) => (
        <StrikeThroughWrapper hasStrikeThrough={!!completed_at}>
          {action_type ? <span className="fs-exclude-data">{parseActionType(action_type)}</span> : null}
        </StrikeThroughWrapper>
      ),
    },
    {
      key: 6,
      header: "Assignee",
      accessor: ({ owner_info, completed_at }) => (
        <StrikeThroughWrapper hasStrikeThrough={!!completed_at}>
          {owner_info ? owner_info.name : null}
        </StrikeThroughWrapper>
      ),
    },
    {
      key: 7,
      header: "Created By",
      accessor: ({ completed_at, creator_info }) => (
        <StrikeThroughWrapper hasStrikeThrough={!!completed_at}>
          {creator_info ? <span className="fs-exclude-data">{creator_info.name}</span> : null}
        </StrikeThroughWrapper>
      ),
    },
    ...(!taskFilters.statuses.includes("IN_PROGRESS")
      ? [
          {
            key: 8,
            header: "Completed At",
            accessor: (data) => (data.completed_at ? formattedDate(data.completed_at) : null),
          },
        ]
      : []),
    {
      key: 9,
      header: "",
      headerStyle: { flexGrow: 1 },
      cellClassName: "task-icon-col",
      accessor: (task) => {
        if (task && task.status === "PAUSED") {
          return (
            <ButtonTooltip
                    children={"Add to calendar is disabled because this task is currently paused"}
              contentPadding={"4px 8px"}
              trigger={<Icon icon="add-task-table" iconKit="gt2" style={{ color: "grey" }} />}
            />
          );
        }
        if (task.owner_user_id === user.id || task.creator_user_id === user.id)
          return (
            <ButtonTooltip
              children="Add to Calendar"
              contentPadding={"4px 8px"}
              trigger={
                <Icon
                  icon="add-task-table"
                  iconKit="gt2"
                  onClick={() => {
                    setSelectedTask(task);
                    openAddToCalendarModal();
                  }}
                  style={{ color: "#007C9E" }}
                  onKeyUp={() => {
                    setSelectedTask(task);
                    openAddToCalendarModal();
                  }}
                  isReachable={true}
                />
              }
            />
          );
        else
          return (
            <ButtonTooltip
               children={"Add to calendar is disabled because you are not the owner or creator of this task"}
              contentPadding={"4px 8px"}
              trigger={<Icon icon="add-task-table" iconKit="gt2" style={{ color: "grey" }} />}
            />
          );
      },
    },
    {
      key: 10,
      headerStyle: { flexGrow: 1 },
      header: "",
      cellClassName: "task-icon-col",
      accessor: (data) => {
        if (data && data.status === "PAUSED")
          return (
            <ButtonTooltip
                    children={"Delete task is disabled because task is currently paused"}
              contentPadding={"4px 8px"}
              trigger={<Icon icon="trash" style={{ color: "grey" }} />}
            />
          );
        if (data.owner_user_id === user.id)
          return (
            <ButtonTooltip
              children="Delete"
              contentPadding={"4px 8px"}
              trigger={
                <Icon
                  icon="trash"
                  onClick={() => {
                    setSelectedTask(data);
                    isOpen ? closeModal() : openModal();
                  }}
                  isReachable={true}
                  onKeyUp={() => {
                    setSelectedTask(data);
                    isOpen ? closeModal() : openModal();
                  }}
                />
              }
            />
          );
        else
          return (
            <ButtonTooltip
              children="Delete option is disabled because you are not the owner of this task"
              contentPadding={"4px 8px"}
              trigger={<Icon icon="trash" style={{ color: "grey" }} />}
            />
          );
      },
    },
  ];

  return (
    <>
      <div>
        <TasksToolbar numTasks={total} userType={userType} filterStatus={taskFilters} />
        <div className="tasks-lists-table">
          <FlexTable
            caption="Lists"
            emptyStateMessageText={getEmptyStateMessageText(userType, taskFilters.statuses)}
            emptyStateMessageSubtext={getEmptyStateMessageSubtext(userType, taskFilters.statuses)}
            emptyStateActionButton={
              !taskFilters.statuses.includes("COMPLETED") ? (
                <AddTaskButton primary={true} userType={userType} contact={contact} />
              ) : null
            }
            columns={
              referrer === "contact"
                ? columns.filter((col) => col.header !== "Constituent")
                : tab === "assigned"
                ? columns.filter((col) => col.header !== "Created By")
                : tab !== "assigned"
                ? columns.filter((col) => col.header !== "Assignee")
                : columns
            }
            data={tasks.slice(0, 50)}
            loading={loading}
          />
        </div>
      </div>
      <DeleteModal
        title="Delete Task"
        body="Are you sure you want to delete this task?"
        isOpen={isOpen}
        onClose={closeModal}
        onSubmit={async () => {
          await deleteTask(selectedTask, userType);
          getAndSetGlobalTasksDueCount();
        }}
      />
      {isAddToCalendarModalOpen && (
        <AddToCalendarModal
          isOpen={isAddToCalendarModalOpen}
          closeModal={closeAddToCalendarModal}
          calendarParams={{
            ...selectedTask,
            due_date: selectedTask.due_date * 1000,
          }}
          showTaskSaved={false}
        />
      )}
      <>
        <AddTask
          isOpen={isTaskModalOpen}
          closeModal={() => {
            setTaskModalOpen(false);
          }}
          handleClose={() => {
            setTaskModalOpen(false);
          }}
          taskObj={selectedTask}
        />
      </>
    </>
  );
};

TasksTable.propTypes = {
  loading: PropTypes.bool,
  tasks: PropTypes.array,
  total: PropTypes.number,
  tab: PropTypes.string,
  userType: PropTypes.string,
  taskFilters: PropTypes.object,
  contact: PropTypes.object,
};

export default TasksTable;
