module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   EverTrue = require("app")
   AuthApi = require("entities/auth/auth-api")


   class Contact extends AuthApi.Model
      urlRoot: ->
         "contacts/v1/contacts/#{@get("id")}"

      defaults:
         avatar: undefined
         properties:
            name_first: value: ""
            name_last: value: ""
            year: value: ""

      parse: (data) ->
         person = customs: {}
         if _.isArray data?.items
            properties = _.first data.items
         else
            properties = data

         # Convert array of property objects into object of property objects
         @toPropLookup = (properties) ->
            lookup = {}
            _.each properties, (prop) -> lookup[prop.name] = prop
            lookup

         # Takes object or array of objects and converts to property lookup
         @parseObject = (obj) ->
            @toPropLookup(obj.properties) unless _.isEmpty obj.properties

         # Check if item is a contact object
         @isObjectList = (item) ->
            check = if _.isArray(item) then _.first item else item
            check && check.object_type

         # Loop through all properties from search
         _.each properties, (value, key) =>
            person[key] = value

            # Values will be objects or arrays of objects with properties
            if @isObjectList value
               if key == "properties"
                  person[key] = @toPropLookup(value)
               else if _.isArray value
                  # Group Custom properties into an array
                  props = _.first(value)?.properties
                  if !props?[0]?.default && !_.isEmpty(props)
                     parsed_customs = _.map value, (obj) => @parseObject(obj)
                     person.customs[key] = parsed_customs
                     person[key] = parsed_customs
                  else
                     person[key] = _.map value, (obj) => @parseObject(obj)
                     # Set groups that are hidden based on permissions to "private" for display in UI
                     if _.every person[key], _.isUndefined
                        person[key] = "private"
               else
                  person[key] = @parseObject(value)

         facebook_uid = _.findWhere(person.identities, {type: "facebook_id"})

         # Make avatar more accessible
         if not _.isEmpty person.linkedin?.picture_url?.value
            person.avatar = person.linkedin.picture_url.value
         else if not _.isEmpty facebook_uid?.value
            person.avatar = "https://graph.facebook.com/#{facebook_uid.value}/picture"

         # Cache all contacts by ID for seamless profile loading
         contactIdMap = EverTrue.store.contactCache || {}
         contactIdMap[properties?.id] = $.extend true, {}, @defaults, person
         EverTrue.store.push "contactCache", contactIdMap

         person

      toJSON: ->
         linkedin_data = @get("lids")
         if linkedin_data?.properties?.picture_url
            @set "avatar", linkedin_data.properties.picture_url, {silent: true}
         super

      recordProfileView: ->
         tempModel = new AuthApi.Model()
         tempModel.urlRoot = "contacts/v1/profile_views/#{@get("id")}"
         if not EverTrue.request "isImpersonating?"
            tempModel.save null,
               disableAlerts: true
               headers: {"Content-Type": "application/json"}
