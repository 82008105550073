import _ from "underscore";
import { createRef, Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import FormField from "components/forms/form-field";
import { Switch, Radio, Flex, ModalConfirm, TextInput, NumericInput } from "@evertrue/et-components";
import GivingCategorySelector from "apps/volunteers/components/giving-category-selector";
import StageGroupSelector from "apps/volunteers/components/stage-group-selector";
import { SELF_SELECTION_STRING, FISCAL_YEAR } from "apps/volunteers/constants";
import CalendarDropdown from "components/controls/calendar-dropdown";
import str from "underscore.string";

const DATE_FORMAT = "YYYY-MM-DD";
const CUSTOM = "CUSTOM";

class PoolFormModalBody extends Component {
  static propTypes = {
    pool: PropTypes.object,
    onClose: PropTypes.func,
    onUnmount: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    header: PropTypes.string.isRequired,
    buttonLabel: PropTypes.string.isRequired,
  };

  static defaultProps = {
    loading_categories: false,
    giving_categories: [],
    pool: {
      name: "",
      participation_goal: null,
      dollar_amount_goal: null,
      duration_type: FISCAL_YEAR,
      end_date: undefined,
      start_date: undefined,
    },
    onClose: _.noop,
    onUnmount: _.noop,
    remove: _.noop,
  };

  state = {
    temp_pool: this.props.pool,
  };

  nameRef = createRef();

  componentDidMount() {
    this.nameRef.current.focus();
    if (this.state.temp_pool.name) {
      this.nameRef.current.select();
    }
  }

  handleChange = (statePiece, key, value) => {
    this.setState((state) => ({ [statePiece]: { ...state[statePiece], [key]: value } }));
  };

  handlePoolChange = _.partial(this.handleChange, "temp_pool");
  handleSelectGivingType = _.partial(this.handlePoolChange, "giving_category_label");

  handleSubmit = () => {
    this.props.handleSubmit(this.state);
    this.handleClose();
  };

  handleClose = () => {
    this.props.onClose();
    this.props.onUnmount();
  };

  render() {
    const { temp_pool: pool } = this.state;
    const giving_category = pool.giving_category_label || null;
    const is_disabled = !str.trim(pool.name);

    return (
      <ModalConfirm
        header={this.props.header}
        saveLabel={this.props.buttonLabel}
        hasMargin
        closeModal={this.handleClose}
        disableSave={is_disabled}
        onSubmit={this.handleSubmit}
        onCancel={this.handleClose}
      >
        <FormField label="Pool Name" inlineLabel={false}>
          <input
            ref={this.nameRef}
            type="text"
            placeholder="Name your pool"
            value={pool.name}
            onChange={(e) => this.handlePoolChange("name", e.currentTarget.value)}
          />
        </FormField>
        <div className="pool-form-modal--giving-stages">
          <FormField label="Giving Data Type" inlineLabel={false}>
            <GivingCategorySelector
              value={giving_category}
              onChange={({ value }) => this.handleSelectGivingType(value)}
            />
          </FormField>
          <StageGroupSelector
            selectedStageGroup={{ id: pool.stage_group_id }}
            showLabel
            onChange={({ id }) => this.handlePoolChange("stage_group_id", id)}
          />
        </div>
        <div className="pool-form-modal--assign-section">
          <strong>Volunteer Assignments</strong>
          <div className="pool-form-modal--switch-section">
            <div>Allow volunteers to choose assignments</div>
            <Switch
              name="selection_mode"
              checked={pool.selection_mode === SELF_SELECTION_STRING}
              value={pool.selection_mode}
              onChange={(e) => {
                const bool = e ? SELF_SELECTION_STRING : "disabled";
                this.handlePoolChange("selection_mode", bool);
              }}
            />
          </div>
        </div>
        <div className="pool-form-modal--assign-section">
          <strong>Lead Volunteer Settings</strong>
          <div className="pool-form-modal--switch-section">
            <div>Allow lead volunteers to update stage and committed amounts</div>
            <Switch
              name="lead_updates_enabled"
              checked={pool.lead_updates_enabled ? pool.lead_updates_enabled : false}
              value={pool.lead_updates_enabled ? pool.lead_updates_enabled : false}
              onChange={(e) => {
                this.handlePoolChange("lead_updates_enabled", e);
              }}
            />
          </div>
        </div>
        <div>
          <FormField label="Pool Duration Type" inlineLabel={false}>
            <Radio
              label="Fiscal Year"
              checked={pool.duration_type === FISCAL_YEAR || pool.duration_type === undefined}
              onChange={() => this.handlePoolChange("duration_type", FISCAL_YEAR)}
            >
              Fiscal Year
            </Radio>
            <Radio
              label="Custom"
              checked={pool.duration_type === CUSTOM}
              onChange={() => this.handlePoolChange("duration_type", CUSTOM)}
            >
              Custom
            </Radio>
          </FormField>
          {pool.duration_type === CUSTOM && (
            <FormField label="Custom Reporting Date Range" inlineLabel={false}>
              <CalendarDropdown
                disabled={pool.duration_type !== CUSTOM}
                className="pool-form-modal--date-picker"
                date_from={moment(pool.start_date).format(DATE_FORMAT)}
                date_to={moment(pool.end_date).format(DATE_FORMAT)}
                onChange={(custom_start_date, custom_end_date) => {
                  this.handlePoolChange("start_date", moment(custom_start_date, DATE_FORMAT).valueOf());
                  this.handlePoolChange("end_date", moment(custom_end_date, DATE_FORMAT).valueOf());
                }}
              />
            </FormField>
          )}
        </div>
        <div className="pool-form-modal--goals">
          <FormField inlineLabel={false} label="Participation Goal">
            <NumericInput
              maskType="percent"
              showIcon
              value={pool.participation_goal}
              integerLimit={3}
              placeholder="Total Participation"
              onChange={(value) => {
                value <= 100
                  ? this.handlePoolChange("participation_goal", value)
                  : this.handlePoolChange("participation_goal", this.state.temp_pool.participation_goal);
              }}
            />
          </FormField>
          <FormField inlineLabel={false} label="Total Dollar Amount Goal">
            <NumericInput
              integerLimit={13}
              placeholder="Total Dollars"
              showIcon
              maskType="currency"
              value={pool.dollar_amount_goal}
              onChange={(value) => {
                this.handlePoolChange("dollar_amount_goal", value);
              }}
            />
          </FormField>
        </div>
        <Flex justifyContent="space-between">
          <FormField inlineLabel={false} label="Giving page URL">
            <TextInput
              className="pool-form-modal--url"
              value={pool.giving_page_url}
              onChange={(val) => this.handlePoolChange("giving_page_url", val)}
            />
          </FormField>
          <FormField inlineLabel={false} label="Resource URL">
            <TextInput
              className="pool-form-modal--url"
              value={pool.resource_url}
              onChange={(val) => this.handlePoolChange("resource_url", val)}
            />
          </FormField>
        </Flex>
      </ModalConfirm>
    );
  }
}

export default PoolFormModalBody;
