import { Fragment, Component } from "react";
import PropTypes from "prop-types";
import Decorator from "clientDecorator";
import EverTrue from "app";
import AppInvitationModal from "apps/volunteers/components/app-invitation-modal";
import { Modal2 } from "@evertrue/et-components";

class ResendInvite extends Component {
  static propTypes = {
    contact: PropTypes.object,
    invite: PropTypes.object,
    render: PropTypes.func,
    roleName: PropTypes.string,
    solicitor: PropTypes.object,
    poolId: PropTypes.number,
    hideHeadAgent: PropTypes.bool,
  };

  static defaultProps = {
    invite: {},
    contact: {},
    render: () => null,
    roleName: "",
    solicitor: {},
  };

  state = {
    show_resend_modal: false,
  };

  handleResend = () => {
    const formatted_name = Decorator.Contacts.getDetailedName(this.props.contact) || "this volunteer";
    EverTrue.Alert.confirm(
      {
        headline: "Are you sure?",
        content: `Resending this app invite will make ${formatted_name}'s previous invite invalid.`,
        buttonLabel: "Continue",
      },
      did_confirm => {
        if (did_confirm) {
          this.setState({ show_resend_modal: true });
        }
      }
    );
  };

  handleClose = () => {
    this.setState({ show_resend_modal: false });
  };

  render() {
    return (
      <Fragment>
        {this.props.render({
          handleResend: this.handleResend,
        })}
        <Modal2 isOpen={this.state.show_resend_modal} onClose={this.handleClose}>
          <AppInvitationModal
            key={this.props.contact.id}
            contact={this.props.contact}
            roleName={this.props.roleName}
            inviteId={this.props.invite.id}
            solicitor={this.props.solicitor}
            poolId={this.props.poolId}
            hideHeadAgent={this.props.hideHeadAgent}
            onClose={this.handleClose}
          />
        </Modal2>
      </Fragment>
    );
  }
}

export default ResendInvite;
