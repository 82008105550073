import * as Yup from "yup";

export const TaskFormSchema = Yup.object().shape({
    title: Yup.string()
        .min(4, "Title is too short.")
        .max(120, "Title is too long.")
        .required("Please enter a title"),
    description: Yup.string()
        .nullable()
        .max(250, "Description is too long"),
    due_date: Yup.number()
        .nullable(),
    owner: Yup.object().shape({
        label: Yup.string().nullable(),
        value: Yup.number().nullable(),
    })
        .nullable(),
    actionType: Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.mixed()
            .oneOf(["OTHER", "RESEARCH", "CALL", "THANK_VIEW", "LINKEDIN", "PRINT_MAIL", "TEXT", "EMAIL"])
            .required(),
    })
        .required("Please select an action type"),
    targetContact: Yup.object().shape({
        id: Yup.number().nullable(),
        name: Yup.string().nullable(),
        avatar: Yup.string().nullable(),
    })
        .nullable(),
});