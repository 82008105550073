import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useIdentity } from "base/identity-resolver";
import Decorator from "clientDecorator";
import ProposalTable from "apps/proposals/components/proposal-table";
import ProposalTableFilterYours from "../components/proposal-table-filter-yours";
import ProposalTableFilterActive from "../components/proposal-table-filter-active";
import ProposalTableFilterStage from "../components/proposal-table-filter-stage";
import ProposalTableSort from "../components/proposal-table-sort";
import useProposalQueryBuilder from "../hooks/use-proposal-query-builder";
import { defaultDateSortOption } from "../utils";

const LIMIT = 10;

const ProposalSearchController = ({ prospectContactId, refetch }) => {
  const { user, org } = useIdentity();
  const userContactId = Decorator.User.getContactId(user, org.id);
  const tableSettings = JSON.parse(localStorage.getItem("EvertrueProposalTableSettings")) || {};
  const [sortProp, setSortProp] = useState(tableSettings.sortProp || defaultDateSortOption.value);
  const [sortReverse, setSortReverse] = useState(tableSettings.sortReverse === false ? false : true); // if undefined, set to true
  const [page, setPage] = useState(() => {
    if (prospectContactId !== tableSettings.prospectContactId) return 1;
    return parseInt(tableSettings.page, 10) || 1;
  });
  const [filterActive, setFilterActive] = useState(tableSettings.filterActive || "all");
  const [filterStage, setFilterStage] = useState(tableSettings.filterStage || "all");
  const [filterYours, setFilterYours] = useState(() => {
    if (tableSettings.filterYours === "yours" && !userContactId) {
      return "all";
    }
    return tableSettings.filterYours || "all";
  });

  useEffect(() => {
    localStorage.setItem(
      "EvertrueProposalTableSettings",
      JSON.stringify({
        prospectContactId,
        filterActive,
        filterYours,
        filterStage,
        page,
        sortProp,
        sortReverse,
      })
    );
  }, [prospectContactId, filterYours, filterActive, filterStage, page, sortProp, sortReverse]);

  const { loading, proposalsData } = useProposalQueryBuilder(
    LIMIT,
    page,
    sortReverse,
    sortProp,
    prospectContactId,
    userContactId,
    filterYours,
    filterActive,
    filterStage,
    refetch
  );

  return (
    <>
      <div className="proposal-search-controller--filter-bar">
        {!!userContactId && (
          <ProposalTableFilterYours
            filterValue={filterYours}
            handleApplyFilter={(val) => {
              setPage(1);
              setFilterYours(val);
            }}
          />
        )}
        <ProposalTableFilterActive
          filterValue={filterActive}
          handleApplyFilter={(val) => {
            setPage(1);
            setFilterActive(val);
          }}
        />
        <ProposalTableFilterStage
          filterValue={filterStage}
          handleApplyFilter={(val) => {
            setPage(1);
            setFilterStage(val);
          }}
        />
      </div>
      <div className="proposal-search-controller--table-settings">
        <div className="proposal-search-controller--row-count">{proposalsData.total} Proposals</div>
        <div>
          <ProposalTableSort
            sortProp={sortProp}
            sortReverse={sortReverse}
            onSort={(sortProp, sortReverse) => {
              setPage(1);
              setSortProp(sortProp);
              setSortReverse(sortReverse);
            }}
          />
        </div>
      </div>
      <ProposalTable
        proposals={proposalsData}
        loading={loading}
        sortBy={sortProp}
        limit={LIMIT}
        total={proposalsData.total}
        page={page}
        handlePageChange={(page) => setPage(page)}
      />
    </>
  );
};

ProposalSearchController.propTypes = {
  prospectContactId: PropTypes.number,
  refetch: PropTypes.number,
};

export default ProposalSearchController;
