import { PropTypes } from "base/react-libs";
import classNames from "classnames";
import { ProgressBar } from "@evertrue/et-components";

const ProgressBarWithLabel = (props) => {
  const { title, showPercent, weight, inlineLabel, containerStyle, ...otherProps } = props;

  const formatPercent = (percent) => {
    if (!percent) return 0;
    return Math.floor(percent.toFixed(2) * 100);
  };

  return (
    <div className={inlineLabel ? "progress-bar-with-label--flex-container" : ""} style={containerStyle}>
      {title && <div>{title}</div>}
      <div className={classNames("progress-bar-with-label--bar", weight)}>
        <ProgressBar {...otherProps} className={classNames(props.className, weight)} />
        {showPercent && (
          <span className="progress-bar-with-label--percent-label">{`${formatPercent(props.percent)}%`}</span>
        )}
      </div>
    </div>
  );
};

ProgressBarWithLabel.propTypes = {
  title: PropTypes.string,
  showPercent: PropTypes.bool,
  inlineLabel: PropTypes.bool,
  containerStyle: PropTypes.object,
  weight: PropTypes.string,
  percent: PropTypes.number,
  width: PropTypes.any,
  className: PropTypes.string,
  backgroundColor: PropTypes.string,
  progressColor: PropTypes.string,
};

export default ProgressBarWithLabel;
