import EverTrue from "app";
import BaseRouter from "base/base-router";
import DynamicListsController from "apps/dynamic-lists/controllers/dynamic-lists-controller";
import ViewDynamicListController from "apps/dynamic-lists/controllers/view-dynamic-list-controller";

class DynamicListsRouter extends BaseRouter {
  static initClass() {
    this.prototype.routes = {
      "dynamic-lists": "showAll",
      "dynamic-lists/:id": "show",
      "dynamic-lists/map/:id": "map",
    };
  }

  showAll() {
    EverTrue.execute("removePageContainer");
    EverTrue.execute("setBrowserTitle", "Dynamic Lists");
    EverTrue.track.set("viewed", { type: "dynamic_lists" });
    EverTrue.track.setReferrer("dynamic_lists");
    this.currentView = <DynamicListsController feature="filters_v2" />;
    EverTrue.page.mount(this.currentView);
  }

  //TODO make adjustments once mixpanel is added
  show(id) {
    EverTrue.execute("removePageContainer");
    EverTrue.execute("setBrowserTitle", "Dynamic Lists");
    EverTrue.track.set("viewed", { type: "dynamic_lists" });
    EverTrue.track.setReferrer("dynamic_lists");
    this.currentView = <ViewDynamicListController list_id={id} showMap={false} />;
    EverTrue.page.mount(this.currentView);
  }

  map(id) {
    EverTrue.execute("removePageContainer");
    EverTrue.execute("setBrowserTitle", "Dynamic Lists");
    EverTrue.track.setReferrer("dynamic_lists");
    this.currentView = <ViewDynamicListController list_id={id} showMap={true} />;
    EverTrue.page.mount(this.currentView);
  }
}

DynamicListsRouter.initClass();
export default DynamicListsRouter;
