// This function converts the filters into an url for the constituent page.
// This is a custom logic based on how the urls are constructed after applying the filters.
export const convertFilterToUrl = (filter, baseUrl, beta = false, operator = "and", page = 1) => {
  const params = new URLSearchParams();

  // Append beta to the URL parameters first
  params.append("beta", beta);

  // Loop through each filter and append it to the URL parameters
  filter.forEach((filterItem, index) => {
    const filterPrefix = `filters[${index}]`;

    params.append(`${filterPrefix}[filter_row_id]`, filterItem.filter_row_id);
    params.append(`${filterPrefix}[filter_id]`, filterItem.filter_id);

    if (typeof filterItem.value === "object" && !Array.isArray(filterItem.value)) {
      // Handle nested objects (like { gte: "1000000" })
      Object.keys(filterItem.value).forEach((key) => {
        const subValue = filterItem.value[key];
        if (typeof subValue === "object" && !Array.isArray(subValue)) {
          // Handle deeper nested objects
          Object.keys(subValue).forEach((subKey) => {
            params.append(`${filterPrefix}[value][${key}][${subKey}]`, subValue[subKey]);
          });
        } else if (Array.isArray(subValue)) {
          // Handle arrays within the object
          subValue.forEach((item, idx) => {
            Object.keys(item).forEach((itemKey) => {
              params.append(`${filterPrefix}[value][${key}][${idx}][${itemKey}]`, item[itemKey]);
            });
          });
        } else {
          params.append(`${filterPrefix}[value][${key}]`, subValue);
        }
      });
    } else if (Array.isArray(filterItem.value)) {
      // Handle arrays like [{ label: "LYBUNT", value: "lybunt" }]
      filterItem.value.forEach((item, idx) => {
        Object.keys(item).forEach((key) => {
          params.append(`${filterPrefix}[value][${idx}][${key}]`, item[key]);
        });
      });
    } else {
      // Handle primitive values (string, boolean, number)
      params.append(`${filterPrefix}[value]`, filterItem.value);
    }
  });

  // Append operator and page to the URL parameters at the end
  params.append("operator", operator);
  params.append("page", page);

  return `${baseUrl}?${params.toString()}`;
};
