import EverTrue from "app";
import PropTypes from "prop-types";
import { Radio, Icon } from "@evertrue/et-components";
import ReactHtmlParser from "react-html-parser";
import { useContext } from "react";
import { EmailTemplatesContext } from "../hooks/email-templates-context";

const EmailTemplateCard = ({
  template,
  selectedTemplate,
  setSelectedTemplate,
  templates,
  setTemplates,
  setShowEditView,
}) => {
  const { deleteEmailTemplate } = useContext(EmailTemplatesContext);

  const { templateName, templateSubject, templateBody, id } = template;
  const { id: selectedTemplateId = 0 } = selectedTemplate;

  const handleDelete = async () => {
    const confirmed = await new Promise((resolve) => {
      EverTrue.Alert.confirm(
        {
          headline: "Delete Template",
          content: `Are you sure you want to delete the template ${templateName}?`,
          buttonLabel: "Delete",
        },
        (confirmation) => resolve(confirmation)
      );
    });

    if (confirmed) await deleteEmailTemplate(id);
  };

  return (
    <div className="email-template-card" onClick={() => setSelectedTemplate(template)}>
      <Radio name="radio" label={templateName} showLabel={false} checked={selectedTemplateId === id} value={id} />
      <div>
        <div style={{ display: "flex" }}>
          <div className="email--template-card--header">
            <p>
              <strong>{templateName}</strong>
            </p>
            <p>
              <strong>{templateSubject}</strong>
            </p>
          </div>
          <div className="email-template--icon-container">
            <Icon icon="edit" onClick={() => setShowEditView(true)} isReachable={true} onKeyUp={() => {}} />
            <Icon
              icon="trash"
              onClick={(e) => {
                e.stopPropagation();
                handleDelete();
              }}
              isReachable={true}
              onKeyUp={handleDelete}
            />
          </div>
        </div>
        <div style={{ marginBottom: 0 }} className="email-template-card-body">
          {ReactHtmlParser(templateBody)}
        </div>
      </div>
    </div>
  );
};

EmailTemplateCard.propTypes = {
  template: PropTypes.object,
  selectedTemplate: PropTypes.object,
  setSelectedTemplate: PropTypes.func,
  templates: PropTypes.array,
  setTemplates: PropTypes.func,
  setShowEditView: PropTypes.func,
};

export default EmailTemplateCard;
