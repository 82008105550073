module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {div, img, span} = ReactLibs.DOMFactories
   AddressMixin = require("mixins/address-mixin")
   {createFactory} = require("base/new-utils")
   Datalist = createFactory require("components/elements/datalist")
   HelpTip = createFactory require("components/tooltip/helptip").default
   EmptyStateMessage = createFactory(require("components/lists/empty-state-message"))
   Icon = createFactory (require("components/elements/icon").default)


   createComponent "AddressCard",
      propTypes:
         address: ReactLibs.PropTypes.object

      renderValue: (display) ->
         if _.isEmpty(@props.address) || display == "Not Available" || !display
            span className: "address-card--empty", "Not Available"
         else span className: "fs-exclude-data",  display

      render: ->
         is_empty = _.isEmpty(@props.address)
         address = AddressMixin.formatAddressObject(@props.address)

         image_params =
            size: "640x175"
            location: "#{address.line_1 || address.address_1} #{address.city}, #{address.state} #{address.city_state_zip || address.postal_zip}"
            sensor: false
            key: "AIzaSyDGNa0vJIdeMUYAsIpnFAPbZefWJ8RlVhY"


         div className: "address-card",
            div className: "address-card--header",
               Icon className: "address-card--location-icon", icon: "location", size: 2
               div className: "inline",
                  div className: "text-label", address?.type || "Address"
                  if is_empty
                     span className: "address-card--empty", "Not Provided"
                  else
                     span className: "fs-exclude-data",
                        "#{address.line_1} #{address.city_state_zip}"
                        if address?.source == "fullcontact"
                           Icon className: "address-card--enrichment-icon", icon: "et-pyramid", size: 1

            div className: "address-card--google-img",
               if is_empty
                  EmptyStateMessage
                     icon: "home"
                     text: "No imagery available"
               else
                  img
                     className: "fs-exclude-data"
                     src: "https://maps.googleapis.com/maps/api/streetview?#{$.param(image_params)}"
                     alt: "Google Street View"

            div className: "address-card--meta-data",
               Datalist
                  label: span null,
                     "Neighborhood Median Home Value"
                     HelpTip help_key: "est_home_value"
                  @renderValue(address.median_home)

               Datalist label: "Value Range",
                  @renderValue(address.range)

               Datalist className:"address-card--meta-data--median", label: "Median Income",
                  @renderValue(address.median_income)
