import useFacets from "apps/query-builder/hooks/use-facets";
import { useState, useContext, useEffect } from "react";
import Api from "entities/helpers/api";
import ErrorLogger from "entities/helpers/error-log-helper";
import MapFilter from "apps/mapv2/components/map-filter";
import { GlobalContactSearchContext } from "apps/layout/controllers/global-contact-search-context";
import { PropTypes } from "base/react-libs";

const MapAddressFilter = ({ updateMapFilters }) => {
  const [filters, setfilters] = useState({});
  const { options } = useFacets(filters ? filters.compound_id : null);
  const [loading, setLoading] = useState(false);
  const { selectedAddressTypes, mapFilters } = useContext(GlobalContactSearchContext);
  const [removeFilters, setRemoveFilters] = useState(false);

  useEffect(() => {
    !mapFilters ? setRemoveFilters(true) : setRemoveFilters(false);
  }, [mapFilters]);

  const apiCall = () => {
    setLoading(true);
    const loadFilters = async () => {
      try {
        const resp = await Api.HUB.FILTERS.get({ params: { type: "MAPS" } });
        setfilters(resp[0]);
      } catch {
        ErrorLogger.captureRequest("Error fetching maps filters");
      }
      setLoading(false);
    };
    loadFilters();
  };

  const handleApply = (val) => {
    const { operator_query_key } = filters.operators[0];
    if (val.length !== 0) {
      const quick_filters = [
        {
          compound_id: filters.compound_id,
          operator: operator_query_key,
          value: val.reduce((accum, curr) => accum.concat(curr.value), []),
        },
      ];
      updateMapFilters(quick_filters, val);
    } else {
      updateMapFilters([]);
    }
  };

  return (
    <>
      <MapFilter
        onOpen={apiCall}
        onChange={handleApply}
        options={options}
        label="Address Filter"
        icon="filter"
        loading={loading}
        selectedValues={selectedAddressTypes}
        headerText="Filter by Address Type"
        clearFilters={removeFilters}
      />
    </>
  );
};

MapAddressFilter.propTypes = {
  updateMapFilters: PropTypes.func,
};

export default MapAddressFilter;
