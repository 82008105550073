module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   EverTrue = require("app")
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")
   Decorator = require("clientDecorator")

   COLLAPSED_CACHE = "collapsed_list_activity"

   createSource "ListActivitySource",
      actions:
         fetchedActivity: true
         fetchedNoteActivity: true
         fetchedContacts: true
         loadingActivity: true
         savedNote: true
         deletedNote: true
         collapsed: true

      downloadContacts: (contact_ids) ->
         return if _.isEmpty(contact_ids)
         query = must: [{id: in: contact_ids}]

         Api.CONTACTS.SEARCH.post
            data: _.jsonStringify(query)
            success: (resp) =>
               resp.items = _.map resp.items, (item) ->
                  Decorator.Contacts.parse(item)
               @actions.fetchedContacts(resp)

      api:
         fetch: (list_id, paging={}) ->
            @actions.loadingActivity(true)
            requests = []

            requests.push Api.CONTACTS.LIST_ACTIVITY.get
               urlArgs: {id: list_id}
               params: paging.activity
               success: (response) =>
                  @actions.fetchedActivity(list_id, response)

            requests.push Api.SEARCH.LIST_NOTE.post
               data: JSON.stringify({
                  "must": [
                     { "target_id": { "match": list_id  	}  }
                  ]
               })
               params: paging.notes
               success: (response) =>
                  @actions.fetchedNoteActivity(list_id, response)

            $.when.apply(@, requests).then =>
               @actions.loadingActivity(false)
            .fail =>
               @actions.loadingActivity(false)

         fetchContacts: (contact_ids) ->
            @downloadContacts(contact_ids)

         saveComment: (list_id, comment_data) ->
            data = _.omit(comment_data, ["mentions", "label"])
            data = _.extend data,
               target_id: list_id
               target_type: "LIST"
               creator_user_id: EverTrue.store.user.get("id")
               interaction_type: "EverTrue Comment"
               category: "GIVINGTREE_COMMENT"

            Api.UGC.COMMENT.request
               urlExtend: if data.id then "/list/#{list_id}/#{data.id}"
               type: if data.id then "PUT" else "POST"
               data: JSON.stringify(data)
               success: (response) =>
                  users_mentioned = _.compact _.pluck response.user_mention, "user_id"
                  contacts_mentioned =  _.compact _.pluck response.contact_mention, "contact_id"

                  @actions.savedNote(list_id, response)

                  @downloadContacts(contacts_mentioned)

                  EverTrue.track.set "interaction_action",
                     type: "list_comment"
                     action: if data.id then "edit" else "create"
                     "colleagues mentioned": users_mentioned.length
                     "constituents mentioned": contacts_mentioned.length

         deleteComment: (list_id, comment_id) ->
            Api.UGC.NOTE_TARGET_ID.delete
               urlArgs:
                  target_id: list_id
                  target_type: "LIST"
                  note_id: comment_id
               error: "Could not delete comment. Please try again or contact genius@evertrue.com for support."
               success: =>
                  EverTrue.track.set "interaction_action",
                     type: "list_comment"
                     action: "delete"
                  @actions.deletedNote(list_id, comment_id)

         fetchCollapsedState: ->
            EverTrue.store.fetch(COLLAPSED_CACHE)
            @collapse(EverTrue.store[COLLAPSED_CACHE])

         collapse: (is_collapsed) ->
            @actions.collapsed(is_collapsed)
            if is_collapsed
               EverTrue.store.push COLLAPSED_CACHE, true, {cache: true}
            else
               EverTrue.store.destroy(COLLAPSED_CACHE)





