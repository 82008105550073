import Api from "entities/helpers/api";
import ErrorLogger from "entities/helpers/error-log-helper";

export const createJourneyMove = async (
  contact = {},
  moveType,
  reasonObject = {},
  reasonDetails = "",
  journeyId,
  taskId,
  stepId
) => {
  const { label } = reasonObject;
  const { id: contactId } = contact;

  let reason;
  if (reasonDetails && reasonDetails.length)
    reason = reasonDetails; // this means the user supplied an "other" reason and supplied details
  else reason = label; // this is the option they selected from the dropdown menue.

  const data = {
    contact_id: contactId,
    journey_id: journeyId,
    journey_task_id: stepId,
    task_id: taskId,
    move_type: moveType,
    reason,
  };
  return await Api.JOURNEYS.JOURNEY_MOVE.post({
    data: JSON.stringify(data),
    success: (response) => {
      return response;
    },
    error: (e) => {
      ErrorLogger.error("Error Creating Journey Move", { extra: { e, page_name: "create journey move" } });
    },
  });
};
