module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   EverTrue = require("app")
   AuthApi = require("entities/auth/auth-api")
   moment = require("moment")


   SortedCollectionMixin =
      sortProp: undefined
      sortReverse: false
      defaultSortProp: undefined

      baseSort: []
      setBaseSort: undefined

      setDefaultSort: (prop) ->
         @sortProp = prop || @defaultSort
         @defaultSortProp = prop if prop

      setSort: (prop, reverse) ->
         if prop == @sortProp
            @sortReverse = !@sortReverse
         else
            @sortReverse = false
            @sortProp = prop

         if !_.isUndefined(reverse) then @sortReverse = reverse

      setSortFromKey: (prop) ->
         if !prop
            @sortProp = @defaultSortProp
            @sortReverse = false
         else if prop == "no_sort"
            @sortProp = undefined
            @sortReverse = false
         else
            @sortProp = _.first _.keys prop
            @sortReverse = prop?[@sortProp]?.order is "desc"

      doSort: (options={}) ->
         return {} unless @sortProp
         sort_param_query = {}
         sort_param_query[@sortProp] =
            order: if @sortReverse then "desc" else "asc"

         @setBaseSort?() if _.isFunction @setBaseSort
         sort_base = _.clone(@baseSort)
         sort_base.unshift sort_param_query

         sort_query = {sort: sort_base}
         opts = $.extend true, {}, sort_query, options
         opts

      groupResultsByDate: (getTimestamp) ->
         grouped = {}
         @each (item) ->
            timestamp = getTimestamp(item) if _.isFunction(getTimestamp)
            day = moment(timestamp).format("YYYY-MM-DD")
            month = moment(timestamp).format("YYYY-MM-01")

            grouped[month] ?= {}
            grouped[month][day] ?= []
            grouped[month][day].push item.toJSON()

         groupedArray = _.map grouped, (days, month) ->
            monthData = {month: month}
            dayData = _.map days, (content, day) -> {day: day, data: content}
            monthData.data = _.sortBy dayData, "day"
            monthData.data.reverse()
            monthData

         sortedGroupedData = _.sortBy groupedArray, "month"
         sortedGroupedData.reverse()
         sortedGroupedData

      fetchSorted: (options) ->
         @fetch(options)

      getSortValue: ->
         return {} if !@sortProp || @sortProp == "no_sort"
         sort_param_query = {}
         sort_param_query[@sortProp] =
            order: if @sortReverse then "desc" else "asc"
         sort_param_query

      sortData: ->
         _.pick @, "sortProp", "sortReverse"
