import { usePromiseResolver } from "@evertrue/promise-resolver";
import API from "entities/helpers/api";

const useProposalDesignations = () => {
  const [proposalDesignations, loading] = usePromiseResolver(
    () => API.UGC.PROPOSAL_DESIGNATIONS.get({ params: { include_inactive: "false" } }),
    []
  );

  const proposalDesignationOptions =
    proposalDesignations &&
    proposalDesignations.map((designation) => {
      return {
        label: designation.name,
        value: designation.id,
      };
    });

  return [proposalDesignationOptions, loading];
};

export default useProposalDesignations;
