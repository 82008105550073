import PropTypes from "prop-types";
import { Button, Icon } from "@evertrue/et-components";
// WARNING: DO NOT USE THE ICON MAP ELSEWHERE - It will be deprecated and should only be used in this component
import deprecatedIconMap from "apps/query-builder/utils/deprecated-icon-map";

const FilterCategoryList = ({ categories = {}, handleCategorySelect = () => {} }) => {
  return (
    <>
      <div className="filter-picker--title filter-picker--browse">Browse by Category</div>
      <div className="filter-picker--options">
        {Object.keys(categories).map((category) => {
          return (
            <Button
              key={category}
              type="simple"
              className="filter-picker--option"
              onClick={() => handleCategorySelect(category, categories[category])}
            >
              <Icon size={1} icon={deprecatedIconMap[category]} />
              {category === "Custom Fields" ? "Custom" : category}
            </Button>
          );
        })}
      </div>
    </>
  );
};

FilterCategoryList.propTypes = {
  categories: PropTypes.object,
  handleCategorySelect: PropTypes.func,
};

export default FilterCategoryList;
