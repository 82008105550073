import { useState, useCallback } from "react";

export const useDrawer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);

  const toggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  const openDrawer = useCallback(() => setIsOpen(true), []);

  const closeDrawer = useCallback(() => {
    setIsMinimized(false);
    setIsOpen(false);
  }, []);

  const minimizeDrawer = useCallback(() => setIsMinimized(true), []);
  const maximizeDrawer = useCallback(() => setIsMinimized(false), []);

  return { isOpen, openDrawer, closeDrawer, toggleMinimize, minimizeDrawer, maximizeDrawer, isMinimized };
};

export default useDrawer;
