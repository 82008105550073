module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories
   ListStore = require("apps/lists/stores/list-store")
   CollaboratorStore = require("apps/lists/stores/collaborator-store")
   CollaboratorSource = require("apps/lists/sources/collaborator-source")
   ListShareSettings = require("apps/lists/components/list-share-settings")
   {createFactory} = require("base/new-utils")
   Icon = createFactory (require("components/elements/icon").default)
   Avatar = require("components/elements/avatar")
   ButtonTooltip = createFactory( require("@evertrue/et-components").ButtonTooltip)
   Modal2 = createFactory(require("@evertrue/et-components").Modal2)
   ModalConfirm = createFactory(require("@evertrue/et-components").ModalConfirm)
   Button = createFactory(require("@evertrue/et-components").Button)
   Flex = createFactory( require("@evertrue/et-components").Flex )


   createComponent "CollaboratorIconList",
      propTypes:
         list_id: ReactLibs.PropTypes.any

      getInitialState: ->
         modal_open: false

      registerStores: ->
         @on ListStore, ->
            list: ListStore.getList(@props.list_id)

         @on CollaboratorStore, ->
            collaborators: CollaboratorStore.getCollaborators(@props.list_id)

      componentDidMount: ->
         CollaboratorSource.fetchCollaborators(@props.list_id)

      UNSAFE_componentWillReceiveProps: (newProps) ->
         if newProps.list_id != @props.list_id
            CollaboratorSource.fetchCollaborators(newProps.list_id)

      closeShareModal: ->
         @setState {show_share_modal: false}

      render: ->
         sort_collabs = _.sortBy(@state.collaborators, "owner")
         has_collabs = @state.collaborators.length > 1 # account for owner

         div className: "collaborator-icon-list",
            if has_collabs
               _.compact _.map sort_collabs, (collab, index) =>
                  return unless _.isObject(collab.user)
                  ButtonTooltip
                     key: collab.user.id + "-" + index,
                     children: collab.user.name + (if collab.owner then " is owner" else " can edit")
                     triggerClassName: classNames("collaborator-icon-list--tooltip", "is-owner": collab.owner)
                     onTriggerClick: (=>
                        @setState({show_share_modal: true})
                     )
                     className: "collaborator-icon-list--action",
                     trigger:
                        Avatar
                           name: collab.user.name || "unnamed collaborator"
                           url: collab.user.avatar
                           className: "collaborator-icon-list--icon"

            Button
               type: "simple"
               onClick: ( => @setState {show_share_modal: true})
               className: "collaborator-icon-list--action",
               title: if has_collabs then "Manage Collaborators" else "Add Collaborators",
               if has_collabs
                  Flex null,
                     Icon icon: "settings"
                     "Manage Collaborators"
               else
                  Flex null,
                     Icon icon: "group-add"
                     "Add Collaborators"

            Modal2 isOpen: @state.show_share_modal, onClose: @closeShareModal,
               ModalConfirm({ header: "Sharing Settings", className: "share-settings--modal", closeModal: @closeShareModal },
                  ListShareSettings list_id: @props.list_id, owner_id: @state.list?.user_id)
