import PropTypes from "prop-types";
import _ from "underscore";
import StageResolver from "apps/assignments/components/stage-resolver";
import { AdvancedCombobox, ConditionalWrap } from "@evertrue/et-components";
import FormField from "components/forms/form-field";

const StageGroupSelector = ({ onChange, selectedStageGroup, showLabel, ...rest }) => {
  return (
    <StageResolver>
      {({ stageGroups, loading, hasStagesFeature }) => {
        if (!hasStagesFeature) return null;
        const groups_arr = Object.values(stageGroups);
        if (groups_arr.length) {
          const options = groups_arr
            .sort((a, b) => b.default)
            .map(stage => ({ ...stage, label: stage.name, value: stage.id }))
            .concat({ label: "No Volunteer Stages", value: null });

          return (
            <ConditionalWrap
              condition={showLabel}
              wrap={children => (
                <FormField className="pool-form-modal--stages" label="Volunteer Stages" inlineLabel={false}>
                  {children}
                </FormField>
              )}
            >
              <AdvancedCombobox
                {...rest}
                loading={loading}
                options={options}
                value={_.findWhere(options, { value: selectedStageGroup.id || null })}
                onChange={opt => onChange(_.omit(opt, ["label", "value"]))}
              />
            </ConditionalWrap>
          );
        }
        return null;
      }}
    </StageResolver>
  );
};

StageGroupSelector.propTypes = {
  onChange: PropTypes.func,
  selectedStageGroup: PropTypes.object,
  showLabel: PropTypes.bool,
};

StageGroupSelector.defaultProps = {
  selectedStageGroup: {},
};

export default StageGroupSelector;
