import PropTypes from "prop-types";
import _ from "underscore";
import { connect } from "@evertrue/et-flux";
import { AdvancedCombobox } from "@evertrue/et-components";
import PoolsStore from "apps/volunteers/stores/pools-store";

const mapStateToProps = () => ({
  loadingCategories: PoolsStore.getLoadingCategories(),
  givingCategories: PoolsStore.getGivingCategories(),
});

const prop_types = {
  givingCategories: PropTypes.array,
  loadingCategories: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

const default_props = {
  givingCategories: [],
  value: { value: null },
};

const GivingCategorySelector = ({ givingCategories, onChange, loadingCategories, value, disabled }) => {
  return (
    <AdvancedCombobox
      disabled={disabled}
      options={givingCategories}
      value={_.findWhere(givingCategories, { value })}
      onChange={onChange}
      loading={loadingCategories}
    />
  );
};

GivingCategorySelector.propTypes = prop_types;
GivingCategorySelector.defaultProps = default_props;

export default connect(
  GivingCategorySelector,
  [PoolsStore],
  mapStateToProps
);
