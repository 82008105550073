module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   Api = require("entities/helpers/api")
   Backbone = require("backbone")
   ErrorLogger = require("entities/helpers/error-log-helper")

   Backbone.ajax = (options) ->
      if options.url.match("http")
         $.ajax(options)
      else
         Request = Api.findEndpoint(options.url)
         if Request
            Request.request(_.omit(options, "url"))
         else ErrorLogger.warn("Request Refactor Error", {url: options.url})

   syncOverride = (method, model, options) ->
      model.syncing = true
      data = options.attrs || model.getServerAttributes?() || model.toJSON()

      sync_options = _.cloneData(options)
      unless options.data || _.isEmpty(data)
         sync_options.data = _.jsonStringify(data)

      sync_options.complete = ->
         model.syncing = false
         model.trigger("sync:done")
         options.complete?()

      Backbone.sync(method, model, sync_options)

   class ApiModel extends Backbone.Model
      url: ->
         if _.isFunction @urlRoot then @urlRoot()
         else @urlRoot
      sync: syncOverride
      helperMethods: {}
      toDataObject: ->
         data = @toJSON()
         _.extend data, @helperMethods

   class ApiCollection extends Backbone.Collection
      url: ->
         if _.isFunction @urlRoot then @urlRoot()
         else @urlRoot
      sync: syncOverride
      toDataObject: ->
         @map (model) -> model.toDataObject()
      toCollectionWrapper: ->
         wrapper = {items: @toJSON()}
         _.extend wrapper, @pageData?(), @sortData?(), {syncing: @syncing}

   {
      Model: ApiModel
      Collection: ApiCollection
   }
