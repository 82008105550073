module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   classNames = require("classnames")
   EverTrue = require("app")
   {div} = ReactLibs.DOMFactories
   {createComponent} = require("@evertrue/et-flux")
   MyTripsUtils = require("apps/my-trips/utils/my-trips-utils")
   {createFactory} = require("base/new-utils")
   MeetingCard = createFactory require("apps/my-trips/components/meeting-card").default
   MapUtils = require("apps/map/map-utils")
   SearchLink = createFactory(require("components/elements/search-link"))
   Icon = createFactory (require("components/elements/icon").default)
   Link = createFactory(require("@evertrue/et-components").Link)

   createComponent "MeetingMapPin",
      propTypes:
         trip: ReactLibs.PropTypes.object
         meeting: ReactLibs.PropTypes.object

      handleTrack: (type) ->
         EverTrue.track.set "trip_action",
            type: type
            trip_id: @props.trip.id

      render: ->
         if @props.meeting?.location
            location = _.pick(@props.meeting.location, "lat", "lng")
            filters = {map: MapUtils.getBoxFromLatLngRadius(location, 1)}
            directions = MyTripsUtils.getDirectionsLink(@props.meeting)


         div className: classNames("trip-map--info-window", @props.className),
            MeetingCard
               trip: @props.trip
               meeting: @props.meeting
               showContacts: false

            if filters
               SearchLink filters: filters, onClick: (=> @handleTrack("trip_search_nearby")),
                  Icon icon: "search", before: true
                  "Search Nearby"

            if directions
               Link
                  href: directions
                  title: "meeting directions"
                  className: "trip-map--info-directions"
                  target: "_blank"
                  onClick: => @handleTrack("trip_directions")

                  Icon icon: "directions", before: true
                  "Get Directions"
