module.exports = do ->
   _ = require("underscore").default
   moment = require("moment")
   twemoji = require("twemoji")
   React = require("react")
   EverTrue = require("app")
   str = require("underscore.string")
   FacebookPostStore = require("apps/facebook/stores/facebook-post-store")
   {createFactory} = require("base/new-utils")
   FacebookPostStats = createFactory require("apps/facebook/components/facebook-post-stats")
   {strong, li, div, i} = ReactLibs.DOMFactories

   FacebookPost = ReactLibs.createClass
      propTypes:
         post: ReactLibs.PropTypes.object

      getInitialState: ->
         like: 0, comment: []

      UNSAFE_componentWillReceiveProps: (newProps) ->
         if @props.post != newProps.post
            @getEngagementData(newProps.post)

      componentDidMount: ->
         @getEngagementData(@props.post)
         # Need to retrigger because doesn't initially have features loaded
         EverTrue.vent.on "org:initialized", => @getEngagementData(@props.post)
         @depracated_is_mounted = true

      componentWillUnmount: ->
         @depracated_is_mounted = false

      getEngagementData: (post) ->
         # if on contact post view
         if post.contact_id
            FacebookPostStore.savePostEngagementData post, (result) =>
               @setState({like: result.like, comment: result.comment}) if @depracated_is_mounted

      showComments: ->
         _.compact _.map @state.comment, (comment, index) ->
            if comment
               updated_comment = twemoji.parse(str.escapeHTML(comment), {size: "16x16"})
               div key: index, className: "social-comment", dangerouslySetInnerHTML: __html: updated_comment

      render: ->
         post = @props.post
         has_comment = @state.comment?.length > 0

         li className: "timeline-item",

            div className: "timeline-label",
               strong null, moment(post.created_at).format("MMM D, YYYY")
               div null, moment(post.created_at).format("h:mm A")

               if @state.like
                  div null,
                     "Liked"
                     i className: "fa fa-fw fa-thumbs-o-up"

               if has_comment
                  div null,
                     "Commented"
                     i className: "fa fa-fw fa-comment-o"

            if has_comment && @props.post.contact_id
               @showComments()

            div className: "timeline-item--content",
               FacebookPostStats url: post.public_url, post_id: post.remote_id, post_width: 450, silent: true
