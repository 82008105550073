import PropTypes from "prop-types";
import { ModalHeaderWithActions } from "@evertrue/et-components";
import EmailTemplatesLibrary from "./email-templates-library";
import EmailTemplatePreview from "./email-template-preview";
import { compileTemplate } from "../../../utils/utils";
import EmptyStateMessage from "components/lists/empty-state-message-tv";
import { EmailTemplatesContext } from "../hooks/email-templates-context";
import { useContext } from "react";
import { useGate } from "components/is-gated";

const EmailTemplatesModal = ({
  closeModal,
  setShowEditView,
  showEditView,
  selectedTemplate,
  setSelectedTemplate,
  emailBodyRef,
  setSubject,
  contact,
}) => {
  const { dynamicTemplate, templateBody = "", templateSubject = "" } = selectedTemplate || {};
  const { total } = useContext(EmailTemplatesContext);
  const [hasDynamicTemplates, hasDynamicTemplatesLoaded] = useGate("unreleased_feature");

  const handleInsertTemplate = async () => {
    const editor = emailBodyRef.current.getEditor();
    let idx = 0;
    if (editor.selection.lastRange) {
      // If there is a cursor position, insert the template at the cursor
      idx = editor.selection.lastRange.index;
    }

    const templateToInsert =
      dynamicTemplate && hasDynamicTemplatesLoaded && hasDynamicTemplates
        ? await compileTemplate(JSON.stringify(templateBody), contact.id)
        : templateBody;

    editor.clipboard.dangerouslyPasteHTML(idx, templateToInsert);
    setSubject(templateSubject);
    closeModal();
  };

  return (
    <>
      <ModalHeaderWithActions
        submitLabel={"Insert Template"}
        title="Email Template Library"
        closeModal={closeModal}
        onSubmit={handleInsertTemplate}
        disableSubmit={!templateBody}
      ></ModalHeaderWithActions>
      {total > 0 ? (
        <div style={{ display: "flex" }}>
          <EmailTemplatesLibrary
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
            setShowEditView={setShowEditView}
            showEditView={showEditView}
          />
          <EmailTemplatePreview template={selectedTemplate} />
        </div>
      ) : (
        <EmptyStateMessage
          style={{ padding: "60px" }}
          text="You don't have any Email Templates"
          children="Create and Save Templates to simplify your Email process."
          icon="email"
          actionLabel={"Create Template"}
          onAction={() => {
            setSelectedTemplate({});
            setShowEditView(true);
          }}
        />
      )}
    </>
  );
};

EmailTemplatesModal.propTypes = {
  closeModal: PropTypes.func,
  setShowEditView: PropTypes.func,
  selectedTemplate: PropTypes.object,
  setSelectedTemplate: PropTypes.func,
  showEditView: PropTypes.bool,
  emailBodyRef: PropTypes.object,
  setSubject: PropTypes.func,
  contact: PropTypes.object,
};

export default EmailTemplatesModal;
