module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   EverTrue = require("app")
   {createComponent} = require("@evertrue/et-flux")
   {div, span, img, table, thead, tbody, tr, td, th} = ReactLibs.DOMFactories
   FeatureStore = require("apps/layout/stores/feature-store").default
   Api = require("entities/helpers/api")
   {createFactory} = require("base/new-utils")
   Module = createFactory require("components/module").default
   Icon = createFactory (require("components/elements/icon").default)
   Loading = require("components/elements/loading")
   Modal = createFactory require("components/modals/modal")
   ModalConfirm = createFactory require("components/modals/modal-confirm")
   ModalTrigger = createFactory require("components/modals/modal-trigger")
   LinkButton = createFactory(require("@evertrue/et-components").LinkButton)
   Button = createFactory(require("@evertrue/et-components").Button)

   TYPES =
      pending:
         icon: "access-time"
         settings_class_name: "eventbrite-settings--account-pending"
         connection_message: "Pending "
         can_disconnect: true
         annotation: "May take up to 24 hours for events to sync"
      connected:
         icon: "check-circle"
         settings_class_name: "eventbrite-settings--account-check"
         connection_message: "Connected "
         can_disconnect: true
      deleted:
         icon: "access-time"
         settings_class_name: "eventbrite-settings--account-pending"
         connection_message: "Pending Disconnection"
         can_disconnect: false
         annotation: "May take up to an hour for your account to be disconnected and events removed"

   createComponent "EventbriteSettingsController",
      getInitialState: ->
         users: []
         loading_users: false
         loading_connection: false

      registerStores: ->
         @on FeatureStore, ->
            has_eventbrite: FeatureStore.hasFeature("events_eventbrite")

      UNSAFE_componentWillMount: ->
         @fetchEventbriteUsers()

      fetchEventbriteUsers: ->
         @setState loading_users: true
         Api.EVENTS.EVENTBRITE_USER.get
            success: (resp) =>
               @setState
                  users: resp
                  loading_users: false
            error: =>
               @setState loading_users: false
               EverTrue.Alert.error "Problem fetching Connected Eventbrite accounts"

      createUser: ->
         @setState loading_connection: true
         userId = EverTrue.store.user.get("id")

         if userId
            Api.EVENTS.EVENTBRITE_USER.post
               data: _.jsonStringify({userId})
               success: (resp) =>
                  @handleRedirect(resp.state)
               error: (err) =>
                  @setState loading_connection: false
                  EverTrue.Alert.error "Problem connecting Eventbrite account"

      deleteUser: (eventbrite_id) ->
         Api.EVENTS.EVENTBRITE_USER.delete
            urlExtend: "/#{eventbrite_id}"
            success: =>
               @fetchEventbriteUsers()
               EverTrue.Alert.success "Successfully disconnected Eventbrite account"
            error: (err) ->
               EverTrue.Alert.error "Problem disconnecting Eventbrite account"

      handleRedirect: (state) ->
         window.location =
            "https://www.eventbrite.com/oauth/authorize?response_type=code&client_id=RX5YA6FGU4VD6Y3HBS&state=" + state

      handleBack: ->
         EverTrue.Navigator("/events", true)

      renderDisconnectModal: (user_id) ->
         ModalConfirm
            className: "eventbrite-settings--modal"
            header: "Disconnect Eventbrite"
            buttonLabel: "OK"
            hasMargin: true
            onSubmit: => @deleteUser(user_id)
            "Are you sure you want to disconnect your account?"

      renderUser: (email, id, config_obj) ->
         tr key: id,
            td null, email
            td null,
               Icon icon: config_obj.icon, className: config_obj.settings_class_name
               span null, config_obj.connection_message

               if config_obj.can_disconnect
                  span null,
                     "("
                     ModalTrigger
                        title: "Disconnect Eventbrite"
                        className: "eventbrite-settings--disconnect"
                        modal: Modal width: 432, @renderDisconnectModal(id)
                        "Disconnect"
                     ")"

               if config_obj.annotation
                  div className: "eventbrite-settings--annotation-text", config_obj.annotation

      render: ->
         # email comes from eventbrite account using user token
         connected_users = _.filter @state.users, (user) -> user.email

         div className: "eventbrite-settings",
            div className: "eventbrite-settings--header clearfix",
               LinkButton
                  className: "eventbrite-settings--back"
                  onClick: @handleBack
                  title: "Go back"
                  Icon icon: "chevron-left", size: 2

               div className: "eventbrite-settings--header-text",
                  "Manage Eventbrite Integration"

            div className: "eventbrite-settings--body",
               if @state.loading_users or @state.loading_connection
                  Loading()

               else if _.size(connected_users) > 0
                  Module
                     className: "eventbrite-settings--account"
                     icon: "event"
                     header: "Eventbrite Account"

                     div className: "eventbrite-settings--account-text",
                        "The following Eventbrite account is connected to EverTrue."
                     table className: "eventbrite-settings--account-table",
                        thead className: "eventbrite-settings--account-table-head",
                           tr null,
                              th null, "Eventbrite Account ID"
                              th null, "Status"
                        tbody className: "eventbrite-settings--account-table-body",
                           _.map connected_users, (user) =>
                              type =
                                 # users may be pending up to an 24 hours after connecting
                                 # email comes from eventbrite account
                                 if user.deleted then "deleted"
                                 else if user.completed then "connected"
                                 else "pending"

                              config_obj = TYPES[type]
                              @renderUser(user.email, user.id, config_obj)

               else
                  Module
                     className: "eventbrite-settings--connect"

                     div className: "eventbrite-settings--connect-content",
                        img src: "/images/eventbrite_logo_lrg.png"
                        div className: "eventbrite-settings--title", "Eventbrite"
                        div className: "eventbrite-settings--connect-text",
                           "Using Eventbrite? Connect your Eventbrite account to EverTrue
                           so you can track event engagement and attendance.
                           Once connected, Eventbrite data will automatically sync to EverTrue.
                           Please note, Eventbrite data may take up to 24 hours to sync."
                        Button
                           className: "eventbrite-settings--connect-btn"
                           onClick: @createUser
                           "Connect Account"
