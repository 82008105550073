import { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

class Icon extends Component {
  static propTypes = {
    icon: PropTypes.string.isRequired,
    iconKit: PropTypes.string,
    size: PropTypes.number,
    rotate: PropTypes.number,
    fixed: PropTypes.bool,
    spin: PropTypes.bool,
    before: PropTypes.bool,
    after: PropTypes.bool,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    title: PropTypes.string,
    isReachable: PropTypes.bool,
  };

  static defaultProps = {
    size: 0,
    fixed: true,
    iconKit: "gt",
    isReachable: true,
  };

  render() {
    let rotation, size;
    const style = {
      marginRight: this.props.before ? 8 : undefined,
      marginLeft: this.props.after ? 8 : undefined,
    };

    if (this.props.rotate) {
      rotation = `${this.props.iconKit}-icon-rotate-${this.props.rotate}`;
    }
    if (this.props.size > 1) {
      size = `${this.props.iconKit}-icon-${this.props.size}x`;
    } else if (this.props.size === 1) {
      size = `${this.props.iconKit}-icon-lg`;
    }

    return (
      <i
        style={style}
        onClick={!this.props.disabled ? this.props.onClick : undefined}
        onKeyUp={({ key }) => {
          if (key === "Enter") {
            return !this.props.disabled ? this.props.onClick() : undefined;
          }
        }}
        className={classNames(
          `${this.props.iconKit}-icon ${this.props.iconKit}-icon-${this.props.icon}`,
          this.props.className,
          rotation,
          size,
          {
            "is-disabled": this.props.disabled,
            "gt-icon-fw": this.props.fixed,
            "gt-icon-spin": this.props.spin,
          }
        )}
        title={this.props.title}
        tabIndex={this.props.isReachable ? 0 : -1}
      />
    );
  }
}

export default Icon;
