import _ from "underscore";
import FeatureStore from "apps/layout/stores/feature-store";
import ListSelectorController from "apps/lists/controllers/lists-selector-controller";
import TripsSelectorController from "apps/my-trips/controllers/trips-selector-controller";
import PoolSelectorController from "apps/volunteers/controllers/pool-selector-controller";
import ButtonSelect from "components/controls/button-select";
import IsGated from "components/is-gated";
import { useState } from "react";
import Proptypes from "prop-types";
import JourneySelectorController from "apps/journeys/components/modals/journey-selector-controller";
import useFetchJourneys from "apps/journeys/hooks/use-fetch-journeys";

const ContactAddTo = ({
  contactIds,
  membership,
  allowAddAll,
  allSelected,
  hideTrips,
  totalResultsCount,
  hideVMS,
  closePopover,
  isFiltersV2,
  filterParams,
  onlyUseQuery,
  setModal,
}) => {
  const hasTrips = FeatureStore.hasFeature("my_trips");
  const hasCadences = FeatureStore.hasFeature("cadences");
  const [view, setView] = useState("lists");
  const showTrips = hasTrips && !hideTrips;

  const { journeys, loadingJourneys } = useFetchJourneys(hasCadences);

  return (
    <div className="contact-add-to">
      <IsGated
        feature="volunteers"
        render={({ show: hasVMS }) => {
          const showVMS = hasVMS && !hideVMS;
          if (showTrips || hasVMS || hasCadences) {
            return (
              <ButtonSelect
                className="contact-add-to--types"
                selected={view}
                buttons={_.compact([
                  { value: "lists", label: "Your Lists", gt_icon: "clipboard-text" },
                  showTrips && { value: "trips", label: "Your Trips", gt_icon: "my-trips" },
                  showVMS && { value: "pools", label: "Your Pools", gt_icon: "pools" },
                  hasCadences && { value: "cadences", label: "Your Cadences", gt_icon: "cadences", iconKit: "gt2" },
                ])}
                onSelect={(value) => setView(value)}
              />
            );
          } else {
            return null;
          }
        }}
      ></IsGated>

      {view === "trips" ? (
        <TripsSelectorController
          totalResultsCount={totalResultsCount}
          filterParams={filterParams}
          contactIds={contactIds}
          membership={membership}
          allSelected={allSelected}
          closePopover={closePopover}
          isFiltersV2={isFiltersV2}
        />
      ) : view === "pools" ? (
        <PoolSelectorController
          totalResultsCount={totalResultsCount}
          filterParams={filterParams}
          contactIds={contactIds}
          allowAddAll={allowAddAll}
          allSelected={allSelected}
          onlyUseQuery={onlyUseQuery}
          closePopover={closePopover}
          setModal={setModal}
          isFiltersV2={isFiltersV2}
        />
      ) : view === "cadences" ? (
        <JourneySelectorController
          contactIds={contactIds}
          closePopover={closePopover}
          journeys={journeys}
          loading={loadingJourneys}
          allSelected={allSelected}
          totalResultsCount={totalResultsCount}
        />
      ) : (
        <ListSelectorController
          totalResultsCount={totalResultsCount}
          filterParams={filterParams}
          contactIds={contactIds}
          membership={membership}
          allowAddAll={allowAddAll}
          onlyUseQuery={onlyUseQuery}
          closePopover={closePopover}
          isFiltersV2={isFiltersV2}
        />
      )}
    </div>
  );
};

ContactAddTo.propTypes = {
  contactIds: Proptypes.array.isRequired,
  membership: Proptypes.object,
  allowAddAll: Proptypes.bool,
  allSelected: Proptypes.bool,
  hideTrips: Proptypes.bool,
  totalResultsCount: Proptypes.number,
  hideVMS: Proptypes.bool,
  closePopover: Proptypes.func.isRequired,
  isFiltersV2: Proptypes.bool,
  filterParams: Proptypes.object,
  onlyUseQuery: Proptypes.bool,
  setModal: Proptypes.func,
};

export default ContactAddTo;
