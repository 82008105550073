module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   numeral = require("numeral")
   {createComponent} = require("@evertrue/et-flux")
   {div, span} = ReactLibs.DOMFactories
   MyTeamStore = require("apps/my-team/stores/my-team-store")
   MyTeamNavItem = require("apps/my-team/components/my-team-nav-item")
   MyTeamChange = require("apps/my-team/components/my-team-change")


   createComponent "MyTeamNav",
      propTypes:
         report: ReactLibs.PropTypes.string
         visitsAreConfigured: ReactLibs.PropTypes.bool

      registerStores: ->
         @on MyTeamStore, "report", (props) ->
            reports: MyTeamStore.getReports()
            rollup: MyTeamStore.getReportsRollup()

      getSums: (report) ->
         sum = _.sum @state.rollup, (item) -> item[report.key + "_total"]
         past_sum = _.sum @state.rollup, (item) -> item[report.key + "_past_total"]
         {sum, past_sum}

      render: ->
         div className: "my-team-nav",
            MyTeamNavItem
               title: "Solicitor Summary"
               value: _.size(_.keys(@state.rollup))
               selected: !@props.report

            _.map @state.reports, (report) =>
               {sum, past_sum} = @getSums(report)

               if report.key == "proposal-amount"
                  sum = numeral(sum).format("$0,0")
                  past_sum = numeral(past_sum).format("$0,0")
               else
                  sum = numeral(sum).format("0,0")
                  past_sum = numeral(past_sum).format("0,0")

               formatted_value = span null,
                  sum
                  unless report.key in ["prospects", "my-trips"]
                     MyTeamChange current: sum, past: past_sum

               if report.key == "visits" && !@props.visitsAreConfigured
                  formatted_value = span className: "text-placeholder-light",
                     "Not Configured"

               MyTeamNavItem
                  key: report.key
                  report: report.key
                  title: report.title
                  value: formatted_value
                  helpTip: report.help_tip
                  selected: @props.report == report.key
