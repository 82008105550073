import PropTypes from "prop-types";
import { Formik, Field, ErrorMessage } from "formik";
import _ from "underscore";
import * as Yup from "yup";
import FormField from "components/forms/form-field";
import { Button, ButtonTooltip, Icon, AdvancedCombobox } from "@evertrue/et-components";
import { useIdentity } from "base/identity-resolver";
import { usePromiseResolver } from "@evertrue/promise-resolver";
import API from "entities/helpers/api";

const USER_VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string().required("First Name is required."),
  email: Yup.string().email("Valid email format is required.").required("Email Address is required."),
  classification: Yup.object({
    label: Yup.string().required("Classification is required."),
    value: Yup.string().required("Classification is required."),
  }).required("Classification is required."),
  jobTitle: Yup.string().required("Title is required."),
});

const ProfileSettings = ({ userInfo, onSubmit, emailDisclaimer }) => {
  const { org } = useIdentity();
  const departments = usePromiseResolver(() => API.AUTH.DEPARTMENTS.get({ urlArgs: { oid: org.id } }), [org.id])[0];
  const classifications = usePromiseResolver(() => API.AUTH.PERSONAS.get({ urlArgs: { oid: org.id } }), [])[0];

  const department_options =
    departments &&
    departments.map((dep) => {
      return { label: dep.value, value: dep.id };
    });

  const department_label = (dept_id) => {
    const option = Array.isArray(department_options) ? department_options.find((dep) => dep.value === dept_id) : {};
    return option ? option.label : undefined;
  };

  const classification_options =
    classifications &&
    classifications.map((cl) => {
      return { label: cl.value, value: cl.value };
    });

  const handleSubmit = (values) => {
    onSubmit({
      name: values.name,
      email: values.email,
      title: values.jobTitle,
      school_division_department_id: values.department && values.department.value,
      persona: values.classification.value,
    });
  };

  const InitialValues = {
    name: userInfo.name || "",
    email: userInfo.email || "",
    confirmedEmail: userInfo.current_email || "",
    jobTitle: userInfo.title || "",
    classification: { label: userInfo.persona, value: userInfo.persona } || "",
    department:
      {
        label: department_label(userInfo.school_division_department_id),
        value: userInfo.school_division_department_id,
      } || null,
  };

  return (
    <Formik
      enableReinitialize
      initialValues={InitialValues}
      validationSchema={USER_VALIDATION_SCHEMA}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {(props) => {
        // eslint-disable-next-line react/prop-types
        const { dirty, errors, touched, values, handleChange, handleReset, handleSubmit } = props;

        return (
          <form onSubmit={handleSubmit}>
            <FormField className="profile-settings-input" label="Name (First and Last)" inlineLabel={false}>
              <Field
                name="name"
                type="text"
                className={errors.name && touched.name ? "error" : ""}
                placeholder="First Last"
              />
              <ErrorMessage name="name" component="div" className="error-feedback" />
            </FormField>
            {emailDisclaimer && (
              <FormField className="profile-settings-input" label="Current Confirmed Email Address" inlineLabel={false}>
                <Field name="confirmedEmail" type="text" disabled={true} />
              </FormField>
            )}
            <FormField className="profile-settings-input" label="Email Address" inlineLabel={false}>
              <Field
                name="email"
                type="text"
                className={errors.email && touched.email ? "error" : ""}
                placeholder="your@emailaddress.com"
              />
              <ErrorMessage name="email" component="div" className="error-feedback" style={{ marginBottom: 0 }} />
              {emailDisclaimer && (
                <div className="email-disclaimer">
                  <Icon icon="warning" className="profile-settings-icon" size={1} />{" "}
                  <strong>This email is unconfirmed.</strong> Please check your current email to confirm this change
                </div>
              )}
            </FormField>
            <FormField className="profile-settings-input" label="Title" inlineLabel={false}>
              <Field
                name="jobTitle"
                type="text"
                className={errors.jobTitle && touched.jobTitle ? "error" : ""}
                placeholder="Job Title"
              />
              <ErrorMessage name="jobTitle" component="div" className="error-feedback" />
            </FormField>

            <div className="profile-settings-input">
              <span className="classification">Classification</span>
              <ButtonTooltip contentWidth={360} trigger={<Icon className="icon" icon="help" />} buttonType="button">
                Please select the option that most specifically describes your day-to-day job
              </ButtonTooltip>
              <AdvancedCombobox
                id="classification"
                title="Classification"
                tabIndex="0"
                className={
                  errors.classification && touched.classification
                    ? "advanced-combobox-secondary error"
                    : "advanced-combobox-secondary"
                }
                options={classification_options}
                label={values.classification.label}
                value={values.classification}
                onChange={handleChange("classification")}
              />
              {errors.classification && touched.classification && (
                <p className="error-feedback">{errors.classification}</p>
              )}
            </div>
            <FormField className="profile-settings-input" label="Department (optional)" inlineLabel={false}>
              <AdvancedCombobox
                title="Department"
                tabIndex="0"
                className="advanced-combobox-secondary"
                options={department_options}
                label={values.department.label}
                value={values.department}
                onChange={handleChange("department")}
              />
            </FormField>
            <div className="profile-settings-buttons">
              <Button type="simple" className="cancel" disabled={!dirty} buttonType="reset" onClick={handleReset}>
                Cancel
              </Button>
              <Button disabled={!dirty || !_.isEmpty(errors)} buttonType="submit">
                Save
              </Button>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

ProfileSettings.propTypes = {
  userInfo: PropTypes.object,
  onSubmit: PropTypes.func,
  emailDisclaimer: PropTypes.bool,
  errors: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    classification: PropTypes.string,
    jobTitle: PropTypes.string,
  }),
  touched: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    classification: PropTypes.string,
    jobTitle: PropTypes.string,
  }),
  values: PropTypes.shape({
    department: PropTypes.string,
    classification: PropTypes.string,
  }),
};

export default ProfileSettings;
