import { PropTypes } from "base/react-libs";
import { useIdentity } from "base/identity-resolver";
import { useState, useEffect } from "react";
import { trackJourneysAction } from "apps/journeys/utils/helpers/mixpanel";
import EverTrue from "app";
import { moveConstituentToJourney } from "apps/journeys/utils/api-requests/modal-actions";
import { fetchAllJourneysForAllJourneyGroups } from "apps/journeys/utils/api-requests/journey-index";
import { Modal, ModalHeaderWithActions, ModalBody, ListboxSelect, Radio } from "@evertrue/et-components";
import FormField from "components/forms/form-field";
import JourneyContactCard from "apps/journeys/components/journey-contact-card";
import { setTaskCompletion } from "apps/tasks/utils/tasks-actions";

const EndJourneyModal = ({
  isOpen,
  onClose,
  task = {},
  step = {},
  completeTaskStateUpdates,
  currentJourney,
  optionalStateManagementFunction = () => {},
}) => {
  const { contact } = task;
  const { user, org } = useIdentity();
  const [journeyOptions, setJourneyOptions] = useState([]);
  const [selected, setSelected] = useState(null);
  const [selectedOption, setSelectedOption] = useState();

  useEffect(() => {
    const getAndSetJourneyOptions = async () => {
      const journeys = await fetchAllJourneysForAllJourneyGroups();
      setJourneyOptions(
        journeys
          .filter((j) => j.name !== currentJourney.name)
          .map((j) => {
            return { label: j.name, value: j.id, id: j.id };
          })
      );
    };

    if (isOpen) getAndSetJourneyOptions();
  }, [currentJourney, isOpen]);

  // handle logic to remove constituent from cadence
  // and update state on table when modal exits
  const removeFromCadence = async () => {
    try {
      await moveConstituentToJourney(currentJourney.id, contact.id, user.id, org.id);
      await setTaskCompletion(task, true);
      trackJourneysAction("remove_cadence");
      optionalStateManagementFunction("removeContact", { contactIdToRemove: contact.id });
    } catch (error) {
      EverTrue.alert.error("Error removing constituent from cadence.");
    }
  };

  const handleSubmit = async () => {
    if (selected && selected.value) {
      await completeTaskStateUpdates(task, step);
      await moveConstituentToJourney(currentJourney.id, contact.id, user.id, org.id, selected.id, selected.label);
    } else if (selectedOption === "remove") {
      removeFromCadence();
    }
    onClose();
  };

  return (
    <Modal isOpen={isOpen} closeModal={onClose}>
      <ModalHeaderWithActions
        title={"End of Cadence"}
        closeModal={onClose}
        onSubmit={handleSubmit}
        submitLabel="Done"
        disableSubmit={!selected && selectedOption !== "remove"}
      />
      <ModalBody scrollable={false}>
        <p className="journey-modal--warning">
          To complete this last step of the cadence, please select the next cadence to move this constituent into or
          remove them from the cadence.
        </p>

        <JourneyContactCard contact={contact} avatarSize={60} />
        <div className="end-journey-form-field">
          <FormField label="Current Cadence" boldLabel={true} inlineLabel={false}>
            {currentJourney.name}
          </FormField>
          <FormField label="Current Task" boldLabel={true} inlineLabel={false} className="end-journey-current-task">
            <div>{`Step ${step.step_number}| Day ${step.day_number}`}</div>
            <div>{step.title}</div>
          </FormField>
        </div>
        <FormField label="Select to end the cadence" boldLabel={true} inlineLabel={false}>
          <div className="end-journey-selectnext--container">
            <Radio
              label="Move to another cadence"
              checked={selectedOption === "selectCadence"}
              onChange={() => setSelectedOption("selectCadence")}
              name="selectCadence"
              className="end-journey--radio"
            >
              <div className="event-type-row--content">
                <div className="event-type-row--text">
                  <div className="radio-title">Move to another cadence</div>
                </div>
              </div>
            </Radio>
            <div className="end-journey-listboxselect">
              <ListboxSelect
                options={journeyOptions}
                size="medium"
                onChange={(val) => {
                  setSelected(val);
                }}
                value={selected}
                label="Select Cadence"
                emptyValue="Select Cadence"
                disabled={selectedOption !== "selectCadence"}
              />
            </div>
            <Radio
              label="Remove from cadence"
              checked={selectedOption === "remove"}
              onChange={() => {
                setSelected(null);
                setSelectedOption("remove");
              }}
              name="remove"
              className="end-journey-remove--radio"
            >
              <div className="event-type-row--content">
                <div className="event-type-row--text">
                  <div className="radio-title">Remove from cadence</div>
                </div>
              </div>
            </Radio>
          </div>
        </FormField>
      </ModalBody>
    </Modal>
  );
};

EndJourneyModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  task: PropTypes.object,
  step: PropTypes.object,
  completeTaskStateUpdates: PropTypes.func,
  currentJourney: PropTypes.object,
  optionalStateManagementFunction: PropTypes.func,
};

export default EndJourneyModal;
