import { PropTypes } from "base/react-libs";
import { createContext, useState, useMemo, useCallback, useEffect } from "react";
import Api from "entities/helpers/api";

const PinnedCommentsContext = createContext(() => {});

const PinnedCommentsProvider = ({ contact, children }) => {
  const [loading, setLoading] = useState(true);
  const [comments, setComments] = useState([]);
  const [error, setError] = useState(null);

  const query = useMemo(
    () => ({
      must: [
        {
          target_id: {
            match: contact.id,
          },
          "secondary_target.target_id": {
            in: [contact.id],
          },
        },
        {
          "interaction_type.untouched": {
            match: "EverTrue Comment",
          },
        },
        {
          pinned: {
            match: true,
          },
        },
      ],
      sort: [
        {
          date_occurred: {
            order: "desc",
          },
        },
      ],
    }),
    [contact.id]
  );

  const fetchPinnedComments = useCallback(async () => {
    setLoading(true);
    try {
      const data = await Api.SEARCH.CONTACT_NOTE.post({
        data: JSON.stringify(query),
        params: {},
      });

      setComments([...data.items]);
      setLoading(false);
    } catch (err) {
      handleError(err);
      setLoading(false);
    }
  }, [query]);

  const handleError = (err) => {
    if (err.status === 404) {
      setError("DELETED");
    }
    if (err.status === 403) {
      setError("ACCESS_DENIED");
    }
  };

  const addNewCommentToState = (comment) => setComments([comment, ...comments]);

  const removeCommentFromState = (comment) => setComments(comments.filter((c) => c.id !== comment.id));

  useEffect(() => {
    fetchPinnedComments();
  }, [fetchPinnedComments]);

  return (
    <PinnedCommentsContext.Provider
      value={{
        contact,
        loading,
        comments,
        error,
        addNewCommentToState,
        removeCommentFromState,
      }}
    >
      {children}
    </PinnedCommentsContext.Provider>
  );
};

PinnedCommentsProvider.propTypes = {
  contact: PropTypes.object,
  children: PropTypes.any,
};

export { PinnedCommentsProvider, PinnedCommentsContext };
