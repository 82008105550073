module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   moment = require("moment")
   {div} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   DatePicker = createFactory require("react-date-picker")
   DateInput = require("components/forms/date-input")
   LinkButton = createFactory(require("@evertrue/et-components").LinkButton)

   WEEK_DAY_NAMES =  ["Su", "M", "T", "W", "Th", "F", "Sa"]
   DEFAULT_FORMAT = "YYYY-MM-DD"
   DATE_REGEX = /\d{4}-\d{2}-\d{2}/
   datePropType = (props, propName, componentName) ->
      date = props[propName]
      if date? and !DATE_REGEX.test(date)
         new Error("#{componentName} date format is #{DEFAULT_FORMAT}.")

   ReactLibs.createClass
      displayName: "CalendarDatePicker",
      propTypes:
         onChange: ReactLibs.PropTypes.func
         date_range: ReactLibs.PropTypes.array
         date_from: datePropType
         date_to: datePropType

      getInitialState: ->
         view_date_from: @props.date_from
         view_date_to: @props.date_to

      componentDidMount: ->
         @depracated_is_mounted = true

      componentWillUnmount: ->
         @depracated_is_mounted = false

      componentDidUpdate: (prevProps) ->
         if ((@props.date_from != prevProps.date_from) || (@props.date_to != prevProps.date_to))
            @setState
               view_date_from: @props.date_from
               view_date_to: @props.date_to

      handleDateFromChange: (date) ->
         dateString = _.makeArray(date)[0]
         @props.onChange(dateString, @props.date_to)

      handleDateToChange: (date) ->
         dateString = _.makeArray(date)[0]
         @props.onChange(@props.date_from, dateString)

      handleViewFromDateChange: (dateString) ->
         @setState {view_date_from: dateString} if @depracated_is_mounted

      handleViewToDateChange: (dateString) ->
         @setState {view_date_to: dateString} if @depracated_is_mounted

      handleRangeChange: (date_from, date_to) ->
         if @depracated_is_mounted
            @setState
               view_date_from: date_from
               view_date_to: date_to
         @props.onChange(date_from, date_to)

      handleRenderSelectedDate: (props, date_check) ->
         if props.date.format(DEFAULT_FORMAT) == date_check
            props.className += " calendar-date-picker--selected-date"
         props

      render: ->
         div className: "calendar-date-picker clearfix",
            if !_.isEmpty(@props.date_range)
               div className: "calendar-date-picker--range",
                  _.map @props.date_range, (range, index) =>
                     LinkButton
                        title: "Change calendar date range"
                        key: index
                        className: "calendar-date-picker--shortcut"
                        onClick: =>
                           @handleRangeChange(range.date_from, range.date_to, range.key)
                        range.title

            div className: "calendar-date-picker--from",
               div className: "calendar-date-picker--label", "From"
               DateInput
                  value: @props.date_from
                  disabled: true
                  className: "calendar-date-picker--input disabled"
                  onChange: @handleDateFromChange

               DatePicker
                  ref: "from"
                  range: [moment(@props.date_from), moment(@props.date_to)]
                  viewDate: @state.view_date_from
                  dateFormat: DEFAULT_FORMAT
                  monthFormat: "MMM"
                  weekDayNames: WEEK_DAY_NAMES
                  todayText: "Go to today"
                  onRangeChange: @handleDateFromChange
                  onViewDateChange: @handleViewFromDateChange
                  onRenderDay: (day_props) =>
                     @handleRenderSelectedDate(day_props, @props.date_from)

            div className: "calendar-date-picker--to",
               div className: "calendar-date-picker--label", "To"
               DateInput
                  value: @props.date_to
                  disabled: true
                  className: "calendar-date-picker--input disabled"
                  onChange: @handleDateToChange

               DatePicker
                  ref: "to"
                  range: [moment(@props.date_from), moment(@props.date_to)]
                  viewDate: @state.view_date_to
                  dateFormat: DEFAULT_FORMAT
                  monthFormat: "MMM"
                  weekDayNames: WEEK_DAY_NAMES
                  todayText: "Go to today"
                  onRangeChange: @handleDateToChange
                  onViewDateChange: @handleViewToDateChange
                  onRenderDay: (day_props) =>
                     @handleRenderSelectedDate(day_props, @props.date_to)
