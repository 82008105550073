import _ from "underscore";
import Decorator from "@evertrue/client-decorator";
import PropTypes from "prop-types";
import Api from "entities/helpers/api";
import { PromiseResolver } from "@evertrue/promise-resolver";
import { AdvancedCombobox } from "@evertrue/et-components";
import { TEAM_SOLICITORS_QUERY } from "apps/portfolio-performance/portfolio-queries";

// This dropdown returns "team" solicitors - which are
// contacts that have a child assignment object of parent_type "solicitor" and pool type "team"
// AMD it merges facets for proposal solicitors into one option array for core filters
const ProposalSolicitorDropdown = props => {
  return (
    <PromiseResolver
      promise={() =>
        Api.CONTACTS.SEARCH.post({
          params: { limit: 500 },
          data: _.jsonStringify(TEAM_SOLICITORS_QUERY()),
        })
      }
      render={({ data = {}, loading }) => {
        const solicitor_names = _.map(data.items, contact => {
          const parsed_contact = Decorator.Contacts.parse(contact);
          return Decorator.Contacts.getFullName(parsed_contact);
        });

        // join results with facets
        const facet_names = _.pluck(props.facets, "value");
        const combined_options = _.union(solicitor_names, facet_names).sort();
        const options = _.map(combined_options, option => ({ label: option, value: option }));

        return (
          <div style={{ width: 275 }}>
            <AdvancedCombobox
              label={props.label}
              options={options}
              value={props.value}
              onChange={props.onChange}
              loading={loading}
              multiple
              searchable
              allowChangeAll
              allowUnselectAll
            />
          </div>
        );
      }}
    />
  );
};

ProposalSolicitorDropdown.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  facets: PropTypes.array,
  onChange: PropTypes.func,
};

ProposalSolicitorDropdown.defaultProps = {
  value: undefined,
  onChange: _.noop,
};

export default ProposalSolicitorDropdown;
