import { useState, useEffect } from "react";
import Api from "entities/helpers/api";
import EverTrue from "app";

const useFetchSearchPermissions = (id) => {
  const [permissions, setPermissions] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchResults = async () => {
      setIsLoading(true);

      try {
        const results = await Api.HUB.SEARCH_PERMISSIONS.get({
          urlArgs: { id },
        });
        setPermissions(results);
        setIsLoading(false);
      } catch {
        setIsLoading(false);
        EverTrue.Alert.error("There was a problem fetching search permissions.");
      }
    };

    if (id) {
      fetchResults();
    }
  }, [id]);

  return { permissions, isLoading };
};

export default useFetchSearchPermissions;
