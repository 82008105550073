const CustomLegend = (value, entry) => {
  const { color } = entry;
  return (
    <span>
      <span
        style={{
          display: "inline-block",
          marginRight: "4px",
          marginBottom: "-1px",
          width: "12px",
          height: "12px",
          backgroundColor: color,
          borderRadius: "3px",
        }}
      ></span>
      <span style={{ color: "black" }}>{value}</span>
    </span>
  );
};

export default CustomLegend;
