module.exports = do ->
   React = require("react")
   classNames = require("classnames")
   {div, label, span} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   HelpTip = createFactory require("components/tooltip/helptip").default

   FormField = ReactLibs.createClass
      displayName: "FormField"
      propTypes:
         label: ReactLibs.PropTypes.any.isRequired
         align: ReactLibs.PropTypes.oneOf ["top", "middle"]
         inlineLabel: ReactLibs.PropTypes.bool
         helpKey: ReactLibs.PropTypes.string
         required: ReactLibs.PropTypes.bool
         requiredLabelSize: ReactLibs.PropTypes.string
         boldLabel: ReactLibs.PropTypes.bool
         centerLabel: ReactLibs.PropTypes.bool

      getDefaultProps: ->
         align: "top"
         inlineLabel: true
         required: false
         requiredLabelSize: ""
         boldLabel: false
         centerLabel: false

      render: ->
         unless @props.align == "top"
            align_class = "is-aligned-#{@props.align}"

         div className: classNames(@props.className, "form-field", align_class, "is-label-top": !@props.inlineLabel),
            label className: classNames("form-field--label", "bold-text": @props.boldLabel, "form-field--label--centered": @props.centerLabel),
               span className: "form-field--label-wrap",
                  @props.label 
                  if @props.required then span className: classNames("form-field--required", @props.requiredLabelSize), "Required"
                  if @props.helpKey
                     HelpTip
                        help_key: @props.helpKey
                        position: "right"

            div className: "form-field--control",
               @props.children
