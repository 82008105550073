import PropTypes from "prop-types";
import AddEventModal from "apps/my-trips/components/add-event-modal";
import { ModalTrigger2, Icon, LinkButton, Card } from "@evertrue/et-components";

const EmptyMeetingCard = (props) => {
  return (
    <ModalTrigger2
      closeOnOutsideClick
      trigger={({ open }) => {
        return (
          <LinkButton title="Add event" onClick={open}>
            <Card className="meeting-empty-card">
              <Icon icon="add" className="meeting-empty-card--icon" />
              Add Event
            </Card>
          </LinkButton>
        );
      }}
    >
      {({ close }) => {
        return <AddEventModal tripId={props.tripId} event={props.event} remove={close} />;
      }}
    </ModalTrigger2>
  );
};

EmptyMeetingCard.propTypes = {
  tripId: PropTypes.number,
  event: PropTypes.object,
};

export default EmptyMeetingCard;
