import { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import DNAStore from "base/dna/dna-store";
import EverTrue from "app";
import { saveUserWidgets } from "apps/dashboard/dashboard-requests";
import WidgetCard from "apps/dashboard/components/widget-card";
import { Button } from "@evertrue/et-components";
import { Responsive, WidthProvider } from "react-grid-layout";
import { connect } from "@evertrue/et-flux";

const mapStateToProps = () => ({
  fiscalYearEnd: moment(DNAStore.getFiscalYearEnd()).year(),
});

const ResponsiveGridLayout = WidthProvider(Responsive);

const UserWidgets = ({ widgets, gqlVariables, userId, canReorder, cancelReorder, fiscalYearEnd }) => {
  const [tempOrder, setTempOrder] = useState({});
  const gridLayout = widgets.map((widget) => {
    return { i: widget.id.toString(), x: widget.position_x, y: widget.position_y, w: widget.width, h: widget.height };
  });

  const handleSave = () => {
    if (Object.keys(tempOrder).length > 0) {
      EverTrue.track.set("launchpad_action", { type: "widget_reorder" });

      const widgetOrder = tempOrder.map((order) => ({
        width: order.w,
        height: order.h,
        position_x: order.x,
        position_y: order.y,
        widget_id: parseInt(order.i, 10),
      }));

      saveUserWidgets(userId, widgetOrder).then(() => {
        EverTrue.Alert.success("Widgets updated");
        cancelReorder();
      });
    }
  };

  const handleLayoutChange = (layout) => {
    setTempOrder(layout);
  };

  return (
    <>
      {canReorder && (
        <div className="dashboard-can-edit--wrap">
          <div>
            <Button className="dashboard-can-edit--save" type="action" onClick={handleSave}>
              Save
            </Button>
            <Button type="simple" onClick={cancelReorder}>
              Cancel
            </Button>
          </div>
          <span className="dashboard-can-edit--text">Drag to reorder your widgets</span>
        </div>
      )}

      <ResponsiveGridLayout
        className="layout"
        breakpoints={{ lg: 1200, md: 996, sm: 700, xs: 550, xxs: 0 }}
        rowHeight={250}
        cols={{ lg: 4, md: 3, sm: 2, xs: 2, xxs: 1 }}
        onLayoutChange={canReorder ? handleLayoutChange : () => {}}
        margin={[0, 12]}
        isDraggable={canReorder}
        isResizable={canReorder}
        layouts={{ lg: gridLayout }}
      >
        {widgets.map((widget) => (
          <div key={widget.id}>
            <WidgetCard widget={widget} variables={gqlVariables} fiscalYear={fiscalYearEnd} canReorder={canReorder} />
          </div>
        ))}
      </ResponsiveGridLayout>
    </>
  );
};

UserWidgets.propTypes = {
  widgets: PropTypes.array.isRequired,
  gqlVariables: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired,
  canReorder: PropTypes.bool.isRequired,
  cancelReorder: PropTypes.func.isRequired,
  fiscalYearEnd: PropTypes.number,
};

export default connect(UserWidgets, [DNAStore], mapStateToProps);
