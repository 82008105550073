import { useState, useEffect } from "react";
import Api from "entities/helpers/api";
import EverTrue from "app";
import RoleHelper from "entities/helpers/role-helper";

const LIMIT = 50;

const useFetchAllUsers = () => {
  const [users, setUsers] = useState([]);

  const fetchUsers = async (name, offset) => {
    const queryRoles = { "affiliations.affiliation_roles.role_id": { in: RoleHelper.toArray() } };
    const querySort = { sort: [{ "name.untouched": { order: "asc" } }] };
    const query = name
      ? { must: [queryRoles, { name: { wildcard: `*${name}*` } }], ...querySort }
      : { must: [queryRoles], ...querySort };

    try {
      const results = await Api.AUTH.USER.post({
        urlExtend: "/search",
        data: JSON.stringify(query),
        params: { limit: LIMIT, offset, include_profile_picture: true },
      });

      if (offset > 0) {
        setUsers((prev) => [...prev, ...results.users]);
      } else {
        setUsers(results.users);
      }
    } catch {
      EverTrue.Alert.error("There was a problem fetching users.");
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return { users, fetchUsers };
};

export default useFetchAllUsers;
