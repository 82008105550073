import moment from "moment";
import ProposalTableProspects from "apps/proposals/components/proposal-table-prospects";
import { Icon } from "@evertrue/et-components";
import { getHumanReadableTimeFromNow } from "apps/proposals/components/proposal-form-utils";
import ProposalTableSolicitors from "./proposal-table-solicitors";
import { proposalSolicitor, proposalTableDateHeader, proposalTableDateValue } from "../utils";

const formatDate = (date) => (
  <span className="fs-exclude-data">{date ? moment(date).format("MMM DD, YYYY") : "-"}</span>
);
const formatTitle = (title) => {
  if (title) {
    return title.length > 50 ? title.substring(0, 50) + "..." : title;
  }

  return "Untitled";
};

const ProposalTableColumns = (userContactId, sortBy) => {
  return [
    {
      key: 1,
      header: "Proposal",
      headerClassName: "proposal-table--first-col-header",
      width: "240px",
      accessor: (data) => {
        const userProposalContact = proposalSolicitor(data.solicitors, userContactId);

        return (
          <div>
            <div className="proposal-table--first-col fs-exclude-data">
              <Icon
                className={
                  userProposalContact.key_proposal
                    ? "proposal-table--key-proposal-star"
                    : "proposal-table--proposal-star"
                }
                title={userProposalContact.key_proposal ? "Key proposal" : "Not a key proposal"}
                icon="star"
                size={1}
              />
              <div>{formatTitle(data.title)}</div>
            </div>
          </div>
        );
      },
    },
    {
      key: 2,
      width: "180px",
      header: "Prospect",
      accessor: (data) => <ProposalTableProspects proposal={data} />,
    },
    {
      key: 3,
      header: "Stage",
      headerClassName: "text-left",
      accessor: (data) => <div className="text-left fs-exclude-data">{data.status || "-"}</div>,
    },
    {
      key: 4,
      header: proposalTableDateHeader(sortBy),
      headerClassName: "text-left",
      accessor: (data) => {
        const dynamicDateValue = proposalTableDateValue(sortBy, data);
        return formatDate(dynamicDateValue);
      },
    },
    {
      key: 5,
      header: "Time in Stage",
      headerClassName: "text-left",
      accessor: (data) => {
        return <div className="text-left fs-exclude-data">{getHumanReadableTimeFromNow(data.status_start_date)}</div>;
      },
    },
    {
      key: 6,
      header: "Solicitors",
      accessor: (data = {}) => {
        return <ProposalTableSolicitors solicitors={data.solicitors} userContactId={userContactId} />;
      },
    },
  ];
};

export default ProposalTableColumns;
