import { Fragment, Component } from "react";
import PropTypes from "prop-types";
import { connect } from "@evertrue/et-flux";
import DNAStore from "base/dna/dna-store";
import {
  fetchPoolById,
  getVolunteerActivityFilterParam,
} from "apps/volunteers/utils";
import VolunteerUserResolver from "apps/volunteers/components/volunteer-user-resolver";
import {
  prospectsTotalQuery,
  numberOfInteractions,
  stageStatsQuery,
  activityTypeQuery,
  numberOfProspectsWithInteractions,
  fetchAnalytics,
  fetchContactsById,
  fetchSessionsByContactId,
} from "apps/volunteers/vms-queries";
import {
  PoolReportController as PoolReport,
  ApiContext,
  VolunteersStatsTable as VolunteersStatsTableWrapper,
  MixPanelContext,
} from "@evertrue/vms-insights";
import { Link } from "@evertrue/et-components";
import StageResolver from "apps/assignments/components/stage-resolver";
import EverTrue from "app";
import Api from "entities/helpers/api";
import IsGated from "components/is-gated";

const statusDict = {
  true: "assigned",
  false: "unassigned",
  all: "all",
};
const mixPanelEvents = {
  toggleAssignmentStatus: ({ status }) => {
    EverTrue.track.set("pool_report_action", { type: "filtered", filter: statusDict[status] });
  },
  sortByColumns: ({ sortField }) => {
    EverTrue.track.set("pool_report_action", { type: "sorted", filter: sortField });
  },
};

const mapStateToProps = () => ({
  orgFiscalYearEnd: DNAStore.getFiscalYearEnd(),
  orgFiscalYear: DNAStore.getByKey("ET.Org.FiscalYear"),
});

const apiCalls = {
  fetchPoolById,
  prospectsTotalQuery: prospectsTotalQuery,
  fetchNumberOfInteractions: numberOfInteractions,
  stageStatsQuery,
  fetchActivityType: activityTypeQuery,
  fetchNumberOfProspectsWithInteractions: numberOfProspectsWithInteractions,
  fetchAnalytics,
  fetchContactsById,
  fetchSessionsByContactId,
  warmAnalyticsData: ({ poolId }) =>
    Api.VOLUNTEER.WARM_ANALYTICS.get({
      params: { pool_id: poolId },
    }),
};

class PoolReportController extends Component {
  static propTypes = {
    pool: PropTypes.object,
    orgFiscalYear: PropTypes.string,
    orgFiscalYearEnd: PropTypes.string,
  };

  componentDidMount() {
    EverTrue.track.set("pool_report_action", { type: "viewed" });
  }

  // Used for linking from volunteer table to core search
  renderActivityCount = (solicitor_contact_id, pool = {}, activity_count) => {
    return (
      <VolunteerUserResolver
        contactIds={[solicitor_contact_id]}
        render={({ volunteer_users }) => {
          const user = volunteer_users[solicitor_contact_id];
          const activity_params = getVolunteerActivityFilterParam(user, pool);
          if (user && pool) {
            return (
              <Link
                title="View activities in core search"
                href={`/interaction?${activity_params}`}
                className="pool-report--link-text"
              >
                {activity_count}
              </Link>
            );
          }
          return activity_count;
        }}
      />
    );
  };

  renderAssignmentCount = (solicitor_contact_id, solicitor_name, pool, assignment_count) => {
    return (
      <p>{assignment_count}</p>
    
      );
  };

  render() {
    return (
      <ApiContext.Provider value={apiCalls}>
        <MixPanelContext.Provider value={mixPanelEvents}>
          <StageResolver
            stageGroupId={this.props.pool.stage_group_id}
            children={({ stagesForGroup, hasStagesFeature }) => (
              <Fragment>
                <PoolReport hasStagesFeature={hasStagesFeature} stagesForGroup={stagesForGroup} {...this.props} />

                <div className="pool-report-controller--table">
                  <IsGated feature="vms_head_agent">
                    {({ show }) => (
                      <VolunteersStatsTableWrapper
                        orgFiscalYear={this.props.orgFiscalYear}
                        orgFiscalYearEnd={this.props.orgFiscalYearEnd}
                        interval={800}
                        pool={this.props.pool}
                        autoHeightMax={400}
                        isHeadAgentEnabled={show}
                        hasStagesFeature={hasStagesFeature}
                        stagesForGroup={stagesForGroup}
                        renderAssignmentCount={this.renderAssignmentCount}
                        renderActivityCount={this.renderActivityCount}
                      />
                    )}
                  </IsGated>
                </div>
              </Fragment>
            )}
          />
        </MixPanelContext.Provider>
      </ApiContext.Provider>
    );
  }
}

export default connect(
  PoolReportController,
  [DNAStore],
  mapStateToProps
);
