import PropTypes from "prop-types";
import ActionMenuItem from "components/controls/action-menu-item";
import IsGated from "components/is-gated";
import { bulkResetPoolsConfirm } from "apps/volunteers/utils";

const PoolActions = ({ pool = {}, openEditPoolModal, openDeletePoolModal }) => {
  const { id: pool_id } = pool;

  return (
    <div className="my-trips-actions">
      <ActionMenuItem icon="settings" onClick={openEditPoolModal} title="Pool Settings">
        Pool Settings
      </ActionMenuItem>
      <ActionMenuItem
        className="pools--reset-icon pools--reset-assignments"
        icon="reset-assignments"
        onClick={() => bulkResetPoolsConfirm([pool_id], "assignments")}
        title="Reset Assignments"
      >
        Reset Assignments
      </ActionMenuItem>
      <IsGated feature="volunteers_stages">
        <ActionMenuItem
          className="pools--reset-icon"
          icon="reset-stages"
          onClick={() => bulkResetPoolsConfirm([pool_id], "stages")}
          title="Reset Stages"
        >
          Reset Stages
        </ActionMenuItem>
      </IsGated>
      <ActionMenuItem className="text-danger" icon="delete" onClick={openDeletePoolModal} title="Delete Pool">
        Delete Pool
      </ActionMenuItem>
    </div>
  );
};
PoolActions.propTypes = {
  pool: PropTypes.object,
  openDeletePoolModal: PropTypes.func.isRequired,
  openEditPoolModal: PropTypes.func.isRequired,
};

export default PoolActions;
