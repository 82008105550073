_ = require("underscore").default
{createComponent} = require("@evertrue/et-flux")
{string, func} = ReactLibs.PropTypes
Api = require("entities/helpers/api")
ErrorLogger = require("entities/helpers/error-log-helper")

module.exports =
   createComponent "RelatedGiftsResolver",
      propTypes:
         related_gift_remote_id: string
         render: func # arg {gifts}

      getDefaultProps: ->
         related_gift_remote_id: ""
         render: -> null

      getInitialState: ->
         loading: false
         gifts: []

      componentDidMount: ->
         if related_id = @props.related_gift_remote_id then @fetchRelatedGifts(related_id)

      fetchRelatedGifts: (related_id, type) ->
         @xhr?.abort?()
         @setState { loading: true }
         @xhr = Api.SEARCH.GIFTS.post
            data: _.jsonStringify(must: [
               {"remote_id": match: related_id}
               {"type": match: type}
            ])
            success: (response) =>
               @setState {gifts: response.items, loading: false}
            error: (xhr) ->
               ErrorLogger.warn "Error loading related Gifts. Error code #{xhr.errorCode}",


      componentDidUpdate: (prevProps) ->
         if related_id = prevProps.related_gift_remote_id
            if related_id isnt @props.related_gift_remote_id
               @fetchRelatedGifts(related_id)

      render: ->
         @props.render({gifts: @state.gifts, loading: @state.loading}) || null
