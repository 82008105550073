const _ = require("underscore").default;
const { createStore } = require("@evertrue/et-flux");
const SelectedContactsSource = require("apps/contact/sources/selected-contacts-source");

module.exports = createStore("SelectedContactsStore", {
  getInitialState() {
    return {
      selected_contacts: [],
      show_selected_indicator: false,
      all_selected: false,
    };
  },

  registerActions() {
    this.on(SelectedContactsSource.actions.selectContacts, this.respondToSelect);
    this.on(SelectedContactsSource.actions.unselectContacts, this.respondToUnselect);
    this.on(SelectedContactsSource.actions.clearContacts, this.respondToClear);
    this.on(SelectedContactsSource.actions.toggleSelectedIndicator, (show_selected_indicator) => {
      this.setState({ show_selected_indicator });
    });
    this.on(SelectedContactsSource.actions.selectAll, (all_selected) => {
      this.setState({ all_selected });
    });
  },

  respondToSelect(contacts) {
    const selected = _.flatten([this.getState("selected_contacts"), contacts]);
    this.setState({ selected_contacts: _.uniq(selected) });
  },

  respondToUnselect(contacts) {
    let selected = this.getState("selected_contacts");
    selected = _.filter(selected, (id) => !_.contains(contacts, id));
    this.setState({ selected_contacts: _.uniq(selected) });
  },

  respondToClear() {
    this.setState({ selected_contacts: [] });
  },

  api: {
    getSelectedContacts() {
      return this.getState("selected_contacts");
    },

    isSelected(id) {
      return _.contains(this.getState("selected_contacts"), id);
    },

    isGroupSelected(ids) {
      return (
        !_.isEmpty(ids) &&
        _.isEmpty(
          _.difference(this.getState("selected_contacts"), ids) && _.difference(ids, this.getState("selected_contacts"))
        )
      );
    },

    getShowSelectedIndicator() {
      return this.getState("show_selected_indicator");
    },

    getIsAllSelected() {
      return this.getState("all_selected");
    },
  },
});
