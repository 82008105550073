import Api from "entities/helpers/api";

const fetchCreatorInfo = async (ids) => {
  const userMap = {};
  const gql = String.raw;
  const query = gql`
    query ($ids: [Int!]) {
      users(ids: $ids) {
        name
        id
      }
    }
  `;

  const creatorInfo = await Api.GRAPHQL.GRAPHQL.post({
    data: JSON.stringify({
      operationName: null,
      query: String.raw`${query}`,
      variables: { ids: ids },
    }),
  });

  creatorInfo.data.users.forEach((creator) => {
    if (creator) {
      const { id, name } = creator;
      userMap[id] = { name };
    }
  });
  return userMap;
};

const mapSourceDataToTarget = (target, source, targetField, newField) => {
  target.forEach((target) => {
    if (target[targetField]) {
      target[newField] = source[target[targetField]];
    }
  });
};

const removeDuplicateTasks = (tasks) => {
  const taskMap = new Set();
  return tasks.reduce((accum, task) => {
    if (!taskMap.has(task.id)) {
      taskMap.add(task.id);
      return [...accum, task];
    }
    return accum;
  }, []);
};

export const fetchTasksByAppliedFilters = async (taskFilters) => {
  const usersSet = new Set();
  const ownersSet = new Set();
  const gql = String.raw;
  const query = gql`
    query (
      $id: BigInt
      $status: String
      $statuses: [String]
      $target_contact_id: BigInt
      $creator_user_id: BigInt
      $owner_user_id: BigInt
      $exclude: [JSON]
      $offset: Int
      $limit: Int
      $sort: [String]
    ) {
      tasks(
        id: $id
        status: $status
        statuses: $statuses
        target_contact_id: $target_contact_id
        creator_user_id: $creator_user_id
        owner_user_id: $owner_user_id
        exclude: $exclude
        offset: $offset
        limit: $limit
        sort: $sort
      ) {
        items {
          id
          action_type
          comments
          completed_at
          contact_journey_id
          created_at
          creator_user_id
          deleted_at
          description
          due_date
          journey_task_id
          oid
          owner_user_id
          status
          target_contact_id
          title
          updated_at
          updater_user_id
          metadata {
            journey_id
            journey_name
            step_number
            day_number
          }
          contact {
            contact_attributes {
              name_full
            }
            profile_image {
              url
            }
            id
          }
        }
        total
      }
    }
  `;
  try {
    const resp = await Api.GRAPHQL.GRAPHQL.post({
      data: JSON.stringify({
        operationName: null,
        query: String.raw`${query}`,
        variables: taskFilters,
      }),
    });
    const tasksResponse = resp.data.tasks.items;

    tasksResponse.forEach((task) => {
      if (task.creator_user_id) {
        usersSet.add(task.creator_user_id);
      }
      if (task.owner_user_id) {
        ownersSet.add(task.owner_user_id);
      }
      task.due_date = Date.parse(task.due_date) / 1000;
      task.completed_at = Date.parse(task.completed_at) / 1000;
    });
    const creatorInfo = await fetchCreatorInfo(Array.from(usersSet));
    const ownerInfo = await fetchCreatorInfo(Array.from(ownersSet));
    mapSourceDataToTarget(tasksResponse, creatorInfo, "creator_user_id", "creator_info");
    mapSourceDataToTarget(tasksResponse, ownerInfo, "owner_user_id", "owner_info");

    return { items: removeDuplicateTasks(tasksResponse), total: resp.data.tasks.total };
  } catch (e) {
    throw e;
  }
};

export const fetchAllTasksCount = async (taskFilters) => {
  const gql = String.raw;
  const query = gql`
    query ($user_id: BigInt) {
      allTasksCount(user_id: $user_id) {
        total
      }
    }
  `;

  try {
    const resp = await Api.GRAPHQL.GRAPHQL.post({
      data: JSON.stringify({
        operationName: null,
        query: String.raw`${query}`,
        variables: taskFilters,
      }),
    });
    return { total: resp.data.allTasksCount.total };
  } catch (e) {
    throw e;
  }
};

export const fetchThankviewEngagements = async (contactId) => {
  const gql = String.raw;
  const query = gql`
    query ($contactId: BigInt!) {
      interactionFindTVEngagementsByContactId(contact_id: $contactId) {
        id
        date_occurred
        custom_fields {
          value
          meta_data {
            display_name
          }
        }
      }
    }
  `;
  try {
    const resp = await Api.GRAPHQL.GRAPHQL.post({
      data: JSON.stringify({
        operationName: null,
        query: String.raw`${query}`,
        variables: { contactId },
      }),
    });
    return resp.data.interactionFindTVEngagementsByContactId;
  } catch (e) {
    throw e;
  }
};
