import { ListboxMultiselect } from "@evertrue/et-components";
// Reusable component for creating map filters
import { useState, useEffect } from "react";
import { PropTypes } from "base/react-libs";

const MapFilter = ({ onChange, icon, options, label, onOpen, selectedValues = [], headerText = "", clearFilters }) => {
  const [values, setValues] = useState([]);

  useEffect(() => {
    if (clearFilters) {
      setValues([]);
    }
  }, [clearFilters]);

  const handleChange = (vals) => {
    setValues(vals);
    onChange(vals);
  };

  return (
    <>
      <ListboxMultiselect
        buttonClassName="map-toolbar--button"
        hideSelectedValue={true}
        onChange={handleChange}
        values={values}
        label={label}
        icon={icon}
        options={options}
        onOpen={onOpen}
        headerText={headerText}
      />
    </>
  );
};

MapFilter.propTypes = {
  onChange: PropTypes.func,
  icon: PropTypes.string,
  options: PropTypes.array,
  onOpen: PropTypes.func,
  label: PropTypes.string,
  selectedValues: PropTypes.array,
  headerText: PropTypes.string,
  clearFilters: PropTypes.bool,
};

export default MapFilter;
