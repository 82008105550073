module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   EverTrue = require("app")
   numeral = require("numeral")
   {div} = ReactLibs.DOMFactories
   MyTeamChange = require("apps/my-team/components/my-team-change")
   AllUsersStore = require("apps/users/stores/all-users-store").default
   {createFactory} = require("base/new-utils")
   UserCard = createFactory require("apps/users/components/user-card")
   Datalist = createFactory require("components/elements/datalist")

   _WIDTHS =
      XS: 120
      S: 144
      M: 176
      L: 192
      XL: 224

   TableCells =
      fundraiser:
         label: "Solicitor"
         width: _WIDTHS.XL
         sticky: true
         prop: "name|prospects_total"
         help_tip: "my_team_solicitors"
         row: (data) ->
            user = AllUsersStore.getUserByName(data.name)
            prospects = "#{data.prospects_total || 0} Prospects"
            div null,
               UserCard
                  user: user || {name: data.name}
                  disableEmail: true
                  div null, prospects

      visits:
         label: "Visits"
         width: _WIDTHS.M
         prop: "visits_total"
         help_tip: "my_team_visits"
         row: (data) ->
            div className: "text-right",
               if data.visits_are_configured
                  div null,
                     div null, numeral(data.visits_total).format("0,0")
                     MyTeamChange
                        current: data.visits_total || 0
                        past: data.visits_past_total || 0
               else
                  div className: "text-placeholder-light", "not configured"

      my_trips:
         label: "My Trips Meetings"
         width: _WIDTHS.M
         prop: "my-trips_total"
         gated: ->
            EverTrue.store?.org?.hasFeature?("my_trips")
         row: (data) ->
            div className: "text-right",
               numeral(data["my-trips_total"]).format("0,0")

      interaction:
         label: "Interactions"
         width: _WIDTHS.M
         prop: "interactions_total"
         row: (data) ->
            div className: "text-right",
               div null,
                  numeral(data.interactions_total).format("0,0")
               MyTeamChange
                  current: data.interactions_total || 0
                  past: data.interactions_past_total || 0

      proposals:
         label: "Proposals"
         width: _WIDTHS.XL
         prop: "proposal-amount"
         gated: ->
            EverTrue.store?.org?.hasFeature?("proposal_reads")
         row: (data) ->
            amounts = data["proposal-amount"] || {}
            percent = Math.max(1.5, ((amounts["Funded Amount"] / amounts["Ask Amount"]) * 100)) || 0

            div className: "my-team-rollup-table--proposal",
               Datalist label: "Original",
                  _.currencyFormatter(amounts["Original Ask Amount"])
                  MyTeamChange
                     current: _.currencyFormatter(amounts["Original Ask Amount"])
                     past: _.currencyFormatter(amounts["past_Original Ask Amount"])
               Datalist label: "Ask",
                  _.currencyFormatter(amounts["Ask Amount"])
                  MyTeamChange
                     current: _.currencyFormatter(amounts["Ask Amount"])
                     past: _.currencyFormatter(amounts["past_Ask Amount"])
               Datalist label: "Expected",
                  _.currencyFormatter(amounts["Expected Amount"])
                  MyTeamChange
                     current: _.currencyFormatter(amounts["Expected Amount"])
                     past: _.currencyFormatter(amounts["past_Expected Amount"])
               Datalist label: "Funded",
                  _.currencyFormatter(amounts["Funded Amount"])
                  MyTeamChange
                     current: _.currencyFormatter(amounts["Funded Amount"])
                     past: _.currencyFormatter(amounts["past_Funded Amount"])
