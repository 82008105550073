module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   {createStore} = require("@evertrue/et-flux")
   MembershipSource = require("apps/profile/sources/membership-source")
   TripContactsSource = require("apps/my-trips/sources/trip-contacts-source")
   ListContactSource = require("apps/lists/sources/list-contact-source")
   ListStore = require("apps/lists/stores/list-store")


   createStore "MembershipStore",
      getInitialState: ->
         contact_membership: {}
         contact_trip_membership: {}
         loading: false

      registerActions: ->
         @on MembershipSource.actions.loading, @respondToLoading
         @on MembershipSource.actions.fetch, @respondToFetch
         @on MembershipSource.actions.fetchedForContact, @respondToFetchedForContact
         @on MembershipSource.actions.fetchedTripsForContact, @respondToFetchedTrips

         @on ListContactSource.actions.loadingListContacts, @respondToLoading
         @on ListContactSource.actions.addedContacts, @respondToAdd
         @on ListContactSource.actions.removedContacts, @respondToRemove

         @on TripContactsSource.actions.loading, @respondToLoading
         @on TripContactsSource.actions.addedContacts, @respondToAddTrip
         @on TripContactsSource.actions.removedContact, @respondToRemoveTrip

      respondToLoading: (is_loading) ->
         @setState {loading: is_loading}

      respondToFetch: (contact_id) -> ->
         unless @getState("contact_membership")?[contact_id]
            MembershipSource.fetchListsForContact(contact_id)

      respondToFetchedForContact: (contact_id, membership) ->
         contact_membership = _.cloneData @getState("contact_membership")
         contact_membership[contact_id] = membership?.items
         @setState {contact_membership: contact_membership}

      respondToFetchedTrips: (contact_id, membership) ->
         contact_membership = _.cloneData @getState("contact_trip_membership")
         contact_membership[contact_id] = membership?.items
         @setState {contact_trip_membership: contact_membership}

      respondToAdd: (list_id, contacts, options={}) ->
         if options.active_contact_id
            MembershipSource.fetchListsForContact(options.active_contact_id)
            @trackEvent("add_to_list", contacts, list_id)

      respondToRemove: (list_id, contacts, options={}) ->
         if options.active_contact_id
            MembershipSource.fetchListsForContact(options.active_contact_id)
            @trackEvent("remove_from_list", contacts, list_id)

      trackEvent: (type, contacts, list_id) ->
         value = undefined
         if list_id && list_id == ListStore.getFavoriteList()?.id
            value = if type == "add_to_list" then true else false
            type = "favorite"

         EverTrue.track.set "contact_action", _.compactObject
            type: type
            count: contacts?.length
            value: value

      respondToAddTrip: (trip_id, contacts, options={}) ->
         if options.active_contact_id
            MembershipSource.fetchTripsForContact(options.active_contact_id)
            @trackEvent("add_to_trip", contacts)

      respondToRemoveTrip: (trip_id, contact_id, options={}) ->
         if options.active_contact_id
            MembershipSource.fetchTripsForContact(options.active_contact_id)
            @trackEvent("remove_from_trip", [contact_id])

      api:
         getLoading: ->
            @getState("loading")

         getListMembership: (contact_id) ->
            _.filter @getState("contact_membership")?[contact_id], (membership) ->
               !!ListStore.getList(membership.id)

         getTripMembership: (contact_id) ->
            @getState("contact_trip_membership")?[contact_id]

         getFavoriteMembership: (contact_id) ->
            favorite = ListStore.getFavoriteList()
            _.findWhere @getState("contact_membership")?[contact_id], {id: favorite?.id}
