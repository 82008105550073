import { PropTypes } from "base/react-libs";
import { ListboxSelect } from "@evertrue/et-components";
import { getPhoneOptions } from "../utils/dialer-utils";
import FormField from "components/forms/form-field";

const DialerNumbersDropdown = ({ name, phones, handleNumberChange, value }) => {
  const { dropdownOptions } = getPhoneOptions(phones);

  const selectedValue = dropdownOptions.find((option) => (value ? option.value.startsWith(value) : undefined));

  return (
    <FormField align="middle" centerLabel={true} inlineLabel={false} label={name}>
      <ListboxSelect
        size="medium"
        options={dropdownOptions}
        onChange={({ value }) => handleNumberChange(value, false)}
        value={selectedValue}
        label="Phone Numbers"
        emptyValue={dropdownOptions.length ? dropdownOptions[0].label : "No phone numbers listed"}
      />
    </FormField>
  );
};

DialerNumbersDropdown.propTypes = {
  name: PropTypes.string,
  phones: PropTypes.array,
  handleNumberChange: PropTypes.func,
  value: PropTypes.string,
};

export default DialerNumbersDropdown;
