module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   EverTrue = require("app")
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   FilterSource = require("apps/filters/sources/filter-source")
   ListSource = require("apps/lists/sources/list-source")
   UrlSource = require("apps/layout/sources/url-source")
   FilterStore = require("apps/filters/stores/filter-store")
   SegmentStore = require("apps/filters/stores/segment-store")
   ContactSource = require("apps/contact/sources/contact-source")
   {div, span, h1} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   Modal = createFactory require("components/modals/modal")
   ModalTrigger = createFactory require("components/modals/modal-trigger")
   SegmentFilterDrawer = require("apps/filters/components/segment-filters-drawer")
   SaveSegmentController = require("apps/filters/controllers/save-segment-controller")
   SegmentActions = require("apps/filters/components/segment-actions")
   ClickToInput = require("components/controls/click-to-input")
   SelectedContactsString = require("components/formatting/selected-contacts-string")
   ActionMenu = createFactory(require("components/controls/action-menu"))
   Icon = createFactory (require("components/elements/icon").default)
   FilterDisplayTitle = require("apps/filters/components/filter-display-title")
   ToolTip = require("components/overlays/tooltip")
   ShowHideAnimate = require("components/show-hide-animate")
   EllipsisOverflow = createFactory require("components/formatting/ellipsis-overflow")
   SegmentSource = require("apps/filters/sources/segment-source")
   CoreSearchStore = require("apps/core-search/stores/core-search-store")
   LinkButton = createFactory(require("@evertrue/et-components").LinkButton)
   Button = createFactory(require("@evertrue/et-components").Button)
   { sendFocus } = require("base/accessibility-utils")

   createComponent "SegmentFiltersController",
      propTypes:
         view: ReactLibs.PropTypes.string
         resultCount: ReactLibs.PropTypes.number
         onExport: ReactLibs.PropTypes.func
         onApply: ReactLibs.PropTypes.func

      getInitialState: ->
         visible: false
         is_docked: false

      registerStores: ->
         @on SegmentStore, ->
            selected_segment: SegmentStore.getSelectedSegment()

         @on FilterStore, ->
            filters: FilterStore.getCurrentFilters()
            active_filters: FilterStore.getActiveFilters()
            operator: FilterStore.getOperator()

         @on CoreSearchStore, ->
            label: CoreSearchStore.getActiveLabel()
            search_type: CoreSearchStore.getActiveQueryType()
            search_type_referrer: CoreSearchStore.getActiveTypeReferrer()

      handleToggle: ->
         current_visibility = @state.visible
         if FilterStore.hasUnappliedFilters() && current_visibility
            EverTrue.Alert.confirm
               type: "info"
               content: "You have made changes to your filters. Would you like to apply these changes?"
               headline: "Apply Changes?"
               buttonLabel: "Apply Changes"
            , (confirmed) =>
               if confirmed then @handleApplyFilter(@state.filters)
               else FilterSource.cancel()
               @setState {visible: !current_visibility}
         else
            @setState {visible: !current_visibility}

      handleApplyFilter: (filters) ->
         filters = _.filter filters, (filter) ->
            empty_item = _.isObject(filter.value) && _.isEmpty(filter.value)
            empty_with_param = false
            
            if filter.value?.parameter
               if filter.value.parameter.value.includes("is")
                  empty_with_param = false
               else
                  empty_with_param = !_.omit(filter.value, "parameter")
            else
               empty_with_param = filter.value?.parameter && !filter.value?.value
            filter.filter_id && !(_.isUndefined(filter.value) || empty_item || empty_with_param)

         if @state.selected_segment
            EverTrue.track.setReferrer(@state.selected_segment?.type)
         else if @props.view == "map"
            EverTrue.track.setReferrer("map")
         else
            EverTrue.track.setReferrer(@state.search_type_referrer)

         FilterSource.set(filters)
         @setState {visible: false}

         _.defer =>
            @props.onApply?()
            @props.onChange?()


      handleCancelFilter: ->
         @setState {visible: false, is_docked: false}
         # when filters is closed, reset focus on filter button
         sendFocus(@refs.filter_button)

      handleDeleteSegment: ->
         EverTrue.Alert.confirm
            content: "Deleted saved search cannot be recovered. Are you sure you want to delete this saved search?"
            headline: "Delete Saved Search?"
            buttonLabel: "Delete Saved Search"
         , (e) =>
            if e
               list_id = @state.selected_segment?.value
               ListSource.delete(list_id)

      handleSaveSegmentName: (name) ->
         if !_.isEmpty(name) && name != @state.selected_segment?.name
            list = SegmentStore.getSegment(@state.selected_segment?.id)
            ListSource.update _.extend {}, list,
               name: name
               type: "dynamic"
               filters: _.map list.filters, (filter) ->
                  {id: filter.filter_id, value: filter.value, version: 1}

            EverTrue.track.set "search_action",
               type: "update_name"
               name: name
               params: FilterStore.getActiveFilterKeys()

      handleSelectSegment: (segment) ->
         SegmentSource.applySegment(segment?.id)

      handleRevert: ->
         unless @state.visible
            FilterSource.set(@state.selected_segment?.filters)
            FilterSource.updateOperator(@state.selected_segment?.operator)
            _.defer =>
               @props.onApply?()
               @props.onChange?()

      handleClearFilters: ->
         UrlSource.updateSegment()
         FilterSource.set()
         ContactSource.query()
         return undefined

      render: ->
         segment_type = @state.selected_segment?.type
         segment_filters = @state.selected_segment?.filters
         has_unsaved_changes = _.deepArrayCompare(segment_filters, @state.active_filters, {exclude: "filter_row_id"})
         has_operator_change = @state.operator != @state.selected_segment?.operator

         # old version of saved search alerter
         segment_results_filter = FilterStore.getActiveFilterByKey("segment_results")
         results_segment = SegmentStore.getSegment(parseFloat(segment_results_filter?.list_id))

         # new version of saved search alerter
         segment_results_filter_v2 = FilterStore.getActiveFilterByKey("segment_results_v2")
         results_segment_v2 = SegmentStore.getSegmentByTagId(parseFloat(segment_results_filter_v2?.tag_id))

         div className: "segment-filters--container",
            div className: classNames("segment-filters clearfix", {"is-docked": @state.visible && @state.is_docked}),
               div className: classNames("segment-filters--header", {"is-open": @state.visible}),

                  Button
                     type: "simple",
                     className: classNames("segment-filters--toggle", {"is-open": @state.visible}),
                     onClick: @handleToggle
                     title: "Open Filters Drawer"
                     ref: "filter_button"
                     "aria-expanded" : @state.visible

                     div className: "segment-filters--toggle-wrap",
                        Icon icon: "filter", size: 1
                        span null, "Filters"
                        Icon icon: (if @state.visible then "chevron-up" else "chevron-down"), size: 1

                  # Main Label
                  div className: "segment-filters--label",
                     if @state.selected_segment && segment_type == "saved_search"
                        span null,
                           ClickToInput
                              value: @state.selected_segment?.name
                              onSave: @handleSaveSegmentName
                              disable: @state.visible
                     else if @state.selected_segment
                        EllipsisOverflow null,
                           h1 tabIndex: -1, @state.selected_segment.name
                     # old version of saved search alerter
                     else if segment_results_filter && results_segment
                        EllipsisOverflow null,
                           span null,
                              "New results for "
                              LinkButton title: "View new results", onClick: (=> @handleSelectSegment(results_segment)),
                                 results_segment?.name
                     # new version of saved search alerter
                     else if segment_results_filter_v2 && results_segment_v2
                        EllipsisOverflow null,
                           span null,
                              "New results for "
                              LinkButton title: "View new results", onClick: (=> @handleSelectSegment(results_segment_v2)),
                                 results_segment_v2?.name
                     else if _.isEmpty(@state.active_filters)
                        h1 tabIndex: -1, "All #{@state.label}"
                     else
                        div null,
                           FilterDisplayTitle
                              filters: @state.active_filters
                              onClick: => @setState {visible: true}

                     if !_.isUndefined(@props.resultCount)
                        div className: "segment-filters--result-count",
                           SelectedContactsString
                              results: @props.resultCount
                              label: @state.label

                  # Save Segment/Filters Options
                  div className: classNames("segment-filters--state", {"is-open": @state.visible}),
                     if(@state.active_filters.length > 0 && !EverTrue.UrlManager.get("selected_segment"))
                        Button
                           type: "simple"
                           onClick: @handleClearFilters
                           title: "ClearFilters"
                           className: "reset-button-margin"
                           span
                              Icon icon: "clear"
                           "Reset All"
                     if (has_unsaved_changes || has_operator_change) && segment_type == "saved_search"
                        div className: "segment-filters--changes",
                           ToolTip
                              className: "inline"
                              content: "Revert saved segment"
                              type: "dark"
                              width: 200
                              disable: @state.visible
                              arrowSize: 1
                              span className: "btn segment-filters--revert", onClick: @handleRevert,
                                 Icon icon: "undo", size: 1

                           ModalTrigger
                              title: "Save Changes"
                              className: "btn segment-filters--updates"
                              modal: Modal(width: 500, SaveSegmentController(segment_id: @state.selected_segment?.id))
                              disable: @state.visible
                              "Save Changes"

                     if @state.search_type == "contacts"
                        ActionMenu className: "segment-filters--save inline", disabled: @state.visible,
                           SegmentActions
                              result_count: @props.resultCount
                              selected_contacts: @state.selected_contacts
                              segment_id: @state.selected_segment?.id
                              segment_type: segment_type
                              onExport: @props.onExport

               SegmentFilterDrawer
                  key: @state.selected_segment?.id
                  visible: @state.visible
                  operator: @state.operator
                  onApply: @handleApplyFilter
                  onCancel: @handleCancelFilter
                  onChange: @props.onChange
                  onDockChange: (is_docked) =>
                     @setState {is_docked: is_docked}

            ShowHideAnimate
               show: @state.visible
               animateInClass: "fadeIn"
               animateOutClass: "fadeOut"
               className: if @state.visible then "segment-filters--overlay"
               onClick: @handleToggle
