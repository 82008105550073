import _ from "underscore";

const Query = require("entities/search/query-parts/query");
const Parameter = require("entities/search/query-parts/parameter-query");
const Property = require("entities/search/query-parts/property-query");

export const parseTimezoneFromContact = (contact = {}) => {
  if (!contact || _.isEmpty(contact)) return null;
  // This function assumes that the address is inside an "addresses" array
  const { addresses = [] } = contact;
  if (!addresses || !addresses.length) return null;

  const primaryAddress = addresses.find((a) => a.primary);
  const address = primaryAddress || addresses[0];

  const { time_zone = {} } = address;
  if (!time_zone) return null;
  const { time_zone: tz = null } = time_zone;

  if (tz) {
    try {
      return new Date()
        .toLocaleTimeString("en-us", {
          timeZone: tz,
          timeZoneName: "short",
        }) // "00:00:00 PM PST"
        .split(" ")[2]; // split and just grab timezone
    } catch (e) {
      return null; // if for some reason it's not there, this will just return undefined
    }
  } else return null;
};

// Copy of function from core-search-config
// Use of core search function breaks test due to coffeescript function import calls
export const createAutoCompleteContactQuery = (value) => {
  const NAME_KEYS = ["name_first", "name_last", "name_nick", "name_maiden", "name_suffix", "name_prefix"];
  const names = value ? value.split(" ") : [value];

  let properties;
  if (_.size(names) > 1) {
    const name_properties = _.map(names, (name) => {
      return Property(NAME_KEYS, name, { type: "wildcard" });
    });
    const result_properties = [];
    name_properties.forEach((property) => {
      const phone_property = Property(["phones.phone"], value.replace(/[\s()]/g, ""), { type: "wildcard" });
      result_properties.push(property.orProperties([phone_property]));
    });
    properties = result_properties;
  } else {
    const remote_ids = _.uniq([value, _.trimRemoteId(value)]);
    const name_property = Property(NAME_KEYS, value, { type: "wildcard" });
    const remote_id_property = Property("identities.value", remote_ids, { type: "contains" });
    const remote_id_property_untouched = Property("identities.value.untouched", remote_ids, { type: "contains" });
    const wildcard_remote_id_property = Property("identities.value", remote_ids, { type: "wildcard" });
    const wildcard_remote_id_property_untouched = Property("identities.value.untouched", remote_ids, {
      type: "wildcard",
    });
    const email_property = Property(["emails.email"], value, { type: "wildcard" });
    const phone_property = Property(["phones.phone"], value.replace(/[\s()]/g, ""), { type: "wildcard" });
    properties = [
      email_property.orProperties([
        phone_property,
        remote_id_property,
        remote_id_property_untouched,
        wildcard_remote_id_property,
        wildcard_remote_id_property_untouched,
        name_property,
      ]),
    ];
  }
  return Query([Parameter("must", properties)]);
};
