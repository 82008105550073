_ = require("underscore").default
React = require("react")
EverTrue = require("app")
{createComponent} = require("@evertrue/et-flux")
{div} = ReactLibs.DOMFactories
{createFactory} = require("base/new-utils")
EventDetailPageHeader = require("apps/events/components/event-detail-page-header")
EventDetailSource = require("apps/events/sources/event-detail-source")
EventDetailStore = require("apps/events/stores/event-detail-store")
EmptyStateMessage = createFactory(require("components/lists/empty-state-message"))
Loading = require("components/elements/loading")
IsGated = createFactory require("components/is-gated").default
EventbriteDetailResolver = createFactory(require("apps/events/components/eventbrite-detail-resolver.js").default)
EventsUnmatchedController = require("apps/events/controllers/events-unmatched-controller")
EventsMatchedController = require("apps/events/controllers/events-matched-controller")
Layout = createFactory require("components/layout").default
Link = createFactory(require("@evertrue/et-components").Link)

module.exports = createComponent "EventsPageController",
   propTypes:
      event_id: ReactLibs.PropTypes.number

   registerStores: ->
      @on EventDetailStore, "event_id", (props) ->
         event: EventDetailStore.getEventById(props.event_id)
         event_is_loading: EventDetailStore.getEventIsLoadingById(props.event_id)
         contacts: EventDetailStore.getContactsByEventId(props.event_id)
         counts: EventDetailStore.getEngagementCounts(props.event_id)
         contact_loading: EventDetailStore.getContactsLoading()
         constituent_search: EventDetailStore.getConstituentSearch()
         activeFilters: EventDetailStore.getActiveFilters()
         filtersQuery: EventDetailStore.getActiveFiltersQuery()

   getInitialState: ->
      activeTable: "matched"

   componentDidMount: ->
      EventDetailSource.setEvent(@props.event_id, @state.activeFilters)
      EverTrue.track.set "event_action", {type: "view", data_type: "matched constituents"}

   UNSAFE_componentWillReceiveProps: (nextProps) ->
      if nextProps.event_id isnt @props.event_id
         EventDetailSource.setEvent(nextProps.event_id, nextProps.filter)

   componentWillUnmount: ->
      EventDetailSource.setEvent(@props.event_id, ["all"])


   handlePage: (page) ->
      EventDetailSource.setPage(page)

   handleSort: (key, reverse) ->
      EventDetailSource.sort(key, reverse)

   handleFilterChange: (filters) ->
      filterKeys = _.pluck filters, "value"
      hadAllSelected = _.contains @state.activeFilters, "all"
      hasAllSelected = _.contains filterKeys, "all"

      if hadAllSelected && filters.length > 1
         filterKeys = _.without filterKeys, "all"
      else if hasAllSelected
         filterKeys = ["all"]

      if filterKeys.length
         EventDetailSource.setEvent(@props?.event_id, filterKeys)
         EverTrue.track.set "event_action",
            type: "filter"
            data_type: this.state.activeTable + " constituents"
            params: filterKeys

   handleToggleChange: (value) ->
      @setState { activeTable: value }
      if value == "unmatched"
         @setState { unmatched_counts: {} }
      EverTrue.track.set "event_action", {type: "view", data_type: value + " constituents"}

   getSelectedFilterCount: ->
      countValues = _.map @state.activeFilters, (key) =>
         @state.counts?[key]
      _.reduce countValues, ((num1, num2) ->
         num1 + num2
      ), 0

   renderHeader: ->
      div className: "events-page-controller--header",


   render: ->
      {
         event,
         activeFilters,
         filtersQuery,
         activeTable,
      } = @state

      { event_id } = @props

      selectedFilterCount = @getSelectedFilterCount()

      isFacebookEvent = event?.source == "facebook"

      if event
         div className: "events-page-controller",
            EventbriteDetailResolver
               event: event
               render: ({matchedCounts, unmatchedCounts}) =>
                  header = div className: "events-page-controller--header",
                     div className: "events-page-controller--page-header-wrapper",
                        EventDetailPageHeader
                           event: event
                           counts: if activeTable == "unmatched" then unmatchedCounts else matchedCounts
                           unmatchedCounts: unmatchedCounts
                           selectedFilterCount: selectedFilterCount
                           onFilterChange: @handleFilterChange
                           showBackButton: true
                           backButtonHref: "/events"
                           filters: activeFilters
                           activeTable: activeTable

                  Layout { header },
                     div className: "events-page-controller--table-wrapper",
                        IsGated feature: "events_eventbrite", render: ({show}) =>
                           if show && ( event.source == "eventbrite" && activeTable == "unmatched")
                              EventsUnmatchedController
                                 event_id: event_id
                                 handleToggleChange: @handleToggleChange
                                 activeFilters: activeFilters
                           else
                              EventsMatchedController
                                 hasWarning: isFacebookEvent
                                 eventSource: event.source
                                 event_id: event_id
                                 selectedFilterCount: selectedFilterCount
                                 filters_query: filtersQuery
                                 activeFilters: activeFilters
                                 handleToggleChange: @handleToggleChange


      else if @state.event_is_loading
         Loading text: "Loading event..."

      else
         EmptyStateMessage
            className: "events-page-controller--not-found"
            icon: "event"
            text: "Event not found"
            Link href: "/events", title: "go back to Events", "Back to Events"
