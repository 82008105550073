module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   EverTrue = require("app")
   {createComponent} = require("@evertrue/et-flux")
   {div, i} = ReactLibs.DOMFactories
   ShowHideAnimate = require("components/show-hide-animate")


   createComponent "ActivityCardGroup",
      propTypes:
         items: ReactLibs.PropTypes.array
         component: ReactLibs.PropTypes.func

      getInitialState: ->
         open: false

      handleToggle: ->
         @setState {open: !@state.open}

      render: ->
         if _.isEmpty(@props.items) then return null
         items = if @state.open then @props.items else [_.first(@props.items)]
         iconClass = EverTrue.classSet "fa fa-fw", "activity-card-group--toggle",
            "fa-angle-down": !@state.open
            "fa-angle-up": @state.open

         div className: EverTrue.classSet("activity-card-group", @props.className,
            "is-closed": (!@state.open && @props.items?.length > 1)),

            div null,
               div className: "activity-card-group--item",
                  @props.component(_.first(@props.items))

               ShowHideAnimate show: @state.open, speed: 100,
                  _.map _.rest(@props.items), (item) =>
                     div className: "activity-card-group--item", key: item.id,
                        @props.component(item)

            if @props.items.length > 1
               i className: iconClass, onClick: @handleToggle
