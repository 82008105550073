module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   EverTrue = require("app")
   {createStore} = require("@evertrue/et-flux")
   ExportGroupConfig = require("apps/export/export-group-config")
   PropertyHelper = require("entities/helpers/property-helper")
   PropertySource = require("apps/contact/sources/property-source")
   GateSource = require("apps/layout/sources/gate-source")
   ExportFieldSource = require("apps/export/sources/export-field-source")
   FeatureStore = require("apps/layout/stores/feature-store").default
   Decorator = require("clientDecorator")


   _fitlerPermission = (schema) ->
      data =
         app_key: EverTrue.config.app_keys.givingtree
         roles: _.pluck(EverTrue.store.user?.getRoles(), "id")
         oid: EverTrue.store.org?.get("id")
         super_user: EverTrue.store.user?.isSuperUser()
      Decorator.Schema.filterPermission(schema, data)

   createStore "ExportFieldStore",
      getInitialState: ->
         fields: {}
         selected: {}
         schema: {}
         loading: false
         schema_error: false

      registerActions: ->
         @on PropertySource.actions.fetchedSchema, @respondToFetchedSchema
         @on GateSource.actions.fetchedDefaults, @respondToNewGates
         @on GateSource.actions.fetchedGates, @respondToNewGates
         @on PropertySource.actions.errorSchema, => @setState {schema_error: true}
         @on PropertySource.actions.loadingSchema, (isLoading) =>
            @setState {loading: isLoading}

         @on ExportFieldSource.actions.selectedCategory, @respondToSelectedCat
         @on ExportFieldSource.actions.unselectedCategory, @respondToUnselectedCat
         @on ExportFieldSource.actions.selectedField, @respondToSelectField
         @on ExportFieldSource.actions.unselectedField, @respondToUnselectField

      respondToNewGates: (gates) ->
         schema = @getState("schema")
         if _.notEmpty(schema) then @respondToFetchedSchema(schema)

      respondToFetchedSchema: (schema) ->
         config = $.extend true, {}, ExportGroupConfig
         schema_props = _fitlerPermission(schema)

         _.each config, (category, key) ->
            if key == "custom"
               category.fields = _.compact _.map schema_props, (prop, prop_key) ->
                  if !prop.default && PropertyHelper.checkAccess(prop)
                     {group: prop.description, properties: [prop_key]}
               category.fields = _.sortBy category.fields, (name) -> name
            else
               _.each category.fields, (field_group) ->
                  field_group.properties =
                     if _.isUndefined(field_group.is_available) or field_group.is_available()
                        _.filter field_group.properties, (prop) ->
                           PropertyHelper.checkAccess(schema_props[prop])
                     else
                        []

                  # Special case to be handled through a custom parameter
                  # also intentionally kept as an empty conditional block because of 
                  # the logical complexity of reversing it, and emphasizing that this
                  # is the exceptional condition when an empty field group is not removed
                  if _.isEmpty(field_group.properties)
                     if FeatureStore.hasFeature("windfall") && field_group.group is "Wealth and Assets"
                     else if FeatureStore.hasFeature("career_updates") && field_group.group is "Career Moves"
                     else
                        category.fields = _.without(category.fields, field_group)


                  # If the RM Assignments gate is turned on, don't include properties for assignment data 
                  # as it will be handled by getShouldIncludeAssignments method
                  if FeatureStore.hasFeature("rm_assignments")
                     if field_group.group is "Assigned To"
                        field_group.properties = _.without(field_group.properties, "assignees.name")
                     else if field_group.group is "Other Giving Data"
                        field_group.properties = _.without(field_group.properties, "assignees.status")

            if _.isEmpty(category.fields)
               config = _.omit(config, key)


         # Temporarily capture error message to try and debug
         # issue where config is empty
         if _.isEmpty(config)
            Raven?.captureMessage "Debug: Empty Export Fields",
               extra:
                  schema: _.keys(schema_props)
                  roles: EverTrue.store.user?.getRoles()

         @setState {fields: config, schema: schema, schema_error: false}

      respondToSelectedCat: (key) ->
         selected = _.clone(@getState("selected"))
         selected[key] = _.pluck @getState("fields")[key]?.fields, "group"
         @setState {selected: selected}

      respondToUnselectedCat: (key) ->
         selected = _.clone(@getState("selected"))
         @setState {selected: _.omit(selected, key)}

      respondToSelectField: (cat, field_group) ->
         selected = _.clone(@getState("selected"))
         selected[cat] ?= []
         selected[cat].push(field_group)
         selected[cat] = _.uniq(selected[cat])
         @setState {selected: selected}

      respondToUnselectField: (cat, field_group) ->
         selected = _.clone(@getState("selected"))
         selected[cat] = _.without(selected[cat], field_group)
         if _.isEmpty(selected[cat]) then selected = _.omit(selected, cat)
         @setState {selected: selected}

      api:
         getGroupedFields: ->
            @getState("fields")

         getSelectedFields: ->
            @getState("selected")

         getError: ->
            @getState("schema_error") || _.isEmpty(@getGroupedFields())

         getLoading: ->
            @getState("loading")

         getShouldIncludeAssignments: ->
            FeatureStore.hasFeature("rm_assignments") &&
            _.some @getState("selected"), (fields) ->
               fields.includes("Assigned To")

         getShouldIncludeWealthData: ->
            FeatureStore.hasFeature("windfall") && 
            _.some @getState("selected"), (fields) ->
               fields.includes("Wealth and Assets")

         getShouldIncludeCareerMoves: ->
            FeatureStore.hasFeature("career_updates") &&
            _.some @getState("selected"), (fields) ->
               fields.includes("Career Moves")
         
         getExportParams: (property_ids) ->
            { 
               property_id: property_ids, 
               include_assigned_to: @getShouldIncludeAssignments(), 
               include_wealth_data: @getShouldIncludeWealthData(),
               include_career_moves_data: @getShouldIncludeCareerMoves()
            }

         getSelectedPropertyIds: ->
            selected = _.clone(@getState("selected"))
            config = _.clone(@getState("fields"))
            if _.contains(selected["scores"], "Donor Potential Score (DPS)")
               EverTrue.track.set("dps_action", type: "export" );

            # Loop through config fields & get all properties in order
            category_props = {}
            _.each config, (category, key) ->
               if selected[key]
                  category_props[category.order - 1] ?= []
                  _.each category.fields, (field) ->
                     if _.contains(selected[key], field.group)
                        _.push category_props[category.order - 1], field.properties

            # Flatten properties in to single array & map property_ids from schema
            keys = _.flatten(_.toArray(category_props))
            schema_props = PropertyHelper.toPropLookup(@getState("schema"))
            _.map keys, (property_key) ->
               schema_props[property_key].id
