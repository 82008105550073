module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   {createStore} = require("@evertrue/et-flux")
   WindowSource = require("apps/layout/sources/window-source")


   createStore "WindowStore",
      getInitialState: ->
         height: undefined
         width: undefined

      registerActions: ->
         @on WindowSource.actions.resize, @respondToResize

      respondToResize: (size) ->
         @setState(size)

      api:
         getWidth: ->
            @getState("width")

         getHeight: ->
            @getState("height")
