module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   EverTrue = require("app")
   {div, p, ul, li, span, a} = ReactLibs.DOMFactories
   {createComponent} = require("@evertrue/et-flux")
   {createFactory} = require("base/new-utils")
   Module = createFactory require("components/module").default
   Modal = createFactory require("components/modals/modal")
   ModalTrigger = createFactory require("components/modals/modal-trigger")
   SecuritySource = require("apps/settings/security-settings/sources/security-source")
   SecurityStore = require("apps/settings/security-settings/stores/security-store")
   moment = require("moment")
   Link = createFactory(require("@evertrue/et-components").Link)
   LoadingMixin = require("mixins/loading-mixin")
   HelpTip = createFactory require("components/tooltip/helptip").default
   SecuritySessionManager = require("apps/settings/security-settings/components/security-session-manager")
   FeatureStore = require("apps/layout/stores/feature-store").default
   Button = createFactory(require("@evertrue/et-components").Button)

   format_date = (date) ->
      if date? then moment(date, "x").format("MMMM DD, YYYY") else "n/a"

   createComponent "SecuritySettingsController",
      mixins: [LoadingMixin]

      getInitialState: ->
         trustedDevice: EverTrue.store.session.get("trusted_device")
         enabledDate: EverTrue.store.user.get("mfa_enabled_at")

      registerStores: ->
         @on SecurityStore, ->
            initialized: SecurityStore.getIsInitialized()
            currentUserMFAStatus: SecurityStore.getUserMFAStatus()
            mfaPhoneNumber: SecurityStore.getMFAPhoneNumber()

      componentDidMount: ->
         EverTrue.store.session.on "change", @onSessionChange
         EverTrue.store.user.on "change", @onUserChange

      componentWillUnmount: ->
         EverTrue.store?.session?.off "change", @onSessionChange
         EverTrue.store?.user?.off "change", @onUserChange

      onSessionChange: ->
         @setState trustedDevice: EverTrue.store.session.get("trusted_device")

      onUserChange: ->
         @setState enabledDate: EverTrue.store.user.get("mfa_enabled_at")

      obfuscatedPhoneNumber: ->
         number = @state.mfaPhoneNumber?.substr(@state.mfaPhoneNumber?.length - 4)
         "(***) *** - #{number}"

      handleDisableMFA: ->
         SecuritySource.disableMFA()

      showMFASettings: ->
         has_sso = EverTrue.store.org.get("sso_method") == "saml"
         has_mfa = EverTrue.store.user.hasMFA() || FeatureStore.hasFeature("two_factor_auth")
         has_mfa && !has_sso

      render: ->
         orgSlug = EverTrue.store.org.attributes.slug || ""
         mfaRequired = EverTrue.store.user.attributes.mfa_required
         isStageEnv = EverTrue.config.environment_data == "Stage"

         if isStageEnv
            mfa_link = "https://stage-accounts.evertrue.com/evertrue/auth/multifactor/create/" + orgSlug + "?redirect_url=" + encodeURIComponent(window.location.href)
         else
            mfa_link = "https://accounts.evertrue.com/evertrue/auth/multifactor/create/" + orgSlug + "?redirect_url=" + encodeURIComponent(window.location.href)

         if mfaRequired && @state.currentUserMFAStatus != undefined && @state.currentUserMFAStatus != "enabled"
            window.location.replace(mfa_link)

         div className: "security-controller",
            if @showMFASettings()
               Module
                  className: "loading-container security-controller--module"
                  header: "2-Step Verification"
                  graytitle: true
                  @moduleSpinner !@state.initialized
                  if @state.currentUserMFAStatus == "enabled"
                     div className: "security-controller--mfa",
                        ul className: "security-controller--verification-info",
                           li className: "security-controller--verification-segment verification-actions",
                              div className: "security-controller--protection-since",
                                 "Protecting your account since " + format_date(@state.enabledDate)

                              if mfaRequired
                                 div null,
                                    p className: "security-controller--disclaimer", "Your organization requires Multifactor Authenication - you can manage the number that is used for verification."
                                    Button
                                       onClick: @handleDisableMFA
                                       title: "Change Verification Phone Number"
                                       "Change Verification Phone Number"
                              else
                                 Button
                                    type: "destroy"
                                    className: "security-controller--disable-mfa"
                                    onClick: @handleDisableMFA
                                    "Turn off 2-Step Verification"

                              div className: "security-controller--remembered-container",
                                 div null,
                                    span className: "security-controller--remembered-header", "Trust this device:"
                                    if @state.trustedDevice
                                       span className: "security-controller--remembered-status is-enabled", "Enabled"
                                    else
                                       span className: "security-controller--remembered-status is-disabled", "Disabled"
                                    HelpTip help_key: "trust_device"

                           li className: "security-controller--verification-segment security-controller--verification-phone",
                              p className: "bold-text", "Verification Phone Number"
                              div className: "security-controller--phone-number-display", @obfuscatedPhoneNumber()
                              p null, "To change your verification phone number turn off 2-Step Verification and setup again using a new phone number."

                  else
                     div className: "security-controller--mfa",
                        div className: "security-controller--contents",
                           "Most people only have one step - their password - to protect their account. 2-Step Verification requires both a password and a mobile device that can receive text messages.
                           With 2-Step Verification, if a bad person gets your password, he/she will still need your phone to get into your account."

                        div null,
                           Button
                              title: "Turn on 2-Step Verification"
                              hrefTarget: "_blank"
                              "data-bypass": true
                              href: mfa_link
                              "Turn on 2-Step Verification"

            SecuritySessionManager()
