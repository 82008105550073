module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   React = require("react")
   {dd, dt, dl, div, span, img} = ReactLibs.DOMFactories
   {createComponent} = require("@evertrue/et-flux")
   {createFactory} = require("base/new-utils")
   FacebookEmbeddedPost = createFactory require("apps/facebook/components/facebook-embedded-post")
   PostStatStore = require("apps/facebook/stores/post-stat-store")
   FacebookReactions = require("apps/facebook/helpers/facebook-reactions-utils")

   createComponent "FacebookPostStats",
      propTypes:
         url: ReactLibs.PropTypes.string
         post_id: ReactLibs.PropTypes.string.isRequired
         post_width: ReactLibs.PropTypes.number

      getInitialState: ->
         PostStatStore.getInitialStats(@props.post_id) # returns "Calculating" unless cached

      componentDidMount: ->
         PostStatStore.bindToUpdates (stats) =>
            post_stat = _.findWhere(stats, {id: @props.post_id})
            if post_stat and @depracated_is_mounted
               @setState(post_stat)
         PostStatStore.getStats(@props.post_id)
         # Need to retrigger because doesn't initially have features loaded
         EverTrue.vent.on "org:initialized", => PostStatStore.getStats(@props.post_id)

      renderReactionCount: ->
         PostStatStore.getReactionData(@props.post_id).total

      renderReactionIcons: ->
         reactionKeys = PostStatStore.getReactionData(@props.post_id).items
         zIndex = reactionKeys.length

         _.map reactionKeys, (reaction_key, index) =>
            img
               key: @props.post_id + reaction_key
               className: "facebook-post-stats--reaction-icon"
               src: FacebookReactions.getIconFor(reaction_key)
               style: {zIndex: zIndex - index}

      render: ->
         div className: "facebook-post-stats " + (@props.className || ""),
            FacebookEmbeddedPost
               url: @props.url
               width: @props.post_width
               post_id: @props.post_id
               silent: @props.silent

            div className: "facebook-post-stats--card",
               div className: "facebook-post-stats--label", "Matched Constituent Stats"

               div className: "facebook-post-stats--body",
                  dl className: "value-list",
                     dd className: "facebook-post-stats--value text-count fs-exclude-data",
                        span null, @state.matched_count
                     dt null, "Engaged Constituents"

                     dd className: "facebook-post-stats--value text-count fs-exclude-data",
                        span null, @renderReactionCount()
                        span null, @renderReactionIcons()
                     dt null, "Reactions"

                     dd className: "facebook-post-stats--value text-count fs-exclude-data",
                        span null, @state.matched_comment_count
                        span null,
                           img
                              className: "facebook-post-stats--reaction-icon"
                              src: "/images/fb-reactions-comment.png"
                     dt null, "Comments"

                     dd className: "facebook-post-stats--value giving-count fs-exclude-data",
                        @state.total_giving
                     dt null, "Total Lifetime Giving"

                     dd className: "facebook-post-stats--value giving-count fs-exclude-data",
                        @state.avg_giving
                     dt null, "Average Lifetime Giving"
