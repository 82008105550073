module.exports = do ->
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {div, strong, span} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   Icon = createFactory (require("components/elements/icon").default)


   createComponent "Education",
      propTypes:
         data: ReactLibs.PropTypes.object

      render: ->
         edu = @props.data || {}

         if _.isEmpty(edu)
            div className: "education-template",
               Icon icon: "education", className: "education-template--icon"
               div className: "education-template--data is-empty",
                  "Not provided"
         else
            div className: "education-template",
               Icon icon: "education", className: "education-template--icon"

               div className: "education-template--data",
                  div null,
                     strong className: "education-template--name fs-exclude-data",
                        edu.school_name

                  div className: "education-template--degree",
                     span className: "fs-exclude-data", edu.degree_in_major

                  div className: "education-template--label",
                     span className: "fs-exclude-data", edu.year
