import PropTypes, { oneOfType } from "prop-types";
import { useOrg } from "base/org/with-org";
import Readmore from "components/formatting/tv-readmore.js";
import { formatTimestampToDate } from "apps/profile/components/activity/tv-activity-helpers.js";
import EverTrue from "app";

const EngagementCard = ({
  id = 0,
  title = "",
  contents = "",
  comment = "",
  date = "",
  icon = null,
  contact = null,
  interactions = [],
}) => {
  const org = useOrg() || {};
  const handleReplyClick = () => {
    EverTrue.track.set("thankview_interactions_action", {
      type: "tv_engagement_link_clicked",
    });
  };

  if (interactions.length === 0) return null;

  return (
    <div className="engagement-card">
      <div className="engagement-card--icon-wrapper">
        <div className="engagement-card--icon">{icon}</div>
      </div>
      <div className="engagement-card--wrapper">
        <div className="engagement-card--title">
          <strong className="fs-exclude-data">{contact.name} interacted with </strong>
          <span style={{ color: "#007c9e" }}>{org.name}'s ThankView</span> |{" "}
          <a
            href={`/interaction/${id}`}
            title="Click here to open the interaction"
            style={{ color: "#53A744" }}
            onClick={handleReplyClick}
          >
            Click here to open the interaction
          </a>
        </div>
        {contents && <div className="engagement-card--contents ellipsis-overflow">{contents}</div>}
        {comment && (
          <div className="engagement-card--comment">
            <Readmore height="20">{comment}</Readmore>
          </div>
        )}
        <div className="engagement-card--metadata">{interactions.join(" | ")}</div>
        <div className="engagement-card--date">Sent: {formatTimestampToDate(date)}</div>
        {!contents && <div style={{ marginBottom: "20px" }} />}
      </div>
    </div>
  );
};

EngagementCard.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  contents: PropTypes.string,
  comment: PropTypes.string,
  date: oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.node,
  contact: PropTypes.object,
  interactions: PropTypes.arrayOf(PropTypes.string),
};

export default EngagementCard;
