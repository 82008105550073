import { Fragment } from "react";
import PropTypes from "prop-types";
import _ from "underscore";
import ToastAlert from "apps/layout/components/toast-alert";
import ProgressBar from "components/forms/progress-bar";

// for more generic jobs. has no action buttons
const propTypes = {
  handleClose: PropTypes.func,
  progress: PropTypes.number,
  text: PropTypes.string,
};

const BulkJobToast = ({ handleClose, progress, text }) => {
  const inProgress = _.isNumber(progress) && progress !== 1;
  return (
    <div className="toast-container">
      <ToastAlert
        type={inProgress ? "info" : "success"}
        icon={inProgress ? "gt-icon-access-time" : undefined}
        onClose={handleClose}
      >
        <Fragment>
          <h5 className="toast--header">{text}</h5>
          {inProgress ? <ProgressBar className="progress-bar-info-inverse" percent={progress} /> : null}
        </Fragment>
      </ToastAlert>
    </div>
  );
};

BulkJobToast.propTypes = propTypes;

export default BulkJobToast;
