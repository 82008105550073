import PropTypes from "prop-types";
import { Icon, Button } from "@evertrue/et-components";

function EmptyStateMessage({
  icon = "search",
  iconKit,
  text,
  size = "default",
  actionLabel,
  actionLink,
  onAction,
  hideIcon = false,
  children,
  style,
}) {
  return (
    <div className={`empty-state-message is-${size}`} style={style}>
      {!hideIcon && (
        <div className="empty-state-message--icon">
          <Icon iconKit={iconKit} icon={icon} />
        </div>
      )}
      {text && <div className="empty-state-message--text">{text}</div>}
      <div className="empty-state-message--content">{children}</div>
      {actionLabel && (
        <div className="empty-state-message--action-area">
          {onAction && (
            <Button type="secondary" className="empty-state-message--action-button" onClick={onAction}>
              {actionLabel}
            </Button>
          )}
          {actionLink && (
            <Button href={actionLink} type="secondary" className="empty-state-message--action-button">
              {actionLabel}
            </Button>
          )}
        </div>
      )}
    </div>
  );
}

EmptyStateMessage.propTypes = {
  icon: PropTypes.string,
  iconKit: PropTypes.string,
  text: PropTypes.node,
  size: PropTypes.oneOf(["small", "default", "page"]),
  actionLabel: PropTypes.any,
  actionLink: PropTypes.any,
  onAction: PropTypes.func,
  hideIcon: PropTypes.bool,
  children: PropTypes.node,
  style: PropTypes.object,
};

export default EmptyStateMessage;
