module.exports = do ->
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories
   Loading = require("components/elements/loading")
   SegmentActivitySource = require("apps/filters/sources/segment-activity-source")
   SegmentActivityStore = require("apps/filters/stores/segment-activity-store")
   {createFactory} = require("base/new-utils")
   EmptyStateMessage = createFactory(require("components/lists/empty-state-message"))
   Link = createFactory(require("@evertrue/et-components").Link)

   # Segment Diff: Fetches activity data, including search criteria for a notification.
   # If successful, it sets a filter and redirects to core search (See SegmentActivityStore)

   createComponent "SegmentDiffController",
      propTypes:
         activity_id: ReactLibs.PropTypes.number

      registerStores: ->
         @on SegmentActivityStore, ->
            loading: SegmentActivityStore.getLoading()
            error: SegmentActivityStore.getError()

      componentDidMount: ->
         # This component get's briefly mounted from the URL, then should
         # redirect to core search through this FLUX flow unless an error occurs.
         SegmentActivitySource.selectActivity(@props.activity_id)

      render: ->
         div className: "segment-diff-controller",
            if @state.loading
               Loading(text: "Loading...")
            else
               div className: "segment-diff-controller--error",
                  EmptyStateMessage
                     icon: "search"
                     text: "Segment Changes Error"
                     "We're sorry, there was a problem trying to display your segment changes. Please
                     contact support if the problem persists."

                  Link href: "/contact", title: "Browse Constituents",
                     "Go to Browse Constituents"
