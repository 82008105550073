import { PropTypes } from "base/react-libs";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    let firstSpaceIndex = label.indexOf(" ");
    let formattedDateLabel = label.substring(firstSpaceIndex);
    return (
      <div className="custom-tooltip">
        <p>{formattedDateLabel}</p>
        {payload.map((entry) => (
          <div key={entry.name}>
            <span className="custom-tooltip-box" style={{ backgroundColor: entry.color }}></span>
            <span>{`${entry.name}: `}</span>
            <span className="bold-text">{`${entry.value}`}</span>
          </div>
        ))}
      </div>
    );
  }
  return null;
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default CustomTooltip;
