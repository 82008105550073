import PropTypes from "prop-types";
import { ActionsMenu, Modal } from "@evertrue/et-components";
import { useCallback, useEffect, useState } from "react";
import { useIdentity } from "base/identity-resolver";
import EverTrue from "app";
import ProfileInteractionsSource from "apps/profile/sources/profile-interactions-source";
import InteractionForm from "apps/interactions/components/create/interaction-form";
import str from "underscore.string";

const InteractionActions = ({ interaction, setLoading, hasInteractionWrites }) => {
  const [canEdit, setCanEdit] = useState(false);
  const { user } = useIdentity();

  const hasEditPermission = useCallback(() => {
    const isComment = interaction.interaction_type === "EverTrue Comment";
    const isSolicitor = interaction.solicitor.some((sol = {}) => sol.user_id === user.id);
    const isAuthor = interaction.author && interaction.author.user_id === user.id;

    setCanEdit(
      (interaction.creator_user_id === user.id || isAuthor || isSolicitor) &&
      (hasInteractionWrites || isComment) &&
      interaction.application !== "VOLUNTEER_APP"
    );
  }, [interaction, user, hasInteractionWrites]);

  useEffect(() => {
    hasEditPermission();
  }, [hasEditPermission]);

  const handleDelete = async () => {
    const contact_id = interaction.contact_id;
    let type = str.humanize(interaction.interaction_type).toLowerCase();
    if (["givingtree comment", "ever true comment"].includes(type.toLowerCase())) type = "EverTrue Comment";

    EverTrue.Alert.confirm(
      {
        content: `Are you sure you want to delete this ${type}? This cannot be undone.`,
        headline: "Delete Interaction?",
      },
      async (confirmed) => {
        if (confirmed) {
          setLoading(true);
          await ProfileInteractionsSource.promise.delete(interaction, contact_id);
          // there is a delay in the source to delete this interaction.
          // As such, we delay the redirect to ensure the delete is complete before moving away from this page
          setTimeout(() => {
            setLoading(false);
            if (EverTrue.UrlManager.hasPreviousPath()) {
              setTimeout(() => window.history.back(), 0);
            } else {
              let route;
              if (contact_id) route = `/contact/${contact_id}`;
              else route = "/interaction";
              EverTrue.Navigator(route, { trigger: true });
            }
          }, 1000);
        }
      }
    );
  };

  const renderInteractionFormModal = ({ editing, is_open, close }) => (
    <Modal isOpen={is_open} closeModal={close} size="medium">
      <InteractionForm
        editing={editing}
        contact={interaction.contact}
        interaction={editing ? interaction : {}}
        referrer={editing ? "edit-pinned-comment-action" : null}
        requestHide={close}
      />
    </Modal>
  );

  const editAction = {
    id: 2,
    icon: "edit",
    label: "Edit",
    mountOnClick: ({ is_open, close }) => {
      return renderInteractionFormModal({ editing: true, is_open, close });
    },
  };

  const deleteAction = {
    id: 3,
    icon: "trash",
    label: "Delete",
    onClick: handleDelete,
  };

  if (canEdit) return <ActionsMenu options={[editAction, deleteAction]} />
  return null

};

InteractionActions.propTypes = {
  interaction: PropTypes.object,
  setLoading: PropTypes.func,
  hasInteractionWrites: PropTypes.bool,
};

export default InteractionActions;
