import _ from "underscore";
import $ from "jquery";

const REDACTED = "ET_is_redacted";

let redacted = localStorage.getItem(REDACTED);

let cbs = [];

const bind = cb => {
   cbs = cbs.concat(cb);
};

const unbind = cb => {
   cbs = _.without(cbs, cb);
};

const trigger = () => {
   _.each(cbs, cb => {
      cb();
   });
};

const options = [
   { label: "Show", key: undefined, className: "is-not-redacted" },
   { label: "Blur", key: "blur", className: "is-redacted-blur" },
   { label: "FS", key: "fs", className: "is-redacted-fs" },
   { label: "Black", key: "cia", className: "is-redacted-cia" },
];

const clear = () => {
   _.each(options, ({ className }) => {
      if (className) {
         $(document.body).removeClass(className);
      }
   });
};

const set = key => {
   const option = _.findWhere(options, { key });
   if (key && option) {
      redacted = key;
      clear();
      $(document.body).addClass(option.className);
      localStorage.setItem(REDACTED, key);
   } else {
      redacted = undefined;
      clear();
      localStorage.removeItem(REDACTED);
   }
   trigger();
};

const getRedacted = () => {
   return redacted;
};

$(() => {
   set(redacted);
});

const redacter = { bind, unbind, getRedacted, options, set };

export default redacter;
