module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   EverTrue = require("app")
   {createComponent} = require("@evertrue/et-flux")
   {div, strong, span} = ReactLibs.DOMFactories
   FeatureStore = require("apps/layout/stores/feature-store").default
   AdminGateSource = require("apps/admin/sources/admin-gate-source")
   AdminGateChangeModal = require("apps/admin/components/admin-gate-change-modal")
   Loading = require("components/elements/loading")
   {createFactory} = require("base/new-utils")
   Icon = createFactory (require("components/elements/icon").default)
   LabelSelector = require("components/forms/label-selector")
   FixedTable = require("components/tables/fixed-table")
   Tooltip = require("components/overlays/tooltip")
   SearchBox = createFactory require("components/forms/search-box")
   Modal = createFactory require("components/modals/modal")
   ModalTrigger = createFactory require("components/modals/modal-trigger")
   str = require("underscore.string")
   Button = createFactory(require("@evertrue/et-components").Button)
   Radio = createFactory(require("@evertrue/et-components").Radio)

   _displayFormat = (string) ->
      str.titleize(str.humanize(string))

   # Callout really important features, like the new UI
   # This are bold, include a badge an bubble to the top of the list
   HIGHLIGHT_FEATURES = []

   createComponent "UserGatingController",
      getInitialState: ->
         search_text: undefined
         product_filter: undefined
         edit_feature: undefined

      registerStores: ->
         @on FeatureStore, ->
            features: FeatureStore.getUsedWebFeatures()
            org_features: FeatureStore.getOrgLevelFeatures()
            loading: FeatureStore.getOrgOnlyGatesLoading()
            saving: false

      componentDidMount: ->
         AdminGateSource.fetchOrgLevelGates()

      handleReset: ->
         EverTrue.Alert.confirm
            content: "This action will reset your gates to the org level or default gate.
            This may take a few seconds to complete."
            headline: "Reset Your Gates?"
         , (e) =>
            if e
               @setState {saving: true}
               keys = _.pluck @state.features, "key"
               AdminGateSource.resetUserGates(keys)

      handleChange: (feature, enabled) ->
         AdminGateSource.updateUserGate(feature.key, enabled) if feature

      handleDelete: (feature) ->
         AdminGateSource.resetUserGates(feature.key)
         @setState {saving: true}

      handleOrgUpdate: (key, value) ->
         org = EverTrue.store.org?.toJSON?()
         platforms = if value == "none" then undefined else value?.split?("|")
         AdminGateSource.updateOrgGate(key, org.id, platforms)

      renderUpdateModal: (feature = {}) ->
         Modal width: 400,
            AdminGateChangeModal
               feature: feature
               key: feature.id
               onSubmit: @handleOrgUpdate

      renderRows: ->
         visibility:
            label:  "Visible to me"
            width: 100
            row: (feature) ->
               if feature.enabled
                  Tooltip
                     className: "inline"
                     content: "Visible for me in #{EverTrue.store.org.get("name")}"
                     position: "right"
                     Icon icon: "eye", className: "icon-highlight", size: 1

         product:
            label: "Product Feature"
            width: 180
            row: (feature) ->
               div className: "text-left",
                  strong null, _displayFormat(feature.feature)
                  div null, _displayFormat(feature.product)

         gate_name:
            label: "Gate"
            width: 260
            row: (feature) ->
               div className: "text-left",
                  str.titleize(str.humanize(feature.key.split(".").join(" ")))
                  if feature.key in HIGHLIGHT_FEATURES
                     Icon icon: "new", className: "icon-info", size: 2

         org_setting:
            label: "Org Setting"
            width: 150
            className: "admin-user-gates--org-inherit"
            row: (feature) =>
               div null,
                  if feature.org_level?.enabled
                     Icon icon: "check", className: "text-bright", size: 1, before: true
                  else
                     Icon icon: "ban", className: "text-muted", size: 1, before: true
                  ModalTrigger modal: @renderUpdateModal(feature), title: "Change Org Setting",
                     span className: "admin-user-gates--org-setting-link", "Change"

         use_org:
            label: "Use Org Setting"
            width: 140
            row: (feature) =>
               Radio
                  name: feature.key
                  checked: feature.is_inherited
                  onChange: => @handleDelete(feature)

         disabled:
            label: "Disabled for Me"
            width: 140
            row: (feature) =>
               Radio
                  name: feature.key
                  checked: !feature.is_inherited && !feature.enabled
                  onChange: => @handleChange(feature, false)

         enabled:
            label: "Enabled for Me"
            width: 140
            row: (feature) =>
               Radio
                  name: feature.key
                  checked: !feature.is_inherited && !!feature.enabled
                  onChange: => @handleChange(feature, true)

      render: ->
         grouped_features = _.groupBy(@state.features, "product")
         product_filters = _.map grouped_features, (group, key) ->
            label: _displayFormat(key)
            items: _.map _.uniq(_.pluck(group, "feature")), (feature_key) ->
               {label: _displayFormat(feature_key), value: feature_key}

         selected_products = _.compact _.pluck(@state.product_filter, "value")
         table_data = _.compact _.map @state.features, (feature) =>
            if feature.feature in selected_products || _.isEmpty(selected_products)
               if _.searchCompare(_displayFormat(feature.key), @state.search_text) ||
                     _.searchCompare(feature.key, @state.search_text)

                  org_level = _.findWhere(@state.org_features, {key: feature.key})
                  is_inherited =  _.isEqual(org_level, _.omit(feature, "feature", "product"))
                  _.extend feature,
                     id: feature.key
                     gate_key: feature.key
                     org_level: org_level
                     is_inherited: is_inherited


         div className: "admin-user-gates fixed-page-wrapper",
            div className: "admin-user-gates--wrapper",
               div className: "admin-user-gates--filters clearfix",
                  LabelSelector
                     className: "admin-user-gates--product-filter advanced-combobox-secondary"
                     value: @state.product_filter
                     options: product_filters
                     searchable: false
                     default_label: "All Feature Groups"
                     allowUnselectAll: true
                     onChange: (val) => @setState({product_filter: val})

                  SearchBox
                     className: "admin-user-gates--gate-filter"
                     placeholder: "Search feature name..."
                     onChange: (val) => @setState {search_text: val}

                  Button type: "destroy", className: "pull-right", onClick: @handleReset,
                     "Reset All To Use Org Setting"

               div className: "admin-user-gates--table",
                  Loading() if @state.saving || @state.loading
                  FixedTable
                     loading: @state.saving || @state.loading
                     data: table_data
                     config: _.map @renderRows(), (row, row_key) -> _.extend(row, key: row_key)
                     emptyMessage: "No Features Found"
