import PropTypes from "prop-types";
import { Icon } from "@evertrue/et-components";

const UserErrorMessage = ({ messageText }) => (
  <div className="dashboard-empty">
    <Icon icon="broken-link" className="dashboard-empty--icon" />
    <div className="dashboard-empty--text">{messageText}</div>
  </div>
);

UserErrorMessage.propTypes = {
  messageText: PropTypes.string.isRequired,
};

export default UserErrorMessage;
