module.exports = do ->
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories
   Loading = require("components/elements/loading")


   createComponent "LoginWaitController",
      render: ->
         div className: "login--wait-container",
            div className: "login--content",
               div className: "login--spinner",
                  Loading({ position: "center"})

