import { PropTypes } from "base/react-libs";
import { LinkButton } from "@evertrue/et-components"
import PinnedComment from "apps/profile/components/pinned-comments/pinned-comment"
import EverTrue from "app";

const PinnedCommentListItem = ({ comment }) => {
  const handleClick = () => {
    EverTrue.track.set("interaction_action", { type: "comment", action: "view" })
    EverTrue.Navigator(`/interaction/${comment.id}`, {trigger: true})
  };

  return (
    <LinkButton 
      title={"Go to pinned comment"}
      className="pinned-comment-list-item"
      onClick={handleClick}
    >
      <PinnedComment comment={comment} />
    </LinkButton>
  );
};

PinnedCommentListItem.propTypes = {
  comment: PropTypes.object,
};

export default PinnedCommentListItem;