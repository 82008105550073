import PropTypes from "prop-types";
import { createContext } from "react";
import useTableSettings from "hooks/use-table-settings";

const ListDataContext = createContext(() => {});

const ListDataProvider = ({ children }) => {
  const { tableSettings, updateTableSettings, resetTable } = useTableSettings();

  return (
    <ListDataContext.Provider
      value={{
        tableSettings,
        updateTableSettings,
        resetTable,
      }}
    >
      {children}
    </ListDataContext.Provider>
  );
};

ListDataProvider.propTypes = {
  children: PropTypes.any,
};

export { ListDataProvider, ListDataContext };
