module.exports = do ->
   _ = require("underscore").default
   AuthApi = require("entities/auth/auth-api")
   SearchQueryCollectionMixin = require("mixins/search-query-mixin")


   class Export extends AuthApi.Model
      @include [SearchQueryCollectionMixin]
      urlRoot: "contacts/v2/exports"

      save: (attrs, options={}) ->
         opts = _.clone(options)
         search_opts = @queryOptions _.pick(_.clone(options), "filters", "query", "search_query")

         data = opts.data || {}
         data.search = _.jsonParse(search_opts.data)
         opts.data = _.jsonStringify(data)

         opts.statusCode ?= {}
         opts.statusCode[202] = ->
            options.success(@)

         delete opts.filters
         super attrs, opts

      bulk: (ids, property_ids) ->
         opts =
            params: {id: ids}
            data: {property_id_list: property_ids}
            disableAlerts: true
            urlRootOverride: "contacts/v1/search/export"
         @save(null, opts)
