export function getIconByActionType(actionType) {
  let icon = "ai-chat";

  // Add logic to determine the icon based on the actionType
  switch (actionType) {
    case "Email":
      icon = "ai-email";
      break;
    case "Call":
      icon = "ai-phone";
      break;
    case "Linkedin":
      icon = "ai-linkedin";
      break;
    case "Text":
      icon = "ai-chat";
      break;
    case "Research":
      icon = "ai-research";
      break;
    case "Other":
      icon = "ai-other";
      break;
    default:
      icon = "ai-chat";
      break;
  }
  return icon;
}
