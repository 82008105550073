module.exports = do ->
   React = require("react")
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   {span} = ReactLibs.DOMFactories
   MyTripUtils = require("apps/my-trips/utils/my-trips-utils")


   createComponent "TripDate",
      propTypes:
         trip: ReactLibs.PropTypes.object

      render: ->
         return null unless @props.trip

         if @props.trip.type=="trip"
            span className: classNames("trip-date", @props.className),
               MyTripUtils.getTripDisplayDate(@props.trip)
         else
            span className: classNames("trip-date", @props.className),
               MyTripUtils.getMeetingDisplayDate(@props.trip)

