module.exports = do ->
   _ = require("underscore").default
   {createComponent} = require("@evertrue/et-flux")
   React = require("react")
   {div} = ReactLibs.DOMFactories
   FixedTable = require("components/tables/fixed-table")
   Pagination = require("components/controls/pagination")
   EventsSource = require("apps/events/sources/events-source")
   EventsStore = require("apps/events/stores/events-store")
   {createFactory} = require("base/new-utils")
   EmptyStateMessage = createFactory(require("components/lists/empty-state-message"))
   IsGated = createFactory require("components/is-gated").default
   EventsTableConfig = require("apps/events/configs/events-table-config")

   createComponent "EventsTable",
      propTypes:
         data: ReactLibs.PropTypes.array
         loading: ReactLibs.PropTypes.bool

      getDefaultProps: ->
         data: []

      registerStores: ->
         @on EventsStore, ->
            page: EventsStore.getPage()
            total_pages: EventsStore.getTotalPages()

      renderFooter: ->
         if @state.total_pages > 1
            div className: "events-table--footer",
               Pagination
                  page: @state.page
                  total: @state.total_pages
                  onChange: (page) -> EventsSource.setPage(page)

      renderEmptyState: ->
         EmptyStateMessage
            icon: "search"
            text: "Your search didn't return any events"

      render: ->
         IsGated
            feature: "events_eventbrite"
            render: ({ show }) =>
               config = if show then EventsTableConfig else _.reject(EventsTableConfig, key: "unmatched_count")
               div className: "events-table",
                  FixedTable
                     className: "events-table--fixed-table"
                     data: @props.data
                     config: config
                     emptyMessage: @renderEmptyState()
                     appendSpaceColumn: false
                     footer: @renderFooter()
                     loading: @props.loading
