const ContactSummaryTaskCompletion = () => {
  const currentDate = new Date().toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  return (
    <div className="padded-sixteen padding-complete">
      <div className="journey-modal--warning">
        <i className="gt-icon gt-icon-check journey-modal--warning-check"></i>
        {`Cadence Step Marked as Complete on ${currentDate}`}
      </div>
    </div>
  );
};

export default ContactSummaryTaskCompletion;
