import { useState, useEffect, useCallback, useMemo } from "react";
import { useIdentity } from "base/identity-resolver";
import Decorator from "clientDecorator";
import get from "lodash.get";

const useDynamicFields = (quillRef, contact, isSignatureForm, autoReplaceDynamicFields) => {
  const dynamicFields = ["{First Name}", "{Last Name}", "{Primary Phone}", "{Primary Email}", "{Your Name}"];

  const [showDynamicPopup, setShowDynamicPopup] = useState(false);
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0, bottom: 0 });
  const [dynamicPopupViaToolbar, setDynamicPopupViaToolbar] = useState(false);
  const { user } = useIdentity();

  const getFieldValue = useCallback(
    (field) => {
      const userName = Decorator.User.getName(user);
      const fieldValues = {
        "{First Name}": (() => {
          if (contact && contact.contact_attributes) return contact.contact_attributes.name_first;
          return get(contact, "properties.name_first.value");
        })(),
        "{Last Name}": (() => {
          if (contact && contact.contact_attributes) return contact.contact_attributes.name_last;
          return get(contact, "properties.name_last.value");
        })(),
        "{Primary Phone}": (() => {
          if (contact && contact.primary_phone) return contact.primary_phone.phone;
          let primaryPhone = "";
          const { phone } = Decorator.Profile.getPrimaryContactInfo(contact);
          if (phone) primaryPhone = phone.phone;
          return primaryPhone;
        })(),
        "{Primary Email}": (() => {
          if (contact && contact.primary_email) return contact.primary_email.email;
          let primaryEmail;
          const { email } = Decorator.Profile.getPrimaryContactInfo(contact);
          if (email) primaryEmail = email.email;
          return primaryEmail;
        })(),
        "{Your Name}": userName,
      };

      return fieldValues[field];
    },
    [contact, user]
  );

  const filteredFieldLabels = useMemo(() => {
    return dynamicFields.filter((field) => getFieldValue(field));
  }, [dynamicFields, getFieldValue]);

  const [filteredFields, setFilteredFields] = useState(filteredFieldLabels);

  useEffect(() => {
    const dynamicIcon = document.querySelector(".ql-dynamic-fields");
    if (!!dynamicIcon) {
      if (showDynamicPopup) dynamicIcon.classList.add("ql-active");
      else dynamicIcon.classList.remove("ql-active");
      if (!showDynamicPopup) {
        setDynamicPopupViaToolbar(false);
        setPopupPosition({ top: 0, left: 454, bottom: -35 });
      }
    }
  }, [showDynamicPopup]);

  useEffect(() => {
    if (quillRef && quillRef.current) {
      const quill = quillRef.current.getEditor();

      const handleDynamicFields = () => {
        const text = quill.getText();

        if (!quill.hasFocus()) {
          quill.focus();
        }

        const cursorPosition = quill.getSelection() ? quill.getSelection().index : null;
        if (cursorPosition) {
          const match = text[cursorPosition - 2] + text[cursorPosition - 1];
          let { top, left, bottom } = quill.getBounds(cursorPosition);

          if (isSignatureForm) {
            left += 410;
          }

          if (dynamicPopupViaToolbar) {
            setPopupPosition({ top: 0, left: 454, bottom: -35 });
            setShowDynamicPopup(true);
            setFilteredFields(filteredFieldLabels);
            if (match !== "{{") {
              setShowDynamicPopup(false);
            }
          } else {
            if (match === "{{") {
              setPopupPosition({ top, left, bottom });
              setShowDynamicPopup(true);
              setFilteredFields(filteredFieldLabels);
            } else if (showDynamicPopup) {
              const startPos = text.lastIndexOf("{{", cursorPosition) + 2;
              const input = text.slice(startPos, cursorPosition);
              const filtered = filteredFieldLabels.filter((field) => field.toLowerCase().includes(input.toLowerCase()));

              setFilteredFields(filtered);
              setPopupPosition({ top, left, bottom });

              if (filtered.length === 0 || input.trim() === "") {
                setShowDynamicPopup(false);
                setFilteredFields(filteredFieldLabels);
              } else {
                setShowDynamicPopup(true);
              }
            }

            if (match === "{") {
              setShowDynamicPopup(false);
            }
          }
        }
      };

      quill.on("text-change", handleDynamicFields);

      return () => {
        quill.off("text-change", handleDynamicFields);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quillRef, filteredFieldLabels, contact, isSignatureForm]);

  const handleFieldClick = (field, viaEnter) => {
    if (quillRef && quillRef.current) {
      const quill = quillRef.current.getEditor();

      if (!quill.hasFocus()) {
        quill.focus();
      }

      let cursorPosition = quill.getSelection() ? quill.getSelection().index : null;

      if (cursorPosition === null) {
        cursorPosition = quill.getLength();
      }

      const text = quill.getText();
      const match = viaEnter
        ? text[cursorPosition - 3] + text[cursorPosition - 2]
        : text[cursorPosition - 2] + text[cursorPosition - 1];

      let startPos;
      if (match !== "{{" && dynamicPopupViaToolbar) {
        startPos = viaEnter ? cursorPosition - 1 : cursorPosition;
      } else {
        startPos =
          text.lastIndexOf("{{", cursorPosition - 1) > -1
            ? text.lastIndexOf("{{", cursorPosition - 1)
            : viaEnter
            ? cursorPosition - 1
            : cursorPosition;
      }

      const deleteLength = cursorPosition - startPos;
      quill.deleteText(startPos, deleteLength);
      const fieldValues = {
        "{First Name}": "{{name_first}}",
        "{Last Name}": "{{name_last}}",
        "{Primary Phone}": "{{primary_phone}}",
        "{Primary Email}": "{{primary_email}}",
        "{Your Name}": "{{user_name}}",
      };

      const valueToInsert = autoReplaceDynamicFields ? getFieldValue(field) : fieldValues[field];
      if (valueToInsert !== undefined) {
        quill.insertText(startPos, valueToInsert + "");
      }

      setFilteredFields(filteredFieldLabels);
      setShowDynamicPopup(false);
      setDynamicPopupViaToolbar(false);
    }
  };

  const dynamicFieldsHandler = useCallback(() => {
    setDynamicPopupViaToolbar(true);
    setShowDynamicPopup((prev) => !prev);
  }, []);

  return {
    showDynamicPopup,
    setShowDynamicPopup,
    popupPosition,
    filteredFields,
    handleFieldClick,
    dynamicFieldsHandler,
    dynamicPopupViaToolbar,
  };
};

export default useDynamicFields;
