import { useEffect, useRef, useState } from "react";
import EverTrue from "app";
import * as contentful from "contentful";
import showdown from "showdown";

const useContentful = () => {
  const [parsedResources, setParsedResources] = useState([]);
  const [loadingEntries, setLoadingEntries] = useState(false);
  const [client, setClient] = useState(null);

  useEffect(() => {
    // Creating a client object
    const contentfulClient = contentful.createClient({
      space: EverTrue.config.contentful.space_id,
      accessToken: EverTrue.config.contentful_token,
      environment: EverTrue.config.environment !== "production" ? "stage" : "master",
    });
    setClient(contentfulClient);
  }, []);

  useEffect(() => {
    // Fetching contentful data
    const getAndSetData = async () => {
      if (client) {
        setLoadingEntries(true);
        try {
          const data = await client.getEntries();
          if (data.items && data.items.length > 0) {
            const formattedData = data.items.map((entry) => {
              // Initializing
              var converter = new showdown.Converter();

              // Setting options for parsing, other options are true by default
              // Disable the automatic generation of header ids
              converter.setOption("noHeaderId", "true");
              // Parses line breaks as <br>
              converter.setOption("simpleLineBreaks", "true");
              // Open all links in a new window
              converter.setOption("openLinksInNewWindow", "true");
              // Enable support for strikethrough syntax
              converter.setOption("strikethrough", "true");
              var processedBody = converter.makeHtml(entry.fields.body);

              // Custom styling to match Contentful
              processedBody = processedBody.replace("<h1>", "<h1 class='resource-section-h1'>");
              processedBody = processedBody.replace("<h2>", "<h2 class='resource-section-h2'>");
              processedBody = processedBody.replace("<h3>", "<h3 class='resource-section-h3'>");
              processedBody = processedBody.replace("<ul>", "<ul class='resource-section-list'>");
              processedBody = processedBody.replace("<ol>", "<ol class='resource-section-list'>");
              processedBody = processedBody.replace("<hr>", "<hr class='resource-section-hr'>");
              processedBody = processedBody.replace("<blockquote>", "<blockquote class='resource-section-blockquote'>");

              //Add a fixed width to be modified in the below useEffect,
              // so that the page doesn't load with max image width in case the page loads slowly.
              processedBody = processedBody.replace(
                /<img([^>]*)src="([^"]+)"([^>]*)\/?>/g,
                '<img$1src="$2?w=300"$3 />'
              );

              return {
                title: entry.fields.title,
                body: processedBody,
              };
            });
            setParsedResources(formattedData);
          }
        } catch (error) {
          console.error("Error fetching data from Contentful:", error);
        } finally {
          setLoadingEntries(false);
        }
      }
    };

    getAndSetData();
  }, [client]);

  const widthCalculated = useRef(false);

  useEffect(() => {
    // Function to calculate container width
    const calculateContainerWidth = () => {
      // Get the container element
      const container = document.querySelector(".resource-section-body");

      // Calculate the container width and update the state
      if (container && parsedResources && parsedResources.length > 0 && !widthCalculated.current) {
        // Calculate the width for each element and subtract 40pixels for padding
        const elementWidth = container.offsetWidth;

        // Update the parsedResources with modified img tags
        const updatedResourcesData = parsedResources.map((item) => {
          // Replace the width in the img tag with the dynamic width and height
          const updatedBody = item.body.replace(
            /<img([^>]*)src="([^"]+?)(\?[^"]*)?"([^>]*)\/?>/g,
            `<img$1src="$2${"$2".includes("?") ? "&" : "?"}w=${elementWidth}&h=${elementWidth}"$3"$4 />`
          );

          return {
            ...item,
            body: updatedBody,
          };
        });

        // Update the state with the modified parsedResources
        setParsedResources(updatedResourcesData);

        // Set the widthCalculated ref to true to avoid re-calculation
        widthCalculated.current = true;
      }
    };

    calculateContainerWidth();
  }, [parsedResources, setParsedResources, widthCalculated]);

  return {
    parsedResources,
    loadingEntries,
  };
};

export default useContentful;
