import _ from "underscore";
import { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Icon } from "@evertrue/et-components";

export const MultiRecipientsInput = ({ inputRecipients, setInputRecipients }) => {
  const emailInputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);

  const removeEmail = (email) => {
    setInputRecipients((prevRecipients) => prevRecipients.filter((recipient) => recipient !== email));
  };

  const addEmail = (email) => {
    setInputRecipients((prevRecipients) => {
      if (!prevRecipients.some((recipient) => recipient.toLowerCase() === email.toLowerCase())) {
        return [...prevRecipients, email];
      } else {
        return prevRecipients;
      }
    });
    emailInputRef.current.value = "";
  };

  const isValid = (email) => {
    return _.validateEmail(email.trim());
  };

  const handleOnFocus = (e) => {
    setIsFocused(true);
  };

  const handleOnBlur = (e) => {
    if (isValid(e.target.value)) addEmail(e.target.value);
    else e.target.value = "";

    if (
      e.relatedTarget &&
      (!e.relatedTarget.classList.contains("email-recipient-label-cross-icon") ||
        e.relatedTarget.classList.length === 0)
    ) {
      setIsFocused(false);
    }
  };

  const handleOnKeydown = (e) => {
    switch (e.key) {
      case "Enter":
      case ",":
      case " ":
        if (isValid(e.target.value)) addEmail(e.target.value);
        e.preventDefault();
        break;
      case "Tab":
        if (isValid(e.target.value)) addEmail(e.target.value);
        else e.target.value = "";
        break;
      case "Backspace":
        if (!e.currentTarget.value) {
          setInputRecipients((prevRecipients) => prevRecipients.slice(0, -1));
        }
        break;
      default:
    }
  };

  return (
    <div>
      <ul className="email-recipients-field">
        {inputRecipients && !isFocused ? (
          <>
            {inputRecipients.slice(0, 3).map((recipient) => (
              <li className="email-recipients-field-tag" key={recipient}>
                {recipient}
                <Icon
                  icon="cancel"
                  size={1}
                  after={true}
                  className={"email-recipient-label-cross-icon"}
                  onClick={() => removeEmail(recipient)}
                  style={{ margin: "5px", cursor: "pointer" }}
                />
              </li>
            ))}
            {inputRecipients.length > 3 && (
              <li className="email-recipients-field-tag"> {inputRecipients.length - 3} more</li>
            )}
          </>
        ) : (
          inputRecipients.map((recipient, index) => (
            <li className="email-recipients-field-tag" key={recipient}>
              {recipient}
              <Icon
                icon="cancel"
                size={1}
                after={true}
                className={"email-recipient-label-cross-icon"}
                onClick={() => removeEmail(recipient)}
                style={{ margin: "5px", cursor: "pointer" }}
              />
            </li>
          ))
        )}

        <input
          ref={emailInputRef}
          type="text"
          onKeyDown={handleOnKeydown}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
          autoFocus={true}
          className={isFocused ? "email-recipients-field-input-focused" : "email-recipients-field-input-blur"}
          data-testid="recipient-field"
        />
      </ul>
    </div>
  );
};

MultiRecipientsInput.propTypes = {
  inputRecipients: PropTypes.array,
  setInputRecipients: PropTypes.func,
};
