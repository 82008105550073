module.exports = do ->
   str = require("underscore.string")
   _ = require("underscore").default
   EverTrue = require("app")
   FacetUtils = require("apps/filters/utils/facet-utils")

   # parser = new QueryParser(config)
   # parser.run(search_query, aliases)
   # input: config, search & aliases
   # outputs: filter/model object

   class QueryParser
      constructor: (config) ->
         @config = config
         @model = {}

      getModel: -> @model

      run: (query, aliases={}, disableSort) ->
         aliasQueries = @_buildAliases(aliases)
         modelKeys = @_mapPropsToKeys()

         _.each query, (parameter, predicate) =>
            if predicate is "like"
               @_setModel(predicate, _.first(parameter))
               return
            else if predicate is "sort"
               unless disableSort
                  EverTrue.UrlManager.set("sort", _.first(parameter))
               return

            _.each parameter, (property_query) =>
               _.each property_query, (operator, prop) =>
                  propKeys = modelKeys[prop]

                  return if @_matchesHomeAlias(aliases, property_query)
                  return if @_matchesGivingHistory(aliases, property_query)
                  return if @_matchesLinkedinCombo(property_query, propKeys)
                  return if @_matchesFullName(operator, propKeys)

                  if !propKeys
                     @_setModelForInstance(property_query)
                     return

                  not_set = true
                  non_aliased_keys = _.filter propKeys, (filter_ref) =>
                     aliased = @_isAlias(filter_ref, aliasQueries, property_query, aliases)
                     if aliased
                        @model[filter_ref] = aliases[filter_ref]
                        not_set = false
                     !@config[filter_ref].aliased

                  if not_set
                     _.each non_aliased_keys, (key)  => @_setModel(key, operator)

         if @model.full_name
            @model.full_name = @model.full_name.join(" ")

         @model

      _buildAliases: (aliases) ->
         aliasQueryMap = {}
         _.each aliases, (value, key) =>
            query = @config[key]?.query_fn(value)
            property = _.first _.first query?.properties()
            aliasQueryMap[key] = property if property
         aliasQueryMap

      _mapPropsToKeys: ->
         propToKeys = {}
         _.each @config, (conf, key) =>
            query = conf.query_fn(false)
            properties = query?.propertyKeys()

            if _.isArray(properties)
               _.each properties, (prop) =>
                  @_addKeyToPropMap(propToKeys, prop, key)
            else @_addKeyToPropMap(propToKeys, properties, key)
         propToKeys

      _addKeyToPropMap: (propToKeys, prop, key) ->
         if propToKeys[prop] then propToKeys[prop].push key
         else propToKeys[prop] = [key]
         propToKeys

      _setModel: (key, value) ->
         if @_isNestedValue(key, value) then @model[key] = _.first _.values(value)
         else @model[key] = _.omit(value, "coerce")

      _setModelForInstance: (property_query) ->
         map = @_mapPropsToKeys()
         parent = _.first _.keys property_query
         instance = property_query[parent]?.instance

         if instance?.house_value_median && instance?.type
            @_setModel map[parent + ".house_value_median"]?[0], instance.house_value_median
         else
            _.each instance, (value, key) =>
               @_setModel map[parent + "." + key]?[0], value

      _isAlias: (key, aliasQueries, property_query, aliases) =>
         hasAliasedConfig = @config[key].aliased
         aliasExists = !!aliasQueries[key]
         if aliasExists
            isQueryMatch = _.jsonStringify(aliasQueries[key]) == _.jsonStringify(property_query)
         else if hasAliasedConfig
            if key is "reunion_year"
               value = property_query["year"]?.in
               isQueryMatch = _.isArray(value)
               if isQueryMatch
                  aliases.reunion_year = @_determineReunionYearValue(value)
                  aliasExists = true
         !!(hasAliasedConfig && aliasExists && isQueryMatch)

      _isNestedValue: (key, value) ->
         try
            query = @config[key]?.query_fn(value)
         catch
            query = @config[key]?.query_fn(false)
         property = _.first _.first query?.properties()
         property?.isNestedType()

      _determineReunionYearValue: (value) ->
         year_ones = _.uniq _.map value, (val) -> _.last(val.toString())
         year_ones = _.sortBy year_ones, (n) -> parseInt(n)
         values = []
         _.each EverTrue.staticFacets["reunion_year"], (opts) ->
            _.each opts.value.split("and"), (n) ->
               values.push(opts.id) if n in year_ones
         _.uniq(values)

      _matchesHomeAlias: (aliases, property_query) ->
         if aliases.wealthy_neighborhood
            isHome = property_query?["addresses.house_value_median"] || property_query?.addresses?.instance?.house_value_median
            if isHome
               values = _.pick aliases, "median_home_value", "wealthy_neighborhood"
               _.each values, (val, key) => @model[key] = val
            isHome

      _matchesGivingHistory: (aliases, property_query) ->
         return false unless (aliases.giving_history || !_.isUndefined(aliases.donor))
         return false if property_query?["giving.lifetime_amount"]?.gte > 0

         model_data = {}
         if _.has property_query, "giving.lybunt"
            model_data.giving_history = "giving.lybunt"
         else if _.has property_query, "giving.sybunt"
            model_data.giving_history = "giving.sybunt"
         else if _.has property_query, "giving.lifetime_amount"
            val = (str.toBoolean(aliases.donor) == true || aliases.giving_history?.match(/must$/))
            model_data.giving_history = if val then "giving.lifetime_amount:must" else "giving.lifetime_amount:must_not"

         @_setModel("giving_history", model_data) unless _.isEmpty(model_data)
         !_.isEmpty(model_data)

      _matchesLinkedinCombo: (property_query, propKeys) ->
         return false if _.isEmpty _.intersection(propKeys, ["company", "industry"])

         model_data = []
         _.each property_query, (operator_query, key) ->
            _.each operator_query.in, (val) ->
               if key.match("linkedin")
                  model_data.push FacetUtils.wrapLinkedinValue(val)
               else model_data.push(val)

         @model[_.first(propKeys)] = model_data unless _.isEmpty(model_data)
         !_.isEmpty(model_data)

      _matchesFullName: (propValue, propKeys) ->
         if _.contains propKeys, "full_name"
            value = propValue?.wildcard || propValue
            @model.full_name ?= []
            @model.full_name.push(value.replace?("*", "") || value)
            @model.full_name = _.uniq(@model.full_name)
            true
         else false
