module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   EverTrue = require("app")
   React = require("react")
   LidsService = require("entities/lids/services")
   Backbone = require("backbone")

   class Facebook extends Backbone.Model
      urlRoot: "https://graph.facebook.com/v2.0/"

      initialize: ->
         @oauth = EverTrue.store.service?.get("oauth2_token")

      parseProfile: (data) ->
         # trying to get results back in similar format as social profiles
         fb_profile = data
         fb_profile.public_profile_photo_url = "https://graph.facebook.com/" + data.id + "/picture?type=large"
         fb_profile.public_profile_url = data.link
         # set id to scoped id
         scoped_id = data.link.match(/// app_scoped_user_id\/(.*?)\/ ///)?[1]
         fb_profile.id = scoped_id
         fb_profile

      fetch: (options={}) ->
         if !@oauth
            @getOauth options, => @fetch(options)
         else
            options.data = _.extend({access_token: @oauth}, options.data)
            options.statusCode ?= {}
            options.statusCode[403] = =>
               Raven.captureMessage("Facebook Graph 403", {token: @oauth})
            super options

      fetchPages: (ids, success) ->
         url = "https://graph.facebook.com/"
         ids = _.reject ids, (id) -> _.isEmpty(id)
         @fetch
            url: url
            cache: true
            data:
               ids: ids.join(",")
               access_token: undefined
               metadata: 1
            success: success
            error: =>
               @fetch
                  url: url
                  cache: true
                  data: {ids: ids.join(","), metadata: 1}
                  success: success
                  error: (xhr, textStatus, errorThrown) ->
                     Raven.captureMessage("Facebook Pages Error", {extra: {error: errorThrown}})

      fetchProfileByUrl: (url, callback) ->
         $.ajax
            url: "https://graph.facebook.com/" + url
            dataType: "jsonp"
            success: (resp) =>
               @fetchProfileByID(resp.id, (profile) ->
                  callback(profile) if _.isFunction(callback)
               )
            error: (xhr, textStatus, error) ->
               error_msg = xhr?.responseText || ""
               Raven.captureMessage("Facebook URL Search Error", {extra: {fb_error: error_msg}})
               callback() if _.isFunction(callback)

      fetchProfileByID: (id, callback) ->
         if !@oauth
            @getOauth {}, => @fetchProfileByID(id, callback)
         else
            $.ajax
               url: @urlRoot + id
               data:
                  access_token: @oauth
               success: (resp) =>
                  result = if resp.id && resp.name then @parseProfile(resp) else null
                  callback(result) if _.isFunction(callback)
               statusCode:
                  403: ->
                     Raven.captureMessage("Facebook Graph 403", {extra: {token: @oauth}})

      fetchReactions: (ids, success) ->
         updatedUrlRoot = "https://graph.facebook.com/v2.6/" # need v2.6 to get reaction data
         if !@oauth
            @getOauth {}, => @fetchReactions(ids, success)
         else
            $.ajax
               url: updatedUrlRoot + "reactions"
               data:
                  ids: ids.join(",")
                  summary: "total_count"
                  access_token: @oauth
               success: (resp) ->
                  success?(resp)
               statusCode:
                  403: =>
                     Raven.captureMessage("Facebook Graph 403", {extra: {token: @oauth}})

      fetchPagesFromPostIds: (post_ids) ->
         pages = _.uniq _.compact _.map post_ids, (post) -> post.split("_")[0]
         pages = _.difference pages, @keys()

         if _.isEmpty(pages) then @trigger("change")
         else @fetchPages(pages)

      getOauth: (options, callback) ->
         service = new LidsService({service: "facebook"})
         service.fetch
            cache: true
            success: =>
               EverTrue.store.push "service", service, {cache: true}
               @oauth = service.get("oauth2_token")
               callback() if _.isFunction(callback)

      toEmbeddedUrl: (full_post_id) ->
         [page_id, post_id] = full_post_id.split("_")
         if @get(page_id)?.username
            "https://www.facebook.com/#{@get(page_id).username}/posts/#{post_id}"
         else
            "https://www.facebook.com/#{page_id}/posts/#{post_id}"
