module.exports = do ->
   _ = require("underscore").default
   {createComponent} = require("@evertrue/et-flux")
   InteractionSource = require("apps/interactions/sources/interaction-source").default
   InteractionStore = require("apps/interactions/stores/interaction-store").default
   InteractionFeed = require("apps/interactions/components/interaction-feed")


   createComponent "InteractionFeedController",
      propTypes:
         interactions: ReactLibs.PropTypes.array
         group: ReactLibs.PropTypes.bool
         loading: ReactLibs.PropTypes.bool
         highlight: ReactLibs.PropTypes.string
         showFullText: ReactLibs.PropTypes.bool
         onEdit: ReactLibs.PropTypes.func
         onDelete: ReactLibs.PropTypes.func

      registerStores: ->
         @on InteractionStore, ->
            @getInteractions(@props)

      componentDidMount: ->
         InteractionSource.loadInteractions(@props.interactions)

      UNSAFE_componentWillReceiveProps: (newProps) ->
         if !_.isEqual(newProps.interactions, @props.interactions)
            InteractionSource.loadInteractions(newProps.interactions)
            @setState(@getInteractions(newProps))

      getInteractions: (props) ->
         ids = _.pluck(props?.interactions, "id") || []
         interactions: InteractionStore.getInteractions(ids)

      render: ->
         InteractionFeed
            className: @props.className
            notes: @state.interactions
            group: @props.group
            loading: @props.loading
            highlight: @props.highlight
            showFullText: @props.showFullText
            onEdit: @props.onEdit
            onDelete: @props.onDelete
            showMeta: false
            showMessage: true
