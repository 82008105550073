import PropTypes from "prop-types";
import { useFluxStore } from "@evertrue/et-flux";
import FeatureStore from "apps/layout/stores/feature-store";
import { NumericInput, AdvancedCombobox, Button, Icon } from "@evertrue/et-components";

const featureStoreMapState = () => ({
  isDesignationInDollars: FeatureStore.hasFeature("proposal_designation_dollars"),
});

export const ProposalFormDesignations = ({
  onFormChange = () => {},
  proposal = {},
  designationLimit = 1,
  designations = [],
  standardFieldsConfig,
}) => {
  const { isDesignationInDollars } = useFluxStore(FeatureStore, featureStoreMapState);
  const { proposal_designations, require_pairs } = standardFieldsConfig;

  const proposalHasDesignation = (designation) => {
    return proposal.proposal_designations.find((propDesig) => propDesig.ugc_designation_id === designation.value);
  };

  const remainingDesignations = designations.reduce((result, designation) => {
    if (proposalHasDesignation(designation)) return result;

    return result.concat({
      value: designation.value,
      label: designation.label,
    });
  }, []);

  const handleRemoveDesignation = (designation) => {
    const designations = proposal.proposal_designations.filter(
      (proposalDesig) => proposalDesig.ugc_designation_id !== designation.ugc_designation_id
    );
    onFormChange("proposal_designations", designations);
  };

  const handleAddDesignation = (designation) => {
    const designations = [
      ...proposal.proposal_designations,
      { ugc_designation_id: designation.value, name: designation.label, value: "" },
    ];
    onFormChange("proposal_designations", designations);
  };

  const handleEditDesignation = (designation, value) => {
    const designations = proposal.proposal_designations.map((proposalDesig) => {
      if (
        proposalDesig.ugc_designation_id === designation.ugc_designation_id &&
        (value <= 100 || isDesignationInDollars)
      ) {
        return { ...designation, value };
      }

      return proposalDesig;
    });

    onFormChange("proposal_designations", designations);
  };

  return (
    <>
      {designationLimit > 0 && (
        <>
          <div className="proposal-form--header">
            Designations <span>{` (max ${designationLimit})`}</span>
            {proposal_designations.required && (
              <label className="form-field--label-wrap">
                <span className="form-field--required">Required</span>
              </label>
            )}
          </div>
          <ul>
            {proposal.proposal_designations.map((designation) => (
              <li key={designation.ugc_designation_id} className="proposal-form--list-row">
                <div
                  className={
                    require_pairs.designations
                      ? "proposal-form--designation-column proposal-form--designation-column-focus"
                      : "proposal-form--designation-column"
                  }
                >
                  <strong>{designation.name}</strong>
                </div>
                {require_pairs.designations && <hr data-testid="proposal-hr" />}
                <div className="proposal-form--designation-column">
                  {isDesignationInDollars ? (
                    <NumericInput
                      className={
                        require_pairs.designations
                          ? "proposal-form--form-field-amount proposal-form--form-field-amount-focus"
                          : "proposal-form--form-field-amount"
                      }
                      aria-label={`Designation amount for ${designation.name}`}
                      placeholder={0}
                      value={designation.value}
                      maskType="currency"
                      integerLimit={10}
                      allowDecimal={true}
                      onChange={(value) => {
                        handleEditDesignation(designation, value);
                      }}
                      showIcon={true}
                    />
                  ) : (
                    <div className="proposal-form--form-field-percent">
                      <NumericInput
                        aria-label={`Designation percent for ${designation.name}`}
                        value={designation.value}
                        placeholder={0}
                        maskType="percent"
                        allowDecimal={true}
                        integerLimit={3}
                        onChange={(value) => {
                          handleEditDesignation(designation, value);
                        }}
                        showIcon={true}
                        className={require_pairs.designations ? "proposal-form--form-field-amount-focus" : ""}
                      />
                    </div>
                  )}
                  <Button
                    type="simple"
                    onClick={() => handleRemoveDesignation(designation)}
                    label={`Remove designation, ${designation.name}`}
                  >
                    <Icon
                      icon="cancel"
                      className="proposal-form--cancel"
                      title={`Remove designation, ${designation.name}`}
                      size={1}
                    />
                  </Button>
                </div>
              </li>
            ))}
            <li className="proposal-form--add-item-row">
              <div className="proposal-form--form-field-dropdown">
                <AdvancedCombobox
                  label={`Select designations to add to proposal up to a max of ${designationLimit}`}
                  disabled={!remainingDesignations.length || proposal.proposal_designations.length >= designationLimit}
                  options={remainingDesignations}
                  placeholder="Select a designation to add..."
                  onChange={(designation) => handleAddDesignation(designation)}
                  searchable={remainingDesignations.length > 6}
                />
              </div>
            </li>
          </ul>
        </>
      )}
    </>
  );
};

ProposalFormDesignations.propTypes = {
  onFormChange: PropTypes.func,
  proposal: PropTypes.object,
  designationLimit: PropTypes.number,
  designations: PropTypes.array,
  standardFieldsConfig: PropTypes.object.isRequired,
};

export default ProposalFormDesignations;
