module.exports = do ->

   _ = require("underscore").default
   React = require("react")
   EverTrue = require("app")
   {createComponent} = require("@evertrue/et-flux")
   MyPortfolioSource = require("apps/major-gifts/sources/my-portfolio-source")
   MyPortfolioStore = require("apps/major-gifts/stores/my-portfolio-store")
   {createFactory} = require("base/new-utils")
   Modal = createFactory require("components/modals/modal")
   Slideshow = require("components/slideshow")


   _slides = [
      {text:"View your assigned prospects by your organization’s Prospect Status.", image: "my_portfolio_slide_01.png"}
      {text:"View your assigned prospects by location on the map.", image: "my_portfolio_slide_02.png"}
      {text:"My Portfolio is now your start page, accessible from the side navigation menu.", image: "my_portfolio_slide_03.png"}
   ]

   createComponent "MyPortfolioSlidesController",
      registerStores: ->
         @on MyPortfolioStore, ->
            show_modal: MyPortfolioStore.getShouldViewSlides()

      onViewed: ->
         MyPortfolioSource.slidesViewed viewed: true

      render: ->
         is_impersonating = EverTrue.request("isImpersonating?")
         if @state.show_modal && !is_impersonating
            Modal width: 640, keepOpen: true,
               Slideshow
                  title: "Introducing My Portfolio"
                  slides: _slides
                  doneButtonText: "Ok, I got it!"
                  onDone: @onViewed

         else null
