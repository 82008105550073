const AdditionalDetails = ({ customFields }) => (
  <div className="additional-details">
    <div className="interaction-details--header">Additional Details</div>
    <div>
      {customFields.length &&
        customFields.map(({ id, displayName, formattedValue } = {}) => (
          <span key={id} className="interaction-details--labels-item">
            <span> {displayName}: </span>

            <span className="interaction-details--value">{formattedValue}</span>
          </span>
        ))}
    </div>
  </div>
);

AdditionalDetails.propTypes = {
  customFields: ReactLibs.PropTypes.array,
};

AdditionalDetails.defaultProps = {
  customFields: [],
};

export default AdditionalDetails;
