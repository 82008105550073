module.exports = do ->
   React = require("react")
   {div, span} = ReactLibs.DOMFactories
   {createComponent} = require("@evertrue/et-flux")

   createComponent "ChartTooltip",
      propTypes:
         left: ReactLibs.PropTypes.number
         top: ReactLibs.PropTypes.number

      render: ->
         styles =
            position: "absolute"
            left: @props.left
            top: @props.top

         div className: "chart-tooltip", style: styles,
            div className: "chart-tooltip--body",
               @props.children
