module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   React = require("react")
   BaseRouter = require("base/base-router")
   NotificationDetailsController = require("apps/notifications/controllers/notification-details-controller")


   class NotificationRouter extends BaseRouter
      routes:
         "notification/:id": "show"

      show: (id) ->
         @currentView = NotificationDetailsController id: _.toNumber(id)
         EverTrue.page.mount @currentView
