module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   {thead, tr, th, span} = ReactLibs.DOMFactories


   createComponent "FixedTableHeader",
      propTypes:
         columns: ReactLibs.PropTypes.array
         columnWidth: ReactLibs.PropTypes.number
         resizeLastColumn: ReactLibs.PropTypes.bool

      getDefaultProps: ->
         columnWidth: 175

      render: ->
         thead className: "paged-col-table--header table--header",
            tr null,
               _.map @props.columns, (column) =>
                  last_item = @props.resizeLastColumn && index == (@props.columns.length - 1)

                  th
                     key: column.key || column.prop
                     className: classNames(column.className, "is-sticky": column.sticky)
                     style:
                        width: if last_item then "auto" else column.width
                        minWidth: if last_item && column.width != "auto" then @props.columnWidth
                        maxWidth: (column.width || @props.columnWidth)

                     span className: "ellipsis-overflow", column.label
