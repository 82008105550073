import { useState, useEffect } from "react";
import _ from "underscore";
import PropTypes from "prop-types";
import Api from "entities/helpers/api";
import EverTrue from "app";
import { saveUserWidgets } from "apps/dashboard/dashboard-requests";
import { Modal, ModalHeaderWithActions, ModalBody, Checkbox, Loading } from "@evertrue/et-components";
import { useDashboard } from "apps/dashboard/hooks/use-user-dashboard";

const AddRemoveWidgetModal = ({ isOpen = false, close, userId, refreshWidgets }) => {
  const { widgets: user_widgets, loading: loading_user_widgets } = useDashboard(userId);
  const [allWidgets, setAllWidgets] = useState([]);
  const [loadingAll, setLoadingAll] = useState(false);
  const [tempWidgets, setTempWidgets] = useState(user_widgets);

  useEffect(() => {
    const widgets_by_id = {};
    user_widgets.forEach((widget) => {
      widgets_by_id[widget.id] = widget;
    });
    setTempWidgets(widgets_by_id);
  }, [loading_user_widgets, user_widgets]);

  useEffect(() => {
    const loadWidgets = async () => {
      try {
        setLoadingAll(true);
        const resp = await Api.HUB.WIDGETS.get();
        setAllWidgets(resp);
        setLoadingAll(false);
      } catch {
        setLoadingAll(false);
      }
    };

    if (isOpen) {
      loadWidgets();
    }
  }, [isOpen]);

  const handleSubmit = () => {
    EverTrue.track.set("launchpad_action", { type: "widget_add_or_remove" });

    const formatted_data = _.map(tempWidgets, (widget = {}, id) => {
      return {
        widget_id: widget.id,
        position_x: widget.position_x,
        position_y: widget.position_y,
        width: widget.width,
        height: widget.height,
      };
    });
    saveUserWidgets(userId, formatted_data).then((resp) => {
      refreshWidgets();
    });
    close();
  };

  return (
    <Modal isOpen={isOpen} closeModal={close}>
      <ModalHeaderWithActions title="Select which Widgets to Display" closeModal={close} onSubmit={handleSubmit} />
      <ModalBody scrollable={false}>
        {loadingAll ? (
          <Loading />
        ) : (
          allWidgets.map((widget) => {
            const userHasWidgetEnabled = tempWidgets[widget.id];
            return (
              <div className="dashboard-widget-modal--row" key={widget.id}>
                <Checkbox
                  label={widget.title}
                  checked={!_.isEmpty(userHasWidgetEnabled)}
                  onChange={(checked) => {
                    if (checked) {
                      setTempWidgets((prevWidgets) => {
                        const new_widget = {};
                        new_widget[widget.id] = widget;
                        return { ...prevWidgets, ...new_widget };
                      });
                    } else {
                      setTempWidgets((prevWidgets) => {
                        return _.omit(prevWidgets, widget.id);
                      });
                    }
                  }}
                >
                  {widget.title}
                </Checkbox>
              </div>
            );
          })
        )}
      </ModalBody>
    </Modal>
  );
};

AddRemoveWidgetModal.propTypes = {
  isOpen: PropTypes.bool,
  userId: PropTypes.number,
  close: PropTypes.func,
  refreshWidgets: PropTypes.func,
};
export default AddRemoveWidgetModal;
