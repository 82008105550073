import { Component } from "react";
import _ from "underscore";
import PoolsSource from "apps/volunteers/sources/pools-source";
import { AdvancedCombobox } from "@evertrue/et-components";
import LabelSelector from "components/forms/label-selector";

const LIMIT = 200;

class VolunteerPoolDropdown extends Component {
  state = { pools: [], totalPools: null, search: null, missingPools: {} };

  static propTypes = {
    value: ReactLibs.PropTypes.array,
    className: ReactLibs.PropTypes.string,
    allowAll: ReactLibs.PropTypes.bool,
    onChange: ReactLibs.PropTypes.func,
  };

  static defaultProps = {
    allowAll: true,
  };

  componentDidMount() {
    this.is_mounted = true;
    this.fetchPools().then(({ total }) => {
      if (this.is_mounted) this.setState({ totalPools: total });
      this.fetchMissingPools();
    });
  }
  componentWillUnmount() {
    this.is_mounted = false;
  }

  fetchPools(search) {
    const params = _.compactObject({ limit: LIMIT, name: search, rollups: false });
    this.setState({ loading: true });

    return PoolsSource.promise.fetchPoolsForSelector(params).then((pools) => {
      if (this.is_mounted) this.setState({ pools: pools.items, loading: false });
      return pools;
    });
  }

  // Support for allowing props.value to be set as [{value: 123}] without pool names/label, only the ID
  // To get the pool name we can check the pools in state, but that only has the first page of pools,
  // This fetches the labels for any pools where the label is missing in the first page.
  fetchMissingPools() {
    const pool_index = _.indexBy(this.state.pools, "id");
    const missing_pools = _.compactMap(this.props.value, (val) => {
      if (!pool_index[val.value]) {
        return _.toNumber(val.value);
      }
      return null;
    });

    if (!_.isEmpty(missing_pools)) {
      PoolsSource.promise.bulkFetch(missing_pools).then((pools) => {
        if (this.is_mounted) this.setState({ missingPools: _.indexBy(pools, "id") });
      });
    }
  }

  handleSearch = _.debounce((val) => {
    this.fetchPools(val);
  }, 300);

  render() {
    const { pools, missingPools } = this.state;
    const sorted_pools = _.sortBy(pools, (p) => p.name.toLowerCase());

    const options = sorted_pools.map((pool) => ({ value: pool.id, label: pool.name }));

    // Reformat props.value to look up the pool name from queried data
    // to support if the value is passed in only with the ID.
    const value = _.map(this.props.value, (val) => {
      let full_value = _.findWhere(options, { value: _.toNumber(val.value) });
      if (!full_value) {
        const missing_value = missingPools[val.value];
        full_value = missing_value ? { label: missing_value.name, value: missing_value.id } : null;
      }
      return full_value || val;
    });

    const dropdown_props = {
      options: this.props.allowAll ? [{ items: options }] : options,
      loading: this.state.loading,
      value,
      onChange: this.props.onChange,
      onSearch: this.state.totalPools > LIMIT ? this.handleSearch : null,
      multiple: true,
      searchable: true,
      allowUnselectAll: true,
      allowChangeAll: true,
    };

    return (
      <div style={{ width: 275 }}>
        {this.props.allowAll ? (
          <LabelSelector {...dropdown_props} default_label="All Pools" />
        ) : (
          <AdvancedCombobox {...dropdown_props} />
        )}
      </div>
    );
  }
}

export default VolunteerPoolDropdown;
