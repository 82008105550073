import EverTrue from "app";
import BaseRouter from "base/base-router";
import ClarityController from "./controllers/clarity-controller";

class ClarityRouter extends BaseRouter {
  static initClass() {
    this.prototype.routes = {
      "clarity(/)": "index",
    };
  }

  index() {
    EverTrue.execute("setBrowserTitle", "Clarity");
    this.currentView = <ClarityController />;
    EverTrue.page.mount(this.currentView);
  }
}

ClarityRouter.initClass();
export default ClarityRouter;
