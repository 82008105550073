module.exports = do ->
   _ = require("underscore").default
   {createComponent} = require("@evertrue/et-flux")
   React = require("react")
   {any, func, string, bool} = ReactLibs.PropTypes
   FacebookPagesStore = require("apps/facebook/stores/facebook-pages-store")
   FacebookSource = require("apps/facebook/sources/facebook-source")
   ComboboxWithImages = require("components/forms/combobox-with-images")

   createComponent "FacebookPagesDropdown",
      propTypes:
         value: any
         onChange: func
         placeholder: string
         multiple: bool

      getDefaultProps: ->
         value: undefined
         multiple: true
         placeholder: "Select Options..."

      registerStores: ->
         @on FacebookPagesStore, ->
            pages: FacebookPagesStore.getPages()
            loading: FacebookPagesStore.getLoading()

      UNSAFE_componentWillMount: ->
         FacebookSource.fetchPages()

      render: ->
         pages = _.map @state.pages, (p) ->
            {value: p.remote_id, label: p.page_name, icon: p.picture_url}
         pages = _.reject pages, (p) -> !p.label
         pages = _.sortBy(pages, "label")

         # The value can get passed in without the page name (which is an extra query)
         # this makes sure the results render the name from just having the id as value
         value = _.compact _.map @props.value, (page) ->
            _.clone _.findWhere pages, {value: page.value}

         ComboboxWithImages
            options: pages
            value: value
            multiple: @props.multiple
            placeholder: @props.placeholder
            loading: @state.loading
            onChange: @props.onChange
            searchable: true
            allowSelectAll: true
