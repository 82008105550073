import { useState, useEffect, useContext } from "react";
import _ from "underscore";
import EverTrue from "app";
import PropTypes from "prop-types";
import Api from "entities/helpers/api";
import { removeSolicitorFromTeam } from "apps/portfolio-performance/portfolio-requests";
import { ModalBody, ModalHeaderWithActions, AdvancedCombobox } from "@evertrue/et-components";
import { GlobalPortfolioContext } from "../contexts/global-portfolio-context";

const RemoveSolicitorModal = ({ refreshData, solicitor, close }) => {
  const { assignmentType } = useContext(GlobalPortfolioContext);
  const [selectedTeam, setSelectedTeam] = useState({});
  const [teamOptions, setTeamOptions] = useState([]);

  // get teams
  useEffect(() => {
    Api.VOLUNTEER.MEMBERSHIP.get({
      urlArgs: {
        contact_id: solicitor.id,
        type: "solicitor",
      },
      params: { pool_type: assignmentType },
    }).then((assignments) => {
      const assignments_by_pool = _.groupBy(assignments, "pool_id");
      const teams = _.map(assignments_by_pool, (pool_assignments, pool_id) => {
        const assignment = pool_assignments[0] || {};
        return { value: pool_id, label: assignment.pool_name };
      });
      setTeamOptions(teams);
    });
  }, [assignmentType, solicitor.id]);

  return (
    <>
      <ModalHeaderWithActions
        title={`Remove ${solicitor.name}`}
        closeModal={close}
        disableSubmit={!selectedTeam.value}
        onSubmit={() => {
          removeSolicitorFromTeam(solicitor.id, selectedTeam.value)
            .then((resp) => {
              close();
              EverTrue.Alert.success("Solicitor removed");
              setTimeout(refreshData, 1000);
            })
            .catch(() => {
              close();
              EverTrue.Alert.error("There was a problem removing this solicitor");
            });
        }}
      />
      <ModalBody scrollable={false}>
        <div className="new-solicitor-modal--text">Select a team to remove the solicitor from</div>
        <AdvancedCombobox
          className="new-solicitor-modal--dropdown"
          options={teamOptions}
          value={selectedTeam}
          onChange={(team) => {
            setSelectedTeam(team);
          }}
          multiple={false}
          searchable={false}
        />
        {!!selectedTeam.value && (
          <div className="text-danger">
            You are attempting to remove <strong>{solicitor.name}</strong> from {selectedTeam.label}.
            <strong className="remove-solicitor-modal--text">
              {" "}
              Any prospects assigned to this solicitor will be unassigned.
            </strong>
            <div>Do you want to remove?</div>
          </div>
        )}
      </ModalBody>
    </>
  );
};

RemoveSolicitorModal.propTypes = {
  solicitor: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  refreshData: PropTypes.func,
};

export default RemoveSolicitorModal;
