module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   EverTrue = require("app")
   {createStore} = require("@evertrue/et-flux")
   Filters = require("entities/search/filters")
   FeaturedSegmentsConfig = require("apps/filters/featured-segments-config")
   ListSource = require("apps/lists/sources/list-source")
   UrlSource = require("apps/layout/sources/url-source")
   SubscriptionPrefSource = require("apps/filters/sources/subscription-pref-source")
   FilterConfigStore = require("apps/filters/stores/filter-config-store")
   FilterSource = require("apps/filters/sources/filter-source")
   FilterParser = require("apps/filters/utils/filter-parser-utils")
   SegmentSource = require("apps/filters/sources/segment-source")
   ContactSource = require("apps/contact/sources/contact-source")

   NUM_SEGMENTS = 4

   createStore "SegmentStore",
      getInitialState: ->
         segments: []
         selected_segment: undefined
         featured_segments: FeaturedSegmentsConfig
         viewed_segment_ids: []
         has_loaded_recent: false

      firstListenerDidMount: ->
         SubscriptionPrefSource.fetch()

      registerActions: ->
         @on ListSource.actions.fetchedLists, @respondToFetchedLists
         @on ListSource.actions.updatedList, @respondToSavedList
         @on ListSource.actions.createdList, @respondToSavedList
         @on ListSource.actions.deletedList, @respondToDeletedList

         @on ListSource.actions.fetchedViewedLists, @respondToViewed

         @on SegmentSource.actions.applySegment, @respondToApply

         @on UrlSource.actions.changedSegment, @respondToChangedSegment
         @on UrlSource.actions.initializedUrl, (url) =>
            selected = if url?.selected_segment then parseFloat(url.selected_segment) else undefined
            @setReferrer(selected)
            @setState {selected_segment: selected}

      respondToFetchedLists: (lists) ->
         # Filter dynamic lists & add lists to segment options
         dynamic_lists = _.filter lists.items, (li) ->
            li.type == "dynamic" && !_.isString(li.criteria)
         @setState {segments: dynamic_lists}

         # Update Filters if selected segment is set
         selected_segment = @getSelectedSegment()
         if selected_segment
            FilterSource.set(@getSegmentFilters())

      respondToSavedList: (list) ->
         return unless list.type == "dynamic"

         # list.temp_id is set for optimistic updates
         lists = @getState("segments")
         updated = _.findWhere lists, {id: list.temp_id || list.id}

         if updated then _.extend updated, list, temp_id: undefined
         else lists.push(list)

         @setState {segments: lists}

         if list.temp_id
            UrlSource.updateSegment(list.id)
            EverTrue.Alert.success "Successfully saved search criteria as '#{list.name}'"

      respondToDeletedList: (id) ->
         lists = @getState("segments")
         deleted_list = _.findWhere lists, {id: id}
         return unless deleted_list?.type == "dynamic"

         @setState {segments: _.without(lists, deleted_list)}
         UrlSource.updateSegment()
         FilterSource.set()

         EverTrue.track.set "search_action", {type: "delete"}

      respondToViewed: (viewed) ->
         @setState
            viewed_segment_ids: viewed
            has_loaded_recent: true
         @saveRecentlyViewed(@getState("selected_segment"))

      respondToChangedSegment: (seg_id) ->
         @setState {selected_segment: seg_id}
         @saveRecentlyViewed(seg_id)
         @setReferrer(seg_id)

      respondToApply: (segment_id_or_obj) ->
         EverTrue.track.set "search_action", {type: "view"}
         if _.isObject(segment_id_or_obj) then segment_obj = segment_id_or_obj
         else segment_obj = @getById(segment_id_or_obj)
         @applySegmentToSearch(segment_obj)

      applySegmentToSearch: (segment_obj) ->
         return unless segment_obj


         if _.isArray(segment_obj.criteria?.sort)
            UrlSource.updateSort(_.first(segment_obj.criteria.sort), true)

         FilterSource.updateOperator(segment_obj.operator) if segment_obj.operator
         FilterSource.set(segment_obj.filters)

         if _.findWhere segment_obj.filters, {filter_id: "0.0"}
            EverTrue.Navigator("/contact/map", true)
         else
            EverTrue.Navigator("/contact", true)

         UrlSource.updateSegment(segment_obj?.id) if segment_obj?.id
         ContactSource.query()

      saveRecentlyViewed: (seg_id) ->
         # has_loaded_recent checks if the request to DNA for settings as been made
         # so that we don't update the settings before we've recieved them
         if seg_id && @getState("has_loaded_recent")
            viewed = _.clone(@getState("viewed_segment_ids")) || {}
            viewed.segments?= []
            viewed.segments.unshift(parseFloat(seg_id))
            viewed.segments = _.first(_.uniq(_.compact(viewed.segments)), 5)
            ListSource.saveView(viewed)

      parseLegacyFilters: (list) ->
         return @parseListFilters(list) if list.filters
         filters = new Filters()
         filters.parse(list.criteria, list.alias, {disableSort: true})
         filters.parseLegacy(FilterConfigStore.getConfig())

      parseListFilters: (list) ->
         _.map list.filters, (filter, index) ->
            {filter_id: filter.id, value: filter.value, filter_row_id: index}

      setReferrer: (seg_id) ->
         if seg_id
            segment = @getById(seg_id)
            EverTrue.track.setReferrer(segment?.type) if segment


      api:
         getAllSegments: ->
            segments = _.map @getSegments(), (seg) ->
               {label: seg.name, value: seg.id, type: "saved_search"}

            _.each @getFeaturedSegments(), (group) ->
               _.each group.segments, (seg) ->
                  segments.push {label: seg.title, value: seg.id, type: "featured_search"}
            segments

         getSegments: ->
            @getState("segments")

         getFeaturedSegments: ->
            @getState("featured_segments")

         getSegment: (id) ->
            segment = _.findWhere @getState("segments"), {id: id}
            if segment
               seg = $.extend true, {}, segment
               seg.filters = @parseLegacyFilters(segment)
               seg.operator = FilterParser.parseSearchOperator(segment.criteria, segment.filters)
               seg.type = "saved_search"
            seg

         getSegmentByTagId: (tag_id) ->
            segment = _.findWhere @getState("segments"), {tag_id: tag_id}
            if segment
               seg = $.extend true, {}, segment
               seg.filters = @parseLegacyFilters(segment)
               seg.operator = FilterParser.parseSearchOperator(segment.criteria, segment.filters)
               seg.type = "saved_search"
            seg

         getFeaturedSegment: (id) ->
            segments = _.flatten _.pluck(@getFeaturedSegments(), "segments")
            segment = _.findWhere segments, {id: id}
            if segment
               seg = $.extend true, {}, segment
               seg.name = segment.title
               seg.type = "featured_segment"
               seg.operator = "and"
               seg.filters = new Filters(segment.filters)?.parseLegacy(FilterConfigStore.getConfig())
            seg

         getById: (id) ->
            @getSegment(id) || @getFeaturedSegment(id)

         getSelectedSegment: ->
            selected = @getState("selected_segment")
            @getById(selected) if selected

         getSegmentFilters: (id) ->
            id ?= @getState("selected_segment")
            selected_segment = @getById(id)
            selected_segment?.filters

         getRecentSegments: ->
            recent = _.uniq @getState("viewed_segment_ids")?.segments
            all_segments = @getAllSegments()

            if !_.isEmpty(recent)
               ordered = _.compact _.map recent, (id) ->
                  _.findWhere all_segments, {value: id}

               if ordered.length < NUM_SEGMENTS
                  remaining = _.filter all_segments, (seg) -> !_.contains recent, seg.value
                  remaining = _.sortBy remaining, "label"
                  remaining = _.first(remaining, NUM_SEGMENTS - ordered.length)
                  ordered = _.compact ordered.concat(remaining)
               ordered
            else
               ordered = _.sortBy all_segments, "label"
