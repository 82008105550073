import { useState } from "react";

const useFileUploader = () => {
  const [files, setFiles] = useState([]);

  const removeFile = (id) => {
    setFiles(files.filter((f) => f.id !== id));
  };

  return { files, setFiles, removeFile };
};

export default useFileUploader;
