module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   {input} = ReactLibs.DOMFactories
   {string, func} = ReactLibs.PropTypes
   {createComponent} = require("@evertrue/et-flux")
   classSet = require("classnames")

   createComponent "CodeInput",
      propTypes:
         onChange: func # arguments: (mfaCode, isValid)
         onEnter: func # no arguments
         className: string

      getInitialState: ->
         otp_code: ""

      componentDidMount: ->
         $(@refs.input).focus()

      handleKeyPress: (event) ->
         if event.key is "Enter" then @props.onEnter?()

      handleChange: (evnt) ->
         otp_code = (evnt.target.value || "").replace(/\D/g,"").slice(0, 6)
         @setState otp_code: otp_code
         @props.onChange?(otp_code, _.size(otp_code) is 6)

      render: ->
         input
            ref: "input"
            className: classSet "code-input", @props.className
            value: @state.otp_code
            type: "text"
            onChange: @handleChange
            onKeyPress: @handleKeyPress
