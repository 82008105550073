module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   SelectedContactsSource = require("apps/contact/sources/selected-contacts-source")
   SelectedContactsStore = require("apps/contact/stores/selected-contacts-store")
   {createFactory} = require("base/new-utils")
   Checkbox = createFactory(require("@evertrue/et-components").Checkbox)

   createComponent "ProspectGroupCheckbox",
      propTypes:
         group_ids: ReactLibs.PropTypes.array

      registerStores: ->
         @on SelectedContactsStore, ->
            isChecked: SelectedContactsStore.isGroupSelected(@props.group_ids)
            all_selected_contacts: SelectedContactsStore.getSelectedContacts()

      handleClick: (isChecked, evnt) ->
         if !isChecked then SelectedContactsSource.unselect(@props.group_ids)
         else SelectedContactsSource.select(@props.group_ids)

      render: ->
         selected = _.filter @props.group_ids, (id) =>
            _.contains(@state.all_selected_contacts, id)
         selected_size = selected.length

         Checkbox
            className: @props.className
            checked: selected_size > 0
            partial: selected_size > 0 && selected_size < @props.group_ids?.length
            onChange: @handleClick
