import EverTrue from "app";
import { Button, Icon } from "@evertrue/et-components";
import { PropTypes } from "base/react-libs";

const handleNavigation = (showMap, referrer, id) => {
  if (!showMap) return id ? `${referrer}/map/${id}` : `${referrer}/map`;
  return id ? `${referrer}/${id}` : referrer;
};

const MapToggle = ({ showMap, referrer, id }) => {
  return (
    <div className="contact-table--map-toggle">
      <Button type="simple" onClick={() => EverTrue.Navigator(handleNavigation(showMap, referrer, id))}>
        <Icon icon={showMap ? "table-large" : "map"}></Icon>
        {showMap ? "Table" : "Map"}
      </Button>
    </div>
  );
};

MapToggle.propTypes = {
  showMap: PropTypes.bool,
  referrer: PropTypes.string,
  id: PropTypes.string,
};

export default MapToggle;
