import { PropTypes } from "base/react-libs";
import { ThreeDotActionsMenu } from "@evertrue/et-components";
import { useContactModalContext } from "apps/profile/contexts/contact-modal-context";
import { useGlobalOutreachContext } from "components/emailer/email-modal-context";
import Api from "entities/helpers/api";
import EverTrue from "app";
import featureStore from "apps/layout/stores/feature-store";
import GiftRowDetails from "../row-details/gift-row-details";
import AddToJourneyModal from "apps/journeys/components/modals/add-to-journey-modal";
import CompleteTaskToggle from "apps/tasks/components/complete-task-toggle";
import CareerMoveRowDetails from "../row-details/career-move-row-details";
import JourneyContactCard from "apps/journeys/components/journey-contact-card";

const RecommendedActionRow = ({
  recommendation = {},
  type = "gift",
  first = false,
  subtitle,
  emailButtonLabel,
  handleViewClick,
}) => {
  const { contact, id } = recommendation;
  const { active_journey = {} } = contact;
  const { setEmailDrawerIsOpen } = useGlobalOutreachContext();
  const { setContactModalIsOpen } = useContactModalContext();

  const hasAI = featureStore.hasFeature("ai_summary");

  const sayThanksAction = {
    id: 1,
    onClick: () => {
      if (hasAI) {
        setContactModalIsOpen(true);
      } else {
        setEmailDrawerIsOpen(true);
      }
      EverTrue.track.set("home_screen", { type: "recommended_action" });
      EverTrue.Navigator(`/contact/${contact.id}`, true);
    },
    label: emailButtonLabel,
    icon: "email",
    iconKit: "gt",
  };

  const moveToCadenceAction = {
    id: 2,
    mountOnClick: ({ is_open, close }) => {
      EverTrue.track.set("home_screen", { type: "recommended_action" });
      return (
        <AddToJourneyModal
          contact={contact}
          onClose={close}
          isOpen={is_open}
          activeJourneys={[active_journey]}
          recommendedAction={true}
        />
      );
    },
    label: "Add to Cadences",
    icon: "cadences",
    iconKit: "gt2",
  };

  const handleCompletionClick = async () => {
    EverTrue.track.set("home_screen", { type: "recommended_action" });
    handleViewClick(recommendation);

    await Api.JOURNEYS.RECOMMENDED_ACTIONS_VIEW.put({
      urlArgs: { id: id },
    });
  };

  return (
    <div className="recommended-action-row--container">
      <div className="recommended-action-row--contact">
        <CompleteTaskToggle task={{ completed_at: false }} size="small" onClick={handleCompletionClick} />
        <JourneyContactCard contact={contact} />
      </div>
      {type === "gift" && <GiftRowDetails giving={contact.giving || {}} first={first} subtitle={subtitle} />}
      {type === "career" && <CareerMoveRowDetails contact={contact} />}
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <ThreeDotActionsMenu options={[sayThanksAction, moveToCadenceAction]} iconSize={1} />
      </div>
    </div>
  );
};

RecommendedActionRow.propTypes = {
  recommendation: PropTypes.object,
  type: PropTypes.string,
  first: PropTypes.bool,
  subtitle: PropTypes.string,
  emailButtonLabel: PropTypes.string,
  handleViewClick: PropTypes.func,
};

export default RecommendedActionRow;
