module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")
   DNA = require("entities/dna/dna")


   createSource "DNASource",
      actions:
         fetched: true

      api:
         fetchAll: ->
            Api.DNA.ALL.get
               disableAlerts: true
               success: (resp) =>
                  dna = new DNA()
                  dna.set(dna.parse(resp))
                  EverTrue.store.push "dna", dna, {cache: true}
                  @actions.fetched(resp)
               error: (model, xhr, textStatus) ->
                  Raven?.captureMessage("DNA Error", {data: xhr?.responseText})

