import PropTypes from "prop-types";
import { ButtonTooltip, Icon } from "@evertrue/et-components";
import classNames from "classnames";
import { helpText, getText } from "entities/helpers/et-help-helper";

const DEFAULT_HELPTIP_WIDTH = 300;

const Helptip = ({ className, help_key, iconSize, body }) => {
  const help_text = getText(help_key);
  const help_content = helpText[help_key] || {};
  const width = help_content.width || DEFAULT_HELPTIP_WIDTH;

  return (
    <ButtonTooltip
      // temp b/c if arrow is open the tooltip only positions itself on the bottom.
      // we should fix in et-comps
      disableArrow={true}
      title="View help information"
      // title = " " to avoid proptype warning and not show title. don't want a title and a tooltip at same time
      trigger={<Icon size={iconSize} className="helptip-item--trigger" icon="question-circle" disableTitle={true} />}
      contentWidth={width}
      wrapperClassName={classNames("helptip-item", className)}
      headerClassName="helptip-item--header"
      header={help_text.header}
    >
      {help_text.body || body}
    </ButtonTooltip>
  );
};

Helptip.propTypes = {
  help_key: PropTypes.string.isRequired,
  className: PropTypes.string,
  iconSize: PropTypes.number,
  body: PropTypes.string,
};

export default Helptip;
