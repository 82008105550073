module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   EverTrue = require("app")
   Contact = require("entities/contacts/contact")
   AuthApi = require("entities/auth/auth-api")
   PaginatedCollectionMixin = require("mixins/pagination-mixin")
   SortedCollectionMixin = require("mixins/sorted-mixin")
   SearchQueryCollectionMixin = require("mixins/search-query-mixin")
   PropertyStore = require("apps/layout/stores/property-store")


   class Contacts extends AuthApi.Collection
      @include [PaginatedCollectionMixin, SortedCollectionMixin, SearchQueryCollectionMixin]

      urlRoot: "contacts/v2/search"
      model: Contact

      parse: (data) ->
         @boundaries(data)
         data?.items

      # This method uses mixins to add additional functionality to
      # the requests options for sorting, paging and querying.
      fetch: (options={}) ->
         opts = _.clone(options)
         opts.params = $.extend true, {load_identities: true}, @paginate(options.params || {})
         opts.data = @doSort(opts.data)
         opts = @queryOptions(opts)
         super opts

      fetchBulk: (ids, options={}) ->
         return if _.isEmpty(ids)
         @fetch $.extend true, {}, @bulkOptions(ids), options

      bulkOptions: (ids) ->
         $.extend true, {},
            query: must: [{id: in: ids}]

      resetAndParse: (array) ->
         items = _.map array, (data) =>
            model = new @model()
            model.set model.parse(data), silent: true
         @reset items

      setBaseSort: ->
         if EverTrue.store.org.hasFeature("scores")
            if PropertyStore.hasReadAccessTo("score.score")
               return if @sortProp == "score.score"
               @baseSort = []
               sort_param_query = {}
               sort_param_query["score.score"] = {order: "desc"}
               @baseSort.push(sort_param_query)
