import { PropTypes } from "base/react-libs";
import { IconButton, LinkButton } from "@evertrue/et-components";
import EnrichmentConfidenceScore from "./enrichment-confidence-score";

const BioItem = ({ editMode, item, itemType, mutationFunc}) => {

const normalizedLocation = ({city, state_code, metro_area, country}) => {
  if (city && state_code) return `${city}, ${state_code}`;
  else if (metro_area) return metro_area;
  else if (country) return country;
  else return null;
};

return (
  <div key={item.id} className="bio-info--item">
    {itemType === "name" ? item.name_full : normalizedLocation(item)}
    {editMode === "delete" && (
      <IconButton
        title={`remove ${itemType} record from constituent`}
        icon="clear"
        className="profile--enrichment-delete-button"
        onClick={() => mutationFunc(item, true, itemType.concat("s"))}
      />
    )}
    {editMode === "restore" && (
      <LinkButton
        className="profile--enrichment-undo-button"
        onClick={() => mutationFunc(item, false, itemType.concat("s"))}
        title=""
      >
        Undo
      </LinkButton>
    )}
    <div className="bio-info--confidence-score">
      {"Confidence Score: "}
      <EnrichmentConfidenceScore
        score={item.confidence_score}
        source={item.source}
      />
    </div>
  </div>
  );
}

BioItem.propTypes = {
  editMode: PropTypes.string,
  item: PropTypes.oneOfType([
    PropTypes.shape({
        id: PropTypes.number.isRequired,
        name_full: PropTypes.string,
        source: PropTypes.string,
        confidence_score: PropTypes.number,
      }),
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        city: PropTypes.string,
        state_code: PropTypes.string,
        metro_area: PropTypes.string,
        country: PropTypes.string,
        source: PropTypes.string,
        confidence_score: PropTypes.number,
      }),
  ]),
  itemType: PropTypes.oneOf(["name", "location"]).isRequired,
  mutationFunc: PropTypes.func,
};
export default BioItem