import { useState } from "react";
import LinkHelper from "entities/helpers/link-helper";

const LIMITER = "CHUNK_TERMINATOR";

/**
 * This component can be used for all the AI related streaming API calls.
 * It uses XMLHttpRequest for API calls
 */
const useDataStream = () => {
  const [isStreaming, setIsStreaming] = useState(false);
  const [response, setResponse] = useState("");
  const [metadata, setMetaData] = useState({});

  let previousChunkLength = 0;
  let responseVersion = 2;

  const openDataStream = (options) => {
    setIsStreaming(true);
    setResponse("");
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.responseType = "text";

      xhr.onreadystatechange = (e) => {
        const isCached = xhr.getResponseHeader("et-cached-response");
        if (xhr.readyState === 3) {
          if (isCached) {
            // Ignore the stream logic for cached responses
            // Parsing a cached response will break the streaming logic
          } else {
            const fragment = processChunk(xhr.responseText);
            setResponse(fragment);
          }
        } else if (xhr.readyState === 4) {
          // Check again for cached response and parse if needed
          if (isCached) {
            const fragment = processChunk(xhr.responseText);
            setResponse(fragment);
          } else {
            const fragment = processChunk(xhr.responseText);
            // when xhr reaches readystate 4 at times the xhr.responseText is null
            if (fragment) {
              setResponse(fragment);
            }
          }
          if (xhr.status === 200) {
            setIsStreaming(false);
            resolve(xhr.response);
          } else {
            reject(new Error(`Request failed with status ${xhr.status}`));
          }
        }
      };
      const { method, endpoint, params, headers, data } = options;
      const full_url = LinkHelper.formatLink(endpoint, { params });

      if (options.params && options.params["ET-Response-Version"]) {
        responseVersion = parseInt(options.params["ET-Response-Version"], 10);
      } else if (options.headers && options.headers["ET-Response-Version"]) {
        responseVersion = parseInt(options.headers["ET-Response-Version"], 10);
      }

      xhr.open(method, full_url);
      for (let key in headers) {
        xhr.setRequestHeader(key, headers[key]);
      }
      xhr.withCredentials = true;
      xhr.send(data);
    });
  };
  /**
   * Processes a raw chunk of data from an HTTP request and returns an array of data chunks..
   * @param {string} rawHTML
   * @template {string} dataChunk
   * @returns {array<dataChunk>} dataChunks
   */
  function processChunk(rawHTML) {
    const rawChunk = formatChunk(rawHTML);
    return parseChunk(rawChunk);
  }

  /**
   * onreadystatechange always returns the full stream:
   * This finds the delta between HTTP streamed chunks
   * @param {string} rawHTML
   * @returns {string} rawChunk
   */
  function formatChunk(rawHTML) {
    const rawChunk = rawHTML.slice(previousChunkLength);
    previousChunkLength = rawHTML.length;
    return rawChunk;
  }

  /**
   * Parses a chunk of a request by removing the limiter and returning an array of data chunks
   * @param {string} chunk
   * @template {string} dataChunk
   * @returns {array<dataChunk>} dataChunks
   */
  function parseChunk(chunk) {
    const dataChunks = [];
    const segments = chunk.split(LIMITER);
    for (let segment of segments) {
      if (segment.length !== 0) {
        try {
          const { data, event, metadata = {} } = JSON.parse(segment);
          if (event !== "DONE") {
            if (Object.keys(metadata).length > 0) {
              setMetaData(metadata);
            }
            if (responseVersion === 3) {
              dataChunks.push(data.text);
            } else {
              dataChunks.push(data.response);
            }
          }
        } catch (e) {
          console.error("Streaming Parse Error", e);
        }
      }
    }
    const fullChunk = dataChunks.join("");
    return fullChunk;
  }

  return {
    openDataStream,
    isStreaming,
    response,
    metadata,
  };
};

export default useDataStream;
