import PropTypes from "prop-types";

const TaskNotificationBadge = ({ count }) => {
  const determineClassnames = () => {
    if (count > 99) return "task-notification task-notification--dynamic";
    return "task-notification";
  };

  return count > 0 ? (
    <div style={{ display: "inline-block" }}>
      <div className={determineClassnames()}>{count}</div>
    </div>
  ) : null;
};

TaskNotificationBadge.propTypes = {
  count: PropTypes.number,
};

export default TaskNotificationBadge;
