import { useState, useEffect, useContext, useMemo } from "react";
import EverTrue from "app";
import Api from "entities/helpers/api";
import Decorator from "@evertrue/client-decorator";
import { GlobalContactSearchContext } from "apps/layout/controllers/global-contact-search-context";
import { getQuery } from "apps/search/utils/search-helper";

const LIMIT = 50;

const useFetchContacts = (dsl, tableSettings, dynamicListId) => {
  const [contactsData, setContactsData] = useState({ items: [] });
  const [loading, setLoading] = useState(true);
  const [resultCount, setResultCount] = useState(0);
  const offset = (tableSettings.page - 1) * LIMIT;
  const { mapCoordinates, mapFilters, radius, mapSettings } = useContext(GlobalContactSearchContext);

  const payload = useMemo(() => {
    return getQuery(dsl, mapCoordinates, mapFilters, radius);
  }, [dsl, mapCoordinates, mapFilters, radius]);

  useEffect(() => {
    const fetchContactIds = async () => {
      const params = {
        offset,
        limit: LIMIT,
        sort: tableSettings.sortProp,
        sort_order: tableSettings.sortReverse ? "desc" : "asc",
      };
      if (tableSettings.sortProp === "maps.distance") {
        params.sort_field_params = JSON.stringify({ lat: mapSettings.center.lat, lon: mapSettings.center.lng });
      }
      return await Api.HUB.FILTERS_SEARCH_CONTACTS.post({
        disableAlerts: true,
        params,
        data: JSON.stringify(payload),
      });
    };

    const mapDateAddedToContacts = async (contactIds, contactData) => {
      const contactsDateAdded = await Api.HUB.BULK.get({
        urlArgs: { id: dynamicListId },
        params: { contact_ids: contactIds.items },
      });

      contactData.items.forEach((contact) => {
        const contactDateAdded = contactsDateAdded.find((elem) => elem.contact_id === contact.id);
        contact.date_added = contactDateAdded.updated_at;
      });
    };

    let isSubscribed = true;
    if (payload) {
      const loadContacts = async () => {
        setLoading(true);

        try {
          const contactIds = await fetchContactIds();

          const contactData = await Api.CONTACTS.BULK.get({
            params: { id: contactIds.items },
          });

          if (dynamicListId) {
            await mapDateAddedToContacts(contactIds, contactData);
          }

          if (isSubscribed) {
            setResultCount(contactIds.total);

            setContactsData(
              Object.assign({}, contactData, {
                page: tableSettings.page,
                sortProp: tableSettings.sortProp,
                sortReverse: tableSettings.sortReverse,
                items: contactData.items.map(Decorator.Contacts.parse),
              })
            );
            setLoading(false);
          }
        } catch (e) {
          EverTrue.Alert.error("Error fetching contacts, please try again");
          setLoading(false);
        }
      };
      loadContacts();
    }
    return () => {
      isSubscribed = false;
    };
  }, [
    tableSettings.page,
    tableSettings.sortReverse,
    tableSettings.sortProp,
    offset,
    dynamicListId,
    payload,
    mapSettings,
  ]);

  return {
    contactsData,
    loading,
    offset,
    LIMIT,
    resultCount,
  };
};

export default useFetchContacts;
