import { useState, useEffect, useMemo } from "react";
import {
  getUserSettings,
  createUserSettings,
  updateUserSettings,
} from "apps/home/utils/api-requests/recommended-actions/use-recommended-actions-user-settings-requests";

const useRecommendedActionsUserSettings = () => {
  const [updateId, setUpdateId] = useState();
  const [enableGiftSection, setEnableGiftSection] = useState(true);
  const [enableBirthdaySection, setEnableBirthdaySection] = useState(true);
  const [enableAnniversarySection, setEnableAnniversarySection] = useState(true);
  const [enableCareerMovesSection, setEnableCareerMovesSection] = useState(true);
  const [giftAmount, setGiftAmount] = useState(0);

  const payload = useMemo(() => {
    return {
      min_gift_amount: giftAmount,
      enable_birthday: enableBirthdaySection,
      enable_gift_anniversary: enableAnniversarySection,
      enable_recent_gift: enableGiftSection,
      enable_career_moves: enableCareerMovesSection,
    };
  }, [giftAmount, enableBirthdaySection, enableAnniversarySection, enableGiftSection, enableCareerMovesSection]);

  const submitUserSettings = async () => {
    if (updateId) return await updateUserSettings(payload, updateId);
    else {
      const newSettings = await createUserSettings(payload);
      setUpdateId(newSettings.id);
      return newSettings;
    }
  };

  useEffect(() => {
    const getAndSetUserSettings = async () => {
      const existingUserSettings = await getUserSettings();

      if (existingUserSettings && Object.entries(existingUserSettings).length) {
        const {
          enable_birthday,
          enable_gift_anniversary,
          enable_recent_gift,
          enable_career_moves,
          min_gift_amount,
          id,
        } = existingUserSettings;
        setEnableGiftSection(enable_recent_gift);
        setEnableBirthdaySection(enable_birthday);
        setEnableAnniversarySection(enable_gift_anniversary);
        setEnableCareerMovesSection(enable_career_moves);
        setGiftAmount(min_gift_amount);
        setUpdateId(id);
      }
    };

    getAndSetUserSettings();
  }, []);

  return {
    submitUserSettings,

    enableGiftSection,
    enableBirthdaySection,
    enableAnniversarySection,
    enableCareerMovesSection,
    giftAmount,
    setUpdateId,
    setEnableGiftSection,
    setEnableBirthdaySection,
    setEnableAnniversarySection,
    setEnableCareerMovesSection,
    setGiftAmount,
  };
};

export default useRecommendedActionsUserSettings;
