import _ from "underscore";
import { Component } from "react";
import PropTypes from "prop-types";
import numeral from "numeral";
import SelectedContactsAddTo from "apps/contact/components/contacts/table/selected-contacts-addto";
import Pagination from "components/controls/pagination";
import SelectedContactsStore from "apps/contact/stores/selected-contacts-store";
import SelectedContactsSource from "apps/contact/sources/selected-contacts-source";
import { connect } from "@evertrue/et-flux";
import SelectAllIndicator from "apps/contact/components/contacts/select-all-indicator";

const mapStateToProps = () => ({
  showSelectedIndicator: SelectedContactsStore.getShowSelectedIndicator(),
  selectedContacts: SelectedContactsStore.getSelectedContacts(),
  allSelected: SelectedContactsStore.getIsAllSelected(),
});

class ContactTableToolbar extends Component {
  static propTypes = {
    contacts: PropTypes.object,
    listActionLabel: PropTypes.string,
    className: PropTypes.string,
    allowAddAll: PropTypes.bool,
    allSelected: PropTypes.bool,
    hide_pagination: PropTypes.bool,
    selectedContacts: PropTypes.array,
    totalResultsCount: PropTypes.number,
    showSelectedIndicator: PropTypes.bool,
    onPage: PropTypes.func,
    children: PropTypes.any,
    filterParams: PropTypes.object,
    hideTrips: PropTypes.bool,
    renderToolbarItems: PropTypes.func,
  };

  static defaultProps = {
    listActionLabel: "Add to List",
    onPage: _.noop,
    className: "",
    hide_pagination: false,
    contacts: {},
    allowAddAll: false,
    allSelected: false,
    showSelectedIndicator: false,
    selectedContacts: [],
    totalResultsCount: null,
    children: () => null,
    filterParams: {},
    hideTrips: false,
  };

  componentDidMount() {
    SelectedContactsSource.clearAllSelectedState();
  }

  render() {
    const {
      contacts = {},
      selectedContacts: { length: number_selected_contacts } = [],
      allSelected,
      totalResultsCount,
    } = this.props;
    const isEmpty = _.isEmpty(contacts.items);
    const contact_ids = _.pluck(contacts.items, "id");
    const formated_total_results_count = numeral(totalResultsCount).format("0,0");

    return (
      <div className={`contact-table-toolbar ${this.props.className || ""}`}>
        <div className="contact-table-toolbar--actions">
          <SelectedContactsAddTo
            hideTrips={this.props.hideTrips}
            label={this.props.listActionLabel}
            contactIds={contact_ids}
            totalResultsCount={totalResultsCount}
            filterParams={this.props.filterParams}
            allowAddAll={this.props.allowAddAll}
            renderToolbarItems={this.props.renderToolbarItems}
          />
          {!isEmpty && !this.props.hide_pagination && (
            <Pagination
              className="contact-table-toolbar--pagination"
              page={_.toNumber(contacts.page)}
              total={contacts.total / contacts.limit}
              onChange={(page) => {
                this.props.onPage(page);
                SelectedContactsSource.clearAllSelectedState();
              }}
            />
          )}
          {this.props.children}
        </div>
        <SelectAllIndicator
          showSelectedIndicator={this.props.showSelectedIndicator}
          formatedTotalResultsCount={formated_total_results_count}
          numberSelectedContacts={number_selected_contacts}
          allSelected={allSelected}
        />
      </div>
    );
  }
}

export default connect(ContactTableToolbar, [SelectedContactsStore], mapStateToProps);
