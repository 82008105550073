module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   EverTrue = require("app")
   {createComponent} = require("@evertrue/et-flux")
   {div, strong} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   AdvancedCombobox = createFactory(require("@evertrue/et-components").AdvancedCombobox)
   ModalConfirm = createFactory(require("components/modals/modal-confirm"))
   str = require("underscore.string")
   GATE_PLATFORM_OPTIONS = [
      {value: "ios", label: "Enable on iOS only"}
      {value: "web", label: "Enable on Web only"}
      {value: "ios|web", label: "Enable on iOS & Web"}
      {value: "none", label: "Disable"}
   ]

   _getPlatform = (feature) ->
      scope = if feature?.org_level?.enabled then "ios|web" else "none"
      _.findWhere(GATE_PLATFORM_OPTIONS, {value: scope})


   createComponent "AdminGateChangeModal",
      propTypes:
         feature: ReactLibs.PropTypes.object
         onSubmit: ReactLibs.PropTypes.func

      getInitialState: ->
         platform: _getPlatform(@props.feature)

      handleSubmit: ->
         @props.onSubmit(@props.feature?.key, @state.platform?.value)
         @handleCancel()

      handleCancel: ->
         @props.requestHide()

      render: ->
         ModalConfirm
            header: "Confirm Gate Change"
            buttonLabel: "Update Gate"
            onSubmit: @handleSubmit
            onCancel: @handleCancel

            div className: "admin-user-gates--confirm",
               "You are updating"
               strong null, str.titleize(str.humanize(@props.feature?.key))
               " on #{EverTrue.config.environment_data?.toLowerCase?()}, for"
               strong null, "ALL USERS in #{EverTrue.store.org.get("name")}"

               AdvancedCombobox
                  value: @state.platform
                  options: GATE_PLATFORM_OPTIONS
                  onChange: (val) =>
                     @setState {platform: val}
