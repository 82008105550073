import PropTypes from "prop-types";
import FilterValueMultiSelect from "apps/query-builder/components/filter-value-multiselect";
import FilterValueSelect from "apps/query-builder/components/filter-value-select";
import uiComponents from "apps/query-builder/utils/filter-ui-components";

const QuickFilterInputComponent = ({ filter, uiComponent, value, onChange }) => {
  switch (uiComponent) {
    case uiComponents.FilterValueMultiSelect:
      return (
        <FilterValueMultiSelect
          emptyValue={`Any ${filter.label}`}
          filterId={filter.compound_id}
          label={filter.label}
          value={value}
          onChange={onChange}
        />
      );

    case uiComponents.FilterValueSelect:
      return (
        <FilterValueSelect
          emptyValue={`Any ${filter.label}`}
          filterId={filter.compound_id}
          label={filter.label}
          value={value}
          onChange={onChange}
        />
      );

    default:
      return null;
  }
};

QuickFilterInputComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  uiComponent: PropTypes.string.isRequired,
  value: PropTypes.any,
};

export default QuickFilterInputComponent;
