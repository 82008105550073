import { Icon } from "@evertrue/et-components";

const ClarityEmpty = () => (
  <div className="clarity-empty">
    <Icon icon="broken-link" className="clarity-empty--icon" />
    <div className="clarity-empty--text">Oh no! It doesn't look like you have any clarity</div>
    <div className="clarity-empty--text">Please contact your administrator</div>
  </div>
);

export default ClarityEmpty;
