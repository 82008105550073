import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

const DynamicFieldPopup = ({
  showDynamicPopup,
  popupPosition,
  filteredFields,
  handleFieldClick,
  setShowDynamicPopup,
  dynamicPopupViaToolbar,
}) => {
  const popupRef = useRef(null);
  const [focusedIndex, setFocusedIndex] = useState(-1);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target) && event.target.id !== "dynamic-fields") {
        setShowDynamicPopup(false);
      }
    };

    const handleMouseDown = (event) => {
      handleOutsideClick(event);
    };

    const handleKeyDown = (event) => {
      switch (event.key) {
        case "ArrowDown":
          setFocusedIndex((prevIndex) => (prevIndex + 1) % filteredFields.length);
          event.preventDefault();
          break;
        case "ArrowUp":
          setFocusedIndex((prevIndex) => (prevIndex - 1 + filteredFields.length) % filteredFields.length);
          event.preventDefault();
          break;
        case "Enter":
          if (focusedIndex >= 0 && focusedIndex < filteredFields.length) {
            handleFieldClick(filteredFields[focusedIndex], true);
          } else {
            setShowDynamicPopup(false);
          }
          event.preventDefault();
          break;
        case "Escape":
          setShowDynamicPopup(false);
          event.preventDefault();
          break;
        default:
          break;
      }
    };

    if (showDynamicPopup || dynamicPopupViaToolbar) {
      document.addEventListener("mousedown", handleMouseDown);
      document.addEventListener("keydown", handleKeyDown);
    } else {
      document.removeEventListener("mousedown", handleMouseDown);
      document.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
      document.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDynamicPopup, setShowDynamicPopup, filteredFields, focusedIndex, handleFieldClick]);

  useEffect(() => {
    if (!showDynamicPopup) {
      setFocusedIndex(-1);
    }
  }, [showDynamicPopup]);

  if (!showDynamicPopup) {
    return null;
  }

  return (
    <div
      className="dynamic-popup"
      ref={popupRef}
      style={{ bottom: 350 - popupPosition.bottom, left: popupPosition.left + 5 }}
    >
      {filteredFields.map((field, index) => (
        <div
          key={field}
          style={{
            cursor: "pointer",
            padding: "5px 6px",
            backgroundColor: focusedIndex === index ? "#edebe4" : "transparent",
            borderRadius: "8px",
          }}
          onMouseDown={() => handleFieldClick(field)}
        >
          {field}
        </div>
      ))}
    </div>
  );
};

DynamicFieldPopup.propTypes = {
  showDynamicPopup: PropTypes.bool.isRequired,
  popupPosition: PropTypes.shape({
    top: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
    bottom: PropTypes.number.isRequired,
  }).isRequired,
  filteredFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleFieldClick: PropTypes.func.isRequired,
  setShowDynamicPopup: PropTypes.func.isRequired,
  dynamicPopupViaToolbar: PropTypes.bool,
};

export default DynamicFieldPopup;
