const DEFAULT_QUERY = {
  quick_filters: [],
  filters: {
    blocks: [
      {
        condition: "and_all_of",
        rules: [{}],
      },
      {
        condition: "and_all_of",
        rules: [{}],
      },
    ],
  },
};

export default DEFAULT_QUERY;
