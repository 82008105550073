module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   moment = require("moment")
   EverTrue = require("app")
   {createStore} = require("@evertrue/et-flux")
   MyTeamReportConfig = require("apps/my-team/my-team-report-config")
   MyTeamSource = require("apps/my-team/sources/my-team-source")
   MyTeamUtils = require("apps/my-team/my-team-utils")
   DNAStore = require("base/dna/dna-store").default
   DNASource = require("base/dna/dna-source")
   GateSource = require("apps/layout/sources/gate-source")
   FeatureStore = require("apps/layout/stores/feature-store").default
   OrgSource = require("base/org/org-source").default
   str = require("underscore.string")
   OPACITY = .5

   _getDefaults = ->
      loading: false
      report_data: {}
      past_report_data: {}
      date_range_key: "fiscal_year"
      interaction_types: undefined

   createStore "MyTeamStore",
      getInitialState: ->
         _getDefaults()

      registerActions: ->
         @on MyTeamSource.actions.fetchedAll, @respondToFetchAll
         @on MyTeamSource.actions.fetchedAllComparisons, @respondToFetchAllCompare
         @on MyTeamSource.actions.startFetchAll, @respondToStartFetchAll
         @on MyTeamSource.actions.changeDateRange, @respondToDateChange
         @on MyTeamSource.actions.loading, @respondToLoading
         @on MyTeamSource.actions.updateVisitTypes, @respondToVisitsUpdate
         @on MyTeamSource.actions.startExport, @respondToExport

         # To make sure reports are gated correctly
         @on GateSource.actions.fetchedGates, @respondToAppLoad

         # To make sure dna for fiscal year is returned
         @on DNASource.actions.fetched, @respondToAppLoad

         @on OrgSource.actions.newOrg, => @setState(_getDefaults())

      respondToAppLoad: ->
         if @hasListeners()
            MyTeamSource.startFetchAll()

      respondToLoading: (is_loading) ->
         @setState {loading: is_loading}

      respondToStartFetchAll: ->
         if DNAStore.isLoaded() && FeatureStore.hasFeaturesLoaded()
            {date_range, date_range_compare} = MyTeamUtils.getDateRangeParams(@getState("date_range_key"))

            available_reports = _.pluck(@getReports(), "key")
            params = _.extend {}, date_range,
               interaction_type: @getState("interaction_types")
            MyTeamSource.fetchAll(available_reports, params)

            compare_params = _.extend {}, params, date_range_compare
            compare_reports = _.without(available_reports, "prospects")
            MyTeamSource.fetchAllComparisons(compare_reports, compare_params)

      respondToFetchAll: (reports) ->
         @setState
            report_data: MyTeamUtils.getFormattedReportData(reports, @getState("report_data"))

      respondToFetchAllCompare: (reports) ->
         @setState
            past_report_data: MyTeamUtils.getFormattedReportData(reports, @getState("report_data"))

      respondToDateChange: (date_val) ->
         @setState {date_range_key: date_val}
         MyTeamSource.startFetchAll()

         EverTrue.track.set "report_action",
            type: "report_action_user"
            action: "changed date range"
            date_range: MyTeamUtils.getDateRangeParams(date_val)

      respondToVisitsUpdate: (visits) ->
         @setState {interaction_types: _.clone(visits)}
         MyTeamSource.startFetchAll()

         EverTrue.track.set "report_action",
            type: "report_action_user"
            action: "changed visit type"
            visit_types: visits

      respondToExport: (key) ->
         table_data = @getTableData(key)
         columns = _.compact(_.pluck(table_data.columns, "label"))
         rows = _.map table_data.rows, (row_obj) ->
            _.map columns, (col) -> row_obj[col] || ""
         MyTeamSource.downloadCSV(key, [columns].concat(rows))

         EverTrue.track.set "report_action",
            type: "report_action_user"
            action: "export data"
            report: "team - #{str.humanize(key).toLowerCase() || "summary"}"

      api:
         getLoading: ->
            @getState("loading")

         getDateRangeKey: ->
            @getState("date_range_key")

         getFormattedDateRange: (prop="date_range") ->
            range = MyTeamUtils.getDateRangeParams(@getState("date_range_key"))[prop]
            if range?.lte == "now" then date_to = moment().format("MMM YYYY")
            else date_to = moment(range?.lte).format("MMM YYYY")
            {from: moment(range?.gte).format("MMM YYYY"), to: date_to}

         getVisitTypes: ->
            @getState("interaction_types") || undefined

         getTitle: (key) ->
            MyTeamReportConfig[key]?.title

         getReports: ->
            _.sortBy _.compact(_.map(MyTeamReportConfig, (value, key) ->
               if !value.gated || value.gated()
                  _.extend {}, value, {key: key}
            )), "sort"

         getReportsRollup: ->
            fundraisers = MyTeamUtils.getFundraiserRollup(@getState("report_data"))
            past_fundraisers = MyTeamUtils.getFundraiserRollup(@getState("past_report_data"), "past")
            $.extend true, fundraisers, past_fundraisers

         # Since the bar charts are horizontal, we re-map the x/y fields.
         getReportData: (key) ->
            chart_data = @getState("report_data")?[key]
            if key in ["visits", "my-trips"]
               dates = _.sortBy(_.uniq(_.pluck(chart_data, "x")))
               _.map dates, (date) ->
                  x: moment(_.toNumber(date)).utc().valueOf()
                  y: _.sum chart_data, (item) ->
                     if item.x == date then item.y else 0
            else
               axis_sum = {}
               report_data = _.map chart_data, (data) ->
                  axis_sum[data.x] ?= 0
                  axis_sum[data.x] += data.y
                  _.extend {}, data,
                     x: data.y
                     y: data.x
                     axis_sum: axis_sum[data.x]
                     x_metadata: data.y_metadata
                     y_metadata: data.x_metadata
               _.sortBy(report_data, "axis_sum").reverse()

         getReportLegend: (key) ->
            chart_data = @getReportData(key)
            series_to_color = {}
            _.each chart_data, (item) ->
               series_to_color[item.series] = item.style?.fill

            _.compact _.map series_to_color, (color, series) ->
               if series && color
                  label: series
                  style:
                     background: color
                     opacity: OPACITY
                  value: series

         getTableData: (key) ->
            if key in ["visits", "my-trips"]
               chart_data = @getState("report_data")[key]
               table_data = _.map chart_data, (data) ->
                  x: data.y
                  y: data.series
                  series: moment(_.toNumber(data.x)).utc().format("MMM YYYY")
            else
               chart_data = @getReportData(key)
               table_data = chart_data

            grouped_data = _.groupBy(table_data, "y") # group by gift officer
            grouped_data = _.omit(grouped_data, "undefined")

            columns = _.map _.uniq(_.pluck(table_data, "series")), (col) -> {label: col}
            columns.unshift {label: "Solicitor", width: 176, sticky: true}

            unless key == "proposal-amount"
               columns.splice 1, 0, {label: "Total", className: "report-table--totals", sticky: true}

            rows = _.map grouped_data, (grouped_values, officer) ->
               obj = {id: officer}
               _.each grouped_values, (val) ->
                  if _.isNumber(val.x)
                     obj[val.series] ?= 0
                     obj[val.series] += val.x
               obj["Total"] = _.sum(_.omit(obj, "id"))
               obj["Solicitor"] = officer
               obj

            # Add Total Row
            total_row = _.reduce rows, (memo, obj) ->
               _.each obj, (val, key) ->
                  if _.isNumber(val)
                     memo[key] ?= 0
                     memo[key] += val if val
               memo
            , {id: "total", className: "report-table--totals"}
            total_row["Solicitor"] = "Total"
            rows.push(total_row)
            {columns: columns, rows: rows}

