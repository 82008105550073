import { Component } from "react";
import TimePicker from "components/forms/time-picker";
import classNames from "classnames";
import moment from "moment";
import { Popover2, Button } from "@evertrue/et-components";
import _ from "underscore";
import colors from "base/colors";

class TimeRange extends Component {
  static propTypes = {
    value: ReactLibs.PropTypes.object,
    timeFormat: ReactLibs.PropTypes.string,
    className: ReactLibs.PropTypes.string,
    onChange: ReactLibs.PropTypes.func.isRequired,
  };

  static defaultProps = {
    timeFormat: "hh:mm a",
  };

  handleChange = (key, moment_value) => {
    const value_object = _.extend({}, this.props.value);
    value_object[key] = moment_value.format(this.props.timeFormat);
    this.props.onChange(value_object);
  };

  render() {
    const { value } = this.props;
    const min = moment(value.gte, this.props.timeFormat);
    const max = moment(value.lte, this.props.timeFormat);

    return (
      <div className={classNames("time-range", this.props.className)}>
        <Popover2
          borderColor={colors.blue300}
          trigger={({ openPopover }) => {
            return (
              <Button type="simple" className="time-range--input" onClick={openPopover}>
                {min.format(this.props.timeFormat)}
              </Button>
            );
          }}
        >
          {({ closePopover }) => (
            <TimePicker
              value={min}
              onChange={(val) => {
                this.handleChange("gte", val);
              }}
              onClose={closePopover}
            />
          )}
        </Popover2>

        <span className="time-range--separator" />

        <Popover2
          borderColor={colors.blue300}
          trigger={({ openPopover }) => {
            return (
              <Button type="simple" className="time-range--input" onClick={openPopover}>
                {max.format(this.props.timeFormat)}
              </Button>
            );
          }}
        >
          {({ closePopover }) => (
            <TimePicker
              value={max}
              onChange={(val) => {
                this.handleChange("lte", val);
              }}
              onClose={closePopover}
            />
          )}
        </Popover2>
      </div>
    );
  }
}

export default TimeRange;
