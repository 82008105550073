import { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import Api from "entities/helpers/api";
import EverTrue from "app";
import { Icon, Button, useOpenClose, Modal } from "@evertrue/et-components";
import { getHumanReadableTimeFromNow } from "apps/proposals/components/proposal-form-utils";
import InteractionForm from "apps/interactions/components/create/interaction-form";

const ProposalDetailInteractions = ({ proposalId, contact = {}, hasInteractionWrites = false }) => {
  const [interactions, setInteractions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalInteractions, setTotalInteractions] = useState(0);
  const [isModalOpen, openModal, closeModal] = useOpenClose();
  const [fetchOffset, setFetchOffset] = useState(0);
  const queryLimit = 10;
  const moreToLoad = totalInteractions > queryLimit + fetchOffset;

  const handleNextPage = () => {
    if (moreToLoad) {
      setFetchOffset((prev) => prev + 10);
    }
  };

  const handleInteractionAdded = () => {
    closeModal();
    setLoading(true);
    setTimeout(() => {
      if (fetchOffset > 0) {
        setFetchOffset(0);
      } else {
        fetchInteractions();
      }
    }, 2000); // Delay to make sure save finishes
  };

  const fetchInteractions = useCallback(() => {
    setLoading(true);

    if (proposalId) {
      Api.SEARCH.CONTACT_NOTE.post({
        data: JSON.stringify({
          must: [{ proposal_id: { match: proposalId } }],
          sort: [{ date_occurred: { order: "desc" } }],
        }),
        params: {
          limit: queryLimit,
          offset: fetchOffset,
        },
        success: (resp) => {
          setTotalInteractions(resp.total);

          if (fetchOffset > 0) {
            setInteractions((prev) => prev.concat(resp.items));
          } else {
            setInteractions(resp.items);
          }

          setLoading(false);
        },
        error: () => {
          setLoading(false);
        },
      });
    }
  }, [proposalId, fetchOffset]);

  useEffect(() => {
    fetchInteractions();
  }, [fetchInteractions]);

  return (
    <>
      <Modal isOpen={isModalOpen} closeModal={closeModal}>
        <InteractionForm
          interaction={{ proposal_id: proposalId }}
          contact={contact}
          referrer="proposal"
          requestHide={handleInteractionAdded}
        />
      </Modal>

      <div className="proposal-detail--interaction-header">
        <div className="proposal-detail--heading">Activity</div>
        {hasInteractionWrites && (
          <Button
            label="Add Interaction"
            className="proposal-detail--add-interaction-button"
            type="secondary"
            onClick={isModalOpen ? closeModal : openModal}
          >
            <Icon icon="add" size={2} />
          </Button>
        )}
      </div>

      <ul>
        {interactions.map((interaction) => (
          <li key={interaction.id} className={interaction.pinned ? "interaction-list-item--pinned" : ""}>
            <Button
              type="simple"
              className="proposal-detail--interaction"
              label="Go to interaction detail"
              onClick={() => {
                const type = interaction.interaction_type === "EverTrue Comment" ? "comment" : "interaction";
                EverTrue.track.set("interaction_action", { type, action: "view" });
                EverTrue.Navigator(`/interaction/${interaction.id}`, { trigger: true });
              }}
            >
              <div className="proposal-detail--interaction-icon">
                <Icon icon="chat" size={1} />
              </div>
              <div>
                <div>{interaction.interaction_type}</div>
                <div className="proposal-detail--interaction-updated">
                  {getHumanReadableTimeFromNow(interaction.updated_at)} ago
                </div>
              </div>
            </Button>
          </li>
        ))}

        {!loading && moreToLoad && (
          <li className="proposal-detail--show-more-interactions">
            <Button
              label="Show more interactions"
              type="simple"
              className="proposal-detail--show-more-link"
              onClick={handleNextPage}
            >
              show more
            </Button>
          </li>
        )}
      </ul>

      {loading
        ? "Loading..."
        : interactions.length === 0 && <div className="proposal-detail--light-text">No Associated Interactions</div>}
    </>
  );
};

ProposalDetailInteractions.propTypes = {
  proposalId: PropTypes.number,
  contact: PropTypes.object,
  hasInteractionWrites: PropTypes.bool,
};

export default ProposalDetailInteractions;
