module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default

   Query = (Parameters) ->
      params = Parameters || []
      query_object = {}

      _mapParameters = (all_params) ->
         parameterMap = {}
         _.each all_params, (param) ->
            parameterMap[param.type()] ?= []
            parameterMap[param.type()].push param
         parameterMap

      {
         toJSON: ->
            query_object = {}
            _.each params, (param) ->
               parameter_object = param.toJSON()
               _.each parameter_object, (properties, type) ->
                  # This is a bit of a gross 1-off hack to manage highlight not being an array
                  if type == "highlight"
                     query_object[type] ?= {}
                     _.each properties, (prop) -> $.extend true, query_object[type], prop
                  else
                     query_object[type] ?= []
                     _.each properties, (prop) -> query_object[type].push prop
            query_object

         toOrJSON: ->
            query_object = {}
            _.each params, (param) ->
               parameter_object = param.toJSON({exclude_parent: true})
               _.each parameter_object, (properties, type) ->
                  query_object[type] ?= []
                  query_object[type][0] ?= {}
                  _.each properties, (prop) -> _.extend query_object[type][0], prop
            query_object

         add: (Parameter) ->
            params.push(Parameter)

         merge: (query) ->
            # Merge Parameters of the same type into one ParameterQuery object
            all_params = _.flatten [query.parameters(), params]
            parameterMap = _mapParameters(all_params)
            params = []
            _.each parameterMap, (parameters, type) ->
               if parameters.length > 1
                  initial_param = _.first(parameters)
                  if initial_param.isNestedDocument?()
                     _.each parameters, (param, index) ->
                        initial_param.merge(param) unless index == 0
                  else
                     _.each parameters, (param, index) ->
                        unless index == 0
                           initial_param.addProperties param.properties()
                  params.push initial_param
               else
                  params.push _.first(parameters)

         parameters: ->
            params

         parameterKeys: ->
            _.map params, (param) -> param.type?()

         properties: ->
            _.map params, (param) -> param.properties()

         propertyKeys: ->
         # Removed _.uniq here to test
            _.flatten _.map params, (param) -> param.propertyKeys()

         getAllProperties: ->
            _.flatten _.map params, (param) -> param.propertyKeys()

         hasParam: (key) ->
            _.contains @parameterKeys(), key

         hasChildParam: ->
            _.any params, (p) -> p.isChildDocument?()

         hasNestedDocument: ->
            _.any params, (p) -> p.isNestedDocument?()
      }

   return Query
