import { Component } from "react";
import { connect } from "@evertrue/et-flux";
import _ from "underscore";
import CronofyCalendarSource from "apps/my-trips/sources/cronofy-calendar-source";
import CronofyCalendarStore from "apps/my-trips/stores/cronofy-calendar-store";

const mapStateToProps = () => ({
  calendars: CronofyCalendarStore.getCalendarsByProvider(),
  isLoading: CronofyCalendarStore.getLoadingCalendars(),
});

class CronofyCalendarContainer extends Component {
  static propTypes = {
    render: ReactLibs.PropTypes.func,
    calendars: ReactLibs.PropTypes.object,
    isLoading: ReactLibs.PropTypes.bool,
  };

  static defaultProps = {
    render: () => null,
    calendars: {},
    isLoading: false,
  };

  componentDidMount() {
    CronofyCalendarSource.fetchCalendarsIfNotCached();
  }

  render() {
    const { render, calendars, isLoading } = this.props;

    return render({
      calendars,
      hasCalendars: !_.isEmpty(calendars),
      isLoading,
    });
  }
}

export default connect(
  CronofyCalendarContainer,
  [CronofyCalendarStore],
  mapStateToProps
);
