module.exports = do ->
   React = require("react")
   {div} = ReactLibs.DOMFactories
   {createComponent} = require("@evertrue/et-flux")
   {createFactory} = require("base/new-utils")
   Nav = createFactory require("components/lists/nav")
   NavItem = createFactory require("components/lists/nav-item")

   createComponent "AdminNav",
      propTypes:
         active: ReactLibs.PropTypes.string

      render: ->
         div null,
            Nav className: "sub-nav", active: @props.active,
               NavItem href: "/admin/gates/active", activeWhen: "active_gates", label: "Your Feature Flags"
               NavItem href: "/admin/gates/advanced", activeWhen: "advanced_gates", label: "Advanced Feature Managment"
