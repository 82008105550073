import { Icon } from "@evertrue/et-components";
import { PropTypes } from "base/react-libs";

const DIALER_OPTIONS = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "*", "0", "#", "blank1", "blank2", "backspace"];

const DialerNumPad = ({ handleNumPadPress }) => {
  return (
    <>
      <div className="dialer-dialpad">Keypad</div>
      <div className="dialer--grid-container">
        {DIALER_OPTIONS.map((option) => {
          if (option.includes("blank")) return <div className="dialer--grid-item" key={option} />;
          else if (option === "backspace")
            return (
              <div key={option} className="dialer--grid-item">
                <Icon icon="backspace" iconKit="gt2" onClick={() => handleNumPadPress(null, true)} />
              </div>
            );
          else
            return (
              <div className="dialer--grid-item" key={option} onClick={() => handleNumPadPress(option, true)}>
                {option}
              </div>
            );
        })}
      </div>
    </>
  );
};

DialerNumPad.propTypes = {
  handleNumPadPress: PropTypes.func,
};

export default DialerNumPad;
