module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   EverTrue = require("app")
   User = require("entities/auth/user")
   AuthApi = require("entities/auth/auth-api")
   PaginatedCollectionMixin = require("mixins/pagination-mixin")
   RoleHelper = require("entities/helpers/role-helper")


   class Users extends AuthApi.Collection
      @include [PaginatedCollectionMixin]
      urlRoot: "auth/users"
      model: User

      parse: (data) ->
         @boundaries(data)
         data.items

      comparator: (model) ->
         model.get("name")

      fetchForGivingTree: (options) ->
         roles = RoleHelper.toArray()
         data = []
         requests = _.map roles, (role_id) =>
            @fetch _.extend {}, options,
               reset: false
               params: {role: role_id}
               success: (collection, response) ->
                  data.push response

         $.when.apply(@, requests).then (args...) =>
            users = _.flatten(data)
            @reset(users, {silent: options.reset})

            if _.isFunction options.success
               options.success(@, @toJSON())
