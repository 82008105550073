import _ from "underscore";
import PropTypes from "prop-types";
import ProposalTableColumns from "apps/proposals/components/proposal-table-columns";
import { useCachedContactByIdsResolver } from "@evertrue/vms-insights";
import { FlexTable } from "@evertrue/et-components";
import { useIdentity } from "base/identity-resolver";
import Decorator from "clientDecorator";
import EverTrue from "app";

const ProposalTable = ({
  proposals = {},
  loading = false,
  sortBy = "",
  limit = 0,
  total = 0,
  page = 0,
  handlePageChange = () => {},
}) => {
  const { user, org } = useIdentity();
  const contactId = Decorator.User.getContactId(user, org.id);

  const contact_ids =
    proposals.items &&
    proposals.items.flatMap((proposal) => {
      return proposal.contacts.map((i) => i.contact_id);
    });

  const handleRowClick = (id) => {
    EverTrue.Navigator(`proposal/${id}`, true);
    EverTrue.track.set("proposal_action", { type: "view" });
  };

  // prefetch list of contact_ids that will be accessed later in the tableCells
  useCachedContactByIdsResolver(_.uniq(contact_ids));

  return (
    <div className="proposal-table">
      <FlexTable
        caption="Proposals"
        emptyStateMessageText="No results match your current filters"
        columns={ProposalTableColumns(contactId, sortBy)}
        data={proposals.items ? proposals.items : []}
        loading={loading}
        getTrProps={(rowData) => {
          return { onClick: () => handleRowClick(rowData.id) };
        }}
        limit={limit}
        total={total}
        page={page}
        handlePageChange={handlePageChange}
      />
    </div>
  );
};

ProposalTable.propTypes = {
  proposals: PropTypes.object,
  loading: PropTypes.bool,
  sortBy: PropTypes.string,
  limit: PropTypes.number,
  total: PropTypes.number,
  page: PropTypes.number,
  handlePageChange: PropTypes.func,
};

export default ProposalTable;
