module.exports = do ->
   _ = require("underscore").default
   {createStore} = require("@evertrue/et-flux")
   StatusSource = require("apps/layout/sources/status-source")

   createStore "StatusStore",
      getInitialState: ->
         components: undefined
         status: undefined
         is_down: undefined

      firstListenerDidMount: ->
         StatusSource.fetch()

      registerActions: ->
         @on StatusSource.actions.fetchedComponents, (components) ->
            @setState {components: components}

         @on StatusSource.actions.isDown, (is_down) ->
            @setState {is_down: is_down}

         @on StatusSource.actions.status, (status) ->
            @setState {status: status}

      api:
         getStatus: ->
            @getState("status")

         isDown: ->
            @getState("is_down")

         isLoginDown: ->
            !!_.find @getState("components"), (comp) ->
               comp.name?.toLowerCase() == "login" && comp.status == "major_outage"
