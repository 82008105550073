import { createContext, useContext, useEffect, useState, useRef, useMemo } from "react";
import _ from "underscore";
import PropTypes from "prop-types";
import Api from "entities/helpers/api";

const CachedContactUserContext = createContext(() => {});

const useCachedUsersByContactIdResolver = contact_id => {
  const { usersByContactId, idsLoading, fetchUserForContactIfNotCached } = useContext(CachedContactUserContext);
  useEffect(() => {
    fetchUserForContactIfNotCached(contact_id);
  }, [contact_id, fetchUserForContactIfNotCached]);
  const user = usersByContactId[contact_id] || {};
  const loading = _.contains(idsLoading, contact_id);
  return { user, loading };
};

const CachedContactUserProvider = props => {
  const [idsLoading, setIdsLoading] = useState([]);
  const [usersByContactId, setUsersByContactId] = useState({});
  // This set tracks ids if a request is already in flight OR have has error-ed out
  // useRef to persist across renders but don't tie this to rendering
  const idsDontFetch = useRef(new Set());

  const fetchUserForContact = contact_id => {
    setIdsLoading(prev => [...prev, contact_id]);
    Api.AUTH.USER_BY_CONTACT_ID.get({
      urlExtend: `/${contact_id}`,
      disableAlerts: true, // this request returns noisy 404s if it doesn't find a user for given contact id
    })
      .then(resp => {
        const new_data = {};
        new_data[contact_id] = resp;
        setUsersByContactId(prev => {
          return { ...prev, ...new_data };
        });
        setIdsLoading(prev => _.without(prev, contact_id));
        // remove here b/c is now in state
        idsDontFetch.current.delete(contact_id);
      })
      .catch(() => {
        // need to clear any data for the user in usersByContactId because if they've
        // been unmatched, their data needs to be cleared out
        const new_data = {};
        new_data[contact_id] = {};
        setUsersByContactId(prev => {
          return { ...prev, ...new_data };
        });
        // don't clear idsDontFetch set here b/c don't refetch something that's errorred out
        setIdsLoading(prev => _.without(prev, contact_id));
      });
  };

  const value = useMemo(() => {
    // put fetchUserForContactIfNotCached here b/c otherwise would not be consistent across renders
    const fetchUserForContactIfNotCached = contact_id => {
      if (contact_id && !idsDontFetch.current.has(contact_id) && !usersByContactId[contact_id]) {
        idsDontFetch.current.add(contact_id); // add to tracker set
        fetchUserForContact(contact_id);
      }
    };
    return {
      usersByContactId: usersByContactId,
      fetchUserForContact: fetchUserForContact,
      fetchUserForContactIfNotCached: fetchUserForContactIfNotCached,
      idsLoading: idsLoading,
    };
  }, [idsLoading, usersByContactId]);

  return <CachedContactUserContext.Provider value={value}>{props.children}</CachedContactUserContext.Provider>;
};

CachedContactUserProvider.propTypes = {
  children: PropTypes.any,
};

export { CachedContactUserContext, CachedContactUserProvider, useCachedUsersByContactIdResolver };
