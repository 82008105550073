import { PropTypes } from "base/react-libs";
import { Button } from "@evertrue/et-components";
import { trackJourneysAction } from "apps/journeys/utils/helpers/mixpanel";
import { useState, useEffect, forwardRef } from "react";
import JourneyContactCard from "apps/journeys/components/journey-contact-card";
import ActiveConstituentActions from "./active-constituent-actions";
import moment from "moment";

const ActiveConstituentsRow = forwardRef(
  ({ contactJourney, journey, userIsOwner, openContactSummary, optionalStateManagementFunction }, ref) => {
    const [currentTask, setCurrentTask] = useState();
    const [nextTask, setNextTask] = useState();

    useEffect(() => {
      let current, next;
      if (contactJourney && contactJourney.tasks && Array.isArray(contactJourney.tasks)) {
        [current, next] = contactJourney.tasks;
        setCurrentTask(current);
        setNextTask(next);
      }
    }, [contactJourney]);

    return (
      <>
        <div key={contactJourney.id} className="journeys-task-table-row--details" ref={ref}>
          <div className="journeys-task-table-contact-card">
            <JourneyContactCard contact={contactJourney.contact} currentJourney={journey} />
          </div>
          {currentTask && currentTask.journey_task ? (
            <>
              <div className="journeys-task-table-row-step-day">
                <div>
                  Current: Step {currentTask.journey_task.step_number} | Day {currentTask.journey_task.day_number}
                </div>
                <div>{currentTask.title}</div>
              </div>
              <div className="journeys-task-table-row--due-date">
                {moment(new Date(currentTask.due_date)).format("MMM Do YYYY")}
              </div>
              <div className="journeys-task-table-row-step-day">
                {nextTask && nextTask.journey_task && (
                  <>
                    <div>
                      Next: Step {nextTask.journey_task.step_number} | Day {nextTask.journey_task.day_number}
                    </div>
                    <div>{nextTask.title}</div>
                  </>
                )}
              </div>
            </>
          ) : (
            <div className="journey-task-no-next-step">No Next Steps</div>
          )}

          <div className="journey-three-dot-view">
            {userIsOwner && (
              <ActiveConstituentActions
                journey={journey}
                contactJourney={contactJourney}
                optionalStateManagementFunction={optionalStateManagementFunction}
              />
            )}
            <Button
              disabled={!contactJourney.contact}
              onClick={() => {
                trackJourneysAction("view_link");
                const { contact = null } = contactJourney;
                openContactSummary(contact);
              }}
              type="simple"
            >
              View
            </Button>
          </div>
        </div>
      </>
    );
  }
);

ActiveConstituentsRow.propTypes = {
  userIsOwner: PropTypes.bool,
  journey: PropTypes.object.isRequired,
  openContactSummary: PropTypes.func,
  optionalStateManagementFunction: PropTypes.func,
  contactJourney: PropTypes.object,
};
export default ActiveConstituentsRow;
