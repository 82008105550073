module.exports = do ->
   $ = require("jquery")
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   FeatureStore = require("apps/layout/stores/feature-store").default
   ProfileStore = require("apps/profile/stores/profile-store")
   {createFactory} = require("base/new-utils")
   Nav = createFactory require("components/lists/nav")
   NavItem = createFactory require("components/lists/nav-item")
   { trackTaskAction } = require("apps/tasks/utils/utils");


   createComponent "ProfileNav",
      registerStores: ->
         @on ProfileStore, ->
            view: ProfileStore.getSelectedSection()

         @on FeatureStore, ->
            has_notes: FeatureStore.hasFeature("ugc_show_imported_notes")
            has_proposals: FeatureStore.hasFeature("proposal_reads")
            has_tasks: FeatureStore.hasFeature("task_management")

      handleChangeTab: (tab) ->
         link = "/contact/#{@props.id}/#{tab}"
         Backbone.history.navigate(link, {trigger: true, replace: true})
         $(".contact-profile--body").scrollTop(0)

      render: ->
         Nav
            className: "profile-nav profile-nav-tabs"
            onSelect: @handleChangeTab
            active: @props.tab || "summary"

            NavItem activeWhen: "summary", label: "Summary"
            NavItem activeWhen: "bio", label: "Constituent Info"
            NavItem activeWhen: "enrichment", label: "Enrichment", icon: "et-pyramid"
            NavItem activeWhen: "posts", label: "Engagement",  icon: "et-pyramid"
            NavItem activeWhen: "giving", label: "Giving"
            NavItem activeWhen: "notes", label: if @state.has_notes then "Interactions" else "Comments"
            if @state.has_proposals
               NavItem activeWhen: "proposals", label: "Proposals"
            if @state.has_tasks
               NavItem activeWhen: "task", label: "Tasks"
