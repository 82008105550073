module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {createFactory} = require("base/new-utils")
   AdvancedCombobox = createFactory(require("@evertrue/et-components").AdvancedCombobox)
   InteractionStore = require("apps/interactions/stores/interaction-store").default
   str = require("underscore.string")
   # Single Form field for an Interaction "label"
   # Wrap AdvancedCombobox with additional funcitonality to support label logic
   # - Displaying "Current Selections" for label values with many options
   # - Filtering and formatting of label values
   # - Clearing a filter item

   createComponent "InteractionLabelField",
      propTypes:
         label: ReactLibs.PropTypes.string
         interaction: ReactLibs.PropTypes.object
         onChange: ReactLibs.PropTypes.func

      getInitialState: ->
         label_query: undefined

      registerStores: ->
         @on InteractionStore, ->
            label_options: InteractionStore.getLabelValues()

      handleLabelChange: (label_name, value) ->
         @props.onChange(label_name, value)

      getLabelOptions: (label_name) ->
         options = _.cloneData @state.label_options?[label_name] || []

         # Filter options based on query
         filtered_options = _.filter options, (option) =>
            _.searchCompare(option.label, @state.label_query)

         # Groups options into "Current Selections" for long lists
         if options.length > 25
            grouped_options = []
            if !_.isEmpty(@props.interaction.labels[label_name])
               grouped_options.push
                  label: "Current Selections"
                  items: @props.interaction.labels[label_name]

            grouped_options.push
               label: str.humanize(label_name)
               items: _.sortBy(filtered_options, "label")
            grouped_options
         else _.sortBy(filtered_options, "label")

      render: ->
         label_name = @props.label

         # If no options are available, show a textbox to "create" instead of dropdown
         if _.isEmpty(@state.label_options?[label_name]) then null
         else
            AdvancedCombobox
               ref: "label_selector"
               className: "interaction-form--label"
               placeholder: "None selected"
               disabled: !@props.interaction.interaction_type
               value: @props.interaction.labels[label_name]
               options: @getLabelOptions(label_name)
               grouped: @state.label_options?[label_name]?.length > 25
               searchable: true
               search_placeholder: "Search ..."
               allowUnselectAll: true
               onSearch: (query) => @setState {label_query: query}
               onChange: (val) => @handleLabelChange(label_name, val)
