import { PropTypes } from "base/react-libs";
import { FlexTable, Link } from "@evertrue/et-components";
import JourneyFavoriteToggle from "apps/journeys/utils/journey-favorite-toggle";
import ActiveJourneyClickable from "apps/journeys/components/modals/active-journey-clickable.js";
import { useGate } from "components/is-gated";
import { isEmpty } from "underscore";
import { getJourneyEmptyState } from "apps/journeys/utils/helpers/journeys-empty-state-handler";
import EmptyStateMessage from "components/lists/empty-state-message";

const JourneysTable = ({
  journeys = [],
  loading,
  toggleFavorite,
  handleOpenSideBar,
  selectedTab,
  onlyJourneysWithStepsDue,
  handleTab,
}) => {
  const [hasUnreleasedFeature, loadedUnreleasedFeature] = useGate("unreleased_feature");

  const totalMessage = () => {
    if (journeys.length === 1) {
      return `${journeys.length} Total Cadence`;
    }
    return `${journeys.length} Total Cadences`;
  };

  const emptyState = getJourneyEmptyState(onlyJourneysWithStepsDue, selectedTab, handleTab);

  const columns = [
    {
      key: 1,
      width: 230,
      header: "Cadence Name",
      accessor: (data) => {
        return (
          <div className="journey-name">
            <JourneyFavoriteToggle journey={data} onClick={toggleFavorite} />
            <Link href={`/cadences/${data.id}`} title="journey-name">
              {data.name}
            </Link>
          </div>
        );
      },
    },
    {
      key: 2,
      width: 100,
      header: "Steps Due",
      accessor: (data) => {
        if (data && !isEmpty(data)) {
          const { tasks_due_counts } = data;
          return tasks_due_counts && tasks_due_counts.total ? tasks_due_counts.total : 0;
        } else return 0;
      },
    },
    {
      key: 3,
      width: 180,
      header: "Active Constituents",
      accessor: (data) => {
        return (
          <>
            {hasUnreleasedFeature && loadedUnreleasedFeature ? (
              <ActiveJourneyClickable handleOpenSideBar={handleOpenSideBar} journey={data} />
            ) : (
              data.active_constituents
            )}
          </>
        );
      },
    },
    {
      key: 4,
      width: 150,
      header: "Total Duration",
      accessor: (data) => {
        const { duration_in_days = 0 } = data;
        return `${duration_in_days} Days`;
      },
    },
    {
      key: 5,
      width: 220,
      header: "Cadence Group",
      accessor: (data) => {
        return data.journey_group != null ? data.journey_group.name : "";
      },
    },
  ];

  return (
    <div>
      <div className="journeys-table--header">{totalMessage()}</div>
      <div className="journeys-table--body">
        {!journeys.length && !loading ? (
          <EmptyStateMessage
            text={emptyState.text}
            subtext={emptyState.subtext}
            actionLabel={emptyState.actionLabel}
            actionLink={emptyState.actionLink}
            icon={emptyState.icon}
            hideIcon={emptyState.hideIcon}
            iconKit={emptyState.iconKit}
            iconClassName={emptyState.iconClassName}
          />
        ) : (
          <FlexTable caption="Lists" columns={columns} data={journeys} loading={loading} />
        )}
      </div>
    </div>
  );
};

JourneysTable.propTypes = {
  journeys: PropTypes.array,
  loading: PropTypes.bool,
  toggleFavorite: PropTypes.func,
  handleOpenSideBar: PropTypes.func,
  selectedTab: PropTypes.object,
  onlyJourneysWithStepsDue: PropTypes.bool,
  handleTab: PropTypes.func,
};

export default JourneysTable;
