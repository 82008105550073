import ImageResize from "quill-image-resize-module-react";

function CustomImageResize(quill, options = {}) {
  // Initialize the original ImageResize module
  const imageResize = new ImageResize(quill, options);
  const originalOnDrag = imageResize.onUpdate.bind(imageResize);

  imageResize.onUpdate = function () {
    originalOnDrag();
    this.img.style.minWidth = `20px`;
  };

  return imageResize;
}

export default CustomImageResize;
