const gql = String.raw;

const homepageQueries = {
  fetchTrips: gql`
    query ($userId: BigInt!, $dayRange: BigInt) {
      trips(userId: $userId, dayRange: $dayRange) {
        name
        type
        upcoming_meetings(dayRange:$dayRange) {
          id
          type
          name
          start_at
          end_at
          contact_meetings {
            contact {
              id
              contact_attributes {
                name_full
              }
            }
          }
        }
      }
    }
  `,
};
export default homepageQueries;
