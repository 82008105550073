import PropTypes from "prop-types";
import ProposalForm from "apps/proposals/components/proposal-form";

/**
 * This is a wrapper for the proposal form so that it can be called from the legacy Modal component in ProfileActions.
 * That component provides an implicit property called requestHide which this component passes to closeModal.
 * In order to use the new Modals in ProfileActions that component needs to be rewritten with the ActionsMenu from
 * et-components, at which point this component can be removed.
 */
export const ProposalFormLegacyModalWrapper = ({ primaryContactId, requestHide, afterCreate }) => {
  return <ProposalForm primaryContactId={primaryContactId} closeModal={requestHide} afterCreate={afterCreate} />;
};

ProposalFormLegacyModalWrapper.propTypes = {
  primaryContactId: PropTypes.number.isRequired,
  afterCreate: PropTypes.func,
  requestHide: PropTypes.func,
};

export default ProposalFormLegacyModalWrapper;
