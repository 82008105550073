module.exports = do ->
   _ = require("underscore").default
   $ = require("jquery")
   React = require("react")
   {div, span} = ReactLibs.DOMFactories
   {createComponent} = require("@evertrue/et-flux")
   FilterPanelRow = require("components/controls/filter-panel-row")
   FacebookPagesDropdown = require("apps/facebook/components/facebook-pages-dropdown")
   FilterTextInput = require("apps/filters/components/filter-text-input")
   FilterMultiSelect = require("apps/filters/components/filter-multi-select")
   DateSelector = require("apps/filters/components/date-selector")
   {createFactory} = require("base/new-utils")
   Range = createFactory require("components/forms/range")
   FacetSource = require("apps/filters/sources/facet-source")
   FeatureStore = require("apps/layout/stores/feature-store").default
   ProfileCardSubtitle = createFactory require("apps/profile/components/profile-card-subtitle").default
   HelpTip = createFactory require("components/tooltip/helptip").default
   FacebookReactionsDropdown = require("apps/facebook/components/facebook-reactions-dropdown")

   ASSIGNEE = "assignees.name.untouched"

   createComponent "FacebookFiltersPanel",
      propTypes:
         filters: ReactLibs.PropTypes.object
         onChange: ReactLibs.PropTypes.func
         activeType: ReactLibs.PropTypes.string

      componentDidMount: ->
         FacetSource.fetch([ASSIGNEE])

      registerStores: ->
         @on FeatureStore, ->
            has_rm_assignments: FeatureStore.hasFeature("rm_assignments")

      handleChange: (key, value) ->
         filters = $.extend true, {}, @props.filters
         if _.isEmpty(value) then filters[key] = undefined
         else filters[key] = value
         @props.onChange?(_.compactObject(filters))

      renderHelptip: (help_key) ->
         if @props.activeType is "posts"
            help_key = @props.activeType.concat("_", help_key)

         span null,
            HelpTip
               className: "facebook-filter--help"
               help_key: help_key
               position: "left-top-aligned"

      render: ->
         filters = _.cloneData(@props.filters) || {}
         lifetime_giving = _.mapObject filters.lifetime_giving, (value, key) ->
            _.toNumber(value)

         div className: "facebook-filters facebook-filter-panel",

            div className: "facebook-filters-first-subtitle",
               ProfileCardSubtitle
                  label: "Facebook Filters"
                  help_key: "facebook_filters"
                  help_position: "right-top-aligned"

            if @props.activeType is "interactions"
               FilterPanelRow label: "Facebook Engagement Type",
                  div className: "facebook-filters--col",
                     FacebookReactionsDropdown
                        placeholder: "All Engagement Types"
                        value: filters.reactions
                        onChange: (value) => @handleChange("reactions", value)

            FilterPanelRow label: "Facebook Page Engagement",
               div className: "facebook-filters--col",
                  FacebookPagesDropdown
                     placeholder: "All Facebook Pages"
                     value: filters.fb_pages
                     onChange: (value) => @handleChange("fb_pages", value)
               @renderHelptip("facebook_page")

            FilterPanelRow label: "Facebook Content Engagement",
               FilterTextInput
                  className: "facebook-filters--text"
                  value: {value: filters.contents}
                  placeholder: "Enter keywords..."
                  onChange: (value) => @handleChange("contents", value)
               @renderHelptip("facebook_content")

            FilterPanelRow label: "Facebook Engagement Date",
               DateSelector
                  className: "facebook-filters--col"
                  date: filters.date
                  onChange: (value) => @handleChange("date", value)
               @renderHelptip("facebook_date_selector")

            if @props.activeType is "interactions"
               div null,
                  ProfileCardSubtitle
                     label: "Constituent Filters"
                     help_key: "facebook_constituent_filters"
                     help_position: "right-top-aligned"

                  FilterPanelRow label: "Lifetime Giving",
                     Range
                        name: "lifetime_giving_range"
                        min: "$0"
                        max: "any"
                        value: lifetime_giving
                        showClear: true
                        mask: {prefix: "$"}
                        onChange: (name, value) => @handleChange("lifetime_giving", value)

                  FilterPanelRow label: "Last Gift Date",
                     DateSelector
                        className: "facebook-filters--col"
                        date: filters.last_gift_date
                        default: "Any Date"
                        calendar_position: "top"
                        onChange: (value) => @handleChange("last_gift_date", value)

                  if !@state.has_rm_assignments
                     FilterPanelRow label: "Assigned To",
                        div className: "facebook-filters--col",
                           FilterMultiSelect
                              filter_id: "5.3" # component uses FilterConfig to load facets
                              value: filters.assignee
                              onChange: (values) => @handleChange("assignee", values)

                  FilterPanelRow label: "Graduation Year",
                     Range
                        name: "grad_year_range"
                        min: "1950"
                        max: new Date().getFullYear().toString()
                        value: filters.grad_year
                        showClear: true
                        mask: {mask: "9999", autoGroup: false}
                        onChange: (name, value) => @handleChange("grad_year", value)
