import { PropTypes } from "base/react-libs";
import { Button, Icon } from "@evertrue/et-components";

const FilterRevert = ({ modified, onRevert = () => {} }) => {
  return modified ? (
    <Button
      type="simple"
      onClick={() => {
        onRevert();
      }}
    >
      <Icon icon="undo" />
      Revert
    </Button>
  ) : null;
};

FilterRevert.propTypes = {
  modified: PropTypes.bool,
  onRevert: PropTypes.func,
};

export default FilterRevert;
