module.exports = do ->
   React = require("react")
   {number, string} = ReactLibs.PropTypes
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   {span} = ReactLibs.DOMFactories

   createComponent "EventSourceIcon",
      propTypes:
         className: string
         source: string
         size: number

      getDefaultProps: ->
         size: 16

      render: ->
         src = switch @props.source
            when "eventbrite" then "/images/eventbrite-logo.png"
            when "facebook" then "/images/event-facebook-event.png"
            when "reply" then "/images/tv_interaction_reply.png"
            else "images/event-unknown-event.png"

         span
            className: classNames("event-source-icon", @props.className)
            style:
               width: @props.size
               height: @props.size
               backgroundImage: "url(#{src})"
