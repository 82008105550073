import { Link } from "@evertrue/et-components";
import { isEmpty } from "underscore";
import { PropTypes } from "base/react-libs";
import { useGlobalJourneyProvider } from "apps/journeys/context/global-journey-context";
import Avatar from "components/elements/avatar";
import EverTrue from "app";
import ContactTimezoneIndicator from "apps/contact/components/contact-timezone-indicator";

const getYear = (contact) => {
  const { contact_attributes = {} } = contact;

  if (contact_attributes) {
    const { year } = contact_attributes;
    if (year) return `Class of ${year}`;
  }

  return null;
};

const getRoleWithYear = (contact = {}) => {
  if (!contact || isEmpty(contact)) return "";

  const { constituencies = [] } = contact;
  let roles;

  if (constituencies.length) {
    const statuses = constituencies.map((c) => c.status).filter((s) => !!s);
    if (statuses.length) {
      roles = statuses.join(", ").replace(";", "");
    }
  }

  const year = getYear(contact);

  if (roles && year) return `${roles} - ${year}`;
  else if (roles) return roles;
  else if (year) return year;
  else return "";
};

const JourneyContactCard = ({
  contact,
  avatarSize = 40,
  padding = 8,
  householdCard = false,
  currentJourney = {},
  recommendedAction = false,
}) => {
  const { setGlobalJourney } = useGlobalJourneyProvider();

  let fullName,
    avatarUrl = "";
  let contactId;
  if (contact && !isEmpty(contact)) {
    const { id, contact_attributes = {}, profile_image = {} } = contact;
    fullName = contact_attributes && contact_attributes.name_full ? contact_attributes.name_full : "";
    avatarUrl = profile_image ? profile_image.url : "";
    contactId = id;
  }

  return (
    <div
      data-testid="journeys--contact-card"
      className="journeys--contact-card--container"
      style={{ padding: padding }}
    >
      <Link
        title={`${fullName}'s Profile`}
        href={contactId ? `/contact/${contactId}` : "/cadences/"}
        onClick={() => {
          if (recommendedAction === true) EverTrue.track.set("home_screen", { type: "recommended_action" });
          setGlobalJourney(currentJourney);
        }}
      >
        <Avatar url={avatarUrl} name={fullName} width={avatarSize} />
      </Link>
      <div className="journeys--contact-card--content fs-exclude-data">
        <div className="journeys--contact-card-main">
          <Link
            title={`${fullName}'s Profile`}
            href={contactId ? `/contact/${contactId}` : "/cadences/"}
            className="journeys-contact-card-name"
            onClick={() => {
              if (recommendedAction === true) EverTrue.track.set("home_screen", { type: "recommended_action" });
              setGlobalJourney(currentJourney);
            }}
          >
            {fullName}
          </Link>
          <ContactTimezoneIndicator contact={contact} />
        </div>
        {!householdCard && <div>{getRoleWithYear(contact)}</div>}
      </div>
    </div>
  );
};

JourneyContactCard.propTypes = {
  contact: PropTypes.object,
  avatarSize: PropTypes.number,
  padding: PropTypes.number,
  householdCard: PropTypes.bool,
  currentJourney: PropTypes.object,
  recommendedAction: PropTypes.bool,
};

export default JourneyContactCard;
