module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   SodasSocialEngagement = require("entities/search/sodas-social-engagement")
   SocialProfile = require("entities/search/social-profile")
   Backbone = require("backbone")
   Contacts = require("entities/contacts/contacts")
   _requests = []


   PostEngagerStore = ->
      engagements = {}
      profiles = {}
      all_profiles = {}

      _getProfileData = (ids) ->
         if _.isEmpty(ids)
            all_profiles.trigger("reset")
         else
            profiles.fetch
               query: must: [
                  {profile_id: in: ids},
                  {contact_id: exists: false}
               ]
               beforeSend: (xhr) ->
                  _requests.push(xhr)
               success: ->
                  all_profiles.add(profiles.toJSON(), {merge: true})
                  all_profiles.trigger("reset")


      api = $.extend true, {}, Backbone.Events,
         base_query: []
         initialize: ->
            engagements = new SodasSocialEngagement()
            profiles = new SocialProfile()
            all_profiles = new SocialProfile()

         bindToUpdates: (callback) ->
            @listenTo all_profiles, "reset", ->
               callback(all_profiles.toJSON(), engagements.toJSON()) if _.isFunction(callback)

         setBaseQuery: (query) ->
            @base_query = query

         unbind: ->
            @stopListening()

         hasMoreToLoad: ->
            !engagements.isFinalPage()

         reset: (opts) ->
            _.each _requests, (req) -> req.abort()
            all_profiles.reset([], opts)
            engagements.infinityReset()

         getPostEngagersByType: (content_id, type) ->
            new_query = must: [{content_id: match: content_id}, {contact_id: exists: false}]

            if type != "all" and type != null
               new_query = must: [{content_id: match: content_id}, {type: in: [type]}]

            @setBaseQuery(new_query)
            @reset({silent: true})
            @infinity()

         infinity: ->
            engagements.infinity
               query: @base_query
               beforeSend: (xhr) ->
                  _requests.push(xhr)
               success: (collection, response) ->
                  ids = _.pluck(response.items, "profile_id")
                  _getProfileData(ids)

         getContactMatches: (query, page_remote_id, callback, limit) ->
            contacts = new Contacts()
            contacts.setLimit(limit)
            contacts.fetch
               filters: full_name: query
               query:
                  must_not: [
                     has_child: [{
                        type: "social",
                        query:
                           must: [
                              {"content.page_remote_id": match: page_remote_id}
                           ]
                     }]
                  ]
               success: ->
                  callback?(contacts.toCollectionWrapper())

         setContactMatch: (profile_id, contact_id) ->
            profile = all_profiles.get(profile_id)
            profile?.set("contact_id", contact_id)
            all_profiles.remove(profile)
            all_profiles.trigger("reset")

   PostEngagerStore()
