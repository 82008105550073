import { Component } from "react";
import _ from "underscore";
import EverTrue from "app";
import {
  ModalHeaderDeprecated,
  ModalFooterWithButtons,
  Avatar,
  SearchBox,
  Radio,
  Loading,
} from "@evertrue/et-components";
import Modal from "components/modals/modal";
import FilterConfig from "apps/filters/filter-config";
import ContactCard from "apps/contact/components/contact-card/contact-card";
import Api from "entities/helpers/api";
import str from "underscore.string";
import Decorator from "clientDecorator";
import ErrorLogger from "entities/helpers/error-log-helper";
import { Link } from "@evertrue/et-components";

const { object, func } = ReactLibs.PropTypes;

const getInitialQuery = (profile = {}) => {
  const { name, email } = profile;
  return name || str.humanize(_.first((email || "").split("@"))) || "";
};

const getProfileDisplay = (profile = {}) => {
  const { name, email } = profile;
  if (name && email) {
    return `'${name} <${email}>'`;
  }
  return `'${name || email || "Unknown Eventbrite Proifle"}'`;
};

const getContactDisplay = (contact = {}) => {
  const name = Decorator.Contacts.getFullName(contact);
  return name ? `'${name}'` : `constituent with ID ${contact.id}`;
};

class EventbriteMatchModal extends Component {
  static propTypes = {
    profile: object,
    onMatchCompleted: func,
  };

  static defaultProps = {
    profile: {},
    onMatchCompleted: _.noop,
  };

  state = {
    loading: false,
    results: [],
    selectedContactId: undefined,
    saving: false,
  };

  componentDidMount() {
    this.debouncedSearchContacts = _.debounce(this.searchContacts.bind(this), 300);
    this.searchContacts(getInitialQuery(this.props.profile));
  }

  componentWillUnmount() {
    this.debouncedSearchContacts.cancel();
  }

  searchContacts(val) {
    const value = val || "";
    const query = FilterConfig.full_name.query_fn(value.trim());

    this.setState({ loading: true });

    Api.CONTACTS.SEARCH.post({
      data: _.jsonStringify(query),
      params: { limit: 5 },
      error: (xhr) => {
        this.setState({ loading: false, results: [] });
        ErrorLogger.captureRequest("searchContactsByName error", xhr);
      },
      success: (resp) => {
        this.setState({
          loading: false,
          results: _.map(resp.items, Decorator.Contacts.parse),
        });
      },
    });
  }

  matchProfileToContact({ closeModal }) {
    const { profile } = this.props;
    const { results, selectedContactId } = this.state;
    const contact = _.findWhere(results, { id: selectedContactId });

    const { email } = profile;
    const profile_display = getProfileDisplay(profile);
    const contact_display = getContactDisplay(contact);
    const contact_id = contact.id;

    this.setState({ saving: true });

    // we have to use encodeURIComponent here because '+' signs are spaces for urls
    // and alex is a butthead and didn't put this in a post body which would have made
    // everything easier

    Api.EVENTS.EVENTBRITE_MATCH.post({
      params: { contact_id, email: encodeURIComponent(email), op: "match" },
      disableAlerts: true,
    })
      .then(() => {
        const href = `/contact/${contact.id}`;
        EverTrue.track.set("identity_matching", {
          type: "match",
          source: "Eventbrite",
          referrer: "event",
        });
        EverTrue.Alert.success(
          <div>
            {`Successfully matched ${profile_display} to `}
            <Link href={href} title={`${contact_display}'s profile`}>
              {contact_display}
            </Link>
            .
          </div>
        );
        this.props.onMatchCompleted();
        closeModal();
      })
      .catch((xhr) => {
        const { code, errors, message } = xhr.responseJSON || {};

        if (code === "IDENTITY_EXISTS") {
          const href = errors && errors.contact_id && `/contact/${errors.contact_id}`;
          EverTrue.Alert.error(
            <div>
              {`${profile_display} is already matched to a `}
              {href ? (
                <Link href={href} title="Constituent profile">
                  constituent
                </Link>
              ) : (
                "constituent"
              )}
              .
            </div>
          );
        } else {
          EverTrue.Alert.error(message || `Problem matching ${profile_display} to contact with ID ${contact_id}`);
        }
        ErrorLogger.captureRequest("Eventbrite match error", xhr);
        this.setState({ saving: false });
      });
  }

  renderResults() {
    if (this.state.loading) {
      return (
        <div className="eventbrite-match-modal--contact-search-empty">
          <Loading size="small" />
        </div>
      );
    } else if (_.isEmpty(this.state.results)) {
      return (
        <div className="eventbrite-match-modal--contact-search-empty">
          <div>No Results</div>
        </div>
      );
    }
    return (
      <div className="eventbrite-match-modal--contact-search-results">
        {_.map(this.state.results, (contact) => (
          <div className="eventbrite-match-modal--contact-search-result" key={contact.id}>
            <ContactCard contact={contact} />
            <Radio
              checked={this.state.selectedContactId === contact.id}
              onChange={() => this.setState({ selectedContactId: contact.id })}
            />
          </div>
        ))}
      </div>
    );
  }

  render() {
    const { profile } = this.props;
    const { selectedContactId, saving } = this.state;
    const { name, email, avatar } = profile;
    const isDisabled = saving || !selectedContactId;

    return (
      <Modal
        removeOverlayOnHide
        width={500}
        render={({ onHide }) => (
          <div>
            <ModalHeaderDeprecated header="Match to Constituent" closeModal={onHide} />
            <div className="eventbrite-match-modal--account-wrapper">
              <div className="eventbrite-match-modal--account-title">Eventbrite Account Information</div>
              <div className="eventbrite-match-modal--account-data">
                <Avatar className="fs-exclude-data" url={avatar} name={name} />
                <div className="eventbrite-match-modal--account-text">
                  <span className="fs-exclude-data">{name}</span>
                  <div className="eventbrite-match-modal--account-email">
                    <span className="fs-exclude-data">{email}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="eventbrite-match-modal--contact-search-wrapper">
              <SearchBox
                className="search-box--et-components-shim fs-exclude-data"
                placeholder="Search for Constituent by Name or Email Address…"
                defaultValue={getInitialQuery(profile)}
                onChange={this.debouncedSearchContacts}
              />
              <div className="eventbrite-match-modal--results-wrapper">{this.renderResults()}</div>
            </div>
            <ModalFooterWithButtons
              disableSave={isDisabled}
              saveLabel="Match to Constituent"
              onSubmit={() => this.matchProfileToContact({ closeModal: onHide })}
              onCancel={onHide}
              closeModal={onHide}
              closeOnSubmit={false}
            />
          </div>
        )}
      />
    );
  }
}

export default EventbriteMatchModal;
