module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   EverTrue = require("app")
   {div, h3, span, table, thead, tr, th, tbody, td} = ReactLibs.DOMFactories
   {createComponent} = require("@evertrue/et-flux")
   UAParser = require("ua-parser-js")
   moment = require("moment")
   {createFactory} = require("base/new-utils")
   Module = createFactory require("components/module").default
   SecuritySource = require("apps/settings/security-settings/sources/security-source")
   SecurityStore = require("apps/settings/security-settings/stores/security-store")
   str = require("underscore.string")
   Button = createFactory(require("@evertrue/et-components").Button)

   createComponent "SecuritySessionManager",

      registerStores: ->
         @on SecurityStore, ->
            activeSessions: SecurityStore.getActiveSessions()

      UNSAFE_componentWillMount: ->
         SecuritySource.fetchActiveSessions()

      extractDevice: (useragentString) ->
         result = UAParser(useragentString)

         string = ""
         browserName = result.browser.name
         deviceName = result.device.model
         osName = result.os.name
         osVersion = result.os.version

         string += "#{browserName} on " if browserName
         string += deviceName if deviceName
         string += "#{result.os.name}" if osName
         string += " (#{result.os.version})" if osName && osVersion

         string = "Unable to determine device" if string.length == 0
         string

      formatDate: (date) ->
         if date? then moment(date, "x").format("MMMM DD, YYYY") else "n/a"

      formatLastSeenDate: (date) ->
         if date? then moment(date, "x").format("MMMM DD, YYYY, h:mm:ss a") else "n/a"

      buildApplicationsString: (apps) ->
         if _.notEmpty(apps)
            "Currently logged in to " + _.map(apps, str.capitalize).join(" and ")

      handleDestroyOtherSessions: ->
         EverTrue.Alert.confirm
            headline: "Sign out of all other devices?"
            content: "You will be signed out of EverTrue everywhere else."
            buttonLabel: "Sign Out"
         , (did_confirm) -> if did_confirm then SecuritySource.destroyOtherSessions()

      handleDestroySession: (session) ->
         SecuritySource.destroySession session.key, @extractDevice(session.data.useragent)

      render: ->
         Module noMargin: true, className: "security-session-manager--module", header: "Active Sessions",
            div className: "security-session-manager",
               div className: "security-session-manager--actions",
                  span className: "security-session-manager--help-text",
                     "The following devices are currently signed into your account."
                  span className: "security-session-manager--logout-container",
                     Button
                        type: "destroy"
                        className: "security-session-manager--logout-button"
                        onClick: @handleDestroyOtherSessions
                        "Sign out of all other sessions"

               table className: "security-session-manager--active-device-table",
                  thead className: "security-session-manager--table-headers",
                     tr null,
                        th className: "security-session-manager--table-header", "Device"
                        th className: "security-session-manager--table-header", "Last Seen"
                        th className: "security-session-manager--table-header", "Created On"
                        th className: "security-session-manager--table-header", "Sign out from Device"

                  tbody className: "security-session-manager--table-body",
                     _.map @state.activeSessions, (session) =>
                        tr className: "security-session-manager--table-row", key: session.key,
                           td className: "security-session-manager--device",
                              div
                                 className: "security-session-manager--device-name"
                                 title: session.data.useragent
                                 @extractDevice(session.data.useragent)

                              div className: "security-session-manager--device-app",
                                 @buildApplicationsString(session.applications)
                           if session.is_current
                              td
                                 className: "security-session-manager--last-seen"
                                 title: @formatLastSeenDate(session.last_activity_at)
                                 "Now"
                           else
                              td
                                 className: "security-session-manager--last-seen"
                                 title: @formatLastSeenDate(session.last_activity_at)
                                 "#{session.last_activity_at_relative} ago"
                           td className: "security-session-manager--session-status", @formatDate(session.created_at)
                           if session.is_current
                              td className: "security-session-manager--delete-session security-session-manager--current-device", "CURRENT DEVICE"
                           else
                              td className: "security-session-manager--delete-session",
                                 Button
                                    type: "secondary"
                                    onClick: => @handleDestroySession(session)
                                    "Sign out"
