import _ from "underscore";
import { useState } from "react";
import PropTypes from "prop-types";
import { useIdentity } from "base/identity-resolver";
import DashboardNav from "apps/dashboard/components/dashboard-nav";
import AddRemoveWidgetModal from "apps/dashboard/components/add-remove-widget-modal";
import PageHeader from "apps/layout/components/page-header";
import ActionMenuItem from "components/controls/action-menu-item";
import DNAStore from "base/dna/dna-store";
import UserDashboard from "apps/dashboard/components/user-dashboard";
import { connect } from "@evertrue/et-flux";
import DashboardMetaDataContext, {
  DashboardMetaDataProvider,
} from "apps/dashboard/contexts/dashboard-meta-data-provider";

const mapStateToProps = () => ({
  daysUntilFiscalYearEnd: DNAStore.getDaysUntilFiscalYearEnd(),
});

// dashboard for individual user/solicitor
const UserDashboardController = ({ user_id, daysUntilFiscalYearEnd }) => {
  const [reorder, setReorder] = useState(false);
  const [reloading, setReloading] = useState(false);
  const { user, org } = useIdentity();
  const [showWidgetModal, setShowWidgetModal] = useState(false);

  const toggleReorder = (can_reorder) => {
    setReorder(can_reorder);
    setReloading(true);
    _.delay(() => setReloading(false), 1000);
  };

  const dashboardActions = (user_id) => {
    return (
      <>
        <ActionMenuItem
          title="Launchpad Help"
          icon="question-circle"
          openNewTab={true}
          href="https://help.evertrue.com/t/m1hwg8h/all-about-launchpad-widgets"
        >
          Go to Launchpad Help
        </ActionMenuItem>
        {user.id === user_id && (
          <>
            <ActionMenuItem
              icon="settings"
              title="Reorder widgets"
              onClick={() => {
                toggleReorder(!reorder);
              }}
            >
              {`${!reorder ? "Enable" : "Disable"} Drag to Reorder`}
            </ActionMenuItem>
            <ActionMenuItem
              icon="settings"
              title="Add or remove widgets from your launchpad"
              onClick={() => setShowWidgetModal(true)}
            >
              Add/Remove Widgets
            </ActionMenuItem>
          </>
        )}
      </>
    );
  };

  return (
    <DashboardMetaDataProvider user={user} org={org} routeUserId={user_id}>
      <DashboardMetaDataContext.Consumer>
        {({ user_id }) => {
          return (
            <>
              <AddRemoveWidgetModal
                isOpen={showWidgetModal}
                close={() => setShowWidgetModal(false)}
                userId={user_id}
                refreshWidgets={() => toggleReorder(false)}
              />
              <PageHeader
                title={<div className="dashboard-title">Launchpad</div>}
                subtitle={
                  <div>
                    <span className="dashboard-days">{daysUntilFiscalYearEnd}</span>
                    <span className="dashboard-days--subtext"> Days left in FY</span>
                  </div>
                }
                actions={dashboardActions(user_id)}
              />
              <DashboardNav tab="user" />
              <UserDashboard reorder={reorder} toggleReorder={toggleReorder} reloading={reloading} />
            </>
          );
        }}
      </DashboardMetaDataContext.Consumer>
    </DashboardMetaDataProvider>
  );
};

UserDashboardController.propTypes = {
  user_id: PropTypes.number,
  daysUntilFiscalYearEnd: PropTypes.number,
};

export default connect(UserDashboardController, [DNAStore], mapStateToProps);
