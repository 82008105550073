module.exports = do ->
   _ = require("underscore").default
   {createStore} = require("@evertrue/et-flux")
   TripLogSource = require("apps/my-trips/sources/trip-log-source")
   ExportSource = require("apps/export/sources/export-source")
   MyTripsStore = require("apps/my-trips/stores/my-trips-store")
   ProfileInteractionsSource = require("apps/profile/sources/profile-interactions-source").default
   InteractionSource = require("apps/interactions/sources/interaction-source").default
   InteractionStore = require("apps/interactions/stores/interaction-store").default


   createStore "TripLogStore",
      getInitialState: ->
         loading: false
         trip_interactions: {}

      registerActions: ->
         @on TripLogSource.actions.loading, @respondToLoading
         @on TripLogSource.actions.fetchedInteractions, @respondToFetch

         @on TripLogSource.actions.startExport, @respondToStartExport
         @on TripLogSource.actions.exportedTripLog, @respondToExported

         @on ProfileInteractionsSource.actions.createdNote, @respondToSave
         @on ProfileInteractionsSource.actions.deletedNote, (note) =>
            @respondToSave(note.id, note)

         # Needs the formatting from the updates here for gt_comments
         @on InteractionSource.actions.changedInteractions, -> @trigger()

      respondToLoading: (is_loading) ->
         @setState {loading: is_loading}

      respondToFetch: (trip_id, interactions, options={}) ->
         trip_notes = _.cloneData(@getState("trip_interactions"))
         trip_notes[trip_id] = interactions
         unless options.prevent_full_load
            InteractionSource.loadInteractions(interactions.items)
         @setState {trip_interactions: trip_notes}

      respondToSave: (target_id, resp) ->
         if @hasListeners()
            @setState {loading: true}
            _.wait 1000, ->
               TripLogSource.fetchInteractions(resp.trip_id)

      respondToStartExport: (trip_id) ->
         begin_date = _.min(_.pluck(@getForTrip(trip_id), "created_at"))
         name = MyTripsStore.getTrip(trip_id)?.name
         TripLogSource.export(trip_id, name, begin_date)

      respondToExported: ->
         ExportSource.start()

      api:
         getLoading: ->
            @getState("loading")

         getForTrip: (trip_id) ->
            trip_interactions = @getState("trip_interactions")[trip_id]?.items
            _.map trip_interactions, (interaction) ->
               InteractionStore.getInteraction(interaction.id) || {}

         getTotalForTrip: (trip_id) ->
            @getState("trip_interactions")[trip_id]?.total

         getExportableForTrip: (trip_id) ->
            _.filter @getForTrip(trip_id), (interaction) ->
               interaction.interaction_type != "EverTrue Comment"
