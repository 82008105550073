import _ from "underscore";
import { useEffect } from "react";
import { useLocalStorage } from "@evertrue/et-components";

const useSavedTeam = (teamOptions, teamCount) => {
  const [teamOption, setTeamOption] = useLocalStorage("launchpad_solicitor_team", "All");

  // Default team to local storage value if exists in array of teams else first option
  useEffect(() => {
    if (teamCount > 0) {
      const team = _.findWhere(teamOptions, { value: teamOption }) ? { value: teamOption } : teamOptions[0];
      setTeamOption(team.value);
    }
  }, [teamOptions, teamOption, teamCount, setTeamOption]);

  return [teamOption, setTeamOption];
};

export default useSavedTeam;
