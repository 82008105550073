import { useEffect, useState } from "react";

const useInfiniteScroll = (ref, onLoadMore, total, limit) => {
  const [offset, setOffset] = useState(limit);
  const [loading, setLoading] = useState(false);

  const resetOffset = () => {
    setOffset(limit);
  };

  useEffect(() => {
    const handleScroll = async (e) => {
      if (loading) return;
      const bottom = Math.trunc(e.target.scrollHeight - e.target.scrollTop) === e.target.clientHeight;
      if (bottom && total - offset >= 0) {
        setLoading(true);
        try {
          await onLoadMore(offset);
          setOffset((prevOffset) => prevOffset + limit);
        } catch (e) {
        } finally {
          setLoading(false);
        }
      }
    };

    const div = ref.current;
    div.addEventListener("scroll", handleScroll);

    return () => {
      div.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, onLoadMore]);

  return [loading, resetOffset];
};

export default useInfiniteScroll;
