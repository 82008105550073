module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")

   createSource "SecuritySource",
      actions:
         currentUserMFAStatus: (mfa_state, otp_mobile) ->
            @require(!mfa_state? || _.isString(mfa_state), "mfa_state should be a string")
            @require(!otp_mobile? || _.isString(otp_mobile), "formatted mobile number should be a string")

         codeInFlight: true
         newWizardStep: (number) ->
            @require _.isFinite(number), "wizard step should be a number"

         unableToRequestCode: true
         enableMFAError: true
         activeSessions: true
         destroySessionSuccess: true
         destroySessionFailure: true
         refreshSession: true

      trustDevice: (trust_device = true) ->
         trust_val = if trust_device then 1 else 0
         Api.AUTH.SKIFF.put
            headers: {"Authorization-Trust-Device": trust_val}
            xhrFields: {withCredentials: true}
            success: =>
               EverTrue.store.push("mfa_trust_device", trust_val)
               @actions.refreshSession()
            error: =>
               @actions.refreshSession()
               Raven?.captureMessage "Error trusting device after MFA setup"

      api:
         refreshUserMFAStatus: ->
            Api.AUTH.USER_MFA_SETTINGS.get
               success: (resp) =>
                  {mfa_state, otp_mobile} = resp
                  @actions.currentUserMFAStatus(mfa_state, otp_mobile)


         getOTPCode: (phoneNumber) ->
            Api.AUTH.USER_MFA_SETTINGS.put
               data: _.jsonStringify {"otp_medium": "sms", "otp_mobile": phoneNumber}
               success: (resp) =>
                  @actions.codeInFlight true
                  @actions.newWizardStep 2
               error: =>
                  @actions.unableToRequestCode true

         resendOTPCode: ->
            Api.AUTH.OTP.post()

         goToWizardPage: (page) ->
            @actions.newWizardStep(page)

         resetWizard: ->
            @actions.newWizardStep 0

         enableMFA: (otpCode, phoneNumber, trustDevice) ->
            Api.AUTH.USER_MFA_SETTINGS.put
               data: _.jsonStringify {"otp_medium": "sms", "otp_mobile": phoneNumber}
               headers: {"Authorization-OTP": otpCode}
               success: (resp) =>
                  {mfa_state, otp_mobile} = resp
                  if mfa_state is "enabled"
                     if trustDevice
                        @trustDevice()
                     else
                        @actions.refreshSession()

                     EverTrue.Alert.success "Two Step Verification is on! You have successfully enabled two step verification for #{phoneNumber}"

                     @actions.currentUserMFAStatus(mfa_state, otp_mobile)
                  else
                     data = _.pick(this, ["type", "url", "forceRequest"])
                     Raven?.captureMessage("received a successful response without MFA enabled", extra: data)

               error: (xhr, error_info) =>
                  if xhr.responseJSON
                     {message} = xhr.responseJSON
                     @actions.enableMFAError(message)

         disableMFA: ->
            Api.AUTH.USER_MFA_SETTINGS.put
               headers: "Authorization-Auto-Send": 1
               data: _.jsonStringify {"otp_medium": null}
               success: (resp) =>
                  EverTrue.Alert.success "Two Step Verification has been disabled for your account."
                  {mfa_state, otp_mobile} = resp
                  @actions.currentUserMFAStatus(mfa_state, otp_mobile)
                  @actions.refreshSession()
               error: ->
                  @actions.refreshSession()
                  Raven?.captureMessage "Error disabling MFA"

         fetchActiveSessions: ->
            Api.AUTH.ACTIVE_SESSIONS.get
               success: (resp) =>
                  @actions.activeSessions resp

         destroySession: (sessionKey, device) ->
            Api.AUTH.ACTIVE_SESSIONS.delete
               urlExtend: "/#{sessionKey}"
               success: (resp) =>
                  EverTrue.Alert.success "Successfully logged out of #{device}"
                  @actions.destroySessionSuccess sessionKey
               error: (resp) =>
                  EverTrue.Alert.error "Unable to log out of #{device}. Please try again."
                  @actions.destroySessionFailure sessionKey

         destroyOtherSessions: ->
            Api.AUTH.ACTIVE_SESSIONS.delete
               urlExtend: "/others"
               success: (resp) =>
                  EverTrue.Alert.success "You have successfully logged out of all other devices."
                  @fetchActiveSessions()
               error: (resp) ->
                  EverTrue.Alert.error "Unable to log out of all other devices. Please try again."
