{dd, dl, dt} = ReactLibs.DOMFactories

module.exports = do ->
   ReactLibs.createClass
      displayName: "Datalist"

      propTypes:
         label: ReactLibs.PropTypes.any

      render: ->
         dl className: @props.className || "",
            dt null, @props.label
            dd null, @props.children

