module.exports = do ->
   _ = require("underscore").default
   {createComponent} = require("@evertrue/et-flux")
   React = require("react")
   {createFactory} = require("base/new-utils")
   AdvancedCombobox = createFactory(require("@evertrue/et-components").AdvancedCombobox)
   ProspectColumnStore = require("apps/major-gifts/stores/prospect-column-store")


   createComponent "ProspectStatusDropdown",
      propTypes:
         value: ReactLibs.PropTypes.any
         onChange: ReactLibs.PropTypes.func
         placeholder: ReactLibs.PropTypes.string
         multiple: ReactLibs.PropTypes.bool
         includeNone: ReactLibs.PropTypes.bool
         includeNoStatus: ReactLibs.PropTypes.bool

      getDefaultProps: ->
         value: undefined
         multiple: false
         placeholder: "Select Options..."
         includeNoStatus: true

      registerStores: ->
         @on ProspectColumnStore, ->
            active_columns: @filterColumns(ProspectColumnStore.getActiveColumns())
            other_columns: @filterColumns(ProspectColumnStore.getAvailableColumns())

      filterColumns: (columns) ->
         _.filter columns, (col) =>
            !(!@props.includeNoStatus && col.label == "No Status")

      render: ->
         options = _.reject [
            {label: "Active Statuses", items: @state.active_columns}
            {label: "Inactive Statuses", items: @state.other_columns}
         ], (group) -> _.isEmpty(group.items)

         if @props.includeNone
            options.unshift {items: [{label: "None", value: null}]}

         AdvancedCombobox
            options: options
            value: @props.value
            grouped: true
            searchable: true
            multiple: @props.multiple
            disabled: @props.disabled
            placeholder: @props.placeholder
            allowChangeAll: true
            allowUnselectAll: @props.multiple
            className: @props.className
            onChange: @props.onChange
