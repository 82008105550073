_ = require("underscore").default
EverTrue = require("app")

   # Create the EverTrue.store object, which is a
   # client side caching system, pass in {cache: true}
   # to data store in LocalStorage
class Store
   prefix: "et:store:"
   global_prefix: "et:global:"

   push: (key, object, options={}) ->
      @[key] = object
      EverTrue.vent?.trigger("add:" + key)
      if options.cache && localStorage
         @_setToStorage(key, object, options)

   destroy: (key, is_global) ->
      if key
         if _.isFunction(@[key]?.clear) then @[key].clear() else delete @[key]
         prefix = if is_global then @global_prefix else @prefix
         localStorage.removeItem(prefix + key) if localStorage
      else
         _.each @, (object, key) =>
            if _.isFunction(@[key]?.clear) then @[key].clear() else delete @[key]
            localStorage.removeItem(@prefix + key) if localStorage

   fetch: (key, model, is_global) ->
      if localStorage
         prefix = if is_global then @global_prefix else @prefix
         data = localStorage.getItem(prefix + key)
         try
            json_data = JSON.parse(data)
         catch
            json_data = {}
         @[key] = if model then new model(json_data) else json_data

   cache: (key, options={}) ->
      @_setToStorage(key, @[key], options) if localStorage

   _setToStorage: (key, object, options) ->
      prefix = if options.acrossSession then @global_prefix else @prefix
      try
         localStorage.setItem(prefix + key, JSON.stringify(object))
      catch
         _.each @, (value, key) =>
            unless key in ["org", "session", "user", "lids_token"]
               localStorage.removeItem(@prefix + key)
               localStorage.removeItem(@global_prefix + key)
         try
            localStorage.setItem(prefix + key, JSON.stringify(object))

module.exports = Store
