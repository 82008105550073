module.exports = do ->
   _ = require("underscore").default
   {createStore} = require("@evertrue/et-flux")

   MfaSource = require("base/mfa/mfa-source")

   # statuses = [inactive, prompting, requesting, locked]

   getDefaults = ->
      status: "inactive"
      error: ""
      number: ""
      resend_status: "ready"
      resend_count: 0
      is_trust_request: false
      mfa_prompt_text: undefined

   createStore "MfaStore",
      getInitialState: ->
         getDefaults()

      registerActions: ->
         @on MfaSource.actions.newStatus, (status) ->
            switch status
               when "inactive" then @setState getDefaults()
               when "requesting" then @setState error: ""
            @setState status: status

         @on MfaSource.actions.isTrustRequest, ->
            @setState is_trust_request: true

         @on MfaSource.actions.newError, (error) ->
            @setState error: error

         @on MfaSource.actions.newMfaNumber, (number) ->
            @setState number: number

         @on MfaSource.actions.newResendStatus, (status) ->
            @setState resend_status: status
            if status is "requesting"
               @setState resend_count: @getState("resend_count") + 1

         @on MfaSource.actions.newMfaPromptText, (promptText) ->
            @setState mfa_prompt_text: promptText

      api:
         getStatus: ->
            @getState("status")

         getError: ->
            @getState("error")

         getNumber: ->
            @getState("number")

         getResendStatus: ->
            @getState("resend_status")

         showTrustDevice: ->
            @getState("is_trust_request")

         getPromptText: ->
            @getState("mfa_prompt_text")
