module.exports = do ->
   _ = require("underscore").default
   {createStore} = require("@evertrue/et-flux")
   Decorator = require("clientDecorator")
   PoolProspectsSource = require("apps/volunteers/sources/pool-prospects-source")
   PoolAssignmentsSource = require("apps/volunteers/sources/pool-assignments-source")
   PaginationUtils = require("mixins/pagination-utils")
   Query = require("entities/search/query-parts/query")
   Parameter = require("entities/search/query-parts/parameter-query")
   Property = require("entities/search/query-parts/property-query")

   LIMIT = 50
   ES_DELAY = 1000

   FILTERS =
      pool: (pool_id) ->
         Query [
            Parameter "must", [
               Property "pool_id", pool_id, {type: "equals"}
               Property "parent_role", "prospect", {type: "equals"}
            ]
         ]

      assigned: (assigned) ->
         if _.isNumber(assigned)
            property = Property "solicitor_contact_id", assigned, {type: "equals"}
         else
            property = Property "solicitor_contact_id", !!assigned, {type: "exists"}
         Query([ Parameter "must", [property] ])


   createStore "PoolProspectsStores",
      getInitialState: ->
         loading_pools: []
         totals_by_pool: {}
         prospects_by_pool: {}
         selected: []
         filters: {}

      registerActions: ->
         @on PoolProspectsSource.actions.loading, @respondToLoading
         @on PoolProspectsSource.actions.fetch, @respondToStartFetch
         @on PoolProspectsSource.actions.fetchedContacts, @respondToSearchedContacts

         @on PoolProspectsSource.actions.setProspectTotal, @respondToSetTotal

         @on PoolProspectsSource.actions.updateFilter, @respondToFilter
         @on PoolProspectsSource.actions.paginate, @respondToPaginate

         @on PoolProspectsSource.actions.select, @respondToSelected
         @on PoolProspectsSource.actions.clearSelection, => @setState selected: []

         # Assignment change should refresh but not load prospects
         respondToAssignWithFilters = (pool_id) =>
            if !_.isEmpty(@getState("filters")) then @respondToAssignment(pool_id, false)
         @on PoolAssignmentsSource.actions.assign, respondToAssignWithFilters
         @on PoolAssignmentsSource.actions.unassign, respondToAssignWithFilters

         # Joint Assignment Change should load
         @on PoolAssignmentsSource.actions.join, @respondToAssignment
         @on PoolAssignmentsSource.actions.unjoin, @respondToAssignment
         @on PoolAssignmentsSource.actions.swapJoin, @respondToAssignment

      respondToLoading: (pool_id, is_loading) ->
         loading_pools = @getState("loading_pools")
         if is_loading then loading_pools.push(pool_id)
         else loading_pools = _.without(loading_pools, pool_id)
         @setState {loading_pools}

      respondToStartFetch: (pool_id) ->
         @fetchWithQuery(pool_id)

      respondToSearchedContacts: (pool_id, contacts) ->
         prospects_by_pool = _.cloneData @getState("prospects_by_pool")
         merged_data = PaginationUtils.mergePagedResults(prospects_by_pool[pool_id], contacts)
         merged_data.items = _.uniq(merged_data.items, false, (item = {}) -> item.id)
         prospects_by_pool[pool_id] = merged_data
         @setState {prospects_by_pool: prospects_by_pool}

         PoolAssignmentsSource.fetchFromContacts(pool_id, _.pluck(contacts.items, "id"))

      respondToSetTotal: (pool_id, total) ->
         totals_by_pool = _.cloneData @getState("totals_by_pool")
         totals_by_pool[pool_id] = total
         @setState {totals_by_pool: totals_by_pool}

      respondToFilter: (pool_id, key, value) ->
         filters = _.cloneData @getState("filters")
         filters[key] = value
         @setState {filters: _.compactObject(filters)}

         # with a filter change (assigned -> unassigned),
         # pass API call params to fetch beginning of list and not merge it
         @fetchWithQuery(pool_id, null, {offset: 0, limit: LIMIT})

      respondToPaginate: (pool_id, page) ->
         prospects_data = @getState("prospects_by_pool")?[pool_id]
         @fetchWithQuery(pool_id, PaginationUtils.paginate(prospects_data))

      respondToSelected: (pool_id, id) ->
         selected = @getState("selected") || []
         if _.contains(selected, id)
            selected = _.without(selected, id)
         else selected.push(id)
         @setState {selected: selected}

      respondToAssignment: (pool_id, load=true) ->
         @respondToLoading(pool_id, load)
         _.wait ES_DELAY, =>
            @fetchWithQuery(pool_id, null, null, load)
            PoolProspectsSource.fetchTotal(pool_id)

      fetchWithQuery: (pool_id, prospects_data, params = null, load=true) ->
         prospects_data ?= @getState("prospects_by_pool")[pool_id]
         query_params = params || _.pick(prospects_data, "offset", "limit")
         if _.isEmpty(query_params)
            query_params = {offset: 0, limit: LIMIT}

         filters = _.compactObject @getState("filters")
         parent_filters = _.omit(filters, _.keys(FILTERS))
         assignment_filters = _.pick(filters, _.keys(FILTERS))

         assignment_query = FILTERS.pool(pool_id)
         _.each assignment_filters, (filter_value, key) ->
            if FILTERS[key]
               assignment_query.merge(FILTERS[key](filter_value))

         query = _.extend {}, Decorator.Filters.getQuery(parent_filters)?.toJSON(),
            sort: [
               {"name_last.untouched": order: "asc"}
               {"name_first.untouched": order: "asc"}
            ]
            has_child: [{
               type: "assignment"
               query: assignment_query.toJSON()
            }]

         PoolProspectsSource.search(pool_id, query, query_params, load)

      api:
         getForPool: (pool_id) ->
            _.clone @getState("prospects_by_pool")?[pool_id]

         getTotalForPool: (pool_id) ->
            @getState("totals_by_pool")[pool_id]

         getLoading: (pool_id) ->
            _.contains(@getState("loading_pools"), pool_id)

         getByContactId: (pool_id, contact_id) ->
            _.findWhere @getForPool(pool_id)?.items, {id: contact_id}

         getSelected: ->
            @getState("selected")

         getFilters: ->
            @getState("filters")

         hasMoreToLoad: (pool_id) ->
            prospects_data = @getForPool(pool_id)
            !PaginationUtils.isFinalPage(prospects_data)
