module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   {createStore} = require("@evertrue/et-flux")
   ShareSegmentSource = require("apps/filters/sources/share-segment-source")
   FilterSource = require("apps/filters/sources/filter-source")
   ContactSource = require("apps/contact/sources/contact-source")
   UrlSource = require("apps/layout/sources/url-source")
   FilterStore = require("apps/filters/stores/filter-store")
   FilterConfigStore = require("apps/filters/stores/filter-config-store")
   ContactQueryStore = require("apps/contact/stores/contact-query-store")
   FeatureStore = require("apps/layout/stores/feature-store").default
   GateSource = require("apps/layout/sources/gate-source")
   PropertySource = require("apps/contact/sources/property-source")


   createStore "ShareSegmentStore",
      getInitialState: ->
         creating_link: false
         search_map: {}
         active_search_hash: undefined
         temp_active_data: undefined

      registerActions: ->
         @on ShareSegmentSource.actions.creatingSharedLink, (navigate) ->
            if navigate then @setState creating_link: true
         @on ShareSegmentSource.actions.createdShareLink, (data, navigate) ->
            @respondToCreate(data, navigate)
            if navigate then @setState creating_link: false
         @on ShareSegmentSource.actions.errorCreatingSharedLink, (navigate) ->
            if navigate then @setState creating_link: false

         @on ShareSegmentSource.actions.sentShareLink, @respondToSent
         @on ShareSegmentSource.actions.clearActiveLink, @respondToClear
         @on ShareSegmentSource.actions.fetchedFilters, @respondToFetchedFilters
         @on ShareSegmentSource.actions.errorFetchingFilters, @respondToFetchedError

         @on UrlSource.actions.reachedLimit, @respondToUrlLimit
         @on GateSource.actions.fetchedGates, @respondToFetchedDependencies
         @on PropertySource.actions.fetchedSchema, @respondToFetchedDependencies

      respondToCreate: (data, navigate) ->
         mapping = @getState("search_map")
         mapping[data.hash_id] = data

         @setState
            active_search_hash: data.hash_id
            search_map: mapping

         if navigate
            type = if _.findWhere(data.filters, {id: "map"}) then "map" else "search"
            EverTrue.UrlManager.set("sharer_id", data.user_id)
            EverTrue.UrlManager.navigate("contact/#{type}/#{data.hash_id}", trigger: true)

      respondToSent: (data, user_ids) ->
         if count = user_ids.length
            text = if count == 1 then "colleague" else "colleagues"
            EverTrue.Alert.success "Shared current search criteria with #{count} #{text}."
         @respondToCreate(data)

      respondToClear: ->
         @setState {active_search_hash: undefined}

      respondToFetchedFilters: (data) ->
         # Filters rely on gates and the filters list to determine if the configuration is available to
         # current user, so this checks that gates have returned before setting.
         if FeatureStore.hasFeaturesLoaded() && FilterConfigStore.hasLoaded()
            @parseAndSetData(data)
         else @setState {temp_active_data: data}

      respondToFetchedError: (hash_id) ->
         Raven.captureMessage("Fetch Shared Search Failed", extra: {hash_id: hash_id})
         EverTrue.Alert.error "We could not find your shared search criteria"
         FilterSource.loading(false)
         EverTrue.UrlManager.navigate("/contact", true)

      respondToUrlLimit: (url) ->               
         if url.filters
            activeFilters = FilterStore.getActiveFilters()
            activeFiltersQueryResult = FilterStore.getActiveFiltersQuery()
            filterCriteria = _.extend({}, activeFiltersQueryResult, ContactQueryStore.getSortQuery())
            filterQuery = FilterStore.getEachFilterQuery()

            if activeFilters.length > 0 && activeFilters[0]?.filter_id == "3.5" && activeFilters[0]?.value?.in
               if activeFiltersQueryResult.must?
                  activeFiltersQueryResult.must[0]["giving.last_gift_date"].in = activeFilters[0]?.value?.in
               else if activeFiltersQueryResult.must_not?
                  activeFiltersQueryResult.must_not[0]["giving.last_gift_date"].in = activeFilters[0]?.value?.in
               filterCriteria = _.extend({}, activeFiltersQueryResult, ContactQueryStore.getSortQuery())

               if filterQuery.length > 0
                  if filterQuery[0]?.criteria?.must?
                     filterQuery[0].criteria.must[0]["giving.last_gift_date"]["in"] = activeFilters[0]?.value?.in
                  else if filterQuery[0]?.criteria?.must_not?
                     filterQuery[0].criteria.must_not[0]["giving.last_gift_date"]["in"] = activeFilters[0]?.value?.in

            ShareSegmentSource.createShareLink
               criteria: filterCriteria
               filters: filterQuery
            , true

      respondToFetchedDependencies: (resp, key) ->
         if FeatureStore.hasFeaturesLoaded() && FilterConfigStore.hasLoaded()
            @parseAndSetData(@getState("temp_active_data"))

      parseAndSetData: (data) ->
         if data
            _.defer ->
               FilterSource.parseAndSet(data.hash_id, data.filters, data.criteria)
               _.defer ->
                  ContactSource.query()
            @setState {temp_active_data: undefined}

      api:
         getIsCreatingLink: ->
            @getState("creating_link")

         getActiveLink: ->
            if hash_id = @getState("active_search_hash")
               search = @getState("search_map")[hash_id]
               if hash_id && search.user_id
                  EverTrue.config.wwwBase + "contact/search/#{hash_id}?sharer_id=#{search.user_id}"
               else "Could not generate link, please contact support."

         getFiltersFor: (hash_id) ->
            @getState("search_map")[hash_id]
