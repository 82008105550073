module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories
   ContactTagList = require("apps/contact/components/contact-tag-list")
   {createFactory} = require("base/new-utils")
   ModalConfirm = createFactory require("components/modals/modal-confirm")


   createComponent "ContactPicker",
      propTypes:
         header: ReactLibs.PropTypes.string
         onSelect: ReactLibs.PropTypes.func

      getInitialState: ->
         selected_contacts: []

      handleSubmit: ->
         @props.onSelect(@state.selected_contacts)
         @props.requestHide()

      render: ->
         ModalConfirm
            className: "contact-picker"
            header: @props.header || "Select Constituents"
            buttonLabel: "Select"
            hasMargin: true
            disableButton: _.isEmpty(@state.selected_contacts)
            onSubmit: @handleSubmit
            onCancel: @props.requestHide

            div null,
               @props.description

            div className: "contact-picker--input clearfix",
               ContactTagList
                  selected: @state.selected_contacts
                  onSelect: (selected) =>
                     @setState {selected_contacts: selected}
