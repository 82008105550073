module.exports = do ->
   React = require("react")
   EverTrue = require("app")
   ContactFormatMixin = require("mixins/contact-format-mixin")
   {createFactory} = require("base/new-utils")
   EllipsisOverflow = createFactory require("components/formatting/ellipsis-overflow")
   {createComponent} = require("@evertrue/et-flux")
   {span} = ReactLibs.DOMFactories
   Link = createFactory(require("@evertrue/et-components").Link)

   createComponent "ContactBusiness",
      propTypes:
         contact: (props) ->
            EverTrue.PropTypes.contactObject(props.contact)

      render: ->
         company = ContactFormatMixin.company(@props.contact)
         industry = ContactFormatMixin.industry(@props.contact)

         EllipsisOverflow className: "contact-card--business",
            span null,
               if company
                  Link
                     className: "fs-exclude-data"
                     href: "company" + ContactFormatMixin.company_link(@props.contact),
                     title: "#{company}"
                     "data-refer": @props.referrer,
                        company

               span null,
                  if company && industry then " | " else " "

               if industry
                  Link
                     className: "fs-exclude-data"
                     href: "industry" + ContactFormatMixin.industry_link(industry)
                     title: "#{industry}"
                     "data-refer": @props.referrer,
                        industry
