module.exports = do ->
   _ = require("underscore").default
   moment = require("moment")
   React = require("react")
   EverTrue = require("app")
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   {div, g} = ReactLibs.DOMFactories
   LayoutSource = require("apps/layout/sources/layout-source")
   ChartContainer = require("components/charts/chart-container")
   ChartTooltip = require("components/charts/chart-tooltip")
   Area = require("components/charts/area")
   Points = require("components/charts/points")
   d3 = require("d3")

   TIMESTAMP_REGEX = /^\d{10}$|^\d{13}$/

   createComponent "AreaChart",
      propTypes:
         data: ReactLibs.PropTypes.array
         height: ReactLibs.PropTypes.number
         defaultWidth: ReactLibs.PropTypes.number
         xFormat: ReactLibs.PropTypes.func
         yFormat: ReactLibs.PropTypes.func
         padding: ReactLibs.PropTypes.object
         onPointHoverIn: ReactLibs.PropTypes.func
         onPointHoverOut: ReactLibs.PropTypes.func
         onPointClick: ReactLibs.PropTypes.func

      getInitialState: ->
         chart_width: @props.defaultWidth
         chart_height: @props.height
         grid_width: @props.defaultWidth
         grid_height: @props.height
         xScale: undefined
         yScale: undefined
         x_axis_scale_ticks: undefined

      UNSAFE_componentWillReceiveProps: (newProps) ->
         if !_.isEqual(newProps.data, @props.data)
            @setState {xScale: undefined, yScale: undefined}
            @handleTooltipHide()

      componentWillUnmount: ->
         @handleTooltipHide()

      handleGetScales: (scale_data) ->
         data = @props.data
         return {} if _.isEmpty(data)

         data = _.map _.groupBy(data, "x"), (group, key) ->
            {x: _.toNumber(key), y: _.sum group, (item) -> item.y}

         y_values = _.pluck(data, "y")
         x_values = _.pluck(data, "x")

         max_x = _.max(x_values)
         min_x = _.min(x_values)
         min_y = _.min(y_values)

         is_timestamp =  TIMESTAMP_REGEX.test(max_x)
         if is_timestamp
            x_scale = d3.time.scale()
               .domain([min_x, max_x])
               .range([0, scale_data.grid_width])
         else
            x_scale = d3.scale.linear()
               .domain([min_x, max_x])
               .range([0, scale_data.grid_width])

         y_scale = d3.scale.linear()
            .domain([0, _.max(y_values)])
            .range([0, scale_data.grid_height])

         if is_timestamp
            if moment(max_x).diff(moment(min_x), "days") <= 7
               x_axis_scale_ticks = "day"
            if moment(max_x).diff(moment(min_x), "months") <= 1
               x_axis_scale_ticks = "week"
            else if moment(max_x).diff(moment(min_x), "years") <= 1
               x_axis_scale_ticks = "month"

         if @depracated_is_mounted
            @setState _.extend {}, scale_data,
               xScale: x_scale
               yScale: y_scale
               x_axis_scale_ticks: x_axis_scale_ticks

      handleGetCoords: (item) ->
         if @state.xScale && @state.yScale
            [@state.xScale?(item.x), @state.yScale?(item.y)]
         else [0, 0]

      handleTooltipShow: (item, position) ->
         if @props.tooltipContent
            if !_.isUndefined(item.original_y)
               data = _.extend {}, item, {y: item.original_y}
            else data = item

            key = _.randomKey()
            content = ChartTooltip null, @props.tooltipContent?(data)
            LayoutSource.overlay(content, _.extend {key: key}, position)
            @setState {tooltip_key: key} if @depracated_is_mounted

      handleTooltipHide: (item, coords) ->
         if @state.tooltip_key
            LayoutSource.removeOverlay(@state.tooltip_key)
            @setState {tooltip_key: undefined} if @depracated_is_mounted

      render: ->
         grouped_data = _.toArray(_.groupBy(_.map(@props.data, _.clone), "series"))
         reversed_groups = grouped_data.reverse()

         x_axis_ticks = @state.xScale?.ticks?()
         if @state.x_axis_scale_ticks
            x_axis_ticks = @state.xScale?.ticks?(d3.time[@state.x_axis_scale_ticks])

         ChartContainer
            className: classNames(@props.className, "area-chart")
            data: @props.data
            height: @props.height
            defaultWidth: @props.defaultWidth
            xFormat: (val) => @props.xFormat(val, @state.x_axis_scale_ticks)
            yFormat: @props.yFormat
            xTicks: x_axis_ticks
            yTicks: @state.yScale?.ticks?(5)
            tooltipContent: @props.tooltipContent
            tooltipData: @state.tooltip
            onSetScales: @handleGetScales
            onGetCoords: @handleGetCoords

            _.map reversed_groups, (group, index) =>
               unless index == (reversed_groups.length - 1)
                  clipData = []
                  totals = _.map group, (item) ->
                     additive_value = _.sum _.rest(reversed_groups, index + 1), (main_group) ->
                        _.findWhere(main_group, {x: item.x})?.y
                     clipData.push _.extend {}, item, {y: additive_value}
                     _.extend {}, item,
                        y: item.y + additive_value
                        original_y: item.y
               else totals = group

               g key: index,
                  Area
                     className: "area-chart--path"
                     data: totals
                     clipData: clipData
                     width: @state.grid_width
                     height: @state.grid_height
                     getCoords: @handleGetCoords

                  Points
                     className: "area-chart--points"
                     data: _.filter totals, (item) ->
                        item.y != 0 || index == (grouped_data.length - 1)
                     width: @state.grid_width
                     height: @state.grid_height
                     getCoords: @handleGetCoords
                     onMouseOver: @handleTooltipShow
                     onMouseOut: @handleTooltipHide
                     onClick: (item) =>
                        @handleTooltipHide()
                        @props.onPointClick?(item)
