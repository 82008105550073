import { Button, Icon } from "@evertrue/et-components";
import EverTrue from "app";
import { PropTypes } from "base/react-libs";

const ActiveJourney = ({ journey = {} }) => {
  const { journey_name, journey_id, day_number, step_number, owner } = journey;
  const ownerName = owner ? owner.name : "EverTrue";

  return (
    <div className="active-journey">
      <Icon icon="cadences" iconKit="gt2" />
      <div>
        <Button type="simple" onClick={() => EverTrue.Navigator(`/cadences/${journey_id}`, true)}>
          {journey_name}
        </Button>
        <p>
          Step {step_number}: Day {day_number}
        </p>
        <p>{ownerName}</p>
      </div>
    </div>
  );
};

ActiveJourney.propTypes = {
  journey: PropTypes.object,
};

export default ActiveJourney;
