import _ from "underscore";
import PropTypes from "prop-types";
import { ListboxMultiselect } from "@evertrue/et-components";
import useFacets from "apps/query-builder/hooks/use-facets";

const proptypes = {
  filterId: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  label: PropTypes.string,
  emptyValue: PropTypes.string,
  isDisabled: PropTypes.bool,
};

const FilterValueMultiSelect = ({
  filterId,
  onChange,
  value = [],
  label = "",
  emptyValue = "Select...",
  isDisabled = false,
}) => {
  const { options, getFacets, loading } = useFacets(filterId);

  let searchQueryTimeout = null;

  let selected = _.map(value, (val) => {
    return _.find(options, { value: val }) || { label: val, value: val };
  });

  const uniqueOptions = _.uniq(options, "value");

  return (
    <ListboxMultiselect
      disabled={isDisabled}
      loading={loading}
      size="medium"
      emptyValue={emptyValue}
      label={label}
      values={selected}
      options={uniqueOptions}
      onChange={(values) => {
        onChange(_.pluck(values, "value"));
      }}
      onSearch={(search) => {
        clearTimeout(searchQueryTimeout);
        searchQueryTimeout = _.wait(500, () => getFacets(search));
      }}
      scrollFetch={(search) => {
        if (uniqueOptions[uniqueOptions.length - 1]) {
          getFacets(search, uniqueOptions[uniqueOptions.length - 1].value);
        }
      }}
      showInfoBar={true}
    />
  );
};

FilterValueMultiSelect.propTypes = proptypes;
export default FilterValueMultiSelect;
