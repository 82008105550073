import BaseRouter from "base/base-router";
import Api from "entities/helpers/api";

class EtHelpRouter extends BaseRouter {
  static initClass() {
    this.prototype.routes = {
      "help(/)(?url=*help_redirect)": "redirect",
    };
  }

  redirect(help_redirect) {
    Api.AUTH.FORUMBEE.post({
      data: JSON.stringify({
        redirect: help_redirect,
      }),
      success: ({url}) => {
        window.location = url
      },
      error: () => {
        window.location = "https://evertrue.forumbee.com/"
      },
    });
  }
}

EtHelpRouter.initClass();
export default EtHelpRouter;
