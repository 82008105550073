module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   {div, strong} = ReactLibs.DOMFactories
   {createComponent} = require("@evertrue/et-flux")
   UserStore = require("apps/users/stores/user-store")
   {createFactory} = require("base/new-utils")
   ModalConfirm = createFactory(require("@evertrue/et-components").ModalConfirm)

   createComponent "UnlinkContact",
      propTypes:
         user: ReactLibs.PropTypes.object

      handleUnlink: ->
         UserStore.updateContactID(@props.user.id, null, null)
         @handleCancel()

      handleCancel: ->
         if _.isFunction @props.requestHide
            @props.requestHide()

      render: ->
         ModalConfirm
            type: "destroy"
            saveLabel: "Unlink"
            header: "Unlink Contact Record?"
            buttonLabel: "Unlink Contact Record"
            onSubmit: @handleUnlink
            onCancel: @handleCancel

            div className: "unlink-user--text",
               "If you unlink the Remote User ID for "
               strong null, @props.user.name
               ", they will no longer see the My Portfolio feature in EverTrue and
               interactions will not tie back to the contact in exports."


