import { PropTypes } from "base/react-libs";
import { Icon, Button } from "@evertrue/et-components";
import { formatDateToLocaleTimeString } from "apps/journeys/utils/helpers/dates";
import EverTrue from "app";
import { useEffect, useState } from "react";

const TripsAndMeetings = ({ tripsAndMeetingDetails }) => {
  const [tripMessage, setTripMessage] = useState();
  const [meetingMessage, setMeetingMessage] = useState();
  const { tripCount = 0, meetingCount = 0, meetings = [] } = tripsAndMeetingDetails || {};

  useEffect(() => {
    let tripMsg = tripCount > 0 ? (tripCount === 1 ? " trip today" : " trips today") : "No trips scheduled today";
    setTripMessage(tripMsg);
    let meetingMsg =
      meetingCount > 0 ? (meetingCount === 1 ? " meeting today" : " meetings today") : "No meetings scheduled today";
    setMeetingMessage(meetingMsg);
  }, [tripCount, meetingCount]);

  return (
    <div className="trips-meetings-wrapper">
      <div className="trips-meetings-header">Trips and Meetings</div>
      <div className="trips-meetings-content">
        <div className="trips-meeting-summary">
          <div className="trips-content">
            <Icon icon="my-trips" iconKit="gt" size={1} />
            {tripCount > 0 ? (
              <span>
                {`You have `}
                <span className="trips-meetings-count">{tripCount}</span>
                {` ${tripMessage}`}
              </span>
            ) : (
              <span className="trips-meeting-empty">{tripMessage}</span>
            )}
          </div>
          <div className="trips-meetings--vertical-line"></div>
          <div className="meetings-content">
            <Icon icon="date-range" iconKit="gt" size={1} />
            {meetingCount > 0 ? (
              <span>
                {`You have `}
                <span className="trips-meetings-count">{meetingCount}</span>
                {` ${meetingMessage}`}
              </span>
            ) : (
              <span className="trips-meeting-empty">{meetingMessage}</span>
            )}
          </div>
        </div>

        <div className="trips-meetings-details">
          {meetings.length > 0 &&
            meetings.slice(0, 2).map((meeting) => (
              <div key={meeting.id} className="meetings-details">
                <div className="meeting-time-name">
                  <div className="meeting-time">
                    <div className="meeting-start-time">{formatDateToLocaleTimeString(meeting.start_at)}</div>
                    <div className="meeting-end-time">
                      <span>&nbsp;-&nbsp;</span>
                      {formatDateToLocaleTimeString(meeting.end_at)}
                    </div>
                  </div>
                  <div className="meeting-name">{meeting.name}</div>
                </div>
                <div className="meeting-participants">{meeting.contact_details}</div>
                <div className="meeting-trip-name">{meeting.trip_name}</div>
              </div>
            ))}
          {meetings.length > 2 && <div className="more-meetings">{`${meetings.length - 2} more`}</div>}
        </div>
      </div>
      <div className="trips-meetings--view-today">
        <Button
          onClick={() => {
            EverTrue.track.set("home_screen", { type: "view_trips_meetings" });
            EverTrue.UrlManager.navigate("/trips", true, false);
          }}
          type="secondary"
        >
          View
        </Button>
      </div>
    </div>
  );
};

TripsAndMeetings.propTypes = {
  tripsAndMeetingDetails: PropTypes.shape({
    tripCount: PropTypes.number,
    meetingCount: PropTypes.number,
    meetings: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        start_at: PropTypes.string,
        end_at: PropTypes.string,
        name: PropTypes.string,
        trip_name: PropTypes.string,
        contact_details: PropTypes.string,
      })
    ),
  }),
};

export default TripsAndMeetings;
