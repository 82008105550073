import _ from "underscore";
import { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Decorator from "clientDecorator";
import MeetingDetails from "apps/my-trips/components/meeting-details-modal";
import Avatar from "components/elements/avatar";
import { ModalTrigger2, Icon, Card } from "@evertrue/et-components";
import Facepile from "components/elements/facepile";

class MeetingCard extends Component {
  static propTypes = {
    meeting: PropTypes.object,
    trip: PropTypes.object,
    showContacts: PropTypes.bool,
    className: PropTypes.string,
  };

  static defaultProps = {
    meeting: {},
    showContacts: true,
  };

  state = {
    edit_view: false,
  };

  render() {
    const { meeting } = this.props;
    const facepile = Decorator.MyTrips.getFacepile(meeting.contacts, 4);
    const zone = meeting.start_datetime ? meeting.start_datetime.timezone_display : null;
    const fullName = Decorator.Contacts.getFullName(_.first(facepile != null ? facepile.contacts : undefined) || {});

    return (
      <ModalTrigger2
        trigger={({ open }) => {
          return (
            <div className={classNames("meeting-card--wrapper", this.props.className)} onClick={open}>
              <Card
                cardSize="small"
                hoverTransform="clickable"
                className={classNames("meeting-card", { "timeblock-card": meeting.type === "timeblock" })}
              >
                <div className="meeting-card--time">
                  {meeting.start_datetime.time_string}
                  {` - `}
                  {meeting.end_datetime.time_string}
                  {zone ? ` ${zone}` : null}

                  {!!meeting.anchor && (
                    <div className="meeting-card--anchor">
                      <Icon icon="star" />
                    </div>
                  )}
                </div>

                <span className="meeting-card--name ellipsis-overflow">{meeting.name}</span>

                <div className="meeting-card--location ellipsis-overflow">
                  {Decorator.MyTrips.getLocationDisplay(meeting)}
                </div>

                {this.props.showContacts && (
                  <div className="meeting-card--avatars fs-exclude-data">
                    {(facepile.contacts != null ? facepile.contacts.length : undefined) === 1 ? (
                      <div className="meeting-card--single-avatar">
                        <Avatar url={_.first(facepile.contacts).avatar} width={30} name={fullName} />
                        {fullName}
                      </div>
                    ) : (
                      <Facepile
                        list={_.compact(facepile.contacts)}
                        total={_.size(facepile.contacts) + facepile.additional_count}
                        avatarWidth={30}
                      />
                    )}
                  </div>
                )}
              </Card>
            </div>
          );
        }}
      >
        {({ close }) => {
          return <MeetingDetails trip={this.props.trip || this.props.meeting.trip} meeting={meeting} remove={close} />;
        }}
      </ModalTrigger2>
    );
  }
}

export default MeetingCard;
