import PropTypes from "prop-types";
import LogicGroup from "apps/query-builder/components/logic-group/logic-group";

const QueryBuilder = ({ appliedFilters, onChange }) => {
  const { filters } = appliedFilters;
  const handleGroupChange = (group, index) => {
    let newBlocks = [...filters.blocks];
    newBlocks[index] = group;
    onChange({
      ...appliedFilters,
      filters: {
        ...filters,
        blocks: newBlocks,
      },
    });
  };

  return (
    <div className="query-builder">
      <LogicGroup
        groupFilters={filters.blocks[0]}
        onChange={(group) => handleGroupChange(group, 0)}
        logicOperators={[
          { label: "Matching All of", value: "and_all_of" },
          { label: "Matching One or More of", value: "and_any_of" },
        ]}
      />
      <LogicGroup
        groupFilters={filters.blocks[1]}
        onChange={(group) => handleGroupChange(group, 1)}
        logicOperators={[
          { label: "And All of", value: "and_all_of" },
          { label: "Or All of", value: "or_all_of" },
          { label: "Or One or More of", value: "or_any_of" },
          { label: "And One or More of", value: "and_any_of" },
        ]}
      />
    </div>
  );
};

QueryBuilder.propTypes = {
  appliedFilters: PropTypes.object,
  onChange: PropTypes.func,
};
export default QueryBuilder;
