import { useState, useEffect, useCallback } from "react";
import Api from "entities/helpers/api";

const useFetchSearchById = ({ id }) => {
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState({});

  const loadSearches = useCallback(async () => {
    setLoading(true);

    try {
      const search = await Api.HUB.SEARCHES.get({
        urlArgs: { id },
      });

      setSearch(search);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    setLoading(true);
    loadSearches();
  }, [id, loadSearches]);

  return { loading, search, refetch: loadSearches };
};

export default useFetchSearchById;
