module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   EverTrue = require("app")
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")
   GateSource = require("apps/layout/sources/gate-source")


   createSource "AdminGateSource",
      actions:
         resetUserGates: true
         fetchedOrgGates: true
         loadingOrgLevelGates: true
         updatedUserGate: (key, gate) ->
            @require _.isString(key), "gate key should be string"
            @require _.isObject(gate), "gate should be object"

      api:
         updateUserGate: (key, enabled) ->
            Api.DNA.GATES.put
               urlExtend: "/#{key}"
               params:
                  user_id: "me"
                  oid: null
                  platform: "web"
                  enabled: enabled
               success: (resp) =>
                  @actions.updatedUserGate(key, resp)
                  enabled_string = if enabled then "enabled" else "disabled"
                  EverTrue.Alert.success "Successfully #{enabled_string} #{key}"

         resetUserGates: (keys) ->
            requests = _.map _.makeArray(keys), (key) ->
               Api.DNA.GATES.delete
                  urlExtend: "/#{key}"
                  params:
                     user_id: "me"
                     oid: null
                     platform: "web"

            $.when.apply(@, requests).then =>
               @actions.resetUserGates()
               @fetchOrgLevelGates({reset: true})
               EverTrue.Alert.success "Successfully reset gate(s)"

         fetchOrgLevelGates: (options={}) ->
            @actions.loadingOrgLevelGates(true)

            Api.DNA.GATES.get
               params: {platform: "web"}
               success: (resp) =>
                  @actions.fetchedOrgGates(resp)
                  @actions.loadingOrgLevelGates(false)
                  if options.reset
                     @actions.resetUserGates()

         updateOrgGate: (key, oid, platforms) ->
            @actions.loadingOrgLevelGates(true)
            requests = _.map ["ios", "web"], (platform) ->
               Api.DNA.GATES.put
                  urlExtend: "/#{key}"
                  params:
                     oid: oid
                     platform: platform
                     enabled: _.contains(platforms, platform)

            $.when(requests).done =>
               @fetchOrgLevelGates()
               GateSource.fetch()
