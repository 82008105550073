import { useState, useEffect } from "react";
import _ from "underscore";
import PropTypes from "prop-types";
import Api from "entities/helpers/api";
import { Loading } from "@evertrue/et-components";

const gql = String.raw;
const query = gql`
  query($ids: [BigInt!]) {
    contacts(ids: $ids) {
      id
      contact_attributes {
        name_full
      }
    }
  }
`;

const CellContactName = ({ id }) => {
  const [contact, setContact] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    Api.GRAPHQL.GRAPHQL.post({
      data: JSON.stringify({
        operationName: null,
        query: String.raw`${query}`,
        variables: { ids: [id] },
      }),
    })
      .then((resp = {}) => {
        const contacts = resp.data.contacts || [];
        const contact = _.findWhere(contacts, { id: id }) || {};
        setContact(contact);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [id]);

  const name =
    contact.contact_attributes && contact.contact_attributes.name_full ? contact.contact_attributes.name_full : "";
  return loading ? <Loading /> : name;
};

CellContactName.propTypes = {
  id: PropTypes.number.isRequired,
};

export default CellContactName;
