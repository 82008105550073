import PropTypes from "prop-types";
import { AdvancedCombobox } from "@evertrue/et-components";

const ProposalTableFilterActive = ({ filterValue, handleApplyFilter }) => {
  return (
    <div className="proposal-search-controller--filter-active">
      <AdvancedCombobox
        className={filterValue !== "all" ? "proposal-search-controller--filter-selected" : ""}
        value={{ value: filterValue }}
        options={[
          { value: "all", label: "Active & Inactive" },
          { value: "active", label: "Active" },
          { value: "inactive", label: "Inactive" },
        ]}
        onChange={(option) => handleApplyFilter(option.value)}
      />
    </div>
  );
};

ProposalTableFilterActive.propTypes = {
  filterValue: PropTypes.string,
  handleApplyFilter: PropTypes.func,
};

export default ProposalTableFilterActive;
