import { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import EverTrue from "app";
import Api from "entities/helpers/api";
import { Switch, Link, Button } from "@evertrue/et-components";
import { BOOKMARK_PERMISSIONS } from "apps/search/utils/search-helper";

const BookmarkOrgSharing = ({ id, orgPermissions, disabled = false }) => {
  const bookmarkURL = `https://${window.location.host}/search/${id}`;
  const [permissions, setPermissions] = useState(false);

  useEffect(() => {
    if (orgPermissions === BOOKMARK_PERMISSIONS.VIEW) {
      setPermissions(true);
    }
  }, [orgPermissions]);

  const handleOrgChange = async (isShared) => {
    setPermissions(isShared);

    try {
      await Api.HUB.SEARCH_PERMISSIONS.put({
        urlArgs: { id: id },
        urlExtend: "/org",
        data: JSON.stringify({ org_permission: isShared ? BOOKMARK_PERMISSIONS.VIEW : null }),
      });

      if (isShared) {
        EverTrue.track.set("filter_v2_action", {
          type: "bookmark_enable_share",
        });
      } else {
        EverTrue.track.set("filter_v2_action", {
          type: "bookmark_disable_share",
        });
      }
    } catch {
      setPermissions(!isShared);
      EverTrue.Alert.error("There was a problem changing permissions.");
    }
  };

  const handleUrlCopy = async () => {
    try {
      await navigator.clipboard.writeText(bookmarkURL);
      EverTrue.Alert.success("Bookmark copied to clipboard");
    } catch {
      EverTrue.Alert.error("There was a problem copying the bookmark. Please manually copy it.");
    }
  };

  return (
    <div className="bookmark-sharing-modal--org-section">
      <Switch
        label="Anyone at your org with the link can view"
        showLabel={true}
        onChange={handleOrgChange}
        checked={permissions}
        disabled={disabled}
      />
      <div className="bookmark-sharing-modal--url-copy">
        <Link title="Copy bookmark url to clipboard" href={bookmarkURL}>
          {bookmarkURL}
        </Link>
        <Button type="simple" onClick={handleUrlCopy}>
          <strong>Copy</strong>
        </Button>
      </div>
    </div>
  );
};

BookmarkOrgSharing.propTypes = {
  id: PropTypes.string.isRequired,
  orgPermissions: PropTypes.oneOf([null, BOOKMARK_PERMISSIONS.VIEW]),
  disabled: PropTypes.bool,
};

export default BookmarkOrgSharing;
