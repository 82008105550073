module.exports = do ->
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {div, span} = ReactLibs.DOMFactories
   {object} = ReactLibs.PropTypes
   Avatar = require("components/elements/avatar")
   classNames = require("classnames")
   {createFactory} = require("base/new-utils")
   Link = createFactory(require("@evertrue/et-components").Link)

   createComponent "RelationshipCard",
      propTypes:
         relationship: object

      render:  ->
         {name, contact_name, remote_id, type, infered_type, avatar_url, contact_id, deceased} = @props.relationship || {}

         link = if contact_id then "/contact/#{contact_id}"

         displayName = name || contact_name || span className: "text-muted", "No Name Provided"
         if typeof displayName is "object"
            displayName = if displayName.name then displayName.name else "No Name Provided"

         div
            className: "relationship-card"
            key: remote_id || name + type
            Avatar
               className: "relationship-card--avatar"
               name: contact_name || name
               url: avatar_url

            div className: "relationship-card--info",
               if link
                  Link
                     href: link
                     title: "#{displayName}'s profile"
                     className: classNames("relationship-card--name fs-exclude-data", "is-deceased-link": deceased)
                     displayName
               else
                  div className: classNames("relationship-card--name", "is-deceased": deceased),
                     span className: "fs-exclude-data", displayName

               if type
                  div className: "relationship-card--type", span className: "fs-exclude-data", type
               else if infered_type
                  div className: "relationship-card--inferred-type", span className: "fs-exclude-data", infered_type

               if remote_id
                  div className: "relationship-card--remote-id",
                     "ID: "
                     span className: "fs-exclude-data", remote_id

            if deceased
               div className: "relationship-card--deceased",
                  "DECEASED"
