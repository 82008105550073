import HomepageCard from "./homepage-card";
import ReactHtmlParser from "react-html-parser";
import useContentful from "apps/home/hooks/use-contentful";

const Resources = () => {
  const { parsedResources } = useContentful();

  return (
    <>
      {parsedResources && parsedResources.length > 0 ? (
        <>
          <h1 className="homepage-card-title">Resources</h1>
          <HomepageCard
            leftHeading={"Stay In The Know"}
            body={
              <div className="resource-card-body">
                {parsedResources.map(({ title, body, width }) => (
                  <div key={title}>
                    <span className="resource-section-title">{title}</span>
                    <div className="resource-section-body">{ReactHtmlParser(body)}</div>
                  </div>
                ))}
              </div>
            }
            cardSize="default"
            padding="20px"
          ></HomepageCard>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default Resources;
