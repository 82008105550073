import { PropTypes } from "base/react-libs";
import { useGate } from "components/is-gated";
import { Button, Icon } from "@evertrue/et-components";

const JourneyDetailTableToggle = ({ tableView, setTableView = () => {} }) => {
  const [hasUnreleased, loadedUnreleased] = useGate("unreleased_feature");
  const message = tableView === "steps" ? "View All Active" : "View Steps Due";

  if (!hasUnreleased && loadedUnreleased) return null;
  return (
    <Button
      type="simple"
      onClick={() => {
        if (tableView === "steps") {
          setTableView("activeConstituents");
        } else setTableView("steps");
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Icon icon="group" size={2} />
        {message}
      </div>
    </Button>
  );
};

JourneyDetailTableToggle.propTypes = {
  tableView: PropTypes.string,
  setTableView: PropTypes.func,
};

export default JourneyDetailTableToggle;
