import { isEmpty } from "underscore";
import { PropTypes } from "base/react-libs";
import { Icon } from "@evertrue/et-components";
import { useRef, useState, useEffect } from "react";
import {
  loadMoreActiveConstituents,
  ACTIVE_CONSTITUENTS_PAGE_LIMIT,
} from "apps/journeys/utils/api-requests/journey-details";
import JourneysTabNav from "apps/journeys/components/index/journeys-table-filters/journeys-tab-nav";
import LoadMoreButton from "components/controls/load-more-button";
import EmptyStateMessage from "components/lists/empty-state-message";
import ActiveConstituentsRow from "./active-constituent-row";

const TABS = [
  { id: 1, label: "Your Constituents" },
  { id: 2, label: "All Constituents" },
];

const ActiveConstituentsTable = ({
  user = {},
  journey = {},
  openContactSummary = () => {},
  activeConstituentPage = 1,
  optionalStateManagementFunction = () => {},
}) => {
  const newItemRef = useRef();
  const [selectedTab, setSelectedTab] = useState(TABS[0]);
  const [constituents, setConstituents] = useState({ items: [], count: 0 });
  const [page, setPage] = useState(1);
  const [loadingConstituents, setLoadingConstituents] = useState(false);

  const loadMore = async () => {
    try {
      setLoadingConstituents(true);
      const newPage = page + 1;
      const {
        active_contact_journeys_paginated_by_user: userContacts,
        active_contact_journeys_paginated: allContacts,
      } = await loadMoreActiveConstituents(journey.id, newPage, ACTIVE_CONSTITUENTS_PAGE_LIMIT, [
        "IN_PROGRESS",
        "PAUSED",
      ]);
      optionalStateManagementFunction("loadMoreActiveConstituents", { userContacts, allContacts, newPage });
    } finally {
      setLoadingConstituents(false);
    }
  };

  useEffect(() => {
    const calculatePage = (data) => {
      if (!data || !data.items || !data.items.length) return 1;

      return Math.ceil(data.items.length / ACTIVE_CONSTITUENTS_PAGE_LIMIT);
    };

    if (journey && !isEmpty(journey)) {
      const {
        active_contact_journeys_paginated_by_user: userContacts,
        active_contact_journeys_paginated: allContacts,
      } = journey;

      let data = { items: [], count: 0 };
      if (selectedTab.id === 1 && userContacts && !isEmpty(userContacts)) data = userContacts;
      else if (selectedTab.id === 2 && allContacts && !isEmpty(allContacts)) data = allContacts;
      setConstituents(data);
      setPage(calculatePage(data));
    }
  }, [journey, selectedTab]);

  return (
    <>
      <JourneysTabNav tabs={TABS} selectedTab={selectedTab} onSelect={(tab) => setSelectedTab(tab)} />
      {constituents && constituents.items && constituents.items.length ? (
        <>
          <div style={{ display: "flex", alignItems: "center", padding: "8px 16px" }}>
            <Icon icon="group" size={2} />
            {`${constituents && constituents.count ? constituents.count : 0} Active Constituents`}
          </div>
          {constituents.items.map((contactJourney, index) => (
            <ActiveConstituentsRow
              contactJourney={contactJourney}
              ref={index === (activeConstituentPage - 1) * ACTIVE_CONSTITUENTS_PAGE_LIMIT ? newItemRef : undefined}
              journey={journey}
              userIsOwner={user.id === contactJourney.user_id}
              openContactSummary={openContactSummary}
              optionalStateManagementFunction={optionalStateManagementFunction}
              key={contactJourney.id}
            />
          ))}
          <LoadMoreButton
            ref={newItemRef}
            total={constituents.count}
            limit={10}
            loading={loadingConstituents}
            loadMore={loadMore}
            displayCount={constituents.items.length}
          />
        </>
      ) : (
        <EmptyStateMessage
          size="page"
          icon="group"
          className="journey-detail--empty-state"
          iconSize={5}
          text={
            selectedTab.id === 1
              ? `You do not have any Constituents in the ${journey.name} Cadence.`
              : `There are no Constituents in the ${journey.name} Cadence.`
          }
        />
      )}
    </>
  );
};

ActiveConstituentsTable.propTypes = {
  user: PropTypes.object,
  journey: PropTypes.object.isRequired,
  openContactSummary: PropTypes.func,
  activeConstituentPage: PropTypes.number,
  optionalStateManagementFunction: PropTypes.func,
};
export default ActiveConstituentsTable;
