import EverTrue from "app";
import BaseRouter from "base/base-router";
import ReportingController from "./controllers/reporting-controller";
import FeatureStore from "apps/layout/stores/feature-store";

class ReportingRouter extends BaseRouter {
  static initClass() {
    this.prototype.routes = {
      "reporting(/)": "index",
      "reporting/activity": "index",
      "reporting/activity/completed": "index",
      "reporting/activity/overdue": "activityOverdue",
      "reporting/activity/skipped": "activitySkipped",
    };
  }

  index() {
    if (FeatureStore.hasFeature("unreleased_feature")) {
      EverTrue.execute("setBrowserTitle", "Reporting");
      this.currentView = <ReportingController tab={"completed"}/>;
      EverTrue.page.mount(this.currentView);
    } else EverTrue.Navigator("/contact", true);
  };

  activityOverdue() {
    if (FeatureStore.hasFeature("unreleased_feature")) {
      EverTrue.execute("setBrowserTitle", "Reporting");
      this.currentView = <ReportingController tab={"overdue"}/>;
      EverTrue.page.mount(this.currentView);
    } else EverTrue.Navigator("/contact", true);
  };

  activitySkipped() {
    if (FeatureStore.hasFeature("unreleased_feature")) {
      EverTrue.execute("setBrowserTitle", "Reporting");
      this.currentView = <ReportingController tab={"skipped"}/>;
      EverTrue.page.mount(this.currentView);
    } else EverTrue.Navigator("/contact", true);
  };
}

ReportingRouter.initClass();
export default ReportingRouter;
