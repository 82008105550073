_ = require("underscore").default
AuthApi = require("entities/auth/auth-api")
SearchQueryCollectionMixin = require("mixins/search-query-mixin")

# Query Interface for Make search Calls
module.exports = class Stats extends AuthApi.Model
   @include [SearchQueryCollectionMixin]
   urlRoot: "search/v2/contacts/search"

   parse: (data) ->
      @total = data.total
      data.stats

   fetch: (options={}) ->
      opts = _.clone(options)
      if options.query || options.filters
         opts = @queryOptions(opts)
      super opts

   getTopRank: (key, count) ->
      key_stats = @get(key)
      top = _.sortBy key_stats, (data) -> -data.stats.mean
      _.map top[0..(count-1)], (data) -> data.group_by_field_value

