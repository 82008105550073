module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")
   Decorator = require("clientDecorator")

   createSource "CompanySource",
      actions:
         fetchedCompany: (company) ->
            @require _.isObject(company), "company should be object"
         fetchedStats: (stats) ->
            @require _.isObject(stats), "stats should be object"
         fetchedCompaniesForIndustry: (companies) ->
            @require _.isObject(companies), "companies should be object"
         fetchedConstituents: (constituents) ->
            @require _.isObject(constituents), "constituents should be object"
         resetData: true

      _getFilters: (name, type) ->
         switch type
            when "company"
               [{
                  "employments.company.untouched":{
                     in: [name]
                  }
               }]
            when "industry"
               [{
                  "employments.industry.untouched":{
                     match: name
                  }
               }]

      api:
         resetData: ->
            @actions.resetData()

         fetchCompany: (name) ->
            @fetchStats(name, "company")

            @actions.fetchedCompany({name: name})

         fetchStats: (name, type) ->
            stats = {}
            filters = @_getFilters(name, type)
            year = new Date()?.getFullYear()

            requests = [
               Api.SEARCH.STATS.post
                  params: {limit: undefined}
                  data:
                     _.jsonStringify
                        must: filters
                        stats: [{"giving.lifetime_amount": {}}]
                  success: (resp) ->
                     stats.lifetime = resp
            ,
               Api.CONTACTS.SEARCH.post
                  params: {limit: 500}
                  data:
                     _.jsonStringify
                        must: filters
                  success: (resp) =>
                     resp.items = _.map resp.items, (contact) ->
                        Decorator.Contacts.parse(contact)
                     stats.instances = resp
                     @actions.fetchedConstituents(resp)
            ,
               Api.SEARCH.STATS.post
                  params: {limit: undefined}
                  data:
                     _.jsonStringify
                        must: _.push filters.slice(), {"giving.lifetime_amount": {gt: 0, coerce: 0}}
                  success: (resp) ->
                     stats.participation_life = resp
            ,
               Api.CONTACTS.SEARCH.post
                  data:
                     _.jsonStringify
                        must: _.push filters.slice(), {
                           giving_annual_donations:
                              instance:
                                 fiscal_year: {match: year}
                                 amount: {gt: 0}
                           }
                  success: (resp) ->
                     stats.current_year = resp
            ,
               Api.CONTACTS.SEARCH.post
                  data:
                     _.jsonStringify
                        must: _.push filters.slice(), {
                           giving_annual_donations:
                              instance:
                                 fiscal_year: {match: year - 1}
                                 amount: {gt: 0}
                        }
                  success: (resp) ->
                     stats.last_year= resp
            ]

            $.when.apply(@,requests).then =>
               @actions.fetchedStats(stats)

         fetchCompaniesForIndustry: (name) ->
            key = "employments.industry.untouched"
            filters = @_getFilters(name, "industry")

            @fetchStats(name, "industry")

            Api.CONTACTS.FACETS.post
               data:
                  _.jsonStringify
                     must: filters
                     facet: [{field: key}]
               success: (resp) =>
                  @actions.fetchedCompaniesForIndustry
                     name: name
                     companies: resp.facets[key]



