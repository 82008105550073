import { useContext, useEffect, useState } from "react";
import TableToolbar from "components/tables/table-toolbar";
import useFetchContacts from "apps/search/hooks/use-fetch-contacts";
import ContactsFixedTable from "apps/contact/components/contacts/contact-fixed-table";
import PropTypes from "prop-types";
import { ListDataContext } from "apps/layout/controllers/list-data-context";
import SortDropdown from "components/controls/sort-dropdown";
import EmptyStateMessage from "components/lists/empty-state-message";
import SelectedContactsStore from "apps/contact/stores/selected-contacts-store";
import { connect } from "@evertrue/et-flux";
import MapsController from "apps/search/controllers/maps-controller";
import MapToggle from "components/tables/map-toggle";

const mapStateToProps = () => ({
  selected: SelectedContactsStore.getSelectedContacts(),
});

const sortOptions = [
  { label: "Date Added", value: "contact_tag.updated_at" },
  { label: "Name", value: "name_last" },
];

const DynamicListTable = ({ list_id, dsl, isAlertShown, selected, showMap }) => {
  const { tableSettings, updateTableSettings } = useContext(ListDataContext);
  const { contactsData, loading, offset, LIMIT, resultCount } = useFetchContacts(dsl, tableSettings, list_id);
  const [enrichmentLoading, setEnrichmentLoading] = useState(true);

  useEffect(() => {
    // on first mount, change the table settings from the context's default to sort by date added (newest first)
    if (tableSettings.sortProp === "giving.et_donor_index") {
      updateTableSettings({ sortProp: sortOptions[0].value, sortReverse: true });
    }
  }, [tableSettings, updateTableSettings]);

  return (
    <>
      <div className="filtered-contacts fixed-page-wrapper" style={{ top: isAlertShown ? 165 : 145 }}>
        <div className="space-between toolbar-container">
          <div className="contacts-table-toolbar">
            <TableToolbar
              resultCount={resultCount}
              page={tableSettings.page}
              offset={offset}
              limit={LIMIT}
              onPage={(page) => {
                setEnrichmentLoading(true);
                updateTableSettings({ page });
              }}
              referrer={"dynamic-lists"}
              id={list_id}
              showMap={showMap}
              loading={loading}
            />
            {contactsData.items.length > 0 && !showMap && (
              <SortDropdown
                options={sortOptions}
                sortProp={tableSettings.sortProp}
                sortReverse={tableSettings.sortReverse}
                onSort={(sortProp, sortReverse) => {
                  updateTableSettings({ sortProp, sortReverse });
                }}
              />
            )}
          </div>
          <MapToggle showMap={showMap} referrer={"dynamic-lists"} id={list_id} />
        </div>
        {!contactsData.items.length && !loading && !showMap ? (
          <div style={{ marginTop: "25vh" }}>
            <EmptyStateMessage text="This list is empty">
              You may want to update the search criteria for this list
            </EmptyStateMessage>
          </div>
        ) : showMap ? (
          <MapsController loading={loading} contacts={contactsData} />
        ) : (
          <ContactsFixedTable
            extraColumns={["date_added"]}
            topPosition="55px"
            contacts={contactsData}
            loading={loading}
            onSort={(prop, order) => updateTableSettings({ sortProp: prop, sortReverse: order })}
            showCheckbox={true}
            numSelected={selected.length}
            enrichmentLoading={enrichmentLoading}
            setEnrichmentLoading={setEnrichmentLoading}
          />
        )}
      </div>
    </>
  );
};

DynamicListTable.propTypes = {
  list_id: PropTypes.string.isRequired,
  dsl: PropTypes.object.isRequired,
  isAlertShown: PropTypes.bool,
  selected: PropTypes.array,
  showMap: PropTypes.bool,
};

export default connect(DynamicListTable, [SelectedContactsStore], mapStateToProps);
