import EverTrue from "app";
import BaseRouter from "base/base-router";
import AISearchHomeController from "./controller/ai-search-home-controller";
import IsGatedController from "components/gates/is-gated-controller";

class AISearchRouter extends BaseRouter {
  static initClass() {
    this.prototype.routes = {
        "ai/search(/)": "index",
    };
  }

  index() {
      EverTrue.execute("setBrowserTitle", "AI Search");
      this.currentView = (
        <IsGatedController gate="ai_search" navigatePath="/home">
          <AISearchHomeController/>
        </IsGatedController>
      );
      EverTrue.page.mount(this.currentView);
  }
}

AISearchRouter.initClass();
export default AISearchRouter;
