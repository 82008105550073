import { useState, useEffect } from "react";
import Api from "entities/helpers/api";
import EverTrue from "app";

export const useEmailer = () => {
  const [emailerIsConnected, setEmailerIsConnected] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const { prevLocation } = JSON.parse(localStorage.getItem("oauthData")) || {};
    if (prevLocation) localStorage.removeItem("oauthData");

    Api.OUTREACH.CREDENTIALS.get()
      .then(() => {
        setEmailerIsConnected(true);
        setLoading(false);
      })
      .catch(({ status, responseJSON: { redirect_url } }) => {
        if (status === 421) {
          setEmailerIsConnected(false);
          setRedirectUrl(redirect_url);
          setLoading(false);
        } else {
          setLoading(false);
          EverTrue.Alert.error("There was a problem fetching your credentials.");
        }
      });
  }, []);

  const handleConnectEmail = () => {
    localStorage.setItem("oauthData", JSON.stringify({ prevLocation: window.location.pathname }));
    window.location.assign(`${redirectUrl}`);
  };

  const handleDisconnectEmail = async () => {
    EverTrue.Alert.confirm(
      {
        headline: "Disconnect Account",
        content: `Are you sure you want to disconnect your account?`,
        buttonLabel: "Disconnect",
      },
      (confirmed) => {
        if (confirmed) {
          Api.OUTREACH.UNLINK.post()
            .then(({ redirect_url }) => {
              setRedirectUrl(redirect_url);
              EverTrue.Alert.success("Your account was disconnected successfully");
              setEmailerIsConnected(false);
            })
            .catch(() => {
              EverTrue.Alert.error("There was a problem disconnecting your account.");
            });
        }
      }
    );
  };

  return { emailerIsConnected, handleConnectEmail, handleDisconnectEmail, loading };
};

export default useEmailer;
