module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   numeral = require("numeral")
   React = require("react")
   ReactDOM = require("react-dom")
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   {div, table, tbody, tr, td, span} = ReactLibs.DOMFactories
   MyPortfolioSource = require("apps/major-gifts/sources/my-portfolio-source")
   MyPortfolioStore = require("apps/major-gifts/stores/my-portfolio-store")
   {createFactory} = require("base/new-utils")
   ContactCard = createFactory(require("apps/contact/components/contact-card/contact-card"))
   ContactDatalist = require("apps/contact/components/contact-card/contact-datalist")
   SelectContactCheckbox = require("apps/contact/components/contacts/select-contact-checkbox")
   MyPortfolioConfig = require("apps/major-gifts/my-portfolio-config")
   Decorator = require("clientDecorator")


   createComponent "MyPorfolioColTable",
      propTypes:
         group: ReactLibs.PropTypes.object

      registerStores: ->
         @on MyPortfolioStore, ->
            show_aging: MyPortfolioStore.getShowAging()
            show_last_contact_date: MyPortfolioStore.getShowLastContactDate()

      componentDidMount: ->
         container = $(ReactDOM.findDOMNode(@))
         container.on "scroll", @infiniteScroll

      componentWillUnmount: ->
         container = $(ReactDOM.findDOMNode(@))
         container.off "scroll", @infiniteScroll

      infiniteScroll: ->
         container = $(ReactDOM.findDOMNode(@))
         container_table = container.find("table")
         load_more_group = @props.group

         if container.scrollTop() + container.height() >= container_table.height()
            num_of_contacts = load_more_group.contacts?.length
            if !@props.group?.infinite_loading and num_of_contacts < load_more_group.total
               MyPortfolioSource.infiniteLoadByStatus(load_more_group.label, num_of_contacts)

      renderContactCardData: (contact, group) ->
         visible_data = [
            {label: "Last Gift Date", value: contact.giving?.last_gift_date?.value}
            {label: "Lifetime", value: numeral(contact.giving?.lifetime_amount?.value).format("$0,0")}
         ]

         if @state.show_aging
            time_in_stage = MyPortfolioConfig.getFormattedTimeInStage(contact.last_move_dates?[group.label])
            visible_data.push {label: "Time In Stage", value: time_in_stage}

         if @state.show_last_contact_date
            last_contact = MyPortfolioConfig.getFormattedTimeInStage(contact.last_contact_date)
            if last_contact then last_contact += " ago" else last_contact = "n/a"
            visible_data.push {label: "Last Contact", value: last_contact}

         visible_data

      render: ->
         group = _.extend {}, @props.group

         div className: classNames("my-portfolio--contact-table", "show-aging": @state.show_aging),
            if !_.isEmpty(group.contacts)
               table null,
                  tbody null,
                     _.map group.contacts, (contact) =>
                        contact = MyPortfolioStore.getContact(contact)
                        return if _.isEmpty(contact)

                        tr
                           key: contact.id
                           className: classNames "prospect--row",
                              "show-aging": @state.show_aging,
                              "show-last-contact-date": @state.show_last_contact_date

                           td className: "prospect--checkbox",
                              SelectContactCheckbox id: contact.id, name: Decorator.Contacts.getDetailedName(contact)

                           td className: "prospect--card",
                              ContactCard
                                 contact: contact
                                 showClassYear: false
                                 ContactDatalist datalist: @renderContactCardData(contact, group)

                     if group.contacts.length < group.total
                        tr null,
                           td colSpan: 2, className: "my-portfolio--col-spinner",
                              div className: "loader is-centered"

            else
               table null,
                  tbody null,
                     tr null,
                        td className: "prospect--empty-cell",
                           div className: "avatar--blue"
                           div null, "No Constituents"

