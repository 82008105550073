module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   EverTrue = require("app")
   Filters = require("entities/search/filters")
   Query = require("entities/search/query-parts/query")


   SearchQueryCollectionMixin =

      setEngagementType: (engagement_type) ->
         query = @baseQuery
         query_must_val = query?.must
         if query_must_val
            query_must_val = _.reject(query_must_val, (obj) ->
               !!obj["engagement.type"]
            )
            query.must = query_must_val
            if engagement_type != null and engagement_type != undefined
               query.must.push({
                  "engagement.type": {
                     match: engagement_type
                  }
               })
            @setBaseQuery(query)
         else
            @setBaseQuery(
               must: [{
                  "engagement.type": {
                     match: engagement_type
                  }
               }]
            )

      setNetworkType: (social_type) ->
         @setBaseQuery(
            must: [{
               network: {
                  match: social_type
               }
            }]
         )

      queryOptions: (options={}) ->
         if options.filters
            filters = new Filters()
            filters.clear()
            filters.set(options.filters)
            filter_options = filters.query()
            delete options.filters

         # Add filter object options for models besides contact Filters
         # This is a seperate options param to prevent clobbering the Filters keys
         if options.search_query
            query_options = @queryFormat(options.search_query)
            delete options.search_query

         data = {}
         _.each [query_options, filter_options, options.query, @baseQuery], (queries) ->
            _.each queries, (param_query, param) ->
               data[param] ?= []
               if _.isArray(param_query)
                  _.each param_query, (q) -> data[param].push q
               else
                  data[param].push param_query

         options.type = "POST" unless options.type
         options.data = JSON.stringify($.extend true, {}, options.data, data)
         delete options.query
         options

      baseQuery: []
      setBaseQuery: (query_params, isFilter) ->
         @baseQuery = query_params
         if isFilter
            filters = new Filters()
            filters.set(query_params)
            @baseQuery = filters.query()

      config: {}
      queryFormat: (filter_object) ->
         fullQuery = Query()
         _.each filter_object, (value, key) =>
            query = @config[key]?.query_fn?(value)
            fullQuery.merge(query) unless _.isEmpty(query)
         fullQuery.toJSON()

