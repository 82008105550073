import { useState, useEffect } from "react";
import Api from "entities/helpers/api";

const useFetchDynamicList = (list_id) => {
  const [dataLoading, setDataLoading] = useState(true);
  const [listData, setListData] = useState({});

  useEffect(() => {
    const loadContacts = async () => {
      setDataLoading(true);

      try {
        const data = await Api.HUB.LIST.get({
          urlArgs: { id: list_id },
        });
        setListData(data);
        setDataLoading(false);
      } catch (err) {
        if (err.status === 404) {
          setListData({ tag_stats: { operation_status: "DELETED" } });
        }
        if (err.status === 403) {
          setListData({ tag_stats: { operation_status: "ACCESS_DENIED" } });
        }
        setDataLoading(false);
      }
    };

    loadContacts();
  }, [list_id]);

  return { dataLoading, listData };
};

export default useFetchDynamicList;
