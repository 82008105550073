import PropTypes from "prop-types";
import { Icon, useOpenClose, Modal, Button } from "@evertrue/et-components";
import { mostRecentCreate, prioritizeCareers } from "apps/profile/components/enrichment/enrichment-utils";
import EmptyStateMessage from "components/lists/empty-state-message";
import ProfileCardHeader from "apps/profile/components/profile-card-header";
import CareerEditModalBody from "apps/profile/components/career-edit-modal-body";
import CareerEnrichmentCard from "apps/profile/components/enrichment/career-enrichment-card";
import EnrichmentModalHeader from "apps/profile/components/enrichment/enrichment-modal-header";
import { useState, useEffect } from "react";

const renderCareers = ( careers ) => {
  return careers.map((career) => {
    const { id, company_id, title } = career;
    return <div key={id} className="profile-enrichment--item">
      <CareerEnrichmentCard data={career} key={company_id + title} />
    </div>
  })
}

const CareerEnrichment = ({ contact, careers, onDelete }) => {
  const [currentEmployments, setCurrentEmployments] = useState([]);
  const [pastEmployments, setPastEmployments] = useState([]);
  const [totalEmployments, setTotalEmployments] = useState(0);
  const [isModalOpen, openModal, closeModal] = useOpenClose();

  useEffect(() => {
    const current = careers.filter((employment) => (employment.status === "current" || employment.status === null) && !employment.rejected);
    const past = careers.filter((employment) => employment.status === "past" && !employment.rejected)
    setCurrentEmployments(prioritizeCareers(current));
    setPastEmployments(prioritizeCareers(past));
    setTotalEmployments(current.length + past.length);
  }, [careers]);


  const renderEditModal = (employments) => (
    <Modal isOpen={isModalOpen} closeModal={closeModal} size="small">
      <EnrichmentModalHeader
        headerText={contact.name + "'s Employments"}
        updateDate={mostRecentCreate(employments)}
        closeModal={closeModal}
      ></EnrichmentModalHeader>
      <CareerEditModalBody contact_id={contact.id} employmentData={prioritizeCareers(employments)} onDelete={onDelete} />
    </Modal>
  );

  return (
    <>
      <ProfileCardHeader title="Professional" updateDate={totalEmployments > 0 ? mostRecentCreate([...currentEmployments, ...pastEmployments]) : null}>
        { totalEmployments > 0 &&
          <Button type="simple" onClick={isModalOpen ? closeModal : openModal}>
            <Icon title="Edit career information" icon="edit"/>
            {renderEditModal(careers)}
          </Button>
        }
      </ProfileCardHeader>

      <div className={`profile--enrichment-inner`}>
        { totalEmployments > 0 ? ( 
          <>
            { !!currentEmployments.length > 0 && (
              <>
                <div className="profile--enrichment-group">
                  <div className="profile--enrichment-group-title">Current Employment</div>
                  <div className="profile--enrichment-group-data">
                    {renderCareers([currentEmployments[0]])}
                  </div>
                </div>
              </>
            )}
            { !!pastEmployments.length > 0 && (
              <>
                <div className="profile--enrichment-group">
                  <div className="profile--enrichment-group-title">Previous Employment</div>
                  <div className="profile--enrichment-group-data">
                    {renderCareers([pastEmployments[0]])}
                  </div>
                </div>
              </>
            )}
            { totalEmployments > 2 && 
            (<Button type="simple" onClick={isModalOpen ? closeModal : openModal} className="profile--enrichment--additional-employments">
              View Additional Professional Data ({totalEmployments - 2} more)
              {renderEditModal(careers)}
            </Button>)}
          </>
        ) : (
          <EmptyStateMessage hideIcon text="No enriched professional data available" />
        )}
      </div>
    </>
  );
};

CareerEnrichment.propTypes = {
  contact: PropTypes.object,
  careers: PropTypes.array,
  onDelete: PropTypes.func,
};

export default CareerEnrichment;
