module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   {span, strong} = ReactLibs.DOMFactories


   createComponent "StringSearchDisplay",
      propTypes:
         string: ReactLibs.PropTypes.string
         separator: ReactLibs.PropTypes.string

      getDefaultProps: ->
         separator: "and"

      render: ->
         return null unless @props.string
         words = _.filter @props.string.split(" "), (word) -> !_.isEmpty(word)

         span className: classNames("string-search-display", @props.className),
            _.map words, (word, index) =>
               span key: index,
                  strong null, "\"#{word}\""
                  if index != (words.length - 1)
                     span null, " #{@props.separator} "
