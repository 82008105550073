module.exports = do ->

   ChildDoc = (type, ChildQuery) ->
      query_object = {type: type, query: {}}
      _query = ChildQuery

      {
         toJSON: ->
            query_object.query = _query.toJSON()
            {has_child: [query_object]}

         keys: -> _query.propertyKeys()

         query: ->
            _query

         propertyKeys: ->
            _query.propertyKeys()

         properties: ->
            _query.properties()

         merge: (InnerQuery) ->
            _query.merge(InnerQuery.query())

         replace: (InnerQuery) ->
            _query = InnerQuery

         type: -> type

         isChildDocument: -> true

         isNestedDocument: -> true
      }

   return ChildDoc
