module.exports = do ->
   React = require("react")
   {div, span} = ReactLibs.DOMFactories
   moment = require("moment")
   {createFactory} = require("base/new-utils")
   EventbriteDetailResolver = createFactory(require("apps/events/components/eventbrite-detail-resolver.js").default)
   EllipsisOverflow = createFactory require("components/formatting/ellipsis-overflow")
   EventIcon = require("apps/events/components/event-icon")
   Link = createFactory(require("@evertrue/et-components").Link)

   event_columns = [
      label: "Event"
      key: "event"
      width: 250
      row: (event) ->
         div className: "events-table--event",
            EventIcon
               className: "events-table--picture"
               event: event
               size: 60
            div className: "events-table--text",
                  Link
                     href: "events/#{event.id}",
                     title: "#{event.name} page"
                     className: "emphasis events-table--title"
                     EllipsisOverflow null,
                        event.name
                  if event.page_name
                     EllipsisOverflow null, "by #{event.page_name}"
   ,
      label: "Event Date"
      key: "date"
      width: 150
      row: (event) ->
         time = moment(event.start_time)
         div null,
            div null, time.format("MM/D/YYYY")
            div null, time.format("h:mm A")
   ,
      label: "Event Location"
      key: "location"
      width: 150
      row: (event) ->
         div className: "events-table--location",
            event.location.name || "-"
            if event.location.street
               div null,
                  event.location.street
            if event.location.city and event.location.state and event.location.zip
               div null,
                  "#{event.location.city}, #{event.location.state} #{event.location.zip}"
   ,
      label: "Matched Engagers"
      key: "matched_count"
      width: 120
      row: (event) ->
         if event.source == "eventbrite"
            EventbriteDetailResolver
               event: event
               include_unmatched: false
               include_filters: false
               render: ({ matchedCounts }) ->
                  div className: "events-table--event-counts",
                     span className: "events-table--event-count",
                        if matchedCounts? then matchedCounts.all else span className: "text-muted", "~"
         else
            count = event._matched_engagers_count
            div className: "events-table--event-counts",
               span className: "events-table--event-count",
                  if count? then count else span className: "text-muted", "~"
   ,
      label: "Unmatched Engagers"
      key: "unmatched_count"
      width: 120
      row: (event) ->
         if event.source == "eventbrite"
            EventbriteDetailResolver
               event: event
               include_matched: false
               include_filters: false
               render: ({ unmatchedCounts }) ->
                  div className: "events-table--event-counts",
                     span className: "events-table--event-count",
                        if unmatchedCounts? then unmatchedCounts.all else span className: "text-muted", "~"
         else
            div className: "events-table--event-counts",
               span className: "events-table--event-count",
                  span className: "text-muted", "~"
   ]

   return event_columns
