import { createSource } from "@evertrue/et-flux";

export default createSource("BulkAddSource", {
  actions: {
    clearList: true,
    clearPool: true,
    addToPool: true,
    addToList: true,
    bulkVMSRollover: true,
    bulkVMSPoolDelete: true,
  },
  api: {
    clearList() {
      this.actions.clearList();
    },
    clearPool() {
      this.actions.clearPool();
    },
    // kick off the toast UI on a 202, then websocket will take over from there
    addToPool({ count, pool_id, progress, type }) {
      this.actions.addToPool({ count, pool_id, progress, type });
    },
    addToList({ count, list_id }) {
      this.actions.addToList({ count, list_id });
    },
    bulkVMSRollover(...args) {
      this.actions.bulkVMSRollover(...args);
    },
    bulkVMSPoolDelete(...args) {
      this.actions.bulkVMSPoolDelete(...args);
    },
  },
});
