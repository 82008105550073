module.exports = do ->
   _ = require("underscore").default

   # This method, pulls apart a search query criteria and
   # exposes getters for making it easier to access values and
   # wraps the logic in one place
   Criteria = (criteria) ->
      _properties = []
      _nested_operator_types = [
         "in"
         "match"
         "exists"
         "instance"
         "wildcard"
      ]

      _getOperatorKeyValue = (operator) ->
         nested_operator = undefined
         nested_operator_key = undefined
         _.each operator, (operator_value, operator_name) ->
            if !nested_operator && _.contains(_nested_operator_types, operator_name)
               nested_operator = operator_value
               nested_operator_key = operator_name

         nested_operator ?= operator
         {key: nested_operator_key, value: nested_operator}

      # TODO: account for has_parent, has_child
      _.each criteria, (parameter, param_name) ->
         _.each parameter, (property_query, index) ->

            if param_name in ["has_child", "has_parent"]
               queries = _.map Criteria(property_query.query).getAll(), (prop) ->
                  _.extend {}, prop, {document_type: property_query.type}
               _properties = _properties.concat(queries)
            else
               _.each property_query, (operator, prop_name) ->
                  formatted_operator = _getOperatorKeyValue(operator)
                  if formatted_operator.key == "instance"
                     _.each operator, (instance_prop, instance_key) ->
                        _.each instance_prop, (instance_op, instance_prop_name) ->
                           formatted_opt = _getOperatorKeyValue(instance_op)
                           _properties.push
                              parameter: param_name
                              property: instance_prop_name
                              parent: prop_name
                              operator: formatted_opt.key
                              value: formatted_opt.value
                  else
                     _properties.push
                        parameter: param_name
                        property: prop_name
                        operator: formatted_operator.key
                        value: formatted_operator.value


      api =
         getAll:  ->
            _properties

         getByParameter: (param) ->
            _.filter _properties, (prop) ->
               prop.parameter == param

         getOneParameter: ->
            _.first _.pluck(_properties, "parameter")

         getOneByParameter: (param) ->
            _.findWhere(_properties, {parameter: param})

         hasParameter: (param) ->
            !!_.findWhere _properties, {parameter: param}

         getByOperator: (operator) ->
            _.filter _properties, (prop) ->
               prop.operator == operator

         getValueByOperator: (operator) ->
            _.first(@getByOperator(operator))?.value

         getByProperty: (property) ->
            _.filter _properties, (prop) ->
               prop.property == property

         hasOperator: (operator) ->
            !!_.findWhere _properties, {operator: operator}

         hasParent: (parent) ->
            !!_.find _properties, (prop) ->
               (parent && prop.parent == parent) || (!parent && !!prop.parent)

         getValue: (property, operator) ->
            found = _.find _properties, (prop) ->
               prop.property == property && (!operator || prop.operator == operator)
            found?.value
