module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   FilterConfig = require("apps/filters/filter-config")
   Decorator = require("clientDecorator")
   InteractionSource = require("apps/interactions/sources/interaction-source").default
   Query = require("entities/search/query-parts/query")
   Parameter = require("entities/search/query-parts/parameter-query")
   Property = require("entities/search/query-parts/property-query")

   _hasFeature = (feature) ->
      EverTrue?.store?.org?.hasFeature?(feature)

   # =========================================================================================================
   # Config Format & Available Options
   # ** CoreSearchConfig object key correlates to the "type" passed through the search functionality
   #
   # 'sort': number to sort in results
   # 'label': REQUIRED display label for Navigation, etc.
   # 'route': REQUIRED in-app route to go to for rendering all search results for that type
   # 'referrer': analytics referrer for mixpanel tracking
   # 'is_available': function - return true to show the feature, do not include if not gated
   # 'parser': function(items) - runs when results have been fetched
   # 'formatter': function(items) - format the results for display
   # 'query_fn': REQUIRED function(value) - format Query from values, return Query object or undefined
   # =========================================================================================================

   CoreSearchConfig =
      contacts:
         sort: 0
         label: "Constituents"
         route: "/contact"
         referrer: "contact"
         formatter: (items) ->
            _.map items, (item) ->
               Decorator.Contacts.parse(item)
         query_fn: (value) ->

            NAME_KEYS = ["name_first", "name_last", "name_nick", "name_maiden", "name_suffix", "name_prefix"]
            names = if value then value?.split?(" ") else [value]

            properties =
               if _.size(names) > 1
                  name_properties = _.map names, (name) ->
                     Property NAME_KEYS, name, {type: "wildcard"}
                  result_properties = []
                  name_properties.forEach (property) ->
                     phone_property = Property ["phones.phone"], (value.replace /[\s()]/g, ""), {type: "wildcard"}
                     result_properties.push property.orProperties([phone_property])
                  result_properties
               else
                  remote_ids = _.uniq [value, _.trimRemoteId(value)]
                  name_property = Property NAME_KEYS, value, {type: "wildcard"}
                  remote_id_property = Property "identities.value", remote_ids, {type: "contains"}
                  remote_id_property_untouched = Property "identities.value.untouched", remote_ids, {type: "contains"}
                  wildcard_remote_id_property = Property "identities.value", remote_ids, {type: "wildcard"}
                  wildcard_remote_id_property_untouched = Property "identities.value.untouched", remote_ids, {type: "wildcard"}
                  email_property = Property ["emails.email"], value, {type: "wildcard"}
                  phone_property = Property ["phones.phone"], (value.replace /[\s()]/g, ""), {type: "wildcard"}
                  [email_property.orProperties([phone_property, remote_id_property, remote_id_property_untouched, wildcard_remote_id_property, wildcard_remote_id_property_untouched, name_property])]
            Query [Parameter("must", properties)]

      interactions:
         sort: 1
         label: "Interactions"
         route: "/interaction"
         referrer: "interaction_results"
         is_available: ->
            _hasFeature("ugc_show_imported_notes")
         parser: (items) ->
            InteractionSource.loadInteractions(items)
         formatter: (items) ->
            _.map items, (item) ->
               _.extend {}, item, contact_id: item.target_id
         query_fn: (query_string) ->
            Query [
               Parameter "must", _.map(query_string.split(" "), (val) ->
                  Property(["text", "summary"], val, type: "wildcard")
               )
               Parameter "highlight", [
                  Property "fields", {text: {pre_tags: [""], post_tags: [""]}}, {type: "object"}
               ]
               Parameter "sort", [
                  Property "date_occurred", {order: "desc"}, {type: "object"}
               ]
            ]

      proposals:
         sort: 2
         label: "Proposals"
         route: "/proposal"
         referrer:  "proposal_results"
         is_available: ->
            _hasFeature("proposal_reads")


   return CoreSearchConfig
