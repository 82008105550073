module.exports = do ->
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {div, p, i, span, h3} = ReactLibs.DOMFactories
   MobileTextMeSource = require("apps/login/stores/login-text-me-source")
   MobileTextMeStore = require("apps/login/stores/login-text-me-store")
   {createFactory} = require("base/new-utils")
   PhoneInput = createFactory(require("components/forms/lazy-phone-input").default)
   classSet = require("classnames")
   Icon = createFactory (require("components/elements/icon").default)
   LinkButton = createFactory(require("@evertrue/et-components").LinkButton)
   Link = createFactory(require("@evertrue/et-components").Link)
   Button = createFactory(require("@evertrue/et-components").Button)

   createComponent "GetMobileApp",

      getInitialState: ->
         number: undefined
         isValid: undefined
         showError: false

      registerStores: ->
         @on MobileTextMeStore, ->
            currentStep: MobileTextMeStore.getCurrentStep()

      componentWillUnmount: ->
         MobileTextMeSource.resetStep()

      handleClick: ->
         if @state.currentStep is 0
            if @state.isValid
               @handleSendText()
               MobileTextMeSource.incrementStep()
            else
               @setState({showError: true})
         else
            MobileTextMeSource.incrementStep()

      handleDone: ->
         @handleExit()

      handleExit: ->
         @props.requestHide()

      handleSendText: ->
         MobileTextMeSource.sendSMS(@state.number)

      handleChange: (number, isValid) ->
         @setState
            number: number
            isValid: isValid
         if @state.currentStep is 0 and isValid
            @setState showError: false

      stepOne: ->
         div className: "get-mobile--modal",
            div className: "modal-confirm--header",
               h3 null,
                  "Get the Mobile App"
                  Icon icon: "close", className: "modal-confirm--close", onClick: @handleExit

            div className: "get-mobile--text-me",
               div className: "get-mobile--content",
                  p null,
                     "Provide your mobile phone number below and we’ll send you an App Store link."
                  p className: "get-mobile--label",
                     "Mobile Phone Number"
                  div null,
                     PhoneInput
                        className: classSet("get-mobile--input", {"is-invalid": @state.showError})
                        onChange: @handleChange
                     div className: "get-mobile--error",
                        if @state.showError then "Please enter a valid mobile phone number able to receive text messages."

               div className: "get-mobile--lower",
                  Button type: "action", className: "get-mobile--send", onClick: @handleClick,
                     i className: "fa fa-mobile"
                     span null,
                        "Send Message"
                  p className: "get-mobile--info",
                     "Standard SMS fees may apply."
                  div className: "get-mobile--alternate",
                     span className: "get-mobile--or",
                        "or"
                  div className: "get-mobile--download",
                     i className: "fa fa-apple"
                     Link
                        className: "get-mobile--appstore-link"
                        "data-bypass": true
                        target: "_blank"
                        title: "downloading the EverTrue app"
                        href: "http://www.evertrue.com/getapp"
                        " Download on the App Store"

      stepTwo: ->
         div className: "mobile-success",
            i className: "fa et-icon-close fa-lg pull-right", onClick: @handleExit
            div className: "mobile-success--content",
               i className: "fa fa-check-circle"
               p className: "mobile-success--text",
                  "A link to download the EverTrue app has been sent to your mobile device."
               Button type: "action", className: "mobile-success--done", onClick: @handleDone,
                  "Done"
               LinkButton className: "mobile-success--resend", onClick: @handleSendText, title: "Resend the download link",
                  "Resend Link"

      render: ->
         switch @state.currentStep
            when 0 then @stepOne()
            when 1 then @stepTwo()
            else null
