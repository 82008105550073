import { Component } from "react";
import _ from "underscore";
import PropTypes from "prop-types";
import { connect } from "@evertrue/et-flux";
import StageSource from "apps/assignments/sources/stage-source";
import StageStore from "apps/assignments/stores/stage-store";
import IsGated from "components/is-gated";

const mapStateToProps = () => ({
  stageGroups: StageStore.getStageGroups(),
  loading: StageStore.getLoading(),
});

class StageResolver extends Component {
  static propTypes = {
    stageGroups: PropTypes.object,
    stageGroupId: PropTypes.number,
    loading: PropTypes.bool,
    type: PropTypes.string,
    children: PropTypes.any,
  };

  static defaultProps = {
    stageGroups: {},
    type: "POOL",
  };

  componentDidMount() {
    StageSource.fetchStageGroupsUnlessCached(this.props.type);
  }

  componentDidUpdate(prevProps) {
    if (this.props.stageGroupId && this.props.stageGroupId !== prevProps.stageGroupId) {
      StageSource.fetchGroupUnlessCached(this.props.stageGroupId, this.props.type);
    }

    if (this.props.type !== prevProps.type) {
      StageSource.fetchStageGroupsUnlessCached(this.props.type);
    }
  }

  render() {
    // stages are associated to assignObj w/ a string, so unique the names
    const group = this.props.stageGroups[this.props.stageGroupId] || {};
    const stagesForGroup = _.chain(group.stages)
      .filter(({ active }) => active)
      .uniq(({ stage }) => stage)
      .value();

    if (this.props.type === "POOL") {
      return (
        <IsGated feature="volunteers_stages">
          {({ show }) =>
            this.props.children({
              hasStagesFeature: show,
              stageGroups: this.props.stageGroups,
              stagesForGroup,
              loading: this.props.loading,
            })
          }
        </IsGated>
      );
    } else {
      return this.props.children({
        hasStagesFeature: true,
        stageGroups: this.props.stageGroups,
        stagesForGroup,
        loading: this.props.loading,
      });
    }
  }
}

export default connect(StageResolver, [StageStore], mapStateToProps);
