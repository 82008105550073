/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback, useRef, useContext } from "react";
import { SearchBox, Button, Loading } from "@evertrue/et-components";
import EmailTemplateCard from "./email-template-card";
import EmptyStateMessage from "components/lists/empty-state-message";
import useDebounce from "hooks/use-debounce";
import Proptypes from "prop-types";
import _ from "lodash";
import useInfiniteScroll from "hooks/use-infinite-scroll";
import { EmailTemplatesContext } from "../hooks/email-templates-context";

const LIMIT = 50;

const EmailTemplatesLibrary = ({ selectedTemplate, setSelectedTemplate, setShowEditView, showEditView }) => {
  const [searchVal, setSearchVal] = useState("");
  const debouncedSearchVal = useDebounce(searchVal, 500);
  const templateListRef = useRef(null);
  const { getAndSetTemplates, total, templates, setTemplates, loading, searchEmailTemplates } =
    useContext(EmailTemplatesContext);

  const searchAndSetTemplates = useCallback(async () => {
    const templates = await searchEmailTemplates(debouncedSearchVal);
    setTemplates(templates);
    if (_.isEmpty(selectedTemplate)) {
      setSelectedTemplate(templates[0] || {});
    }
  }, [debouncedSearchVal, setSelectedTemplate]);

  const [loadingMore, resetOffset] = useInfiniteScroll(templateListRef, getAndSetTemplates, total, LIMIT);

  useEffect(() => {
    if (!showEditView) {
      if (debouncedSearchVal !== "") {
        searchAndSetTemplates(debouncedSearchVal);
      } else {
        getAndSetTemplates(0, resetOffset);
      }
    }
  }, [debouncedSearchVal, setSelectedTemplate, showEditView]);

  return (
    <div className="templates-library--container">
      <>
        <SearchBox
          value={searchVal}
          onChange={(query) => setSearchVal(query)}
          placeholder="Search templates..."
          style={{ marginRight: "15px" }}
        />
        <div
          className={`template-library--list ${templates.length <= 0 ? "template-library--empty" : ""}`}
          ref={templateListRef}
        >
          {loading ? (
            <div style={{ position: "relative", paddingTop: "350px" }}>
              <Loading />
            </div>
          ) : templates.length > 0 ? (
            <>
              {templates.map((template) => {
                return (
                  <div key={template.id}>
                    <EmailTemplateCard
                      template={template}
                      setSelectedTemplate={setSelectedTemplate}
                      selectedTemplate={selectedTemplate}
                      templates={templates}
                      setTemplates={setTemplates}
                      setShowEditView={setShowEditView}
                    />
                  </div>
                );
              })}
              {loadingMore && (
                <div style={{ position: "relative", bottom: "-20px", height: "45px" }}>
                  <Loading size="small" />
                </div>
              )}
            </>
          ) : (
            <EmptyStateMessage text="No templates found" />
          )}
        </div>
      </>
      <Button
        onClick={() => {
          setSelectedTemplate({});
          setShowEditView(true);
        }}
        type="secondary"
        className="create-template-btn"
      >
        Create Template
      </Button>
    </div>
  );
};

EmailTemplatesLibrary.propTypes = {
  selectedTemplate: Proptypes.object,
  setSelectedTemplate: Proptypes.func,
  setShowEditView: Proptypes.func,
  showEditView: Proptypes.bool,
};

export default EmailTemplatesLibrary;
