import { createStore } from "@evertrue/et-flux";
import InviteTeamSource from "apps/settings/invite-team-settings/sources/invite-team-source";

const InviteTeamStore = createStore("InviteTeamStore", {
  getInitialState() {
    return {
      errors: [],
    };
  },

  registerActions() {
    this.on(InviteTeamSource.actions.sentInvite, () => {
      this.setState({ errors: [], formSubmitted: true, bulkSuccessfulInvites: [] });
    });

    this.on(InviteTeamSource.actions.setErrors, (errorResponse, error_code, user_object) => {
      const errors = [
        { invitation: user_object.affiliation_invitation, error: errorResponse, error_code, key: Math.random() },
      ];
      this.setState({ errors, formSubmitted: false });
    });

    this.on(InviteTeamSource.actions.setBulkErrors, (bulkErrors, bulkSuccessfulInvites) => {
      this.setState({ errors: bulkErrors, formSubmitted: false, bulkSuccessfulInvites });
    });

    this.on(InviteTeamSource.actions.setSupportError, () => {
      this.setState({ supportError: true, formSubmitted: false });
    });

    this.on(InviteTeamSource.actions.setLoading, loading => {
      this.setState({ loading });
    });

    this.on(InviteTeamSource.actions.clearErrors, () => {
      this.setState({ errors: [], bulkSuccessfulInvites: [], formSubmitted: false });
    });
  },

  api: {
    getSuccessfulInvites() {
      return this.getState("bulkSuccessfulInvites");
    },
    getErrorMessages() {
      return this.getState("errors");
    },
    getSupportError() {
      return this.getState("supportError");
    },
    getIsFormSubmitState() {
      return this.getState("formSubmitted");
    },
    getIsLoadingState() {
      return this.getState("loading");
    },
  },
});

export default InviteTeamStore;
