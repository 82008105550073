import { useState, useEffect } from "react";
import Api from "entities/helpers/api";

const useRecentlyViewed = ({ limit }) => {
  const [recentlyViewed, setRecentlyViewed] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadRecentlyViewed = async () => {
      setLoading(true);

      try {
        const resp = await Api.CONTACTS.RECENT.get({
          params: { limit },
        });
        setRecentlyViewed(resp.items);
        setLoading(false);
      } catch {
        setLoading(false);
      }
    };
    loadRecentlyViewed();
  }, [limit]);

  return { recentlyViewed, loading };
};

export default useRecentlyViewed;
