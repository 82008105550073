import Loading from "components/elements/loading";
import AiIcon from "../../../../../src/images/ai-icon.png";
import PropTypes from "prop-types";

const AISummary = ({
  isStreaming,
  summary,
  requestId }) => {

  return (
    <div className="profile-ai-summary fs-exclude-data">
      <div className="profile-summary-pill">
        <img src={AiIcon} alt="AI Icon" className="profile-summary-ai-icon" />
        AI-generated Summary
      </div>
      {isStreaming && !summary ? (
        <div className="loader-div">
          <Loading spinner_size="small" position="center" />
        </div>
      ) : (
        <div>
          <p>{summary}</p>
          {!isStreaming && <p><small>{`Request ID: ${requestId}`}</small></p>}
        </div>
      )}
    </div>
  );
};

AISummary.propTypes = {
  isStreaming: PropTypes.bool,
  summary: PropTypes.string,
  requestId: PropTypes.string,
};

export default AISummary;
