module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   EverTrue = require("app")
   {createComponent} = require("@evertrue/et-flux")
   {div, table, tbody, tr, td, span, i, a} = ReactLibs.DOMFactories
   FilterStore = require("apps/filters/stores/filter-store")
   MapStore = require("apps/map/stores/map-store")
   MapSource = require("apps/map/sources/map-source")
   MapUtils = require("apps/map/map-utils")
   GeolocationSource = require("apps/contact/sources/geolocation-source")
   GeolocationStore = require("apps/contact/stores/geolocation-store")
   SegmentStore = require("apps/filters/stores/segment-store")
   UrlSource = require("apps/layout/sources/url-source")
   Parameter = require("entities/search/query-parts/parameter-query")
   Property = require("entities/search/query-parts/property-query")
   {createFactory} = require("base/new-utils")
   MapWithClusters = createFactory require("apps/map/components/map-with-clusters").default
   ClusterContactSource = require("apps/map/sources/cluster-contact-source")
   ListContactSource = require("apps/lists/sources/list-contact-source")
   SelectedContactsSource = require("apps/contact/sources/selected-contacts-source")


   createComponent "ListMapController",
      propTypes:
         list_id: ReactLibs.PropTypes.any

      registerStores: ->
         @on MapStore, ->
            zoom: MapStore.getZoom()
            clusters: MapStore.getClusters()
            loading_clusters: MapStore.getLoadingClusters()
            bounding_box: MapStore.getBoundingBox()

      UNSAFE_componentWillMount: ->
         UrlSource.fetchMap()

      componentWillUnmount: ->
         SelectedContactsSource.clear()
         ListContactSource.filter(@props.list_id)

      getQuery: (bounds) ->
         query_bounds = MapUtils.getQueryFromBox(bounds)
         must: [
            {"list_memberships.list_id": {match: @props.list_id}}
            {"addresses.location": query_bounds}
         ]

      handleMapInit: (bounds) ->
         MapSource.fetchClusters(@state.zoom, {query: @getQuery(bounds)})
         MapSource.setBoundingBox(bounds)
         @handleContactFilter(bounds)

      handleMapChange: (zoom, bounds) ->
         MapSource.fetchClusters(zoom, {query: @getQuery(bounds)})
         MapSource.setBoundingBox(bounds)
         UrlSource.updateMap(bounds)
         @handleContactFilter(bounds)

      handleContactFilter: (bounds) ->
         SelectedContactsSource.clear()
         ListContactSource.filter(@props.list_id, {"addresses.location": bounds})

      handleClusterOpen: (key, cluster) ->
         ClusterContactSource.fetch(key, @getQuery(cluster.geobox))

      render: ->
         div className: "map-search-controller",
            MapWithClusters
               clusters: @state.clusters
               loading: @state.loading_clusters
               defaultBounds: @state.bounding_box
               onMapInit: @handleMapInit
               onMapChange: @handleMapChange
               onClusterOpen: @handleClusterOpen
