import { PageHeader, Button, useOpenClose } from "@evertrue/et-components";
import FormField from "components/forms/form-field";
import ControlledMaskedInput from "components/forms/controlled-masked-input";
import ValidatePhoneModal from "./validate-phone-modal";
import useCallSettings from "./use-call-settings";
import PropTypes from "prop-types";

const FormFieldWithMaskedInput = ({ label, placeholder, value, onChange, mask, className }) => (
  <FormField label={label} inlineLabel={false} className={`call-settings-${className}-label`}>
    <ControlledMaskedInput
      type="text"
      mask={mask}
      placeholder={placeholder}
      placeholderChar="|"
      value={value}
      className={`call-settings-${className}-input`}
      onChange={onChange}
    />
  </FormField>
);

const PhoneSettingsForm = () => {
  const { callerIdNumber, setCallerIdNumber, validatePhoneNumber, validationCode, handleCloseModal } =
    useCallSettings();
  const [isValidationModalOpen, openValidationModal, closeValidationModal] = useOpenClose();

  const handleVerify = async () => {
    await validatePhoneNumber(callerIdNumber, openValidationModal);
  };

  const handleClose = async () => {
    await handleCloseModal(closeValidationModal);
  };

  return (
    <>
      <PageHeader title="Phone" className="call-settings-header" />
      <p className="call-settings-custom-number-header">Custom Caller ID Number</p>
      <p>Required to use phone features within EverTrue.</p>
      <div style={{ display: "flex" }}>
        <FormFieldWithMaskedInput
          placeholder="(111) 111-1111"
          label="This number will appear on the call recipient’s caller ID in place of your EverTrue number. To use this feature you must verify that you own the number entered here. Please be sure to complete steps requested and end the call before closing the pop-up verification code window."
          value={callerIdNumber}
          onChange={(number) => setCallerIdNumber(number)}
          mask={["(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
          className="custom-number"
        />
        <div className="call-settings-custom-number-verify">
          <Button onClick={handleVerify} className="call-settings-verify-button">
            Verify
          </Button>
        </div>
      </div>

      <ValidatePhoneModal code={validationCode} isOpen={isValidationModalOpen} onClose={handleClose} />
    </>
  );
};

FormFieldWithMaskedInput.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  mask: PropTypes.array.isRequired,
  className: PropTypes.string.isRequired,
};

export default PhoneSettingsForm;
