module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   EverTrue = require("app")
   {createSource} = require("@evertrue/et-flux")

   STATUSES = ["inactive", "code-unsent", "prompting", "requesting", "locked"]
   RESEND_STATUSES = ["ready", "requesting", "success", "rate-limited", "error"]

   _replay_request = undefined
   _resend_request = undefined
   _timeout = undefined

   _reset = ->
      clearTimeout(_timeout)
      _replay_request = undefined
      _resend_request = undefined
      _timeout = undefined

   createSource "mfaSource",
      actions:
         newStatus: (status) ->
            @require _.contains(STATUSES, status), "status should one of: '#{RESEND_STATUSES.join("', '")}'"

         newError: (error) ->
            @require _.isString(error), "error should be a string message"

         newResendStatus: (status) ->
            @require _.contains(RESEND_STATUSES, status), "resend status should one of: '#{RESEND_STATUSES.join("', '")}'"

         newMfaNumber: true
         isTrustRequest: true
         newMfaPromptText: true

      handleRerunSuccess: ->
         @actions.newStatus("inactive")

      handleRerunError: (error_info = {}) ->
         @actions.newStatus("prompting")
         {error_code, message} = error_info

         switch error_code
            when "invalid_otp"
               @actions.newError(message)

            when "mfa_locked"
               @actions.newError(message)
               @actions.newStatus("locked")

            when "mfa_required"
               EverTrue.Alert.error(message)
               EverTrue.execute("resetApp")
            else
               Raven?.captureMessage("MFA Error on request retry", {extra: error_info})

      rerunRequest: (request, code, trust_device) ->
         request.headers["Authorization-OTP"] = code

         if trust_device?
            trust_value = if trust_device then 1 else 0
            request.headers["Authorization-Trust-Device"] = trust_value

         $.ajax(request)
         @actions.newStatus("requesting")
         
      setResetCodeStatus: (delay = 10000) ->
         clearTimeout(_timeout)
         _timeout = _.delay((=> @actions.newResendStatus("ready")), delay)

      createReplayRequest: (original_request) ->
         source = this
         {success, error} = original_request

         cloned = $.extend true, {}, original_request
         _.extend cloned,
            success: ->
               _reset()
               source.handleRerunSuccess()
               success?.apply(this, arguments)
            error: (xhr) ->
               if xhr.status is 403
                  source.handleRerunError(xhr.responseJSON)
               else
                  error?.apply(this, arguments)

      createResendRequest: (original_request) ->
         cloned = $.extend true, {}, original_request
         cloned.headers or= {}
         cloned.headers["Authorization-Auto-Send"] = 1
         _.extend cloned,
            success: =>
               @actions.newResendStatus("error")
            error: (xhr) =>
               switch xhr.responseJSON?.otp_sent
                  when true then @actions.newResendStatus("success")
                  when false then @actions.newResendStatus("rate-limited")
                  else @actions.newResendStatus("error")
            complete: =>
               @actions.newStatus("prompting")
               @setResetCodeStatus()


      api:
         requestLoginCode: (original_request, response) ->
            _replay_request = @createReplayRequest(original_request)
            # replay_request is the request we'll replay when we get an otp_code from the user.

            _resend_request = @createResendRequest(original_request)
            # resend_request is a call we can make to inititate another otp_code being sent.

            if response.otp_sent
               @actions.newStatus("prompting")
            else
               @actions.newStatus("code-unsent")

            if number = response.mfa?.otp_mobile
               @actions.newMfaNumber(number)

            if response.mfa?.prompt_trust and original_request.headers?["Authorization-Trust-Device"] isnt 1
               # the api will prompt for the trust checkbox,
               # but we don't show the checkbox if the original request was an attempt to trust
               @actions.isTrustRequest()

            if prompt_text = original_request.mfaPromptText
               @actions.newMfaPromptText prompt_text

         requestAdditionalCode: ->
            @actions.newResendStatus("requesting")
            $.ajax(_resend_request)

         verify: (code, trust_device) ->
            @rerunRequest(_replay_request, code, trust_device)

         cancelMfaRequest: ->
            _reset()
            @actions.newStatus("inactive")




