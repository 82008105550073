module.exports = do ->
   _ = require("underscore").default
   Query = require("entities/search/query-parts/query")
   Parameter = require("entities/search/query-parts/parameter-query")
   Property = require("entities/search/query-parts/property-query")
   ChildDoc = require("entities/search/query-parts/child-doc-query")
   {getEngagementActionsByTab, getTabByEngagementAction} = require("apps/events/utils/engagement-config")

   INTERESTED_ACTIONS = ["Attending", "Checked In", "attending", "unsure"]

   OPTIONS = [
      key: "upcoming_interest"
      value: {gte: "now-1d"}
   ,
      key: "one_month_interest"
      value: {gte: "now-1M", lte: "now"}
   ,
      key: "one_year_interest"
      value: {gte: "now-1y", lte: "now"}
   ,
      key: "any"
      value: {}
   ]

   _getListValues = (value) ->
      _.compact _.map value, (val) -> val?.value

   _getEventDateQuery = (value) ->
      date = value.event_date
      if _.notEmpty(date)
         Property "event.start_time", date, {type: "object"}

   _getEventIdQuery = (value) ->
      if events = value.events
         Property "event.id", _getListValues(events), {type: "contains"}

   _getResponseQuery = (value) ->
      values = _getListValues(value.event_response)
      actions = _.flatMap values, getEngagementActionsByTab
      if _.notEmpty(actions)
         Property "engagement_action", actions, type: "contains"

   _getSourceQuery = (features = {}) ->
      Property "event.source", _.compact [
         if features.hasFacebook then "facebook"
         if features.hasEventbrite then "eventbrite"
      ], type: "contains"

   _getInterestedQuery = (dateObj, features) ->
      Query [ Parameter "must", [
         ChildDoc "contact_event_engagement", Query [
            Parameter "must", _.compact [
               _getEventDateQuery(event_date: dateObj)
               Property "engagement_action", INTERESTED_ACTIONS, type: "contains"
               _getSourceQuery(features)
            ]
         ]
      ]]

   _getParamFromDate = (date) ->
      _.find(OPTIONS, ({value}) -> _.isEqual(date, value))?.key

   _getValueByKey = (must, key) ->
      _.find(must, (prop) -> prop[key]?)?[key]

   return {
      getQuery: (value = {}, features) ->
         param = value.param
         if param in ["must", "must_not"]
            Query [ Parameter param, [
               ChildDoc "contact_event_engagement", Query [
                  Parameter "must", _.compact [
                     _getEventDateQuery(value)
                     _getEventIdQuery(value)
                     _getResponseQuery(value)
                     _getSourceQuery(features)
                  ]
               ]
            ]]
         else if dateObj = _.findWhere(OPTIONS, key: param)?.value
            _getInterestedQuery(dateObj, features)
         else if param is "any"
            _getInterestedQuery(null, features)


      parseCriteria: (criteria) ->
         {parameter, value} = criteria.getAll()?[0] || {}
         event_must = value?[0]?.query?.must

         action_keys = _getValueByKey(event_must, "engagement_action")?["in"]

         # check for a match against standard action keys
         if _.isEqual(action_keys, INTERESTED_ACTIONS)

            # check if the time matches a standard time
            if param = _getParamFromDate(_getValueByKey(event_must, "event.start_time"))
               return {param: param}

         # since this isn't a canned query, just parse it as an advanced query
         filters = param: parameter

         _.each event_must, (property) ->
            key = _.keys(property)?[0]
            value = _.values(property)?[0]
            switch key
               when "event.start_time"
                  filters.event_date = value
               when "event.id"
                  vals = _.uniq(value.in)
                  if _.notEmpty(vals)
                     filters.events = _.map vals, (val) -> value: val
               when "engagement_action"
                  vals = _.uniq(_.map(value.in, getTabByEngagementAction))
                  if _.notEmpty(vals)
                     filters.event_response = _.map vals, (val) -> value: val

         return filters
   }

