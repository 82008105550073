module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   numeral = require("numeral")
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories
   ListSource = require("apps/lists/sources/list-source")
   FilterStore = require("apps/filters/stores/filter-store")
   FeatureStore = require("apps/layout/stores/feature-store").default
   {createFactory} = require("base/new-utils")
   Modal = createFactory require("components/modals/modal")
   ModalConfirm = createFactory require("components/modals/modal-confirm")
   ExportSearchController = createFactory require("apps/export/controllers/export-search-controller")
   SaveSegmentController = require("apps/filters/controllers/save-segment-controller")
   ShareSegmentController = require("apps/filters/controllers/share-segment-controller")
   ListSelectorController = createFactory(require("apps/lists/controllers/lists-selector-controller").default)
   BulkAssignContactModal = require("apps/contact/components/profile/bulk-assign-contact-modal")
   ActionMenuItem = createFactory(require("components/controls/action-menu-item").default)


   createComponent "SegmentActions",
      propTypes:
         segment_id: ReactLibs.PropTypes.number
         segment_type: ReactLibs.PropTypes.string
         result_count: ReactLibs.PropTypes.number
         selected_contacts: ReactLibs.PropTypes.array
         onExport: ReactLibs.PropTypes.func

      registerStores: ->
         @on FilterStore, ->
            active_filters: FilterStore.getActiveFilters()

         @on FeatureStore, ->
            has_bulk_writes: FeatureStore.hasFeature("write_assignee")

      handleDeleteSegment: ->
         EverTrue.Alert.confirm
            content: "Deleted saved searches cannot be recovered. Are you sure you want to delete this saved search?"
            headline: "Delete Saved Search?"
            buttonLabel: "Delete Saved Search"
         , (e) =>
            ListSource.delete(@props.segment_id) if e
            _.defer (-> EverTrue.Navigator("/contact", true))

      renderExportModal: ->
         Modal width: 600, keepOpen: true,
            ExportSearchController
               onExport: @props.onExport
               exportText: "You are about to export #{@props.result_count} records"


      renderShareModal: ->
         Modal width: 600, keepOpen: true,
            ModalConfirm header: "Share Current Search",
               ShareSegmentController()

      render: ->
         num_selected_contacts = @props.selected_contacts?.length
         constituent_text = if num_selected_contacts == 1 then "Constituent" else "Constituents"

         div className: "segment-actions",
            if !_.isEmpty(@props.selected_contacts)
               ActionMenuItem
                  title: "Add #{num_selected_contacts} #{constituent_text} to a List"
                  icon: "playlist-add"
                  modal: Modal width: 400,
                     ModalConfirm header: "Add Constituents to List",
                        ListSelectorController contactIds: @props.selected_contacts
                  "Add #{num_selected_contacts} #{constituent_text} to a List"

            if !_.isEmpty(@props.selected_contacts) and @state.has_bulk_writes and EverTrue.store?.user?.isOwnerOrSuperUser()
               ActionMenuItem
                  title: "Add Assignment to #{num_selected_contacts} #{constituent_text}"
                  icon: "playlist-add"
                  modal: Modal width: 400, keepOpen: true,
                     BulkAssignContactModal contactIds: @props.selected_contacts
                  "Add Assignment to #{num_selected_contacts} #{constituent_text}"

            if !_.isEmpty(@state.active_filters)
               ActionMenuItem
                  title: "Save Search Criteria"
                  icon: "turned-in"
                  modal: Modal(width: 500, SaveSegmentController(segment_id: @props.segment_id))
                  "Save Search Criteria"

            if !_.isEmpty(@state.active_filters)
               ActionMenuItem
                  title: "Share Current Search Criteria"
                  icon: "share"
                  modal: @renderShareModal()
                  "Share Current Search Criteria"

            ActionMenuItem
               title: "Export #{numeral(@props.result_count).format("0,0")} Results"
               icon: "cloud-download"
               modal: @renderExportModal()
               "Export #{numeral(@props.result_count).format("0,0")} Results"


            if @props.segment_id && @props.segment_type == "saved_search"
               ActionMenuItem
                  title: "Delete Saved Search"
                  icon: "delete"
                  className: "text-danger"
                  onClick: @handleDeleteSegment
                  "Delete Saved Search"
