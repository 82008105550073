module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   React = require("react")
   {div} = ReactLibs.DOMFactories
   {createComponent} = require("@evertrue/et-flux")
   UserStore = require("apps/users/stores/user-store")
   InviteStore = require("apps/users/stores/invite-store")
   {createFactory} = require("base/new-utils")
   ModalConfirm = createFactory require("components/modals/modal-confirm")
   UserCard = createFactory require("apps/users/components/user-card")

   createComponent "DeleteUser",
      propTypes:
         user: ReactLibs.PropTypes.object
         onClose: ReactLibs.PropTypes.func

      handleDelete: ->
         if @props.user.is_invite
            InviteStore.delete(@props.user.id)
         else
            UserStore.delete(@props.user.id)
         @props.onClose()

      render: ->
         user = @props.user

         div className: "delete-user",
            ModalConfirm
               type: "error"
               header: "Remove User?"
               buttonLabel: "Remove"
               onSubmit: @handleDelete
               onCancel: @props.onClose

               div className: "delete-user--users",
                  UserCard user: user

               div className: "delete-user--text",
                  if user.is_invite
                     "If you remove this user, their pending invitation will no longer be valid."
                  else
                     "Revoke this user's access to EverTrue"
