import { createContext, useEffect, useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import DEFAULT_QUERY from "apps/query-builder/utils/default-query";
import { useIdentity } from "base/identity-resolver";
import useTableSettings from "hooks/use-table-settings";
import { getBounds, getMapSettings } from "apps/search/utils/map-helpers";
import { getQuery } from "apps/search/utils/search-helper";

const DEFAULT_MAP_SETTINGS = { zoom: 3, center: { lat: 40.245992, lng: -96.502414 } };

const GlobalContactSearchContext = createContext(() => {});

const GlobalContactSearchProvider = ({ children }) => {
  const [filters, setFilters] = useState(DEFAULT_QUERY); // object containing base query filters and quick filters
  const [mapCoordinates, setMapCoordinates] = useState(null); // object containing north, south, east, and west boundaries of the map
  const [radius, setMapRadius] = useState(null); // object containing map center and radius
  const [mapFilters, setMapFilters] = useState(null); // array of the selected map quickfilters for the dsl query
  const [selectedAddressTypes, setSelectedAddressTypes] = useState([]); // array of the selected address types selected from map quickfilters
  const [mapMarkers, setMapMarkers] = useState({}); // object of arrays consisting of long, lat, and id for each marker
  const [mapSettings, setMapSettings] = useState(DEFAULT_MAP_SETTINGS); // object containing map center and zoom
  const [modified, setModified] = useState(false);
  const [isAllSelectedCheckbox, setIsAllSelectedCheckbox] = useState(false);
  const { tableSettings, updateTableSettings, resetTable } = useTableSettings();
  const { org } = useIdentity();

  const dslQuery = useMemo(() => {
    return getQuery(filters, mapCoordinates, mapFilters, radius);
  }, [filters, mapCoordinates, mapFilters, radius]);

  const setContactFilters = useCallback(
    (dsl, shouldResetTable = true) => {
      setFilters(dsl);
      if (shouldResetTable) resetTable();
    },
    [resetTable]
  );

  const resetMapFilter = useCallback(
    (resetMap) => {
      setMapRadius(null);
      setMapMarkers({});
      setMapFilters(null);
      setSelectedAddressTypes([]);
      setMapSettings(DEFAULT_MAP_SETTINGS);
      if (resetMap) setMapCoordinates(null);
    },
    [setMapFilters]
  );

  const resetFiltersAndSettings = useCallback(
    (resetMap = true) => {
      setContactFilters(JSON.parse(JSON.stringify(DEFAULT_QUERY)));
      setModified(false);
      resetMapFilter(resetMap);
    },
    [setContactFilters, resetMapFilter]
  );

  const filterCount = filters.filters.blocks.reduce((acc, cur) => {
    if (cur.rules.length === 1 && Object.keys(cur.rules[0]).length === 0) {
      return acc;
    }
    return (acc += cur.rules.length);
  }, 0);

  const quickFiltersCount = filters.quick_filters.length;

  useEffect(() => {
    resetFiltersAndSettings();
  }, [org.id, resetFiltersAndSettings]);

  const updateMapBounds = useCallback(
    (map) => {
      if (map) {
        setMapSettings(getMapSettings(map));
        setMapCoordinates(getBounds(map));
        setMapMarkers({});
      }
    },
    [setMapCoordinates, setMapMarkers]
  );

  return (
    <GlobalContactSearchContext.Provider
      value={{
        contactFilters: filters,
        setContactFilters,
        resetFiltersAndSettings,
        tableSettings,
        updateTableSettings,
        modified,
        setModified,
        filterCount,
        quickFiltersCount,
        isAllSelectedCheckbox,
        setIsAllSelectedCheckbox,
        mapCoordinates,
        setMapCoordinates,
        mapMarkers,
        setMapMarkers,
        selectedAddressTypes,
        setSelectedAddressTypes,
        mapFilters,
        setMapFilters,
        updateMapBounds,
        radius,
        setMapRadius,
        dslQuery,
        resetMapFilter,
        mapSettings,
      }}
    >
      {children}
    </GlobalContactSearchContext.Provider>
  );
};

GlobalContactSearchProvider.propTypes = {
  children: PropTypes.any,
};

export { GlobalContactSearchProvider, GlobalContactSearchContext };
