module.exports = do ->
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")


   createSource "MembershipSource",
      actions:
         fetch: true
         fetchedForContact: true
         fetchedTripsForContact: true
         loading: true

      api:
         fetch: (contact_id) ->
            @actions.fetch(contact_id)

         fetchListsForContact: (contact_id) ->
            @actions.loading(true)

            Api.CONTACTS.LISTS.get
               urlExtend: "/contact/#{contact_id}"
               disableAlerts: true
               error: => @actions.loading(false)
               success: (resp) =>
                  @actions.fetchedForContact(contact_id, resp)
                  @actions.loading(false)

         fetchTripsForContact: (contact_id) ->
            @actions.loading(true)

            Api.VOYAGER.TRIPS.get
               params: {contact_id: contact_id}
               disableAlerts: true
               error: => @actions.loading(false)
               success: (resp) =>
                  @actions.fetchedTripsForContact(contact_id, resp)
                  @actions.loading(false)
