import { fetchFromGraphql } from "entities/helpers/gqlCall";

const gql = String.raw;

export const getHouseholdMembersForContact = async (contact) => {
  const query = gql`
  query ($id: BigInt!) {
    contact (id: $id){
      household_members {
        id,
        contact_attributes {
          name_full
        }
      }
    }
  }`;

  const params = { id: contact.id };
  const result = await fetchFromGraphql(query, params, "getHouseholdMembersForContact");
  const householdMembers = result.contact && result.contact.household_members ? result.contact.household_members : [];
  return householdMembers
};