import { useEffect, useContext } from "react";
import Map from "apps/mapv2/components/map";
import MapContactList from "apps/search/components/map-contact-list";
import { PropTypes } from "base/react-libs";
import useMap from "apps/mapv2/hooks/use-map";
import useMapContacts from "apps/search/hooks/use-map-contacts";
import MapToolbar from "apps/mapv2/components/map-toolbar";
import MapAddressFilter from "../components/map-address-filter";
import useMapMarkerTooltip from "apps/search/hooks/use-map-marker-tooltip";
import getGoogleApi from "apps/search/utils/get-google-api";
import { GlobalContactSearchContext } from "apps/layout/controllers/global-contact-search-context";

const google = getGoogleApi();
const defaultMarker = "/images/map_pin_person.png";

const MapsController = ({ contacts, loading }) => {
  const { mapSettings, radius } = useContext(GlobalContactSearchContext);
  const { createMapMarkers, clearMap, map, googleMapMarkers } = useMap(defaultMarker, mapSettings);
  const { handleInit, handleMapChange, updateMapFilters, handleRadiusChange } = useMapContacts(
    contacts,
    map,
    clearMap,
    createMapMarkers
  );

  const { handleMarkerMouseOver, closeAllInfoWindow } = useMapMarkerTooltip();

  useEffect(() => {
    googleMapMarkers.current.forEach(({ marker, position }) => {
      google.event.addListener(marker, "mouseover", () => {
        closeAllInfoWindow(googleMapMarkers);
        handleMarkerMouseOver(marker, map, position, googleMapMarkers);
      });
    });
  });

  return (
    <div className="maps-controller">
      <MapContactList loading={loading} contacts={contacts} />
      <Map map={map} onInit={handleInit} onMapCoordinateChange={handleMapChange}>
        <MapToolbar
          map={map}
          onMapCoordinateChange={handleMapChange}
          onMapRadiusChange={handleRadiusChange}
          radius={radius ? radius.radius : null}
        >
          <MapAddressFilter updateMapFilters={updateMapFilters} />
        </MapToolbar>
      </Map>
    </div>
  );
};

MapsController.propTypes = {
  contacts: PropTypes.object,
  loading: PropTypes.bool,
};

export default MapsController;
