import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import _ from "underscore";
import get from "lodash.get";
import {
  ModalHeader,
  ModalBody,
  FlexTable,
  Button,
  useInfiniteScroll,
  EmptyStateMessage,
  Loading,
} from "@evertrue/et-components";
import API from "entities/helpers/api";
import DisplayWidgetModalColumns from "apps/dashboard/components/display-widget-columns";

const LIMIT = 20;

/**
 * This is written with a lot of assumptions about the general structure of widget data and data retrieval.
 * All widgets should have a value_display_results array in the template object.
 * This holds info about what query to run to retrieve value results, and how the value results should be parsed out.
 *
 * @param contact_id
 * @param title
 * @param template
 * @param query
 * @param close
 * @returns {*}
 * @constructor
 */
const DisplayWidgetPagedResultsModal = ({
  variables = {},
  title = "Extra Info",
  template = {},
  headerStyle = {},
  close,
}) => {
  const valueResultsScrollBodyRef = useRef(null);
  const [valueResults, setValueResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const valueResultsDisplayInfo = template.value_results_display[activeTab];
  const query = valueResultsDisplayInfo.query;
  const key = valueResultsDisplayInfo.prop;
  const total_key = valueResultsDisplayInfo.total_prop;

  const fetchData = (didCancel = false) => {
    if (loading || (total > 0 && valueResults.length >= total)) {
      return;
    }

    let gqlVariables = {
      ...variables,
      page: page,
      perPage: LIMIT,
    };

    if (valueResultsDisplayInfo.sort) {
      gqlVariables.sort = { field: valueResultsDisplayInfo.sort, order: "ASC" };
    }

    API.GRAPHQL.GRAPHQL.post({
      data: JSON.stringify({
        operationName: null,
        query: query,
        variables: gqlVariables,
      }),
    })
      .then((resp = {}) => {
        const results = resp.data;
        const newValueResults = get(results, key);

        // This appends new value results to the existing value results.
        if (!didCancel) {
          setValueResults((prevVal) => {
            if (newValueResults && newValueResults.length > 0) {
              return [...prevVal, ...newValueResults];
            }
            return prevVal;
          });
        }

        const total = get(results, total_key);

        if (!didCancel) {
          // If a total count cannot be derived then set the total to be the length of the results so pagination will stop
          total ? setTotal(total) : setTotal(newValueResults.length);
          setPage((prev) => prev + 1);
          setLoading(false);
        }
      })
      .catch((err = {}) => {
        if (!didCancel) {
          setLoading(false);
        }
      });
  };

  useInfiniteScroll(fetchData, valueResultsScrollBodyRef.current);

  const renderValueResultsTable = (valueResults, valueResultsDisplayInfo) => {
    let data = valueResults;
    const query = valueResultsDisplayInfo.query;
    const regex = new RegExp("page", "g");
    // if "page" is in the query then we dont manually sort, it will be sorted already
    if (valueResultsDisplayInfo.sort && !regex.test(query)) {
      data = _.sortBy(valueResults, (row) => {
        return get(row, valueResultsDisplayInfo.sort);
      });
    }
    // If there are results and there is only a single value result display param then just show the table
    return (
      <div>
        {valueResultsDisplayInfo.columns ? (
          <FlexTable
            columns={DisplayWidgetModalColumns(
              valueResultsDisplayInfo.columns,
              variables.poolId ? variables.poolId : "",
              headerStyle
            )}
            data={data}
            caption="Table widget results"
            emptyStateMessageText="No results to display"
          />
        ) : null}
      </div>
    );
  };

  const renderTabbedView = () => {
    if (template.value_results_display && template.value_results_display.length > 1) {
      return (
        <div
          style={{
            display: "flex",
            alignDirection: "row",
          }}
        >
          {template.value_results_display.map((value_result_display_info, index) => (
            <Button
              type="simple"
              key={value_result_display_info.prop}
              className={`display-widget-results--button ${activeTab === index ? "active" : ""}`}
              title={value_result_display_info.label}
              onClick={() => {
                //Only update if the tab has actually changed.
                if (activeTab !== index) {
                  setValueResults([]);
                  setPage(1);
                  setTotal(0);
                  setActiveTab(index);
                }
              }}
            >
              {value_result_display_info.label}
            </Button>
          ))}
        </div>
      );
    }
  };

  useEffect(() => {
    let didCancel = false;

    if (template && template.value_results_display && template.value_results_display.length > 0) {
      setLoading(true);
      fetchData(didCancel);
    }

    return () => {
      didCancel = true;
    };
    // eslint-disable-next-line
  }, [activeTab]);

  const results_display = template && template.value_results_display ? template.value_results_display[activeTab] : {};
  // If the modal is tabbed then the widget result table's max height has to be a
  // bit small to account for the additional tabs.
  let isTabbedView = false;
  if (template && template.value_results_display && template.value_results_display.length > 1) {
    isTabbedView = true;
  }

  return (
    <>
      <ModalHeader title={title} closeModal={close} />
      <ModalBody scrollable={false}>
        <div className="display-widget--results-modal">
          {renderTabbedView()}
          <div
            className={`display-widget-results--table ${isTabbedView ? "is-tabbed-view" : ""}`}
            ref={valueResultsScrollBodyRef}
          >
            {loading ? (
              <Loading />
            ) : (
              <>
                {valueResults && valueResults.length > 0 ? (
                  renderValueResultsTable(valueResults, results_display)
                ) : (
                  <EmptyStateMessage text="No Results" />
                )}
              </>
            )}
          </div>
        </div>
      </ModalBody>
    </>
  );
};

DisplayWidgetPagedResultsModal.propTypes = {
  close: PropTypes.func.isRequired,
  template: PropTypes.object.isRequired,
  variables: PropTypes.object,
  title: PropTypes.string.isRequired,
  headerStyle: PropTypes.object,
};

export default DisplayWidgetPagedResultsModal;
