import EverTrue from "app";
import PropTypes from "prop-types";
import { useDashboard } from "apps/dashboard/hooks/use-user-dashboard";
import NavigationSubItem from "apps/layout/components/navigation-sub-item";
import { useIdentity } from "base/identity-resolver";

const NavigationDashboardLink = ({ active_path = "" }) => {
  const { user } = useIdentity();
  const { has_dashboard, completed_dashboard_checks } = useDashboard(user.id);
  if (!completed_dashboard_checks) return null;
  if (has_dashboard) {
    return (
      <NavigationSubItem
        icon="everhub"
        label="Launchpad"
        isActive={!!active_path.match(new RegExp(`dashboard.*`))}
        onClick={() => {
          EverTrue.track.set("tab_view", { type: "launchpad" });
          EverTrue.Navigator("/dashboard", true);
        }}
      />
    );
  } else {
    return null;
  }
};

NavigationDashboardLink.propTypes = {
  active_path: PropTypes.string,
};

export default NavigationDashboardLink;
