module.exports = do ->
   _ = require("underscore").default
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")
   Decorator = require("clientDecorator")
   PaginationUtils = require("mixins/pagination-utils")

   createSource "RecentContactSource",
      actions:
         loading: (bool) ->
            @require _.isBoolean(bool), "bool should be a boolean"
         changedPage: (page) ->
            @require _.isNumber(page), "page should be a number"
         fetchedRecentContacts: (contacts) ->
            @require _.isObject(contacts), "contacts should be an object"

      api:
         changePage: (page) ->
            @actions.changedPage(page)

         fetch: (options={}) ->
            @actions.loading(true)

            Api.CONTACTS.RECENT.get
               params: PaginationUtils.getParams(options)
               success: (resp) =>
                  resp.items = _.map resp.items, (contact) ->
                     Decorator.Contacts.parse(contact)
                  @actions.fetchedRecentContacts(resp)
                  @actions.loading(false)
               error: =>
                  @actions.loading(false)
