module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   EverTrue = require("app")
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   ListSource = require("apps/lists/sources/list-source")
   WindowSource = require("apps/layout/sources/window-source")
   SegmentStore = require("apps/filters/stores/segment-store")
   FilterStore = require("apps/filters/stores/filter-store")
   ContactQueryStore = require("apps/contact/stores/contact-query-store")
   SubscriptionPrefStore = require("apps/filters/stores/subscription-pref-store")
   {div, hr, input, h4} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   Nav = createFactory require("components/lists/nav")
   NavItem = createFactory require("components/lists/nav-item")
   SearchPreferenceSelect = require("apps/filters/components/search-preference-select")
   HelpTip = createFactory require("components/tooltip/helptip").default
   FilterConfigStore = require("apps/filters/stores/filter-config-store")
   Button = createFactory(require("@evertrue/et-components").Button)
   Switch = createFactory(require("@evertrue/et-components").Switch)


   createComponent "SaveSegmentController",
      propTypes:
         segment_id: ReactLibs.PropTypes.any
         onSave: ReactLibs.PropTypes.func

      getInitialState: ->
         action: "save"
         name: undefined
         temp_preferences: undefined

      registerStores: ->
         @on SegmentStore, ->
            segment: SegmentStore.getById(@props.segment_id)

         @on SubscriptionPrefStore, ->
            preferences: SubscriptionPrefStore.getBySegmentId(@props.segment_id)

         @on FilterStore, ->
            can_save_notifications: FilterStore.canSaveNotifications()
            active_filters: FilterStore.getActiveFilters()
            operator: FilterStore.getOperator()

         @on FilterConfigStore, ->
            options: FilterConfigStore.getAllFilterData()

      UNSAFE_componentWillMount: ->
         unless @props.segment_id
            all_labels = _.compact _.map @state.active_filters, (filter) =>
               _.findWhere(@state.options, {filter_id: filter.filter_id})?.label
            @setState {name: all_labels.join(" #{@state.operator} ")}

      componentDidMount: ->
         @refs.name.focus()
         if @state.segment?.type == "saved_search"
            @setState {action: "update"}

         unless @props.segment_id
            $(@refs.name).select()

      getPreferences: ->
         prefs = @state.temp_preferences || @state.preferences
         if @state.segment && @state.segment?.type != "saved_search" && !@state.temp_preferences
            prefs = SubscriptionPrefStore.getDefaultPreferences()
         prefs

      handleSelectAction: (action) ->
         @setState {action: action}
         _.defer((=> @refs.name.focus()))

      handleInputChange: (e) ->
         @setState {name: e.target.value}

      handleCancel: ->
         @refs.name.value = ""
         WindowSource.outsideClick()

      handleSaveSearch: ->
         name = @state.name || @state.segment?.name
         return unless name
         name = "Copy of #{name}" if @state.action == "save" && !@state.name

         activeFilters = @state.active_filters
         activeFiltersQueryResult = FilterStore.getActiveFiltersQuery()
         filterCriteria = _.extend {}, activeFiltersQueryResult, ContactQueryStore.getSortQuery()

         if activeFilters.length > 0 && activeFilters[0]?.filter_id == "3.5" && activeFilters[0]?.value?.in
            if activeFiltersQueryResult.must?
               activeFiltersQueryResult.must[0]["giving.last_gift_date"].in = activeFilters[0]?.value?.in
            else if activeFiltersQueryResult.must_not?
               activeFiltersQueryResult.must_not[0]["giving.last_gift_date"].in = activeFilters[0]?.value?.in
            filterCriteria = _.extend({}, activeFiltersQueryResult, ContactQueryStore.getSortQuery())

         data = _.extend {}, @state.segment,
            type: "dynamic"
            name: name
            criteria: filterCriteria
            preferences: if @state.can_save_notifications then @getPreferences()
            filters: _.map FilterStore.getActiveFilters(), (filter) ->
               {id: filter.filter_id, value: filter.value, version: 1}

         switch @state.action
            when "update" then ListSource.update(data)
            when "save" then ListSource.create(data)

         EverTrue.track.set "search_action",
            type: @state.action
            params: FilterStore.getActiveFilterKeys()
            name: name

         @handleCancel()
         @props.onSave?()

      handleUpdateFrequency: (args...) ->
         if args.length == 3 then [id, type, value] = args
         else [type, value] = args

         prefs = @getPreferences()
         prefs[type + "_frequency"] = value
         @setState {temp_preferences: prefs}

      renderPreference: (label, key) ->
         prefs = @getPreferences()
         enabled_key = key + "_enabled"
         frequency_key = key + "_frequency"

         div null,
            Switch
               className: "pull-left save-segment--switch"
               label: label
               name: key
               checked: !!prefs?[enabled_key]
               onChange: (isChecked, name) =>
                  obj = prefs
                  obj[name + "_enabled"] = isChecked
                  @setState {temp_preferences: obj}

            SearchPreferenceSelect
               className: "pull-right"
               isNewSearch: true
               enabled: prefs?[enabled_key]
               frequency: prefs?[frequency_key]
               preference_type: key
               onChange: @handleUpdateFrequency

      render: ->
         name = @state.name || @state.segment?.name
         if !@state.name && @state.action == "save" && @state.segment?.type == "saved_search"
            name = "Copy of #{@state.segment.name}"

         div className: "save-segment",
            div className: "save-segment--header",
               h4 null, "Save Search Criteria"

            if @state.segment?.type == "saved_search"
               Nav className: "save-segment--options", active: @state.action, onSelect: @handleSelectAction,
                  NavItem activeWhen: "update", label: "Update Current Search"
                  NavItem activeWhen: "save", label: "Save as New Search"
            else hr()

            div className: "save-segment--wrapper",
               input
                  type: "text"
                  ref: "name"
                  placeholder: "Search name..."
                  value: name
                  onChange: @handleInputChange

               if @state.can_save_notifications
                  div null,
                     div className: "save-segment--container clearfix",
                        @renderPreference("Email Updates", "email")

                     div className: "save-segment--container clearfix",
                        @renderPreference("Mobile Push Updates", "push")
               else
                  div className: "save-segment--empty",
                     "Notifications are not available for this search."
                     HelpTip help_key: "notifications_unavailable"

            hr()

            div className: "save-segment--footer",
               Button type: "secondary", className: "save-segment--btn inline", onClick: @handleCancel, "Cancel"
               Button
                  className: "save-segment--btn inline"
                  onClick: @handleSaveSearch
                  disabled: !name
               , "Save Search Criteria"
