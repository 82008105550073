import EverTrue from "app";
import PropTypes from "prop-types";
import { useGate } from "components/is-gated";
import NavigationSubItem from "apps/layout/components/navigation-sub-item";

const NavigationReportingLink = ({ active_path = "" }) => {
  const [enabled, loaded] = useGate("unreleased_feature");

  if (!loaded || !enabled) return null;
  
  return (
    <NavigationSubItem
      icon="reporting"
      label="Reporting"
      isActive={!!active_path.match(new RegExp(`reporting.*`))}
      onClick={() => {
        EverTrue.track.set("reporting", { type: "navigate" });
        EverTrue.Navigator("/reporting", true);
      }}
      iconKit="gt2"
    />
  );
};

NavigationReportingLink.propTypes = {
  active_path: PropTypes.string,
};

export default NavigationReportingLink;
