import _ from "underscore";
import PropTypes from "prop-types";
import { ListboxSelect } from "@evertrue/et-components";
import EverTrue from "app";
import FilterInputComponent from "apps/query-builder/components/logic-group/filter-input-component";
import uiComponents from "apps/query-builder/utils/filter-ui-components";

const proptypes = {
  value: PropTypes.any,
  filter: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

const OperatorValue = ({ filter, value = {}, onChange, isDisabled = false }) => {
  if (!filter.operators) {
    return null;
  }

  const formatted_operators = filter.operators.map((operator) => {
    return { label: operator.label, value: operator.operator_query_key, ui_component: operator.ui_component };
  });

  return (
    <div className={isDisabled ? "dxo-filter-operator" : "filter-operator"}>
      {value.ui_component !== uiComponents.BooleanRadio && (
        <ListboxSelect
          disabled={isDisabled}
          label="filter operator"
          value={{ value: value && value.operator }}
          options={formatted_operators}
          onChange={({ value, ui_component }) => {
            EverTrue.track.set("filter_v2_action", {
              type: "operator_changed",
              filters_operator: value,
            });
            onChange(Object.assign({}, { value: undefined, operator: value, ui_component }));
          }}
        />
      )}
      {value && value.operator && (
        <div className="filter-operator--value">
          <FilterInputComponent
            filter={_.omit(filter, "label")}
            uiComponent={value.ui_component}
            value={value.value}
            operator={value.operator}
            isDisabled={isDisabled}
            onChange={(change) => {
              onChange(Object.assign({}, value, { value: change }));
            }}
          />
        </div>
      )}
    </div>
  );
};

OperatorValue.propTypes = proptypes;
export default OperatorValue;
