import { useRef } from "react";
import { PropTypes } from "base/react-libs";
import { CSSTransition } from "react-transition-group";

/**
 * DOCS: How to use and what each prop does:
 * https://reactcommunity.org/react-transition-group/css-transition
 * https://reactcommunity.org/react-transition-group/transition
 * There are three states:
 * APPEAR -- when the component is MOUNTED (ie when the page loads)
 * ENTER -- when the component is brought IN (like if you click the "View" button)
 * EXIT -- when the component is called OUT (like if you click the "Hide" button)
 */

const TransitionWrapper = ({
  start, // boolean which triggers the "enter" state; start of transition
  speed = 400, // This is how long the "active" status lasts. can be segmented like so: { appear: ##, enter: ##, exit: ## }. Providing one number applies the same number to all 3. Number in milliseconds
  classNames, // this tells the CSSTransition component which transitions to apply. read docs for more details
  className = "", // this is just in case you want to add custom styling to this component that is NOT lumped into the transitions
  enableAppearAnimations = false, // runs the enter state on mount (appear)
  enableEnterAnimations = true,
  enableExitAnimations = true,
  mountOnEnter = false,
  unmountOnExit = false,
  onEnter = () => {},
  onEntering = () => {},
  onEntered = () => {},
  onExit = () => {},
  onExiting = () => {},
  onExited = () => {},
  children,
}) => {
  const nodeRef = useRef(null);
  return (
    <CSSTransition
      in={start}
      timeout={speed}
      classNames={classNames}
      className={className}
      appear={enableAppearAnimations}
      enter={enableEnterAnimations}
      exit={enableExitAnimations}
      mountOnEnter={mountOnEnter}
      unmountOnExit={unmountOnExit}
      onEnter={onEnter}
      onEntering={onEntering}
      onEntered={onEntered}
      onExit={onExit}
      onExiting={onExiting}
      onExited={onExited}
    >
      <div ref={nodeRef} className={className}>
        {children}
      </div>
    </CSSTransition>
  );
};

TransitionWrapper.propTypes = {
  start: PropTypes.bool.isRequired,
  speed: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  classNames: PropTypes.string.isRequired,
  className: PropTypes.string,
  enableAppearAnimations: PropTypes.bool,
  enableEnterAnimations: PropTypes.bool,
  enableExitAnimations: PropTypes.bool,
  mountOnEnter: PropTypes.bool,
  unmountOnExit: PropTypes.bool,
  onEnter: PropTypes.func,
  onEntering: PropTypes.func,
  onEntered: PropTypes.func,
  onExit: PropTypes.func,
  onExiting: PropTypes.func,
  onExited: PropTypes.func,
  children: PropTypes.node.isRequired,
};

export default TransitionWrapper;
