import _ from "underscore";
import FormField from "components/forms/form-field";
import PropTypes from "prop-types";
import classNames from "classnames";
import moment from "moment";

export const isDateInputValid = (date) => {
  return date !== "" && _.isNaN(date);
};

export const ProposalFormField = ({ className, required = false, ...rest }) => (
  <FormField
    inlineLabel={false}
    required={required}
    className={classNames("proposal-form--form-field", className)}
    {...rest}
  />
);
ProposalFormField.propTypes = {
  className: PropTypes.string,
  required: PropTypes.bool,
};

// This formats the proposal whether or not it already exists.
// If it is new, then it receives all the default values, otherwise it receives
// the values from the existing proposal.
export const parseProposal = (proposal) => {
  return {
    proposal_id: proposal && proposal.id ? proposal.id : undefined,
    active: proposal && !_.isUndefined(proposal.active) ? proposal.active : true,
    primary_contact: {},
    solicitors: proposal && proposal.solicitors ? proposal.solicitors : [],
    contacts: [],
    created_date: proposal && proposal.created_date ? proposal.created_date : "",
    title: proposal && proposal.title ? proposal.title : "",
    description: proposal && proposal.description ? proposal.description : "",
    status: proposal && proposal.status ? proposal.status : "",
    original_ask_amount: proposal && proposal.original_ask_amount >= 0 ? proposal.original_ask_amount : "",
    original_ask_date: proposal && proposal.original_ask_date ? proposal.original_ask_date : "",
    ask_amount: proposal && proposal.ask_amount >= 0 ? proposal.ask_amount : "",
    ask_date: proposal && proposal.ask_date ? proposal.ask_date : "",
    expected_amount: proposal && proposal.expected_amount >= 0 ? proposal.expected_amount : "",
    expected_date: proposal && proposal.expected_date ? proposal.expected_date : "",
    funded_amount: proposal && proposal.funded_amount >= 0 ? proposal.funded_amount : "",
    funded_date: proposal && proposal.funded_date ? proposal.funded_date : "",
    custom_fields: proposal && proposal.custom_fields ? proposal.custom_fields : [],
    proposal_type: proposal && proposal.proposal_type ? proposal.proposal_type : "",
    reported: proposal && proposal.reported ? proposal.reported : false,
    confidence_score: proposal && proposal.confidence_score ? proposal.confidence_score : undefined,
    proposal_designations: proposal && proposal.proposal_designations ? proposal.proposal_designations : [],
  };
};

export const getHumanReadableTimeFromNow = (date) => {
  const now = moment();
  let timeInStageReadable = "-";
  if (date) {
    const timeInStage = now.diff(moment(date), "seconds");
    timeInStageReadable = moment.duration(timeInStage, "seconds").humanize(false);
  }
  return timeInStageReadable;
};

export const validateProposal = (proposal, customFields, standardFieldsConfig = {}) => {
  const editedCustomFields = _.indexBy(proposal.custom_fields, "custom_field_property_id");
  const customFieldsInvalid = customFields
    .filter((custom_field) => custom_field.required)
    .some((field) => {
      const requiredField = editedCustomFields[field.id];
      return !requiredField || requiredField.value === "" || requiredField.value === null;
    });

  // Solicitors and title are always required
  standardFieldsConfig.solicitors = { ...standardFieldsConfig.solicitors, required: true };
  standardFieldsConfig.title = { ...standardFieldsConfig.title, required: true };

  // Contacts and primary contact are always false
  standardFieldsConfig.contacts = { ...standardFieldsConfig.contacts, required: false };
  standardFieldsConfig.primary_contact = { ...standardFieldsConfig.primary_contact, required: false };

  const standardFieldsInvalid = Object.entries(standardFieldsConfig)
    .filter(([field, config]) => typeof proposal[field] !== "boolean" && config.required)
    .some(([field]) => {
      if (field === "solicitor_title") {
        return proposal.solicitors.some((solicitor) => !solicitor.title);
      } else {
        return !proposal[field] || proposal[field].length < 1;
      }
    });

  const isInvalidProposalLength = () => {
    const { description, title } = proposal;
    let invalidTitleLength, invalidDescriptionLength;
    if (title) invalidTitleLength = title.length > standardFieldsConfig.title.max_length;
    if (description) invalidDescriptionLength = description.length > standardFieldsConfig.description.max_length;
    return invalidDescriptionLength || invalidTitleLength;
  };

  const hasInvalidDesignations = () => {
    const { proposal_designations } = proposal;
    if (standardFieldsConfig.require_pairs.designations) {
      return proposal_designations.some(({ value }) => !value);
    }
  };

  const hasInvalidPairs = Object.entries(standardFieldsConfig.require_pairs)
    .filter(([field, val]) => val === true && field !== "designations")
    .some(([field]) => {
      const amt = field === "original" ? `${field}_ask_amount` : `${field}_amount`;
      const date = field === "original" ? `${field}_ask_date` : `${field}_date`;
      return (proposal[amt] && !proposal[date]) || (proposal[date] && !proposal[amt]);
    });

  return (
    standardFieldsInvalid ||
    customFieldsInvalid ||
    hasInvalidPairs ||
    isInvalidProposalLength() ||
    hasInvalidDesignations()
  );
};
