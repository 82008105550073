import { useCallback } from "react";
import { connect, useFluxStore } from "@evertrue/et-flux";
import _ from "underscore";
import FeatureStore from "apps/layout/stores/feature-store";
import PropTypes from "prop-types";

const { bool, string, array, oneOfType, func } = PropTypes;

const mapStateToProps = ({ feature }) => {
  const loading = FeatureStore.getGatesLoading();
  const hasFeatureEnabled = _.isArray(feature)
    ? _.every(_.map(feature, FeatureStore.hasFeature))
    : FeatureStore.hasFeature(feature);

  let featuresEnabled = _.isArray(feature)
    ? feature.reduce((accum, key) => {
        accum[key] = FeatureStore.hasFeature(key);
        return accum;
      }, {})
    : {};

  return { hasFeatureEnabled, loading, featuresEnabled };
};

const GatedComp = ({ hasFeatureEnabled, children, inverse, loading, render, featuresEnabled }) => {
  if (loading) return null; // prevent flash of gated feature (FOGF)
  const show = inverse ? !hasFeatureEnabled : hasFeatureEnabled;

  const obj = { show, featuresEnabled };

  if (render) return render(obj);
  if (typeof children === "function") return children(obj);

  return show ? children : null;
};

const IsGated = connect(GatedComp, [FeatureStore], mapStateToProps);

export const WithGate = (WrappedComponent, feature) => (props) =>
  <IsGated feature={feature} render={(args) => <WrappedComponent {...args} {...props} />} />;

export const useGate = (feature) => {
  const mapState = useCallback(
    () => ({
      enabled: FeatureStore.hasFeature(feature),
      loaded: FeatureStore.hasFeaturesLoaded(),
    }),
    [feature]
  );
  const { enabled, loaded } = useFluxStore(FeatureStore, mapState);
  return [enabled, loaded];
};

IsGated.propTypes = {
  hasFeatureEnabled: bool,
  loading: bool,
  inverse: bool,
  feature: oneOfType([array, string]).isRequired,
  render: func,
};

IsGated.defaultProps = {
  hasFeatureEnabled: false,
  loading: false,
  inverse: false,
  feature: "",
  render: undefined,
};

GatedComp.propTypes = {
  hasFeatureEnabled: PropTypes.bool,
  children: PropTypes.any,
  inverse: PropTypes.bool,
  loading: PropTypes.bool,
  render: PropTypes.func,
  featuresEnabled: PropTypes.object,
};

export default IsGated;
