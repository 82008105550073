module.exports = do ->
   _ = require("underscore").default
   {createSource} = require("@evertrue/et-flux")
   config = require("config/env")

   PAGE_KEY = "mnvchxx6cghv"

   createSource "StatusSource",
      actions:
         isDown: (is_down) ->
            @require _.isBoolean(is_down), "is_down should be boolean"
         status: (status) ->
            @require _.isObject(status), "status should be object"
         fetchedComponents: (components) ->
            @require _.isArray(components), "components should be an array"

      redirect: (outage) ->
         isProduction = config.getEnv() == "production"
         if isProduction && outage && window.location.pathname != "/status"
            # For some reason the redirect on the login page doesn't always work
            if window.location.pathname == "/login"
               window.location = window.location.origin + "/status"
            else
               EverTrue.Navigator("/status", {trigger: true})

      api:
         check: ->
            sp = new StatusPage?.page({page: PAGE_KEY})
            sp?.components
               success: (data) =>
                  @actions.fetchedComponents(data.components)
                  outage = _.some data.components, (comp) ->
                     comp.id in ["qvk5p8s5bv6z"] && comp.status == "major_outage"
                  @actions.isDown(outage)
                  @redirect(outage)

         fetch: ->
            sp = new StatusPage?.page({page: PAGE_KEY})
            sp?.status
               success: (data) =>
                  if data.status
                     @actions.status(data.status)
