module.exports = do ->
   _ = require("underscore").default
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")


   createSource "SubscriptionPrefSource",
      actions:
         fetchedPreferences: (preferences) ->
            @require _.isArray(preferences), "preferences should be an array"
         updatedPreference: (preference) ->
            @require _.isObject(preference), "preference should be an object"
         deletedPreference: (key) ->
            @require _.isString(key), "key should be string"

      formatPreferences: (prefs) ->
         prefs.email_frequency = prefs.email_frequency.toLowerCase()
         prefs.push_frequency = prefs.push_frequency.toLowerCase()
         prefs

      api:
         fetch: ->
            Api.EMS.PREFS.get
               success: (resp) =>
                  @actions.fetchedPreferences(resp.preferences)

         update: (key, data) ->
            Api.EMS.PREFS.put
               urlExtend: "/#{key}"
               data: _.jsonStringify(@formatPreferences(data))
               success: (pref) =>
                  @actions.updatedPreference(pref[0])

         delete: (key) ->
            Api.EMS.PREFS.delete
               urlExtend: "/#{key}"
               disableAlerts: true
               success: =>
                  @actions.deletedPreference(key)
