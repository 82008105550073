module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   Decorator = require("clientDecorator")
   {createStore} = require("@evertrue/et-flux")
   AssignProspectSource = require("apps/major-gifts/sources/assign-prospect-source")
   AllUsersStore = require("apps/users/stores/all-users-store").default


   createStore "AssignProspectStore",
      getInitialState: ->
         is_saving: false
         remote_id_queue: []

      registerActions: ->
         @on AssignProspectSource.actions.savingToContact, @respondToSaving
         @on AssignProspectSource.actions.add, @respondToAdd
         @on AssignProspectSource.actions.change, @respondToChange
         @on AssignProspectSource.actions.remove, @respondToRemove
         @on AssignProspectSource.actions.updateForContact, @respondToContactUpdate
         @on AssignProspectSource.actions.changedRemoteIds, =>
            @setState {remote_id_queue: []}

      respondToSaving: (is_saving) ->
         @setState {is_saving: is_saving}

      respondToContactUpdate: (full_contact, basic_assignee_data) ->
         assignees = _.cloneData full_contact?.assignees

         new_assignee_data = _.map basic_assignee_data, (assignee_data) =>
            @getMergedAssigneeData(assignee_data.user_id, assignee_data)

         # Check if data from original assignees has changed or been deleted
         assignee_updates = _.compact _.map assignees, (original) =>
            changed_item = _.findWhere(new_assignee_data, id: original.id)
            @getUpdatedItem(original, changed_item)

         # Add new rows
         _.each new_assignee_data, (data) =>
            if !_.findWhere(assignees, {id: data.id}) && !_.isEmpty(_.omit(data, "id"))
               assignee_updates.push @getFormattedAssigneeObject(data)

         contact_updates = @getContactWrapper(full_contact, assignee_updates)
         AssignProspectSource.saveDataToContact(contact_updates, @getState("remote_id_queue"))

      # Bulk Add Contact Assignment
      respondToAdd: (user_id, contacts, assignee_data) ->
         new_assignee_data = @getMergedAssigneeData(user_id, assignee_data)

         # Add contact assignment object
         contact_updates = _.map contacts, (contact) =>
            contact_assignment_data = @getContactWrapper(contact, (_.cloneData(contact.assignees) || []))
            contact_assignment_data.assignees.push @getFormattedAssigneeObject(new_assignee_data)
            contact_assignment_data

         AssignProspectSource.saveDataToContact(contact_updates, @getState("remote_id_queue"))

      # Bulk / Individually change contacts assignment
      respondToChange: (user_id, contacts, assignee_data) ->
         # Update current assignment if exists
         contact_updates = @getAssigneeObjects(user_id, contacts)
         contact_updates = _.map contact_updates, (contact) =>
            contact.assignees = _.compact _.map contact.assignees, (original_assignee) =>
               if @getIsAssignedToUser(user_id, original_assignee)
                  updated_props = _.compact _.map original_assignee.properties, (prop) ->
                     if assignee_data[prop.name]
                        name: prop.name
                        value: assignee_data[prop.name]
                  _.extend({}, original_assignee, {properties: updated_props})
            contact

         AssignProspectSource.saveDataToContact(contact_updates, @getState("remote_id_queue"))

      # Remove all of user_ids assignments for contacts
      respondToRemove: (user_id, contacts) ->
         contact_updates = @getDeletedAssigneeObjects(user_id, contacts)
         AssignProspectSource.saveDataToContact(contact_updates)

      addToRemoteIDQueue: (user, affiliation) ->
         queue = _.cloneData(@getState("remote_id_queue"))
         queue.push({user: user, affiliation: affiliation})
         @setState {remote_id_queue: queue}

      getContactWrapper: (contact, assignees) ->
         id: contact.id
         updated_at: contact.updated_at
         assignees: assignees

      getFormattedAssigneeObject: (assignee_data) ->
         object_type: "assignee"
         properties: _.compact _.map _.omit(assignee_data, "id"), (value, type) ->
            {name: type, value: value} if !!value

      getAssigneeObjects: (user_id, contacts) ->
         _.map contacts, (contact) =>
            @getContactWrapper contact, (_.cloneData(contact.assignees) || [])

      getDeletedAssigneeObjects: (user_id, contacts) ->
         _.map contacts, (contact) =>
            @getContactWrapper contact,
               _.map _.cloneData(contact.assignees), (original_assignee) =>
                  if @getIsAssignedToUser(user_id, original_assignee)
                     @getUpdatedItem(original_assignee, {})
                  else original_assignee

      getIsAssignedToUser: (user_id, assignee_object) ->
         user = AllUsersStore.getUser(user_id)
         affil = Decorator.User.getAffiliationFor(user, EverTrue.store.org.get("id"))
         _.find assignee_object.properties, (prop) ->
            (prop.name == "name" && user.name == prop.value) ||
               (prop.name == "remote_user_id" && prop.value == affil.remote_user_id) ||
               (prop.name == "user_id" && prop.value == user.id)

      getMergedAssigneeData: (user_id, assignee_data) ->
         user = AllUsersStore.getUser(user_id)
         affil = Decorator.User.getAffiliationFor(user, EverTrue.store.org.get("id"))

         if user_id && !user.removed
            if !(affil?.remote_user_id || assignee_data.remote_user_id)
               @addToRemoteIDQueue(user, affil)

         _.extend {
            name: user.name
            user_id: user.id
            remote_user_id: affil?.remote_user_id
         }, assignee_data

      getUpdatedItem: (orig_item, changed_item) ->
         original_clone = _.cloneData(orig_item)

         # Delete / Update object
         _.each orig_item.properties, (prop) ->
            if !changed_item?[prop.name]
               prop.deleted = true
            else if changed_item?[prop.name] != prop.value
               prop.value = changed_item[prop.name]

         # Add new objects
         _.each changed_item, (value, key) ->
            if !_.findWhere(orig_item.properties, {name: key}) and !!value and key != "id"
               orig_item.properties?.push {name: key, value: value}

         if !_.isEqual(original_clone, orig_item) then orig_item

      api:
         getIsSaving: ->
            @getState("is_saving")
