import { useEffect } from "react";
import Api from "entities/helpers/api";
import EverTrue from "app";
import { Loading } from "@evertrue/et-components";
import { useGlobalOutreachContext } from "./email-modal-context";

const OauthRedirectController = () => {
  const { setEmailDrawerIsOpen } = useGlobalOutreachContext();

  useEffect(() => {
    const url = new URL(window.location.href);
    const code = url.searchParams.get("code");
    const { prevLocation } = JSON.parse(localStorage.getItem("oauthData")) || {};
    localStorage.removeItem("oauthData");

    const redirectBack = () => {
      if (prevLocation && prevLocation.includes("/contact")) {
        setEmailDrawerIsOpen(true);
      }
      EverTrue.Navigator(prevLocation || "/contact", { trigger: true, replace: true });
    };

    if (code) {
      Api.OUTREACH.LINK.post({
        data: JSON.stringify({
          auth: { code },
        }),
      })
        .then(() => {
          EverTrue.Alert.success(`Your account has been connected successfully!`);
          redirectBack();
        })

        .catch(() => {
          EverTrue.Alert.error(`There was a problem linking your account. Please try again.`);
          redirectBack();
        });
    }
  }, [setEmailDrawerIsOpen]);

  return <Loading />;
};

export default OauthRedirectController;
