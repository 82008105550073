import { Checkbox } from "@evertrue/et-components";
import PropTypes from "prop-types";

const HouseholdMemberCheckboxes = ({ householdMembers = [], onChange = () => {}, disabled = false }) => {
  return (
    <>
      {householdMembers.map((member) => {
        return (
          <Checkbox
            className="interaction-form--household-checkbox"
            label="Add household member"
            key={member.id}
            onChange={(checked) => {
              onChange(checked, member);
            }}
            disabled={disabled}
          >
            Add {member.contact_attributes.name_full} (Household Member)
          </Checkbox>
        );
      })}
    </>
  );
};

HouseholdMemberCheckboxes.propTypes = {
  householdMembers: PropTypes.array,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default HouseholdMemberCheckboxes;
