import PropTypes from "prop-types";
import { deactivateJourneyTask } from "apps/journeys/utils/api-requests/journey-details";
import { Modal, ModalHeader, ModalBody, Button } from "@evertrue/et-components";

const DeleteStepModal = ({ isOpen, onClose, journeyTask, optionalStateManagementFunction = () => {} }) => {
  const deleteJourneyTask = async () => {
    await deactivateJourneyTask(journeyTask);
    optionalStateManagementFunction("deleteStep", { journeyTask });
    onClose();
  };
  return (
    <Modal isOpen={isOpen} closeModal={onClose}>
      <ModalHeader title="Delete Step" closeModal={onClose} />
      <ModalBody scrollable={false}>
        <div>
          <div className="delete-modal-header">Are you sure you want to delete this step ?</div>
          <div className="delete-modal-button--group">
            <Button type="simple" onClick={onClose}>
              Cancel
            </Button>
            <div className="delete-modal-button--delete">
              <Button type="destroy" onClick={deleteJourneyTask}>
                Delete
              </Button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

DeleteStepModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  journeyTask: PropTypes.object,
  optionalStateManagementFunction: PropTypes.func,
};

export default DeleteStepModal;
