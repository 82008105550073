module.exports = do ->
   _ = require("underscore").default
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")
   Decorator = require("clientDecorator")

   createSource "ProfileRelationshipsSource",
      actions:
         fetchedContactRelationships: true
         fetchedSearchRelationships: true

      api:
         fetchContactRelationships: (contact_id, remote_ids = []) ->
            remote_ids = _.uniq remote_ids.concat(_.map(remote_ids, _.trimRemoteId))
            if contact_id and _.notEmpty(remote_ids)
               Api.CONTACTS.SEARCH.post
                  data: _.jsonStringify must: ["identities.value.untouched": in: remote_ids]
                  success: (resp) =>
                     resp.items = _.map(resp.items, Decorator.Contacts.parse)
                     @actions.fetchedContactRelationships(contact_id, resp)

         fetchSearchRelationships: (contact_id, remote_id) ->
            remote_ids = _.uniq [remote_id, _.trimRemoteId(remote_id)]
            if contact_id and remote_id
               Api.CONTACTS.SEARCH.post
                  data: _.jsonStringify
                     must: ["relationships.remote_id.untouched": in: remote_ids]
                  success: (resp) =>
                     resp.items = _.map(resp.items, Decorator.Contacts.parse)
                     @actions.fetchedSearchRelationships(contact_id, resp)
