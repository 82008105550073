import { PropTypes } from "base/react-libs";
import { ThreeDotActionsMenu } from "@evertrue/et-components";
import JourneyTaskModal from "../../../../modals/journey-task-modal";
import DeleteStepModal from "../../../../modals/delete-step-modal";

const StepRowActionButton = ({ journey, existingJourneyTask = {}, optionalStateManagementFunction = () => {} }) => {
  const editStep = {
    id: 1,
    mountOnClick: ({ is_open, close }) => (
      <JourneyTaskModal
        isOpen={is_open}
        onClose={close}
        journey={journey}
        existingJourneyTask={existingJourneyTask}
        optionalStateManagementFunction={optionalStateManagementFunction}
      />
    ),
    icon: "edit",
    label: "Edit Step",
  };
  const deleteStep = {
    id: 2,
    mountOnClick: ({ is_open, close }) => (
      <DeleteStepModal
        isOpen={is_open}
        onClose={close}
        journeyTask={existingJourneyTask}
        optionalStateManagementFunction={optionalStateManagementFunction}
      />
    ),
    icon: "trash",
    label: "Delete Step",
  };

  return (
    <>
      <ThreeDotActionsMenu options={[editStep, deleteStep]} />
    </>
  );
};

StepRowActionButton.propTypes = {
  journey: PropTypes.object,
  existingJourneyTask: PropTypes.object,
  optionalStateManagementFunction: PropTypes.func,
};

export default StepRowActionButton;
