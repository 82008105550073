module.exports = do ->
   _ = require("underscore").default
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")

   createSource "FullContactSource",
      actions:
         loading: (bool) ->
            @require _.isBoolean(bool), "bool should be true or false"
         fetchedFullContact: (contact) ->
            @require _.isObject(contact), "contact should be an object"

      api:
         getFullContact: (id) ->
            @actions.loading(true)
            Api.CONTACTS.CONTACT.get
               urlArgs: {id: id}
               success: (contact) =>
                  @actions.fetchedFullContact(contact)
                  @actions.loading(false)
               error: =>
                  @actions.loading(false)
