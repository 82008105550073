import { useState, useEffect } from "react";
import Api from "entities/helpers/api";

const useSearchList = (limit) => {
  const [offset, setOffset] = useState(0);
  const [searches, setSearches] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadSearches = async () => {
      setLoading(true);

      try {
        const resp = await Api.HUB.ALL_SEARCHES.get({
          params: {
            offset,
            limit,
          },
        });

        offset === 0 ? setSearches(resp.items) : setSearches((prev) => [...prev, ...resp.items]);
        setTotal(resp.total);
        setLoading(false);
      } catch {
        setLoading(false);
      }
    };

    loadSearches();
  }, [offset, limit]);

  return { offset, setOffset, total, searches, loading };
};

export default useSearchList;
