module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {g} = ReactLibs.DOMFactories
   Bar = require("components/charts/bar")

   MIN_HEIGHT = 2


   createComponent "BarStack",
      propTypes:
         data: ReactLibs.PropTypes.array
         xScale: ReactLibs.PropTypes.any
         yScale: ReactLibs.PropTypes.any
         grid_height: ReactLibs.PropTypes.number
         padding: ReactLibs.PropTypes.number
         horizontal: ReactLibs.PropTypes.bool
         onMouseOver: ReactLibs.PropTypes.func
         onMouseOut: ReactLibs.PropTypes.func
         onBarClick: ReactLibs.PropTypes.func

      getDefaultProps: ->
         padding: 5

      handleGetCoords: (item) ->
         if @props.xScale && @props.yScale
            [@props.xScale?(item.x), @props.yScale?(item.y)]
         else [0, 0]

      render: ->
         group = @props.data
         sorted_group = _.sortBy(group, "series")
         series_sum = 0

         g className: "bar-stack",
            _.map sorted_group, (datum, i) =>
               [x, y] = @handleGetCoords(datum)

               if @props.xScale && @props.yScale
                  if @props.horizontal
                     bar_width = Math.max(x, MIN_HEIGHT)
                     bar_height = @props.yScale.rangeBand() - @props.padding
                     x_prop = series_sum
                     y_prop = @props.grid_height - y - (bar_height / MIN_HEIGHT)
                     series_sum += x
                  else
                     series_sum += y
                     bar_width = @props.xScale.rangeBand?() - @props.padding
                     bar_height = Math.max(y, MIN_HEIGHT)
                     x_prop = x - (bar_width / MIN_HEIGHT)
                     y_prop = Math.min((@props.grid_height - y - (series_sum - y)), @props.grid_height - MIN_HEIGHT)

                  Bar
                     className: "area-chart--points"
                     width: bar_width
                     height: bar_height
                     x: x_prop
                     y: y_prop
                     key: _.randomKey()
                     style: datum.style
                     onMouseOver: (value, label, width, position, e) =>
                        position = _.extend {}, $(e.target).offset(), width: width
                        if @props.horizontal then position.left += (width / 2)
                        else position.left += width
                        @props.onMouseOver(datum, position)
                     onMouseOut: @props.onMouseOut
                     onClick: => @props.onBarClick?(datum)

