const gql = String.raw;

export const queryDynamicLists = gql`
query(
  $entityId: BigInt!
) {
  listsByUserId(
    entityId: $entityId
  ) {
    id
    oid
    search_id
    name
    list_permissions (entityId: $entityId) {
      id
      list_id
      entity_id
      entity_type
      permission
    }
  }
}
`;

export const queryBookmarkedSearches = gql`
query(
  $entityId: BigInt!
) {
  searchesByUserId(
    entityId: $entityId
  ) {
    id
    oid
    name
    search_permissions (entityId: $entityId) {
      search_id
      entity_id
      entity_type
      permission
    }
  }
}
`;