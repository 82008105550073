module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {div, span, strong} = ReactLibs.DOMFactories
   EverTrue = require("app")
   FilterDrawer = require("components/controls/filter-drawer")
   EventsFilterPanel = require("apps/events/components/events-filter-panel")
   EventsStore = require("apps/events/stores/events-store")
   EventsSource = require("apps/events/sources/events-source")
   FeatureStore = require("apps/layout/stores/feature-store").default
   {createFactory} = require("base/new-utils")
   ActionMenu = createFactory(require("components/controls/action-menu"))
   ActionMenuItem = createFactory(require("components/controls/action-menu-item").default)
   FiltersLabel = require("components/formatting/filters-label")

   MAX = 25

   FILTER_LABELS =
      date: "Date"
      source: "Event Source"
      fb_page: "Facebook Page"

   createComponent "EventsHeader",
      registerStores: ->
         @on EventsStore, ->
            current_filters: EventsStore.getCurrentFilters()
            temp_filters: EventsStore.getTempFilters()
            event_count: EventsStore.getEventCount()
            page: EventsStore.getPage()

         @on FeatureStore, ->
            hasEventbrite: FeatureStore.hasFeature("events_eventbrite")

      renderTitle: ->
         applied_filters =
            _.compact _.map FILTER_LABELS, (label, key) =>
               if @state.current_filters?[key] then label

         FiltersLabel
            labels: applied_filters
            defaultValue: "All Events"
            onClick: @handleClickFilter

      handleClickFilter: ->
         this.drawer?.handleToggle?()

      renderHeader: ->
         {page, event_count, hasEventbrite} = @state
         user = EverTrue.store.user
         shouldAccessEventbrite =
            user.isSuperUser() or user.isOwner()

         div className: "events-header",
            span null,
               span className: "events-header--text", @renderTitle()

               div null,
                  if page > 1
                     span null,
                        strong(null, (page - 1) * MAX + 1)
                        " - "
                        strong(null, Math.min(page * MAX, event_count))
                        " of "

                  strong(null, event_count)
                  span null, " Events"

            if hasEventbrite and shouldAccessEventbrite
               ActionMenu
                  className: "events-header--actions inline"
                  ActionMenuItem
                     icon: "settings"
                     href: "/events/eventbrite"
                     title: "Manage Eventbrite Integration"
                     "Manage Eventbrite Integration"

      handleResetFilters: ->
         EventsSource.resetFilters()

      handleApplyFilters: ->
         EverTrue.track.set "event_action",
            type: "filter"
            data_type: "events"
            params: _.keys(@state.temp_filters)
         EventsSource.setCurrentFilters(@state.temp_filters)

      handleCancelFilters: ->
         EventsSource.setTempFilters(@state.current_filters)

      setDrawerRef: (element) ->
         this.drawer = element

      render: ->
         FilterDrawer
            ref: @setDrawerRef
            canApply: true
            onReset: if not _.isEmpty @state.temp_filters then @handleResetFilters
            header: @renderHeader()
            onApply: @handleApplyFilters
            onCancel: @handleCancelFilters
            EventsFilterPanel
               temp_filters: @state.temp_filters
               onChange: (temp_filters) ->
                  EventsSource.setTempFilters(temp_filters)
