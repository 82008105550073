import Api from "entities/helpers/api";
import EverTrue from "app";
import { UPDATE_PROP } from "apps/dynamic-lists/utils/dynamic-list-enums";

export const updateList = async (list_id, prop, val) => {
  try {
    await Api.HUB.LIST.patch({
      urlArgs: { id: list_id },
      data: JSON.stringify({
        [prop]: val,
      }),
    });
    EverTrue.track.set("dynamic_lists_action", { type: "modify_dynamic_list" });
    if (prop === UPDATE_PROP.DSL_CRITERIA) {
      EverTrue.Alert.success("Updating List. This may take a few minutes.");
      EverTrue.Navigator("dynamic-lists");
    } else {
      EverTrue.Alert.success("Your list has been updated successfully.");
    }
  } catch {
    EverTrue.Alert.error("There was a problem updating your list, please try again later");
  }
};

export const deleteDynamicList = async (list_id, setSubmitting) => {
  try {
    setSubmitting(true);
    await Api.HUB.LIST.delete({
      urlArgs: { id: list_id },
    });
    EverTrue.track.set("dynamic_lists_action", { type: "delete_dynamic_list" });
    setSubmitting(false);
    EverTrue.Alert.success("Dynamic List Deleted Successfully");
    EverTrue.Navigator("dynamic-lists");
  } catch {
    setSubmitting(false);
    EverTrue.Alert.error("There was a problem deleting your list, please try again later");
  }
};

export const updateDynamicListAlerts = (list_id, prefs) => {
  Api.EMS.PREFS_BY_KEY.put({
    urlArgs: { key: `list_v2:${list_id}:` },
    data: JSON.stringify(prefs),
  })
    .then(() => {
      if (prefs.email_enabled) {
        Api.HUB.ALERT.post({
          urlArgs: { id: list_id },
        });
      } else {
        Api.HUB.ALERT.delete({
          urlArgs: { id: list_id },
        });
      }
    })
    .then(() => {
      EverTrue.Alert.success("Your alert preferences have been updated.");
    })
    .catch(() => {
      EverTrue.Alert.error("There was a problem updating your alert preferences.");
    });
};
