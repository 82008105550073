import { PropTypes } from "prop-types";
import { Modal, ModalHeader, ModalBody } from "@evertrue/et-components";
import BookmarkOrgSharing from "apps/search/components/bookmark-org-sharing";
import { BOOKMARK_PERMISSIONS } from "apps/search/utils/search-helper";

const BookMarkSharingModal = ({ isOpen, onClose, id }) => {
  return (
    <Modal isOpen={isOpen} closeModal={onClose} size="small">
      <ModalHeader title="Share Bookmark" closeModal={onClose} />
      <ModalBody>
        <div className="bookmark-sharing-modal--heading">Shareable Link</div>
        <BookmarkOrgSharing id={id} orgPermissions={BOOKMARK_PERMISSIONS.VIEW} disabled={true} />
      </ModalBody>
    </Modal>
  );
};

BookMarkSharingModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default BookMarkSharingModal;
