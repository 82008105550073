module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   ReactDOM = require("react-dom")
   clickOutside = require("clickOutside")


   OutsideClickMixin =
      componentDidMount: ->
         @is_mounted = true
         _.defer =>
            if @is_mounted
               $(ReactDOM.findDOMNode(@)).bind "clickoutside", @handleOutsideClick

      componentWillUnmount: ->
         @is_mounted = false
         $(ReactDOM.findDOMNode(@)).unbind "clickoutside", @handleOutsideClick

      handleInsideClick: (evnt) ->
         evnt.stopPropagation()
