module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   PoolSolicitorsStore = require("apps/volunteers/stores/pool-solicitors-store")
   PoolSolicitorsSource = require("apps/volunteers/sources/pool-solicitors-source").default
   {createFactory} = require("base/new-utils")
   AdvancedCombobox = createFactory(require("@evertrue/et-components").AdvancedCombobox)
   ContactCard = createFactory(require("apps/contact/components/contact-card/contact-card"))

   createComponent "SolicitorSelector",
      propTypes:
         poolId: ReactLibs.PropTypes.number
         selected: ReactLibs.PropTypes.array
         onAssign: ReactLibs.PropTypes.func

      getInitialState: ->
         solicitors: undefined
         loading: false

      registerStores: ->
         @on PoolSolicitorsStore, "poolId", (props) ->
            selected_solicitor: PoolSolicitorsStore.getSelected(props.poolId)
            solicitor_total: PoolSolicitorsStore.getForPool(props.poolId)?.total || 0

      componentDidMount: ->
         @is_mounted = true
         if @props.poolId
            @fetchSolicitors(@props.poolId)

      # When solicitors are added or removed, update results
      componentDidUpdate: (prevProps, prevState) ->
         if prevState.solicitor_total != @state.solicitor_total && @state.solicitor_total
            @fetchSolicitors(@props.poolId)

      componentWillUnmount: (nextProps, nextState) ->
         @is_mounted = false

      fetchSolicitors: (pool_id, name) ->

         if _.isEmpty(@state.solicitors) && @is_mounted
            @setState({loading: true})

         PoolSolicitorsSource.promise.fetchByName(pool_id, name).then (solicitors) =>
            if @is_mounted
               @setState({solicitors: solicitors, loading: false})

      handleSearch: (val) ->
         @fetchSolicitors(@props.poolId, val)

      render: ->
         is_disabled = !@props.selected?.length
         text = "Assign "
         if !is_disabled then text += "#{@props.selected.length}"

         selected = @state.selected_solicitor
         solicitor = _.extend {}, selected, {value: selected?.contact_id}

         solicitor_options = _.map @state.solicitors, (contact) ->
            {contact: contact, value: contact.id}

         if selected
            options = [
               {items: [solicitor]}
               {items: _.filter(solicitor_options, (item) -> item.id != selected?.id)}
            ]
         else options = solicitor_options

         AdvancedCombobox
            className: classNames("pool-prospects--assign", "is-disabled": is_disabled)
            options: options
            searchable: true
            grouped: !!@state.selected_solicitor
            disabled: !@props.selected.length
            placeholder: "#{text} to..."
            loading: @state.loading
            formatItem: (item = {}, isSelected, isHovered) ->
               ContactCard
                  key: item.contact_id
                  contact: item.contact
                  showNameLink: false
            onChange: @props.onAssign
            onSearch: @handleSearch
            title: "Solicitor selector"
