module.exports = do ->
   React = require("react")
   PropTypes = require("prop-types")
   numeral = require("numeral")
   moment = require("moment")
   Decorator = require("clientDecorator")
   {createComponent} = require("@evertrue/et-flux")
   {div, span} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   PoolSolicitorCard = createFactory(require("apps/volunteers/components/pool-solicitor-card").default)
   Icon = createFactory (require("components/elements/icon").default)
   ProfileCardSubtitle = createFactory require("apps/profile/components/profile-card-subtitle").default
   Datalist = createFactory require("components/elements/datalist")

   _formatGift = (data) -> numeral(data?.value).format("$0,0")
   _formatDate = (data) ->
      if data?.value then moment(new Date(data.value)).format("MMM, DD YYYY") else ""


   createComponent "SolicitorPanel",
      propTypes:
         contact: PropTypes.object
         onClose: PropTypes.func
         poolId: PropTypes.number

      render: ->
         contact = @props.contact || {}
         giving = contact.giving || {}

         div className: "solicitor-panel",
            div className: "solicitor-panel--header",
               PoolSolicitorCard
                  className: "solicitor-panel--contact-card"
                  contact: contact
                  poolId: @props.poolId

               Icon
                  icon: "close"
                  className: "solicitor-panel--close"
                  onClick: => @props.onClose()

            div className: "solicitor-panel--body",
               ProfileCardSubtitle label: "Giving Summary"

               Datalist label: "Last Gift",
                  div className: "solicitor-panel--gift", _formatGift(giving.last_gift_amount)
                  span className: "text-label", _formatDate(giving.last_gift_date)

               Datalist label: "Largest Gift",
                  div className: "solicitor-panel--gift", _formatGift(giving.largest_gift_amount)
                  span className: "text-label", _formatDate(giving.largest_gift_date)

               Datalist label: "Lifetime Giving",
                  div className: "solicitor-panel--gift", _formatGift(giving.lifetime_amount)
