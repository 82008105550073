export const proposalSolicitor = (solicitors, contactId) => {
  if (!solicitors || !contactId) return {};
  const solicitor = solicitors.find((solicitor) => solicitor.contact_id === contactId);
  return solicitor ? solicitor : {};
};

export const defaultDateSortOption = { label: "Created Date", value: "created_date", isDate: true };

export const proposalSortOptions = [
  { label: "Ask Date", value: "ask_date", isDate: true },
  defaultDateSortOption,
  { label: "Expected Date", value: "expected_date", isDate: true },
  { label: "Funded Date", value: "funded_date", isDate: true },
  { label: "Time in Stage", value: "status_start_date", isDate: false },
];

// Date column header and values are dynamic if a date is selected to sort on
const sortDate = (sortBy) => proposalSortOptions.find((option) => option.isDate && option.value === sortBy);

export const proposalTableDateHeader = (sortBy) => {
  const dateColumn = sortDate(sortBy);
  return dateColumn ? dateColumn.label : defaultDateSortOption.label;
};

export const proposalTableDateValue = (sortBy, data) => {
  const dateColumn = sortDate(sortBy);
  return dateColumn ? data[dateColumn.value] : data[defaultDateSortOption.value];
};

export const askAmounts = [
  {
    key: "ORIGINAL",
    date: "original_ask_date",
    amount: "original_ask_amount",
    label: "Original",
  },
  {
    key: "ASK",
    date: "ask_date",
    amount: "ask_amount",
    label: "Ask",
  },
  {
    key: "EXPECTED",
    date: "expected_date",
    amount: "expected_amount",
    label: "Expected",
  },
  {
    key: "FUNDED",
    date: "funded_date",
    amount: "funded_amount",
    label: "Funded",
  },
];

export const INTERACTION_CHARACTER_LIMIT = Object.freeze({
  TEXT: 4294967294,
  DXO_TEXT: 4294967294,
  SUMMARY: 65534,
});

export const PROPOSAL_CHARACTER_LIMIT = Object.freeze({
  DESCRIPTION: 65534,
  TITLE: 190,
});
