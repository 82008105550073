module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")
   ErrorLogger = require("entities/helpers/error-log-helper")
   LIMIT = 25

   createSource "EventsSource",
      actions:
         loading: (bool) ->
            @require _.isBoolean(bool), "bool should be true or false"
         fetchedEvents: (events) ->
            @require _.isObject(events), "events should be an object"
         fetchedMatchedEngagersCounts: (counts) ->
            @require _.isObject(counts), "event ocounts should be an object"
         newPage: (page) ->
            @require _.isNumber(page), "page should be a number"
         setCurrentFilters: (filters) ->
            @require _.isObject(filters), "filters should be an object"
         setTempFilters: (filters) ->
            @require _.isObject(filters), "filters should be an object"
         newQuickSearch: true
         newSort: (prop, reverse) ->
            @require _.isString(prop), "prop should be a string"
            @require _.isBoolean(reverse), "reverse should be true or false"
         resetFilters: true

      api:
         fetchEvents: (query = {}, page = 1) ->
            @actions.loading(true)
            Api.EVENTS.EVENTS.post
               data: _.jsonStringify(query)
               params: limit: LIMIT, offset: LIMIT * (page - 1)
               success: (events) =>
                  @actions.fetchedEvents(events)
               error: (xhr) =>
                  ErrorLogger.captureRequest("Error loading events data", xhr)
                  @actions.loading(false)

         fetchEventEngagersCount: (event_ids = []) ->
            requests =
               _.map event_ids, (event_id) ->
                  promise = $.Deferred()
                  contact_query = has_child: [
                     type: "contact_event_engagement"
                     query: must: [{"event.id": match: event_id}, {"contact_id": exists: true}]
                  ]
                  Api.CONTACTS.SEARCH.post
                     data: _.jsonStringify(contact_query)
                     params: offset: 0, limit: 0
                     success: ({total}) -> promise.resolve({event_id, total})
                     error: (xhr, status, error) -> promise.fail(error)
                  promise.promise()
            $.when(requests...).then (resp...) =>
               counts = _.mapObject(_.indexBy(resp, "event_id"), ({total}) -> total)
               @actions.fetchedMatchedEngagersCounts(counts)

         setPage: (page) ->
            @actions.newPage(page)

         setCurrentFilters: (filters) ->
            @actions.setCurrentFilters(filters)

         setTempFilters: (filters) ->
            @actions.setTempFilters(filters)

         setQuickSearch: (query) ->
            @actions.newQuickSearch(query)

         newSort: (prop, reverse) ->
            @actions.newSort(prop, reverse)

         resetFilters: ->
            @actions.resetFilters()
