module.exports = do ->
   _ = require("underscore").default
   Decorator = require("clientDecorator")
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")
   ExportFieldStore = require("apps/export/stores/export-field-store")


   createSource "FacebookDetailSource",
      actions:
         loading: true
         fetchedPost: true
         fetchPostContacts: true
         searchedPostContacts: true
         setSort: true
         setPage: true
         changeView: true
         startPostExport: true
         exportStarted: true
         fetchedPostComments: true

      api:
         fetchPost: (post_id) ->
            Api.SEARCH.SOCIAL_CONTENT.post
               data: _.jsonStringify must: [{remote_id: match: post_id}]
               params: {limit: 1}
               success: (resp) =>
                  post = _.first(resp?.items) || {}
                  _.extend post, {id: post_id}
                  @actions.fetchedPost(post)

         fetchPostContacts: (post_id) ->
            if post_id
               @actions.loading(true)
               @actions.fetchPostContacts(post_id)

         searchPostContacts: (query, options) ->
            @actions.loading(true)

            Api.CONTACTS.SEARCH.post
               data: _.jsonStringify(query)
               params: options
               success: (resp={}) =>
                  @actions.loading(false)
                  resp.items = _.map resp.items, (item) ->
                     Decorator.Contacts.parse(item)
                  @actions.searchedPostContacts(resp)

         sort: (sort_prop, sort_reverse) ->
            @actions.setSort(sort_prop, sort_reverse)

         paginate: (page) ->
            @actions.setPage(page)

         changeView: (type, should_update) ->
            @actions.changeView(type, should_update)

         export: (property_ids) ->
            @actions.startPostExport(property_ids)

         exportContacts: (property_ids, data) ->
            exportParams = ExportFieldStore.getExportParams(property_ids);

            Api.CONTACTS.EXPORTS.post
               params: exportParams
               data: _.jsonStringify(data)
               disableAlerts: true
               success: =>
                  @actions.exportStarted()

         getPostComments: (post_id, contact_ids) ->
            Api.SEARCH.SOCIAL.post
               data: _.jsonStringify must: [
                  {"engagement.type": in: ["comment"]}
                  {"content.compound_id": {match: post_id}}
                  {contact_id: {in: contact_ids}}
               ]
               success: (resp) =>
                  @actions.fetchedPostComments(resp)

