module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   EverTrue = require("app")
   PropTypes = require("prop-types")
   {createComponent} = require("@evertrue/et-flux")
   MyTripsStore = require("apps/my-trips/stores/my-trips-store")
   MyTripsSource = require("apps/my-trips/sources/my-trips-source")
   TripContactsSource = require("apps/my-trips/sources/trip-contacts-source")
   MembershipSource = require("apps/profile/sources/membership-source")
   MembershipStore = require("apps/profile/stores/membership-store")
   WindowSource = require("apps/layout/sources/window-source")
   {div, strong} = ReactLibs.DOMFactories
   TripForm = require("apps/my-trips/components/trip-form")
   SelectorMenu = require("components/controls/selector-menu")
   LayoutSource = require("apps/layout/sources/layout-source")
   {createFactory} = require("base/new-utils")
   LinkButton = createFactory(require("@evertrue/et-components").LinkButton)
   Button = createFactory(require("@evertrue/et-components").Button)
   Icon = createFactory (require("components/elements/icon").default)


   createComponent "TripsSelectorController",
      propTypes:
         contactIds: PropTypes.any
         totalResultsCount: PropTypes.number,
         filterParams: PropTypes.object,
         membership: PropTypes.any
         allSelected: PropTypes.bool
         closePopover: PropTypes.func
         isFiltersV2: PropTypes.bool

      getDefaultProps: ->
         closePopover: _.noop
         isFiltersV2: false

      getInitialState: ->
         new_trip: undefined

      registerStores: ->
         @on MyTripsStore, ->
            trips: _.sortBy MyTripsStore.getTrips(), "name"
            loading: MyTripsStore.getLoading()
            trips_by_date: MyTripsStore.getAllTripsByDate()

         @on MembershipStore, ->
            trip_membership: _.pluck(MembershipStore.getTripMembership(@props.membership), "id")

      componentDidMount: ->
         user = EverTrue.store.user.get("id")
         MyTripsSource.setUser(user)
         MyTripsSource.fetchTrips()

         if @props.membership && _.isEmpty(@state.trip_membership)
            MembershipSource.fetchTripsForContact(@props.membership)

      handleCreate: (text) ->
         @props.requestHide?()
         @props.closePopover()
         LayoutSource.launchModal TripForm
            trip: {name: text || ""}
            contactIds: @props.contactIds
            filterParams: @props.filterParams
            isFiltersV2: @props.isFiltersV2

      handleSelect: (trip) ->
         id = trip.value
         options =
            active_contact_id: @props.membership
            preventTripEvents: true
            preventSearchEvents: !!@props.membership

         if !_.isEmpty(@props.contactIds)
            if _.contains(@state.trip_membership, id)
               TripContactsSource.removeContact(trip.value, @props.contactIds, options)
            else
               TripContactsSource.addContacts(trip.value, @props.contactIds, options)
         # if filterParams are passed in (like in portfolio & performane) then
         # use the query to bulk add the contacts
         else if !_.isEmpty(@props.filterParams)
            TripContactsSource.addContactsFromQuery(trip.value, @props.filterParams, options, @props.isFiltersV2)

         WindowSource.outsideClick()
         @props.requestHide?()
         @props.closePopover()

      render: ->
         {upcoming_trips, past_trips} = @state.trips_by_date
         div className: "trip-selector",
            if @props.isFiltersV2 && @props.allSelected && @props.totalResultsCount > 50
               div className: "msg-container",
                  Icon className: "msg-icon", icon: "my-trips"
                  strong className:"msg",
                     "Please Select 1 or more constituent first"
                  Button onClick: (=> @props.closePopover()),
                     "OK"
            else if @props.allSelected && @props.totalResultsCount > 50
               div className: "trip-selector--empty",
                  "Unable to add more than 50 Constitents to a trip at one time. Please undo your selection."
            else if _.isEmpty(@state.trips)
               div className: "trip-selector--empty",
                  "You don't have any trips"
                  div null,
                     LinkButton title: "Add a new trip", onClick: (=> @handleCreate()),
                        "Add to new trip"
            else
               SelectorMenu
                  label: "trip"
                  options: [{
                     value: "upcoming_trips"
                     label: "Upcoming"
                     items: _.map upcoming_trips, (trip) =>
                        {value: trip.id, label: trip.name, selected: _.contains(@state.trip_membership, trip.id)}
                  },{
                     value: "past_trips"
                     label: "Past"
                     items: _.map past_trips, (trip) =>
                        {value: trip.id, label: trip.name, selected: _.contains(@state.trip_membership, trip.id)}
                  }]
                  onCreate: @handleCreate
                  onSelect: @handleSelect
