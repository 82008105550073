module.exports = do ->
   _ = require("underscore").default
   {createComponent} = require("@evertrue/et-flux")
   React = require("react")
   {any, func} = ReactLibs.PropTypes
   ComboboxWithImages = require("components/forms/combobox-with-images")

   OPTIONS = [
      label: "Eventbrite Events"
      value: "eventbrite"
      icon: "/images/icon_eventbrite_lg.png"
   ,
      label: "Facebook Events"
      value: "facebook"
      icon: "/images/FB-f-Logo__blue_50.png"
   ]


   createComponent "EventSourceDropdown",
      propTypes:
         value: any
         onChange: func

      handleChange: (selected) ->
         items = _.map selected, (item) -> _.pick item, ["label", "value"]
         @props.onChange(items)

      render: ->
         ComboboxWithImages
            className: "event-source-dropdown"
            options: OPTIONS
            value: @props.value
            multiple: true
            placeholder: "All Event Sources"
            onChange: @handleChange


