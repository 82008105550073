import PropTypes from "prop-types";
import ContactSummaryInteractionListItem from "./contact-summary-interaction-list-item";

const ContactSummaryInteractionFeed = ({
  type = "interaction",
  interactions = [],
  loading = false,
  error = null,
  displayLimit = null,
}) => {
  const loadingOrEmpty = () => {
    if (loading) return null;

    const { message } = emptyData();

    if (!interactions.length) return <div className="text-placeholder padded-sixteen fs-exclude-data">{message}</div>;
  };

  const emptyData = () => {
    let message = `There are no ${type}s`;
    let icon = "gt-comment";

    if (error === "DELETED") {
      icon = "trash";
      message = `This ${type} has been permanently deleted`;
    }
    if (error === "ACCESS_DENIED") {
      icon = "lock";
      message = "You do not have access to this list";
    }

    return { icon, message };
  };

  const interactionsToDisplay = () => {
    if (!displayLimit) return interactions;

    return interactions.slice(0, displayLimit);
  };

  return (
    <div>
      {loadingOrEmpty() || (
        <div>
          {interactionsToDisplay().map((interaction) => (
            <ContactSummaryInteractionListItem interaction={interaction} key={interaction.id} type={type} />
          ))}
        </div>
      )}
    </div>
  );
};

ContactSummaryInteractionFeed.propTypes = {
  type: PropTypes.string,
  interactions: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.any,
  displayLimit: PropTypes.number,
};

export default ContactSummaryInteractionFeed;
