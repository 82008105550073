/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import $ from "jquery";

import _ from "underscore";
import EverTrue from "app";
import { createSource } from "@evertrue/et-flux";
import Api from "entities/helpers/api";

const _toast_options = (id) => {
  const has_action = !(window.location.pathname != null
    ? window.location.pathname.match(new RegExp(`^\\/interaction\\/${id}`))
    : undefined);
  const action_callback = () => EverTrue.Navigator(`/interaction/${id}`, { trigger: true });

  return {
    className: "toast-action-lg",
    timeout: 8000,
    actionLabel: has_action ? "View Interaction" : undefined,
    onAction: has_action ? action_callback : undefined,
  };
};

export default createSource("NoteSource", {
  actions: {
    fetching: true,
    fetchedNotes: true,
    filterNotes: true,
    createdNote: true,
    deletedNote: true,
    loadMore: true,
    loading: true,
  },

  trackSave(data, type) {
    const users_mentioned = _.uniq(
      _.compact(_.map(data != null ? data.mentions : undefined, (mention) => mention.mentioned_user_id))
    );
    const contacts_mentioned = _.uniq(
      _.compact(_.map(data != null ? data.mentions : undefined, (mention) => mention.mentioned_contact_id))
    );

    return EverTrue.track.set("interaction_action", {
      type,
      category: data.interaction_type || data.category,
      "colleagues mentioned": users_mentioned.length,
      "constituents mentioned": contacts_mentioned.length,
      action: data && data.id ? "edit" : "create",
    });
  },

  promise: {
    delete(note, contact_id) {
      return Api.UGC.COMMENT.delete({
        urlExtend: `/contact/${contact_id}/${note.id}`,
        data: _.jsonStringify({
          update_source: "EVERTRUE",
        }),
      }).then(() => {
        // The delay is so that the interaction gets removed from the store correctly
        // previously the delete was causing notes to fetch before ES had processed the delete
        _.delay(() => this.actions.deletedNote(note, contact_id), 1000);
        const type = note.interaction_type === "EverTrue Comment" ? "comment" : "interaction";
        EverTrue.track.set("interaction_action", { type, action: "delete" });
        EverTrue.Alert.success(`Your ${type} has been deleted`);
      });
    },
  },

  api: {
    fetch(contact_id) {
      this.actions.loading(true);
      this.actions.fetching(contact_id);
    },

    search(id, options) {
      const opts = _.omit(options, "success", "data");
      return Api.SEARCH.CONTACT_NOTE.post(
        $.extend(
          true,
          {},
          {
            data: _.jsonStringify(options.data),
            success: (resp) => {
              this.actions.fetchedNotes(id, resp);
              return this.actions.loading(false);
            },
            error: () => {
              this.actions.fetchedNotes(id, { items: [], total: 0 });
              return this.actions.loading(false);
            },
          },
          opts
        )
      );
    },

    applyFilters(contact_id, filters, sort_order) {
      this.actions.loading(true);
      return this.actions.filterNotes(contact_id, filters, sort_order);
    },

    loadMore(id) {
      return this.actions.loadMore(id);
    },

    save(target_id, data, options) {
      if (
        !__guardMethod__(EverTrue.store.user, "getCurrentAffiliation", (o) => o.getCurrentAffiliation()) &&
        (EverTrue.config != null ? EverTrue.config.environment : undefined) === "production"
      ) {
        return EverTrue.Alert.error("Sorry, you must be affiliated with this organization to save an interaction.");
      } else {
        this.trackSave(data, "interaction");
        return Api.UGC.COMMENT.request({
          urlExtend: data.id ? `/contact/${target_id}/${data.id}` : undefined,
          type: data.id ? "PUT" : "POST",
          data: _.jsonStringify(
            _.extend(
              {},
              {
                target_type: "CONTACT",
                target_id,
              },
              data
            )
          ),
          success: (resp) => {
            this.actions.createdNote(target_id, resp);
            return EverTrue.Alert.success("Success! Your interaction has been saved.", _toast_options(resp.id));
          },

          error(xhr, testStatus) {
            // 503 error status and message is for index blocked writes ES related from UGC so ignoring the hard coded errorMessage 
          if (xhr.status !== 503) {
            return EverTrue.Alert.error(`We're sorry, an error occurred while trying to save your interaction. \
Please try again or contact support if the problem persists.`);
          }
          },
        });
      }
    },

    // TODO: This saves to contacts and then get's picked up by ES an unknown amount
    // of time later. Eventually this needs to be fixed (similar to how we managed Lists contacts)
    // But for now we're managing the changes all on the client.
    saveComment(target_id, comment, options) {
      if (options == null) {
        options = {};
      }
      this.trackSave(comment, "comment");
      const data = _.compactObject(_.omit(comment, "mentions"));

      return Api.UGC.COMMENT.request({
        urlExtend: comment.id ? `/contact/${target_id}/${comment.id}` : undefined,
        type: comment.id ? "PUT" : "POST",
        data: _.jsonStringify(
          _.extend(
            {},
            {
              target_id,
              target_type: "CONTACT",
              contact_id: target_id,
              interaction_type: "EverTrue Comment",
            },
            data
          )
        ),
        success: (resp) => {
          this.actions.createdNote(target_id, resp);
          if (options.target_name) {
            return EverTrue.Alert.success(
              `Success! Your comment has been saved for ${options.target_name}.`,
              _toast_options(resp.id)
            );
          } else {
            return EverTrue.Alert.success("Success! Your comment has been saved.", _toast_options(resp.id));
          }
        },
        error(xhr) {
         // 503 error status and message is for index blocked writes ES related from UGC so ignoring the hard coded errorMessage 
         if (xhr.status !== 503) { 
          return EverTrue.Alert.error(`We're sorry, an error occurred while trying to save your comment. \
Please try again or contact support if the problem persists.`);
         }
        },
      });
    },

    saveCommentFromResponse(target_id, api_resp) {
      this.trackSave(api_resp, "comment");
      this.actions.createdNote(target_id, api_resp);
    },

    saveInteractionFromResponse(target_id, api_resp) {
      this.trackSave(api_resp, "interaction");
      this.actions.createdNote(target_id, api_resp);
    },
  },
});

function __guardMethod__(obj, methodName, transform) {
  if (typeof obj !== "undefined" && obj !== null && typeof obj[methodName] === "function") {
    return transform(obj, methodName);
  } else {
    return undefined;
  }
}
