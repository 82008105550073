module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   EverTrue = require("app")
   Requester = require("clientRequester")
   ErrorLogger = require("entities/helpers/error-log-helper")

   _endpoint_config =
      AUTH:
         ORGS: "auth/organizations"
         SESSION: "auth/session"
         REMOVE_FB_TOKEN: "auth/users/:id/keychain/facebook_user_token"
         HAS_FB_IDENTITIES: "auth/users/has_facebook_identities"
         USER: "auth/users"
         USER_BY_CONTACT_ID: "auth/users/contact_id"
         AFFIL: "auth/affiliations"
         AFFIl_ROLES: "/affiliation_roles"
         AFFIL_INVITES: "auth/affiliation_invitations"
         AFFIL_INVITES_BULK: "auth/affiliation_invitations/bulk"
         AFFIL_INVITES_CSV_INVITE: "auth/affiliation_invitations/csv_invite"
         CSV_UPLOAD: "auth/csv_invites"
         OTP: "auth/otp"
         USER_MFA_SETTINGS: "auth/settings/mfa"
         SKIFF: "skiff"
         ACTIVE_SESSIONS: "auth/settings/logins"
         ROLES: "auth/roles"
         PROFILE: "auth/profiles/:affiliation_id"
         DEPARTMENTS: "auth/school_division_departments"
         PERSONAS: "auth/personas"
         PHOTO: "auth/profiles/:affiliation_id/photos"
         TABLEAU: "auth/tableau/user/dashboards"
         TABLEAU_URL: "auth/tableau/login/url"
         FORUMBEE: "auth/forumbee"
      CONTACTS:
         CONTACT: "contacts/v1/contacts/:id"
         SEARCH: "contacts/v2/search"
         EXPORTS: "contacts/v2/exports"
         EXPORT_TYPE: "contacts/v2/exports/:type"
         EXPORT_LATEST: "contacts/v2/exports/latest"
         SEARCH_EXPORT: "contacts/v2/search/export"
         SEARCH_SHARE: "contacts/v2/search/share"
         FACETS: "contacts/v2/search/facet"
         SCHEMA: "contacts/v1/properties"
         LISTS: "contacts/v1/lists"
         LIST_FAVORITES: "contacts/v1/lists/auto"
         LIST_FILTERS: "contacts/v1/lists/filters"
         LIST_COLLABS: "contacts/v1/lists/:list_id/collaborators"
         LIST_CONTACTS: "contacts/v1/lists/:id/contacts"
         LIST_ACTIVITY: "contacts/v1/lists/:id/activities"
         LIST_GROUPS: "contacts/v1/list_groups"
         LISTS_V2: "contacts/v2/lists"
         RECENT: "contacts/v1/profile_views/recent"
         RECORD_PROFILE_VIEW: "contacts/v1/profile_views/:id"
         ROLES_OVERRIDE: "contacts/v1/roles/override/contact/:id"
         BULK: "contacts/v1/contacts/bulk"
         GIFTS: "contacts/v1/gifts/contact"
         IDENTITY_MATCH: "contacts/v1/contacts/identities/match"
         BULK_ADD_TO_LIST: "contacts/v2/lists/:list_id/contacts/bulk"
         BULK_ADD_TO_LIST_V2: "contacts/v2/lists/:list_id/contacts/bulk/filters_v2"
         SCHEDULED: "contacts/v2/exports/scheduled"
      ENGAGEMENTS:
         SUMMARY: "engage/v1/engagers/summary"
         UNMATCHED_ENGAGERS: "engage/v1/engagers/pages/:page_id/unmatched_engagers"
      EXPORTS:
         EXPORT_TYPE: "exporter/v1/submit/:type"
         CORE_WEALTH_META: "exporter/v1/submit/enrichment/wealth/metadata"
         CORE_WEALTH: "exporter/v1/submit/enrichment/wealth/download"
         CORE_ENRICHMENT_CAREER: "exporter/v1/submit/enrichment/career-updates"
      SEARCH:
         CONTACTS: "search/v2/contacts/search"
         GIFTS: "search/v2/gifts/search"
         STATS: "search/v2/contacts/search"
         SESSION: "search/v2/sessions/search"
         SOCIAL_CONTENT: "search/v2/social_content/search"
         SOCIAL_ENGAGEMENT: "search/v2/social_engagement/search"
         SOCIAL_PROFILE: "search/v2/social_profile/search"
         SOCIAL: "search/v2/social/search"
         USER: "search/v2/users/search"
         REMOTE_USER: "search/v2/remoteuser/search"
         CONTACT_NOTE: "search/v2/contact_note/search"
         LIST_NOTE: "search/v2/list_note/search"
         PROPOSAL: "ugc/v2/search/proposal"
         PROPOSAL_STATS: "search/v2/proposal/search"
         LAST_CONTACT_DATE: "search/v2/contact_note/last_contact_date"
         LAST_ACTIVITY_DATE_BY_CONTACT: "search/v2/contact_activity/last_activity_date_by_contact"
         ASSIGNMENTS: "search/v2/assignment/search"
         SESSIONS: "search/v2/sessions/search"
      DNA:
         GATES: "dna/gates"
         SETTING: "dna/setting_values/:setting_key"
         ALL: "dna/setting_values"
      EMS:
         ACTIVITY: "ems/activities"
         NOTIFICATION: "ems/notifications"
         PREFS: "ems/preferences"
         SMS: "ems/messages/sms/canned"
         PREFS_BY_KEY: "ems/preferences/:key"
      LIDS:
         ACCESS_TOKEN: "lids/callback"
         ASSOCIATE: "lids/auth/association"
         ACTIVATE: "lids/auth/association/activate"
         AUTH_URL: "lids/auth"
         COMPANY: "lids/companies"
         CONNECTIONS: "lids/contacts/:contact_id/linkedin/connections"
         LI_PROFILE: "lids/linkedin/profile"
         MATCHES: "lids/contacts/:contact_id/matches"
         SOCIAL_PROFILE: "lids/contacts"
         SERVICES: "lids/services"
         REGISTRATIONS: "lids/auth/registrations"
         SOCIAL_AGGREGATIONS: "lids/social_aggregations"
         ORG_DATA: "lids/organizations"
         FACEBOOK_AUTH: "lids/facebook_auth"
         FACEBOOK_TOKENS: "lids/tokens/facebook/:page_id/deactivate"
      UGC:
         COMMENT: "ugc/v2/note"
         NOTE_TARGET_ID: "ugc/v2/note/:target_type/:target_id/:note_id"
         CUSTOM_FIELDS: "ugc/v2/interactions/custom_fields"
         CUSTOM_FIELD_VALUES: "ugc/v2/interactions/custom_field_values/form_values/:custom_field_id"
         INTERACTION_TYPES: "ugc/v2/types/form_values/interaction"
         REMINDERS: "ugc/v2/reminder"
         PROPOSAL_STAGES: "ugc/v2/proposal/stage"
         PROPOSAL_STAGE: "ugc/v2/proposal/:proposal_id/stage"
         PROPOSAL: "ugc/v2/proposal"
         PROPOSAL_CUSTOM_FIELDS: "ugc/v2/proposal/custom_fields"
         PROPOSAL_FACET: "ugc/v2/search/proposal/facet"
         PROPOSAL_TYPES: "ugc/v2/types/form_values/proposal"
         KEY_PROPOSAL: "ugc/v2/proposal/:proposal_id/solicitor/:contact_id/key_proposal"
         PROPOSAL_SOLICITOR_TITLES: "ugc/v2/solicitor_titles/form_values/proposal"
         PROPOSAL_DESIGNATIONS: "ugc/v2/designations/proposal"
      IMPORTER:
         LATEST: "importer/v3/jobs/latest"
      GIVE:
         ACCOUNT: "payments/v1/account"
         ASSETS: "payments/v1/assets"
         CAMPAIGNS: "payments/v1/campaigns"
         CAMPAIGN: "payments/v1/campaigns/:campaign_id"
         DEFAULT_CAMPAIGN: "payments/v1/campaigns/default"
         APPEALS: "payments/v1/appeals"
         APPEAL_EVENTS: "search/v2/appeal_event/search"
         CONTAT_APPEAL: "search/v2/contact_appeal/search"
         CONTACT_APPEAL_EVENTS: "search/v2/contact_appeal_event/search"
         AUTHORIZE: "payments/v1/oauth/authorize"
         DEAUTHORIZE: "payments/v1/oauth/deauthorize"
         OAUTH_CALLBACK: "payments/v1/oauth/callback"
         CAMPAIGN_ASSETS: "payments/v1/campaigns/:campaign_id/assets"
         EXPORT: "payments/v1/exports"
         RECEIPTS: "payments/v1/receipts"
         PAYMENTS_EVENT: "payments/v1/events/:event_id"
         PAYMENTS_BATCH: "payments/v1/events/batch/:batch_id"
         PAYMENTS_VISITOR: "payments/v1/events/visitor/:visitor_id"
      CHARTING:
         GIFTS_FY: "charting/v1/gifts/fy"
         V2: "charting/v2"
      WINDLASS:
         UPLOAD: "windlass/asset"
      ASSIGNMENTS:
         SEARCH: "assignments-java/v2/search/assignments"
         ASSIGNMENT_TITLES: "assignments-java/v2/assignments/titles"
         BULK_ADD_TO_POOL: "assignments-java/v2/:type/criteria/filters_v2"
         UPDATE_JOURNEY_STAGE: "assignments-java/v4/assignments/stage"
      VOLUNTEER:
         POOLS: "assignments-java/v2/pools"
         SOLICITORS: "assignments-java/v2/solicitors"
         SOLICITOR_CONTACT: "assignments-java/v2/solicitors/contact"
         PROSPECTS: "assignments-java/v2/prospects"
         ASK_AMOUNT: "assignments-java/v2/asks"
         ASSIGNMENTS: "assignments-java/v2/assignments"
         MEMBERSHIP: "assignments-java/v2/pools/:contact_id/:type/memberships"
         BULK_MEMBERSHIPS: "assignments-java/v2/pools/:type/memberships/bulk"
         STAGES: "assignments-java/v2/stages"
         UPDATE_STAGE: "assignments-java/v2/assignments/:assignment_id/stage"
         UPDATE_ASSIGNMENT_TITLE: "assignments-java/v2/assignments/:assignment_id/assignment_title"
         BULK_ADD: "assignments-java/v2/:type/criteria"
         BULK_ROLLOVER: "assignments-java/v2/pools/bulk/rollover"
         ANALYTICS: "search/v2/assignment_analytics/search"
         WARM_ANALYTICS: "assignments-java/v2/analytics"
         COMMITTED_AMOUNT: "assignments-java/v2/committed"
         KEY_PROSPECT: "assignments-java/v2/assignments/:assignment_id/key_prospect"
         AVG_TIME_IN_STAGE: "assignments-java/v2/pools/:pool_id/solicitor/:solicitor_contact_id/time_in_stage"
      VOYAGER:
         TRIPS: "voyager/v1/trips"
         MEETINGS: "voyager/v1/meetings"
         USER: "voyager/v1/platform/user"
         CONNECTIONS: "voyager/v1/connections"
         CONTACTS: "voyager/v1/trips/:trip_id/contacts"
         CONTACTS_BULK: "voyager/v1/trips/:trip_id/contacts/bulk"
         CRONOFY: "voyager/v1/cronofy"
         CALENDARS: "voyager/v1/cronofy/calendars"
         CALENDAR: "voyager/v1/cronofy/calendar"
         ACCOUNT: "voyager/v1/cronofy/account"
      CHRONOMETER:
         SCHEDULE: "chronometer/v1/schedule"
      EVENTS:
         EVENTS: "search/v2/event/search"
         ENGAGEMENTS: "search/v2/contact_event_engagement/search"
         EVENTBRITE_USER: "events/v1/user"
         EVENTBRITE_MATCH: "events/v1/match/eventbrite"
         EVENTBRITE_PROFILES_EVENT: "events/v1/event/:event_id/eventbrite_profiles"
         EVENTBRITE_PROFILES: "events/v1/eventbrite_profile"
         EVENT_ENGAGEMENTS: "events/v1/event/engagements"
      SUGGESTIONS:
         SUBMISSIONS: "suggestions/v1/submissions"
         SUBMISSIONS_BULK: "suggestions/v1/submissions/bulk"
      EMMA:
         ACCOUNTS: "emma/v1/account"
         LIST: "emma/v1/list/:list_id"
         GROUPS_BY_LIST: "emma/v1/group/list/:list_id"
         PUSH_LIST: "emma/v1/list/push"
      GRAPHQL:
         GRAPHQL: "graphql"
      GOALS:
         SOLICITOR: "hub/v1/goal/solicitor"
         TEAM: "hub/v1/goal/team"
      HUB:
         FILTERS: "hub/v1/filters"
         FILTERS_SEARCH_CONTACTS: "hub/v1/filters/search/contact/ui"
         FILTER_OPTIONS: "hub/v1/filters/:filter_id/facets"
         WIDGETS: "hub/v1/widget"
         WIDGET_USER_SETTINGS: "hub/v1/widget/user_settings/batch"
         TEAM_WIDGETS: "hub/v1/widget/team"
         ALL_SEARCHES: "hub/v1/searches",
         SEARCHES: "hub/v1/searches/:id"
         SEARCH_CREATE: "hub/v1/searches"
         SEARCH_PERMISSIONS: "hub/v1/searches/:id/permissions"
         SEARCH_USER_PERMISSIONS: "hub/v1/searches/:search_id/permissions/user/:user_id"
         LISTS: "hub/v1/lists"
         LIST: "hub/v1/lists/:id"
         BULK: "hub/v1/lists/:id/contact_tags"
         ALERT: "hub/v1/lists/:id/alert"
         SEARCH_LIST_PERMISSIONS: "hub/v1/lists/:id/permissions"
         SEARCH_LIST_USER_PERMISSIONS: "hub/v1/lists/:search_id/permissions/user/:user_id"
      JOURNEYS:
         TASKS: "journeys/v1/tasks"
         TASK: "journeys/v1/tasks/:id"
         USER_TASKS: "journeys/v1/tasks/users/:user_id"
         CONTACT_TASKS: "journeys/v1/tasks/contacts/:contact_id"
         COMPLETE_TASK: "journeys/v1/tasks/:task_id/complete"
         SKIP_TASK: "journeys/v1/tasks/:task_id/skip"
         JOURNEY_TIMEOUT_BY_USER: "journeys/v1/journey-timeouts/users/:user_id"
         JOURNEY_TIMEOUT: "journeys/v1/journey-timeouts/:id"
         JOURNEY_TIMEOUTS: "journeys/v1/journey-timeouts/"
         CANCEL_JOURNEY_TIMEOUT: "journeys/v1/journey-timeouts/:id/cancel"
         ACTION: "journeys/v1/actions"
         TASK_ACTION_TYPES: "journeys/v1/tasks/action-types"
         CONTACT_JOURNEY: "journeys/v2/contact-journeys"
         GET_CONTACT_JOURNEY: "journeys/v2/contact-journeys/journey/:contact_id"
         USER_SETTINGS: "journeys/v1/recommended-actions-user-settings/"
         EDIT_USER_SETTINGS: "journeys/v1/recommended-actions-user-settings/:id"
         RECOMMENDED_ACTIONS: "journeys/v1/recommended-actions"
         RECOMMENDED_ACTIONS_VIEW: "journeys/v1/recommended-actions/:id/view"
         JOURNEY_MOVE: "journeys/v1/journey-moves"
         BULK_ADD_CONSTITUENTS: "journeys/v2/contact-journeys/bulk"
         JOURNEY_FAVORITES: "journeys/v1/favorites"
         JOURNEY_FAVORITE: "journeys/v1/favorites/:id"
         JOURNEYS: "journeys/v1/journeys"
         JOURNEY_TASKS: "journeys/v1/journey-tasks"
         JOURNEY_TASK: "journeys/v1/journey-tasks/:id"
         JOURNEY: "journeys/v1/journeys/:id"
         DEACTIVATE_JOURNEY_TASK: "journeys/v1/journey-tasks/:id/inactive"
      OUTREACH:
         VOIP_TOKEN: "outreach/v1/voip/token"
         CREDENTIALS: "outreach/v1/email/credentials"
         PROVIDERS: "outreach/v1/email/providers"
         SEND: "outreach/v1/email/send"
         LINK: "outreach/v1/email/link"
         UNLINK: "outreach/v1/email/unlink"
         VOIP_USER_SETTINGS: "outreach/v1/voip/user-settings"
         VOIP_USER_SETTING: "outreach/v1/voip/user-settings/:voip_user_settings_id"
         VOIP_USER_SETTINGS_BY_USER_ID: "outreach/v1/voip/user-settings/users/:owner_user_id"
         VALIDATE_PHONE: "outreach/v1/voip/validate"
         VALIDATION_REQUEST: "outreach/v1/voip/validation/request/:callSid"
         EMAIL_TEMPLATES: "outreach/v1/email-template"
         EMAIL_TEMPLATES_SEARCH: "outreach/v1/email-template/search"
         EMAIL_TEMPLATE: "outreach/v1/email-template/:id"
         UPLOAD_PRESIGNED_URL: "outreach/v1/email-attachment/upload-presigned-url"
         EMAIL_SIGNATURES: "outreach/v1/email-signature/user-signatures"
         EMAIL_SIGNATURE: "outreach/v1/email-signature"
         UPDATE_EMAIL_SIGNATURE:"outreach/v1/email-signature/:id"
         DELETE_EMAIL_SIGNATURE: "outreach/v1/email-signature/:id"
         DOWNLOAD_PRESIGNED_URL: "outreach/v1/email-attachment/download-presigned-url"
         COMPILE_TEMPLATE: "outreach/v1/compile"
      AI:
         SUMMARY: "ai/v1/summarizations/profile/:contact_id"
         PROMPTS: "ai/v1/prompts"
         CREATE_CHAT: "ai/v1/chats"


   Api = Requester.Manager _endpoint_config,
      debug: true
      baseRoute: EverTrue.config.apiBase
      appKey: EverTrue.config.app_keys?.givingtree

      onRequest: (options) ->
         $.ajax(options)

      onGlobalError: (original, options, args) ->
         [xhr, textStatus, error] = args
         status = xhr?.status
         response = _.jsonParse(xhr.responseText, {})
         is_authorized = options.headers?["Authorization"]

         checkLids = (status_code) ->
            if options?.url.indexOf("/lids/") > -1
               linkedin_is_unauthorized = _.any [
                  (response.errors?.user?.keychain?.linkedin_access_token in ["is invalid", "is missing"]),
                  (response.message == "LinkedIn::Unauthorized")
               ]
               if linkedin_is_unauthorized
                  EverTrue.execute("promptForLinkedin")
               else if response.errors?.contact isnt "was not matched to a LinkedIn profile"
                  ErrorLogger.captureRequest "Unknown LIDS #{status_code}", xhr,
                     extra: _.pick(options, ["type", "url", "forceRequest"])
                     tags: {endpoint: _.urlInfo(options.url)?.endpoint}

         switch status
            when 401
               if is_authorized && options.headers["Authorization-Provider"] == "evertrueauthtoken"
                  EverTrue.store.session.renewSession(options)
                  return unless options.forceRequest
            when 403
               if (is_authorized || options.xhrFields?.withCredentials)
                  if response.error_code == "otp_required"
                     EverTrue.execute("requestMfaCode", options, response)
                     return
                  else if response.error_code in ["mfa_locked", "mfa_required"]
                     EverTrue.Alert.error(response.message)
                  else
                     checkLids(403)
            when 412
               checkLids(412)
            when 421
               return
            else
               # Do not run if impersonation error or cancel - or if unknown api error
               if status != 5000 && status != 0
                  if !options.disableAlerts && !_.isEmpty(response)
                     defaultErrorMessage = "Error, please contact genius@evertrue.com for assistance"
                     EverTrue.Alert.error(options.error_message || response?.message || defaultErrorMessage)

         original?(xhr, textStatus, error)

      onGlobalSuccess: (original, options, args) ->
         [resp, status, xhr] = args

         if options.success_message && !options.disabledAlerts
            EverTrue.Alert.success(options.success_message)

         # If impersonating error throw 5000 Status Code for Handling Support

         if EverTrue.request("isImpersonating?")
            impersonating = xhr?.getResponseHeader("ET-Impersonating")
            impostor = xhr?.getResponseHeader("ET-Impostor")
            if !impostor || !impersonating
               ErrorLogger.warn "Endpoint Doesn't Support Impersonation",
                  tags: {endpoint: _.urlInfo(options.url)?.endpoint}
               if _.isFunction(options.error)
                  options.error?(xhr, "error", {message: "Impersonation Not Supported"})
               else original?(resp, status, xhr)
            else original?(resp, status, xhr)
         else original?(resp, status, xhr)

   Api
