import { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "@evertrue/et-flux";
import ProfileCardHeader from "./profile-card-header";
import TopFieldsStore from "../stores/top-fields-store";
import Modal from "components/modals/modal";
import ModalTrigger from "components/modals/modal-trigger";
import TopFieldsSelector from "./top-fields-selector";
import Icon from "components/elements/icon";
import Datalist from "components/elements/datalist";
import TopFieldsSource from "../sources/top-fields-source";

const mapStateToProps = ({ contact }) => ({
  topContactData: TopFieldsStore.getTopForContact(contact),
  hasEditableFields: TopFieldsStore.hasEditableFields(),
});

const ProfileTopFields = ({ contact, topContactData, hasEditableFields }) => {
  useEffect(() => {
    TopFieldsSource.fetchTopFields();
  }, [contact]);

  const renderCustomizeModal = (
    <Modal width={660} animationClass={false}>
      <TopFieldsSelector />
    </Modal>
  );

  return (
    <div className="profile-top-fields">
      <ProfileCardHeader className="contact-profile--card-header" title="My Top Fields">
        {hasEditableFields && (
          <ModalTrigger title="Customize" className="pull-right" modal={renderCustomizeModal}>
            <Icon icon="settings">Customize</Icon>
            Customize
          </ModalTrigger>
        )}
      </ProfileCardHeader>
      <div className="profile-top-fields--list">
        {topContactData.map((field) => (
          <Datalist label={field.label} key={field.key}>
            {field.value && field.value.length > 0 ? (
              field.value.map((val) => (
                <div key={val}>
                  <span className="fs-exclude-data">{val}</span>
                </div>
              ))
            ) : (
              <span className="profile-top-fields--empty-field">Not Provided</span>
            )}
          </Datalist>
        ))}
      </div>
    </div>
  );
};

ProfileTopFields.propTypes = {
  contact: PropTypes.object,
  topContactData: PropTypes.array,
  hasEditableFields: PropTypes.bool,
};

ProfileTopFields.defaultProps = {
  contact: {},
};

export default connect(ProfileTopFields, [TopFieldsStore], mapStateToProps);
