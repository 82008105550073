import EverTrue from "app";
import BaseRouter from "base/base-router";
import JourneyDetailController from "./controllers/journey-detail-controller";
import JourneyIndexController from "./controllers/journey-index-controller";
import IsGatedController from "components/gates/is-gated-controller";

class JourneysRouter extends BaseRouter {
  static initClass() {
    this.prototype.routes = {
      "cadences(/)": "index",
      "cadences/:id": "journey",
    };
  }

  // ALL Journeys
  async index() {
    EverTrue.track.set("cadences", { type: "left_nav" });
    EverTrue.execute("setBrowserTitle", "Cadences");
    this.currentView = (
      <IsGatedController gate="cadences">
        <JourneyIndexController />
      </IsGatedController>
    );
    EverTrue.page.mount(this.currentView);
  }
  // ONE Journey
  journey(id) {
    EverTrue.track.set("cadences", { type: "left_nav" });
    EverTrue.execute("setBrowserTitle", "Cadences");
    this.currentView = (
      <IsGatedController gate="cadences">
        <JourneyDetailController journeyId={id} />
      </IsGatedController>
    );
    EverTrue.page.mount(this.currentView);
  }
}

JourneysRouter.initClass();
export default JourneysRouter;
