import classNames from "classnames";
import { PropTypes } from "base/react-libs";
import { Button, Icon } from "@evertrue/et-components";

const NavButton = ({ activeWhen, active, onSelect, icon, buttonProps, children }) => {
  return (
    <Button
      className={classNames("journey-nav-tabs", { "is-active": activeWhen === active })}
      onClick={onSelect}
      {...buttonProps}
      type="simple"
    >
      {icon && <Icon icon={icon} />}
      {children}
    </Button>
  );
};

NavButton.propTypes = {
  activeWhen: PropTypes.any,
  active: PropTypes.number,
  onSelect: PropTypes.func,
  icon: PropTypes.string,
  buttonProps: PropTypes.object,
  children: PropTypes.any,
};

export default NavButton;
