import PropTypes from "prop-types";
import Icon from "components/elements/icon";
import classNames from "classnames";

const InteractionIcon = ({ interaction = {}, isSecondary = false, className = "" }) => {
  const icons = [
    { type: "phone", iconName: "call" },
    { type: "call", iconName: "call" },
    { type: "meeting", iconName: "calendar" },
    { type: "mailing", iconName: "mailing" },
    { type: "task", iconName: "task" },
    { type: "email", iconName: "email" },
    { type: "evertrue comment", iconName: "gt-comment", className: "is-comment" },
    { type: "dxo ask", iconName: "dollar" },
    { type: "note", iconName: "edit" },
    { type: "other-interaction", iconName: "other-interaction" },
  ];

  const icon = icons.find((icon) => {
    const interactionType = interaction.interaction_type ? interaction.interaction_type : "other-interaction";
    return interactionType.toLowerCase().match(icon.type);
  });

  return (
    <div
      className={classNames("interaction-icon", { "is-secondary": isSecondary }, className, icon ? icon.className : "")}
    >
      <Icon icon={icon ? icon.iconName : "other-interaction"} />
    </div>
  );
};

InteractionIcon.propTypes = {
  interaction: PropTypes.object,
  isSecondary: PropTypes.bool,
  className: PropTypes.string,
};

export default InteractionIcon;
