module.exports = do ->
   _ = require("underscore").default
   numeral = require("numeral")
   React = require("react")
   EverTrue = require("app")
   {div, h3, strong, span} = ReactLibs.DOMFactories
   {createComponent} = require("@evertrue/et-flux")
   FeatureStore = require("apps/layout/stores/feature-store").default
   TripLogSource = require("apps/my-trips/sources/trip-log-source")
   TripLogStore = require("apps/my-trips/stores/trip-log-store")
   TripContactsStore = require("apps/my-trips/stores/trip-contacts-store")
   MeetingsStore = require("apps/my-trips/stores/meetings-store")
   MyTripsUtils = require("apps/my-trips/utils/my-trips-utils")
   InteractionFeed = require("apps/interactions/components/interaction-feed")
   {createFactory} = require("base/new-utils")
   InteractionAddBtn = createFactory(require("apps/interactions/components/create/interaction-add-btn").default)
   EmptyStateMessage = createFactory(require("components/lists/empty-state-message"))
   Icon = createFactory (require("components/elements/icon").default)
   LinkButton = createFactory(require("@evertrue/et-components").LinkButton)

   createComponent "TripLogController",
      propTypes:
         trip: ReactLibs.PropTypes.object
         onClose: ReactLibs.PropTypes.func

      registerStores: ->
         @on TripLogStore, ->
            interactions: TripLogStore.getForTrip(@props.trip?.id)
            count: TripLogStore.getTotalForTrip(@props.trip?.id)
            loading: TripLogStore.getLoading()

         @on MeetingsStore, ->
            booked = MeetingsStore.getBookedContacts(@props.trip?.id)
            not_booked = MeetingsStore.getUnconfirmedContacts(@props.trip?.id)
            contact_options: MyTripsUtils.getGroupedContactOptions(booked, not_booked)

         @on TripContactsStore, ->
            contacts: TripContactsStore.getContactsForTrip(@props?.trip?.id)

         @on FeatureStore, ->
            has_interaction_writes: FeatureStore.hasInteractionWrites()

      componentDidMount: ->
         if @props.trip?.id
            TripLogSource.fetchInteractions(@props.trip.id)
         EverTrue.track.set "viewed", {type: "trip_log"}

      UNSAFE_componentWillReceiveProps: (newProps) ->
         if !_.isEqual(newProps.trip, @props.trip)
            if newProps.trip?.id
               TripLogSource.fetchInteractions(newProps.trip.id)

      renderInteractionButton: ->
         if !_.isEmpty(@state.contacts) && @props.trip.is_my_trip
            InteractionAddBtn
               interaction: {trip_id: @props.trip?.id}
               contactOptions: @state.contact_options
               referrer: "trip"

      render: ->
         noun = if @state.has_interaction_writes then "interactions" else "comments"

         div className: "trip-log",
            div className: "trip-log--header",
               LinkButton title: "Close trip log", className: "trip-log--toggle", onClick: @props.onClose,
                  Icon icon: "collapse", rotate: 180, size: 2

               div className: "trip-log--title",
                  span className: "pull-left",
                     h3 null, "Trip Log"
                     strong null, numeral(@state.count).format("0")
                     if @state.has_interaction_writes
                        if @state.count == 1 then " Interaction" else " Interactions"
                     else
                        if @state.count == 1 then " Comment" else " Comments"

                  unless _.isEmpty(@state.interactions)
                     span className: "pull-right",
                        @renderInteractionButton()

            div className: "trip-log--body",
               if !@state.loading && _.isEmpty(@state.interactions)
                  div className: "trip-log--empty",
                     EmptyStateMessage
                        icon: "trip-log"
                        text: "No #{noun} logged for this trip."
                        "Use #{noun} to report on meetings, phone calls, or other engagements with constituents."
                     @renderInteractionButton()
               else
                  InteractionFeed
                     className: "trip-log--feed"
                     notes: @state.interactions
                     loading: @state.loading
                     showMessage: true
                     showMeta: false
