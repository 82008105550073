module.exports = do ->
   _ = require("underscore").default
   $ = require("jquery")
   React = require("react")
   {div} = ReactLibs.DOMFactories
   {createComponent} = require("@evertrue/et-flux")
   FilterPanelRow = require("components/controls/filter-panel-row")
   FacebookPagesDropdown = require("apps/facebook/components/facebook-pages-dropdown")
   DateSelector = require("apps/filters/components/date-selector")
   {createFactory} = require("base/new-utils")
   ProfileCardSubtitle = createFactory require("apps/profile/components/profile-card-subtitle").default
   EventSourceDropdown = require("apps/events/components/event-source-dropdown")
   FeatureStore = require("apps/layout/stores/feature-store").default

   createComponent "EventsFiltersPanel",
      propTypes:
         temp_filters: ReactLibs.PropTypes.object
         onChange: ReactLibs.PropTypes.func # args(filters)

      registerStores: ->
         @on FeatureStore, ->
            hasEventbrite: FeatureStore.hasFeature("events_eventbrite")
            hasFacebook: FeatureStore.hasFeature("events_facebook")

      handleChange: (key, value) ->
         filters = $.extend true, {}, @props.temp_filters
         if _.isEmpty(value) then filters[key] = undefined
         else filters[key] = value
         @props.onChange?(_.compactObject(filters))

      render: ->
         filters = _.cloneData(@props.temp_filters) || {}

         div className: "events-filters events-filter-panel",

            div className: "events-filters-first-subtitle",
               ProfileCardSubtitle
                  label: "Event Filters"

            FilterPanelRow label: "Event Date",
               DateSelector
                  className: "events-filters--col"
                  date: filters.date
                  default: "Any Date"
                  calendar_position: "bottom"
                  onChange: (value) => @handleChange("date", value)

            if @state.hasEventbrite and @state.hasFacebook
               FilterPanelRow label: "Event Source Type",
                  div className: "events-filters--col",
                     EventSourceDropdown
                        value: filters.source
                        onChange: (value) => @handleChange("source", value)

            if @state.hasFacebook
               FilterPanelRow label: "Facebook Page",
                  div className: "events-filters--col",
                     FacebookPagesDropdown
                        placeholder: "All Facebook Pages"
                        value: filters.fb_page
                        onChange: (value) => @handleChange("fb_page", value)


