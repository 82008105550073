module.exports = do ->
   _ = require("underscore").default
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")
   EverTrue = require("app")
   TOP_FIELDS_SETTINGS_KEY = "GT_profile_fields"


   createSource "TopFieldsSource",
      actions:
         fetchedTopFields: true
         updatedTopFields: true

      api:
         fetchTopFields: ->
            Api.DNA.SETTING.get
               urlArgs: {setting_key: TOP_FIELDS_SETTINGS_KEY}
               disableAlerts: true
               params: {user_id: "me"}
               success: (response) =>
                  fields = response?.settings[TOP_FIELDS_SETTINGS_KEY]
                  @actions.fetchedTopFields(fields?.value)
               error: =>
                  @actions.fetchedTopFields()

         updateTopFields: (new_fields) ->
            # Optimistically update fields before dealing with DNA
            # DNA saving can be behind the scenes & w/o UI display
            @actions.updatedTopFields(new_fields)

            Api.DNA.SETTING.put
               urlArgs: {setting_key: TOP_FIELDS_SETTINGS_KEY}
               disableAlerts: true
               params: {user_id: "me"}
               data: _.jsonStringify {value: new_fields}
               success: (response) ->
                  fields = response?.settings[TOP_FIELDS_SETTINGS_KEY]
                  EverTrue.Alert.success "Successfully updated top fields"
