module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   ModalTrigger = createFactory require("components/modals/modal-trigger")
   Modal = createFactory require("components/modals/modal")
   ProfileCardHeader = createFactory require("apps/profile/components/profile-card-header").default
   EmptyStateMessage = createFactory(require("components/lists/empty-state-message"))
   Loading = require("components/elements/loading")
   classNames = require("classnames")
   ProfileStore = require("apps/profile/stores/profile-store")
   ProfileRelationshipsSource = require("apps/profile/sources/profile-relationships-source")
   RelationshipCard = require("apps/profile/components/relationship-card")
   ModalConfirm = createFactory require("components/modals/modal-confirm")
   Decorator = require("clientDecorator")

   createComponent "ProfileRelationshipsController",
      propTypes:
         contact_id: ReactLibs.PropTypes.number
         contact: ReactLibs.PropTypes.object

      registerStores: ->
         @on ProfileStore, "contact_id", (props) ->
            relationships: ProfileStore.getRelationships(props.contact_id)

      componentDidMount: ->
         @fetchRelationships({}, @props.contact)

      UNSAFE_componentWillReceiveProps: (nextProps) ->
         @fetchRelationships(@props.contact, nextProps.contact)

      fetchRelationships: (old_contact = {}, new_contact = {}) ->
         if contact_id = new_contact.id

            old_id = Decorator.Relationships.getRemoteId(old_contact)
            new_id = Decorator.Relationships.getRemoteId(new_contact)

            old_ids = Decorator.Relationships.getRelationshipRemoteIds(old_contact)
            new_ids = Decorator.Relationships.getRelationshipRemoteIds(new_contact)

            if (new_id and (old_id isnt new_id)) or (_.notEmpty(new_ids) and not _.isEqual(old_ids, new_ids))

               # fetch other contact records corresponding to this contact's relationships
               if _.notEmpty(new_ids)
                  ProfileRelationshipsSource.fetchContactRelationships(contact_id, new_ids)

               # fetch other contacts who have this contact as a relationship
               if new_id
                  ProfileRelationshipsSource.fetchSearchRelationships(contact_id, new_id)


      renderFullData: ->
         Modal width: 480,
            ModalConfirm header: "Relationships",
               _.map @state.relationships, (relationship) ->
                  div
                     className: "profile-relationships-controller--all-relationship"
                     key: relationship.remote_id || _.uniqueId()
                     RelationshipCard
                        relationship: relationship


      render: ->
         count = _.size(@state.relationships)

         div className: "profile-relationships-controller",
            if @state.loading
               Loading text: "Loading..."

            ProfileCardHeader
               className: "contact-profile--card-header"
               title: "Relationships"
               help: "relationships"
               if count > 3
                  ModalTrigger
                     title: "View All #{count} Relationships"
                     className: "pull-right"
                     modal: @renderFullData()
                     "View All #{count} Relationships"

            div className: "profile-relationships-controller--content",
               if _.isEmpty(@state.relationships)
                  EmptyStateMessage
                     className: "profile-relationships-controller--empty-message"
                     icon: "link"
                     text: "No Relationships"
                     "Relationship data has not been provided"
               else
                  div className: "profile-relationships-controller--relationships-list",
                     _.map _.first(@state.relationships, 3), (relationship) ->
                        div
                           key: _.uniqueId()
                           className: classNames("profile-relationships-controller--relationship", "is-of-three": count > 2)
                           RelationshipCard relationship: relationship
