module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   EverTrue = require("app")
   BaseRouter = require("base/base-router")
   EventsController = require("apps/events/controllers/events-controller")
   EventPageController = require("apps/events/controllers/events-page-controller")
   EventbriteSettingsController = require("apps/events/controllers/eventbrite-settings-controller")
   {createFactory} = require("base/new-utils")
   IsGated = createFactory(require("components/is-gated").default)

   class EventsRouter extends BaseRouter
      routes:
         "events(/)": "index"
         "events/eventbrite": "eventbrite"
         "events/:event_id(/)" : "eventPage"

      index: ->
         @currentView =
            IsGated feature: "events_facebook", render: ({ show: has_fb }) ->
               IsGated feature: "events_eventbrite", render: ({ show: has_eb }) ->
                  if has_fb || has_eb then EventsController() else null
         EverTrue.page.mount @currentView

      eventPage: (event_id) ->
         EverTrue.track.setReferrer("event detail")
         @currentView = EventPageController(event_id: _.toNumber(event_id), filters: ["all"])
         EverTrue.page.mount @currentView

      eventbrite: ->
         @currentView = EventbriteSettingsController()
         EverTrue.page.mount @currentView
