import EverTrue from "app";
import BaseRouter from "base/base-router";
import SegmentsPageController from "apps/segments/controllers/segments-page-controller";

class SegmentsRouter extends BaseRouter {
  static initClass() {
    this.prototype.routes = {
      segments: "segments",
    };
  }

  segments() {
    EverTrue.execute("setBrowserTitle", "Segments");
    window.scrollTo(0, 0);
    EverTrue.track.set("viewed", { type: "saved_searches" });
    this.currentView = <SegmentsPageController feature="filters_v2" />;
    EverTrue.page.mount(this.currentView);
  }
}

SegmentsRouter.initClass();
export default SegmentsRouter;
