import { PageHeader, Button } from "@evertrue/et-components";
import usePauseJourneyForm from "./use-pause-journey-form";
import DatePickerDropdown from "components/calendar/date-picker-dropdown";
import ConfirmModal from "./confirm-pause-modal";
import FormField from "components/forms/form-field";
import { trackJourneysAction } from "apps/journeys/utils/helpers/mixpanel";
import useJourneyTimeoutsApi from "apps/journeys/hooks/use-journey-timeouts-api";
import { useTasksNotificationContext } from "apps/tasks/hooks/tasks-notification-context.js";

const PauseJourneyForm = () => {
  const { inProgressJourneyTimeout, futureJourneyTimeout, handleCreate, handleUpdate, handleResume, handleCancel } =
    useJourneyTimeoutsApi();
  const { getAndSetGlobalTasksDueCount } = useTasksNotificationContext();

  const {
    startMessage,
    selectedStartDate,
    endMessage,
    selectedEndDate,
    disableSave,
    modalTitle,
    modalBody,
    modalAction,
    isModalOpen,
    closeModal,
    handleEndDateClick,
    handleStartDateClick,
    handleCancelClick,
    handleResumeClick,
  } = usePauseJourneyForm(inProgressJourneyTimeout, futureJourneyTimeout);

  const handleCreateClick = async () => {
    trackJourneysAction("create_cadence_pause");
    await handleCreate(selectedStartDate, selectedEndDate);
    getAndSetGlobalTasksDueCount();
  };

  const handleUpdateClick = () => {
    handleUpdate(selectedStartDate, selectedEndDate);
  };

  const resumeAction = async () => {
    trackJourneysAction("end_cadence_pause");
    await handleResume();
    getAndSetGlobalTasksDueCount();
    closeModal();
  };

  const cancelAction = () => {
    handleCancel();
    closeModal();
  };

  return (
    <>
      <PageHeader title="Pause Cadences and Tasks" className="pause-journey-header" />
      <p className="pause-journey-warning">
        Pausing cadences will freeze all your cadences and tasks until you return. You can use this feature when you are
        heading out on some well deserved time off, are out of office visiting donors, or have a busy week of meetings.
        Don’t forget to set a pause for any departmental holidays! 
      </p>
      <p className="pause-journey-warning">
      You can resume your cadences at any time, or set a
        return date on any weekday to automatically resume your tasks when you log on for the day.
      </p>
      <FormField label="Start Date" inlineLabel={false} className="pause-journey-date-label">
        <div className="pause-journey-date-container" data-testid="date-picker-dropdown">
          <DatePickerDropdown
            date={selectedStartDate}
            onDayClick={handleStartDateClick}
            triggerClassName={"pause-journey-date-picker"}
            chevronClassName={"date-picker-chevron"}
            allowClear={true}
          />
          <p style={{ marginLeft: "20px" }}>{startMessage}</p>
        </div>
      </FormField>
      <FormField label="Return Date" inlineLabel={false} className="pause-journey-date-label">
        <div className="pause-journey-date-container" data-testid="date-picker-dropdown">
          <DatePickerDropdown
            date={selectedEndDate}
            onDayClick={handleEndDateClick}
            triggerClassName={"pause-journey-date-picker"}
            chevronClassName={"date-picker-chevron"}
            allowClear={true}
            disableWeekends={true}
          />
          <p style={{ marginLeft: "20px" }}>{endMessage}</p>
        </div>
      </FormField>
      {!!inProgressJourneyTimeout || !!futureJourneyTimeout ? (
        <div className="pause-journey-buttons">
          {!!inProgressJourneyTimeout && (
            <Button type="secondary" onClick={handleResumeClick}>
              Resume Cadences and Tasks
            </Button>
          )}
          {!!futureJourneyTimeout && (
            <Button type="secondary" onClick={handleCancelClick}>
              Cancel Pause
            </Button>
          )}
          <Button onClick={handleUpdateClick} disabled={disableSave}>
            Save
          </Button>
        </div>
      ) : (
        <div className="pause-journey-buttons">
          <Button onClick={handleCreateClick} disabled={!selectedStartDate || !selectedEndDate}>
            Save
          </Button>
        </div>
      )}
      <ConfirmModal
        title={modalTitle}
        body={modalBody}
        handleAction={modalAction === "Resume" ? resumeAction : cancelAction}
        actionName={modalAction}
        isOpen={isModalOpen}
        onClose={closeModal}
      />
    </>
  );
};

export default PauseJourneyForm;
