module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   numeral = require("numeral")
   React = require("react")
   ReactDOM = require("react-dom")
   EverTrue = require("app")
   {div, strong, span} = ReactLibs.DOMFactories
   {createComponent} = require("@evertrue/et-flux")
   InteractionUtils = require("apps/interactions/interaction-utils")
   GlobalAutocompleteSource = require("apps/layout/sources/global-autocomplete-source")
   GlobalAutocompleteStore = require("apps/layout/stores/global-autocomplete-store")
   SimpleContactsTable = require("apps/contact/components/contacts/simple-contacts-table")
   InteractionFeedController = require("apps/interactions/controllers/interaction-feed-controller")
   StringSearchDisplay = require("components/formatting/string-search-display")
   {createFactory} = require("base/new-utils")
   EmptyStateMessage = createFactory(require("components/lists/empty-state-message"))
   Link = createFactory(require("@evertrue/et-components").Link)
   LinkButton = createFactory(require("@evertrue/et-components").LinkButton)

   createComponent "GlobalAutocompleteResultsController",
      propTypes:
         query: ReactLibs.PropTypes.string

      registerStores: ->
         @on GlobalAutocompleteStore, ->
            results: GlobalAutocompleteStore.getResultsByQuery(@props.query)
            loading: GlobalAutocompleteStore.getLoading()

      componentDidMount: ->
         GlobalAutocompleteSource.registerQuery(@props.query)
         GlobalAutocompleteSource.search(@props.query)
         @setTracking()

      componentWillUnmount: ->
         GlobalAutocompleteSource.unregisterQuery(@props.query)

      UNSAFE_componentWillReceiveProps: (newProps) ->
         if @props.query != newProps.query
            GlobalAutocompleteSource.registerQuery(newProps.query)
            GlobalAutocompleteSource.unregisterQuery(@props.query)
            @setState
               results: GlobalAutocompleteStore.getResultsByQuery(newProps.query)
            @setTracking()

      componentDidUpdate: ->
         if @props.query
            @highlightQuery(@props.query)

      setTracking: ->
         EverTrue.track.setReferrer("global_search")

      highlightQuery: (text) ->
         elements = $(ReactDOM.findDOMNode(@)).find(".contact-card--name strong")
         words = _.map text.trim().split(" "), (str) -> str.trim()
         elements.highlight(words, "text-highlight")

      handleViewAll: (type) ->
         GlobalAutocompleteSource.viewAll(type, @props.query, {referrer: "global_search"})

      render: ->
         results_by_type = _.groupBy(@state.results, "type")
         total = _.sum results_by_type.header, (header) -> header.total
         real_results = _.omit(results_by_type, "header")


         div className: "global-autocomplete-results",
            div className: "global-autocomplete-results--details",
               strong null, numeral(total).format("0,0")
               " results in "
               strong null, _.size(real_results)
               if _.size(real_results) == 1 then " category for " else " categories for "
               StringSearchDisplay string: @props.query

            if _.isEmpty(real_results)
               div className: "global-autocomplete-results--empty",
                  EmptyStateMessage
                     text: span null,
                        "No results found for "
                        StringSearchDisplay string: @props.query
                        div className: "global-autocomplete-results--browse-link",
                           Link title: "Return to Browse", href: "/contact", "Return to Browse"
            else
               div className: "global-autocomplete-results--content",
                  _.map real_results, (results, key) =>
                     heading = _.findWhere results_by_type.header, {key: key}

                     div className: "global-autocomplete-results--module", key: key,
                        div className: "global-autocomplete-results--module-header",
                           div className: "global-autocomplete-results--module-label",
                              heading?.label
                           div className: "global-autocomplete-results--meta",
                              "Showing "
                              strong null, "1 - #{Math.min(heading.total, 5)}"
                              " of "
                              strong null, numeral(heading.total).format("0,0")
                              if heading.total == 1 then " result for " else " results for "
                              StringSearchDisplay string: @props.query
                              LinkButton title: "View all", onClick: (=> @handleViewAll(key)), " View All"

                        div className: "global-autocomplete-results--module-body",
                           switch key
                              when "contacts"
                                 SimpleContactsTable contacts: results
                              when "interactions"
                                 InteractionFeedController
                                    interactions: results
                                    highlight: InteractionUtils.getHighlightRegex(@props.query)
