module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   React = require("react")
   ReactDOM = require("react-dom")
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   {div} = ReactLibs.DOMFactories


   createComponent "StickyHeaderModule",
      propTypes:
         maxHeight: ReactLibs.PropTypes.number
         children: ReactLibs.PropTypes.array.isRequired

      getInitialState: ->
         {offset: 0, scroll: 0, active_offset: 0, active_key: undefined}

      UNSAFE_componentWillReceiveProps: (newProps) ->
         # Required for sortinging elements within the module and showing correct header
         new_children_keys = _.map newProps.children, (c) -> c.props.header_key
         old_children_keys = _.map @props.children, (c) -> c.props.header_key
         new_key = _.first(new_children_keys)

         if new_key != @state.active_key
            _.defer =>
               @setState {active_key: new_key} if @depracated_is_mounted

         if new_key != _.first(old_children_keys)
            _.defer =>
               $(ReactDOM.findDOMNode(@)).scrollTop(0)

      handleScroll: ->
         $outer = $(@refs.outer_container)
         @setState
            offset: $outer.offset()?.top
            scroll: $outer.scrollTop()

      handleCollide: (key, offset) ->
         @setState
            active_key: key
            active_offset: offset

      renderHeader: ->
         return unless @state.active_key
         child = _.find @props.children, (child) =>
            child.props.header_key == @state.active_key
         React.cloneElement(child, {}) if child

      renderChildren: ->
         React.Children.map @props.children, (child) =>
            if child.type == "div"
               child
            else
               React.cloneElement child,
                  offset: @state.offset
                  scroll: @state.scroll
                  onCollide: @handleCollide

      render: ->
         div
            ref: "outer_container"
            onScroll: @handleScroll
            className: classNames("sticky-header-module", @props.className)
            style: {maxHeight: @props.maxHeight}

            div className: "sticky--inner", ref: "inner_container", style: {position: "relative"},
               div className: "sticky--header", ref: "header", style: {top: Math.abs(@state.scroll)},
                  @renderHeader()

               div className: "sticky--content", ref: "content",
                  @renderChildren()
