module.exports = do ->
   _ = require("underscore").default
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")
   PaginationUtils = require("mixins/pagination-utils")
   EverTrue = require("app")
   BulkAddSource = require("apps/layout/sources/bulk-add-source").default
   ExportFieldStore = require("apps/export/stores/export-field-store")
   # Add delay for ES flushing so that we
   # don't see the delay for removed contacts
   ES_DELAY = 1000

   # url size grows with number of contact_ids and after 350+, nginx will regect the URL
   CONTACT_ID_LIMIT = 250

   isFiltersEmpty = (filter_params, isFiltersV2) => 
      if _.isEmpty(filter_params)
         return true
      if isFiltersV2 && _.isEmpty(filter_params.filters.blocks[0].rules[0]) && _.isEmpty(filter_params.filters.blocks[1].rules[0]) && filter_params.quick_filters.length == 0 
         return true
      return false

   # TODO: Pagination/Sorting Integration
   createSource "ListContactSource",
      actions:
         loadingListContacts: true
         errorListContacts: true
         fetchedContacts: true
         fetchedSortedContacts: true
         addedContacts: true
         removedContacts: true
         fetchedForContact: true
         exportedListContacts: true
         setEvent: true
         changedSort: true
         changedPage: true
         filterListContacts: true
         resetTablePagination: true
         bulkAddToListInProgress: (list) ->
            @require _.isObject(list), "list should be an object"


      api:
         bulkAddToList: (filter_params, list, count, isFiltersV2) ->
            list_id = _.toNumber(list.value)
            path = if isFiltersV2 then Api.CONTACTS.BULK_ADD_TO_LIST_V2 else Api.CONTACTS.BULK_ADD_TO_LIST
            inDynamicList = window.location.pathname.includes("/dynamic-list");
            if isFiltersEmpty(filter_params, isFiltersV2) && !inDynamicList
               EverTrue.Alert.error("Adding your entire constituent database to a list is not permitted")
               return
            @actions.bulkAddToListInProgress(_.extend({count: count}, list), true)

            path.post
               urlArgs: {list_id}
               data: _.jsonStringify filter_params
               success: (response) ->
                  if count > 500
                     BulkAddSource.clearList()
               error: ->
                  EverTrue.Alert.error("There was an error adding these constituents to the list. Please try again.")

         fetchContacts: (list_id, options={}) ->
            @actions.loadingListContacts(true)

            Api.CONTACTS.SEARCH.post
               params: PaginationUtils.getParams(options.page)
               data: _.jsonStringify
                  must: _.compact _.flatten [
                     {"list_memberships.list_id": {match: list_id}}
                     options.query
                  ]
                  sort: options.sort
               disableAlerts: true
               error: =>
                  @actions.loadingListContacts(false)
                  @actions.errorListContacts()
               success: (resp) =>
                  @actions.loadingListContacts(false)
                  @actions.fetchedContacts(list_id, resp)

         fetchSortedContacts: (list_id, page=1) ->
            @actions.fetchedSortedContacts(list_id, page)

         addContacts: (list_id, contact_ids, options={}) ->
            @actions.loadingListContacts(true)

            completed_contact_ids = []

            addSomeContacts = (remaining_contact_ids) =>
               active_ids = _.first(remaining_contact_ids, CONTACT_ID_LIMIT)
               pending_ids = _.rest(remaining_contact_ids, CONTACT_ID_LIMIT)

               Api.CONTACTS.LIST_CONTACTS.post
                  urlArgs: {id: list_id}
                  urlExtend: "/bulk"
                  params: {contact_id: active_ids}
                  success: (resp) =>
                     completed_contact_ids = completed_contact_ids.concat(active_ids)
                     if _.notEmpty(pending_ids)
                        addSomeContacts(pending_ids)
                     else
                        _.wait ES_DELAY, =>
                           @actions.loadingListContacts(false)
                           @actions.addedContacts(list_id, contact_ids, options)
                  error: =>
                     Raven.captureMessage "Added List Contact(s) Error",
                        extra: {list_id: list_id, contact_ids: contact_ids}
                     _.wait ES_DELAY, =>
                        @actions.loadingListContacts(false)
                        @actions.addedContacts(list_id, completed_contact_ids, {disableAlerts: true})

            addSomeContacts(contact_ids)

         removeContacts: (list_id, contact_ids, options={}) ->
            @actions.loadingListContacts(true)

            Api.CONTACTS.LIST_CONTACTS.delete
               urlArgs: {id: list_id}
               urlExtend: "/bulk"
               params: {contact_id: contact_ids}
               error_message: "Some constituents did not get removed. Please try again."
               error: =>
                  Raven.captureMessage "Remove List Contact(s) Error",
                     extra: {list_id: list_id, contact_ids: contact_ids}
                  _.delay =>
                     @actions.loadingListContacts(false)
                     @actions.removedContacts(list_id, [], {disableAlerts: true})
                  , ES_DELAY
               success: (resp) =>
                  _.delay =>
                     @actions.loadingListContacts(false)
                     @actions.removedContacts(list_id, contact_ids, options)
                  , ES_DELAY

         export: (list_id, property_ids) ->
            exportParams = ExportFieldStore.getExportParams(property_ids);

            Api.CONTACTS.EXPORTS.post
               disableAlerts: true
               params: exportParams
               data: _.jsonStringify
                  list_id: list_id
                  search: must: [{"list_memberships.list_id": {match: list_id}}]
               success: =>
                  @actions.exportedListContacts(list_id)

         setEvent: (key) ->
            @actions.setEvent(key)
         
         changeSort: (list_id, sort_key, reverse, silent) ->
            @actions.changedSort(list_id, sort_key, reverse, silent)

         changePage: (list_id, page) ->
            @actions.changedPage(list_id, page)


         filter: (list_id, query) ->
            @actions.filterListContacts(list_id, query)

         resetTablePagination: ()-> 
            @actions.resetTablePagination()
