module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default

   Parameter = (type, Properties) ->
      parameter_object = {}
      param_type = type
      parameter_object[param_type] = []

      all_properties = Properties
      properties = []
      propertiesWithParents = []
      parentPropMap = {}

      setProperties = ->
         properties = _.filter all_properties, (Prop) -> Prop if !Prop?.parent?()
         propertiesWithParents = _.filter all_properties, (Prop) -> Prop if Prop?.parent?()

         _.each propertiesWithParents, (Prop) ->
            parentPropMap[Prop.parent()] ?= []
            parentPropMap[Prop.parent()].push Prop

      setProperties()

      {
         toJSON: (options={}) ->
            if param_type is "like"
               parameter_object[param_type] = properties
               return parameter_object

            _.each properties, (prop) ->
               parameter_object[param_type].push prop.toJSON()

            if options.exclude_parent
               _.each propertiesWithParents, (prop) ->
                  parameter_object[param_type].push prop.toJSON()
            else
               _.each parentPropMap, (props, parent) ->
                  if props.length > 1
                     property_wrapper = {}
                     _.each props, (prop) ->
                        $.extend true, property_wrapper, prop.toJSONwithParent()
                     parameter_object[param_type].push property_wrapper
                  else
                     prop = _.first(props)
                     parameter_object[param_type].push prop.toJSON()

            parameter_object

         addProperties: (Props) ->
            _.each Props, (prop) -> all_properties.push(prop)
            setProperties()

         properties: -> all_properties
         propertyKeys: -> _.flatten _.map all_properties, (prop) -> prop.keys()
         type: -> type
      }

   return Parameter
