module.exports = do ->
   _ = require("underscore").default

   REACTIONS = [
      {value: "comment", label: "Comment", icon: "/images/fb-reactions-comment.png"}
      {value: "like", label: "Like", icon: "/images/fb-reactions-like.png"}
      {value: "love", label: "Love", icon: "/images/fb-reactions-love.png"}
      {value: "wow", label: "Wow", icon: "/images/fb-reactions-wow.png"}
      {value: "sad", label: "Sad", icon: "/images/fb-reactions-sad.png"}
      {value: "haha", label: "Haha", icon: "/images/fb-reactions-haha.png"}
      {value: "angry", label: "Angry", icon: "/images/fb-reactions-angry.png"}
      {value: "thankful", label: "Thankful", icon: "/images/fb-reactions-thankful.png"}
   ]

   FacebookReactions =
      findReaction: (reaction_key) ->
         _.findWhere REACTIONS, {value: reaction_key}

      getAll: ->
         _.clone REACTIONS

      getAllValues: ->
         _.map REACTIONS, (reaction) -> _.clone(reaction.value)

      getIconFor: (reaction_key) ->
         reaction = @findReaction(reaction_key)
         if reaction
            reaction.icon
         else
            @findReaction("comment").icon

      getLabelFor: (reaction_key) ->
         @findReaction(reaction_key)?.label
