import { useContext } from "react";
import { Formik } from "formik";
import { TaskFormSchema } from "apps/tasks/utils/task-schema";
import { buildTaskDefaults, setTaskPayload } from "apps/tasks/utils/utils";
import { ModalBody, ModalHeaderWithActions, AdvancedCombobox } from "@evertrue/et-components";
import PropTypes from "prop-types";
import DatePickerDropdown from "components/calendar/date-picker-dropdown";
import FormField from "components/forms/form-field";
import TaskContact from "./task-contact";
import useTasks from "apps/tasks/hooks/use-tasks";
import CharacterCounter from "apps/interactions/components/character-counter";
import { TasksContext } from "apps/tasks/hooks/tasks-context";
import AddUser from "apps/search/components/add-user";
import { useIdentity } from "base/identity-resolver";
import { useGate } from "components/is-gated";

const TaskForm = ({
  contact = {},
  taskObj = {},
  handleClose = () => {},
  optionalStateManagementFunction = () => {},
  openAddToCalendarModal = () => {},
  setTaskFormData = () => {},
}) => {
  const { user } = useIdentity();
  const { createTask, updateTask, taskActionTypes } = useTasks();
  const { getAndSetTasks = () => {}, taskFilters } = useContext(TasksContext);
  const [hasCalendar] = useGate("unreleased_feature");
  const { id, contact_journey_id, taskDefaults } = buildTaskDefaults(taskObj, user, contact);
  const isNotTaskOwnerOrCreator =
    taskObj.id && taskObj.owner_user_id !== user.id && taskObj.creator_user_id !== user.id;

  const taskIsPaused = taskObj.status === "PAUSED";

  const disableEdit = taskIsPaused || isNotTaskOwnerOrCreator;

  const handleSubmit = async (values) => {
    const payload = setTaskPayload(values);
    handleClose();
    setTaskFormData(values);
    if (id) {
      const task = await updateTask(payload, taskObj);
      optionalStateManagementFunction("addTask", { task });
    } else {
      const task = await createTask(payload);
      optionalStateManagementFunction("addTask", { task });
    }
    if (hasCalendar) {
      openAddToCalendarModal();
    }
    getAndSetTasks(taskFilters);
  };

  const formTitle = id ? "Edit Task" : "Add Task";

  return (
    <Formik
      initialValues={taskDefaults}
      validationSchema={TaskFormSchema}
      onSubmit={(values) => handleSubmit(values)}
      validationOnMount={true}
    >
      {({ handleChange, handleSubmit, values, isValid }) => (
        <>
          <ModalHeaderWithActions
            title={formTitle}
            closeModal={handleClose}
            onSubmit={handleSubmit}
            disableSubmit={!isValid}
          />
          <ModalBody scrollable={false}>
            <TaskContact
              targetContact={values.targetContact}
              setTargetContact={handleChange("targetContact")}
              disableDelete={!!contact_journey_id || disableEdit}
            />
            <FormField required label="Task Name" inlineLabel={false}>
              <input
                type="text"
                value={values.title}
                placeholder={`Enter the Task Name...`}
                onChange={handleChange("title")}
                disabled={!!contact_journey_id || disableEdit}
              />
              <CharacterCounter limit={120} count={values.title.length} />
            </FormField>
            <FormField label="Description" inlineLabel={false}>
              <textarea
                value={values.description}
                placeholder="Add Details..."
                onChange={handleChange("description")}
                disabled={!!contact_journey_id || disableEdit}
              />
              <CharacterCounter limit={250} count={values.description.length} />
            </FormField>
            <div className="task-options">
              <FormField required={!contact_journey_id} label="Action Type" inlineLabel={false}>
                <AdvancedCombobox
                  options={taskActionTypes}
                  onChange={handleChange("actionType")}
                  value={values.actionType}
                  placeholder="Select Type..."
                  disabled={!!contact_journey_id || disableEdit}
                />
              </FormField>
              <FormField label="Assignee" inlineLabel={false}>
                <AddUser
                  handleOnChange={handleChange("owner")}
                  value={values.owner}
                  disabled={!!contact_journey_id || disableEdit}
                />
              </FormField>
            </div>
            <FormField label="Due Date" inlineLabel={false}>
              <DatePickerDropdown
                date={values.due_date}
                onDayClick={handleChange("due_date")}
                triggerClassName={"task-calendar"}
                chevronClassName={"date-picker-chevron"}
                allowClear
                disabled={disableEdit}
              />
            </FormField>
          </ModalBody>
        </>
      )}
    </Formik>
  );
};

TaskForm.propTypes = {
  contact: PropTypes.object,
  handleClose: PropTypes.func,
  taskObj: PropTypes.object,
  optionalStateManagementFunction: PropTypes.func,
  openAddToCalendarModal: PropTypes.func,
  setTaskFormData: PropTypes.func,
};

export default TaskForm;
