module.exports = do ->
   _ = require("underscore").default
   str = require("underscore.string")
   React = require("react")
   moment = require("moment")
   Decorator = require("clientDecorator")
   {createComponent} = require("@evertrue/et-flux")
   EverTrue = require("app")
   MeetingsSource = require("apps/my-trips/sources/meetings-source")
   AllUsersStore = require("apps/users/stores/all-users-store").default
   ContactTagList = require("apps/contact/components/contact-tag-list")
   {createFactory} = require("base/new-utils")
   CalendarPickerDropdown = createFactory require("components/controls/calendars/calendar-picker-dropdown")
   AdvancedCombobox = createFactory(require("@evertrue/et-components").AdvancedCombobox)
   FormField =  createFactory(require("components/forms/form-field"))
   TimeRange = createFactory(require("components/forms/time-range").default)
   CalendarContainer = createFactory(require("apps/my-trips/components/cronofy-calendar-container").default)
   LinkButton = createFactory(require("@evertrue/et-components").LinkButton)
   Loading = require("components/elements/loading")
   {div, input, textarea} = ReactLibs.DOMFactories
   Modal = createFactory require("components/modals/modal")
   ModalConfirm = createFactory(require("@evertrue/et-components").ModalConfirm)

   createComponent "VirtualMeetingForm",
      propTypes:
         tripId: ReactLibs.PropTypes.number
         meeting: ReactLibs.PropTypes.object
         selected_contacts: ReactLibs.PropTypes.any
         solicitors: ReactLibs.PropTypes.array
         headerVerb: ReactLibs.PropTypes.string
         title: ReactLibs.PropTypes.string
         current_user_platform_data: ReactLibs.PropTypes.any

      getInitialState: ->
         meeting: {}
         utc_offset: undefined

      UNSAFE_componentWillMount: ->
         @setMeeting(@props.meeting)

      componentDidMount: ->
         @titleInput.focus()

      registerStores: ->
         @on AllUsersStore, ->
            users: _.map AllUsersStore.getAllGivingTreeUsers(), (user) ->
               {value: user.id, label: user.name}

      setMeeting: (meeting) ->
         meeting = _.cloneData(meeting || {})
         meeting.start_at ?= moment(@state.meeting.start_at).hour(9).minute(0).valueOf()
         meeting.end_at ?= moment(meeting.start_at).add("30", "minute").valueOf()
         meeting = Decorator.MyTrips.parseMeeting(meeting)
         meeting_options = @setMeetingPlatformOption()

         contacts = _.map @props.selected_contacts, (contact) ->
            Decorator.Contacts.parse(contact)

         link_type = if !_.isEmpty meeting.meeting_link_type then _.findWhere(meeting_options, {value: meeting.meeting_link_type}) else {}

         @setState meeting: _.extend meeting,
            name: meeting.name || @props.title
            date: meeting.start_datetime.date_string
            description: meeting.description
            contacts: contacts
            meeting_link_type: link_type
            meeting_link: meeting.meeting_link
            solicitor_user_ids: meeting.solicitor_user_ids
            solicitors: @props.solicitors
            time_start: meeting.start_datetime.time_string
            time_end: meeting.end_datetime.time_string
            anchor: meeting.anchor

      setMeetingPlatformOption: ->
         meeting_options =
            _.map @props.current_user_platform_data, (data) ->
               platform = data.platform.toUpperCase()
               platform_label = str.capitalize data.platform
               {label: "Create #{platform_label} Meeting", value: platform}
         meeting_options.push {label: "Provide Manual Link", value: "MANUAL"}
         meeting_options

      handleSubmit: ->
         meeting = _.cloneData @state.meeting
         {date} = meeting

         start = moment("#{date} #{meeting.time_start}", "YYYY-MM-DD hh:mm A")
         end = moment("#{date} #{meeting.time_end}", "YYYY-MM-DD hh:mm A")

         meeting_length = end.diff(start, "minutes", true)

         url = meeting.meeting_link
         if meeting.meeting_link and !/^https?:\/\//i.test(meeting.meeting_link)
            url = "https://"+meeting.meeting_link

         return if meeting.meeting_link_type.value == "ZOOM"
            customer_type = _.findWhere(@props.current_user_platform_data, {platform: "zoom"}).user_type
            if customer_type == "BASIC" && meeting_length > 40
               return EverTrue.Alert.error("Zoom Basic users cannot schedule a meeting longer than 40 minutes.")

         data =
            type: "virtual"
            name: meeting.name
            description: meeting.description
            meeting_link_type: meeting.meeting_link_type.value
            meeting_link: url || ""
            contact_ids: _.pluck(meeting.contacts, "id")
            solicitor_user_ids: _.pluck(meeting.solicitors, "id")
            start_time: start.format("HH:mm")
            end_time: end.format("HH:mm")
            start_date: date
            end_date: date

         data.id = meeting.id if meeting.id
         MeetingsSource.saveMeeting(data)
         @props.remove()

      handleChange: (prop, value) ->
         meeting = _.cloneData(@state.meeting || {})
         meeting[prop] = value
         @setState {meeting: meeting}

      handleChangeMeetingType: (value) ->
         meeting = _.cloneData(@state.meeting || {})
         meeting["meeting_link_type"] = value
         meeting["meeting_link"] = ""
         @setState {meeting: meeting}

      handleTimeChange: (value) ->
         meeting = _.cloneData(@state.meeting || {})
         _.extend meeting,
            time_start: value.gte
            time_end: value.lte
         @setState {meeting: meeting}

      handleConnectedCalClick: ->
         EverTrue.Navigator("/trips/calendar", true)
         @props.remove()

      renderHelperText: ->
         CalendarContainer
            render: ({hasCalendars, isLoading}) =>
               if isLoading then Loading()
               else if hasCalendars
                  div null,
                     "Changes made to this meeting will be updated in your"
                     LinkButton onClick: @handleConnectedCalClick, title: "connected calendars", " connected calendars"
               else null

      render: ->
         meeting = @state.meeting || {}
         trip = @state.trip
         valid_time = Decorator.MyTrips.hasValidTimeRange(meeting.time_start, meeting.time_end)

         link_type = if _.isEmpty @props.current_user_platform_data then {label: "Provide Manual Link", value: "MANUAL"} else meeting.meeting_link_type

         Modal
            width: 575
            keepOpen: true
            remove: @props.remove
            onUnmount: @props.onUnmount

            ModalConfirm
               className: "meeting-form"
               header: "Virtual Meeting"
               disableSave: !meeting.name || !valid_time
               hasMargin: true
               onSubmit: @handleSubmit
               onCancel: @props.remove

               FormField label: "Title*",
                     input
                        ref: ((e) => @titleInput = e)
                        type: "text"
                        placeholder: "Enter meeting title..."
                        value: meeting.name
                        onChange: (e) =>
                           @handleChange("name", e.currentTarget.value)

               FormField label: "Time*", helpKey: "my_meeting_time",
                     CalendarPickerDropdown
                        className: "meeting-form--calendar"
                        value: meeting.date || Decorator.MyTrips.getDate()
                        minDate: if trip then Decorator.MyTrips.getDate(trip.start_at)
                        hideClear: true
                        onChange: (date) =>
                           @handleChange("date", date)

                     TimeRange
                        className: if !valid_time then "meeting-form--invalid-time"
                        autoRange: true
                        value: {gte: meeting.time_start, lte: meeting.time_end}
                        onChange: @handleTimeChange

               FormField label: "Meeting Platform",
                     AdvancedCombobox
                        placeholder: "Select a Platform..."
                        options: @setMeetingPlatformOption()
                        value: link_type
                        onChange: (platform) =>
                           @handleChangeMeetingType(platform)


               if meeting.meeting_link_type.value == "MANUAL" || _.isEmpty @props.current_user_platform_data
                  FormField label: "Meeting Link", helpKey: "virtual_meeting_url",
                     input
                        type: "text"
                        value: meeting.meeting_link
                        placeholder: "www.example.com"
                        onChange: (e) =>
                           @handleChange("meeting_link", e.target.value)

               FormField label: "Constituent(s)",
                     ContactTagList
                        focusOnMount: false
                        presetResults: _.map meeting.contacts, (contact) ->
                           Decorator.Contacts.getFullName(contact)
                        selected: _.map meeting.contacts, (contact) ->
                           _.extend {name: Decorator.Contacts.getFullName(contact)}, contact
                        onSelect: (contacts) =>
                           @handleChange("contacts", contacts)

               FormField label: "Additional Solicitors",
                     AdvancedCombobox
                        placeholder: "Select Users..."
                        multiple: true
                        searchable: true
                        value: _.map meeting.solicitors, (solicitor) ->
                           _.extend {}, solicitor,
                              value: solicitor.id
                              label: solicitor.name
                        options: _.sortBy @state.users, "label"
                        onChange: (users) =>
                           solicitors = _.map users, (user) ->
                              _.extend {id: user.value, name: user.label}, user
                           @handleChange("solicitors", solicitors)

               FormField label: "Description",
                     textarea
                        value: meeting.description || ""
                        placeholder: "Describe your meeting here (Optional)..."
                        onChange: (e) =>
                           @handleChange("description", e.currentTarget.value)

               div className: "modal-confirm--helper-text",
                  @renderHelperText()
