import { Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const prop_types = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  validateEmail: PropTypes.bool,
  hideErrorMsg: PropTypes.bool,
  invalidMsg: PropTypes.string,
  className: PropTypes.string,
  valid: PropTypes.func,
};

const EmailInput = ({
  onChange,
  value,
  validateEmail,
  hideErrorMsg,
  invalidMsg = "Enter a valid email address	",
  className,
  valid,
  ...rest
}) => {
  const is_valid = valid(value);
  return (
    <Fragment>
      <input
        {...rest}
        type="text"
        className={classNames("email-input", className, { "has-error": !valid })}
        onChange={({ target: { value } }) => onChange(value)}
        value={value}
      />
      {!is_valid && !hideErrorMsg && <div className="email-input--error-message">{invalidMsg}</div>}
    </Fragment>
  );
};

EmailInput.propTypes = prop_types;

export default EmailInput;
