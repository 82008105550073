import { useEffect, forwardRef } from "react";
import PropTypes from "prop-types";
import { Button } from "@evertrue/et-components";

const LoadMoreButton = forwardRef(({ loadMore, loading, displayCount, limit, total }, newItemRef) => {
  /**
   * When loading more, move the keyboard focus to the first item in the newly added results
   */
  useEffect(() => {
    if (displayCount > limit) {
      newItemRef.current && newItemRef.current.focus();
    }
  }, [displayCount, limit, newItemRef]);

  if (displayCount < total) {
    return (
      <div className="load-more">
        <Button type="secondary" onClick={loadMore} disabled={loading}>
          {loading ? "Loading..." : "Load More"}
        </Button>
      </div>
    );
  }

  return null;
});

LoadMoreButton.propTypes = {
  loadMore: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  displayCount: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

export default LoadMoreButton;
