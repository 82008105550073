module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   Api = require("entities/helpers/api")
   {createSource} = require("@evertrue/et-flux")
   ExportFieldStore = require("apps/export/stores/export-field-store")

   PORTFOLIO_SLIDES_KEY = "GT_my_portfolio_slides"

   EXCLUDE_TYPES = ["Note", "EverTrue Comment"]

   createSource "MyPortfolioSource",
      actions:
         resetStore: true
         exportedProspects: true
         clearProspects: true
         clearMedianAges: true
         changeUser: (user) ->
            @require _.isObject(user), "user should be an object"
         startExport: (ids) ->
            @require _.isArray(ids), "ids should be an array"
         mapLoading: (bool) ->
            @require _.isBoolean(bool), "bool should be a boolean"
         prospectStatusLoading: (status, bool, type) ->
            @require _.isString(status), "status should be a string"
            @require _.isBoolean(bool), "bool should be a boolean"
            @require _.isString(type), "type should be a string"
         fetchedLastContactDates: (items) ->
            @require _.isArray(items), "items should be an array"
         fetchedLastMoveDates: (items) ->
            @require _.isArray(items), "items should be an array"
         fetchedProspectsByStatus: (status, data) ->
            @require _.isString(status), "status should be a string"
            @require _.isObject(data) or _.isEmpty(data), "data should be empty or an object"
         fetchedMedianAgeByStatus: (status, time) ->
            @require _.isString(status), "status should be a string"
            @require _.isNumber(time), "time should be a number"
         fetchedTotalMedianAge: (time) ->
            @require _.isNumber(time), "time should be a number"
         fetchedTotalProspectCount: (count) ->
            @require _.isNumber(count), "count should be a number"
         loadingTotalCount: (bool) ->
            @require _.isBoolean(bool), "bool should be a boolean"
         infiniteLoadByStatus: (status, offset) ->
            @require _.isString(status), "status should be a string"
            @require _.isNumber(offset), "offset should be an number"
         startFetchForMappedProspects: (data) ->
            @require _.isObject(data) or _.isEmpty(data), "data should be empty or an object"
         fetchedMapProspects: (data) ->
            @require _.isObject(data) or _.isEmpty(data), "data should be empty or an object"
         paginateMappedProspects: (offset) ->
            @require _.isNumber(offset), "offset should be an number"
         setAddressTypes: (address_types) ->
            @require _.isArray(address_types), "address types should be an array"
         updateProspectSort: (sort) ->
            @require _.isObject(sort), "sort should be an object"
         updateMapSort: (sort) ->
            @require _.isObject(sort), "sort should be an object"
         updateHasAssignees: (bool) ->
            @require _.isBoolean(bool), "bool should be a boolean"
         changeSortParam: (sort_param) ->
            @require _.isObject(sort_param), "sort_param should be an object"
         fetchedViewedSlides: (data) ->
            @require _.isObject(data) || _.isEmpty(data), "data should be empty or an object"
         changeStatus: true

      api:
         resetStore: -> @actions.resetStore()

         startExport: (ids) ->
            @actions.startExport(ids)

         export: (property_ids, query) ->
            data = {
               name: "my_prospects"
               search: query
            }

            exportParams = ExportFieldStore.getExportParams(property_ids);

            Api.CONTACTS.EXPORTS.post
               disableAlerts: true
               params: exportParams
               data: _.jsonStringify(data)
               success: =>
                  @actions.exportedProspects()

         changeUser: (user) ->
            @actions.clearProspects()
            @actions.clearMedianAges()
            @actions.changeUser(user)

         changeSortParam: (sort_param) ->
            @actions.clearProspects()
            @actions.changeSortParam(sort_param)

         fetchLastContactDate: (ids) ->
            return unless ids?.length > 0
            data =
               ids: ids
               exclude: EXCLUDE_TYPES

            Api.SEARCH.LAST_CONTACT_DATE.post
               data: _.jsonStringify(data)
               success: (resp) =>
                  if resp?.total > 0
                     @actions.fetchedLastContactDates(resp?.items)

         fetchLastMoveDate: (query, status) ->
            # Note: This endpoint has a default limit of 500, so we're not going to use the offset
            # We are assuming no one has more than 500 contacts in a status so we won't need to
            # infinite load this query

            Api.SEARCH.LAST_ACTIVITY_DATE_BY_CONTACT.post
               data: _.jsonStringify(query)
               success: (resp) =>
                  if resp?.total > 0
                     @actions.fetchedLastMoveDates(resp?.items, status)

         fetchProspectsByStatus: (query, status, sort_param, offset) ->
            loading_type = if (!offset or offset == 0) then "initial_loading" else "infinite_loading"
            @actions.prospectStatusLoading(status, true, loading_type)

            Api.CONTACTS.SEARCH.post
               data: _.jsonStringify(query)
               params:
                  offset: offset || 0
               success: (resp) =>
                  @actions.prospectStatusLoading(status, false, loading_type)
                  @actions.updateProspectSort(sort_param) if sort_param
                  @actions.fetchedProspectsByStatus(status, resp)

         infiniteLoadByStatus: (status, offset) ->
            @actions.infiniteLoadByStatus(status, offset)

         paginateMappedProspects: (offset) ->
            @actions.paginateMappedProspects(offset)

         startFetchForMappedProspects: (data) ->
            @actions.startFetchForMappedProspects(data)

         fetchMedianAgeByStatus: (query, status) ->
            Api.SEARCH.STATS.post
               data: _.jsonStringify(query)
               success: (resp) =>
                  if resp.total > 0
                     median = resp?.stats?._score?.percentiles?["50.0"]
                     @actions.fetchedMedianAgeByStatus(status, median) if _.isFinite(median)

         fetchMappedProspects: (query, offset, bounds, sort_param) ->
            @actions.mapLoading(true)
            Api.CONTACTS.SEARCH.post
               data: _.jsonStringify(query)
               params:
                  offset: offset || 0
               success: (resp) =>
                  page = (resp.offset / resp.limit) + 1
                  page = _.withinRange(page, 1, Math.ceil(resp.total/resp.limit))
                  resp.page = page
                  resp.bounds = bounds
                  @actions.fetchedMapProspects(resp)
                  @actions.updateMapSort(sort_param) if sort_param
                  @actions.mapLoading(false)
               error: =>
                  @actions.mapLoading(false)

         fetchTotalProspectCount: (query) ->
            @actions.loadingTotalCount(true)
            Api.CONTACTS.SEARCH.post
               data: _.jsonStringify(query)
               success: (resp) =>
                  @actions.loadingTotalCount(false)
                  total = resp.total
                  @actions.fetchedTotalProspectCount(total)
               error: =>
                  @actions.loadingTotalCount(true)

         setAddressTypes: (address_types) ->
            @actions.setAddressTypes(address_types)

         fetchSingleAssignee: ->
            query = must: [{"assignees.user_id": {match: EverTrue.store.user.get("id")}}]
            Api.CONTACTS.SEARCH.post
               data: _.jsonStringify(query)
               params: {limit: 1}
               success: (resp) =>
                  has_assignees = if resp?.items?.length > 0 then true else false
                  @actions.updateHasAssignees(has_assignees)
               error: =>
                  @actions.updateHasAssignees(false)

         fetchViewSlides: ->
            Api.DNA.SETTING.get
               urlArgs: {setting_key: PORTFOLIO_SLIDES_KEY}
               disableAlerts: true
               params:
                  user_id: "me"
                  oid: null
               success: (resp) =>
                  @actions.fetchedViewedSlides(resp.settings?[PORTFOLIO_SLIDES_KEY]?.value)

         slidesViewed: (data)->
            @actions.fetchedViewedSlides(data)
            Api.DNA.SETTING.put
               urlArgs: {setting_key: PORTFOLIO_SLIDES_KEY}
               disableAlerts: true
               params:
                  user_id: "me"
                  oid: null
               data: _.jsonStringify({value: data})
               error: ->
                  Raven?.captureMessage("My Portfolio slides settings Didn't Save", extra: data)
