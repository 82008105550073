import { Fragment } from "react";
import PropTypes from "prop-types";
import { ModalForm, Icon } from "@evertrue/et-components";
import Api from "entities/helpers/api";
import _ from "underscore";
import BulkAddSource from "apps/layout/sources/bulk-add-source";
import {
  FORMATTED_CONFIRM_STRING,
  CONFIRM_STRING,
  DEFAULT_GIVING_DATA_STRING,
  SELF_SELECTION_STRING,
} from "apps/volunteers/constants";

const propTypes = {
  selected: PropTypes.object,
  pools: PropTypes.array,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};

const IconWrapper = (
  { children } // eslint-disable-line
) => (
  <li className="confirm-reset-pools-modal--icon-wrapper">
    <Icon icon="check" className="confirm-reset-pools-modal--check-icon" />
    {children}
  </li>
);

const ConfirmResetPoolsModal = ({ selected, pools, onSubmit, onClose, ...rest }) => (
  <ModalForm
    {...rest}
    type="destroy"
    className="confirm-reset-pools"
    onClose={onClose}
    width={600}
    header="Confirm reset"
    data={{ confirm_string: "" }}
    disableFunc={(state) => state.confirm_string.toLowerCase().trim() !== CONFIRM_STRING}
    handleSubmit={() => {
      const pool_ids = _.pluck(pools, "id");
      // Total giving data (default)'s value is null, so clear all out if that one is selected
      const giving_category = _.isNull(selected.category_obj.value)
        ? {
            clear: true,
            label: null,
          }
        : {
            label: selected.category_obj.value,
            clear: false,
          };
      const stage_group = _.isEmpty(selected.selected_stage_group)
        ? {
            clear: true,
          }
        : {
            stage_group_id: selected.selected_stage_group.id,
            clear: false,
          };

      const data = {
        pool_ids,
        ...(selected.unassign && { unassign_prospects: true }),
        ...(selected.giving_category && {
          giving_category,
        }),
        ...(selected.stage && {
          stage_group,
          ...(selected.selected_stage.stage && {
            stage: {
              stage: selected.selected_stage.stage,
              clear: false,
            },
          }),
        }),
        ...(selected.self_selection && {
          selection_mode: selected.selection_mode,
        }),
      };
      BulkAddSource.bulkVMSRollover({ pool_ids, progress: 0 });
      Api.VOLUNTEER.BULK_ROLLOVER.put({
        data: _.jsonStringify(data),
      }).catch((xhr) => {
        if (xhr.status !== 202) {
          BulkAddSource.bulkVMSRollover({});
        }
      });
    }}
    submitLabel="Confirm"
    render={({ state, setState }) => {
      const {
        stage,
        selected_stage_group,
        selected_stage,
        giving_category,
        category_obj,
        unassign,
        self_selection,
        selection_mode,
      } = selected;
      const pools_text = `pool${pools.length > 1 ? "s" : ""}`;
      const unassign_text = unassign && (
        <IconWrapper>
          <strong>Remove All Assignments</strong>
          <div className="confirm-reset-pools-modal--sub-label">(unassign all)</div>
        </IconWrapper>
      );
      const stage_text = stage && (
        <Fragment>
          <IconWrapper>
            <strong>Update Stage to:</strong>
            <div className="confirm-reset-pools-modal--sub-label">
              {selected_stage_group.name || "No Volunteer Stages"}
            </div>
          </IconWrapper>
          {!!selected_stage.stage && (
            <IconWrapper>
              <strong> Update assignments stages to:</strong>
              <div className="confirm-reset-pools-modal--sub-label">{selected_stage.stage}</div>
            </IconWrapper>
          )}
        </Fragment>
      );

      const giving_category_text = giving_category && (
        <IconWrapper>
          <strong>Update Giving Category to:</strong>
          <div className="confirm-reset-pools-modal--sub-label">
            {_.isNull(category_obj.value) ? DEFAULT_GIVING_DATA_STRING : category_obj.label}
          </div>
        </IconWrapper>
      );

      const self_selection_text = self_selection && (
        <IconWrapper>
          <strong>Update Self Selection to:</strong>
          <div className="confirm-reset-pools-modal--sub-label">
            {selection_mode === SELF_SELECTION_STRING ? "Enabled" : "Disabled"}
          </div>
        </IconWrapper>
      );
      return (
        <Fragment>
          <div className="confirm-reset-pools-modal">
            <div className="confirm-reset-pools-modal--top-container">
              <div className="confirm-reset-pools-modal--pool-list">
                <strong>The following {pools_text} will be affected:</strong>
                <ul className="confirm-reset-pools-modal--list">
                  {pools.map((pool) => (
                    <li key={pool.id}> {pool.name}</li>
                  ))}
                </ul>
              </div>
              <div className="confirm-reset-pools-modal--actions">
                <strong> Selected Action(s):</strong>
                <ul className="confirm-reset-pools-modal--list">
                  {unassign_text}
                  {stage_text}
                  {giving_category_text}
                  {self_selection_text}
                </ul>
              </div>
            </div>
          </div>
          <div className="confirm-reset-pools-modal--confirm-section">
            You must agree to confirm that this action will be completed. Please type {`"${FORMATTED_CONFIRM_STRING}" `}
            to complete these actions. ({pools.length} {pools_text} will be affected by this update)
            <div className="confirm-reset-pools-modal--confirm-string">Note: This cannot be undone.</div>
            <input
              type="text"
              placeholder={FORMATTED_CONFIRM_STRING}
              className="confirm-reset-pools-modal--input"
              value={state.confirm_string}
              onChange={({ target: { value: confirm_string } }) => setState({ confirm_string })}
            />
          </div>
        </Fragment>
      );
    }}
  />
);

ConfirmResetPoolsModal.propTypes = propTypes;

export default ConfirmResetPoolsModal;
