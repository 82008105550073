module.exports = do ->
   _ = require("underscore").default
   FacetSource = require("apps/filters/sources/facet-source")
   SessionSource = require("base/session/session-source")
   {createStore} = require("@evertrue/et-flux")
   ProspectColumnSource = require("apps/major-gifts/sources/prospect-column-source")

   PROSPECT_STATUS = "assignees.status.untouched"
   _groups = {prospect_status: "Prospect Status"}

   createStore "ProspectColumnStore",
      getInitialState: ->
         applied_columns: []
         all_columns: []

      firstListenerDidMount: ->
         @refresh()

      refresh: ->
         ProspectColumnSource.fetch()
         FacetSource.fetch([PROSPECT_STATUS])

      registerActions: ->
         @on ProspectColumnSource.actions.fetchedColumns, @respondToChange
         @on ProspectColumnSource.actions.updatedColumns, @respondToChange

         @on FacetSource.actions.fetchedFacets, @respondToFetchedFacets

      respondToFetchedFacets: (data) ->
         prospect_array = data?.facets?[PROSPECT_STATUS]
         if prospect_array
            new_columns = _.map prospect_array, (prospect) ->
               {label: prospect.value, key: prospect.value, group: _groups.prospect_status}
            # Add default "No Status" column
            new_columns.push {label: "No Status", key: "No Status", group: _groups.prospect_status}
            @setState {all_columns: new_columns}

      respondToChange: (columns) ->
         @setState {applied_columns: columns}

      api:
         getAvailableColumns: ->
            applied = @getState("applied_columns")
            _.compact _.map @getState("all_columns"), (col) ->
               if !_.findWhere applied, {key: col.key}
                  _.extend {}, col, {value: col.key}

         getActiveColumns: ->
            _.map @getState("applied_columns"), (col) ->
               _.extend {}, col, {value: col.key}

         getAllColumns: ->
            _.map @getState("all_columns"), (col) ->
               _.extend {}, col, {value: col.key}


