import PropTypes from "prop-types";
import numeral from "numeral";
import classNames from "classnames";
import _ from "underscore";
import { Checkbox } from "@evertrue/et-components";
import FixedTable from "components/tables/fixed-table";
import StageResolver from "apps/assignments/components/stage-resolver";
import { SELF_SELECTION_STRING, DEFAULT_GIVING_DATA_STRING } from "apps/volunteers/constants";
import { Link } from "@evertrue/et-components";

const propTypes = {
  pools: PropTypes.array,
  footer: PropTypes.node,
  // not sure why eslint isn't working here disabling for now
  selectedPools: PropTypes.array, // eslint-disable-line
  handleSelectPool: PropTypes.func, // eslint-disable-line
};

const defaultProps = {
  selectedPools: [],
  pools: [],
};

const VolunteerPoolsTableController = (props) => {
  return (
    <div className="volunteer-pools-table-controller">
      <FixedTable
        data={props.pools}
        footer={props.footer}
        config={[
          {
            label: "Pool Name",
            key: "pools",
            sticky: true,
            width: 200,
            row: (pool) => (
              <div className="volunteer-pools-table-controller--checkbox-cell">
                <Checkbox
                  label={`Select ${pool.name}`}
                  checked={Boolean(_.findWhere(props.selectedPools, { id: pool.id }))}
                  onChange={(bool) => {
                    props.handleSelectPool(pool, bool);
                  }}
                />
                <Link
                  className={"ellipsis-overflow"}
                  title={`Link to pool ${pool.name}`}
                  href={`volunteers/pool/${pool.id}`}
                >
                  {pool.name}
                </Link>
              </div>
            ),
          },
          {
            label: "% Assigned",
            key: "assigned_prospect_rate",
            width: 200,
            row: (pool) => (
              <div className="volunteer-pools-table-controller--assigned-rate">
                {" "}
                {numeral(pool.assigned_prospect_rate).format("0.0%")}
              </div>
            ),
          },
          {
            label: "Volunteers & Prospects",
            key: "total_prospect_count",
            width: 200,
            row: (pool) => (
              <div>
                <div>{pool.total_solicitor_count} Volunteers</div>
                <div> {pool.total_prospect_count} Prospects</div>
              </div>
            ),
          },
          {
            label: "Giving Category",
            key: "giving_category",
            width: 200,
            row: (pool) => (
              <div>
                {_.isNull(pool.giving_category_label) ? DEFAULT_GIVING_DATA_STRING : pool.giving_category_label}
              </div>
            ),
          },
          {
            label: "Self Selection",
            key: "self_selection",
            width: 200,
            row: (pool) => {
              const is_enabled = pool.selection_mode === SELF_SELECTION_STRING;
              return (
                <div
                  className={classNames("volunteer-pools-table-controller--selection-mode-label", {
                    disabled: !is_enabled,
                  })}
                >
                  {is_enabled ? "Enabled" : "Disabled"}
                </div>
              );
            },
          },
          {
            label: "Stage Group",
            key: "stage_group",
            width: 200,
            row: (pool) => (
              <StageResolver>
                {({ stageGroups, hasStagesFeature }) => {
                  if (!hasStagesFeature) return null;
                  const group = stageGroups[pool.stage_group_id] || {};
                  return <div>{group.name}</div>;
                }}
              </StageResolver>
            ),
          },
        ]}
      />
    </div>
  );
};

VolunteerPoolsTableController.propTypes = propTypes;

VolunteerPoolsTableController.defaultProps = defaultProps;

export default VolunteerPoolsTableController;
