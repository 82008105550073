module.exports = do ->
   React = require("react")
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   EverTrue = require("app")
   {div, span} = ReactLibs.DOMFactories
   Avatar = require("components/elements/avatar")
   Decorator = require("clientDecorator")
   {createFactory} = require("base/new-utils")
   ActionMenu = createFactory(require("components/controls/action-menu"))
   ProfileActionsMenu = createFactory(require("apps/profile/components/profile-actions-menu").default)
   Icon = createFactory (require("components/elements/icon").default)
   ProfileInteractionsSource = require("apps/profile/sources/profile-interactions-source").default
   LinkButton = createFactory(require("@evertrue/et-components").LinkButton)
   Link = createFactory(require("@evertrue/et-components").Link)

   createComponent "ProfileDetailHeader",
      propTypes:
         contact: ReactLibs.PropTypes.object
         title: ReactLibs.PropTypes.string.isRequired
         section: ReactLibs.PropTypes.string

      handleBack: ->
         if window.history.length > 1 then window.history.back()
         else EverTrue.Navigator("/contact", true)

      handleSave: (comment) ->
         ProfileInteractionsSource.create(@props.contact?.id, comment)

      render: ->
         contact = @props.contact
         deceased = Decorator.Profile.getDeceased(contact)
         fullName =  Decorator.Contacts.getDetailedName(contact)

         div className: "profile-detail-header",
            div className: "clearfix",
               LinkButton title: "Go back", className: "profile-header--back", onClick: @handleBack,
                  Icon icon: "chevron-left", size: 2

               div className: "profile-detail-header--avatar fs-exclude-data",
                  Avatar url: contact.avatar, className: "profile-detail-header--avatar", name: fullName

               div className: "profile-detail-header--user fs-exclude-data",
                  Link title: "#{fullName}'s profile'", href: "/contact/#{contact?.id}?section=#{@props.section || "summary"}",
                     span className: classNames("profile-detail-header--name", "is-deceased": deceased),
                        fullName

                  span className: "profile-detail-header--breadcrumb", " / "
                  "#{@props.title}"

               div className: "pull-right",
                  ProfileActionsMenu contact: @props.contact
