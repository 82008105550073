import EverTrue from "app";
import { Component } from "react";
import { Switch } from "@evertrue/et-components";
import SessionStore from "base/session/session-store";
import { queryDynamicLists, queryBookmarkedSearches } from "./lists-searches-query";
import { gqlCall } from "entities/helpers/gqlCall";
import PropTypes from "prop-types";
import { connect } from "@evertrue/et-flux";

const mapStateToProps = () => ({
  currentUser: SessionStore.getUser(),
});
class FiltersBetaToggle extends Component {
  static propTypes = {
    currentUser: PropTypes.object,
  };

  static defaultProps = {
    currentUser: {},
  };

  state = {
    dynamicLists: [],
    bookmarkedSearches: [],
    disableToggle: false,
    isOptedOut: !!localStorage.getItem("filters_v2_opt_out"),
  };

  componentDidMount() {
    const path = window.location.pathname;
    const fetchData = async () => {
      await gqlCall(queryDynamicLists, { entityId: this.props.currentUser.id }).then((resp = {}) => {
        if(resp.data.listsByUserId.length > 0)
          this.setState({ ...this.state, disableToggle: true });
      });
      await gqlCall(queryBookmarkedSearches, { entityId: this.props.currentUser.id }).then((resp = {}) => {
        if(resp.data.searchesByUserId.length > 0)
          this.setState({ ...this.state, disableToggle: true });
      });
      if(this.state.disableToggle === false){
        this.setState({...this.state, isOptedOut: true})
        EverTrue.track.set("filter_v2_action", { type: "opt_out" });
        localStorage.setItem("filters_v2_opt_out", "true");

        if (path === "/constituents") {
          EverTrue.Navigator("/contact?beta=false", true);
        } else if (path === "/dynamic-lists") {
          EverTrue.Navigator("/segments?beta=false", true);
        } else {
          EverTrue.Navigator(`${path}?beta=false`, true);
        }
      }
    };

    if (this.props.currentUser.id) {
      fetchData();
    }

  }

  handleChange = () => {
    const path = window.location.pathname;
    this.setState({...this.state, isOptedOut: !this.state.isOptedOut})
    if (this.state.isOptedOut) {
      EverTrue.track.set("filter_v2_action", { type: "opt_in" });
      localStorage.removeItem("filters_v2_opt_out");

      if (path === "/segments") {
        EverTrue.Navigator(`/dynamic-lists?beta=true`, true);
      } else {
        EverTrue.Navigator(`${path}?beta=true`, true);
      }
    } else {
      EverTrue.track.set("filter_v2_action", { type: "opt_out" });
      localStorage.setItem("filters_v2_opt_out", "true");

      if (path === "/constituents") {
        EverTrue.Navigator("/contact?beta=false", true);
      } else if (path === "/dynamic-lists") {
        EverTrue.Navigator("/segments?beta=false", true);
      } else {
        EverTrue.Navigator(`${path}?beta=false`, true);
      }
    }
  };

  render() {

    return (
      <>
      {!this.state.disableToggle ? (
        <></>
      ) : (
      <div className="filters-v2-switcher">
            <Switch
              name="beta_mode"
              label="Beta mode"
              checked={!this.state.isOptedOut}
              showLabel={false}
              onChange={this.handleChange}
            />
            <span className="filters-v2-switcher--label">
              {this.state.isOptedOut ? (
                <>
                  Use Toggle to turn on Beta Filters -- ⚠️ Attention: The Beta Filter experience will soon be removed
                  from the platform.
                </>
              ) : (
                <>
                  ⚠️ Attention ⚠️ This Beta Filter experience will soon be removed from the platform. Please speak with
                  you customer success representative to learn more.
                </>
              )}
            </span>
      </div>
      )}
      </>
    );
  }
}

export default connect(FiltersBetaToggle, [SessionStore], mapStateToProps);
