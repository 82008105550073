module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   EverTrue = require("app")
   {createComponent} = require("@evertrue/et-flux")
   ExportStore = require("apps/export/stores/export-store")
   ExportSource = require("apps/export/sources/export-source")
   {p, div, h5} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   ProgressBar = createFactory(require("components/forms/progress-bar").default)
   LinkButton = createFactory(require("@evertrue/et-components").LinkButton)
   Link = createFactory(require("@evertrue/et-components").Link)

   createComponent "ExportToast",
      propTypes:
         export_id: ReactLibs.PropTypes.any
         onToastClose: ReactLibs.PropTypes.func

      getInitialState: ->
         {collapsed: false}

      registerStores: ->
         @on ExportStore, ->
            export: ExportStore.getById(@props.export_id)

      componentDidMount: ->
         _.delay =>
            if @depracated_is_mounted && @state.export?.percent_complete < 1
               @setState {collapsed: true}
         , 5000

      componentWillUnmount: ->
         ExportSource.stop(@props.export_id)

      handleDownload: ->
         EverTrue.track.setReferrer("export_toast")
         ExportSource.download(@state.export)

         if _.isFunction @props.onToastClose
            _.defer((=> @props.onToastClose()))

      handleViewAll: ->
         EverTrue.Navigator("/data/exports", true)
         if _.isFunction @props.onToastClose
            _.defer((=> @props.onToastClose()))

      render: ->
         progress = @state.export.percent_complete || 0
         # Add 0.1 to progress so it doesn't hang at 0
         if progress != 1 then progress += 0.1

         div className: "export-toast", role: "alert",
            div "aria-live": "assertive",
               if progress < 1
                  div null,
                     h5 null, "Creating Export File"
                     if !@state.collapsed
                        p null, "Your .CSV file is being created behind the scenes. You will be notified when it's ready."
                     ProgressBar className: "progress-bar-info-inverse", percent: progress

               else if progress == 1 && @state.export.state == "finished_success"
                  div null,
                     h5 null, "Export File Ready"
                     p null, "Your export file has been created successfully."

                     div className: "toast--actions export-toast--actions",
                        Link href: @state.export.download_link, title: "Download File", "data-bypass": true, onClick: @handleDownload, "Download"
                        LinkButton title: "View All Exports", onClick: @handleViewAll, "View All"

               else if progress == 1 && @state.export.state == "finished_error"
                  div null,
                     h5 null, "Export File Failed"
                     p null, "Your export file failed, please try again."
