import { PropTypes } from "base/react-libs";
import { InteractionsProvider } from "apps/profile/contexts/interactions-context";
import InteractionAddBtn from "apps/interactions/components/create/interaction-add-btn";
import InteractionsWrapper from "../interaction-feed/interactions-wrapper";
import SummaryRow from "../summary-row";

const ContactSummaryInteractions = ({ contact }) => {
  return (
    <InteractionsProvider contact={contact}>
      <SummaryRow title="Interactions">
        <InteractionAddBtn
          contact={contact}
          buttonClass="contact-summary--header-row--button"
          buttonType="simple"
          title="+"
        />
        <span>Add Interaction</span>
      </SummaryRow>
      <InteractionsWrapper />
    </InteractionsProvider>
  );
};

ContactSummaryInteractions.propTypes = {
  contact: PropTypes.object,
};

export default ContactSummaryInteractions;
