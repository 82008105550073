import _ from "underscore";
import classNames from "classnames";
import { Component } from "react";
import { connect } from "@evertrue/et-flux";
import PropTypes from "prop-types";
import GeolocationStore from "apps/contact/stores/geolocation-store";
import GeolocationError from "apps/map/components/geolocation-error";
import { Icon, IconButton, Button } from "@evertrue/et-components";

const GEOLOCATION_TEXT = "Current Location";
const google = window.google;

const mapStateToProps = () => ({
  error: GeolocationStore.getError(),
});

class MapLocationSearch extends Component {
  static propTypes = {
    onSelect: PropTypes.func,
    onGeolocation: PropTypes.func,
    className: PropTypes.string,
    error: PropTypes.bool,
  };

  static defaultProps = {
    onSelect: _.noop,
    onGeolocation: _.noop,
  };

  state = {
    location_value: "",
    is_focused: false,
  };

  componentDidMount() {
    const text_input = document.getElementById("map-location-input");
    this.autocomplete = new google.maps.places.Autocomplete(text_input);

    google.maps.event.addListener(this.autocomplete, "place_changed", () => {
      const place = this.autocomplete.getPlace();
      const place_geometry = place ? place.geometry : null;
      const location = place_geometry ? place_geometry.location : null;
      text_input.blur();

      if (location) {
        const google_locale = {
          lat: location != null ? location.lat() : undefined,
          lng: location != null ? location.lng() : undefined,
        };
        this.props.onSelect(google_locale);
      } else if (place != null ? place.name : undefined) {
        this.handleGeocode(place.name);
      }

      this.setState({
        is_focused: false,
        location_value:
          (place != null ? place.formatted_address : undefined) || (place != null ? place.name : undefined),
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.error !== this.props.error || prevState.location_value !== this.state.location_value) {
      if (this.props.error && this.state.location_value === GEOLOCATION_TEXT) {
        this.setState({ location_value: undefined });
      }
    }
  }

  handleGeocode = (location) => {
    if (location === GEOLOCATION_TEXT) {
      this.props.onSelect();
    } else if (location) {
      const geocoder = typeof google !== "undefined" && google !== null ? new google.maps.Geocoder() : undefined;
      geocoder.geocode({ address: location }, (results) => {
        const data = results != null ? results[0] : undefined;
        const data_geometry = data ? data.geometry : null;
        location = data_geometry ? data_geometry.location : null;
        const google_locale = {
          lat: location ? location.lat() : undefined,
          lng: location ? location.lng() : undefined,
        };
        this.props.onSelect(google_locale);
      });
    }
  };

  handleGeolocation = () => {
    if (!this.props.error) {
      this.props.onGeolocation();
      this.setState({
        is_focused: false,
        location_value: GEOLOCATION_TEXT,
      });
    }
  };

  handleSearchClick = () => {
    const text_input = document.getElementById("map-location-input");
    const location_name = text_input.value;
    this.handleGeocode(location_name);
  };

  handleOutsideClick = () => {
    this.setState({ is_focused: false });
  };

  render() {
    const gps_icon = this.props.error ? "gps-off" : "gps-fixed";

    return (
      <div className={classNames("map-location-search", this.props.className)} onClick={this.handleInsideClick}>
        <input
          type="text"
          id="map-location-input"
          value={this.state.location_value}
          className="map-location-search--input"
          placeholder="Set map to location..."
          onChange={(e) => this.setState({ location_value: e.target.value })}
          onFocus={() => this.setState({ is_focused: true })}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              return google.maps.event.trigger(this.autocomplete, "place_changed");
            }
          }}
        />
        <IconButton
          title="Search in my location"
          icon={gps_icon}
          onClick={this.handleGeolocation}
          className={classNames("map-location-search--geolocation", { "is-disabled": this.props.error })}
        />
        <Button
          type="default"
          title="Search location"
          className="map-location-search--btn"
          onClick={this.handleSearchClick}
        >
          <Icon icon="search" />
        </Button>
        <GeolocationError />
        {this.state.is_focused && !this.props.error && (
          <a className="map-location-search--current" onClick={this.handleGeolocation}>
            <Icon icon={gps_icon} size={1} />
            {GEOLOCATION_TEXT}
          </a>
        )}
      </div>
    );
  }
}

export default connect(MapLocationSearch, [GeolocationStore], mapStateToProps);
