module.exports = do ->
   React = require("react")
   numeral = require("numeral")
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   {span, div, br} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   Icon = createFactory (require("components/elements/icon").default)
   Tooltip = require("components/overlays/tooltip")
   MyTeamStore = require("apps/my-team/stores/my-team-store")


   createComponent "MyTeamChange",
      propTypes:
         current: ReactLibs.PropTypes.any
         past: ReactLibs.PropTypes.any

      registerStores: ->
         @on MyTeamStore, ->
            dates: MyTeamStore.getFormattedDateRange("date_range_compare")

      render: ->
         current = numeral(@props.current).value()
         past = numeral(@props.past).value()

         return null if current == past || past == 0

         is_increasing = current >= past
         percent_change = ((current - past) / past)

         Tooltip
            className: "inline"
            content: div null,
               "Percentage change based"
               br()
               "on the previous period "
               br()
               if @state.dates.to == @state.dates.from
                  "(#{@state.dates.from})"
               else
                  "(#{@state.dates.from} to #{@state.dates.to})"


            position: "top"

            span className: classNames("my-team-change", "is-positive": is_increasing),
               Icon icon: if is_increasing then "arrow-up" else "arrow-down"
               numeral(percent_change).format("0%")
