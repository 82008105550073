module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   classNames = require("classnames")
   {createComponent} = require("@evertrue/et-flux")
   {div, strong} = ReactLibs.DOMFactories
   {arrayOf, shape, string, number, oneOfType, node} = ReactLibs.PropTypes
   {createFactory} = require("base/new-utils")
   Icon = createFactory (require("components/elements/icon").default)

   createComponent "CardAnalytics",
      propTypes:
         className: string
         data: arrayOf shape
            label: string.isRequired
            value: oneOfType([string, number, node])
            icon: string

      render: ->
         size = _.size(@props.data)

         div className: classNames("card-analytics", @props.className),
            _.map @props.data, (item) ->
               isEmpty = !item.value || item.value in [0, "0", "$0", "0%"]

               div
                  key: item.label
                  className: classNames("card-analytics--column", "is-empty": isEmpty)
                  style: {width: (100 / size) + "%"}

                  div className: "card-analytics--data",
                     strong null, item.value
                     if item.icon then Icon icon: item.icon
                  div null, item.label
