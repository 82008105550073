import { useState } from "react";
import PropTypes from "prop-types";
import _ from "underscore";
import { FilterPopover } from "@evertrue/et-components";

const filterConfig = {
  date: {
    type: "radio",
    title: "Date Range",
    options: [
      {
        name: "Last 7 Days",
        key: "l-7-d",
      },
      {
        name: "Last 30 Days",
        key: "l-30-d",
      },
      {
        name: "Last 3 Months",
        key: "l-3-m",
      },
      {
        name: "Last Year",
        key: "l-12-m",
      },
    ],
  },
};

const FacebookEngagementFilters = ({ onApply, activeFilters }) => {
  const [tempFilter, onTempFilterChange] = useState(activeFilters);
  const buttonText = _.findWhere(filterConfig.date.options, { key: activeFilters.date }).name;

  return (
    <FilterPopover
      buttonText={buttonText}
      showCount={false}
      config={filterConfig}
      activeFilters={tempFilter}
      onFilterChange={onTempFilterChange}
      onApply={() => onApply(tempFilter)}
    />
  );
};

FacebookEngagementFilters.propTypes = {
  onApply: PropTypes.func,
  activeFilters: PropTypes.object,
};

export default FacebookEngagementFilters;
