module.exports = do ->
   _ = require("underscore").default
   {createComponent} = require("@evertrue/et-flux")
   React = require("react")
   {div} = ReactLibs.DOMFactories
   {any, func, string, bool} = ReactLibs.PropTypes
   {createFactory} = require("base/new-utils")
   Checkbox = createFactory(require("@evertrue/et-components").Checkbox)
   AdvancedCombobox = createFactory(require("@evertrue/et-components").AdvancedCombobox)
   FeatureStore = require("apps/layout/stores/feature-store").default
   classNames = require("classnames")
   {getEventActionsByNetwork} = require("apps/events/utils/engagement-config")

   EVENTBRITE_OPTIONS = getEventActionsByNetwork("eventbrite")
   FACEBOOK_OPTIONS = getEventActionsByNetwork("facebook")

   EngagementIcon = require("apps/profile/components/engagement-icon")

   formatItem = (item, isSelected, isHovered) ->
      div className: "event-response-dropdown--item",
         Checkbox
            className: "event-response-dropdown--checkbox checkbox-small"
            checked: isSelected
            value: item?.value

         div className: "event-response-dropdown--label",
            item?.label

         EngagementIcon
            size: 16
            className: "event-response-dropdown--icon"
            tab: item?.value

   createComponent "EventResponseDropdown",
      propTypes:
         value: any
         onChange: func # args: ()
         placeholder: string
         multiple: bool
         className: string

      getDefaultProps: ->
         multiple: true
         placeholder: "All Responses"

      registerStores: ->
         @on FeatureStore, ->
            has_facebook: FeatureStore.hasFeature("events_facebook")
            has_eventbrite: FeatureStore.hasFeature("events_eventbrite")

      handleChange: (args...) ->
         @props.onChange?(args...)

      renderOptions: ->
         if @state.has_eventbrite and @state.has_facebook
            grouped: true
            options: [
               {label: "Eventbrite Responses", items: EVENTBRITE_OPTIONS}
               {label: "Facebook Responses", items: FACEBOOK_OPTIONS}
            ]

         else if @state.has_facebook
            grouped: false
            options: FACEBOOK_OPTIONS

         else if @state.has_eventbrite
            grouped: false
            options: EVENTBRITE_OPTIONS
         else { grouped: false, options: [] }

      render: ->
         {multiple, className, placeholder} = @props
         {options, grouped} = @renderOptions()
         value = _.cloneData(@props.value)

         AdvancedCombobox
            className: classNames("event-response-dropdown", className)
            options: options
            grouped: grouped
            value: value
            multiple: multiple
            placeholder: placeholder
            onChange: @handleChange
            formatItem: formatItem
            searchable: false
            allowChangeAll: true
            allowUnselectAll: multiple
