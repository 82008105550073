module.exports = do ->
   $ = require("jquery")
   _ = require("underscore").default
   moment = require("moment")
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")
   MyTeamReportConfig = require("apps/my-team/my-team-report-config")

   VALID_REPORTS = _.keys(MyTeamReportConfig)
   VISIT_TYPE_KEY = "GT_giftofficer_visit_types"


   createSource "MyTeamSource",
      actions:
         loading: true
         startFetchAll: true
         startExport: true
         fetchedAll: (report_data) ->
            @require _.isObject(report_data), "report_data should be an object"
         fetchedAllComparisons: (report_data) ->
            @require _.isObject(report_data), "report_data should be an object"
         fetchedReportData: (report, data) ->
            @require _.contains(VALID_REPORTS, report), "report needs to be a valid report"
            @require _.isArray(data), "data needs to be an array"
         changeDateRange: (date) ->
            @require _.isString(date), "date should be string"
         updateVisitTypes: (types) ->
            @require _.isArray(types) || _.isEmpty(types), "types should be an array"

      fetchSingleReport: (key, params) ->
         promise = $.Deferred()
         endpoint = MyTeamReportConfig[key]?.endpoint
         if !endpoint
            console.error("Report #{key} not found.")
            promise.fail(key)
         else
            valid_params = params
            if key != "visits"
               valid_params = _.omit(params, "interaction_type")

            Api.CHARTING.V2.get
               urlExtend: endpoint
               params: valid_params
               disableAlerts: true
               success: (resp) -> promise.resolve(key, resp.data)
               error: -> promise.resolve()
         promise.promise()

      fetchAllReports: (keys, params) ->
         requests = _.map keys, (report) =>
            @fetchSingleReport(report, params)

         $.when.apply(@, requests).then (args...) ->
            report_obj = {}
            _.each args, (data) ->
               unless _.isEmpty(data)
                  report_obj[data[0]] = data[1]
            return report_obj

      fetchVisitTypes: ->
         new Promise (resolve, reject) ->
            Api.DNA.SETTING.get
               urlArgs: {setting_key: VISIT_TYPE_KEY}
               disableAlerts: true
               success: (response) ->
                  types = response?.settings[VISIT_TYPE_KEY]?.value
                  resolve(types)
               error: reject

      api:
         startInitialFetch: ->
            @actions.loading(true)
            @fetchVisitTypes()
               .then (types) =>
                  # Triggers startFetchAll so want to keep loading
                  @actions.updateVisitTypes(types)
               .catch => @actions.loading(false)

         startFetchAll: ->
            @actions.loading(true)
            @actions.startFetchAll()

         # Fetches data for all reports that are available to user
         fetchAll: (keys, params) ->
            @actions.loading(true)
            @fetchAllReports(keys, params)
               .then (data) =>
                  @actions.fetchedAll(data, params)
                  @actions.loading(false)
               .fail => @actions.loading(false)

         # Fetches data to show percent changed from previous time range
         fetchAllComparisons: (keys, params) ->
            @fetchAllReports(keys, params)
               .then (data) =>
                  @actions.fetchedAllComparisons(data, params)

         changeDateRange: (value) ->
            @actions.changeDateRange(value)

         updateVisitTypes: (types) ->
            @actions.loading(true)
            Api.DNA.SETTING.request
               type: if _.isEmpty(types) then "DELETE" else "PUT"
               urlArgs: {setting_key: VISIT_TYPE_KEY}
               disableAlerts: true
               data: unless _.isEmpty(types) then _.jsonStringify({value: _.compact(types)})
               success: (response) =>
                  types = response?.settings[VISIT_TYPE_KEY]?.value
                  @actions.updateVisitTypes(types)
                  @actions.loading(false)

         startExport: (key) ->
            @actions.startExport(key)

         downloadCSV: (key, data) ->
            csv_content = "data:text/csv;charset=utf-8,"
            _.each data, (infoArray, index) ->
               data_string = infoArray.join(", ")
               if index < data.length
                  csv_content += data_string + "\n"
               else data_string

            date = moment().format("YYYY-MM-DD")
            filename = "evertrue-#{key}-#{date}.csv"
            data = encodeURI(csv_content)

            [browser] = navigator.sayswho()?.split(" ")
            if browser.match("ie")
               window.open(data)
            else
               link = document.createElement("a")
               link.setAttribute "href", data
               link.setAttribute "download", filename
               link.click()

