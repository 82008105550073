import { useIdentity } from "base/identity-resolver";
import { getUpcomingTaskCounts, fetchNextFiveDays } from "apps/home/utils/api-requests/home-page";
import { useEffect, useState } from "react";

const ACTION_TYPE_FIELDS = {
  CALL: "Call",
  EMAIL: "Email",
  LINKEDIN: "LinkedIn",
  RESEARCH: "Research",
  PRINT_MAIL: "Print Mail",
  TEXT: "Text",
  THANK_VIEW: "ThankView",
  OTHER: "Other",
};

const useHomePage = () => {
  const { user } = useIdentity();
  const [nextFiveDaysCounts, setNextFiveDaysCounts] = useState([]);
  const [todayTaskCount, setTodayTaskCount] = useState({});
  const [todayJourneyCount, setTodayJourneyCount] = useState({});
  const [loadingJourneysTodayCount, setLoadingJourneysTodayCount] = useState(false);
  const [loadingTasksTodayCount, setLoadingTasksTodayCount] = useState(false);
  const [loadingNextFiveCounts, setLoadingNextFiveCounts] = useState(false);

  // JOURNEY TASKS and OTHER TASK COUNTS FOR NEXT FIVE DATES
  useEffect(() => {
    const getAndSetNextFiveDayCounts = async () => {
      setLoadingNextFiveCounts(true);
      const nextFive = await fetchNextFiveDays(user.id, ["IN_PROGRESS"], true, 7);
      setNextFiveDaysCounts(nextFive);
      setLoadingNextFiveCounts(false);
    };

    getAndSetNextFiveDayCounts();
  }, [user.id]);

  // JOURNEY COUNTS FOR TODAY
  useEffect(() => {
    const getAndSetJourneyCounts = async () => {
      setLoadingJourneysTodayCount(true);
      const counts = await getUpcomingTaskCounts(user.id, ["IN_PROGRESS"], false, true, 1);
      setTodayJourneyCount(counts);
      setLoadingJourneysTodayCount(false);
    };

    getAndSetJourneyCounts();
  }, [user.id]);

  // TASK COUNTS FOR TODAY
  useEffect(() => {
    const getAndSetTaskCounts = async () => {
      setLoadingTasksTodayCount(true);
      const counts = await getUpcomingTaskCounts(user.id, ["IN_PROGRESS"], false, false, 1);
      setTodayTaskCount(counts);
      setLoadingTasksTodayCount(false);
    };

    getAndSetTaskCounts();
  }, [user.id]);

  const addTaskToCount = (task = {}) => {
    if (!task || !(typeof task === "object")) return;

    const { action_type = "" } = task;
    const mapped = ACTION_TYPE_FIELDS[action_type] || "Other";
    const taskCountCopy = { ...todayTaskCount };
    taskCountCopy[mapped] += 1;
    taskCountCopy["total"] += 1;

    setTodayTaskCount(taskCountCopy);
  };

  return {
    nextFiveDaysCounts,
    todayTaskCount,
    todayJourneyCount,
    addTaskToCount,
    loadingNextFiveCounts,
    loadingJourneysTodayCount,
    loadingTasksTodayCount,
  };
};
export default useHomePage;
