import _ from "underscore";
import { createStore } from "@evertrue/et-flux";
import CachedContactSource from "apps/contact/sources/cached-contact-source";

export default createStore("CachedContactStore", {
  getInitialState() {
    return {
      contacts: {},
      cached_ids: [],
      autocomplete_results: {},
      loading: false,
    };
  },

  registerActions() {
    this.on(CachedContactSource.actions.startFetchContacts, this.respondToStartFetch);
    this.on(CachedContactSource.actions.fetchedContacts, this.respondToFetchedContacts);
    this.on(CachedContactSource.actions.cacheContacts, this.respondToCache);
    this.on(CachedContactSource.actions.clearFromCache, this.respondToClear);
    this.on(CachedContactSource.actions.searchedAutocomplete, this.respondToAutocomplete);
  },

  respondToStartFetch(ids) {
    this.setState({ loading: true });
    let contacts = _.cloneData(this.getState("contacts"));
    const cached_contacts = _.map(_.keys(contacts), _.toNumber);
    const new_ids = _.difference(ids, cached_contacts);

    // Register these ids in the store as requiring access
    // Note: we want duplicates to account for multiple components usage
    const cached_ids = _.cloneData(this.getState("cached_ids")).concat(ids);

    // Handle invalidating the contacts that are cached so we're not store thousands
    if (cached_contacts.length > 200) {
      if (cached_contacts.length < 500) {
        contacts = _.pick(contacts, _.uniq(cached_ids));
      } else {
        console.warn("CachedContactSource has cached a lot of contacts. Check logic");
      }
    }

    this.setState({ cached_ids, contacts });

    if (!_.isEmpty(new_ids)) {
      CachedContactSource.fetchContactsByIds(new_ids);
    } else {
      this.setState({ loading: false });
      CachedContactSource.changedContacts();
    }
  },

  respondToFetchedContacts(contact_ids, contacts) {
    const cached_contacts = _.cloneData(this.getState("contacts") || {});
    _.each(contact_ids, id => (cached_contacts[id] = _.findWhere(contacts.items, { id })));
    this.setState({ contacts: cached_contacts, loading: false });
    CachedContactSource.changedContacts();
  },

  respondToCache(contact_objects) {
    const ids = _.pluck(contact_objects, "id");
    this.respondToFetchedContacts(ids, { items: contact_objects });
  },

  respondToClear(ids) {
    const cached_ids = _.cloneData(this.getState("cached_ids"));
    const ids_to_remove = _.cloneData(ids);

    const cleaned_cached_ids = _.filter(cached_ids, function(id) {
      const index_to_remove = _.indexOf(ids_to_remove, id);
      if (index_to_remove !== -1) {
        ids_to_remove.splice(index_to_remove, 1);
        return false;
      } else {
        return true;
      }
    });

    this.setState({ cached_ids: cleaned_cached_ids });
  },

  respondToAutocomplete(string, results) {
    const cached_results = _.cloneData(this.getState("autocomplete_results"));
    cached_results[string] = results;
    this.setState({ autocomplete_results: cached_results });
    this.respondToFetchedContacts(_.pluck(results.items, "id"), results);
  },

  api: {
    getAllContacts() {
      return _.cloneData(this.getState("contacts"));
    },

    getContactById(id) {
      return _.cloneData(this.getState("contacts")[id]);
    },

    getAutocompleteResults() {
      return _.cloneData(this.getState("autocomplete_results"));
    },

    getLoading() {
      return this.getState("loading");
    },
  },
});
