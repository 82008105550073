import { useRef } from "react";
import PropTypes from "prop-types";
import EverTrue from "app";
import { FlexTable, Button } from "@evertrue/et-components";
import LoadMoreButton from "components/controls/load-more-button";
//TODO consider if bookmark_permissions are the same as list permissions and, if so, maybe make this enum more generic
import { PERMISSIONS, LIST_STATUS } from "apps/dynamic-lists/utils/dynamic-list-enums";
import { getHumanReadableTimeFromNow } from "apps/proposals/components/proposal-form-utils";
import { formatListChanges } from "apps/dynamic-lists/utils/dynamic-list-utils";

const DynamicListsTable = ({ lists = [], total = 0, loading = false, loadMore = () => {}, offset = 0, limit = 0 }) => {
  const newItemRef = useRef();

  const columns = [
    {
      key: 1,
      width: 300,
      header: "List Name",
      accessor: (data, idx) => {
        const isFirstNewResult = idx === offset;

        return (
          <Button
            ref={isFirstNewResult ? newItemRef : undefined}
            className="dynamic-lists-table--link"
            type="simple"
            title={`Open list ${data.name}`}
            onClick={() => {
              EverTrue.track.set("dynamic_lists_action", { type: "total_view_per_user" });
              EverTrue.Navigator(`dynamic-lists/${data.id}`);
            }}
          >
            {data.name}
          </Button>
        );
      },
    },
    {
      key: 2,
      header: "Owner",
      accessor: (data) => {
        if (data.current_user_access === PERMISSIONS.OWNER) {
          return "You";
        }

        return data.permission_stats.owner_name || "Removed User";
      },
    },
    {
      key: 3,
      header: "Constituents",
      accessor: (data) => (data.tag_stats === null ? "" : data.tag_stats.total_constituents.toLocaleString("en-US")),
    },
    {
      key: 4,
      header: "Updated",
      accessor: (data) => {
        const { tag_stats } = data;
        return tag_stats === null ? (
          ""
        ) : (
          <div>
            <p style={{ margin: 0 }}>{`${getHumanReadableTimeFromNow(tag_stats.operation_ended_at)} ago`}</p>
            <p style={{ fontSize: "12px", margin: 0 }}>
              {formatListChanges(tag_stats.total_added, tag_stats.total_expired)}
            </p>
          </div>
        );
      },
    },
    {
      key: 5,
      header: "Status",
      accessor: (data) => (data.tag_stats === null ? LIST_STATUS.QUEDED : LIST_STATUS[data.tag_stats.operation_status]),
    },
    // {
    //   key: 6,
    //   header: "Email Alerts",
    //   accessor: (data) => "TODO",
    // },
  ];

  return (
    <div className="dynamic-lists-table">
      <FlexTable
        caption="Lists"
        emptyStateMessageText="You don't have any dynamic lists"
        emptyStateMessageSubtext="Setup dynamic lists to be notified of new constituents that match your search criteria whenever you want!"
        emptyStateActionLabel="Learn More"
        handleEmptyStateAction={(window) => {
          window.open("https://help.evertrue.com/t/y4hct1a/creating-and-viewing-dynamic-lists", "_blank");
        }}
        columns={columns}
        data={lists}
        loading={!lists.length && loading}
      />
      <LoadMoreButton
        ref={newItemRef}
        loadMore={loadMore}
        loading={loading}
        displayCount={lists.length}
        limit={limit}
        total={total}
      />
    </div>
  );
};

DynamicListsTable.propTypes = {
  lists: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  offset: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
};

export default DynamicListsTable;
