import { Component } from "react";
import SearchBox from "components/forms/search-box";
import VolunteerPoolDropdown from "apps/volunteers/components/volunteer-pool-dropdown";

class VolunteerManagementHeader extends Component {
  static propTypes = {
    selectedPools: ReactLibs.PropTypes.array,
    onPoolChange: ReactLibs.PropTypes.func,
    onSearchChange: ReactLibs.PropTypes.func,
    searchVal: ReactLibs.PropTypes.string,
  };

  static defaultProps = {
    pools: {},
    selectedPool: {},
  };

  render() {
    return (
      <div className="volunteer-management-header">
        <SearchBox
          name=""
          placeholder="Search for volunteers by name..."
          defaultValue={this.props.searchVal}
          onKeyUp={(query) => this.props.onSearchChange(query)}
          className="volunteer-management-header--search-bar"
        />

        <VolunteerPoolDropdown value={this.props.selectedPools} onChange={this.props.onPoolChange} />
      </div>
    );
  }
}

export default VolunteerManagementHeader;
