module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   EverTrue = require("app")
   moment = require("moment")
   {createComponent} = require("@evertrue/et-flux")
   {div, ul, li, label} = ReactLibs.DOMFactories
   {createFactory} = require("base/new-utils")
   SearchBox = createFactory require("components/forms/search-box")
   EmptyStateMessage = createFactory(require("components/lists/empty-state-message"))
   Icon = createFactory (require("components/elements/icon").default)
   ListStore = require("apps/lists/stores/list-store")
   ArrowSelectMixin = require("mixins/arrow-select-mixin")
   LinkButton = createFactory(require("@evertrue/et-components").LinkButton)
   Link = createFactory(require("@evertrue/et-components").Link)

   createComponent "ListDetailedSelector",
      mixins: [ArrowSelectMixin]
      propTypes:
         onSelect: ReactLibs.PropTypes.func
         onCreate: ReactLibs.PropTypes.func

      getInitialState: ->
         query: undefined

      registerStores: ->
         @on ListStore, ->
            lists: _.sortBy ListStore.getJoinedLists(), "name"

      componentDidMount: ->
         @refs.searchbox.focus()

      getSelectable: ->
         _.filter @state.lists, (li) =>
            _.searchCompare(li.name, @state.query)

      handleType: (query) ->
         @setState {query: query}

      handleKeyEnter: (selectedIndex) ->
         list = @getSelectable()[selectedIndex]
         @props.onSelect?(list) if list

      handleCreateNewList: ->
         @props.onCreate?()

      render: ->
         current_user = EverTrue.store?.user.get("id")
         filtered_lists = @getSelectable()

         div className: "list-detailed-selector",
            div className: "list-detailed-selector--search",
               SearchBox
                  ref: "searchbox"
                  placeholder: "Search lists by name..."
                  onKeyUp: (query, e) =>
                     @handleKeyPress(e, {input_value: query})

            div className: "list-detailed-selector--container", ref: "arrowSelectBody",
               label className: "list-detailed-selector--label", "Your Lists: "

               if filtered_lists.length
                  ul className: "list-detailed-selector--list",
                     _.map filtered_lists, (list, index) =>
                        li
                           key: list.id
                           className: EverTrue.classSet("is-selected": index == @state.selectedIndex)
                           onMouseOver: => @handleHoverSelect(index)
                           onClick: =>
                              @props.onSelect?(list)
                              @props.requestHide()

                           div className: "bold-text",
                              Link href: "/list/#{list.id}", title: "list: #{list.name}", list.name

                           if current_user != list.user_id && list.owner_name
                              div className: "list-detailed-selector--item-creator",
                                 "Created by #{list.owner_name}"

                           div className: "list-detailed-selector--item-date",
                              "Last Updated on #{moment(list.updated_at).format("MMMM DD, YYYY")}"

                           Icon
                              className: "list-detailed-selector--item-icon"
                              icon: if list.private then "lock" else "group"
               else
                  EmptyStateMessage
                     icon: "clipboard-text"
                     size: "small"
                     text: "No lists match your search"

            div className: "list-detailed-selector--footer",
               LinkButton
                  title: "Create new list"
                  className: "list-detailed-selector--footer-link"
                  onClick: @handleCreateNewList
                  "Create New List"
