import { useContext } from "react";
import { InteractionsContext } from "apps/profile/contexts/interactions-context";
import ContactSummaryInteractionFeed from "./contact-summary-interaction-feed";
import ViewAllInteractionsModal from "./view-all-interactions-modal";

const InteractionsWrapper = () => {
  const { loading, interactions } = useContext(InteractionsContext);

  return (
    <>
      <ContactSummaryInteractionFeed loading={loading} interactions={interactions} displayLimit={3} />
      <ViewAllInteractionsModal type="Interaction" interactions={interactions} />
    </>
  );
};

export default InteractionsWrapper;
