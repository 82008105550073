module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   classNames = require("classnames")
   {createFactory} = require("base/new-utils")
   EllipsisOverflow = createFactory require("components/formatting/ellipsis-overflow")
   {createComponent} = require("@evertrue/et-flux")
   {div, span} = ReactLibs.DOMFactories

   createComponent "ContactBusiness",
      propTypes:
         datalist: ReactLibs.PropTypes.array

      render: ->
         div className: "new-contact-card--datalist",
            _.map @props.datalist, (item, i) ->
               EllipsisOverflow key: item.key || i, className: classNames("new-contact-card--datalist-item", item.className),
                  if item.label
                     span className: "new-contact-card--datalist-label",
                        item.label
                        ": "
                  if item.value
                     span className: "new-contact-card--datalist-value",
                        item.value || span className: "text-placeholder-light", "Not Provided"
