import EverTrue from "app";
import BaseRouter from "base/base-router";
import IsGated from "components/is-gated";
import ConstituentsController from "apps/constituents/controllers/constituents-controller";

class ConstituentsRouter extends BaseRouter {
  static initClass() {
    this.prototype.routes = {
      constituents: "constituents",
    };
  }

  constituents() {
    EverTrue.execute("setBrowserTitle", "Constituents");
    EverTrue.track.set("filter_v2_action", {
      type: "landing_page_view",
    });
    this.currentView = (
      <IsGated
        feature="filters_v2"
        render={({ show: has_new_filters }) => {
          if (has_new_filters && !localStorage.getItem("filters_v2_opt_out")) {
            return <ConstituentsController />;
          } else {
            return null;
          }
        }}
      />
    );
    EverTrue.page.mount(this.currentView);
  }
}

ConstituentsRouter.initClass();
export default ConstituentsRouter;
