module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   EverTrue = require("app")
   {createComponent} = require("@evertrue/et-flux")
   ExportFieldStore = require("apps/export/stores/export-field-store")
   ExportFieldSource = require("apps/export/sources/export-field-source")
   PropertySource = require("apps/contact/sources/property-source")
   {div, h3, ul, li, span} = ReactLibs.DOMFactories
   Loading = require("components/elements/loading")
   {createFactory} = require("base/new-utils")
   Checkbox = createFactory(require("@evertrue/et-components").Checkbox)
   LinkButton = createFactory(require("@evertrue/et-components").LinkButton)

   createComponent "ExportFieldSelector",
      getInitialState: ->
         category: "basic"

      registerStores: ->
         @on ExportFieldStore, ->
            fields: ExportFieldStore.getGroupedFields()
            selected: ExportFieldStore.getSelectedFields()
            loading: ExportFieldStore.getLoading()
            error: ExportFieldStore.getError()

      handleSelectCurrentCategory: (key) ->
         @setState {category: key}

      handleSelectCategory: (key) ->
         ExportFieldSource.selectCategory(key)

      handleUnselectCategory: (key) ->
         ExportFieldSource.unselectCategory(key)

      handleSelectField: (field) ->
         ExportFieldSource.selectField(@state.category, field)

      handleUnselectField: (field) ->
         ExportFieldSource.unselectField(@state.category, field)

      handleErrorRetry: ->
         PropertySource.forceFetch()

      render: ->
         div className: "export-field-selector",
            div className: "export-field-selector--description",
               "Choose the fields to include in your .CSV file. When your export is ready,
               you'll receive an alert and an email with a link to your download."

            div className: "export-field-selector--container",

               if @state.loading then Loading()
               else if @state.error
                  div className: "export-field-selector--error",
                     span null, "An error occurred while trying to retrieve your export options. "
                     LinkButton title: "Click here to try again", onClick: @handleErrorRetry, "Click here to try again. "
                     span null, "Please contact genius@evertrue.com if the error persists"
               else
                  div null,
                     div className: "export-field-selector--categories",
                        h3 className: "export-field-selector--heading", "Categories"

                        ul className: "export-field-selector--cat-list",
                           _.map @state.fields, (categories, key) =>
                              selected_for_cat = @state.selected[key]
                              selected_length = selected_for_cat?.length

                              li
                                 key: key
                                 className: EverTrue.classSet({"is-selected": key == @state.category})
                                 onClick: => @handleSelectCurrentCategory(key)

                                 Checkbox
                                    name: key
                                    checked: !!selected_for_cat
                                    onChange: (isChecked, e) =>
                                       if isChecked then @handleSelectCategory(key)
                                       else @handleUnselectCategory(key)
                                    partial: selected_length > 0 && selected_length < categories.fields.length
                                 categories.category

                     div className: "export-field-selector--fields",
                        h3 className: "export-field-selector--heading", "Field Groups"

                           ul className: "export-field-selector--sub-list",
                              _.map @state.fields?[@state.category]?.fields, (field_group) =>
                                 selected_for_group = _.find @state.selected, (fields, cat) =>
                                    cat == @state.category && _.contains(fields, field_group.group)

                                 li key: field_group.group,
                                    Checkbox
                                       name: field_group.group
                                       checked: !!selected_for_group
                                       onChange: (isChecked, e) =>
                                          if isChecked then @handleSelectField(field_group.group)
                                          else @handleUnselectField(field_group.group)
                                       field_group.group

