module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   {createSource} = require("@evertrue/et-flux")
   Api = require("entities/helpers/api")
   ErrorLogger = require("entities/helpers/error-log-helper")
   str = require("underscore.string")

   _redirectToLogin = ->
      EverTrue.Navigator("/login", true)
      EverTrue.store.destroy("authorize_url")

   _truncate = (token) ->
      str.truncate(token, 8) + token?.slice?(-5)

   _throwError = (endpoint, xhr, meta_data={}) ->
      EverTrue.store.fetch("authorize_url")

      ErrorLogger.captureRequest "Auth Error for #{endpoint}", xhr, _.extend {
         authorize_url: EverTrue.store.authorize_url
      }, _.omit(meta_data, "display_message")

   createSource "LoginSource",
      actions:
         fetchedAccessToken: (token) ->
            @require !_.isUndefined(token), "token needs to be defined"
         activateToken: true
         setInviteToken: true
         signinWithLids: true
         clearedInviteToken: true
         createdSessionWithLidsToken: true
         resetToken: true
         error: (error) ->
            @require _.isString(error), "error should be string"

      api:
         fetchInviteToken: ->
            @setInviteToken(EverTrue.store?.fetch("invite_token"))

         setInviteToken: (token) ->
            @actions.setInviteToken(token) if token

         clearInviteToken: ->
            EverTrue.store.destroy("invite_token")
            @actions.clearedInviteToken()

         redirectToAuthUrl: ->
            Api.LIDS.AUTH_URL.get
               params:
                  callback_url: EverTrue.config.wwwBase + "lids/access"
                  verify: if EverTrue.config.base_environment == "dev" then 1 else 0
               headers:
                  "Authorization" : null
                  "Authorization-Provider": null
               disableAlerts: true
               success: (resp) ->
                  if resp.authorize_url
                     _.defer((-> window.location = resp.authorize_url))
                     EverTrue.store.push "authorize_url", resp.authorize_url, {cache: true}
               error: (xhr) =>
                  error = "Sorry, an error occurred while trying to connect with LinkedIn."
                  @actions.error(error)
                  _redirectToLogin()
                  _throwError "lids/auth", xhr,
                     display_message: error

         fetchAccessToken: (params) ->
            location = window.location.search.slice(1)
            if params.error || !(params.code && params.state)
               _.defer =>
                  error = "LinkedIn Access Denied. You may have canceled
                     your login or have otherwise disabled LinkedIn authentication."
                  @actions.error(error)
                  _redirectToLogin()
                  _throwError "Invalid Params", {},
                     display_message: error
                     param_data: params
                     location: location
            else
               Api.LIDS.ACCESS_TOKEN.get
                  params: params
                  forceRequest: true
                  disableAlerts: true
                  success: (resp) =>
                     token = resp.linkedin_access_token
                     @actions.fetchedAccessToken(token)
                  error: (xhr) =>
                     error = "Unable to retrieve login token."
                     @actions.error(error)
                     _redirectToLogin()
                     _throwError "lids/callback", xhr,
                        display_message: error
                        param_data: params
                        location: location

         activate: (token) ->
            Api.LIDS.ACTIVATE.put
               params: {linkedin_access_token: token}
               disableAlerts: true
               success: =>
                  @actions.activateToken()
               error: (xhr) =>
                  if xhr?.status == 404
                     dispMsg = "That LinkedIn account is not associated
                     with an EverTrue user. You may be logged in to a
                     secondary LinkedIn account."
                  else
                     dispMsg = "Unable to activate LinkedIn token."
                  @actions.error(dispMsg)
                  _redirectToLogin()
                  _throwError "lids/auth/association/activate", xhr,
                     display_message: dispMsg
                     li_token: _truncate(token)
                  @resetToken()

         resetToken: ->
            @actions.resetToken()
