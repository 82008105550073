import { PropTypes } from "base/react-libs";
import { useContext, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, useOpenClose } from "@evertrue/et-components";
import MoreFiltersModalLeftArea from "./more-filters-modal-left-area";
import MoreFiltersModalRightArea from "./more-filters-modal-right-area";
import { FiltersDataContext } from "apps/query-builder/contexts/filters-data-context";

const MoreFiltersModal = ({ filterCount }) => {
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [isOpen, openModal, closeModal] = useOpenClose();
  const { categorizedFilters } = useContext(FiltersDataContext);

  return (
    <>
      <Button type="secondary" onClick={() => openModal()}>
        {filterCount > 0 ? `New Beta Filters (${filterCount})` : "New Beta Filters"}
      </Button>

      <Modal isOpen={isOpen} closeModal={closeModal} size={"large"}>
        <ModalHeader title="More Filters" closeModal={closeModal} />
        <ModalBody scrollable={true}>
          <div className="extra-filters-parent-body">
            <MoreFiltersModalLeftArea
              categorizedFilters={categorizedFilters}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
            />
            <MoreFiltersModalRightArea selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} />
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

MoreFiltersModal.propTypes = {
  filterCount: PropTypes.number,
};

export default MoreFiltersModal;
