import { useEffect, useState } from "react";
import { useIdentity } from "base/identity-resolver";
import {
  getVoipUserSettingsByOwnerUserId,
  validateUserPhoneNumber,
  validationNumberRequest,
} from "./use-call-settings-requests";
import EverTrue from "app";

const useCallSettings = () => {
  //voip/call settings states
  const [callerIdNumber, setCallerIdNumber] = useState("");
  const [callSid, setCallSid] = useState();
  const [validationCode, setValidationCode] = useState("");
  const { user } = useIdentity();

  useEffect(() => {
    // check if user already has a settings record. if they do, pre-populate the the form
    const getAndSetCurrentUserSettings = async () => {
      const { caller_id_phone_number = "" } = await getVoipUserSettingsByOwnerUserId(user.id);
      setCallerIdNumber(caller_id_phone_number);
    };

    getAndSetCurrentUserSettings();
  }, [user.id]);

  const validatePhoneNumber = async (callerNumber, openValidationModal) => {
    const { validationCode, errorMsg, callSid, success } = await validateUserPhoneNumber(callerNumber);
    if (success) {
      setCallSid(callSid);
      setValidationCode(validationCode);
      openValidationModal();
    } else {
      EverTrue.Alert.error(errorMsg);
    }
  };

  const handleCloseModal = async (closeValidationModal = () => {}) => {
    let userSettings = {};
    let attempt = 1;
    let validationRequest;
    // this is unfortunate, but it is preferable to adding a delay before fetching the API
    // Any time a user makes a request, we store it in the DB (success or fail)
    // So, we try and fetch the request until we find it (hard-code a limit to prevent infinite looping)
    while (attempt <= 10 && !validationRequest) {
      validationRequest = await validationNumberRequest(callSid);
      if (validationRequest) {
        // If we get a response, we know the request went through (even if it was unsuccessful).
        // This means that the "validation cycle" is complete.
        const { successfullyValidated } = validationRequest;
        if (successfullyValidated) {
          // this means the user's settings have been created or updated
          userSettings = await getVoipUserSettingsByOwnerUserId(user.id);
        } else {
          // this is NOT an "error state." Everything worked as expected. The user just didn't enter the correct code.
          EverTrue.Alert.error(
            "Phone validation unsuccessful. This likely means you did not enter the correct code. Please try again."
          );
        }
        // we found what we were looking for, so exit the loop.
        break;
      }
      attempt++;
    }
    closeValidationModal();
    generateToast(userSettings);
  };

  const generateToast = (userSettings = {}) => {
    const { caller_id_phone_number = "" } = userSettings;
    if (!Object.keys(userSettings).length) {
      EverTrue.Alert.error("We were unable to store changes to your caller ID. please try again.");
    } else {
      EverTrue.Alert.success(`Success! Your phone number is now ${caller_id_phone_number}.`);
    }
  };

  return {
    callerIdNumber,
    setCallerIdNumber,
    validatePhoneNumber,
    validationCode,
    handleCloseModal,
  };
};

export default useCallSettings;
