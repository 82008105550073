_ = require("underscore").default
{createStore} = require("@evertrue/et-flux")
PoolAssignmentsSource = require("apps/volunteers/sources/pool-assignments-source")
PoolSolicitorsSource = require("apps/volunteers/sources/pool-solicitors-source").default
SocketSource = require("apps/notifications/sources/socket-source")

# assignments are hashed by compound_id b/c of an assignment object w/o an
# assignment_id is a solicitor w/o an assignment
module.exports = createStore "PoolAssignmentsStores",
   getInitialState: ->
      loading_all: false
      loading_prospects: []
      prospects_by_compound_id: {}

   firstListenerDidMount: ->
      SocketSource.bindOrg("assignment_change")
      SocketSource.bindOrg("ask_change")
      SocketSource.bindOrg("committed_amount_change")

   registerActions: ->
      @on PoolAssignmentsSource.actions.loading, @respondToLoading
      @on PoolAssignmentsSource.actions.loadingAll, @respondToLoadingAll

      @on PoolAssignmentsSource.actions.fetchedProspect, @respondToFetched
      @on PoolAssignmentsSource.actions.fetchedFromContacts, @respondToFetched
      @on PoolAssignmentsSource.actions.fetchForContactIdsIfNotCached, @respondToFetchUnlessCached
      @on PoolAssignmentsSource.actions.updatedStage, (compound_id, res) =>
         target = @state.prospects_by_compound_id[compound_id]
         edited = _.extend {}, target, {assignment_stage: res.stage}
         updated = _.extend {}, @state.prospects_by_compound_id, "#{compound_id}": edited
         @setState {prospects_by_compound_id: updated}

      @on PoolSolicitorsSource.actions.removedSolicitor, @respondToSolicitorRemoved

      # TODO: discuss w/ Product removing websockets and just refetch normally
      @on SocketSource.actions.ask_change, (data={}) ->
         @respondToLoading(data.assignment_id, true)
         _.wait 1000, ->
            PoolAssignmentsSource.fetch(_.makeArray(data.assignment_id))

      @on SocketSource.actions.assignment_change, (data={}) ->
         # prevent overfetching if pool is hasn't been loaded into memory we don't care ab its updatess
         if !Object.keys(this.state.prospects_by_compound_id).length
            return
         pool_in_memory = Object.values(this.state.prospects_by_compound_id).find((prospect) ->
            prospect.pool_id == data.pool_id
         )
         if pool_in_memory
            ids = _.makeArray(data.id)
            @respondToLoading(ids, true)
            _.wait 1000, ->
               PoolAssignmentsSource.fetch(ids)

      @on SocketSource.actions.committed_amount_change, (data={}) ->
         @respondToLoading(data.assignment_id, true)
         _.wait 1000, ->
            PoolAssignmentsSource.fetch(_.makeArray(data.assignment_id))

   respondToLoadingAll: (is_loading) ->
      @setState {loading_all: is_loading}

   respondToLoading: (prospects, is_loading) ->
      loading_prospects = _.clone(@getState("loading_prospects"))
      prospect_ids = _.makeArray(prospects)

      if is_loading
         loading_prospects = loading_prospects.concat(prospect_ids)
      else loading_prospects = _.difference(loading_prospects, prospect_ids)
      @setState {loading_prospects}

   respondToFetchUnlessCached:  (contact_ids, query) ->
      loading_id_set = new Set(@state.loading_prospects)
      uncached_ids = _.reject contact_ids, (id) =>
         _.findWhere @state.prospects_by_compound_id, {solicitor_contact_id: id} ||
         loading_id_set.has(id)

      if uncached_ids.length
         PoolAssignmentsSource.fetchSolicitorsForContactIds(uncached_ids, query)

   respondToFetched: (results) ->
      prospects_by_compound_id = _.reduce results.items, (accum, current) ->
         return _.extend {}, accum, "#{current.compound_id}": current
      , @state.prospects_by_compound_id
      @setState {prospects_by_compound_id}

   respondToSolicitorRemoved: (pool_id, solicitor_id, contact_id) ->
      prospects_by_compound_id = _.cloneData(@getState("prospects_by_compound_id"))
      _.each prospects_by_compound_id, (prospect) ->
         if prospect.pool_id == pool_id && prospect.solicitor_contact_id == contact_id
            prospect.solicitor_contact_id = null
      @setState({prospects_by_compound_id})

   api:
      getLoading: (prospect_id) ->
         _.contains(@getState("loading_prospects"), prospect_id)

      getLoadingAll: ->
         @getState("loadingAll")

      getByContactInPool: (pool_id, contact_id) ->
         @getAssignmentIndex(pool_id)[contact_id]

      getAssignmentIndex: (pool_id) ->
         for_pool = _.filter @state.prospects_by_compound_id, (prospect) ->
            prospect.pool_id == pool_id
         _.indexBy(for_pool, "prospect_contact_id")

      getBySolicitorAssignmentsByContactIds: ->
         _.reduce @state.prospects_by_compound_id, (accum, prospect = {}) ->
            existing = accum[prospect.solicitor_contact_id]
            if existing
               return _.extend {}, accum, "#{prospect.solicitor_contact_id}": [].concat(existing, prospect)
            return _.extend {}, accum, "#{prospect.solicitor_contact_id}": [prospect]
         , {}

      getAssignmentsByCompoundId: -> @state.prospects_by_compound_id
