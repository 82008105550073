module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   React = require("react")

   Gated = ReactLibs.createClass
      propTypes:
         el: ReactLibs.PropTypes.string
         feature: ReactLibs.PropTypes.string.isRequired
         inverse: ReactLibs.PropTypes.bool

      getDefaultProps: ->
         el: "span"

      componentDidMount: ->
         EverTrue.vent.on "org:initialized", @forceUpdate, @

      componentWillUnmount: ->
         EverTrue.vent.off "org:initialized", @forceUpdate, @

      render: ->
         gated = EverTrue.store.org.hasFeature(@props.feature)
         gated = !gated if @props.inverse

         if gated
            ReactLibs.DOMFactories[@props.el]?({className: @props.className || ""}, @props.children)
         else
            ReactLibs.DOMFactories[@props.el]?({style: {display: "none"}})
