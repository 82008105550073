module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   classNames = require("classnames")
   {div, h4, span} = ReactLibs.DOMFactories
   { DNDController } = require("components/controls/dnd/dnd-controller")
   {createFactory} = require("base/new-utils")
   DNDControllerFactory = createFactory(DNDController)
   SearchBox = createFactory require("components/forms/search-box")
   ModalConfirm = createFactory require("components/modals/modal-confirm")
   EmptyStateMessage = createFactory(require("components/lists/empty-state-message"))
   {createComponent} = require("@evertrue/et-flux")
   Icon = createFactory (require("components/elements/icon").default)
   LinkButton = createFactory(require("@evertrue/et-components").LinkButton)

   createComponent "SortSelector",
      propTypes:
         options: ReactLibs.PropTypes.array #[{label: "", value: "", group: ""}]
         value: ReactLibs.PropTypes.array #[{label: "", value: "", group: ""}]
         header: ReactLibs.PropTypes.string
         action: ReactLibs.PropTypes.string
         maxActive: ReactLibs.PropTypes.number
         activeInstructionContent: ReactLibs.PropTypes.node
         onSave: ReactLibs.PropTypes.func
         onCancel: ReactLibs.PropTypes.func

      getInitialState: ->
         available_items: []
         active_items: []
         query: ""
         is_remove_disabled: false

      componentDidMount: ->
         @setOptions(@props.options, @props.value)

      UNSAFE_componentWillReceiveProps: (newProps) ->
         if _.jsonStringify(@props.options) != _.jsonStringify(newProps.options) ||
               _.jsonStringify(@props.value) != _.jsonStringify(newProps.value)
            @setOptions(newProps.options, newProps.value)

      setOptions: (options, value) ->
         @setState
            active_items: _.map value, _.clone
            available_items: _.map options, _.clone

      handleCancel: ->
         @props.onCancel?()
         @handleClose()

      handleClose: ->
         @props.requestHide?()

      handleSave: ->
         unless _.isEmpty(@state.active_items)
            @props.onSave?(@state.active_items)
            @handleClose()

      handleRemoveAll: ->
         @setState
            active_items: []
            available_items: @state.available_items.concat(@state.active_items)
            is_remove_disabled: true

      handleAddAll: ->
         @setState
            active_items: @state.active_items.concat(@state.available_items)
            available_items: []
            is_remove_disabled: false

      handleRemoveItem: (remove_item) ->
         @setState
            available_items: @state.available_items.concat _.extend({}, remove_item, {selected: false})
            active_items: _.filter @state.active_items, (item) ->
               item.value != remove_item.value
            is_remove_disabled: @state.active_items.length == 1 && remove_item.value == @state.active_items[0].value  

      handleAddItem: (add_item) ->
         @setState
            active_items: @state.active_items.concat(_.omit(add_item, "component"))
            available_items: _.filter @state.available_items, (item) ->
               item.value != add_item.value
            is_remove_disabled: !@state.active_items.length == 0

      handleSearch: (query) ->
         @setState {query: query}

      handleReorder: ({available, active}) ->
         @setState {available_items: available, active_items: active}

      renderAvailableEmptyState: ->
         if _.isEmpty(@state.available_items)
            EmptyStateMessage
               text: "All available items are currently active"
               icon: "thumbs-up"
               size: "small"
         else
            EmptyStateMessage
               text: "No available items match your search"
               icon: "ban"
               size: "small"

      renderActiveEmptyState: ->
         EmptyStateMessage
            size: "small"
            text: "At least one item must be active"
            icon: "alert-circle"

      renderListItem: (item, list) ->
         is_add_disabled = @props.maxActive && @state.active_items?.length == @props.maxActive

         div className: classNames("drag-list--item", "is-disabled": is_add_disabled && list == "available"),
            Icon className: "drag-list--handle text-muted", icon: "menu"
            span className: "drag-list--label", item.label
            if list == "available"
               if !is_add_disabled
                  span className: "drag-list--icons", onClick: ((e) => @handleAddItem(item)),
                     Icon className: "icon-action", icon: "arrow-right"
            else
               span className: "drag-list--icons", onClick: ((e) => @handleRemoveItem(item)),
                  Icon className: "text-muted", icon: "cancel"

      render: ->
         edited_available = @state.available_items
            .filter (item) =>
               _.searchCompare(item.label, @state.query)
            .map (item) =>
               _.extend {}, item, {component: @renderListItem(item, "available")}

         edited_active = @state.active_items
            .map (item) => _.extend {}, item, {component: @renderListItem(item, "active")}

         ModalConfirm
            disableButton: _.isEmpty(@state.active_items)
            header: @props.header
            buttonLabel: @props.action
            onSubmit: @handleSave
            onCancel: @handleCancel



            div className: classNames("sort-selector", @props.className),
               div className: "sort-selector--top-row",
                  div className: "sort-selector--available",
                     div className: "sort-selector--heading",
                        h4 null, "Available"
                        unless @props.maxActive
                           LinkButton title: "Add all", onClick: (if @state.available_items?.length == 0 then -> else @handleAddAll), className: (if @state.available_items?.length == 0 then "text-muted-hover" else ""), disabled: @state.available_items?.length == 0,
                              "Add All"
                              Icon icon: "arrow-right"
                     div className: "sort-selector--search",
                        SearchBox
                           placeholder: "Search..."
                           onKeyUp: @handleSearch
                           defaultValue: @state.query
                  div className: "sort-selector--active",
                     div className: "sort-selector--heading",
                        h4 null, "Active"
                        LinkButton title: "Remove all", className: (if @state.is_remove_disabled then "text-muted-hover" else "text-danger"), disabled: @state.is_remove_disabled, onClick: (if @state.is_remove_disabled then -> else @handleRemoveAll),
                           "Remove All"
                           Icon icon: "cancel"
                     div className: "sort-selector--active-instruction-content",
                        @props.activeInstructionContent

               DNDControllerFactory
                  onReorder: @handleReorder
                  className: "sort-selector--content"
                  lists: {
                     available: {
                        items: edited_available,
                        notSortable: true
                        grouped: true,
                        emptyStateComponent: @renderAvailableEmptyState(),
                        disabled: @props.maxActive && @state.active_items?.length == @props.maxActive
                     },
                     active:{ items: edited_active, emptyStateComponent: @renderActiveEmptyState()}
                  }
