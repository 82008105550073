import _ from "underscore";
import get from "lodash.get";
import { Component } from "react";
import classNames from "classnames";
import { getAddresses } from "mixins/address-mixin";
import AddressCard from "apps/profile/components/address-card";

class CapacityInfo extends Component {
  static propTypes = {
    contact: ReactLibs.PropTypes.object,
  };

  static defaultProps = {
    contact: {},
  };

  renderSlides(addresses) {
    return addresses.map(address => ({
      content() {
        return <AddressCard address={address} />;
      },
    }));
  }

  render() {
    const { contact } = this.props;
    const capacity_score = get(contact, "giving.capacity_score.value", 0);
    let addresses = getAddresses(this.props.contact);
    if (_.isEmpty(addresses)) {
      addresses = [{}];
    }

    return (
      <div className="capacity-info">
        <div className="capacity-info--score">
          <div className={classNames("capacity-info--score-value", { "is-empty": !capacity_score })}>
            {capacity_score || "Not Provided"}
          </div>
          Your Capacity Score
        </div>
      </div>
    );
  }
}

export default CapacityInfo;
