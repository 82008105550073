module.exports = do ->
   EverTrue = require("app")
   React = require("react")
   BaseRouter = require("base/base-router")
   AdminWrapper = require("apps/admin/components/admin-wrapper")
   {createFactory} = require("base/new-utils")
   AdminGatingController = createFactory(require("apps/admin/controllers/admin-gating-controller"))
   UserGatingController = require("apps/admin/controllers/user-gating-controller")

   class AdminRouter extends BaseRouter
      routes:
         "admin/gates/active": "active_gating"
         "admin/gates/advanced": "adv_gating"

      beforeRouteFilter: ->
         !!EverTrue.store.user.isSuperUser()

      active_gating: ->
         EverTrue.execute("setBrowserTitle", "Admin | Active Gates")
         @currentView = AdminWrapper
            active: "active_gates"
            subtitle: "Your feature flags for #{EverTrue.store.org?.get?("name")}"
            UserGatingController()
         EverTrue.page.mount @currentView

      adv_gating: ->
         EverTrue.execute("setBrowserTitle", "Admin | Adv. Gating")
         @currentView = AdminWrapper
            active: "advanced_gates"
            subtitle: "Flagging for all features in all organizations",
            AdminGatingController()
         EverTrue.page.mount @currentView
