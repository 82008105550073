module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   {div} = ReactLibs.DOMFactories

   LoadingMixin =
      loading: (data, centered) ->
         syncing = if _.isObject(data) then data.syncing else data
         if syncing
            div className: "loading-overlay",
              div className: "loader"

      moduleSpinner: (loading_state) ->
         if loading_state
            div className: "loading-overlay",
               div className: "loader is-top-centered"

