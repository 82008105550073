module.exports = do ->
   _ = require("underscore").default
   moment = require("moment")

   # This is the new version of SortedCollectionMixin
   # to be used without backbone collections.
   SortUtils =
      getDefaultSort: (key) ->
         {sortProp: key, sortReverse: false}

      getQueryFromObj: (sort_object={}) ->
         {sortProp, sortReverse} = sort_object
         @getQuery(sortProp, sortReverse)

      getQuery: (key, reverse=false) ->
         order = if reverse then "desc" else "asc"
         [_.toObject(key, {order: order})]

      changeSort: (key, prev_obj) ->
         sort =
            sortProp: key
            sortReverse: if key == prev_obj.sortProp then !prev_obj.sortReverse else false

      groupResultsByDate: (items, getTimestamp) ->
         grouped = {}
         _.each items, (item) ->
            timestamp = getTimestamp(item) if _.isFunction(getTimestamp)
            day = moment(timestamp).format("YYYY-MM-DD")
            month = moment(timestamp).format("YYYY-MM-01")

            grouped[month] ?= {}
            grouped[month][day] ?= []
            grouped[month][day].push item

         groupedArray = _.map grouped, (days, month) ->
            monthData = {month: month}
            dayData = _.map days, (content, day) -> {day: day, data: content}
            monthData.data = _.sortBy dayData, "day"
            monthData.data.reverse()
            monthData

         sortedGroupedData = _.sortBy groupedArray, "month"
         sortedGroupedData.reverse()
         sortedGroupedData
