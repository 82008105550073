module.exports = do ->
   _ = require("underscore").default
   EverTrue = require("app")
   {createStore} = require("@evertrue/et-flux")
   ColumnSource = require("apps/contact/sources/column-source")
   PropertySource = require("apps/contact/sources/property-source")
   PropertyStore = require("apps/layout/stores/property-store")

   _groups =
      summary: "Summary"
      capacity: "Capacity"
      work: "Career"
      education: "Education"
      engagement: "Engagement"
      giving: "Giving History"
      location: "Location"
      true_view: "TrueView"
      wealth_enrichment: "Wealth Enrichment"
      career_moves: "Career Moves"

   _gated = (feature) ->
      EverTrue.store?.org?.hasFeature(feature)

   _gateForOrg = (prop_oid) ->
      EverTrue.store?.org?.get("id") == prop_oid


   createStore "ContactColumnStore",
      getInitialState: ->
         applied_columns: []
         custom_column_options: []
         all_columns: [
            {label: "Annual Giving Score", key: "annual_giving_score", group: _groups.summary, default: true, gated: -> _gated("give_reporting") and _gated("annual_giving_score")}
            {label: "EverTrue Score", key: "evertrue_score", group: _groups.summary, default: true, gated: -> _gated("scores") }
            {label: "Assigned To", key: "assignee", group: _groups.summary, default: true }
            {label: "Unassigned", key: "unassigned", group: _groups.summary }
            {label: "Constituency", key: "constituency", group: _groups.summary, default: true}
            {label: "Solicitation Codes", key: "solicitation_codes", group: _groups.summary}
            {label: "Primary Email", key: "primary_email", group: _groups.summary}
            {label: "Remote ID", key: "remote_id", group: _groups.summary}

            {label: "Your Capacity Score", key: "capacity_score", group: _groups.capacity, default: true}
            {label: "Is Unrated?", key: "is_unrated", group: _groups.capacity}
            {label: "Wealthy Neighborhood", key: "wealthy_neighborhood", group: _groups.capacity}
            {label: "Donor Score", key: "donor_score", group: _groups.capacity}
            {label: "Donor Potential Score", key: "donor_potential_score", group: _groups.capacity, default: true, toolTip: "The Donor Potential Score is a score from 1-100 that indicates the strength of a constituent's giving pattern. The score is calculated using gift transaction information, lifetime giving, and other data points. Scores are calculated nightly for each constituent with gift transaction information."}

            {label: "Company", key: "company", group: _groups.work, default: true}
            {label: "Title", key: "title", group: _groups.work, default: true}
            {label: "Function", key: "function", group: _groups.work}
            {label: "Industry", key: "industry", group: _groups.work, default: true}

            {label: "Graduation Year", key: "grad_year", group: _groups.education, default: true}
            {label: "Education", key: "education", group: _groups.education}
            {label: "Major", key: "major", group: _groups.education}
            {label: "Athletics", key: "athletics", group: _groups.education}
            {label: "Activities", key: "extracurricular_activities", group: _groups.education}

            {label: "Your Engagement Score", key: "engagement_score", group: _groups.engagement, default: true}
            {label: "Facebook Post Likes", key: "facebook_like_count", group: _groups.engagement, default: true}
            {label: "Facebook Post Reactions", key: "facebook_reaction_count", group: _groups.engagement, default: true}
            {label: "Engaged with Facebook Post", key: "facebook_engagement", group: _groups.engagement, default: true}
            {label: "Last Facebook Post Engagement", key: "last_fb_engagement", group: _groups.engagement}
            {label: "Facebook Post Comments", key: "facebook_comments_count", group: _groups.engagement}
            {label: "Facebook Match?", key: "facebook_match", group: _groups.engagement}

            {label: "Lifetime Giving", key: "lifetime_giving", group: _groups.giving, default: true}
            {label: "Largest Gift", key: "largest_gift", group: _groups.giving, default: true}
            {label: "Last Gift", key: "last_gift", group: _groups.giving, default: true}
            {label: "Last Gift Date", key: "last_gift_date", group: _groups.giving, default: true}
            {label: "Pledge Balance", key: "lifetime_pledge_balance", group: _groups.giving, gated: -> _gated("imported_pledge_balance")}
            {label: "Current FY Giving", key: "current_fiscal_giving", group: _groups.giving}
            {label: "CFY Giving - 1", key: "last_fiscal_giving", group: _groups.giving}
            {label: "CFY Giving - 2", key: "last_fiscal_giving_2", group: _groups.giving}
            {label: "CFY Giving - 3", key: "last_fiscal_giving_3", group: _groups.giving}
            {label: "CFY Giving - 4", key: "last_fiscal_giving_4", group: _groups.giving}
            {label: "CFY Giving - 5", key: "last_fiscal_giving_5", group: _groups.giving}

            {label: "Location", key: "location", group: _groups.location, default: true}
            {label: "City", key: "city", group: _groups.location}
            {label: "State", key: "state", group: _groups.location}
            {label: "Country", key: "country", group: _groups.location}

            {label: "Net Worth", key: "net_worth", group: _groups.wealth_enrichment, gated: -> _gated("windfall")}
            {label: "Physical Assets", key: "physical_assets", group: _groups.wealth_enrichment,  gated: -> _gated("windfall")}
            {label: "Wealth Indicators", key: "wealth_indicators", group: _groups.wealth_enrichment,  gated: -> _gated("windfall")}
            {label: "Wealth Life Events", key: "wealth_life_events", group: _groups.wealth_enrichment,  gated: -> _gated("windfall")}

            {label: "Enriched Current Company", key: "enriched_current_company", group: _groups.true_view}
            {label: "Enriched Current Title", key: "enriched_current_title", group: _groups.true_view}
            {label: "Enriched Metro Area", key: "enriched_metro", group: _groups.true_view}
            {label: "Enriched City", key: "enriched_city", group: _groups.true_view}
            {label: "Enriched State", key: "enriched_state", group: _groups.true_view}
            {label: "Enriched Country", key: "enriched_country", group: _groups.true_view}
            {label: "Linkedin URL", key: "enriched_linkedin", group: _groups.true_view}
            {label: "Facebook URL", key: "enriched_facebook", group: _groups.true_view}
            {label: "Twitter URL", key: "enriched_twitter", group: _groups.true_view}
            {label: "Linkedin Connections", key: "linkedin_connections", group: _groups.true_view}
            {label: "Twitter Followers", key: "twitter_followers", group: _groups.true_view}

            {label: "Career Moves Type", key: "career_moves_type", group: _groups.career_moves, gated: -> _gated("career_updates")}
            {label: "Career Moves Last File Date", key: "career_moves_date", group: _groups.career_moves,  gated: -> _gated("career_updates")}
            {label: "Career Moves Start Date", key: "career_moves_start_date", group: _groups.career_moves,  gated: -> _gated("career_updates")}
            
         ]

      firstListenerDidMount: ->
         ColumnSource.fetch()

      registerActions: ->
         @on ColumnSource.actions.fetchedColumns, @respondToChange
         @on ColumnSource.actions.updatedColumns, @respondToChange

         @on PropertySource.actions.fetchedSchema, (schema) ->
            options = _.compact _.map PropertyStore.getCustomFields(), (field) ->
               if field.name
                  {label: field.description, key: "custom:#{field.name}", group: "Custom Fields", gated: -> _gateForOrg(field.oid)}
            @setState {custom_column_options: options}

      respondToChange: (columns) ->
         applied_columns =
            if _.isEmpty(columns)
               _.cloneData @getDefaultColumns()
            else
               all_columns_index = _.indexBy @getAllOrgColumns(), "key"
               _.map columns, (col) -> _.extend {}, col, all_columns_index[col.key]

         @setState {applied_columns: applied_columns}

      getAllOrgColumns: ->
         @getState("all_columns").concat(@getState("custom_column_options"))

      getDefaultColumns: ->
         _.filter @getState("all_columns"), (col) ->
            col.default && (!col.gated || col.gated?())

      api:
         getColumns: ->
            applied = @getState("applied_columns")
            options = @getAllOrgColumns()
            _.compact _.map options, (col) ->
               if !col.gated || col.gated?()
                  if !_.findWhere applied, {key: col.key}
                     _.extend {}, col, {value: col.key}

         getAppliedColumns: ->
            options = @getAllOrgColumns()
            _.compact _.map @getState("applied_columns"), (col) ->
               config = _.findWhere(options, {key: col.key})
               if (config && (!config?.gated || config?.gated?()))
                  _.extend {}, col, {value: col.key}

