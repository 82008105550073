module.exports = do ->
   _ = require("underscore").default
   React = require("react")
   {createComponent} = require("@evertrue/et-flux")
   {svg} = ReactLibs.DOMFactories

   createComponent "Chart",
      propTypes:
         className: ReactLibs.PropTypes.string
         width: ReactLibs.PropTypes.number
         height: ReactLibs.PropTypes.number

      render: ->
         svg
            className: @props.className || ""
            width: @props.width
            height: @props.height
            viewBox: "0 0 #{@props.width} #{@props.height}"
            preserveAspectRatio: "xMidYMid meet",
               @props.children


