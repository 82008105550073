module.exports = do ->
   React = require("react")
   {a, div} = ReactLibs.DOMFactories
   {createComponent} = require("@evertrue/et-flux")
   _ = require("underscore").default
   {createFactory} = require("base/new-utils")
   AdvancedCombobox = createFactory(require("@evertrue/et-components").AdvancedCombobox)
   TimezoneHelper = require("entities/helpers/timezone-helper")

   TIME_ZONES = TimezoneHelper.getFormattedGroups()
   USA_ZONES = TIME_ZONES[0].items

   createComponent "TimeZoneDropdown",
      propTypes:
         value: ReactLibs.PropTypes.any
         onChange: ReactLibs.PropTypes.func
         disabled: ReactLibs.PropTypes.bool

      getInitialState: ->
         return {
            zone: null
            zones: USA_ZONES
            zone_type: "usa"
         }

      render: ->
         zones = if @state.zone_type == "usa"
            _.uniq([].concat(USA_ZONES, @props.value), (zone) -> zone.value)
         else
            TIME_ZONES
         AdvancedCombobox
            className: @props.className
            placeholder: "Select a timezone"
            options: zones
            value: @props.value || TIME_ZONES[1]
            searchable: true
            grouped: if @state.zone_type == "usa" then false else true
            disabled: @props.disabled
            onChange: (val) =>
               @props.onChange(val)

            if @state.zone_type != "all"
               div className: "timezone-dropown--view-all",
                  a onClick: (=> @setState({zone_type: "all"})),
                     "View All Timezones"
